import { useState, useEffect } from 'react';
import { anchorTag, numberWithCommas, hccSeperator, textToOpenOrCloseFormatRender } from '../../assets/constants/customRender';
import { getAcaIssuer, getAcaIssuerList } from '../../assets/constants/issuerList';
import api from '../../assets/constants/Rafservice.Instance';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import SingleHorizontalBar from '../../components/Charts/SingleHorizontalBar.chart';
import AcaIssuersSelect from '../../components/DropDowns/AcaIssuersSelect';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import history from '../../history';
import ServerSideGridTable from '../../components/TableGrids/ServerSideGridTable.table';
import { HCC, PROVIDER_ID,MAO_Chart_Review_Member,MAO_TYPE } from '../../assets/constants/string.constants';
import CardComponent from '../../components/Cards/card';
import BarVertical from '../../components/GChart/BarVertical';
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';
import { getMeInitialSelect} from '../../assets/constants/meInitialList';

const MaoHCCRecomendation = (props) => {

    const [HccChartData, setHccChartData] = useState([]);
    const [issuer, setIssuer] = useState(getAcaIssuer());
    const [typeName, settypeName] = useState(localStorage.getItem(MAO_TYPE));

    const memberSummaryColumn = [
        {
            label: "Member ID",
            name: "MEMBER_ID",
            options: anchorTag
        },
        {
            name: "Reported_hcc",
            label: "Reported HCCs",
            options: hccSeperator
        },
        {
            name: "Gap_hcc",
            label: "Gap HCCs",
            options: hccSeperator
        },
        {
            name: "Raf_score",
            label: "RAF Score",
        },
        {
            name: "Gap_in_raf_score",
            label: "Gap in raf score",
        },
        {
            name: "Revenu_leekage",
            label: "Potential Revenue Impact $",
            options:numberWithCommas
        },
        {
            name: "Revenue_percent",
            label: "Potential Revenue Impact %",
        },
        {
            name: "Hcc_score",
            label: "Reported HCC Score",
        },
        {
            name: "Gap_in_hcc_score",
            label: "Gap In HCC Score",
        },
        {
            name: "HCC_CAPTURE_RECOMMENDATION",
            label: "RECOMMENDATION",
        },
        {
            name: "OPPORTUNITY_FLAG",
            label: "OPPORTUNITY FLAG",
            options:textToOpenOrCloseFormatRender
        },
        {
            name: "PROVIDER_NAME",
            label: "PROVIDER NAME"
        },
    ];

    const memberSummaryTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(MAO_Chart_Review_Member, rowdata[cellMeta.rowIndex].MemberID);
            history.push('/layout/medicare/mao_member_summary')
        }else if ((cellMeta.colIndex === 1) && (colData.props.children != "")) {
            if(typeof(colData.props.children) == "object"){
                localStorage.setItem(HCC,(cellMeta.event.target.innerText).replace(',',''));
            }
            else{
                localStorage.setItem(HCC,rowdata[cellMeta.rowIndex].ReportedHCCS);
            }
            history.push('/layout/medicare/reported_hcc')
        }else if ((cellMeta.colIndex === 2) && (colData.props.children != "")) {
            if(typeof(colData.props.children) == "object"){
                localStorage.setItem(HCC,(cellMeta.event.target.innerText).replace(',',''));
            }
            else{
                localStorage.setItem(HCC,rowdata[cellMeta.rowIndex].AddedHccs);
            }
            history.push('/layout/medicare/chart_review_hcc_add')
        }else if ((cellMeta.colIndex === 3) && (colData.props.children != "")) {
            if(typeof(colData.props.children) == "object"){
                localStorage.setItem(HCC,(cellMeta.event.target.innerText).replace(',',''));
            }
            else{
                localStorage.setItem(HCC,rowdata[cellMeta.rowIndex].DeletedHccs);
            }
            history.push('/layout/medicare/chart_review_hcc_delete')
        }
    }
    useEffect(() => {
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect
    ])

    useEffect(() => {
    }, [
        issuer
    ])

    return (
        <div className="level-summary">
            <div className="row pb-2">
                <div className="col-lg-12">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Members By Recomandation Type'} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="hcc-list pb-5">
                    <CardComponent  
                        heading={`HCC RECOMENDATION - ${typeName} - ${props.yearSelect} / ${props.meInitialSelect}`}
                        body={
                                <ServerSideGridTable
                                    url={`/HccCaptureRecoDrillDown/year/${getAcaYear()}/period/${getMeInitialSelect()}/type/${typeName}`}
                                    columns={memberSummaryColumn}
                                    tableClick={memberSummaryTableClick}
                                ></ServerSideGridTable>
                            }>
                    </CardComponent>
            </div>
            <br />
            <br />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(MaoHCCRecomendation);
