import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import WidgetCard from "../../components/Cards/WidgetCard";
import SingleVerticalBarChart from '../../components/Charts/SingleVerticalBar.Chart';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import GridTable from "../../components/TableGrids/GridTable.table";
import { anchorTag, hccSeperator, numberWithCommas, numberWithCommasPrefixDolar } from '../../assets/constants/customRender'
import history from "../../history";
import { MEMBER_ID, ISSUER_ID, HCC, WIDGET_TYPES, REPORTED_INSIGHTS, MEMBER_NAME, CARRIER_ID, PLAN_ID, GENDER, MAO_RISK_CONDITION_CHART } from "../../assets/constants/string.constants";
import { percentFormat } from "../../assets/helpers/formats.helpers";
import CardComponent from "../../components/Cards/card";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import GChart from '../../components/GChart/GChart';
import IssuersACA from '../ACA/ACAIssuers.page';
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import SubHeader from '../../components/home/header/subHeader';
import { getViewBySelect } from '../../assets/constants/viewByList';
import { getModelSelect } from '../../assets/constants/modelList';
import { getMetalTierSelect } from '../../assets/constants/metalTierList';
import { getPlanSelect } from '../../assets/constants/planList';
import { getModuleSelect } from '../../assets/constants/moduleList';
import Link from '@material-ui/core/Link';
import { connect } from 'react-redux';
import BarVerticalHCC from '../../components/GChart/BarVerticalHCC';
import { getMeInitialSelect} from '../../assets/constants/meInitialList';

//import google from "react-google-charts";
const MaoRafDrillDown = (props) => {

    const [drillDownYearData, setDrillDownYearData] = useState([]);
    const [chartRiskCondition] = useState(localStorage.getItem(MAO_RISK_CONDITION_CHART));
    const [HccChartData, setHccChartData] = useState([]);

     //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    const loadMaoRafDrillDown = () => {
        api.get('/RafDrillDown/year/'+getAcaYear()+'/rafRange/'+chartRiskCondition+'/period/'+getMeInitialSelect())
        .then(res => {
            setDrillDownYearData(res);
        })
        .catch(err => {
            // console.log(err)
        })
    }
  
     //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    const loadMaoRafDrillDownGraph = () => {
        let chartDataRow = [];
        chartDataRow.push(["RisK Model", "Member Count", { role: 'annotation' }]);
        api.get('/RafDrillDownGraph/year/'+ getAcaYear() +'/range/'+chartRiskCondition+'/period/'+getMeInitialSelect())
            .then(res => {
                for (const dataObj of res) {
                    chartDataRow.push([dataObj.description + ':' + dataObj.hccCode, dataObj.totalCount, dataObj.totalCount]);
                }
                setHccChartData(chartDataRow);
            })
        
    }

    const DrillDownYearDataColumns = [
        {
            name: "totalMembers",
            label: "Total Members",
            options:numberWithCommas,
        },
        {
            name: "totalRevenue",
            label: "Total Revenue $",
            options:numberWithCommasPrefixDolar,
        },
        {
            name: "membersSingleHcc",
            label: "Members Single Hcc",
            options:numberWithCommas,
        },
        {
            name: "membersSingleHccRevenue",
            label: "Members Single Hcc Revenue $",
            options:numberWithCommasPrefixDolar,
        },
        {
            name: "membersMultipleHcc",
            label: "Members Multiple Hcc",
            options:numberWithCommas,
        },
        {
            name: "membersMultipleHccRevenue",
            label: "Members Multiple Hcc Revenue $",
            options:numberWithCommasPrefixDolar,
        },
        {
            name: "membersMultipleHccWithInteraction",
            label: "Members Multiple Hcc With Interaction",
            options:numberWithCommas,
        },
        {
            name: "membersMultipleHccWithInteractionRevenue",
            label: "Members Multiple Hcc With Interaction Revenue $",
            options:numberWithCommasPrefixDolar,
        },
        {
            name: "membersMultipleHccWithoutInteraction",
            label: "Members Multiple Hcc Without Interaction",
            options:numberWithCommas,
        },
        {
            name: "membersMultipleHccWithoutInteractionRevenue",
            label: "Members Multiple Hcc Without Interaction Revenue $",
            options:numberWithCommasPrefixDolar,
        },
    ];

    useEffect(() => { 
        loadMaoRafDrillDown();     
        loadMaoRafDrillDownGraph();     
    },  [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect(),
        chartRiskCondition
    ]);

    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Raf DrillDown'} />
                        </div>
                    </div>
                </div>
                
                <div className="col-lg-12 pr-0 pl-0 mt-3">
                    <CardComponent
                        heading={`SPLIT UP FOR MEMBERS WITH RAF RANGE - `+chartRiskCondition+` FOR YEAR `+props.yearSelect+` / `+props.meInitialSelect}
                        body={
                            <div className="box-height-chart">
                                <GridTable
                                    data={drillDownYearData}
                                    columns={DrillDownYearDataColumns}
                                ></GridTable>
                            </div>
                        }></CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-3">
                    <CardComponent
                        heading={`TOP 10 HCC's REPORTED`}
                        body={
                            <BarVerticalHCC data={HccChartData} chartType="BarChart" height="400px" width="100%" setStorgeItemName={HCC} redirectUrl={'medicare/hcc_drill_down'} hAxisTitle={"Member Count"} yAxistitle={"HCC's"}></BarVerticalHCC>
                        }></CardComponent>
                </div>
                
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br/>

                <br/>
                <br/> 

                    
            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(MaoRafDrillDown);
