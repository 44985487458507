import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { Chart } from "react-google-charts";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


const BarChartReprtingAWV = (props) => {
    const chart = useRef(null);
    const [type, setType] = useState('');
    const [data, setData] = useState([]);

    useLayoutEffect(() => {
        console.log(props.data) 
        setData(props.data);

    }, [props.data]);

    const options = {
        // width: 900,
        // height: 500,
        // series: {
        //   // Gives each series an axis name that matches the Y-axis below.
        //   0: { axis: "Temps" },
        //   1: { axis: "Daylight" },
        // },
        // axes: {
        //   // Adds labels to each axis; they don't have to match the axis names.
        //   y: {
        //     Temps: { label: "Temps (Celsius)" },
        //     Daylight: { label: "Daylight" },
        //   },
        // },
        hAxis: { title: "Distinct count of Awe Id" },
        legend: { position: "right" },
        vAxis: { title: "Month of Service Date", minValue: 0 },
        chartArea: { width: "78%", height: "70%" },
        colors: ['#1a9698', '#fcb441', '#e3e3e3']
    };

    return (
        <Chart
            chartType="BarChart"
            width="100%"
            height="400px"
            data={data}
            options={options}
        />
        // <div id="chartdivStack" style={{ width: "100%", height: "400px", cursor: 'pointer' }}></div>
    );
}
export default BarChartReprtingAWV;