import React, { useState, useEffect, useRef } from 'react';
import Select, { AriaOnFocus } from 'react-select';

const CustomAutocomplete = (props) => {
    const [options, setOptions] = React.useState(props.options);
    const [value, setValue] = React.useState(props.value);
    const optionsRef = useRef(null);
    // console.log('CustomAutocomplete', props);

    useEffect(() => {
        setOptions(props.options);
    }, [props.options]);

    useEffect(() => {
        // console.log('props.value', props.value, props.index);
        if (props.value === undefined) {
            setValue('');
        } else {
            setValue(props.value);
        }
    }, [props.value]);

    useEffect(() => {
        setOptions([]);
    }, [props.ModelOpen]);

    useEffect(() => {
        // Add event listener to handle clicks outside the input field
        const handleClickOutside = (event) => {
            if (optionsRef.current && !optionsRef.current.contains(event.target)) {
                setOptions([]); // Clear options when clicked outside
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleKeyDown = (event) => {
        const optionsData = document.querySelectorAll('.options li');
        if (!options || options.length === 0) {
            return;
        }
        const currentIndex = options.indexOf(value);
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            if (currentIndex < options.length - 1) {
                setValue(options[currentIndex + 1]);
                optionsData[currentIndex + 1].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            if (currentIndex > 0) {
                setValue(options[currentIndex - 1]);
                optionsData[currentIndex - 1].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        } else if (event.key === 'Enter') {
            if (currentIndex !== -1) {
                // Simulate click event on the currently selected option
                optionsData[currentIndex].click();
                event.preventDefault(); // Prevent form submission if needed
            }
        }
    };

    return (
        <div className="form-group mb20 custom-autocomplete" ref={optionsRef}>
            <input
                {...props}
                type="text"
                value={value}
                placeholder="Type to search..."
                autoComplete="off"
                className="form-control"
                onKeyDown={handleKeyDown}
                style={{ width: "200px" }}
            />
            <ul className="options custom-scrollbar">
                {options && options.length > 0 && options.map((option, index) => (
                    <li key={index} className={option === value ? 'selected' : ''} onClick={() => props?.handleOnClick(option)}>
                        {option}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CustomAutocomplete;
