import { useState, useEffect } from 'react';
import { anchorTag, numberWithCommas, hccSeperator, textToOpenOrCloseFormatRender, anchorTagModelOpen, addSuffixTwoDecimal } from '../../assets/constants/customRender';
import { getAcaIssuer, getAcaIssuerList } from '../../assets/constants/issuerList';
import api from '../../assets/constants/Rafservice.Instance';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import SingleHorizontalBar from '../../components/Charts/SingleHorizontalBar.chart';
import AcaIssuersSelect from '../../components/DropDowns/AcaIssuersSelect';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import history from '../../history';
import ServerSideGridTable from '../../components/TableGrids/ServerSideGridTable.table';
import { HCC, PROVIDER_ID,MAO_Chart_Review_Member,MAO_TYPE, MAO_RISK_CONDITION_CHART } from '../../assets/constants/string.constants';
import CardComponent from '../../components/Cards/card';
import BarVertical from '../../components/GChart/BarVertical';
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';
import { getMeInitialSelect} from '../../assets/constants/meInitialList';

const MaoHccDrillDown = (props) => {

    const [HccChartData, setHccChartData] = useState([]);
    const [issuer, setIssuer] = useState(getAcaIssuer());
    const [typeName, settypeName] = useState(localStorage.getItem(MAO_TYPE));
    const [rafRange] = useState(localStorage.getItem(MAO_RISK_CONDITION_CHART));
    const [hcc] = useState(localStorage.getItem(HCC));

    const memberSummaryColumn = [
        {
            label: "Member ID",
            name: "MEMBER_ID",
            options: anchorTag
        },
        {
            name: "hcclist",
            label: "List of HCCs",
            options: hccSeperator
        },
        {
            name: "DEMOGRAPHIC_SCORE",
            label: "DEMOGRAPHIC SCORE",
            options:addSuffixTwoDecimal,
        },
        {
            name: "OREC_SCORE",
            label: "OREC SCORE",
        },
        {
            name: "HCC_SCORE",
            label: "HCC SCORE",
            options:addSuffixTwoDecimal,
        },
        {
            name: "INTERACTION_SCORE",
            label: "INTERACTION SCORE"
        },
        {
            name: "RAF_SCORE",
            label: "RAF SCORE",
            options:addSuffixTwoDecimal,
        },
        {
            name: "REVENUE",
            label: "Revenue $",
        },
        {
            name: "claimcount",
            label: "# Eligible Claims",
        },
        {
            name: "MEMBER_ID",
            label: "Models",
            options: anchorTagModelOpen
        },
    ];

    const memberSummaryTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(MAO_Chart_Review_Member, rowdata[cellMeta.rowIndex].MEMBER_ID);
            history.push('/layout/medicare/mao_member_summary')
        }else if ((cellMeta.colIndex === 1) && (colData.props.children != "")) {
            if(typeof(colData.props.children) == "object"){
                localStorage.setItem(HCC,(cellMeta.event.target.innerText).replace(',',''));
            }
            else{
                localStorage.setItem(HCC,rowdata[cellMeta.rowIndex].hcclist);
            }
            history.push('/layout/medicare/reported_hcc')
        }else if ((cellMeta.colIndex === 9) && (colData.props.children != "")) {
            localStorage.setItem(MAO_Chart_Review_Member, rowdata[cellMeta.rowIndex].MEMBER_ID);
            history.push('/layout/medicare/member_reference')
        }
    }
    useEffect(() => {
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect
    ])

    useEffect(() => {
    }, [
        issuer
    ])

    return (
        <div className="level-summary">
            <div className="row pb-2">
                <div className="col-lg-12">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'DrillDownHCC'} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="hcc-list pb-5">
                    <CardComponent  
                        heading={`List of Active Members with HCC : ${hcc} (Dementia Without Complication) and RAF Range of ${rafRange} for Year ${props.yearSelect}`}
                        body={ 
                                <ServerSideGridTable
                                    url={`/RafDrillDownHcc/hcc/${hcc}/rafRange/${rafRange}/year/${getAcaYear()}/period/${getMeInitialSelect()}`}
                                    columns={memberSummaryColumn}
                                    tableClick={memberSummaryTableClick}
                                ></ServerSideGridTable>
                            }>
                    </CardComponent>
            </div>
            <br />
            <br />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(MaoHccDrillDown);
