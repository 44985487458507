import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
// import MuiDataTableCSS from '../../Styles/MuiDataTable';
import PropTypes from "prop-types";
import api from "../../assets/constants/Rafservice.Instance";
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles
} from "@material-ui/core/styles";
const ServerSideGridTable = (props) => {

    const { url, columns, tableClick, defaultSortCol, defaultSortDir } = props;

    const [rowdata, setRowdata] = useState(['Loading Data...']);
    const [count, setCount] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(10);
    const [Page] = useState(0);
    const [sortOrder] = useState({});
    const [col] = useState(defaultSortCol ? defaultSortCol : 0);
    const [Dir] = useState(defaultSortDir ? defaultSortDir : "asc");
    const [Search] = useState("");
    const [ColumnSearch] = useState("");
    const [TableAfterSearch, setTableAfterSearch] = useState(false);
    const [rowdataCSV, setrowdataCSV] = useState(['Downloading Data...']);

    const loadTable = (pageNo, rowsOnPage, col, dir, searchtxt, columnSearch) => {

        api.get(`${url}?iDisplayStart=${pageNo * rowsOnPage}&iDisplayLength=${rowsOnPage}&iSortCol_0=${col}&sSortDir_0=${dir}&sSearch=${searchtxt}&columnSearch=${columnSearch}`)
            .then(res => {

                setRowdata(res.aaData);
                //setCount(res.iTotalRecords);
                setCount(res.iTotalDisplayRecords);
                props.rowData(res.aaData);
            })
            .catch(err => {
                // console.log(err);
            })

    }
    const downloadTable = (pageNo, rowsOnPage, col, dir, searchtxt, columnSearch) => {
        api.get(`${url}?iDisplayStart=${pageNo * rowsOnPage}&iDisplayLength=${rowsOnPage}&iSortCol_0=${col}&sSortDir_0=${dir}&sSearch=${searchtxt}&columnSearch=${columnSearch}`)
            .then(res => {
                setrowdataCSV(res.aaData);
            })
            .catch(err => {
                // console.log(err);
            })

    }



    const ObjectToFindValue = (objectName, keyName) => {
        // console.log('ObjectToFindValue');
        for (let [key, value] of Object.entries(objectName)) {
            if (key === keyName) {
                // console.log(`${key}: ${value}`);
                return value;
            }
        }
    }

    const DefaultOptions = {
        filter: props.filter ? props.filter : false,
        filterType: 'dropdown',
        responsive: 'standard',
        tableBodyHeight: 'auto',
        tableBodyMaxHeight: '900px',
        selectableRows: false,
        selectedOnly :true, 
        serverSide: true,
        count: count,
        responsive: 'stacked',
        rowsPerPage: RowPerPage,
        rowsPerPageOptions: [10, 25, 50, 100],
        sortOrder: sortOrder,
        downloadOptions: {
            filename: 'Download.csv',
            filterOptions: { useDisplayedColumnsOnly: true },
            useDisplayedRowsOnly: true,
        },
        onCellClick: (colData, cellMeta) => {
            //    console.log(colData,cellMeta);
            if (props.tableClick) {
                tableClick(rowdata, colData, cellMeta);
            }

        },

        onDownload: (buildHead, buildBody, columns, data) => {
            //downloadTable(Page,count,col,Dir,Search);
            let values = [];
            var AllDataArray = []
            let rowArray = [];
            columns.forEach(columnInside => {

                values.push(columnInside.name)
            })
            rowdataCSV.forEach((element, index) => {
                rowArray = [];
                values.forEach((el, i) => {
                    rowArray.push(ObjectToFindValue(element, el));
                });
                AllDataArray.push({ index: index, data: rowArray });
            });
            //data = toArray(filterRowObject)

            return "\uFEFF" + buildHead(columns) + buildBody(AllDataArray);
        },
        onTableChange: (action, tableState) => {

            let filterColumnObject = [];
            let columnArray = [];
            //multiple filter
            if (props.filter == true && action == 'filterChange' && tableState.columns && tableState.filterList) {
                tableState.columns.forEach(element => {
                    if (element.name) {
                        columnArray.push(element.name)
                    }
                });
                if (columnArray && columnArray.length > 0) {
                    tableState.filterList.forEach((element, index) => {
                        if (element && element[0]) {
                            filterColumnObject.push({ [columnArray[index]]: element[0] })
                        }
                    });
                }
                // console.log("filterColumnObject "+filterColumnObject)
            }

            switch (action) {
                case "changePage":
                    loadTable(tableState.page, tableState.rowsPerPage, col, Dir, Search, filterColumnObject.length > 0 ? JSON.stringify(filterColumnObject) : ColumnSearch);
                    break;
                case "sort":
                    loadTable(tableState.page, tableState.rowsPerPage, tableState.activeColumn, tableState.sortOrder.direction, Search, filterColumnObject.length > 0 ? JSON.stringify(filterColumnObject) : ColumnSearch);
                    break;
                case "changeRowsPerPage":
                    setRowPerPage(tableState.rowsPerPage);
                    loadTable(tableState.page, tableState.rowsPerPage, col, Dir, Search, filterColumnObject.length > 0 ? JSON.stringify(filterColumnObject) : ColumnSearch);
                    break;
                case "search":
                    setTableAfterSearch(true);
                    loadTable(tableState.page, tableState.rowsPerPage, col, Dir, tableState.searchText ? tableState.searchText : Search, ColumnSearch);
                    break;
                case "filterChange":
                    setTableAfterSearch(true);
                    loadTable(tableState.page, tableState.rowsPerPage, col, Dir, tableState.searchText ? tableState.searchText : Search, filterColumnObject.length > 0 ? JSON.stringify(filterColumnObject) : ColumnSearch);
                    break;
                case "onSearchClose":
                    // setTableAfterSearch(false);
                    // loadTable(tableState.page,tableState.rowsPerPage,col,Dir,Search,ColumnSearch);
                    break;
            }
        }
    }
    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MuiTableCell: {
                    head: {
                        backgroundColor: "rgb(82, 108, 236) !important",
                        color: "rgb(239, 240, 247) !important",
                        fontWeight: "bold !important"
                    },
                    sortOrder: {
                        backgroundColor: "rgb(82, 108, 236) !important",
                        color: "rgb(239, 240, 247) !important",
                        fontWeight: "bold !important"
                    }
                },
                MUIDataTableSelectCell: {
                    headerCell: {
                        backgroundColor: "rgb(82, 108, 236) !important",
                        color: "rgb(239, 240, 247) !important",
                        fontWeight: "bold !important"
                    }
                },
                MUIDataTableHeadCell: {
                    sortActive: {
                        color: "rgb(239, 240, 247) !important",
                    }
                }
            }
        });


    useEffect(() => {
        loadTable(Page, RowPerPage, col, Dir, Search);

    }, [url]);
    useEffect(() => {
        downloadTable(Page, count, col, Dir, Search);

    }, [count]);
    return (
        <div className="issuer-table table-responsive">
            {(rowdata.length > 0 || TableAfterSearch) ?
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        data={rowdata}
                        columns={columns}
                        options={DefaultOptions}
                    />
                </MuiThemeProvider>
                :
                <h6 className="text-center">No records found!..</h6>
            }
        </div>

    )
}

ServerSideGridTable.propTypes = {
    columns: PropTypes.array,
    url: PropTypes.string,
    tableClick: PropTypes.func,
    defaultSortCol: PropTypes.number,
    defaultSortDir: PropTypes.string
}

export default ServerSideGridTable;
