import React, { useState, useEffect } from "react";
import AWVApi from '../../../../assets/constants/AWVRafservice.Instance';
import { connect } from 'react-redux';
import BarChartDiffDataAWV from '../../../../components/Charts/BarChartDiffDataAWV';
import ColumnChartSingleExcelAWV from '../../../../components/Charts/ColumnChartSingleExcelAWV';
import ColumnChartStackAWV from '../../../../components/Charts/ColumnChartStackAWV';
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import CustomAweDatatable from "../../../../components/TableGrids/CustomAweDatatable";
import moment from "moment";
 

const AweAnalytics = (props) => {

    const [totalAWVVisitOverview, setTotalAWVVisitOverview] = useState();
    const [chartAWVVisitRate, setChartAWVVisitRate] = useState([]);
    const [chartCumulativeAllYear, setChartCumulativeAllYear] = useState('');
    const [chartOverAllVisitYear, setChartOverAllVisitYear] = useState([]);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const cin = urlParams.get('cin');

    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            totalAWVVisitOverviewData();
            chartOverAllVisitYearData();
            chartCumulativeAllYearData();
            chartAWVVisitRateData();
        }
    }, [props.yearSelect, props.providerGroupSelect, props.lobSelect]);


    // Define a sorting function to sort the months based data - Bugfix ID780
    function monthSortKey(month) {
        const monthsOrder = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return monthsOrder.indexOf(month);
    }

    const chartAWVVisitRateData = () => {
        let queryStr = '';
        if (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL')
            queryStr = '&provider_group=' + props.providerGroupSelect;
        AWVApi.get('/get-awv-visit-rate?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + queryStr)
            .then(res => {
                if (res.data) {

                    const responseData = res.data;
                    const sortedData = responseData.sort((a, b) => monthSortKey(a["Month"]) - monthSortKey(b["Month"]));
                    setChartAWVVisitRate(sortedData);
                } else {
                    setChartAWVVisitRate();
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const totalAWVVisitOverviewData = () => {
        var providerGroupSelect = (props.providerGroupSelect != 'ALL') ? props.providerGroupSelect : '';
        let queryStr = '';
        if (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL')
            queryStr = '&provider_group=' + props.providerGroupSelect;
        AWVApi.get('/awv_total_visit?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + queryStr)
            .then(res => {
                if (res.data) {
                    setTotalAWVVisitOverview(res.data);
                } else {
                    setTotalAWVVisitOverview();
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const chartCumulativeAllYearData = () => {
        var providerGroupSelect = (props.providerGroupSelect != 'ALL') ? props.providerGroupSelect : '';
        let queryStr = '';
        if (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL')
            queryStr = '&provider_group=' + props.providerGroupSelect;
        AWVApi.get('/awv_cumulative_visits?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + queryStr)
            .then(res => {
                if (res.data) {
                    setChartCumulativeAllYear(res.data);
                } else {
                    setChartCumulativeAllYear('');
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const chartOverAllVisitYearData = () => {
        var providerGroupSelect = (props.providerGroupSelect != 'ALL') ? props.providerGroupSelect : '';

        let queryStr = '';
        if (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL')
            queryStr = '&provider_group=' + props.providerGroupSelect;

        AWVApi.get('/awv_overall_visit?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + queryStr)
            .then(res => {
                if (res.data) {
                    const responseData = res.data;

                    // Convert the object into an array of key-value pairs, then sort it based on the custom sorting function
                    const sortedDataBasedOnMonth = Object.entries(responseData).sort((a, b) => monthSortKey(a[0]) - monthSortKey(b[0]));

                    // Convert the sorted array back into an object (if necessary)
                    const sortedData = Object.fromEntries(sortedDataBasedOnMonth);
                    setChartOverAllVisitYear(sortedData);
                } else {
                    setChartOverAllVisitYear('');
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const coderAWERecordColumns = [
        {
            name: "Provider_group",
            label: "PROVIDER GROUP",
            options: {
                showColumn: true,
                filter: true,
                filterType: 'text',
                search: true,
                sort: true,
                width: '150px',
                otherColumnName: '',
            }
        },
        {
            name: "Total_Member",
            label: "TOTAL MEMBERS",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: true,
                sort: true,
                width: '140px',
            }
        },
        {
            name: "Progress percentage",
            label: "AWV PROGRESS %",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: true,
                sort: true,
                width: '190px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <div className="flex_item_cs gap10">
                            <span className='flex-shrink-0'>
                                {`${Math.round(value,)}%`}
                            </span>
                            <LinearProgress className="w-100" sx={{ height: 10 }} variant="determinate" value={value} />
                        </div>
                    );
                }
            }
        },
        {
            name: "AWV_Spending",
            label: "AWV SPENDING",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '140px',
                otherColumnName: '',
            }
        },
        {
            name: "awv_form_per_month",
            label: "AVG. AWE'S PROCESSED PER MONTH",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '250px',
                otherColumnName: ''
            }
        },
    ];

    var providerGroupSelectVar = (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL') ? props.providerGroupSelect : '';

    return (
        <>
            <div className="row">
                <div className="col-lg-6 mb-4">
                    <div className="card border p-3 p-lg-4">
                        <div class="card_header flex_item_cb mb24">
                            <h2 class="fs18 card-title text-dark">TOTAL AWE VISITS - OVERVIEW</h2>
                        </div>
                        <div className="card-body p-0">
                            <div className="row no-gutters">
                                <div className="col-sm-7">
                                    <div className="justify-content-center text-center ml-3 mt-5" style={{ width: '230px' }}>
                                        <CircularProgressbarWithChildren
                                            value={(totalAWVVisitOverview?.complete_visit) ? totalAWVVisitOverview?.complete_visit : 0}
                                            strokeWidth={10}
                                            styles={buildStyles({
                                                pathColor: `#1D9A9B`,
                                            })}
                                        >
                                            <div >
                                                <strong style={{ fontSize: 30, marginTop: 0, color: '#000' }}>{(totalAWVVisitOverview?.complete_visit) ? totalAWVVisitOverview?.complete_visit : 0}</strong> <br />
                                                <strong style={{ fontSize: 15, marginTop: 5, color: '#000' }}>Completed</strong>
                                            </div>
                                        </CircularProgressbarWithChildren >
                                        <div className="circular-content mt-3 ml-3 row">
                                            <strong style={{ fontSize: 12, color: '#000' }}>
                                                <div style={{ height: '13px', width: '20px', backgroundColor: '#1D9A9B', position: 'absolute' }}></div>
                                                <div className="ml-4">Completed</div>
                                            </strong>
                                            <strong className="ml-4" style={{ fontSize: 12, color: '#000' }}>
                                                <div style={{ height: '13px', width: '20px', backgroundColor: '#D6D6D6', position: 'absolute' }}></div>
                                                <div className="ml-4">Pending</div>
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-5 border-left">
                                    <div className="overview_chart_item_text p-3 border-bottom" style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <h6 className="fs18">{(totalAWVVisitOverview?.total_visit) ? totalAWVVisitOverview?.total_visit : 0}</h6>
                                        <p className="fs14 fw700 text-dark">Total AWE Visits</p>
                                    </div>
                                    <div className="overview_chart_item_text p-3 border-bottom" style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <h6 className="fs18 text-primary">{(totalAWVVisitOverview?.complete_visit) ? totalAWVVisitOverview?.complete_visit : 0}</h6>
                                        <p className="fs14 fw700 text-dark">Completed AWE Visits</p>
                                    </div>
                                    <div className="overview_chart_item_text p-3 border-bottom" style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <h6 className="fs18 text-warning-700">{(totalAWVVisitOverview?.pending_visit) ? totalAWVVisitOverview?.pending_visit : 0}</h6>
                                        <p className="fs14 fw700 text-dark">Pending AWE Visits</p>
                                    </div>
                                    <div className="overview_chart_item_text p-3" >
                                        <h6 className="fs18 text-primary">{(totalAWVVisitOverview?.void) ? totalAWVVisitOverview?.void : 0}</h6>
                                        <p className="fs14 fw700 text-dark">Void</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 mb-4">
                    <div className="card border p-3 p-lg-4">
                        <div class="card_header flex_item_cb mb24">
                            <h2 class="fs18 card-title text-dark">AWE VISIT RATE BY YEAR</h2>
                        </div>
                        <div className="card-body p-0">
                            <ColumnChartStackAWV data={chartAWVVisitRate} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 mb-4">
                    <div className="card border p-3 p-lg-4">
                        <div class="card_header flex_item_cb mb24">
                            <h2 class="fs18 card-title text-dark">CUMULATIVE (SUM OF ALL MONTHS WITH YEAR)</h2>
                        </div>
                        <div className="card-body p-0">
                            <BarChartDiffDataAWV data={chartCumulativeAllYear} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 mb-4">
                    <div className="card border p-3 p-lg-4">
                        <div class="card_header flex_item_cb mb24">
                            <h2 class="fs18 card-title text-dark">OVERALL VISITS IN A YEAR</h2>
                        </div>
                        <div className="card-body p-0">
                            <ColumnChartSingleExcelAWV data={chartOverAllVisitYear} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                <CustomAweDatatable
                    titleName="AWE Record Details"
                    downloadFileName="diagonsis_code"
                    UrlAxios={'AWVApi'}
                    urlLink={`/get-provider-awe-detail?payment_year=${props.yearSelect}&organisation_id=1&lob=${props.lobSelect}&provider_group=${(providerGroupSelectVar && providerGroupSelectVar != '') ? providerGroupSelectVar : ''}`}
                    columns={coderAWERecordColumns}
                    showColumnBtn={false}
                    searchBox={false}
                    downloadBtn={false}
                    pagination={true}
                    filterBox={false}
                    colSpan={5}
                />
            </div>
        </>
    )

};
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        lobSelect: state.moduleFilter.lobSelect,
    };
}
export default connect(mapStateToProps)(AweAnalytics);
