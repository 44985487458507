import { useState, useEffect } from 'react';
import { anchorTag, numberWithCommas, hccSeperator, textToSignFormatRender, dateFormatRender } from '../../assets/constants/customRender';
import { getAcaIssuer, getAcaIssuerList } from '../../assets/constants/issuerList';
import api from '../../assets/constants/RafserviceKPI.Instance';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import SingleHorizontalBar from '../../components/Charts/SingleHorizontalBar.chart';
import AcaIssuersSelect from '../../components/DropDowns/AcaIssuersSelect';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import history from '../../history';
import ServerSideGridTable from '../../components/TableGrids/ServerSideGridTable.table';
import { HCC, PROVIDER_ID, MAO_Chart_Review_Member, MAO_TYPE, KPI_CATEGORY, KPI_PPG } from '../../assets/constants/string.constants';
import CardComponent from '../../components/Cards/card';
import BarVertical from '../../components/GChart/BarVertical';
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';
import GridTable from "../../components/TableGrids/GridTable.table";
import GaugeChart from 'react-gauge-chart'
import KpiModuleSelect from '../../components/DropDowns/KpiModuleSelect';
import { getKPIList, getKPISelect, KPIList } from '../../assets/constants/kpiList';
import { SELECT_KPI } from "../../assets/constants/string.constants";

const ProviderPerformancePage = (props) => {
    const [memberSummaryDataAED, setMemberSummaryDataAED] = useState([]);
    const [memberSummaryDataAWV, setMemberSummaryDataAWV] = useState([]);
    const [memberSummaryDataHCC, setMemberSummaryDataHCC] = useState([]);
    const [memberSummaryDataLongHCC, setMemberSummaryDataLongHCC] = useState([]);
    const [memberSummaryChartDataAED, setMemberSummaryChartDataAED] = useState(0);
    const [memberSummaryChartDataAWV, setMemberSummaryChartDataAWV] = useState(0);
    const [memberSummaryChartDataHCC, setMemberSummaryChartDataHCC] = useState(0);
    const [memberSummaryChartDataLongHCC, setMemberSummaryChartDataLongHCC] = useState(0);
    const [memberSummaryObjectAED, setMemberSummaryObjectAED] = useState({});
    const [memberSummaryObjectAWV, setMemberSummaryObjectAWV] = useState({});
    const [memberSummaryObjectHCC, setMemberSummaryObjectHCC] = useState({});
    const [memberSummaryObjectLongHCC, setMemberSummaryObjectLongHCC] = useState({});
    const [memberSummaryObjectAll, setMemberSummaryObjectAll] = useState([]);

    const [memberSummarySelectObjectAED, setMemberSummarySelectObjectAED] = useState({});
    const [memberSummarySelectObjectAWV, setMemberSummarySelectObjectAWV] = useState({});
    const [memberSummarySelectObjectHCC, setMemberSummarySelectObjectHCC] = useState({});
    const [memberSummarySelectObjectLongHCC, setMemberSummarySelectObjectLongHCC] = useState({});
    const [memberSummarySelectDataAED, setMemberSummarySelectDataAED] = useState([]);
    const [memberSummarySelectDataAWV, setMemberSummarySelectDataAWV] = useState([]);
    const [memberSummarySelectDataHCC, setMemberSummarySelectDataHCC] = useState([]);
    const [memberSummarySelectDataLongHCC, setMemberSummarySelectDataLongHCC] = useState([]);
    const [AEDSliderSet, setAEDSliderSet] = useState(0);
    const [KPIModuleSelect, setKPIModuleSelect] = useState();
    const memberSummaryAllColumn = [
        {
            label: "# Members Eligible",
            name: "TOTAL Members Eligible"
        },
        {
            label: "# Members Completed",
            name: "Total Members Completed"
        },
        {
            label: "% Completed ",
            name: "Completed"
        },
        {
            label: "# Providers Below Average",
            name: "Total Providers Below Average"
        },
        {
            label: "# Providers Above Average",
            name: "Total Providers Above Average"
        },
    ];
    const memberSummaryColumn = [
        {
            label: "# Members Eligible",
            name: "TOTAL Members Eligible"
        },
        {
            label: "# Members Completed",
            name: "Total Members Completed"
        },
        {
            label: "% Completed ",
            name: "Completed"
        },
    ];


    const loadAllDataCount = () => {
        api.get('/all-data')
            .then(res => {
                if (res.data) {
                    setMemberSummaryDataAWV([res.data[0]]);
                    setMemberSummaryDataAED([res.data[30]]);
                    setMemberSummaryDataHCC([res.data[15]]);
                    setMemberSummaryDataLongHCC([res.data[46]]);

                    setMemberSummaryObjectAWV(res.data[0]);
                    setMemberSummaryObjectAED(res.data[30]);
                    setMemberSummaryObjectHCC(res.data[15]);
                    setMemberSummaryObjectLongHCC(res.data[46]);

                    setMemberSummaryChartDataAWV(res.data[0]?.Completed.replace('%', "") / 100);
                    setMemberSummaryChartDataAED(res.data[30]?.Completed.replace('%', "") / 100);
                    setMemberSummaryChartDataHCC(res.data[15]?.Completed.replace('%', "") / 100);
                    setMemberSummaryChartDataLongHCC(res.data[46]?.Completed.replace('%', "") / 100);
                }

            })
            .catch(err => {
                // console.log(err)
            })
    }

    const loadDataCount = () => {
        api.get('/data')
            .then(res => {
                if (res.data) {
                    setMemberSummaryObjectAll(res.data);
                }

            })
            .catch(err => {
                // console.log(err)
            })
    }


    const chartDataFilter = (Dtype, DPPG) => {
        const val = DPPG.target.value;
        memberSummaryObjectAll.forEach(element => {
            if (element.KPI == Dtype && element.PPG == val) {
                if (Dtype == 'AWV') {
                    setMemberSummarySelectObjectAWV(element);
                    setMemberSummarySelectDataAWV([element]);
                } else if (Dtype == 'HCC') {
                    setMemberSummarySelectObjectHCC(element);
                    setMemberSummarySelectDataHCC([element]);
                } else if (Dtype == 'AED') {
                    setMemberSummarySelectObjectAED(element);
                    setMemberSummarySelectDataAED([element]);
                } else if (Dtype == 'LFHCC') {
                    setMemberSummarySelectObjectLongHCC(element);
                    setMemberSummarySelectDataLongHCC([element]);
                }
            }
        });
    }

    const KPIchartClick = (KPITitle, KPIPPG) => {
      //  console.log(KPITitle);
      //  console.log(KPIPPG);
        localStorage.setItem(KPI_CATEGORY, KPITitle);
        localStorage.setItem(KPI_PPG, KPIPPG);
        history.push('/layout/healthcare/provider_group');
    }


    const chartStyle = {
        height: 'auto',
        width: 300,
    }

    useEffect(() => {
        chartDataFilter("AWV", { target: { value: props.kpiModuleSelect } });
        chartDataFilter("HCC", { target: { value: props.kpiModuleSelect } });
        chartDataFilter("AED", { target: { value: props.kpiModuleSelect } });
        chartDataFilter("LFHCC", { target: { value: props.kpiModuleSelect } });  
    }, [memberSummaryObjectAll]);

    useEffect(() => {
        chartDataFilter("AWV", { target: { value: props.kpiModuleSelect } });
        chartDataFilter("HCC", { target: { value: props.kpiModuleSelect } });
        chartDataFilter("AED", { target: { value: props.kpiModuleSelect } });
        chartDataFilter("LFHCC", { target: { value: props.kpiModuleSelect } });
    }, [props.kpiModuleSelect]);

    useEffect(() => {
        loadAllDataCount();
        loadDataCount();        
        KPIList();
        setKPIModuleSelect(localStorage.getItem(SELECT_KPI));
    }, [])

    return (
        <div className="level-summary">
            <div className="row pb-2">
                <div className="col-lg-12">
                    <div className="state-level">
                        <div className="card-header">
                            <div>
                                <span className="d-flex bd-highlight">
                                    <h6 className="pt-2 flex-grow-1 header-title-text">Provider Performance</h6>
                                    <div className="d-flex align-items-center">
                                        <label className="pr-2 pb-0 mb-0">Select PPG</label>
                                        <KpiModuleSelect
                                            KPIList={getKPIList()}
                                            KPIModuleSelect={KPIModuleSelect}
                                            onKPIModuleSelect={((e) => setKPIModuleSelect(e))}>
                                        </KpiModuleSelect>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-lg-12">
                    <CardComponent
                        heading={`Preventive Visits (AWV - Preventive Visits)`}
                        body={
                            <>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className='d-flex justify-content-center'>
                                            <div onClick={() => KPIchartClick('AWV', 'ALL')} style={{ 'cursor': 'pointer' }}>
                                                <GaugeChart id="gauge-chart6"
                                                    animate={false}
                                                    nrOfLevels={10}
                                                    percent={memberSummaryChartDataAWV}
                                                    needleColor="#345243"
                                                    className="mb-3"
                                                    style={chartStyle}
                                                    textColor={"#000000"}
                                                    arcsLength={[0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1]}
                                                    colors={["#FF0000", "#00FF00"]}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            {memberSummaryObjectAWV && memberSummaryObjectAWV.Completed && memberSummaryObjectAWV["Y-O-Y Change"].includes("-") > 0 ?
                                                <h3><b>{memberSummaryObjectAWV.Completed + ' (Decreased by ' + memberSummaryObjectAWV["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                : memberSummaryObjectAWV && memberSummaryObjectAWV.Completed ?
                                                    <h3><b>{memberSummaryObjectAWV.Completed + ' (Increased by ' + memberSummaryObjectAWV["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        <GridTable
                                            data={memberSummaryDataAWV}
                                            columns={memberSummaryAllColumn}
                                        ></GridTable>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className='d-flex justify-content-center'>
                                            <div onClick={() => KPIchartClick('AWV', props.kpiModuleSelect)} style={{ 'cursor': 'pointer' }}>
                                                <GaugeChart id="gauge-chart6"
                                                    animate={false}
                                                    nrOfLevels={10}
                                                    percent={
                                                        memberSummarySelectObjectAWV && memberSummarySelectObjectAWV.Completed ?
                                                            memberSummarySelectObjectAWV?.Completed.replace('%', "") / 100
                                                            :
                                                            0
                                                    }
                                                    needleColor="#345243"
                                                    className="mb-3"
                                                    style={chartStyle}
                                                    textColor={"#000000"}
                                                    arcsLength={[0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1]}
                                                    colors={["#FF0000", "#00FF00"]}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            {memberSummarySelectObjectAWV && memberSummarySelectObjectAWV.Completed && memberSummarySelectObjectAWV["Y-O-Y Change"].includes("-") > 0 ?
                                                <h3><b>{memberSummarySelectObjectAWV.Completed + ' (Decreased by ' + memberSummarySelectObjectAWV["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                : memberSummarySelectObjectAWV && memberSummarySelectObjectAWV.Completed ?
                                                    <h3><b>{memberSummarySelectObjectAWV.Completed + ' (Increased by ' + memberSummarySelectObjectAWV["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        <GridTable
                                            data={memberSummarySelectDataAWV}
                                            columns={memberSummaryColumn}
                                        ></GridTable>
                                    </div>
                                </div>

                            </>
                        }>
                    </CardComponent>
                </div>
            </div>
            <div className="row mt-3 mb-3">
                <div className="col-lg-12">
                    <CardComponent
                        heading={`Low Value Care (AED - Low Value Care)`}
                        body={
                            <>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className='d-flex justify-content-center'>
                                            <div onClick={() => KPIchartClick('AED', 'ALL')} style={{ 'cursor': 'pointer' }}>
                                                <GaugeChart id="gauge-chart6"
                                                    animate={false}
                                                    nrOfLevels={10}
                                                    percent={memberSummaryChartDataAED}
                                                    needleColor="#345243"
                                                    className="mb-3"
                                                    style={chartStyle}
                                                    textColor={"#000000"}
                                                    arcsLength={[0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1]}
                                                    colors={["#FF0000", "#00FF00"]}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            {memberSummaryObjectAED && memberSummaryObjectAED.Completed && memberSummaryObjectAED["Y-O-Y Change"].includes("-") > 0 ?
                                                <h3><b>{memberSummaryObjectAED.Completed + ' (Decreased by ' + memberSummaryObjectAED["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                : memberSummaryObjectAED && memberSummaryObjectAED.Completed ?
                                                    <h3><b>{memberSummaryObjectAED.Completed + ' (Increased by ' + memberSummaryObjectAED["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        <GridTable
                                            data={memberSummaryDataAED}
                                            columns={memberSummaryAllColumn}
                                        ></GridTable>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className='d-flex justify-content-center'>
                                            <div onClick={() => KPIchartClick('AED', props.kpiModuleSelect)} style={{ 'cursor': 'pointer' }}>
                                                <GaugeChart id="gauge-chart6"
                                                    animate={false}
                                                    nrOfLevels={10}
                                                    percent={
                                                        memberSummarySelectObjectAED && memberSummarySelectObjectAED.Completed ?
                                                            memberSummarySelectObjectAED?.Completed.replace('%', "") / 100
                                                            :
                                                            0
                                                    }
                                                    needleColor="#345243"
                                                    className="mb-3"
                                                    style={chartStyle}
                                                    textColor={"#000000"}
                                                    arcsLength={[0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1]}
                                                    colors={["#FF0000", "#00FF00"]}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            {memberSummarySelectObjectAED && memberSummarySelectObjectAED.Completed && memberSummarySelectObjectAED["Y-O-Y Change"].includes("-") > 0 ?
                                                <h3><b>{memberSummarySelectObjectAED.Completed + ' (Decreased by ' + memberSummarySelectObjectAED["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                : memberSummarySelectObjectAED && memberSummarySelectObjectAED.Completed ?
                                                    <h3><b>{memberSummarySelectObjectAED.Completed + ' (Increased by ' + memberSummarySelectObjectAED["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        <GridTable
                                            data={memberSummarySelectDataAED}
                                            columns={memberSummaryColumn}
                                        ></GridTable>
                                    </div>
                                </div>

                            </>
                        }>
                    </CardComponent>
                </div>
            </div>
            <div className="row mt-3 mb-3">
                <div className="col-lg-12">
                    <CardComponent
                        heading={`HCC Capture Rate (HCC - Member Count)`}
                        body={
                            <>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className='d-flex justify-content-center'>
                                            <div onClick={() => KPIchartClick('HCC', 'ALL')} style={{ 'cursor': 'pointer' }}>
                                                <GaugeChart id="gauge-chart6"
                                                    animate={false}
                                                    nrOfLevels={10}
                                                    percent={memberSummaryChartDataHCC}
                                                    needleColor="#345243"
                                                    className="mb-3"
                                                    style={chartStyle}
                                                    textColor={"#000000"}
                                                    arcsLength={[0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1]}
                                                    colors={["#FF0000", "#00FF00"]}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            {memberSummaryObjectHCC && memberSummaryObjectHCC.Completed && memberSummaryObjectHCC["Y-O-Y Change"].includes("-") > 0 ?
                                                <h3><b>{memberSummaryObjectHCC.Completed + ' (Decreased by ' + memberSummaryObjectHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                : memberSummaryObjectHCC && memberSummaryObjectHCC.Completed ?
                                                    <h3><b>{memberSummaryObjectHCC.Completed + ' (Increased by ' + memberSummaryObjectHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        <GridTable
                                            data={memberSummaryDataHCC}
                                            columns={memberSummaryAllColumn}
                                        ></GridTable>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className='d-flex justify-content-center'>
                                            <div onClick={() => KPIchartClick('HCC', props.kpiModuleSelect)} style={{ 'cursor': 'pointer' }}>
                                                <GaugeChart id="gauge-chart6"
                                                    animate={false}
                                                    nrOfLevels={10}
                                                    percent={
                                                        memberSummarySelectObjectHCC && memberSummarySelectObjectHCC.Completed ?
                                                            memberSummarySelectObjectHCC?.Completed.replace('%', "") / 100
                                                            :
                                                            0
                                                    }
                                                    needleColor="#345243"
                                                    className="mb-3"
                                                    style={chartStyle}
                                                    textColor={"#000000"}
                                                    arcsLength={[0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1]}
                                                    colors={["#FF0000", "#00FF00"]}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            {memberSummarySelectObjectHCC && memberSummarySelectObjectHCC.Completed && memberSummarySelectObjectHCC["Y-O-Y Change"].includes("-") > 0 ?
                                                <h3><b>{memberSummarySelectObjectHCC.Completed + ' (Decreased by ' + memberSummarySelectObjectHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                : memberSummarySelectObjectHCC && memberSummarySelectObjectHCC.Completed ?
                                                    <h3><b>{memberSummarySelectObjectHCC.Completed + ' (Increased by ' + memberSummarySelectObjectHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        <GridTable
                                            data={memberSummarySelectDataHCC}
                                            columns={memberSummaryColumn}
                                        ></GridTable>
                                    </div>
                                </div>
                            </>
                        }>
                    </CardComponent>
                </div>
            </div>
            <div className="row mt-3 mb-3">
                <div className="col-lg-12">
                    <CardComponent
                        heading={`HCC Capture Rate (Life Long HCC - HCC Capture Rate)`}
                        body={
                            <>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className='d-flex justify-content-center'>
                                            <div onClick={() => KPIchartClick('LFHCC', 'ALL')} style={{ 'cursor': 'pointer' }}>
                                                <GaugeChart id="gauge-chart6"
                                                    animate={false}
                                                    nrOfLevels={10}
                                                    percent={memberSummaryChartDataLongHCC}
                                                    needleColor="#345243"
                                                    className="mb-3"
                                                    style={chartStyle}
                                                    textColor={"#000000"}
                                                    arcsLength={[0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1]}
                                                    colors={["#FF0000", "#00FF00"]}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            {memberSummaryObjectLongHCC && memberSummaryObjectLongHCC.Completed ?
                                                <h3><b>{memberSummaryObjectLongHCC.Completed + ' (Increased by ' + memberSummaryObjectLongHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                :
                                                <></>
                                            }
                                        </div>
                                        <GridTable
                                            data={memberSummaryDataLongHCC}
                                            columns={memberSummaryAllColumn}
                                        ></GridTable>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className='d-flex justify-content-center'>
                                            <div onClick={() => KPIchartClick('LFHCC', props.kpiModuleSelect)} style={{ 'cursor': 'pointer' }}>
                                                <GaugeChart id="gauge-chart6"
                                                    animate={false}
                                                    nrOfLevels={10}
                                                    percent={
                                                        memberSummarySelectObjectLongHCC && memberSummarySelectObjectLongHCC.Completed ?
                                                            memberSummarySelectObjectLongHCC?.Completed.replace('%', "") / 100
                                                            :
                                                            0
                                                    }
                                                    needleColor="#345243"
                                                    className="mb-3"
                                                    style={chartStyle}
                                                    textColor={"#000000"}
                                                    arcsLength={[0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1]}
                                                    colors={["#FF0000", "#00FF00"]}
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            {memberSummarySelectObjectLongHCC && memberSummarySelectObjectLongHCC.Completed ?
                                                <h3><b>{memberSummarySelectObjectLongHCC.Completed + ' (Increased by ' + memberSummarySelectObjectLongHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                :
                                                <></>
                                            }
                                        </div>
                                        <GridTable
                                            data={memberSummarySelectDataLongHCC}
                                            columns={memberSummaryColumn}
                                        ></GridTable>
                                    </div>
                                </div>
                            </>
                        }>
                    </CardComponent>
                </div>
            </div>
            <div className="hcc-list pb-5 mb-3">
            </div>
            <br />
            <br />
            <br />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
        kpiModuleSelect: state.moduleFilter.kpiModuleSelect,
    };
}
export default connect(mapStateToProps)(ProviderPerformancePage);
