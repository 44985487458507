import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { currentRoute } from '../../store/actions';
import PersonIcon from '@mui/icons-material/Person';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
import { connect } from 'react-redux';

import styles from "../../assets/jss/awsLayouts/sidebarStyle.js";

const useStyles = makeStyles(styles);

const AwvCustomNavLink = ({ color, logo, image, logoText, prop, navOpen, parentSelect, currentRoute, activateRoute, itemKey, moduleSelect, defaultSelect }) => {

  const classes = useStyles();
  const [open, setOpen] = React.useState();
  const [activateClass, setActivateClass] = useState();

  useEffect(() => {
    currentRoute(defaultSelect);
    setActivateClass(defaultSelect);
  }, [moduleSelect])

  useEffect(() => {
    setActivateClass(activateRoute['route']);
  }, [activateRoute])

  const activeClass = {
    "color": "#fff",
    "background": "#1a9698"
  };

  const activeClassShowTextClass = {
    "color": "#fff",
    "background": "none",
    "opacity": 1,
    "box-shadow": "none"
  };

  const activeClassHideTextClass = {
    "color": "#fff",
    "background": "none",
    "opacity": 0,
    "box-shadow": "none"
  };

  const showTextClass = {
    "opacity": 1
  };

  const hideTextClass = {
    "opacity": 0
  };

  const getListClass = (route) => {
    if (activateClass === route) {
      return true;
    } else {
      return false;
    }
  }

  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }

  const handleClick = (tabName) => {
    if (open === undefined) {
      if (itemKey === 0) {
        setOpen(false);
      }
      else {
        setOpen(true);
      }
    } else {
      setOpen(!open);
    }
  };

  const listItemClasses = classNames({
    [" " + classes[color]]: activeRoute(prop.layout + prop.path)
  });

  const whiteFontClasses = classNames({
    [" " + classes.whiteFont]: activeRoute(prop.layout + prop.path)
  });

  const renderSubRoutes = (subRoutes, key) => {
    const listItemClasses = classNames({
      [" " + classes[color]]: activeRoute(subRoutes.layout + subRoutes.path)
    });

    const whiteFontClasses = classNames({
      [" " + classes.whiteFont]: activeRoute(subRoutes.layout + subRoutes.path)
    });

    const setRouteToStore = (subRoute) => {
      currentRoute(subRoute.layout + subRoute.path);

    }

    const getMenuPermissionCheck = (permissionSlug) => {
      var permissionCheck = false;
      if (permissionSlug && permissionSlug != '') {
        var permissionGets = JSON.parse(localStorage.getItem('controlPermissions'));
        if (permissionGets && permissionGets.length > 0) {
          permissionGets && permissionGets.forEach(elem => {
            if (elem.permission == permissionSlug && elem.status == true) {
              permissionCheck = false;
            }
          });
        }
      }
      return permissionCheck;
    }

    return subRoutes.map((subRoute) => {
      if (getMenuPermissionCheck(subRoute.permissionSlug) == true) {
        return '';
      }
      return (
        <React.Fragment key={key} >

          {navOpen ?
            <NavLink
              to={subRoute.layout + subRoute.path}
              className={classes.item}
              activeClassName={classNames(classes.activeClass, whiteFontClasses)}
            >
              <ListItem button
                onClick={() => setRouteToStore(subRoute)}
                style={getListClass(subRoute.layout + subRoute.path) ? activeClass : {}}
                className={classes.itemLink + listItemClasses} >
                {typeof subRoute.icon === "string" ? (
                  <Icon
                    className={classNames(classes.itemIcon, whiteFontClasses)}
                    style={getListClass(subRoute.layout + subRoute.path) ? activeClass : {}}
                  >
                    {subRoute.icon}
                  </Icon>
                ) : (
                  <subRoute.icon
                    className={classNames(classes.itemIcon, whiteFontClasses)}
                    style={getListClass(subRoute.layout + subRoute.path) ? activeClass : {}}
                  />
                )}
                <ListItemText
                  primary={subRoute.name}
                  // className={classNames(classes.itemText, whiteFontClasses )}
                  className={classNames(whiteFontClasses)}
                  disableTypography={true}
                />
              </ListItem>
            </NavLink>
            :
            <NavLink
              to={subRoute.layout + subRoute.path}
              className={classes.item}
              activeClassName={classNames(classes.activeClassClose, whiteFontClasses)}
            >
              <ListItem button
                onClick={() => setRouteToStore(subRoute)}
                style={getListClass(subRoute.layout + subRoute.path) ? activeClass : {}}
                className={classes.itemLinkClose + listItemClasses} >
                {typeof subRoute.icon === "string" ? (
                  <Icon
                    className={classNames(classes.itemIcon, whiteFontClasses)}
                    style={getListClass(subRoute.layout + subRoute.path) ? activeClass : {}}
                  >
                    {subRoute.icon}
                  </Icon>
                ) : (
                  <subRoute.icon
                    className={classNames(classes.itemIcon, whiteFontClasses)}
                    style={getListClass(subRoute.layout + subRoute.path) ? activeClass : {}}
                  />
                )}
              </ListItem>
            </NavLink>
          }



        </React.Fragment>
      );
    });
  }
  return (
    <List
      className={classes.item}
      key={itemKey}
    >
      {navOpen ?
        <ListItem button className={'mainMenuRootSidebar ' + classes.itemLink + listItemClasses}
          onClick={() => handleClick()}
          style={
            parentSelect == prop.name ?
              activeClassShowTextClass
              :
              hideTextClass
          }
        >
          {prop.icon != '' ?
            typeof prop.icon === "string" ? (
              <Icon
                className={classNames(classes.itemIcon, whiteFontClasses)}
                style={parentSelect == prop.name ? activeClassShowTextClass : {}}
              >
                {prop.icon}
              </Icon>
            ) : (
              <prop.icon
                className={classNames(classes.itemIcon, whiteFontClasses)}
                style={parentSelect == prop.name ? activeClassShowTextClass : {}}
              />
            )
            : ""
          }

          <div style={{ display: 'flex' }}>
            <ListItemText
              primary={prop.name}
              className={classNames(classes.itemText, whiteFontClasses)}
              style={
                parentSelect == prop.name ?
                  navOpen ? activeClassShowTextClass : activeClassHideTextClass
                  :
                  navOpen ? showTextClass : hideTextClass
              }
              disableTypography={true}
            />
            {open === undefined && (itemKey === 0 || itemKey === 5) ? <ExpandLess className={classNames(classes.expandIcon)} /> :
              !open ? <ExpandMore className={classNames(classes.expandIcon)} /> : <ExpandLess className={classNames(classes.expandIcon)} />
            }
          </div>
        </ListItem>
        :
        <ListItem button className={'mainMenuRootSidebar ' + classes.itemLinkClose + listItemClasses}
          onClick={() => handleClick()}
          style={
            parentSelect == prop.name ?
              activeClassShowTextClass
              :
              hideTextClass
          }
        >
          {prop.icon != '' ?
            typeof prop.icon === "string" ? (
              <Icon
                className={classNames(classes.itemIcon, whiteFontClasses)}
                style={parentSelect == prop.name ? activeClassShowTextClass : {}}
              >
                {prop.icon}
              </Icon>
            ) : (
              <prop.icon
                className={classNames(classes.itemIcon, whiteFontClasses)}
                style={parentSelect == prop.name ? activeClassShowTextClass : {}}
              />
            )
            : ""
          }
        </ListItem>
      }


      {open === undefined && (itemKey === 0 || itemKey === 5) ? <Collapse in={true} timeout="auto" unmountOnExit>
        {prop.subRoutes && prop.subRoutes.length > 0 ? renderSubRoutes(prop.subRoutes) : ''}
      </Collapse> :
        <Collapse in={open} timeout="auto" unmountOnExit>
          {prop.subRoutes && prop.subRoutes.length > 0 ? renderSubRoutes(prop.subRoutes) : ''}
        </Collapse>}

    </List>
  );

}

const mapStateToProps = (state) => {
  return {
    moduleSelect: state.moduleFilter.moduleSelect,
    activateRoute: state.route
  };
}

export default connect(mapStateToProps, { currentRoute })(AwvCustomNavLink);
