import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import WidgetCard from "../../components/Cards/WidgetCard";
import SingleVerticalBarChart from '../../components/Charts/SingleVerticalBar.Chart';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import GridTable from "../../components/TableGrids/GridTable.table";
import { anchorTag, hccSeperator, numberWithCommas } from '../../assets/constants/customRender'
import history from "../../history";
import { MAO_Chart_Review_Member, MAO_Encounter_Type, REPORTED_INSIGHTS, MEMBER_NAME, CARRIER_ID, PLAN_ID, GENDER, WIDGET_TYPES } from "../../assets/constants/string.constants";
import { percentFormat } from "../../assets/helpers/formats.helpers";
import CardComponent from "../../components/Cards/card";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import GChart from '../../components/GChart/GChart';
import IssuersACA from '../ACA/ACAIssuers.page';
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import SubHeader from '../../components/home/header/subHeader';
import { getViewBySelect } from '../../assets/constants/viewByList';
import { getModelSelect } from '../../assets/constants/modelList';
import { getMetalTierSelect } from '../../assets/constants/metalTierList';
import { getPlanSelect } from '../../assets/constants/planList';
import { getModuleSelect } from '../../assets/constants/moduleList';
import { getMeInitialSelect} from '../../assets/constants/meInitialList';

import Link from '@material-ui/core/Link';
import { connect } from 'react-redux';
//import google from "react-google-charts";
const MaoChartReviewMember = (props) => {

    const [WidgetDetails, setWidgetDetails] = useState({});
    const [memberDownYearData, setMemberDownYearData] = useState({});
    const [MAOChartReviewMember] = useState(localStorage.getItem(MAO_Chart_Review_Member));
    let sliderRangeInt = 0;

    const widgetStyles = {
        position: 'absolute',
        left: 0,
        //right: 20,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.15,
        color: "white"
    };

    const getIcon = (type) => {
        var data = ''
        switch (type) {
            case WIDGET_TYPES.ISSUER:
                data = <AccountBalanceTwoToneIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.MEMBERS:
                data = <PeopleAltIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERCENT:
                data = <ShowChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.SCORE:
                data = <BarChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERSON:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.CHILD:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.ADULT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.INFANT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.REVENUE:
                data = <AttachMoneyIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.COUNT:
                data = <ShoppingCartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.LOCKICON:
                data = <LockOpenIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.TRENDING:
                data = <TrendingUpIcon style={widgetStyles} />
                break;
        }

        return data;
    }

    const WidgetDetailsType = [
        WIDGET_TYPES.LOCKICON,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.PERCENT,
        WIDGET_TYPES.SCORE,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.TRENDING,
        WIDGET_TYPES.PERCENT,
    ]

    const type = WidgetDetailsType;

    const memberDownYearColumns = [
        {
            label: "Reported HCCs",
            name: "reportedHCCS",
        },
        {
            name: "addedHccs",
            label: "Added HCCs",
        },
        {
            name: "deletedHccs",
            label: "Deleted HCCs",
        },
        {
            name: "reportedHCCScore",
            label: "Reported HCC Score",
        },
        {
            name: "correctionInHCCScore",
            label: "Correction In HCC Score",
        },
        {
            name: "reportedInteractionScore",
            label: "Reported Interaction Score",
        },
        {
            name: "correctionInInteractionScore",
            label: "Correction In Interaction Score",
        },
    ];

    const memberAddedHCCsCols = [
        {
            label: "Claim Id",
            name: "ClaimId",
        },
        {
            name: "LinkedFlag",
            label: "Linked Flag",
        },
        {
            name: "ReportedDiagnosis",
            label: "Reported Diagnosis",
        },
        {
            name: "ReportedHccs",
            label: "Reported Hccs",
        },
        {
            name: "AddedHccs",
            label: "Added Hccs",
        },
        {
            name: "AddedDiagnosis",
            label: "Added Diagnosis",
        },
    ];
    
    const memberDeletedHCCsCols = [
        {
            label: "Claim Id",
            name: "ClaimId",
        },
        {
            name: "LinkedFlag",
            label: "Linked Flag",
        },
        {
            name: "ReportedDiagnosis",
            label: "Reported Diagnosis",
        },
        {
            name: "ReportedHccs",
            label: "Reported Hccs",
        },
        {
            name: "DeletedHccs",
            label: "Deleted Hccs",
        },
        {
            name: "DeletedDiagnosis",
            label: "Deleted Diagnosis",
        },
    ];

 //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect
    const loadWidgetDetails = () => {
        api.get('/MaoCRMember/year/'+getAcaYear()+'/id/'+MAOChartReviewMember+'/period/'+getMeInitialSelect())
        .then(res => {
            setMemberDownYearData(res);
            setWidgetDetails({
                'Total Members': res[0].memberID,
                'Members With CR': res[0].reportedRAF,
                'CR Corrected Revenue': res[0].correctionInRAF,
                'Overall Revenue With CR': res[0].correctedOverallRevenue,
                '% Of RAF Score Contributed By CR': res[0].revenueCorrection,
                '% Of RAF Score Contributed By CR': (res[0].chartReviewAsAPercentageOfCorrectedRevenue+' %'),
            });
            sliderRangeInt = 6;
        })
        .catch(err => {
            // console.log(err);
        });
    };

 //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect
    useEffect(() => { 
      loadWidgetDetails();
    },  [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect()
    ]);

    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Chart Review Member'} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0">
                    <div className="state-level">                        
                        {(Object.entries(WidgetDetails).length > 0) ?                     
                            <SlickSlider WidgetDetails={WidgetDetails} getIcon={getIcon} type={type} />
                        :
                            <></>
                        }                       
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-3">
                    <CardComponent
                        heading={''}
                        body={
                            <div className="box-height-chart">
                                <GridTable
                                    data={memberDownYearData}
                                    columns={memberDownYearColumns}
                                ></GridTable>
                            </div>
                        }></CardComponent>
                </div>
                <div className='col-lg-12 pr-0 pl-0'>
                    <div className="list-member mt-4">
                        <CardComponent
                            heading={`ADDED CODES`}
                            body={
                                    <ServerSideGridTable
                                        url={`/MaoCRMemberHcc/AddedHCCs/id/${MAOChartReviewMember}/year/${getAcaYear()}/period/${getMeInitialSelect()}`}
                                        columns={memberAddedHCCsCols}
                                    ></ServerSideGridTable>
                                }>
                        </CardComponent>
                    </div>
                </div>
                <div className='col-lg-12 pr-0 pl-0'>
                    <div className="list-member mt-4">
                        <CardComponent
                            heading={`DELETED CODES`}
                            body={
                                    <ServerSideGridTable
                                        url={`/MaoCRMemberHcc/DeletedHCCs/id/${MAOChartReviewMember}/year/${getAcaYear()}/period/${getMeInitialSelect()}`}
                                        columns={memberDeletedHCCsCols}
                                    ></ServerSideGridTable>
                                }>
                        </CardComponent>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br/>

                <br/>
                <br/> 

            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,                
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(MaoChartReviewMember);
