/**
 * @file Medicare Enrollment Stratification ROI Component
 * @description This component displays the Medicare Enrollment Stratification ROI information that calls Embed file and display Quick Sight Dashboard.
 * @import Typography from '@material-ui/core/Typography';
 * @import Container from '@material-ui/core/Container';
 * @import { Amplify } from 'aws-amplify';
 * @import awsconfig from '../../aws-exports';
 * @import Embed from './Embed';
 * @import { makeStyles } from '@material-ui/core/styles';
 * @author SONAL JAIN
 * @created 11/05/2023
 */

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Amplify } from 'aws-amplify';
import awsconfig from '../../assets/constants/amplify.config';
import Embed from '../../assets/constants/Embed';
import { makeStyles } from '@material-ui/core/styles';

// Configure Amplify using the provided AWS configuration store in file
Amplify.configure(awsconfig);

/**
 * Custom styles for the component.
 */
const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(2)
  },
}));

/**
 * Medicare Enrollment Stratification ROI Component.
 * @returns {JSX.Element} - The rendered component.
 */
function ReportsDashboard() {
  const classes = useStyles();
  
  return (
    <Embed dashboardIdName = 'quick_sight_awe_reports_dashboardId_uat' embedConsole ={false} role = {'READER'}/>
  );
}

export default ReportsDashboard;
