import React,{useRef, useState, useEffect} from "react";
import { connect } from 'react-redux';
import { signOut } from '../../../store/actions';
import { currentRoute } from '../../../store/actions';
import history from '../../../history';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ReactTooltip from 'react-tooltip';
import mainLogo from '../../../assets/images/Invent_logo_White.png';
import NavMenu from './navMenu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import ModuleSelect from '../../../components/DropDowns/ModuleSelect';
import { getModuleList, getModuleSelect, ModuleList } from '../../../assets/constants/moduleList';
import api, { url } from '../../../assets/constants/Rafservice.Instance';
import { CenterFocusStrong } from "@material-ui/icons";
import Button from '@material-ui/core/Button';
import axios from "axios";

const Header = (props) => {
    
    const notificationRef = useRef();
    const [show, setShow] = useState(false);
    const [NotificationCount, setNotificationCount] = useState(0);
    const [NotificationList, setNotificationList] = useState([]);
    // const [moduleSelect, setModuleSelect] = useState('');
    const logout = () => {
        props.signOut();
        props.currentRoute('/layout/acaReportedMemberSummary');
        history.push('/');
        localStorage.clear();
    }
    const authenticate = props.isSignedIn;
    const username = props.userName;
    const widgetStyles = {
        width: '30px',
        height: '30px',
        opacity: 1,
        color: "white"
    };
    
    useEffect(() => {
        getNotifications();
        // ModuleList();
        // setModuleSelect(getModuleSelect());
    }, []);

    useEffect(() => {
        const checkIfClickedOutside = e => {
          if (show && notificationRef.current && !notificationRef.current.contains(e.target)) {
            setShow(false)
          }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
          document.removeEventListener("mousedown", checkIfClickedOutside)
        }
        
    }, [show])

    const getNotifications = () => {    
        api.get('/newNotificationsCount/1')
            .then(res => {
                if(res && (res.data || res.data == '0')){
                    setNotificationCount(res.data);
                }else{
                    setNotificationCount(res);
                }
        })
       
        api.get('/allNotificationsPerUserId/1')
            .then(res => {
                setNotificationList(res);
        })
    }
  
    
    const clearNotifications = () => {    
        api.put('/readAllNotifications/1')
            .then(res => {
                getNotifications();
        })
    }
  
    const downloadReport = (id) => {
        axios({
          url: url + "/downloadReports/" + id,
          responseType: "arraybuffer",
          method: "GET",
        })
          .then((response) => {
            let header = response.headers;
            let filenameStr = header["content-disposition"].split(" ")[1];
            let filenames = filenameStr.split("=");
            let fileTypes = filenames[1].split(".");
            let filetype = fileTypes[1];
            let filpathForLinux = filenames[1].split("//");
            let filpathForWindows = filenames[1].split("\\");
            let filpath = filpathForLinux;
            if (filpathForLinux[2] === undefined) {
              filpath = filpathForWindows;
            }
            let fileType;
    
            if (filetype.indexOf("pdf") != -1) {
              fileType = "application/pdf";
            } else {
              fileType = "application/xls";
            }
    
            let file = new Blob([response.data], {
              type: fileType,
            });
            //trick to download store a file having its URL
            let fileURL = URL.createObjectURL(file);
            let a = document.createElement("a");
            a.href = fileURL;
            a.target = "_blank";
            a.download = filpath[filpath.length - 1];
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            //	$scope.getNotifications(userId);
          })
          .catch((err) => {
            // console.log(err);
          });
    };

    return(
        <div className={authenticate ? 'header1': 'header'}  >
            <nav className="navbar navbar-expand-lg navbar-light">
                <span className="navbar-brand" style={{ width: '256px' }}>
                    <a href="https://www.inventhealth.com/" target="_blank"><img src={mainLogo}  alt="" className="logo"></img></a>
                </span>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            {/* <div className="collapse navbar-collapse d-flex justify-content-start">
                <span className="d-flex bd-highlight">
                    <h6 className="pt-2 flex-grow-1 mr-2" style={{color: '#fff'}}>Module</h6>
                    <ModuleSelect
                        ModuleList={getModuleList()}
                        moduleSelect={moduleSelect}
                        onModuleSelect={((e) => { setModuleSelect(e); })}
                    ></ModuleSelect>
                </span>
            </div> */}
            <div className="collapse navbar-collapse d-flex justify-content-end" id="navbarText">
                {/* {(props.route != null)&&authenticate?<NavMenu></NavMenu>:''} */}

            <Button style ={{alignItems:CenterFocusStrong, color: "white"}} color="green" className="d-flex align-items-left" onClick={() => history.push('/home')}>Home</Button>

            <div className="mr-2 header-notification-badge">
                <Badge color="secondary"  badgeContent={NotificationCount} >
                    <NotificationsIcon style={widgetStyles} onClick={() => setShow(!show)}/>
                </Badge>
            </div> 
            <div className="notice-dropdown dropdown">
                <div className={show == true ? "dropdown-menu show" : "dropdown-menu"} ref={notificationRef}>
                    <div className="dropdown-menuheader d-flex justify-content-between align-items-center">
                        <div className="drop-menuleft">
                            <h5 className="mb-0">
                                {NotificationList.length} New Notifications
                            </h5>
                        </div>
                        <div className="drop-menuright">
                            <a type="button" onClick={()=> clearNotifications()} className="text-muted">
                                Clear all
                            </a>
                        </div>
                    </div>
                    <div className="scrollbar-container drop-menucontent scrollbar">
                        {NotificationList && NotificationList.length > 0 && NotificationList.map((element, i) => {
                            return(
                                <div key={i} style={{"borderBottom": "1px solid #f4ecec"}}>
                                    <a type="button" onClick={() => downloadReport(element.id)} className="drop-noticeRow">
                                        {/* <h4>Notification</h4> */}
                                        <div className="desc">{element.message}</div>
                                        {/* <span className="d-flex justify-content-end sub-text text-muted">5 sec ago</span> */}
                                    </a>
                                </div>
                            )
                        })
                        }
                    </div>
                </div>
            </div>
            <span className="navbar-text">
                    {
                          authenticate ? 
                          <a type="button">{username? username :'DefaultUser'}</a>
                          : ''
                    }  
                </span>
                <span className="navbar-text">
                    {
                          authenticate ? 
                          <a type="button" className="nav-link" style={{cursor:"pointer"}} data-tip="Logout" onClick={() => logout()} href={() => false}><ExitToAppIcon /><ReactTooltip/></a>
                          : ''
                    }  
                </span>
                </div>
            </nav>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        userName: state.auth?.userDetails?.userEmail,
        route: state.route.route
    };
  }
export default connect(mapStateToProps, {signOut, currentRoute})(Header);
