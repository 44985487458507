import React, { useState, useEffect } from "react";
import AWVApi from '../../../assets/constants/AWESummaryRafservice.instance';
import awvApiMember from '../../../assets/constants/AWESummaryRafservice.instance';
import api from '../../../assets/constants/Rafservice.Instance';
import AWVServerSideGridTable from "../../../components/TableGrids/AWVServerSideGridTable.table";
import AWVExpandGridTable from "../../../components/TableGrids/AWVExpandGridTable.table";
import { HCC, ISSUER_ID,yearLimit } from "../../../assets/constants/string.constants";
import AwvCardComponent from "../../../components/Cards/AwvCard";
import { connect } from 'react-redux';
import AwvSubHeader from '../../../components/home/header/AwvSubHeader';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import Tooltip from '@mui/material/Tooltip';
import { purple, red } from '@mui/material/colors';
import Awvheader from '../../../components/home/header/Awvheader';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AddAWVFileUpload from '../../../components/Dialog/AddAWVFileUpload';
import AreaChartMultiExcelAWV from '../../../components/Charts/AreaChartMultiExcelAWV';
import BarChartDiffDataAWV from '../../../components/Charts/BarChartDiffDataAWV';
import BarChartReprtingAWV from '../../../components/Charts/BarChartReprtingAWV';
import LineChartAWV from '../../../components/Charts/LineChartAWV';
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useForm, Controller } from "react-hook-form";
import FormControl from '@material-ui/core/FormControl';
import Select from "react-select";
import Dropdown from "../../../components/DropDowns/dropDown";
import Moment from 'moment';

const ReportingAnalytics = (props) => {

    const [totalAWVVisitOverview, setTotalAWVVisitOverview] = useState();
    const [selectAWVVisitMonth, setSelectAWVVisitMonth] = useState('1');
    const [chartAWVVisitRate, setChartAWVVisitRate] = useState([]);
    const [chartCumulativeAllYear, setChartCumulativeAllYear] = useState('');
    const [chartOverAllVisitYear, setChartOverAllVisitYear] = useState([]);
    const [formDataList, setFormDataList] = useState([]);
    const [distinctYearwiseTotal, setDistinctYearwiseTotal] = useState([]);
    const [distinctYearwiseTotalShow, setDistinctYearwiseTotalShow] = useState([]);
    const [distinctGridViewData, setDistinctGridViewData] = useState([]);

    const { formState: { errors }, control } = useForm();
    const [groupsData, setGroupsData] = useState([]);
    const [selectGroupsArray, setSelectGroupsArray] = useState([]);
    const [providersData, setProviderData] = useState([]);
    const [yearList, setYearList] = useState([]);
    // const [year, setYear] = useState(Moment().format('YYYY'));
    const [year, setYear] = useState('2022');
    const [lobSelect] = useState(['Medicare'])
    const [selectLob, setSelectLob] = useState(lobSelect[0]);
    const [selectProviderArray, setSelectProviderArray] = useState([]);
    const [selectProviderValueArray, setSelectProviderValueArray] = useState('');
    const [selectIPACode, setSelectIPACode] = useState('');
    const [selectIPACodeApi, setSelectIPACodeApi] = useState('');
    const [selectIPAName, setSelectIPAName] = useState('');

    const handleLOBSelect = (e) => {
        setSelectLob(e);
    }
    const handleDropdownForYear = (e) => {
        setYear(e)
    }
    const handleDropdownChangeForProviderName = (event) => {
        var setProviderSelected = [];
        var setProviderNameSelected = [];
        if (event != '' && event.length > 0) {
            event.map(function (el) {
                setProviderSelected.push(el.index.toString());
                setProviderNameSelected.push(el.data)
            })
            //Adding '' and send as comman separated value for multiple filter
            setSelectProviderValueArray('\"' + setProviderNameSelected.join('\",\"') + '\"');
            setSelectProviderArray(setProviderSelected);
        }
        else {
            //Adding '' and send as comman separated value for multiple filter
            setSelectProviderValueArray('');
            setSelectProviderArray([]);
        }
    };

    const handleDropdownChange = (event) => {
        var ipaCode = []
        var ipaName = []
        let setGroupSelected = [];
        let setGroupNameSelected = [];
        if (event != '' && event.length > 0) {
            event.map(function (el) {
                var splitIPACode = el.data
                ipaCode.push(splitIPACode.substr(0, (splitIPACode).lastIndexOf('-')))
                ipaName.push(splitIPACode.substr((splitIPACode).lastIndexOf('-') + 1))
                setGroupSelected.push(el.index.toString());
                setGroupNameSelected.push(el.data)
            })
            //Adding '' and send as comman separated value for multiple filter
            setSelectIPACodeApi(ipaCode.join(','));
            setSelectIPACode('\"' + ipaCode.join('\",\"') + '\"');
            setSelectIPAName('\"' + ipaName.join('\",\"') + '\"')
            setSelectGroupsArray(setGroupSelected);
        }
        else {
            setSelectIPACodeApi('');
            setSelectIPAName('')
            setSelectGroupsArray([]);
        }
    };

    const loadGroupData = () => {
        let values = [];
        awvApiMember.get(`/ppg_group`)
            .then(res => {
                if (res.data != null && !res.data.includes('errorMessage')) {
                    res.data.forEach((element, index) => {
                        for (let [key, value] of Object.entries(element)) {
                            if (!values.includes(value)) {
                                values.push({ index: index, data: value });
                            }
                        }
                    })
                }
                setGroupsData(values);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const loadProviderNameData = () => {
        let values = [];
        awvApiMember.get(`/pcp_provider_name`)
            .then(res => {
                if (res.data != null && !res.data.includes('errorMessage')) {
                    res.data.forEach((element, index) => {
                        for (let [key, value] of Object.entries(element)) {
                            if (!values.includes(value)) {
                                values.push({ index: index, data: value });
                            }
                        }
                    })
                }
                setProviderData(values);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const loadProviderData = () => {
        var groupsArray = [];

        providersData.forEach(element => {
            groupsArray.push(element.index.toString())
        });
        setSelectProviderArray(groupsArray);
    }

    const loadYearData = () => {
        api.get('/yearList')
        .then(res=>{        
            let resArray = [];
            for(var i =0; i <=yearLimit; i++) {
                resArray.push(res[i]);
            }
            setYearList(resArray);    
        })
        .catch(err=>{
            // console.log(err);
        })
    }



    useEffect(() => {
        loadGroupData();
        loadProviderNameData();
        loadProviderData();
        loadYearData();
    }, []);

    useEffect(() => {
        console.log(year);
        chartCumulativeAllYearData();
        chartAWVVisitRateData();
        loadformDataTable();
        // setDistinctYearwiseTotal([
        //     { 'year': 2020, 'jan': 10, 'feb': 10, 'mar': 10, 'apr': 10, 'may': 10, 'jun': 10, 'jul': 10, 'aug': 10, 'sep': 10, 'oct': 10, 'nov': 10, 'dec': 10 },
        //     { 'year': 2021, 'jan': 10, 'feb': 10, 'mar': 10, 'apr': 10, 'may': 10, 'jun': 10, 'jul': 10, 'aug': 10, 'sep': 10, 'oct': 10, 'nov': 10, 'dec': 10 },
        //     { 'year': 2022, 'jan': 10, 'feb': 10, 'mar': 10, 'apr': 10, 'may': 10, 'jun': 10, 'jul': 10, 'aug': 10, 'sep': 10, 'oct': 10, 'nov': 10, 'dec': 10 }
        // ]);
    }, [year, selectIPACodeApi, lobSelect]);


    const handleSelectAWVVisitMonth = (e) => {
        setSelectAWVVisitMonth(e.target.value);
    }

    const loadformDataTable = () => {
        // setFormDataList([
        //     {
        //         'id': "1",
        //         'provider_group': "Group Name",
        //         'total_members': "36",
        //         'awv_progress': "40",
        //         'awv_spending': "#DS-9574",
        //         'awv_per_month': "360",
        //     },
        // ]);

        let queryStr = '';
        if (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL')
            queryStr = '&provider_group=' + props.providerGroupSelect;
        AWVApi.get('/lambda-awe-summary-ppg?year='+year+'&ppg='+selectIPACodeApi+'&lob='+lobSelect)
            .then(res => {
                if (res.data) {
                    setDistinctGridViewData(res.data)
                } else {
                    setDistinctGridViewData([]);
                }
            })
            .catch(err => {
                console.log(err)
            })
    }


    const chartAWVVisitRateData = () => {
        let queryStr = '';
        if (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL')
            queryStr = '&provider_group=' + props.providerGroupSelect;
        AWVApi.get('/get_all_awv_monthly_count?year='+year+'&ppg='+selectIPACodeApi+'&lob='+lobSelect)
            .then(res => {
                if (res.data && res.data.length > 0) {
                    var mainCustomArray = [];
                    var index = 0;
                    var customArrayKey = [];
                    for (var key in res.data[0]) {
                        var customArray = [];
                        customArrayKey.push(key);
                        customArray[0] = key;
                        if (key == 'YEAR') {
                            if(res.data[2][key])
                            customArray[1] = String(res.data[2][key]);
                            if(res.data[1][key])
                            customArray[2] = String(res.data[1][key]);
                            if(res.data[0][key])
                            customArray[3] = String(res.data[0][key]);
                        } else {
                            if(res.data[2][key])
                            customArray[1] = res.data[2][key];
                            if(res.data[1][key])
                            customArray[2] = res.data[1][key];
                            if(res.data[0][key])
                            customArray[3] = res.data[0][key];
                        }
                        mainCustomArray[index] = customArray;
                        index++;
                    }
                    // console.log('mainCustomArray');
                    // console.log(mainCustomArray);
                    // var customArray1 = [];
                    // for (var key in res.data[0]) {
                    //     customArray1.push(res.data[0][key]);
                    // }
                    // var customArray2 = [];
                    // for (var key in res.data[1]) {
                    //     customArray2.push(res.data[1][key]);
                    // }
                    // var customArray3 = [];
                    // for (var key in res.data[2]) {
                    //     customArray3.push(res.data[2][key]);
                    // } 
                    // mainCustomArray[0] = customArrayKey;
                    // mainCustomArray[1] = customArray1;
                    // mainCustomArray[2] = customArray2;
                    // mainCustomArray[3] = customArray3;
                    // for (const key in res.data[0]) {

                    //     console.log(res.data[0][key]);
                    //     console.log(res.data[1]);
                    //     console.log(res.data[2][key]);
                    //     index++;
                    //     mainCustomArray[1][index] = res.data[0][key];
                    //     mainCustomArray[2][index] = res.data[1][key];
                    //     mainCustomArray[3][index] = res.data[2][key];
                    // };
                    setChartAWVVisitRate(mainCustomArray);
                } else {
                    setChartAWVVisitRate([
                        ["Year", "Q1", "Q2", "Q3", "Q4"],
                        [(year-2), 0, 0, 0],
                        [(year-1), 0, 0, 0],
                        [year, 0, 0, 0],
                    ]);
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const chartCumulativeAllYearData = () => {
        var providerGroupSelect = (props.providerGroupSelect != 'ALL') ? props.providerGroupSelect : '';
        let queryStr = '';
        if (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL')
            queryStr = '&ppg=' + props.providerGroupSelect;
        AWVApi.get('/get_all_awv_monthly_count?year='+year+'&ppg='+selectIPACodeApi+'&lob='+lobSelect)
            .then(res => {
                if (res.data) {
                    var mainCustomArray = [];
                    var index = 0;
                    var customArrayKey = [];
                    for (var key in res.data[0]) {
                        var customArray = [];
                        customArrayKey.push(key);
                        customArray[0] = key;
                        if (key == 'YEAR') {
                            customArray[1] = String(res.data[2][key]);
                            customArray[2] = String(res.data[1][key]);
                            customArray[3] = String(res.data[0][key]);
                        } else {
                            customArray[1] = res.data[2][key];
                            customArray[2] = res.data[1][key];
                            customArray[3] = res.data[0][key];
                        }

                        mainCustomArray[index] = customArray;
                        index++;
                    }
                    setChartOverAllVisitYear(mainCustomArray);
                    setDistinctYearwiseTotal(res.data);
                } else {
                    setDistinctYearwiseTotal([]);
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <>
            <div className='state-level'>
                <div className="card-header">
                    <span className="d-flex bd-highlight">
                        {/* <div className="d-flex align-items-center mr-3">
                            <label className="pr-2 pb-0 mb-0 d-flex align-items-center">Provider Group</label>
                            <div className='d-flex justify-content-end'>
                                <div className="btn-group" style={{ width: '200px' }}>
                                    <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '220px', 'zIndex': '2' }}>
                                        <Controller
                                            className="input-control"
                                            name="groups"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    variant="outlined"
                                                    isMulti
                                                    closeMenuOnSelect={true}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    options={groupsData}
                                                    getOptionLabel={option => `${option.data}`}
                                                    getOptionValue={option => `${option.index}`}
                                                    value={groupsData.filter(obj => selectGroupsArray.includes(obj.index.toString()))}
                                                    hideSelectedOptions={true}
                                                    backspaceRemovesValue={false}
                                                    onChange={e => handleDropdownChange(e)}
                                                    menuPlacement="bottom"
                                                />
                                            )}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                        {errors?.groups?.type === "required" && <label className="text-danger">This field is required</label>}
                                    </FormControl>
                                </div>
                            </div>
                        </div> */}
                       {/* <div className="d-flex align-items-center mr-3" style={{ display: 'none', paddingLeft: '28px' }}>
                            <label className="pr-2 pb-0 mb-0">Year</label>
                            <div className="d-flex justify-content-end">
                                <div className="btn-group">
                                    <Dropdown
                                        list={yearList}
                                        value={year}
                                        onSelect={((e) => { handleDropdownForYear(e) })}>
                                    </Dropdown>
                                </div>
                            </div>
                          </div>*/}
                        <div className="d-flex align-items-center mr-3">
                            <label className="pr-2 pb-0 mb-0">LOB</label>
                            <div className="d-flex justify-content-end" >
                                <Dropdown
                                    list={lobSelect}
                                    value={selectLob}
                                    onSelect={((e) => { handleLOBSelect(e) })}>
                                </Dropdown>

                            </div>
                        </div>
                    </span>
                </div>
            </div>
            <br />
            <div className="row mt-1">
                <div className="col-lg-12 pr-0 pl-0">
                    <AwvCardComponent
                        //heading={`'Distinct count of Awe Id' by 'Year of Service Date' and 'Quarter of Service Date'`}
                        heading={`AWEs by Service Month- Comparative View`}
                        body={
                            <>
                                <LineChartAWV data={chartAWVVisitRate} />
                            </>
                        }
                    />
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-lg-12 pr-0 pl-0">
                    <AwvCardComponent
                        //heading={`'Distinct count of Awe Id' by 'Month of Service Date'`}
                        heading={`AWE Trend by Service Month`}
                        body={
                            <>
                                <BarChartReprtingAWV data={chartOverAllVisitYear} />
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center" ></TableCell>
                                                <TableCell align="center" colSpan={3}><strong>Q4</strong></TableCell>
                                                <TableCell align="center" colSpan={3}><strong>Q3</strong></TableCell>
                                                <TableCell align="center" colSpan={3}><strong>Q2</strong></TableCell>
                                                <TableCell align="center" colSpan={3}><strong>Q1</strong></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell align="center"><strong>Year</strong></TableCell>
                                                <TableCell align="center"><strong>Dec</strong></TableCell>
                                                <TableCell align="center" ><strong>Nov</strong></TableCell>
                                                <TableCell align="center" ><strong>Oct</strong></TableCell>
                                                <TableCell align="center" ><strong>Sep</strong></TableCell>
                                                <TableCell align="center" ><strong>Aug</strong></TableCell>
                                                <TableCell align="center" ><strong>Jul</strong></TableCell>
                                                <TableCell align="center" ><strong>Jun</strong></TableCell>
                                                <TableCell align="center" ><strong>May</strong></TableCell>
                                                <TableCell align="center" ><strong>Apr</strong></TableCell>
                                                <TableCell align="center" ><strong>Mar</strong></TableCell>
                                                <TableCell align="center" ><strong>Feb</strong></TableCell>
                                                <TableCell align="center" ><strong>Jan</strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {distinctYearwiseTotal.length > 0 && distinctYearwiseTotal.map((row, key) => (
                                                <TableRow
                                                    key={key}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="center">{row?.YEAR}</TableCell>
                                                    <TableCell align="center">{row?.December}</TableCell>
                                                    <TableCell align="center">{row?.November}</TableCell>
                                                    <TableCell align="center">{row?.October}</TableCell>
                                                    <TableCell align="center">{row?.September}</TableCell>
                                                    <TableCell align="center">{row?.August}</TableCell>
                                                    <TableCell align="center">{row?.July}</TableCell>
                                                    <TableCell align="center">{row?.June}</TableCell>
                                                    <TableCell align="center">{row?.May}</TableCell>
                                                    <TableCell align="center">{row?.April}</TableCell>
                                                    <TableCell align="center">{row?.March}</TableCell>
                                                    <TableCell align="center">{row?.February}</TableCell>
                                                    <TableCell align="center">{row?.January}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        }
                    />
                </div>
            </div>
            <div className="row mt-5">
                <AwvCardComponent
                   // heading={`Grid View to show the details for all years for each Provider Group ( `+(year-2)+`-`+year+`)`}
                   heading={`AWEs by Provider Group`}
                    body={
                        <>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" ></TableCell>
                                            <TableCell align="center" colSpan={3}><strong>{year}</strong></TableCell>
                                            <TableCell align="center" colSpan={3}><strong>{year-1}</strong></TableCell>
                                            <TableCell align="center" colSpan={3}><strong>{year-2}</strong></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center"><strong>IPA Description</strong></TableCell>
                                            <TableCell align="center"><strong>Total Members</strong></TableCell>
                                            <TableCell align="center" ><strong>Total AWE Count</strong></TableCell>
                                            <TableCell align="center" ><strong>Complete %</strong></TableCell>
                                            <TableCell align="center"><strong>Total Members</strong></TableCell>
                                            <TableCell align="center" ><strong>Total AWE Count</strong></TableCell>
                                            <TableCell align="center" ><strong>Complete %</strong></TableCell>
                                            <TableCell align="center"><strong>Total Members</strong></TableCell>
                                            <TableCell align="center" ><strong>Total AWE Count</strong></TableCell>
                                            <TableCell align="center" ><strong>Complete %</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {distinctGridViewData.map((row, key) => (
                                            <TableRow
                                                key={key}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="center">{row['IPA Description']}</TableCell>
                                                <TableCell align="center">{row['Total Members of '+year]}</TableCell>
                                                <TableCell align="center">{row['Total AWE Count of '+year]}</TableCell>
                                                <TableCell align="center">{row['AWE Completion Rate of '+year]}</TableCell>
                                                <TableCell align="center">{row['Total Members of '+(year-1)]}</TableCell>
                                                <TableCell align="center">{row['Total AWE Count of '+(year-1)]}</TableCell>
                                                <TableCell align="center">{row['AWE Completion Rate of '+(year-1)]}</TableCell>
                                                <TableCell align="center">{row['Total Members of '+(year-2)]}</TableCell>
                                                <TableCell align="center">{row['Total AWE Count of '+(year-2)]}</TableCell>
                                                <TableCell align="center">{row['AWE Completion Rate of '+(year-2)]}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    }
                />
            </div>
        </>
    )

};
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        lobSelect: state.moduleFilter.lobSelect,
    };
}
export default connect(mapStateToProps)(ReportingAnalytics);