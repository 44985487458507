import React, { Fragment, useState, useEffect } from 'react';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import adminApi from '../../assets/constants/AdminRafservice.Instance';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@mui/material/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import { useForm, Controller } from "react-hook-form";
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import { toast } from 'react-toastify';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import moment from 'moment';

const styles = (theme) => ({
    dialog: {
        backgroundImage: "url(https://i.imgur.com/HeGEEbu.jpg)"
    },
    dialogPaper: {
        maxWidth: '80%',
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const Dialog = withStyles((theme) => ({
    root: {
        // border: "10px solid gray",
    },
}))(MuiDialog);

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const AddPolicy = (props) => {

    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [year, setYear] = useState(props.yearSelect);
    const [open, setOpen] = useState();
    const [startDatevalue, setStartDateValue] = useState(new Date(moment()));
    const [endDatevalue, setEndDateValue] = useState(new Date(moment().add(10, 'day')));
    const [statusCheck, setStatusCheck] = useState('1');
    const [effectCheck, setEffectCheck] = useState('1');
    const [isLoading, setIsLoading] = useState(false);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    useEffect(() => {
        if (props.editPolicyData && props.editPolicyData.id) {
            setValue('id', props.editPolicyData.id);
            setValue('policy_name', props.editPolicyData.policy_name);
            setValue('aws_service', props.editPolicyData.aws_service);
            setValue('description', props.editPolicyData.description);
            setValue('resources', props.editPolicyData.resources);
            setValue('request_condition', props.editPolicyData.request_condition);
            setValue('status', props.editPolicyData.status);
            setValue('start_date', props.editPolicyData.start_date);
            setValue('end_date', props.editPolicyData.end_date);
            setStartDateValue(props.editPolicyData.start_date);
            setEndDateValue(props.editPolicyData.end_date);
            setStatusCheck(props.editPolicyData.status);
            setEffectCheck(props.editPolicyData.effect);
        } else {
            setValue('id', '');
            setValue('policy_name', '');
            setValue('aws_service', '');
            setValue('description', '');
            setValue('resources', '');
            setValue('request_condition', '');
            setValue('start_date', startDatevalue);
            setValue('end_date', endDatevalue);
            setValue('status', statusCheck);
        }
    }, [props.editPolicyData]);

    const handleRadioChange = (event) => {
        const value = event.target.value;
        setStatusCheck(event.target.value);
        setValue('status', event.target.value);
    };

    const handleDatepickerChange = (event) => {
        const value = event.target.value;
        if (event.target.name == 'start_date') {
            setStartDateValue(value);
            setValue('start_date', value);
        } else if (event.target.name == 'end_date') {
            setEndDateValue(value);
            setValue('end_date', value);
        }
    };

    const onSubmit = (data) => {
        setIsLoading(true);
        // event.preventDefault();
        if (data.id && data.id != '') {
            adminApi.put('/policy/' + data.id, data)
                .then(res => {
                    props.handleClose();
                    setValue('id', '');
                    setValue('policy_name', '');
                    setValue('aws_service', '');
                    setValue('description', '');
                    setValue('resources', '');
                    setValue('request_condition', '');
                    setValue('start_date', startDatevalue);
                    setValue('end_date', endDatevalue);
                    setValue('status', '1');
                    setStatusCheck('1');
                    props.loadPolicyData();
                    setIsLoading(false);
                })
                .catch(err => {
                    setIsLoading(false);
                    toast.error(err?.response?.data?.message);
                })
        } else {
            adminApi.post('/policy', data)
                .then(res => {
                    props.handleClose();
                    setValue('id', '');
                    setValue('policy_name', '');
                    setValue('aws_service', '');
                    setValue('description', '');
                    setValue('resources', '');
                    setValue('request_condition', '');
                    setValue('start_date', startDatevalue);
                    setValue('end_date', endDatevalue);
                    setStatusCheck('1');
                    props.loadPolicyData();
                    setIsLoading(false);
                })
                .catch(err => {
                    setIsLoading(false);
                    toast.error(err?.response?.data?.message);
                })
        }
    };


    return (
        <div>
            <div className='d-flex flex-row-reverse'>
                <div style={{ 'marginTop': '-53px', 'marginRight': '40px' }}>
                    <Tooltip title="Add Policy">
                        <Button alignItems="center" color="primary" onClick={() => props.handleClickOpen('')}>
                            <AddCircleOutlineIcon style={{ 'fontSize': '30px' }} />
                        </Button>
                    </Tooltip>
                </div>
            </div>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogTitle id="report-generation" onClose={props.handleClose}>
                    {props.editPolicyData && props.editPolicyData.id && props.editPolicyData.id != '' ?
                        'Update Policy'
                        :
                        'Create Policy'
                    }
                </DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                    <DialogContent >
                        <div className='row mb-3'>
                            <div className='col-md-6'>
                                <div className="d-flex align-items-center">
                                    <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                        {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                        <Controller
                                            className="input-control"
                                            name="policy_name"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField  {...field} label="Policy Name" variant="outlined" />
                                            )}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                        {errors?.policy_name?.type === "required" && <label className="text-danger">This field is required</label>}
                                    </FormControl>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="d-flex align-items-center">
                                    <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                        {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                        <Controller
                                            className="input-control"
                                            name="aws_service"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField  {...field} label="AWS Service" variant="outlined" />
                                            )}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                        {errors?.aws_service?.type === "required" && <label className="text-danger">This field is required</label>}
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-12'>
                                <div className="d-flex align-items-center">
                                    <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                        {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                        <Controller
                                            className="input-control"
                                            name="description"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField  {...field} label="Policy Description" variant="outlined" />
                                            )}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                        {errors?.description?.type === "required" && <label className="text-danger">This field is required</label>}
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-6'>
                                <div className="d-flex align-items-center">
                                    <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                        {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                        <Controller
                                            className="input-control"
                                            name="resources"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField  {...field} label="Resources" variant="outlined" />
                                            )}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                        {errors?.resources?.type === "required" && <label className="text-danger">This field is required</label>}
                                    </FormControl>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="d-flex align-items-center">
                                    <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                        {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                        <Controller
                                            className="input-control"
                                            name="request_condition"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField  {...field} label="Request Condition" variant="outlined" />
                                            )}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                        {errors?.request_condition?.type === "required" && <label className="text-danger">This field is required</label>}
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div className='row mb-3'>
                            <div className='col-md-6'>
                                <div className="d-flex align-items-center">
                                    <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                        {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Controller
                                                className="input-control"
                                                name="start_date"
                                                control={control}
                                                render={({ field }) => (
                                                    <DateTimePicker
                                                        {...field}
                                                        label="Start Date Time"
                                                        minDateTime={new Date(moment().subtract(1, 'day'))}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        value={startDatevalue}
                                                        onChange={(newValue) => {
                                                            handleDatepickerChange({ 'target': { 'name': 'start_date', 'value': newValue } });
                                                        }}
                                                    />
                                                )}
                                                rules={{
                                                    required: true,
                                                }}
                                            />
                                            {errors?.start_date?.type === "required" && <label className="text-danger">This field is required</label>}
                                        </LocalizationProvider>
                                    </FormControl>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="d-flex align-items-center">
                                    <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                        {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <Controller
                                                className="input-control"
                                                name="end_date"
                                                control={control}
                                                render={({ field }) => (
                                                    <DateTimePicker
                                                        {...field}
                                                        label="End Date Time"
                                                        minDateTime={new Date(moment())}
                                                        renderInput={(params) => <TextField {...params} />}
                                                        value={endDatevalue}
                                                        onChange={(newValue) => {
                                                            handleDatepickerChange({ 'target': { 'name': 'end_date', 'value': newValue } });
                                                        }}
                                                    />
                                                )}
                                                rules={{
                                                    required: true,
                                                }}
                                            />
                                            {errors?.end_date?.type === "required" && <label className="text-danger">This field is required</label>}
                                        </LocalizationProvider>
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mt-3">
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label"><b>Status</b></FormLabel>
                                <Controller
                                    className="input-control"
                                    name="status"
                                    control={control}
                                    render={({ field }) => (
                                        <RadioGroup
                                            {...field}
                                            row
                                            onChange={handleRadioChange}
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                        >
                                            <FormControlLabel className='ml-2' id='status-active' value="1" control={<Radio color="primary" checked={statusCheck && statusCheck == 1 ? true : false} />} label="Active" />
                                            <FormControlLabel className='ml-2' id='status-inactive' value="0" control={<Radio color="primary" checked={statusCheck == 0 ? true : false} />} label="Inactive" />
                                        </RadioGroup>
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.status?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>

                    </DialogContent>

                    <DialogActions className='mt-2'>
                        <Button type='submit' className="mr-2" color="primary" variant="contained"
                            disabled={isLoading && isLoading == true ? true : false} >
                            {props.editPolicyData && props.editPolicyData.id && props.editPolicyData.id != '' ?
                                'Update Policy'
                                :
                                'Create Policy'
                            }
                        </Button>
                        <Button type='button' variant="contained" color="grey" onClick={props.handleClose}>Cancel</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div >
    );
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default (withStyles(styles), connect(mapStateToProps))(AddPolicy);
