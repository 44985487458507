import { Container } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const SlickSlider = (props) =>{

    const [dataSlider, setDataSlider] = useState([]);

    useEffect(() => {
        setDataSlider(Object.entries(props.WidgetDetails));
    }, [props]);

    const sliderSettingsAca = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        adaptiveHeight: true,
        // variableWidth: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
                initialSlide: 1,
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 2,
                initialSlide: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
              }
            }
          ]
    
    };


    return (
        <div className="card-body container mt-3 mb-3">
            <Slider {...sliderSettingsAca}>
            {
                dataSlider.map((element, i) => {
                    return (
                        <div key={i} className="relative box">
                            <div className="bg1">
                                {props.getIcon(props.type[i])}
                            </div>
                            <div className="bg1 pl-2 text-left">
                                <p className="main-text" data-toggle="tooltip" data-placement="top" title={element[0]}>{element[0]}</p>
                                <p className="small-heading">{element[1]}</p>
                            </div>
                        </div>
                    )
                })
            }
            </Slider>
        </div>
  );
}

export default SlickSlider;