import React, { useState, useEffect } from 'react';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import adminApi from '../../assets/constants/AdminRafservice.Instance';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
const styles = (theme) => ({
    dialogPaper: {
        maxWidth: '80%',
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const AddModule = (props) => {
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [year, setYear] = useState(props.yearSelect);
    const [open, setOpen] = React.useState();
    const [rolesData, setRolesData] = useState([]);
    const [selectRole, setSelectRole] = useState([]);
    const [selectGroupNames, setSelectRoleNames] = useState([]);
    const [statusCheck, setStatusCheck] = useState('1');
    const [isLoading, setIsLoading] = useState(false);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };


    useEffect(() => {
        if (props.editModuleData && props.editModuleData.id) {
            setValue('id', props.editModuleData.id);
            setValue('title', props.editModuleData.title);
            setValue('description', props.editModuleData.description);
            setValue('status', props.editModuleData.status);
            setStatusCheck(props.editModuleData.status);
        } else {
            setValue('id', '');
            setValue('title', '');
            setValue('description', '');
            setValue('status', statusCheck);
        }
    }, [props.editModuleData]);

    const handleRadioChange = (event) => {
        const value = event.target.value;
        setStatusCheck(event.target.value);
        setValue('status', event.target.value);
    };

    const onSubmit = (data) => {
        setIsLoading(true);
        // event.preventDefault();
        if (data.id && data.id != '') {
            adminApi.put('/module/' + data.id, data)
                .then(res => {
                    props.handleClose();
                    setValue('id', '');
                    setValue('title', '');
                    setValue('description', '');
                    setValue('status', '1');
                    setStatusCheck('1');
                    props.loadModuleData();
                    setIsLoading(false);
                })
                .catch(err => {
                    setIsLoading(false);
                    toast.error(err?.response?.data?.message);
                })
        } else {
            adminApi.post('/module', data)
                .then(res => {
                    props.handleClose();
                    setValue('id', '');
                    setValue('title', '');
                    setValue('description', '');
                    setValue('status', '1');
                    setStatusCheck('1');
                    props.loadModuleData();
                    setIsLoading(false);
                })
                .catch(err => {
                    setIsLoading(false);
                    toast.error(err?.response?.data?.message);
                })
        }
    };


    return (
        <div>
            <div className='d-flex flex-row-reverse'>
                <div style={{ 'marginTop': '-53px', 'marginRight': '40px' }}>
                    <Tooltip title="Add Module">
                        <Button alignItems="center" color="primary" onClick={() => props.handleClickOpen('')}>
                            <AddCircleOutlineIcon style={{ 'fontSize': '30px' }} />
                        </Button>
                    </Tooltip>
                </div>
            </div>
            <MuiDialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogTitle id="create-group" onClose={props.handleClose}>
                    {props.editModuleData && props.editModuleData.id && props.editModuleData.id != '' ?
                        'Update Module'
                        :
                        'Create Module'
                    }
                </DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                    <DialogContent >

                        <div className="d-flex align-items-center">
                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                <Controller
                                    className="input-control"
                                    name="title"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField  {...field} label="Module Name" variant="outlined" />
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.title?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>
                        <div className="d-flex align-items-center">
                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                <Controller
                                    className="input-control"
                                    name="description"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField  {...field} label="Description" variant="outlined" />
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.description?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>
                        <div className="d-flex align-items-center mt-3">
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label"><b>Status</b></FormLabel>
                                <Controller
                                    className="input-control"
                                    name="status"
                                    control={control}
                                    render={({ field }) => (
                                        <RadioGroup
                                            {...field}
                                            row
                                            onChange={handleRadioChange}
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                        >
                                            <FormControlLabel className='ml-2' id='status-active' value="1" control={<Radio color="primary" checked={statusCheck && statusCheck == 1 ? true : false} />} label="Active" />
                                            <FormControlLabel className='ml-2' id='status-inactive' value="0" control={<Radio color="primary" checked={statusCheck == 0 ? true : false} />} label="Inactive" />
                                        </RadioGroup>
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.status?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>

                    </DialogContent>
                    <DialogActions className='mt-2'>
                        <Button type='submit' className="mr-2" color="primary" variant="contained"
                            disabled={isLoading && isLoading == true ? true : false} >
                            {props.editModuleData && props.editModuleData.id && props.editModuleData.id != '' ?
                                'Update Module'
                                :
                                'Create Module'
                            }
                        </Button>
                        <Button type='button' variant="contained" color="grey" onClick={props.handleClose}>Cancel</Button>
                    </DialogActions>
                </form>
            </MuiDialog>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default (withStyles(styles), connect(mapStateToProps))(AddModule);
