import React, { useState, useEffect } from 'react';
import mainLogo from '../../assets/images/Invent_logo_White.png';

import headerLogo from '../../assets/images/lacareheaderlogo.png';
import footerLogo from '../../assets/images/lacarefooterlogo.png';
import headerLogo2 from '../../assets/images/headerlogo2.PNG';

import Moment from 'moment';

const ProviderQueryLetter = (props) => {
    const { queryLetterEmailEdit, memberDetails } = props;

    const getOrdinalSuffix = (number) => {
        const suffixes = ["th", "st", "nd", "rd"];
        const lastDigit = number % 10;
        const suffix = lastDigit <= 3 ? suffixes[lastDigit] : suffixes[0];
        return number + suffix;
    }

    return (
        <div className="container bg-white p-3" >
            <div className="header header-pdf d-flex justify-content-between pb-3 border-bottom border-primary">
                <span className="attempt text-danger font-weight-bold">
                    {queryLetterEmailEdit && queryLetterEmailEdit?.attempt && queryLetterEmailEdit?.attempt != '' ?
                        <>{getOrdinalSuffix(queryLetterEmailEdit?.attempt)} Attempt</>
                        :
                        "1st ATTEMPT"
                    }
                </span>
                <span className="title font-weight-bold" style={{ fontSize: "18px" }}>Provider Query Letter</span>
                <div className="logo bg-light d-flex align-items-center justify-content-center" >
                    <span className='p-1' style={{ fontSize: "25px" }}>
                        <img src={headerLogo} style={{ width: "auto", height: "82px" }} alt="" className="logo" />
                    </span>
                </div>
            </div>

            <div className="content content-pdf p-3">
                <p className="date font-weight-bold" style={{ marginBottom: "10px" }}>Date: <span className='font-weight-normal'>{queryLetterEmailEdit && queryLetterEmailEdit?.created_on && queryLetterEmailEdit?.created_on != '' ? Moment(queryLetterEmailEdit?.created_on).format('DD-MM-YYYY') : ''}</span></p>
                <p className="subject font-weight-bold" style={{ marginBottom: "10px" }}>RE: {queryLetterEmailEdit && queryLetterEmailEdit?.subject && queryLetterEmailEdit?.subject != '' ? queryLetterEmailEdit?.subject : ''}</p>
                <p className="member-name font-weight-bold" style={{ marginBottom: "10px" }}>Member Name: <span className='font-weight-normal'>{localStorage.getItem("member_name")}</span></p>
                <p className="dob font-weight-bold" style={{ marginBottom: "10px" }}>DOB: <span className='font-weight-normal'>{localStorage.getItem("member_dob") && localStorage.getItem("member_dob") != '' ? Moment(localStorage.getItem("member_dob")).format('DD-MM-YYYY') : ''}</span></p>
                <p className="dos font-weight-bold" style={{ marginBottom: "10px" }}>DOR: <span className='font-weight-normal'>{queryLetterEmailEdit && queryLetterEmailEdit?.date_of_service && queryLetterEmailEdit?.date_of_service != '' ? Moment(queryLetterEmailEdit?.date_of_service).format('DD-MM-YYYY') : ''}</span></p>
                <p className="awe-id font-weight-bold" style={{ marginBottom: "10px" }}>AWE ID: <span className='font-weight-normal'>#{queryLetterEmailEdit && queryLetterEmailEdit?.awe_id && queryLetterEmailEdit?.awe_id != '' ? queryLetterEmailEdit?.awe_id : ''}</span></p>

                <p className="query-text">Dear {queryLetterEmailEdit && queryLetterEmailEdit?.action_taken_to && queryLetterEmailEdit?.first_name != '' ? (queryLetterEmailEdit?.first_name + ' ' + queryLetterEmailEdit?.last_name) : 'User'},</p>
                 {/*
                <div className="query-text instructions text-underline mb-3">
                    <p>{queryLetterEmailEdit && queryLetterEmailEdit?.clinical_description && queryLetterEmailEdit?.clinical_description != '' ? queryLetterEmailEdit?.clinical_description : ''}</p>
                </div>
                */}
                <div className="query-text instructions text-underline mb-3">
                    <p dangerouslySetInnerHTML={{ __html: queryLetterEmailEdit && queryLetterEmailEdit?.clinical_description && queryLetterEmailEdit?.clinical_description != '' ? queryLetterEmailEdit?.clinical_description : '' }}></p>
                </div>
                {/*
                <div className="query-text mb-3">
                    <p>{queryLetterEmailEdit && queryLetterEmailEdit?.description && queryLetterEmailEdit?.description != null ? (queryLetterEmailEdit?.description) : ''}</p>
                </div>
                */}
                <div className="query-text mb-3" dangerouslySetInnerHTML={{ __html: queryLetterEmailEdit?.description }} ></div>

                {/* <div className="query-text mb-3">
                    <p>In your documentation, you stated the following clinical indicators:</p>
                    <p>__________________________</p>
                    <p>__________________________</p>
                </div>

                <div className="query-text mb-3">
                    <p>Based on the above clinical indicators, please clarify the following:</p>
                    <p>__________________________</p>
                    <p>__________________________</p>
                </div>

                <div className="query-text instructions font-weight-bold text-underline mb-3">
                    <p>Please return this provider query letter and the clinical documentation clarification by <span className='text-danger'>XXXday, Month XX, 2023</span><span className='font-weight-normal'>, via fax (000) 000-8251 or encrypted secure email to </span><span style={{ color: "#007bff" }}>providerrisk@gmail.com</span></p>
                </div>

                <div className="query-text mb-3">
                    <p>If you have any questions/concerns, send an email to our risk adjustment education team at <span style={{ color: "#007bff" }}>providerrisk@gmail.com</span> or call (000) 000-8251 ex. 4000</p>
                </div>

                <div className="query-text footer mb-3">
                    <p><u className='font-weight-bold'>Remember to include your best contact number</u> in the voicemail, email, or on the fax cover sheet.</p>
                </div>

                <div className="query-text mb-3">
                    <p><u>Non-responsiveness could impact your AWE incentive.</u></p>
                </div> */}
                {/* <div className="query-text instructions font-weight-bold text-underline mb-3">
                    <p>Please return this provider query letter and the clinical documentation clarification by <span className='text-danger'>XXXday, Month XX, 2023</span><span className='font-weight-normal'>, via fax (000) 000-8251 or encrypted secure email to </span><span style={{ color: "#007bff" }}>providerrisk@gmail.com</span></p>
                </div>

                <div className="query-text mb-3">
                    <p>If you have any questions/concerns, send an email to our risk adjustment education team at <span style={{ color: "#007bff" }}>providerrisk@gmail.com</span> or call (000) 000-8251 ex. 4000</p>
                </div>

                <div className="query-text footer mb-3">
                    <p><u className='font-weight-bold'>Remember to include your best contact number</u> in the voicemail, email, or on the fax cover sheet.</p>
                </div>

                <div className="query-text mb-3">
                    <p><u>Non-responsiveness could impact your AWE incentive.</u></p>
                </div> 


                <div className="mb-3">
                    <span>Thank you</span><br />
                    <span>Reference Dummy HP</span><br />
                    <span>Provider Risk Adjustment Education Team</span>
                </div> */}
            </div>

            <div className="footer footer-pdf d-flex justify-content-between pt-3 border-top border-primary">
                <div className="address text-muted">
                    <span>{process.env.REACT_APP_PATH_QUERY_LETTER_FOOTER_ONE}</span><br />
                    <span>{process.env.REACT_APP_PATH_QUERY_LETTER_FOOTER_TWO}</span>
                </div>
                <div className="logo">
                    <span className='p-1' style={{ fontSize: "25px" }}>
                        <img src={footerLogo} style={{ width: "auto", height: "40px" }} alt="" className="logo" />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ProviderQueryLetter;
