import api from '../constants/Rafservice.Instance';

let ACA_Issuers = [];
let ACA_issuer = '';
let ACA_MetricsIssuers = [];
let ACA_Metricsissuer = '';
export const AcaIssuerList = (year) =>{

    api.get('/aca_issueridList/year/'+year)
    .then(res=>{
        SetAcaIssuer(res[1]);
        SetAcaIssuerList(res);    
    })
    .catch(err=>{
       // console.log(err);
    })
}

export const AcaMetricsIssuerList = (year) =>{

    api.get('/aca_issuer_metricsidList/year/' + year)
    .then(res=>{
       // SetAcaMetricsIssuer(res[1]);
        SetAcaMetricsIssuer(res);
        SetAcaMetricsIssuerList(res);    
    })
    .catch(err=>{
        // console.log(err);
    })
}

export const SetAcaIssuerList = (Issuers) =>{
    ACA_Issuers = Issuers;
}

export const SetAcaIssuer = (Issuer) =>{
    ACA_issuer = Issuer;
}

export const getAcaIssuerList = () =>{
    return ACA_Issuers;
}

export const getAcaIssuer = () =>{
    return ACA_issuer;
}

export const SetAcaMetricsIssuerList = (Issuers) =>{
    ACA_MetricsIssuers = Issuers;
}

export const SetAcaMetricsIssuer = (Issuer) =>{
    ACA_Metricsissuer = Issuer;
}

export const getAcaMetricsIssuerList = () =>{
    return ACA_MetricsIssuers;
}

export const getAcaMetricsIssuer = () =>{
    return ACA_Metricsissuer;
}