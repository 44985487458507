import React, { useState, useEffect } from "react";
import history from "../../history";
import api from '../../assets/constants/Rafservice.Instance';
import CardComponent from "../../components/Cards/card";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import { connect } from 'react-redux';
import { anchorTag } from "../../assets/constants/customRender";
import { SERVICE_CATEGORY, HCC } from "../../assets/constants/string.constants";
import SubHeader from '../../components/home/header/subHeader';
import BarVertical from '../../components/GChart/BarVertical';
import { getMeInitialSelect } from '../../assets/constants/meInitialList';

const SUServiceUtilization = (props) => {
  const [serviceUtilization, setServiceUtilization] = useState([]);

  const ServiceDataColumns = [{
    label: "Service Category",
    name: "servicecategory",
    options: anchorTag
  },
  {
    label: "Description",
    name: "description"
  },
  {
    label: "Utilization",
    name: "utilization"
  },
  {
    label: "% of Overall Utilization",
    name: "percentageofOverallutilisation"
  },
  {
    label: "No of Members",
    name: "numOfMembers"
    //  render: $.fn.dataTable.render.number( ',', '.', 0 )
  }];




  const SCTableClick = (rowdata, colData, cellMeta) => {
    if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {

      //localStorage.setItem(ISSUER_ID, issuer);
      // localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].code);
      localStorage.setItem(SERVICE_CATEGORY, rowdata[cellMeta.rowIndex].servicecategory);
      history.push('/layout/ServiceCategory')
    }
  };
  /*  const loadHccReportedChart = () => {
 
     const handlechartClick = (element) => {
       // console.log("Here");
       let chartData = element[0]['_chart'].config.data;
       let idx = element[0]['_index'];
       let hcc = chartData.labels[idx];
       let parts = hcc.split(':');
       let hcc1 = parts[1];
 
       localStorage.setItem(SERVICE_CATEGORY, hcc1);
 
       history.push('/layout/ServiceCategory');
     };
 
     let hccCode = [];
     let totalCount = [];
     // console.log(props.moduleSelect);
     if (props.moduleSelect == 'Medicare') {
       // console.log(props.moduleSelect);
       api.get('/SU_Utilization/year/' + props.yearSelect)
         .then(res => {
           for (const dataObj of res) {
             hccCode.push((dataObj.description + ':' + dataObj.hccCode));
             totalCount.push(dataObj.totalCount);
           }
           setHCCChartData({
             labels: hccCode,
             data: totalCount,
             dLabel: "Percentage",
             hccCustomFunc: handlechartClick
           });
         })
     }
     else if (props.moduleSelect == 'Aca') {
       // console.log(props.moduleSelect);
     }
   }; */

  const loadReportedChart = () => {

    const handlechartClick = (element) => {
      // console.log("Here");
      let chartData = element[0]['_chart'].config.data;
      let idx = element[0]['_index'];
      let hcc = chartData.labels[idx];
      let parts = hcc.split(':');
      let hcc1 = parts[1];

      localStorage.setItem(SERVICE_CATEGORY, hcc1);
    };
    let newChartData = [];
    newChartData.push(["Service", "Percentage", { role: 'annotation' }]);
    api.get('/SU_Utilization/year/' + props.yearSelect)
      .then(res => {
        for (const dataObj of res) {
          newChartData.push([dataObj.description + ':' + dataObj.hccCode, dataObj.totalCount, dataObj.totalCount + '%']);
        }
        setServiceUtilization(newChartData);


      })
      .catch(err => {
        console.log("Error while fetching data" + err);
      })
  }

  useEffect(() => {
    //   loadHccReportedChart();
    if (props.yearSelect && props.yearSelect != '') {
      loadReportedChart();
    }
  }, [
    props.moduleSelect,
    props.viewBySelect,
    props.modelSelect,
    props.metalTierSelect,
    props.planSelect,
    getAcaYear(),
    getMeInitialSelect()
  ]);


  return (
    <div className="container level-summary">
      <div className="row">
        <div className="col-lg-12 pr-0 pl-0 mb-3">
          <div className="state-level">
            <div className="card-header">
              <SubHeader title={'Service Utilization Summary'} selectType={'utilization'} />
            </div>
          </div>
        </div>
        <div className='col-lg-12 pr-0 pl-0'>
          <div className="list-member mt-4">
            <CardComponent
              heading={`TOP 10 UTILIZED SERVICES`}
              body={
                <div className="box-height-chart">
                  <BarVertical data={serviceUtilization} chartType="BarChart" height="300px" width="100%" setStorgeItemName={SERVICE_CATEGORY} redirectUrl={'ServiceCategory'} yAxistitle={"HCC's"} hAxisTitle={'Percentage (%)'}></BarVertical>
                </div>
              }></CardComponent>
          </div>
        </div>
        <div className='col-lg-12 pr-0 pl-0'>
          <div className="list-member mt-4">
            <CardComponent
              heading={`SERVICE CATEGORY LIST`}
              body={
                <ServerSideGridTable
                  url={'/SU_UtilizationDetails/' + props.yearSelect}
                  columns={ServiceDataColumns}
                  tableClick={SCTableClick}
                ></ServerSideGridTable>
              }></CardComponent>
          </div>
        </div>
        <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
        <br />

        <br />
        <br />

      </div>
    </div>


  );

};

const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    moduleSelect: state.moduleFilter.moduleSelect,
    viewBySelect: state.moduleFilter.viewBySelect,
    modelSelect: state.moduleFilter.modelSelect,
    metalTierSelect: state.moduleFilter.metalTierSelect,
    planSelect: state.moduleFilter.planSelect,
    yearSelect: state.moduleFilter.yearSelect,
  };
}

export default connect(mapStateToProps)(SUServiceUtilization);
