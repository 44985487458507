import { useState, useEffect } from 'react';
import { anchorTag, hccSeperator } from '../../assets/constants/customRender';
import { getAcaIssuer, getAcaIssuerList } from '../../assets/constants/issuerList';
import api from '../../assets/constants/Rafservice.Instance';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import SingleHorizontalBar from '../../components/Charts/SingleHorizontalBar.chart';
import AcaIssuersSelect from '../../components/DropDowns/AcaIssuersSelect';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import history from '../../history';
import ServerSideGridTable from '../../components/TableGrids/ServerSideGridTable.table';
import { HCC, PROVIDER_ID,GAP_INSIGHTS,MAO_Chart_Review_Member,MAO_MEMBER_SELECT_TAB } from '../../assets/constants/string.constants';
import CardComponent from '../../components/Cards/card';
import BarVertical from '../../components/GChart/BarVertical';
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';
import { getMeInitialSelect} from '../../assets/constants/meInitialList';

const MaoProviderClaimList = (props) => {

    const [HccChartData, setHccChartData] = useState([]);
    const [ProviderId, setProviderId] = useState(localStorage.getItem(PROVIDER_ID));
    const [ClaimId, setClaimId] = useState('');

    const hccMedicareColumns = [
        {
            label: "Claim Id",
            name: "ClaimId",
            options: anchorTag
        },
        {
            label: "MEMBER ID",
            name: "MEMBER_ID",
            options: anchorTag
        },
        {
            label: "Diagnosis codes",
            name: "Diagnosis_codes"
        },
        {
            label: "Claim Type",
            name: "Type"
        },
        {
            label: "Patient Type",
            name: "Patient_type"
        },
        {
            label: "HCC's",
            name: "otherhcc",
            options:hccSeperator
        },
        {
            label: "Co-morbid Gap HCC",
            name: "GAP_HCC",
            options:hccSeperator
        },
    ]

    const ProviderComorbidDiagCodesColumns = [
        {
            label: "DIAGNOSTIC CODES",
            name: "DIAGNOSTIC_CODES"
        },
        {
            label: "CODE FIRST",
            name: "CODE_FIRST"
        },
        {
            label: "USE ADDITIONAL CODE",
            name: "USE_ADDITIONAL_CODE"
        },
        {
            label: "CODE ALSO",
            name: "CODE_ALSO"
        },
    ]

    const hccTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            setClaimId(rowdata[cellMeta.rowIndex].ClaimId)
        }else if ((cellMeta.colIndex === 1) && (colData.props.children != "")) {
            localStorage.setItem(MAO_Chart_Review_Member, rowdata[cellMeta.rowIndex].MEMBER_ID);
            localStorage.setItem(MAO_MEMBER_SELECT_TAB, GAP_INSIGHTS);
            history.push('/layout/medicare/mao_member_summary')
        }else if ((cellMeta.colIndex === 5) && (colData.props.children != "")) {
            if(typeof(colData.props.children) == "object"){
                localStorage.setItem(HCC,(cellMeta.event.target.innerText).replace(',',''));
            }
            else{
                localStorage.setItem(HCC,rowdata[cellMeta.rowIndex].otherhcc);
            }
            history.push('/layout/medicare/reported_hcc')
        }else if ((cellMeta.colIndex === 6) && (colData.props.children != "")) {
            if(typeof(colData.props.children) == "object"){
                localStorage.setItem(HCC,(cellMeta.event.target.innerText).replace(',',''));
            }
            else{
                localStorage.setItem(HCC,rowdata[cellMeta.rowIndex].GAP_HCC);
            }
            history.push('/layout/medicare/gap_hcc')
        }
    };

    useEffect(() => {
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect
    ])
 

    return (
        <div className="level-summary">
            <div className="row pb-2">
                <div className="col-lg-12">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Provider Retrospective Gaps'} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="hcc-list pb-5">
                <CardComponent
                    heading={`CLAIM LIST PROVIDER NUMBER - ${ProviderId} , YEAR - ${props.yearSelect} , PERIOD - ${getMeInitialSelect()}`}
                    body={  
                            <ServerSideGridTable
                                url={`/ProviderComorbidClaims/year/${props.yearSelect}/period/${getMeInitialSelect()}/provider_no/`+ProviderId}
                                columns={hccMedicareColumns}
                                tableClick={hccTableClick}
                                defaultSortCol={0}
                                defaultSortDir={'desc'}
                            ></ServerSideGridTable>
                    }></CardComponent>
            </div>
            {ClaimId && ClaimId != ''?
                <div className="hcc-list pb-5">
                    <CardComponent
                        heading={`CO MORBID GAP CODES: CLAIM ID - ${ClaimId}`}
                        body={
                            <ServerSideGridTable
                                url={`/ProviderComorbidDiagCodes/year/${props.yearSelect}/period/${getMeInitialSelect()}/claim_id/`+ClaimId}
                                columns={ProviderComorbidDiagCodesColumns}
                                defaultSortCol={0}
                                defaultSortDir={'desc'}
                            ></ServerSideGridTable>
                    }></CardComponent>
                </div>
            :
                <></>
            }

            <br />
            <br />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(MaoProviderClaimList);
