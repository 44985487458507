import axios from "axios";
const QueryLetterApi = axios.create({
  baseURL:process.env.REACT_APP_QUERY_LETTER_INSTANCE_BASE_URL,
  // baseURL: "http://54.224.35.50:3000/api",
  // baseURL: "http://44.193.160.138:8080/RAFScoreService",
});
//Both URL should be same
export const url = process.env.REACT_APP_QUERY_LETTER_INSTANCE_BASE_URL;
// export const url = "http://54.224.35.50:3000/api";
// export const url = "http://44.193.160.138:8080/RAFScoreService";
 
export default QueryLetterApi;