import React, { useState, useEffect } from "react";
import history from "../../history";
import api from '../../assets/constants/Rafservice.Instance';
import CardComponent from "../../components/Cards/card";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { PROVIDER_ID, MEMBER_ID, HCC } from "../../assets/constants/string.constants";
import { anchorTag } from "../../assets/constants/customRender";
import SubHeader from '../../components/home/header/subHeader';
import { connect } from 'react-redux';
import BarVertical from '../../components/GChart/BarVertical';
import { getMeInitialSelect } from '../../assets/constants/meInitialList';
import { getAcaYear } from '../../assets/constants/yearList';

const ProviderUtilization = (props) => {
    //const [HccChartData, setHCCChartData] = useState({});
    const [providerUtilization, setProviderUtilization] = useState([]);

    const HCCDataColumns = [{
        label: "Provider ID",
        name: "providerID",
        options: anchorTag
    },
    {
        label: "Utilization",
        name: "utilization"
    },
    {
        label: "% of Overall Utilization",
        name: "overallPercentageOfUtilization"
    },
    {
        label: "No of members Visiting this Provider",
        name: "memberCount",
        // render: $.fn.dataTable.render.number( ',', '.', 0 )
    },
    {
        label: "No of Services Rendered",
        name: "NumOfServicesRendered",
        //  render: $.fn.dataTable.render.number( ',', '.', 0 )
    }];




    const memberTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {

            localStorage.setItem(PROVIDER_ID, rowdata[cellMeta.rowIndex].providerID);
            history.push('/layout/SUProviderDetails');
        }
    };

    /*  const loadHccReportedChart = () => {
 
         const handlechartClick = (element) => {
             let chartData = element[0]['_chart'].config.data;
             let idx = element[0]['_index'];
             let hcc = chartData.labels[idx];
             let parts = hcc.split(':');
             let hcc1 = parts[1];
 
 
             localStorage.setItem(PROVIDER_ID, hcc);
 
             history.push('/layout/SUProviderDetails');
         };
 
         let providerID = [];
         let utilization = [];
         api.get('/SU_ProviderSummary/year/' + props.yearSelect)
             .then(res => {
                 for (const dataObj of res) {
                     providerID.push((dataObj.providerID));
                     utilization.push(dataObj.utilization);
                 }
                 setHCCChartData({
                     labels: providerID,
                     data: utilization,
                     dLabel: "Percentage",
                     hccCustomFunc: handlechartClick
                 });
             })
     } */

    const loadReportedChat = () => {

        let newChartData = [];
        const handlechartClick = (element) => {
            let chartData = element[0]['_chart'].config.data;
            let idx = element[0]['_index'];
            let hcc = chartData.labels[idx];
            let parts = hcc.split(':');
            let hcc1 = parts[1];

            localStorage.setItem(PROVIDER_ID, hcc1);
        };
        newChartData.push(["Provider", "Percentage", { role: 'annotation' }]);
        api.get('/SU_ProviderSummary/year/' + props.yearSelect)
            .then(res => {
                for (const dataObj of res) {
                    newChartData.push([dataObj.providerID, dataObj.utilization, dataObj.utilization + '%']);
                }
                setProviderUtilization(newChartData);
            })
            .catch(err => {
                console.log("Error while fetching data" + err);
            })
    }

    //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect
    useEffect(() => {
        //        loadHccReportedChart();
        if (props.yearSelect && props.yearSelect != '') {
            loadReportedChat();
        }
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect()
    ]);


    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Provider Utilization Summary'} selectType={'utilization'} />
                        </div>
                    </div>
                </div>
                <div className='col-lg-12 pr-0 pl-0'>
                    <div className="list-member mt-4">
                        <CardComponent
                            heading={`TOP 10 PROVIDERS UTILIZED`}
                            body={
                                <div className="box-height-chart">
                                    <BarVertical data={providerUtilization} chartType="BarChart" height="300px" width="100%" setStorgeItemName={PROVIDER_ID} redirectUrl={'SUProviderDetails'} yAxistitle={"HCC's"} hAxisTitle={'Percentage (%)'}></BarVertical>
                                </div>
                            }></CardComponent>
                    </div>
                </div>
                <div className='col-lg-12 pr-0 pl-0'>
                    <div className="list-member mt-4">
                        <CardComponent
                            heading={`PROVIDER LIST`}
                            body={props.yearSelect ?
                                <ServerSideGridTable
                                    url={'/SU_ProviderSummaryDetails/' + props.yearSelect}
                                    columns={HCCDataColumns}
                                    tableClick={memberTableClick}
                                ></ServerSideGridTable>
                                :
                                <></>
                            }></CardComponent>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br />

                <br />
                <br />

            </div>
        </div>


    );


};

const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
    };
}

export default connect(mapStateToProps)(ProviderUtilization);
