import React, { useEffect, useState } from 'react'
import HomeIcon from '../../icons/HomeIcon'
import IconButton from './IconButton'
import MenuBarsIcon from '../../icons/MenuBarsIcon'
import { connect } from 'react-redux';
import Breadcrumbs from './Breadcrumbs'
import userIconPng from '../../../assets/awe/images/user_icon.png';
import history from '../../../history';
import { signOut, currentRoute, currentAWERightSidebarType, currentAWERightSidebar } from '../../../store/actions';
import { Link, NavLink } from 'react-router-dom'
// import IconButton from '../../../components/CustomButtons/IconButton'
import NotificationIcon from '../../../components/icons/NotificationIcon';
import logo from '../../../assets/awe/images/logo.png';
import UserIcon from '../../../components/icons/UserIcon';
import UserLatestIcon from '../../../components/icons/UserLatestIcon';

const MainHeader = ({ sidebarToggle, sidebarOpen, titleName, ...props }) => {
    const [screenSize, setScreenSize] = useState(window.innerWidth)

    useEffect(() => {
        window.addEventListener('resize', function (event) {
            setScreenSize(window.innerWidth)
        });
    }, [])

    const logout = () => {
        props.signOut();
        props.currentRoute('/home');
        history.push('/');
        localStorage.clear();
    }

    const notificationRightDrawer = (open, drawerType = '') => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (drawerType && drawerType?.type && drawerType?.type != '') {
            props.currentAWERightSidebarType(drawerType);
        } else {
            props.currentAWERightSidebarType({ 'type': 'notification' });
        }
        props.currentAWERightSidebar(open);
    };

    return (
        <header class='header-wrapper top-header'>
            <nav class="navbar navbar-expand-lg gap12">
                <div class="mobile-toggle-icon d-block d-xl-none">
                    <IconButton buttonType={"default"} className={"link_primary"} onClick={() => sidebarToggle(!sidebarOpen)}>
                        <MenuBarsIcon className="w24" />
                    </IconButton>
                </div>
                <Breadcrumbs className="d-none d-sm-block" data={titleName} />
                <div class="top-navbar-right ml-auto">
                    <ul class="navbar-nav gap-3 xl-gap-4 align-items-center" id="primary_menu">

                    <li class="nav-item dropdown notification_menu">
                            <a class="p-0 w42 p-2" href="#" role="button">
                                <NotificationIcon className="w24 h24 text-white" onClick={notificationRightDrawer(true)} />
                            </a>
                            {/* <div className="dropdown-menu dropdown-menu-start p-3">
                                <div className="dropdown_header flex_item_cb border-bottom pb-3 mb-3">
                                    <h6>Notification</h6>
                                    <div className="flex_item_ce gap12">
                                        <a href='#' className='text-primary fs14 fw600'>View All</a>
                                        <a href='#' className='text-danger fs14 fw600'>Clear All</a>
                                    </div>
                                </div>
                                <ul class="">
                                    <li className='border-bottom pb-3 mb-3'>
                                        <h6 className='fs16 fw700 text-light-dark-800 mb-1'>Lorem ipsum dolor sit adipisicing elit. Provident, qui?</h6>
                                        <p className='fs14 fw400 text-light-dark-800'>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
                                    </li>
                                    <li className='border-bottom pb-3 mb-3'>
                                        <h6 className='fs16 fw700 text-light-dark-800 mb-1'>Lorem ipsum dolor sit adipisicing elit. Provident, qui?</h6>
                                        <p className='fs14 fw400 text-light-dark-800'>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
                                    </li>
                                    <li className='border-bottom pb-3 mb-3'>
                                        <h6 className='fs16 fw700 text-light-dark-800 mb-1'>Lorem ipsum dolor sit adipisicing elit. Provident, qui?</h6>
                                        <p className='fs14 fw400 text-light-dark-800'>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
                                    </li>
                                    <li className='border-bottom pb-3 mb-3'>
                                        <h6 className='fs16 fw700 text-light-dark-800 mb-1'>Lorem ipsum dolor sit adipisicing elit. Provident, qui?</h6>
                                        <p className='fs14 fw400 text-light-dark-800'>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
                                    </li>
                                </ul>
                            </div> */}
                        </li>

                        <li class="nav-item dropdown user_menu">
                            <a class="nav-link flex_item_cc dropdown-toggle no-arrow p-0" href="#" role="button" data-toggle="dropdown">
                                <div class="user_img w42 p-2" style={{ backgroundColor: "rgb(26, 150, 152)" }}>
                                     {/* <img src={userIconPng} className="user-img" alt="" />*/}
                                   <UserLatestIcon className="w24 h24" /> 
                                </div>
                                <div class="user_text d-xl-block d-none">
                                    <h6 class="fw600" title={props?.userDetails?.userEmail}>{props?.userDetails?.userEmail.split('@')[0]}</h6>
                                    <p class="fs14">Provider</p>
                                </div>
                                <span class="has_arrow">
                                    <svg width={14} height={9} viewBox="0 0 14 9" fill="none">
                                        <path d="M1.16669 1.66602L6.62373 7.90264C6.82294 8.1303 7.1771 8.1303 7.37631 7.90264L12.8334 1.66602" stroke="#29293E" strokeWidth="1.5" strokeLinecap="round" />
                                    </svg>
                                </span>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end">
                                <li class="d-xl-none d-block border-bottom">
                                    <a href="#">
                                        <div class="d-flex align-items-center">
                                            <div class="">
                                                <h5 class="mb-0 dropdown-user-name">{props.userName ? props.userName : 'DefaultUser'}</h5>
                                                <small class="mb-0 dropdown-user-designation text-secondary">Provider</small>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <NavLink to={"/home"}><span>Dashboard</span></NavLink>
                                </li>
                                <li>
                                    <a role="button" onClick={() => logout()} ><span>Logout</span></a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
            <div onClick={() => sidebarToggle(!sidebarOpen)} className={`overlay ${screenSize < 1200 ? sidebarOpen ? "isOpen" : "" : ""}`} id="overlay" />
        </header>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        userName: state.auth?.userDetails?.userEmail,
        userDetails: state.auth?.userDetails,
        route: state.route.route
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, signOut, currentRoute })(MainHeader);