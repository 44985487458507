import React, { useRef, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../../store/actions';
import AWVApi from '../../../assets/constants/AWVRafservice.Instance';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import Divider from '@mui/material/Divider';
import { useForm, Controller } from "react-hook-form";
import Box from '@mui/material/Box';
import pdfIcon from '../../../assets/images/pdf_icon.png';
import Moment from 'moment';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { styled } from '@mui/material/styles';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import CloseIcon from "../../icons/CloseIcon";
import IconButton from "../../layout/aweLayout/IconButton";
import Button from "../../layout/aweLayout/Button";
import PlusFileIcon from '../../icons/PlusFileIcon';
import axios from "axios";
import AWS from 'aws-sdk';
import {LogFileUploadToS3} from '../../../assets/constants/LogFileUploadToS3'
const aws = require('aws-sdk');

const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = localStorage.getItem('access_key');
const SECRET_ACCESS_KEY = localStorage.getItem('secret_key');

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const StatusAWVFileUpload = (props) => {
    const [editFormData, setEditFormData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { register: registerHcc, formState: { errors: errorsHcc }, handleSubmit: handleSubmitHcc, reset: resetHcc, control: controlHcc, setValue: setValueHcc } = useForm();
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [fileUrl, setFileUrl] = useState();
    const [fileName, setFileName] = useState();
    const [rejectionCodeList, setRejectionCodeList] = useState([]);
    const [editDetailsGridShow, setEditDetailsGridShow] = useState(false);
    const [editHcccDetailsIndex, setEditHcccDetailsIndex] = useState('');
    const [formDataList, setFormDataList] = useState([]);
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
    const [logMessages, setLogMessages] = useState([]); // Array to store log messages
    const writeToLogFile = (message) => {
       setLogMessages(prevLogMessages => [...prevLogMessages, `${new Date().toISOString()} - ${message}`]);
   };
   const [generateLog, setGenerateLog] = useState(false);

    console.log('StatusAWVFileUpload', props);
    useEffect(() => {
        editLoadFormData(props.awv_backend_id ?  props.awv_backend_id: props.aweRightSidebarType.awv_backend_id);
        loadRejectionCode();
    }, [props.awv_backend_id]);

    const generateSignedURL = (url) => {
        aws.config.update({
            accessKeyId: ACCESS_KEY,
            secretAccessKey: SECRET_ACCESS_KEY,
            region: REGION
        });
        const s3d = new aws.S3();
        var credentials = {
            accessKeyId: localStorage.getItem('access_key'),
            secretAccessKey: localStorage.getItem('secret_key')
        };
        AWS.config.update({ credentials: credentials, region: process.env.REACT_APP_REGION, signatureVersion: "v4" });
        var s3 = new AWS.S3({
            signatureVersion: "v4"
        });

        const [protocol, fullHost, ...pathSegments] = url.split('/');
        const [bucketName, port] = pathSegments[0].split('.');
        const objectKey = pathSegments.slice(1).join('/');

        const Key = `${objectKey}`;

        s3.setupRequestListeners = (request) => {
            request.on('build', (req) => {

                req.httpRequest.headers['x-amz-date'] = process.env.REACT_APP_KeyID;
            });
        }
        /*  adding condition to upload form in different folder as per lob select */
       
        var presignedGETURL = s3.getSignedUrl('getObject', {
            Bucket: bucketName,
            Key: Key,
            Expires: 60
        });
    
        return presignedGETURL;
    };

    useEffect(() => {
        if (editFormData?.awv_backend_id && editFormData?.awv_backend_id != '') {
            let controlUser = JSON.parse(localStorage.getItem('controlUser'));

            if (props.aweRightSidebarType?.status && (props.aweRightSidebarType?.status == '7' || props.aweRightSidebarType?.status == '2')) {
                setValue('action_status', 2);
                // setValue('role', 2);
            } else {
                setValue('action_status', 1);
                /*  if (props.modelFormRole && props.modelFormRole != '') {
                     setValue('role', props.modelFormRole);
                 } else {
                     setValue('role', 1);
                 } */
            }

            setValue('reason_code', '');
            setValue('awv_backend_id', editFormData?.awv_backend_id);
            setValue('createdOn', editFormData?.created_on);
            setValue('lob', props?.lobSelect);
            setValue('user_id', controlUser?.user_id);
            setValue('provider_id', controlUser?.user_id);
            setValue('pos_name', editFormData?.POS);
            setValue('form_status', editFormData?.awe_source_coordinator && editFormData?.awe_source_coordinator == 'HCD' && props.aweRightSidebarType?.status == '4' ? '9' : props.aweRightSidebarType?.status);
            setValue('cin', editFormData?.cin);
            setValue('member_id', editFormData?.member_id);
            setValue('organisation_id', (editFormData?.organisation_id).toString());
            setValue('payment_year', props.yearSelect);
            setValue('provider_group', editFormData?.provider_group);

            setValue('onhold_status', editFormData?.on_hold ? editFormData?.on_hold.toString() : "");

        }
    }, [editFormData]);

    const notificationRightDrawer = (open, valueId = '') => {
        props.currentAWERightSidebarType({ 'type': 'notification' });
        props.currentAWERightSidebar(open);
    };

    const loadRejectionCode = () => {
        writeToLogFile("loadRejectionCode() call");
        AWVApi.get('/get_rejection_code')
            .then(res => {
                setRejectionCodeList(res.data);
                writeToLogFile("Data retrieved successfully...");
            })
            .catch(err => {
                writeToLogFile("Error in retrieving data... " + err );
            })
    }

    const editLoadFormData = (value) => {
        writeToLogFile("value...", value);
        value = btoa(value)
        writeToLogFile("value after encryption ", value)
        writeToLogFile("editLoadFormData() call");
        setIsLoading(true);
        var url = '/get-all-awv-record?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + '&awv_backend_id=' + value + '&iSortCol_0=provider_group&sSortDir_0=asc&iDisplayStart=0&iDisplayLength=1&formAttached=0'
        AWVApi.get(url)
            .then(res => {
                console.log(res.data);
                if (res.data && res.data[0]) {
                    var url = generateSignedURL(res.data[0].form_url);
                    res.data[0]['url'] = url;
                    setEditFormData(res.data[0]);
                    setEditDetailsGridShow(false);
                    setEditHcccDetailsIndex('');
                    setIsLoading(false);
                    writeToLogFile("Data retrieved successfully...");
                } else {
                    setEditFormData();
                    setIsLoading(false);
                    writeToLogFile("Error in retrieving data Response..." + res);
                }
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
                writeToLogFile("Error in retrieving data... " + err );
            })
    }

    const loadEditDetails = (event) => {
        setEditDetailsGridShow(!editDetailsGridShow);
    }

    const onSubmit = (data) => {
        writeToLogFile("onSubmit() call ");
        if (editFormData?.awv_backend_id && editFormData?.awv_backend_id != '') {
            // if(props.aweRightSidebarType?.project_id && props.aweRightSidebarType?.project_id !='6'){
            setFormSubmitisLoading(true);
            data.awv_backend_id = data.awv_backend_id ? data.awv_backend_id.toString() : '';
            data.pos_name = data.pos_name ?? "";
            data.action_status = data.form_status == 7 ? data.action_status = '3' : data.action_status = data.action_status.toString();
            data.form_status = data.form_status.toString();
            data.current_form_status = (editFormData?.form_status == 'Reject' ? 'pendingforCoordinator' : editFormData?.form_status);

            // Set form_status to '3' if aweRightSidebarType.status is 2
            data.form_status = props.aweRightSidebarType?.status === 2 ? '3' : data.form_status;

            // Set role based on form_status
            // removing role as it is not required
            // data.role = ['2', '3'].includes(data.form_status) ? '4' : data.role?.toString() ?? "";

            // Set additional properties for form_status == 4
            if (data.form_status === '4' || data.form_status === '9') {
                data.is_record_on_hold = editFormData?.on_hold?.toString() ?? 'N';
                data.un_hold_remark_pfc = 'Record marked un-hold as the Coder approved AWE';
            }
            writeToLogFile("data: " + data);
            if(data?.lob)
              data.lob = data.lob.toString();

            writeToLogFile('awv_backend_id before encryption ', data.awv_backend_id)
            data.cin = btoa(data.cin)
            data.awv_backend_id = btoa(data.awv_backend_id)
            data.member_id = btoa(data.member_id)
            AWVApi.post('/create-awv-remark-record', data)
                .then(res => {

                    setValue('remark', '');
                    setValue('action_status', '');
                    setValue('awv_backend_id', '');
                    setValue('createdOn', '');
                    setValue('lob', '');
                    setValue('user_id', '');
                    setValue('reason_code', '');
                    setFormSubmitisLoading(false);
                    toast.success('Remarks added successfully');
                    notificationRightDrawer(false);
                    props.currentAWERightSidebarCloseDatatableReload(true);
                    writeToLogFile("Data retrieved successfully...");
                    setGenerateLog(true)
                })
                .catch(err => {
                    setFormSubmitisLoading(false);
                    toast.error(err?.response?.data?.message);
                    writeToLogFile("Error in retrieving data... " + err );
                    setGenerateLog(true)
                })
    } 
    };
    
    const onClick = (event, url) => {
        try {
            event.preventDefault();
            axios({
                url: url,
                responseType: "arraybuffer",
                method: "GET",
            }).then((res) => {
                window.location.href = url
            }).catch((err) => {
                // alertTitleClasses(err)
                toast.error('Error while downloading the form');
            })
        } catch (error) {
            toast.error('Error while downloading the form');
        }

    }
    useEffect(() => {
        // Generate and download log file when logMessages have been updated
        if (logMessages.length > 0  && generateLog === true) {
        LogFileUploadToS3(logMessages,  ACCESS_KEY, SECRET_ACCESS_KEY, process.env.REACT_APP_LOG_BUCKET, "AWE_Record_Action")
        }
    }, [ generateLog === true, logMessages, props.yearSelect, props.AwvId , props.lobSelect]); 

    return (
        <div className="diagnosis_code_sidebar offcanvas offcanvas-end show" tabIndex={-1} aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header flex_item_cb">
                <h5 id="offcanvasRightLabel" className='fs15'>
                    {(props.aweRightSidebarType?.status && props.aweRightSidebarType?.status == 7) ?
                        'MARK AS VOID'
                        : (props.aweRightSidebarType?.status && props.aweRightSidebarType?.status == 2) ?
                            'REJECT'
                            :
                            'APPROVE'
                    }
                </h5>
                <Tooltip title="Close">
                    <IconButton type="button" buttonType={"default"} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => notificationRightDrawer(false)}>
                        <CloseIcon className="w24" />
                    </IconButton>
                </Tooltip>
            </div>
            <Divider />
            <div className="offcanvas-body pt-1 custom-scrollbar overflow-auto">
                <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                    <div className="flex_item_cb custom_row flex-wrap mb20">
                        <div className="custom_col">
                            <div className="">
                                <h6 className="fs14">CIN/RXID</h6>
                                <p className="fs13 fw500">{editFormData?.cin}</p>
                            </div>
                        </div>
                        <div className="custom_col">
                            <div className="">
                                <h6 className="fs14">AWE ID</h6>
                                <p className="fs13 fw500">{editFormData?.awe_display_id}</p>
                            </div>
                        </div>
                        <div className="custom_col">
                            <div className="">
                                <h6 className="fs14">UPDATED ON</h6>
                                <p className="fs13 fw500">{Moment(editFormData?.updated_on).format("YYYY-MM-DD")}</p>
                            </div>
                        </div>
                                                <div className="custom_col">
                            <div className="">
                                <h6 className="fs14">TIME SPENT</h6>
                                <p className="fs13 fw500">{Moment(editFormData?.updated_on).diff(Moment(editFormData?.created_on), 'days') + ' Days'}</p>
                            </div>
                        </div>
                                            </div>
                                        <div className="mb20">
                        <h6 className="fs14 mb-1">PROGRESS</h6>
                        <div className="flex_item_cs">
                            <label htmlFor="" className="text-grey-100 fs15">{`${Math.round(editFormData?.progress)}%`}</label>
                            <LinearProgress
                                className="flex-grow-1"
                                sx={{
                                    height: 10,
                                    borderRadius: 5,
                                    [`&.${linearProgressClasses.colorPrimary}`]: {
                                        backgroundColor: [800],
                                    },
                                    [`& .${linearProgressClasses.bar}`]: {
                                        borderRadius: 5,
                                        backgroundColor: '#1a9698',
                                    },
                                }}
                                color="primary"
                                variant="determinate" value={editFormData?.progress} />
                        </div>
                    </div>
                                        <Divider className="mb20" />
                    <div className="mb20">
                        <h6 className="fs14 mb-1">DOCUMENT UPLOADED</h6>
                        <div className="">
                            {/* {(fileUrl && editFormData?.form_url && editFormData?.form_url != '') ? */}
                                <>
                                    { editFormData?.url &&  editFormData?.url .includes(".pdf") ?
                                        <a target="_blank" onClick={(e) => onClick(e, editFormData?.url)} className="flex_item_cs gap8 text-primary fs15 fw600">
                                            <PlusFileIcon className="w24 h24" />
                                            <span>{editFormData?.form_url ? editFormData?.form_url?.split('/').pop() : ''}</span>
                                        </a>
                                        :
                                        <a onClick={(e) => onClick(e, editFormData?.url)} target="_blank" download className="flex_item_cs gap8 text-primary fs15 fw600">
                                            <PlusFileIcon className="w24 h24" />
                                            <span>{editFormData?.form_url ? editFormData?.form_url?.split('/').pop() : ''}</span>
                                        </a>
                                    }
                                </>
                                
                        </div>
                    </div>
                    <Divider className="mb20" />
                    <div className="mb20">
                        <h6 className="fs14">ASSIGNED TO</h6>
                        <p className="fs13 fw500">{editFormData?.assignedUserName}</p>
                    </div>
                    <div className="mb20">
                        <h6 className="fs14">STATUS</h6>
                        <p className="fs13 fw500">{editFormData?.form_status}</p>
                    </div>
                    <Divider className="mb20" />
                    <div >
                        {(((editFormData?.form_status && editFormData?.form_status == 'pendingforCoordinator') || editFormData.form_stage == "2") && (props.aweRightSidebarType?.status && (props.aweRightSidebarType?.status != 2 && props.aweRightSidebarType?.status != 7))) ?
                            <div className="form-group mb20">
                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2"><h6 className="fs14 mb-1">PLACE OF SERVICE</h6></label>
                                <div className="flex_item_cs gap8">
                                    <Controller
                                        name="pos_name"
                                        value={editFormData?.POS}
                                        control={control}
                                        render={({ field }) => (
                                            <select
                                                {...field}
                                                className="form-control"
                                            >
                                                <option value={''} >
                                                    Select
                                                </option>
                                                <option value={'In Home Assessment'} >
                                                    In Home Assessment
                                                </option>
                                                <option value={'Office Visit'} >
                                                    Office Visit
                                                </option>
                                                <option value={'TeleHealth'} >
                                                    Tele Health
                                                </option>
                                            </select>
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                </div>
                                {errors?.pos_name?.type === "required" && <label className="form-input-error">This field is required</label>}
                            </div>
                            : editFormData?.POS && editFormData?.POS != '' ?
                            <div className="mb20">
                                    <h6 className="fs14 mb-1">PLACE OF SERVICE</h6>
                                    <p className="fs13 fw500">{editFormData?.POS}</p>
                                </div>
                                :
                                <></>
                        }
                    </div>
                    <div >
                        {(((editFormData?.form_status && editFormData?.form_status == 'pendingforCoder') || editFormData.form_stage == "3") && (props.aweRightSidebarType?.status && (props.aweRightSidebarType?.status != 2 && props.aweRightSidebarType?.status != 7))) ?
                            <div className="form-group mb20">
                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2"><h6 className="fs14 mb-1">STAGE</h6></label>
                                <div className="flex_item_cs gap8">
                                    <Controller
                                        name="form_status"
                                        value={editFormData?.form_status}
                                        control={control}
                                        render={({ field }) => (
                                            editFormData?.awe_source_coordinator && editFormData?.awe_source_coordinator == 'HCD' ?
                                                <select
                                                    {...field}
                                                    className="form-control"
                                                >
                                                    <option value={''} >
                                                        Select
                                                    </option>
                                                    <option value={'9'} >
                                                        Approved - No Payment
                                                    </option>n
                                                </select>
                                                :
                                                editFormData?.awe_source_coordinator && editFormData?.awe_source_coordinator != 'HCD' ?
                                                    <select
                                                        {...field}
                                                        className="form-control"
                                                    >
                                                        <option value={''} >
                                                            Select
                                                        </option>
                                                        <option value={'4'} >
                                                            Pending for Payment Approval
                                                        </option>
                                                        <option value={'5'} >
                                                            Approved for Payment
                                                        </option>
                                                    </select>
                                                    :
                                                    // Code to handle the case when awe_source_coordinator is null, empty, or undefined. 
                                                    (editFormData?.awe_source_coordinator === null || editFormData?.awe_source_coordinator === '' || editFormData?.awe_source_coordinator === undefined) ?
                                                        <select
                                                            {...field}
                                                            className="form-control"
                                                        >
                                                            <option value={''} >
                                                            </option>
                                                            <option value={'9'} >
                                                                Approved - No Payment
                                                            </option>
                                                            <option value={'4'} >
                                                                Pending for Payment Approval
                                                            </option>
                                                            <option value={'5'} >
                                                                Approved for Payment
                                                            </option>
                                                        </select>
                                                        :
                                                        <></>
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                </div>
                                {errors?.form_status?.type === "required" && <label className="form-input-error">This field is required</label>}
                            </div>
                            : editFormData?.form_status && editFormData?.form_status != '' ?
                                <>
                                </>
                                :
                                <></>
                        }
                    </div>
                    <div >
                        {(props.aweRightSidebarType?.status && (props.aweRightSidebarType?.status == 2)) ?
                            <div className="form-group mb20">
                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2"><h6 className="fs14 mb-1">CONFIRMATION</h6></label>
                                <div className="flex_item_cs gap8">
                                    <Controller
                                        name="reason_code"
                                        value={editFormData?.reason_code}
                                        control={control}
                                        render={({ field }) => (
                                            <select
                                                {...field}
                                                className="form-control"
                                            >
                                                <option value={''} >
                                                    Select
                                                </option>
                                                {rejectionCodeList && rejectionCodeList.length > 0 && rejectionCodeList.map((element, index) =>
                                                    <option key={index} value={element.reason_code_id}>{element.reason_code_description}</option>
                                                )}
                                            </select>
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                </div>
                                {errors?.reason_code?.type === "required" && <label className="form-input-error">This field is required</label>}
                            </div>
                            :
                            <></>
                        }
                    </div>
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2"><h6 className="fs14 mb-1">REMARK</h6></label>
                        <Controller
                            name="remark"
                            control={control}
                            render={({ field }) => (
                                <textarea rows={3} {...field} className="form-control" />
                            )}
                            rules={{
                                required: false,
                            }}
                        />
                        {errors?.remark?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    {editDetailsGridShow ?
                        <div className="col-lg-12 align-items-center mt-3 mb-5">
                            <div className="card m-3">
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell align="center">HCC Code</StyledTableCell>
                                                <StyledTableCell align="center">HCC Description</StyledTableCell>
                                                <StyledTableCell align="center">DX Code</StyledTableCell>
                                                <StyledTableCell align="center">DX Description</StyledTableCell>
                                                <StyledTableCell align="center">HCC Confirmed</StyledTableCell>
                                                <StyledTableCell align="center">Remarks</StyledTableCell>
                                                {/* <StyledTableCell align="center">Action</StyledTableCell> */}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {formDataList.map((row, index) => (
                                                <StyledTableRow key={index}>
                                                    {editHcccDetailsIndex && editHcccDetailsIndex == (index + 1) ?
                                                        <>
                                                            <StyledTableCell align="center">
                                                                <Controller
                                                                    className="form-control"
                                                                    name={"HCC_Code"}
                                                                    value={row.HCC_Code}
                                                                    control={controlHcc}
                                                                    render={({ field }) => (
                                                                        <input  {...field} type="number" variant="outlined" className="form-control" />
                                                                    )}
                                                                />
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                <Controller
                                                                    className="form-control"
                                                                    name={"HCC_Description"}
                                                                    value={row.HCC_Description}
                                                                    control={controlHcc}
                                                                    render={({ field }) => (
                                                                        <input  {...field} type="text" variant="outlined" className="form-control" />
                                                                    )}
                                                                />
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                <Controller
                                                                    className="form-control"
                                                                    name={"DX_code"}
                                                                    value={row.DX_code}
                                                                    control={controlHcc}
                                                                    render={({ field }) => (
                                                                        <input  {...field} type="text" variant="outlined" className="form-control" />
                                                                    )}
                                                                />
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                <Controller
                                                                    className="form-control"
                                                                    name={"DX_description"}
                                                                    value={row.DX_description}
                                                                    control={controlHcc}
                                                                    render={({ field }) => (
                                                                        <input  {...field} type="text" variant="outlined" />
                                                                    )}
                                                                />
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                <Controller
                                                                    className="form-control"
                                                                    name={"HCC_Confirmed"}
                                                                    value={row.HCC_Confirmed}
                                                                    control={controlHcc}
                                                                    render={({ field }) => (
                                                                        <select
                                                                            {...field}
                                                                            className="form-control"
                                                                        >
                                                                            <option value={''} >
                                                                                Select
                                                                            </option>
                                                                            <option value={'confirmed'} >
                                                                                Confirmed
                                                                            </option>
                                                                            <option value={'not confirmed'} >
                                                                                Not Confirmed
                                                                            </option>
                                                                            <option value={'no longer applicable'} >
                                                                                No Longer Applicable
                                                                            </option>
                                                                        </select>
                                                                    )}
                                                                />
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                <Controller
                                                                    className="form-control"
                                                                    name={"remark"}
                                                                    value={row.remark}
                                                                    control={controlHcc}
                                                                    render={({ field }) => (
                                                                        <input  {...field} type="text" variant="outlined" />
                                                                    )}
                                                                />
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                <Button type='submit' className={"minw80"}
                                                                    disabled={isLoading && isLoading == true ? true : false}
                                                                >
                                                                    Submit
                                                                </Button>
                                                            </StyledTableCell>
                                                        </>
                                                        :
                                                        <>
                                                            <StyledTableCell align="center">
                                                                {row.HCC_Code}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.HCC_Description}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.DX_code}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.DX_description}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.HCC_Confirmed}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {row.remark}
                                                            </StyledTableCell>
                                                            {/* <StyledTableCell align="center">
                                                                            <span className="text-danger" style={{ cursor: 'pointer' }} onClick={() => editHCCActionData(row, (index + 1))}>EDIT</span> 
                                                                        </StyledTableCell> */}
                                                        </>
                                                    }

                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                        :
                        <></>
                    }
                    <div className="form-group flex_item_cs gap10">
                        <Button type='button' className={"btn-white minw80"} onClick={() => notificationRightDrawer(false)}>CANCEL</Button>
                        <Button type='submit'
                            className={(props.aweRightSidebarType?.status && (props.aweRightSidebarType?.status == 7 || props.aweRightSidebarType?.status == 2)) ? 'btn-danger minw80' : 'minw80'}
                            disabled={formSubmitisLoading && formSubmitisLoading == true ? true : false}
                        >
                            {(props.aweRightSidebarType?.status && props.aweRightSidebarType?.status == 7) ?
                                'MARK AS VOID'
                                : (props.aweRightSidebarType?.status && props.aweRightSidebarType?.status == 2) ?
                                    'REJECT'
                                    :
                                    'APPROVE'
                            }
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(StatusAWVFileUpload);

