import PropTypes from "prop-types";
import {SetModelSelect} from '../../assets/constants/modelList';
import { currentModelSelect } from '../../store/actions';
import { connect } from 'react-redux';

const ModelSelect = (props) => {

    const { ModelList, modelSelect} = props;
    props.currentModelSelect(modelSelect);

    const handleSelect = (e) =>{
        SetModelSelect(e.target.value);        
        props.currentModelSelect(e.target.value);
        props.onModelSelect(e.target.value);
    }

    return(
        <div className="d-flex justify-content-end">
            <div className="btn-group">
            <select className="btn btn-info dropdown-toggle btn" value={modelSelect} onChange={handleSelect}>
                {
                    ModelList && ModelList.map(el => <option value={el} key={el}> {el} </option>)
                }
            </select>
            </div>
        </div>
    )
}

ModelSelect.propTypes ={
    ModelList : PropTypes.array,
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
    };
  }
export default connect(mapStateToProps, {currentModelSelect})(ModelSelect);