import { useState, useEffect } from 'react';
import { anchorTag, numberWithCommas, hccSeperator, textToSignFormatRender, dateFormatRender } from '../../assets/constants/customRender';
import { getAcaIssuer, getAcaIssuerList } from '../../assets/constants/issuerList';
import api from '../../assets/constants/RafserviceKPI.Instance';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import SingleHorizontalBar from '../../components/Charts/SingleHorizontalBar.chart';
import AcaIssuersSelect from '../../components/DropDowns/AcaIssuersSelect';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import history from '../../history';
import { HCC, PROVIDER_ID, MAO_Chart_Review_Member, MAO_TYPE, RAF_RANGE, KPI_CATEGORY,KPI_PPG } from '../../assets/constants/string.constants';
import CardComponent from '../../components/Cards/card';
import BarVertical from '../../components/GChart/BarVertical';
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';
import KPIGridTable from "../../components/TableGrids/KPIGridTable.table";
import { numberDecimalFormat, revenueFormat } from "../../assets/helpers/formats.helpers";

const ProviderGroup = (props) => {
    const [memberSummaryData, setMemberSummaryData] = useState([]);
    const [KPICategoryData, setKPICategoryData] = useState(localStorage.getItem(KPI_CATEGORY));
    const [KPIPPGData, setKPIPPGData] = useState(localStorage.getItem(KPI_PPG));

    const memberSummaryColumn = [
        {
            label: "PPG",
            name: "PPG"
        },
        {
            label: "PPG DESCRIPTION",
            name: "PPG_DESCRIPTION"
        },
        {
            label: "KPI",
            name: "KPI"
        },
        {
            label: "Metric Type",
            name: "Metric Type"
        },
        {
            label: "KPI Description",
            name: "KPI Description"
        },
        {
            label: "YEAR",
            name: "YEAR"
        },
        {
            label: "TOTAL Members Eligible",
            name: "TOTAL Members Eligible"
        },
        {
            label: "Total Members Completed",
            name: "Total Members Completed"
        },
        {
            label: "% Completed ",
            name: "Completed"
        },
        {
            label: "Compared w Overall",
            name: "Compared w Overall"
        },
        {
            label: "% of Previous Year",
            name: "% of Previous Year"
        },
        {
            label: "Y-O-Y Change",
            name: "Y-O-Y Change"
        },
    ];


    const loadDataCount = () => {
        api.get('/data')
            .then(res => {
                gridDataFilter(res.data, KPICategoryData, KPIPPGData);
            })
            .catch(err => {
                // console.log(err)
            })
    }
 
    const gridDataFilter = (KPIData, KPICategoryData = '', KPIPPGData = '') => {
        var filterData = [];
        if (KPICategoryData && KPIPPGData && KPICategoryData != '' && KPIPPGData != '') {
            KPIData.forEach(element => {
                if (KPICategoryData == element.KPI && element.PPG == 'ALL' && KPIPPGData != 'ALL'){
                    filterData.push(element);
                }
                if (KPICategoryData == element.KPI && KPIPPGData == element.PPG) {
                    filterData.push(element);
                }
            });
            setMemberSummaryData(filterData);
            localStorage.setItem(KPI_CATEGORY, '');
            localStorage.setItem(KPI_PPG, '');
        }else{
            setMemberSummaryData(KPIData);
        } 
    }

    useEffect(() => {
        loadDataCount();
        // console.log(HCCMODELData);
        // console.log(PCCMODELData);
        // console.log(BLENDEDMODELData);
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect,
        props.kpiModuleSelect,
    ])

    return (
        <div className="level-summary">
            <div className="hcc-list pb-5">
                <CardComponent
                    heading={``}
                    body={
                        <KPIGridTable
                            data={memberSummaryData}
                            columns={memberSummaryColumn}
                            pagination={'true'}
                            download={'true'}
                            filter={'true'}
                        ></KPIGridTable>
                    }>
                </CardComponent>
            </div>
            <div className="hcc-list pb-5 mb-3">
            </div>
            <br />
            <br />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
        kpiModuleSelect: state.moduleFilter.kpiModuleSelect,
    };
}
export default connect(mapStateToProps)(ProviderGroup);
