import React from 'react'

export default function MultipleForwardLeftIcon({ ...props }) {
    return (
        <svg {...props} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Bold / Messages, Conversation / Multiple Forward Left">
                <path id="Vector" d="M8.31664 3.95528L4.99811 6.9051C4.37773 7.45658 4.06754 7.73231 3.95322 8.05784C3.85283 8.34378 3.85283 8.65538 3.95322 8.94131C4.06754 9.26684 4.37773 9.54258 4.99811 10.094L8.31664 13.0439C8.59817 13.2941 8.73897 13.4192 8.85851 13.4237C8.96237 13.4276 9.06217 13.3828 9.12824 13.3026C9.20431 13.2103 9.20431 13.0219 9.20431 12.6452V10.7853C10.823 10.7853 12.5328 11.3052 13.7812 12.2281C14.4311 12.7086 14.7561 12.9488 14.8798 12.9393C15.0005 12.9301 15.0771 12.883 15.1397 12.7794C15.2039 12.6732 15.1472 12.3412 15.0337 11.6774C14.2971 7.36664 11.3304 6.21386 9.20431 6.21386V4.3539C9.20431 3.97722 9.20431 3.78888 9.12824 3.69654C9.06217 3.61633 8.96237 3.57154 8.85851 3.57543C8.73897 3.5799 8.59817 3.70503 8.31664 3.95528Z" fill="#E8F5F5" />
                <path id="Vector_2" fill-rule="evenodd" clip-rule="evenodd" d="M6.54518 3.15977C6.35728 2.95742 6.04091 2.94571 5.83856 3.13361L2.36109 6.36269C1.7298 6.94889 1.37109 7.77147 1.37109 8.63293C1.37109 9.54107 1.76955 10.4035 2.46109 10.9921L5.8547 13.8807C6.06498 14.0597 6.38054 14.0344 6.55953 13.8241C6.73853 13.6138 6.71313 13.2983 6.50287 13.1193L3.10926 10.2307C2.64093 9.832 2.37109 9.248 2.37109 8.63293C2.37109 8.04953 2.61401 7.49247 3.04154 7.09548L6.51901 3.8664C6.72133 3.6785 6.73307 3.36213 6.54518 3.15977Z" fill="#E8F5F5" />
            </g>
        </svg>
    )
}