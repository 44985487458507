import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import SingleHorizontalBar from "../../components/Charts/SingleHorizontalBar.chart";
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import { getAcaIssuer, getAcaIssuerList } from "../../assets/constants/issuerList";
import AcaIssuersSelect from "../../components/DropDowns/AcaIssuersSelect";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag, numberWithCommas } from "../../assets/constants/customRender";
import { HCC, ISSUER_ID } from "../../assets/constants/string.constants";
import history from "../../history";
import CardComponent from "../../components/Cards/card";
import ACAHighFreqencyChart from "../../components/Charts/ACAHighFreqencyChart";
import Chart from "react-google-charts";
import ReportedHccACA from "../../Pages/ACA/ACAReportedHcc.page";
import Gradient from "javascript-color-gradient";
import SubHeader from '../../components/home/header/subHeader';
import { connect } from 'react-redux';

const ReportedHccSummaryACA = (props) => {
    const [HccChartData, setHccChartData] = useState({});
    const [issuer, setIssuer] = useState(getAcaIssuer());
    const [ChartData, setChartData] = useState([]);
    const [ChartDataAM4, setChartDataAM4] = useState([]);
    const [type, setType] = useState('');
    const colorGradient = new Gradient();
    colorGradient.setGradient("#006C90", "#A3C3CF");

    const loadHccChartIssuer = () => {
        setChartData([]);
        let hccCode = [];
        let totalCount = [];
        let newChartData = [];
        let newAm4ChartData = [];
        newChartData.push(["Year", "Description", "Count"]);
        // let URLString = "http://13.127.17.243/RAFScoreService/acaReportedHccs/year/" + year + "/issuerId/" + issuer + "?sEcho=1&iColumns=3&sColumns=%2C%2C&iDisplayStart=0&iDisplayLength=10&mDataProp_0=code&sSearch_0=&bRegex_0=false&bSearchable_0=true&bSortable_0=true&mDataProp_1=description&sSearch_1=&bRegex_1=false&bSearchable_1=true&bSortable_1=true&mDataProp_2=count&sSearch_2=&bRegex_2=false&bSearchable_2=true&bSortable_2=true&sSearch=&bRegex=false&iSortCol_0=2&sSortDir_0=desc&iSortingCols=1";
        let URLString = "/acaReportedHccs/year/" + props.yearSelect + "/issuerId/" + btoa(issuer) + "?sEcho=1&iColumns=3&sColumns=%2C%2C&iDisplayStart=0&iDisplayLength=100&mDataProp_0=code&sSearch_0=&bRegex_0=false&bSearchable_0=true&bSortable_0=true&mDataProp_1=description&sSearch_1=&bRegex_1=false&bSearchable_1=true&bSortable_1=true&mDataProp_2=count&sSearch_2=&bRegex_2=false&bSearchable_2=true&bSortable_2=true&sSearch=&bRegex=false&iSortCol_0=2&sSortDir_0=desc&iSortingCols=1";
        api.get(URLString)
            .then(res => {
                for (const dataObj of res.aaData) {
                    hccCode.push((dataObj.description + ':' + dataObj.hccCode));
                    totalCount.push(dataObj.totalCount);
                }
                let rowData = res.aaData;
                // newChartData.push([year, null, 0]);
                for (let i = 0; i < rowData.length; i++) {
                    newChartData.push([rowData[i].code + ' ' + rowData[i].description, props.yearSelect, 0]);
                }
                for (let i = 0; i < rowData.length; i++) {
                    newChartData.push([rowData[i].count, rowData[i].code + ' ' + rowData[i].description, rowData[i].count]);
                }
                colorGradient.setMidpoint(rowData.length);
                const colorArr = colorGradient.getArray();
                for (let i = 0; i < rowData.length; i++) {
                    newAm4ChartData.push({ "key": rowData[i].code, "data": parseInt(rowData[i].count), "description": rowData[i].description, "color": colorArr[i] });
                }
                setChartData(newChartData);
                setChartDataAM4(newAm4ChartData);
                // console.log("newChartData", newChartData);
                // console.log("newAm4ChartData", newAm4ChartData);
                setHccChartData({
                    labels: hccCode,
                    data: totalCount,
                    dLabel: "Count",
                });
            })


    }

    const backGround = [
        "#AAB7B8",
        "#EB984E",
        "#58D68D",
        "#5DADE2",
        "#AF7AC5",
        "#EC7063"
    ]

    const hccColumns = [
        {
            label: "Code",
            name: "code",
            options: anchorTag
        },
        {
            label: "Description",
            name: "description"
        },
        {
            label: "# Members ",
            name: "count",
            options: numberWithCommas
        }
    ];

    const hccTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(ISSUER_ID, issuer);
            localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].code);
            history.push('/layout/acaReportedHcc')
        }
    };

    const HccChartClick = (element) => {
        let chartData = element[0]['_chart'].config.data;
        let idx = element[0]['_index'];
        let hcc = chartData.labels[idx];
        let parts = hcc.split(':');
        let hcc1 = parts[1];
        localStorage.setItem(HCC, hcc1);
        localStorage.setItem(ISSUER_ID, issuer);
        history.push('/layout/acaReportedHcc');
    }

    useEffect(() => {
        setChartData([]);
        if (props.yearSelect && props.yearSelect != '') {
            loadHccChartIssuer();
        }
        setIssuer(getAcaIssuer());
        localStorage.setItem(HCC, '');
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect,
        issuer
    ]);


    return (
        <div className="level-summary" >
            <div className="row pb-2">
                <div className="col-lg-12">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Reported HCC Summary'} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row frequency-report" >
                <ACAHighFreqencyChart data={ChartDataAM4} type={type} year={props.yearSelect} />
            </div>

            {/* <div className="row frequency-report">
                    <div className="col-lg-12 pb-5">
                        <h6 className="bg-white p-4 mb-0">ACA HIGH FREQUENCY REPORTED HCC</h6>
                        <div className="hcc-heatmap">
                            {ChartData.length > 0 ?
                            
                                <Chart
                                    className="bg-white mb-4"
                                    chartType="TreeMap"
                                    width={"100%"}
                                    height={"700px"}
                                    loader={<div>Loading Chart</div>}
                                    data={ChartData}
                                    options={{
                                        isStacked: true,
                                        highlightOnMouseOver: true,
                                        legend: { position: 'right', maxLines: 20 },
                                        vAxis: { minValue: 0 },
                                        is3D: true,
                                        minColor: '#cae8fc',
                                        midColor: '#2c92f7',
                                        maxColor: '#5EA5E4',
                                        // generateTooltip: (row, size, legend) => {
                                        // 	return (
                                        // 		'<div style="background:#fd9; padding:10px; border-style:solid"> row: ' +
                                        // 		row +
                                        // 		' size: ' +
                                        // 		size +
                                        // 		' val: ' +
                                        // 		legend +
                                        // 		'</div>'
                                        // 	)
                                        // },
                                    }}
                                    rootProps={{ 'data-testid': '1' }}
                                /> : "Loading data..."}
                        </div>
                    </div>
                </div> */}

            <div className="row frequency-report pb-5">
                <div className="col-lg-12 hcc-list pb-5">
                    <div className="pb-5">
                        {props.yearSelect ?
                            <CardComponent
                                heading={`HCC LIST`}
                                body={
                                    <ServerSideGridTable
                                        url={`/acaReportedHccs/year/${props.yearSelect}/issuerId/${btoa(issuer)}`}
                                        columns={hccColumns}
                                        tableClick={hccTableClick}
                                        defaultSortCol={2}
                                        defaultSortDir={'desc'}
                                    ></ServerSideGridTable>
                                }></CardComponent>
                            :
                            <></>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
    };
}
export default connect(mapStateToProps)(ReportedHccSummaryACA);