import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import WidgetCard from "../../components/Cards/WidgetCard";
import SingleVerticalBarChart from '../../components/Charts/SingleVerticalBar.Chart';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import { getAcaYear } from '../../assets/constants/yearList';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag, hccSeperator, numberWithCommas } from '../../assets/constants/customRender'
import history from "../../history";
import { FLAG, ISSUER_ID, HCC, WIDGET_TYPES, REPORTED_INSIGHTS, MEMBER_NAME, UTILIZATION, CARRIER_ID, PLAN_ID, GENDER, MAO_RISK_CONDITION_CHART, MAO_Chart_Review_Member, MAO_MEMBER_SELECT_TAB, MEMBER_ID } from "../../assets/constants/string.constants";
import { percentFormat } from "../../assets/helpers/formats.helpers";
import CardComponent from "../../components/Cards/card";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import SubHeader from '../../components/home/header/subHeader';
import Link from '@material-ui/core/Link';
import { connect } from 'react-redux';
import GridTable from "../../components/TableGrids/GridTable.table";
import { getMeInitialSelect } from '../../assets/constants/meInitialList';
//import google from "react-google-charts";

const MemberUtilizationPage = (props) => {
    const [WidgetDetails, setWidgetDetails] = useState({});
    const [readmissionCountTableData, setReadmissionCountTableData] = useState([]);
    const [memberCondSummaryTableData, setMemberCondSummaryTableData] = useState([]);
    const WidgetDetailsType = [
        WIDGET_TYPES.LOCKICON,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.PERCENT,
        WIDGET_TYPES.SCORE,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.TRENDING,
        WIDGET_TYPES.PERCENT,
    ]

    const type = WidgetDetailsType;
    const widgetStyles = {
        position: 'absolute',
        left: 0,
        //right: 20,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.15,
        color: "white"
    };

    const loadWidgetDetails = () => {
        api.get('/suMemberSummary/' + props.yearSelect + '/flag/0')
            .then(res => {
                if (res.length > 0) {
                    setWidgetDetails({
                        '# Members': Number(res[0].totalmember).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        'Overall Utilization': '$' + Number(res[0].overallutilization).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        'Overall Revenue': '$' + Number(res[0].overallrevenue).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        'Overall Yield': Number(res[0].overallyield).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' %',
                    });
                } else {
                    setWidgetDetails({});
                }
            })
            .catch(err => {
                // console.log(err);
            });
    };

    const loadMemberTableClick = (id) => {
        localStorage.setItem(FLAG, id);
        history.push('/layout/medicare/sumem_with_wellness');
    };

    const readmissionTableClick = (colData, cellMeta, data, columns) => {
        if ((cellMeta.colIndex === 1)) {
            localStorage.setItem(FLAG, cellMeta.rowIndex + 1);
            history.push('/layout/medicare/sure_admission_details');
        }
    }

    const getIcon = (type) => {
        var data = ''
        switch (type) {
            case WIDGET_TYPES.ISSUER:
                data = <AccountBalanceTwoToneIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.MEMBERS:
                data = <PeopleAltIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERCENT:
                data = <ShowChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.SCORE:
                data = <BarChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERSON:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.CHILD:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.ADULT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.INFANT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.REVENUE:
                data = <AttachMoneyIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.COUNT:
                data = <ShoppingCartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.LOCKICON:
                data = <LockOpenIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.TRENDING:
                data = <TrendingUpIcon style={widgetStyles} />
                break;
        }

        return data;
    }

    const readmissionCountColumns = [
        {
            name: "totalmember",
            label: "Readmission Type ",
        },
        {
            name: "overallyield",
            label: "Members with Readmissions",
            options: anchorTag
        },
        {
            name: "overallutilization",
            label: "Number of Readmissions"
        },
        {
            name: "overallrevenue",
            label: "Impact Amount"
        },

    ]

    const memberMedicareCols = [
        {
            name: "memberid",
            label: "Member ID",
            options: anchorTag
        },
        {
            name: "utilization",
            label: "Utilization",
        },
        {
            name: "reportedRevenue",
            label: "Reported Revenue"
        },
        {
            name: "revenuePostCR",
            label: "Revenue Post Chart Review"
        },
        {
            name: "reportedYield",
            label: "Reported Yield"
        },
        {
            name: "yieldPostCR",
            label: "Yield Post Chart Review"
        },
        {
            name: "percentContOverall",
            label: "% Contribution to Overall Utilization"
        },
        {
            name: "claim_count",
            label: "No of Claims"
        },

    ]


    const memberTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            //localStorage.setItem(MEMBER_ID, rowdata[cellMeta.rowIndex].memberid);
            localStorage.setItem(MAO_Chart_Review_Member, rowdata[cellMeta.rowIndex].memberid);
            localStorage.setItem(MAO_MEMBER_SELECT_TAB, UTILIZATION);
            history.push('/layout/medicare/mao_member_summary');
        }
    };

    const loadReadmissionCount = () => {
        api.get('/suReadmissionCount/' + props.yearSelect)
            .then(res => {
                setReadmissionCountTableData(res);
            })
            .catch(err => {
                // console.log(err);
            })
    }

    const loadMemberCondSummary = () => {
        api.get('/suMemberCondSummary/' + props.yearSelect + '/flag/0')
            .then(res => {
                if (res.length > 0) {
                    setMemberCondSummaryTableData({
                        'Members1': Number(res[6].totalmember).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        'Members2': Number(res[4].totalmember).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        'Members3': Number(res[3].totalmember).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        'Members4': Number(res[2].totalmember).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        'Members5': Number(res[1].totalmember).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        'Members6': Number(res[0].totalmember).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    });
                } else {
                    setMemberCondSummaryTableData({});
                }

            })
            .catch(err => {
                // console.log(err);
            })
    }


    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            loadWidgetDetails();
            loadReadmissionCount();
            loadMemberCondSummary();
        }
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect()
    ]);

    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'SERVICE UTILIZATION MEMBER SUMMARY VIEW'} selectType={'utilization'} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0">
                    <div className="state-level">
                        {(Object.entries(WidgetDetails).length > 0) ?
                            <SlickSlider WidgetDetails={WidgetDetails} getIcon={getIcon} type={type} />
                            :
                            <></>
                        }
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <CardComponent
                        heading={'Member Visit Details'}
                        body={
                            <div className="MuiPaper-root MUIDataTable-paper-284 pb-5 MuiPaper-elevation4 MuiPaper-rounded">
                                <div className="MUIDataTableFilterList-root-295"></div>
                                <div className="MUIDataTable-responsiveStacked-291" style={{ "position": "relative", "max-height": "none", "height": "auto" }}>
                                    <table role="grid" className="MuiTable-root MUIDataTable-tableRoot-286" tabindex="0">
                                        <caption className="MUIDataTable-caption-293"></caption>
                                        <thead className="MuiTableHead-root MUIDataTableHead-responsiveStacked-298 MUIDataTableHead-main-297">
                                            <tr className="MuiTableRow-root MUIDataTableHeadRow-root-301 MuiTableRow-head">
                                                <th className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-313 MUIDataTableHeadCell-fixedHeader-314" scope="col" data-colindex="0">
                                                    <span role="button" className="MUIDataTableHeadCell-toolButton-322" data-testid="headcol-0" tabindex="0">
                                                        <div className="MUIDataTableHeadCell-sortAction-318">
                                                            <div className="MUIDataTableHeadCell-data-317"># Members with No Wellness Visits </div>
                                                            <div className="MUIDataTableHeadCell-sortAction-318"><span className="MuiButtonBase-root MuiTableSortLabel-root MUIDataTableHeadCell-sortLabelRoot-320" tabindex="0" role="button" aria-disabled="false"></span></div>
                                                        </div>
                                                    </span>
                                                </th>
                                                <th className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-313 MUIDataTableHeadCell-fixedHeader-314" scope="col" data-colindex="1">
                                                    <span role="button" className="MUIDataTableHeadCell-toolButton-322" data-testid="headcol-1" tabindex="0">
                                                        <div className="MUIDataTableHeadCell-sortAction-318" title="Sort">
                                                            <div className="MUIDataTableHeadCell-data-317"># Members with Emergency Visits</div>
                                                            <div className="MUIDataTableHeadCell-sortAction-318"><span className="MuiButtonBase-root MuiTableSortLabel-root MUIDataTableHeadCell-sortLabelRoot-320" tabindex="0" role="button" aria-disabled="false"></span></div>
                                                        </div>
                                                    </span>
                                                </th>
                                                <th className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-313 MUIDataTableHeadCell-fixedHeader-314" scope="col" data-colindex="2">
                                                    <span role="button" className="MUIDataTableHeadCell-toolButton-322" data-testid="headcol-2" tabindex="0">
                                                        <div className="MUIDataTableHeadCell-sortAction-318" title="Sort">
                                                            <div className="MUIDataTableHeadCell-data-317"># Members with Emergency Visits but no Wellness Visits</div>
                                                            <div className="MUIDataTableHeadCell-sortAction-318"><span className="MuiButtonBase-root MuiTableSortLabel-root MUIDataTableHeadCell-sortLabelRoot-320" tabindex="0" role="button" aria-disabled="false"></span></div>
                                                        </div>
                                                    </span>
                                                </th>
                                                <th className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-313 MUIDataTableHeadCell-fixedHeader-314" scope="col" data-colindex="3">
                                                    <span role="button" className="MUIDataTableHeadCell-toolButton-322" data-testid="headcol-3" tabindex="0">
                                                        <div className="MUIDataTableHeadCell-sortAction-318" title="Sort">
                                                            <div className="MUIDataTableHeadCell-data-317"># Members with Health Conditions and Emergency Visits</div>
                                                            <div className="MUIDataTableHeadCell-sortAction-318"><span className="MuiButtonBase-root MuiTableSortLabel-root MUIDataTableHeadCell-sortLabelRoot-320" tabindex="0" role="button" aria-disabled="false"></span></div>
                                                        </div>
                                                    </span>
                                                </th>
                                                <th className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-313 MUIDataTableHeadCell-fixedHeader-314" scope="col" data-colindex="3">
                                                    <span role="button" className="MUIDataTableHeadCell-toolButton-322" data-testid="headcol-3" tabindex="0">
                                                        <div className="MUIDataTableHeadCell-sortAction-318" title="Sort">
                                                            <div className="MUIDataTableHeadCell-data-317"># Members visiting 3 or more Providers</div>
                                                            <div className="MUIDataTableHeadCell-sortAction-318"><span className="MuiButtonBase-root MuiTableSortLabel-root MUIDataTableHeadCell-sortLabelRoot-320" tabindex="0" role="button" aria-disabled="false"></span></div>
                                                        </div>
                                                    </span>
                                                </th>
                                                <th className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-313 MUIDataTableHeadCell-fixedHeader-314" scope="col" data-colindex="3">
                                                    <span role="button" className="MUIDataTableHeadCell-toolButton-322" data-testid="headcol-3" tabindex="0">
                                                        <div className="MUIDataTableHeadCell-sortAction-318" title="Sort">
                                                            <div className="MUIDataTableHeadCell-data-317"># Members visiting more than 1 Provider for Same Service</div>
                                                            <div className="MUIDataTableHeadCell-sortAction-318"><span className="MuiButtonBase-root MuiTableSortLabel-root MUIDataTableHeadCell-sortLabelRoot-320" tabindex="0" role="button" aria-disabled="false"></span></div>
                                                        </div>
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="MuiTableBody-root">
                                            <tr className="MuiTableRow-root MUIDataTableBodyRow-root-329 undefined MUIDataTableBodyRow-responsiveStacked-331 MUIDataTableBody-lastStackedCell-327 MuiTableRow-hover" data-testid="MUIDataTableBodyRow-0" id="MUIDataTableBodyRow-0">
                                                {Object.entries(memberCondSummaryTableData) && Object.entries(memberCondSummaryTableData).map((element, i) => {
                                                    return (
                                                        <td key={i} className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                                <a onClick={() => loadMemberTableClick(i + 1)} className="hyperlink" style={{ "cursor": "pointer" }}>
                                                                    {element[1]}
                                                                </a>
                                                            </div>
                                                        </td>
                                                    )
                                                })
                                                }
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="MUIDataTable-liveAnnounce-294" aria-live="polite"></div>
                            </div>
                        }></CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <CardComponent
                        heading={'Readmissions Details'}
                        body={
                            <GridTable
                                data={readmissionCountTableData}
                                columns={readmissionCountColumns}
                                onTableClick={readmissionTableClick}
                            ></GridTable>
                        }></CardComponent>
                </div>
                <div className='col-lg-12 pr-0 pl-0'>
                    <div className="list-member mt-4">
                        <CardComponent
                            heading={`MEMBER LIST`}
                            body={props.yearSelect ?
                                <ServerSideGridTable
                                    url={`/suMemberTabledetails/year/${props.yearSelect}/flag/0/max/1000`}
                                    columns={memberMedicareCols}
                                    tableClick={memberTableClick}
                                ></ServerSideGridTable>
                                :
                                <></>
                            }>
                        </CardComponent>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br />

                <br />
                <br />

            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default connect(mapStateToProps)(MemberUtilizationPage);
