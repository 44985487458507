import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import AWVApi from '../../../../assets/constants/AWVRafservice.Instance';
import { currentRoute, currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../../../store/actions';
import AWVServerSideGridTable from "../../../../components/TableGrids/AWVServerSideGridTable.table";
import AWVServerSideGridDataTable from "../../../../components/TableGrids/AWVServerSideGridDataTable.table";
import AWVExpandGridTable from "../../../../components/TableGrids/AWVExpandGridTable.table";
import { HCC, ISSUER_ID } from "../../../../assets/constants/string.constants";
import AwvCardComponent from "../../../../components/Cards/AwvCard";
import { connect } from 'react-redux';
import AwvSubHeader from '../../../../components/home/header/AwvSubHeader';
import CardComponent from "../../../../components/Cards/card";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import Tooltip from '@mui/material/Tooltip';
import { purple, red } from '@mui/material/colors';
import Awvheader from '../../../../components/home/header/Awvheader';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AddAWVFileUpload from '../../../../components/Dialog/AddAWVFileUpload';
import AreaChartMultiExcelAWV from '../../../../components/Charts/AreaChartMultiExcelAWV';
import ColumnChartSingleExcelAWV from '../../../../components/Charts/ColumnChartSingleExcelAWV';
import ColumnChartStackAWV from '../../../../components/Charts/ColumnChartStackAWV';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import history from "../../../../history";
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AWVMemberDetails from '../../../../components/Dialog/AWVMemberDetails';
import AWVMemberDetailsCoder from '../../../../components/Dialog/AWVMemberDetailsCoder';
import AWVMemberDetailsAllCoder from '../../../../components/Dialog/AWVMemberDetailsAllCoder';
import AWVMemberDetailsCoodinator from '../../../../components/Dialog/AWVMemberDetailsCoodinator';
import Moment from 'moment';
// import Moment from 'react-moment';
import AWS from 'aws-sdk';
import axios from "axios";
import { handleDownloadFromS3 } from "../../../ACA/DownloadFile";
import DownloadFileFromS3 from "../../../ACA/DownloadFileFromS3";
import { useRef } from "react";
import AWVServerSideGridTableHCC from "../../../../components/TableGrids/AWVServerSideGridTableHCC";
import AWVNLPInsightPDF from '../../../../components/customRightSidebar/AWVNLPInsightPDF';
import NoteAddIcon from '@mui/icons-material/NoteAdd';

const MemberDetailsReport = (props) => {
    const [isDataGridLoading, setIsDataGridLoading] = useState(false);
    const [isUserHccDetails, setIsUserHccDetails] = useState(false);
    const [memberDetailsData, setMemberDetailsData] = useState([]);
    const [formDataList, setFormDataList] = useState([]);
    const [coderRecordList, setCoderRecordList] = useState([]);
    const [coodinatorRecordList, setCoodinatorRecordList] = useState([]);
    const [open, setOpen] = useState(false);
    const [allCoderOpen, setAllCoderOpen] = useState(false);
    const [coderOpen, setCoderOpen] = useState(false);
    const [coodinatorOpen, setCoodinatorOpen] = useState(false);
    const [editFormData, setEditFormData] = useState({});
    const [editCoderFormData, setCoderFormData] = useState({});
    const [editCoodinatorFormData, setCoodinatorFormData] = useState({});
    const [showUserDetails, setShowUserDetails] = useState(false);
    const [userDetails, setUserDetails] = useState();
    const [HCCUserDetails, setHCCUserDetails] = useState();
    const [memberDetails, setMemberDetails] = useState();
    const [cinIdDetails, setCinIdDetails] = useState();
    const [memberExtraDetailsData, setMemberExtraDetailsData] = useState({});
    const [memberExtraDetailsLoading, setMemberExtraDetailsLoading] = useState(false);
    const [coderDetailsLoading, setCoderDetailsLoading] = useState(false);
    const [coordinatorDetailsLoading, setCoordinatorDetailsLoading] = useState(false);
    const [isDataGridCheked, setIsDataGridCheked] = useState(false);
    const params = new URL(window.location.href).searchParams;
    const [userMemberId] = useState(params.get('cin'));
    const [isControlUser] = useState(JSON.parse(localStorage.getItem('controlUser')));
    const [cinDrilldown, setCINDrilldown] = useState();
    const [aweIdDetails, setAWEIdDetails] = useState();
    const [hccCodeList, setHccCodeList] = useState([]);
    const [rxcCodeList, setRXCCodeList] = useState([]);

    useEffect(() => {
        // loadMemberDetailsDataTable();
    }, [props.yearSelect, props.providerGroupSelect, props.lobSelect]);

    // immediate gridreload HCC capture and coder, coordinator checklist. 
    useEffect(() => {
        if (props.aweRightSidebarCloseDatatableReload && props.aweRightSidebarCloseDatatableReload == true) {
            loadformDataTable(memberDetails?.CIN);
            loadformCoderDataTable(memberDetails?.CIN, memberDetails?.AWE_ID);
            loadformCoodinatorDataTable(memberDetails?.CIN, memberDetails?.AWE_ID)
            // Fixing bug 608: Do-Nut Chart & Count Figures aren't Updating 
            loadMemberDataHCCCapture(memberDetails?.CIN);
            props.currentAWERightSidebarCloseDatatableReload(false);
        }
    }, [props.aweRightSidebarCloseDatatableReload]);

    useEffect(() => {
        if (props.yearSelect && userMemberId && props.yearSelect != '' && userMemberId != '') {
            setShowUserDetails(true);
            props.handleMemberIdSet(userMemberId);
            loadMemberDataHCCCapture(userMemberId);
            loadformDataTable(userMemberId);
            loadMemberDetailsReport(userMemberId);
            setCINDrilldown(userMemberId)
        }
    }, [userMemberId, props.yearSelect, props.providerGroupSelect, props.lobSelect]);

    useEffect(() => {
        if (props.aweRightSidebarCloseDatatableReload && props.aweRightSidebarCloseDatatableReload == true) {
            setIsDataGridCheked(false)
            setIsDataGridCheked(true)
            if (props.yearSelect && userMemberId && props.yearSelect != '' && userMemberId != '') {
                loadMemberDetailsReport(userMemberId);
            }
            props.currentAWERightSidebarCloseDatatableReload(false);
        }
    }, [props.aweRightSidebarCloseDatatableReload]);

    const memberDetailsColumns = [
        {
            name: "CIN",
            label: "CIN/RXID",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ width: '100px', textAlign: "center", color: "#1a9698", cursor: "pointer" }} onClick={() => showUserDetailsLoad(tableMeta.rowData)}>{value}</span>
                        </Box>
                    );
                },
                setCellProps: () => ({
                    style: {
                        position: "sticky",
                        width: '100px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '100px'
                    }
                })
            }
        },
        {
            name: "awe_display_id",
            label: "AWE ID",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '95px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '95px'
                    }
                })
            }
        },
        {
            name: "member_name",
            label: "Member Name",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <span>{(memberDetailsData && tableMeta && memberDetailsData[tableMeta?.rowIndex]?.LAST_NAME) ? value + ' ' + memberDetailsData[tableMeta?.rowIndex]?.LAST_NAME : value}</span>
                        </Box>
                    );
                },
                setCellProps: () => ({
                    style: {
                        position: "sticky",
                        width: '120px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '120px'
                    }
                })
            }
        },
        {
            name: "ADDRESS",
            label: "Member Address",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: true,
                setCellProps: () => ({
                    style: {
                        position: "sticky",
                        width: '150px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '150px'
                    }
                })
            }
        },
        {
            name: "CITY",
            label: "Member City",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '100px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '100px'
                    }
                })
            }
        },
        {
            name: "STATE",
            label: "Member State",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '100px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '100px'
                    }
                })
            }
        },
        {
            name: "ZIP",
            label: "Member Zip",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '85px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '85px'
                    }
                })
            }
        },
        {
            name: "PPG",
            label: "PPG",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: true,
                setCellProps: () => ({
                    style: {
                        position: "sticky",
                        width: '80px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '80px'
                    }
                })
            }
        },
        {
            name: "PRIMARY_PROVIDER",
            label: "Primary Provider",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '110px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '110px'
                    }
                })
            }
        },
        {
            name: "SITE_ID",
            label: "Site ID",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '100px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '100px'
                    }
                })
            }
        },
        {
            name: "PCP_Name",
            label: "PCP Name",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '120px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        //whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '120px'
                    }
                })
            }
        },
        {
            name: "STATUS",
            label: "Staus",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '100px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '100px'
                    }
                })
            }
        },
        {
            name: "YEAR",
            label: "Year",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '100px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '100px'
                    }
                })
            }
        },
        {
            name: "BENEFIT_START_DATE",
            label: "Benefit Start Date",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {/* {Moment.unix(value).format("MM/DD/YYYY")} */}
                            {Moment(value).format("YYYY-MM-DD")}
                        </Box>
                    );
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '115px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '115px'
                    }
                })
            }
        },
        {
            name: "BENEFIT_END_DATE",
            label: "Benefit end date",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {/* {Moment.unix(value).format("MM/DD/YYYY")} */}
                            {Moment(value).format("YYYY-MM-DD")}
                        </Box>
                    );
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '110px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '110px'
                    }
                })
            }
        },
        {
            name: "BIRTH_DATE",
            label: "Birth Date",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {/* {Moment.unix(value).format("MM/DD/YYYY")} */}
                            {Moment(value).format("YYYY-MM-DD")}
                        </Box>
                    );
                },
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '100px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '100px'
                    }
                })
            }
        },
        {
            name: "GENDER",
            label: "Gender",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '100px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '100px'
                    }
                })
            }
        },
        {
            name: "HOMEPHONE",
            label: "Home Phone",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '100px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '100px'
                    }
                })
            }
        },
        {
            name: "PCPLicense",
            label: "PCP License",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '100px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '100px'
                    }
                })
            }
        },
        {
            name: "PCP_ADDRESS",
            label: "PCP Address",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: false,
                setCellProps: () => ({
                    style: {
                        position: "sticky",
                        width: '120px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '120px'
                    }
                })
            }
        },
        {
            name: "PCP_CITY",
            label: "PCP City",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '100px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '100px'
                    }
                })
            }
        },
        /* {
            name: "PCP_LAST_NAME",
            label: "PCP Last Name",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '100px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '100px'
                    }
                })
            }
        }, */
        {
            name: "PCP_PHONE",
            label: "PCP Phone",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '100px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '100px'
                    }
                })
            }
        },
        {
            name: "PCP_STATE",
            label: "PCP State",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '100px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '100px'
                    }
                })
            }
        },
        {
            name: "PCP_ZIP",
            label: "PCP Zip",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '100px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '100px'
                    }
                })
            }
        },
        {
            name: "PPG",
            label: "PPG",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '100px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '100px'
                    }
                })
            }
        },
        {
            name: "PPG_DESCRIPTION",
            label: "PPG Description",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: false,
                setCellProps: () => ({
                    style: {
                        position: "sticky",
                        width: '120px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '120px'
                    }
                })
            }
        },
        {
            name: "hicn",
            label: "Hicn",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '100px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '100px'
                    }
                })
            }
        },
        {
            name: "lob",
            label: "Lob",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '100px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '100px'
                    }
                })
            }
        },
        {
            name: "address_grouping",
            label: "Provider Address Group",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '150px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '150px'
                    }
                })
            }
        },
        {
            name: "site_zip",
            label: "Site Zip",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '100px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '100px'
                    }
                })
            }
        },
        {
            name: "site_city",
            label: "Site City",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '100px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '100px'
                    }
                })
            }
        },
        {
            name: "site_address",
            label: "Site Address",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                display: false,
                setCellProps: () => ({
                    style: {
                        // whiteSpace: "nowrap",
                        position: "sticky",
                        width: '120px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        // whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '120px'
                    }
                })
            }
        },
    ];

    const memberDetailsSubColumns = [
        {
            name: "risk_code_type",
            label: "RISK CODE TYPE",
            cols: "3",
            customRender: false,
            backgroundColor: '#1D9A9B',
        },
        {
            name: "source_code_type",
            label: "SOURCE CODE TYPE",
            cols: "3",
            customRender: false,
            backgroundColor: '#1D9A9B',
        },
        {
            name: "HCC_Code",
            label: "RISK CODE",
            cols: "1",
            customRender: false,
            backgroundColor: '#1D9A9B',
        },
        {
            name: "HCC_Description",
            label: "RISK CODE DESCRIPTION",
            cols: "2",
            customRender: false,
            backgroundColor: '#000',
        },
        // {
        //     name: "rxc_code",
        //     label: "RXC CODE",
        //     cols: "1",
        //     customRender: false,
        //     backgroundColor: '#1D9A9B',
        // },
        // {
        //     name: "rxc_description",
        //     label: "RXC CODE DESCRIPTION",
        //     cols: "2",
        //     customRender: false,
        //     backgroundColor: '#000',
        // },
        {
            name: "DX_code",
            label: "SOURCE CODE",
            cols: "1",
            customRender: false,
            backgroundColor: '#CA0B00',
        },
        {
            name: "DX_description",
            label: "SOURCE CODE DESCRIPTION",
            cols: "2",
            customRender: false,
            backgroundColor: '#1D9A9B',
        },
        // {
        //     name: "cpt_code",
        //     label: "CPT CODE",
        //     cols: "1",
        //     customRender: false,
        //     backgroundColor: '#CA0B00',
        // },
        // {
        //     name: "cpt_description",
        //     label: "CPT DESCRIPTION",
        //     cols: "2",
        //     customRender: false,
        //     backgroundColor: '#1D9A9B',
        // },
        // {
        //     name: "ndc_code",
        //     label: "NDC CODE",
        //     cols: "1",
        //     customRender: false,
        //     backgroundColor: '#1D9A9B',
        // },
        {
            name: "meat_flag",
            label: "INSUFFICIENT DOCUMENTATION",
            cols: "3",
            customRender: false,
            backgroundColor: '#1D9A9B',
        },
        {
            name: "HCC_Confirmed",
            label: "CONFIRMATION",
            cols: "1",
            customRender: false,
            backgroundColor: '#000',
        },
        /* {
            name: "remark",
            label: "REMARKS",
            cols: "1",
            customRender: false,
            backgroundColor: '#CA0B00',
        }, */
        {
            name: "HCC_Code",
            label: "ACTION",
            cols: "1",
            customRender: true,
            backgroundColor: '#CA0B00',
        }
    ];


    const coderRecordColumns = [
        {
            name: "description",
            label: "AWE SOURCE",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "status",
            label: "STATUS",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            {value == '1' ?
                                <span className="expand-grid-success-status pt-1 pb-1 pl-3 pr-3">Confirmed</span>
                                : value == '0' ?
                                    <span className="expand-grid-danger-status pt-1 pb-1 pl-3 pr-3">Not Confirmed</span>
                                    :
                                    <></>
                            }
                        </>
                    );
                }
            }
        },
        {
            name: "remark",
            label: "REMARKS",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "checklist_id",
            label: "ACTION",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        // memberExtraDetailsData && isControlUser && isControlUser.user_id && memberExtraDetailsData.user_assigned && memberExtraDetailsData.user_assigned == isControlUser.user_id && memberExtraDetailsData?.form_status == 'pendingforCoder' && memberExtraDetailsData?.on_hold != 'Y' ?
                        //memberExtraDetailsData && isControlUser && isControlUser.user_id && memberExtraDetailsData.user_assigned && memberExtraDetailsData.user_assigned == isControlUser.user_id && memberExtraDetailsData?.form_status == 'pendingforCoder' ?
                        // Enable checklist for pendingforCoder and pendingForPaymentApproval
                        memberExtraDetailsData && isControlUser && isControlUser.user_id && memberExtraDetailsData.user_assigned && memberExtraDetailsData.user_assigned == isControlUser.user_id && (memberExtraDetailsData?.form_status == 'pendingforCoder' || memberExtraDetailsData?.form_status == 'pendingForPaymentApproval') ?
                            <span key={value} className="text-danger" style={{ cursor: 'pointer' }} onClick={() => editHCCDetails({ 'checklist_id': value }, cinIdDetails)}>EDIT</span>
                            :
                            <></>
                    );
                }
            }
        }
    ];

    const coodinatorRecordColumns = [
        {
            name: "awe_source",
            label: "AWE SOURCE",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "receive_date",
            label: "RECEIVE DATE",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        value && value != '' ?
                            <span >{Moment(value).format("MM/DD/YYYY")}</span>
                            :
                            <></>

                    );
                }
            }
        },
        {
            name: "received_method",
            label: "RECEIVED METHOD",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "additional_data",
            label: "ADDITIONAL DATA",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            {value == '1' ?
                                <span className="expand-grid-success-status pt-1 pb-1 pl-3 pr-3">Yes</span>
                                : value == '0' ?
                                    <span className="expand-grid-danger-status pt-1 pb-1 pl-3 pr-3">No</span>
                                    :
                                    <></>
                            }
                        </>
                    );
                }
            }
        },
        /* correct the spell of telehelth to TELEHEALTH */
        {
            name: "telehelth_check",
            label: "CHECKBOX DONE VIA TELEHEALTH",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            {value == '1' ?
                                <span className="expand-grid-success-status pt-1 pb-1 pl-3 pr-3">Yes</span>
                                : value == '0' ?
                                    <span className="expand-grid-danger-status pt-1 pb-1 pl-3 pr-3">No</span>
                                    :
                                    <></>
                            }
                        </>
                    );
                }
            }
        },
        {
            name: "service_date",
            label: "SERVICE DATE",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        value && value != '' ?
                            <span >{Moment(value).format("MM/DD/YYYY")}</span>
                            :
                            <></>
                    );
                }
            }
        },
        // add Rendering Provider NPI | Rendering Provider Name | Site ID | PPG in coodinator checklist table
        {
            name: "rendering_provider_npi",
            label: "RENDERING PROVIDER NPI  ",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "rendering_provider_name",
            label: "RENDERING PROVIDER NAME",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "site_id",
            label: "SITE ID",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "ppg",
            label: "PPG",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "remarks",
            label: "REMARKS",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "awe_source",
            label: "ACTION",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        // memberExtraDetailsData && isControlUser && isControlUser.user_id && memberExtraDetailsData.user_assigned && memberExtraDetailsData.user_assigned == isControlUser.user_id && memberExtraDetailsData?.form_status == 'pendingforCoordinator' && memberExtraDetailsData?.on_hold != 'Y' ?
                        //memberExtraDetailsData && isControlUser && isControlUser.user_id && memberExtraDetailsData.user_assigned && memberExtraDetailsData.user_assigned == isControlUser.user_id && memberExtraDetailsData?.form_status == 'pendingforCoordinator' ?
                        // enable coordinator checklist
                        memberExtraDetailsData && isControlUser && isControlUser.user_id && memberExtraDetailsData.user_assigned
                            && memberExtraDetailsData.user_assigned == isControlUser.user_id
                            && (memberExtraDetailsData?.form_status == 'pendingforCoordinator'
                                || memberExtraDetailsData?.form_status == 'pendingforCoder') ?
                            <span key={value} className="text-danger" style={{ cursor: 'pointer' }} onClick={() => editHCCDetails({ 'awe_source': value }, cinIdDetails)}>EDIT</span>
                            :
                            <></>
                    );
                }
            }
        }
    ];

    const formDataColumns = [
        {
            name: "risk_code_type",
            label: "RISK CODE TYPE",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "source_code_type",
            label: "SOURCE CODE TYPE",
            options: {
                filter: true,
                sort: true,
            }
        }, {
            name: "HCC_Code",
            label: "RISK CODE",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "HCC_Description",
            label: "RISK CODE DESCRIPTION",
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th
                            key={columnMeta.index}
                            style={{ borderBottom: "1px solid #e0e0e0", background: "#f7f7f7", width: "13rem", fontSize: "12px", padding: "2px 0 0 15px" }}
                            onClick={() => handleToggleColumn(columnMeta.index)}
                        >
                            {columnMeta.label}
                        </th>
                    );
                }
            }
        },
        {
            name: "rxc_code",
            label: "RXC CODE",
            options: {
                filter: true,
                sort: true,
                display: false,
            }
        },
        {
            name: "rxc_description",
            label: "RXC CODE DESCRIPTION",
            options: {
                filter: true,
                sort: true,
                display: false,
            }
        },
        {
            name: "DX_code",
            label: "SOURCE CODE",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "DX_description",
            label: "SOURCE CODE DESCRIPTION",
            options: {
                filter: true,
                sort: true,
                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th
                            key={columnMeta.index}
                            style={{ borderBottom: "1px solid #e0e0e0", background: "#f7f7f7", width: "13rem", fontSize: "12px", padding: "2px 0 0 15px" }}
                            onClick={() => handleToggleColumn(columnMeta.index)}
                        >
                            {columnMeta.label}
                        </th>
                    );
                }
            }
        },
        {
            name: "cpt_code",
            label: "CPT CODE",
            options: {
                filter: true,
                sort: true,
                display: false,

            }
        },
        {
            name: "cpt_description",
            label: "CPT DESCRIPTION",
            options: {
                filter: true,
                sort: true,
                display: false,

                customHeadRender: (columnMeta, handleToggleColumn) => {
                    return (
                        <th
                            key={columnMeta.index}
                            style={{ borderBottom: "1px solid #e0e0e0", background: "#f7f7f7", width: "13rem", fontSize: "12px", padding: "2px 0 0 15px" }}
                            onClick={() => handleToggleColumn(columnMeta.index)}
                        >
                            {columnMeta.label}
                        </th>
                    );
                }
            }
        },
        {
            name: "ndc_code",
            label: "NDC CODE",
            options: {
                filter: true,
                sort: true,
                display: false,

            }
        },
        {
            name: "meat_flag",
            label: "INSUFFICIENT DOCUMENTATION",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        // awe record page--> output Yes/No will come in place of 0 or 1 in meat flag from backend  
                        <span>{value != null ? value == '0' ? "No" : "Yes" : ""}</span>
                    );
                }
            }
        },
        {
            name: "HCC_Confirmed",
            label: "CONFIRMATION",
            options: {
                filter: true,
                sort: true,
                // customBodyRender: (value, tableMeta, updateValue) => {
                //     return (
                //         <Box sx={{ display: 'flex', alignItems: 'center', width: "200px" }}>
                //             <select className="btn-info dropdown-toggle" value={value} >
                //                 <option value={'confirmed'}> Confirmed </option>
                //                 <option value={'not_confirmed'}> Not Confirmed </option>
                //                 <option value={'not_longer_applicable'}> Not Longer Applicable </option>
                //             </select>
                //         </Box>
                //     );
                // }
            }
        },
        {
            name: "remark",
            label: "REMARKS",
            options: {
                filter: true,
                sort: true,
                display: 'excluded'
            }
        },
        // Add the Action col :: Adding fuctionality is left to the edit buttom 
        {
            name: "HCC_Code",
            label: "ACTION",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (<>
                        <span
                            disabled={typeof memberDetails?.CIN === 'undefined'}
                            style={{
                                color: typeof memberDetails?.CIN === 'undefined' ? 'rgb(220, 53, 69,0.5)' : 'rgb(220, 53, 69)',
                                //add cursor pointer
                                cursor: 'pointer'
                            }}
                            onClick={() => editActionData(tableMeta, (userMemberId === null || userMemberId === '') ? memberDetails?.CIN : userMemberId, hccCodeList, rxcCodeList)}
                        >
                            EDIT
                        </span>
                        <Tooltip title="HCC Checklist Notes">
                            <a className="hyperlink " style={{ cursor: "pointer", color: typeof memberDetails?.CIN === 'undefined' ? 'rgba(46, 147, 147, 0.5)' : "#209f9d" }} onClick={() => typeof memberDetails?.CIN === 'undefined' ? '' : notificationRightDrawer(true, 'memberHccRemark', tableMeta, '', memberDetails)}>
                                <NoteAddIcon color="#209f9d" style={{ width: "1.1em", height: "1.2em" }} />
                            </a>
                        </Tooltip>
                    </>
                    );
                }
            },

        },
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: true,
                display: 'excluded'
            }
        },
        {
            name: "cin",
            label: "cin",
            options: {
                filter: true,
                sort: true,
                display: 'excluded'
            }
        },
        {
            name: "member_id",
            label: "member_id",
            options: {
                filter: true,
                sort: true,
                display: 'excluded'
            }
        },
        {
            name: "awv_id",
            label: "awv_id",
            options: {
                filter: true,
                sort: true,
                display: 'excluded'
            }
        },
    ];

    const handleCloseCoodinator = () => {
        setCoodinatorOpen(false);
        setCoodinatorFormData({});
    };

    const handleCloseCoder = () => {
        setCoderOpen(false);
        setCoderFormData({});
    };

    const handleCloseAllCoder = () => {
        setAllCoderOpen(false);
        setCoderFormData({});
    };

    const handleClose = () => {
        setOpen(false);
        setEditFormData({});
    };

    const editLoadCoodinatorFormData = (value, CinId, action = 'Edit') => {
        setCoodinatorOpen(true);
        value.awe_source = value[0];
        value.receiver_date = value[1];
        value.received_method = value[2];
        value.additional_data = value[3];
        value.telehealth_check = value[4]; /* correct the spell of telehelth to TELEHEALTH */
        value.service_date = value[5];
        value.remarks = value[6];
        value.AWE_ID = memberDetails?.AWE_ID;
        value.CIN = CinId;
        value.action = action;
        setCoodinatorFormData(value);
    }

    const editLoadCoderFormData = (value, CinId, action = 'Edit') => {
        setCoderOpen(true);
        value.description = value[0];
        value.remarks = value[2];
        value.status = value[1];
        value.checklist_id = value[3];
        value.AWE_ID = memberDetails?.AWE_ID;
        value.CIN = CinId;
        value.action = action;
        setCoderFormData(value);
    }

    const editLoadFormData = (value, CinId, action = 'Edit') => {
        setOpen(true);
        value.CIN = CinId;
        value.action = action;
        setEditFormData(value);
    }

    const editLoadAllCoderFormData = () => {
        setAllCoderOpen(true);
        var tempValue = {};
        tempValue.coderRecordList = coderRecordList;
        tempValue.AWE_ID = memberDetails?.AWE_ID;
        tempValue.CIN = memberDetails?.CIN;
        tempValue.action = 'Edit';
        setCoderFormData(tempValue);
    }

    const showUserDetailsLoad = (value) => {
        setShowUserDetails(true);
        setCINDrilldown(value[0])
        loadMemberDataHCCCapture(value[0]);
        loadformDataTable(value[0]);
        loadMemberDetailsReport(value[0]);
        props.handleMemberIdSet(value[0]);
    }

    const loadformDataTable = (CinId) => {
        var value = [];
        AWVApi.get('/get_member_hcc_details?cin=' + btoa(CinId) + '&year=' + props?.yearSelect + '&lob=' + props?.lobSelect + '&organisation_id=1')
            .then(res => {
                value.push(...res.data);
                for (let i = 0; i < value.length; i++) {
                    const data = value[i];
                    if (data.risk_code_type === 'RXC') {
                        data.HCC_Code = data.rxc_code;
                        data.HCC_Description = data.rxc_description;
                    }
                    if (data.source_code_type === 'CPT_CODE') {
                        data.DX_code = data.cpt_code;
                        data.DX_description = data.cpt_description;
                    }
                    else if (data.source_code_type === 'NDC') {
                        data.DX_code = data.ndc_code;
                        data.DX_description = data.ndc_description;
                    }
                }
                const hccCodeList = value.map((data) => data.HCC_Code);
                const rxcCodeList = value
                  .filter((data) => data.risk_code_type === 'RXC')
                  .map((data) => data.rxc_code);
                setHccCodeList(hccCodeList)
                setRXCCodeList(rxcCodeList)
                setFormDataList(res.data);
            })
            .catch(err => {
                console.log(err)
            })
    }

    const loadformCoodinatorDataTable = (CinId, AweId) => {
        setCoordinatorDetailsLoading(true);
        AWVApi.get('/getawecoordinatorchecklist?cin=' + btoa(CinId) + '&awe_id=' + btoa(AweId) + '&year=' + props?.yearSelect + '&lob=' + props?.lobSelect + '&organisation_id=1')
            .then(res => {

                setCoodinatorRecordList(res.data);
                setCoordinatorDetailsLoading(false);
            })
            .catch(err => {
                setCoordinatorDetailsLoading(false);
                console.log(err)
            })
    }

    const loadformCoderDataTable = (CinId, AweId) => {
        setCoderDetailsLoading(true);
        AWVApi.get('/getawecoderchecklist?cin=' + btoa(CinId) + '&awe_id=' + btoa(AweId) + '&year=' + props?.yearSelect + '&lob=' + props?.lobSelect + '&organisation_id=1')
            .then(res => {
                setCoderRecordList(res.data);
                setCoderDetailsLoading(false);
            })
            .catch(err => {
                setCoderDetailsLoading(false);
                console.log(err)
            })
    }


    const loadMemberDetailsDataTable = () => {
        setIsDataGridLoading(true);
        let queryStr = '';
        if (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL')
            queryStr = '&provider_group=' + props.providerGroupSelect;

        AWVApi.get('/get-awv-member-details?year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + queryStr)
            .then(res => {
                setIsDataGridLoading(false);
                setMemberDetailsData(res.data);
            })
            .catch(err => {
                setIsDataGridLoading(false);
                console.log(err)
            })
    }

    const loadMemberDetailsExtraData = (CinId, AweId) => {
        setMemberExtraDetailsLoading(true);
        AWVApi.get('/get-all-awv-record?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + '&formAttached=0&form_attached=&provider_group=&iSortCol_0=cin&sSortDir_0=asc&iDisplayStart=0&iDisplayLength=10&ColumnSearch=cin="' + btoa(CinId) + '",awv_id="' + btoa(AweId) + '"')
            .then(res => {
                if (res.data && res.data.length > 0) {
                    setMemberExtraDetailsData(res.data[0]);
                } else {
                    setMemberExtraDetailsData({});
                }
                setMemberExtraDetailsLoading(false);
            })
            .catch(err => {
                setMemberExtraDetailsLoading(false);
                console.log(err)
            })

    }

    const loadMemberDataHCCCapture = (CinId) => {
        setIsUserHccDetails(true);
        AWVApi.get('/get-member-hcc-summary?cin=' + CinId + '&year=' + props?.yearSelect + '&lob=' + props?.lobSelect + '&organisation_id=1')
            .then(res => {
                setIsUserHccDetails(false);
                setHCCUserDetails(res.data);
            })
            .catch(err => {
                setIsUserHccDetails(false);
                console.log(err)
            })

    }

    const loadMemberDetailsReport = (CinId) => {
        setIsUserHccDetails(true);
        setMemberExtraDetailsLoading(true);
        setCoderDetailsLoading(true);
        setCoordinatorDetailsLoading(true);
        AWVApi.get('/get-member-detail-drilldown?lob=' + props.lobSelect + '&year=' + props.yearSelect + '&cin=' + CinId + '&organisation_id=1')
            .then(res => {
                //setIsUserHccDetails(false);
                setMemberDetails(res.data[0]);
                loadformCoderDataTable(res.data[0]?.CIN, res.data[0]?.AWE_ID);
                loadformCoodinatorDataTable(res.data[0]?.CIN, res.data[0]?.AWE_ID);
                loadMemberDetailsExtraData(res.data[0]?.CIN, res.data[0]?.AWE_ID);
                setCinIdDetails(res.data[0]?.CIN);
                setAWEIdDetails(res.data[0]?.AWE_ID)
            })
            .catch(err => {
                //setIsUserHccDetails(false);
                console.log(err)
            })

    }

    const handleOnclickUrl = () => {
        if (params.get('cin') && params.get('cin') != '') {
            //history.push('/awe/awe_analytics_summary?cin='+params.get('cin'));
            history.push('/awe/awe_form_list');
            props.handleMemberIdSet('');
        } else {
            setShowUserDetails(false);
            props.handleMemberIdSet('');
        }
    }

    const awvCoderWorkbench = () => {
        history.push('/awe/awe_coder_benchmark');
    }

    //passing more arguments to show data on edit the right side bar:ndc_code,rxc_code,cpt_code
    const editActionData = (value, CinId, hccCodeList, rxcCodeList, action = 'Edit') => {
        props.currentAWERightSidebarType({ 'type': 'memberHccDetailsReport', 'cin_id': CinId, 'status': 0, 'awv_id': memberDetails?.AWE_ID, 'DX_code': value.rowData[6], 'HCC_Code': value.rowData[2], 'rxc_code': value.rowData[4], 'ndc_code': value.rowData[10], 'cpt_code': value.rowData[8], "id": value.rowData[15], 'hccCodeList': hccCodeList, 'rxcCodeList': rxcCodeList });
        props.currentAWERightSidebar(true);
    }
    const editHCCDetails = (value, CinId, action = 'Edit', aweId, hccCodeList, rxcCodeList) => {

        // dx code is not mandatory so it can be blank, hence changing condition to value.HCC_Code from value.DX_code
        if ((value.id && value.id != '') && action == 'Edit') {

            //passing more arguments to show data on edit the right side bar:ndc_code,rxc_code,cpt_code
            props.currentAWERightSidebarType({ 'type': 'memberHccDetailsReport', 'cin_id': CinId, 'status': 0, 'awv_id': memberDetails?.AWE_ID, 'DX_code': value.DX_code, 'HCC_Code': value.HCC_Code, 'rxc_code': value.rxc_code, 'ndc_code': value.ndc_code, 'cpt_code': value.cpt_code, 'id': value.id, "hccCodeList": hccCodeList, 'rxcCodeList': rxcCodeList });
            props.currentAWERightSidebar(true);

        } else if (value.checklist_id && value.checklist_id != '' && action == 'Edit') {

            props.currentAWERightSidebarType({ 'type': 'memberCoderChecklistReport', 'cin_id': CinId, 'status': 0, 'awv_id': memberDetails?.AWE_ID, 'checklist_id': value.checklist_id });
            props.currentAWERightSidebar(true);

        } else if (action == 'MultiEdit') {

            props.currentAWERightSidebarType({ 'type': 'memberMultiCoderChecklistReport', 'cin_id': CinId, 'status': 0, 'awv_id': memberDetails?.AWE_ID });
            props.currentAWERightSidebar(true);

        } else if (((value.awe_source && value.awe_source != '') || value.awe_source == null) && action == 'Edit') {

            props.currentAWERightSidebarType({ 'type': 'memberCoordinatorChecklistReport', 'cin_id': CinId, 'awv_id': memberDetails?.AWE_ID, 'status': 0, 'awe_source': value.awe_source });
            props.currentAWERightSidebar(true);

        } else {

            props.currentAWERightSidebarType({ 'type': 'memberHccDetailsReport', 'cin_id': CinId, 'status': 1, 'aweId': aweId, "hccCodeList": hccCodeList, 'rxcCodeList': rxcCodeList });
            props.currentAWERightSidebar(true);

        }
        setIsDataGridCheked(false)
    };

    const notificationRightDrawer = (open, typeValue, valueId, statusId = '', cinId = '') => {
        // props.currentAWERightSidebarType({ 'type': typeValue, 'awv_id': valueId, 'cin_id': cinId, 'status': statusId });
        props.currentAWERightSidebarType({ 'type': typeValue, 'awv_id': valueId.rowData[18], 'cin_id': valueId.rowData[16], 'status': statusId, 'title': 'HCC CHECKLIST NOTES', 'hcc_code': valueId.rowData[2], 'hcc_description': valueId.rowData[3], 'risk_code_type': valueId.rowData[0]});
        props.currentAWERightSidebar(open);
        setIsDataGridCheked(false)
    };

    var providerGroupSelectVar = (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL') ? props.providerGroupSelect : '';
    //Calculating Member Age
    const calculateAge = (dob) => {
        if (dob) {
            const today = new Date();
            const birthDay = new Date(dob);
            let age = today.getFullYear() - birthDay.getFullYear();
            const monthDifference = today.getMonth() - birthDay.getMonth();
            if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDay.getDate())) {
                age--;
            }
            return age;
        } else {
            return "";
        }
    };
    const div = document.querySelector('.customPagination_HCC');
    const div2 = document.querySelector('.pagination_page');
    useEffect(() => {

        if (div2 && div) {
            div2.appendChild(div);
        }
    }, [div, div2]);

    return (
        <>
            <AWVMemberDetailsCoodinator
                loadFormData={loadformCoodinatorDataTable}
                editFormData={editCoodinatorFormData}
                open={coodinatorOpen}
                handleClose={handleCloseCoodinator}
            />
            <AWVMemberDetailsCoder
                loadFormData={loadformCoderDataTable}
                editFormData={editCoderFormData}
                open={coderOpen}
                handleClose={handleCloseCoder}
            />
            <AWVMemberDetailsAllCoder
                loadFormData={loadformCoderDataTable}
                editFormData={editCoderFormData}
                open={allCoderOpen}
                handleClose={handleCloseAllCoder}
            />
            <AWVMemberDetails
                loadFormData={loadMemberDetailsDataTable}
                editFormData={editFormData}
                open={open}
                handleClose={handleClose}
            />
            {showUserDetails ?
                <div className="row pr-0 pl-0 mt-1">
                    <div className="row pr-0 pl-0">
                        <Tooltip title="Back">
                            <div style={{ position: "absolute", top: "188px", right: "1%", color: "#1a9698", cursor: "pointer" }}>
                                <i class="fa fa-arrow-circle-left fa-3x" aria-hidden="true" onClick={() => handleOnclickUrl()}></i>
                            </div>
                        </Tooltip>

                        <div className="col-lg-6 pr-2">
                            <div className="col-lg-12 card">
                                <table width={'100%'}>
                                    <tr className="mt-2 pb-3 pl-2" style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <td className="mt-2 pb-3 pt-2 pl-2">
                                            <div className="ml-4">AWE ID</div>
                                            <div className="ml-4"><b>{memberDetails?.awe_display_id}</b></div>
                                        </td>
                                        <td className="mt-2 pb-3 pt-2 pl-2" style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                            <div className="ml-4">CIN/RXID</div>
                                            <div className="ml-4"><b>{memberDetails?.CIN}</b></div>
                                        </td>
                                    </tr>
                                    <tr className="mt-2 pb-3 pt-2 pl-2" style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <td className="mt-2 pb-3 pt-2 pl-2">
                                            <div className="ml-4">PCP Effective Date</div>
                                            <div className="ml-4"><b> {memberDetails?.Date_of_Service ? Moment(memberDetails?.Date_of_Service).format("YYYY-MM-DD") : ''}</b></div>
                                        </td>
                                        <td className="mt-2 pb-3 pt-2 pl-2" style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                            <div className="ml-4">Primary Provider</div>
                                            <div className="ml-4"><b> {memberDetails?.Rendring_Provider}</b></div>
                                        </td>
                                    </tr>
                                    <tr className="mt-2 pb-3 pl-2" style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <td className="mt-2 pb-3 pt-2 pl-2">
                                            <div className="ml-4">Service Provider Group</div>
                                            <div className="ml-4"><b> {memberDetails?.Service_Provider_Group}</b></div>
                                        </td>
                                        <td className="mt-2 pb-3 pt-2 pl-2" style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                            <div className="ml-4">Pay To Provider Group</div>
                                            <div className="ml-4"><b>{memberDetails?.Pay_To_Provider_Group}</b></div>
                                        </td>
                                    </tr>
                                    <tr className="mt-2 pb-3 pl-2" style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <td className="mt-2 pb-3 pt-2 pl-2">
                                            <div className="ml-4">Member Address</div>
                                            <div className="ml-4"><b>{memberDetails?.Member_Address}</b></div>
                                        </td>
                                        <td className="mt-2 pb-3 pt-2 pl-2" style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                            <div className="ml-4">Site ID</div>
                                            <div className="ml-4"><b>{memberDetails?.Site_ID}</b></div>
                                        </td>
                                    </tr>
                                    <tr className="mt-2 pb-3 pl-2" >
                                        <td className="mt-2 pb-3 pt-2 pl-2">&nbsp;
                                            <div className="ml-4">DOB(Age) :
                                                {memberDetails?.BIRTH_DATE ?
                                                    <b>{memberDetails?.BIRTH_DATE ? Moment(memberDetails?.BIRTH_DATE).format("YYYY-MM-DD") : ""}({calculateAge(memberDetails?.BIRTH_DATE)})</b>
                                                    : <></>}</div>
                                        </td>
                                        <td className="mt-2 pb-3 pt-2 pl-2" style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                            <div className="ml-4"></div>
                                            <div className="ml-4"><b></b></div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div className="col-lg-6 pl-2 ">
                            <div className="col-lg-12 card text-center pt-2">
                                <h1><b>Member HCC Capture Summary</b></h1>
                                <div className="col-lg-12 row mb-4 mt-4">
                                    <div className="col-lg-8 p-0 m-0">
                                        <div className="container align-items-center justify-content-center text-center mt-5" style={{ width: '230px' }}>
                                            <CircularProgressbarWithChildren
                                                value={(HCCUserDetails?.Total_Confirmed) ? HCCUserDetails?.Total_Confirmed : 0}
                                                strokeWidth={10}
                                                styles={buildStyles({
                                                    pathColor: `#1D9A9B`,
                                                })}
                                            >
                                                <div >
                                                    <strong style={{ fontSize: 30, marginTop: 0, color: '#000' }}>{(HCCUserDetails?.Total_Confirmed) ? HCCUserDetails?.Total_Confirmed : 0}</strong> <br />
                                                    <strong style={{ fontSize: 15, marginTop: 5, color: '#000' }}>Completed</strong>
                                                </div>
                                            </CircularProgressbarWithChildren >
                                            <div className="circular-content mt-3 ml-3 row">
                                                <strong style={{ fontSize: 12, color: '#000' }}>
                                                    <div style={{ height: '13px', width: '20px', backgroundColor: '#1D9A9B', position: 'absolute' }}></div>
                                                    <div className="ml-4">Completed</div>
                                                </strong>
                                                <strong className="ml-4" style={{ fontSize: 12, color: '#000' }}>
                                                    <div style={{ height: '13px', width: '20px', backgroundColor: '#D6D6D6', position: 'absolute' }}></div>
                                                    <div className="ml-4">Pending</div>
                                                </strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 p-0 m-0" style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <div className="mt-2 pb-3 pl-2" style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                            <strong style={{ fontSize: 25, marginTop: '15px', color: '#000' }}>{((HCCUserDetails?.Total_Expected_HCC) ? HCCUserDetails?.Total_Expected_HCC : 0).toFixed(0)}</strong> <br />
                                            <strong style={{ fontSize: 12, marginTop: 5, color: '#000' }}>Total Expected HCC</strong>
                                        </div>
                                        <div className="mt-2 pb-3 pl-2" style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                            <strong style={{ fontSize: 25, marginTop: '15px', color: '#1D9A9B' }}>{((HCCUserDetails?.Total_Confirmed) ? HCCUserDetails?.Total_Confirmed : 0).toFixed(0)}</strong> <br />
                                            <strong style={{ fontSize: 12, marginTop: 5, color: '#000' }}>Total Confirmed</strong>
                                        </div>
                                        <div className="mt-2 pb-3 pl-2" >
                                            <strong style={{ fontSize: 25, marginTop: '15px', color: '#fcb441' }}>{((HCCUserDetails?.HCC_Capture_Rate) ? HCCUserDetails?.HCC_Capture_Rate : 0).toFixed(0)}%</strong> <br />
                                            <strong style={{ fontSize: 12, marginTop: 5, color: '#000' }}>HCC Capture Rate</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {memberExtraDetailsData && memberExtraDetailsData?.form_status && (memberExtraDetailsData?.form_status != 'PendingUpload') ?
                        <div className="pl-0 pr-0 col-lg-12 mt-5">
                            {/* //added scrollbar-X */}
                            <AwvCardComponent
                                heading={`COORDINATOR CHECKLIST`}
                                body={
                                    <div style={{
                                        width: "70rem"
                                    }}>
                                        <div style={{
                                            width: "100rem",
                                            padding: "10px"

                                        }}>
                                            <AWVServerSideGridTable
                                                data={coodinatorRecordList}
                                                columns={coodinatorRecordColumns}
                                                filter={false}
                                                search={false}
                                                pagination={false}
                                                isLoading={coordinatorDetailsLoading}
                                            ></AWVServerSideGridTable>
                                        </div>
                                    </div>
                                }>
                            </AwvCardComponent>
                        </div>
                        :
                        <></>
                    }
                    {/** && isControlUser && isControlUser.user_id && memberExtraDetailsData.user_assigned && memberExtraDetailsData.user_assigned == isControlUser.user_id */}
                    {memberExtraDetailsData && memberExtraDetailsData?.form_status && (memberExtraDetailsData?.form_status != 'PendingUpload' && memberExtraDetailsData?.form_status != 'pendingforCoordinator') ?
                        <>
                            {coderRecordList && coderRecordList.length > 0 && ((memberExtraDetailsData?.form_status == 'pendingforCoder' || memberExtraDetailsData?.form_status == 'pendingForPaymentApproval') && isControlUser && isControlUser.user_id && memberExtraDetailsData.user_assigned && memberExtraDetailsData.user_assigned == isControlUser.user_id) ?
                                <>
                                    <div className="col-lg-12" style={{ zIndex: '1', top: '62px', left: '80%', borderRadius: "5px" }}>
                                        {/* {(memberExtraDetailsData?.form_status == 'pendingforCoder' && memberExtraDetailsData?.on_hold != 'Y' && isControlUser && isControlUser.user_id && memberExtraDetailsData.user_assigned && memberExtraDetailsData.user_assigned == isControlUser.user_id) ? */}
                                        {/* {(memberExtraDetailsData?.form_status == 'pendingforCoder' && isControlUser && isControlUser.user_id && memberExtraDetailsData.user_assigned && memberExtraDetailsData.user_assigned == isControlUser.user_id) ? */}
                                        {/* Enable checklist for pendingforCoder and pendingForPaymentApproval */}
                                        {((memberExtraDetailsData?.form_status == 'pendingforCoder' || memberExtraDetailsData?.form_status == 'pendingForPaymentApproval') && isControlUser && isControlUser.user_id && memberExtraDetailsData.user_assigned && memberExtraDetailsData.user_assigned == isControlUser.user_id) ?
                                            // <Button onClick={editLoadAllCoderFormData} style={{ position: 'absolute', color: 'white', padding: '7px 25px', fontFamily: "'Source Sans Pro', sans-serif", backgroundColor: "rgb(46 147 147)" }} >
                                            <Button onClick={() => editHCCDetails('', cinIdDetails, 'MultiEdit')} style={{ position: 'absolute', color: 'white', padding: '7px 25px', fontFamily: "'Source Sans Pro', sans-serif", backgroundColor: "rgb(46 147 147)" }} >
                                                Edit All
                                            </Button>
                                            :
                                            <></>
                                        }
                                    </div>
                                    <div className="col-lg-12" style={{ zIndex: '1', top: '62px', left: '60%', borderRadius: "5px" }}>
                                        <NavLink to={'/awe/awe_coder_benchmark?cin=' + memberDetails?.CIN}>
                                            <Button onclick={() => awvCoderWorkbench()} style={{ position: 'absolute', color: 'white', padding: '7px 25px', fontFamily: "'Source Sans Pro', sans-serif", backgroundColor: "rgb(46 147 147)" }} >
                                                Coder Workbench
                                            </Button>
                                        </NavLink>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="col-lg-12" style={{ zIndex: '1', top: '62px', left: '74%', borderRadius: "5px" }}>
                                        <NavLink to={'/awe/awe_coder_benchmark?cin=' + memberDetails?.CIN}>
                                            <Button onclick={() => awvCoderWorkbench()} style={{ position: 'absolute', color: 'white', padding: '7px 25px', fontFamily: "'Source Sans Pro', sans-serif", backgroundColor: "rgb(46 147 147)" }} >
                                                Coder Workbench
                                            </Button>
                                        </NavLink>
                                    </div>
                                </>
                            }

                            <div className="pl-0 pr-0 col-lg-12 mt-5">
                                <AwvCardComponent
                                    heading={`CODER CHECKLIST`}
                                    body={
                                        <AWVServerSideGridTable
                                            data={coderRecordList}
                                            columns={coderRecordColumns}
                                            filter={false}
                                            search={false}
                                            pagination={true}
                                            isLoading={coderDetailsLoading}
                                        ></AWVServerSideGridTable>
                                    }>
                                </AwvCardComponent>
                            </div>
                        </>
                        :
                        <></>
                    }
                    {/* Adding NLP processed pdf button */}
                    <div className="col-lg-12">
                    {typeof cinDrilldown === 'undefined' ?
                        <></>
                        :
                            <AWVNLPInsightPDF form_url={memberExtraDetailsData.form_url} cin={cinDrilldown} position={'relative'} left = {'73%'} />
                    }
                        <Button
                            disabled={typeof memberDetails?.CIN === 'undefined'}
                            onClick={() => editHCCDetails('', cinIdDetails, 'memberHccDetailsReport', aweIdDetails, hccCodeList, rxcCodeList)}
                            style={{
                                position: 'relative',
                                color: 'white',
                                left: '66%', 
                                transform: 'translateX(-50%, -50%)', 
                                padding: props.padding ? props.padding : '7px 25px',
                                fontFamily: "'Source Sans Pro', sans-serif",
                                backgroundColor: typeof memberDetails?.CIN === 'undefined' ? 'rgba(46, 147, 147, 0.5)' : 'rgb(46, 147, 147)',
                                top: '80%'
                            }}
                        >
                            Add Risk Code
                        </Button>
                        </div>
                    <div className="col-lg-12 mt-5 card">
                        <div style={{
                            width: "70rem"
                        }}>
                            <div style={{
                                width: "100rem",
                                padding: "10px"

                            }}>
                                <AWVServerSideGridTableHCC
                                    title={'HCC Capture Record'}
                                    data={formDataList}
                                    columns={formDataColumns}
                                    filter={false}
                                    search={false}
                                    pagination={true}
                                ></AWVServerSideGridTableHCC>
                            </div>
                        </div>
                    </div>
                    <div className="pagination_page" style={{ display: "flex", width: "100%", flexDirection: "row-reverse" }}>
                        {/* scrapping the pagination from mui table */}
                    </div>
                </div >
                :
                <div className="row pr-0 pl-0 mt-3">
                    <div className="col-lg-12" style={{ position: 'absolute', zIndex: '1', top: '200px', left: '76.5%', borderRadius: "5px" }}>
                        {/* <Button onClick={()=>handleDownloadFromS3(process.env.REACT_APP_AWE_BATCH_UPLOAD_BUcket,process.env.REACT_APP_AWE_BUCKET_TEMPLATE)} style={{ position: 'absolute', color: 'white', padding: '9px 25px', fontFamily: "'Source Sans Pro', sans-serif", backgroundColor: "rgb(46 147 147)" }} >
                            Download batch template
                        </Button> */}
                        <DownloadFileFromS3
                            name={"Download batch template"}
                            bucketName={process.env.REACT_APP_AWE_BATCH_UPLOAD_BUcket}
                            bucketKey={process.env.REACT_APP_AWE_BUCKET_TEMPLATE}
                            isBatchTemplate={true}

                        />
                        <br />
                    </div>
                    <br />
                    <div></div>
                    <div className="card col-lg-12 pr-1 pl-1 reporting-member-details-awv" style={{ position: "absolute", minHeight: isDataGridLoading ? "200px" : formDataList && formDataList.length > 0 ? "30px" : "0px" }}>
                        {/* <AWVExpandGridTable
                            title={''}
                            data={memberDetailsData}
                            columns={memberDetailsColumns}
                            filter={true}
                            search={false}
                            pagination={true}
                            isLoading={isDataGridLoading}
                            subColumns={memberDetailsSubColumns}
                            editActionData={editLoadFormData}
                            viewColumns={true}
                            colSpan={12}
                        ></AWVExpandGridTable> */}
                        <AWVServerSideGridDataTable
                            title={'AWE Record Details'}
                            url={`/get-awv-member-details?year=` + props.yearSelect + `&organisation_id=1&lob=` + props.lobSelect + `&provider_group=` + providerGroupSelectVar + `&address_grouping=` + props.providerAddressGroupsNameSelect + `&site_zip=` + props.zipSelect + `&site_city=` + props.citySelect + `&site_address=` + props.siteAddressSelect + `&site_id=` + props.siteIdSelect + `&`}
                            columns={memberDetailsColumns}
                            pagination={true}
                            search={false}
                            viewColumns={true}
                            download={false}
                            filter={true}
                            isLoadingGrid={isDataGridCheked}
                            subColumns={memberDetailsSubColumns}
                            editActionData={editHCCDetails}
                            colSpan={11}
                            yearSelect={props.yearSelect}
                            form_url={memberExtraDetailsData.form_url}
                        ></AWVServerSideGridDataTable>
                    </div>
                </div>

            }

        </>
    )
};
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        lobSelect: state.moduleFilter.lobSelect,
        aweSiteSelect: state.moduleFilter.aweSiteSelect,
        aweProviderGroupingSelect: state.moduleFilter.aweProviderGroupingSelect,
        aweCitySelect: state.moduleFilter.aweCitySelect,
        aweAddressSelect: state.moduleFilter.aweAddressSelect,
        aweZipSelect: state.moduleFilter.aweZipSelect,
        aweRightSidebarCloseDatatableReload: state.moduleFilter.aweRightSidebarCloseDatatableReload,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(MemberDetailsReport);