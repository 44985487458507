import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/layouts/adminStyle.js";
import bgImage from "../../assets/images/sidebar.jpeg";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components

// core components
//import Navbar from "../Navbars/Navbar.js";
//import Footer from  "../Footer/Footer.js"
import Sidebar from "../Sidebar/Sidebar.js";
//import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import routes from "../../routes/routes.js";
import logo from "../../assets/images/logo.png";

import ReportedHccSummaryACA from '../../Pages/ACA/ACAreportedHccSummary.page'
import ReportedMemberSummaryACA from "../../Pages/ACA/ACAreportedMemberSummary.page";
import ACAChartReviewHccSummary from '../../Pages/ACA/ACAChartReviewHCCSummary.page';
import MetricsACA from "../../Pages/ACA/ACAmetrics.page";
import RarsdComparisonACA from "../../Pages/ACA/ACArarsdComparison.page";
import ConditionCampaignsACA from "../../Pages/ACA/ACAconditionCampaigns.page";
import GapHccSummaryACA from "../../Pages/ACA/ACAgapHccSummary.page";
import GapMemberSummaryACA from "../../Pages/ACA/ACAgapMemberSummary.page";
import ACAGapHcc from "../../Pages/ACA/AcaGapHcc.page";
import IssuersACA from '../../Pages/ACA/ACAIssuers.page.js';
import RarsdDiscrepancyDetailsACA from '../../Pages/ACA/ACARarsdDiscrepancyDetails.page.js';
import RafDrilDownACA from '../../Pages/ACA/ACARafDrillDown.page.js';
import HccDrillDownACA from '../../Pages/ACA/ACAHccDrillDown.page.js';
import ReportedHccACA from '../../Pages/ACA/ACAReportedHcc.page.js';
import MemberListACA from '../../Pages/ACA/ACAMemberList.page.js';
import MemberSummaryACA from '../../Pages/ACA/ACAMemberSummary.page.js';
import RafDrillDownMemberListACA from '../../Pages/ACA/ACARafDrillDownMember.page.js';
import IssuerGapSummaryACA from '../../Pages/ACA/ACAgapIssuerSummary.page.js';
import ACAChartReviewOverallSummaryPage from '../../Pages/ACA/ACAChartReviewOverallSummary.page';
import ACAChartReviewMemberSummaryPage from '../../Pages/ACA/ACAChartReviewMemberSummary.page';
import GapSummaryMembersACA from '../../Pages/ACA/ACAGapSummaryMembers.page.js';
import ACAChartReviewHccAdd from '../../Pages/ACA/ACAChartReviewHccAdd.page';
import ACAChartReviewHccDelete from '../../Pages/ACA/ACAChartReviewHccDelete.page';
import MemberClaimDetailsACA from '../../Pages/ACA/ACAMemberClaimDetails.page.js';
import BigramHccACA from '../../Pages/ACA/ACABigramHcc.page.js';
import TrigramHccACA from '../../Pages/ACA/ACATrigramHcc.page.js';
import TetragramHccACA from '../../Pages/ACA/ACATetragramHcc.page.js';
import ReconSummary from '../../Pages/ACA/ACAReconSummary.page.js';
import ReconHCCSummary from '../../Pages/ACA/ACAReconHCCSummary.page.js';
import ProviderReconSummary from '../../Pages/ACA/ACAProviderReconSummary.page';
import ReconProviderMemberSummary from '../../Pages/ACA/ACAProviderMemberSummary.page';
import ReportGeneration from '../../Pages/Reports/ReportGeneration.page';
import HeatChartDemo from '../../Pages/Reports/HeatChartDemo';
import TreeMapDemo from '../../Pages/Reports/TreeMapDemo';

import ScrollToTop from '../ScrollToTop/ScrollToTop';
import ACACRProviderSummary from '../../Pages/ACA/ACAChartReviewProviders.page';
import ACACRProviderHCCSummary from '../../Pages/ACA/ACACRProvidersHCC.page';
import GapRecoMembersACA from '../../Pages/ACA/ACAReccomandationDrillDown.page';
import { Image } from '@material-ui/icons';
import PCPMemberDetailsACA from '../../Pages/ACA/ACAPCP1.page';
import PCPDetailsACA from '../../Pages/ACA/ACAPCP.page';
import PGPDetailsACA from '../../Pages/ACA/ACAPPGSummary.page';
import PGPDetailsMedicare from '../../Pages/MedicarePage/MedicarePGP.page';

import MaoReportedMemberSummaryPage from '../../Pages/MedicarePage/MaoReportedMemberSummary.page';
import MaoEncounterComparisonPage from '../../Pages/MedicarePage/MaoEncounterComparison.page';
import MaoChartReviewPage from '../../Pages/MedicarePage/MaoChartReview.page';
import MaoEncountersSummaryPage from '../../Pages/MedicarePage/MaoEncountersSummary.page';
import MaoChartReviewOverallSummaryPage from '../../Pages/MedicarePage/MaoChartReviewOverallSummary.page';
import MaoChartReviewMemberSummaryPage from '../../Pages/MedicarePage/MaoChartReviewMemberSummary.page';
import MaoChartReviewProviderSummaryPage from '../../Pages/MedicarePage/MaoChartReviewProviderSummary.page';
import MaoGapMemberSummaryPage from '../../Pages/MedicarePage/MaoGapMemberSummary.page';
import MaoReportedHccSummaryPage from '../../Pages/MedicarePage/MaoReportedHccSummary.page';
import MaoGapHccSummaryPage from '../../Pages/MedicarePage/MaoGapHccSummary.page';
import MaoGapsByPCPPage from '../../Pages/MedicarePage/MaoGapsByPCP.page';
import MaoProviderRetrospectiveGapsPage from '../../Pages/MedicarePage/MaoProviderRetrospectiveGaps.page';
import MaoMemberSummaryPage from '../../Pages/MedicarePage/MaoMemberSummary.page';
import MaoForecastSummaryPage from '../../Pages/MedicarePage/MaoForecastSummary.page';

import MaoRafDrillDown from '../../Pages/MedicarePage/MaoRafDrillDown';
import MaoAllowedDisallowedSummary from '../../Pages/MedicarePage/MaoAllowedDisallowedSummary';
import MaoEncounterMaoComparisonMember from '../../Pages/MedicarePage/MaoEncounterMaoComparisonMember';
import MaoSatisticsChart from '../../Pages/MedicarePage/MaoSatisticsChart';
import MaoDisallowedSummary from '../../Pages/MedicarePage/MaoDisallowedSummary';
import MaoDisallowedClaims from '../../Pages/MedicarePage/MaoDisallowedClaims';
import MaoChartReviewMember from '../../Pages/MedicarePage/MaoChartReviewMember';
import MaoReportedHcc from '../../Pages/MedicarePage/MaoReportedHcc';
import MaoGapHcc from '../../Pages/MedicarePage/MaoGapHcc';
import MaoChartReviewHccAdd from '../../Pages/MedicarePage/MaoChartReviewHccAdd';
import MaoChartReviewHccDelete from '../../Pages/MedicarePage/MaoChartReviewHccDelete';
import MaoMemberSummary from '../../Pages/MedicarePage/MaoMemberSummary';
import MaoChartReviewHccSummaryPage from '../../Pages/MedicarePage/MaoChartReviewHccSummary.page';
import MaoChartReviewProviderAdd from '../../Pages/MedicarePage/MaoChartReviewProviderAdd';
import MaoChartReviewProviderDelete from '../../Pages/MedicarePage/MaoChartReviewProviderDelete';
import MaoForecastSummary from '../../Pages/MedicarePage/MaoForecastSummary';
import MaoDemographicForecast from '../../Pages/MedicarePage/MaoDemographicForecast';
import MaoHCCForecast from '../../Pages/MedicarePage/MaoHCCForecast';
import MaoInteractionForecast from '../../Pages/MedicarePage/MaoInteractionForecast';
import MaoOrecForecast from '../../Pages/MedicarePage/MaoOrecForecast';
import MaoProviderClaimList from '../../Pages/MedicarePage/MaoProviderClaimList';
import MaoSuspectMemberDetails from '../../Pages/MedicarePage/MaoSuspectMemberDetails';
import MaoProviderPCPReco from '../../Pages/MedicarePage/MaoProviderPCPReco';
import MaoHCCRecomendation from '../../Pages/MedicarePage/MaoHCCRecomendation';
import MaoClaim from '../../Pages/MedicarePage/MaoClaim';
import MaoHccCaptureRecoDrillDown from "../../Pages/MedicarePage/MaoHccCaptureRecoDrillDown";
import MaoSuspectLHDrillDown from "../../Pages/MedicarePage/MaoSuspectLHDrillDown";
import MaoHccDrillDown from "../../Pages/MedicarePage/MaoHccDrillDown";
import MaoBlendedModel from "../../Pages/MedicarePage/MaoBlendedModel";
import MemberUtilizationPage from "../../Pages/Utilization/MemberUtilization.page";
import ServiceStatisticsPage from "../../Pages/Utilization/ServiceStatistics.page";
import HighNeedUtilizationPage from "../../Pages/Utilization/HighNeedUtilization.page";
import SUMemWithNoWellness from "../../Pages/Utilization/SUMemWithNoWellness";
import SUReadmissionDetails from "../../Pages/Utilization/SUReadmissionDetails";
import HighNeedUtilizationHcc from "../../Pages/Utilization/HighNeedUtilizationHcc";
import HighCostMembers from "../../Pages/Utilization/HighCostMembers";
import ServiceStatisticsCharts from "../../Pages/Utilization/ServiceStatisticsCharts";
import ConditionalPrediction from "../../Pages/MedicarePage/ConditionalPrediction.page";
import SUServiceUtilization from "../../Pages/Utilization/SUServiceUtilizationSummary";
import ProviderUtilization from "../../Pages/Utilization/ProviderUtilisation";
import ServiceCategory from "../../Pages/Utilization/ServiceCategory";
import ServiceCategoryMember from "../../Pages/Utilization/ServiceCategoryMember";
import SUProviderDetails from '../../Pages/Utilization/SUProviderDetails';
import RangeSliderDemo from "../../Pages/Reports/RangeSliderDemo";
import ConditionalDrillDown from "../../Pages/MedicarePage/ConditionalDrillDown.page";
import HealthcareDashboardPage from "../../Pages/KPI/healthcareDashboard.page";
import RiskStratification from "../../Pages/MedicarePage/RiskStratification.page";
import PredictionMemberSummary from "../../Pages/MedicarePage/PredictionMemberSummary.page";
import PredictionRafDrillDown from "../../Pages/MedicarePage/PredictionRafDrillDown.page";
import PredictionRafDeltaDrillDown from "../../Pages/MedicarePage/PredictionRafDeltaDrillDown.page";
import PredictionHccDrillDown from "../../Pages/MedicarePage/PredictionHccDrillDown.page";
import PCPDetailsMedicare from '../../Pages/MedicarePage/MedicarePCP.page';
import Header from '../../components/home/header/header';
import QSDashboardMedicareMemberDetails from '../../Pages/MedicarePage/QSDashboardMedicareMemberDetails';
import QSDashboardMedicarePpgMetrics from '../../Pages/MedicarePage/QSDashboardMedicarePpgMetrics';

const useStyles = makeStyles(styles);

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/layout") {
        // // console.log('Paths layout',prop.layout + prop.path);
        return (
          <>
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
            {
              prop.subRoutes && prop.subRoutes.length > 0 ?
                prop.subRoutes.map((subRoute, key1) => {
                  <Route
                    path={subRoute.layout + subRoute.path}
                    component={subRoute.component}
                    key={key1}
                  />
                })
                : ''
            }
          </>
        );
      }
      return null;
    })}
    <Redirect from="/layout" to="/layout/dashboard" />
  </Switch>
);

let ps;
export default function Layout() {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  //const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  React.useEffect(() => {
    document.getElementsByTagName("body")[0].classList.add("normal-layout-container")
    document.getElementsByTagName("body")[0].classList.remove("awe-layout-container")
  }, [])
  //console.log("code-material-ui-image", Image);
  const handleImageClick = image => {
    setImage(image);
  };
  // const handleColorClick = color => {
  //   setColor(color);
  // };
  // const handleFixedClick = () => {
  //   if (fixedClasses === "dropdown") {
  //     setFixedClasses("dropdown show");
  //   } else {
  //     setFixedClasses("dropdown");
  //   }
  // };

  const handleDrawerToggle = () => {
    // console.log("code-layout");
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  const getMenuPermissionCheck = (permissionSlug) => {
    var permissionCheck = false;
    if (permissionSlug && permissionSlug != '') {
      var permissionGets = JSON.parse(localStorage.getItem('controlPermissions'));
      if (permissionGets && permissionGets.length > 0) {
        permissionGets && permissionGets.forEach(elem => {
          if (elem.permission == permissionSlug && elem.status == true) {
            permissionCheck = true;
          }
        });
      }
    }
    return permissionCheck;
  }

  return (
    <div className="App main-layout-background">
      <Header></Header>
      <div className="router-box">
        <div className={classes.wrapper}>
          <Sidebar
            routes={routes}
            logo={logo}
            image={image}
            handleDrawerToggle={handleDrawerToggle}
            open={mobileOpen}
            color={color}
          />
          <div className={classes.mainPanel} ref={mainPanel} id="headerTop">
            <ScrollToTop>
              <div className={classes.content}>
                <div className={classes.container}>


                  {getMenuPermissionCheck('reported_member_summary_1') ?
                    <Route path="/layout/acaReportedMemberSummary" component={ReportedMemberSummaryACA} />
                    :
                    <></>
                  }


                  {/* <Route path="/layout/acaReportedMemberSummary" component={ReportedMemberSummaryACA} /> */}
                  <Route path="/layout/acaReportedHccSummary" component={ReportedHccSummaryACA} />
                  <Route path="/layout/acaGapMemberSummary" component={GapMemberSummaryACA} />
                  <Route path="/layout/acaGapHccSummary" component={GapHccSummaryACA} />
                  <Route path="/layout/acaCampaign" component={ConditionCampaignsACA} />
                  <Route path="/layout/acaRarsdAnalysis" component={RarsdComparisonACA} />
                  <Route path="/layout/acaMetrics" component={MetricsACA} />
                  <Route path="/layout/AcaGapHcc" component={ACAGapHcc} />
                  <Route path="/layout/acaissuer" component={IssuersACA} />
                  <Route path="/layout/acaMemberClaimDetails" component={MemberClaimDetailsACA} />
                  <Route path="/layout/acaRARsdDiscrepancyDetails" component={RarsdDiscrepancyDetailsACA} />
                  <Route path="/layout/acaRafDrilldown" component={RafDrilDownACA} />
                  <Route path="/layout/acaHccDrillDown" component={HccDrillDownACA} />
                  <Route path="/layout/acaReportedHcc" component={ReportedHccACA} />
                  <Route path="/layout/acaMemberDetails" component={MemberListACA} />
                  <Route path="/layout/acaMember" component={MemberSummaryACA} />
                  <Route path="/layout/acaRafDrillDownMember" component={RafDrillDownMemberListACA} />
                  <Route path="/layout/acaIssuerGapsummary" component={IssuerGapSummaryACA} />
                  <Route path="/layout/acaCROverallSummary" component={ACAChartReviewOverallSummaryPage} />
                  <Route path="/layout/acaCRHCCSummary" component={ACAChartReviewHccSummary} />
                  <Route path="/layout/acaGapSummaryMembers" component={GapSummaryMembersACA} />
                  <Route path="/layout/acaCRMEMBERSummary" component={ACAChartReviewMemberSummaryPage} />
                  <Route path="/layout/acaCRhccAdd" component={ACAChartReviewHccAdd} />
                  <Route path="/layout/acaCRhccDelete" component={ACAChartReviewHccDelete} />
                  <Route path="/layout/acaBigramHcc" component={BigramHccACA} />
                  <Route path="/layout/acaTrigramHcc" component={TrigramHccACA} />
                  <Route path="/layout/acaTetragramHcc" component={TetragramHccACA} />

                  <Route path="/layout/acaReconciliationSummary" component={ReconSummary} />
                  <Route path="/layout/acaReconHCCSummary" component={ReconHCCSummary} />
                  <Route path="/layout/acaProviderSummary" component={ProviderReconSummary} />
                  <Route path="/layout/acaProviderMemberSummary" component={ReconProviderMemberSummary} />
                  <Route path="/layout/acaProviders" component={ACACRProviderSummary} />
                  <Route path="/layout/acaProvidersHcc" component={ACACRProviderHCCSummary} />
                  <Route path="/layout/reportgeneration" component={ReportGeneration} />
                  <Route path="/layout/heatchartdemo" component={HeatChartDemo} />
                  <Route path="/layout/treemapdemo" component={TreeMapDemo} />
                  <Route path="/layout/recoDrillDown" component={GapRecoMembersACA} />
                  <Route path="/layout/pcpDetails" component={PCPDetailsACA} />
                  <Route path="/layout/pcpMemberDetails" component={PCPMemberDetailsACA} />
                  <Route path="/layout/pgpDetails" component={PGPDetailsACA} />
                  <Route path="/layout/overall_summary" component={ACAChartReviewOverallSummaryPage} />
                  <Route path="/layout/member_summary" component={ACAChartReviewMemberSummaryPage} />


                  <Route path="/layout/medicare/raf_drill_down" component={MaoRafDrillDown} />
                  <Route path="/layout/medicare/hcc_drill_down" component={MaoHccDrillDown} />
                  <Route path="/layout/medicare/member_reference" component={MaoBlendedModel} />
                  <Route path="/layout/medicare/allowed_disallowed_summary" component={MaoAllowedDisallowedSummary} />
                  <Route path="/layout/medicare/encounter_comparison_member" component={MaoEncounterMaoComparisonMember} />
                  <Route path="/layout/medicare/satistics_chart" component={MaoSatisticsChart} />
                  <Route path="/layout/medicare/disallowed_summary" component={MaoDisallowedSummary} />
                  <Route path="/layout/medicare/disallowed_claims" component={MaoDisallowedClaims} />
                  <Route path="/layout/medicare/chart_review_member" component={MaoChartReviewMember} />
                  <Route path="/layout/medicare/gap_hcc" component={MaoGapHcc} />
                  <Route path="/layout/medicare/reported_hcc" component={MaoReportedHcc} />
                  <Route path="/layout/medicare/chart_review_hcc_add" component={MaoChartReviewHccAdd} />
                  <Route path="/layout/medicare/chart_review_hcc_delete" component={MaoChartReviewHccDelete} />
                  <Route path="/layout/medicare/mao_member_summary" component={MaoMemberSummary} />
                  <Route path="/layout/medicare/chart_review_provider_add" component={MaoChartReviewProviderAdd} />
                  <Route path="/layout/medicare/chart_review_provider_delete" component={MaoChartReviewProviderDelete} />
                  <Route path="/layout/medicare/member_forecast" component={MaoForecastSummary} />
                  <Route path="/layout/medicare/demographic_member_forecast" component={MaoDemographicForecast} />
                  <Route path="/layout/medicare/hcc_member_forecast" component={MaoHCCForecast} />
                  <Route path="/layout/medicare/interaction_member_forecast" component={MaoInteractionForecast} />
                  <Route path="/layout/medicare/orec_member_forecast" component={MaoOrecForecast} />
                  <Route path="/layout/medicare/provider_claim_list" component={MaoProviderClaimList} />
                  <Route path="/layout/medicare/suspect_member_list" component={MaoSuspectMemberDetails} />
                  <Route path="/layout/medicare/provider_pcp_summary" component={MaoProviderPCPReco} />
                  <Route path="/layout/medicare/gap_hcc_recomendation" component={MaoHCCRecomendation} />
                  <Route path="/layout/medicare/claim" component={MaoClaim} />

                  {/* medicare pages */}
                  {getMenuPermissionCheck('medicare_insights_2') ?
                    <Route path="/layout/medicare/reported_member_summary" component={MaoReportedMemberSummaryPage} />
                    :
                    <></>
                  }
                  {/* <Route path="/layout/medicare/reported_member_summary" component={MaoReportedMemberSummaryPage} /> */}
                  <Route path="/layout/medicare/encounter_comparison" component={MaoEncounterComparisonPage} />
                  <Route path="/layout/medicare/chart_review" component={MaoChartReviewPage} />
                  <Route path="/layout/medicare/encounters_summary" component={MaoEncountersSummaryPage} />
                  <Route path="/layout/medicare/overall_summary" component={MaoChartReviewOverallSummaryPage} />
                  <Route path="/layout/medicare/review_member_summary" component={MaoChartReviewMemberSummaryPage} />
                  <Route path="/layout/medicare/provider_summary" component={MaoChartReviewProviderSummaryPage} />
                  <Route path="/layout/medicare/reported_hcc_summary" component={MaoReportedHccSummaryPage} />
                  <Route path="/layout/medicare/gap_member_summary" component={MaoGapMemberSummaryPage} />
                  <Route path="/layout/medicare/gap_hcc_summary" component={MaoGapHccSummaryPage} />
                  <Route path="/layout/medicare/gap_by_pcp" component={MaoGapsByPCPPage} />
                  <Route path="/layout/medicare/provider_retrospective_gaps" component={MaoProviderRetrospectiveGapsPage} />
                  <Route path="/layout/medicare/review_hcc_summary" component={MaoChartReviewHccSummaryPage} />
                  <Route path="/layout/medicare/member_summary" component={MaoMemberSummaryPage} />
                  <Route path="/layout/medicare/forecast_summary" component={MaoForecastSummaryPage} />
                  <Route path="/layout/medicare/hcc_capture_reco_drill" component={MaoHccCaptureRecoDrillDown} />
                  <Route path="/layout/medicare/member_utilization" component={MemberUtilizationPage} />
                  <Route path="/layout/medicare/service_statistics" component={ServiceStatisticsPage} />
                  <Route path="/layout/medicare/high_need_utilization" component={HighNeedUtilizationPage} />
                  <Route path="/layout/medicare/sumem_with_wellness" component={SUMemWithNoWellness} />
                  <Route path="/layout/medicare/sure_admission_details" component={SUReadmissionDetails} />
                  <Route path="/layout/medicare/high_need_hcc_utilization" component={HighNeedUtilizationHcc} />
                  <Route path="/layout/medicare/high_cost_member" component={HighCostMembers} />
                  <Route path="/layout/medicare/service_statistics_charts" component={ServiceStatisticsCharts} />
                  <Route path="/layout/medicare/suspect_hcc_drill_down" component={MaoSuspectLHDrillDown} />

                  <Route path="/layout/medicare/ConditionPredictor" component={ConditionalPrediction} />
                  <Route path="/layout/medicare/ConditionalDrillDown" component={ConditionalDrillDown} />
                  <Route path="/layout/medicare/RiskStratification" component={RiskStratification} />
                  <Route path="/layout/medicare/PredictionMemberSummary" component={PredictionMemberSummary} />
                  <Route path="/layout/medicare/PredictionRafDrillDown" component={PredictionRafDrillDown} />
                  <Route path="/layout/medicare/PredictionHccDrillDown" component={PredictionHccDrillDown} />
                  <Route path="/layout/medicare/PredictionRafDeltaDrillDown" component={PredictionRafDeltaDrillDown} />
                  <Route path="/layout/medicare/SUServiceUtilization" component={SUServiceUtilization} />
                  <Route path="/layout/medicare/ProviderUtilization" component={ProviderUtilization} />
                  <Route path="/layout/medicare/pgpDetails" component={PGPDetailsMedicare} />
                  <Route path="/layout/medicare/pcpDetails" component={PCPDetailsMedicare} />
                  <Route path="/layout/ServiceCategory" component={ServiceCategory} />
                  <Route path="/layout/SUProviderDetails" component={SUProviderDetails} />

                  <Route path="/layout/healthcare/dashboard" component={HealthcareDashboardPage} />
                  <Route path="/layout/rangechart" component={RangeSliderDemo} />
                  <Route path="/layout/medicare/qs_member_details" component={QSDashboardMedicareMemberDetails} />
                  <Route path="/layout/medicare/qs_ppg_metrics" component={QSDashboardMedicarePpgMetrics} />

                </div>
              </div>
            </ScrollToTop>
            {/*{getRoute() ? <Footer /> : null}
           <FixedPlugin
            handleImageClick={handleImageClick}
            handleColorClick={handleColorClick}
            bgColor={color}
            bgImage={image}
            handleFixedClick={handleFixedClick}
            fixedClasses={fixedClasses}
          /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
