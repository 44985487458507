import AcaYearSelect from "../../components/DropDowns/AcaYearSelect"
import React, { useState, useEffect } from "react";
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import api from '../../assets/constants/EncounterSummaryAPI.instance';
import { getAcaMetricsIssuer } from "../../assets/constants/issuerList";
import CardComponent from "../../components/Cards/card";
import ExpandableRow from "./EncounterSummaryExpand.page";
import Dropdown from "../../components/DropDowns/dropDown";
import ServerSideGridTable from "../../components/TableGrids/DynamicColumnwithData.table";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../components/Tab/TabPanel"; import {
    METRIC_GRID,
    METRIC_EXPAND,
} from "../../assets/constants/string.constants";
import { connect } from 'react-redux';

const MetricsACA = (props) => {

    const [year, setYear] = useState(props.yearSelect);
    const [tableData, setTableData] = useState([]);
    const [metricsIssuer, setMetricsIssuer] = useState(getAcaMetricsIssuer());
    const [ppgData, setPPGData] = useState([]);
    const [ppg, setPPG] = useState(ppgData[0]);
    const [value, setValue] = useState(METRIC_GRID);
    const [gridData, setGridData] = useState([]);
    const [columnName, setColumnName] = useState([]);
    const [count, setCount] = useState(1);
    const [currentDate, setCurrentDate] = useState('');

    const Columns = [
        {
            name: "METRIC_NAME",
            label: "METRIC"
        },
    ]

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const loadMetricTable = () => {
        api.get('/metrics?year=' + year)
            .then(res => {
                setTableData(res.data);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const loadMetricGrid = () => {
        api.get('/overall_data?ppg_group=' + ppg + '&year=' + year)
            .then(res => {
                if (JSON.stringify(res).includes('errorMessage') || res.data == null) {
                }
                else {
                    setGridData(res.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    function a11yProps(index) {
        return {
            id: `wrapped-tab-${index}`,
            "aria-controls": `wrapped-tabpanel-${index}`,
        };
    }
    const loadColumn = () => {
        let values = [];

        gridData.forEach((element, index) => {
            for (let [key, value] of Object.entries(element)) {
                if (!values.includes(key)) {
                    values.push(key)
                }
            }
        });

        setColumnName(values)

    }

    const loadPPGGroup = () => {
        let values = [];
        api.get('/ppg_group?ppg_group=&year=' + year)
            .then(res => {
                // fetching only value from response and pushing to add array
                if (JSON.stringify(res.data).includes('errorMessage') || res.data == null) {
                }
                else {
                    res.data.forEach((element, index) => {
                        for (let [key, value] of Object.entries(element)) {
                            values.push(value)
                        }
                    })
                }
                setPPGData(values)
                setPPG(values[0])
            })
            .catch(err => {
                console.log(err);
            })
    }

    const Calculate = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        setCurrentDate('EncounterSummary_' + mm + dd + yyyy)
    }

    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            loadMetricGrid();
            Calculate()
        }
    }, [props.yearSelect, ppg, year])

    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            loadMetricTable();
            setMetricsIssuer(getAcaMetricsIssuer())
        }
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect,
        metricsIssuer
    ]);

    useEffect(() => {
        loadPPGGroup();
    }, [year])

    useEffect(() => {
        loadColumn();
    }, [gridData]);

    /**
    * PD-61 
    * description : Changes for Expand row for PPG and metric and changes for PPG group, year filter 
    */

    return (
        <div className="level-summary">
            <div className="row pb-2">
                <div className="col-lg-12">
                    <div className="state-level">
                        <div className="card-header">
                            <span className="d-flex bd-highlight">
                                <h6 className="pt-2 flex-grow-1 header-title-text d-flex align-items-center">ACA Metrics</h6>
                                <div className="d-flex align-items-center">
                                    <label className="pr-2 pb-0 mb-0">Year</label>
                                    <AcaYearSelect
                                        AcaYears={getAcaYearList()}
                                        year={year}
                                        onAcaYearSelect={((e) => setYear(e))}>
                                    </AcaYearSelect>
                                </div>
                                <div className="d-flex align-items-center" style={{ 'paddingLeft': '1%' }}>
                                    <label className="pr-2 pb-0 mb-0">Provider Group</label>
                                    <Dropdown
                                        list={ppgData}
                                        value={ppg}
                                        onSelect={((e) => setPPG(e))}></Dropdown>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="pb-5">
                <AppBar position="static" color="default">
                    <Tabs
                        variant="fullWidth"
                        textColor="primary"
                        indicatorColor="primary"
                        value={value}
                        onChange={handleChange}
                        centered
                    >
                        <Tab
                            value={METRIC_GRID}
                            label="ACA metric"
                            {...a11yProps(METRIC_GRID)}
                        />
                        <Tab
                            value={METRIC_EXPAND}
                            label="ACA Metric Drill Down"
                            {...a11yProps(METRIC_EXPAND)}
                        />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={METRIC_GRID}>
                    <CardComponent
                        heading={`ACA KEY METRICS`}
                        body={
                            <ServerSideGridTable
                                data={gridData}
                                columns={columnName}
                                className={'Dynamic_detail_table'}
                                fileName={currentDate}
                            ></ServerSideGridTable>
                        }></CardComponent>

                    <br />
                </TabPanel>
                <TabPanel value={value} index={METRIC_EXPAND}>

                    <div className="pb-5 aca-metrics">
                        <CardComponent
                            heading={`ACA KEY METRICS Drilldown`}
                            className="issuer-details"
                            body={
                                <ExpandableRow
                                    data={tableData}
                                    columns={Columns}
                                    pagination={true}
                                    ppg={ppg}
                                    year={year}
                                ></ExpandableRow>
                            }></CardComponent>

                    </div>
                    <br />
                    <br />
                    <br />
                </TabPanel>

            </div>

            <br />
            <br />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
    };
}
export default connect(mapStateToProps)(MetricsACA);
