import React, { useRef, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../../store/actions';
import AWVCoderWorkBench from '../../../assets/constants/AWVCoderWorkBenchRafservice.Instance';
import Divider from '@mui/material/Divider';
import Moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import { CircularProgress } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { styled } from '@mui/material/styles';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import IconButton from "../../layout/aweLayout/IconButton";
import Button from "../../layout/aweLayout/Button";
import CloseIcon from "../../icons/CloseIcon";

const EditMEDICATIONCoderWorkBench = (props) => {
    const { register, formState: { errors }, trigger, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [formDataList, setFormDataList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
    const [editFormData, setEditFormData] = useState({});
    const [dxCodeDOSBegin, setDxCodeDOSBegin] = useState();
    useEffect(() => {
        console.log(errors);
    }, [errors]);

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const cin = urlParams.get('cin');
        setValue('ih_member_id', cin);
    }, []);

    useEffect(() => {
        if (props?.Id) {
            loadformDataTable(props.Id);
            editLoadFormData(props.Id);
        }
    }, [props?.Id]);

    useEffect(() => {
        if (props?.Id && editFormData?.id && editFormData?.id != '') {
            setValue('id', editFormData?.id);
            setValue('page_no', editFormData?.page_no);
            setValue('entiy_id', editFormData?.entiy_id);
            setValue('entiy_text', editFormData?.entiy_text);
            setValue('remarks', editFormData?.remarks);
            setValue('status', editFormData?.status);
            setValue('ih_member_id', editFormData?.ih_member_id);
            setValue('dos_start', Moment(editFormData?.dos_start).format("YYYY-MM-DD"));
            setValue('dos_end', Moment(editFormData?.dos_end).format("YYYY-MM-DD"));
            setDxCodeDOSBegin(Moment(editFormData?.dos_start).format("YYYY-MM-DD"));
        }
    }, [editFormData]);

    const notificationRightDrawer = (open, valueId = '') => {
        props.currentAWERightSidebarType({ 'type': 'notification' });
        props.currentAWERightSidebar(open);
    };

    const editLoadFormData = (id) => {
        setIsLoading(true);
        AWVCoderWorkBench.get('data/medication/' + id)
            .then(res => {
                console.log(res.data);
                if (res.data && res.data) {
                    setEditFormData(res.data);
                    setIsLoading(false);
                } else {
                    setEditFormData();
                    setIsLoading(false);
                }
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
            })
    }

    const loadformDataTable = (id) => {
        setIsLoading(true);
        AWVCoderWorkBench.get('data/cwb/history/medication/' + id) //'&provider_group=' + props?.providerGroupSelect
            .then(res => {
                console.log(res.data);
                setFormDataList(res.data);
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    }


    const onSubmit = (data) => {
        let controlUser = JSON.parse(localStorage.getItem('user'));
        //setValue('updated_on', Moment().format('YYYY-MM-DD HH:mm:ss'));
        data.updated_by = controlUser?.userEmail;
        console.log(data);
        if (props?.Id) {
            var apiUrl = 'data/medication/' + props.Id;
        } else {
            var apiUrl = 'data/medication/save';
            //setValue('created_on', Moment().format('YYYY-MM-DD HH:mm:ss'));
            data.created_by = controlUser?.userEmail;
        }
        data.page_no = data.page_no ? data.page_no : "";
        data.entiy_id = data.entiy_id ? data.entiy_id : "";
        data.entiy_text = data.entiy_text ? data.entiy_text : "";
        data.dos_start = Moment(data.dos_start).format("YYYY-MM-DD");
        data.dos_end = Moment(data.dos_end).format("YYYY-MM-DD");
        setFormSubmitisLoading(true);
        AWVCoderWorkBench.post(apiUrl, data)
            .then(res => {
                setValue('id', '');
                setValue('page_no', '');
                setValue('entiy_id', '');
                setValue('entiy_text', '');
                setValue('dos_start', '');
                setValue('dos_end', '');
                setValue('updated_on', '');
                setValue('updated_by', '');
                setFormSubmitisLoading(false);
                if (props?.Id) {
                    toast.success('Updated successfully');
                } else {
                    toast.success('Created successfully');
                }
                props.currentAWERightSidebarCloseDatatableReload(true);
                notificationRightDrawer(false);
            })
            .catch(err => {
                setFormSubmitisLoading(false);
                toast.error(err?.response?.data?.message);
            })
    }

    const handleChangeDate = (event) => {
        if (event?.target?.name == 'dos_start') {
            setValue('dos_start', event?.target?.value);
            setValue('dos_end', event?.target?.value);
            setDxCodeDOSBegin(event?.target?.value);
            handleManualValidation();
        }
    }
    const handleManualValidation = async () => {
        try {
          // Trigger manual revalidation for all fields
          await trigger();
        } catch (error) {
          //console.error('Manual validation error:', error);
        }
      };
    return (
        <div className="diagnosis_code_sidebar offcanvas offcanvas-end show" tabIndex={-1} aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header flex_item_cb">
                <h5 id="offcanvasRightLabel" className='fs15'>{(props?.Id) ? 'Edit' : 'Add'}  MEDICATION</h5>
                <Tooltip title="Close">
                    <IconButton type="button" buttonType={"default"} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => notificationRightDrawer(false)}>
                        <CloseIcon className="w24" />
                    </IconButton>
                </Tooltip>
            </div>
            <Divider />
            <div className="offcanvas-body pt-1 custom-scrollbar overflow-auto">
                <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">MEMBER ID</label>
                        <Controller
                            name="ih_member_id"
                            control={control}
                            render={({ field }) => (
                                <input type="text" {...field} className="form-control" readOnly />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.ih_member_id?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>

                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">DOS START</label>
                        <Controller
                            name="dos_start"
                            control={control}
                            render={({ field }) => (
                                <input type="date" className="form-control" {...field} onChange={handleChangeDate} />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.dos_start?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>

                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">DOS END</label>
                        <Controller
                            name="dos_end"
                            control={control}
                            render={({ field }) => (
                                <input type="date" {...field} className="form-control" min={Moment(dxCodeDOSBegin).format("YYYY-MM-DD")} />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.dos_end?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Status</label>
                        <Controller
                            name="status"
                            control={control}
                            render={({ field }) => (
                                <select
                                    {...field}
                                    className="form-control"
                                >
                                    <option value={''} >
                                        Select
                                    </option>
                                    <option value={'Accepted'} >
                                        Accepted
                                    </option>
                                    <option value={'Rejected'} >
                                        Rejected
                                    </option>
                                    <option value={'Pending for clarification'} >
                                        Pending for Clarification
                                    </option>
                                    <option value={'No Action'} >
                                        No Action
                                    </option>
                                </select>
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.status?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">REMARK</label>
                        <Controller
                            name="remarks"
                            control={control}
                            render={({ field }) => (
                                <textarea rows={3} {...field} className="form-control" />
                            )}
                            rules={{
                                required: false,
                            }}
                        />
                        {errors?.remarks?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group flex_item_cs gap10">
                        <Button type='button' className={"btn-white minw80"} onClick={() => notificationRightDrawer(false)}>CANCEL</Button>
                        <Button type='submit' className={"minw80"} disabled={formSubmitisLoading && formSubmitisLoading == true ? true : false}>SAVE</Button>
                    </div>
                </form>
                {isLoading ?
                    <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        {formDataList && formDataList.length > 0 ?
                            <div className="">
                                <h5 className="fs18 mb10">History</h5>
                                <Divider />
                                <div className="history_card_list">
                                    {formDataList && formDataList.map((element, index) =>
                                        <div key={index} className={
                                            element.form_status === 'Accepted' ? 'history_card_item status-primary' :
                                                element.form_status === 'Rejected' ? 'history_card_item status-danger' :
                                                    element.form_status === 'No Action' ? 'history_card_item status-light' :
                                                        element.form_status === 'Pending for clarification' ? 'history_card_item status-warning' :
                                                            'history_card_item status-primary'
                                        } >
                                            <p className="fs12 mb-1 fw700">
                                                {Moment(element.created_on).format('h:mm a, MMMM DD YYYY')}
                                            </p>
                                            <p className="fs12 mb-1">
                                                <span className="fw700">Status :-</span>
                                                {element.form_status}
                                            </p>
                                            <p className="fs12 mb-1">
                                                <span className="fw700">Remarks :-</span>
                                                {element.remarks}
                                            </p>
                                            <p className="fs12 mb-1">
                                                <span className="fw700">Last updated by :-</span>
                                                {element.user_id}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            :
                            <></>
                        }
                    </>
                }
            </div>

        </div >
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(EditMEDICATIONCoderWorkBench);

