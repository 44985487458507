import React, { useRef, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar } from '../../../store/actions';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from "../../layout/aweLayout/IconButton";
import Button from "../../layout/aweLayout/Button";
import CloseIcon from "../../icons/CloseIcon";
import PlusFileIcon from '../../icons/PlusFileIcon';

const NotificationAWE = (props) => {

    const notificationRightDrawer = (open, valueId = '') => {
        props.currentAWERightSidebarType({ 'type': 'notification' });
        props.currentAWERightSidebar(open);
    };

    return (
        <div className="diagnosis_code_sidebar offcanvas offcanvas-end show" tabIndex={-1} aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header flex_item_cb">
                <h5 id="offcanvasRightLabel" className='fs15'>
                    Notification
                </h5>
                <Tooltip title="Close">
                    <IconButton type="button" buttonType={"default"} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => notificationRightDrawer(false)}>
                        <CloseIcon className="w24" />
                    </IconButton>
                </Tooltip>
            </div>
            <Divider />
            <div className="offcanvas-body pt-1 custom-scrollbar overflow-auto">
                <div>
                    <ul class="">
                        {/* <li className='border-bottom pb-3 mb-3'>
                            <h6 className='fs16 fw700 text-light-dark-800 mb-1'>Lorem ipsum dolor sit adipisicing elit. Provident, qui?</h6>
                            <p className='fs14 fw400 text-light-dark-800'>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
                        </li>
                        <li className='border-bottom pb-3 mb-3'>
                            <h6 className='fs16 fw700 text-light-dark-800 mb-1'>Lorem ipsum dolor sit adipisicing elit. Provident, qui?</h6>
                            <p className='fs14 fw400 text-light-dark-800'>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
                        </li>
                        <li className='border-bottom pb-3 mb-3'>
                            <h6 className='fs16 fw700 text-light-dark-800 mb-1'>Lorem ipsum dolor sit adipisicing elit. Provident, qui?</h6>
                            <p className='fs14 fw400 text-light-dark-800'>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
                        </li>
                        <li className='border-bottom pb-3 mb-3'>
                            <h6 className='fs16 fw700 text-light-dark-800 mb-1'>Lorem ipsum dolor sit adipisicing elit. Provident, qui?</h6>
                            <p className='fs14 fw400 text-light-dark-800'>Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
                        </li> */}
                    </ul>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        userName: state.auth?.userDetails?.userEmail,
        route: state.route.route,
        moduleSelect: state.moduleFilter.moduleSelect,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar })(NotificationAWE);