import React, { useState, useEffect } from "react";
import AWVApi from '../../assets/constants/AWVRafservice.Instance';
import { currentRoute, currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../store/actions';
import AWVExpandGridTable from "../../components/TableGrids/AWVExpandGridTable.table";
import AWVServerSideGridDataTable from "../../components/TableGrids/AWVServerSideGridDataTable.table";
import { HCC, ISSUER_ID } from "../../assets/constants/string.constants";
import AwvCardComponent from "../../components/Cards/AwvCard";
import { connect } from 'react-redux';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import {
    createMuiTheme,
    MuiThemeProvider,
} from "@material-ui/core/styles";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import Tooltip from '@mui/material/Tooltip';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { purple, red } from '@mui/material/colors';
import AwvSubHeader from '../../components/home/header/AwvSubHeader';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FolderIcon from '@mui/icons-material/Folder';
import AddAWVFileUpload from '../../components/Dialog/AddAWVFileUpload';
import ViewAWVFileUpload from '../../components/Dialog/ViewAWVFileUpload';
import StatusAWVFileUpload from '../../components/Dialog/StatusAWVFileUpload';
import AWVCircularSlider from '../../components/SlickSlider/AWVCircularSlider';
import Swal from 'sweetalert2';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import history from "../../history";
import AwvTabHeader from '../../components/home/header/AwvTabHeader';
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { DriveEtaSharp } from "@material-ui/icons";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import Moment from 'moment';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import FlagIcon from '@mui/icons-material/Flag';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import { FormGroup, FormLabel, TextField, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';


const UploadAWVForm = (props) => {

    const [formDataList, setFormDataList] = useState([]);
    const [circularDataList, setCircularDataList] = useState({});
    const [year, setYear] = useState(props.yearSelect);
    const [openEdit, setOpenEdit] = useState(false);
    const [openView, setOpenView] = useState(false);
    const [openStatus, setOpenStatus] = useState(false);
    const [modelStatus, setModelStatus] = useState(false);
    const [modelFormStatus, setModelFormStatus] = useState(0);
    const [modelFormRole, setModelFormRole] = useState(0);
    const [percentage, setPercentage] = useState(20);
    const [editFormData, setEditFormData] = useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElFirst, setAnchorElFirst] = React.useState(null);
    const [selectOnHoldStatus, setSelectOnHoldStatus] = React.useState('N');
    const openAction = Boolean(anchorEl);
    const openActionFirst = Boolean(anchorElFirst);
    const [formAttached, setFormAttached] = useState([]);
    const [formDataFilter, setFormDataFilter] = useState(false);
    const [currentActionAWVID, setCurrentActionAWVID] = useState('');
    const [currentActionUserId, setCurrentActionUserId] = useState('');
    const [currentActionStatus, setCurrentActionStatus] = useState('');
    const [nextActionStatus, setNextActionStatus] = useState('');
    const [formWithoutAtachmentTotal, setFormWithoutAtachmentTotal] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isDataGridLoading, setIsDataGridLoading] = useState(false);
    const [isDataGridCheked, setIsDataGridCheked] = useState(false);
    const [isControlUser] = useState(JSON.parse(localStorage.getItem('controlUser')));
    const [formAttachedFlag, setFormAttachedFlag] = useState([0]);
    // for setting form assigned value for assign to me filter
    const [formAssignedFlag, setFormAssignedFlag] = useState('');
    const providerGroup = [
        "ALL", "HCPM", "APPL", "PIPA", "HCLA", "SVIP", "AMHS", "LAKE", "REMG", "PROS", "CFCA", "PROF", "APCV", "APIA", "APPL", "GCMG", "PPN", "BVMG", "DPPG"
    ];
    const [siteIdSelect, setsiteIdSelect] = useState('');
    const [providerAddressGroupsSelect, setProviderAddressGroupsSelect] = useState([]);
    const [providerAddressGroupsNameSelect, setProviderAddressGroupsNameSelect] = useState([]);
    const [citySelect, setCitySelect] = useState('');
    const [siteAddressSelect, setSiteAddressSelect] = useState('');
    const [zipSelect, setZipSelect] = useState('');
    const [filterDateRange, setFilterDateRange] = useState({ startDate: null, endDate: null });
    const [dateRangeFilter, setDateRangeFilter] = useState({ startDate: null, endDate: null });


    useEffect(() => {
        if (props.aweRightSidebarCloseDatatableReload && props.aweRightSidebarCloseDatatableReload == true) {
            loadformDataTable();
            props.currentAWERightSidebarCloseDatatableReload(false);
        }
    }, [props.aweRightSidebarCloseDatatableReload]);

    useEffect(() => {
        if (props.aweRightSidebarCloseDatatableReload && props.aweRightSidebarCloseDatatableReload == true) {
            loadformDataTable();
            props.currentAWERightSidebarCloseDatatableReload(false);
        }
    }, [props.aweRightSidebarCloseDatatableReload]);

    const handleSiteSelect = (e) => {
        if (e && e != '') {
            setsiteIdSelect(e.site_id);
        } else {
            setsiteIdSelect('');
        }
    }

    const handleProviderAddressGroupsSelect = (event) => {
        var setProviderSelected = [];
        var setProviderNameSelected = [];
        if (event != '' && event.length > 0) {
            event.map(function (el) {
                setProviderSelected.push(el.ppg_address_grouping.toString());
                setProviderNameSelected.push(el.ppg_address_grouping)
            })
            //Adding '' and send as comman separated value for multiple filter
            setProviderAddressGroupsSelect(setProviderSelected);
            setProviderAddressGroupsNameSelect('\"' + setProviderNameSelected.join('\",\"') + '\"');
        }
        else {
            //Adding '' and send as comman separated value for multiple filter
            setProviderAddressGroupsSelect([]);
            setProviderAddressGroupsNameSelect('');
        }
    }

    const handleCitySelect = (e) => {
        if (e && e != '') {
            setCitySelect(e.site_city);
        } else {
            setCitySelect('');
        }
    }

    const handleAddressSelect = (e) => {
        if (e && e != '') {
            setSiteAddressSelect(e.site_address);
        } else {
            setSiteAddressSelect('');
        }
    }

    const handleZipSelect = (e) => {
        if (e && e != '') {
            setZipSelect(e.site_zip);
        } else {
            setZipSelect('');
        }
    }


    const sliderSettingsAca = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        adaptiveHeight: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                    initialSlide: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                    initialSlide: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                }
            }
        ]

    };
    const handleStatusName = (status) => {
        if (status && status == 'DefaultStatus') {
            return 'DS';
        } else if (status && status == 'PendingUpload') {
            return 'PU';
        } else if (status && status == 'pendingforCoordinator') {
            return 'PFCT';
        } else if (status && status == 'pendingforCoder') {
            return 'PFC';
        } else if (status && status == 'pendingForPaymentApproval') {
            return 'PFPA';
        } else if (status && status == 'ApprovedForPayment') {
            return 'AFP';
        } else if (status && status == 'PaymentDone') {
            return 'PD';
        } else if (status && status == 'Reject') {
            return 'REJ';
        } else if (status && status == 'void') {
            return 'Void';
        } else if (status && status == 'ApprovedNoPayment') {
            return 'ANP';
        } else {
            return '';
        }
    }
    const handleStatusDesc = (status) => {
        if (status && status == 'DefaultStatus') {
            return 'Default Status';
        } else if (status && status == 'PendingUpload') {
            return 'Pending Upload';
        } else if (status && status == 'pendingforCoordinator') {
            return 'Pending for Coordinator';
        } else if (status && status == 'pendingforCoder') {
            return 'Pending for Coder';
        } else if (status && status == 'pendingForPaymentApproval') {
            return 'Pending for Payment Approval';
        } else if (status && status == 'ApprovedForPayment') {
            return 'Approved for Payment';
        } else if (status && status == 'Reject') {
            return 'Reject';
        } else if (status && status == 'PaymentDone') {
            return 'Payment Done';
        } else if (status && status == 'void') {
            return 'Void';
        } else if (status && status == 'ApprovedNoPayment') {
            return 'Approved - No Payment';
        } else {
            return '';
        }
    }

    const handleClickAction = (event, awvId, status, user_assigned, stage_id, onHoldStatus = 'N') => {
        setAnchorEl(event.currentTarget);
        setCurrentActionAWVID(awvId);
        setCurrentActionUserId(user_assigned);
        setSelectOnHoldStatus(onHoldStatus);

        if (status && status == 'DefaultStatus') {
            setCurrentActionStatus(0);
            setModelFormRole(0);
        } else if ((status && status == 'PendingUpload') || stage_id == 1) {
            setCurrentActionStatus(1);
            setModelFormRole(2);
        } else if (status && status == 'pendingforCoordinator') {
            setCurrentActionStatus(2);
            setModelFormRole(3);
        } else if (status && status == 'pendingforCoder') {
            setCurrentActionStatus(3);
            setModelFormRole(4);
        } else if (status && status == 'pendingForPaymentApproval') {
            setCurrentActionStatus(4);
            setModelFormRole(4);
        } else if (status && status == 'ApprovedForPayment') {
            setCurrentActionStatus(5);
            setModelFormRole(4);
        } else if (status && status == 'PaymentDone') {
            setCurrentActionStatus(6);
            setModelFormRole(4);
        } else if (status && status == 'void') {
            setCurrentActionStatus(7);
            setModelFormRole(4);
        } else if (status && status == 'Reject') {
            setCurrentActionStatus(8);
            setModelFormRole(4);
        } else if (status && status == 'ApprovedNoPayment') {
            setCurrentActionStatus(9);
            setModelFormRole(4);
        } else {
            setCurrentActionStatus(1);
            setModelFormRole(2);
        }

        // localStorage.setItem('awvid', event.currentTarget.name);
    };

    const handleClickFirstAction = (event) => {
        setAnchorElFirst(event.currentTarget);
        //console.log(event.currentTarget.name);
        localStorage.setItem('awvid', event.currentTarget.name);
    };

    const handleCloseAction = () => {
        setAnchorEl(null);
    };

    const handleCloseFirstAction = () => {
        setAnchorElFirst(null);
    };

    const showUserDetailsLoad = (memberId) => {
        history.push('/awe/awe_analytics_summary?cin=' + memberId);
    };

    const handleAssignMeAction = (rowData, checked) => {
        // setTimeout(() => {
        setIsDataGridLoading(true)
        setIsDataGridCheked(false)
        // }, "1000")
        var dataTemp = {
            "awv_id": btoa(String(rowData[2])),
            "organisation_id": "1",
            "updated_by": isControlUser?.user_id,
            "payment_year": props.yearSelect,
            "lob": props.lobSelect,
            "assigned": checked,
            "provider_id": isControlUser?.user_id
        }
        AWVApi.put('/awv_form_assign', dataTemp)
            .then(res => {
                if (res.data) {
                    // loadformDataTable();
                    if (checked && checked == 'unchecked') {
                        toast.success('Record unassigned');
                        setIsDataGridLoading(false)
                        setIsDataGridCheked(true)
                        loadCircularDataTable();
                        fetchFormWithoutAttachmentCount();
                    } else {
                        toast.success('Record assigned');
                        setIsDataGridLoading(false)
                        setIsDataGridCheked(true)
                        loadCircularDataTable();
                        fetchFormWithoutAttachmentCount();
                    }
                }
            })
            .catch(err => {
                console.log(err);
                setIsDataGridLoading(false)
            })
    };

    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MuiTableCell: {
                    head: {
                        backgroundColor: "#F7F7F7 !important",
                        color: "#202020 !important",
                        fontWeight: "bold !important"
                    },
                    sortOrder: {
                        backgroundColor: "#F7F7F7 !important",
                        color: "#202020 !important",
                        fontWeight: "bold !important"
                    }
                },
                MUIDataTableSelectCell: {
                    headerCell: {
                        backgroundColor: "#F7F7F7 !important",
                        color: "#202020 !important",
                        fontWeight: "bold !important"
                    }
                },
                MUIDataTableHeadCell: {
                    sortActive: {
                        color: "#202020 !important",
                    }
                }
            }
        });
    const styles = theme => ({
        customHead: {
            backgroundColor: "#F7F7F7 !important",
            color: "#202020 !important",
            fontWeight: "bold !important"
        },
        customSortOrder: {
            backgroundColor: "#F7F7F7 !important",
            color: "#202020 !important",
            fontWeight: "bold !important"
        },
    });

    const CustomizedTableCell = withStyles(styles)(TableCell);


    const formDataColumns = [
        {
            name: "user_assigned",
            label: "Assign me",
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({
                    style: {
                        width: '100px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        width: '100px'
                    }
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            {value && value != '' ?
                                <Checkbox type="checkbox" key={tableMeta.rowData[1]} onClick={() => handleAssignMeAction(tableMeta?.rowData, 'unchecked')} checked={true} size="small" />
                                :
                                <Checkbox type="checkbox" key={tableMeta.rowData[1]} onClick={() => handleAssignMeAction(tableMeta?.rowData, 'checked')} size="small" />
                            }
                            {tableMeta.rowData[14] && tableMeta.rowData[14] == 'Y' ?
                                <Tooltip title="On Hold">
                                    <FlagIcon color="mr-2" style={{ width: "0.9em", height: "0.8em", color: "#f1c40f", cursor: "pointer" }} ></FlagIcon>
                                </Tooltip>
                                :
                                <></>
                            }
                        </>
                    )
                }
            }
        },
        {
            name: "cin",
            label: "CIN/RXID",
            options: {
                filter: true,
                filterType: "textField",
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ width: '100px', textAlign: "center", color: "#1a9698", cursor: "pointer" }} onClick={() => showUserDetailsLoad(value)}>{value}</span>
                        </Box>
                    );
                },
            }
        },
        {
            name: "awv_id",
            label: "AWE ID",
            options: {
                filter: false,
                filterType: "textField",
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            {tableMeta.rowData[4] && tableMeta.rowData[4] != '' ?
                                tableMeta.rowData[12]
                                :
                                ''
                            }
                        </>
                    )
                }
            }
        },
        {
            name: 'form_delivery_date',
            label: 'FDD',
            options: {
                filter: true,
                filterType: 'custom',
                customHeadRender: (columnMeta) => (
                    <Tooltip title="Form Delivery Date">
                        <CustomizedTableCell
                            className={styles.customHead}
                        >
                            <TableSortLabel>{columnMeta.label}</TableSortLabel>
                        </CustomizedTableCell>
                    </Tooltip>
                ),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            {value && value != '' ?
                                Moment(value).format("YYYY-MM-DD")
                                :
                                ''
                            }
                        </>
                    )
                },
                customFilterListOptions: {
                    render: v => {
                        if (v[0] && v[1]) {
                            return [`Start Date: ${v[0]}`, `End Date: ${v[1]}`];
                        } else if (v[0]) {
                            // const endDate = new Date().toISOString().split('T')[0];
                            return `Start Date: ${v[0]}`;
                        } else if (v[1]) {
                            return `End Date: ${v[1]}`;
                        }
                        return false;
                    },
                    update: (filterList, filterPos, index) => {
                        console.log('customFilterListOnDelete: ', filterList, filterPos, index);

                        if (filterPos === 0) {
                            filterList[index].splice(filterPos, 1, '');
                        } else if (filterPos === 1) {
                            filterList[index].splice(filterPos, 1);
                        } else if (filterPos === -1) {
                            filterList[index] = [];
                        }

                        return filterList;
                    },
                },
                filterOptions: {
                    names: [],
                    display: (filterList, onChange, index, column) => (
                        <div>
                            <FormLabel>Form Delivery Date</FormLabel>
                            <FormGroup row>
                                <TextField
                                   placeholder="Test Placeholder"
                                    value={filterList[index][0]}
                                    inputProps={{ max: Moment().format("YYYY-MM-DD") }}
                                    type="date"
                                    onChange={event => {
                                        filterList[index][0] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}
                                    style={{ width: '45%', marginRight: '5%' }}
                                    required
                                />
                                <TextField
                                    // label="End Date"
                                    value={filterList[index][1] || ''}
                                    inputProps={{ max: Moment().format("YYYY-MM-DD") }}
                                    type="date"
                                    defaultValue={''}
                                    onChange={event => {
                                        filterList[index][1] = event.target.value;
                                        onChange(filterList[index], index, column);
                                    }}
                                    style={{ width: '45%' }}
                                />
                            </FormGroup>
                        </div>
                    ),
                },
                print: false,
            },
        },
        {
            name: "updated_on",
            label: "Updated on",
            cols: "2",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            {value && value != '' ?
                                Moment(value).format("YYYY-MM-DD")
                                :
                                ''
                            }
                        </>
                    )
                }

            }
        },
        {
            name: "batch_id",
            label: "Batch ID",
            options: {
                filter: false,
                sort: true,
            }
            /*options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            {tableMeta.rowData[3] && tableMeta.rowData[3] != '' ?
                                Moment(tableMeta.rowData[3]).diff(Moment(value), 'days') + ' Days'
                                :
                                ''
                            }
                        </>
                    )
                }
            }*/
        },
        {
            name: "progress",
            label: "Progress",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ minWidth: 35 }}>
                                <Typography variant="body2" color="text.secondary">{`${Math.round(
                                    value,
                                )}%`}</Typography>
                            </Box>
                            <Box sx={{ width: '100%', ml: 1 }}>
                                <LinearProgress
                                    sx={{
                                        height: 10,
                                        borderRadius: 5,
                                        [`&.${linearProgressClasses.colorPrimary}`]: {
                                            backgroundColor: [800],
                                        },
                                        [`& .${linearProgressClasses.bar}`]: {
                                            borderRadius: 5,
                                            backgroundColor: '#1a9698',
                                        },
                                    }}
                                    color="primary"
                                    variant="determinate" value={value} />
                            </Box>
                        </Box>
                    );
                }
            }
        },
        {
            name: "form_status",
            label: "Status",
            options: {
                filter: true,
                filterType: "dropdown",
                sort: true,
                filterList: (formDataFilter == true) ? formAttached : '',
                filterOptions: [
                    'PendingUpload',
                    'pendingforCoordinator',
                    'pendingforCoder',
                    'pendingForPaymentApproval',
                    'ApprovedForPayment',
                    'ApprovedNoPayment',
                    'PaymentDone',
                    'Reject',
                    'void'
                ],
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {(value === 'PaymentDone') ?
                                <span style={{ width: '62px', textAlign: "center" }} className="expand-grid-success-status pt-1 pb-1 pl-3 pr-3" title={handleStatusDesc(value)}>{handleStatusName(value)}</span>
                                :
                                <span style={{ width: '62px', textAlign: "center" }} className="expand-grid-warning-status pt-1 pb-1 pl-3 pr-3" title={handleStatusDesc(value)}>{handleStatusName(value)}</span>
                            }
                        </Box>
                    );
                }
            }
        },
        {
            name: "provider_group",
            label: "Provider Group",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "assignedUserName",
            label: "Assigned to",
            options: {
                filter: false,
                sort: true,
            }
        },
        {
            name: "on_hold",
            label: "On Hold",
            options: {
                filter: true,
                filterType: "dropdown",
                sort: true,
                cols: 1,
                filterList: (formDataFilter == true) ? formAttached : '',
                filterOptions: [
                    'Yes',
                    'No'
                ],
            }
        },

        {
            name: "awv_id",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '130px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '130px'
                    }
                }),
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '130px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '130px'
                    }
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    let controlUser = JSON.parse(localStorage.getItem('controlUser'));
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Tooltip title="View">
                                <a className="hyperlink " style={{ cursor: "pointer", color: "#209f9d" }} onClick={() => notificationRightDrawer(true, 'viewAweFileUpload', value)}>
                                    {/* <a className="hyperlink " style={{ cursor: "pointer" }} onClick={() => handleClickOpenView(value)}> */}
                                    <VisibilityIcon color="mr-3" style={{ width: "0.9em", height: "0.8em" }} />
                                </a>
                            </Tooltip>
                            <Tooltip title="Additional Attachments">
                                <a className="hyperlink " style={{ cursor: "pointer", color: "#209f9d" }} onClick={() => notificationRightDrawer(true, 'additionalAttachments', value, '', tableMeta?.rowData[1])}>
                                    <AttachmentIcon style={{ color: "#209f9d", width: "0.9em", height: "0.8em" }} color="mr-3" />
                                </a>
                            </Tooltip>
                            <Tooltip title="Timeline">
                                <a className="hyperlink " style={{ cursor: "pointer", color: "#209f9d" }} onClick={() => notificationRightDrawer(true, 'timeline', value, '', tableMeta?.rowData[1])}>
                                    <AccountTreeIcon color="mr-3" style={{ width: "0.9em", height: "0.8em" }} />
                                </a>
                            </Tooltip>
                            <Tooltip title="Quick Notes">
                                <a className="hyperlink " style={{ cursor: "pointer", color: "#209f9d" }} onClick={() => notificationRightDrawer(true, 'quickNotes', value, '', tableMeta?.rowData[1])}>
                                    <NoteAddIcon color="#209f9d" style={{ width: "0.9em", height: "0.8em" }} />
                                </a>
                            </Tooltip>
                            {tableMeta?.rowData[0] && controlUser?.user_id && tableMeta?.rowData[0] == controlUser?.user_id && tableMeta?.rowData[7] && tableMeta?.rowData[7] != 'PaymentDone' && tableMeta?.rowData[7] != 'void' ?
                                <>
                                    <IconButton
                                        aria-label="more"
                                        id="long-button"
                                        name={value}
                                        aria-controls={openAction ? 'long-menu' : undefined}
                                        aria-expanded={openAction ? 'true' : undefined}
                                        aria-haspopup="true"
                                        onClick={(event) => handleClickAction(event, value, tableMeta?.rowData[7], tableMeta?.rowData[0], tableMeta?.rowData[12], tableMeta.rowData[14])}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    <Menu
                                        id="long-menu"
                                        MenuListProps={{
                                            'aria-labelledby': 'long-button',
                                        }}
                                        anchorEl={anchorEl}
                                        open={openAction}
                                        onClose={handleCloseAction}
                                        PaperProps={{
                                            style: {
                                                maxHeight: 45 * 4.5,
                                                width: '25ch',
                                            },
                                        }}
                                    >
                                        {(currentActionStatus != '0' && currentActionStatus != '1' && currentActionStatus != '8' && currentActionStatus != '6' && currentActionStatus != '7' && currentActionStatus != '5' && currentActionStatus != '9') ?
                                            <MenuItem key={tableMeta.rowData[12]} onClick={() => notificationRightDrawer(true, 'statusAweFileUpload', currentActionAWVID, (currentActionStatus + 1))}>

                                                <CheckCircleIcon className="mr-3" />{'Approve'}
                                            </MenuItem>
                                            : currentActionStatus == '8' ?
                                                <MenuItem key={tableMeta.rowData[12]} onClick={() => notificationRightDrawer(true, 'statusAweFileUpload', currentActionAWVID, (2 + 1))}>

                                                    <CheckCircleIcon className="mr-3" />{'Approve'}
                                                </MenuItem>
                                                :
                                                <></>
                                        }

                                        {(currentActionStatus != '0' && currentActionStatus != '1' && currentActionStatus != '5' && currentActionStatus != '6' && currentActionStatus != '7' && currentActionStatus != '8' && currentActionStatus != '9') ?
                                            <MenuItem key={tableMeta.rowData[12]} onClick={() => notificationRightDrawer(true, 'statusAweFileUpload', currentActionAWVID, 2)}>
                                                {/* <MenuItem key={'Reject'} onClick={() => handleClickOpenStatus(currentActionAWVID, true, 2)}> */}
                                                <CancelIcon className="mr-3" />{'Reject'}
                                            </MenuItem>
                                            :
                                            <></>
                                        }
                                        {/* {(currentActionStatus != '0' && currentActionStatus != '1' && currentActionStatus != '8') ? */}
                                        {/* removing check that restricting onhold/unhold option display at reject status */}
                                        {/* hide on-hold from PFPA stage */}
                                        {(currentActionStatus != '0' && currentActionStatus != '1' && currentActionStatus != '4' && currentActionStatus != '5' && currentActionStatus != '9') ?
                                            <MenuItem key={tableMeta.rowData[12]} onClick={() => notificationRightDrawer(true, 'onHoldAweFileUpload', currentActionAWVID, 2)}>
                                                {/* <MenuItem key={'Reject'} onClick={() => handleClickOpenStatus(currentActionAWVID, true, 2)}> */}
                                                <CancelIcon className="mr-3" />{(selectOnHoldStatus && selectOnHoldStatus == "Y") ? 'Un Hold' : 'On Hold'}
                                            </MenuItem>
                                            :
                                            <></>
                                        }
                                        {(isControlUser && currentActionUserId == isControlUser.user_id && (currentActionStatus == '0' || currentActionStatus == '1' || currentActionStatus == '8')) ?
                                            <MenuItem key={tableMeta.rowData[12]} onClick={() => notificationRightDrawer(true, 'addAweFileUpload', currentActionAWVID)}>
                                                {/* <MenuItem key={'Edit'} onClick={() => handleClickOpenEdit(currentActionAWVID)}> */}
                                                <ModeEditOutlineIcon className="mr-3" />{'Edit'}
                                            </MenuItem>
                                            :
                                            <></>
                                        }
                                        {/* stage change dropdown */}
                                        {(currentActionStatus != '0' && currentActionStatus != '1' && currentActionStatus != '6' && currentActionStatus != '7' && currentActionStatus != '2' && (currentActionStatus != '2' && currentActionStatus != '8')) ?
                                            <MenuItem key={tableMeta.rowData[12]} onClick={() => notificationRightDrawer(true, 'stageChange', currentActionAWVID, (currentActionStatus))}>
                                                {/* {alert(JSON.stringify(currentActionStatus))} */}
                                                <ArrowCircleLeftRoundedIcon className="mr-3" />{'Stage Change'}
                                            </MenuItem>
                                            :
                                            <></>
                                        }
                                        {(currentActionStatus != '0' && currentActionStatus != '1' && currentActionStatus != '5' && currentActionStatus != '6' && currentActionStatus != '7' && currentActionStatus != '9') ?
                                            <MenuItem key={tableMeta.rowData[12]} onClick={() => notificationRightDrawer(true, 'statusAweFileUpload', currentActionAWVID, 7)}>
                                                {/* <MenuItem key={'Delete'} onClick={() => handleClickOpenStatus(currentActionAWVID, true, 7)}> */}
                                                <DeleteRoundedIcon className="mr-3" />{'Mark as Void'}
                                            </MenuItem>
                                            :
                                            <></>
                                        }
                                    </Menu>
                                </>
                                :
                                <></>
                            }

                        </Box>
                    );
                }
            }
        },
        {
            name: "awe_display_id",
            label: "AWE ID",
            options: {
                filter: true,
                sort: true,
                display: 'excluded',
                filterType: "textField",
            },
        },
        {
            name: "awv_id",
            label: "AWV Display",
            options: {
                filter: false,
                sort: true,
                display: 'excluded'
            }
        },
        {
            name: "on_hold",
            label: "On Hold",
            options: {
                filter: false,
                sort: true,
                // display: 'false',
                display: 'excluded'
            }
        },
        {
            name: "form_url",
            label: "form_url",
            options: {
                filter: false,
                sort: false,
                // display: 'false',
                display: 'excluded'
            }
        },
        {
            name: "is_nlp_processed",
            label: "Is NLP Processed",
            options: {
                filter: true,
                sort: false,
                display: 'excluded',
                filterList: (formDataFilter == true) ? formAttached : '',
                filterOptions: [
                    'Yes',
                    'No'
                ],
            }
        }
    ];

    const memberDetailsSubColumns = [
        {
            name: "risk_code_type",
            label: "RISK CODE TYPE",
            cols: "2",
            customRender: false,
            backgroundColor: '#1D9A9B',
        },
        {
            name: "source_code_type",
            label: "SOURCE CODE TYPE",
            cols: "3",
            customRender: false,
            backgroundColor: '#1D9A9B',
        },
        {
            name: "HCC_Code",
            label: "RISK CODE",
            cols: "1",
            customRender: false,
            backgroundColor: '#1D9A9B',
        },
        {
            name: "HCC_Description",
            label: "RISK CODE DESCRIPTION",
            cols: "3",
            customRender: false,
            backgroundColor: '#000',
        },
        {
            name: "DX_code",
            label: "SOURCE CODE",
            cols: "1",
            customRender: false,
            backgroundColor: '#CA0B00',
        },
        {
            name: "DX_description",
            label: "SOURCE DESCRIPTION",
            cols: "3",
            customRender: false,
            backgroundColor: '#1D9A9B',
        },
        {
            name: "meat_flag",
            label: "INSUFFICIENT DOCUMENTATION",
            cols: "2",
            customRender: false,
            backgroundColor: '#1D9A9B',
        },
        {
            name: "HCC_Confirmed",
            label: "CONFIRMATION",
            cols: "1",
            customRender: false,
            backgroundColor: '#000',
        },
        /* {
            name: "remark",
            label: "REMARKS",
            cols: "1",
            customRender: false,
            backgroundColor: '#CA0B00',
        }, */
        {
            name: "HCC_Code",
            label: "ACTION",
            cols: "1",
            customRender: true,
            backgroundColor: '#CA0B00',
        }
    ];

    const loadformDataTable = () => {
        setIsDataGridCheked(false)
        setIsDataGridCheked(true)
        fetchFormWithoutAttachmentCount();
        loadCircularDataTable();
        // setFormDataList([
        //     {
        //         'id': "1",
        //         'member_id': "#DS-9574",
        //         'awv_id': 55,
        //         'updated_on': "15 May 2022",
        //         'time_spent': "40 Hours",
        //         'progress': "40",
        //         'status': "1",
        //         'provider_group': "Group Name",
        //         'assigs_to': "Jon Doe",
        //     },
        //     {
        //         'id': "1",
        //         'member_id': "#DS-9574",
        //         'awv_id': 5001,
        //         'updated_on': "15 May 2022",
        //         'time_spent': "40 Hours",
        //         'progress': "40",
        //         'status': "1",
        //         'provider_group': "Group Name",
        //         'assigs_to': "Jon Doe",
        //     },
        // ])

        // let queryStr = '';
        // if (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL')
        //     queryStr = '&provider_group=' + props.providerGroupSelect;
        // setIsLoading(true);
        // setIsDataGridLoading(true);
        // loadCircularDataTable();
        // fetchFormWithoutAttachmentCount();
        // setFormDataList([]);
        // AWVApi.get('/get-all-awv-record?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + '&form_attached=' + formAttached + queryStr)
        //     .then(res => {
        //         setIsLoading(false);
        //         setIsDataGridLoading(false);
        //         setFormDataList(res?.data);

        //         /*
        //         var formWithoutAttachmentObj = res?.data.filter(function (el) {
        //             return el.form_status === 'PendingUpload' ||
        //                 el.form_status === 'DefaultStatus';
        //         });

        //         setFormWithoutAtachmentTotal(formWithoutAttachmentObj.length);
        //         */
        //     })
        //     .catch(err => {
        //         setIsLoading(false);
        //         setIsDataGridLoading(false);
        //         console.log(err);
        //     })
    }

    const loadCircularDataTable = () => {
        let queryStr = '';
        if (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL') {
            queryStr = '&provider_group=' + props.providerGroupSelect;
        }
        //queryStr = '&provider_group=' + props.providerGroupSelect ;
        AWVApi.get('/get-awv-form-status?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + queryStr)
            .then(res => {
                if (res.data) {
                    setCircularDataList(res?.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const fetchFormWithoutAttachmentCount = () => {
        let queryStr = '';
        if (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL') {
            queryStr = '&provider_group=' + props.providerGroupSelect;
        }
        let controlUser = JSON.parse(localStorage.getItem('controlUser'));
        let user_id = controlUser?.user_id;
        //queryStr = '&provider_group=' + props.providerGroupSelect ;
        AWVApi.get('/get-count?payment_year=' + props.yearSelect + '&organisation_id=1&user_id=' + user_id + '&lob=' + props.lobSelect + queryStr)
            .then(res => {
                if (res.data) {
                    setFormWithoutAtachmentTotal(res?.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            // loadformDataTable();
            loadCircularDataTable();
            fetchFormWithoutAttachmentCount();
        }
    }, [props.yearSelect, props.providerGroupSelect, props.lobSelect]);



    const handleClickOpenStatus = (value, modelStatus, formStatus) => {
        if (value) {
            editLoadFormData(value)
            setModelStatus(modelStatus);
            setModelFormStatus(formStatus);
            setOpenStatus(true);
            handleCloseAction();
        }
    };

    const handleCloseStatus = () => {
        setOpenStatus(false);
        setModelStatus(false);
        setEditFormData({});
        handleCloseAction();
    };

    const handleClickOpenView = (value) => {
        if (value) {
            editLoadFormData(value)
            setOpenView(true);
            handleCloseAction();
        }
    };

    const handleCloseView = () => {
        setOpenView(false);
        setEditFormData({});
        handleCloseAction();
    };

    const handleClickOpenEdit = (value = '') => {
        setOpenEdit(true);
        if (value && value != '') {
            editLoadFormData(value)
            handleCloseAction();
        } else {
            setEditFormData({});
            handleCloseAction();
        }
    };

    const handleCloseEdit = () => {
        setOpenEdit(false);
        setEditFormData({});
        handleCloseAction();
    };

    const editLoadFormData = (value) => {
        AWVApi.get('/get-all-awv-record?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + '&awvId=' + btoa(value) + '&iSortCol_0=provider_group&sSortDir_0=asc&iDisplayStart=0&iDisplayLength=1&formAttached=0')
            .then(res => {
                if (res.data && res.data[0]) {
                    console.log(res.data);
                    setEditFormData(res.data[0]);
                } else {
                    setEditFormData();
                }
            })
            .catch(err => {
                console.log(err)
            })
    }


    const handlechange = (event) => {
        setFormDataFilter(true);
        if (event.target.checked == true) {
            setFormAttachedFlag("2,3,4,5,6,7,8");
        } else {
            setFormAttached([]);
            setFormAttachedFlag(0);
        }
        setTimeout(() => {
            setFormDataFilter(false);
        }, 1000);

    }
    // to implement form assign filter
    const handleChangeFormAssigned = (event) => {
        if (event.target.checked == true) {
            setFormAssignedFlag(isControlUser?.user_id);
        } else {
            setFormAssignedFlag("");
        }
        setTimeout(() => {
            setFormDataFilter(false);
        }, 1000);

    }

    const editHCCDetails = (value, CinId, action = 'Edit', aweId, hccCodeList, rxcCodeList) => {
        // changed condition to fix blank or duplicate hcc code issue on edit clik, as id is primary key
        // if ((value.HCC_Code && value.HCC_Code != '' )||(value.rxc_code && value.rxc_code!="")&& action == 'Edit') {
        if ((value.id && value.id != '') && action == 'Edit') {
            props.currentAWERightSidebarType({ 'type': 'memberHccDetailsReport', 'cin_id': CinId, 'status': 0, 'DX_code': value.DX_code, 'HCC_Code': value.HCC_Code, 'rxc_code': value.rxc_code, 'ndc_code': value.ndc_code, 'cpt_code': value.cpt_code, 'id': value.id, "hccCodeList": hccCodeList, 'rxcCodeList': rxcCodeList });
            props.currentAWERightSidebar(true);
        } else {
            // adding AWE ID for hcc add functionality
            props.currentAWERightSidebarType({ 'type': 'memberHccDetailsReport', 'cin_id': CinId, 'status': 1, 'aweId': aweId, "hccCodeList": hccCodeList, 'rxcCodeList': rxcCodeList });
            props.currentAWERightSidebar(true);
        }
        setIsDataGridCheked(false);
    };

    const notificationRightDrawer = (open, typeValue, valueId, statusId = '', cinId = '') => {
        setAnchorEl('');
        props.currentAWERightSidebarType({ 'type': typeValue, 'awv_id': valueId, 'cin_id': cinId, 'status': statusId });
        props.currentAWERightSidebar(open);
        setIsDataGridCheked(false);
    };

    var providerGroupSelectVar = (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL') ? props.providerGroupSelect : '';
    return (
        <div className="container level-summary">

            <div className="row card">
                <div className="m-2">
                    <AwvSubHeader title={'AWE(Annual Wellness Exam) Summary by Status'} />
                </div>
            </div>

            <div className="row">
                <div className="col-lg-12 pr-0 pl-0">
                    <div className="mt-1 pl-2">
                        <AwvTabHeader
                            handleSiteSelect={handleSiteSelect}
                            handleProviderAddressGroupsSelect={handleProviderAddressGroupsSelect}
                            handleCitySelect={handleCitySelect}
                            handleAddressSelect={handleAddressSelect}
                            handleZipSelect={handleZipSelect}
                            siteIdSelect={siteIdSelect}
                            providerAddressGroupsSelect={providerAddressGroupsSelect}
                            providerAddressGroupsNameSelect={providerAddressGroupsNameSelect}
                            citySelect={citySelect}
                            siteAddressSelect={siteAddressSelect}
                            zipSelect={zipSelect}
                        />
                    </div>
                    <div className="state-level">
                        <div className="col-lg-12 mr-5 pr-0 pl-0 mt-3 card">
                            <div className="mb-4 mt-4 " style={{ overflowX: 'auto', display: 'flex', flexWrap: 'nowrap', position: 'relative' }}>
                                <div className="justify-content-center text-center ml-3 awv-circuler-progress">
                                    <CircularProgressbarWithChildren
                                        value={circularDataList?.totalFormUploaded}
                                        strokeWidth={0}
                                        background
                                        backgroundPadding={6}
                                        styles={buildStyles({
                                            backgroundColor: "#1D9A9B",
                                            textColor: "#fff",
                                            pathColor: "#fff",
                                            trailColor: "transparent"
                                        })}
                                    >
                                        <div >
                                            <strong style={{ fontSize: 30, marginTop: 0, color: '#fff' }}>{circularDataList?.totalFormUploaded}</strong> <br />
                                            {/*<strong style={{ fontSize: 12, marginTop: 5, color: '#fff' }}>Forms</strong> */}
                                        </div>
                                    </CircularProgressbarWithChildren >
                                    <div className="circular-content">
                                        <div style={{ height: "40px" }}>
                                            <strong style={{ fontSize: 12, color: '#000' }}>Total Uploaded </strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="justify-content-center text-center ml-3  awv-circuler-progress">
                                    <CircularProgressbarWithChildren
                                        value={((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.pendingForCoordinator / circularDataList?.totalFormUploaded) * 100).toFixed(0)}
                                        strokeWidth={10}
                                        styles={buildStyles({
                                            pathColor: `#1D9A9B`,
                                        })}
                                    >
                                        <div >
                                            <strong style={{ fontSize: 30, marginTop: 0, color: '#000' }}>{circularDataList?.pendingForCoordinator}</strong> <br />
                                            {/*<strong style={{ fontSize: 12, marginTop: 5, color: '#000' }}>Forms</strong>*/}
                                        </div>
                                    </CircularProgressbarWithChildren >
                                    <div className="circular-content">
                                        <div style={{ height: "40px" }}>
                                            <strong style={{ fontSize: 12, color: '#000' }}>Pending for Coordinator Review </strong>
                                        </div>
                                        <Divider />
                                        <span style={{ fontSize: 11, color: '#1D9A9B' }}>{((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.pendingForCoordinator / circularDataList?.totalFormUploaded) * 100).toFixed(0)}% forms is pending for coordinator review</span>
                                    </div>
                                </div>
                                <div className="justify-content-center text-center ml-3  awv-circuler-progress">
                                    <CircularProgressbarWithChildren
                                        value={((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.pendingforCoder / circularDataList?.totalFormUploaded) * 100).toFixed(0)}
                                        strokeWidth={10}
                                        styles={buildStyles({
                                            pathColor: `#1D9A9B`,
                                        })}
                                    >
                                        <div >
                                            <strong style={{ fontSize: 30, marginTop: 0, color: '#000' }}>{circularDataList?.pendingforCoder}</strong> <br />
                                            {/*<strong style={{ fontSize: 12, marginTop: 5, color: '#000' }}>Forms</strong>*/}
                                        </div>
                                    </CircularProgressbarWithChildren >
                                    <div className="circular-content">
                                        <div style={{ height: "40px" }}>
                                            <strong style={{ fontSize: 12, color: '#000' }}>Pending for Coder Review </strong>
                                        </div>
                                        <Divider />
                                        <span style={{ fontSize: 11, color: '#1D9A9B' }}>{((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.pendingforCoder / circularDataList?.totalFormUploaded) * 100).toFixed(0)}% forms is pending for coder review</span>
                                    </div>
                                </div>
                                <div className="justify-content-center text-center ml-3 awv-circuler-progress">
                                    <CircularProgressbarWithChildren
                                        value={((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.pendingForPaymentApproval / circularDataList?.totalFormUploaded) * 100).toFixed(0)}
                                        strokeWidth={10}
                                        styles={buildStyles({
                                            pathColor: `#1D9A9B`,
                                        })}
                                    >
                                        <div >
                                            <strong style={{ fontSize: 30, marginTop: 0, color: '#000' }}>{circularDataList?.pendingForPaymentApproval}</strong> <br />
                                            {/*<strong style={{ fontSize: 12, marginTop: 5, color: '#000' }}>Forms</strong>*/}
                                        </div>
                                    </CircularProgressbarWithChildren >
                                    <div className="circular-content">
                                        <div style={{ height: "40px" }}>
                                            <strong style={{ fontSize: 12, color: '#000' }}>Pending For Payment Approval </strong>
                                        </div>
                                        <Divider />
                                        <span style={{ fontSize: 11, color: '#1D9A9B' }}>{((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.pendingForPaymentApproval / circularDataList?.totalFormUploaded) * 100).toFixed(0)}% forms is pending for payment approval</span>
                                    </div>
                                </div>
                                <div className="justify-content-center text-center ml-3 awv-circuler-progress">
                                    <CircularProgressbarWithChildren
                                        value={((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.ApprovedForPayment / circularDataList?.totalFormUploaded) * 100).toFixed(0)}
                                        strokeWidth={10}
                                        styles={buildStyles({
                                            pathColor: `#1D9A9B`,
                                        })}
                                    >
                                        <div >
                                            <strong style={{ fontSize: 30, marginTop: 0, color: '#000' }}>{circularDataList?.ApprovedForPayment}</strong> <br />
                                            {/*<strong style={{ fontSize: 12, marginTop: 5, color: '#000' }}>Forms</strong>*/}
                                        </div>
                                    </CircularProgressbarWithChildren >
                                    <div className="circular-content" >
                                        <div style={{ height: "40px" }}>
                                            <strong style={{ fontSize: 12, color: '#000' }}>Approved For Payment </strong>
                                        </div>
                                        <Divider />
                                        <span style={{ fontSize: 11, color: '#1D9A9B' }}>{((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.ApprovedForPayment / circularDataList?.totalFormUploaded) * 100).toFixed(0)}% forms is approved for payment</span>
                                    </div>
                                </div>
                                <div className="justify-content-center text-center ml-3 awv-circuler-progress">
                                    <CircularProgressbarWithChildren
                                        value={((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.PaymentDone / circularDataList?.totalFormUploaded) * 100).toFixed(0)}
                                        strokeWidth={10}
                                        styles={buildStyles({
                                            pathColor: `#1D9A9B`,
                                        })}
                                    >
                                        <div >
                                            <strong style={{ fontSize: 30, marginTop: 0, color: '#000' }}>{circularDataList?.PaymentDone}</strong> <br />
                                            {/* <strong style={{ fontSize: 12, marginTop: 5, color: '#000' }}>Forms</strong>*/}
                                        </div>
                                    </CircularProgressbarWithChildren >
                                    <div className="circular-content">
                                        <div style={{ height: "40px" }}>
                                            <strong style={{ fontSize: 12, color: '#000' }}>Payment Done </strong>
                                        </div>
                                        <Divider />
                                        <span style={{ fontSize: 11, color: '#1D9A9B' }}>{((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.PaymentDone / circularDataList?.totalFormUploaded) * 100).toFixed(0)}% forms payment done</span>
                                    </div>
                                </div>
                                <div className="justify-content-center text-center ml-3 awv-circuler-progress">
                                    <CircularProgressbarWithChildren
                                        value={((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.ApprovedNoPayment / circularDataList?.totalFormUploaded) * 100).toFixed(0)}
                                        strokeWidth={10}
                                        styles={buildStyles({
                                            pathColor: `#1D9A9B`,
                                        })}
                                    >
                                        <div >
                                            <strong style={{ fontSize: 30, marginTop: 0, color: '#000' }}>{circularDataList?.ApprovedNoPayment}</strong> <br />
                                        </div>
                                    </CircularProgressbarWithChildren >
                                    <div className="circular-content">
                                        <div style={{ height: "40px" }}>
                                            <strong style={{ fontSize: 12, color: '#000' }}>Approved - No Payment </strong>
                                        </div>
                                        <Divider />
                                        <span style={{ fontSize: 11, color: '#1D9A9B' }}>{((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.ApprovedNoPayment / circularDataList?.totalFormUploaded) * 100).toFixed(0)}% forms approved - no payment</span>
                                    </div>
                                </div>
                                <div className="justify-content-center text-center ml-3 awv-circuler-progress">
                                    <CircularProgressbarWithChildren
                                        value={((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.holdStatus / circularDataList?.totalFormUploaded) * 100).toFixed(0)}
                                        strokeWidth={10}
                                        styles={buildStyles({
                                            pathColor: `#f1c40f`,
                                        })}
                                    >
                                        <div >
                                            <strong style={{ fontSize: 30, marginTop: 0, color: '#000' }}>{circularDataList?.holdStatus}</strong> <br />
                                            {/* <strong style={{ fontSize: 12, marginTop: 5, color: '#000' }}>Forms</strong>*/}
                                        </div>
                                    </CircularProgressbarWithChildren >
                                    <div className="circular-content">
                                        <div style={{ height: "40px" }}>
                                            <strong style={{ fontSize: 12, color: '#000' }}>Hold </strong>
                                        </div>
                                        <Divider />
                                        <span style={{ fontSize: 11, color: '#1D9A9B' }}>{((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.holdStatus / circularDataList?.totalFormUploaded) * 100).toFixed(0)}% forms is in Hold</span>
                                    </div>
                                </div>
                                <div className="justify-content-center text-center ml-3 awv-circuler-progress">
                                    <CircularProgressbarWithChildren
                                        value={((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.PaymentReject / circularDataList?.totalFormUploaded) * 100).toFixed(0)}
                                        strokeWidth={10}
                                        styles={buildStyles({
                                            pathColor: `#F00`,
                                        })}
                                    >
                                        <div >
                                            <strong style={{ fontSize: 30, marginTop: 0, color: '#000' }}>{circularDataList?.PaymentReject}</strong> <br />
                                            {/* <strong style={{ fontSize: 12, marginTop: 5, color: '#000' }}>Forms</strong>*/}
                                        </div>
                                    </CircularProgressbarWithChildren >
                                    <div className="circular-content">
                                        <div style={{ height: "40px" }}>
                                            <strong style={{ fontSize: 12, color: '#000' }}>Rejected </strong>
                                        </div>
                                        <Divider />
                                        <span style={{ fontSize: 11, color: '#1D9A9B' }}>{((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.PaymentReject / circularDataList?.totalFormUploaded) * 100).toFixed(0)}% forms Rejected</span>
                                    </div>
                                </div>
                                <div className="justify-content-center text-center ml-3 awv-circuler-progress" style={{ marginRight: "2.7%" }}>
                                    <CircularProgressbarWithChildren
                                        value={((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.void / circularDataList?.totalFormUploaded) * 100).toFixed(0)}
                                        strokeWidth={0}
                                        background
                                        backgroundPadding={6}
                                        styles={buildStyles({
                                            backgroundColor: "#1D9A9B",
                                            textColor: "#fff",
                                            pathColor: "#fff",
                                            trailColor: "transparent"
                                        })}
                                    >
                                        <div >
                                            <strong style={{ fontSize: 30, marginTop: 0, color: '#fff' }}>{circularDataList?.void}</strong> <br />
                                            {/*  <strong style={{ fontSize: 12, marginTop: 5, color: '#fff' }}>Forms</strong>*/}
                                        </div>
                                    </CircularProgressbarWithChildren >
                                    <div className="circular-content" >
                                        <div style={{ height: "40px" }}>
                                            <strong style={{ fontSize: 12, color: '#000' }}>Void </strong>
                                        </div>
                                        <Divider />
                                        <span style={{ fontSize: 11, color: '#1D9A9B' }}>{((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.void / circularDataList?.totalFormUploaded) * 100).toFixed(0)}% forms is in void</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row justify-content-center awv-form-upload-div ml-0 mr-0 mt-3 pl-0 pr-0" >
                            <div className="col-lg-4 row justify-content-center align-items-center">
                                <div className="justify-content-center align-items-center">
                                    <div className="mb-2">
                                        <div className="row justify-content-center"><CloudUploadIcon sx={{ fontSize: 130, marginTop: '-20px', color: '#ac10f2' }} /></div>
                                        <div className="row justify-content-center" style={{ fontSize: '35px', marginTop: '-20px', fontWeight: "bold" }} >Upload PDF</div>
                                        <div className="row justify-content-center" style={{ fontSize: '15px' }}>Supported Extension: PDF</div>
                                    </div>
                                    <div className="row justify-content-center awv-file-upload-icon" onClick={() => handleClickOpen('')}>
                                        <div className="mr-3"><FolderIcon sx={{ fontSize: 40 }} /></div>
                                        <div className="mt-1" style={{ fontSize: '20px' }}>Select File</div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <StatusAWVFileUpload
                            loadFormData={loadformDataTable}
                            editFormData={editFormData}
                            open={openStatus}
                            modelStatus={modelStatus}
                            modelFormStatus={modelFormStatus}
                            modelFormRole={modelFormRole}
                            handleClickOpen={handleClickOpenStatus}
                            handleClose={handleCloseStatus}
                        />
                        <ViewAWVFileUpload
                            loadFormData={loadformDataTable}
                            editFormData={editFormData}
                            open={openView}
                            handleClickOpen={handleClickOpenView}
                            handleClose={handleCloseView}
                        />
                        <AddAWVFileUpload
                            loadFormData={loadformDataTable}
                            editFormData={editFormData}
                            open={openEdit}
                            handleClickOpen={handleClickOpenEdit}
                            handleClose={handleCloseEdit}
                        />
                        <div className="col-lg-12 pr-0 pl-0 mt-5">
                            {formWithoutAtachmentTotal && formWithoutAtachmentTotal.form_without_attachment ?
                                <>
                                    <div style={{ position: 'absolute', zIndex: '1', left: '28%', top: '20px', fontFamily: "'Source Sans Pro', sans-serif" }}>
                                        Forms Without Attachments <span style={{ backgroundColor: 'red', color: '#fff', borderRadius: '10px', padding: '1px 3px 1px 3px' }}>{formWithoutAtachmentTotal?.form_without_attachment}</span>
                                    </div>
                                    {/* adding form assigned checkbox */}
                                    <div style={{ position: 'absolute', zIndex: '1', left: '52%', top: '10px', fontFamily: "'Source Sans Pro', sans-serif" }}>
                                        <FormControlLabel
                                            value="attached"
                                            control={<Checkbox
                                                name="form_attached"
                                                onChange={handleChangeFormAssigned}
                                            />}
                                            label="Assigned to me"
                                            labelPlacement="end"
                                        />
                                        <span style={{ backgroundColor: 'red', color: '#fff', borderRadius: '10px', padding: '1px 3px 1px 3px' }}>{formWithoutAtachmentTotal?.User_assigned_count}</span>
                                    </div>
                                    <div style={{ position: 'absolute', zIndex: '1', left: '70%', top: '10px', fontFamily: "'Source Sans Pro', sans-serif" }}>
                                        <FormControlLabel
                                            value="attached"
                                            control={<Checkbox
                                                name="form_attached"
                                                onChange={handlechange}
                                            />}
                                            label="Forms Attached"
                                            labelPlacement="end"
                                        />
                                    </div>
                                </>
                                :
                                <></>
                            }
                            <div className="card" style={{ minHeight: isDataGridLoading ? "200px" : formDataList && formDataList.length > 0 ? "30px" : "0px" }}>
                                <AWVServerSideGridDataTable
                                    title={'AWE Record Details'}
                                    url={`/get-all-awv-record?payment_year=` + props.yearSelect + `&organisation_id=1&lob=` + props.lobSelect + `&formAttached=` + formAttachedFlag + '&assigned_to_me=' + formAssignedFlag + `&form_attached=` + formAttached + `&provider_group=` + providerGroupSelectVar + `&address_grouping=` + providerAddressGroupsNameSelect + `&site_zip=` + zipSelect + `&site_city=` + citySelect + `&site_address=` + siteAddressSelect + `&site_id=` + siteIdSelect + `&`}
                                    columns={formDataColumns}
                                    pagination={true}
                                    search={false}
                                    viewColumns={true}
                                    download={false}
                                    filter={true}
                                    //isExpandSlider={true}
                                    isLoadingGrid={isDataGridCheked}
                                    subColumns={memberDetailsSubColumns}
                                    editActionData={editHCCDetails}
                                    colSpan={13}
                                    cinKeyNumber={1}
                                    yearSelect={props.yearSelect}
                                ></AWVServerSideGridDataTable>
                            </div>
                        </div>
                        <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                        <br />
                        <br />
                        <br />
                    </div>
                </div>
            </div>
        </div >
    )
};
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        lobSelect: state.moduleFilter.lobSelect,
        aweRightSidebarCloseDatatableReload: state.moduleFilter.aweRightSidebarCloseDatatableReload,
        aweSiteSelect: state.moduleFilter.aweSiteSelect,
        aweProviderGroupingSelect: state.moduleFilter.aweProviderGroupingSelect,
        citySelect: state.moduleFilter.citySelect,
        aweAddressSelect: state.moduleFilter.aweAddressSelect,
        aweZipSelect: state.moduleFilter.aweZipSelect,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(UploadAWVForm);