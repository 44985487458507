import axios from "axios";
import { useEffect, useState } from "react";
import {
  anchorTag,
  anchorTagColmnHide,
  numberWithCommasPrefixDolar,
  numberWithCommasPrefixDolarThreeDigit,
  numberWithCommasPrefixDolarColmnHide,
  numberWithCommasPrefixDolarThreeDigitColmnHide
} from "../../assets/constants/customRender";
import {
  getAcaMetricsIssuer,
  getAcaMetricsIssuerList,
} from "../../assets/constants/issuerList";
import api from "../../assets/constants/Rafservice.Instance";
import {
  DISCREPANCY_REASON,
  MEMBER_ID,
  MEMBER_NAME,
  PLAN_ID,
  REPORTED_INSIGHTS,
  WIDGET_TYPES
} from "../../assets/constants/string.constants";
import { getAcaYear } from "../../assets/constants/yearList";
import CardComponent from "../../components/Cards/card";
import AcaIssuersSelect from "../../components/DropDowns/AcaIssuersSelect";
import Dropdown from "../../components/DropDowns/dropDown";
import GridTable from "../../components/TableGrids/GridTable.table";
import AcaYearSelect from "../../components/DropDowns/AcaYearSelect";
import { getAcaYearList } from "../../assets/constants/yearList";
import { DetailsSharp } from "@material-ui/icons";
import history from "../../history";
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';
import ColumnChartSingleExcelMedicare from '../../components/Charts/ColumnChartSingleExcelMedicare';
import "../../assets/css/Datatable.css";
import MedicareSlickSlider from '../../components/SlickSlider/MedicareSlickSlider';

import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LockOpenIcon from "@material-ui/icons/LockOpen";
import PercentIcon from '@mui/icons-material/Percent';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

const PGPDetailsMedicare = (props) => {
  const [memberDiscrepancyDetails, setMemberDiscrepancyDetails] = useState([]);
  const [year, setYear] = useState(props.yearSelect);
  const [yearList, setyearList] = useState(["2021", ...getAcaYearList()]);
  const [isLoading, setIsLoading] = useState(true);
  const [WidgetDetails, setWidgetDetails] = useState({});
  const [chartAWVVisitRate, setChartAWVVisitRate] = useState([]);
  const [hccDetails, sethccDetails] = useState([]);
  const [bucketName, setBucketName] = useState(process.env.REACT_APP_PPG_REPORT)

  const loadWidgetDetails = () => {

    //api.get('/acaGapsummaryOrg/year/'+props.yearSelect)
    api.get('https://9gn8o0axi8.execute-api.us-west-2.amazonaws.com/v1/get_pcp_all_details?year=' + props.yearSelect + '&gap_closure=50&lob=Medicare')
      .then(res => {
        sethccDetails([{
          'HCC_SCORE_CAPTURED_CURRENT_YEAR': parseFloat(res.HCC_SCORE_CAPTURED_CURRENT_YEAR).toFixed(3),
          'TOTAL_MEMBERS_WITH_HISTORIC_GAP': parseInt(res.TOTAL_MEMBERS_WITH_HISTORIC_GAP),
          'EXPECTED_GAP_CLOSURE': parseFloat(res.EXPECTED_GAP_CLOSURE).toFixed(3),
          'Top_25_Perc_Gap_Closure': parseFloat(res.Top_25_Perc_Gap_Closure).toFixed(3),
          'Top_50_Perc_Gap_Closure': parseFloat(res.Top_50_Perc_Gap_Closure).toFixed(3),
          'Top_90_Perc_Gap_Closure': parseFloat(res.Top_90_Perc_Gap_Closure).toFixed(3),
          'Current_Average_Risk_Score': parseFloat(res.Current_Average_Risk_Score).toFixed(3),
          'Average_Risk_score_After_closing_the_gaps': parseFloat(res.Average_Risk_score_After_closing_the_gaps).toFixed(3),
        }]);
        setWidgetDetails({
          'HCC SCORE CAPTURED CURRENT YEAR': Number(res.HCC_SCORE_CAPTURED_CURRENT_YEAR).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          'TOTAL MEMBERS WITH HISTORIC GAP': Number(res.TOTAL_MEMBERS_WITH_HISTORIC_GAP).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          'EXPECTED GAP CLOSURE': Number(res.EXPECTED_GAP_CLOSURE).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          'Top 25 Perc Gap Closure': Number(res.Top_25_Perc_Gap_Closure).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          'Top 50 Perc Gap Closure': Number(res.Top_50_Perc_Gap_Closure).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          'Top 90 Perc Gap Closure': Number(res.Top_90_Perc_Gap_Closure).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          'Current Average Risk Score': Number(res.Current_Average_Risk_Score).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          'Average Risk score After closing the gaps': Number(res.Average_Risk_score_After_closing_the_gaps).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        });
      })
      .catch(err => {
        // // console.log(err);
      });

  };

  const hccDetailsCols = [
    {
      name: "HCC_SCORE_CAPTURED_CURRENT_YEAR",
      label: "HCC SCORE CAPTURED CURRENT YEAR"
    },
    {
      name: "TOTAL_MEMBERS_WITH_HISTORIC_GAP",
      label: "TOTAL MEMBERS WITH HISTORIC GAP"
    },
    {
      name: "EXPECTED_GAP_CLOSURE",
      label: "EXPECTED GAP CLOSURE"
    },
    {
      name: "Top_25_Perc_Gap_Closure",
      label: "Top 25 Perc Gap Closure"
    },
    {
      name: "Top_50_Perc_Gap_Closure",
      label: "Top 50 Perc Gap Closure"
    },
    {
      name: "Top_90_Perc_Gap_Closure",
      label: "Top 90 Perc Gap Closure"
    },
    {
      name: "Current_Average_Risk_Score",
      label: "Current AverageRisk Score"
    },
    {
      name: "Average_Risk_score_After_closing_the_gaps",
      label: "Average Risk score After closing the gaps"
    }
  ];

  const WidgetDetailsType = [
    'QueryStatsIcon',
    'Members',
    'SsidChart',
    'BarChartIcon',
    'BarChartIcon',
    'BarChartIcon',
    'QueryStatsIcon',
    'SsidChart',
    'BarChartIcon'
  ];

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  const type = WidgetDetailsType;
  const widgetStyles = {
    position: "absolute",
    left: 0,
    //right: 20,
    top: 0,
    width: "100%",
    height: "100%",
    opacity: 0.15,
    color: "white",
  };

  const getIcon = (type) => {
    var data = "";
    console.log(type);
    switch (type) {
      
      case 'SsidChart':
        data = <SsidChartIcon style={widgetStyles} />;
        break;
      case 'Members':
        data = <PeopleAltIcon style={widgetStyles} />;
        break;
      case 'Percent':
        data = <PercentIcon style={widgetStyles} />;
        break;
      case 'QueryStatsIcon':
        data = <QueryStatsIcon style={widgetStyles} />;
        break;
      case 'BarChartIcon':
        data = <BarChartIcon style={widgetStyles} />;
        break;
      case WIDGET_TYPES.ISSUER:
        data = <BarChartIcon style={widgetStyles} />;
        break;
      case WIDGET_TYPES.MEMBERS:
        data = <PeopleAltIcon style={widgetStyles} />;
        break;
      case WIDGET_TYPES.PERCENT:
        data = <BarChartIcon style={widgetStyles} />;
        break;
      case WIDGET_TYPES.SCORE:
        data = <BarChartIcon style={widgetStyles} />;
        break;
      case WIDGET_TYPES.PERSON:
        data = <BarChartIcon style={widgetStyles} />;
        break;
      case WIDGET_TYPES.CHILD:
        data = <PersonIcon style={widgetStyles} />;
        break;
      case WIDGET_TYPES.ADULT:
        data = <PersonIcon style={widgetStyles} />;
        break;
      case WIDGET_TYPES.INFANT:
        data = <PersonIcon style={widgetStyles} />;
        break;
      case WIDGET_TYPES.REVENUE:
        data = <AttachMoneyIcon style={widgetStyles} />;
        break;
      case WIDGET_TYPES.COUNT:
        data = <ShoppingCartIcon style={widgetStyles} />;
        break;
      case WIDGET_TYPES.LOCKICON:
        data = <LockOpenIcon style={widgetStyles} />;
        break;
    }
    return data;
  };


  const discrepancyCols = [
    {
      name: "PPG",
      label: "PPG CODE",
    },
    {
      name: "PPG_DESCRIPTION",
      label: "PPG DESCRIPTION",
    },

    {
      name: "TOTAL_MEMBERS",
      label: " # MEMBERS",
    },
    {
      name: "TOTAL_MEMBERS_WITH_HISTORIC_GAP",
      label: "# MEMBERS WITH HCC GAP",
    },
    {
      name: "HCC_SCORE_CAPTURED_CURRENT_YEAR",
      label: "HCC SCORE CAPTURED CURRENT YEAR",
    },
    {
      name: "HCC_SCORE_CAPTURED_PREV_YEAR",
      label: "HCC SCORE CAPTURED PREV YEAR",
    },
    {
      name: "Top_25_MEMBERS",
      label: "Top 25 Members",
    },
    {
      name: "Top_25_Gap_Closure",
      label: "Top 25 Gap Closure",
    },
    {
      name: "Top_25_Perc_Gap_Closure",
      label: "% Top 25 Gap Closure",
    },
    {
      name: "Top_50_MEMBERS",
      label: "Top 50 Members",
    },
    {
      name: "Top_50_Gap_Closure",
      label: "Top 50 Gap Closure",
    },
    {
      name: "Top_50_Perc_Gap_Closure",
      label: "%Top 50 Gap Closure",
    },
    {
      name: "Top_90_MEMBERS",
      label: "Top 90 Members",
    },
    {
      name: "Top_90_Gap_Closure",
      label: "Top 90 Gap Closure",
    },
    {
      name: "Top_90_Perc_Gap_Closure",
      label: "%Top 90 Gap Closure",
    },
    {
      name: "TOTAL_ACTIVE_MEMBERS",
      label: "# ACTIVE MEMBERS",
      options: {
        display: false,
      },
    },
    {
      name: "TOTAL_NEWLY_ENROLLED_MEMBERS",
      label: "# NEWLY ENROLLED MEMBERS",
      options: {
        display: false,
      },
    },
    {
      name: "TOTAL_COST",
      label: "$ UTILIZATION COST",
      options: numberWithCommasPrefixDolarColmnHide,
    },
    /* {
      name: "TOTAL_RISK_SCORE_25",
      label: "TOTAL RISK SCORE 25",
      options: numberWithCommasPrefixDolarThreeDigitColmnHide,
    },
    {
      name: "TOTAL_RISK_SCORE_50",
      label: "TOTAL RISK SCORE 50",
      options: numberWithCommasPrefixDolarThreeDigitColmnHide,
    },
    {
      name: "TOTAL_RISK_SCORE_90",
      label: "TOTAL RISK SCORE 90",
      options: numberWithCommasPrefixDolarThreeDigitColmnHide,
    },
    {
      name: "TOTAL_REP_RISK_SCORE",
      label: "TOTAL REP RISK SCORE",
      options: numberWithCommasPrefixDolarThreeDigitColmnHide,
    }, */
    {
      name: "TOTAL_ENROLLED_MEMBER_MONTHS",
      label: "# ENROLLED MEMBER MONTHS",
      options: {
        display: false,
      },
    },
    {
      name: "TOTAL_PROSPECTIVE",
      label: " # PROSPECTIVE",
      options: anchorTagColmnHide,
    },
    {
      name: "TOTAL_RETROSPECTIVE",
      label: "# RETROSPECTIVE",
      options: anchorTagColmnHide,
    },
    {
      name: "TOTAL_OPPORTUNITY_LOST",
      label: "# OPPORTUNITY LOST",
      options: {
        display: false,
      },
    },
    {
      name: "MEMBERS_WITH_PREVENTIVE_VISITS",
      label: "# MEMBERS WITH PREVENTIVE VISITS",
      options: {
        display: false,
      },
    },
    {
      name: "PREVENTIVE_VISIT_PREVALENCE",
      label: "PREVENTIVE VISIT PREVALENCE",
      options: {
        display: false,
      },
    },
    {
      name: "TARGET_PREVENTIVE_VISITS_MEMBERS",
      label: "TARGET PREVENTIVE VISITS MEMBERS",
      options: {
        display: false,
      },
    },
    {
      name: "TOTAL_MEMBERS_WITH_HISTORIC_GAP",
      label: "# MEMBERS WITH HCC GAP",
      options: {
        display: false,
      },
    },
    {
      name: "EXPECTED_GAP_CLOSURE",
      label: "EXPECTED GAP CLOSURE",
      options: {
        display: false,
      },
    },
    {
      name: "PERCENT_MEMBERS_WITH_GAPS",
      label: "% MEMBERS WITH GAPS",
      options: {
        display: false,
      },
    },
    {
      name: "PERCENT_MEMBERS_PROSPECTIVE",
      label: "% MEMBERS PROSPECTIVE",
      options: {
        display: false,
      },
    },
    {
      name: "HCC_SCORE_CAPTURED_PREV_YEAR",
      label: "HCC SCORE CAPTURED PREV YEAR",
      options: {
        display: false,
      },
    },
    {
      name: "PREVIOUS_YEAR_PREVALENCE_RATE",
      label: "PREVIOUS YEAR PREVALENCE RATE",
      options: {
        display: false,
      },
    },
    {
      name: "HCC_SCORE_CAPTURED_CURRENT_YEAR",
      label: "HCC SCORE CAPTURED CURRENT YEAR",
      options: {
        display: false,
      },
    },
    {
      name: "HCC_SCORE_CHANGE_YOY",
      label: "HCC SCORE CHANGE YOY",
      options: {
        display: false,
      },
    },
    {
      name: "TOTAL_COST_PREVENTIVE_VISITS",
      label: "$ COST PREVENTIVE VISITS",
      options: numberWithCommasPrefixDolarColmnHide,
    },
    {
      name: "PER_MEMBER_COST_PREVENTIVE_VISIT",
      label: " $ PMPM / Visit",
      options: numberWithCommasPrefixDolarColmnHide,
    },
    {
      name: "Top_25_Gap_Closure",
      label: "Top 25 Gap Closure",
      options: {
        display: false,
      },
    },
    {
      name: "Top_25_MEMBERS",
      label: "Top 25 Members",
      options: {
        display: false,
        anchorTag
      },
    },
    {
      name: "Top_25_Perc_Gap_Closure",
      label: "% Top 25 Gap Closure",
      options: {
        display: false,
      },
    },
    {
      name: "Top_50_Gap_Closure",
      label: "Top 50 Gap Closure",
      options: {
        display: false,
      },
    },
    {
      name: "Top_50_MEMBERS",
      label: "Top 50 Members",
      options: {
        display: false,
        anchorTag
      },
    },
    {
      name: "Top_50_Perc_Gap_Closure",
      label: "%Top 50 Gap Closure",
      options: {
        display: false,
      },
    },
    {
      name: "Top_90_Gap_Closure",
      label: "Top 90 Gap Closure",
      options: {
        display: false,
      },
    },

    {
      name: "Top_90_MEMBERS",
      label: "Top 90 Members",
      options: {
        display: false,
        anchorTag
      },
    },
    {
      name: "Top_90_Perc_Gap_Closure",
      label: "%Top 90 Gap Closure",
      options: {
        display: false,
      },
    },
  ];

  const rarsdTableClick = (colData, cellMeta, rows, e, el) => {
    if (
      cellMeta.colIndex === 20 &&
      colData.props.children != "" &&
      colData.props.children !== 0
    ) {
      let ppg = rows[cellMeta.dataIndex].PPG;

      localStorage.setItem("PPG", ppg);
      localStorage.setItem("PRType", "PPG-RETROSPECTIVE");
      history.push("/layout/pcpMemberDetails");
    }

    if (
      cellMeta.colIndex === 19 &&
      colData.props.children != "" &&
      colData.props.children !== 0
    ) {
      let ppg = rows[cellMeta.dataIndex].PPG;
      localStorage.setItem("PPG", ppg);
      localStorage.setItem("PRType", "PPG-PROSPECTIVE");
      history.push("/layout/pcpMemberDetails");
    }
    /* if (
      cellMeta.colIndex === 6 &&
      colData.props.children != "" &&
      colData.props.children !== 0
    ) {
      let ppg = rows[cellMeta.dataIndex].PPG;

      let top25 = rows[cellMeta.dataIndex].Top_25_MEMBERS;

      localStorage.setItem("PPG", ppg);
      localStorage.setItem("PRType", "TOP 25");
      localStorage.setItem("MEMCOUNT", top25);
      localStorage.setItem("PCPYEAR", props.yearSelect);
      history.push("/layout/pcpMemberDetails");
    }

    if (
      cellMeta.colIndex === 9 &&
      colData.props.children != "" &&
      colData.props.children !== 0
    ) {
      let ppg = rows[cellMeta.dataIndex].PPG;
      let top50 = rows[cellMeta.dataIndex].Top_50_MEMBERS;

      localStorage.setItem("PPG", ppg);
      localStorage.setItem("PRType", "TOP 50");
      localStorage.setItem("MEMCOUNT", top50);
      localStorage.setItem("PCPYEAR", props.yearSelect);
      history.push("/layout/pcpMemberDetails");
    }
    if (
      cellMeta.colIndex === 12 &&
      colData.props.children != "" &&
      colData.props.children !== 0
    ) {
      let ppg = rows[cellMeta.dataIndex].PPG;
      let top90 = rows[cellMeta.dataIndex].Top_90_MEMBERS;

      localStorage.setItem("PPG", ppg);
      localStorage.setItem("PRType", "TOP 90");
      localStorage.setItem("MEMCOUNT", top90);
      localStorage.setItem("PCPYEAR", props.yearSelect);
      history.push("/layout/pcpMemberDetails");
    } */
  };

  useEffect(() => {
    if (props.yearSelect && props.yearSelect != '') {
      loadPlanList();
      chartAWVVisitRateData();
      loadWidgetDetails();
    }
  }, [
    props.moduleSelect,
    props.viewBySelect,
    props.modelSelect,
    props.metalTierSelect,
    props.planSelect,
    props.yearSelect,
    props.meInitialSelect
  ]);

  const loadPlanList = () => {
    let data = [];
    setIsLoading(true);

    let keys = [];
    let details = {};
    api
      .get("https://9gn8o0axi8.execute-api.us-west-2.amazonaws.com/v1/provider-reports-demo?file=PPG%20Level&lob=Medicare&year=" + props.yearSelect)
      .then((res) => {
        keys = Object.keys(res);
        if (keys !== undefined) {
          for (const key of keys) {
            details.TOTAL_PROSPECTIVE = res[key].TOTAL_PROSPECTIVE;
            details.TOTAL_RETROSPECTIVE = res[key].TOTAL_RETROSPECTIVE;
            details.TOTAL_ACTIVE_MEMBERS = res[key].TOTAL_ACTIVE_MEMBERS;
            details.TOTAL_MEMBERS = res[key].TOTAL_MEMBERS;
            details.TOTAL_COST = Number(res[key].TOTAL_COST).toFixed(2);
            details.PPG = res[key].PPG;
            details.TOTAL_RISK_SCORE_25 = res[key].TOTAL_RISK_SCORE_25;
            details.TOTAL_RISK_SCORE_50 = res[key].TOTAL_RISK_SCORE_50;
            details.TOTAL_RISK_SCORE_90 = res[key].TOTAL_RISK_SCORE_90;
            details.TOTAL_REP_RISK_SCORE = res[key].TOTAL_REP_RISK_SCORE;
            details.PPG_DESCRIPTION = res[key].PPG_DESCRIPTION;
            details.TOTAL_ENROLLED_MEMBER_MONTHS =
              res[key].TOTAL_ENROLLED_MEMBER_MONTHS;
            details.TOTAL_MEMBERS_WITH_HISTORIC_GAP =
              res[key].TOTAL_MEMBERS_WITH_HISTORIC_GAP;
            details.TOTAL_OPPORTUNITY_LOST = res[key].TOTAL_OPPORTUNITY_LOST;
            details.MEMBERS_WITH_PREVENTIVE_VISITS =
              res[key].MEMBERS_WITH_PREVENTIVE_VISITS;
            details.TOTAL_NEWLY_ENROLLED_MEMBERS =
              res[key].TOTAL_NEWLY_ENROLLED_MEMBERS;
            details.TOTAL_ACTIVE_MEMBERS = res[key].TOTAL_ACTIVE_MEMBERS;
            details.PREVENTIVE_VISIT_PREVALENCE =
              res[key].PREVENTIVE_VISIT_PREVALENCE;
            details.TARGET_PREVENTIVE_VISITS_MEMBERS =
              res[key].TARGET_PREVENTIVE_VISITS_MEMBERS;
            details.EXPECTED_GAP_CLOSURE = res[key].EXPECTED_GAP_CLOSURE;
            details.PERCENT_MEMBERS_WITH_GAPS =
              res[key].PERCENT_MEMBERS_WITH_GAPS;
            details.PERCENT_MEMBERS_PROSPECTIVE =
              res[key].PERCENT_MEMBERS_PROSPECTIVE;
            details.HCC_SCORE_CAPTURED_PREV_YEAR =
              res[key].HCC_SCORE_CAPTURED_PREV_YEAR;
            details.PREVIOUS_YEAR_PREVALENCE_RATE =
              res[key].PREVIOUS_YEAR_PREVALENCE_RATE;
            details.TOTAL_COST_PREVENTIVE_VISITS =
              res[key].TOTAL_COST_PREVENTIVE_VISITS;
            details.HCC_SCORE_CAPTURED_CURRENT_YEAR =
              res[key].HCC_SCORE_CAPTURED_CURRENT_YEAR;
            details.HCC_SCORE_CHANGE_YOY = res[key].HCC_SCORE_CHANGE_YOY;
            details.TOTAL_COST_PREVENTIVE_VISITS =
              res[key].TOTAL_COST_PREVENTIVE_VISITS;
            details.PER_MEMBER_COST_PREVENTIVE_VISIT =
              res[key].PER_MEMBER_COST_PREVENTIVE_VISIT;
            details.AVG_HCC_SCORE = res[key].AVG_HCC_SCORE;
            details.NO_OF_MEMBERS = res[key].NO_OF_MEMBERS;
            details.REPORTED_HCC_SCORE = res[key].REPORTED_HCC_SCORE;
            details.Top_25_Gap_Closure = res[key].Top_25_Perc_Gap_Closure;
            details.Top_50_Gap_Closure = res[key].Top_50_Perc_Gap_Closure;
            details.Top_90_Gap_Closure = res[key].Top_90_Perc_Gap_Closure;
            details.Top_25_MEMBERS = res[key].Top_25_Perc_MEMBERS;
            details.Top_50_MEMBERS = res[key].Top_50_Perc_MEMBERS;
            details.Top_90_MEMBERS = res[key].Top_90_Perc_MEMBERS;
            details.Top_25_Perc_Gap_Closure =
              res[key].Top_25_Percentile_Gap_Closure_Percentage;
            details.Top_50_Perc_Gap_Closure =
              res[key].Top_50_Percentile_Gap_Closure_Percentage;
            details.Top_90_Perc_Gap_Closure =
              res[key].Top_90_Percentile_Gap_Closure_Percentage;

            data.push(details);
            details = {};
          }
        }

        // console.log("here");
        setIsLoading(false);
        setMemberDiscrepancyDetails(data);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
  };

  const chartAWVVisitRateData = () => {
    let queryStr = '';
    if (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL')
      queryStr = '&provider_group=' + props.providerGroupSelect;
    api.get('https://9gn8o0axi8.execute-api.us-west-2.amazonaws.com/v1/hcc_gaps_by_ppg?year=' + props.yearSelect + '&lob=Medicare')
      .then(res => {
        if (res && res.length > 0) {
          var mainCustomArray = [];
          var index = 0;
          var customArrayKey = [];
          customArrayKey[0] = 'Code';
          customArrayKey[1] = 'Total Member with Gaps';
          mainCustomArray[0] = customArrayKey;
          var sortedData = res.sort((a, b) => parseInt(a.TOTAL_MEMBERS_WITH_HISTORIC_GAP) - parseInt(b.TOTAL_MEMBERS_WITH_HISTORIC_GAP));

          for (var key in sortedData) {
            var customArray = [];
            index++;
            customArray[0] = res[key]['PPG'];
            customArray[1] = res[key]['TOTAL_MEMBERS_WITH_HISTORIC_GAP'];
            mainCustomArray[index] = customArray;
          }
          setChartAWVVisitRate(mainCustomArray);
        } else {
          setChartAWVVisitRate([
            ["Code", 'Total'],
            ["AHPN", 0],
          ]);
        }
      })
      .catch(err => {
        console.log(err)
      })
  }


  return (
    <div className="container level-summary">
      <div className="row mb-3">
        <div className="col-lg-12 pr-0 pl-0 mb-3">
          <div className="state-level">
            <div className="card-header">
               <SubHeader  ppg={true} title={''} />
            </div>
           {/*  <MedicareSlickSlider WidgetDetails={WidgetDetails} getIcon={getIcon} type={type} /> */}
          </div>
        </div>
        <div className="col-lg-12 pr-0 pl-0 mb-3">
          <CardComponent
            heading={`HCC Gap Summary Recommendations`}
            body={
              <GridTable
                data={hccDetails}
                columns={hccDetailsCols}
              ></GridTable>
            }></CardComponent>
        </div>
        <div className="col-lg-12 pr-0 pl-0 mb-3">
          <CardComponent
            heading={"Summary view"}
            additionalClass={`text-capital`}
            body={
              <div className="mb-3">
                <ColumnChartSingleExcelMedicare data={chartAWVVisitRate} />
              </div>
            }
          ></CardComponent>
        </div>
        <div className="col-lg-12 pr-0 pl-0 mb-3">
          <CardComponent
            heading={"Provider Performance Recommendaitons"}
            additionalClass={`text-capital`}
            display={true}
            year={props.yearSelect}
            downloadMemberRafReportFile={true}
            ppgSummaryReportName={'Download PPG Summary Report'}
            memberRafReportName = {'Member Raf Score Report'}
            diagnosisReportName = {'Diagnosis Code Report'}
            DiagnosisCode_Button ={true}
            bucketName={bucketName}
            keyMemberRafReport = {'member_raf_score_report/Medicare/'}
            keyDiagnosisReport = {'Diagnosis Code/Medicare/'}
            keyPPGSummaryReport = {'PPGSummary/Medicare/'}
            body={
              <GridTable
                columns={discrepancyCols}
                data={memberDiscrepancyDetails}
                pagination={true}
                search={true}
                viewColumns={true}
                download={true}
                filter={true}
                onTableClick={rarsdTableClick}
                className={"pcp_details_table"}
                isLoading={isLoading}
              ></GridTable>
            }
          ></CardComponent>
        </div>
        <br />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    moduleSelect: state.moduleFilter.moduleSelect,
    viewBySelect: state.moduleFilter.viewBySelect,
    modelSelect: state.moduleFilter.modelSelect,
    metalTierSelect: state.moduleFilter.metalTierSelect,
    planSelect: state.moduleFilter.planSelect,
    yearSelect: state.moduleFilter.yearSelect,
    meInitialSelect: state.moduleFilter.meInitialSelect,
  };
}
export default connect(mapStateToProps)(PGPDetailsMedicare);
