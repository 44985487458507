import React, { useState, useEffect, useRef } from "react";
import WidgetCard from "../../components/Cards/WidgetCard";
import AcaYearSelect from "../../components/DropDowns/AcaYearSelect";
import api from "../../assets/constants/Rafservice.Instance";
import SingleVerticalBarChart from "../../components/Charts/SingleVerticalBar.Chart";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { VennDiagram, VennSeries, VennArc, VennLabel, VennOuterLabel, TooltipArea, ChartTooltip, TooltipTemplate } from 'reaviz';
import {
    anchorTag,
    hccSeperator,
    numberWithCommas,
    addSuffixPercent,
    addSuffixTwoDecimal,
    numberWithCommasPrefixDolar,
    textToOpenOrCloseFormatRender,
} from "../../assets/constants/customRender";
import {
    CARRIER_ID,
    GAP_ORG,
    GAP_ORG_METAL,
    GAP_OR_REPORTED,
    GENDER,
    HCC,
    ISSUER_ID,
    MEMBER_ID,
    MEMBER_NAME,
    METAL_TIER,
    PLAN_ID,
    WIDGET_TYPES,
    RECOMMENDATION_TYPE,
    MAO_Chart_Review_Member,
    MAO_MEMBER_SELECT_TAB,
    GAP_INSIGHTS,
    LIKELIHOOD_FLAG,
    FLAG,
    MAO_TYPE,
    SELECT_DISTRIBUTION
} from "../../assets/constants/string.constants";
import history from "../../history";
import CardComponent from "../../components/Cards/card";
import GridTable from "../../components/TableGrids/GridTable.table";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import GChart from '../../components/GChart/GChart';
import GChartClick from '../../components/GChart/GChartClick';
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import SubHeader from '../../components/home/header/subHeader';
import { getAcaYear } from '../../assets/constants/yearList';
import { getMeInitialSelect } from '../../assets/constants/meInitialList';
import { connect } from 'react-redux';


const MaoGapMemberSummaryTab = (props) => {

    const myRefChartData = useRef('')
    const [WidgetDetails, setWidgetDetails] = useState({});
    const [LikelihoodPercentageChartData, setLikelihoodPercentageChartData] = useState({});
    const [LikelihoodCountOfMembersChartData, setLikelihoodCountOfMembersChartData] = useState({});
    const [HccCaptureRecommendationData, setHccCaptureRecommendationData] = useState([]);
    const [PercentageOfGapMemberCountData, setPercentageOfGapMemberCountData] = useState([]);
    const [active, setActive] = useState(null);
    const [gapType, setGapType] = useState('');
    const [venChartData, setVenChartData] = useState([]);
    const [venChartLink, setVenChartLink] = useState([]);
    const [DistributionSelect, setDistributionSelect] = useState(localStorage.getItem(SELECT_DISTRIBUTION));

    const backGround = [
        { backgroundColor: "#AAB7B8" },
        { backgroundColor: "#EB984E" },
        { backgroundColor: "#58D68D" },
        { backgroundColor: "#5DADE2" },
        { backgroundColor: "#AF7AC5" },
        { backgroundColor: "#EC7063" }
    ]

    // history.push('/layout/medicare/gap_hcc_recomendation')
    const loadWidgetDetails = () => {
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/SuspectMemberSummary/' + getAcaYear() + '/period/' + getMeInitialSelect())
            .then(res => {
                setWidgetDetails({
                    '# Total Members': Number(res[0].totalMembers).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    'Overall Revenue': '$' + Number(res[0].overallRevenue).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    '# Members with Gap': Number(res[0].risk_impacting_members).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    //'# Risk Impacting Members': Number(res[0].risk_impacting_members).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    'Gap Revenue': '$' + Number(res[0].totalRevenueImpact).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                });
            })
            .catch(err => {
                // console.log(err);
            });

    };

    const WidgetDetailsType = [
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.ADULT,
        WIDGET_TYPES.CHILD,
        WIDGET_TYPES.INFANT,
        WIDGET_TYPES.SCORE,
        WIDGET_TYPES.PERCENT
    ];

    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        adaptiveHeight: true,
    };

    const type = WidgetDetailsType;
    const widgetStyles = {
        position: "absolute",
        left: 0,
        //right: 20,
        top: 0,
        width: "100%",
        height: "100%",
        opacity: 0.15,
        color: "white",
    };

    const getIcon = (type) => {
        var data = "";
        switch (type) {
            case WIDGET_TYPES.ISSUER:
                data = <AccountBalanceTwoToneIcon style={widgetStyles} />;
                break;
            case WIDGET_TYPES.MEMBERS:
                data = <PeopleAltIcon style={widgetStyles} />;
                break;
            case WIDGET_TYPES.PERCENT:
                data = <ShowChartIcon style={widgetStyles} />;
                break;
            case WIDGET_TYPES.SCORE:
                data = <BarChartIcon style={widgetStyles} />;
                break;
            case WIDGET_TYPES.PERSON:
                data = <PersonIcon style={widgetStyles} />;
                break;
            case WIDGET_TYPES.CHILD:
                data = <PersonIcon style={widgetStyles} />;
                break;
            case WIDGET_TYPES.ADULT:
                data = <PersonIcon style={widgetStyles} />;
                break;
            case WIDGET_TYPES.INFANT:
                data = <PersonIcon style={widgetStyles} />;
                break;
            case WIDGET_TYPES.REVENUE:
                data = <AttachMoneyIcon style={widgetStyles} />;
                break;
            case WIDGET_TYPES.COUNT:
                data = <ShoppingCartIcon style={widgetStyles} />;
                break;
            case WIDGET_TYPES.LOCKICON:
                data = <LockOpenIcon style={widgetStyles} />;
                break;
        }
        return data;
    };


    const HccCaptureRecommendationColumns = [
        {
            name: "hccCaptureReco",
            label: "Capture Recommendation"
        },
        {
            name: "totalMembers",
            label: "# No of Members",
            options: anchorTag
        },
        {
            name: "percentageOfMembers",
            label: "Percentage of Gap Revenue",
            options: addSuffixPercent
        },
        {
            name: "HccGapScore",
            label: "Revenue Impacted",
            options: numberWithCommasPrefixDolar
        },
    ];

    const PercentageOfGapMemberCountColumns = [
        {
            name: "count",
            label: "# No Of Members"
        },
        {
            name: "percentage",
            label: "Percentage Of Gap Revenue",
            options: addSuffixPercent
        },
        {
            name: "revenue",
            label: "Revenue Impacted",
            options: numberWithCommasPrefixDolar
        },
    ];

    const memberColumn = [
        {
            label: "Member ID",
            name: "MEMBER_ID",
            options: anchorTag
        },
        {
            name: "Reported_hcc",
            label: "Reported HCCs",
            options: hccSeperator
        },
        {
            name: "Gap_hcc",
            label: "Gap HCCs",
            options: hccSeperator
        },
        {
            name: "Raf_score",
            label: "RAF Score",
        },
        {
            name: "Gap_in_raf_score",
            label: "Gap In RAF Score",
        },
        {
            name: "Revenu_leekage",
            label: "Potential Revenue Impact $",
        },
        {
            name: "Revenue_percent",
            label: "Potential Revenue Impact %",
        },
        {
            name: "Hcc_score",
            label: "Reported HCC Score",
        },
        {
            name: "Gap_in_hcc_score",
            label: "Gap in HCC Score",
        }
    ];

    const memberTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(MAO_Chart_Review_Member, rowdata[cellMeta.rowIndex].MEMBER_ID);
            localStorage.setItem(MAO_MEMBER_SELECT_TAB, GAP_INSIGHTS);
            history.push('/layout/medicare/mao_member_summary')
        } else if ((cellMeta.colIndex === 1) && (colData.props.children != "")) {
            if (typeof (colData.props.children) == "object") {
                localStorage.setItem(HCC, (cellMeta.event.target.innerText).replace(',', ''));
            }
            else {
                localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].Reported_hcc);
            }
            history.push('/layout/medicare/reported_hcc')
        } else if ((cellMeta.colIndex === 2) && (colData.props.children != "")) {
            if (typeof (colData.props.children) == "object") {
                localStorage.setItem(HCC, (cellMeta.event.target.innerText).replace(',', ''));
            }
            else {
                localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].Gap_hcc);
            }
            history.push('/layout/medicare/gap_hcc')
        }
    }


    const memberGapColumn = [
        {
            label: "Member ID",
            name: "MEMBER_ID",
            options: anchorTag
        },
        {
            name: "Reported_hcc",
            label: "Reported HCCs",
            options: hccSeperator
        },
        {
            name: "Gap_hcc",
            label: "Gap HCCs",
            options: hccSeperator
        },
        {
            name: "Raf_score",
            label: "RAF Score",
        },
        {
            name: "Gap_in_raf_score",
            label: "Gap In RAF Score",
        },
        {
            name: "Revenu_leekage",
            label: "Potential Revenue Impact $",
        },
        {
            name: "Revenue_percent",
            label: "Potential Revenue Impact %",
        },
        {
            name: "Hcc_score",
            label: "Reported HCC Score",
        },
        {
            name: "Gap_in_hcc_score",
            label: "Gap in HCC Score",
        },
        {
            name: "HCC_CAPTURE_RECOMMENDATION",
            label: "RECOMMENDATION",
        },
        {
            name: "OPPORTUNITY_FLAG",
            label: "OPPORTUNITY FLAG",
            options: textToOpenOrCloseFormatRender
        },
        {
            name: "PROVIDER_NAME",
            label: "PROVIDER NAME",
        },
    ];

    const memberGapTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(MAO_Chart_Review_Member, rowdata[cellMeta.rowIndex].MEMBER_ID);
            localStorage.setItem(MAO_MEMBER_SELECT_TAB, GAP_INSIGHTS);
            history.push('/layout/medicare/mao_member_summary')
        } else if ((cellMeta.colIndex === 1) && (colData.props.children != "")) {
            if (typeof (colData.props.children) == "object") {
                localStorage.setItem(HCC, (cellMeta.event.target.innerText).replace(',', ''));
            }
            else {
                localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].Reported_hcc);
            }
            history.push('/layout/medicare/reported_hcc')
        } else if ((cellMeta.colIndex === 2) && (colData.props.children != "")) {
            if (typeof (colData.props.children) == "object") {
                localStorage.setItem(HCC, (cellMeta.event.target.innerText).replace(',', ''));
            }
            else {
                localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].Gap_hcc);
            }
            history.push('/layout/medicare/gap_hcc')
        }
    }


    const loadLikelihoodPercentageChart = () => {
        let chartDataRow = [];
        chartDataRow.push(["Gap Suspect Likelihood", "Percentage", { role: 'annotation' }]);
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/likelihoodGraph/year/' + getAcaYear() + '/period/' + getMeInitialSelect() + '/member/' + DistributionSelect)
            .then(res => {
                for (const dataObj of res) {
                    chartDataRow.push([dataObj.rafRange, dataObj.percent, dataObj.percent + '%']);
                }
                setLikelihoodPercentageChartData(chartDataRow);
            })
            .catch(err => {
                // console.log(err);
            })

    }

    const loadLikelihoodCountOfMembersChart = () => {
        let chartDataRow = [];
        chartDataRow.push(["Gap Suspect Likelihood", "Member Count", { role: 'annotation' }, { role: 'tooltip' }]);
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/likelihoodGraph/year/' + getAcaYear() + '/period/' + getMeInitialSelect() + '/member/' + DistributionSelect)
            .then(res => {
                for (const dataObj of res) {
                    // chartDataRow.push([dataObj.rafRange, dataObj.countOfMembers, dataObj.percent+'%']);
                    // chartDataRow.push([dataObj.rafRange, dataObj.countOfMembers, dataObj.countOfMembers, dataObj.percent + '%']);
                    //removing % symbol to display count of member
                    chartDataRow.push([dataObj.rafRange, dataObj.countOfMembers, dataObj.countOfMembers, dataObj.countOfMembers]);
                }
                setLikelihoodCountOfMembersChartData(chartDataRow);
            })
            .catch(err => {
                // console.log(err);
            })

    }

    const loadHccCaptureRecommendation = () => {
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/GapRecommendationSummary/' + getAcaYear() + '/period/' + getMeInitialSelect())
            .then(res => {
                setHccCaptureRecommendationData(res);
            })
            .catch(err => {
                // console.log(err)
            })
    }

    const loadPercentageOfGapMemberCount = () => {
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/GapPercentToClose/' + getAcaYear() + '/period/' + getMeInitialSelect())
            .then(res => {
                setPercentageOfGapMemberCountData(res);
            })
            .catch(err => {
                // console.log(err)
            })
    }

    const loadGapMemberDistribution = () => {
        let NewVenChartData = [];
        let NewVenChartLink = [];
        // SuspectMemberGapCount/2019/period/FINAL/member/RISK
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/SuspectMemberGapCount/' + getAcaYear() + '/period/' + getMeInitialSelect() + '/member/' + DistributionSelect)
            .then(res => {
                for (const dataObj of res) {
                    NewVenChartData = [
                        { name: "Historic", key: ["Historic"], data: dataObj.h },
                        { name: "Co-Morbid", key: ["Co-Morbid"], data: dataObj.c },
                        { name: "Lab", key: ["Lab"], data: dataObj.l },
                        { name: "Pharma", key: ["Pharma"], data: dataObj.p },

                        { name: "Co-Morbid,Historic", data: dataObj.ch, key: ["Co-Morbid", "Historic"] },
                        { name: "Historic,Lab", data: dataObj.hl, key: ["Historic", "Lab"] },
                        { name: "Historic,Pharma", data: dataObj.hp, key: ["Historic", "Pharma"] },
                        { name: "Co-Morbid,Lab", data: dataObj.cl, key: ["Co-Morbid", "Lab"] },
                        { name: "Co-Morbid,Pharma", data: dataObj.cp, key: ["Co-Morbid", "Pharma"] },
                        { name: "Lab,Pharma", data: dataObj.lp, key: ["Lab", "Pharma"] },

                        { name: "Co-Morbid,Historic,Lab", data: dataObj.chl, key: ["Co-Morbid", "Historic", "Lab"] },
                        { name: "Co-Morbid,Historic,Pharma", data: dataObj.chp, key: ["Co-Morbid", "Historic", "Pharma"] },
                        { name: "Co-Morbid,Lab,Pharma", data: dataObj.clp, key: ["Co-Morbid", "Lab", "Pharma"] },
                        { name: "Historic,Lab,Pharma", data: dataObj.hlp, key: ["Historic", "Lab", "Pharma"] },

                        { name: "Co-Morbid,Historic,Lab,Pharma", data: dataObj.chlp, key: ["Co-Morbid", "Historic", "Lab", "Pharma"] }
                    ];
                    NewVenChartLink = [
                        { name: "Historic", link: 'H', color: '#6db382', index: 0 },
                        { name: "Co-Morbid", link: 'C', color: '#7c7fe4', index: 1 },
                        { name: "Lab", link: 'L', color: '#ff85ad', index: 2 },
                        { name: "Pharma", link: 'P', color: '#d3b17e', index: 3 },

                        { name: "Co-Morbid|Historic", link: 'CH', color: '#d143a1', index: 4 },
                        { name: "Historic|Lab", link: 'HL', color: '#d143a1', index: 5 },
                        { name: "Historic|Pharma", link: 'HP', color: '#aa4b3e', index: 6 },
                        { name: "Co-Morbid|Lab", link: 'CL', color: '#d143a1', index: 7 },
                        { name: "Co-Morbid|Pharma", link: 'CP', color: '#bb544d', index: 8 },
                        { name: "Lab|Pharma", link: 'LP', color: '#db714c', index: 9 },

                        { name: "Historic|Co-Morbid|Lab", link: 'CHL', color: '#aa4b3e', index: 10 },
                        { name: "Co-Morbid|Historic|Pharma", link: 'CHP', color: '#aa4b3e', index: 11 },
                        { name: "Co-Morbid|Lab|Pharma", link: 'CLP', color: '#a04c3f', index: 12 },
                        { name: "Historic|Lab|Pharma", link: 'HLP', color: '#aa4b3e', index: 13 },

                        { name: "Co-Morbid|Historic|Lab|Pharma", link: 'CHLP', color: '#f35555', index: 14 },

                    ];
                }
                setVenChartData(NewVenChartData);
                setVenChartLink(NewVenChartLink);
            })
            .catch(err => {
                // console.log(err);
            })

    };

    const HccCaptureRecommendationTableClick = (colData, cellMeta, data, columns) => {
        if ((cellMeta.colIndex === 1) && (colData.props.children != "")) {
            localStorage.setItem(RECOMMENDATION_TYPE, data[cellMeta.rowIndex].hccCaptureReco);
            history.push('/layout/medicare/hcc_capture_reco_drill');
        }
    };


    const getHeadingForOrgIssuer = (flag) => {
        let Heading = '';
        if (flag == 'H')
            Heading = "Members with only Historic Gaps";
        if (flag == 'C')
            Heading = "Members with only Co-Morbid Gaps";
        if (flag == 'L')
            Heading = "Members with only Lab Gaps";
        if (flag == 'P')
            Heading = "Members with only Pharma Gaps";
        if (flag == 'CH')
            Heading = "Members with Historic & Comorbid Gaps";
        if (flag == 'HL')
            Heading = "Members with Historic & Lab Gaps";
        if (flag == 'HP')
            Heading = "Members with Historic & Pharma Gaps";
        if (flag == 'CL')
            Heading = "Members with Comorbid & Lab Gaps";
        if (flag == 'CP')
            Heading = "Members with Comorbid & Pharma Gaps";
        if (flag == 'LP')
            Heading = "Members with Lab & Pharma Gaps";
        if (flag == 'CHL')
            Heading = "Members with Historic,Comorbid & Lab Gaps";
        if (flag == 'CHP')
            Heading = "Members with Historic,Comorbid & Pharma Gaps";
        if (flag == 'CLP')
            Heading = "Members with Comorbid,Lab & Pharma Gaps";
        if (flag == 'HLP')
            Heading = "Members with Historic,Pharma & Lab Gaps";
        if (flag == 'CHLP')
            Heading = "Members with Historic,Comorbid,Lab & Pharma Gaps";

        return Heading;
    }

    //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            loadWidgetDetails();
            loadGapMemberDistribution();
            loadLikelihoodPercentageChart();
            loadLikelihoodCountOfMembersChart();
            loadHccCaptureRecommendation();
            loadPercentageOfGapMemberCount();
        }
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear()
    ]);

    useEffect(() => {
        loadGapMemberDistribution();
        loadLikelihoodPercentageChart();
        loadLikelihoodCountOfMembersChart();
    }, [
        DistributionSelect,
    ]);

    useEffect(() => {
        setDistributionSelect(localStorage.getItem(SELECT_DISTRIBUTION));
    }, [props.distributionSelect]);

    const executeScroll = () => {
        myRefChartData.current.scrollIntoView();
    }

    useEffect(() => {
        executeScroll();
    }, [gapType]);

    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card-header bg-white">
                        <SubHeader title={''} />
                    </div>
                    <SlickSlider WidgetDetails={WidgetDetails} getIcon={getIcon} type={type} />
                </div>
                <div className="row col-lg-12 mt-4">
                    <div className="col-lg-6">
                        <CardComponent
                            heading={`DISTRIBUTION OF MEMBERS BASED ON GAPS SOURCE`}
                            body={
                                <div style={{ height: "687px" }}>
                                    <div className="mb-2" style={{ height: '20px' }}>{active !== null && (<p>{'Selected Item : ' + active}</p>)}</div>
                                    <div className="d-flex justify-content-center">
                                        <div style={{ margin: '5px', textAlign: 'center', cursor: 'pointer' }}>
                                            <VennDiagram
                                                type="starEuler"
                                                height={230}
                                                width={490}
                                                data={venChartData}
                                                series={
                                                    <VennSeries
                                                        colorScheme={(data, index) => {
                                                            var color_change = '';
                                                            venChartLink && venChartLink.map((va, ind) => {
                                                                if (index == va.index) {
                                                                    color_change = va.color;
                                                                }
                                                            })
                                                            return color_change;
                                                            // return index % 2 ? 'red' : 'blue';
                                                        }}
                                                        arc={
                                                            <VennArc
                                                                tooltip={false}
                                                                stroke={(data, index, active, hovered) => {
                                                                    if (hovered) {
                                                                        return 'blue';
                                                                    } else if (active) {
                                                                        return 'green';
                                                                    }
                                                                    return 'white';
                                                                }}
                                                                onMouseEnter={({ value }) => {
                                                                    setActive(`${value.sets.join(' & ')} - ${value.size.toLocaleString()}`);
                                                                }}
                                                                onMouseLeave={() => setActive(null)}
                                                                onClick={(data, index) => {
                                                                    var data_click = '';
                                                                    venChartLink && venChartLink.map((va, ind) => {
                                                                        if (data.value.key == va.name) {
                                                                            data_click = va.link;
                                                                        }
                                                                    })
                                                                    localStorage.setItem(FLAG, data_click);
                                                                    // localStorage.setItem(GAP_OR_REPORTED, props.type);
                                                                    setGapType(data_click);
                                                                    // history.push('/layout/medicare/suspect_member_list');
                                                                    return '';
                                                                }}
                                                            />
                                                        }
                                                        label={<VennLabel labelType="value" showAll={true} />}
                                                    />
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            }></CardComponent>
                    </div>
                    <div className="col-lg-6">
                        <div>
                            <div className="mb-3">
                                <CardComponent
                                    heading={`HCC CAPTURE RECOMMENDATION`}
                                    body={
                                        <div className="mt-1 mb-1" style={{ height: "240px", overflow: "auto" }}>
                                            <GridTable
                                                data={HccCaptureRecommendationData}
                                                columns={HccCaptureRecommendationColumns}
                                                onTableClick={HccCaptureRecommendationTableClick}
                                            ></GridTable>
                                        </div>
                                    }></CardComponent>
                            </div>
                            <div>
                                <CardComponent
                                    heading={`PERCENTAGE OF GAP BASED ON MEMBER COUNT`}
                                    body={
                                        <div className="mt-1 mb-1" style={{ height: "350px", overflow: "auto" }}>
                                            <GridTable
                                                data={PercentageOfGapMemberCountData}
                                                columns={PercentageOfGapMemberCountColumns}
                                            ></GridTable>
                                        </div>
                                    }></CardComponent>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12" ref={myRefChartData}>
                    {gapType != '' ?
                        <div className="mt-4">
                            <div className="list-member mt-2">
                                <CardComponent
                                    heading={`${getHeadingForOrgIssuer(localStorage.getItem(FLAG))} - ${props.yearSelect} / ${props.meInitialSelect}`}
                                    body={
                                        <ServerSideGridTable
                                            url={`SuspectMemberGapDetail/year/` + getAcaYear() + `/flag/` + gapType + `/period/` + getMeInitialSelect() + `/member/ALL`}
                                            columns={memberGapColumn}
                                            tableClick={memberGapTableClick}
                                        ></ServerSideGridTable>
                                    }>
                                </CardComponent>
                            </div>
                        </div>
                        :
                        <></>
                    }
                </div>
                <div className="row col-lg-12 mt-4">
                    <div className="col-lg-6">
                        <CardComponent
                            heading={`PERCENTAGE OF GAP BASED ON GAP SUSPECT LIKELIHOOD`}
                            body={
                                <GChartClick data={LikelihoodPercentageChartData} chartType="ColumnChart" height="300px" width="100%" setStorgeItemName={LIKELIHOOD_FLAG} redirectUrl={'medicare/suspect_hcc_drill_down'} hAxisTitle={"Gap Suspect Likelihood"} yAxistitle={"Percentage (%)"}></GChartClick>
                                // <GChart data={LikelihoodPercentageChartData} chartType="ColumnChart" height="300px" width="100%"  hAxisTitle={"Gap Suspect Likelihood"} yAxistitle={"Percentage (%)"}></GChart>
                            }></CardComponent>
                    </div>
                    <div className="col-lg-6">
                        <CardComponent
                            heading={`DISTRIBUTION OF MEMBERS BASED ON GAP SUSPECT LIKELIHOOD`}
                            body={
                                <GChartClick data={LikelihoodCountOfMembersChartData} chartType="ColumnChart" height="300px" width="100%" setStorgeItemName={LIKELIHOOD_FLAG} redirectUrl={'medicare/suspect_hcc_drill_down'} hAxisTitle={"Gap Suspect Likelihood"} yAxistitle={"Member Count"}></GChartClick>
                                // <GChart data={LikelihoodCountOfMembersChartData} chartType="ColumnChart" height="300px" width="100%"  hAxisTitle={"Gap Suspect Likelihood"} yAxistitle={"Member Count"}></GChart>
                            }></CardComponent>
                    </div>
                </div>
                {/* <div className='col-lg-12'>
                    <div className="list-member mt-4">
                        <CardComponent
                            heading={`MEMBER LIST`}
                            body={
                                    <ServerSideGridTable
                                        url={`SuspectMember/year/`+props.yearSelect+`/period/`+props.meInitialSelect+`/member/ALL/max/929.7`}
                                        columns={memberColumn}
                                        tableClick={memberTableClick}
                                    ></ServerSideGridTable>
                                }>
                        </CardComponent>
                    </div>
                </div> */}
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br />

                <br />
                <br />
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
        distributionSelect: state.moduleFilter.distributionSelect,
    };
}
export default connect(mapStateToProps)(MaoGapMemberSummaryTab);