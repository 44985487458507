
/**
 * @author ih-sonalPD-61 
 * description : Expand row for muidata table (METRIC)
 *
 */

import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { CircularProgress } from '@material-ui/core';
import ExpandableRow from "./ExpandRowTree.page";
const EncounterSummaryExpand = (props) => {

    const { title, data, year, columns, ppg, onTableClick, className, isLoading = false, pagination = false } = props;

    const options = {
        selectableRows: false,
        pagination: props.pagination ? props.pagination : false,
        print: props.print ? props.print : false,
        searchOpen: props.searchOpen ? props.searchOpen : false,
        filter: props.filter ? props.filter : false,
        searchIcon: props.searchIcon ? props.searchIcon : false,
        download: props.download ? props.download : false,
        search: props.search ? props.search : false,
        viewColumns: props.viewColumns ? props.viewColumns : false,
        downloadOptions: {
            filename: 'Download.csv'
        },
        responsive: 'stacked',
        tableBodyHeight: 'auto',
        tableBodyMaxHeight: '900px',
        expandableRows: true,
        expandableRowsHeader: false,

        renderExpandableRow: (rowData, rowMeta) => {

            const colSpan = rowData.length + 1;
            /* Calling ExpandRowTree, 
                passing props: rowData, year, ppg
             */
            return (
                <ExpandableRow
                    rowData={rowData}
                    year={year}
                    ppg={ppg}
                    colSpan={colSpan}>
                </ExpandableRow>
            );
        },
        onCellClick: (colData, cellMeta) => {
            if (props.onTableClick) {
                onTableClick(colData, cellMeta, data, columns);
            }
        },
    }

    return (
        <div className={className}>
            {data.length > 0 ?
                <MUIDataTable
                    title={title}
                    data={data}
                    columns={columns}
                    options={options}
                    className={"pb-5"}
                />
                :
                isLoading ? <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                    <CircularProgress />
                </div> : <h6 className="text-center">No records found!..</h6>
            }
        </div>
    );

}

EncounterSummaryExpand.propTypes = {
    columns: PropTypes.array,
    url: PropTypes.string,
    tableClick: PropTypes.func,
    defaultSortCol: PropTypes.number,
    defaultSortDir: PropTypes.string
}

export default EncounterSummaryExpand;
