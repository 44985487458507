import React, { useState, useEffect } from 'react';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import adminApi from '../../assets/constants/AdminRafservice.Instance';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import { connect } from 'react-redux';
import MaterialSelect from '@material-ui/core/Select';
import Select, { components } from "react-select";
import { useForm, Controller } from "react-hook-form";
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import { toast } from 'react-toastify';

const styles = (theme) => ({
    dialogPaper: {
        maxWidth: '80%',
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const ViewRole = (props) => {
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [year, setYear] = useState(props.yearSelect);
    const [open, setOpen] = useState();
    const [expanded, setExpanded] = React.useState([]);

    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };

    const handleExpandClick = () => {
        // setExpanded((oldExpanded) =>
        //     oldExpanded.length === 0 ? ['1', '5', '6', '7'] : [],
        // );
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    useEffect(() => {
        // console.log(props.editRoleData);
    }, [props.editRoleData]);
    var treeIndex = 0;
    return (
        <>
            <MuiDialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogTitle id="create-user" onClose={props.handleClose}>
                    View Role
                </DialogTitle>
                <form id="add-verification-model">
                    <DialogContent >
                        <div className="d-flex align-items-center mt-3">
                            <Box sx={{ height: 270, flexGrow: 1, overflowY: 'auto' }}>
                                {/* <Box sx={{ mb: 1 }}>
                                    <Button onClick={handleExpandClick}>
                                        {expanded.length === 0 ? 'Expand all' : 'Collapse all'}
                                    </Button>
                                </Box> */}
                                <TreeView
                                    aria-label="controlled"
                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                    defaultExpandIcon={<ChevronRightIcon />}
                                    expanded={expanded}
                                    onNodeToggle={handleToggle}
                                    multiSelect
                                >

                                    {props.editRoleData && props.editRoleData.id ?
                                        <TreeItem nodeId={treeIndex += 1} label={props.editRoleData.title + ' (Role)'}>
                                            {props.editRoleData.permissions && props.editRoleData.permissions.length > 0 ?
                                                props.editRoleData.permissions.map((elementPermission) => (
                                                    <TreeItem nodeId={treeIndex += 1} label={elementPermission?.title + ' (Permission)'}>
                                                        {elementPermission.menus && elementPermission.menus.length > 0 ?
                                                            elementPermission.menus.map((elementMenu, index) => (
                                                                <TreeItem nodeId={treeIndex += 1} label={(index + 1) + '. ' + elementMenu?.title + ' (Menu) (Module Name :- ' + elementMenu?.module?.title + ')'}>
                                                                </TreeItem>
                                                            ))
                                                            :
                                                            <></>
                                                        }
                                                    </TreeItem>
                                                ))
                                                :
                                                <></>
                                            }
                                        </TreeItem>
                                        :
                                        <></>
                                    }
                                    {/* <TreeItem nodeId="5" label="Documents">
                                        <TreeItem nodeId="6" label="MUI">
                                            <TreeItem nodeId="7" label="src">
                                                <TreeItem nodeId="8" label="index.js" />
                                                <TreeItem nodeId="9" label="tree-view.js" />
                                            </TreeItem>
                                        </TreeItem>
                                    </TreeItem> */}
                                </TreeView>
                            </Box>
                        </div>
                    </DialogContent>
                    <DialogActions className='mt-2'>
                        <Button type='button' variant="contained" color="grey" onClick={props.handleClose}>Cancel</Button>
                    </DialogActions>
                </form>
            </MuiDialog>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default (withStyles(styles), connect(mapStateToProps))(ViewRole);
