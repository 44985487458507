import React from 'react'

export default function BigArrowRightIcon({ ...props }) {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1926_1096)">
                <path fillRule="evenodd" clipRule="evenodd" d="M6.41907 23.7204C5.91589 23.303 5.8576 22.5699 6.28891 22.0829L15.2195 12L6.28891 1.91709C5.85762 1.43012 5.91589 0.696964 6.41907 0.27952C6.92227 -0.13777 7.67982 -0.0814089 8.11113 0.405558L17.7112 11.2443C18.0963 11.6792 18.0963 12.3208 17.7112 12.7558L8.11113 23.5944C7.67982 24.0814 6.92225 24.1378 6.41907 23.7204Z" fill="currentcolor" />
            </g>
            <defs>
                <clipPath id="clip0_1926_1096">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}