import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { Button, CircularProgress } from '@material-ui/core';

const GridTable = (props) =>{
    const{count, data, columns, tableClick, className,isLoading=false } = props;
    // const handleRowClick = (rowData, rowMeta) => {
    //     console.log("rowData" ,rowData, "rowMeta", rowMeta);
    // };

    const options = {
        selectableRows: false,
        sort: false,
        // selectableRowsOnClick: true,
        pagination:props.pagination?props.pagination:false,
        print:props.print ? props.print :false,
        searchOpen:props.searchOpen ? props.searchOpen : false,
        filter: props.filter ? props.filter : false,
        searchIcon : props.searchIcon ? props.searchIcon : false,
        download:props.download ? props.download : false,
        search:props.search ? props.search : false,
        viewColumns:props.viewColumns ? props.viewColumns : false,     
        downloadOptions :{
            filename : 'Download.csv'
        },
        
      count:count,
        responsive: 'stacked',
        tableBodyHeight: 'auto',
        tableBodyMaxHeight:'800px',
        //onRowClick: handleRowClick,
      //  onRowsSelect :handleRowClick
      onCellClick:(colData, cellMeta) => {
        //    console.log(colData,cellMeta);
          if(props.tableClick){
              tableClick(data,colData, cellMeta);
          }
      
      },
  
    }    

    return(
        <div className={className}>
             {data.length>0?
                <MUIDataTable
                    data={data}
                    columns={columns}
                    options={options}
                />
            :
            isLoading ? <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
            <CircularProgress />
          </div>    : <h6 className="text-center">No records found!..</h6>
            }
        </div>
    );

}

GridTable.propTypes ={
    count : PropTypes.array,
    data : PropTypes.array,
    columns : PropTypes.array,
    tableClick : PropTypes.func,
    pagination:PropTypes.bool,
    isLoading:PropTypes.bool
}

export default GridTable;