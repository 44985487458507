import { useState, useEffect } from 'react';
import { anchorTag, numberWithCommas, hccSeperator, textToSignFormatRender, dateFormatRender } from '../../assets/constants/customRender';
import { getAcaIssuer, getAcaIssuerList } from '../../assets/constants/issuerList';
import api from '../../assets/constants/RafserviceKPI.Instance';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import SingleHorizontalBar from '../../components/Charts/SingleHorizontalBar.chart';
import AcaIssuersSelect from '../../components/DropDowns/AcaIssuersSelect';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import history from '../../history';
import ServerSideGridTable from '../../components/TableGrids/ServerSideGridTable.table';
import { HCC, PROVIDER_ID, MAO_Chart_Review_Member, MAO_TYPE, RAF_RANGE, KPI_CATEGORY,KPI_PPG } from '../../assets/constants/string.constants';
import CardComponent from '../../components/Cards/card';
import BarVertical from '../../components/GChart/BarVertical';
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';
import GridTable from "../../components/TableGrids/GridTable.table";
import GaugeChart from 'react-gauge-chart'

const HealthcareInsightsPage = (props) => {
    const [memberSummaryDataAEV, setMemberSummaryDataAEV] = useState([]);
    const [memberSummaryDataAWV, setMemberSummaryDataAWV] = useState([]);
    const [memberSummaryDataHCC, setMemberSummaryDataHCC] = useState([]);
    const [memberSummaryDataLongHCC, setMemberSummaryDataLongHCC] = useState([]);
    const [memberSummaryChartDataAEV, setMemberSummaryChartDataAEV] = useState(0);
    const [memberSummaryChartDataAWV, setMemberSummaryChartDataAWV] = useState(0);
    const [memberSummaryChartDataHCC, setMemberSummaryChartDataHCC] = useState(0);
    const [memberSummaryChartDataLongHCC, setMemberSummaryChartDataLongHCC] = useState(0);
    const [memberSummaryObjectAEV, setMemberSummaryObjectAEV] = useState({});
    const [memberSummaryObjectAWV, setMemberSummaryObjectAWV] = useState({});
    const [memberSummaryObjectHCC, setMemberSummaryObjectHCC] = useState({});
    const [memberSummaryObjectLongHCC, setMemberSummaryObjectLongHCC] = useState({});
    const [AEVSliderSet, setAEVSliderSet] = useState(0);

    const memberSummaryColumn = [
        {
            label: "# Members Eligible",
            name: "TOTAL Members Eligible"
        },
        {
            label: "# Members Completed",
            name: "Total Members Completed"
        },
        {
            label: "% Completed ",
            name: "Completed"
        },
        {
            label: "# Providers Below Average",
            name: "Total Providers Below Average"
        },
        {
            label: "# Providers Above Average",
            name: "Total Providers Above Average"
        },
    ];

    const loadDataCount = () => {
        api.get('/all-data')
            .then(res => {
                if (res.data) {
                    setMemberSummaryDataAWV([res.data[0]]);
                    setMemberSummaryDataAEV([res.data[30]]);
                    setMemberSummaryDataHCC([res.data[15]]);
                    setMemberSummaryDataLongHCC([res.data[46]]);

                    setMemberSummaryObjectAWV(res.data[0]);
                    setMemberSummaryObjectAEV(res.data[30]);
                    setMemberSummaryObjectHCC(res.data[15]);
                    setMemberSummaryObjectLongHCC(res.data[46]);

                    setMemberSummaryChartDataAWV(res.data[0]?.Completed.replace('%', "") / 100);
                    setMemberSummaryChartDataAEV(res.data[30]?.Completed.replace('%', "") / 100);
                    setMemberSummaryChartDataHCC(res.data[15]?.Completed.replace('%', "") / 100);
                    setMemberSummaryChartDataLongHCC(res.data[46]?.Completed.replace('%', "") / 100);
                }

            })
            .catch(err => {
                // console.log(err)
            })
    }
    const chartStyle = {
        height: 'auto',
        width: 200,
    }

    const KPIchartClick = (KPITitle, KPIPPG) => {
       // console.log(KPITitle);
       // console.log(KPIPPG);
        localStorage.setItem(KPI_CATEGORY, KPITitle);
        localStorage.setItem(KPI_PPG, KPIPPG);
        history.push('/layout/healthcare/provider_group');
    }

    useEffect(() => {
        loadDataCount();

        // console.log(HCCMODELData);
        // console.log(PCCMODELData);
        // console.log(BLENDEDMODELData);
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect,
        props.kpiModuleSelect,
    ])


    return (
        <div className="level-summary">
            <div className="row pb-2">
                <div className="col-lg-12">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Plan Performance Dashboard'} selectType={'KPL'} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="hcc-list pb-5">
                <div className="row mt-3">
                    <div className="col-lg-6">
                        <CardComponent
                            heading={`Preventive Visits (AWV - Preventive Visits)`}
                            body={
                                <>
                                    <div className='d-flex justify-content-center'>
                                        <div onClick={() => KPIchartClick('AWV', 'ALL')}  style={{'cursor': 'pointer'}}>
                                            <GaugeChart id="gauge-chart6"
                                                animate={false}
                                                nrOfLevels={10}
                                                percent={memberSummaryChartDataAWV}
                                                needleColor="#345243"
                                                className="mb-3"
                                                style={chartStyle}
                                                textColor={"#000000"}
                                                arcsLength={[0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1]}
                                                colors={["#FF0000", "#00FF00"]}
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        {memberSummaryObjectAWV && memberSummaryObjectAWV.Completed && memberSummaryObjectAWV["Y-O-Y Change"].includes("-") > 0 ?
                                            <h3><b>{memberSummaryObjectAWV.Completed + ' (Decreased by ' + memberSummaryObjectAWV["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                            : memberSummaryObjectAWV && memberSummaryObjectAWV.Completed ?
                                                <h3><b>{memberSummaryObjectAWV.Completed + ' (Increased by ' + memberSummaryObjectAWV["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                :
                                                <></>
                                        }
                                    </div>
                                    <GridTable
                                        data={memberSummaryDataAWV}
                                        columns={memberSummaryColumn}
                                    ></GridTable>
                                </>
                            }>
                        </CardComponent>
                    </div>
                    <div className="col-lg-6">
                        <CardComponent
                            heading={`Low Value Care (AEV - Low Value Care)`}
                            body={
                                <>
                                    <div className='d-flex justify-content-center'>
                                        <div onClick={() => KPIchartClick('AED', 'ALL')}  style={{'cursor': 'pointer'}}>
                                            <GaugeChart id="gauge-chart6"
                                                animate={false}
                                                nrOfLevels={10}
                                                percent={memberSummaryChartDataAEV}
                                                needleColor="#345243"
                                                className="mb-3"
                                                style={chartStyle}
                                                textColor={"#000000"}
                                                arcsLength={[0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1]}
                                                colors={["#FF0000", "#00FF00"]}
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        {memberSummaryObjectAEV && memberSummaryObjectAEV.Completed && memberSummaryObjectAEV["Y-O-Y Change"].includes("-") > 0 ?
                                            <h3><b>{memberSummaryObjectAEV.Completed + ' (Decreased by ' + memberSummaryObjectAEV["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                            : memberSummaryObjectAEV && memberSummaryObjectAEV.Completed ?
                                                <h3><b>{memberSummaryObjectAEV.Completed + ' (Increased by ' + memberSummaryObjectAEV["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                :
                                                <></>
                                        }
                                    </div>
                                    <GridTable
                                        data={memberSummaryDataAEV}
                                        columns={memberSummaryColumn}
                                    ></GridTable>
                                </>
                            }>
                        </CardComponent>
                    </div>
                </div>
                <div className="row mt-3 mb-3">
                    <div className="col-lg-6">
                        <CardComponent
                            heading={`HCC Capture Rate (HCC - Member Count)`}
                            body={
                                <>
                                    <div className='d-flex justify-content-center'>
                                        <div onClick={() => KPIchartClick('HCC', 'ALL')} style={{'cursor': 'pointer'}}>
                                            <GaugeChart id="gauge-chart6"
                                                animate={false}
                                                nrOfLevels={10}
                                                percent={memberSummaryChartDataHCC}
                                                needleColor="#345243"
                                                className="mb-3"
                                                style={chartStyle}
                                                textColor={"#000000"}
                                                arcsLength={[0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1]}
                                                colors={["#FF0000", "#00FF00"]}
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        {memberSummaryObjectHCC && memberSummaryObjectHCC.Completed && memberSummaryObjectHCC["Y-O-Y Change"].includes("-") > 0 ?
                                            <h3><b>{memberSummaryObjectHCC.Completed + ' (Decreased by ' + memberSummaryObjectHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                            : memberSummaryObjectHCC && memberSummaryObjectHCC.Completed ?
                                                <h3><b>{memberSummaryObjectHCC.Completed + ' (Increased by ' + memberSummaryObjectHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                :
                                                <></>
                                        }
                                    </div>
                                    <GridTable
                                        data={memberSummaryDataHCC}
                                        columns={memberSummaryColumn}
                                    ></GridTable>
                                </>
                            }>
                        </CardComponent>
                    </div>
                    <div className="col-lg-6">
                        <CardComponent
                            heading={`HCC Capture Rate (Life Long HCC - HCC Capture Rate)`}
                            body={
                                <>
                                    <div className='d-flex justify-content-center'>
                                        <div onClick={() => KPIchartClick('LFHCC', 'ALL')} style={{'cursor': 'pointer'}}>
                                            <GaugeChart id="gauge-chart6"
                                                animate={false}
                                                nrOfLevels={10}
                                                percent={memberSummaryChartDataLongHCC}
                                                needleColor="#345243"
                                                className="mb-3"
                                                style={chartStyle}
                                                textColor={"#000000"}
                                                arcsLength={[0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1]}
                                                colors={["#FF0000", "#00FF00"]}
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        {memberSummaryObjectLongHCC && memberSummaryObjectLongHCC.Completed && memberSummaryObjectLongHCC["Y-O-Y Change"].includes("-") > 0 ?
                                            <h3><b>{memberSummaryObjectLongHCC.Completed + ' (Decreased by ' + memberSummaryObjectLongHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                            : memberSummaryObjectLongHCC && memberSummaryObjectLongHCC.Completed ?
                                                <h3><b>{memberSummaryObjectLongHCC.Completed + ' (Increased by ' + memberSummaryObjectLongHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                :
                                                <></>
                                        }
                                    </div>
                                    <GridTable
                                        data={memberSummaryDataLongHCC}
                                        columns={memberSummaryColumn}
                                    ></GridTable>
                                </>
                            }>
                        </CardComponent>
                    </div>
                </div>
            </div>
            <div className="hcc-list pb-5 mb-3">
            </div>
            <br />
            <br />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
        kpiModuleSelect: state.moduleFilter.kpiModuleSelect,
    };
}
export default connect(mapStateToProps)(HealthcareInsightsPage);
