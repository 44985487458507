import React, { useRef, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../store/actions';
import AWVCoderWorkBench from '../../assets/constants/AWVCoderWorkBenchRafservice.Instance';
import Divider from '@mui/material/Divider';
import Moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import { CircularProgress } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { styled } from '@mui/material/styles';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Autocomplete from "@mui/material/Autocomplete";
import CWB_icd_hcc_json from "../../Pages/config/CPT_code_list.json";

const EditCWPROCCODECoderWorkBench = (props) => {
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [formDataList, setFormDataList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
    const [editFormData, setEditFormData] = useState({});
    const [dxCodeDataArray, setDxCodeDataArray] = useState([]);
    const [dxCodeDataArrayPush, setDxCodeDataArrayPush] = useState([]);
    const [dxCodeData, setDxCodeData] = useState([]);
    const [isdxCodeValue, setIsDxCodeValues] = useState(false);
    const [dxCodeValue, setDxCodeValues] = useState();
    const [modifier1CodeData, setModifier1CodeData] = useState([]);
    const [modifier2CodeData, setModifier2CodeData] = useState([]);
    const [modifier3CodeData, setModifier3CodeData] = useState([]);
    const [modifier4CodeData, setModifier4CodeData] = useState([]);
    const [modifier1CodeValue, setModifier1CodeValue] = useState();
    const [modifier2CodeValue, setModifier2CodeValue] = useState();
    const [modifier3CodeValue, setModifier3CodeValue] = useState();
    const [modifier4CodeValue, setModifier4CodeValue] = useState();
    const [ismodifier1CodeValue, setIsModifier1CodeValue] = useState(false);
    const [ismodifier2CodeValue, setIsModifier2CodeValue] = useState(false);
    const [ismodifier3CodeValue, setIsModifier3CodeValue] = useState(false);
    const [ismodifier4CodeValue, setIsModifier4CodeValue] = useState(false);
    const [dxCodeDOSBegin, setDxCodeDOSBegin] = useState();
    useEffect(() => {
        console.log(errors);
    }, [errors]);

    useEffect(async () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const cin = urlParams.get('cin');
        setValue('ih_member_id', cin);
        //setValue('dos_begin', Moment().format("YYYY-MM-DD"));
        //setDxCodeDOSBegin(Moment().format("YYYY-MM-DD"));
        /*
        const dataArray = await Object.entries(CWB_icd_hcc_json).map(([key, value]) => {
            return { dx_code: key, ...value };
        });*/

        // const dataArray = CWB_icd_hcc_json
        // let valuesArray = [];
        // await dataArray.forEach((element, index) => {
        //     if (!valuesArray.includes(element.HCPCS + '-' + element.DESCRIPTION)) {
        //         valuesArray.push(element.HCPCS + '-' + element.DESCRIPTION);
        //     }
        // });
        // await setDxCodeDataArray(dataArray);
        // await setDxCodeDataArrayPush(valuesArray);
        // await loadDxCodeData(valuesArray, '');

    }, []);

    useEffect(() => {
        if (props?.Id) {
            loadformDataTable(props.Id);
            editLoadFormData(props.Id);
        }
    }, [props?.Id]);

    useEffect(() => {
        if (props?.Id && editFormData?.id && editFormData?.id != '') {
            setValue('id', editFormData?.id);
            setValue('page_no', editFormData?.page_no);
            setValue('ih_member_id', editFormData?.ih_member_id);
            setValue('proc_code', editFormData.proc_code);
            setDxCodeValues(editFormData.proc_code);
            setValue('entity_id', editFormData?.entity_id);
            setValue('entity_text', editFormData?.entity_text);
            setValue('remarks', editFormData?.remarks);

            setValue('modifier_1', editFormData?.modifier_1);
            setModifier1CodeValue(editFormData?.modifier_1);
            setValue('modifier_2', editFormData?.modifier_2);
            setModifier2CodeValue(editFormData?.modifier_2);
            setValue('modifier_3', editFormData?.modifier_3);
            setModifier3CodeValue(editFormData?.modifier_3);
            setValue('modifier_4', editFormData?.modifier_4);
            setModifier4CodeValue(editFormData?.modifier_4);
            setValue('dos_begin', Moment(editFormData?.dos_begin).format("YYYY-MM-DD"));
            setValue('dos_end', Moment(editFormData?.dos_end).format("YYYY-MM-DD"));
            setDxCodeDOSBegin(Moment(editFormData?.dos_begin).format("YYYY-MM-DD"));
            setIsDxCodeValues(true);
            setIsModifier1CodeValue(true);
            setIsModifier2CodeValue(true);
            setIsModifier3CodeValue(true);
            setIsModifier4CodeValue(true);
            console.log(editFormData);
            console.log(getValues());
        }
    }, [editFormData]);

    const notificationRightDrawer = (open, valueId = '') => {
        props.currentAWERightSidebarType({ 'type': 'notification' });
        props.currentAWERightSidebar(open);
    };

    //load DX codes from JSON
    const handleChangeDxCode = (eventValue) => {
        const result = dxCodeDataArray.find((element) => element.hcpcs + '-' + element.description === eventValue);
        setValue('proc_code', eventValue);
        setDxCodeValues(eventValue);
        if (result) {
            setIsDxCodeValues(true);
            setValue('proc_description', result?.description);
            //setValue('proc_code', result?.hcpcs);
            //setValue('HCC_V28', result?.hcc_v28 + '-' + result?.hcc_v28_description);
        }
        setDxCodeData([]);
    }

    const handleInputChangeDxCode = (eventValue) => {
        setIsDxCodeValues(false);
        if (eventValue.length >= 3) {

        } else {
            setDxCodeDataArray([]);
            let valuesArray = [];
            setValue('proc_code', eventValue);
            setDxCodeValues(eventValue);
            loadDxCodeData(valuesArray, eventValue);
            setIsLoading(false);

            setValue('proc_code', eventValue);
            setDxCodeValues(eventValue);
            return true;
        }

        setIsLoading(true);
        console.log(eventValue);
        var data = { 'search': eventValue };
        AWVCoderWorkBench.post('/data/cpt_code', data)
            .then(res => {
                setDxCodeDataArray(res.data);
                let valuesArray = [];
                res.data.forEach((element, index) => {
                    if (!valuesArray.includes(element.hcpcs + '-' + element.description)) {
                        valuesArray.push(element.hcpcs + '-' + element.description);
                    }
                });
                setValue('proc_code', eventValue);
                setDxCodeValues(eventValue);
                loadDxCodeData(valuesArray, eventValue);
                setIsLoading(false);
            })
            .catch(err => {
                setDxCodeDataArray([]);
                let valuesArray = [];
                setValue('proc_code', eventValue);
                setDxCodeValues(eventValue);
                loadDxCodeData(valuesArray, eventValue);
                setIsLoading(false);
            })
    }

    const loadDxCodeData = (dxCodeDataArrayPush, dxCodeValue) => {
        let values = [];
        if (dxCodeDataArrayPush && dxCodeDataArrayPush.length > 0 && dxCodeValue) {
            dxCodeDataArrayPush.forEach((element, index) => {
                if (!values.includes(element) && (element.toLowerCase()).includes(dxCodeValue.toLowerCase())) {
                    values.push(element);
                }
            });
            setDxCodeData(values);
        }else{
            setDxCodeData(values);
        }
    };

    const handleInputChangeModifierCode = (eventValue, modifireType) => {
        if (modifireType == '1') {
            setValue('modifier_1', eventValue);
            setModifier1CodeValue(eventValue);
            setIsModifier1CodeValue(false);
        } else if (modifireType == '2') {
            setValue('modifier_2', eventValue);
            setModifier2CodeValue(eventValue);
            setIsModifier2CodeValue(false);
        } else if (modifireType == '3') {
            setValue('modifier_3', eventValue);
            setModifier3CodeValue(eventValue);
            setIsModifier3CodeValue(false);
        } else if (modifireType == '4') {
            setValue('modifier_4', eventValue);
            setModifier4CodeValue(eventValue);
            setIsModifier4CodeValue(false);
        }
        if (eventValue.length >= 2) {

        } else {
            if (modifireType == '1') {
                setModifier1CodeData([]);
            } else if (modifireType == '2') {
                setModifier1CodeData([]);
            } else if (modifireType == '3') {
                setModifier1CodeData([]);
            } else if (modifireType == '4') {
                setModifier1CodeData([]);
            }
            return true;
        }
        setIsLoading(true);
        console.log(eventValue);
        var data = { 'search': eventValue };
        AWVCoderWorkBench.post('/data/modifier_code', data)
            .then(res => {
                let valuesArray = [];
                res.data.forEach((element, index) => {
                    if (!valuesArray.includes(element.code + '-' + element.description)) {
                        valuesArray.push(element.code + '-' + element.description);
                    }
                });
                if (modifireType == '1') {
                    setModifier1CodeData(valuesArray);
                } else if (modifireType == '2') {
                    setModifier2CodeData(valuesArray);
                } else if (modifireType == '3') {
                    setModifier3CodeData(valuesArray);
                } else if (modifireType == '4') {
                    setModifier4CodeData(valuesArray);
                }
                setIsLoading(false);
            })
            .catch(err => {
                let valuesArray = [];
                if (modifireType == '1') {
                    setModifier1CodeData(valuesArray);
                } else if (modifireType == '2') {
                    setModifier2CodeData(valuesArray);
                } else if (modifireType == '3') {
                    setModifier3CodeData(valuesArray);
                } else if (modifireType == '4') {
                    setModifier4CodeData(valuesArray);
                }
                setIsLoading(false);
            })
    }
 
    const handleChangeModifierCode = (eventValue, modifireType) => {
        if (modifireType == '1') {
            const result = modifier1CodeData.find((element) => element == eventValue);
            setValue('modifier_1', eventValue);
            setModifier1CodeValue(eventValue);
            if(result){
                setIsModifier1CodeValue(true);
                setModifier1CodeData([]);
            }        
        } else if (modifireType == '2') {
            const result = modifier2CodeData.find((element) => element == eventValue);
            setValue('modifier_2', eventValue);
            setModifier2CodeValue(eventValue);
            if(result){
                setIsModifier2CodeValue(true);                
                setModifier2CodeData([]);
            }
        } else if (modifireType == '3') {
            const result = modifier3CodeData.find((element) => element == eventValue);
            setValue('modifier_3', eventValue);
            setModifier3CodeValue(eventValue);            
            if(result){
                setIsModifier3CodeValue(true);
                setModifier3CodeData([]);
            }
        } else if (modifireType == '4') {
            const result = modifier4CodeData.find((element) => element == eventValue);
            setValue('modifier_4', eventValue);
            setModifier4CodeValue(eventValue);            
            if(result){
                setIsModifier4CodeValue(true);
                setModifier4CodeData([]);
            }
        }
    }

    const editLoadFormData = (id) => {
        setIsLoading(true);
        AWVCoderWorkBench.get('data/cw_proc_code/' + id)
            .then(res => {
                console.log(res.data);
                if (res.data && res.data) {
                    setEditFormData(res.data);
                    setIsLoading(false);
                } else {
                    setEditFormData();
                    setIsLoading(false);
                }
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
            })
    }

    const loadformDataTable = (id) => {
        setIsLoading(true);
        AWVCoderWorkBench.get('data/cwb/history/cwp_proc_code/' + id) //'&provider_group=' + props?.providerGroupSelect
            .then(res => {
                console.log(res.data);
                setFormDataList(res.data);
                // setFormDataList([
                //     {
                //         'created_on': '11 Jan 2023',
                //         'notes': 'test',
                //         'created_by': 'test',
                //     },
                //     {
                //         'created_on': '11 Jan 2023',
                //         'notes': 'test2',
                //         'created_by': 'test2',
                //     },
                // ]);
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    }

    const handleInputChange1 = (event) => {
        const newValue = event.target.value;

        // Allow only numeric characters
        if (/^\d*$/.test(newValue)) {
            setValue('modifier_1', newValue);
        }
    };

    const handleInputChange2 = (event) => {
        const newValue = event.target.value;

        // Allow only numeric characters
        if (/^\d*$/.test(newValue)) {
            setValue('modifier_2', newValue);
        }
    };

    const handleInputChange3 = (event) => {
        const newValue = event.target.value;

        // Allow only numeric characters
        if (/^\d*$/.test(newValue)) {
            setValue('modifier_3', newValue);
        }
    };

    const handleInputChange4 = (event) => {
        const newValue = event.target.value;

        // Allow only numeric characters
        if (/^\d*$/.test(newValue)) {
            setValue('modifier_4', newValue);
        }
    };


    const onSubmit = (data) => {
        console.log(data);
        let controlUser = JSON.parse(localStorage.getItem('user'));
        //data.last_updated_on = Moment().format('YYYY-MM-DD HH:mm:ss');
        data.last_updated_by = controlUser?.userEmail;
        if (props?.Id) {
            var apiUrl = 'data/cw_proc_code/' + props.Id;
        } else {
            var apiUrl = 'data/cw_proc_code/save';
            //data.created_on = Moment().format('YYYY-MM-DD HH:mm:ss');
            data.created_by = controlUser?.userEmail;
        }
        if(isdxCodeValue && dxCodeValue){

        }else{
            data.proc_code = "";
            data.proc_description = "";
            toast.error('Please enter a valid PROC CODE value.');
            return true;
        }

        if(ismodifier1CodeValue && modifier1CodeValue){

        }else{
            data.modifier_1 = "";
            if(modifier1CodeValue && modifier1CodeValue != ''){
                toast.error('Please enter a valid MODIFIER 1 value');
                return true;
            }
        }

        if(ismodifier2CodeValue && modifier2CodeValue){

        }else{
            data.modifier_2 = "";
            if(modifier2CodeValue && modifier2CodeValue != ''){
                toast.error('Please enter a valid MODIFIER 2 value');
                return true;
            }
        }
    
        if(ismodifier3CodeValue && modifier3CodeValue){

        }else{
            data.modifier_3 = "";
            if(modifier3CodeValue && modifier3CodeValue != ''){
                toast.error('Please enter a valid MODIFIER 3 value');
                return true;
            }
        }

        if(ismodifier4CodeValue && modifier4CodeValue){

        }else{
            data.modifier_4 = "";
            if(modifier4CodeValue && modifier4CodeValue != ''){
                toast.error('Please enter a valid MODIFIER 4 value');
                return true;
            }
        }

        data.page_no = data.page_no ? data.page_no : "";
        data.entity_id = data.entity_id ? data.entity_id : "";
        data.entity_text = data.entity_text ? data.entity_text : "";
        data.dos_begin = Moment(data.dos_begin).format("YYYY-MM-DD");
        data.dos_end = Moment(data.dos_end).format("YYYY-MM-DD");
        console.log(data);
        // return true;
        setFormSubmitisLoading(true);
        AWVCoderWorkBench.post(apiUrl, data)
            .then(res => {
                setValue('id', '');
                setValue('page_no', '');
                setValue('ih_member_id', '');
                setValue('proc_code', '');
                setValue('entity_id', '');
                setValue('entity_text', '');
                setValue('last_updated_on', '');
                setValue('last_updated_by', '');
                setValue('modifier_1', '');
                setValue('modifier_2', '');
                setValue('modifier_3', '');
                setValue('modifier_4', '');


                setFormSubmitisLoading(false);
                if (props?.Id) {
                    toast.success('Updated successfully');
                } else {
                    toast.success('Created successfully');
                }
                props.currentAWERightSidebarCloseDatatableReload(true);
                notificationRightDrawer(false);
            })
            .catch(err => {
                setFormSubmitisLoading(false);
                toast.error(err?.response?.data?.message);
            })
    }

    const handleChangeDate = (event) => {
        if (event?.target?.name == 'dos_begin') {
            setValue('dos_begin', event?.target?.value);
            setValue('dos_end', '');
            setDxCodeDOSBegin(event?.target?.value);
        }
    }

    return (
        <div style={{ padding: "10px 0px" }}>
            <div key={'index'} variant={'head'} style={{ width: '350px', padding: "10px", height: "50px" }}>
                <div class="float">
                    <div class="float-left">
                        <span><b>{(props?.Id) ? 'Edit' : 'Add'} CW PROC CODE Coder Workbench</b></span>
                    </div>
                    <div class="float-right">
                        <Tooltip title="Close">
                            <CancelIcon style={{ color: "#1A9698", cursor: "pointer" }} onClick={() => notificationRightDrawer(false)} />
                        </Tooltip>
                    </div>
                </div>
            </div>
            <Divider />
            <div>
                <div class="awv-recored-right-sidebar-form mt-3">
                    <div variant={'head'} style={{ width: '350px' }}>
                        <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">

                            <div className="col-lg-12 align-items-center mb-3">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>MEMBER ID</b></InputLabel>
                                <FormControl fullWidth margin="dense" variant="outlined" className="mt-1" style={{ 'min-width': '70px' }}>
                                    <Controller
                                        className="input-control"
                                        name="ih_member_id"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField type="text" {...field} variant="outlined" InputProps={{
                                                readOnly: true,
                                            }} />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.ih_member_id?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>
                            <div className="col-lg-12 align-items-center mb-3 custom-autocomplete">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>PROC CODE</b></InputLabel>
                                <FormControl
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    style={{ "min-width": "200px" }}
                                >
                                    <Controller
                                        className="input-control"
                                        name="proc_code"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                variant="outlined"
                                                value={dxCodeValue}
                                                onChange={(event) => handleInputChangeDxCode(event?.target?.value)}
                                                placeholder="Type to search..."
                                                autoComplete="off"
                                            />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    <ul className="options">
                                        {dxCodeData.map((option, index) => (
                                            <li key={index} onClick={() => handleChangeDxCode(option)}>
                                                {option}
                                            </li>
                                        ))}
                                    </ul>
                                    {errors?.proc_code?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>
                            {/* 
                            <div className="col-lg-12 align-items-center mb-3">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>PROC CODE</b></InputLabel>
                                <FormControl fullWidth margin="dense" variant="outlined" className="mt-1" style={{ 'min-width': '70px' }}>
                                    <Controller
                                        className="input-control"
                                        name="proc_code"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField type="text" {...field} variant="outlined" />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.proc_code?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>*/}
                            {/* <div className="col-lg-12 align-items-center mb-3">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>PAGE NO2</b></InputLabel>
                                <FormControl fullWidth margin="dense" variant="outlined" className="mt-1" style={{ 'min-width': '70px' }}>
                                    <Controller
                                        className="input-control"
                                        name="page_no"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField type="text" {...field} variant="outlined" />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.page_no?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>
                            <div className="col-lg-12 align-items-center mb-3">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>ENTITY ID</b></InputLabel>
                                <FormControl fullWidth margin="dense" variant="outlined" className="mt-1" style={{ 'min-width': '70px' }}>
                                    <Controller
                                        className="input-control"
                                        name="entity_id"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField type="text" {...field} variant="outlined" />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.entity_id?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>
                            <div className="col-lg-12 align-items-center mb-3">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>ENTITY TEXT</b></InputLabel>
                                <FormControl fullWidth margin="dense" variant="outlined" className="mt-1" style={{ 'min-width': '100px' }}>
                                    <Controller
                                        className="input-control"
                                        name="entity_text"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField multiline rows={3} {...field} variant="outlined" />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.entity_text?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div> */}

                            <div className="col-lg-12 align-items-center mb-3">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>DOS START</b></InputLabel>
                                <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                    <Controller
                                        className="input-control"
                                        name="dos_begin"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField type="date" {...field} variant="outlined" onChange={handleChangeDate} />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.dos_begin?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>
                            <div className="col-lg-12 align-items-center mb-3">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>DOS END</b></InputLabel>
                                <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                    <Controller
                                        className="input-control"
                                        name="dos_end"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField type="date" {...field} variant="outlined" inputProps={{ min: Moment(dxCodeDOSBegin).format("YYYY-MM-DD") }} />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.dos_end?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>

                            <div className="col-lg-12 align-items-center mb-3 custom-autocomplete">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>MODIFIER 1</b></InputLabel>
                                <FormControl
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    style={{ "min-width": "200px" }}
                                >
                                    <Controller
                                        className="input-control"
                                        name="modifier_1"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                variant="outlined"
                                                value={modifier1CodeValue}
                                                onChange={(event) => handleInputChangeModifierCode(event?.target?.value, 1)}
                                                placeholder="Type to search..."
                                                autoComplete="off"
                                            />
                                        )}
                                        rules={{
                                            required: false,
                                        }}
                                    />
                                    <ul className="options">
                                        {modifier1CodeData && modifier1CodeData.map((option, index) => (
                                            <li key={index} onClick={() => handleChangeModifierCode(option, 1)}>
                                                {option}
                                            </li>
                                        ))}
                                    </ul>
                                    {errors?.modifier_1?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>

                            <div className="col-lg-12 align-items-center mb-3 custom-autocomplete">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>MODIFIER 2</b></InputLabel>
                                <FormControl
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    style={{ "min-width": "200px" }}
                                >
                                    <Controller
                                        className="input-control"
                                        name="modifier_2"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                variant="outlined"
                                                value={modifier2CodeValue}
                                                onChange={(event) => handleInputChangeModifierCode(event?.target?.value, 2)}
                                                placeholder="Type to search..."
                                                autoComplete="off"
                                            />
                                        )}
                                        rules={{
                                            required: false,
                                        }}
                                    />
                                    <ul className="options">
                                        {modifier2CodeData && modifier2CodeData.map((option, index) => (
                                            <li key={index} onClick={() => handleChangeModifierCode(option, 2)}>
                                                {option}
                                            </li>
                                        ))}
                                    </ul>
                                    {errors?.modifier_2?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>

                            <div className="col-lg-12 align-items-center mb-3 custom-autocomplete">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>MODIFIER 3</b></InputLabel>
                                <FormControl
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    style={{ "min-width": "200px" }}
                                >
                                    <Controller
                                        className="input-control"
                                        name="modifier_3"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                variant="outlined"
                                                value={modifier3CodeValue}
                                                onChange={(event) => handleInputChangeModifierCode(event?.target?.value, 3)}
                                                placeholder="Type to search..."
                                                autoComplete="off"
                                            />
                                        )}
                                        rules={{
                                            required: false,
                                        }}
                                    />
                                    <ul className="options">
                                        {modifier3CodeData && modifier3CodeData.map((option, index) => (
                                            <li key={index} onClick={() => handleChangeModifierCode(option, 3)}>
                                                {option}
                                            </li>
                                        ))}
                                    </ul>
                                    {errors?.modifier_3?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>

                            <div className="col-lg-12 align-items-center mb-3 custom-autocomplete">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>MODIFIER 4</b></InputLabel>
                                <FormControl
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    style={{ "min-width": "200px" }}
                                >
                                    <Controller
                                        className="input-control"
                                        name="modifier_4"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                variant="outlined"
                                                value={modifier4CodeValue}
                                                onChange={(event) => handleInputChangeModifierCode(event?.target?.value, 4)}
                                                placeholder="Type to search..."
                                                autoComplete="off"
                                            />
                                        )}
                                        rules={{
                                            required: false,
                                        }}
                                    />
                                    <ul className="options">
                                        {modifier4CodeData && modifier4CodeData.map((option, index) => (
                                            <li key={index} onClick={() => handleChangeModifierCode(option, 4)}>
                                                {option}
                                            </li>
                                        ))}
                                    </ul>
                                    {errors?.modifier_4?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>

                            <div className="col-lg-12 align-items-center mb-3">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>REMARKS</b></InputLabel>
                                <FormControl fullWidth margin="dense" variant="outlined" className="mt-1" style={{ 'min-width': '100px' }}>
                                    <Controller
                                        className="input-control"
                                        name="remarks"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField multiline rows={3} {...field} variant="outlined" />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.remarks?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>
                            <div className="col-lg-12 mt-3 mb-3">
                                <Button type='button' variant="contained" color="grey" onClick={() => notificationRightDrawer(false)}>CANCEL</Button>
                                <Button type='submit'
                                    className={'mr-2 ml-2 btn-custom-primary'}
                                    variant="contained"
                                    disabled={formSubmitisLoading && formSubmitisLoading == true ? true : false}
                                >
                                    Save
                                </Button>
                            </div>
                        </form>
                    </div>
                    {isLoading ?
                        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                            <CircularProgress />
                        </div>
                        :
                        <>
                            {formDataList && formDataList.length > 0 ?
                                <>
                                    <div class="row col-lg-12 mt-5 pl-4">
                                        <div class="float">
                                            <div class="float-left">
                                                <span style={{ fontSize: "20px" }}><b>History</b></span>
                                            </div>
                                        </div>
                                    </div>
                                    <Divider />
                                    {formDataList && formDataList.map((element, index) =>
                                        <div key={'index'} variant={'head'} style={{ width: '350px', padding: "10px" }}>
                                            <div className={
                                                element.form_status === 'Accepted' ? 'card p-2 history-custom-primary' :
                                                    element.form_status === 'Rejected' ? 'card p-2 history-custom-danger' :
                                                        element.form_status === 'No Action' ? 'card p-2 history-custom-warning' :
                                                            'card p-2 history-custom-primary'
                                            }>
                                                <span className="mb-1" style={{ fontSize: "11px" }}>
                                                    <b>{Moment(element.created_on).format('h:mm a, MMMM DD YYYY')}</b>
                                                </span>
                                                <span style={{ fontSize: "11px", lineHeight: "1rem" }}>
                                                    <b>Status:- </b>{element.form_status}
                                                </span>
                                                <span style={{ fontSize: "11px", lineHeight: "1rem" }}>
                                                    <b>Remarks:- </b>{element.remarks}
                                                </span>
                                                <div>
                                                    <span className="mr-1" style={{ color: "#777777", fontSize: "11px" }}>
                                                        Last updated by:
                                                    </span>
                                                    <span style={{ color: "#1a9698", fontSize: "11px" }}>
                                                        <b>  {element.user_id}</b>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                                :
                                <></>
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(EditCWPROCCODECoderWorkBench);

