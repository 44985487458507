import React from 'react'

export default function CloseIcon({ ...props }) {
    return (
        <svg {...props} viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_1567_16222)">
                <rect x="1" width="25" height="25" rx="12.5" fill="#1A9698" />
                <g clip-path="url(#clip0_1567_16222)">
                    <path d="M10.3179 8.25739C10.025 7.9645 9.55015 7.9645 9.25726 8.25739C8.96436 8.55029 8.96436 9.02516 9.25726 9.31805L12.4392 12.5L9.25729 15.682C8.96439 15.9749 8.96439 16.4497 9.25729 16.7426C9.55018 17.0355 10.0251 17.0355 10.3179 16.7426L13.4999 13.5607L16.6819 16.7427C16.9748 17.0356 17.4496 17.0356 17.7425 16.7427C18.0354 16.4498 18.0354 15.9749 17.7425 15.682L14.5606 12.5L17.7426 9.31802C18.0355 9.02512 18.0355 8.55025 17.7426 8.25736C17.4497 7.96446 16.9748 7.96446 16.6819 8.25736L13.4999 11.4394L10.3179 8.25739Z" fill="#E8F5F5" />
                </g>
            </g>
            <defs>
                <filter id="filter0_d_1567_16222" x="0" y="0" width="27" height="27" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1567_16222" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1567_16222" result="shape" />
                </filter>
                <clipPath id="clip0_1567_16222">
                    <rect width="16" height="16" fill="white" transform="translate(5.5 4.5)" />
                </clipPath>
            </defs>
        </svg>

    )
}