import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import WidgetCard from "../../components/Cards/WidgetCard";
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import { getAcaIssuer, getAcaIssuerList } from "../../assets/constants/issuerList";
import AcaIssuersSelect from "../../components/DropDowns/AcaIssuersSelect";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag, hccSeperator } from "../../assets/constants/customRender";
import { GENDER, WIDGET_TYPES } from "../../assets/constants/string.constants";
import { MEMBER_ID, PLAN_ID, CARRIER_ID, MEMBER_NAME, ISSUER_ID, HCC, CR_INSIGHTS } from "../../assets/constants/string.constants";
import history from '../../history';
import CardComponent from "../../components/Cards/card";
import SubHeader from '../../components/home/header/subHeader';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import { connect } from 'react-redux';
const ACAChartReviewMemberSummaryPage = (props) => {

    const [WidgetDetails, setWidgetDetails] = useState({});
    const [year, setYear] = useState(props.yearSelect);
    const [issuer, setIssuer] = useState(getAcaIssuer());
    const [cardToggle, setcardToggle] = useState(true);


    const loadCRWidgetDetails = () => {
        api.get('/ACAchartReviewMemberSummary/year/' + year + '/issuer_id/' + issuer)
            .then(res => {
                setWidgetDetails({
                    '# Total Members': Number(res[0].totalMembersForYear).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    '# Total Members with CR': Number(res[0].membersWithCR).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    '# Adult with CR': Number(res[0].adultmembersWithCR).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    '# Child with CR': Number(res[0].childmembersWithCR).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    '# Infant with CR': Number(res[0].infantmembersWithCR).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    '% Of Risk Score Contributed by CR': res[0] ? res[0].perOfRAFScoreContributedByCR : 0,

                });
            })
            .catch(err => {
                // console.log(err);
            });
    };

    let dataIndex = 0;

    const backGround = [
        { backgroundColor: "#AAB7B8" },
        { backgroundColor: "#EB984E" },
        { backgroundColor: "#58D68D" },
        { backgroundColor: "#5DADE2" },
        { backgroundColor: "#AF7AC5" },
        { backgroundColor: "#EC7063" }
    ]

    const WidgetDetailsType = [
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.ADULT,
        WIDGET_TYPES.CHILD,
        WIDGET_TYPES.INFANT,
        WIDGET_TYPES.SCORE
    ]

    const sliderSettingsAcac = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        adaptiveHeight: true,
    };

    const type = WidgetDetailsType;
    const widgetStyles = {
        position: 'absolute',
        left: 0,
        //right: 20,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.15,
        color: "white"
    };

    const getIcon = (type) => {
        var data = ''
        switch (type) {
            case WIDGET_TYPES.ISSUER:
                data = <AccountBalanceTwoToneIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.MEMBERS:
                data = <PeopleAltIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERCENT:
                data = <ShowChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.SCORE:
                data = <BarChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERSON:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.CHILD:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.ADULT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.INFANT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.REVENUE:
                data = <AttachMoneyIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.COUNT:
                data = <ShoppingCartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.LOCKICON:
                data = <LockOpenIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.TRENDING:
                data = <TrendingUpIcon style={widgetStyles} />
                break;
        }

        return data;
    }


    const memberColumns = [
        {
            label: "Member ID",
            name: "MemberID",
            options: anchorTag
        },
        {
            name: "MEMBER_Name",
            label: "Member Name"
        },
        {
            name: "Carrier_ID",
            label: "Carrier ID"
        },
        {
            name: "ReportedHCCS",
            label: "Reported HCCs",
            options: hccSeperator

        },
        {
            name: "AddedHccs",
            label: "Added HCCs",
            options: hccSeperator
        },
        {
            name: "DeletedHccs",
            label: "Deleted HCCs",
            options: hccSeperator
        },
        {
            name: "ReportedRAF",
            label: "Reported Risk Score"
        },
        {
            name: "CorrectionInRAF",
            label: "Risk Score"
        },
        {
            name: "METAL_TIER",
            label: "Metal Tier"
        },
        {
            name: "RISK_MODEL",
            label: "Risk Model"
        },
        {
            name: "PlanID",
            label: "Plan ID"
        }
    ];

    const memberTableClick = (rowdata, colData, cellMeta) => {

        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(MEMBER_ID, rowdata[cellMeta.rowIndex].MemberID);
            localStorage.setItem(MEMBER_NAME, rowdata[cellMeta.rowIndex].MEMBER_Name);
            localStorage.setItem(CARRIER_ID, rowdata[cellMeta.rowIndex].Carrier_ID);
            localStorage.setItem(PLAN_ID, rowdata[cellMeta.rowIndex].PlanID);
            localStorage.setItem(GENDER, rowdata[cellMeta.rowIndex].Gender);
            history.push('/layout/acaMember', CR_INSIGHTS)
        }
        else if ((cellMeta.colIndex === 3) && (colData.props.children != "")) {
            localStorage.setItem(ISSUER_ID, issuer);
            history.push('/layout/acaReportedHccSummary')
        }
        else if ((cellMeta.colIndex === 4) && (colData.props.children != "")) {
            if (typeof (colData.props.children) == "object") {
                localStorage.setItem(HCC, (cellMeta.event.target.innerText).replace(',', ''));
            }
            else {
                localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].AddedHccs);
            }
            localStorage.setItem(ISSUER_ID, issuer);
            history.push('/layout/acaCRhccAdd')
        }
        else if ((cellMeta.colIndex === 5) && (colData.props.children != "")) {
            if (typeof (colData.props.children) == "object") {
                localStorage.setItem(HCC, (cellMeta.event.target.innerText).replace(',', ''));
            }
            else {
                localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].DeletedHccs);
            }
            localStorage.setItem(ISSUER_ID, issuer);
            history.push('/layout/acaCRhccDelete')
        }
    }

    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            loadCRWidgetDetails();
            setIssuer(getAcaIssuer());
        }
    }, [year, issuer]);

    let sliderRange = Object.entries(WidgetDetails).length / 3;
    let sliderRangeInt = Object.entries(WidgetDetails).length;
    sliderRange = parseInt(sliderRange);
    sliderRangeInt = parseInt(sliderRangeInt);

    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Member Summary'} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <SlickSlider WidgetDetails={WidgetDetails} getIcon={getIcon} type={type} />
                </div>
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <CardComponent
                        heading={`MEMBER LIST`}
                        body={
                            <ServerSideGridTable
                                url={`/ACAcrMemberSummary/year/${year}/issuer_id/${btoa(issuer)}/max/100`}
                                columns={memberColumns}
                                tableClick={memberTableClick}
                            ></ServerSideGridTable>
                        }></CardComponent>
                </div>
            </div>
            <br />
            <br />
        </div>
    );

};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default connect(mapStateToProps)(ACAChartReviewMemberSummaryPage);
