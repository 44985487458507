import React, { useRef, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../../store/actions';
import AWVApi from '../../../assets/constants/AWVRafservice.Instance';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import Divider from '@mui/material/Divider';
import { useForm, Controller } from "react-hook-form";
import Box from '@mui/material/Box';
import pdfIcon from '../../../assets/images/pdf_icon.png';
import Moment from 'moment';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Autocomplete from '@mui/material/Autocomplete';
import { RenderingProvider } from '../../../Pages/config/RenderingProvider.config'
import { SELECT_SITE_ID, PROVIDER_GROUP_LIST } from "../../../assets/constants/string.constants";
import Button from "../../layout/aweLayout/Button";
import CloseIcon from "../../icons/CloseIcon";
import CalendarIcon from "../../icons/CalendarIcon";
import IconButton from "../../layout/aweLayout/IconButton";

const AWVMemberDetailsCoodinator = (props) => {
    const { register, formState: { errors }, trigger, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
    const [editFormData, setEditFormData] = useState({});
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const [siteId, setSiteId] = useState('');
    const [siteIdData, setSiteIdData] = useState([]);
    const [ppg, setPPG] = useState('');
    const [ppgdata, setPPGData] = useState([]);
    const [providerIdValue, setProviderIdValue] = useState('');
    const [providerIdData, setProviderIdData] = useState([]);
    const [providerId, setProviderId] = useState('')
    const [providerName, setProviderName] = useState('');
    const [providerNameData, setProviderNameData] = React.useState([]);

    useEffect(() => {
        if (props.aweRightSidebarType?.cin_id && props.aweRightSidebarType?.cin_id != '') {
            editLoadFormData(props.aweRightSidebarType?.cin_id, props.aweRightSidebarType?.awv_id);
            setValue('awe_id', String(props.aweRightSidebarType?.awv_id));
            setValue('cin', props.aweRightSidebarType?.cin_id);
        }
    }, [props.aweRightSidebarType?.cin_id]);

    useEffect(() => {
        console.log(editFormData);
        if (editFormData?.awe_source && editFormData?.awe_source != '') {
            setValue('awe_source', editFormData?.awe_source);
            setValue('receiver_date', Moment(editFormData?.receive_date).format("YYYY-MM-DD"));
            setValue('received_method', editFormData?.received_method);
            setValue('additional_data', editFormData?.additional_data);
            setValue('telehealth_checkbox', editFormData?.telehelth_check);/* correct the spell of telehelth to TELEHEALTH */
            setValue('service_date', Moment(editFormData?.service_date).format("YYYY-MM-DD"));
            setValue('remarks', editFormData?.remarks);
            setValue('awe_id', String(props.aweRightSidebarType?.awv_id));
            setValue('cin', props.aweRightSidebarType?.cin_id);
            setValue('rendering_provider_npi', editFormData?.rendering_provider_npi);
            setValue('rendering_provider_name', editFormData?.rendering_provider_name);
            setValue('ppg', editFormData?.ppg);
            setValue('site_id', editFormData?.site_id);
            setSiteId(editFormData?.site_id)
            setPPG(editFormData?.ppg)
            setProviderId(editFormData?.rendering_provider_npi)
            setProviderName(editFormData?.rendering_provider_name)
        }
    }, [editFormData]);

    // For rendering siteID and PPG data 
    useEffect(() => {
    }, []);

    const notificationRightDrawer = (open, valueId = '') => {
        props.currentAWERightSidebarType({ 'type': 'notification' });
        props.currentAWERightSidebar(open);
    };

    const editLoadFormData = (cinId, awvId) => {
        setIsLoading(true);
        AWVApi.get('/getawecoordinatorchecklist?cin=' + cinId + '&awe_id=' + awvId + '&year=' + props?.yearSelect + '&lob=' + props?.lobSelect + '&organisation_id=1')
            // AWVApi.get('/get_member_hcc_details?member_id=3RE5W09MM81')
            .then(res => {
                if (res.data && res.data.length > 0) {
                    res.data.forEach(element => {
                        if (props.aweRightSidebarType?.awe_source && element.awe_source == props.aweRightSidebarType?.awe_source) {
                            setEditFormData(element);
                            setIsLoading(false);
                        }

                    });
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
            })
    }

    // Storing providerid into state : setProviderIdData
    const getproviderIdData = () => {
        let value = [];
        RenderingProvider.forEach((element) => value.push(element.providerId))
        setProviderIdData(value)
    }

    // Storing providerName into state : setProviderNameData
    const getproviderNameData = () => {
        let value = [];
        RenderingProvider.forEach((element) => value.push(element.providerName))
        setProviderNameData(value)
    }

    // get ppg list from localStorage and store in array
    const getProviderGroup = () => {
        if (localStorage.getItem(PROVIDER_GROUP_LIST) && localStorage.getItem(PROVIDER_GROUP_LIST) != '') {
            var data = JSON.parse(localStorage.getItem(PROVIDER_GROUP_LIST))
            setPPGData(data);
        };
    }

    const autoPopulateRenderingProvider = (value) => {
        let valuesArray = [];
        setProviderId(value);
        if (value != null && value != "") {
            RenderingProvider.forEach((element, index) => {
                if (!valuesArray.includes(element.providerId)) {
                    valuesArray.push(element.providerId);
                }
            });
        }
        setProviderIdData(valuesArray);
        handleManualValidation();
    }

    const autoPopulateRenderingProviderSelected = (value) => {
        if (value != null && value != "") {
            RenderingProvider.forEach((ele, index) => {
                if (ele.providerId == value) {
                    setProviderId(ele.providerId)
                    setProviderName(ele.providerName)
                    setProviderIdData([]);
                    setProviderNameData([]);
                    setValue('rendering_provider_npi', ele.providerId);
                    setValue('rendering_provider_name', ele.providerName);
                }
            })
        } else {
            setProviderId(value)
        }
        handleManualValidation();
    }

    const autoPopulateRenderingProviderName = (value) => {
        let valuesArray = [];
        setProviderName(value);
        if (value != null && value != "") {
            RenderingProvider.forEach((element, index) => {
                if (!valuesArray.includes(element.providerName)) {
                    valuesArray.push(element.providerName);
                }
            });
        }
        setProviderNameData(valuesArray);
        handleManualValidation();
    }

    const autoPopulateRenderingProviderNameSelected = (value) => {
        if (value != null && value != "") {
            RenderingProvider.forEach((ele, index) => {
                if (ele.providerName == value) {
                    setProviderId(ele.providerId)
                    setProviderName(ele.providerName)
                    setProviderIdData([]);
                    setProviderNameData([]);
                    setValue('rendering_provider_name', ele.providerName);
                    setValue('rendering_provider_npi', ele.providerId);
                }
            })
        } else {
            setProviderId(value)
        }
        handleManualValidation();
    }

    const autoPopulateRenderingSiteId = (value) => {
        let valuesArray = [];
        setSiteId(value);
        if (value != null && value != "") {
            var dataSites = JSON.parse(localStorage.getItem(SELECT_SITE_ID))
            dataSites.forEach((element) => valuesArray.push(element.site_id))
        }
        setSiteIdData(valuesArray);
        handleManualValidation();
    }

    const autoPopulateRenderingSiteIdSelected = (value) => {
        if (value != null && value != "") {
            var dataSites = JSON.parse(localStorage.getItem(SELECT_SITE_ID))
            dataSites.forEach((ele, index) => {
                if (ele.site_id == value) {
                    setSiteId(ele.site_id)
                    setValue('site_id', ele.site_id);
                    setSiteIdData([]);
                }
            })
        } else {
            setSiteId(value)
        }
        handleManualValidation();
    }

    const autoPopulateRenderingPPG = (value) => {
        let valuesArray = [];
        setPPG(value);
        if (value != null && value != "") {
            valuesArray = JSON.parse(localStorage.getItem(PROVIDER_GROUP_LIST))
        }
        setPPGData(valuesArray);
        handleManualValidation();
    }

    const autoPopulateRenderingPPGSelected = (value) => {
        if (value != null && value != "") {
            var dataSites = JSON.parse(localStorage.getItem(PROVIDER_GROUP_LIST))
            dataSites.forEach((ele, index) => {
                if (ele == value) {
                    setPPG(ele)
                    setValue('ppg', ele);
                    setPPGData([]);
                }
            })
        } else {
            setPPG(value)
        }
        handleManualValidation();
    }

    const onSubmit = (data) => {
        if (data?.cin && data?.cin != '') {
            setFormSubmitisLoading(true);
            let controlUser = JSON.parse(localStorage.getItem('controlUser'));
            data.updated_by = controlUser?.user_id;
            data.additional_data = String(data?.additional_data);
            data.telehealth_checkbox = String(data?.telehealth_checkbox);/* correct the spell of telehelth to TELEHEALTH */
            data.site_id = siteId ? siteId : "";
            data.ppg = ppg ? ppg : "";
            data.rendering_provider_npi = providerId ? String(providerId) : "";
            data.rendering_provider_name = providerName ? providerName : "";
            data.remarks = data.remarks ? data.remarks : "";
            //adding lob, year, organisation_id to request body
            data.lob = props?.lobSelect;
            data.year = props?.yearSelect;
            data.organisation_id = '1';
            if(data?.lob)
                    data.lob = data.lob.toString();
            AWVApi.put('/update_awe_coordinator_checklist', data)
                .then(res => {
                    setValue('awe_source', '');
                    setValue('receiver_date', '');
                    setValue('received_method', '');
                    setValue('additional_data', '');
                    setValue('service_date', '');
                    setValue('remarks', '');
                    setValue('telehealth_checkbox', '');
                    setValue('awe_id', '');
                    setValue('cin', '');
                    setSiteId('');
                    setPPG('');
                    setProviderId('');
                    setProviderName('');
                    setFormSubmitisLoading(false);
                    toast.success('Coordinator Record updated successfully');
                    notificationRightDrawer(false);
                    props.currentAWERightSidebarCloseDatatableReload(true);
                })
                .catch(err => {
                    setFormSubmitisLoading(false);
                    toast.error(err?.response?.data?.message);
                })
        }

    };

    const handleChangeDate = (event) => {
        setValue(event?.target?.name, event?.target?.value);
        handleManualValidation();
    }
    const handleManualValidation = async () => {
        try {
          // Trigger manual revalidation for all fields
          await trigger();
        } catch (error) {
          //console.error('Manual validation error:', error);
        }
    };

    return (
        <div className="diagnosis_code_sidebar offcanvas offcanvas-end show" tabIndex={-1} aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header flex_item_cb">
                <h5 id="offcanvasRightLabel" className='fs15'>
                    {(props?.aweRightSidebarType?.status && props?.aweRightSidebarType?.status == '1') ?
                        'COORDINATOR CHECKLIST ADD'
                        :
                        'COORDINATOR CHECKLIST EDIT'
                    }
                </h5>
                <Tooltip title="Close">
                    <IconButton type="button" buttonType={"default"} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => notificationRightDrawer(false)}>
                        <CloseIcon className="w24" />
                    </IconButton>
                </Tooltip>
            </div>
            <Divider />
            <div className="offcanvas-body pt-1 custom-scrollbar overflow-auto">
                <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">AWE SOURCE</label>
                        <Controller
                            name="awe_source"
                            value={editFormData?.awe_source}
                            control={control}
                            render={({ field }) => (
                                <select
                                    {...field}
                                    className="form-control"
                                >
                                    <option value={''} >
                                        Select
                                    </option>
                                    <option value={'Provider'} >
                                        Provider
                                    </option>
                                    <option value={'HCD'} >
                                        HCD
                                    </option>
                                    <option value={'Applecare'} >
                                        Applecare
                                    </option>
                                </select>
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.awe_source?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">RECEIVE DATE</label>
                        <Controller
                            name="receiver_date"
                            value={Moment(editFormData?.receive_date).format("YYYY-MM-DD")}
                            control={control}
                            render={({ field }) => (
                                <input type="date" className="form-control" {...field} onChange={handleChangeDate} />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.receiver_date?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">RECEIVED METHOD</label>
                        <Controller
                            name="received_method"
                            value={editFormData?.received_method}
                            control={control}
                            render={({ field }) => (
                                <select
                                    {...field}
                                    className="form-control"
                                >
                                    <option value={''} >
                                        Select
                                    </option>
                                    <option value={'Secure email'} >
                                        Secure email
                                    </option>
                                    <option value={'Fax'} >
                                        Fax
                                    </option>
                                    <option value={'FTP'} >
                                        FTP
                                    </option>
                                    <option value={'Cozeva'} >
                                        Cozeva
                                    </option>
                                </select>
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.received_method?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">SERVICE DATE</label>
                        <Controller
                            name="service_date"
                            value={Moment(editFormData?.service_date).format("YYYY-MM-DD")}
                            control={control}
                            render={({ field }) => (
                                <input type="date" className="form-control" {...field} onChange={handleChangeDate} />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.service_date?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">ADDITIONAL DATA</label>
                        <Controller
                            name="additional_data"
                            value={editFormData?.additional_data}
                            control={control}
                            render={({ field }) => (
                                <select
                                    {...field}
                                    className="form-control"
                                >
                                    <option value={''} >
                                        Select
                                    </option>
                                    <option value={'1'} >
                                        Yes
                                    </option>
                                    <option value={'0'} >
                                        No
                                    </option>
                                </select>
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.additional_data?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">TELEHEALTH</label>
                        <Controller
                            name="telehealth_checkbox"
                            value={editFormData?.telehealth_checkbox}
                            control={control}
                            render={({ field }) => (
                                <select
                                    {...field}
                                    className="form-control"
                                >
                                    <option value={''} >
                                        Select
                                    </option>
                                    <option value={'1'} >
                                        Yes
                                    </option>
                                    <option value={'0'} >
                                        No
                                    </option>
                                </select>
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.telehealth_checkbox?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group mb20 custom-autocomplete">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">RENDERING PROVIDER NPI</label>
                        <Controller
                            name="rendering_provider_npi"
                            control={control}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    value={providerId}
                                    onChange={(event) => autoPopulateRenderingProvider(event?.target?.value)}
                                    placeholder="Type to search..."
                                    autoComplete="off"
                                    className="form-control"
                                    onBlur={handleManualValidation}
                                />
                            )}
                            rules={{
                                required: false,
                            }}
                        />
                        <ul className="options custom-scrollbar">
                            {providerIdData.map((option, index) => (
                                <li key={index} onClick={() => autoPopulateRenderingProviderSelected(option)}>
                                    {option}
                                </li>
                            ))}
                        </ul>
                        {errors?.rendering_provider_npi?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group mb20 custom-autocomplete">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">RENDERING PROVIDER NAME</label>
                        <Controller
                            name="rendering_provider_name"
                            control={control}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    value={providerName}
                                    onChange={(event) => autoPopulateRenderingProviderName(event?.target?.value)}
                                    placeholder="Type to search..."
                                    autoComplete="off"
                                    className="form-control"
                                    onBlur={handleManualValidation}
                                />
                            )}
                            rules={{
                                required: false,
                            }}
                        />
                        <ul className="options custom-scrollbar">
                            {providerNameData.map((option, index) => (
                                <li key={index} onClick={() => autoPopulateRenderingProviderNameSelected(option)}>
                                    {option}
                                </li>
                            ))}
                        </ul>
                        {errors?.rendering_provider_name?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group mb20 custom-autocomplete">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">SITE ID</label>
                        <Controller
                            name="site_id"
                            control={control}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    value={siteId}
                                    onChange={(event) => autoPopulateRenderingSiteId(event?.target?.value)}
                                    placeholder="Type to search..."
                                    autoComplete="off"
                                    className="form-control"
                                    onBlur={handleManualValidation}
                                />
                            )}
                            rules={{
                                required: false,
                            }}
                        />
                        <ul className="options custom-scrollbar">
                            {siteIdData.map((option, index) => (
                                <li key={index} onClick={() => autoPopulateRenderingSiteIdSelected(option)}>
                                    {option}
                                </li>
                            ))}
                        </ul>
                        {errors?.site_id?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group mb20 custom-autocomplete">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">PPG</label>
                        <Controller
                            name="ppg"
                            control={control}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    value={ppg}
                                    onChange={(event) => autoPopulateRenderingPPG(event?.target?.value)}
                                    placeholder="Type to search..."
                                    autoComplete="off"
                                    className="form-control"
                                    onBlur={handleManualValidation}
                                />
                            )}
                            rules={{
                                required: false,
                            }}
                        />
                        <ul className="options custom-scrollbar">
                            {ppgdata.map((option, index) => (
                                <li key={index} onClick={() => autoPopulateRenderingPPGSelected(option)}>
                                    {option}
                                </li>
                            ))}
                        </ul>
                        {errors?.ppg?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">REMARKS</label>
                        <Controller
                            name="remarks"
                            value={editFormData?.remarks}
                            control={control}
                            render={({ field }) => (
                                <textarea rows={3} {...field} className="form-control" />
                            )}
                            rules={{
                                required: false,
                            }}
                        />
                        {errors?.remarks?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>

                    <div className="form-group flex_item_cs gap10">
                        <Button type='button' className={"btn-white minw80"} onClick={() => notificationRightDrawer(false)}>CANCEL</Button>
                        <Button type='submit' className={"minw80"}
                            disabled={formSubmitisLoading && formSubmitisLoading == true ? true : false}>
                            {(props?.aweRightSidebarType?.status && props?.aweRightSidebarType?.status == '1') ?
                                'ADD'
                                :
                                'UPDATE'
                            }
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(AWVMemberDetailsCoodinator);

