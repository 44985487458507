import React, { useEffect, useRef } from 'react';
import { withRouter } from 'react-router';

const ScrollToTopAdmin = ({ children, location: { pathname } }) => {
  const ref = useRef()
  useEffect(() => {
    // ref.current.scrollIntoView({ block: "start" });
    document.getElementById('BODYTOPLEVEL').scrollIntoView();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }, [pathname]);

  return(
    <> 
      <div id="BODYTOPLEVEL" style={{'paddingBottom': '5px'}} ></div>
      <div>
        {children || null}   
      </div> 
    </>
   )
  // return children || null;
};


export default withRouter(ScrollToTopAdmin)