import { useState, useEffect } from 'react';
import { anchorTag, numberWithCommas } from '../../assets/constants/customRender';
import { getAcaIssuer, getAcaIssuerList } from '../../assets/constants/issuerList';
import api from '../../assets/constants/Rafservice.Instance';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import SingleHorizontalBar from '../../components/Charts/SingleHorizontalBar.chart';
import AcaIssuersSelect from '../../components/DropDowns/AcaIssuersSelect';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import history from '../../history';
import ServerSideGridTable from '../../components/TableGrids/ServerSideGridTable.table';
import { HCC, ISSUER_ID } from '../../assets/constants/string.constants';
import CardComponent from '../../components/Cards/card';
import BarVerticalHCC from '../../components/GChart/BarVerticalHCC';
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';
import { getMeInitialSelect } from '../../assets/constants/meInitialList';

const MaoGapHccSummaryPage = (props) => {

    const [HccChartData, setHccChartData] = useState([]);
    const [issuer, setIssuer] = useState(getAcaIssuer());

    const loadHccChartIssuer = () => {
        let chartDataRow = [];
        chartDataRow.push(["HCC's", "Member Count", { role: 'annotation' }]);
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/hccByYear/year/' + getAcaYear() + '/period/' + getMeInitialSelect())
            .then(res => {
                for (const dataObj of res) {
                    chartDataRow.push([dataObj.description + ':' + dataObj.hccCode, dataObj.totalCount, dataObj.totalCount]);
                }
                setHccChartData(chartDataRow);
            })

    }


    const hccMedicareColumns = [
        {
            label: "HCC",
            name: "hcc_desc",
            options: anchorTag
        },
        {
            label: "# Members with Comorbid",
            name: "comorbid",
            options: numberWithCommas
        },
        {
            label: "# Members with Historic",
            name: "historic",
            options: numberWithCommas
        },
        {
            label: "# Members with Lab Other",
            name: "lab_other",
            options: numberWithCommas
        },
        {
            label: "# Members with Pharma",
            name: "pharma",
            options: numberWithCommas
        },
        {
            label: "# Multiple Sources",
            name: "members_with_mult_sources",
            options: numberWithCommas
        },
        {
            label: "# Members with Gap",
            name: "total_members_with_gap",
            options: numberWithCommas
        }
    ]

    const hccTableClick = (rowdata, colData, cellMeta) => {
        console.log("vinuthan");
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(ISSUER_ID, issuer);
            localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].hcc);
            history.push('/layout/medicare/gap_hcc');
        }
    };
    //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            loadHccChartIssuer();
        }
        setIssuer(getAcaIssuer());

    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear()
    ])

    useEffect(() => {
        loadHccChartIssuer();
        setIssuer(getAcaIssuer());
    }, [
        issuer
    ])

    return (
        <div className="level-summary">
            <div className="row pb-2">
                <div className="col-lg-12">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Gap HCC Summary'} />
                        </div>
                    </div>
                </div>
            </div>

            <CardComponent
                heading={`HIGH FREQUENCY GAP HCC`}
                body={
                    <BarVerticalHCC data={HccChartData} chartType="BarChart" height="400px" width="100%" setStorgeItemName={HCC} redirectUrl={'medicare/gap_hcc'}></BarVerticalHCC>
                }></CardComponent>
            <br />
            <div className="hcc-list pb-5">
                <CardComponent
                    heading={`HCC LIST`}
                    body={
                        <ServerSideGridTable
                            url={`/hccsWithCount/${getAcaYear()}/period/${getMeInitialSelect()}/lob/${props.moduleSelect}`}
                            columns={hccMedicareColumns}
                            tableClick={hccTableClick}
                            defaultSortCol={0}
                            defaultSortDir={'desc'}
                        ></ServerSideGridTable>
                    }></CardComponent>
            </div>
            <br />
            <br />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default connect(mapStateToProps)(MaoGapHccSummaryPage);
