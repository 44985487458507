import React, { useState, useReducer, useEffect } from 'react';
import Chart from "react-google-charts";
import api from '../../assets/constants/Rafservice.Instance';

const HeatChartDemo = () => {
    // console.log("code-heat-map");
    const [ChartData, setChartData] = useState([]);
    const loadHeatChart = () => {
        let newChartData = [];
        newChartData.push(["Issue", "Hdr", "Count"]);
        api.get('/acaMemberGapCountforAllYears')
            .then(res => {
                newChartData.push(['Year', null, 0]);
                let data;
                for (let i = 0; i < res.length; i++) {
                    newChartData.push([res[i].raf_year, 'Year', 0]);
                }
                for (let i = 0; i < res.length; i++) {
                    data = res[i];
                    let AllKeys = Object.keys(data);
                    let AllValues = Object.values(data);
                    let year = AllValues[0];
                    let a = 0;
                    for (const dataObj of AllKeys) {
                        if (a > 3) {
                            newChartData.push([dataObj + " " + year.toString(), year.toString(), AllValues[a]]);
                        }
                        a++;
                    }
                }
                // console.log("code-newChartData", newChartData);

                if (ChartData.length === 0) {
                    setChartData(newChartData);
                }

            })
    }
    useEffect(() => {
        // console.log('code-use-effect');
        loadHeatChart();
    });

    return (
        <>
            <div className="container level-summary">
                <div className="row">
                    <div className="col-lg-12 pr-0">
                        <br />
                        <div className="card state-level">
                            <div className="card-header">
                                <span className="d-flex bd-highlight">
                                    <h6 className="p-2 flex-grow-1">Chart Demo </h6>
                                </span>
                            </div>
                            <div className="card-body container">

                            </div>
                        </div>
                    </div>

                    <br />
                    <div className="col-lg-12">
                        <h6 className="pt-4 pl-2">Heat Map Chart</h6>
                        <div>
                            <Chart
                                width={'100%'}
                                height={'300px'}
                                chartType="TreeMap"
                                loader={<div>Loading Chart</div>}
                                data={ChartData}
                                options={{
                                    minColor: '#f00',
                                    midColor: '#ddd',
                                    maxColor: '#0d0',
                                    headerHeight: 15,
                                    fontColor: 'black',
                                    showScale: true,
                                }}
                                rootProps={{ 'data-testid': '1' }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>);
};
export default HeatChartDemo;
