import { connect } from 'react-redux';
import React, { useEffect, useState } from "react";
import AWEDetailedAnalysis from './AWEDetailedAnalysis';
import AWESummaryDashboardHeader from './AWESummaryDashboardHeader';
import ReportingAnalytics from './annual_wellness_tab/reporting_analytics';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';


const AWVMemberDetails = (props) => {

    const [value, setValue] = useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <div className="col-lg-12 pr-0 pl-0">
                <div className='card'>
                    <h6 className="pt-2 pl-2 flex-grow-1 header-title-text">Annual Wellness Exam</h6>
                </div><br />
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', pl: 3 }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                                <Tab label="AWE Summary Dashboard" value="1" />
                                <Tab label="AWE Detailed Analysis" value="2" />
                            </TabList>
                        </Box>
                        
                        <TabPanel value='1'>
                            {/* <AWESummaryDashboardHeader></AWESummaryDashboardHeader> */}
                            
                            <ReportingAnalytics></ReportingAnalytics>
                        </TabPanel>
                        <TabPanel value='2'>
                            <AWEDetailedAnalysis></AWEDetailedAnalysis>
                        </TabPanel>
                    </TabContext>

                </Box>
            </div>

        </>
    )

};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
    };
}
export default connect(mapStateToProps)(AWVMemberDetails);
