import React, { useState, useEffect } from 'react';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import adminApi from '../../assets/constants/AdminRafservice.Instance';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import { connect } from 'react-redux';
import MaterialSelect from '@material-ui/core/Select';
import Select, { components } from "react-select";
import { useForm, Controller } from "react-hook-form";
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import UserPool from '../../assets/constants/user-pool';
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";


const styles = (theme) => ({
    dialogPaper: {
        maxWidth: '80%',
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const AddUser = (props) => {
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [year, setYear] = useState(props.yearSelect);
    const [open, setOpen] = useState();
    const [organizationsData, setOrganizationsData] = useState([]);
    const [groupsData, setGroupsData] = useState([]);
    const [selectOrganization, setSelectOrganization] = useState('');
    const [selectGroups, setSelectGroups] = useState();
    const [selectGroupsArray, setSelectGroupsArray] = useState([]);
    const [statusCheck, setStatusCheck] = useState('1');
    const [isLoading, setIsLoading] = useState(false);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    useEffect(() => {
        if (props.editUserData && props.editUserData.id) {
            setValue('id', props.editUserData.id);
            setValue('email', props.editUserData.email);
            setValue('password', '**********');
            setValue('description', props.editUserData.description);
            setValue('status', props.editUserData.status);
            setValue('organizationId', props.editMenuData?.organizationId);
            setStatusCheck(props.editUserData.status);
            setSelectOrganization(props.editUserData?.organizationId);
            var groupsArray = [];
            if (props.editUserData.groups && props.editUserData.groups.length > 0) {
                props.editUserData.groups.forEach(element => {
                    groupsArray.push(element.id.toString())
                });
            }
            setValue('groups', groupsArray);
            setSelectGroups(groupsArray.join(","));
            setSelectGroupsArray(groupsArray);
        } else {
            setValue('id', '');
            setValue('email', '');
            setValue('password', '');
            setValue('description', '');
            setValue('status', statusCheck);
            setValue('groups', '');
            setValue('organizationId', '');
            setSelectGroups('');
            setSelectOrganization('');
            setSelectGroupsArray([]);
        }
    }, [props.editUserData]);

    const onSubmit = (dataUser) => {
        setIsLoading(true);
        if (dataUser.id && dataUser.id != '') {
            adminApi.put('/user/' + dataUser.id, dataUser)
                .then(res => {
                    props.handleClose();
                    setValue('id', '');
                    setValue('email', '');
                    setValue('password', '');
                    setValue('description', '');
                    setValue('status', '1');
                    setValue('groups', '');
                    setValue('organizationId', '');
                    setSelectGroups('');
                    setSelectGroupsArray([]);
                    setStatusCheck('1');
                    setSelectOrganization('');
                    props.loadUserData();
                    setIsLoading(false);
                })
                .catch(err => {
                    setIsLoading(false);
                    toast.error(err?.response?.data?.message);
                })
        } else {
            UserPool.signUp(dataUser.email, dataUser.password, [], null, (err, data) => {
                if (err) {
                    setIsLoading(false);
                    toast.error(`Unable to create user ${err.message}`);
                } else {
                    toast.success("User get created successfully. Please contact adminstrator to confirm.");
                    dataUser.user_id = data.userSub;
                    //toast.error("User get created successfully. Please contact adminstrator to confirm.");
                    addUserToControlDb(dataUser);
                }

            });

        }

    };
    const addUserToControlDb = (data) => {
        adminApi.post('/user', data)
            .then(res => {
                props.handleClose();
                setValue('id', '');
                setValue('email', '');
                setValue('password', '');
                setValue('description', '');
                setValue('status', '1');
                setValue('groups', '');
                setValue('organizationId', '');
                setSelectGroups('');
                setSelectGroupsArray([]);
                setStatusCheck('1');
                setSelectOrganization('');
                props.loadUserData();
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
                toast.error(err?.response?.data?.message);
            })

    }

    useEffect(() => {
        setSelectGroups(selectGroupsArray.join(","));
        setValue('groups', selectGroupsArray);
    }, [selectGroupsArray]);

    const handleChange = (event) => {
        let setGroupSelected = [];
        if (event != '' && event.length > 0) {
            event.map(function (el) {
                setGroupSelected.push(el.id.toString());
            })
        }
        setSelectGroupsArray(setGroupSelected);
    };

    const handleRadioChange = (event) => {
        const value = event.target.value;
        setStatusCheck(event.target.value);
        setValue('status', event.target.value);
    };

    useEffect(() => {
        setValue('organizationId', selectOrganization);
    }, [selectOrganization]);

    const handleChangeOrganization = (event) => {
        const value = event?.target?.value;
        setSelectOrganization(value);
    };

    useEffect(() => {
        loadGroupData();
        loadOrganizationData();
    }, []);

    const loadGroupData = () => {
        adminApi.get('/group/active')
            .then(res => {
                setGroupsData(res.data);
            })
            .catch(err => {
                toast.error(err?.response?.data?.message);
            })
    }

    const loadOrganizationData = () => {
        adminApi.get('/organization/active')
            .then(res => {
                setOrganizationsData(res.data);
            })
            .catch(err => {
                toast.error(err?.response?.data?.message);
            })
    }

    return (
        <div>
            <div className='d-flex flex-row-reverse'>
                <div style={{ 'marginTop': '-53px', 'marginRight': '40px' }}>
                    <Tooltip title="Add User">
                        <Button alignItems="center" color="primary" onClick={() => props.handleClickOpen('')}>
                            <AddCircleOutlineIcon style={{ 'fontSize': '30px' }} />
                        </Button>
                    </Tooltip>
                </div>
            </div>
            <MuiDialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogTitle id="create-user" onClose={props.handleClose}>
                    {props.editUserData && props.editUserData.id && props.editUserData.id != '' ?
                        'Update User'
                        :
                        'Create User'
                    }
                </DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model" autoComplete="new-password">
                    <DialogContent >
                        <input type="hidden" {...register("id")} />
                        {props.editUserData && props.editUserData.id && props.editUserData.id != '' ?
                            <>
                                <div className='row d-flex'>
                                    <div className='col-md-12'>
                                        <div className="d-flex align-items-center">
                                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                                {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                                <Controller
                                                    className="input-control"
                                                    name="email"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField  {...field} label="Email" variant="outlined" autoComplete="new-password"
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    )}
                                                    rules={{
                                                        required: true,
                                                        pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/
                                                    }}
                                                />
                                                {errors?.email?.type === "required" && <label className="text-danger">This field is required</label>}
                                                {errors?.email?.type === "pattern" && <label className="text-danger">The field must be a valid email.</label>}
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                        {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                        <Controller
                                            className="input-control"
                                            name="password"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField  {...field} type='password' label="Password" variant="outlined" autoComplete="new-password"
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                />
                                            )}
                                            rules={{
                                                required: true,
                                                minLength: 6,
                                                maxLength: 20
                                            }}
                                        />
                                        {errors?.password?.type === "required" && <label className="text-danger">This field is required</label>}
                                        {errors?.password?.type === "minLength" && <label className="text-danger">The field must be 6 or more.</label>}
                                        {errors?.password?.type === "maxLength" && <label className="text-danger">The field must be 20 or less.</label>}
                                    </FormControl>
                                </div>
                            </>
                            :
                            <>
                                <div className='row d-flex'>
                                    <div className='col-md-12'>
                                        <div className="d-flex align-items-center">
                                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                                {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                                <Controller
                                                    className="input-control"
                                                    name="email"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField  {...field} label="Email" variant="outlined" autoComplete="new-password" />
                                                    )}
                                                    rules={{
                                                        required: true,
                                                        pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/
                                                    }}
                                                />
                                                {errors?.email?.type === "required" && <label className="text-danger">This field is required</label>}
                                                {errors?.email?.type === "pattern" && <label className="text-danger">The field must be a valid email.</label>}
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                        {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                        <Controller
                                            className="input-control"
                                            name="password"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField  {...field} type='password' label="Password" variant="outlined" autoComplete="new-password" />
                                            )}
                                            rules={{
                                                required: true,
                                                minLength: 6,
                                                maxLength: 20
                                            }}
                                        />
                                        {errors?.password?.type === "required" && <label className="text-danger">This field is required</label>}
                                        {errors?.password?.type === "minLength" && <label className="text-danger">The field must be 6 or more.</label>}
                                        {errors?.password?.type === "maxLength" && <label className="text-danger">The field must be 20 or less.</label>}
                                    </FormControl>
                                </div>
                            </>
                        }

                        <div className="d-flex align-items-center">
                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                <InputLabel id="module-multiple-checkbox-label">Organization</InputLabel>
                                <Controller
                                    className="input-control"
                                    name="organizationId"
                                    control={control}
                                    render={({ field }) => (
                                        <MaterialSelect
                                            {...field}
                                            labelId="module-multiple-checkbox-label"
                                            id="module-multiple-checkbox"
                                            value={selectOrganization}
                                            onChange={handleChangeOrganization}
                                            label="Organization"
                                            variant="outlined"
                                            MenuProps={MenuProps}
                                            menuPlacement="top"
                                        >
                                            {organizationsData.map((element) => (
                                                <MenuItem key={element.id} value={element.id} >
                                                    {element.title}
                                                </MenuItem>
                                            ))}
                                        </MaterialSelect>
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.organizationId?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>
                        <div className="mt-3 mb-2">
                            <div className='align-items-center'>
                                <InputLabel id="group-multiple-checkbox-label">Groups</InputLabel>
                            </div>
                            <div className='align-items-center'>
                                <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px', 'zIndex': '2' }}>
                                    <Controller
                                        className="input-control"
                                        name="groups"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                variant="outlined"
                                                isMulti
                                                closeMenuOnSelect={true}
                                                isSearchable={true}
                                                isClearable={true}
                                                options={groupsData}
                                                getOptionLabel={option => `${option.title}`}
                                                getOptionValue={option => `${option.id}`}
                                                value={groupsData.filter(obj => selectGroupsArray.includes(obj.id.toString()))}
                                                hideSelectedOptions={true}
                                                backspaceRemovesValue={false}
                                                onChange={e => handleChange(e)}
                                                menuPlacement="top"
                                            />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.groups?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                <Controller
                                    className="input-control"
                                    name="description"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField  {...field} label="Description" variant="outlined" />
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.description?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>
                        <div className="d-flex align-items-center mt-3">
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label"><b>Status</b></FormLabel>
                                <Controller
                                    className="input-control"
                                    name="status"
                                    control={control}
                                    render={({ field }) => (
                                        <RadioGroup
                                            row
                                            onChange={handleRadioChange}
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                        >
                                            <FormControlLabel className='ml-2' id='status-active' value="1" control={<Radio color="primary" checked={statusCheck && statusCheck == 1 ? true : false} />} label="Active" />
                                            <FormControlLabel className='ml-2' id='status-inactive' value="0" control={<Radio color="primary" checked={statusCheck == 0 ? true : false} />} label="Inactive" />
                                        </RadioGroup>
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.status?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>

                    </DialogContent>
                    <DialogActions className='mt-2'>
                        <Button type='submit' className="mr-2" color="primary" variant="contained"
                            disabled={isLoading && isLoading == true ? true : false} >
                            {props.editUserData && props.editUserData.id && props.editUserData.id != '' ?
                                'Update User'
                                :
                                'Create User'
                            }
                        </Button>
                        <Button type='button' variant="contained" color="grey" onClick={props.handleClose}>Cancel</Button>
                    </DialogActions>
                </form>
            </MuiDialog>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default (withStyles(styles), connect(mapStateToProps))(AddUser);
