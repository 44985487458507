import React, { useState, useEffect } from 'react';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import adminApi from '../../assets/constants/AdminRafservice.Instance';
import { logoutModelClose, signOut } from '../../store/actions';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import history from '../../history';
import { toast } from 'react-toastify';
const styles = (theme) => ({
    dialogPaper: {
        maxWidth: '80%',
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const LogoutModel = (props) => {


    useEffect(() => {

    }, []);

    const handleClose = () => {
        props.logoutModelClose();
    };
  
    const handleCloseAndRedirect = () => {
        props.logoutModelClose();
        props.signOut();
        localStorage.clear();
        history.push('/');
    };

    return (
        <div>
            <MuiDialog
                open={props.isLogoutModelOpen}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogTitle id="create-group" onClose={handleClose} onClick={handleCloseAndRedirect}>
                    <strong>Attention!</strong>
                </DialogTitle>
                <form id="add-verification-model">
                    <DialogContent >

                        <div className="d-flex align-items-center">
                            Your session has expired. Please login again.
                        </div>

                    </DialogContent>
                    <DialogActions className='mt-2'>
                        <Button type='button' variant="contained" color="grey" onClick={handleCloseAndRedirect}>Ok</Button>
                    </DialogActions>
                </form>
            </MuiDialog>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        isLogoutModelOpen: state.auth.isLogoutModelOpen,
    };
}
export default (withStyles(styles), connect(mapStateToProps, { logoutModelClose, signOut }))(LogoutModel);
