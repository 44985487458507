import React, { useEffect, useState } from 'react'
import HomeIcon from '../../icons/HomeIcon'
import IconButton from './IconButton'
import MenuBarsIcon from '../../icons/MenuBarsIcon'
import Breadcrumbs from './Breadcrumbs'
import PeterVelel from '../../../assets/awe/images/Peter-Velel.jpg';

export default function MainFooter({ sidebarToggle, sidebarOpen }) {
    const [screenSize, setScreenSize] = useState(window.innerWidth)
    useEffect(() => {
        window.addEventListener('resize', function (event) {
            setScreenSize(window.innerWidth)
        });
    }, [])
    return (
        <footer className="footer-wrapper">
            <div className="text-center">
                <p>CPT codes, descriptions and other data only are copyright 1995 - 2022 American Medical Association. All rights reserved. <br/> CPT is a registered trademark of the American Medical Association (AMA).</p>
            </div>
        </footer>
    )
}
