import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { Chart } from "react-google-charts";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


const LineChartAWV = (props) => {
    const chart = useRef(null);
    const [type, setType] = useState('');
    const [data, setData] = useState([]);

    useLayoutEffect(() => {
        setData(props.data);
        // setData([
        //     ['YEAR', '2022', '2021', '2020'],
        //     ["August1", 10, 11, 12],
        //     ["August2", 5, 6, 7],
        //     ["August3", 15, 16, 17],
        //     ["August4", 15, 16, 17],
        // ]);
    }, [props.data]);

    const options = {
        // width: 900,
        // height: 500,
        // series: {
        //   // Gives each series an axis name that matches the Y-axis below.
        //   0: { axis: "Temps" },
        //   1: { axis: "Daylight" },
        // },
        // axes: {
        //   // Adds labels to each axis; they don't have to match the axis names.
        //   y: {
        //     Temps: { label: "Temps (Celsius)" },
        //     Daylight: { label: "Daylight" },
        //   },
        // },
        hAxis: { title: "Month of Service Date" },
        legend: { position: "right" },
        vAxis: { title: "Distinct count of Awe Id", minValue: 0 },
        chartArea: { width: "78%", height: "70%" },
        series: {
            1: { curveType: "function" },
        },
        colors: ['#1a9698', '#ff0000', '#e3e3e3', '#fcb441']
    };

    return (
        <Chart
            chartType="LineChart"
            width="100%"
            height="400px"
            data={data}
            options={options}
        />
        // <div id="chartdivStack" style={{ width: "100%", height: "400px", cursor: 'pointer' }}></div>
    );
}
export default LineChartAWV;