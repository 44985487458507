import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/layouts/adminStyle.js";
import bgImage from "../../assets/images/sidebar.jpeg";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import Description from '../../Pages/ETL-S3Configuration/Description';
import Sidebar from "../Sidebar/ETLSidebar";
import routes from "../../routes/etlRoutes";
import logo from "../../assets/images/logo.png";
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import FileUpload from '../../Pages/ETL-S3Configuration/FileUpload';
import FileDownload from '../../Pages/ETL-S3Configuration/FileDownload';
import Header from '../../components/home/header/header';
import ReportsDashboard from '../../Pages/ETL-S3Configuration/ReportsDashboard';

const useStyles = makeStyles(styles);

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/etl") {
        return (
          <>
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />

            {

              prop.subRoutes && prop.subRoutes.length > 0 ?
                prop.subRoutes.map((subRoute, key1) => {
                  <Route
                    path={subRoute.layout + subRoute.path}
                    component={subRoute.component}
                    key={key1}
                  />

                  // subRoute.subRoute.map((subRoute1, key2) => {
                  //   console.log('Paths subRoute layout',subRoute1.layout + subRoute1.path );
                  //   <Route
                  //   path={subRoute1.layout + subRoute1.path}
                  //   component={subRoute1.component}
                  //   key={key2}
                  // />
                  // })
                })
                : ''
            }
          </>
        );
      }
      return null;
    })}
    <Redirect from="/etl" to="/etl/fileUpload" />
  </Switch>
);

let ps;
export default function EtlLayout() {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  //const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  React.useEffect(() => {
    document.getElementsByTagName("body")[0].classList.add("normal-layout-container")
    document.getElementsByTagName("body")[0].classList.remove("awe-layout-container")
  }, [])
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  const getMenuPermissionCheck = (permissionSlug) => {
    var permissionCheck = false;
    if (permissionSlug && permissionSlug != '') {
      var permissionGets = JSON.parse(localStorage.getItem('controlPermissions'));
      if (permissionGets && permissionGets.length > 0) {
        permissionGets && permissionGets.forEach(elem => {
          if (elem.permission == permissionSlug && elem.status == true) {
            permissionCheck = true;
          }
        });
      }
    }
    return permissionCheck;
  }

  return (
    <div className="App  main-layout-background">
      <Header></Header>
      <div className="router-box">
        <div className={classes.wrapper}>
          <Sidebar
            routes={routes}
            logo={logo}
            image={image}
            handleDrawerToggle={handleDrawerToggle}
            open={mobileOpen}
            color={color}
          />

          <div className={classes.mainPanel} ref={mainPanel} id="headerTop">
            <ScrollToTop>
              <div className={classes.content}>
                <div className={classes.container}>

                  <Route path="/etl/fileUpload" component={FileUpload} />
                  <Route path="/etl/desctiption" component={Description} />
                  <Route path="/etl/fileDownload" component={FileDownload} />
                  <Route path="/etl/reports" component={ReportsDashboard} />                  
                </div>
              </div>
            </ScrollToTop>
          </div>
        </div>
      </div>
    </div>
  );
}
