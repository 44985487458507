import React, { useState, useEffect } from "react";
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import AcaYearSelect from "../../components/DropDowns/AcaYearSelect";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag, numberWithCommas } from "../../assets/constants/customRender";
import { HCC, ISSUER_ID } from "../../assets/constants/string.constants";
import { getAcaIssuer } from "../../assets/constants/issuerList";
import history from "../../history";
import CardComponent from "../../components/Cards/card";
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';

const ConditionCampaignsACA = (props) => {

    const [year, setYear] = useState(props.yearSelect);
    const [issuer] = useState(getAcaIssuer());
    const hccColumns = [
        {
            label: "Code",
            name: "code",
            options: anchorTag
        },
        {
            label: "Description",
            name: "description"
        },
        {
            label: "Total Member",
            name: "count",
            options: numberWithCommas
        }
    ]

    useEffect(() => {

    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect,
        props.meInitialSelect
    ]);

    const hccTableClick = (rowdata, colData, cellMeta) => {

        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(ISSUER_ID, issuer);
            localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].code);
            history.push('/layout/acaBigramHcc')
        }
    };

    return (
        <div className="container level-summary">
            <div className="row mb-3">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Condition Based Campaigns'} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    {year ?
                        <CardComponent
                            heading={`HCC LIST`}
                            body={
                                <ServerSideGridTable
                                    url={`/acaReportedHccs/year/${year}/issuerId/ALL`}
                                    columns={hccColumns}
                                    tableClick={hccTableClick}
                                    defaultSortCol={2}
                                    defaultSortDir={'desc'}
                                ></ServerSideGridTable>
                            }></CardComponent>
                        :
                        <></>
                    }

                </div>
                <br />
                <br />
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default connect(mapStateToProps)(ConditionCampaignsACA);