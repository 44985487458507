import React, { useRef, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar } from '../../../store/actions';
import AWVApi from '../../../assets/constants/AWVRafservice.Instance';
import AWVCoderWorkBench from '../../../assets/constants/AWVCoderWorkBenchRafservice.Instance';
import Divider from '@mui/material/Divider';
import Moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import { CircularProgress } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { styled } from '@mui/material/styles';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import IconButton from "../../layout/aweLayout/IconButton";
import Button from "../../layout/aweLayout/Button";
import CloseIcon from "../../icons/CloseIcon";

const MemberHCCRemark = (props) => {
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [formDataList, setFormDataList] = useState([]);
    const [cwdxRemarkList, setCwdxRemarkist] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
    const [editFormData, setEditFormData] = useState({});
    let controlUser = JSON.parse(localStorage.getItem('controlUser'));
    useEffect(() => {
        editLoadFormData(props.AwvId, props.CinId);
        // loadformDataTable(props.AwvId, props.CinId, props.hcc_code, props.hcc_description);
    }, [props.CinId]);

    useEffect(() => {
        if(props.nlp_id){
            loadCWRemarkData(props.id)
        } else {
            loadformDataTable(props.AwvId, props.CinId, props.hcc_code, props.hcc_description);
        }
        
    }, [props.CinId])

    useEffect(() => {

        if (editFormData?.awv_id && editFormData?.awv_id != '') {
            setValue('awv_id', editFormData?.awv_id);
            setValue('cin', editFormData?.cin);
            setValue('year', props?.yearSelect);
            setValue('lob', props?.lobSelect.toString());
            setValue('organisation_id', "1"); //To doi
            setValue('member_id', editFormData?.member_id);
            setValue('created_by', controlUser?.user_id);
            setValue('hcc_code', props.hcc_code)
            setValue('hcc_description', props.hcc_description)
        }
    }, [editFormData]);

    const notificationRightDrawer = (open, valueId = '') => {
        props.currentAWERightSidebarType({ 'type': 'notification' });
        props.currentAWERightSidebar(open);
    };

    const editLoadFormData = (valueAwv, valueCin) => {
        setIsLoading(true);
        AWVApi.get('/get-all-awv-record?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + '&awvId=' + valueAwv + '&cin=' + valueCin + '&iSortCol_0=provider_group&sSortDir_0=asc&iDisplayStart=0&iDisplayLength=1&formAttached=0')
            .then(res => {
                console.log(res.data);
                if (res.data && res.data[0]) {
                    setEditFormData(res.data[0]);
                    setIsLoading(false);
                } else {
                    setEditFormData();
                    setIsLoading(false);
                }
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
            })
    }

    const loadformDataTable = (valueAwv, valueCin, hcc_code, hcc_description) => {
        setIsLoading(true);
        AWVApi.get('/get-hcc-checklist-remark?cin=' + valueCin + '&awv_backend_id=' + valueAwv + '&lob=' + props?.lobSelect + '&organisation_id=1&year=' + props?.yearSelect + '&dx_code=' + hcc_code + '&dx_description=' + hcc_description) //'&provider_group=' + props?.providerGroupSelect
            .then(res => {
                var dxRemarks = (res.data && res.data) ? res.data : [];
                var conactArrayNew = formDataList.concat(dxRemarks);
                conactArrayNew.sort(function (a, b) {
                    var dateA = new Date(a.created_on), dateB = new Date(b.created_on)
                    return dateB - dateA;
                });
                setCwdxRemarkist(conactArrayNew);
                setIsLoading(false);
                
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    }
    const loadCWRemarkData = (id) => {
        AWVCoderWorkBench.get('data/cwb/history/cw_dx/' + btoa(id)) //'&provider_group=' + props?.providerGroupSelect
            .then(res => {
                setCwdxRemarkist(res?.data);
                setFormDataList(res?.data);               
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    }

    const onSubmit = (data) => {
        console.log(data);
        data.awv_id = props.AwvId;
        data.cin = props.CinId.toString();
        data.year = props.yearSelect.toString();
        data.lob = props?.lobSelect.toString();
        data.organisation_id = "1";
        data.created_by = controlUser?.user_id.toString();
        data.hcc_code = props.hcc_code.toString();
        data.hcc_description = props.hcc_description.toString();
        data.member_id = data.member_id ? data.member_id : '';
        setFormSubmitisLoading(true);
        AWVApi.post('/create-hcc-checklist-remark', data)
            .then(res => {
                setValue('notes', '');
                setFormSubmitisLoading(false);
                toast.success('Remarks added successfully');
                loadformDataTable(props.AwvId, props.CinId, props.hcc_code, props.hcc_description);
                // notificationRightDrawer(false);
            })
            .catch(err => {
                setFormSubmitisLoading(false);
                toast.error(err?.response?.data?.message);
            })
    }

    return (
        <div className="diagnosis_code_sidebar offcanvas offcanvas-end show" tabIndex={-1} aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header flex_item_cb">
                <h5 id="offcanvasRightLabel" className='fs15'>{props.title ? props.title : 'QUICK NOTES'}</h5>
                <Tooltip title="Close">
                    <IconButton type="button" buttonType={"default"} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => notificationRightDrawer(false)}>
                        <CloseIcon className="w24" />
                    </IconButton>
                </Tooltip>
            </div>
            <Divider />
            <div className="offcanvas-body pt-1 custom-scrollbar overflow-auto">
                {/* <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">REMARK</label>
                        <Controller
                            value={getValues('remark')}
                            name="remark"
                            control={control}
                            render={({ field }) => (
                                <textarea rows={3} {...field} className="form-control" />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.remark?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group flex_item_cs gap10">
                        <Button type='button' className={"btn-white minw80"} onClick={() => notificationRightDrawer(false)}>
                            CANCEL
                        </Button>
                        <Button type='submit' className={"minw80"} disabled={formSubmitisLoading && formSubmitisLoading == true ? true : false}>
                            SAVE
                        </Button>
                    </div>
                </form> */}
                {isLoading ?
                    <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        {cwdxRemarkList && cwdxRemarkList.length > 0 ?
                            <div className="">
                                <h5 className="fs18 mb10">History</h5>
                                <Divider />
                                <div className="history_card_list">
                                    {cwdxRemarkList && cwdxRemarkList.map((element, index) =>
                                         <div key={index} className={
                                            element.form_status === 'Accepted' ? 'history_card_item status-primary' :
                                                element.form_status === 'Rejected' ? 'history_card_item status-danger' :
                                                        element.form_status === 'Pending for clarification' ? 'history_card_item status-warning' :
                                                            'history_card_item status-primary'
                                        } >
                                            <p className="fs12 mb-1 fw700">
                                                {Moment(element.created_on).format('h:mm a, MMMM DD YYYY')}
                                            </p>
                                            <p className="fs12 mb-1">
                                            {element.form_status ? 
                                                <span className="fw700">Status :-</span>
                                            : <></> }
                                                {element.form_status}
                                            
                                            </p>
                                            <p className="fs12 mb-1">
                                            <span className="fw700">Remarks : </span>
                                                {element.remarks ? element.remarks : element.remark}
                                            </p>
                                            <p className="fs12 mb-1">
                                                <span className="fw700">Last updated by : </span>
                                                {element.user_id ? element.user_id :element.created_by }
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            :
                            <> </>
                        }
                    </>
                }
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar })(MemberHCCRemark);

