import React, { useState, useEffect } from 'react';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import adminApi from '../../assets/constants/AdminRafservice.Instance';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Select, { components } from "react-select";
import { connect } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import {LogFileUploadToS3} from '../../assets/constants/LogFileUploadToS3'

const styles = (theme) => ({
    dialogPaper: {
        maxWidth: '80%',
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const AddRole = (props) => {
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [year, setYear] = useState(props.yearSelect);
    const [open, setOpen] = React.useState();
    const [permissionsData, setPermissionsData] = useState([]);
    const [selectPermissions, setSelectPermissions] = useState();
    const [selectPermissionsArray, setSelectPermissionsArray] = useState([]);
    const [statusCheck, setStatusCheck] = useState('1');
    const [isLoading, setIsLoading] = useState(false);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const [logMessages, setLogMessages] = useState([]); 
    const writeToLogFile = (message) => {
       setLogMessages(prevLogMessages => [...prevLogMessages, `${new Date().toISOString()} - ${message}`]);
   };
   const [generateLog, setGenerateLog] = useState(false);

    useEffect(() => {
        writeToLogFile("props.editRoleData: " + JSON.stringify(props.editRoleData));
        if (props.editRoleData && props.editRoleData.id) {
            setValue('id', props.editRoleData.id);
            setValue('title', props.editRoleData.title);
            setValue('description', props.editRoleData.description);
            setValue('status', props.editRoleData.status);
            setStatusCheck(props.editRoleData.status);
            var permissionsArray = [];
            if (props.editRoleData.permissions && props.editRoleData.permissions.length > 0) {
                props.editRoleData.permissions.forEach(element => {
                    permissionsArray.push(element.id.toString())
                });
            }
            setValue('permissions', permissionsArray);
            setSelectPermissions(permissionsArray.join(","));
            setSelectPermissionsArray(permissionsArray);
        } else {
            writeToLogFile("Else part of the table")
            setValue('id', '');
            setValue('title', '');
            setValue('description', '');
            setValue('status', statusCheck);
            setValue('permissions', '');
            setSelectPermissions('');
            setSelectPermissionsArray([]);
        }
    }, [props.editRoleData]);

    const handleRadioChange = (event) => {
        writeToLogFile("Inside handleRadioChange()")
        const value = event.target.value;
        writeToLogFile("value: ", value)
        setStatusCheck(event.target.value);
        setValue('status', event.target.value);
    };

    const onSubmit = (data) => {
        writeToLogFile("Inside onSubmit()");
        setIsLoading(true);
        // event.preventDefault();
        writeToLogFile("data: " + JSON.stringify(data));
        if (data.id && data.id != '') {
            adminApi.put('/role/' + data.id, data)
                .then(res => {
                    props.handleClose();
                    setValue('title', '');
                    setValue('description', '');
                    setValue('status', '1');
                    setValue('permissions', '');
                    setSelectPermissions('');
                    setSelectPermissionsArray([]);
                    setStatusCheck('1');
                    props.loadRoleData();
                    setIsLoading(false);
                })
                .catch(err => {
                    setIsLoading(false);
                    toast.error(err?.response?.data?.message);
                })
        } else {
            adminApi.post('/role', data)
                .then(res => {
                    props.handleClose();
                    setValue('title', '');
                    setValue('description', '');
                    setValue('status', '1');
                    setValue('permissions', '');
                    setSelectPermissions('');
                    setSelectPermissionsArray([]);
                    setStatusCheck('1');
                    props.loadRoleData();
                    setIsLoading(false);
                })
                .catch(err => {
                    setIsLoading(false);
                    toast.error(err?.response?.data?.message);
                })
        }

    };

    useEffect(() => {
        loadPermissionData();
    }, []);

    useEffect(() => {
        setSelectPermissions(selectPermissionsArray.join(","));
        setValue('permissions', selectPermissionsArray);
    }, [selectPermissionsArray]);

    const handleChange = (event) => {
        let setPermissionSelected = [];
        if (event != '' && event.length > 0) {
            event.map(function (el) {
                setPermissionSelected.push(el.id.toString());
            })
        }
        setSelectPermissionsArray(setPermissionSelected);
    };

    const loadPermissionData = () => {
        adminApi.get('/permission/active')
            .then(res => {
                setPermissionsData(res.data);
            })
            .catch(err => {
                toast.error(err?.response?.data?.message);
            })
    }

    return (
        <div>
            <div className='d-flex flex-row-reverse'>
                <div style={{ 'marginTop': '-53px', 'marginRight': '40px' }}>
                    <Tooltip title="Add Role">
                        <Button alignItems="center" color="primary" onClick={() => props.handleClickOpen('')}>
                            <AddCircleOutlineIcon style={{ 'fontSize': '30px' }} />
                        </Button>
                    </Tooltip>
                </div>
            </div>
            <MuiDialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogTitle id="create-role" onClose={props.handleClose}>
                    {props.editRoleData && props.editRoleData.id && props.editRoleData.id != '' ?
                        'Update Role'
                        :
                        'Create Role'
                    }
                </DialogTitle>
                <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                    <DialogContent >
                        <div className="d-flex align-items-center">
                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                <Controller
                                    className="input-control"
                                    name="title"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField  {...field} label="Role Name" variant="outlined" />
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.title?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>
                        <div className="d-flex align-items-center">
                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                <Controller
                                    className="input-control"
                                    name="description"
                                    control={control}
                                    render={({ field }) => (
                                        <TextField  {...field} label="Description" variant="outlined" />
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.description?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>
                        <div className="mt-3 mb-2">
                            <div className='align-items-center'>
                                <InputLabel id="group-multiple-checkbox-label">Permission</InputLabel>
                            </div>
                            <div className='align-items-center'>
                                <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px', 'zIndex': '2' }}>
                                    <Controller
                                        className="input-control"
                                        name="permissions"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                variant="outlined"
                                                isMulti
                                                closeMenuOnSelect={true}
                                                isSearchable={true}
                                                isClearable={true}
                                                options={permissionsData}
                                                getOptionLabel={option => `${option.title}`}
                                                getOptionValue={option => `${option.id}`}
                                                value={permissionsData.filter(obj => selectPermissionsArray.includes(obj.id.toString()))}
                                                hideSelectedOptions={true}
                                                backspaceRemovesValue={false}
                                                onChange={e => handleChange(e)}
                                                menuPlacement="top"
                                            />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.permissions?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mt-3">
                            <FormControl>
                                <FormLabel id="demo-row-radio-buttons-group-label"><b>Status</b></FormLabel>
                                <Controller
                                    className="input-control"
                                    name="status"
                                    control={control}
                                    render={({ field }) => (
                                        <RadioGroup
                                            row
                                            onChange={handleRadioChange}
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                        >
                                            <FormControlLabel className='ml-2' id='status-active' value="1" control={<Radio color="primary" checked={statusCheck && statusCheck == 1 ? true : false} />} label="Active" />
                                            <FormControlLabel className='ml-2' id='status-inactive' value="0" control={<Radio color="primary" checked={statusCheck == 0 ? true : false} />} label="Inactive" />
                                        </RadioGroup>
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.status?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>

                    </DialogContent>
                    <DialogActions className='mt-2'>
                        <Button type='submit' className="mr-2" color="primary" variant="contained"
                            disabled={isLoading && isLoading == true ? true : false} >
                            {props.editRoleData && props.editRoleData.id && props.editRoleData.id != '' ?
                                'Update Role'
                                :
                                'Create Role'
                            }
                        </Button>
                        <Button type='button' variant="contained" color="grey" onClick={props.handleClose}>Cancel</Button>
                    </DialogActions>
                </form>
            </MuiDialog>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default (withStyles(styles), connect(mapStateToProps))(AddRole);
