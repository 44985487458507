import { SIGN_IN, SIGN_OUT, PERMISSION_ASSIGN, LOGOUT_MODEL_OPEN, LOGOUT_MODEL_CLOSE } from '../actions/types';
const INITIAL_STATE = {
    isSignedIn: null,
    userDetails: null,
    permissionDetails: null,
    isLogoutModelOpen: false
};

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SIGN_IN:
            return { ...state, isSignedIn: true, userDetails: action.payload, userName: action.payload.user };
        case SIGN_OUT:
            return { ...state, isSignedIn: false, userName: null };
        case LOGOUT_MODEL_OPEN:
            return { ...state, isLogoutModelOpen: true, userName: null };
        case LOGOUT_MODEL_CLOSE:
            return { ...state, isLogoutModelOpen: false, userName: null };
        case PERMISSION_ASSIGN:
            return { ...state, permissionDetails: action.payload };
        default:
            return state;
    }
};

export default authReducer;