import React, { useState, useEffect, useRef} from "react";
import api from '../../assets/constants/Rafservice.Instance';
import {getAcaYear} from '../../assets/constants/yearList';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag, hccSeperator, numberWithCommas,addSuffixTwoDecimal } from "../../assets/constants/customRender";
import GridTable from "../../components/TableGrids/GridTable.table";
import SingleHorizontalBar from "../../components/Charts/SingleHorizontalBar.chart";
import history from '../../history';
import { GAP_INSIGHTS, HCC, GENDER,MAO_Chart_Review_Member, ISSUER_ID, MAO_MEMBER_SELECT_TAB, REPORTED_INSIGHTS } from "../../assets/constants/string.constants";
import CardComponent from "../../components/Cards/card";
import SubHeader from '../../components/home/header/subHeader';
import { connect } from 'react-redux';
import BarVerticalHCC from '../../components/GChart/BarVerticalHCC';
import { getMeInitialSelect} from '../../assets/constants/meInitialList';
//import google from "react-google-charts";
const MaoGapHcc = (props) => {
    const hccElementRef = useRef('')
    const [hcc, setHcc] = useState(localStorage.getItem(HCC));
    const [issuer_id] = useState(localStorage.getItem(ISSUER_ID));
    const [HccReportedChartData, setHccReportedChartData] = useState({});  
    const [HccGAPChartData, setHccGAPChartData] = useState({});  
    const [tableData, setTableData] = useState([]);
    const [TopCooccurringHccConditionGapChartData, setTopCooccurringHccConditionGapChartData] = useState([]);
    const [TopCooccurringHccReportedConditionChartData, setTopCooccurringHccReportedConditionChartData] = useState([]);

    const  summaryColumns =[
        {
            name: "hccCode",
            label: "HCC Code ",
        },
        {
            name: "description",
            label: "Description",
        },
        {
            name: "totalCount",
            label: "# Members",
            options:numberWithCommas
        },
        {
            name: "totalGaps",
            label: "# Members with Gap",
            options:numberWithCommas
        },
        
    ]


    const memberColumn = [
        {
            label: "Member ID",
            name: "MEMBER_ID",
            options: anchorTag
        },
        {
            name: "Reported_hcc",
            label: "Reported HCCs",
            options: hccSeperator
        },
        {
            name: "Gap_hcc",
            label: "Gap HCCs",
            options: hccSeperator
        },
        {
            name: "Raf_score",
            label: "RAF Score",
            options:addSuffixTwoDecimal,
        },
        {
            name: "Gap_in_raf_score",
            label: "Gap In RAF Score",
            options:addSuffixTwoDecimal,
        },
        {
            name: "Revenu_leekage",
            label: "Potential Revenue Impact $",
        },
        {
            name: "Revenue_percent",
            label: "Potential Revenue Impact %",
        },
        {
            name: "Hcc_score",
            label: "Reported HCC Score",
            options:addSuffixTwoDecimal,
        },
        {
            name: "Gap_in_hcc_score",
            label: "Gap in HCC Score",
            options:addSuffixTwoDecimal,
        }
    ];

    const memberTableClick = (rowdata, colData, cellMeta) =>{
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(MAO_Chart_Review_Member, rowdata[cellMeta.rowIndex].MEMBER_ID);
            localStorage.setItem(MAO_MEMBER_SELECT_TAB, GAP_INSIGHTS);
            history.push('/layout/medicare/mao_member_summary')
        }else if ((cellMeta.colIndex === 1) && (colData.props.children != "")) {
            if(typeof(colData.props.children) == "object"){
                localStorage.setItem(HCC,(cellMeta.event.target.innerText).replace(',',''));
            }
            else{
                localStorage.setItem(HCC,rowdata[cellMeta.rowIndex].Reported_hcc);
            }
            history.push('/layout/medicare/reported_hcc')
        }else if ((cellMeta.colIndex === 2) && (colData.props.children != "")) {
            if(typeof(colData.props.children) == "object"){
                localStorage.setItem(HCC,(cellMeta.event.target.innerText).replace(',',''));
            }
            else{
                localStorage.setItem(HCC,rowdata[cellMeta.rowIndex].Gap_hcc);
            }
            history.push('/layout/medicare/chart_review_hcc_add')
        }
    }

    //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    const loadMetricTable = () =>{
        api.get('/gaphccDetail/hcc/'+hcc+'/year/'+getAcaYear()+'/period/'+getMeInitialSelect())
        .then(res=>{
            setTableData(res);
        })
        .catch(err=>{
            // console.log(err);
        })
    }

     //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    const loadTopCooccurringHccConditionGapChartdData = () => {        
        let newChartData = [];
            newChartData.push(["Provider", "Percentage", { role: 'annotation' }]);
            api.get('/gapToReported/year/'+getAcaYear()+'/hcc/'+hcc+'/period/'+getMeInitialSelect())
                .then(res => {
                    for (const dataObj of res) {
                        newChartData.push([dataObj.description+':'+dataObj.hccCode, dataObj.percentage, dataObj.percentage+'%']);
                    }
                    setTopCooccurringHccConditionGapChartData(newChartData);
            })
            .catch(err => {
                // console.log(err);
            })
    }

     //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    const loadTopCooccurringHccReportedConditionChartdData = () => {        
        let newChartData = [];
            newChartData.push(["Provider", "Percentage", { role: 'annotation' }]);
            api.get('/reportedToReported/year/'+getAcaYear()+'/hcc/'+hcc+'/period/'+getMeInitialSelect())
                .then(res => {
                    for (const dataObj of res) {
                        newChartData.push([dataObj.description+':'+dataObj.hccCode, dataObj.percentage, dataObj.percentage+'%']);
                    }
                    setTopCooccurringHccReportedConditionChartData(newChartData);
            })
            .catch(err => {
                // console.log(err);
            })
    }

    useEffect(() =>{
         loadMetricTable();
         loadTopCooccurringHccConditionGapChartdData();
         loadTopCooccurringHccReportedConditionChartdData();
    },[      
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect(),
        hcc
    ]);

    useEffect(() => {
        setHcc(localStorage.getItem(HCC));
    }, [localStorage.getItem(HCC)]);

    useEffect(() => {
        loadMetricTable();
        loadTopCooccurringHccConditionGapChartdData();
        loadTopCooccurringHccReportedConditionChartdData();
    }, [hcc]);

    return(
        <div className="container level-summary">
            <div className="row ">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Gap HCC'} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mb-3" ref={hccElementRef}>
                    <CardComponent
                    heading={`GAP HCC VIEW - ${props.yearSelect} / ${props.meInitialSelect}`}
                    body={
                        <GridTable
                            data={tableData}
                            columns={summaryColumns}
                        ></GridTable>
                    }></CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <CardComponent
                    heading={`MEMBERS WITH GAPS`}
                    body={
                        <ServerSideGridTable
                            url={`GapHcc/year/${getAcaYear()}/hcc/${hcc}/period/${getMeInitialSelect()}`}
                            columns={memberColumn}
                            tableClick={memberTableClick}
                        ></ServerSideGridTable>
                    }></CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-4">
                    <CardComponent
                            heading={`TOP 5 CO-OCCURRING HCCS FOR THIS CONDITION GAP`}
                            body={
                                <div className="box-height-chart"> 
                                    <BarVerticalHCC data={TopCooccurringHccConditionGapChartData} chartType="BarChart" height="300px" width="100%" hAxisTitle={'Percentage (%)'} yAxistitle={"HCC's"} setStorgeItemName={HCC} redirectUrl={'medicare/gap_hcc'} scrollRef={hccElementRef}></BarVerticalHCC>
                                </div>
                        }></CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-4">
                    <CardComponent
                            heading={`TOP 5 CO-OCCURRING HCCS FOR THIS REPORTED CONDITION`}
                            body={
                                <div className="box-height-chart"> 
                                    <BarVerticalHCC data={TopCooccurringHccReportedConditionChartData} chartType="BarChart" height="300px" width="100%" hAxisTitle={'Percentage (%)'} yAxistitle={"HCC's"} setStorgeItemName={HCC} redirectUrl={'medicare/gap_hcc'} scrollRef={hccElementRef}></BarVerticalHCC>
                                </div>
                        }></CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br/>

                <br/>
                <br/>    
            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(MaoGapHcc);
