export const revenueFormat = (value) =>{
    
    return '$' + Number(value).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const numberDecimalFormat = (value) =>{
    
    return Number(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const percentFormat = (value) =>{
    if(value > 0){
        return Number(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")+' %';
    }else{        
        return value+' %';
    }
}

export const dateFormate = (value) => {
    let todayTime = new Date(value);
    let month = (todayTime .getMonth() + 1);
    let day = (todayTime .getDate());
    let  year = (todayTime .getFullYear());
    return month + "/" + day + "/" + year;
}