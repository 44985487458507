import { CognitoUserPool } from 'amazon-cognito-identity-js';

const APP_CLIENT_ID = process.env.REACT_APP_PATH_CLIENT_ID;
const USER_POOL_ID = process.env.REACT_APP_PATH_USER_POOL_ID;

const poolData = {
  UserPoolId: USER_POOL_ID,
  ClientId: APP_CLIENT_ID
};

export default new CognitoUserPool(poolData);
