import React, { useRef, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../store/actions';
import AWVApi from '../../assets/constants/AWVRafservice.Instance';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import Divider from '@mui/material/Divider';
import { useForm, Controller } from "react-hook-form";
import Box from '@mui/material/Box';
import pdfIcon from '../../assets/images/pdf_icon.png';
import Moment from 'moment';
import Button from '@material-ui/core/Button';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const AWVMemberDetailsMultiCoder = (props) => {
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
    const [editFormData, setEditFormData] = useState({});
    const [coderRecordList, setCoderRecordList] = useState({});
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    useEffect(() => {
        if (props.aweRightSidebarType?.cin_id && props.aweRightSidebarType?.cin_id != '') {
            editLoadFormData(props.aweRightSidebarType?.cin_id, props.aweRightSidebarType?.awv_id);
        }

    }, [props.aweRightSidebarType?.cin_id]);

    useEffect(() => {
        if (editFormData?.checklist_id && editFormData?.checklist_id != '') {
            setValue('awe_id', String(props.aweRightSidebarType?.awv_id));
            setValue('cin', String(props.aweRightSidebarType?.cin_id));
        }
    }, [editFormData?.checklist_id]);

    const notificationRightDrawer = (open, valueId = '') => {
        props.currentAWERightSidebarType({ 'type': 'notification' });
        props.currentAWERightSidebar(open);
    };

    const editLoadFormData = (cinId, awvId) => {
        setIsLoading(true);
        AWVApi.get('/getawecoderchecklist?cin=' + cinId + '&awe_id=' + awvId + '&year=' + props?.yearSelect + '&lob=' + props?.lobSelect + '&organisation_id=1')
            // AWVApi.get('/get_member_hcc_details?member_id=3RE5W09MM81')
            .then(res => {
                if (res.data && res.data.length > 0) {
                    console.log(res.data);
                    setCoderRecordList(res.data);
                    setIsLoading(false);
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
            })
    }



    console.log(errors);
    console.log(errors?.remark);
    const onSubmit = (data) => {
        console.log(coderRecordList);
        console.log(data);
        if (coderRecordList && coderRecordList.length > 0) {

            setFormSubmitisLoading(true);
            let controlUser = JSON.parse(localStorage.getItem('controlUser'));
            data.cin = props.aweRightSidebarType?.cin_id;
            data.awe_id = props.aweRightSidebarType?.awv_id;
            if (props?.aweRightSidebarType?.status && props?.aweRightSidebarType?.status == '1') {

            } else {
                data.checklist = [];
                coderRecordList && coderRecordList.length > 0 && coderRecordList.map((element, index) =>
                    data.checklist.push({
                        'remarks': (data.remark[element.checklist_id] && data.remark[element.checklist_id] != 'null') ? data.remark[element.checklist_id] : '',
                        'status': (data.status[element.checklist_id] && data.status[element.checklist_id] != 'null') ? data.status[element.checklist_id] : '',
                        'checklist_id': element.checklist_id.toString()
                    })
                );
                console.log(data);
                if (coderRecordList && data.checklist) {
                    setFormSubmitisLoading(false);
                    delete data.remark;
                    delete data.status;
                    data.updated_by = controlUser?.user_id;
                    // adding lob, year, org id in payload
                    data.lob = props?.lobSelect;
                    data.year = props?.yearSelect;
                    data.organisation_id = '1';
                    if(data?.lob)
                        data.lob = data.lob.toString();
                    AWVApi.put('https://y3r5o7igxbzzag6ffmdejo3gky0twrxi.lambda-url.us-west-2.on.aws/update_awe_coder_checklist', data)
                        .then(res => {
                            setValue('remark', '');
                            setValue('status', '');
                            setValue('checklist_id', '');
                            setValue('awe_id', '');
                            setValue('cin', '');
                            setFormSubmitisLoading(false);
                            toast.success('Your submission of checklist is accepted for updation, it will be updated soon.');
                            notificationRightDrawer(false);
                            props.currentAWERightSidebarCloseDatatableReload(true);
                        })
                        .catch(err => {
                            setFormSubmitisLoading(false);
                            toast.error(err?.response?.data?.message);
                        })
                }
            }
        }

    };

    return (
        <div style={{ padding: "10px 0px" }}>
            <div key={'index'} variant={'head'} style={{ width: '350px', padding: "10px", height: "50px" }}>
                <div class="float">
                    <div class="float-left">
                        <span>
                            <b>
                                {(props?.aweRightSidebarType?.status && props?.aweRightSidebarType?.status == '1') ?
                                    'MULTI CODER CHECKLIST ADD'
                                    :
                                    'MULTI CODER CHECKLIST EDIT'
                                }
                            </b>
                        </span>
                    </div>
                    <div class="float-right">
                        <Tooltip title="Close">
                            <CancelIcon style={{ color: "#1A9698", cursor: "pointer" }} onClick={() => notificationRightDrawer(false)} />
                        </Tooltip>
                    </div>
                </div>
            </div>
            <Divider />
            <div class="awv-recored-right-sidebar-form">
                {isLoading == false ?
                    <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                        {coderRecordList && coderRecordList.length > 0 && coderRecordList.map((element, index) =>
                            <div key={index} data-title={index}>
                                <div className="col-lg-12 align-items-center mb-3 mt-3">
                                    <div className='mb-1'><strong>AWE SOURCE</strong></div>
                                    <div style={{ width: "310px" }}>{element?.description}</div>
                                </div>
                                <div className="col-lg-12 align-items-center mb-3 mt-3">
                                    <div className='mb-1'><strong>Status</strong></div>
                                    <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                        {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                        <Controller
                                            className="input-control"
                                            name={"status[" + element.checklist_id + "]"}
                                            value={element?.status}
                                            defaultValue={element?.status}
                                            control={control}
                                            render={({ field }) => (
                                                // <TextField  {...field} variant="outlined" />
                                                <Select
                                                    {...field}
                                                    labelId="module-multiple-checkbox-label"
                                                    id="module-multiple-checkbox"
                                                    // value={selectModule}
                                                    // onChange={handleChange}
                                                    label="Module"
                                                    variant="outlined"
                                                    // MenuProps={MenuProps}
                                                    menuPlacement="top"
                                                >
                                                    <MenuItem key={'Confirmed'} value={'1'} >
                                                        Confirmed
                                                    </MenuItem>
                                                    <MenuItem key={'not_confirmed'} value={'0'} >
                                                        Not Confirmed
                                                    </MenuItem>
                                                </Select>
                                            )}
                                            rules={{
                                                required: false,
                                            }}
                                        />
                                        {(element.checklist_id && errors?.status && errors?.status[element.checklist_id]?.type === "required") ? <label className="text-danger">This field is required</label> : ""}
                                    </FormControl>
                                </div>
                                <div className="col-lg-12 align-items-center mb-3 mt-3">
                                    <div className='mb-1'><strong>REMARKS</strong></div>
                                    <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                        {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                        <Controller
                                            className="input-control"
                                            name={"remark[" + element.checklist_id + "]"}
                                            value={element?.remark}
                                            defaultValue={element?.remark}
                                            control={control}
                                            render={({ field }) => (
                                                <TextField  {...field} variant="outlined" />
                                            )}
                                            rules={{
                                                required: false,
                                            }}
                                        />
                                        {(element.checklist_id && errors?.remark && errors?.remark[element.checklist_id]?.type === "required") ? <label className="text-danger">This field is required</label> : ""}
                                    </FormControl>
                                </div>
                                <hr className="mb-2 mt-1" />
                            </div>
                        )}

                        <div className="col-lg-12 align-items-center mb-3">
                            <Button type='button' variant="contained" color="grey" onClick={() => notificationRightDrawer(false)}>CANCEL</Button>
                            <Button type='submit'
                                className={'mr-2 btn-custom-primary mr-2 ml-2'}
                                variant="contained"
                                disabled={formSubmitisLoading && formSubmitisLoading == true ? true : false}
                            >
                                {(props?.aweRightSidebarType?.status && props?.aweRightSidebarType?.status == '1') ?
                                    'ADD'
                                    :
                                    'UPDATE'
                                }
                            </Button>
                        </div>
                    </form>
                    : isLoading ?
                        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                            <CircularProgress />
                        </div>
                        :
                        <></>
                }
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(AWVMemberDetailsMultiCoder);

