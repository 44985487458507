import DescriptionIcon from '@material-ui/icons/Description';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import UploadAWVForm from "../Pages/AWV/UploadAWVForm.page";
import UploadAWVWorkFlow from "../Pages/AWV/UploadAWVWorkFlow.page";
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import AWVMemberDetails from "../Pages/AWV/AWVMemberDetails";
import AWVCoderBenchmark from "../Pages/AWV/coder_workbench/AWVCoderBenchmark";
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ChartSquareIcon from '../components/icons/ChartSquareIcon';
import ChartChaseRecordPage from "../Pages/Awe/Pages/ChartChaseRecordPage";


const awvRoutes = [
  {
    name: 'Annual Wellness Exam (AWE)',
    icon: MedicalInformationIcon,
    layout: "/awe",
    permissionSlug: "awv_analytic_tab_8",
    path: '',
    module: "ACA",
    subRoutes: [
      {
        path: "/awe-analytics-summary",
        name: "AWE Analytics Summary",
        icon: QueryStatsIcon,
        component: UploadAWVWorkFlow,
        layout: "/new_awe",
        permissionSlug: "awv_member_details_8",
      },
      {
        path: "/awe-record",
        name: "AWE Record",
        icon: AssignmentTurnedInIcon,
        component: UploadAWVForm,
        layout: "/new_awe",
        permissionSlug: "awv_member_details_8",

      },

      {
        path: "/chart-chase-record",
        name: "Chart Chase Record",
        icon: ChartSquareIcon,
        component: ChartChaseRecordPage,
        layout: "/new_awe",
        permissionSlug: 'awv_member_details_8',
        roleSlug: 'awv_member_details_8'
      },

      {
        path: "/AWEMemberDetails",
        name: "AWE Universal Report",
        icon: SummarizeOutlinedIcon,
        component: AWVMemberDetails,
        layout: "/awe",
        permissionSlug: 'awv_analytic_tab_8',
        roleSlug: 'awv_analytic_tab_8'
      },
      // {
      //   path: "/awe_coder_benchmark",
      //   name: "AWE Coder Benchmark",
      //   icon: SummarizeOutlinedIcon,
      //   component: AWVCoderBenchmark,
      //   layout: "/awe",
      //   permissionSlug: 'awv_analytic_tab_8',
      //   roleSlug: 'awv_analytic_tab_8'
      // },
    ]
  },


]

export default awvRoutes;