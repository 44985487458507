/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import ConditionCampaignsACA from "../Pages/ACA/ACAconditionCampaigns.page";
import GapHccSummaryACA from "../Pages/ACA/ACAgapHccSummary.page";
import GapMemberSummaryACA from "../Pages/ACA/ACAgapMemberSummary.page";
//import ExecutiveSummary from './Pages/executiveSummary.page';
//import ReportedHccSummary from './Pages/repotedHccSummary.page';
import ReportedHccSummaryACA from '../Pages/ACA/ACAreportedHccSummary.page'
import ReportedMemberSummaryACA from '../Pages/ACA/ACAreportedMemberSummary.page';
import ACAChartReviewHccSummary from '../Pages/ACA/ACAChartReviewHCCSummary.page';
import ACAChartReviewMemberSummary from '../Pages/ACA/ACAChartReviewMemberSummary.page';
import ProviderReconSummary from '../Pages/ACA/ACAProviderReconSummary.page';
import ReconSummary from '../Pages/ACA/ACAReconSummary.page.js';
import MetricsACA from "../Pages/ACA/ACAmetrics.page";
import RarsdComparisonACA from "../Pages/ACA/ACArarsdComparison.page";
import ListRoundedIcon from '@material-ui/icons/ListRounded';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import WcOutlinedIcon from '@material-ui/icons/WcOutlined';
import PermIdentityTwoToneIcon from '@material-ui/icons/PermIdentityTwoTone';
import ACAChartReviewOverallSummary from '../Pages/ACA/ACAChartReviewOverallSummary.page';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
import BookIcon from '@material-ui/icons/Book';
import PersonIcon from '@material-ui/icons/Person';
import CameraIcon from '@material-ui/icons/Camera';
import FilterHdrIcon from '@material-ui/icons/FilterHdr';
import AssessmentSharpIcon from '@material-ui/icons/AssessmentSharp';
import GrainIcon from '@material-ui/icons/Grain';
import FingerprintSharpIcon from '@material-ui/icons/FingerprintSharp';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import ACACRProviderSummary from '../Pages/ACA/ACAChartReviewProviders.page';
import PCPMemberDetailsACA from '../Pages/ACA/ACAPCP1.page';
import PGPDetailsMedicare from '../Pages/MedicarePage/MedicarePGP.page';
import PGPDetailsACA from '../Pages/ACA/ACAPPGSummary.page';
import PCPDetailsACA from '../Pages/ACA/ACAPCP.page';

//import Icon from '@material-ui/icons/Report';
import ReportGeneration from '../Pages/Reports/ReportGeneration.page';
import HeatChartDemo from '../Pages/Reports/HeatChartDemo';
import TreeMapDemo from '../Pages/Reports/TreeMapDemo';
import TimelineIcon from '@material-ui/icons/Timeline';
import PieChartIcon from '@material-ui/icons/PieChart';
import DescriptionIcon from '@material-ui/icons/Description';

import ACAChartReviewOverallSummaryPage from "../Pages/ACA/ACAChartReviewOverallSummary.page";
import ACAChartReviewMemberSummaryPage from "../Pages/ACA/ACAChartReviewMemberSummary.page";

import MaoChartReviewOverallSummaryPage from "../Pages/MedicarePage/MaoChartReviewOverallSummary.page";
import MaoChartReviewMemberSummaryPage from "../Pages/MedicarePage/MaoChartReviewMemberSummary.page";
import MaoChartReviewProviderSummaryPage from "../Pages/MedicarePage/MaoChartReviewProviderSummary.page";
import MaoReportedMemberSummaryPage from "../Pages/MedicarePage/MaoReportedMemberSummary.page";
import MaoEncountersSummaryPage from "../Pages/MedicarePage/MaoEncountersSummary.page";
import MaoEncounterComparisonPage from "../Pages/MedicarePage/MaoEncounterComparison.page";
import MaoChartReviewPage from "../Pages/MedicarePage/MaoChartReview.page";
import MaoGapMemberSummaryPage from "../Pages/MedicarePage/MaoGapMemberSummary.page";
import MaoGapHccSummaryPage from "../Pages/MedicarePage/MaoGapHccSummary.page";
import MaoGapsByPCPPage from "../Pages/MedicarePage/MaoGapsByPCP.page";
import MaoProviderRetrospectiveGapsPage from "../Pages/MedicarePage/MaoProviderRetrospectiveGaps.page";
import MaoReportedHccSummaryPage from "../Pages/MedicarePage/MaoReportedHccSummary.page";
import MaoChartReviewHccSummaryPage from "../Pages/MedicarePage/MaoChartReviewHccSummary.page";
import MaoMemberSummaryPage from "../Pages/MedicarePage/MaoMemberSummary.page";
import MaoForecastSummaryPage from "../Pages/MedicarePage/MaoForecastSummary.page";
import PCPDetailsMedicare from '../Pages/MedicarePage/MedicarePCP.page';
import MemberUtilizationPage from "../Pages/Utilization/MemberUtilization.page";
import ServiceStatisticsPage from "../Pages/Utilization/ServiceStatistics.page";
import HighNeedUtilizationPage from "../Pages/Utilization/HighNeedUtilization.page";
import SUServiceUtilization from "../Pages/Utilization/SUServiceUtilizationSummary";
import ProviderUtilization from "../Pages/Utilization/ProviderUtilisation";
import AWSS3Download from "../Pages/Reports/AWSS3Download";
import RangeSliderDemo from "../Pages/Reports/RangeSliderDemo";

import HealthcareInsightsPage from "../Pages/KPI/healthcare_insights.page";
import ProviderGroupPage from "../Pages/KPI/provider_group.page";
import ProviderPerformancePage from "../Pages/KPI/provider_performance.page";
import HealthcareDashboardPage from "../Pages/KPI/healthcareDashboard.page";
import ConditionalPredicition from "../Pages/MedicarePage/ConditionalPrediction.page";
import RiskStratification from "../Pages/MedicarePage/RiskStratification.page";
import QSDashboardMedicareMemberDetails from "../Pages/MedicarePage/QSDashboardMedicareMemberDetails";
import QSDashboardMedicarePpgMetrics from "../Pages/MedicarePage/QSDashboardMedicarePpgMetrics";




const dashboardRoutes = [
  {
    name: "ACA Insights",
    icon: TimelineIcon,
    layout: "/layout",
    permissionSlug: 'aca_insights_1',
    path: '',
    module: "ACA",
    subRoutes: [
      {
        path: "/acaReportedMemberSummary",
        name: "Reported Member Summary",
        icon: PersonIcon,
        component: ReportedMemberSummaryACA,
        layout: "/layout",
        permissionSlug: 'reported_member_summary_1'
      },
      {
        name: "Reported HCC Summary",
        icon: AssessmentSharpIcon,
        path: '/acaReportedHccSummary',
        component: ReportedHccSummaryACA,
        layout: "/layout",
        permissionSlug: "reported_hcc_summary_1"
      },
      {
        path: "/acaMetrics",
        name: "ACA Metrics",
        icon: FingerprintSharpIcon,
        component: MetricsACA,
        layout: "/layout",
        permissionSlug: "aca_metrics_1"
      },
      {
        path: "/healthcare/dashboard",
        name: "HealthCare Insights",
        icon: MonetizationOnOutlinedIcon,
        component: HealthcareDashboardPage,
        layout: "/layout",
        permissionSlug: "healthcare_insights_1"
      },
    ]
  },
  {
    name: "ACA Gap Analysis",
    icon: TimelineIcon,
    layout: "/layout",
    permissionSlug: "aca_gap_analysis_1",
    path: '',
    module: "ACA",
    subRoutes: [
      {
        path: "/acaGapMemberSummary",
        name: "Gap Member Summary",
        icon: MonetizationOnOutlinedIcon,
        component: GapMemberSummaryACA,
        layout: "/layout",
        permissionSlug: "gap_member_summary_1",
      },
      {
        path: "/acaGapHccSummary",
        name: "Gap Hcc Summary",
        icon: ListRoundedIcon,
        component: GapHccSummaryACA,
        layout: "/layout",
        permissionSlug: "gap_hcc_summary_1",
      },
      {
        path: "/acaRarsdAnalysis",
        name: "RARSD Comparison",
        icon: CameraIcon,
        component: RarsdComparisonACA,
        layout: "/layout",
        permissionSlug: "rarsd_comparison_1",
      },

    ]
  },
  {
    name: "PROVIDER SUMMARY",
    icon: ListRoundedIcon,
    layout: "/layout",
    permissionSlug: "provider_summary_1",
    path: '',
    module: "ACA",
    subRoutes: [{
      path: "/pgpDetails",
      name: "PPG Summary",
      icon: MonetizationOnOutlinedIcon,
      component: PGPDetailsACA,
      layout: "/layout",
      permissionSlug: "ppg_summary_1",
    },
    {
      path: "/pcpDetails",
      name: "PCP Level Summary",
      icon: MonetizationOnOutlinedIcon,
      component: PCPDetailsACA,
      layout: "/layout",
      permissionSlug: "pcp_level_summary_1",
    }
    ]
  },
  {
    name: "ChartReview Data Analytics",
    icon: PieChartIcon,
    layout: "/layout",
    permissionSlug: "chartreview_data_analytics_1",
    path: '',
    module: "ACA",
    subRoutes: [
      {
        path: "/overall_summary",
        name: " Overall  Summary",
        icon: FilterHdrIcon,
        component: ACAChartReviewOverallSummaryPage,
        layout: "/layout",
        permissionSlug: "overall_summary_1",
      },
      {
        path: "/member_summary",
        name: "Member Summary",
        icon: WcOutlinedIcon,
        component: ACAChartReviewMemberSummaryPage,
        layout: "/layout",
        permissionSlug: "member_summary_1",
      },
      {
        path: "/acaCRHCCSummary",
        name: "HCC Summary",
        icon: HorizontalSplitIcon,
        component: ACAChartReviewHccSummary,
        layout: "/layout",
        permissionSlug: "hcc_summary_1",
      },
      {
        path: "/acaProviders",
        name: "HCC Coding Opportunities",
        icon: HorizontalSplitIcon,
        component: ACACRProviderSummary,
        layout: "/layout",
        permissionSlug: "hcc_coding_opportunities_1",
      },
      {
        path: "/acaReconciliationSummary",
        name: "Member Reconciliation",
        icon: AccountBalanceIcon,
        component: ReconSummary,
        layout: "/layout",
        permissionSlug: "member_reconciliation_1",
      },
      {
        path: "/acaProviderSummary",
        name: " Provider Reconciliation",
        icon: GrainIcon,
        component: ProviderReconSummary,
        layout: "/layout",
        permissionSlug: "provider_reconciliation_1",
      },
    ]
  },
  {
    name: 'REPORTS',
    icon: DescriptionIcon,
    layout: "/layout",
    permissionSlug: "reports_1",
    path: '',
    module: "ACA",
    subRoutes: [
      {
        path: "/reportgeneration",
        name: "Report Generation",
        icon: MonetizationOnOutlinedIcon,
        component: ReportGeneration,
        layout: "/layout",
        permissionSlug: "report_generation_1",
      },
    ]
  },
  {
    name: 'MEDICARE INSIGHTS ',
    icon: DescriptionIcon,
    layout: "/layout",
    permissionSlug: "medicare_insights_2",
    path: '',
    module: "Medicare",
    subRoutes: [
      {
        path: "/medicare/qs_member_details",
        name: "Member Details",
        icon: WcOutlinedIcon,
        component: QSDashboardMedicareMemberDetails,
        layout: "/layout",
        permissionSlug: "reported_member_summary_2",
      },
      {
        path: "/medicare/qs_ppg_metrics",
        name: "PPG Metrics",
        icon: WcOutlinedIcon,
        component: QSDashboardMedicarePpgMetrics,
        layout: "/layout",
        permissionSlug: "reported_member_summary_2",
      },
      {
        path: "/medicare/reported_member_summary",
        name: "Reported Member Summary",
        icon: MonetizationOnOutlinedIcon,
        component: MaoReportedMemberSummaryPage,
        layout: "/layout",
        permissionSlug: "reported_member_summary_2",
      },
      {
        path: "/medicare/reported_hcc_summary",
        name: "Reported HCC Summary",
        icon: MonetizationOnOutlinedIcon,
        component: MaoReportedHccSummaryPage,
        layout: "/layout",
        permissionSlug: "reported_hcc_summary_2",
      },
      /* {
        path: "/medicare/forecast_summary",
        name: "Forecasting",
        icon: WcOutlinedIcon,
        component: MaoForecastSummaryPage,
        layout: "/layout"
      }, */
    ]
  },
  {
    name: 'Medicare Gap Analysis ',
    icon: DescriptionIcon,
    layout: "/layout",
    permissionSlug: "medicare_gap_analysis_2",
    path: '',
    module: "Medicare",
    subRoutes: [
      {
        path: "/medicare/gap_member_summary",
        name: "Gap Member Summary",
        icon: MonetizationOnOutlinedIcon,
        component: MaoGapMemberSummaryPage,
        layout: "/layout",
        permissionSlug: "gap_member_summary_2",
      },
      {
        path: "/medicare/gap_hcc_summary",
        name: "Gap HCC Summary",
        icon: MonetizationOnOutlinedIcon,
        component: MaoGapHccSummaryPage,
        layout: "/layout",
        permissionSlug: "gap_hcc_summary_2",
      },
      {
        path: "/medicare/gap_by_pcp",
        name: "Gaps by PCP",
        icon: MonetizationOnOutlinedIcon,
        component: MaoGapsByPCPPage,
        layout: "/layout",
        permissionSlug: "gaps_by_pcp_2",
      },
      {
        path: "/medicare/provider_retrospective_gaps",
        name: "Provider Retrospective Summary",
        icon: MonetizationOnOutlinedIcon,
        component: MaoProviderRetrospectiveGapsPage,
        layout: "/layout",
        permissionSlug: "provider_retrospective_summary_2",
      },
    ],
  },
  {
    name: 'MAO 04 Reconciliation ',
    icon: DescriptionIcon,
    layout: "/layout",
    permissionSlug: "mao_04_reconciliation_2",
    path: '',
    module: "Medicare",
    subRoutes: [
      {
        path: "/medicare/member_summary",
        name: "Member Summary",
        icon: MonetizationOnOutlinedIcon,
        component: MaoMemberSummaryPage,
        layout: "/layout",
        permissionSlug: "member_summary_2",
      },
      {
        path: "/medicare/encounters_summary",
        name: "Encounters Summary",
        icon: MonetizationOnOutlinedIcon,
        component: MaoEncountersSummaryPage,
        layout: "/layout",
        permissionSlug: "encounters_summary_2",
      },
      {
        path: "/medicare/encounter_comparison",
        name: "MAO Comparison Summary",
        icon: MonetizationOnOutlinedIcon,
        component: MaoEncounterComparisonPage,
        layout: "/layout",
        permissionSlug: "mao_comparison_summary_2",
      },
      {
        path: "/medicare/chart_review",
        name: "Chart review summary",
        icon: MonetizationOnOutlinedIcon,
        component: MaoChartReviewPage,
        layout: "/layout",
        permissionSlug: "chart_review_summary_2",
      },
    ]
  },
  {
    name: "ChartReview Data Analytics ",
    icon: PieChartIcon,
    layout: "/layout",
    permissionSlug: "chartreview_data_analytics_2",
    path: '',
    module: "Medicare",
    subRoutes: [
      {
        path: "/medicare/overall_summary",
        name: "Overall  Summary",
        icon: FilterHdrIcon,
        component: MaoChartReviewOverallSummaryPage,
        layout: "/layout",
        permissionSlug: "overall_summary_2",
      },
      {
        path: "/medicare/review_member_summary",
        name: "Member Summary",
        icon: WcOutlinedIcon,
        component: MaoChartReviewMemberSummaryPage,
        layout: "/layout",
        permissionSlug: "member_summary_chart_2",
      },
      {
        path: "/medicare/review_hcc_summary",
        name: "HCC Summary",
        icon: WcOutlinedIcon,
        component: MaoChartReviewHccSummaryPage,
        layout: "/layout",
        permissionSlug: "hcc_summary_2",
      },
      {
        path: "/medicare/provider_summary",
        name: "Provider Summary",
        icon: WcOutlinedIcon,
        component: MaoChartReviewProviderSummaryPage,
        layout: "/layout",
        permissionSlug: "provider_summary_2",
      },
    ]
  },
  {
    name: "Utilization ",
    icon: DescriptionIcon,
    layout: "/layout",
    permissionSlug: "utilization_2",
    path: '',
    module: "Medicare",
    subRoutes: [
      {
        path: "/medicare/member_utilization",
        name: "Member Utilization",
        icon: FilterHdrIcon,
        component: MemberUtilizationPage,
        layout: "/layout",
        permissionSlug: "member_utilization_2",
      },
      {
        path: "/medicare/service_statistics",
        name: "Service Statistics",
        icon: FilterHdrIcon,
        component: ServiceStatisticsPage,
        layout: "/layout",
        permissionSlug: "service_statistics_2",
      },
      {
        path: "/medicare/high_need_utilization",
        name: "High Need Member Utilization",
        icon: FilterHdrIcon,
        component: HighNeedUtilizationPage,
        layout: "/layout",
        permissionSlug: "high_need_member_utilization_2",
      },
      {
        path: "/medicare/SUServiceUtilization",
        name: "Service Utilization",
        icon: MonetizationOnOutlinedIcon,
        component: SUServiceUtilization,
        layout: "/layout",
        permissionSlug: "service_utilization_2",
      },
      {
        path: "/medicare/ProviderUtilization",
        name: "Provider Utilization",
        icon: MonetizationOnOutlinedIcon,
        component: ProviderUtilization,
        layout: "/layout",
        permissionSlug: "provider_utilization_2",
      },
    ]
  },
  {
    name: "Prediction",
    icon: DescriptionIcon,
    layout: "/layout",
    permissionSlug: "prediction_2",
    path: '',
    module: "Medicare",
    subRoutes: [
      {
        path: "/medicare/ConditionPredictor",
        name: "Condition Predictor",
        icon: FilterHdrIcon,
        component: ConditionalPredicition,
        layout: "/layout",
        permissionSlug: "condition_predictor_2",
      }, {
        path: "/medicare/RiskStratification",
        name: "Risk Stratification Potential",
        icon: FilterHdrIcon,
        component: RiskStratification,
        layout: "/layout",
        permissionSlug: "risk_stratification_potential_2",
      },


    ]
  },
  {
    name: "PROVIDER SUMMARY",
    icon: ListRoundedIcon,
    layout: "/layout",
    permissionSlug: "prediction_2",
    path: '',
    module: "Medicare",
    subRoutes: [
      {
        path: "/medicare/pgpDetails",
        name: "PPG Summary",
        icon: MonetizationOnOutlinedIcon,
        component: PGPDetailsMedicare,
        layout: "/layout",
        permissionSlug: "condition_predictor_2",
      },
      {
        path: "/medicare/pcpDetails",
        name: "PCP Level Summary",
        icon: MonetizationOnOutlinedIcon,
        component: PCPDetailsMedicare,
        layout: "/layout",
        permissionSlug: "pcp_level_summary_1",
      }
    ]
  },
  // {
  //   name: 'CHART-DEMO',
  //   icon: PieChartIcon,
  //   layout: "/layout",
  //   permissionSlug:"",
  //   path:'',
  //   module:"ACA",
  //   subRoutes: [ 
  //       {
  //         path: "/download/aws3_export",
  //         name: 'AWS S3 Download',
  //         icon: PieChartIcon,
  //         component: AWSS3Download,
  //         layout: "/layout",
  //         permissionSlug:"", 
  //       },
  //     {
  //         path: "/heatchartdemo",
  //         name: "Heat Map",
  //         icon: HorizontalSplitIcon,
  //         component: HeatChartDemo,
  //         layout: "/layout",
  //         permissionSlug:"", 
  //     },
  //     {
  //       path: "/treemapdemo",
  //       name: "Word Tree",
  //       icon: HorizontalSplitIcon,
  //       component: TreeMapDemo,
  //       layout: "/layout",
  //       permissionSlug:"", 
  //   }
  //   ]
  // },
  // {
  //   name: 'AWS S3 Download',
  //   icon: PieChartIcon,
  //   path:'test',
  //   component: AWSS3Download,
  // }
]

export default dashboardRoutes;