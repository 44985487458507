import React, { useEffect, useState, useRef } from 'react'
// import IconButton from '../CustomButtons/IconButton';
import SortBothIcon from '../icons/SortBothIcon';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '../icons/SearchIcon'
import PlusFillIcon from '../icons/PlusFillIcon'
import DownloadSquareIcon from '../icons/DownloadSquareIcon'
import AWVApi from '../../assets/constants/AWVRafservice.Instance';
import { Popover, Select, MenuItem, LinearProgress } from '@mui/material'
import FilterIcon from '../icons/FilterIcon'
import ColumnLinesIcon from '../icons/ColumnLinesIcon'
import SmallArrowLeftIcon from '../icons/SmallArrowLeftIcon';
import SmallArrowRightIcon from '../icons/SmallArrowRightIcon';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import EditPenIcon from '../icons/EditPenIcon';
import EyeIcon from '../icons/EyeIcon';
import PinIcon from '../icons/PinIcon';
import ListBoxIcon from '../icons/ListBoxIcon';
import PlusFileIcon from '../icons/PlusFileIcon';
import BigArrowRightIcon from '../icons/BigArrowRightIcon';
import Button from '../layout/aweLayout/Button';
import IconButton from '../layout/aweLayout/IconButton';
import FlagIcon from '../icons/FlagIcon';
import CustomAweExpandSubTable from './subComponents/CustomAweExpandSubTable';
import { connect } from 'react-redux';
import { currentRoute, currentAWERightSidebarCloseDatatableReload } from '../../store/actions';
// import IconButton from '../CustomButtons/IconButton';
import { toast } from 'react-toastify';
import Moment from 'moment';

const CustomAweServerSideDatatable = (props) => {
    const arrowRef = useRef()
    const {
        titleName = '', downloadFileName = 'download', statusOptions = [],
        columns, urlLink, searchBox = false, showColumnBtn = false, subColumns = [],
        downloadBtn = false, filterBox = false, addNewEvent = '',
        colSpan = 2, pagination = false, expandedRows = []
    } = props;
    const [dataSource, setDataSource] = useState([]);
    const [subDataSource, setSubDataSource] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubLoading, setIsSubLoading] = useState(false);
    const [filterShow, setFilterShow] = useState(null);
    const [columnShow, setColumnShow] = useState(null);
    const [customFilterAttribute, setCustomFilterAttribute] = useState({});
    const [customDateRangeFilterAttribute, setCustomDateRangeFilterAttribute] = useState({});
    const [tempDateRangeFilterAttribute, settempDateRangeFilterAttribute] = useState({});
    const [customFilterApply, setCustomFilterApply] = useState(false);
    const [searchFilter, setSearchFilter] = useState('');
    const [sortColumnName, setSortColumnName] = useState('cin');
    const [sortName, setSortName] = useState('asc');
    const [columnsToFilter, setColumnsToFilter] = useState([]);
    const [columnsToShows, setColumnsToShows] = useState([]);
    const [subColumnData, setSubColumnData] = useState([]);
    const open = Boolean(filterShow);
    const openColumn = Boolean(columnShow);
    const id = open ? 'simple-popover' : undefined;
    const idColumn = openColumn ? 'simple-popover' : undefined;
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [maxPages, setMaxPages] = useState();
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;

    useEffect(() => {
        if (props.aweRightSidebarCloseDatatableReload && props.aweRightSidebarCloseDatatableReload == true) {
            loadDatatable();
            props.currentAWERightSidebarCloseDatatableReload(false);
        }
    }, [props.aweRightSidebarCloseDatatableReload]);

    useEffect(() => {
        if (props?.yearSelect && props?.lobSelect && props?.yearSelect != '' && props?.lobSelect != '') {
            loadDatatable();
        }

        var filterColumn = [];
        var showColumns = [];
        columns && columns.map((elementColumn, indexColumn) => {
            if (elementColumn?.options?.search == true) {
                filterColumn.push(elementColumn.name);
            }
            if (elementColumn?.options?.showColumn == true) {
                showColumns.push(elementColumn.label);
            }
        });
        setColumnsToFilter(filterColumn);
        setColumnsToShows(showColumns);
    }, []);

    useEffect(() => {
        if (props?.yearSelect && props?.lobSelect && props?.yearSelect != '' && props?.lobSelect != '') {
            loadDatatable();
        }
    }, [
        props?.formAssignedFlag,
        props?.formAttachedFlag,
        props?.zipSelectValue,
        props?.siteIdSelectValue,
        props?.providerAddressGroupsNameSelectValue,
        props?.citySelectValue,
        props?.siteAddressSelectValue,
        props?.yearSelect,
        props?.providerGroupSelect,
        props?.lobSelect,
    ]);

    useEffect(() => {
        loadDatatable();
    }, [sortColumnName, sortName, searchFilter, currentPage, pageSize]);


    const handleNextPage = () => {
        if (currentPage < maxPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleSort = (key) => {
        if (sortColumnName === key && sortName === 'asc') {
            setSortColumnName(key);
            setSortName('desc');
        } else {
            setSortColumnName(key);
            setSortName('asc');
        }
    };

    const handleFilterDateRangChange = (event, filterName) => {
        const name = event?.target?.name;
        const value = event?.target?.value;

        var updatedRangeFilter = { ...tempDateRangeFilterAttribute };
        if (value && value != '') {
            updatedRangeFilter[name] = value.toString();
        } else {
            delete updatedRangeFilter[name];
        }
        settempDateRangeFilterAttribute(updatedRangeFilter);
        if (updatedRangeFilter?.[filterName + '_start_date'] && updatedRangeFilter?.[filterName + '_end_date'] && updatedRangeFilter?.[filterName + '_start_date'] != '' && updatedRangeFilter?.[filterName + '_end_date'] != '') {
            const name = filterName;
            const value = filterName + ' BETWEEN "' + Moment(updatedRangeFilter?.[filterName + '_start_date']).format("YYYY-MM-DD") + '" AND "' + Moment(updatedRangeFilter?.[filterName + '_end_date']).format("YYYY-MM-DD") + '"';
            var updatedFilter = { ...customDateRangeFilterAttribute };
            if (value && value != '') {
                updatedFilter[filterName] = value.toString();
            } else {
                delete updatedFilter[name];
            }
           
        } else if( updatedRangeFilter?.[filterName + '_start_date'] && updatedRangeFilter?.[filterName + '_start_date'] !='') {
            const name = filterName;
            const value = filterName + ' >= "' + Moment(updatedRangeFilter?.[filterName + '_start_date']).format("YYYY-MM-DD") + '"';
            var updatedFilter = { ...customDateRangeFilterAttribute };
            if (value && value != '') {
                updatedFilter[filterName] = value.toString();
            } else {
                delete updatedFilter[name];
            }
        } else if( updatedRangeFilter?.[filterName + '_end_date'] && updatedRangeFilter?.[filterName + '_end_date'] !='') {
            const name = filterName;
            const value = filterName + ' <= "' + Moment(updatedRangeFilter?.[filterName + '_end_date']).format("YYYY-MM-DD") + '"';
            var updatedFilter = { ...customDateRangeFilterAttribute };
            if (value && value != '') {
                updatedFilter[filterName] = value.toString();
            } else {
                delete updatedFilter[name];
            }
        }
        setCustomDateRangeFilterAttribute(updatedFilter);
    };

    const handleFilterChange = (event) => {
        const name = event?.target?.name;
        const value = event?.target?.value;
        var updatedFilter = { ...customFilterAttribute };
        if (value && value != '') {
            updatedFilter[name] = value.toString();
        } else {
            delete updatedFilter[name];
        }
        setCustomFilterAttribute(updatedFilter);
        setCurrentPage(1)
    };


    const customFilterSubmit = () => {
        loadDatatable();
        setFilterShow(null);
    };

    const customFilterReset = () => {
        var updatedFilter = { ...customFilterAttribute };
        for (const key in updatedFilter) {
            updatedFilter[key] = '';
        }
        setCustomDateRangeFilterAttribute({});
        settempDateRangeFilterAttribute({});
        setCustomFilterAttribute(updatedFilter);
        setCustomFilterApply(true);
    };

    const customColumnShowReset = () => {
        var showColumns = [];
        columns && columns.map((elementColumn, indexColumn) => {
            if (elementColumn?.options?.showColumn == true) {
                showColumns.push(elementColumn.label);
            }
        });
        setColumnsToShows(showColumns);
    };

    useEffect(() => {
        if (customFilterApply == true) {
            loadDatatable();
            setCustomFilterApply(false);
            setFilterShow(null);
        }
    }, [customFilterAttribute]);


    const handleClose = () => {
        setFilterShow(null);
    };

    const handleClick = (event) => {
        setFilterShow(event.currentTarget);
    };

    const handleColumnClose = () => {
        setColumnShow(null);
    };

    const handleColumnClick = (event) => {
        setColumnShow(event.currentTarget);
    };

    const handleChangeShowColumn = (event) => {
        setColumnsToShows((prevColumnsToShows) => {
            const updatedColumns = [...prevColumnsToShows];
            if (event.target.checked) {
                updatedColumns.push(event.target.value);
            } else {
                const index = updatedColumns.indexOf(event.target.value);
                if (index !== -1) {
                    updatedColumns.splice(index, 1);
                }
            }
            return updatedColumns;
        });
    };

    const handlePageSizeChange = (e) => {
        const newPageSize = parseInt(e.target.value);
        setPageSize(newPageSize);
        setCurrentPage(1);
    };

    const loadDatatable = () => {
        const keyValueArray = Object.entries(customFilterAttribute);
        const keyValueDateRangeArray = Object.entries(customDateRangeFilterAttribute);
        var ColumnSearchFilter = keyValueArray && keyValueArray.filter(([key, value]) => value != '').map(([key, value]) => `${key}="${value}"`).join(', ');
        var ColumnSearchFilterDateRange = keyValueDateRangeArray && keyValueDateRangeArray.filter(([key, value]) => value != '').map(([key, value]) => `${value}`).join(', ');
        if (ColumnSearchFilter && ColumnSearchFilter != '' && ColumnSearchFilterDateRange && ColumnSearchFilterDateRange != '') {
            ColumnSearchFilter = ColumnSearchFilter + ', ' + ColumnSearchFilterDateRange;
        } else if (ColumnSearchFilterDateRange && ColumnSearchFilterDateRange != '') {
            ColumnSearchFilter = ColumnSearchFilterDateRange;
        }
        ColumnSearchFilter = ColumnSearchFilter.replace("PendingUpload", "1");
        ColumnSearchFilter = ColumnSearchFilter.replace("pendingforCoordinator", "2");
        ColumnSearchFilter = ColumnSearchFilter.replace("pendingforCoder", "3");
        ColumnSearchFilter = ColumnSearchFilter.replace("pendingForPaymentApproval", "4");
        ColumnSearchFilter = ColumnSearchFilter.replace("ApprovedForPayment", "5");
        ColumnSearchFilter = ColumnSearchFilter.replace("PaymentDone", "6");
        ColumnSearchFilter = ColumnSearchFilter.replace("void", "7");
        ColumnSearchFilter = ColumnSearchFilter.replace("Reject", "8");
        ColumnSearchFilter = ColumnSearchFilter.replace("ApprovedNoPayment", "9");
        ColumnSearchFilter = ColumnSearchFilter.replaceAll("Yes", "Y");
        ColumnSearchFilter = ColumnSearchFilter.replaceAll("No", "N");
        setIsLoading(true);
        // console.log('loadDatatable', urlLink + `iSortCol_0=${sortColumnName}&sSortDir_0=${sortName}&iDisplayStart=${startIndex}&iDisplayLength=${pageSize}&ColumnSearch=${ColumnSearchFilter}`);
        AWVApi.get(urlLink + `iSortCol_0=${sortColumnName}&sSortDir_0=${sortName}&iDisplayStart=${startIndex}&iDisplayLength=${pageSize}&ColumnSearch=${btoa(ColumnSearchFilter)}`)
            .then(res => {
                props.expandedRowsReset();
                if (props.yearSelect != '' && props.lobSelect != '' && res.data?.errorType && res.data?.errorType != '') {
                    setDataSource([]);
                    setMaxPages(0);
                    toast.error(res.data?.errorMessage);
                } else {
                    if (res.data && res.data[0] && res.data[0].iTotalRecords) {
                        setMaxPages(Math.ceil(res.data[0]?.iTotalRecords / pageSize));
                    }
                    if (props.cinColumnName && res.data && res.data[0] && res.data[0]?.[props.cinColumnName] && res.data[0]?.[props.cinColumnName] != '') {
                        setDataSource(res.data);
                    } else {
                        setDataSource([]);
                        setMaxPages(0);
                    }
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setDataSource([]);
                setMaxPages(0);
                setIsLoading(false);
            })
    }

    const exportData = () => {
        const csvContent = 'data:text/csv;charset=utf-8,' + convertToCsv(dataSource);
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', downloadFileName + '.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const convertToCsv = (data) => {
        const headers = Object.keys(data[0]);
        const csvRows = [];
        csvRows.push(headers.join(','));

        for (const row of data) {
            const values = headers.map((header) => {
                return `"${row[header]}"`;
            });
            csvRows.push(values.join(','));
        }

        return csvRows.join('\n');
    };

    return (
        <>
            <div className="card_header flex_item_cb mb20 flex-wrap">
                <h2 className="fs18 card-title">{titleName}</h2>
                <div className="flex_item_ce flex-wrap">
                    {!statusOptions && statusOptions.length > 0 &&
                        <ul className="flex_item_ce gap10">
                            {statusOptions && statusOptions.map((statusOption, index) => (
                                <li key={index}>
                                    <Tooltip title={statusOption.text} arrow placement="top">
                                        <div>
                                            <span className={`badge ${statusOption.colorClass}`}>
                                                {statusOption.count}
                                            </span>
                                        </div>
                                    </Tooltip>
                                </li>
                            ))}
                        </ul>
                    }

                    {!searchBox && searchBox == true &&
                        <div className="search_box minw300">
                            <div className="form-group mb-0">
                                <div className="form-control gap10 flex_item_cs">
                                    <IconButton className={"lh-1"} buttonType={"default"}>
                                        <SearchIcon className="w16" />
                                    </IconButton>
                                    <input type="search" name="search" className="" placeholder="Search" onChange={(e) => setSearchFilter(e.target.value)} />
                                </div>
                            </div>
                        </div>
                    }

                    <ul className="flex_item_ce flex-wrap">
                        {(props.formWithoutAttachment || props.formWithoutAttachment == '0') &&
                            <li className="fs14 fw700 flex_item_cc flex-shrink-0 gap8">
                                Forms Without Attachments <span className={`status_badge badge_danger`}>{props.formWithoutAttachment}</span>
                            </li>
                        }
                        {(props.userAssignedCount || props.userAssignedCount == '0') &&
                            <li className="flex-shrink-0">
                                <FormControlLabel className="custom_checkbox m-0 gap8"
                                    control={<Checkbox className="p-0" id='checkbox2' onChange={(e) => props.handleChangeFormAssigned(e)} />}
                                    label={<span className="fs14 fw700 flex_item_cc gap8">
                                        Assigned to me
                                        <Tooltip title={""} arrow placement="top">
                                            <div>
                                                <span className={`status_badge badge_danger`}>
                                                    {props.userAssignedCount}
                                                </span>
                                            </div>
                                        </Tooltip>
                                    </span>} />
                            </li>
                        }
                        {props.handlechange &&
                            <li className="flex-shrink-0">
                                <FormControlLabel className="custom_checkbox m-0 gap8"
                                    control={<Checkbox className="p-0" id='checkbox3' onChange={(e) => props.handlechange(e)} />}
                                    label={<span className="fs14 fw700 flex_item_cc gap8">
                                        Forms Attached
                                    </span>
                                    } />
                            </li>
                        }
                    </ul>

                    <ul className="flex_item_ce gap10">
                        {downloadBtn && downloadBtn == true &&
                            <li>
                                <Tooltip title={'Download CSV'} arrow placement="top">
                                    <div>
                                        <IconButton className={"link_primary"} buttonType={"default"} onClick={() => exportData()}>
                                            <DownloadSquareIcon className="w24" />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </li>
                        }
                        {props?.downloadCustomBtn && props?.downloadCustomBtnName && props?.downloadCustomBtn != '' &&
                            <li>
                                <Tooltip title={props?.downloadCustomBtnName} arrow placement="top">
                                    <div>
                                        <IconButton className={"link_primary"} buttonType={"default"} onClick={() => exportData()}>
                                            <DownloadSquareIcon className="w24" >
                                                {props?.downloadCustomBtn}
                                            </DownloadSquareIcon>
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </li>
                        }
                        {showColumnBtn && showColumnBtn == true &&
                            <li className="filter_dropdown">
                                <Tooltip title={'Show Columns'} arrow placement="top">
                                    <div>
                                        <IconButton aria-describedby={idColumn} onClick={handleColumnClick} type="button" className={"link_primary"} buttonType={"default"}>
                                            <ColumnLinesIcon className="w24" />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                                <Popover
                                    id={idColumn}
                                    open={openColumn}
                                    anchorEl={columnShow}
                                    onClose={handleColumnClose}
                                    placement="top-end"
                                    className="filter_dropdown_menu"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    modifiers={[
                                        {
                                            name: 'flip',
                                            enabled: true,
                                            options: {
                                                altBoundary: true,
                                                rootBoundary: 'document',
                                                padding: 8,
                                            },
                                        },
                                        {
                                            name: 'preventOverflow',
                                            enabled: true,
                                            options: {
                                                altAxis: true,
                                                altBoundary: true,
                                                tether: true,
                                                rootBoundary: 'document',
                                                padding: 8,
                                            },
                                        },
                                        {
                                            name: 'arrow',
                                            enabled: true,
                                            options: {
                                                element: arrowRef,
                                            },
                                        },
                                    ]}
                                >
                                    <div className="flex_item_cb mb-3">
                                        <h2 className="fs14">Show Columns</h2>
                                        <Button type="button" onClick={() => customColumnShowReset()} className="text-grey-600 fs14" buttonType={"default"}>Reset</Button>
                                    </div>
                                    {columns && columns.map((elementColumn, indexColumn) => {
                                        return (
                                            <div key={indexColumn} >
                                                <FormControlLabel
                                                    className="custom_checkbox m-0 gap8 text-grey-900"
                                                    control={
                                                        <Checkbox
                                                            className="p-0"
                                                            type="checkbox"
                                                            value={elementColumn?.label}
                                                            checked={columnsToShows.includes(elementColumn?.label)}
                                                            name={elementColumn?.name}
                                                            onChange={handleChangeShowColumn}
                                                            text="ss"
                                                            size="small"
                                                        />
                                                    }
                                                    label={<span className="fs14 fw400 flex_item_cc gap8">{elementColumn?.label}</span>}
                                                />
                                            </div>
                                        )
                                    })
                                    }
                                </Popover>
                            </li>
                        }
                        {filterBox && filterBox == true &&
                            <li className="filter_dropdown">
                                <Tooltip title={'Filter'} arrow placement="top">
                                    <div>
                                        <IconButton aria-describedby={id} onClick={handleClick} type="button" className={"link_primary"} buttonType={"default"}>
                                            <FilterIcon className="w24" />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                                <Popover
                                    id={id}
                                    open={open}
                                    anchorEl={filterShow}
                                    onClose={handleClose}
                                    placement="top-end"
                                    className="filter_dropdown_menu"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    modifiers={[
                                        {
                                            name: 'flip',
                                            enabled: true,
                                            options: {
                                                altBoundary: true,
                                                rootBoundary: 'document',
                                                padding: 8,
                                            },
                                        },
                                        {
                                            name: 'preventOverflow',
                                            enabled: true,
                                            options: {
                                                altAxis: true,
                                                altBoundary: true,
                                                tether: true,
                                                rootBoundary: 'document',
                                                padding: 8,
                                            },
                                        },
                                        {
                                            name: 'arrow',
                                            enabled: true,
                                            options: {
                                                element: arrowRef,
                                            },
                                        },
                                    ]}
                                >
                                    <div className="flex_item_cb mb-3">
                                        <h2 className="fs14">Filter</h2>
                                        <div>
                                            <Button type="button" onClick={() => customFilterSubmit()} className="text-primary fs14 mr-2" buttonType={"default"}>Apply</Button>
                                            <Button type="button" onClick={() => customFilterReset()} className="text-primary fs14" buttonType={"default"}>Clear</Button>
                                        </div>
                                    </div>
                                    {columns && columns.filter((elementCol) => {
                                        return elementCol?.options?.filter == true;
                                    }).map((elementColumn, indexColumn) => {
                                        return (
                                            <div key={indexColumn} className='form-group'>
                                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2">
                                                    {elementColumn?.label}
                                                </label>
                                                {elementColumn?.options?.filterType && elementColumn?.options?.filterType == 'selectStatus' ?
                                                    <Select
                                                        displayEmpty
                                                        className="custom_select"
                                                        name={elementColumn?.name}
                                                        value={customFilterAttribute?.[elementColumn?.name]}
                                                        onChange={(e) => handleFilterChange(e)}
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >
                                                        <MenuItem value="">
                                                            <em>All</em>
                                                        </MenuItem>
                                                        {statusOptions && statusOptions.map((statusOption, index) => (
                                                            <MenuItem value={statusOption.value} key={index}>
                                                                {statusOption.text}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    : elementColumn?.options?.filterType && elementColumn?.options?.filterType == 'select' ?
                                                        <Select
                                                            displayEmpty
                                                            className="custom_select"
                                                            name={elementColumn?.name}
                                                            onChange={(e) => handleFilterChange(e)}
                                                            value={customFilterAttribute?.[elementColumn?.name]}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                        >
                                                            <MenuItem value="">
                                                                <em>All</em>
                                                            </MenuItem>
                                                            {elementColumn?.options?.filterOptions && elementColumn?.options?.filterOptions.map((statusOption, index) => (
                                                                <MenuItem value={statusOption} key={index}>
                                                                    {statusOption}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        : elementColumn?.options?.filterType && elementColumn?.options?.filterType == 'date-range' ?
                                                            <>
                                                                <br />
                                                                <label htmlFor="" className="text_c3A3C40 fs13 mb-1">Start Date</label>
                                                                <input
                                                                    type="date"
                                                                    value={tempDateRangeFilterAttribute?.[elementColumn?.name + '_start_date']}
                                                                    name={elementColumn?.name + '_start_date'}
                                                                    onChange={(e) => handleFilterDateRangChange(e, elementColumn?.name)}
                                                                    className="form-control mb-2"
                                                                    placeholder='Start Date'
                                                                />
                                                                <label htmlFor="" className="text_c3A3C40 fs13 mb-1">End Date</label>
                                                                <input
                                                                    type="date"
                                                                    min={Moment(tempDateRangeFilterAttribute?.[elementColumn?.name + '_start_date']).format("YYYY-MM-DD")}
                                                                    value={tempDateRangeFilterAttribute?.[elementColumn?.name + '_end_date']}
                                                                    name={elementColumn?.name + '_end_date'}
                                                                    onChange={(e) => handleFilterDateRangChange(e, elementColumn?.name)}
                                                                    className="form-control"
                                                                    placeholder='End Date'
                                                                />
                                                            </>
                                                            : elementColumn?.options?.filterType && elementColumn?.options?.filterType == 'date' ?
                                                                <input
                                                                    type="date"
                                                                    value={customFilterAttribute?.[elementColumn?.name]}
                                                                    name={elementColumn?.name}
                                                                    onChange={(e) => handleFilterChange(e)}
                                                                    className="form-control"
                                                                />
                                                                :
                                                                <input
                                                                    type="search"
                                                                    value={customFilterAttribute?.[elementColumn?.name]}
                                                                    name={elementColumn?.name}
                                                                    onChange={(e) => handleFilterChange(e)}
                                                                    className="form-control"
                                                                />
                                                }
                                            </div>
                                        )
                                    }
                                    )}
                                </Popover>
                            </li>
                        }
                        {addNewEvent && addNewEvent != '' &&
                            <li>
                                <Tooltip title={'Add'} arrow placement="top">
                                    <div>
                                        {addNewEvent}
                                    </div>
                                </Tooltip>
                            </li>
                        }
                    </ul>
                </div>
            </div>
            <div className={'mui_custom_table'} style={{ minHeight: "100px" }}>
                <div class="table-responsive custom-scrollbar">
                    <table class="table">
                        <thead>
                            <tr>
                                {columns && columns.filter((elementCol) => {
                                    return columnsToShows.includes(elementCol?.label);
                                }).map((elementColumn, indexColumn) => (
                                    <th scope="col"
                                        active={sortColumnName === elementColumn?.name}
                                        direction={sortColumnName === elementColumn?.name ? sortName : 'asc'}
                                        onClick={() => handleSort(elementColumn?.name)}
                                        className={`${elementColumn?.options?.sort && elementColumn?.options?.sort == true ? "sort_th" : "sort_th"}`} key={indexColumn} style={{ width: (elementColumn?.options && elementColumn?.options?.width) ? elementColumn?.options?.width : '100px', minWidth: (elementColumn?.options && elementColumn?.options?.width) ? elementColumn?.options?.width : '100px', maxWidth: (elementColumn?.options && elementColumn?.options?.width) ? elementColumn?.options?.width : '100px' }}>
                                        <div className="flex_item_cs gap4">
                                            {elementColumn?.label}
                                            {elementColumn?.options?.sort && elementColumn?.options?.sort == true ?
                                                <IconButton buttonType={"default"} className={"table_sort_icon lh_1"} type="button">
                                                    <SortBothIcon className="w16" />
                                                </IconButton>
                                                :
                                                <></>
                                            }
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading && isLoading == true &&
                                <tr >
                                    <td colSpan={colSpan}>
                                        <div className='text-center mt-2 mb-2'>Loading...</div>
                                    </td>
                                </tr>
                            }
                            {isLoading != true && dataSource && dataSource.length == 0 &&
                                <tr >
                                    <td colSpan={colSpan}>
                                        <div className='text-center mt-2 mb-2'>Sorry, there is no matching data to display</div>
                                    </td>
                                </tr>
                            }
                            {dataSource && dataSource.length > 0 && dataSource.map((elementRow, indexRow) => (
                                <>
                                    <tr key={indexRow}>
                                        {columns && columns.filter((elementCol) => {
                                            return columnsToShows.includes(elementCol?.label);
                                        }).map((elementColumn, indexColumn) => (
                                            <td key={indexColumn} style={{ width: (elementColumn?.options && elementColumn?.options?.width) ? elementColumn?.options?.width : '100px' }}>
                                                {elementColumn?.options?.customBodyRender && elementColumn?.options?.otherColumnName == 'all' ?
                                                    elementColumn?.options?.customBodyRender(elementRow?.[elementColumn?.name], elementRow, indexRow)
                                                    : elementColumn?.options?.customBodyRender && elementColumn?.options?.customBodyRender != '' ?
                                                        elementColumn?.options?.customBodyRender(elementRow?.[elementColumn?.name], elementRow?.[elementColumn?.options?.otherColumnName])
                                                        : elementColumn?.options?.customBodyRender ?
                                                            elementColumn?.options?.customBodyRender(elementRow?.[elementColumn?.name])
                                                            :
                                                            elementRow?.[elementColumn?.name]
                                                }
                                            </td>
                                        ))}
                                    </tr>
                                    {subColumns && subColumns.length > 0 && expandedRows && expandedRows.length > 0 ?
                                        <tr className="collapse" id={"collapseTable" + indexRow}>
                                            {expandedRows.includes(indexRow) && (
                                                <td colSpan={columnsToShows.length} className="p-0">
                                                    <CustomAweExpandSubTable
                                                        subColumns={subColumns}
                                                        columnsToShows={columnsToShows}
                                                        colSpan={colSpan}
                                                        rowData={elementRow}
                                                        cinColumnName={props.cinColumnName}
                                                        statusColumnName={props.statusColumnName}
                                                        editActionData={props?.editActionData}
                                                        awe_display_id={props?.awe_display_id}
                                                        awv_backend_id={props?.awv_backend_id}
                                                    />
                                                </td>
                                            )}
                                        </tr>
                                        :
                                        <></>
                                    }
                                </>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {pagination && pagination == true &&
                <div className="pagination-wrap flex_item_ce mt-4">
                    <div className="flex_item_ce gap4">
                        <p className="mb-0">Rows per page:</p>
                        <Select
                            value={pageSize}
                            onChange={handlePageSizeChange}
                            className="pagination_select"
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                            {[10, 20, 25, 50, 100].map((size) => (
                                <MenuItem key={size} className="text-grey-800" value={size}>
                                    {size}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className="flex_item_ce gap10">
                        <IconButton
                            onClick={handlePrevPage}
                            disabled={(currentPage && currentPage > 1) ? false : true}
                            className={"btn-white flex_item_cc p-0"}
                            style={{ width: '24px', height: "20px" }}
                        >
                            <SmallArrowLeftIcon className="w16" />
                        </IconButton>
                        <p className="mb-0 text-grey">
                            {currentPage}
                            <span className="text-grey-800">
                                /{maxPages}
                            </span>
                        </p>
                        <IconButton
                            onClick={handleNextPage}
                            disabled={(maxPages && currentPage != maxPages) ? false : true}
                            className={"btn-white flex_item_cc p-0"}
                            style={{ width: '24px', height: "20px" }}
                        >
                            <SmallArrowRightIcon className="w16" />
                        </IconButton>
                    </div>
                </div>
            }
        </>
    );

}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        lobSelect: state.moduleFilter.lobSelect,
        aweRightSidebarCloseDatatableReload: state.moduleFilter.aweRightSidebarCloseDatatableReload
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarCloseDatatableReload })(CustomAweServerSideDatatable);