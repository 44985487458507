import React, { useState, useEffect } from "react";
import Chart from "react-google-charts";

const BarGChart = (props) => {
    var { data, chartType, width, height, className } = props;
    // const stly ={'marginTop':'100px'}
    useEffect(() => {        
        
    }, [props.data]);

    return (
        <div style={{'cursor':'pointer'}}>
            {data.length > 1?
                <Chart
                    width={width}
                    height={height}
                    className={className}
                    chartType={chartType}
                    loader={<div>Loading Chart</div>}
                    data={data}
                    options={{
                        // isStacked: true,
                        bars: 'vertical',
                        legend: { position: 'top',maxLines: 99},
                        vAxis: { minValue: 0},
                        is3D: true,
                        colors: ['#5FBEFB','#9292E7','#7AD38C','#F4BC7D','#800000'],
                        hAxis: {
                            title: '',
                            minValue: 0,
                        },
                        vAxis: {
                            title: '',
                        },
                    }}
                    rootProps={{ 'data-testid': '1' }}
                />
            :
                <h6 className="text-center">No records found!..</h6>
            }
        </div>
    )
}
export default BarGChart;