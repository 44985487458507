import api from '../constants/Rafservice.Instance';
import { AcaIssuerList, AcaMetricsIssuerList } from './issuerList';
import { 
    SELECT_YEAR, SELECT_INITIAL, 
    SELECT_METAL_TIER, SELECT_VIEW_BY,
    SELECT_PLAN, SELECT_MODEL, yearLimit
} from "../../assets/constants/string.constants";

let ACA_years = [];
let ACA_year = '';
export const AcaYearList = () =>{

    api.get('/yearList')
    .then(res=>{        
        let resArray = [];
        for(var i =0; i <=yearLimit; i++) {
            resArray.push(res[i]);
        }
        SetAcaYearList(resArray);    
        if(localStorage.getItem(SELECT_YEAR) && localStorage.getItem(SELECT_YEAR) != ''){
            // localStorage.setItem('YEAR', res[0]);            
            SetAcaYear(localStorage.getItem(SELECT_YEAR));
        }else{
            localStorage.setItem(SELECT_YEAR, res[0]);            
            SetAcaYear(res[0]);
        }
    })
    .catch(err=>{
        // console.log(err);
    })
}

export const SetAcaYearList = (years) =>{
    ACA_years = years;
}

export const SetAcaYear = (year) =>{
    AcaIssuerList(year);
    AcaMetricsIssuerList(year);
    ACA_year = year;    
    localStorage.setItem(SELECT_YEAR, year);
}

export const getAcaYearList = () =>{
    return ACA_years;
}

export const getAcaYear = () =>{
    return ACA_year;
}