import { useState, useEffect } from 'react';
import { anchorTag, numberWithCommas, hccSeperator, textToSignFormatRender, dateFormatRender } from '../../assets/constants/customRender';
import { getAcaIssuer, getAcaIssuerList } from '../../assets/constants/issuerList';
import api from '../../assets/constants/RafserviceKPI.Instance';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import SingleHorizontalBar from '../../components/Charts/SingleHorizontalBar.chart';
import AcaIssuersSelect from '../../components/DropDowns/AcaIssuersSelect';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import history from '../../history';
import ServerSideGridTable from '../../components/TableGrids/ServerSideGridTable.table';
import {
    HCC, PROVIDER_ID, MAO_Chart_Review_Member, MAO_TYPE, KPI_CATEGORY, KPI_PPG,
    Plan_Performance_Dashboard,
    Provider_Group,
    Provider_Performance
} from '../../assets/constants/string.constants';
import CardComponent from '../../components/Cards/card';
import BarVertical from '../../components/GChart/BarVertical';
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';
import GridTable from "../../components/TableGrids/GridTable.table";
import GaugeChart from 'react-gauge-chart'
import KpiModuleSelect from '../../components/DropDowns/KpiModuleSelect';
import RangeSliderChart from '../../components/Charts/RangeSliderChart';
import { getKPIList, getKPISelect, KPIList } from '../../assets/constants/kpiList';
import { SELECT_KPI } from "../../assets/constants/string.constants";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ProviderPerformance = (props) => {
    const [memberSummaryObjectAED, setMemberSummaryObjectAED] = useState({});
    const [memberSummaryObjectAWV, setMemberSummaryObjectAWV] = useState({});
    const [memberSummaryObjectHCC, setMemberSummaryObjectHCC] = useState({});
    const [memberSummaryObjectLongHCC, setMemberSummaryObjectLongHCC] = useState({});
    const [memberSummaryObjectAll, setMemberSummaryObjectAll] = useState([]);

    const [expanded, setExpanded] = useState(false);
    const [selectValue, setSelectValue] = useState('');
    const [selectTypeValue, setSelectTypeValue] = useState('');
    const [selectIndexValue, setSelectIndexValue] = useState(0);
    const [selectMemberSummaryObject, setSelectMemberSummaryObject] = useState({});
    const [selectMemberSummaryData, setSelectMemberSummaryData] = useState([]);
    const [PreventiveServicesTotal, setPreventiveServicesTotal] = useState(0);
    const [LowValueCareTotal, setLowValueCareTotal] = useState(0);
    const [HCCCodingAnalysisTotal, setHCCCodingAnalysisTotal] = useState(0);
    const [memberSummaryKPIData, setMemberSummaryKPIData] = useState([]);
    const [KPIModuleSelect, setKPIModuleSelect] = useState();

    const memberSummaryColumn = [
        {
            label: "#Members Eligible",
            name: "TOTAL Members Eligible"
        },
        {
            label: "#Members Completed",
            name: "Total Members Completed"
        },
        {
            label: "% Completed ",
            name: "Completed"
        },
    ];


    const loadDataCount = () => {
        api.get('/data')
            .then(res => {
                if (res.data) {
                    setMemberSummaryObjectAll(res.data);
                    setMemberSummaryKPIData(res.data);
                }


            })
            .catch(err => {
                // console.log(err)
            })
    }


    const KPIchartClick = (KPITitle, KPIPPG) => {
        localStorage.setItem(KPI_CATEGORY, KPITitle);
        localStorage.setItem(KPI_PPG, KPIPPG);
        // history.push('/layout/healthcare/provider_group');
        props.handleChange('', Provider_Group);
    }


    const chartStyle = {
        height: 'auto',
        width: 300,
    }

    const handleChange = (panel, index, type) => (event, isExpanded) => {
        if (panel['KPI'] != '') {
            setSelectMemberSummaryObject(panel);
            setSelectMemberSummaryData(panel);
            setSelectValue(panel['KPI']);
            setSelectTypeValue(panel['KPI Grouping']);
            setSelectIndexValue(index);
        } else {
            setSelectMemberSummaryObject({});
            setSelectMemberSummaryData([]);
            setSelectValue('');
            setSelectTypeValue('');
            setSelectIndexValue(0);
        }
        setExpanded(isExpanded ? panel['KPI'] : false);
    };

    const HealthcareDataFilter = (KPIGrouping) => {
        var groupMemberCount = 0;
        memberSummaryKPIData && memberSummaryKPIData.forEach(element => {
            // if (element['KPI Grouping'] == KPIGrouping && element['PPG'] == 'ALL') {
            if (element['KPI Grouping'] == KPIGrouping && element['PPG'] == 'PROH') {
                groupMemberCount++;
            }
        });
        return groupMemberCount;
    }

    const HealthcareDataAllCompleted = (KPIName) => {
        var kpiAllComp = '';
        memberSummaryKPIData && memberSummaryKPIData.forEach(element => {
            if (element['KPI'] == KPIName && element['PPG'] == 'ALL') {
                kpiAllComp = element;
            }
        });
        return kpiAllComp;
    }

    useEffect(() => {
        setPreventiveServicesTotal(HealthcareDataFilter('Preventive Services'));
        setLowValueCareTotal(HealthcareDataFilter('Low Value Care'));
        setHCCCodingAnalysisTotal(HealthcareDataFilter('HCC Coding Analysis'));

    }, [memberSummaryKPIData]);


    useEffect(() => {

    }, [props.kpiModuleSelect]);

    useEffect(() => {
        loadDataCount();
        KPIList();
        setKPIModuleSelect(localStorage.getItem(SELECT_KPI));
    }, [])

    var PreventiveServicesIndex = 0;
    var LowValueCareIndex = 0;
    var HCCCodingAnalysisIndex = 0;

    return (
        <div className="level-summary">
            <div className="row pb-2">
                <div className="col-lg-12">
                    <div className="state-level">
                        <div className="card-header">
                            <div>
                                <span className="d-flex bd-highlight">
                                    <h6 className="pt-2 flex-grow-1 header-title-text"></h6>
                                    <div className="d-flex align-items-center">
                                        <label className="pr-2 pb-0 mb-0">Select PPG</label>
                                        <KpiModuleSelect
                                            KPIList={getKPIList()}
                                            KPIModuleSelect={KPIModuleSelect}
                                            onKPIModuleSelect={((e) => setKPIModuleSelect(e))}>
                                        </KpiModuleSelect>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mt-3">
                <div className="col-lg-12">
                    <CardComponent
                        heading={`Preventive Visits`}
                        additionalClass={`text-bold`}
                        body={
                            <div className="row mt-3 mb-5">
                                {memberSummaryKPIData && PreventiveServicesTotal > 0 && memberSummaryKPIData.map((element, index) => {
                                    return (
                                        element['KPI Grouping'] == 'Preventive Services' && element['PPG'] == props.kpiModuleSelect ?
                                            <>
                                                <div className="col-lg-4 mb-4">
                                                    <Accordion expanded={expanded === element['KPI']} onChange={handleChange(element, ((PreventiveServicesIndex += 1) % 3 == 0) ? PreventiveServicesIndex : ((PreventiveServicesIndex + 1) % 3 == 0) ? (PreventiveServicesIndex + 1) : (PreventiveServicesIndex + 2))}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls={element['KPI'] + "-content"}
                                                            id={element['KPI'] + "-header"}
                                                        >
                                                            <div className='col-lg-12 pr-0 mb-3'>
                                                                <div className='col-lg-12 pr-0 mb-2'>
                                                                    {element['KPI']} – Member Count
                                                                </div>
                                                                <div className='col-lg-11 pr-0 justify-content-center'>
                                                                    <RangeSliderChart
                                                                        minValue={
                                                                            0
                                                                        }
                                                                        maxValue={
                                                                            100
                                                                        }
                                                                        firstValue={
                                                                            element['Completed'].replace('%', "")
                                                                        }
                                                                        secondValue={
                                                                            (HealthcareDataAllCompleted(element['KPI']) && HealthcareDataAllCompleted(element['KPI']) != '') ?
                                                                                HealthcareDataAllCompleted(element['KPI'])?.Completed?.replace('%', "")
                                                                                :
                                                                                0
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </AccordionSummary>
                                                    </Accordion>
                                                </div>
                                                {(PreventiveServicesTotal % 3 != 0) && (PreventiveServicesIndex % 3 != 0) && (PreventiveServicesTotal == PreventiveServicesIndex) ?
                                                    <>
                                                        <span data-id={PreventiveServicesIndex += 1}></span>
                                                        {(PreventiveServicesIndex % 3 != 0) ?
                                                            <span data-id={PreventiveServicesIndex += 1}></span>
                                                            :
                                                            <></>
                                                        }
                                                    </>
                                                    :
                                                    <></>
                                                }
                                                {selectMemberSummaryObject && expanded && (PreventiveServicesIndex % 3 == 0) && PreventiveServicesIndex == selectIndexValue && selectTypeValue == 'Preventive Services' ?
                                                    <div className="container" style={{ "marginTop": "-37px", "marginBottom": "25px" }}>
                                                        <div className="col-lg-12 bg-white">
                                                            <div className="pb-3"></div>
                                                            <div className="col-lg-12 mt-3 mb-3">
                                                                <span style={{ color: '#526CEC', fontSize: '17px', fontWeight: "700" }}>
                                                                    {selectMemberSummaryObject['KPI'] + ' – Member Count'}
                                                                </span>
                                                            </div>
                                                            <div className="row col-lg-12">
                                                                <div class="col-lg-6">
                                                                    <div className='d-flex justify-content-center mb-2'>
                                                                        {selectMemberSummaryObject && selectMemberSummaryObject['Completed'] && selectMemberSummaryObject["Y-O-Y Change"].includes("-") > 0 ?
                                                                            <h3><b>{selectMemberSummaryObject['Completed'] + ' (Decreased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                            : selectMemberSummaryObject && selectMemberSummaryObject['Completed'] ?
                                                                                <h3><b>{selectMemberSummaryObject['Completed'] + ' (Increased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </div>
                                                                    <div className='justify-content-center'>
                                                                        <div onClick={() => KPIchartClick(selectMemberSummaryObject['KPI'], props.kpiModuleSelect)} style={{ 'cursor': 'pointer' }}>
                                                                            <RangeSliderChart
                                                                                minValue={
                                                                                    0
                                                                                }
                                                                                maxValue={
                                                                                    100
                                                                                }
                                                                                firstValue={
                                                                                    selectMemberSummaryObject['Completed'].replace('%', "")
                                                                                }
                                                                                secondValue={
                                                                                    (HealthcareDataAllCompleted(selectMemberSummaryObject['KPI']) && HealthcareDataAllCompleted(selectMemberSummaryObject['KPI']) != '') ?
                                                                                        HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])?.Completed?.replace('%', "")
                                                                                        :
                                                                                        0
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className='d-flex justify-content-center mb-2'>
                                                                        <div className='d-flex justify-content-center mb-3'>
                                                                            {HealthcareDataAllCompleted(selectMemberSummaryObject['KPI']) && HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])?.Completed && HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])["Y-O-Y Change"].includes("-") > 0 ?
                                                                                <h3><b>{HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])?.Completed + ' (Decreased by ' + HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                : HealthcareDataAllCompleted(selectMemberSummaryObject['KPI']) && selectMemberSummaryObject?.Completed?.replace('%', "") ?
                                                                                    <h3><b>{HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])?.Completed + ' (Increased by ' + HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row col-lg-12">
                                                                <div class="col-lg-6 mt-2">
                                                                    <GridTable
                                                                        data={[selectMemberSummaryData, HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])]}
                                                                        columns={memberSummaryColumn}
                                                                    ></GridTable>
                                                                </div>

                                                            </div>
                                                        </div>

                                                    </div>
                                                    :
                                                    <></>
                                                }
                                            </>
                                            :
                                            <></>
                                    )
                                })}


                            </div>
                        }>
                    </CardComponent>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-lg-12">
                    <CardComponent
                        heading={`Low Value Care`}
                        additionalClass={`text-bold`}
                        body={
                            <div className="row mt-3 mb-5">
                                {memberSummaryKPIData && LowValueCareTotal > 0 && memberSummaryKPIData.map((element, index) => {
                                    return (
                                        element['KPI Grouping'] == 'Low Value Care' && element['PPG'] == props.kpiModuleSelect ?
                                            <>
                                                <div className="col-lg-4 mb-4">
                                                    <Accordion expanded={expanded === element['KPI']} onChange={handleChange(element, ((LowValueCareIndex += 1) % 3 == 0) ? LowValueCareIndex : ((LowValueCareIndex + 1) % 3 == 0) ? (LowValueCareIndex + 1) : (LowValueCareIndex + 2))}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls={element['KPI'] + "-content"}
                                                            id={element['KPI'] + "-header"}
                                                        >
                                                            <div className='col-lg-12 pr-0 mb-3'>
                                                                <div className='col-lg-12 pr-0 mb-2'>
                                                                    {element['KPI']} – Member Count
                                                                </div>
                                                                <div className='col-lg-11 pr-0 justify-content-center'>
                                                                    <RangeSliderChart
                                                                        minValue={
                                                                            0
                                                                        }
                                                                        maxValue={
                                                                            100
                                                                        }
                                                                        firstValue={
                                                                            element['Completed'].replace('%', "")
                                                                        }
                                                                        secondValue={
                                                                            (HealthcareDataAllCompleted(element['KPI']) && HealthcareDataAllCompleted(element['KPI']) != '') ?
                                                                                HealthcareDataAllCompleted(element['KPI'])?.Completed?.replace('%', "")
                                                                                :
                                                                                0
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </AccordionSummary>
                                                    </Accordion>
                                                </div>
                                                {(LowValueCareTotal % 3 != 0) && (LowValueCareIndex % 3 != 0) && (LowValueCareTotal == LowValueCareIndex) ?
                                                    <>
                                                        <span data-id={LowValueCareIndex += 1}></span>
                                                        {(LowValueCareIndex % 3 != 0) ?
                                                            <span data-id={LowValueCareIndex += 1}></span>
                                                            :
                                                            <></>
                                                        }
                                                    </>
                                                    :
                                                    <></>
                                                }
                                                {selectMemberSummaryObject && expanded && (LowValueCareIndex % 3 == 0) && LowValueCareIndex == selectIndexValue && selectTypeValue == 'Low Value Care' ?
                                                    <div className="container" style={{ "marginTop": "-37px", "marginBottom": "25px" }}>
                                                        <div className="col-lg-12 bg-white">
                                                            <div className="pb-3"></div>
                                                            <div className="col-lg-12 mt-3 mb-3">
                                                                <span style={{ color: '#526CEC', fontSize: '17px', fontWeight: "700" }}>
                                                                    {selectMemberSummaryObject['KPI'] + ' – Member Count'}
                                                                </span>
                                                            </div>
                                                            <div className="row col-lg-12">
                                                                <div class="col-lg-6">
                                                                    <div className='d-flex justify-content-center mb-2'>
                                                                        {selectMemberSummaryObject && selectMemberSummaryObject['Completed'] && selectMemberSummaryObject["Y-O-Y Change"].includes("-") > 0 ?
                                                                            <h3><b>{selectMemberSummaryObject['Completed'] + ' (Decreased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                            : selectMemberSummaryObject && selectMemberSummaryObject['Completed'] ?
                                                                                <h3><b>{selectMemberSummaryObject['Completed'] + ' (Increased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </div>
                                                                    <div className='justify-content-center'>
                                                                        <div onClick={() => KPIchartClick(selectMemberSummaryObject['KPI'], props.kpiModuleSelect)} style={{ 'cursor': 'pointer' }}>
                                                                            <RangeSliderChart
                                                                                minValue={
                                                                                    0
                                                                                }
                                                                                maxValue={
                                                                                    100
                                                                                }
                                                                                firstValue={
                                                                                    selectMemberSummaryObject['Completed'].replace('%', "")
                                                                                }
                                                                                secondValue={
                                                                                    (HealthcareDataAllCompleted(selectMemberSummaryObject['KPI']) && HealthcareDataAllCompleted(selectMemberSummaryObject['KPI']) != '') ?
                                                                                        HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])?.Completed.replace('%', "")
                                                                                        :
                                                                                        0
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className='d-flex justify-content-center mb-2'>
                                                                        <div className='d-flex justify-content-center mb-3'>
                                                                            {HealthcareDataAllCompleted(selectMemberSummaryObject['KPI']) && HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])?.Completed && HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])["Y-O-Y Change"].includes("-") > 0 ?
                                                                                <h3><b>{HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])?.Completed + ' (Decreased by ' + HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                : HealthcareDataAllCompleted(selectMemberSummaryObject['KPI']) && selectMemberSummaryObject?.Completed?.replace('%', "") ?
                                                                                    <h3><b>{HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])?.Completed + ' (Increased by ' + HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row col-lg-12">
                                                                <div class="col-lg-6 mt-2">
                                                                    <GridTable
                                                                        data={[selectMemberSummaryData, HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])]}
                                                                        columns={memberSummaryColumn}
                                                                    ></GridTable>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    :
                                                    <></>
                                                }
                                            </>
                                            :
                                            <></>
                                    )
                                })}


                            </div>
                        }>
                    </CardComponent>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-lg-12">
                    <CardComponent
                        heading={`HCC Coding Analysis`}
                        additionalClass={`text-bold`}
                        body={
                            <div className="row mt-3 mb-5">
                                {memberSummaryKPIData && HCCCodingAnalysisTotal > 0 && memberSummaryKPIData.map((element, index) => {
                                    return (
                                        element['KPI Grouping'] == 'HCC Coding Analysis' && element['PPG'] == props.kpiModuleSelect ?
                                            <>
                                                <div className="col-lg-4 mb-4" >
                                                    <Accordion expanded={expanded === element['KPI']} onChange={handleChange(element, ((HCCCodingAnalysisIndex += 1) % 3 == 0) ? HCCCodingAnalysisIndex : ((HCCCodingAnalysisIndex + 1) % 3 == 0) ? (HCCCodingAnalysisIndex + 1) : (HCCCodingAnalysisIndex + 2))}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls={element['KPI'] + "-content"}
                                                            id={element['KPI'] + "-header"}
                                                        >
                                                            <div className='col-lg-12 pr-0 mb-3' >
                                                                <div className='col-lg-12 pr-0 mb-2'>
                                                                    {element['KPI']} – Member Count
                                                                </div>
                                                                <div className='col-lg-11 pr-0 justify-content-center'>
                                                                    <RangeSliderChart
                                                                        minValue={
                                                                            0
                                                                        }
                                                                        maxValue={
                                                                            100
                                                                        }
                                                                        firstValue={
                                                                            element['Completed'].replace('%', "")
                                                                        }
                                                                        secondValue={
                                                                            (HealthcareDataAllCompleted(element['KPI']) && HealthcareDataAllCompleted(element['KPI']) != '') ?
                                                                                HealthcareDataAllCompleted(element['KPI'])?.Completed?.replace('%', "")
                                                                                :
                                                                                0
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </AccordionSummary>
                                                    </Accordion>
                                                </div>
                                                {(HCCCodingAnalysisTotal % 3 != 0) && (HCCCodingAnalysisIndex % 3 != 0) && (HCCCodingAnalysisTotal == HCCCodingAnalysisIndex) ?
                                                    <>
                                                        <span data-id={HCCCodingAnalysisIndex += 1}></span>
                                                        {(HCCCodingAnalysisIndex % 3 != 0) ?
                                                            <span data-id={HCCCodingAnalysisIndex += 1}></span>
                                                            :
                                                            <></>
                                                        }
                                                    </>
                                                    :
                                                    <></>
                                                }
                                                {selectMemberSummaryObject && expanded && (HCCCodingAnalysisIndex % 3 == 0) && HCCCodingAnalysisIndex == selectIndexValue && selectTypeValue == 'HCC Coding Analysis' ?
                                                    <div className="container" style={{ "marginTop": "-37px", "marginBottom": "25px" }}>
                                                        <div className="col-lg-12 bg-white">
                                                            <div className="pb-3"></div>
                                                            <div className="col-lg-12 mt-3 mb-3">
                                                                <span style={{ color: '#526CEC', fontSize: '17px', fontWeight: "700" }}>
                                                                    {selectMemberSummaryObject['KPI'] + ' – Member Count'}
                                                                </span>
                                                            </div>
                                                            <div className="row col-lg-12">
                                                                <div class="col-lg-6">
                                                                    <div className='d-flex justify-content-center mb-2'>
                                                                        {selectMemberSummaryObject && selectMemberSummaryObject['Completed'] && selectMemberSummaryObject["Y-O-Y Change"].includes("-") > 0 ?
                                                                            <h3><b>{selectMemberSummaryObject['Completed'] + ' (Decreased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                            : selectMemberSummaryObject && selectMemberSummaryObject['Completed'] ?
                                                                                <h3><b>{selectMemberSummaryObject['Completed'] + ' (Increased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </div>
                                                                    <div className='justify-content-center'>
                                                                        <div onClick={() => KPIchartClick(selectMemberSummaryObject['KPI'], props.kpiModuleSelect)} style={{ 'cursor': 'pointer' }}>
                                                                            <RangeSliderChart
                                                                                minValue={
                                                                                    0
                                                                                }
                                                                                maxValue={
                                                                                    100
                                                                                }
                                                                                firstValue={
                                                                                    selectMemberSummaryObject['Completed'].replace('%', "")
                                                                                }
                                                                                secondValue={
                                                                                    (HealthcareDataAllCompleted(selectMemberSummaryObject['KPI']) && HealthcareDataAllCompleted(selectMemberSummaryObject['KPI']) != '') ?
                                                                                        HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])?.Completed.replace('%', "")
                                                                                        :
                                                                                        0
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className='d-flex justify-content-center mb-2'>
                                                                        <div className='d-flex justify-content-center mb-3'>
                                                                            {HealthcareDataAllCompleted(selectMemberSummaryObject['KPI']) && HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])?.Completed && HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])["Y-O-Y Change"].includes("-") > 0 ?
                                                                                <h3><b>{HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])?.Completed + ' (Decreased by ' + HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                : HealthcareDataAllCompleted(selectMemberSummaryObject['KPI']) && selectMemberSummaryObject?.Completed?.replace('%', "") ?
                                                                                    <h3><b>{HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])?.Completed + ' (Increased by ' + HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row col-lg-12">
                                                                <div class="col-lg-6 mt-2">
                                                                    <GridTable
                                                                        data={[selectMemberSummaryData, HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])]}
                                                                        columns={memberSummaryColumn}
                                                                    ></GridTable>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    :
                                                    <></>
                                                }
                                            </>
                                            :
                                            <></>
                                    )
                                })}


                            </div>
                        }>
                    </CardComponent>
                </div>
            </div>


            {/* <div className="row mt-3">
                <div className="col-lg-12">
                    <CardComponent
                        heading={`Preventive Visits`}
                        body={
                            <div className="row mt-3 mb-5">
                                {memberSummaryKPIData && PreventiveServicesTotal > 0 && memberSummaryKPIData.map((element, index) => {
                                    return (
                                        element['KPI Grouping'] == 'Preventive Services' && element['PPG'] == props.kpiModuleSelect ?
                                            <>
                                                <div className="col-lg-6 mb-4">
                                                    <Accordion expanded={expanded === element['KPI']} onChange={handleChange(element, ((PreventiveServicesIndex += 1) % 2 == 0) ? PreventiveServicesIndex : (PreventiveServicesIndex + 1))}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls={element['KPI'] + "-content"}
                                                            id={element['KPI'] + "-header"}
                                                        >
                                                            <div className='col-lg-12 pr-0 mb-3'>
                                                                <div className='col-lg-12 pr-0 mb-2'>
                                                                    {element['KPI']} – Member Count
                                                                </div>
                                                                <div className='col-lg-11 pr-0 justify-content-center'>
                                                                    <RangeSliderChart
                                                                        minValue={
                                                                            0
                                                                        }
                                                                        maxValue={
                                                                            100
                                                                        }
                                                                        firstValue={
                                                                            element['Completed'].replace('%', "")
                                                                        }
                                                                        secondValue={
                                                                            HealthcareDataAllCompleted(element['KPI'])?.Completed?.replace('%', "")
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </AccordionSummary>
                                                    </Accordion>
                                                </div>
                                                {(PreventiveServicesTotal % 2 != 0) && (PreventiveServicesIndex % 2 != 0) && (PreventiveServicesTotal == PreventiveServicesIndex) ?
                                                    <span data-id={PreventiveServicesIndex += 1}></span>
                                                    :
                                                    <></>
                                                }
                                                {selectMemberSummaryObject && expanded && (PreventiveServicesIndex % 2 == 0) && PreventiveServicesIndex == selectIndexValue && selectTypeValue == 'Preventive Services' ?
                                                    <div className="container" style={{ "marginTop": "-37px", "marginBottom": "25px" }}>
                                                        <div className="col-lg-12 bg-white">
                                                            <div className="pb-3"></div>
                                                            <div className="col-lg-12 mt-3 mb-3">
                                                                <span style={{ color: '#526CEC', fontSize: '17px', fontWeight: "700" }}>
                                                                    {selectMemberSummaryObject['KPI'] + ' – Member Count'}
                                                                </span>
                                                            </div>
                                                            <div className="row col-lg-12">
                                                                <div class="col-lg-6">
                                                                    <div className='justify-content-center'>
                                                                        <div onClick={() => KPIchartClick(selectMemberSummaryObject['KPI'], props.kpiModuleSelect)} style={{ 'cursor': 'pointer' }}>
                                                                            <RangeSliderChart
                                                                                minValue={
                                                                                    0
                                                                                }
                                                                                maxValue={
                                                                                    100
                                                                                }
                                                                                firstValue={
                                                                                    selectMemberSummaryObject['Completed'].replace('%', "")
                                                                                }
                                                                                secondValue={
                                                                                    HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])?.Completed?.replace('%', "")
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className='d-flex justify-content-center mb-2'>
                                                                        {selectMemberSummaryObject && selectMemberSummaryObject['Completed'] && selectMemberSummaryObject["Y-O-Y Change"].includes("-") > 0 ?
                                                                            <h3><b>{selectMemberSummaryObject['Completed'] + ' (Decreased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                            : selectMemberSummaryObject && selectMemberSummaryObject['Completed'] ?
                                                                                <h3><b>{selectMemberSummaryObject['Completed'] + ' (Increased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </div>
                                                                    <div className='d-flex justify-content-center mb-2'>
                                                                        <div className='d-flex justify-content-center mb-3'>
                                                                            {HealthcareDataAllCompleted(selectMemberSummaryObject['KPI']) && HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])?.Completed && HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])["Y-O-Y Change"].includes("-") > 0 ?
                                                                                <h3><b>{HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])?.Completed + ' (Decreased by ' + HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                : HealthcareDataAllCompleted(selectMemberSummaryObject['KPI']) && selectMemberSummaryObject?.Completed?.replace('%', "") ?
                                                                                    <h3><b>{HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])?.Completed + ' (Increased by ' + HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6 mt-2">
                                                                    <GridTable
                                                                        data={[selectMemberSummaryData, HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])]}
                                                                        columns={memberSummaryColumn}
                                                                    ></GridTable>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    :
                                                    <></>
                                                }
                                            </>
                                            :
                                            <></>
                                    )
                                })}


                            </div>
                        }>
                    </CardComponent>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-lg-12">
                    <CardComponent
                        heading={`Low Value Care`}
                        body={
                            <div className="row mt-3 mb-5">
                                {memberSummaryKPIData && LowValueCareTotal > 0 && memberSummaryKPIData.map((element, index) => {
                                    return (
                                        element['KPI Grouping'] == 'Low Value Care' && element['PPG'] == props.kpiModuleSelect ?
                                            <>
                                                <div className="col-lg-6 mb-4">
                                                    <Accordion expanded={expanded === element['KPI']} onChange={handleChange(element, ((LowValueCareIndex += 1) % 2 == 0) ? LowValueCareIndex : (LowValueCareIndex + 1))}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls={element['KPI'] + "-content"}
                                                            id={element['KPI'] + "-header"}
                                                        >
                                                            <div className='col-lg-12 pr-0 mb-3'>
                                                                <div className='col-lg-12 pr-0 mb-2'>
                                                                    {element['KPI']} – Member Count
                                                                </div>
                                                                <div className='col-lg-11 pr-0 justify-content-center'>
                                                                    <RangeSliderChart
                                                                        minValue={
                                                                            0
                                                                        }
                                                                        maxValue={
                                                                            100
                                                                        }
                                                                        firstValue={
                                                                            element['Completed'].replace('%', "")
                                                                        }
                                                                        secondValue={
                                                                            HealthcareDataAllCompleted(element['KPI'])?.Completed?.replace('%', "")
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </AccordionSummary>
                                                    </Accordion>
                                                </div>
                                                {(LowValueCareTotal % 2 != 0) && (LowValueCareIndex % 2 != 0) && (LowValueCareTotal == LowValueCareIndex) ?
                                                    <span data-id={LowValueCareIndex += 1}></span>
                                                    :
                                                    <></>
                                                }
                                                {selectMemberSummaryObject && expanded && (LowValueCareIndex % 2 == 0) && LowValueCareIndex == selectIndexValue && selectTypeValue == 'Low Value Care' ?
                                                    <div className="container" style={{ "marginTop": "-37px", "marginBottom": "25px" }}>
                                                        <div className="col-lg-12 bg-white">
                                                            <div className="pb-3"></div>
                                                            <div className="col-lg-12 mt-3 mb-3">
                                                                <span style={{ color: '#526CEC', fontSize: '17px', fontWeight: "700" }}>
                                                                    {selectMemberSummaryObject['KPI'] + ' – Member Count'}
                                                                </span>
                                                            </div>
                                                            <div className="row col-lg-12">
                                                                <div class="col-lg-6">
                                                                    <div className='justify-content-center'>
                                                                        <div onClick={() => KPIchartClick(selectMemberSummaryObject['KPI'], props.kpiModuleSelect)} style={{ 'cursor': 'pointer' }}>
                                                                            <RangeSliderChart
                                                                                minValue={
                                                                                    0
                                                                                }
                                                                                maxValue={
                                                                                    100
                                                                                }
                                                                                firstValue={
                                                                                    selectMemberSummaryObject['Completed'].replace('%', "")
                                                                                }
                                                                                secondValue={
                                                                                    HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])?.Completed.replace('%', "")
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className='d-flex justify-content-center mb-2'>
                                                                        {selectMemberSummaryObject && selectMemberSummaryObject['Completed'] && selectMemberSummaryObject["Y-O-Y Change"].includes("-") > 0 ?
                                                                            <h3><b>{selectMemberSummaryObject['Completed'] + ' (Decreased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                            : selectMemberSummaryObject && selectMemberSummaryObject['Completed'] ?
                                                                                <h3><b>{selectMemberSummaryObject['Completed'] + ' (Increased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </div>
                                                                    <div className='d-flex justify-content-center mb-2'>
                                                                        <div className='d-flex justify-content-center mb-3'>
                                                                            {HealthcareDataAllCompleted(selectMemberSummaryObject['KPI']) && HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])?.Completed && HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])["Y-O-Y Change"].includes("-") > 0 ?
                                                                                <h3><b>{HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])?.Completed + ' (Decreased by ' + HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                : HealthcareDataAllCompleted(selectMemberSummaryObject['KPI']) && selectMemberSummaryObject?.Completed?.replace('%', "") ?
                                                                                    <h3><b>{HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])?.Completed + ' (Increased by ' + HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6 mt-2">
                                                                    <GridTable
                                                                        data={[selectMemberSummaryData, HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])]}
                                                                        columns={memberSummaryColumn}
                                                                    ></GridTable>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    :
                                                    <></>
                                                }
                                            </>
                                            :
                                            <></>
                                    )
                                })}


                            </div>
                        }>
                    </CardComponent>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-lg-12">
                    <CardComponent
                        heading={`HCC Coding Analysis`}
                        body={
                            <div className="row mt-3 mb-5">
                                {memberSummaryKPIData && memberSummaryKPIData.map((element, index) => {
                                    return (
                                        element['KPI Grouping'] == 'HCC Coding Analysis' && element['PPG'] == props.kpiModuleSelect ?
                                            <>
                                                <div className="col-lg-6 mb-4">
                                                    <Accordion expanded={expanded === element['KPI']} onChange={handleChange(element, ((HCCCodingAnalysisIndex += 1) % 2 == 0) ? HCCCodingAnalysisIndex : (HCCCodingAnalysisIndex + 1))}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls={element['KPI'] + "-content"}
                                                            id={element['KPI'] + "-header"}
                                                        >
                                                            <div className='col-lg-12 pr-0 mb-3'>
                                                                <div className='col-lg-12 pr-0 mb-2'>
                                                                    {element['KPI']} – Member Count
                                                                </div>
                                                                <div className='col-lg-11 pr-0 justify-content-center'>
                                                                    <RangeSliderChart
                                                                        minValue={
                                                                            0
                                                                        }
                                                                        maxValue={
                                                                            100
                                                                        }
                                                                        firstValue={
                                                                            element['Completed'].replace('%', "")
                                                                        }
                                                                        secondValue={
                                                                            HealthcareDataAllCompleted(element['KPI'])?.Completed?.replace('%', "")
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        </AccordionSummary>
                                                    </Accordion>
                                                </div>
                                                {(HCCCodingAnalysisTotal % 2 != 0) && (HCCCodingAnalysisIndex % 2 != 0) && (HCCCodingAnalysisTotal == HCCCodingAnalysisIndex) ?
                                                    <span data-id={HCCCodingAnalysisIndex += 1}></span>
                                                    :
                                                    <></>
                                                }
                                                {selectMemberSummaryObject && expanded && (HCCCodingAnalysisIndex % 2 == 0) && HCCCodingAnalysisIndex == selectIndexValue && selectTypeValue == 'HCC Coding Analysis' ?
                                                    <div className="container" style={{ "marginTop": "-37px", "marginBottom": "25px" }}>
                                                        <div className="col-lg-12 bg-white">
                                                            <div className="pb-3"></div>
                                                            <div className="col-lg-12 mt-3 mb-3">
                                                                <span style={{ color: '#526CEC', fontSize: '17px', fontWeight: "700" }}>
                                                                    {selectMemberSummaryObject['KPI'] + ' – Member Count'}
                                                                </span>
                                                            </div>
                                                            <div className="row col-lg-12">
                                                                <div class="col-lg-6">
                                                                    <div className='justify-content-center'>
                                                                        <div onClick={() => KPIchartClick(selectMemberSummaryObject['KPI'], props.kpiModuleSelect)} style={{ 'cursor': 'pointer' }}>
                                                                            <RangeSliderChart
                                                                                minValue={
                                                                                    0
                                                                                }
                                                                                maxValue={
                                                                                    100
                                                                                }
                                                                                firstValue={
                                                                                    selectMemberSummaryObject['Completed'].replace('%', "")
                                                                                }
                                                                                secondValue={
                                                                                    HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])?.Completed?.replace('%', "")
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className='d-flex justify-content-center mb-2'>
                                                                        {selectMemberSummaryObject && selectMemberSummaryObject['Completed'] && selectMemberSummaryObject["Y-O-Y Change"].includes("-") > 0 ?
                                                                            <h3><b>{selectMemberSummaryObject['Completed'] + ' (Decreased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                            : selectMemberSummaryObject && selectMemberSummaryObject['Completed'] ?
                                                                                <h3><b>{selectMemberSummaryObject['Completed'] + ' (Increased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                :
                                                                                <></>
                                                                        }
                                                                    </div>
                                                                    <div className='d-flex justify-content-center mb-2'>
                                                                        <div className='d-flex justify-content-center mb-3'>
                                                                            {HealthcareDataAllCompleted(selectMemberSummaryObject['KPI']) && HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])?.Completed && HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])["Y-O-Y Change"].includes("-") > 0 ?
                                                                                <h3><b>{HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])?.Completed + ' (Decreased by ' + HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                : HealthcareDataAllCompleted(selectMemberSummaryObject['KPI']) && selectMemberSummaryObject?.Completed?.replace('%', "") ?
                                                                                    <h3><b>{HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])?.Completed + ' (Increased by ' + HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-6 mt-2">
                                                                    <GridTable
                                                                        data={[selectMemberSummaryData, HealthcareDataAllCompleted(selectMemberSummaryObject['KPI'])]}
                                                                        columns={memberSummaryColumn}
                                                                    ></GridTable>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    :
                                                    <></>
                                                }
                                            </>
                                            :
                                            <></>
                                    )
                                })}


                            </div>
                        }>
                    </CardComponent>
                </div>
            </div> */}
            {/* <div className="row mt-3 mb-5">
                <div className="col-lg-6">
                    <Accordion expanded={expanded === 'AWV'} onChange={handleChange('AWV')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="AWV-content"
                            id="AWV-header"
                        >
                            <div className='col-lg-12 pr-0 mb-3'>
                                <div className='col-lg-12 pr-0 mb-3'>
                                    <b>PREVENTIVE VISITS </b>
                                </div>
                                <div className='col-lg-12 pr-0 mb-2'>
                                    AWV – Member Count
                                </div>
                                <div className='col-lg-11 pr-0 justify-content-center'>
                                    <RangeSliderChart
                                        minValue={
                                            30
                                        }
                                        maxValue={
                                            90
                                        }
                                        firstValue={
                                            memberSummaryObjectAWV && memberSummaryObjectAWV.Completed ?
                                                parseInt(memberSummaryObjectAWV?.Completed.replace('%', ""))
                                                :
                                                0
                                        }
                                        secondValue={
                                            memberSummarySelectObjectAWV && memberSummarySelectObjectAWV.Completed ?
                                                parseInt(memberSummarySelectObjectAWV?.Completed.replace('%', ""))
                                                :
                                                0
                                        }
                                    />
                                </div>
                            </div>
                        </AccordionSummary>
                    </Accordion>
                </div>
                <div className="col-lg-6">
                    <Accordion expanded={expanded === 'AED'} onChange={handleChange('AED')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="AED-content"
                            id="AED-header"
                        >
                            <div className='col-lg-12 pr-0 mb-3'>
                                <div className='col-lg-12 pr-0 mb-3'>
                                    <b>LOW VALUE CARE</b>
                                </div>
                                <div className='col-lg-12 pr-0 mb-2'>
                                    AEV  – Member Count
                                </div>
                                <div className='col-lg-11 pr-0 justify-content-center'>
                                    <RangeSliderChart
                                        minValue={
                                            4
                                        }
                                        maxValue={
                                            39
                                        }
                                        firstValue={
                                            memberSummaryObjectAED && memberSummaryObjectAED.Completed ?
                                                parseInt(memberSummaryObjectAED?.Completed.replace('%', ""))
                                                :
                                                0
                                        }
                                        secondValue={
                                            memberSummarySelectObjectAED && memberSummarySelectObjectAED.Completed ?
                                                parseInt(memberSummarySelectObjectAED?.Completed.replace('%', ""))
                                                :
                                                0
                                        }
                                    />
                                </div>
                            </div>
                        </AccordionSummary>
                    </Accordion>
                </div>
                {selectMemberSummaryObject && selectMemberSummaryData && (expanded == 'AED' || expanded == 'AWV') ?
                    <div className="container" style={{ "marginTop": "-15px" }}>
                        <div className="col-lg-12 bg-white">
                            <div className="pb-3"></div>
                            <div className="col-lg-12 mt-3 mb-3">
                                <span style={{ color: '#526CEC', fontSize: '17px', fontWeight: "700" }}>{selectMemberSummaryTitle}</span>
                            </div>
                            <div className="row col-lg-12">
                                <div class="col-lg-6">
                                    <div className='justify-content-center'>
                                        <div onClick={() => KPIchartClick(selectValue, props.kpiModuleSelect)} style={{ 'cursor': 'pointer' }}>
                                            <RangeSliderChart
                                                minValue={
                                                    30
                                                }
                                                maxValue={
                                                    90
                                                }
                                                firstValue={
                                                    selectAllMemberSummaryObject && selectAllMemberSummaryObject.Completed ?
                                                        parseInt(selectAllMemberSummaryObject?.Completed.replace('%', ""))
                                                        :
                                                        0
                                                }
                                                secondValue={
                                                    selectMemberSummaryObject && selectMemberSummaryObject.Completed ?
                                                        parseInt(selectMemberSummaryObject?.Completed.replace('%', ""))
                                                        :
                                                        0
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center mb-2'>
                                        {selectAllMemberSummaryObject && selectAllMemberSummaryObject.Completed && selectAllMemberSummaryObject["Y-O-Y Change"].includes("-") > 0 ?
                                            <h3><b>{selectAllMemberSummaryObject.Completed + ' (Decreased by ' + selectAllMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                            : selectAllMemberSummaryObject && selectAllMemberSummaryObject.Completed ?
                                                <h3><b>{selectAllMemberSummaryObject.Completed + ' (Increased by ' + selectAllMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                :
                                                <></>
                                        }
                                    </div>
                                    <div className='d-flex justify-content-center mb-3'>
                                        {selectMemberSummaryObject && selectMemberSummaryObject.Completed && selectMemberSummaryObject["Y-O-Y Change"].includes("-") > 0 ?
                                            <h3><b>{selectMemberSummaryObject.Completed + ' (Decreased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                            : selectMemberSummaryObject && selectMemberSummaryObject.Completed ?
                                                <h3><b>{selectMemberSummaryObject.Completed + ' (Increased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>
                                <div class="col-lg-6 mt-2">
                                    <GridTable
                                        data={selectMemberSummaryData}
                                        columns={memberSummaryColumn}
                                    ></GridTable>
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    <></>
                }
            </div>
            <div className="row mt-5 mb-5">
                <div className="col-lg-12">
                    <Accordion expanded={expanded === 'HCC'} onChange={handleChange('HCC')}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="HCC-content"
                            id="HCC-header"
                        >
                            <div className='col-lg-12 pr-0 mb-3'>
                                <div className='col-lg-12 pr-0 mb-3'>
                                    <b>HCC CAPTURE RATE</b>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className='col-lg-12 pr-0 mb-2'>
                                            HCC – Member Count
                                        </div>
                                        <div className='col-lg-11 pr-0 justify-content-center'>
                                            <RangeSliderChart
                                                minValue={
                                                    30
                                                }
                                                maxValue={
                                                    95
                                                }
                                                firstValue={
                                                    memberSummaryObjectHCC && memberSummaryObjectHCC.Completed ?
                                                        parseInt(memberSummaryObjectHCC?.Completed.replace('%', ""))
                                                        :
                                                        0
                                                }
                                                secondValue={
                                                    memberSummarySelectObjectHCC && memberSummarySelectObjectHCC.Completed ?
                                                        parseInt(memberSummarySelectObjectHCC?.Completed.replace('%', ""))
                                                        :
                                                        0
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='col-lg-12 pr-0'>
                                            Life Long HCC – Member Count
                                        </div>
                                        <div className='col-lg-11 pr-0 justify-content-center'>
                                            <RangeSliderChart
                                                minValue={
                                                    39
                                                }
                                                maxValue={
                                                    95
                                                }
                                                firstValue={
                                                    memberSummaryObjectLongHCC && memberSummaryObjectLongHCC.Completed ?
                                                        parseInt(memberSummaryObjectLongHCC?.Completed.replace('%', ""))
                                                        :
                                                        0
                                                }
                                                secondValue={
                                                    memberSummarySelectObjectLongHCC && memberSummarySelectObjectLongHCC.Completed ?
                                                        parseInt(memberSummarySelectObjectLongHCC?.Completed.replace('%', ""))
                                                        :
                                                        0
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </AccordionSummary>
                    </Accordion>
                </div>
                {selectMemberSummaryObject && selectMemberSummaryData && expanded == 'HCC' ?
                    <div className="container" style={{ "marginTop": "-15px" }}>
                        <div className="col-lg-12 bg-white">
                            <div className="pb-3"></div>
                            <div className="row col-lg-12">
                                <div class="col-lg-6">
                                    <div className="col-lg-12 mt-3 mb-3">
                                        <span style={{ color: '#526CEC', fontSize: '17px', fontWeight: "700" }}>
                                            {'HCC – Member Count'}
                                        </span>
                                    </div>
                                    <div className='justify-content-center'>
                                        <div onClick={() => KPIchartClick('HCC', props.kpiModuleSelect)} style={{ 'cursor': 'pointer' }}>
                                            <RangeSliderChart
                                                minValue={
                                                    30
                                                }
                                                maxValue={
                                                    95
                                                }
                                                firstValue={
                                                    memberSummaryObjectHCC && memberSummaryObjectHCC.Completed ?
                                                        parseInt(memberSummaryObjectHCC?.Completed.replace('%', ""))
                                                        :
                                                        0
                                                }
                                                secondValue={
                                                    memberSummarySelectObjectHCC && memberSummarySelectObjectHCC.Completed ?
                                                        parseInt(memberSummarySelectObjectHCC?.Completed.replace('%', ""))
                                                        :
                                                        0
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center mb-2'>
                                        {memberSummaryObjectHCC && memberSummaryObjectHCC.Completed && memberSummaryObjectHCC["Y-O-Y Change"].includes("-") > 0 ?
                                            <h3><b>{memberSummaryObjectHCC.Completed + ' (Decreased by ' + memberSummaryObjectHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                            : memberSummaryObjectHCC && memberSummaryObjectHCC.Completed ?
                                                <h3><b>{memberSummaryObjectHCC.Completed + ' (Increased by ' + memberSummaryObjectHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                :
                                                <></>
                                        }
                                    </div>
                                    <div className='d-flex justify-content-center mb-3'>
                                        {memberSummarySelectObjectHCC && memberSummarySelectObjectHCC.Completed && memberSummarySelectObjectHCC["Y-O-Y Change"].includes("-") > 0 ?
                                            <h3><b>{memberSummarySelectObjectHCC.Completed + ' (Decreased by ' + memberSummarySelectObjectHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                            : memberSummarySelectObjectHCC && memberSummarySelectObjectHCC.Completed ?
                                                <h3><b>{memberSummarySelectObjectHCC.Completed + ' (Increased by ' + memberSummarySelectObjectHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                :
                                                <></>
                                        }
                                    </div>
                                    <GridTable
                                        data={memberSummaryCombineDataHCC}
                                        columns={memberSummaryColumn}
                                    ></GridTable>
                                </div>
                                <div class="col-lg-6">
                                    <div className="col-lg-12 mt-3 mb-3">
                                        <span style={{ color: '#526CEC', fontSize: '17px', fontWeight: "700" }}>
                                            {'Life Long HCC – Member Count'}
                                        </span>
                                    </div>
                                    <div className='justify-content-center'>
                                        <div onClick={() => KPIchartClick('LFHCC', props.kpiModuleSelect)} style={{ 'cursor': 'pointer' }}>
                                            <RangeSliderChart
                                                minValue={
                                                    39
                                                }
                                                maxValue={
                                                    95
                                                }
                                                firstValue={
                                                    memberSummaryObjectLongHCC && memberSummaryObjectLongHCC.Completed ?
                                                        parseInt(memberSummaryObjectLongHCC?.Completed.replace('%', ""))
                                                        :
                                                        0
                                                }
                                                secondValue={
                                                    memberSummaryObjectLongHCC && memberSummaryObjectLongHCC.Completed ?
                                                        parseInt(memberSummaryObjectLongHCC?.Completed.replace('%', ""))
                                                        :
                                                        0
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center mb-2'>
                                        {memberSummaryObjectLongHCC && memberSummaryObjectLongHCC.Completed && memberSummaryObjectLongHCC["Y-O-Y Change"].includes("-") > 0 ?
                                            <h3><b>{memberSummaryObjectLongHCC.Completed + ' (Decreased by ' + memberSummaryObjectLongHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                            : memberSummaryObjectLongHCC && memberSummaryObjectLongHCC.Completed ?
                                                <h3><b>{memberSummaryObjectLongHCC.Completed + ' (Increased by ' + memberSummaryObjectLongHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                :
                                                <></>
                                        }
                                    </div>
                                    <div className='d-flex justify-content-center mb-3'>
                                        {memberSummaryObjectLongHCC && memberSummaryObjectLongHCC.Completed && memberSummaryObjectLongHCC["Y-O-Y Change"].includes("-") > 0 ?
                                            <h3><b>{memberSummaryObjectLongHCC.Completed + ' (Decreased by ' + memberSummaryObjectLongHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                            : memberSummaryObjectLongHCC && memberSummaryObjectLongHCC.Completed ?
                                                <h3><b>{memberSummaryObjectLongHCC.Completed + ' (Increased by ' + memberSummaryObjectLongHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                :
                                                <></>
                                        }
                                    </div>
                                    <GridTable
                                        data={memberSummaryCombineDataLongHCC}
                                        columns={memberSummaryColumn}
                                    ></GridTable>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <></>
                }
            </div> */}
            {/* <div className="row mt-3">
                <div className="col-lg-12">
                    <CardComponent
                        heading={`Preventive Visits (AWV - Preventive Visits)`}
                        body={
                            <>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className='justify-content-center'>
                                            <div onClick={() => KPIchartClick('AWV', props.kpiModuleSelect)} style={{ 'cursor': 'pointer' }}>
                                                <RangeSliderChart
                                                    minValue={parseInt(memberSummaryChartDataAWV)}
                                                    maxValue={
                                                        memberSummarySelectObjectAWV && memberSummarySelectObjectAWV.Completed ?
                                                            parseInt(memberSummarySelectObjectAWV?.Completed.replace('%', ""))
                                                            :
                                                            0
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            {memberSummaryObjectAWV && memberSummaryObjectAWV.Completed && memberSummaryObjectAWV["Y-O-Y Change"].includes("-") > 0 ?
                                                <h3><b>{memberSummaryObjectAWV.Completed + ' (Decreased by ' + memberSummaryObjectAWV["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                : memberSummaryObjectAWV && memberSummaryObjectAWV.Completed ?
                                                    <h3><b>{memberSummaryObjectAWV.Completed + ' (Increased by ' + memberSummaryObjectAWV["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        <div className='d-flex justify-content-center mb-2'>
                                            {memberSummarySelectObjectAWV && memberSummarySelectObjectAWV.Completed && memberSummarySelectObjectAWV["Y-O-Y Change"].includes("-") > 0 ?
                                                <h3><b>{memberSummarySelectObjectAWV.Completed + ' (Decreased by ' + memberSummarySelectObjectAWV["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                : memberSummarySelectObjectAWV && memberSummarySelectObjectAWV.Completed ?
                                                    <h3><b>{memberSummarySelectObjectAWV.Completed + ' (Increased by ' + memberSummarySelectObjectAWV["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        <GridTable
                                            data={memberSummaryCombineDataAWV}
                                            columns={memberSummaryAllColumn}
                                        ></GridTable>
                                    </div>
                                </div>

                            </>
                        }>
                    </CardComponent>
                </div>
            </div>
            <div className="row mt-3 mb-3">
                <div className="col-lg-12">
                    <CardComponent
                        heading={`Low Value Care (AED - Low Value Care)`}
                        body={
                            <>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className='justify-content-center'>
                                            <div onClick={() => KPIchartClick('AED', props.kpiModuleSelect)} style={{ 'cursor': 'pointer' }}>
                                                <RangeSliderChart
                                                    minValue={memberSummaryChartDataAED}
                                                    maxValue={
                                                        memberSummarySelectObjectAED && memberSummarySelectObjectAED.Completed ?
                                                            parseInt(memberSummarySelectObjectAED?.Completed.replace('%', ""))
                                                            :
                                                            0
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            {memberSummaryObjectAED && memberSummaryObjectAED.Completed && memberSummaryObjectAED["Y-O-Y Change"].includes("-") > 0 ?
                                                <h3><b>{memberSummaryObjectAED.Completed + ' (Decreased by ' + memberSummaryObjectAED["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                : memberSummaryObjectAED && memberSummaryObjectAED.Completed ?
                                                    <h3><b>{memberSummaryObjectAED.Completed + ' (Increased by ' + memberSummaryObjectAED["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        <div className='d-flex justify-content-center mb-2'>
                                            {memberSummarySelectObjectAED && memberSummarySelectObjectAED.Completed && memberSummarySelectObjectAED["Y-O-Y Change"].includes("-") > 0 ?
                                                <h3><b>{memberSummarySelectObjectAED.Completed + ' (Decreased by ' + memberSummarySelectObjectAED["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                : memberSummarySelectObjectAED && memberSummarySelectObjectAED.Completed ?
                                                    <h3><b>{memberSummarySelectObjectAED.Completed + ' (Increased by ' + memberSummarySelectObjectAED["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        <GridTable
                                            data={memberSummaryCombineDataAED}
                                            columns={memberSummaryAllColumn}
                                        ></GridTable>
                                    </div>
                                </div>

                            </>
                        }>
                    </CardComponent>
                </div>
            </div>
            <div className="row mt-3 mb-3">
                <div className="col-lg-12">
                    <CardComponent
                        heading={`HCC Capture Rate (HCC - Member Count)`}
                        body={
                            <>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className='justify-content-center'>
                                            <div onClick={() => KPIchartClick('HCC', props.kpiModuleSelect)} style={{ 'cursor': 'pointer' }}>
                                                <RangeSliderChart
                                                    minValue={parseInt(memberSummaryChartDataHCC)}
                                                    maxValue={
                                                        memberSummarySelectObjectHCC && memberSummarySelectObjectHCC.Completed ?
                                                            parseInt(memberSummarySelectObjectHCC?.Completed.replace('%', ""))
                                                            :
                                                            0
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            {memberSummaryObjectHCC && memberSummaryObjectHCC.Completed && memberSummaryObjectHCC["Y-O-Y Change"].includes("-") > 0 ?
                                                <h3><b>{memberSummaryObjectHCC.Completed + ' (Decreased by ' + memberSummaryObjectHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                : memberSummaryObjectHCC && memberSummaryObjectHCC.Completed ?
                                                    <h3><b>{memberSummaryObjectHCC.Completed + ' (Increased by ' + memberSummaryObjectHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        <div className='d-flex justify-content-center mb-2'>
                                            {memberSummarySelectObjectHCC && memberSummarySelectObjectHCC.Completed && memberSummarySelectObjectHCC["Y-O-Y Change"].includes("-") > 0 ?
                                                <h3><b>{memberSummarySelectObjectHCC.Completed + ' (Decreased by ' + memberSummarySelectObjectHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                : memberSummarySelectObjectHCC && memberSummarySelectObjectHCC.Completed ?
                                                    <h3><b>{memberSummarySelectObjectHCC.Completed + ' (Increased by ' + memberSummarySelectObjectHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        <GridTable
                                            data={memberSummaryCombineDataHCC}
                                            columns={memberSummaryAllColumn}
                                        ></GridTable>
                                    </div>
                                </div>
                            </>
                        }>
                    </CardComponent>
                </div>
            </div>
            <div className="row mt-3 mb-3">
                <div className="col-lg-12">
                    <CardComponent
                        heading={`HCC Capture Rate (Life Long HCC - HCC Capture Rate)`}
                        body={
                            <>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className='justify-content-center'>
                                            <div onClick={() => KPIchartClick('LFHCC', props.kpiModuleSelect)} style={{ 'cursor': 'pointer' }}>
                                                <RangeSliderChart
                                                    minValue={parseInt(memberSummaryChartDataLongHCC)}
                                                    maxValue={
                                                        memberSummarySelectObjectLongHCC && memberSummarySelectObjectLongHCC.Completed ?
                                                            parseInt(memberSummarySelectObjectLongHCC?.Completed.replace('%', ""))
                                                            :
                                                            0
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-center'>
                                            {memberSummaryObjectLongHCC && memberSummaryObjectLongHCC.Completed ?
                                                <h3><b>{memberSummaryObjectLongHCC.Completed + ' (Increased by ' + memberSummaryObjectLongHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                :
                                                <></>
                                            }
                                        </div>
                                        <div className='d-flex justify-content-center mb-2'>
                                            {memberSummarySelectObjectLongHCC && memberSummarySelectObjectLongHCC.Completed ?
                                                <h3><b>{memberSummarySelectObjectLongHCC.Completed + ' (Increased by ' + memberSummarySelectObjectLongHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                :
                                                <></>
                                            }
                                        </div>
                                        <GridTable
                                            data={memberSummaryCombineDataLongHCC}
                                            columns={memberSummaryAllColumn}
                                        ></GridTable>
                                    </div>
                                </div>
                            </>
                        }>
                    </CardComponent>
                </div>
            </div>
            <div className="hcc-list pb-5 mb-3">
            </div> */}
            <br />
            <br />
            <br />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
        kpiModuleSelect: state.moduleFilter.kpiModuleSelect,
    };
}
export default connect(mapStateToProps)(ProviderPerformance);
