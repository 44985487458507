import { useState } from "react";
import { anchorTag, hccSeperator } from "../../assets/constants/customRender";
import { CARRIER_ID, GENDER, HCC, ISSUER_ID, MEMBER_ID, MEMBER_NAME, PLAN_ID, RAF_RANGE, REPORTED_INSIGHTS } from "../../assets/constants/string.constants";
import { getAcaYear } from "../../assets/constants/yearList";
import CardComponent from "../../components/Cards/card";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import history from "../../history";
import { connect } from 'react-redux';

const HccDrillDownACA = (props) =>{

    const [issuer ] = useState(localStorage.getItem(ISSUER_ID));
    const [hcc ] = useState(localStorage.getItem(HCC));
    const [rafRange ] = useState(localStorage.getItem(RAF_RANGE));
    const [year ] = useState(props.yearSelect);

    const memberCols = [
        {
            label : "Member ID",
            name:"MEMBER_ID",
            options : anchorTag
        },
		{
            name : "MEMBER_Name",
            label : "Member Name"
        },
        {
            name : "Carrier_ID",
            label : "Carrier ID"
        },								
        {
            name : "HCC_POST_HIERARCHY",
            label : "Hcc List",
            options : hccSeperator
        },
        {
            name : "Risk_Score",
            label : "Risk Score"
        },
        {
            name : "Metal_Tier",
            label : "Metal Tier"
        },
        {
            name : "Risk_Model",
            label : "Risk Model"
        },
        {
            name : "Gender",
            label : "Gender"
        },
        {
            name : "Plan_ID",
            label : "Plan ID"
        }
    ];

    const memberTableClick = (rowdata, colData, cellMeta) =>{
        if((cellMeta.colIndex === 0)&&(colData.props.children != "")){
            localStorage.setItem(MEMBER_ID,rowdata[cellMeta.rowIndex].MEMBER_ID);
            localStorage.setItem(MEMBER_NAME,rowdata[cellMeta.rowIndex].MEMBER_Name);
            localStorage.setItem(CARRIER_ID,rowdata[cellMeta.rowIndex].Carrier_ID);
            localStorage.setItem(PLAN_ID,rowdata[cellMeta.rowIndex].Plan_ID);
            localStorage.setItem(GENDER,rowdata[cellMeta.rowIndex].Gender);
            history.push('/layout/acaMember', REPORTED_INSIGHTS)
        }
        else if((cellMeta.colIndex === 3)&&(colData.props.children != "")){
            if(typeof(colData.props.children) == "object"){
                localStorage.setItem(HCC,(cellMeta.event.target.innerText).replace(',',''));
            }
            else{
                localStorage.setItem(HCC,rowdata[cellMeta.rowIndex].HCC_POST_HIERARCHY);
            }
            localStorage.setItem(ISSUER_ID,rowdata[cellMeta.rowIndex].ISSUER_ID);
            history.push('/layout/acaReportedHcc')
        }
    };

    return(
        <div>
            <CardComponent
            heading={`List of Active Members under Issuer Id : ${issuer} with HCC : ${hcc} and Risk Score Range of ${rafRange} for Year ${year}`}
            body={
                <ServerSideGridTable
                    url={`/acaRafDrillDownHcc/hcc/${hcc}/rafRange/${rafRange}/year/${year}/issuer_id/${issuer}`}
                    columns={memberCols}
                    tableClick={memberTableClick}
                ></ServerSideGridTable>
            }></CardComponent>
             <br/>
            <br/>     
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(HccDrillDownACA);