import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/layouts/adminStyle.js";
import bgImage from "../../assets/images/sidebar.jpeg";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components

import ScrollToTopAdmin from '../ScrollToTop/ScrollToTopAdmin';
// core components
//import Navbar from "../Navbars/Navbar.js";
//import Footer from  "../Footer/Footer.js"
import AdminSidebar from "../Sidebar/AdminSidebar.js";
//import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import routes from "../../routes/adminRoutes.js";
import logo from "../../assets/images/logo.png";

import GroupPage from '../../Pages/Admin/group.page';
import PolicyPage from '../../Pages/Admin/policy.page';
import RolesPage from '../../Pages/Admin/roles.page';
import UsersPage from '../../Pages/Admin/users.page';
import PermissionPage from '../../Pages/Admin/permission.page';
import MenuPage from '../../Pages/Admin/menu.page';
import ModulePage from '../../Pages/Admin/module.page';
import Header from '../../components/home/header/header';

const useStyles = makeStyles(styles);

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/layout") {
        // // console.log('Paths layout',prop.layout + prop.path);
        return (
          <>
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
            {
              prop.subRoutes && prop.subRoutes.length > 0 ?
                prop.subRoutes.map((subRoute, key1) => {
                  <Route
                    path={subRoute.layout + subRoute.path}
                    component={subRoute.component}
                    key={key1}
                  />
                })
                : ''
            }
          </>
        );
      }
      return null;
    })}
    <Redirect from="/admin" to="/admin/dashboard" />
  </Switch>
);

let ps;
export default function AdminLayout() {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  //const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  
  React.useEffect(() => {
    document.getElementsByTagName("body")[0].classList.add("normal-layout-container")
    document.getElementsByTagName("body")[0].classList.remove("awe-layout-container")
  }, [])

  //console.log("code-material-ui-image", Image);
  const handleImageClick = image => {
    setImage(image);
  };
  // const handleColorClick = color => {
  //   setColor(color);
  // };
  // const handleFixedClick = () => {
  //   if (fixedClasses === "dropdown") {
  //     setFixedClasses("dropdown show");
  //   } else {
  //     setFixedClasses("dropdown");
  //   }
  // };

  const handleDrawerToggle = () => {
    // console.log("code-layout");
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/admin/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const getMenuPermissionCheck = (permissionSlug) => {
    var permissionCheck = false;
    if (permissionSlug && permissionSlug != '') {
      var permissionGets = JSON.parse(localStorage.getItem('controlPermissions'));
      if (permissionGets && permissionGets.length > 0) {
        permissionGets && permissionGets.forEach(elem => {
          if (elem.permission == permissionSlug && elem.status == true) {
            permissionCheck = true;
          }
        });
      }
    }
    return permissionCheck;
  }

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);
  return (
    <div className={"main-layout-background " + classes.wrapper}>
      <Header></Header>
      <div className="router-box">
        <AdminSidebar
          routes={routes}
          logo={logo}
          image={image}
          handleDrawerToggle={handleDrawerToggle}
          open={mobileOpen}
          color={color}
        />
        <div className={classes.mainPanel} ref={mainPanel} id="headerTop">
          <ScrollToTopAdmin>
            <div className={classes.content}>
              <div className={classes.container}>
                {getMenuPermissionCheck('users_3') ?
                  <Route path="/admin/users" component={UsersPage} />
                  :
                  <></>
                }
                <Route path="/admin/roles" component={RolesPage} />
                <Route path="/admin/groups" component={GroupPage} />
                <Route path="/admin/permssions" component={PermissionPage} />
                <Route path="/admin/menus" component={MenuPage} />
                <Route path="/admin/modules" component={ModulePage} />
                <Route path="/admin/policy" component={PolicyPage} />


              </div>
            </div>
          </ScrollToTopAdmin>
          {/*{getRoute() ? <Footer /> : null}
           <FixedPlugin
            handleImageClick={handleImageClick}
            handleColorClick={handleColorClick}
            bgColor={color}
            bgImage={image}
            handleFixedClick={handleFixedClick}
            fixedClasses={fixedClasses}
          /> */}
        </div>
      </div>
    </div>
  );
}
