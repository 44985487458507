import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import GridTable from "../../components/TableGrids/GridTable.table";
import {getAcaYearList, getAcaYear} from '../../assets/constants/yearList';
 import { HCC, ISSUER_ID } from "../../assets/constants/string.constants";
import CardComponent from "../../components/Cards/card";
import { connect } from 'react-redux';

const TetragramHccACA = (props) =>{

    const [tableData1, setTableData1] = useState([]);
    const [tableData2, setTableData2] = useState([]);
    const [year, setYear] = useState(props.yearSelect);
    const [issuer_id, setIssuer] = useState(localStorage.getItem(ISSUER_ID));
    const [hcc, setHcc] = useState(localStorage.getItem(HCC));
    
    const  summaryColumns1=[
        {
            name: "hccCode",
            label: "HCC Code",
           
        },
        {
            name: "description",
            label: "Description",
            
        },
        {
            name: "totalCount",
            label: "# Members"
        },
        {
            name: "changeInCount",
            label: "Probability"
        }
    ];

    
    const  summaryColumns2 =[
        {
            name: "hccCode",
            label: "HCC Code",
           
        },
        {
            name: "description",
            label: "Description",
            
        },
        {
            name: "totalCount",
            label: "# Members"
        },
        {
            name: "changeInCount",
            label: "Probability"
        } 
    ];

    const loadMetricTable1 = () =>{
        api.get('/Fourgram/hcc/'+hcc+'/year/'+year+'/issuer_id/'+issuer_id,)
        .then(res=>{
            setTableData1(res);
        })
        .catch(err=>{
            // console.log(err);
        })
    }

    const loadMetricTable2 = () =>{
        api.get('/FourgramHcc/hcc/'+hcc+'/year/'+year+'/issuer_id/'+issuer_id,)
        .then(res=>{
            setTableData2(res);
        })
        .catch(err=>{
            // console.log(err);
        })
    }


    useEffect(() =>{
        loadMetricTable1();
        loadMetricTable2();      
    },[year]);

    return(
        <div>
            <CardComponent
            heading={`Health Conditions in Focus - Year : ${year}`}
            body={
                <GridTable
                    data={tableData1}
                    columns={summaryColumns1}
                ></GridTable>
            }></CardComponent>
            <br/>
            <CardComponent
            heading={`You may want to focus on the following Health Conditions as well`}
            body={
                <GridTable
                data={tableData2}
                columns={summaryColumns2}
                ></GridTable>
            }></CardComponent>
             <br/>
            <br/>     
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(TetragramHccACA);