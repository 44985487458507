import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import WidgetCard from "../../components/Cards/WidgetCard";
import SingleVerticalBarChart from '../../components/Charts/SingleVerticalBar.Chart';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag, hccSeperator, numberWithCommas } from '../../assets/constants/customRender'
import history from "../../history";
import { FLAG, ISSUER_ID, HCC, WIDGET_TYPES, REPORTED_INSIGHTS, MEMBER_NAME, CARRIER_ID, PLAN_ID, GENDER, MAO_RISK_CONDITION_CHART,MAO_Chart_Review_Member, MAO_MEMBER_SELECT_TAB } from "../../assets/constants/string.constants";
import { percentFormat } from "../../assets/helpers/formats.helpers";
import CardComponent from "../../components/Cards/card";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import SubHeader from '../../components/home/header/subHeader';
import Link from '@material-ui/core/Link';
import { connect } from 'react-redux';
import GridTable from "../../components/TableGrids/GridTable.table";
//import google from "react-google-charts";

const SUReadmissionDetails = (props) => {
    const [readmissionCountTableData, setReadmissionCountTableData] = useState([]);
    const [memberCondSummaryTableData, setMemberCondSummaryTableData] = useState([]);
    const [selectFlag, setSelectFlag] = useState(localStorage.getItem(FLAG));
    
    const  memberMedicareCols =[
        {
            name: "memberid",
            label: "Member ID",
        },
        {
            name: "No_of_Readmissions",
            label: "Number Of Readmissions",
        },
        {
            name: "Amount",
            label: "Impact Amount"
        },        
    ]



    useEffect(() => { 
        setSelectFlag(localStorage.getItem(FLAG))
    },  [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect,
        props.meInitialSelect,
        localStorage.getItem(FLAG)
    ]);

    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Readmission Summary'} selectType={'utilization'}/>
                        </div>
                    </div>
                </div>
                <div className='col-lg-12 pr-0 pl-0'>
                    <div className="list-member mt-4">
                        <CardComponent
                            heading={
                                selectFlag == '1'?
                                    `Inpatient to Inpatient Readmission within 30 days - ${props.yearSelect}`
                                :selectFlag == '2'?
                                    `Inpatient to Inpatient Readmission within 60 days - ${props.yearSelect}`
                                :selectFlag == '3'?
                                    `Inpatient to Emergency Readmission within 30 days - ${props.yearSelect}`
                                :selectFlag == '4'?
                                    `Inpatient to Emergency Readmission within 60 days - ${props.yearSelect}`
                                :
                                    ''
                            }
                            body={
                                    <ServerSideGridTable
                                        url={`/suReadmissionTabledetails/year/${props.yearSelect}/flag/${selectFlag}`}
                                        columns={memberMedicareCols}
                                        // tableClick={memberTableClick}
                                    ></ServerSideGridTable>
                                }>
                        </CardComponent>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br/>

                <br/>
                <br/> 

            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(SUReadmissionDetails);
