import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import {getAcaYear} from '../../assets/constants/yearList';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag, hccSeperator, numberWithCommas } from "../../assets/constants/customRender";
import GridTable from "../../components/TableGrids/GridTable.table";
import SingleHorizontalBar from "../../components/Charts/SingleHorizontalBar.chart";
import history from '../../history';
import { CLAIM_ID, HCC, GENDER,MAO_Chart_Review_Member, WIDGET_TYPES, MAO_MEMBER_SELECT_TAB } from "../../assets/constants/string.constants";
import CardComponent from "../../components/Cards/card";
import SubHeader from '../../components/home/header/subHeader';
import { connect } from 'react-redux';
import BarVerticalHCC from '../../components/GChart/BarVerticalHCC';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import LockOpenIcon from '@material-ui/icons/LockOpen';
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import { getMeInitialSelect} from '../../assets/constants/meInitialList';
//import google from "react-google-charts";
const MaoClaim = (props) => {

    const [hcc, setHcc] = useState(localStorage.getItem(HCC));
    const [CLAIMID] = useState(localStorage.getItem(CLAIM_ID));
    const [HccReportedChartData, setHccReportedChartData] = useState({});  
    const [HccGAPChartData, setHccGAPChartData] = useState({});  
    const [tableData, setTableData] = useState([]);
    const [TopCooccurringHccConditionGapChartData, setTopCooccurringHccConditionGapChartData] = useState([]);
    const [TopCooccurringHccReportedConditionChartData, setTopCooccurringHccReportedConditionChartData] = useState([]);
    const [WidgetDetails, setWidgetDetails] = useState({});
    const [memberIdData, setMemberIdData] = useState(localStorage.getItem(MAO_Chart_Review_Member));  

    let sliderRangeInt = 0;
    let dataIndex = 0;

    const backGround = [
        { backgroundColor: "#AAB7B8" },
        { backgroundColor: "#EB984E" },
        { backgroundColor: "#58D68D" },
        { backgroundColor: "#5DADE2" },
        { backgroundColor: "#AF7AC5" },
        { backgroundColor: "#EC7063" }
    ]

    const WidgetDetailsType = [
        WIDGET_TYPES.LOCKICON,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.PERCENT,
        WIDGET_TYPES.SCORE,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.TRENDING,
        WIDGET_TYPES.PERCENT,
    ]

    const type = WidgetDetailsType;
    const widgetStyles = {
        position: 'absolute',
        left: 0,
        //right: 20,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.15,
        color: "white"
    };

    const sliderSettingsAca = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        adaptiveHeight: true,
        variableWidth: true
    };

    
    const getIcon = (type) => {
        var data = ''
        switch (type) {
            case WIDGET_TYPES.ISSUER:
                data = <AccountBalanceTwoToneIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.MEMBERS:
                data = <PeopleAltIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERCENT:
                data = <ShowChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.SCORE:
                data = <BarChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERSON:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.CHILD:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.ADULT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.INFANT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.REVENUE:
                data = <AttachMoneyIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.COUNT:
                data = <ShoppingCartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.LOCKICON:
                data = <LockOpenIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.TRENDING:
                data = <TrendingUpIcon style={widgetStyles} />
                break;
        }

        return data;
    }


    const  summaryColumns =[
        {
            name: "hccCode",
            label: "HCC Code",
            options: anchorTag
        },
        {
            name: "co_eff",
            label: "HCC Coefficient",
        },
        {
            name: "description",
            label: "Description",
        },
        
    ]

    const summaryTableClick = (colData,cellMeta,data,columns) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(HCC, data[cellMeta.rowIndex].hccCode);
            history.push('/layout/medicare/reported_hcc');
        }
    };

    const loadMetricTable = () =>{
        api.get('/associatedHcc/'+CLAIMID+'/year/'+getAcaYear()+'/period/'+getMeInitialSelect()+'/memberId/'+memberIdData)
        .then(res=>{
            setTableData(res.aaData);
        })
        .catch(err=>{
            // console.log(err);
        })
    }

    const loadIssuerDetailsScore = () => {
        api.get('/claim/'+CLAIMID+'/year/'+getAcaYear()+'/period/'+getMeInitialSelect())
        .then(res => {
            setWidgetDetails({
                'Member Id': res[0].memberId,
                'Claim Type': res[0].type,
                'From Date': res[0].startDate,
                'To Date': res[0].endDate,
                'Provider Id': res[0].prividerId,
                'Eligible For RAF': res[0].isEligible
            });
            sliderRangeInt = 6;
        })
        .catch(err => {
            // console.log(err);
        });
        
    }

    //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    useEffect(() =>{
        loadIssuerDetailsScore();
        loadMetricTable();
    },[      
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect()
    ]);


    return(
        <div className="container level-summary">
            <div className="row ">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'CLAIM ID - '+CLAIMID+' YEAR - '+props.yearSelect+' / '+props.meInitialSelect} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0">
                    <div className="state-level">          
                        {(Object.entries(WidgetDetails).length > 0) ?                     
                            <SlickSlider WidgetDetails={WidgetDetails} getIcon={getIcon} type={type} />
                        :
                            <></>
                        }                       
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <CardComponent
                    heading={`HCC'S ASSOCIATED WITH THIS CLAIM`}
                    body={
                        <GridTable
                            data={tableData}
                            columns={summaryColumns}
                            onTableClick={summaryTableClick}
                        ></GridTable>
                    }></CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br/>

                <br/>
                <br/>    
            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(MaoClaim);
