import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import CardComponent from "../../components/Cards/card";
import GridTable from "../../components/TableGrids/GridTable.table";
import { getAcaYear,getAcaYearList } from "../../assets/constants/yearList";
import { anchorTag} from "../../assets/constants/customRender"; 
import { PROVIDER_ID,MEMBER_ID } from "../../assets/constants/string.constants";
import history from "../../history";
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';

const ReconProviderMemberSummary = (props) =>{


    const [year,setYear] = useState(props.yearSelect);
    const[provider,setProvider] = useState(localStorage.getItem(PROVIDER_ID));
    const[providerName, setProviderName] = useState();
   // const[memeber,setMember]= useState()
    const [tableData, setTableData] = useState([]);
    const[membersData,setMembersData] =useState([]);
    const[summaryData,setSummaryData] =useState([]);
    
    useEffect(()=>{
      //npm  loadMetricTable();
        loadMembersTable();
        loadSummaryTable();
    },[
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect,
        props.meInitialSelect
    ]);
// columns: ,,,,,,PROSPECTIVE_TARGETTED,PROSPECTIVE_CLOSED
	
//
//
const providersColumns = [
         
        
        {
            name : "VERY_HIGH_TARGETTED",
            label : "Very  High Targetted"
        },
        {
            name : "VERY_HIGH_CLOSED",
            label : "Very High Closed"            
            
        },
        {
            name : "HIGH_TARGETTED",
            label : "High Targetted",
            
        },
        {
            name : "HIGH_CLOSED",
            label : "High Closed"
        },
       
        {
            name : "MEDIUM_TARGETTED",
            label : "Medium Targetted"
        },
       
        {
            name : "MEDIUM_CLOSED",
            label : "Medium Closed"
        }
        ,
       
        {
            name : "VERY_LOW_TARGETTED",
            label : "Very Low Targetted"
        }
        ,
       
        {
            name : "VERY_LOW_CLOSED",
            label : "Very Low Closed"
        },
       
        {
            name : "PROSPECTIVE_TARGETTED",
            label : "Prospective Targetted"
        },
       
        {
            name : "PROSPECTIVE_CLOSED",
            label : "Prospective Closed"
        }];

       // {""":"Chart Review Claim","PROVIDER_NUMBER":"1003061797","MEMBER_PART_OF_CHART_CHASE":"Yes","CATEGORY":"VERY_LOW","PROVIDER":"PARKVIEW HEALTH SYSTEM\/PHYSICIAN PRACTICES","YEAR":2020},
//{"MEMBER_ID":"QMXM00000344947","RECOMMENDATION":"R91","HCC":"30",
//"EFFECTIVE_RISK_SCORE_IMPACT":2.13,"GAP_CLOSURE_SOURCE":"Chart Review Claim",
//"PROVIDER_NUMBER":"1003061797","MEMBER_PART_OF_CHART_CHASE":"Yes",
//"CATEGORY":"VERY_LOW","PROVIDER":"PARKVIEW HEALTH SYSTEM\/PHYSICIAN PRACTICES","YEAR":2020},
       const memberColumns = [
        {
            label : "Member ID",
            name:"MEMBER_ID",
            options : anchorTag
        },
        {
            label:"Member Name",
            name:"NAME",
        },
        
        {
            name : "RECOMMENDATION",
            label : "Recommendation"
        },
        {
            name : "HCC",
            label : "HCC"            
            
        },
        {
            name : "EFFECTIVE_RISK_SCORE_IMPACT",
            label : "Effective Risk Score Impact",
            
        },
        {
            name : "CATEGORY",
            label : "Category"
        },
        {
            name : "GAP_CLOSURE_SOURCE",
            label : "Gap Closure Source"
        },
        {
            name : "MEMBER_PART_OF_CHART_CHASE",
            label :"Member Part Of Chart Chase"
        }
        
    
        ];
//[{"PROVIDER_NUMBER":"1003061797","CATEGORY":"MEDIUM","GAP_TARGETTED_CHART_CHASE_SCORE":33.23,"GAP_TARGETTED_PROSPECTIVE_SCORE":0,"CHART_CHASE_GAP_CLOSURE_SCORE":0.66,"PROSPECTIVE_GAP_CLOSURE_SCORE":0.0,"GAP_PENDING_CHARTCHASE_SCORE":32.57,"GAP_PENDING_PROSPECTIVE":0,"YEAR":2020,"PROVIDER":"PARKVIEW HEALTH SYSTEM\/PHYSICIAN PRACTICES"},

const summaryColumns = [
    {
        name : "CATEGORY",
        label :"Category"
    },
    {
        name : "GAP_TARGETTED_CHART_CHASE_SCORE",
        label :"Gap Targetted Chart Chase Score"
    },
    {
        name : "GAP_TARGETTED_PROSPECTIVE_SCORE",
        label :"Gap Targetted Prospective Score"
    },
    {
        name : "PROSPECTIVE_GAP_CLOSURE_SCORE",
        label :"Gap Prospective Closure Score"
    },
    {
        name : "CHART_CHASE_GAP_CLOSURE_SCORE",
        label :"Chart Chase Gap Closure Score"
    },
    
];

        const loadMetricTable = () =>{
            api.get('/getAcaProvidersSummary/'+props.yearSelect+'/'+provider)
            .then(res=>{
                // console.log(res);
                setTableData(res);
               
            })
            .catch(err=>{
                // console.log(err);
            })
        };


        
        const loadSummaryTable = () =>{
            api.get('/getAcaProviderCategorylevel/'+props.yearSelect+'/'+provider)
            .then(res=>{
                // console.log(res);
                setSummaryData(res);
                
            })
            .catch(err=>{
                // console.log(err);
            })
        };
        const Tableclick =(rowdata, cellMeta ) =>{    
            if((cellMeta.colIndex === 0)&&(rowdata.props.children != "")){
              
          localStorage.setItem(MEMBER_ID,rowdata.props.children);
              history.push('/layout/acaReconHCCSummary');
         }
      };
        const loadMembersTable = () =>{
            api.get('/getAcaProvidersMemberList/'+props.yearSelect+"/"+provider)
            .then(res=>{
                // console.log(res);
                setMembersData(res);
                setProviderName(res[0].PROVIDER);
            
            })
            .catch(err=>{
                // console.log(err);
            })
        };
        return(
            <div className="container level-summary">
                <div className="row mb-3">
                    <div className="col-lg-12 pr-0 pl-0 mb-3">
                        <div className="state-level">
                            <div className="card-header">
                                <SubHeader title={'Recon ProviderMember Details'} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 pr-0 pl-0 mb-3">
                        <CardComponent
                        heading={`PROVIDER DETAILS-${provider} : ${providerName}`}
                        body={
                            <GridTable
                                data={summaryData}
                                columns={summaryColumns}
                            // pagination={true}
                                
                            ></GridTable>
                        }></CardComponent>
                    </div>
                    <div className="col-lg-12 pr-0 pl-0 mb-3">
                        <CardComponent
                            heading={"MEMBERS WITH GAPS CLOSED"}
                            body={
                                <GridTable
                                    data={membersData}
                                    columns={memberColumns}
                                    pagination={true}
                                    onTableClick={Tableclick}
                                ></GridTable>
                            }></CardComponent>
                    </div>
                <br/>
                <br/>     
            </div>
        </div>
        );

}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
  export default connect(mapStateToProps)(ReconProviderMemberSummary);
