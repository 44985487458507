import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import WidgetCard from "../../components/Cards/WidgetCard";
import SingleVerticalBarChart from '../../components/Charts/SingleVerticalBar.Chart';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag, numberWithCommasPrefixDolar, addSuffixTwoPercent } from '../../assets/constants/customRender'
import history from "../../history";
import { MEMBER_ID, WIDGET_TYPES, MAO_TYPE, RAF_RANGE, SELECT_DISTRIBUTION, MAO_Chart_Review_Member, MAO_MEMBER_SELECT_TAB } from "../../assets/constants/string.constants";
import { percentFormat } from "../../assets/helpers/formats.helpers";
import CardComponent from "../../components/Cards/card";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import SubHeader from '../../components/home/header/subHeader';
import Link from '@material-ui/core/Link';
import { connect } from 'react-redux';
import GridTable from "../../components/TableGrids/GridTable.table";
import GChart from '../../components/GChart/GChart';
import GChartClick from '../../components/GChart/GChartClick';
import { getMeInitialSelect } from '../../assets/constants/meInitialList';
//import google from "react-google-charts";

const RiskStratification = (props) => {
    const [WidgetDetails, setWidgetDetails] = useState({});
    const [LikelihoodPercentageChartData, setLikelihoodPercentageChartData] = useState({});
    const [LikelihoodCountOfMembersChartData, setLikelihoodCountOfMembersChartData] = useState({});


    const [DistributionSelect, setDistributionSelect] = useState(localStorage.getItem(SELECT_DISTRIBUTION));
    const WidgetDetailsType = [
        WIDGET_TYPES.LOCKICON,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.PERCENT,
        WIDGET_TYPES.SCORE,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.TRENDING,
        WIDGET_TYPES.PERCENT,
    ]

    const type = WidgetDetailsType;
    const widgetStyles = {
        position: 'absolute',
        left: 0,
        //right: 20,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.15,
        color: "white"
    };


    const loadLikelihoodPercentageChart = () => {
        let chartDataRow = [];
        chartDataRow.push(["RAF Score", "Member Count", { role: 'annotation' }]);
        api.get('/predictedrafRangeGraph')
            .then(res => {
                for (const dataObj of res) {
                    chartDataRow.push([dataObj.rafRange, dataObj.countOfMembers, dataObj.countOfMembers]);
                }
                setLikelihoodPercentageChartData(chartDataRow);
            })
            .catch(err => {
                // console.log(err);
            })

    }

    const loadLikelihoodCountOfMembersChart = () => {
        let chartDataRow = [];
        chartDataRow.push(["RAF Score", "Member Count", { role: 'annotation' }, { role: 'tooltip' }]);
        api.get('/predictedDeltaRafRangeGraph')
            .then(res => {
                for (const dataObj of res) {
                    // chartDataRow.push([dataObj.rafRange, dataObj.countOfMembers, dataObj.percent+'%']);
                    chartDataRow.push([dataObj.rafRange, dataObj.countOfMembers, dataObj.countOfMembers, dataObj.countOfMembers]);
                }
                setLikelihoodCountOfMembersChartData(chartDataRow);
            })
            .catch(err => {
                // console.log(err);
            })

    }





    const loadWidgetDetails = () => {
        ///modelPredictionsummary/2019/Diabetes

        api.get('/predictedmembersummary')
            .then(res => {
                setWidgetDetails({
                    '# Members': Number(res[0].totalMembers).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    '# Members without Condition': Number(res[0].potentialMembersWithCondition).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    '# Potential Impact on RAFScore': Number(res[0].potentialImpactOnRAFScore * 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, +' ,') + '%',
                    'Potential Revenue Impact': '$' + Number(res[0].potentialRevenueImpact).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),

                });
            })
            .catch(err => {
                // console.log(err);
            });
    };


    const getIcon = (type) => {
        var data = ''
        switch (type) {
            case WIDGET_TYPES.ISSUER:
                data = <AccountBalanceTwoToneIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.MEMBERS:
                data = <PeopleAltIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERCENT:
                data = <ShowChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.SCORE:
                data = <BarChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERSON:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.CHILD:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.ADULT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.INFANT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.REVENUE:
                data = <AttachMoneyIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.COUNT:
                data = <ShoppingCartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.LOCKICON:
                data = <LockOpenIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.TRENDING:
                data = <TrendingUpIcon style={widgetStyles} />
                break;
        }

        return data;
    }

    const memberMedicareCols = [
        {
            name: "MemberID",
            label: "Member ID",
            options: anchorTag,
        },
        {
            name: "TOTAL_POTENTIAL_RAF_SCORE",
            label: "Potential RAF Score",
            options: addSuffixTwoPercent
        },
        {
            name: "NUMBER_OF_CONDITION_CATEGORIES",
            label: "No of Conditions"
        },

        {
            name: "TOTAL_POTENTIAL_REVENUE",
            label: "Total Revenue Impact",
            options: numberWithCommasPrefixDolar
        },


    ];
    const memberTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(MEMBER_ID, rowdata[cellMeta.rowIndex].MemberID);
            history.push('/layout/medicare/PredictionMemberSummary')
        }
    }



    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            loadLikelihoodCountOfMembersChart();
            loadWidgetDetails();
            loadLikelihoodPercentageChart();
        }
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect(),

    ]);

    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'PREDICTION SUMMARY VIEW'} selectType={'utilization'} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0">
                    <div className="state-level">
                        {(Object.entries(WidgetDetails).length > 0) ?
                            <SlickSlider WidgetDetails={WidgetDetails} getIcon={getIcon} type={type} />
                            :
                            <></>
                        }
                    </div>
                </div>

                <div className="row col-lg-12 mt-4">
                    <div className="col-lg-6">
                        <CardComponent
                            heading={`DELTA RAF SCORE DISTRIBUTION FOR MEMBERS WITH CONDITION`}
                            body={
                                <GChartClick data={LikelihoodCountOfMembersChartData} chartType="ColumnChart" height="300px" width="100%" setStorgeItemName={RAF_RANGE} redirectUrl={'medicare/PredictionRafDeltaDrillDown'} hAxisTitle={"RAF Score"} yAxistitle={"Member Count"}></GChartClick>
                                // <GChart data={LikelihoodCountOfMembersChartData} chartType="ColumnChart" height="300px" width="100%"  hAxisTitle={"Gap Suspect Likelihood"} yAxistitle={"Member Count"}></GChart>
                            }></CardComponent>
                    </div>
                    <div className="col-lg-6">
                        <CardComponent
                            heading={`TOTAL POTENTIAL RAF SCORE DISTRIBUTION FOR MEMBERS WITH CONDITION`}
                            body={
                                <GChartClick data={LikelihoodPercentageChartData} chartType="ColumnChart" height="300px" width="100%" setStorgeItemName={RAF_RANGE} redirectUrl={'medicare/PredictionRafDrillDown'} hAxisTitle={"RAF Score"} yAxistitle={"Member Count"}></GChartClick>
                                // <GChart data={LikelihoodPercentageChartData} chartType="ColumnChart" height="300px" width="100%"  hAxisTitle={"Gap Suspect Likelihood"} yAxistitle={"Percentage (%)"}></GChart>
                            }></CardComponent>
                    </div>

                </div>
                <div className='col-lg-12 pr-0 pl-0'>
                    <div className="list-member mt-4">
                        <CardComponent
                            heading={`MEMBER LIST`}
                            body={
                                <ServerSideGridTable
                                    //Condition/Diabetes/2019?
                                    url={`/predictedMembersList`}
                                    columns={memberMedicareCols}
                                    tableClick={memberTableClick}
                                ></ServerSideGridTable>
                            }>
                        </CardComponent>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br />

                <br />
                <br />

            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default connect(mapStateToProps)(RiskStratification);
