import React, { useEffect, useState } from 'react'
import AweRecordPage from "./Pages/AWERecordPage";
import AweAnalyticsSummaryPage from "./Pages/AweAnalyticsSummaryPage";
import AWVCoderBenchmark from './Pages/coder_workbench/AWVCoderBenchmark';
import { Switch, Route, Redirect } from "react-router-dom";
import AWEDashboard from './Pages/AWEDashboard';
import OperationalDashboard from './Pages/OperationalDashboard';
import QueryLetterSummary from './Pages/QueryLetterSummary';

export default function Index() {

  const getMenuPermissionCheck = (permissionSlug) => {
    var permissionCheck = false;
    if (permissionSlug && permissionSlug != '') {
      var permissionGets = JSON.parse(localStorage.getItem('controlPermissions'));
      if (permissionGets && permissionGets.length > 0) {
        permissionGets && permissionGets.forEach(elem => {
          if (elem.permission == permissionSlug && elem.status == true) {
            permissionCheck = true;
          }
        });
      }
    }
    return permissionCheck;
  }

  return (
    <>
      {getMenuPermissionCheck('awv_analytic_tab_8') ?
        <Route path="/new_awe/awe-analytics-summary" component={AweAnalyticsSummaryPage} />
        :
        <></>
      }

      {getMenuPermissionCheck('awv_member_details_8') ?
        <Route path="/new_awe/awe-record" component={AweRecordPage} />
        :
        <></>
      }

      {getMenuPermissionCheck('awv_member_details_8') ?
        <Route path="/new_awe/awe-coder-workbench" component={AWVCoderBenchmark} />
        :
        <></>
      }
      {/* {getMenuPermissionCheck('awv_member_details_8') ?
        <Route path="/new_awe/awe_reports" component={AWEReportsDashboard} />
        :
        <></>
      } */}
      {getMenuPermissionCheck('awv_member_details_8') ?
        <Route path="/new_awe/awe_dashboard" component={AWEDashboard} />
        :
        <></>
      }
      {getMenuPermissionCheck('awv_member_details_8') ?
        <Route path="/new_awe/operational_dashboard" component={OperationalDashboard} />
        :
        <></>
      }

      <Route path="/new_awe/query_letter_summary" component={QueryLetterSummary} />
    </>
  )
}
