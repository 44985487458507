import PropTypes from "prop-types";
import {SetAcaYear} from '../../assets/constants/yearList';
import { currentYearSelect } from '../../store/actions';
import { connect } from 'react-redux';

const AcaYearSelect = (props) => {

    const { AcaYears, year} = props;
    props.currentYearSelect(year);
    
    const handleSelect = (e) =>{
        SetAcaYear(e.target.value);        
        props.currentYearSelect(e.target.value);
        props.onAcaYearSelect(e.target.value);
    }

    return(
        <div className="d-flex justify-content-end">
            <div className="btn-group">
            <select className="btn btn-info dropdown-toggle btn" value={year} onChange={handleSelect}>
                {
                    AcaYears && AcaYears.map(el => <option value={el} key={el}> {el} </option>)
                }
            </select>
            </div>
        </div>
    )
}

AcaYearSelect.propTypes ={
    AcaYears : PropTypes.array,
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
    };
  }
export default connect(mapStateToProps, {currentYearSelect})(AcaYearSelect);