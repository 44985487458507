import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { setToken } from '../../../assets/constants/serviceOptions';
import { NavLink } from "react-router-dom";
import SnackBar from '../snackbar/snackbar';
import AgreementDialog from '../agreement-popup/agreement';
import adminApi from '../../../assets/constants/AdminRafservice.Instance';
import mainLogo from '../../../assets/images/Invent_logo_White.png';
import clsx from 'clsx';
import UserPool from '../../../assets/constants/user-pool';
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Header from '../../home/header/header';
import { connect } from 'react-redux';
import { signIn, signOut, permssionAssign } from '../../../store/actions';
import history from '../../../history';
import ForgotPassword from '../forgot-password/forgot-password';
import { Auth } from 'aws-amplify';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.inventhealth.com/">
        www.inventhealth.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'white',
    padding: '20px',
    borderRadius: '10px',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textField: {
    width: '100%',
  },
}));

const SignIn = (props) => {
  var userEmail;
  const [snackBarStatus, setSnackBarStatus] = useState({ open: false, message: '' });
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [loginShow, setLoginShow] = useState(true);
  const [showForgot, setShowForgot] = useState(false);
  const [checkBoxValue, setCheckBoxValue] = useState(true);

  const [values, setValues] = React.useState({
    password: '',
    showPassword: false,
  });
  const classes = useStyles();
  const center = {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '50%',
    padding: '10px'
  }

  React.useEffect(() => {
    document.getElementsByTagName("body")[0].classList.add("normal-layout-container")
    document.getElementsByTagName("body")[0].classList.remove("awe-layout-container")
  }, [])

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  async function  signUpWithAuthAmplify (userEmail, password) {
    try {
      const signUpResponse = await Auth.signUp({
        username: userEmail,
        password: password,
      // Perform actions after successful login
    })}
     catch (error) {
      console.log( error);
    }
  
  }

  const signUp = (email, password) => {
    UserPool.signUp(email, password, [], null, (err, data) => {
      if (err) {
        //console.error(err);
        setSnackBarStatus({ open: true, message: `Unable to create user ${err.message}` });
      } else {
        setSnackBarStatus({ open: true, message: "User get created successfully. Please contact adminstrator to confirm." });
        let user = {
          user_id: data.userSub,
          user_name: email,
          email: email,
          password: password,
          status: true
        };
        //Amplify Auth Signup api call
        signUpWithAuthAmplify(email,password )
        addUserToControlDb(user);
      }

    });
  }

  const addUserToControlDb = (data) => {

    adminApi.post('/auth/signup', data)
      .then(res => {
      })
      .catch(e => {
        // console.log(err)
      })

  }

  const getSession = async () =>
    await new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            reject();
          } else {
            resolve(session);
          }
        });
      } else {
        reject();
      }
    });

    async function signInWithAuth(username, password) {
      try {
        await Auth.signIn(username, password);
      } catch (error) {
        console.log('Error signing in:', error);
      }
    }
    
  const getAuthenticated = async (user, authDetails, loginData) => {
    new Promise((resolve, reject) => {
      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          setSnackBarStatus({ open: true, message: "Login is successful..." });
          setLoginSuccess(true);
          props.signIn({ userEmail: userEmail, data: data });
          localStorage.setItem('user', JSON.stringify({ userEmail: userEmail, data: data }));
          // Amplify Auth signin api call
          signInWithAuth(data.idToken.payload.email, loginData.password)
          history.push("/home");
          const accessToken = data.getAccessToken().getJwtToken();
          // // console.log("HERE"+accessToken);
          localStorage.setItem("Token", JSON.stringify(accessToken));
          adminApi.post('/auth/signin', loginData)
            .then(res => {
              localStorage.setItem('controlUser', JSON.stringify(res.data.userDetails));
              localStorage.setItem('controlPermissions', JSON.stringify(res.data.permissions));
              localStorage.setItem('controlAccessToken', res.data.accessToken);
              props.permssionAssign(res.data.permissions);
            })
            .catch(err => {
              // console.log(err)
            })

          return resolve(data);
        },

        onFailure: (err) => {
          console.error("onFailure:", err);
          setSnackBarStatus({ open: true, message: `Unable to authenticate user ! ${err.message}` });
          return reject(err);
        },

        newPasswordRequired: (data) => {
          //   // console.log("newPasswordRequired:", data);
          setSnackBarStatus({ open: true, message: "New Password is required" });
          return resolve(data);
        }
      });
    });
  }



  const login = async (event) => {
    event.preventDefault();
    const email = event.target[0].value;
    const password = event.target[2].value;
    const termAndCondition = event.target[5].checked;
    const newPassword = 'demo1234';

    userEmail = email;
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool
    });

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password
    });

    const loginData = {
      email: email,
      password: password,
    }

    if (email !== '' && password !== '' && termAndCondition === false) {
      setSnackBarStatus({ open: true, message: `Please select terms & condition !` });
    }
    else {

      if (loginShow) {
        let result = await getAuthenticated(user, authDetails, loginData);
        // // console.log(result);
        const user2 = UserPool.getCurrentUser();
        if (user2) {
          user2.getSession((err, session) => {
            if (err) {
            } else {
              //      // console.log('session', session);
            }
          });
        }
      } else {
        signUp(email, password);
      }
      // event.preventDefault();
    }

  }

  const renderButtons = () => {
    return (
      <>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
        >
          {loginShow ? 'Sign In ' : 'Sign Up'}
        </Button>
      </>
    );
  }

  return (
    <div className="App  main-layout-background">
      <Header></Header>
      <div className="router-box">
        <Container component="main" maxWidth="xs">
          <div className="mt-5 pt-5">
            <CssBaseline />
            <div className="heading">
              <Avatar className={classes.avatar} style={{ height: '100px', width: 'auto', padding: '0px 10px 0px 0px', margin: '0px', background: '#21518b' }}>
                <LockOpenIcon style={{ height: '3rem', width: '3rem', }} />
              </Avatar>
              {loginShow ? 'Sign in' : 'Sign up'}
            </div>
            <div>
              <img src={mainLogo} width="200" height="200" alt="" className="logo"></img>
            </div>
            <div className={classes.paper}>
              <AgreementDialog open={loginSuccess} />
              <SnackBar open={snackBarStatus.open} message={snackBarStatus.message} />

              <Typography component="h1" variant="h5">
              </Typography>

              {showForgot ?
                <>
                  <ForgotPassword />
                  <Grid container>
                    <Grid item xs>
                      <Link href="#" variant="body2" onClick={() => setShowForgot(!showForgot)}>
                        {'Back to Login'}
                      </Link>
                    </Grid>
                  </Grid>
                </> :
                <>
                  <form className={classes.form} noValidate onSubmit={(event) => login(event, loginShow)}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      autoFocus
                    />
                    <FormControl className={clsx(classes.textField)} variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">Password * </InputLabel>
                      <OutlinedInput
                        fullWidth
                        required
                        id="outlined-adornment-password"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChange('password')}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        }
                        labelWidth={100}
                      />
                    </FormControl>
                    {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
                    <Grid container>
                      <Grid item xs>
                        <Checkbox value="remember" color="primary" checked={checkBoxValue} onClick={() => setCheckBoxValue(!checkBoxValue)} />
                        <NavLink to={'/terms'}>I agree with terms and conditions</NavLink>
                      </Grid>
                    </Grid>

                    {renderButtons()}
                    <Grid container>
                      <Grid item xs>
                        <Link href="#" variant="body2" onClick={() => setShowForgot(!showForgot)}>
                          {loginShow ? 'Forgot password?' : ''}
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link href="#" variant="body2" onClick={() => { setLoginShow(!loginShow) }}>
                          {loginShow ? "Don't have an account? Sign Up" : "Go back to Sign In"}
                        </Link>
                      </Grid>
                    </Grid>
                  </form>
                </>
              }
            </div>
            <Box mt={8}>
              <Copyright />
            </Box>
          </div>
        </Container>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return { isSignedIn: state.auth.isSignedIn };

}
export default connect(mapStateToProps, { signIn, signOut, permssionAssign })(SignIn);
