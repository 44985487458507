import axios from "axios";
import { useEffect, useState } from "react";
import { anchorTag } from "../../assets/constants/customRender";
import {
  getAcaMetricsIssuer,
  getAcaMetricsIssuerList,
} from "../../assets/constants/issuerList";
import api from "../../assets/constants/Rafservice.Instance";
import {
  DISCREPANCY_REASON,
  MEMBER_ID,
  MEMBER_NAME,
  PLAN_ID,
  REPORTED_INSIGHTS,
  GENDER,
} from "../../assets/constants/string.constants";
import { getAcaYear } from "../../assets/constants/yearList";
import CardComponent from "../../components/Cards/card";
import AcaIssuersSelect from "../../components/DropDowns/AcaIssuersSelect";
import Dropdown from "../../components/DropDowns/dropDown";
import GridTable from "../../components/TableGrids/GridTable.table";
import AcaYearSelect from "../../components/DropDowns/AcaYearSelect";
import { getAcaYearList } from "../../assets/constants/yearList";
import { DetailsSharp } from "@material-ui/icons";
import "../../assets/css/Datatable.css";
import history from "../../history";
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';

const PCPMemberDetailsACA = (props) => {
  const [memberDiscrepancyDetails, setMemberDiscrepancyDetails] = useState([]);
  const [year, setYear] = useState(props.yearSelect);
  const [isLoading, setIsLoading] = useState(true);

  const [yearList, setyearList] = useState(["2021", ...getAcaYearList()]);
  const [ppg, setPpg] = useState(localStorage.getItem("PPG"));
  const [type, setType] = useState(localStorage.getItem("PRType"));
  const [providerId, setProviderId] = useState(
    localStorage.getItem("PROVIDER_ID")
  );
  const [rank, setRank] = useState(localStorage.getItem("MEMCOUNT"));
  const discrepancyCols = [
    {
      name: "MEMBER_ID",
      label: "MEMBER ID",
      options: anchorTag,
    },
    {
      name: "GENDER",
      label: "GENDER",
    },
    {
      name: "AGE",
      label: "AGE",
    },
    {
      name: "PRIMARY_PROVIDER",
      label: "PRIMARY PROVIDER",
    },
    {
      name: "PPG",
      label: "PPG",
    },
    {
      name: "OPPORTUNITY_LOST",
      label: "OPPORTUNITY LOST",
    },
    {
      name: "PROSPECTIVE",
      label: "PROSPECTIVE",
    },
    {
      name: "HCC_POST_HIERARCHY",
      label: "HCC POST HIERARCHY",
    },
    {
      name: "HCC_SCORE",
      label: "HCC SCORE",
    },
    {
      name: "ENROL_DURATION_MONTHS",
      label: "ENROL DURATION MONTHS",
      options: {
        display: false,
      },
    },
    {
      name: "ALLOWED_AMOUNT",
      label: "ALLOWED AMOUNT",
    },
    {
      name: "CHRONIC_CONDITION_HISTORY",
      label: "CHRONIC CONDITION HISTORY",
    },
    {
      name: "GAP_SCORE",
      label: "GAP SCORE",
    },
    {
      name: "ELIGIBE_MEDICAL_CLAIMS",
      label: "ELIGIBE MEDICAL CLAIMS",
    },
    {
      name: "ANY_MEDICAL_CLAIMS",
      label: "ANY MEDICAL CLAIMS",
    },
    {
      name: "PREVENTIVE_VISITS",
      label: "PREVENTIVE VISITS",
    },
    {
      name: "NEWLY_ENROLLED",
      label: "NEWLY ENROLLED",
    },
    {
      name: "BENEFIT_END_DATE",
      label: "BENEFIT END DATE",
    },
    {
      name: "ACTIVE_STATUS",
      label: "ACTIVE STATUS",
    },
    {
      name: "CHASE",
      label: "CHASE",
    },
    {
      name: "VISITED_PCP",
      label: "VISITED PCP",
    },
    {
      name: "ANY_MEDICAL_CLAIMS",
      label: "ANY MEDICAL CLAIMS",
    },
    {
      name: "RANK",
      label: "RANK",
    },
  ];

  useEffect(() => {
    loadPlanList();
  }, [       
    props.moduleSelect,
    props.viewBySelect,
    props.modelSelect,
    props.metalTierSelect,
    props.planSelect,
    props.yearSelect,
    props.meInitialSelect
  ]);

  const memberTableClick = (colData, cellMeta, rowdata) => {
    if (cellMeta.colIndex === 0 && colData.props.children != "") {
      localStorage.setItem(MEMBER_ID, rowdata[cellMeta.dataIndex].MEMBER_ID);
      localStorage.setItem(GENDER, rowdata[cellMeta.dataIndex].Gender);
      history.push("/layout/acaMember", REPORTED_INSIGHTS);
    }
  };

  const loadPlanList = () => {
    let data = [];
    let datappg = [];
    let keys = [];
    let details = {};
    setIsLoading(true);
    api
      .get("/PCPMemberList/" + props.yearSelect)

      .then((res) => {
        // console.log("here in members");
        keys = Object.keys(res);
        if (keys !== undefined) {
          for (const key of keys) {
            details.MEMBER_ID = res[key].MEMBER_ID;
            details.GENDER = res[key].GENDER;
            details.AGE = res[key].AGE;
            details.PRIMARY_PROVIDER = res[key].PRIMARY_PROVIDER;
            details.PPG = res[key].PPG;
            details.OPPORTUNITY_LOST = res[key].OPPORTUNITY_LOST;
            details.PROSPECTIVE = res[key].PROSPECTIVE;
            details.HCC_POST_HIERARCHY = res[key].HCC_POST_HIERARCHY;
            details.HCC_SCORE = res[key].HCC_SCORE;
            details.ENROL_DURATION_MONTHS = res[key].ENROL_DURATION_MONTHS;
            details.ALLOWED_AMOUNT = res[key].ALLOWED_AMOUNT;
            details.CHRONIC_CONDITION_HISTORY =
              res[key].CHRONIC_CONDITION_HISTORY;
            details.GAP_SCORE = res[key].GAP_SCORE;
            details.ELIGIBE_MEDICAL_CLAIMS = res[key].ELIGIBE_MEDICAL_CLAIMS;
            details.PREVENTIVE_VISITS = res[key].PREVENTIVE_VISITS;
            details.NEWLY_ENROLLED = res[key].NEWLY_ENROLLED;
            details.BENEFIT_END_DATE = res[key].BENEFIT_END_DATE;
            details.ACTIVE_STATUS = res[key].ACTIVE_STATUS;
            details.CHASE = res[key].CHASE;
            details.VISITED_PCP = res[key].VISITED_PCP;
            details.ANY_MEDICAL_CLAIMS = res[key].ANY_MEDICAL_CLAIMS;
            details.RANK = res[key].RANK;

            data.push(details);

            if (ppg != null) {
              if (ppg == details.PPG) {
                if (type == "PPG-PROSPECTIVE" && details.PROSPECTIVE == "Yes") {
                  datappg.push(details);
                } else if (
                  type == "PPG-RETROSPECTIVE" &&
                  details.CHASE == "Yes"
                ) {
                  datappg.push(details);
                } else if (
                  type == "PCP-RETROSPECTIVE" &&
                  details.CHASE == "Yes" &&
                  providerId == details.PRIMARY_PROVIDER
                ) {
                  datappg.push(details);
                } else if (
                  type == "PCP-PROSPECTIVE" &&
                  details.PROSPECTIVE == "Yes" &&
                  providerId == details.PRIMARY_PROVIDER
                ) {
                  datappg.push(details);
                } else if (
                  type == "Top 50%" &&
                  rank >= details.RANK &&
                  details.PROSPECTIVE == "Yes"
                ) {
                  datappg.push(details);
                } else if (
                  type == "Top 25%" &&
                  rank >= details.RANK &&
                  details.PROSPECTIVE == "Yes"
                ) {
                  datappg.push(details);
                } else if (
                  type == "Top 90%" &&
                  rank >= details.RANK &&
                  details.PROSPECTIVE == "Yes"
                ) {
                  datappg.push(details);
                }
              }
            }

            details = {};
          }
        }

        setMemberDiscrepancyDetails(datappg);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <div className="container level-summary">
      <div className="row mb-3">
          <div className="col-lg-12 pr-0 pl-0 mb-3">
              <div className="state-level">
                  <div className="card-header">
                      <SubHeader title={'Member Details'} />
                  </div>
              </div>
          </div>
          <div className="col-lg-12 pr-0 pl-0 mb-3">
            <CardComponent
              heading={"MEMBER DETAILS - " + type+ " List of Provider: " + ppg.toUpperCase()}
              additionalClass={`text-transform: none`}
              body={
                <GridTable
                  columns={discrepancyCols}
                  data={memberDiscrepancyDetails}
                  pagination={true}
                  search={true}
                  filter={true}
                  viewColumns={true}
                  download={true}
                  className={"pcp_details_table"}
                  onTableClick={memberTableClick}
                  isLoading={isLoading}
                ></GridTable>
              }
            ></CardComponent>
          </div>
        <br />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
      isSignedIn: state.auth.isSignedIn,
      moduleSelect: state.moduleFilter.moduleSelect,
      viewBySelect: state.moduleFilter.viewBySelect,
      modelSelect: state.moduleFilter.modelSelect,
      metalTierSelect: state.moduleFilter.metalTierSelect,
      planSelect: state.moduleFilter.planSelect,
      yearSelect: state.moduleFilter.yearSelect,        
      meInitialSelect: state.moduleFilter.meInitialSelect,
  };
}
export default connect(mapStateToProps)(PCPMemberDetailsACA);
