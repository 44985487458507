import { useEffect, useState } from "react";
import { anchorTag} from "../../assets/constants/customRender";
import { getAcaMetricsIssuer, getAcaMetricsIssuerList } from "../../assets/constants/issuerList";
import api from "../../assets/constants/Rafservice.Instance";
import { DISCREPANCY_REASON, MEMBER_ID, MEMBER_NAME, PLAN_ID, REPORTED_INSIGHTS,ISSUER_ID } from "../../assets/constants/string.constants";
import { getAcaYear } from "../../assets/constants/yearList";
import CardComponent from "../../components/Cards/card";
import AcaIssuersSelect from "../../components/DropDowns/AcaIssuersSelect"
import Dropdown from "../../components/DropDowns/dropDown";
import GridTable from "../../components/TableGrids/GridTable.table";
import history from "../../history";
import { connect } from 'react-redux';

const RarsdDiscrepancyDetailsACA = (props) =>{

    const [issuer, setIssuer] = useState(localStorage.getItem(ISSUER_ID));
    const [memberDiscrepancyDetails, setmemberDiscrepancyDetails] = useState([]);
    const [claimDiscrepancyDetails, setclaimDiscrepancyDetails] = useState([]);
    const [year] = useState(props.yearSelect);
    const [planList, setPlanList] = useState([]);
    const [modelList] = useState(["ADULT","CHILD","INFANT"]);
    const [planId, setPlanId] = useState(planList[0]);
    const [model, setModel] = useState(modelList[0]);
    const [reson] = useState(localStorage.getItem(DISCREPANCY_REASON));
    const [showClaim, setShowClaim] = useState(false);

    /* Changing name field that are getting from response
    @author Sonal Jain */
    const discrepancyCols = [
        {
            name:"MEMBER_ID",
            label:"Member ID",
            options: anchorTag
        },
        {
            name:"PLAN_ID",
            label:"Plan ID"
        },
        {
            name:"ISSUER_ID",
            label:"ISSUER_ID"
        },
        {
            name:"RISK_MODEL",
            label:"RISK_MODEL"
        },
        {
            name:"METAL_TIER",
            label:"METAL_TIER"
        },
        {
            name:"DISCREPANT_FLAG",
            label:"DISCREPANT_FLAG"
        },
        {
            name:"HCC_DISC",
            label:"HCC_DISC"
        },
        {
            name:"RISK_SCORE_GAP",
            label:"RISK_SCORE_GAP"
        },
        {
            name:"CLAIM_COUNT",
            label:"CLAIM_COUNT"
        }
    ];

    const claimDiscrepancyCols = [
        {
            name:"issuer",
            label:"Issuer"
        },
        {
            name:"plan_id",
            label:"Plan ID"
        },
        {
            name:"model",
            label:"Model"
        },
        {
            name:"member_id",
            label:"Member ID",
            options: anchorTag
        },
        {
            name:"claim_id",
            label:"Claim ID"
        },
        {
            name:"gap_score",
            label:"HCC Score Gap"
        },
        {
            name:"provider_id",
            label:"Billing Provider ID"
        },
        {
            name:"hcc_list",
            label:"HCCs List"
        },
        {
            name:"reason",
            label:"Claim Status"
        }
    ];

    const loadPlanList = () =>{
        api.get('/rasrdDescrpencyPlanList/year/'+year+'/issuer/'+issuer)
        .then(res=>{
                if(res.Plan_ID.length){
                    setPlanList(res.Plan_ID);
                    setPlanId(res.Plan_ID[0]);
                }
            /*
            if(res.includes('errorMessage')){
                setPlanList('');
                setPlanId('');
            }
            else{
                setPlanList(res.Plan_ID);
                setPlanId(res.Plan_ID[0]);
            }*/
            
        })
        .catch(err=>{
            // console.log(err);
        })
    };

    const loadMemberDiscrepancy = () =>{

        api.get('/rasrdDescrpencyList/year/'+year+'/model/'+model+'/issuer/'+issuer+'/plan/'+planId+'/Reason/'+reson)
        .then(res=>{
            /* Commenting existing code
            @author Sonal Jain
            starts from here */

              /*  let data = [];
                  let keys = [] ;
                  let details = {}; */

           /*  keys=Object.keys(res.ISSUER_ID)
            if(keys !== undefined){
                for(const key of keys){
                    details.member_id=res.MEMBER_ID[key];
                    details.plan_id=res.PLAN_ID[key];
                    details.model=res.RISK_MODEL[key];
                    details.gap_score=res.RISK_SCORE_GAP[key];
                    details.claims=res.CLAIM_COUNT[key];
                    details.hcc_list=res.HCC_DISC[key];
                    details.reason=res.DISCREPANT_FLAG[key];
                    data.push(details)
                    details = {};
                }
            }
            setmemberDiscrepancyDetails(data) 
            ends here */

           /*  passing res instead of data */

            setmemberDiscrepancyDetails(res)
        })
        .catch(err=>{
             console.log(err);
        })
    };

    const loadClaimTable = (MemberID) =>{
        let data = [];
        let keys = [];
        let details = {};
        api.get('/rasrdDescrpencyClaimList/year/'+year+'/model/'+model+'/issuer/'+issuer+'/plan/'+planId+'/memberId/'+MemberID)
        .then(res=>{
            // console.log(res);
            keys=Object.keys(res.ISSUER_ID)
            if(keys !== undefined){
                for(const key of keys){
                    details.issuer=res.ISSUER_ID[key];
                    details.plan_id=res.PLAN_ID[key];
                    details.model=res.RISK_MODEL[key];
                    details.member_id=res.MEMBER_ID[key];
                    details.claim_id=res.CLAIM_ID[key];
                    details.gap_score=res.GAP_SCORE[key];
                    details.provider_id=res.PROVIDER_NUMBER[key];
                    details.hcc_list=res.HCC_CLAIM[key];
                    details.reason=res.CLAIM_DISCREPANCY[key];
                    data.push(details)
                    details = {};
                }
                setclaimDiscrepancyDetails(data);
                setShowClaim(true);
            }
        })
        .catch(err=>{
            // console.log(err);
        })
    }

    const memberDiscrepancyTableClick = (e,el) =>{
        if(el.colIndex == 0){
            // console.log(e,el);
            loadClaimTable(e.props.children);
        }
    }

    const claimDiscrepancyTableClick = (e,el) =>{
        if(el.colIndex == 3){
            // console.log(e,el);
            let memberId = e.props.children;
            api.get('/fetchMembername/memberId/'+memberId)
            .then(res=>{
                let name = res.NAME;
                localStorage.setItem(MEMBER_NAME,name);
                localStorage.setItem(PLAN_ID, planId);
				localStorage.setItem(MEMBER_ID,memberId);
				history.push('/layout/acaMember', REPORTED_INSIGHTS)
            })
            .catch(err=>{
                // console.log(err);
            })
        }
    }

    useEffect(()=>{
        loadPlanList();
    },[issuer]);

    useEffect(()=>{
        loadMemberDiscrepancy();
    },[model,planId]);

    return(
        <div>
            <div className="d-flex">
            <AcaIssuersSelect
            AcaIssuers={getAcaMetricsIssuerList()}
            issuer = {issuer}
            onAcaIssuerSelect={((e) =>{setIssuer(e);})}
            ></AcaIssuersSelect>
            <br/>
            <div className="row d-flex justify-content-end mb-3">
                <div className="col-sm-auto">
                    <Dropdown
                    list={planList}
                    value={planId}
                    onSelect={((e) =>{setPlanId(e)})}></Dropdown>
                </div>

                <div className="col-sm-auto">
                    <Dropdown
                    list={modelList}
                    value={model}
                    onSelect={((e) =>{setModel(e)})}></Dropdown>
                    </div>
                    </div>
            </div>
            <CardComponent
            heading={"MEMBER DETAIL DISCREPANCY"}
            body={
                <GridTable
                columns={discrepancyCols}
                data={memberDiscrepancyDetails}
                onTableClick={memberDiscrepancyTableClick}></GridTable>
            }></CardComponent>
            <br/>
            {showClaim && <CardComponent
            heading={"CLAIM DETAIL DISCREPANCY"}
            body={
                <GridTable
                columns={claimDiscrepancyCols}
                data={claimDiscrepancyDetails}
                onTableClick={claimDiscrepancyTableClick}></GridTable>
            }></CardComponent>}
             <br/>
            <br/>     
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(RarsdDiscrepancyDetailsACA);
