import React from 'react'

export default function ColumnLinesIcon({ ...props }) {
    return (
        <svg {...props} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="svgviewer-output 1">
                <path id="Vector" d="M10.2739 18.5H15.2739V5.5H10.2739V18.5ZM4.27393 18.5H9.27393V5.5H4.27393V18.5ZM16.2739 5.5V18.5H21.2739V5.5H16.2739Z" fill="#1A9698" />
            </g>
        </svg>
    )
}