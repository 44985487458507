import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import MUIDataTable from "mui-datatables"
import PropTypes from "prop-types";
import { signOut, currentRoute } from '../../store/actions';
import AWVApi from '../../assets/constants/AWVRafservice.Instance';
import { Button, CircularProgress } from '@material-ui/core';
import {
    createMuiTheme,
    MuiThemeProvider,
} from "@material-ui/core/styles";
import AWVExpandableGridRow from './AWVExpandableGridRow';
import AWVExpandableGridRowSlider from './AWVExpandableGridRowSlider';
import { forEach } from "lodash";

const AWVServerSideGridDataTable = (props) => {

    const { title, data, url, isLoadingGrid, onTableClick, defaultSortCol, defaultSortDir, downloadFileName = false, columns, className, isExpand = true, isExpandSlider = false, subColumns = [] } = props;

    const [rowdata, setRowdata] = useState([]);
    const [isDataGridLoading, setIsDataGridLoading] = useState(false);
    const [count, setCount] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(10);
    const [Page, setPage] = useState(0);
    const [sortColumn, setSortColumn] = useState('');
    const [sortDir, setSortDir] = useState(defaultSortDir ? defaultSortDir : "asc");
    const [sortOrder] = useState({});
    const [col] = useState(defaultSortCol ? defaultSortCol : 0);
    const [Dir] = useState(defaultSortDir ? defaultSortDir : "asc");
    const [Search] = useState(['undefined']);
    const [ColumnSearch, setColumnSearch] = useState("");
    const [TableAfterSearch, setTableAfterSearch] = useState(false);
    const [rowdataCSV, setrowdataCSV] = useState(['Downloading Data...']);
    const [columnName, setColumnName] = useState(['cin']);
    const [isLoading, setIsLoading] = useState(false);

    const [filteredValue, setFilteredValue] = useState([]);
    const [filteredColumns, setFilteredColumns] = useState([]);

    const loadTable = (pageNo, rowsOnPage, col, dir, searchtxt, search, data, ColumnSearch) => {
        // console.log('loadTable data');
        // console.log(pageNo, rowsOnPage, col, dir, searchtxt, search, data, ColumnSearch);
        setIsLoading(true);
        //setRowdata([]);
        let completeUrl = `${url}iSortCol_0=${col}&sSortDir_0=${dir}&iDisplayStart=${pageNo * rowsOnPage}&iDisplayLength=${rowsOnPage}&ColumnSearch=${(search)}`;
        // //Temporary fix for numeric filter values
        completeUrl = completeUrl.replace("PendingUpload", "1");
        completeUrl = completeUrl.replace("pendingforCoordinator", "2");
        completeUrl = completeUrl.replace("pendingforCoder", "3");
        completeUrl = completeUrl.replace("pendingForPaymentApproval", "4");
        completeUrl = completeUrl.replace("ApprovedForPayment", "5");
        completeUrl = completeUrl.replace("PaymentDone", "6");
        completeUrl = completeUrl.replace("void", "7");
        completeUrl = completeUrl.replace("Reject", "8");
        completeUrl = completeUrl.replace("ApprovedNoPayment", "9");
        completeUrl = completeUrl.replaceAll("Yes", "Y");
        completeUrl = completeUrl.replaceAll("No", "N");

        AWVApi.get(completeUrl)
            .then(res => {
                // console.log(res);
                // res.iTotalDisplayRecords = 10;
                // if (res.data && res.data[0] && res.data[0].iTotalRecords && res.data[0].iTotalRecords != 0)
                //     res.iTotalRecords = res.data[0].iTotalRecords;
                // else
                //     res.iTotalRecords = 0;
                if (res.data && res.data[0] && res.data[0].iTotalRecords && res.data[0].iTotalRecords != 0) {
                    setRowdata([]);
                    setCount(res.data[0].iTotalRecords);
                    setRowdata(res.data);
                    setIsLoading(false);
                } else if (res.data && res.data[0] && res.data[0].count && res.data[0].count != 0) {
                    setRowdata([]); 
                    setCount(res.data[0].count);
                    setRowdata(res.data);
                    setIsLoading(false);
                } else {
                    // /*
                    setCount(0);
                    setRowdata([]);
                    setPage(0);
                    setRowPerPage(10);
                    setIsLoading(false);
                    // */
                    // setRowdata([]);
                    // setCount(res.data[0].total_count);
                    // setIsLoading(false);

                }


            })
            .catch(err => {
                setIsLoading(false);
                console.log(err);
            })

    }
    const loadColumn = () => {
        let values = [];
        if (rowdata != null && rowdata.length > 0) {
            rowdata.forEach((element, index) => {
                for (let [key, value] of Object.entries(element)) {
                    if (!values.includes(key)) {
                        values.push(key)
                    }
                }
            });
            values.pop();
            var filteredValues= []
            setColumnName(values)
            columns.forEach(element => {
                if (element.name) {
                    filteredValues.push(element.name)
                }
            });
            setFilteredValue(filteredValues)
          //  alert(filteredValue)
        }
    }

    const downloadTable = (pageNo, rowsOnPage, col, dir, searchtxt, search, data, ColumnSearch) => {
        AWVApi.get(`${url}iSortCol_0=${col}&sSortDir_0=${dir}&iDisplayStart=${pageNo * rowsOnPage}&iDisplayLength=${rowsOnPage}&ColumnSearch=${(search)}`)
            .then(res => {
                setrowdataCSV(res.data);
            })
            .catch(err => {
                console.log(err);
            })

    }

    const ObjectToFindValue = (objectName, keyName) => {
        // console.log('ObjectToFindValue');
        for (let [key, value] of Object.entries(objectName)) {
            if (key === keyName) {
                // console.log(`${key}: ${value}`);
                return value;
            }
        }
    }

    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            loadTable(Page, RowPerPage, columnName[col], Dir, '',ColumnSearch,'',Search);
        }
    }, [url]);

    useEffect(() => {
        loadColumn()
    }, [rowdata]);

    useEffect(() => {
        if (isLoadingGrid && isLoadingGrid == true) {
            // console.log(Page, RowPerPage, columnName[col], Dir, Search, ColumnSearch);
            if (ColumnSearch.length == 0)
                loadTable(Page, RowPerPage, columnName[col], Dir, Search, ColumnSearch);
            else
                loadTable(0, 10, columnName[col], Dir, Search, ColumnSearch);
        }
    }, [isLoadingGrid]);

    useEffect(() => {
        // downloadTable(Page, count, columnName[col], Dir, Search);
    }, [count]);

    // const onTableClick = (e,el,data,columns) =>{
    //          console.log("here inside grid"+ e,el,data,columns);
    //     if(props.onTableClick){
    //         onTableClick(e,el,data,columns);

    //     }        
    // }

    const options = {
        selectableRows: false,
        pagination: props.pagination ? props.pagination : false,
        print: props.print ? props.print : false,
        searchOpen: props.searchOpen ? props.searchOpen : false,
        filter: props.filter ? props.filter : false,
        searchIcon: props.searchIcon ? props.searchIcon : false,
        download: props.download ? props.download : false,
        search: props.search ? props.search : false,
        viewColumns: props.viewColumns ? props.viewColumns : false,
        downloadOptions: {
            filename: 'Download.csv'
        },
        // responsive: "scrollMaxHeight",
        responsive: 'stacked',
        tableBodyHeight: 'auto',
        tableBodyMaxHeight: '900px',
        serverSide: true,
        count: count,
        responsive: 'stacked',
        rowsPerPage: RowPerPage,
        tableBodyHeight: 'auto',
        tableBodyMaxHeight: '900px',
        expandableRows: isExpand,
        textLabels: {
            body: {
                noMatch: isLoading ?
                    <CircularProgress />
                    :
                    'Sorry, there is no matching data to display',
            },
        },
        renderExpandableRow: (rowData, rowMeta) => {
            return (
                <>
                    {isExpandSlider && isExpandSlider == true ?
                        <AWVExpandableGridRowSlider rowData={rowData} subColumns={subColumns} />
                        :
                        props.editActionData && props.colSpan ?
                            <AWVExpandableGridRow rowData={rowData} form_url = {props.form_url} subColumns={subColumns} cinKeyNumber={(props.cinKeyNumber) ? props.cinKeyNumber : 0} editActionData={props.editActionData} colSpan={props.colSpan} />
                            : props.editActionData ?
                                <AWVExpandableGridRow rowData={rowData} subColumns={subColumns} cinKeyNumber={(props.cinKeyNumber) ? props.cinKeyNumber : 0} editActionData={props.editActionData} />
                                :
                                <AWVExpandableGridRow rowData={rowData} subColumns={subColumns} cinKeyNumber={(props.cinKeyNumber) ? props.cinKeyNumber : 0} />
                    }
                </>
            )

        },
        onCellClick: (colData, cellMeta) => {
            //    console.log(colData,cellMeta);
            if (props.onTableClick) {
                onTableClick(colData, cellMeta, data, columns);
            }
        },
        onDownload: (buildHead, buildBody, columns, data) => {
            //  downloadTable(Page, count, columnName[col], Dir, Search);
            let values = [];
            var AllDataArray = []
            let rowArray = [];
            columns.forEach(columnInside => {

                values.push(columnInside.name)
            })
            rowdataCSV.forEach((element, index) => {
                rowArray = [];
                values.forEach((el, i) => {
                    rowArray.push(ObjectToFindValue(element, el));
                });
                AllDataArray.push({ index: index, data: rowArray });
            });
            //data = toArray(filterRowObject)

            return "\uFEFF" + buildHead(columns) + buildBody(AllDataArray);
        },
        onTableChange: (action, tableState) => {
            let filterColumnObject = [];
            let columnArray = [];
            //multiple filter
            if (props.filter == true && action == 'filterChange' && tableState.columns && tableState.filterList) {
                tableState.columns.forEach(element => {
                    if (element.name) {
                        columnArray.push(element.name)
                    }
                });
                //storig columnname for apply button filter change
                filteredValue.push(columnArray)
                if (columnArray && columnArray.length > 0) {
                    tableState.filterList.forEach((element, index) => {
                        if (element && element[0]) {
                            var key = columnArray[index] + '=' + "\"" + element[0] + "\"";
                            //console.log('key ',key)
                            filterColumnObject.push(key)
                        }

                    });
                }
                // console.log("filterColumnObject "+filterColumnObject)
            }
            //filteredValue.push(filterColumnObject)
            // console.log(action);
            switch (action) {
                case "changePage":
                    // setPage(tableState.page);
                    setRowPerPage(tableState.rowsPerPage);
                    // if sorting performed and performed pagination
                    if (tableState.sortOrder.name)
                        loadTable(tableState.page, tableState.rowsPerPage, tableState.sortOrder.name, tableState.sortOrder.direction, Search, filteredColumns.length > 0 ? filteredColumns : '');
                    else
                        loadTable(tableState.page, tableState.rowsPerPage, columnName[col], Dir, Search, filteredColumns.length > 0 ? filteredColumns : '');
                    break;
                case "sort":
                    setPage(tableState.page);
                    setRowPerPage(tableState.rowsPerPage);
                    // set sorting column name and order of sorting
                    setSortColumn(tableState.sortOrder.name)
                    setSortDir(tableState.sortOrder.direction)
                    setColumnSearch(filterColumnObject.length > 0 ? JSON.stringify(filterColumnObject) : ColumnSearch);
                    // loadTable(tableState.page, tableState.rowsPerPage, columnName[tableState.activeColumn], tableState.sortOrder.direction, Search, filteredColumns.length > 0 ? filteredColumns : ColumnSearch);
                    loadTable(tableState.page, tableState.rowsPerPage, tableState.sortOrder.name, tableState.sortOrder.direction, Search, filteredColumns.length > 0 ? filteredColumns : ColumnSearch);
                    break;
                case "changeRowsPerPage":
                    setPage(tableState.page);
                    setRowPerPage(tableState.rowsPerPage);
                    // if sorting performed and performed pagination
                    if (tableState.sortOrder.name) {
                        setColumnSearch(filterColumnObject.length > 0 ? JSON.stringify(filterColumnObject) : ColumnSearch);
                        loadTable(tableState.page, tableState.rowsPerPage, tableState.sortOrder.name, tableState.sortOrder.direction, Search, filteredColumns.length > 0 ? filteredColumns : ColumnSearch);
                    } else {
                        setColumnSearch(filterColumnObject.length > 0 ? JSON.stringify(filterColumnObject) : ColumnSearch);
                        loadTable(tableState.page, tableState.rowsPerPage, columnName[col], Dir, Search, filteredColumns.length > 0 ? filteredColumns : ColumnSearch);
                    }
                    break;
                case "search":
                    setTableAfterSearch(true);
                    setPage(tableState.page);
                    setRowPerPage(tableState.rowsPerPage);
                    setColumnSearch(ColumnSearch);
                    loadTable(tableState.page, tableState.rowsPerPage, columnName[col], Dir, tableState.searchText ? tableState.searchText : Search, ColumnSearch);
                    break;
                /* case "filterChange":

                    //commenting to restrict hit backend api on each letter type
                    setTableAfterSearch(true);
                    setPage(tableState.page);
                    setRowPerPage(tableState.rowsPerPage);
                    var arrayObject = filterColumnObject;
                    setColumnSearch(arrayObject);
                    loadTable(tableState.page, tableState.rowsPerPage, columnName[col], Dir, tableState.searchText ? tableState.searchText : Search, arrayObject.length > 0 ? arrayObject : '');
                    break; */
                case "resetFilters":
                    setTableAfterSearch(true);
                    setPage(tableState.page);
                    setRowPerPage(tableState.rowsPerPage);
                    setColumnSearch('');
                    loadTable(tableState.page, tableState.rowsPerPage, columnName[col], Dir, tableState.searchText ? tableState.searchText : Search, '');
                    break;
            }
        },

        /* Changes to add apply filter button and function on click on that button */
        /*  onFilterOption: (applyNewFilters) => {
             return (
                 <div>
                   <Button variant="contained" onClick={() => handleFilterSubmit(applyNewFilters)}>Apply Filtersss</Button>
                 </div>
               );
           }, */
        // Calling the applyNewFilters parameter applies the selected filters to the table 
        customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
            return (
                <div style={{ marginTop: '40px', float:'right', paddingBottom:'10px'}}>
                    <Button variant="contained" style={{ color: 'white', fontFamily: "'Source Sans Pro', sans-serif", backgroundColor: "rgb(46 147 147)" }} onClick={() => handleFilterSubmit(applyNewFilters)}>Apply Filters</Button>
                </div>
            );
        },
        confirmFilters: true,
        onFilterChipClose: (index, removedFilter, filterList) => {
            let filterColumnObject = [];
            filterList.forEach((element, index) => {
                if (element && element[0]) {
                    var key = filteredValue[index] + '=' + "\"" + element[0] + "\"";
                    //console.log('key ',key)
                    filterColumnObject.push(key)
                    //  filteredColumns.push(key)
                }
            });

            var arrayObject = filterColumnObject;
            setFilteredColumns(filterColumnObject)
            setTableAfterSearch(true);
            setPage(Page);
            setRowPerPage(RowPerPage);
            setColumnSearch(arrayObject);
            if (sortColumn == '') {
                loadTable(0, 10, columnName[col],
                    sortDir, Search, arrayObject.length > 0 ? arrayObject : '');
            } else {
                loadTable(Page, RowPerPage, sortColumn,
                    sortDir, Search, arrayObject.length > 0 ? arrayObject : '');
            }
        }
    }

    const handleFilterSubmit = applyFilters => {
        let filterColumnObject = [];
        let filterList = applyFilters();
        // alert(columnName)
        filterList.forEach((element, index) => {
            if (element && element[0]) {
                //adding this to handle NO and null search
                if(element[0] == 'No') {
                    var key = '('+ filteredValue[index] + '=' + "\"" + element[0] + "\"" + ' OR ' + filteredValue[index] +  ' is NULL)';
                }
                else {
                    if (filteredValue[index] == 'form_delivery_date'){
                        var key = filteredValue[index] + " BETWEEN "  + "\"" + element[0] + "\"" + " AND " + "\"" + element[1] + "\"";
                    } else {
                        var key = filteredValue[index] + '=' + "\"" + element[0] + "\"";
                    }
                }
                filterColumnObject.push(key)
            }
        });
        var arrayObject = filterColumnObject;
        setFilteredColumns(filterColumnObject)
        setTableAfterSearch(true);
        setColumnSearch(arrayObject);
        // loadTable(0,10, columnName[col],
        //     Dir, Search, arrayObject.length > 0 ? arrayObject : '');
        if (sortColumn == '') {
            setPage(0);
            setRowPerPage(10);
            loadTable(0, 10, columnName[col],
                sortDir, Search, arrayObject.length > 0 ? arrayObject : '');
        } else {
            setPage(Page);
            setRowPerPage(RowPerPage);
            loadTable(Page, RowPerPage, sortColumn,
                sortDir, Search, arrayObject.length > 0 ? arrayObject : '');
        }
    }

    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MuiTableCell: {
                    head: {
                        backgroundColor: "#F7F7F7 !important",
                        color: "#202020 !important",
                        fontWeight: "bold !important"
                    },
                    sortOrder: {
                        backgroundColor: "#F7F7F7 !important",
                        color: "#202020 !important",
                        fontWeight: "bold !important"
                    }
                },
                MUIDataTableSelectCell: {
                    headerCell: {
                        backgroundColor: "#F7F7F7 !important",
                        color: "#202020 !important",
                        fontWeight: "bold !important"
                    }
                },
                MUIDataTableHeadCell: {
                    sortActive: {
                        color: "#202020 !important",
                    }
                }
            }
        });

    return (
        <div className={className} style={{ minHeight: "100px" }}>
            {/* {rowdata && rowdata.length >=0 ?
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={title}
                        data={rowdata}
                        columns={columns}
                        options={options}
                        className={"pb-5"}
                    />
                </MuiThemeProvider>
                :
                isLoading ? <div style={{ position: 'absolute', top: '30%', left: '48%' }}>
                    <CircularProgress />
                </div> : <h6 style={{ position: 'absolute', top: '50%', left: '44%' }} className="text-center">No records found!..</h6>
            } */}
            <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title={title}
                    data={rowdata}
                    columns={columns}
                    options={options}
                    className={"pb-5"}
                />
            </MuiThemeProvider>
        </div>
    );

}

AWVServerSideGridDataTable.propTypes = {
    title: PropTypes.array,
    isLoadingGrid: PropTypes.array,
    columns: PropTypes.array,
    url: PropTypes.string,
    tableClick: PropTypes.func,
    defaultSortCol: PropTypes.number,
    defaultSortDir: PropTypes.string,
    subColumns: PropTypes.string,
    isExpandSlider: PropTypes.string,
    isExpand: PropTypes.string
}

export default AWVServerSideGridDataTable;