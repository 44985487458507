import React, { useEffect, useRef, useState } from 'react'
import HomeIcon from '../../icons/HomeIcon';
import { Link } from 'react-router-dom';
import history from '../../../history';
export default function Breadcrumbs(props) {

    useEffect(() => {
        console.log(history?.location?.pathname);
        console.log(history?.location?.pathname == '/new_awe/awe-record');
    }, [history?.location?.pathname]);

    return (
        <div className={`breadcrumb mb-0`}>
            <ul className="flex_item_cs flex-wrap gap4 fw400 text_c979797">
                <li className="flex_item_cs gap4 text_c979797">
                    <span className='icon text-primary'><HomeIcon className="w24" /></span>
                    <Link to={"/home"}>Home</Link>
                    <span>/</span>
                </li>
                {
                    props?.data.map((items, ind) => (
                        items.link ?
                            <li className="flex_item_cs gap4 text_c979797" key={ind}>
                                <Link to={items?.link}>{items?.title}</Link>
                                <span>/</span>
                            </li>
                            : <li className="flex_item_cs gap8 text-primary" key={ind}>
                                <span>{items?.title}</span>
                            </li>
                    ))
                }


            </ul>
        </div>
    )
}
