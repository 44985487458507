import { useState } from "react";
import CardToggle from "./CardToggling";
import PropTypes from "prop-types";
import DownloadFile from '../../Pages/ACA/DownloadFile';
import DownloadMemberRafReport from '../../Pages/ACA/DownloadMemberRafReport';
import DownloadDiagnosis from '../../Pages/ACA/DownloadDiagnosis';
const  CardComponent = (props) =>{

    const{heading,subHeading,body,cardRefreshm, additionalClass,display,name,year, downloadMemberRafReportFile,DiagnosisCode_Button, bucketName,
        keyMemberRafReport, keyDiagnosisReport, keyPPGSummaryReport} = props;

    const [cardToggle, setcardToggle] = useState(true);
    //const [cardRefresh,setCardRefresh] =useState
    
    return(
        <div>
            <div className="card">
                <div className="card-header">
                    <span className="d-flex bd-highlight">
                        <h6 className={(additionalClass?additionalClass:'')}>{heading}</h6>
                        <h6 className="p-2 flex-grow-1">{subHeading}</h6>
                        {DiagnosisCode_Button ?    <DownloadDiagnosis name  = {props.diagnosisReportName} year={props.year}
                         bucketName= {bucketName} bucketKey = {keyDiagnosisReport}>  
                        </DownloadDiagnosis> 
                        :
                        <></>}
                        {downloadMemberRafReportFile ?    <DownloadMemberRafReport name  = {props.memberRafReportName} year={props.year}
                        bucketName= {bucketName} bucketKey = {keyMemberRafReport}>  
                        </DownloadMemberRafReport> 
                        :
                        <></>}
                        {display ?    <DownloadFile name  = {props.ppgSummaryReportName} year={props.year}
                        bucketName= {bucketName} bucketKey = {keyPPGSummaryReport}>  </DownloadFile> 
                        :
                        <></>}
                        <CardToggle 
                        onClick={(e)=>{setcardToggle(e)}}></CardToggle>
                    </span>
                </div>
                {cardToggle&&<div className="card-body">{body}</div>}
            </div>
        </div>
    )
}

CardComponent.propTypes ={
    heading : PropTypes.string,
    body: PropTypes.element,
    subHeading : PropTypes.string,
    additionalClass:  PropTypes.string
}

export default CardComponent;
