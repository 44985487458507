import React, { useRef, useState, useEffect } from "react";
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import adminApi from '../../assets/constants/AdminRafservice.Instance';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import PlusFillIcon from '../../components/icons/PlusFillIcon'
import EditPenIcon from '../../components/icons/EditPenIcon'
import { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../store/actions';
import AWVCoderWorkBench from '../../assets/constants/AWVCoderWorkBenchRafservice.Instance';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import CustomAutocomplete from '../CustomInput/CustomAutocomplete';
import Divider from '@mui/material/Divider';
import Moment from 'moment';
import CancelIcon from '@mui/icons-material/Cancel';
import { CircularProgress } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "../layout/aweLayout/IconButton";
import Button from "../layout/aweLayout/Button";
import CloseIcon from '@material-ui/icons/Close';
import CalendarIcon from "../icons/CalendarIcon";
import hcc_json from "../../Pages/config/HCC_Code.config.json";
import hcc_aca_json from "../../Pages/config/HCC_CODE_ACA.config.json"
import AWVApi from "../../assets/constants/AWVRafservice.Instance";
import { useLocation } from 'react-router-dom';
import {LogFileUploadToS3} from '../../assets/constants/LogFileUploadToS3'

const styles = (theme) => ({
    dialogPaper: {
        maxWidth: '80%',
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const EditCWDXCoderWorkBench = (props) => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const { register, formState: { errors }, trigger, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [formDataList, setFormDataList] = useState([]);
    const [cwdxRemarkList, setCwdxRemarkList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditDataLoading, setIsEditDataLoading] = useState(false);
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
    const [editFormData, setEditFormData] = useState({});
    const [dxRecordData, setDXRecordData] = useState([]);
    const [dxCodeDataArray, setDxCodeDataArray] = useState([]);
    const [dxCodeDataArrayPush, setDxCodeDataArrayPush] = useState([]);
    const [dxCodeData, setDxCodeData] = useState([]);
    const [isdxCodeValue, setIsDxCodeValues] = useState(false);
    const [dxCodeValue, setDxCodeValues] = useState([]);
    const [dxCodeDOSBegin, setDxCodeDOSBegin] = useState();
    // new code
    const [hccValue, setHCCValues] = React.useState([]);
    const [inputValueHCC, setInputValueHCC] = React.useState("");
    const [inputValueHCCDescription, setInputValueHCCDescription] =
        React.useState("");
    const [inputValueDXDescription, setInputValueDXDescription] =
        React.useState("");
    const [dxValue, setDXValues] = React.useState();
    const [previousDXCode, setpreviousDXCode] = useState(editFormData?.DX_CODE);
    const [hccData, setHCCData] = useState([]);
    const [aweId, setAWEId] = useState([]);
    const [cinId, setCinId] = useState('');
    const location = useLocation();
    const [editFormDataMember, setEditFormDataMember] = useState({});
    const [hccV28Data, setHccV28Data] = useState([]);
    const [hccV28DataValue, setHccV28DataValue] = useState([])
    const [hccV28DataLocalStorage, setHccV28DataLocalStorage] = useState()
    const [dataInputs, setDataInputs] = useState([]);
    const [logMessages, setLogMessages] = useState([]); // Array to store log messages
    const writeToLogFile = (message) => {
       setLogMessages(prevLogMessages => [...prevLogMessages, `${new Date().toISOString()} - ${message}`]);
   };
   const [generateLog, setGenerateLog] = useState(false);

    var cin = ''

    useEffect(() => {
        setIsDxCodeValues([]);
        setDxCodeValues([]);
        setValue(`DX_CODE`, '');
        setValue(`HCC_v24`, '');
        setValue(`HCC_V28`, '');
        setValue(`DOS_Begin`, '');
        setValue(`DOS_End`, '');
        setValue(`insufficient_document`, '');
        setValue(`PAGE_NO`, '');
        setValue(`CW_DX_STATUS`, '');
        setValue(`REMARKS`, '');
        setDxCodeDOSBegin('');
        setFormSubmitisLoading(false);

    }, [props?.open]);

    useEffect(() => {

    }, [props?.Id, props.open]);

    useEffect(() => {
        console.log({ errors });
    }, [errors]);

    useEffect(async () => {
        reset();
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        cin = location.state?.cin;
        setCinId(cin);
        setValue('IH_MEMBER_ID', cin);
    }, [props?.open]);

    useEffect(async () => {
        if (cinId) {
            var dataIds = await Array.from(document.querySelectorAll('#procedure_code tbody tr td:first-child a[data-id]')).map(a => parseInt(a.dataset.id));
            await editLoadFormData(cinId, props?.lob, props?.year, dataIds);
        }
    }, [cinId, props?.open]);

    useEffect(() => {
        // Retrieve and parse the data from localStorage
        const storedData = localStorage.getItem('hcc_v28_list');
        if (storedData) {
            setHccV28DataLocalStorage(JSON.parse(storedData));
        }
    }, []);



    useEffect(() => {
        if (editFormData.length > 0) {
            /*
            editFormData.forEach((data, index) => {
                if (props?.Id && data?.ID !== '' && props?.Id === data?.ID) {
                    setValue('PAGE_NO', data?.PAGE_NO);
                    setValue('IH_MEMBER_ID', data?.cin);
                    setValue('DX_CODE', data?.DX_CODE ? (data?.dx_description ? data?.DX_CODE + '-' + data?.dx_description : data?.DX_CODE) : '');
                    // setValue('ENTITY_ID', editFormData?.ENTITY_ID);
                    setValue('ENTITY_TEXT', data?.ENTITY_TEXT);
                    setValue('REMARKS', data?.REMARKS);
                    setValue('CW_DX_STATUS', data?.CW_DX_STATUS);
                    if (data?.DOS_Begin && data?.DOS_Begin != null && data?.DOS_Begin != '') {
                        setValue('DOS_Begin', Moment(data?.DOS_Begin).format("YYYY-MM-DD"));
                        setDxCodeDOSBegin(Moment(data?.DOS_Begin).format("YYYY-MM-DD"));
                    }
                    if (data?.DOS_End && data?.DOS_End != null && data?.DOS_End != '') {
                        setValue('DOS_End', Moment(data?.DOS_End).format("YYYY-MM-DD"));
                    }

                    setDxCodeValues(data?.DX_CODE ? (data?.dx_description ? data?.DX_CODE + '-' + data?.dx_description : data?.DX_CODE) : '');
                    setValue('insufficient_document', data?.insufficient_document);
                    data?.model_1 && setValue('HCC_v24', data?.model_1 ? (data?.model_1_description ? data?.model_1 + '-' + data?.model_1_description : data?.model_1) : '');
                    data?.model_2 && setValue('HCC_V28', data?.model_2 ? (data?.model_2_description ? data?.model_2 + '-' + data?.model_2_description : data?.model_2) : '');
                    setValue('billing_provider', data?.billing_provider);
                    setValue('rendering_provider', data?.rendering_provider);
                    setIsDxCodeValues(true);
                }
            });*/
        }
    }, [editFormData]);

    const notificationRightDrawer = (open, valueId = '') => {
        props.currentAWERightSidebarType({ 'type': 'notification' });
        props.currentAWERightSidebar(open);
    };


    //load DX codes from JSON
    const handleChangeDxCode = (eventValue, index = 0) => {
        writeToLogFile("handleChangeDxCode() call " + eventValue);
        const result = dxCodeDataArray.find((element) => element.icd_code + '-' + element.icd_code_description === eventValue);
        setValue(`DX_CODE[${index}]`, eventValue);
        // console.log('handleChangeDxCode', { eventValue }, { index });
        for (let indexTemp = 0; indexTemp <= index; indexTemp++) {
            setDxCodeValues(prevValues => {
                const updatedValues = [...prevValues]; // Create a copy of the array
                if (indexTemp == index) {
                    updatedValues[index] = eventValue; // Set the value at the specified index
                } if (updatedValues[indexTemp]) {

                } else {
                    updatedValues[indexTemp] = ''; // Set the value at the specified index
                }
                return updatedValues; // Return the updated array
            }, indexTemp);
        }
        if (result) {
            setIsDxCodeValues(true);
            if (result?.hcc_v24_description != '') {
                for (let indexTemp = 0; indexTemp <= index; indexTemp++) {
                    setHCCValues(prevValues => {
                        const updatedValues = [...prevValues]; // Create a copy of the array
                        if (indexTemp == index) {
                            updatedValues[indexTemp] = result?.hcc_v24 + '-' + result?.hcc_v24_description; // Set the value at the specified index
                        } if (updatedValues[indexTemp]) {

                        } else {
                            updatedValues[indexTemp] = ''; // Set the value at the specified index
                        }
                        return updatedValues; // Return the updated array
                    }, indexTemp);
                }
                setValue(`HCC_v24[${index}]`, result?.hcc_v24 + '-' + result?.hcc_v24_description);
                setValue(`HCC_Code[${index}]`, result?.hcc_v24 + '-' + result?.hcc_v24_description);
            } else {
                for (let indexTemp = 0; indexTemp <= index; indexTemp++) {
                    setHCCValues(prevValues => {
                        const updatedValues = [...prevValues]; // Create a copy of the array
                        if (indexTemp == index) {
                            updatedValues[indexTemp] = result?.hcc_v24 && result?.hcc_v24 == 'No HCC' ? '' : result?.hcc_v24; // Set the value at the specified index
                        } if (updatedValues[indexTemp]) {

                        } else {
                            updatedValues[indexTemp] = ''; // Set the value at the specified index
                        }
                        return updatedValues; // Return the updated array
                    }, indexTemp);
                }
                setValue(`HCC_v24[${index}]`, result?.hcc_v24 && result?.hcc_v24 == 'No HCC' ? '' : result?.hcc_v24);
                setValue(`HCC_Code[${index}]`, result?.hcc_v24 && result?.hcc_v24 == 'No HCC' ? '' : result?.hcc_v24);
            }

            if (result?.hcc_v28_description != '') {
                for (let indexTemp = 0; indexTemp <= index; indexTemp++) {
                    setHccV28DataValue(prevValues => {
                        const updatedValues = [...prevValues]; // Create a copy of the array
                        if (indexTemp == index) {
                            updatedValues[indexTemp] = result?.hcc_v28 + '-' + result?.hcc_v28_description; // Set the value at the specified index
                        } if (updatedValues[indexTemp]) {

                        } else {
                            updatedValues[indexTemp] = ''; // Set the value at the specified index
                        }
                        return updatedValues; // Return the updated array
                    }, indexTemp);
                }
                setValue(`HCC_V28[${index}]`, result?.hcc_v28 + '-' + result?.hcc_v28_description);
            } else {


                for (let indexTemp = 0; indexTemp <= index; indexTemp++) {
                    setHccV28DataValue(prevValues => {
                        const updatedValues = [...prevValues]; // Create a copy of the array
                        if (indexTemp == index) {
                            updatedValues[indexTemp] = result?.hcc_v28 && result?.hcc_v28 == 'No HCC' ? '' : result?.hcc_v28; // Set the value at the specified index
                        } if (updatedValues[indexTemp]) {

                        } else {
                            updatedValues[indexTemp] = ''; // Set the value at the specified index
                        }
                        return updatedValues; // Return the updated array
                    }, indexTemp);
                }
                setValue(`HCC_V28[${index}]`, result?.hcc_v28 && result?.hcc_v28 == 'No HCC' ? '' : result?.hcc_v28);
            }

            if (result?.rxhcc_v05_description != '') {
                setValue(`RXHCC_v05[${index}]`, result?.rxhcc_v05 + '-' + result?.rxhcc_v05_description);
            } else {
                setValue(`RXHCC_v05[${index}]`, result?.rxhcc_v05);
            }
            if (result?.rxhcc_v08_description != '') {
                setValue(`RXHCC_v08[${index}]`, result?.rxhcc_v08 + '-' + result?.rxhcc_v08_description);
            } else {
                setValue(`RXHCC_v08[${index}]`, result?.rxhcc_v08);
            }
        }

        for (let indexTemp = 0; indexTemp <= index; indexTemp++) {
            setDxCodeData(prevValues => {
                const updatedValues = [...prevValues]; // Create a copy of the array
                if (indexTemp == index) {
                    updatedValues[indexTemp] = []; // Set the value at the specified index
                } if (updatedValues[indexTemp]) {

                } else {
                    updatedValues[indexTemp] = []; // Set the value at the specified index
                }
                return updatedValues; // Return the updated array
            }, indexTemp);
        }

    }

    const handleInputChangeDxCode = (eventValue, index = 0) => {
        writeToLogFile("handleInputChangeDxCode() call " + eventValue);
        setIsDxCodeValues(false);
        setValue(`DX_CODE[${index}]`, eventValue);
        if (eventValue.length >= 3) {

        } else {
            setDxCodeDataArray([]);
            let valuesArray = [];
            for (let indexTemp = 0; indexTemp <= index; indexTemp++) {
                setDxCodeValues(prevValues => {
                    const updatedValues = [...prevValues]; // Create a copy of the array
                    if (indexTemp == index) {
                        updatedValues[indexTemp] = eventValue; // Set the value at the specified index
                    } else if (updatedValues[indexTemp]) {

                    } else {
                        updatedValues[indexTemp] = '';
                    }
                    return updatedValues; // Return the updated array
                }, indexTemp);
            }

            loadDxCodeData(valuesArray, eventValue, index);
            setIsLoading(false);
            return true;
        }
        setIsLoading(true);
        var data = { 'search': eventValue, 'lob': String(props?.lobSelect) };
        writeToLogFile("API Call: "+ '/data/icd_code'+ data);
        AWVCoderWorkBench.post('/data/icd_code', data)
            .then(res => {
                setDxCodeDataArray(res.data);
                let valuesArray = [];
                res.data.forEach((element, index) => {
                    if (!valuesArray.includes(element.icd_code + '-' + element.icd_code_description)) {
                        valuesArray.push(element.icd_code + '-' + element.icd_code_description);
                    }
                });

                // setValue(`DX_CODE_${index}`, eventValue);
                for (let indexTemp = 0; indexTemp <= index; indexTemp++) {
                    setDxCodeValues(prevValues => {
                        const updatedValues = [...prevValues]; // Create a copy of the array
                        if (indexTemp == index) {
                            updatedValues[indexTemp] = eventValue; // Set the value at the specified index
                        } else if (updatedValues[indexTemp]) {

                        } else {
                            updatedValues[indexTemp] = '';
                        }
                        return updatedValues; // Return the updated array
                    }, indexTemp);
                }
                loadDxCodeData(valuesArray, eventValue, index);
                setIsLoading(false);
            })
            .catch(err => {
                setDxCodeDataArray([]);
                let valuesArray = [];
                // setValue(`DX_CODE_${index}`, eventValue);
                for (let indexTemp = 0; indexTemp <= index; indexTemp++) {
                    setDxCodeValues(prevValues => {
                        const updatedValues = [...prevValues]; // Create a copy of the array
                        if (indexTemp == index) {
                            updatedValues[indexTemp] = eventValue; // Set the value at the specified index
                        } else if (updatedValues[indexTemp]) {

                        } else {
                            updatedValues[indexTemp] = '';
                        }
                        return updatedValues; // Return the updated array
                    }, indexTemp);
                }
                loadDxCodeData(valuesArray, eventValue, index);
                setIsLoading(false);
            })

    }

    const loadDxCodeData = (dxCodeDataArrayPush, codeValue, index = 0) => {
        writeToLogFile("loadDxCodeData()  call");
        let values = [];
        if (dxCodeDataArrayPush && dxCodeDataArrayPush.length > 0 && codeValue) {
            dxCodeDataArrayPush.forEach((element, index) => {
                if (!values.includes(element) && ((element.toLowerCase()).includes(codeValue.toLowerCase()))) {
                    values.push(element);
                }
            });
            writeToLogFile("values: " +values);
            for (let indexTemp = 0; indexTemp <= index; indexTemp++) {
                setDxCodeData(prevValues => {
                    const updatedValues = [...prevValues]; // Create a copy of the array
                    if (indexTemp == index) {
                        updatedValues[indexTemp] = values; // Set the value at the specified index
                    } else if (updatedValues[indexTemp]) {

                    } else {
                        updatedValues[indexTemp] = [];
                    }
                    return updatedValues; // Return the updated array
                }, indexTemp);
            }

        } else {
            for (let indexTemp = 0; indexTemp <= index; indexTemp++) {
                setDxCodeData(prevValues => {
                    const updatedValues = [...prevValues]; // Create a copy of the array
                    if (indexTemp == index) {
                        updatedValues[indexTemp] = values; // Set the value at the specified index
                    } else if (updatedValues[indexTemp]) {

                    } else {
                        updatedValues[indexTemp] = [];
                    }
                    return updatedValues; // Return the updated array
                }, indexTemp);
            }
        }
    };

    const editLoadFormData = (cin, lob, year, dataIds) => {
        writeToLogFile("awv_backend_id before encryption - ", props.awv_backend_id)
        var cin = btoa(cin)
        var awe_display_id = btoa(props.awe_display_id)
        var awv_backend_id = btoa(props.awv_backend_id)
        writeToLogFile("editLoadFormData() call ");
        setIsEditDataLoading(true);
        var url = '/get_member_hcc_details?cin=' + cin + '&lob=' + props.lobSelect + '&year=' + props?.yearSelect + '&organisation_id=1' + '&chart_record=' + props.chart_chase + '&awe_display_id=' + awe_display_id + '&awv_backend_id=' + awv_backend_id
        writeToLogFile("API call: " + url);
        AWVApi.get(url)
            .then(async (res) => {
                if (res.data && res.data) {
                    localStorage.setItem("allCWDXData", JSON.stringify(res.data));
                    //const filteredData = await res.data.filter(object => dataIds.includes(Number(object.id)));
                    const filteredData = await res.data.filter(object => dataIds.includes(Number(object.id)));
                    console.log(filteredData);
                    setEditFormData(filteredData);
                    setDataInputs(filteredData);
                    var dxCodeArray = [];
                    var dxCodeDataArray = [];
                    filteredData.map((item, index) => {
                        setValue(`id[${index}]`, item?.id);
                        setValue(`DX_CODE[${index}]`, item?.dx_description === '' ? item?.dx_code : item?.dx_code + '-' + item?.dx_description);
                        setValue(`HCC_v24[${index}]`, item?.model_1_description === '' ? item?.model_1 : item?.model_1 + '-' + item?.model_1_description);
                        setValue(`HCC_Code[${index}]`, item?.model_1_description === '' ? item?.model_1 : item?.model_1 + '-' + item?.model_1_description);
                        //setValue(`HCC_Code_${index}`, result?.hcc_v24 && result?.hcc_v24 == 'No HCC' ? '' : result?.hcc_v24);

                        setValue(`HCC_V28[${index}]`, item?.model_2_description === '' ? item?.model_2 : item?.model_2 + '-' + item?.model_2_description);
                        setValue(`DOS_Begin[${index}]`, Moment(item?.dos_begin).format("YYYY-MM-DD"));
                        setValue(`DOS_End[${index}]`, Moment(item?.dos_end).format("YYYY-MM-DD"));
                        setValue(`insufficient_document[${index}]`, item?.insufficient_document);
                        setValue(`PAGE_NO[${index}]`, item?.page_no);
                        setValue(`CW_DX_STATUS[${index}]`, item?.status);
                        setValue(`REMARKS[${index}]`, item?.remarks);
                        dxCodeArray.push(item?.dx_description === '' ? item?.dx_code : item?.dx_code + '-' + item?.dx_description);
                        setValue(`historic_id[${index}]`, item?.historic_id);
                        var newInputsTest = [item?.dx_description === '' ? item?.dx_code : item?.dx_code + '-' + item?.dx_description];
                        dxCodeDataArray.push(newInputsTest);
                    });
                    writeToLogFile("Error in retrieving data Response..." + dxCodeArray);
                    setDxCodeValues(dxCodeArray);
                    // setDxCodeData(dxCodeDataArray);
                    setIsEditDataLoading(false);
                } else {
                    setEditFormData();
                    setIsEditDataLoading(false);
                    writeToLogFile("Error in retrieving data...response " + res );
                }
            })
            .catch(err => {
                console.log(err)
                setIsEditDataLoading(false);
                writeToLogFile("Error in retrieving data... " + err );
            })
    }

    const dateConvert = (timestamp) => {
        // Create a new Date object using the timestamp
        var date = new Date(timestamp);

        // Get the individual components of the date
        var year = date.getFullYear();
        var month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        var day = date.getDate().toString().padStart(2, '0');

        // Form the "yyyy-mm-dd" format
        var formattedDate = year + '-' + month + '-' + day;
        return formattedDate;

    }

    const onSubmit = (data) => {
        writeToLogFile("onSubmit() call ");
        var previousData = []
        dataInputs.map((item, index) => {
            const historyData = {
                "previous_source_code": item?.dx_description === '' ? item?.dx_code : item?.dx_code + '-' + item?.dx_description,
                "previous_model_1": item?.model_1_description === '' ? item?.model_1 : item?.model_1 + '-' + item?.model_1_description,
                "previous_model_2": item?.model_2_description === '' ? item?.model_2 : item?.model_2 + '-' + item?.model_2_description,
                "previous_DOS_Begin": Moment(item?.dos_begin).format("YYYY-MM-DD"),
                "previous_DOS_End": Moment(item?.dos_end).format("YYYY-MM-DD"),
                "previous_insufficient_document": item?.insufficient_document,
                "previous_status": item?.status,
                "RXHCC_v05": item?.rxhcc_v05,
                "RXCHCC_v08": item?.rxhcc_v08,
                "previous_insufficient_document": item?.insufficient_document,
                "cin": btoa(item?.cin),
                "lob": String(props.lobSelect),
                "organisation_id": "1",
                "project_id": '',
                'year': String(props?.yearSelect),
                "current_dx_code": data.DX_CODE ? data.DX_CODE : '',
                "is_nlp": item?.nlp_id && item?.nlp_id != '' ? 'Y' : '',
                "is_historic": item?.historic_id && item?.historic_id != '' ? 'Y' : '',
                'log_api': process.env.REACT_APP_LOG_URL,
                'awv_backend_id': btoa(String(props?.awv_backend_id)),
                'awe_display_id': btoa(props.awe_display_id)
            }

            previousData.push(historyData)
            writeToLogFile("History Data: " + JSON.stringify(historyData));
        });

        const historyApiUrl = "/create-historic-npl-dx-data";
        const hccChecklistApiUrl = props.aweRightSidebarType?.project_id && props.aweRightSidebarType?.project_id == '6' ? "/create-chart-chase-hcc-checklist-remark" : "/create-hcc-checklist-remark";
        const updateMemberHccApiUrl = props.aweRightSidebarType?.project_id && props.aweRightSidebarType?.project_id == '6' ? "/update-chart-chase-member-dx-details" : "/update-member-hcc-details";
        const saveApiUrl = `data/cw_dx/update`;
        const controlUser = JSON.parse(localStorage.getItem('user'));
        const controlUserId = JSON.parse(localStorage.getItem("controlUser"))?.user_id;
        data.LAST_UPDATED_BY = controlUser?.userEmail;
        let history = {}

        var totalLength = dataInputs.length;
        for (let index = 0; index <= totalLength; index++) {
            if (data[`DX_CODE[${index}]`] && data[`DX_CODE[${index}]`] != '' && data[`DX_CODE[${index}]`] != 'undefined' && data[`DX_CODE[${index}]`].length > 8 && data[`DX_CODE[${index}]`].includes("-")) {

            } else {
                if (data[`DX_CODE[${index}]`] == '' || data[`DX_CODE[${index}]`] === undefined) {
                    data[`DX_CODE[${index}]`] = '';
                } else {
                    toast.error(data[`DX_CODE[${index}]`] + ' DX CODE is Invalid. Please select');
                    return true;
                }
            }
        }

        // Helper function to format date
        const formatDate = date => Moment(date).format("YYYY-MM-DD");

        // Common reset function for form values
        const resetFormValues = () => {
            const fieldsToReset = ['ID', 'PAGE_NO', 'IH_MEMBER_ID', 'DX_CODE', 'ENTITY_ID', 'ENTITY_TEXT', 'REMARKS', 'LAST_UPDATED_ON', 'LAST_UPDATED_BY',
                'DOS_Begin', 'DOS_End', 'HCC_v24', 'HCC_V28', 'insufficient_document'];
            fieldsToReset.forEach(field => setValue(field, ''));
            setFormSubmitisLoading(false);
        };

        ///Validate the xisting data 
        let allCWDXData = JSON.parse(localStorage.getItem("allCWDXData") || "[]");


        let length = data.DX_CODE.length;
        for (let i = 0; i < length; i++) {
            if (data.DOS_Begin[i] === "Invalid date" || data.DOS_End[i] === "Invalid date") {
                toast.error('Please enter valide dates for DOS START and DOS END.');
                return;
            }

            let dxCode = data.DX_CODE[i];

            let dosBegin = data.DOS_Begin[i].replaceAll("-", "/");
            let id = data.id[i];
            let historicId = data.historic_id[i];
            const filteredData = allCWDXData.filter(obj =>
                obj.historic_id !== historicId.toString() &&
                obj.id !== id.toString() &&
                obj.dx_code === dxCode &&
                obj.dos_begin === dosBegin
            );
            if (filteredData.length > 0) {
                toast.error(dxCode + ' already added for the DOS ' + dosBegin);
                return;
            }
        }

       // Function to handle API calls
        const handleApiCall = (api, url, method, data, successMessage, errorMessage) => {
            setFormSubmitisLoading(true);
            writeToLogFile("handleApiCall() "+ "api, url, method, data, successMessage, errorMessage" + api, url, method, data, successMessage, errorMessage);
            api[method](url, data)
                .then(res => {
                if(url != "/create-historic-npl-dx-data") {
                
                    setFormSubmitisLoading(false);
                    resetFormValues();
                    if (successMessage != '')
                        toast.success(successMessage);

                    props.handleClose('edit')
                    props.currentAWERightSidebarCloseDatatableReload(true);
                    notificationRightDrawer(false);
                }

                })
                .catch(err => {
                    setFormSubmitisLoading(false);
                    if (errorMessage != '') {
                        toast.error(err?.response?.data?.message);
                        return;
                    }
                    
                });
        };


        handleApiCall(AWVApi, historyApiUrl, 'post', previousData, '', '');
        // Prepare data for save
        const saveData = {
            "id": data.id,
            "CREATED_BY": controlUser?.userEmail,
            "DOS_Begin": data.DOS_Begin,
            "DOS_End": data.DOS_End,
            "RXHCC_v05": data.RXHCC_v05,
            "RXHCC_v08": data.RXHCC_v08,
            "lob": props.lobSelect,
            "organisation_id": '1',
            "year": props.yearSelect,
            "insufficient_document": data.insufficient_document,
            "HCC_v24": data.HCC_v24,
            "HCC_V28": data.HCC_V28,
            "IH_MEMBER_ID": btoa(data.IH_MEMBER_ID),
            "DX_CODE": data.DX_CODE,
            "CW_DX_STATUS": data.CW_DX_STATUS,
            "REMARKS": data.REMARKS,
            "LAST_UPDATED_BY": controlUser?.userEmail,
            "PAGE_NO": data.PAGE_NO,
            "historic_id": data.historic_id,
            "project_id": props.aweRightSidebarType?.project_id ? props.aweRightSidebarType?.project_id : null,
            "awv_backend_id": btoa(String(props?.awv_backend_id)),
            "is_present_coder_workbench": props?.is_cw_present,
            "CW_ID": props?.cw_id,
            'log_api': process.env.REACT_APP_LOG_URL

        };
        // console.log('saveData', saveData);
        // return true;
        writeToLogFile("saveData:  "+ JSON.stringify(saveData)) 
        handleApiCall(AWVCoderWorkBench, saveApiUrl, 'post', saveData, 'Updated successfully', 'Error while update the record');
        setGenerateLog(true)
    }

    const handleChangeDate = (event, index = 0) => {
        handleManualValidation()
        if (event?.target?.name == `DOS_Begin[${index}]`) {
            setValue(`DOS_Begin[${index}]`, event?.target?.value);
            setValue(`DOS_End[${index}]`, event?.target?.value);
            // handleManualValidation();

        } else {
            setValue(`DOS_End[${index}]`, event?.target?.value);
            // handleManualValidation();
        }
    }

    //load HCC codes from JSON hcc_json->Medicare || hcc_aca_json -> ACA
    const loadHCCData = (value) => {
        let values = [];
        setHCCValues(value);
        setValue("HCC_v24", value);
        if (props.lobSelect == 2 && hcc_json && hcc_json.length > 0 && value != '') {
            values = hcc_json.filter(element =>
                (element.hcc_code.toLowerCase()).includes(value.toLowerCase())
            ).map(element => element.hcc_code + '-' + element.hcc_description);
            setHCCData(values);
        } else if (hcc_aca_json && hcc_aca_json.length > 0 && value != '') {
            values = hcc_aca_json.filter(element =>
                (element.hcc_code.toLowerCase()).includes(value.toLowerCase())
            ).map(element => element.hcc_code + '-' + element.hcc_description);
            setHCCData(values);
        } else {
            setHCCData(values);
        }
    };

    const loadHCCV28Data = (value) => {
        let values = [];
        setHccV28DataValue(value);
        setValue("HCC_V28", value);

        values = hccV28DataLocalStorage ? hccV28DataLocalStorage.filter(element =>
            (element.hcc_v28).includes(value)
        ).map(element => element.hcc_v28) : '';

        setHccV28Data(values);
    };

    const loadHCCDataSelected = (value) => {
        let values = [];
        setHCCValues(value);
        setHCCData(values);
        setValue("HCC_v24", value);
    };

    const loadHCC_V28DataSelected = (value) => {
        debugger
        setHccV28DataValue(value)
        setHccV28Data([])
        setValue("HCC_V28", value);
    };


    const handleManualValidation = async () => {
        try {
            // Trigger manual revalidation for all fields
            await trigger();
        } catch (error) {
            //console.error('Manual validation error:', error);
        }
    };

    const handleAddInput = () => {
        setDataInputs([...dataInputs, '']);
    };

    const handleRemoveInput = (index) => {
        // console.log('handleRemoveInput before', { index }, { dataInputs }, getValues())
        const newInputsTest = [...dxCodeData];
        newInputsTest.splice(index, 1);
        setDxCodeData(newInputsTest);

        const newInputsTest1 = [...dxCodeValue];
        newInputsTest1.splice(index, 1);
        setDxCodeValues(newInputsTest1);

        setValue(`DX_CODE${index}`, '');
        setValue(`HCC_v24${index}`, '');
        setValue(`HCC_V28${index}`, '');

        const newInputs = [...dataInputs];
        newInputs.splice((index - 1), 1);
        setDataInputs(newInputs);
        // setDxCodeValues
        // const [dxCodeData, setDxCodeData] = useState([]);
        // const [dxCodeValue, setDxCodeValues] = useState([]);
    };
     // Regular expression pattern to allow only alphanumeric characters and spaces
     const numericPattern = /^[0-9]*$/;

    useEffect(() => {
        // Generate and download log file when logMessages have been updated
        if (logMessages.length > 0  && generateLog === true) {
        LogFileUploadToS3(logMessages,  localStorage.getItem("access_key"), localStorage.getItem("secret_key"), process.env.REACT_APP_LOG_BUCKET, "Multi_DX_Code_Edit")
        }
    }, [generateLog === true, logMessages, cinId,  props?.yearSelect, props.lobSelect, props.awe_display_id]); 
  
    return (
        <div>
            <div className='d-flex flex-row-reverse mr-3'>
                <div>
                    {/* <Tooltip title="Add Menu"> */}
                    {/* <Button type="button" alignItems="center" color="primary" onClick={() => props.handleClickOpen('')}>
                            <AddCircleOutlineIcon style={{ 'fontSize': '30px' }} />
                        </Button> */}
                    <IconButton type="button" onClick={() => props.handleClickOpen('edit')}>
                        <EditPenIcon className="w16" />
                    </IconButton>
                    {/* </Tooltip> */}
                </div>
            </div>
            <MuiDialog
                open={props.open}
                onClose={() => props.handleClose('edit')}
                fullWidth={true}
                maxWidth={'xl'}
            >
                <DialogTitle ID="create-group" onClose={() => props.handleClose('edit')}>
                    {props.editMenuData && props.editMenuData.ID && props.editMenuData.ID != '' ?
                        'Update Menu'
                        :
                        'Edit Bulk DX Code - ' + getValues('IH_MEMBER_ID')
                    }
                </DialogTitle>
                <div className="offcanvas-body pt-1 custom-scrollbar overflow-auto">
                    <form onSubmit={handleSubmit(onSubmit)} ID="add-verification-model">
                        <DialogContent >
                            <div className={'mui_custom_table'} style={{ minHeight: "100px" }}>
                                <div class="table-responsive custom-scrollbar">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">
                                                    Dx CODE
                                                </th>
                                                {props.lobSelect == '2' && props.aweRightSidebarType?.project_id != '6' ?
                                                    <>
                                                        <th scope="col">
                                                            HCC V24
                                                        </th>
                                                        <th scope="col">
                                                            HCC V28
                                                        </th>
                                                    </>
                                                    :
                                                    <th scope="col">
                                                        HCC
                                                    </th>
                                                }
                                                <th scope="col">
                                                    DOS START
                                                </th>
                                                <th scope="col">
                                                    DOS END
                                                </th>
                                                <th scope="col">
                                                    INSUFFICIENT DOCUMENTATION
                                                </th>
                                                <th scope="col">
                                                    PAGE NO
                                                </th>
                                                <th scope="col">
                                                    Status
                                                </th>
                                                <th scope="col">
                                                    REMARKS
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dataInputs && isEditDataLoading ?
                                                <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                                                    <CircularProgress />
                                                </div>
                                                : dataInputs && dataInputs.length > 0 && (getValues('id') && getValues('id').length > 0) ?
                                                    dataInputs.map((data, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="form-group mb20 custom-autocomplete">
                                                                    {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Dx CODE<span style={{ color: 'red' }}> *</span></label> */}
                                                                    <Controller
                                                                        name={`DX_CODE[${index}]`}
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <CustomAutocomplete
                                                                                {...field}
                                                                                id={`DX_CODE[${index}]`}
                                                                                index={index}
                                                                                value={(dxCodeValue && dxCodeValue.length > 0) ? dxCodeValue[index] : ''}
                                                                                onChange={(event) => handleInputChangeDxCode(event?.target?.value, index)}
                                                                                handleOnClick={(event) => handleChangeDxCode(event, index)}
                                                                                options={(dxCodeData && dxCodeData.length > 0) ? dxCodeData[index] : []}
                                                                                optionTitle={'title'}
                                                                                optionValue={'value'}
                                                                                optionListCustome={true}
                                                                                editDataValue={true}
                                                                                placeholder="Type to search..."
                                                                                autoComplete="off"
                                                                                className="form-control"
                                                                                ModelOpen={props?.open}
                                                                            />
                                                                        )}
                                                                        rules={{
                                                                            required: true,
                                                                        }}
                                                                    />
                                                                    {errors && errors?.DX_CODE?.[index]?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                </div>
                                                            </td>
                                                            {props.lobSelect == '2' && props.aweRightSidebarType?.project_id != '6' ? (
                                                                <>
                                                                    <td>
                                                                        <div className="form-group mb20 custom-autocomplete">
                                                                            {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">HCC V24 </label> */}
                                                                            <Controller
                                                                                name={`HCC_v24[${index}]`}
                                                                                control={control}
                                                                                render={({ field }) => (
                                                                                    <input
                                                                                        value={data?.HCC_v24}
                                                                                        {...field}
                                                                                        type="text"
                                                                                        placeholder="Type to search..."
                                                                                        autoComplete="off"
                                                                                        className="form-control"
                                                                                        readOnly={true}
                                                                                    />
                                                                                )}
                                                                                rules={{
                                                                                    required: false,
                                                                                }}
                                                                            />
                                                                            {errors && errors?.HCC_v24?.[index]?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group mb20 custom-autocomplete">
                                                                            {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">HCC V28 </label> */}
                                                                            <Controller
                                                                                name={`HCC_V28[${index}]`}
                                                                                control={control}
                                                                                render={({ field }) => (
                                                                                    <input
                                                                                        value={data?.HCC_V28}
                                                                                        {...field}
                                                                                        type="text"
                                                                                        placeholder="Type to search..."
                                                                                        autoComplete="off"
                                                                                        className="form-control"
                                                                                        readOnly={true}
                                                                                    />
                                                                                )
                                                                                }
                                                                                rules={{
                                                                                    required: false,
                                                                                }}
                                                                            />
                                                                            {errors && errors?.HCC_V28?.[index]?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                        </div>
                                                                    </td>
                                                                </>)
                                                                :
                                                                <td>
                                                                    <div className="form-group mb20 custom-autocomplete">
                                                                        {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">HCC V28 </label> */}
                                                                        <Controller
                                                                            name={`HCC_Code[${index}]`}
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <input
                                                                                    value={data?.HCC_Code}
                                                                                    {...field}
                                                                                    type="text"
                                                                                    placeholder="Type to search..."
                                                                                    autoComplete="off"
                                                                                    className="form-control"
                                                                                    readOnly={true}
                                                                                />
                                                                            )
                                                                            }
                                                                            rules={{
                                                                                required: false,
                                                                            }}
                                                                        />
                                                                        {errors && errors?.HCC_Code?.[index]?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                    </div>
                                                                </td>
                                                            }
                                                            <td>
                                                                <div className="form-group mb20">
                                                                    {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">DOS START <span style={{ color: 'red' }}> *</span></label> */}
                                                                    <Controller
                                                                        name={`DOS_Begin[${index}]`}
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <input type="date" className="form-control" {...field} onChange={(event) => handleChangeDate(event, index)} />
                                                                        )}
                                                                        rules={{
                                                                            required: true,
                                                                        }}
                                                                    />
                                                                    {errors && errors?.DOS_Begin?.[index]?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="form-group mb20">
                                                                    {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">DOS END <span style={{ color: 'red' }}> *</span></label> */}
                                                                    <Controller
                                                                        name={`DOS_End[${index}]`}
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <input type="date"  {...field} className="form-control" min={Moment(dxCodeDOSBegin).format("YYYY-MM-DD")} onChange={(event) => handleChangeDate(event, index)} />
                                                                        )}
                                                                        rules={{
                                                                            required: true,
                                                                        }}
                                                                    />
                                                                    {errors && errors?.DOS_End?.[index]?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="form-group mb20">
                                                                    {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">INSUFFICIENT DOCUMENTATION</label> */}
                                                                    <div className="flex_item_cs gap8">
                                                                        <Controller
                                                                            name={`insufficient_document[${index}]`}
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <select
                                                                                    value={data?.insufficient_document}
                                                                                    {...field}
                                                                                    className="form-control"
                                                                                    onClick={(e) => setValue(`insufficient_document[${index}]`, e.target.value)}
                                                                                >
                                                                                    <option value={''} >
                                                                                        Select
                                                                                    </option>
                                                                                    <option value={'1'} >
                                                                                        Yes
                                                                                    </option>
                                                                                    <option value={'0'} >
                                                                                        No
                                                                                    </option>
                                                                                </select>
                                                                            )}
                                                                            rules={{
                                                                                required: false,
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    {errors && errors?.insufficient_document?.[index]?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="form-group mb20">
                                                                    {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">REMARKS</label> */}
                                                                    <Controller
                                                                        name={`PAGE_NO[${index}]`}
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <input type="text" value={data?.PAGE_NO} {...field} className="form-control" />
                                                                        )}
                                                                        rules={{
                                                                            required: false,
                                                                            pattern: {
                                                                                value: numericPattern,
                                                                                message: 'Special character are not allowed',
                                                                            },
                                                                        }}
                                                                    />
                                                                    {errors && errors?.PAGE_NO?.[index]?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="form-group mb20">
                                                                    {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Status <span style={{ color: 'red' }}> *</span></label> */}
                                                                    <Controller
                                                                        name={`CW_DX_STATUS[${index}]`}
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <select
                                                                                value={data?.CW_DX_STATUS}
                                                                                {...field}
                                                                                className="form-control"
                                                                                style={{ paddingRight: "30px" }}
                                                                            >
                                                                                <option value={''} >
                                                                                    Select
                                                                                </option>
                                                                                <option value={'Accepted'} >
                                                                                    Accepted
                                                                                </option>
                                                                                <option value={'Rejected'} >
                                                                                    Rejected
                                                                                </option>
                                                                                {props.aweRightSidebarType?.project_id != '6' ?
                                                                                    <option value={'Pending for clarification'} >
                                                                                        Pending for Clarification
                                                                                    </option>
                                                                                    :
                                                                                    <></>
                                                                                }
                                                                            </select>
                                                                        )}
                                                                        rules={{
                                                                            required: true,
                                                                        }}
                                                                    />
                                                                    {errors && errors?.CW_DX_STATUS?.[index]?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="form-group mb20">
                                                                    {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">REMARKS</label> */}
                                                                    <Controller
                                                                        name={`REMARKS[${index}]`}
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <textarea rows={1} value={data?.REMARKS} {...field} className="form-control" />
                                                                        )}
                                                                        rules={{
                                                                            required: false,
                                                                        }}
                                                                    />
                                                                    {errors && errors?.REMARKS?.[index]?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    :
                                                    <></>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions className='mt-2'>
                            <div className="form-group flex_item_cs gap10">
                                <Button type='button' className={"btn-white minw80"} onClick={() => props.handleClose('edit')}>CANCEL</Button>
                                <Button type='submit' className={"minw80"} disabled={formSubmitisLoading && formSubmitisLoading == true ? true : false}>SAVE</Button>
                            </div>
                        </DialogActions>
                    </form>
                </div>
            </MuiDialog>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default (withStyles(styles), connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload }))(EditCWDXCoderWorkBench);
