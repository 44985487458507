/**
 * Creating js file for to load column dynamic
 * Story - PD-40
 * @author ih-sonal
 *
 */
import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables"
import PropTypes from "prop-types";
import api from '../../assets/constants/AWVMemberDetails.RafService.Instance';
import { Button, CircularProgress } from '@material-ui/core';
import {
    createMuiTheme,
    MuiThemeProvider,
} from "@material-ui/core/styles";

import { forEach } from "lodash";

const DynamicColumnShow = (props) => {

    const { url, tableClick, defaultSortCol, defaultSortDir, downloadFileName, columns } = props;

    const [rowdata, setRowdata] = useState(['Loading Data...']);
    const [isDataGridLoading, setIsDataGridLoading] = useState(false);
    const [count, setCount] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(10);
    const [Page] = useState(0);
    const [sortOrder] = useState({});
    const [col] = useState(defaultSortCol ? defaultSortCol : "");
    const [Dir] = useState(defaultSortDir ? defaultSortDir : "asc");
    const [Search] = useState("");
    const [ColumnSearch] = useState("");
    const [TableAfterSearch, setTableAfterSearch] = useState(false);
    const [rowdataCSV, setrowdataCSV] = useState(['Downloading Data...']);
    const [columnName, setColumnName] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [filteredValue, setFilteredValue] = useState([]);
    const [filteredColumns, setFilteredColumns] = useState([]);

    const loadTable = (pageNo, rowsOnPage, col, dir, searchtxt, search) => {

        setIsLoading(true)
        setIsDataGridLoading(true);
        api.get(`${url}&iDisplayStart=${pageNo * rowsOnPage}&iDisplayLength=${rowsOnPage}&iSortCol_0=${col}&sSortDir_0=${dir}&ColumnSearch=${search}`)
            .then(res => {

                if (res.data == null || res.data.includes('errorMessage')) {
                    setIsLoading(false);
                    setIsDataGridLoading(false);
                }
                else {
                    setRowdata(res.data);
                    setCount(res.data[0].Total_Count);
                    //  alert("download count... ",res.data[0].Total_Count)
                    setIsLoading(false);
                    setIsDataGridLoading(false);
                }
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
                setIsDataGridLoading(false);
            })

    }
    const loadColumn = () => {
        let values = [];
        if (rowdata.includes('Loading Data...') == false) {
            rowdata.forEach((element, index) => {
                for (let [key, value] of Object.entries(element)) {
                    if (!values.includes(key)) {
                        values.push(key)
                    }
                }
            });
        }
        values.pop();
        setColumnName(values)
        var filteredValues= []
       // alert(values)
        values.forEach(element => {
            if (element) {
                filteredValues.push(element)
            }
        });
        setFilteredValue(filteredValues)
      //  alert(filteredValue)

    }

    const downloadTable = (pageNo, rowsOnPage, col, dir, searchtxt) => {

        api.get(`${url}&iDisplayStart=${pageNo * rowsOnPage}&iDisplayLength=${rowsOnPage}&iSortCol_0=${col}&sSortDir_0=${dir}&ColumnSearch=${searchtxt}`)
            .then(res => {
                setrowdataCSV(res.data);
                // alert("download count... ",res.data)
            })
            .catch(err => {
                console.log(err);
            })

    }

    const ObjectToFindValue = (objectName, keyName) => {
        // console.log('ObjectToFindValue');
        for (let [key, value] of Object.entries(objectName)) {
            if (key === keyName) {
                // console.log(`${key}: ${value}`);
                return value;
            }
        }
    }

    const DefaultOptions = {
        filter: props.filter ? props.filter : false,
        filterType: 'textField',
        display: false,
        selectableRows: false,
        serverSide: true,
        count: count,
        responsive: 'stacked',
        tableBodyHeight: 'auto',
        tableBodyMaxHeight: '900px',
        pagination: true,
        rowsPerPage: RowPerPage,
        rowsPerPageOptions: [10, 25, 50, 100], search: props.search ? props.search : false,
        searchOpen: props.searchOpen ? props.searchOpen : false,
        searchIcon: props.searchIcon ? props.searchIcon : false,
        search: props.search ? props.search : false,
        searchOpen: props.searchOpen ? props.searchOpen : false,
        searchIcon: props.searchIcon ? props.searchIcon : false,
        sortOrder: sortOrder,
        textLabels: {
            body: {
                noMatch: isLoading ?
                    <CircularProgress />
                    :
                    'Sorry, there is no matching data to display',
            },
        },
        downloadOptions: {
            filename: props.downloadFileName ? props.downloadFileName : 'Download.csv'
        },
        onCellClick: (colData, cellMeta) => {
            //    console.log(colData,cellMeta);
            if (props.tableClick) {
                tableClick(rowdata, colData, cellMeta);
            }

        },

        onDownload: (buildHead, buildBody, columns, data) => {
            //downloadTable(Page,count,col,Dir,Search);
            let values = [];
            var AllDataArray = []
            let rowArray = [];
            columns.forEach(columnInside => {

                values.push(columnInside.name)
            })
            rowdataCSV.forEach((element, index) => {
                rowArray = [];
                values.forEach((el, i) => {
                    rowArray.push(ObjectToFindValue(element, el));
                });
                AllDataArray.push({ index: index, data: rowArray });
            });
            //data = toArray(filterRowObject)

            return "\uFEFF" + buildHead(columns) + buildBody(AllDataArray);
        },
        onTableChange: (action, tableState) => {
            let filterColumnObject = [];
            let columnArray = [];
            //multiple filter
            if (props.filter == true && action == 'filterChange' && tableState.columns && tableState.filterList) {
                tableState.columns.forEach(element => {
                    if (element.name) {
                        columnArray.push(element.name)
                    }
                });
                if (columnArray && columnArray.length > 0) {
                    tableState.filterList.forEach((element, index) => {
                        if (element && element[0]) {
                            var key = columnArray[index] + '=' + "\"" + element[0] + "\"";
                            //console.log('key ',key)
                            filterColumnObject.push(key)
                        }
                    });
                }
                // console.log("filterColumnObject "+filterColumnObject)
            }

            switch (action) {
                case "changePage":
                    loadTable(tableState.page, tableState.rowsPerPage, columnName[col], Dir, Search, filteredColumns.length > 0 ? filteredColumns : '');
                    break;
                case "sort":
                    loadTable(tableState.page, tableState.rowsPerPage, columnName[tableState.activeColumn], tableState.sortOrder.direction, Search, filteredColumns.length > 0 ? filteredColumns : ColumnSearch);
                    break;
                case "changeRowsPerPage":
                    setRowPerPage(tableState.rowsPerPage);
                    loadTable(tableState.page, tableState.rowsPerPage, columnName[col], Dir, Search, filteredColumns.length > 0 ? filteredColumns : ColumnSearch);
                    break;
                case "search":
                    setTableAfterSearch(true);
                    loadTable(tableState.page, tableState.rowsPerPage, columnName[col], Dir, tableState.searchText ? tableState.searchText : Search, ColumnSearch);
                    break;
                /* case "filterChange":
                  //commenting to restrict hit backend api on each letter type
                    setTableAfterSearch(true);
                    var arrayObject = filterColumnObject;
                    loadTable(tableState.page, tableState.rowsPerPage, columnName[col], Dir,
                        tableState.searchText ? tableState.searchText : Search, arrayObject.length > 0 ? arrayObject : ColumnSearch);
                    break; */
                case "onSearchClose":
                    // setTableAfterSearch(false);
                    // loadTable(tableState.page,tableState.rowsPerPage,col,Dir,Search,ColumnSearch);
                    break;
            }
        },
        /* Changes to add apply filter button and function on click on that button */
       /*  onFilterOption: (applyNewFilters) => {
            return (
                <div>
                  <Button variant="contained" style={{ color: 'white', fontFamily: "'Source Sans Pro', sans-serif", backgroundColor: "rgb(46 147 147)" }} onClick={() => handleFilterSubmit(applyNewFilters)}>Apply Filters</Button>
                </div>
              );
          }, */
         // Calling the applyNewFilters parameter applies the selected filters to the table 
      customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
        return (
            <div  style={{ marginTop: '40px', float:'right', paddingBottom:'10px'}}>
              <Button variant="contained" style={{ color: 'white', fontFamily: "'Source Sans Pro', sans-serif", backgroundColor: "rgb(46 147 147)" }} onClick={() => handleFilterSubmit(applyNewFilters)}>Apply Filters</Button>
            </div>
          );
      },
      confirmFilters: true,
      
      onFilterChipClose: (index, removedFilter, filterList ) => {
        let filterColumnObject = [];
        filterList.forEach((element, index) => {
            if (element && element[0]) {
                var key = filteredValue[index] + '=' + "\"" + element[0] + "\"";
                //console.log('key ',key)
                filterColumnObject.push(key)
              //  filteredColumns.push(key)
            }
        });
       
        var arrayObject = filterColumnObject;
        setFilteredColumns(filterColumnObject)
        setTableAfterSearch(true);
        setRowPerPage(RowPerPage);
        loadTable(0,10, columnName[col],
            Dir, Search, arrayObject.length > 0 ? arrayObject : '');
    }            
    }
    
    const handleFilterSubmit = applyFilters => {
        let filterColumnObject = [];
        let filterList = applyFilters();
       // alert(columnName)
        filterList.forEach((element, index) => {
            if (element && element[0]) {
                var key = filteredValue[index] + '=' + "\"" + element[0] + "\"";
                //console.log('key ',key)
                filterColumnObject.push(key)
               // filteredColumns.push(key)
            }
        });
        var arrayObject = filterColumnObject;
        setFilteredColumns(filterColumnObject)
        setTableAfterSearch(true);
        setRowPerPage(RowPerPage);
        loadTable(0,10, columnName[col],
            Dir, Search, arrayObject.length > 0 ? arrayObject : '');
    }

    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MuiTableCell: {
                    head: {
                        backgroundColor: "#F7F7F7 !important",
                        color: "#202020 !important",
                        fontWeight: "bold !important"
                    },
                    sortOrder: {
                        backgroundColor: "#F7F7F7 !important",
                        color: "#202020 !important",
                        fontWeight: "bold !important"
                    }
                },
                MUIDataTableSelectCell: {
                    headerCell: {
                        backgroundColor: "#F7F7F7 !important",
                        color: "#202020 !important",
                        fontWeight: "bold !important"
                    }
                },
                MUIDataTableHeadCell: {
                    // root: {
                    //     width: "150px",
                    //     minWidth: "50px",
                    // },
                    sortActive: {
                        color: "#202020 !important",
                    }
                },
                MUIDataTable: {
                    responsiveStacked: {
                        maxHeight: 'none',
                        overflowX: 'auto'
                    },
                },
            }
        });


    useEffect(() => {
        loadTable(Page, RowPerPage, columnName[col], Dir, Search);
    }, [url]);

    useEffect(() => {
        loadColumn()
    }, [rowdata]);

    useEffect(() => {
        downloadTable(Page, count, columnName[col], Dir, Search);
    }, [count, url]);

    return (
        <div className="col-lg-12 reporting-member-details-awv" style={{ position: "absolute", minHeight: isDataGridLoading ? "200px" : rowdata && rowdata.length > 0 ? "30px" : "0px" }}>
            {(rowdata.length > 0 || TableAfterSearch) ?
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        data={rowdata}
                        columns={columnName}
                        options={DefaultOptions}
                        className="card Dynamic_detail_table"
                    />
                </MuiThemeProvider>
                :
                isLoading ? <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                    <CircularProgress />
                </div > : <h6 className="text-center" >No records found!..</h6>
            }
        </div>
    )
}

DynamicColumnShow.propTypes = {
    columns: PropTypes.array,
    url: PropTypes.string,
    tableClick: PropTypes.func,
    defaultSortCol: PropTypes.number,
    defaultSortDir: PropTypes.string
}

export default DynamicColumnShow;
