import React, { useState, useEffect, useRef } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import {getAcaYear} from '../../assets/constants/yearList';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag, hccSeperator, addSuffixPercent,addSuffixTwoDecimal } from "../../assets/constants/customRender";
import GridTable from "../../components/TableGrids/GridTable.table";
import SingleHorizontalBar from "../../components/Charts/SingleHorizontalBar.chart";
import history from '../../history';
import BarVerticalHCC from '../../components/GChart/BarVerticalHCC';
import { GAP_INSIGHTS, HCC, GENDER,MAO_Chart_Review_Member, ISSUER_ID, MEMBER_NAME, CARRIER_ID, PLAN_ID, MAO_MEMBER_SELECT_TAB, CR_INSIGHTS } from "../../assets/constants/string.constants";
import CardComponent from "../../components/Cards/card";
import SubHeader from '../../components/home/header/subHeader';
import { getMeInitialSelect} from '../../assets/constants/meInitialList';
import { connect } from 'react-redux';
//import google from "react-google-charts";
const MaoChartReviewHccDelete = (props) => {
    const myRefChartData = useRef('')
    const [hcc, setHcc] = useState(localStorage.getItem(HCC));
    const [issuer_id] = useState(localStorage.getItem(ISSUER_ID));
    const [HccReportedChartData, setHccReportedChartData] = useState({});  
    const [HccGAPChartData, setHccGAPChartData] = useState({});  
    const [tableData, setTableData] = useState([]);
    
     //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect
    const loadMetricTable = () =>{
        api.get('/CRHCCDescForDel/year/'+getAcaYear()+'/HCC/'+hcc+'/period/'+getMeInitialSelect())
        .then(res=>{
            setTableData(res);
        })
        .catch(err=>{
            // console.log(err);
        })
    }

    const  summaryColumns =[
        {
            name: "hccCode",
            label: "HCC Code ",
        },
        {
            name: "description",
            label: "Description",
        },
        {
            name: "memberCount",
            label: "# Members"
        },
        {
            name: "providerCount",
            label: "Provider Count"
        },
        
    ]


    const memberColumn = [
        {
            label: "Member ID",
            name: "MemberID",
            options: anchorTag
        },
        {
            name: "ReportedHCCS",
            label: "Reported HCCs",
            options: hccSeperator
        },
        {
            name: "DeletedHccs",
            label: "Deleted HCCs",
            options: hccSeperator
        },
        {
            name: "ReportedRAF",
            label: "Reported RAF",
        },
        {
            name: "CorrectionInRAF",
            label: "RAF Impact due to CR",
            options:addSuffixTwoDecimal,
        },
        {
            name: "CorrectedOverallRevenue",
            label: "Overall Revenue Post CR",
        },
        {
            name: "RevenueCorrection",
            label: "Revenue Impact due to CR",
        },
        {
            name: "ChartReviewAsAPercentageOfCorrectedRevenue",
            label: "% Contribution by CR"
        }
    ];
  
    const crHccAddDiagDetailColumn = [
        {
            label : "Diagnosis Code",
            name:"code",
        },
        {
            name : "CountOFDiagnosis",
            label : "Count of Diagnosis"
        },
        {
            name : "PercentContribution",
            label : "# Contribution of this Diagnosis"
        },
    ];
   
    const crHccAddProviderDetailColumn = [
        {
            label : "Provider ID",
            name:"code",
        },
        {
            name : "DiagnosisCode",
            label : "Diagnosis Added by Provider to Result in this HCC"
        },
        {
            name : "CountOFDiagnosis",
            label : "No of Addition of this Diagnosis by this Provider"
        },
        {
            name : "PercertageContribution",
            label : "% Contribution of Score by Provider-Diagnosis to this HCC Addition"
        },
    ];

    const memberTableClick = (rowdata, colData, cellMeta) =>{
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(MAO_Chart_Review_Member, rowdata[cellMeta.rowIndex].MemberID);
            localStorage.setItem(MAO_MEMBER_SELECT_TAB, CR_INSIGHTS);
            history.push('/layout/medicare/mao_member_summary')
        }else if ((cellMeta.colIndex === 1) && (colData.props.children != "")) {
            if(typeof(colData.props.children) == "object"){
                localStorage.setItem(HCC,(cellMeta.event.target.innerText).replace(',',''));
            }
            else{
                localStorage.setItem(HCC,rowdata[cellMeta.rowIndex].ReportedHCCS);
            }
            history.push('/layout/medicare/reported_hcc')
        }else if ((cellMeta.colIndex === 2) && (colData.props.children != "")) {
            if(typeof(colData.props.children) == "object"){
                localStorage.setItem(HCC,(cellMeta.event.target.innerText).replace(',',''));
            }
            else{
                localStorage.setItem(HCC,rowdata[cellMeta.rowIndex].DeletedHccs);
            }
            history.push('/layout/medicare/chart_review_hcc_delete')
        }
    }

     //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    const loadHccGAPChart = () =>{
        let newChartData = [];
        newChartData.push(["HCC's", "Member Count", { role: 'annotation' }]);
        api.get('/CRAssociatedTopHCCForDel/year/'+getAcaYear()+'/HCC/'+hcc+'/period/'+getMeInitialSelect())
        .then(res=>{
            for (const dataObj of res) {
                newChartData.push([dataObj.description+':'+dataObj.hccCode, dataObj.memberCount, dataObj.memberCount]);
            }
            setHccGAPChartData(newChartData);
        })
    }

    const executeScroll = () => {
        myRefChartData.current.scrollIntoView();
    }   

    useEffect(() =>{
         loadMetricTable();
         loadHccGAPChart();
    },[       
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect()
    ]);

    useEffect(() => {
        setHcc(localStorage.getItem(HCC));
    }, [localStorage.getItem(HCC)]);

    useEffect(() => {
        loadMetricTable();
        loadHccGAPChart();
        executeScroll();
    }, [hcc]);


    return(
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Chart Review Hcc Delete'} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mb-3" ref={myRefChartData}>
                    <CardComponent
                        heading={`Hcc View - For year : ${props.yearSelect} `}
                        body={
                            <GridTable
                                data={tableData}
                                columns={summaryColumns}
                            ></GridTable>
                    }></CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <CardComponent
                        heading={`Top 5 Co-occurring HCCs for this Gap Condition`}
                        body={
                            <BarVerticalHCC data={HccGAPChartData} chartType="BarChart" height="300px" width="100%" setStorgeItemName={HCC} redirectUrl={'medicare/chart_review_hcc_delete'}></BarVerticalHCC>
                    }></CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <CardComponent
                        heading={`MEMBERS DETAILS`}
                        body={
                            <ServerSideGridTable
                                url={`crHccDeletedMemDetail/year/${getAcaYear()}/HCC/${hcc}/period/${getMeInitialSelect()}`}
                                columns={memberColumn}
                                tableClick={memberTableClick}
                            ></ServerSideGridTable>
                    }></CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <CardComponent
                        heading={`DIAGNOSIS LIST`}
                        body={
                            <ServerSideGridTable
                                url={`crHccDelDiagDetail/year/${getAcaYear()}/HCC/${hcc}/period/${getMeInitialSelect()}`}
                                columns={crHccAddDiagDetailColumn}
                            ></ServerSideGridTable>
                    }></CardComponent>
                </div>
                <br/>
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <CardComponent
                        heading={`PROVIDER LIST`}
                        body={
                            <ServerSideGridTable
                                url={`crHccDeleteProviderDetail/year/${getAcaYear()}/HCC/${hcc}/period/${getMeInitialSelect()}`}
                                columns={crHccAddProviderDetailColumn}
                            ></ServerSideGridTable>
                    }></CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br/>

                <br/>
                <br/>    
            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(MaoChartReviewHccDelete);
