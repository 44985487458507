import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import WidgetCard from "../../components/Cards/WidgetCard";
import SingleVerticalBarChart from '../../components/Charts/SingleVerticalBar.Chart';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag, hccSeperator, numberWithCommas } from '../../assets/constants/customRender'
import history from "../../history";
import { MEMBER_ID, ISSUER_ID, HCC, WIDGET_TYPES, REPORTED_INSIGHTS, MEMBER_NAME, CARRIER_ID, PLAN_ID, GENDER, MAO_RISK_CONDITION_CHART,MAO_Chart_Review_Member, MAO_MEMBER_SELECT_TAB } from "../../assets/constants/string.constants";
import { percentFormat } from "../../assets/helpers/formats.helpers";
import CardComponent from "../../components/Cards/card";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import SubHeader from '../../components/home/header/subHeader';
import Link from '@material-ui/core/Link';
import { connect } from 'react-redux';
import GridTable from "../../components/TableGrids/GridTable.table";
import { getMeInitialSelect} from '../../assets/constants/meInitialList';
//import google from "react-google-charts";

const HighCostMembers = (props) => {
    const [hccCode, setHccCode] = useState(localStorage.getItem(HCC));

    const  memberMedicareCols =[
        {
            name: "memberid",
            label: "Member ID",
        },
        {
            name: "utilization",
            label: "Utilization",    
        },
        {
            name: "reportedRevenue",
            label: "Reported Revenue",    
        },
        {
            name: "revenuePostCR",
            label: "Revenue Post Chart Review",    
        },
        {
            name: "reportedYield",
            label: "Reported Yield",    
        },
        {
            name: "yieldPostCR",
            label: "Yield Post Chart Review",    
        },
        {
            name: "percentContOverall",
            label: "% Contribution to Overall Utilization",    
        },
        
    ]

    const memberTableClick = (rowdata, colData, cellMeta) =>{
        if ((cellMeta.colIndex === 1) && (colData.props.children != "")) {
            localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].hcc);
            history.push('/layout/medicare/high_need_hcc_utilization');
        }
    }
   

    useEffect(() => {      
    },  [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect()
    ]);

    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Member List'} selectType={'utilization'} />
                        </div>
                    </div>
                </div>
                <div className='col-lg-12 pr-0 pl-0'>
                    <div className="list-member mt-4">
                        <CardComponent
                            heading={`MEMBERS WITH CHRONIC CONDITION - ACUTE MYOCARDIAL INFARCTION : YEAR - ${props.yearSelect}`}
                            body={
                                    <ServerSideGridTable
                                        url={`/HighCostMemberdetails/year/${props.yearSelect}/hcc/${hccCode}`}
                                        columns={memberMedicareCols}
                                        // tableClick={memberTableClick}
                                    ></ServerSideGridTable>
                                }>
                        </CardComponent>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br/>

                <br/>
                <br/> 

            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(HighCostMembers);
