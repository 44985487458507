import React, { useState, useEffect } from 'react';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import adminApi from '../../assets/constants/AdminRafservice.Instance';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import Divider from '@mui/material/Divider';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import pdfIcon from '../../assets/images/pdf_icon.png';
import Moment from 'moment';
import { ByteConverter } from "../../assets/constants/customRender";
import axios from "axios";
import AWS from 'aws-sdk';
import FilePresentIcon from '@mui/icons-material/FilePresent';
const aws = require('aws-sdk');




const S3_BUCKET = process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET;
const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = localStorage.getItem("access_key");
const SECRET_ACCESS_KEY = localStorage.getItem("secret_key");

const styles = (theme) => ({
    dialogPaper: {
        maxWidth: '80%',
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;

    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const ViewAWVFileUpload = (props) => {

    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [year, setYear] = useState(props.yearSelect);
    const [open, setOpen] = React.useState();
    const [isLoading, setIsLoading] = useState(false);
    const [fileUrl, setFileUrl] = useState();
    const [fileName, setFileName] = useState();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };





    useEffect(() => {
        if (props.open && props.editFormData?.form_url && props.editFormData?.form_url != '') {
            aws.config.update({
                accessKeyId: ACCESS_KEY,
                secretAccessKey: SECRET_ACCESS_KEY,
                region: REGION
            });

            const s3d = new aws.S3();


            var credentials = {
                accessKeyId: localStorage.getItem("access_key"),
                secretAccessKey: localStorage.getItem("secret_key")
            };
            AWS.config.update({ credentials: credentials, region: process.env.REACT_APP_REGION, signatureVersion: "v4" });
            var s3 = new AWS.S3({
                signatureVersion: "v4"
            });

            //let Key = 'test.pdf';
            let url = props.editFormData?.form_url
            let Key = url.split('/').pop();

            var keyWithSubString = '';
            //setFileName(Key);

            s3.setupRequestListeners = (request) => {
                request.on('build', (req) => {

                    req.httpRequest.headers['x-amz-date'] = process.env.REACT_APP_KeyID;
                });
            }
            let fileKey =  process.env.REACT_APP_AWE_DIR + '/' +Key;

            var presignedGETURL = s3.getSignedUrl('getObject', {
                Bucket: S3_BUCKET,
                Key: fileKey,
                Expires: 60
            });
            if (Key.includes('/')) {
                keyWithSubString = Key.substr(((Key).lastIndexOf('/')))
            } else {
                keyWithSubString = Key
            }
            axios({
                url: presignedGETURL,
                responseType: "arraybuffer",
                method: "GET",
            }).then((res) => {

                let file = new Blob([res.data], {
                    type: "application/pdf",
                });

                let fileURL = URL.createObjectURL(file);
                //props.editFormData.form_url = fileURL;
                //this.setState('form_url','fileURL')
                setFileUrl(presignedGETURL);

                /*
                let a = document.createElement("a");
                a.href = fileURL;
                a.target = "_blank";
                a.download = keyWithSubString;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);*/

            }).catch((err) => { console.log("Error while downloading file...") })

        }
    }, [props.editFormData])
    // useEffect(() => {
    //     if (props.editFormData && props.editFormData.id) {
    //         setValue('id', props.editFormData.id);
    //         setValue('title', props.editFormData.title);
    //         setValue('description', props.editFormData.description);
    //         setValue('status', props.editFormData.status);
    //         setStatusCheck(props.editFormData.status);
    //     } else {
    //         setValue('id', '');
    //         setValue('title', '');
    //         setValue('description', '');
    //         setValue('status', statusCheck);
    //     }
    // }, [props.editFormData]);

    const onSubmit = (data) => {
        setIsLoading(true);
    };


    return (
        <div>
            <MuiDialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth={true}
                maxWidth={'md'}
                className="awv_model_layout"
            >
                <DialogTitle id="create-group" onClose={props.handleClose}>
                    VIEW
                </DialogTitle>
                <Divider />
                <div className='row'>
                    <div className='col-lg-12'>
                        <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                            <DialogContent >
                                <div className="row mb-3">
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong>CIN/RXID</strong></div>
                                        <div>{props?.editFormData?.cin}</div>
                                    </div>
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong>AWE ID</strong></div>
                                        <div>{props?.editFormData?.awe_display_id}</div>
                                    </div>
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong>UPDATED ON</strong></div>
                                        <div>{props?.editFormData?.updated_on}</div>
                                    </div>
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong>TIME SPENT</strong></div>
                                        <div>{Moment(props?.editFormData?.updated_on).diff(Moment(props?.editFormData?.created_on), 'days') + ' Days'}</div>
                                    </div>
                                </div>
                                <div className="row  mb-3">
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong>PROGRESS</strong></div>
                                        <div>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Box sx={{ minWidth: 20 }}>
                                                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                                                        props?.editFormData?.progress,
                                                    )}%`}</Typography>
                                                </Box>
                                                <Box sx={{ width: '100%', ml: 1 }}>
                                                    <Box sx={{ width: '100%', ml: 1 }}>
                                                        <LinearProgress
                                                            sx={{
                                                                height: 10,
                                                                borderRadius: 5,
                                                                [`&.${linearProgressClasses.colorPrimary}`]: {
                                                                    backgroundColor: [800],
                                                                },
                                                                [`& .${linearProgressClasses.bar}`]: {
                                                                    borderRadius: 5,
                                                                    backgroundColor: '#1a9698',
                                                                },
                                                            }}
                                                            color="primary"
                                                            variant="determinate" value={props?.editFormData?.progress} />
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong>DOCUMENT UPLOADED</strong></div>
                                        <div>
                                            {(props?.editFormData?.form_url && props?.editFormData?.form_url != '') ?
                                                <>
                                                    <FilePresentIcon className="" style={{ width: "0.9em", height: "0.8em" }} />
                                                    <a target="_blank" href={fileUrl}>{props.editFormData?.form_url ? props.editFormData?.form_url?.split('/').pop() : ''}</a>
                                                </>
                                                :
                                                <></>
                                            }

                                        </div>
                                    </div>
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong>ASSIGNED TO</strong></div>
                                        <div>{props?.editFormData?.assignedUserName}</div>
                                    </div>
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong>STATUS</strong></div>
                                        <div>
                                            <span className="expand-grid-warning-status pt-1 pb-1 pl-3 pr-3">{props?.editFormData?.form_status}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    {props?.editFormData?.POS ?
                                        <div className="col-lg-3 align-items-center">
                                            <div className='mb-1'><strong>POS</strong></div>
                                            <div>{props?.editFormData?.POS}</div>
                                        </div>
                                        :
                                        <></>
                                    }
                                    {props?.editFormData?.reason_code_description ?
                                        <div className="col-lg-3 align-items-center">
                                            <div className='mb-1'><strong>Reason Code</strong></div>
                                            <div>{props?.editFormData?.reason_code_description}</div>
                                        </div>
                                        :
                                        <></>
                                    }
                                    <div className="col-lg-9 align-items-center">
                                        <div className='mb-1'><strong>REMARK</strong></div>
                                        <div>{props?.editFormData?.approval_remarks} {props?.editFormData?.rejected_remark}</div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 align-items-center">
                                        {/* <div>Last updated by: <span className='txt-custom-primary' style={{ textTransform: 'capitalize' }}>{editFormData?.form_pending_on}</span> <strong>| {editFormData?.updated_on}</strong></div> */}
                                        {/* removing role and | symbol */}
                                        <div>Last updated on: {/*<span className='txt-custom-primary' style={{ textTransform: 'capitalize' }}>{props?.editFormData?.form_pending_on}</span> */}<strong>{props?.editFormData?.updated_on}</strong></div>
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions className='mt-2'>
                                <Button type='button' variant="contained" color="grey" onClick={props.handleClose}>CANCEL</Button>
                            </DialogActions>
                        </form>
                    </div>
                </div>
            </MuiDialog>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default (withStyles(styles), connect(mapStateToProps))(ViewAWVFileUpload);
