import React, { useState, useEffect } from "react";
import GridTable from "../../components/TableGrids/GridTable.table";
import api from "../../assets/constants/Rafservice.Instance";
import CardComponent from "../../components/Cards/card";
import SubHeader from '../../components/home/header/subHeader';
import { connect } from 'react-redux';
import {CONDITION, MEMBER_ID } from "../../assets/constants/string.constants";
import { anchorTag,addSuffixTwoPercent,numberWithCommasPrefixDolar } from '../../assets/constants/customRender';
import { array } from "@amcharts/amcharts4/core";

const PredictionMemberSummary = () => {


    const [tableData, setTableData] = useState([]);
    const [condTableData, setCondTableData] = useState([]);
    const[memberId,setMemberId]=useState(localStorage.getItem(MEMBER_ID));


    const CondColumns = [
        {
            name: "condition",
            label: "Condition"
             
        },
        {
            name : "hccList",
            label : "HCCs"
        },
        {
            name: "rafScore",
            label: "Delta RAF Score",
            options:addSuffixTwoPercent
        }, 
        {
            name: "probaility",
            label: "Probability"
        },
    ];

    const Columns = [
        {
            name: "memberId",
            label: "Member ID"
             
        },
        {
            name: "numberOfConditions",
            label: "No. of Conditions"
        }, 
        {
            name: "rafScore",
            label: "RAF Score",
            options:addSuffixTwoPercent
        } ,
        {
            name: "deltaRafScore",
            label: "Delta RAF Score",
            options:addSuffixTwoPercent
        },
        {
            name: "totalRafScore",
            label: "Potential RAF Score",
            options:addSuffixTwoPercent
        } ,
        {
            name: "deltaRevenueImpact",
            label: "Delta Revenue $",
            options:numberWithCommasPrefixDolar
        } ,
        {
            name: "totalRevenueImpact",
            label: "Potential Revenue Impact $",
            options:numberWithCommasPrefixDolar
        } 
    ]
 
    const loadMemberList = () => { 
        api.get(`/PredictionMember/${memberId}`)
        .then(res => {
            // console.log(res);
            setTableData(res);
        })
        .catch(err => {
            // console.log(err);
        })

    }

    const loadConditionalist = () => { 
        api.get(`/PredictionMemberConditionDetails/${memberId}`)
        .then(res => {
            // console.log(res);
             let arr =Object.values(res);      
             setCondTableData(arr);
        })
        .catch(err => {
            // console.log(err);
        })

    }
    useEffect(() => {
        loadMemberList();
        loadConditionalist();         
    },[memberId]);

    return (
        <div className="level-summary">
        <div className="row pb-2">
          <div className="col-lg-12">
            <div className="state-level">
              <div className="card-header">
                <span className="d-flex bd-highlight">
                  <h6 className="pt-2 flex-grow-1"> PREDICTION MEMBER SUMMARY</h6>
                  <div className="d-flex align-items-center"></div>
                </span>
              </div>
            </div>
          </div>
        </div>          

                <div className="col-lg-12 pr-0 pl-0">
                    <div className="state-level">  
                    <CardComponent
                        heading={`MEMBER VIEW (PREDICTED)`}
                        className="issuer-details"
                        body={                        
                            <GridTable
                                data={tableData}
                                columns={Columns}
                                pagination={true}
                                download={true}
                                search={true}
                                print ={true}
                                viewColumns={true}
                               ></GridTable>
                        }></CardComponent>
                        <br/>
                    <CardComponent
                        heading={`MEMBER CONDITION DETAILS (PREDICTED)`}
                        className="issuer-details"
                        body={                        
                            <GridTable
                                data={condTableData}
                                columns={CondColumns}
                                pagination={true}
                                download={true}
                                search={true}
                                print ={true}
                                viewColumns={true}
                               ></GridTable>
                        }></CardComponent>
                </div>
            </div>

            <br />
            <br />
        </div>
    )
};
 export default PredictionMemberSummary;
