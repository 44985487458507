import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  currentAWERightSidebarType,
  currentAWERightSidebar,
  currentAWERightSidebarCloseDatatableReload,
} from "../../store/actions";
import AWVApi from "../../assets/constants/AWVRafservice.Instance";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import Divider from "@mui/material/Divider";
import { useForm, Controller, set } from "react-hook-form";
import Box from "@mui/material/Box";
import pdfIcon from "../../assets/images/pdf_icon.png";
import Moment from "moment";
import Button from "@material-ui/core/Button";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Typography from "@material-ui/core/Typography";
import { CircularProgress, OutlinedInput } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import hcc_json from "../../Pages/config/HCC_Code.config.json";
import dx_json from "../../Pages/config/DX_Code.config.json";
import Autocomplete from "@mui/material/Autocomplete";
import rxc_json from "../../Pages/config/RXC_Code.config.json";
import cpt_json from "../../Pages/config/CPT_Code.config.json";
import ndc_json from "../../Pages/config/NDC_Code.config.json";
import hcc_aca_json from "../../Pages/config/HCC_CODE_ACA.config.json"

const AWVMemberDetails = (props) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    control,
    getValues,
    setValue,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
  const [editFormData, setEditFormData] = useState({});
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const [hccData, setHCCData] = useState([]);
  const [selectGroupsArray, setSelectGroupsArray] = useState([]);
  const [hccValue, setHCCValues] = React.useState();
  const [inputValueHCC, setInputValueHCC] = React.useState("");
  const [inputValueHCCDescription, setInputValueHCCDescription] =
    React.useState("");
  const [inputValueDXDescription, setInputValueDXDescription] =
    React.useState("");
  const [dxValue, setDXValues] = React.useState();
  const [dxCodeData, setDXCodeData] = useState([]); 
  const [codeList, setCodeList] = useState([]);
  const [riskcodetype, setRiskcodetype] = useState("");
  const [codeListType, setCodeListType] = useState("");
  const [rxcValue, setRXCValues] = React.useState();
  const [inputValueRXCDescription, setInputValueRXCDescription] =
    React.useState();
  const [rxcData, setRXCData] = useState([]);
  const [cptValue, setCPTValues] = React.useState();
  const [cptCodeData, setCPTCodeData] = useState([]);
  const [inputValueCPTDescription, setInputValueCPTDescription] =
    React.useState();
  const [ndcValue, setNDCValues] = React.useState();
  const [ndcCodeData, setNDCodeData] = useState([]);
  const [previousHccValue, setPreviousHccValue] = useState(editFormData?.HCC_Code);
  const [previousRxcValue, setPreviousRxcValue] = useState(editFormData?.rxc_code);
  const [previousDXCode, setpreviousDXCode] = useState(editFormData?.DX_code);
  const [hccRecordData, setHCCRecordData] = useState([]); 

  useEffect(() => {

    if (
      props.aweRightSidebarType?.cin_id &&
      props.aweRightSidebarType?.cin_id != ""
    ) {
      editLoadFormData(props.aweRightSidebarType?.cin_id);
    }
      loadHCCRecordData(props.aweRightSidebarType?.cin_id)

  }, [props.aweRightSidebarType?.cin_id]);

  useEffect(() => {
  
   /*  if (
      (editFormData?.HCC_Code && editFormData?.HCC_Code != "") ||
      (editFormData?.rxc_code && editFormData?.rxc_code != "")
    ) { */
      setValue("remark", editFormData?.remark);
      setValue("HCC_Confirmed", editFormData?.HCC_Confirmed);
      setValue("DX_description", editFormData?.DX_description);
      setValue("meat_flag", editFormData?.meat_flag);
      setValue("DX_code", editFormData?.DX_code);
      setValue("HCC_Description", editFormData?.HCC_Description);
      setValue("HCC_Code", editFormData?.HCC_Code);
      setValue("previous_hcc", editFormData?.HCC_Code?editFormData?.HCC_Code:"");
      setValue("CIN", editFormData?.CIN);
      setHCCValues(editFormData?.HCC_Code);
      setInputValueRXCDescription(editFormData?.rxc_description);
      setInputValueHCCDescription(editFormData?.HCC_Description);
      setInputValueDXDescription(editFormData?.DX_description);
      setDXValues(editFormData?.DX_code);
      setValue("awe_id", editFormData?.awv_id);
    
      //RENDERING THE DATA VALUE INTO RIGHT SIDE BAR
      setValue(
        "previous_rxc",
        editFormData?.rxc_code ? editFormData?.rxc_code : ""
      );
      setValue("risk_code_type", editFormData?.risk_code_type);
      setRiskcodetype(editFormData?.risk_code_type);
      setValue("rxc_code", editFormData?.rxc_code);
      setValue("rxc_description", editFormData?.rxc_description);
      updateCodeList(editFormData?.risk_code_type);
      setValue("source_code_type", editFormData?.source_code_type ? editFormData?.source_code_type :"");
      setCodeListType(editFormData?.source_code_type);
      setRXCValues(editFormData?.rxc_code);
      autoPopulateDXDescription(editFormData?.DX_code);
      autoPopulateHccDescription(editFormData?.HCC_Code);
      setValue("cpt_code", editFormData?.cpt_code);
      setCPTValues(editFormData?.cpt_code);
      autoPopulateCPTDescription(editFormData?.cpt_code);
      autoPopulateRxcDescription(editFormData?.rxc_code);
      setValue("ndc_code", editFormData?.ndc_code);
      setNDCValues(editFormData?.ndc_code);
      //adding primary key id to resolve null hcc code update issue
      setValue("id", editFormData?.id ? String(editFormData?.id) : "" );
    // }
  }, [editFormData]);

  useEffect(() => {
    setPreviousHccValue(editFormData?.HCC_Code);
    setPreviousRxcValue(editFormData?.rxc_code);
    setpreviousDXCode(editFormData?.DX_code);
    
  }, [editFormData?.HCC_Code, editFormData?.rxc_code]);

  const notificationRightDrawer = (open, valueId = "") => {
    props.currentAWERightSidebarType({ type: "notification" });
    props.currentAWERightSidebar(open);
  };

  const editLoadFormData = (value) => {
    setIsLoading(true);
    AWVApi.get(
      "/get_member_hcc_details?cin=" +
        value +
        "&year=" +
        props?.yearSelect +
        "&lob=" +
        props?.lobSelect +
        "&organisation_id=1"
    )
      .then((res) => {
        if (res.data && res.data.length > 0) {
          res.data.forEach((element) => {
            if (
                (element.id && element.id === props.aweRightSidebarType?.id &&
                element.DX_code === props.aweRightSidebarType?.DX_code &&
                element.HCC_Code === props.aweRightSidebarType?.HCC_Code) ||
                (element.id === props.aweRightSidebarType?.id &&
                  props.aweRightSidebarType?.rxc_code && props.aweRightSidebarType?.rxc_code !== "" &&
                element.rxc_code === props.aweRightSidebarType?.rxc_code &&
                (element.ndc_code === props.aweRightSidebarType?.ndc_code ||
                element.cpt_code === props.aweRightSidebarType?.cpt_code ||
                element.DX_code === props.aweRightSidebarType?.DX_code))
            ) {
              setEditFormData(element);
              setIsLoading(false);
            }
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };


  const loadHCCRecordData = (value) =>{

    AWVApi.get(
      "/get_member_hcc_details?cin=" +
        value +
        "&year=" +
        props?.yearSelect +
        "&lob=" +
        props?.lobSelect +
        "&organisation_id=1"
    )
      .then((res) => {
        if (res.data && res.data.length > 0) {
          setHCCRecordData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });

  }

  useEffect(() => {
    loadHCCData();
    loadDXData();
    loadRXCData();
    loadCPTData();
  }, [editFormData]);

  //rendering the codelist as per risk code type changes
  useEffect(() => {
    if (riskcodetype && riskcodetype != "") {
    updateCodeList(riskcodetype);
    }
  }, [riskcodetype]);

  // set value according to LOB .
  const updateCodeList = (event) => {
    const selectedValue = event;
    // setRiskcodetype(selectedValue);
    if (selectedValue === "HCC") {
      setCodeList(["DX_CODE"]);
    } else if (selectedValue === "RXC") {
      setCodeList(["NDC", "CPT_CODE"]);
    }
    //IF RISK CODE TYPE CHANGES DURING EDIT THEN REMOVING PREVIOUS VALUE ASSIGNED SO THAT UPDATE THE NEW VALUE
    if(selectedValue!=editFormData?.risk_code_type && props.lobSelect==1)
    {
      setCodeListType("")
      setCPTValues("")
      setNDCValues("")
      setHCCValues("")
      setRXCValues("")
      setDXValues("")
    }
  };
    //IF SOURCE CODE TYPE CHANGES DURING EDIT THEN REMOVING PREVIOUS VALUE ASSIGNED SO THAT UPDATE THE NEW VALUE
  useEffect(()=>{
    if(codeListType!= editFormData?.source_code_type)
    {
      setCPTValues("")
      setNDCValues("")
      // setHCCValues("")
    }

  },[codeListType])
 
  //default selecting the value of source_code_type and risk_code_type in case of medicare
  useEffect(()=>{
    if(props.lobSelect == 2)
    {
      setRiskcodetype("HCC");
      setCodeListType("DX_CODE")
    }

  },[props.lobSelect])
  //RENDER AUTOPOPULATE WHEN RXC_VALUE CHANGES
  useEffect(()=>{
     autoPopulateRxcDescription(rxcValue)
  },[rxcValue])

  //RENDER AUTOPOPULATE WHEN HCC_VALUE CHANGES
  useEffect(()=>{
     autoPopulateHccDescription(hccValue)
  },[hccValue])

  //RENDER AUTOPOPULATE WHEN CPT_VALUE CHANGES
  useEffect(()=>{
     autoPopulateCPTDescription(cptValue)
  },[cptValue])

  //RENDER AUTOPOPULATE WHEN DX_VALUE CHANGES
  useEffect(()=>{
    autoPopulateDXDescription(dxValue)
 },[dxValue])


  //load HCC codes from JSON hcc_json->Medicare || hcc_aca_json -> ACA
  const loadHCCData = () => {
    let values = [];
    if(props.lobSelect==2)
    {
      hcc_json.forEach((element, index) => {
      if (!values.includes(element.hcc_code)) {
        values.push(element.hcc_code);
      }});
    }
    else
    {
      hcc_aca_json.forEach((element, index) => {
      if (!values.includes(element.hcc_code)) {
        values.push(element.hcc_code);
      }});
    }
    setHCCData(values);
  };

  //load DX codes from JSON
  const loadDXData = () => {
    let values = [];
    dx_json.forEach((element, index) => {
      if (!values.includes(element.dx_code)) {
        values.push(element.dx_code);
      }
    });
    setDXCodeData(values);
  };

  //load RXC codes from JSON
  const loadRXCData = () => {
    let values = [];
    rxc_json.forEach((element, index) => {
      if (!values.includes(element.rxc_code)) {
        values.push(element.rxc_code);
      }
    });
    setRXCData(values);
  };

  //load CPT codes from JSON
  const loadCPTData = () => {
    let values = [];
    cpt_json.forEach((element, index) => {
      if (!values.includes(element.cpt_code)) {
        values.push(element.cpt_code);
      }
    });
    setCPTCodeData(values);
  };
  //load NDC codes from JSON
  const loadNDCData = (rxc_value) => {
    let values = [];
    ndc_json.forEach((element, index) => {
      if (!values.includes(element.NDC) && element.RXC == rxc_value) {
        values.push(element.NDC);
      }
    });
    setNDCodeData(values);
  };

  const onSubmit = (data) => {
    if ((hccValue && hccValue != "") || (rxcValue && rxcValue != "")) {
      const { aweRightSidebarType } = props;
      setFormSubmitisLoading(true);
      let controlUser = JSON.parse(localStorage.getItem("controlUser"));
      // data.risk_code_type =riskcodetype;
      // data.source_code_type =codeListType;
      data.rxc_code = rxcValue ? rxcValue : "";
      data.ndc_description = "";
      data.rxc_description = inputValueRXCDescription
        ? inputValueRXCDescription
        : "";
      data.ndc_code = ndcValue ? ndcValue : "";
      data.cpt_code = cptValue ? cptValue : "";
      data.cpt_description = inputValueCPTDescription
        ? inputValueCPTDescription
        : "";
      data.HCC_Code = hccValue ? hccValue : "";
      data.HCC_Description = inputValueHCCDescription
        ? inputValueHCCDescription
        : "";
      data.DX_code = dxValue ? dxValue : "";
      data.DX_description = inputValueDXDescription
        ? inputValueDXDescription
        : "";
      data.remark = data.remark ? data.remark : "";
      data.meat_flag =
        data.meat_flag !== null && data.meat_flag !== undefined
          ? String(data.meat_flag)
          : "";
      data.cin = props.aweRightSidebarType?.cin_id;
      // adding lob, year, org id in payload
      data.lob = String(props?.lobSelect) ;
      data.year = String(props?.yearSelect) ;

      //default selecting the value of source_code_type and risk_code_type in case of medicare
      if(props.lobSelect==2)
      {
        data.risk_code_type="HCC";
        data.source_code_type="DX_CODE"
      }
      
      data.organisation_id = "1";
      if (
        props?.aweRightSidebarType?.status &&
        props?.aweRightSidebarType?.status == "1"
      ) {
        data.created_by = controlUser?.user_id;
        data.created_on = Moment().format("YYYY-MM-DD HH:mm:ss");
        data.awe_id = props.aweRightSidebarType?.aweId.toString();
        let errorMessage = "";
        if (aweRightSidebarType) {

          console.log(hccRecordData);
          let recordExists = [];
          recordExists = hccRecordData.filter((val) => val.HCC_Code === data.HCC_Code && val.DX_code === data.DX_code);
          console.log(recordExists);

          /*
          if (aweRightSidebarType.hccCodeList && aweRightSidebarType.hccCodeList.includes(data.HCC_Code)) {
            errorMessage = 'HCC Code already exists. Kindly, enter new HCC.';
          } else if (aweRightSidebarType.rxcCodeList && aweRightSidebarType.rxcCodeList.includes(data.rxc_code)) {
            errorMessage = 'RXC Code already exists. Kindly, enter new RXC.';
          }
          */
         if(recordExists.length > 0){
          errorMessage = 'HCC Code and RXC Code already exists. Kindly, enter new HCC details.';
         }

        }
        if (errorMessage) {
          setFormSubmitisLoading(false);
          toast.error(errorMessage);
        }  else {
          if(data?.lob)
              data.lob = data.lob.toString();
          AWVApi.post("/create-hcc-details", data)
            .then((res) => {
              setValue("remark", "");
              setValue("HCC_Confirmed", "");
              setValue("DX_description", "");
              setInputValueDXDescription("");
              setInputValueRXCDescription("");
              setValue("meat_flag", "");
              setValue("DX_code", "");
              setDXValues("");
              setValue("HCC_Description", "");
              setValue("source_code_type", "");
              setCodeListType("");
              setValue("risk_code_type", "");
              setRiskcodetype("");
              setInputValueHCCDescription("");
              setValue("HCC_Code", "");
              setHCCValues("");
              setValue("previous_hcc", "");
              setValue("previous_rxc", "");
              setValue("CIN", "");
              setHCCValues("");
              setInputValueHCCDescription("");
              setInputValueDXDescription("");
              setInputValueRXCDescription("");
              setDXValues("");
              setFormSubmitisLoading(false);
              toast.success("Member Risk Code added successfully");
              notificationRightDrawer(false);
              props.currentAWERightSidebarCloseDatatableReload(true);
            })
            .catch((err) => {
              setFormSubmitisLoading(false);
              toast.error(err?.response?.data?.message);
            });
        }
      } else {
        data.updated_by = controlUser?.user_id;
        let errorMessage = "";
        let recordExists = [];
        recordExists = hccRecordData.filter((val) => val.HCC_Code === data.HCC_Code && val.DX_code === data.DX_code );
        if(data.HCC_Code == previousHccValue && data.DX_code == previousDXCode && previousDXCode != ''){
          recordExists = [];
        }
        if (aweRightSidebarType) {
          /*
          if (aweRightSidebarType.hccCodeList && aweRightSidebarType.hccCodeList.includes(data.HCC_Code) && data.HCC_Code != previousHccValue ) {
            errorMessage = 'HCC Code already exists. Kindly, enter new HCC.';
          } else if (aweRightSidebarType.rxcCodeList && aweRightSidebarType.rxcCodeList.includes(data.rxc_code) && data.rxc_code != previousRxcValue) {
            errorMessage = 'RXC Code already exists. Kindly, enter new RXC.';
          }
          */
         if(recordExists.length >0){
           errorMessage = 'HCC Code and RXC Code already exists. Kindly, enter new HCC details.';
         }
        }
        if (errorMessage) {
          setFormSubmitisLoading(false);
          toast.error(errorMessage);
        } else {
          if(data?.lob)
                data.lob = data.lob.toString();
          AWVApi.put("/update-member-hcc-details", data)
          .then((res) => {
            setValue("remark", "");
            setValue("HCC_Confirmed", "");
            setValue("DX_description", "");
            setInputValueDXDescription("");
            setInputValueRXCDescription("");
            setValue("meat_flag", "");
            setValue("DX_code", "");
            setDXValues("");
            setValue("HCC_Description", "");
            setInputValueHCCDescription("");
            setValue("HCC_Code", "");
            setValue("source_code_type", "");
            setCodeListType("");
            setValue("risk_code_type", "");
            setRiskcodetype("");
            setHCCValues("");
            setValue("previous_hcc", "");
            setValue("previous_rxc", "");
            setValue("CIN", "");
            setHCCValues("");
            setInputValueHCCDescription("");
            setDXValues("");
            setFormSubmitisLoading(false);
            toast.success("Member Risk Code updated successfully");
            notificationRightDrawer(false);
            props.currentAWERightSidebarCloseDatatableReload(true);
          })
          .catch((err) => {
            setFormSubmitisLoading(false);
            toast.error(err?.response?.data?.message);
          });
        }
      }
    }
  };

  //to auto populate HCC dexcription on selection of HCC code hcc_json->Medicare || hcc_aca_json -> ACA
  var hccDesc = "";
  const autoPopulateHccDescription = (value) => {
    if (value  && value != "") {
      if(props.lobSelect==2)
      {
       hcc_json.forEach((ele, index) => {
        if (ele.hcc_code == value) {
        hccDesc = ele.hcc_description;
        setInputValueHCCDescription(hccDesc);
         }
        })
      }
      else
      {
        hcc_aca_json.forEach((ele, index) => {
         if (ele.hcc_code == value) {
          hccDesc = ele.hcc_description;
          setInputValueHCCDescription(hccDesc);
          }
        })
      }
      
    } else {
      setInputValueHCCDescription("");
    }
  };

  //to auto populate dx dexcription on selection of dx code
  var dxDesc = "";
  const autoPopulateDXDescription = (value) => {
    if (value  && value != "") {
      dx_json.forEach((ele, index) => {
        if (ele.dx_code == value) {
          dxDesc = ele.dx_description;
          setInputValueDXDescription(dxDesc);
        }
      });
    } else {
      setInputValueDXDescription("");
    }
  };
  //to auto populate RXC dexcription on selection of RXC code
  var rxcDesc = "";
  const autoPopulateRxcDescription = (value) => {

    if (value && value != "") {
      let rxc_value = value.split(" ");
      rxc_json.forEach((ele, index) => {
        if (ele.rxc_code == value) {
          rxcDesc = ele.rxc_description;
          setInputValueRXCDescription(rxcDesc);
          loadNDCData(rxc_value[1]);
        }
      });
    } else {
      setInputValueRXCDescription("");
      setNDCodeData("");
       loadNDCData("");
       setNDCValues("");
       setRXCValues("");
       
    }
  };
  //to auto populate CPT dexcription on selection of RXC code
  var cptDesc = "";
  const autoPopulateCPTDescription = (value) => {
    if (value && value != "") {
      cpt_json.forEach((ele, index) => {
        if (ele.cpt_code == value) {
          cptDesc = ele.cpt_description;
          setInputValueCPTDescription(cptDesc);
        }
      });
    } else {
      setInputValueCPTDescription("");
    }
  };

  return (
    <div style={{ padding: "10px 0px" }}>
      <div
        key={"index"}
        variant={"head"}
        style={{ width: "350px", padding: "10px", height: "50px" }}
      >
        <div class="float">
          <div class="float-left">
            <span>
              <b>
                {props?.aweRightSidebarType?.status &&
                props?.aweRightSidebarType?.status == "1"
                  ? "Member Detail Report ADD"
                  : "Member Detail Report EDIT"}
              </b>
            </span>
          </div>
          <div class="float-right">
            <Tooltip title="Close">
              <CancelIcon
                style={{ color: "#1A9698", cursor: "pointer" }}
                onClick={() => notificationRightDrawer(false)}
              />
            </Tooltip>
          </div>
        </div>
      </div>
      <Divider />
      <div class="awv-recored-right-sidebar-form">
        {isLoading == false ? (
          <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
            {/* Add risk_code_type */}

            <div className="col-lg-12 align-items-center mb-3 mt-3">
              <div className="mb-1">
                <strong>
                  RISK CODE TYPE <span style={{ color: "red" }}> *</span>
                </strong>
              </div>
              <FormControl
                fullWidth
                margin="dense"
                variant="outlined"
                style={{ "min-width": "200px" }}
              >
                <Controller
                  className="input-control"
                  name="risk_code_type"
                  value={editFormData?.risk_code_type}
                  control={control}
                  render={({ field }) =>
                    props.lobSelect == 2 ? (
                      <>
                        <Select
                          {...field}
                          required
                          labelId="module-multiple-checkbox-label"
                          id="module-multiple-checkbox"
                          label="Module"
                          variant="outlined"
                          menuPlacement="top"
                          defaultValue={"HCC"}
                        >
                          <MenuItem
                            key={"HCC"}
                            value={"HCC"}
                            onClick={(e) => setRiskcodetype(e.target.innerText)}
                          >
                            HCC
                          </MenuItem>
                        </Select>
                      </>
                    ) : (
                      <>
                        <Select
                          {...field}
                          required
                          labelId="module-multiple-checkbox-label"
                          id="module-multiple-checkbox"
                          label="Module"
                          variant="outlined"
                          menuPlacement="top"
                          input={<OutlinedInput label="Module" />}
                        >
                          <MenuItem
                            key={"HCC"}
                            value={"HCC"}
                            onClick={(e) => setRiskcodetype(e.target.innerText)}
                          >
                            HCC
                          </MenuItem>
                          <MenuItem
                            key={"RXC"}
                            value={"RXC"}
                            onClick={(e) => setRiskcodetype(e.target.innerText)}
                          >
                            RXC
                          </MenuItem>
                        </Select>
                      </>
                    )
                  }
                />
              </FormControl>
            </div>
            {/* ADD source_code_type */}
            <div className="col-lg-12 align-items-center mb-3 mt-3">
              <div className="mb-1">
                <strong>SOURCE CODE TYPE</strong>
              </div>
              <FormControl
                fullWidth
                margin="dense"
                variant="outlined"
                style={{ "min-width": "200px" }}
              >
                <Controller
                  className="input-control"
                  name="source_code_type"
                  value={editFormData?.source_code_type}
                  control={control}
                  render={({ field }) =>
                    props.lobSelect == 2 ? (
                      <>
                        <Select
                          {...field}
                          labelId="module-multiple-checkbox-label"
                          id="module-multiple-checkbox"
                          label="Module"
                          value={codeListType}
                          variant="outlined"
                          menuPlacement="top"
                          defaultValue={"DX_CODE"}
                        >
                          <MenuItem
                            key={"DX_CODE"}
                            value={"DX_CODE"}
                            onClick={(e) => setCodeListType(e.target.innerText)}
                          >
                            DX_CODE
                          </MenuItem>
                        </Select>
                      </>
                    ) : (
                      <>
                        <Select
                          {...field}
                          labelId="module-multiple-checkbox-label"
                          id="module-multiple-checkbox"
                          value={codeListType}
                          label="Module"
                          variant="outlined"
                          menuPlacement="top"
                        >
                          
                          {codeList &&
                            codeList.length > 0 &&
                            codeList.map((element, index) => (
                              <MenuItem
                                key={index}
                                value={element}
                                onClick={(e) =>
                                  setCodeListType(e.target.innerText)
                                }
                              >
                                {element}
                              </MenuItem>
                            ))}
                        </Select>
                      </>
                    )
                  }
                  // rules={{
                  //   required: false,                           // commenting this, added required under textField
                  //   }}
                />
              </FormControl>
            </div>
            {riskcodetype === "RXC"  &&  (
              <>
                <div className="col-lg-12 align-items-center mb-3 mt-3">
                  <div className="mb-1">
                    <strong>
                      RISK CODE <span style={{ color: "red" }}> *</span>
                    </strong>
                  </div>
                  <FormControl
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    style={{ "min-width": "200px" }}
                  >
                    <Controller
                      className="input-control"
                      name="rxc_code"
                      value={editFormData?.rxc_code}
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          value={rxcValue}
                          onChange={(event, newValue) => setRXCValues(newValue)}
                          id="controllable-states-demo"
                          options={rxcData}
                          sx={{ width: 318 }}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}
                              value={rxcValue}
                              required
                            />
                          )}
                        />
                      )}
                      rules={{
                        required: false,
                      }}
                    />
                    {errors?.rxc_code?.type === "required" && <label className="text-danger">This field is required</label>}
                  </FormControl>
                </div>
                <div className="col-lg-12 align-items-center mb-3">
                  <div className="mb-1">
                    <strong>
                      RISK CODE DESCRIPTION{" "}
                      <span style={{ color: "red" }}> *</span>
                    </strong>
                  </div>
                  <FormControl
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    style={{ "min-width": "200px" }}
                  >
                    <Controller
                      className="input-control"
                      name="rxc_description"
                      value={inputValueRXCDescription}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          value={inputValueRXCDescription}
                         
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </>
            ) }{riskcodetype==="HCC"&& (
              <>
                <div className="col-lg-12 align-items-center mb-3 mt-3">
                  <div className="mb-1">
                    <strong>
                      RISK CODE <span style={{ color: "red" }}> *</span>
                    </strong>
                  </div>
                  <FormControl
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    style={{ "min-width": "200px" }}
                  >
                    <Controller
                      className="input-control"
                      name="HCC_Code"
                      value={editFormData?.HCC_Code}
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          value={hccValue}
                          onChange={(event, newValue) => setHCCValues(newValue)}
                          id="controllable-states-demo"
                          options={hccData}
                          sx={{ width: 318 }}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}
                              value={hccValue}
                              required
                              
                            />
                          )}
                       
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <div className="col-lg-12 align-items-center mb-3">
                  <div className="mb-1">
                    <strong>
                      RISK CODE DESCRIPTION{" "}
                      <span style={{ color: "red" }}> *</span>
                    </strong>
                  </div>
                  <FormControl
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    style={{ "min-width": "200px" }}
                  >
                    <Controller
                      className="input-control"
                      name="HCC_Description"
                      value={inputValueHCCDescription}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          value={inputValueHCCDescription}
                          
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </>
            
            )}

            {/* RDC CODE AND DESCRIPTION */}
            {codeListType === "NDC" || editFormData?.ndc_code ? (
              <>
                <div className="col-lg-12 align-items-center mb-3">
                  <div className="mb-1">
                    <strong>SOURCE CODE</strong>
                  </div>
                  <FormControl
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    style={{ "min-width": "200px" }}
                  >
                    <Controller
                      className="input-control"
                      name="ndc_code"
                      value={editFormData?.ndc_code}
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          value={ndcValue}
                          onChange={(event, newValue) => setNDCValues(newValue)}
                          id="controllable-states-demo"
                          options={ndcCodeData}
                          sx={{ width: 318 }}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}
                              value={ndcValue}
                              
                            />
                          )}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </>
            ) : codeListType === "CPT_CODE" || editFormData?.cpt_code ? (
              <>
                <div className="col-lg-12 align-items-center mb-3">
                  <div className="mb-1">
                    <strong>SOURCE CODE</strong>
                  </div>
                  <FormControl
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    style={{ "min-width": "200px" }}
                  >
                    <Controller
                      className="input-control"
                      name="cpt_code"
                      value={editFormData?.cpt_code}
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          value={cptValue}
                          onChange={(event, newValue) => setCPTValues(newValue)}
                          id="controllable-states-demo"
                          options={cptCodeData}
                          sx={{ width: 318 }}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}
                              value={cptValue}
                            />
                          )}
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <div className="col-lg-12 align-items-center mb-3">
                  <div className="mb-1">
                    <strong>SOURCE CODE DESCRIPTION</strong>
                  </div>
                  <FormControl
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    style={{ "min-width": "200px" }}
                  >
                    <Controller
                      className="input-control"
                      name="cpt_description"
                      value={inputValueCPTDescription}
                      control={control}
                      render={({ field }) => (
                        //<TextField  {...field} variant="outlined" />
                        <TextField
                          {...field}
                          variant="outlined"
                          value={inputValueCPTDescription}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </>
            ) : codeListType === "DX_CODE" || editFormData?.DX_code ? (
              <>
                <div className="col-lg-12 align-items-center mb-3">
                  <div className="mb-1">
                    <strong>SOURCE CODE</strong>
                  </div>
                  <FormControl
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    style={{ "min-width": "200px" }}
                  >
                    <Controller
                      className="input-control"
                      name="DX_code"
                      value={editFormData?.DX_code}
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          value={dxValue}
                          onChange={(event, newValue) => setDXValues(newValue)}
                          id="controllable-states-demo"
                          options={dxCodeData}
                          sx={{ width: 318 }}
                          renderInput={(params) => (
                            <TextField
                              variant="outlined"
                              {...params}
                              value={dxCodeData}
                            />
                          )}
                        />
                      )}
                    />
                  </FormControl>
                </div>
                <div className="col-lg-12 align-items-center mb-3">
                  <div className="mb-1">
                    <strong>SOURCE DESCRIPTION</strong>
                  </div>
                  <FormControl
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    style={{ "min-width": "200px" }}
                  >
                    <Controller
                      className="input-control"
                      name="DX_description"
                      value={inputValueDXDescription}
                      control={control}
                      render={({ field }) => (
                        //<TextField  {...field} variant="outlined" />
                        <TextField
                          {...field}
                          variant="outlined"
                          value={inputValueDXDescription}
                        />
                      )}
                    />
                  </FormControl>
                </div>
              </>
            ) : (
              <></>
            )}
            {/* Adding meatflag in the rightsidebar */}
            <div className="col-lg-12 align-items-center mb-3">
              <div className="mb-1">
                <strong>INSUFFICIENT DOCUMENTATION</strong>
              </div>
              <FormControl
                fullWidth
                margin="dense"
                variant="outlined"
                style={{ "min-width": "200px" }}
              >
                <Controller
                  className="input-control"
                  name="meat_flag"
                  value={editFormData?.meat_flag == 0 ? "No" : "Yes"}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      labelId="module-multiple-checkbox-label"
                      id="module-multiple-checkbox"
                      label="Module"
                      variant="outlined"
                      menuPlacement="top"
                    >
                      <MenuItem key={"1"} value={"1"}>
                        Yes
                      </MenuItem>
                      <MenuItem key={"0"} value={"0"}>
                        No
                      </MenuItem>
                    </Select>
                  )}
                  rules={{
                    required: false,
                  }}
                />
                {errors?.meat_flag?.type === "required" && (
                  <label className="text-danger">This field is required</label>
                )}
              </FormControl>
            </div>
            <div className="col-lg-12 align-items-center mb-3">
              <div className="mb-1">
                <strong>
                  CONFIRMATION <span style={{ color: "red" }}> *</span>
                </strong>
              </div>
              <FormControl
                fullWidth
                margin="dense"
                variant="outlined"
                style={{ "min-width": "200px" }}
              >
                {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                <Controller
                  className="input-control"
                  name="HCC_Confirmed"
                  value={editFormData?.HCC_Confirmed}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      required
                      labelId="module-multiple-checkbox-label"
                      id="module-multiple-checkbox"
                      label="Module"
                      variant="outlined"
                      menuPlacement="top"
                    >
                      <MenuItem key={"confirmed"} value={"confirmed"}>
                        Confirmed
                      </MenuItem>
                      <MenuItem key={"not confirmed"} value={"not confirmed"}>
                        Not Confirmed
                      </MenuItem>
                      <MenuItem
                        key={"no longer applicable"}
                        value={"no longer applicable"}
                      >
                        No Longer Applicable
                      </MenuItem>
                      <MenuItem key={"acute"} value={"acute"}>
                        Acute
                      </MenuItem>
                    </Select>
                  )}
                  rules={{
                        required: true,         // commenting this, added required under select 
                  }}
                />
              {errors?.HCC_Confirmed?.type === "required" && <label className="text-danger">This field is required</label>}  
              </FormControl>
            </div>
            {/* <div className="col-lg-12 align-items-center mb-3">
              <div className="mb-1">
                <strong>REMARKS</strong>
              </div>
              <FormControl
                fullWidth
                margin="dense"
                variant="outlined"
                style={{ "min-width": "200px" }}
              >
                <Controller
                  className="input-control"
                  name="remark"
                  value={editFormData?.remark}
                  control={control}
                  render={({ field }) => (
                    <TextField {...field} variant="outlined" />
                  )}
                  rules={{
                    required: false,
                  }}
                />
                {errors?.remark?.type === "required" && (
                  <label className="text-danger">This field is required</label>
                )}
              </FormControl>
            </div> */}
            <div className="col-lg-12 align-items-center mb-3">
              <Button
                type="button"
                variant="contained"
                color="grey"
                onClick={() => notificationRightDrawer(false)}
              >
                CANCEL
              </Button>
              <Button
                type="submit"
                className={"mr-2 btn-custom-primary mr-2 ml-2"}
                variant="contained"
                disabled={
                  formSubmitisLoading && formSubmitisLoading == true
                    ? true
                    : false
                }
              >
                {
                  props?.aweRightSidebarType?.status &&
                  props?.aweRightSidebarType?.status == "1"
                    ? "ADD"
                    : "Save" // Label change edit -> save
                }
              </Button>
            </div>
          </form>
        ) : isLoading ? (
          <div style={{ position: "absolute", top: "50%", left: "50%" }}>
            <CircularProgress />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    yearSelect: state.moduleFilter.yearSelect,
    lobSelect: state.moduleFilter.lobSelect,
    aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
    aweRightSidebar: state.moduleFilter.aweRightSidebar,
  };
};
export default connect(mapStateToProps, {
  currentAWERightSidebarType,
  currentAWERightSidebar,
  currentAWERightSidebarCloseDatatableReload,
})(AWVMemberDetails);
