import React, { useRef, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../store/actions';
import AWVApi from '../../assets/constants/AWVRafservice.Instance';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import Divider from '@mui/material/Divider';
import { useForm, Controller } from "react-hook-form";
import Box from '@mui/material/Box';
import pdfIcon from '../../assets/images/pdf_icon.png';
import Moment from 'moment';
import Button from '@material-ui/core/Button';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Autocomplete from '@mui/material/Autocomplete';
import {RenderingProvider} from '../../Pages/config/RenderingProvider.config'
import { SELECT_SITE_ID, PROVIDER_GROUP_LIST } from "../../assets/constants/string.constants";

const AWVMemberDetailsCoodinator = (props) => {
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
    const [editFormData, setEditFormData] = useState({});
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const [siteId, setSiteId] = useState('');
    const [siteIdData, setSiteIdData] = useState([]);
    const [ppg, setPPG] = useState('');
    const [ppgdata, setPPGData] = useState([]);
    const [providerIdData, setProviderIdData] = useState([]);
    const [providerId, setProviderId] = useState('')
    const [providerName, setProviderName] = useState('');
    const [providerNameData, setProviderNameData] = React.useState([]);

    useEffect(() => {
        if (props.aweRightSidebarType?.cin_id && props.aweRightSidebarType?.cin_id != '') {
            editLoadFormData(props.aweRightSidebarType?.cin_id, props.aweRightSidebarType?.awv_id);
            setValue('awe_id', String(props.aweRightSidebarType?.awv_id));
            setValue('cin', props.aweRightSidebarType?.cin_id);
        }
    }, [props.aweRightSidebarType?.cin_id]);

    useEffect(() => {
        console.log(editFormData);
        if (editFormData?.awe_source && editFormData?.awe_source != '') {
            setValue('awe_source', editFormData?.awe_source);
            setValue('receiver_date', Moment(editFormData?.receive_date).format("YYYY-MM-DD"));
            setValue('received_method', editFormData?.received_method);
            setValue('additional_data', editFormData?.additional_data);
            setValue('telehealth_checkbox', editFormData?.telehelth_check);/* correct the spell of telehelth to TELEHEALTH */
            setValue('service_date', Moment(editFormData?.service_date).format("YYYY-MM-DD"));
            setValue('remarks', editFormData?.remarks);
            setValue('awe_id', String(props.aweRightSidebarType?.awv_id));
            setValue('cin', props.aweRightSidebarType?.cin_id);
            setSiteId(editFormData?.site_id)
            setPPG(editFormData?.ppg)
            setProviderId(editFormData?.rendering_provider_npi)
            setProviderName(editFormData?.rendering_provider_name)
        }
    }, [editFormData]);

    // For rendering siteID and PPG data 
    useEffect(() => {
        getProviderGroup();
        getproviderIdData();
        getproviderNameData();
        if (localStorage.getItem(SELECT_SITE_ID) && localStorage.getItem(SELECT_SITE_ID) != '') {
            var data = JSON.parse(localStorage.getItem(SELECT_SITE_ID))
            let values = [];
            data.forEach((element) => values.push(element.site_id))
            setSiteIdData(values);
        };
    }, []);

    const notificationRightDrawer = (open, valueId = '') => {
        props.currentAWERightSidebarType({ 'type': 'notification' });
        props.currentAWERightSidebar(open);
    };

    const editLoadFormData = (cinId, awvId) => {
        setIsLoading(true);
        AWVApi.get('/getawecoordinatorchecklist?cin=' + cinId + '&awe_id=' + awvId + '&year='+ props?.yearSelect + '&lob=' + props?.lobSelect + '&organisation_id=1')
            // AWVApi.get('/get_member_hcc_details?member_id=3RE5W09MM81')
            .then(res => {
                if (res.data && res.data.length > 0) {
                    res.data.forEach(element => {
                        if (props.aweRightSidebarType?.awe_source && element.awe_source == props.aweRightSidebarType?.awe_source) {
                            setEditFormData(element);
                            setIsLoading(false);
                        }

                    });
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
            })
    }

    // Storing providerid into state : setProviderIdData
    const getproviderIdData = () => {
        let value = [];
        RenderingProvider.forEach((element) => value.push(element.providerId))
        setProviderIdData(value)
    }

    // Storing providerName into state : setProviderNameData
    const getproviderNameData = () => {
        let value = [];
        RenderingProvider.forEach((element) => value.push(element.providerName))
        setProviderNameData(value)
    }

    // get ppg list from localStorage and store in array
    const getProviderGroup = () => {
        if (localStorage.getItem(PROVIDER_GROUP_LIST) && localStorage.getItem(PROVIDER_GROUP_LIST) != '') {
            var data = JSON.parse(localStorage.getItem(PROVIDER_GROUP_LIST))
            setPPGData(data);
        };
    }

    var providerNameValue = ''
    var providerIdValue = ''
    const autoPopulateRenderingProvider = (value) => {
        if (value != null && value != "") {
            RenderingProvider.forEach((ele, index) => {
                if (ele.providerId == value) {
                    providerNameValue = ele.providerName
                    setProviderName(providerNameValue)
                } else if (ele.providerName == value) {
                    providerIdValue = ele.providerId;
                    setProviderId(providerIdValue)
                }
            })
        } else {
            setProviderId('')
            setProviderName('')
        }
    }

    const onSubmit = (data) => {
        if (data?.cin && data?.cin != '') {
            setFormSubmitisLoading(true);
            let controlUser = JSON.parse(localStorage.getItem('controlUser'));
            data.updated_by = controlUser?.user_id;
            data.additional_data = String(data?.additional_data);
            data.telehealth_checkbox = String(data?.telehealth_checkbox);/* correct the spell of telehelth to TELEHEALTH */
            data.site_id = siteId ? siteId : "";
            data.ppg = ppg ? ppg : "";
            data.rendering_provider_npi = providerId ? String(providerId) : "";
            data.rendering_provider_name = providerName ? providerName : "";
            data.remarks = data.remarks ? data.remarks : "";
            //adding lob, year, organisation_id to request body
            data.lob = props?.lobSelect;
            data.year = props?.yearSelect;
            data.organisation_id = '1';
            if(data?.lob)
                    data.lob = data.lob.toString();
            AWVApi.put('/update_awe_coordinator_checklist', data)
                .then(res => {
                    setValue('awe_source', '');
                    setValue('receiver_date', '');
                    setValue('received_method', '');
                    setValue('additional_data', '');
                    setValue('service_date', '');
                    setValue('remarks', '');
                    setValue('telehealth_checkbox', '');
                    setValue('awe_id', '');
                    setValue('cin', '');
                    setSiteId('');
                    setPPG('');
                    setProviderId('');
                    setProviderName('');
                    setFormSubmitisLoading(false);
                    toast.success('Coordinator Record updated successfully');
                    notificationRightDrawer(false);
                    props.currentAWERightSidebarCloseDatatableReload(true);
                })
                .catch(err => {
                    setFormSubmitisLoading(false);
                    toast.error(err?.response?.data?.message);
                })
        }

    };

    return (
        <div style={{ padding: "10px 0px" }}>
            <div key={'index'} variant={'head'} style={{ width: '350px', padding: "10px", height: "50px" }}>
                <div class="float">
                    <div class="float-left">
                        <span>
                            <b>
                                {(props?.aweRightSidebarType?.status && props?.aweRightSidebarType?.status == '1') ?
                                      'COORDINATOR CHECKLIST ADD'
                                      :
                                      'COORDINATOR CHECKLIST EDIT'
                                }
                            </b>
                        </span>
                    </div>
                    <div class="float-right">
                        <Tooltip title="Close">
                            <CancelIcon style={{ color: "#1A9698", cursor: "pointer" }} onClick={() => notificationRightDrawer(false)} />
                        </Tooltip>
                    </div>
                </div>
            </div>
            <Divider />
            <div class="awv-recored-right-sidebar-form">
                {isLoading == false ?
                    <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                        {/* <div className="col-lg-12 align-items-center mb-3 mt-3">
                            <div className='mb-1'><strong>HCC CODE</strong></div>
                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                <Controller
                                    className="input-control"
                                    name="HCC_Code"
                                    value={editFormData?.HCC_Code}
                                    control={control}
                                    render={({ field }) => (
                                        <TextField  {...field} variant="outlined" />
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.HCC_Code?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>*/}
                        <div className="col-lg-12 align-items-center mb-3 mt-3">
                            <div className='mb-1'><strong>AWE SOURCE <span style={{ color: 'red' }}> *</span></strong></div>
                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                <Controller
                                    className="input-control"
                                    name="awe_source"
                                    value={editFormData?.awe_source}
                                    control={control}
                                    render={({ field }) => (
                                        // <TextField  {...field} variant="outlined" />
                                        <Select
                                            {...field}
                                            labelId="module-multiple-checkbox-label"
                                            id="module-multiple-checkbox"
                                            // value={selectModule}
                                            // onChange={handleChange}
                                            label="Module"
                                            variant="outlined"
                                            // MenuProps={MenuProps}
                                            menuPlacement="top"
                                        >
                                            <MenuItem key={'Provider'} value={'Provider'} >
                                                Provider
                                            </MenuItem>
                                            <MenuItem key={'HCD'} value={'HCD'} >
                                                HCD
                                            </MenuItem>
                                            <MenuItem key={'Applecare'} value={'Applecare'} >
                                                Applecare
                                            </MenuItem>
                                        </Select>
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.awe_source?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>
                        <div className="col-lg-12 align-items-center mb-3 mt-3">
                            <div className='mb-1'><strong>RECEIVE DATE <span style={{ color: 'red' }}> *</span></strong></div>
                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                <Controller
                                    className="input-control"
                                    name="receiver_date"
                                    value={Moment(editFormData?.receive_date).format("YYYY-MM-DD")}
                                    control={control}
                                    render={({ field }) => (
                                        <TextField type="date" {...field} variant="outlined" inputProps={{ max: Moment().format("YYYY-MM-DD") }} />
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.receiver_date?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>
                        <div className="col-lg-12 align-items-center mb-3 mt-3">
                            <div className='mb-1'><strong>RECEIVED METHOD <span style={{ color: 'red' }}> *</span></strong></div>
                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                <Controller
                                    className="input-control"
                                    name="received_method"
                                    value={editFormData?.received_method}
                                    control={control}
                                    render={({ field }) => (
                                        // <TextField  {...field} variant="outlined" />
                                        <Select
                                            {...field}
                                            labelId="module-multiple-checkbox-label"
                                            id="module-multiple-checkbox"
                                            // value={selectModule}
                                            // onChange={handleChange}
                                            label="Module"
                                            variant="outlined"
                                            // MenuProps={MenuProps}
                                            menuPlacement="top"
                                        >
                                           {/* correct the spell of emai to email  add cozeva in dropdown*/}
                                            <MenuItem key={'Secure email'} value={'Secure email'} >
                                                Secure email
                                            </MenuItem>
                                            <MenuItem key={'Fax'} value={'Fax'} >
                                                Fax
                                            </MenuItem>
                                            <MenuItem key={'FTP'} value={'FTP'} >
                                                FTP
                                            </MenuItem>
                                            <MenuItem key={'Cozeva'} value={'Cozeva'} >
                                                Cozeva        
                                            </MenuItem>
                                        </Select>
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.received_method?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>
                        
                        <div className="col-lg-12 align-items-center mb-3 mt-3">
                            <div className='mb-1'><strong>SERVICE DATE <span style={{ color: 'red' }}> *</span></strong></div>
                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                <Controller
                                    className="input-control"
                                    name="service_date"
                                    value={Moment(editFormData?.service_date).format("YYYY-MM-DD")}
                                    control={control}
                                    render={({ field }) => (
                                        <TextField type="date" {...field} variant="outlined" inputProps={{ max: Moment().format("YYYY-MM-DD") }} />
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.service_date?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>

                        <div className="col-lg-12 align-items-center mb-3 mt-3">
                            <div className='mb-1'><strong>ADDITIONAL DATA <span style={{ color: 'red' }}> *</span></strong></div>
                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                <Controller
                                    className="input-control"
                                    name="additional_data"
                                    value={editFormData?.additional_data}
                                    control={control}
                                    render={({ field }) => (
                                        // <TextField  {...field} variant="outlined" />
                                        <Select
                                            {...field}
                                            labelId="module-multiple-checkbox-label"
                                            id="module-multiple-checkbox"
                                            // value={selectModule}
                                            // onChange={handleChange}
                                            label="Module"
                                            variant="outlined"
                                            // MenuProps={MenuProps}
                                            menuPlacement="top"
                                        >
                                            <MenuItem key={'1'} value={'1'} >
                                                Yes
                                            </MenuItem>
                                            <MenuItem key={'0'} value={'0'} >
                                                No
                                            </MenuItem>
                                        </Select>
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.additional_data?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>
                        <div className="col-lg-12 align-items-center mb-3 mt-3">
                            {/* correct the spell of telehelth to TELEHEALTH */}
                            <div className='mb-1'><strong>TELEHEALTH <span style={{ color: 'red' }}> *</span></strong></div>
                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                <Controller
                                    className="input-control"
                                    name="telehealth_checkbox"
                                    value={editFormData?.telehealth_checkbox}
                                    control={control}
                                    render={({ field }) => (
                                        // <TextField  {...field} variant="outlined" />
                                        <Select
                                            {...field}
                                            labelId="module-multiple-checkbox-label"
                                            id="module-multiple-checkbox"
                                            // value={selectModule}
                                            // onChange={handleChange}
                                            label="Module"
                                            variant="outlined"
                                            // MenuProps={MenuProps}
                                            menuPlacement="top"
                                        >
                                            <MenuItem key={'1'} value={'1'} >
                                                Yes
                                            </MenuItem>
                                            <MenuItem key={'0'} value={'0'} >
                                                No
                                            </MenuItem>
                                        </Select>
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.telehealth_checkbox?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>
                        {/* Rendering Provider NPI changes - ID-305, ID-329 */}
                        <div className="col-lg-12 align-items-center mb-3 mt-3">
                            <div className='mb-1'><strong>RENDERING PROVIDER NPI </strong></div>
                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                <Controller
                                    className="input-control"
                                    name="rendering_provider_npi"
                                    value={editFormData?.rendering_provider_npi}
                                    control={control}
                                    render={({ field }) => (
                                        <Autocomplete
                                            value={providerId}
                                            onChange={(event, newValue) => {
                                                setProviderId(newValue);
                                                autoPopulateRenderingProvider(newValue)
                                            }}
                                            id="controllable-states-demo"
                                            options={providerIdData}
                                            sx={{ width: 318 }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" value={providerId}
                                                inputProps={{
                                                    ...params.inputProps,
                                                }}
                                                required={false}
                                            />}
                                        />
                                    )}
                                    rules={{
                                        required: false,
                                    }}
                                />
                                {errors?.rendering_provider_npi?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>
                        <div className="col-lg-12 align-items-center mb-3 mt-3">
                            <div className='mb-1'><strong>RENDERING PROVIDER NAME </strong></div>
                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                <Controller
                                    className="input-control"
                                    name="rendering_provider_name"
                                    value={editFormData?.rendering_provider_name}
                                    control={control}
                                    render={({ field }) => (
                                        <Autocomplete
                                            value={providerName}
                                            onChange={(event, newValue) => {
                                                setProviderName(newValue);
                                                autoPopulateRenderingProvider(newValue)
                                            }}
                                            id="controllable-states-demo"
                                            options={providerNameData}
                                            sx={{ width: 318 }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" value={providerName}
                                                inputProps={{
                                                    ...params.inputProps,
                                                }}
                                                required={false}
                                            />}
                                        />
                                    )}
                                    rules={{
                                        required: false,
                                    }}
                                />
                                {errors?.rendering_provider_npi?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>
                        <div className="col-lg-12 align-items-center mb-3 mt-3">
                            <div className='mb-1'><strong>SITE ID</strong></div>
                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                <Controller
                                    className="input-control"
                                    name="site_id"
                                    value={editFormData?.site_id}
                                    control={control}
                                    render={({ field }) => (
                                        <Autocomplete
                                            value={siteId}
                                            onChange={(event, newValue) => setSiteId(newValue)}
                                            id="controllable-states-demo"
                                            options={siteIdData}
                                            sx={{ width: 318 }}
                                            renderInput={(params) => <TextField {...params} variant="outlined" value={siteId}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    // required: params.inputProps.value.length === 0
                                                }}
                                            />}
                                        />
                                    )}
                                    rules={{
                                        required: false,
                                    }}
                                />
                                {errors?.site_id?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>
                        <div className="col-lg-12 align-items-center mb-3 mt-3">
                            <div className='mb-1'><strong>PPG</strong></div>
                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                <Controller
                                    className="input-control"
                                    name="ppg"
                                    value={editFormData?.ppg}
                                    control={control}
                                    render={({ field }) => (
                                        <Autocomplete
                                            value={ppg}
                                            id="controllable-states-demo"
                                            options={ppgdata}
                                            sx={{ width: 318 }}
                                            onChange={(event, newValue) => setPPG(newValue)}
                                            renderInput={(params) => <TextField {...params} variant="outlined" value={ppg}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    // required: params.inputProps.value.length === 0
                                                }}
                                            />}
                                        />
                                    )}
                                    rules={{
                                        required: false,
                                    }}
                                />
                                {errors?.ppg?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>
                        <div className="col-lg-12 align-items-center mb-3 mt-3">
                            <div className='mb-1'><strong>REMARKS</strong></div>
                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                <Controller
                                    className="input-control"
                                    name="remarks"
                                    value={editFormData?.remarks}
                                    control={control}
                                    render={({ field }) => (
                                        <TextField  {...field} variant="outlined" />
                                    )}
                                    rules={{
                                        required: false,
                                    }}
                                />
                                {errors?.remarks?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>
                        <div className="col-lg-12 align-items-center mb-3">
                            <Button type='button' variant="contained" color="grey" onClick={() => notificationRightDrawer(false)}>CANCEL</Button>
                            <Button type='submit'
                                className={'mr-2 btn-custom-primary mr-2 ml-2'}
                                variant="contained"
                                disabled={formSubmitisLoading && formSubmitisLoading == true ? true : false}
                            >
                                {(props?.aweRightSidebarType?.status && props?.aweRightSidebarType?.status == '1') ?
                                    'ADD'
                                    :
                                    'UPDATE'
                                }
                            </Button>
                        </div>
                    </form>
                    : isLoading ?
                        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                            <CircularProgress />
                        </div>
                        :
                        <></>
                }
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(AWVMemberDetailsCoodinator);

