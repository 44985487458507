import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { Chart } from "react-google-charts";
import { connect } from 'react-redux';

const BarChartDiffDataAWV = (props) => {
    const chart = useRef(null);
    const [type, setType] = useState('');
    const [data, setData] = useState([]);

    const dataOld = [
        ["Name", String(props.yearSelect - 1)],
        ["Jan", 0],
    ];

    const dataNew = [
        ["Name", String(props.yearSelect)],
        ["Jan", 0],
    ];

    useEffect(() => {
        if (props?.data?.length > 0 && props.data[0] && props.data[1]) {
            var oldCustomArray = [];
            var newCustomArray = [];
            var indexOld = 0;
            var indexNew = 0;
            if (props.data[0]) {
                for (const key in props.data[0]) {
                    var customArray = [];
                    if (indexOld == 0) {
                        customArray[0] = "Month";
                        if (props.data[0]?.payment_year) {
                            customArray[1] = props.data[0]?.payment_year.toString();
                        }
                    } else {
                        customArray[0] = key;
                        if (props.data[0]?.[key] || props.data[0]?.[key] == 0) {
                            customArray[1] = props.data[0]?.[key];
                        }
                    }
                    oldCustomArray[indexOld] = customArray;
                    indexOld++;
                };
            }

            if (props.data[1]) {
                for (const key in props.data[1]) {
                    var customArray = [];
                    if (indexNew == 0) {
                        customArray[0] = "Month";
                        if (props.data[1]?.payment_year) {
                            customArray[1] = props.data[1]?.payment_year.toString();
                        }
                    } else {
                        customArray[0] = key;
                        if (props.data[1]?.[key] || props.data[1]?.[key] == 0) {
                            customArray[1] = props.data[1]?.[key];
                        }
                    }
                    newCustomArray[indexNew] = customArray;
                    indexNew++;
                };
            }
            setData({
                old: oldCustomArray,
                new: newCustomArray,
            });
        } else {
            setData({
                old: dataOld,
                new: dataNew,
            });
        }
    }, [props.data]);

    // const data = [
    //     ["Month", "2021", "2022"],
    //     ["January", 1000, 400],
    //     ["February ", 1170, 460],
    //     ["March", 660, 1120],
    //     ["April", 1030, 540],
    //     ["May", 1030, 540],
    //     ["June", 1030, 540],
    //     ["July", 1030, 540],
    //     ["August", 1030, 540],
    //     ["September", 1030, 540],
    //     ["Octomber", 1030, 540],
    //     ["November", 1030, 540],
    //     ["December", 1030, 540],
    // ];

    const options = {
        title: "CUMULATIVE (SUM OF ALL MONTHS WITH YEAR)",
        hAxis: { title: "AWE VISITS", titleTextStyle: { color: "#333" } },
        legend: { position: "bottom" },
        vAxis: { title: "Month", titleTextStyle: { color: "#333", width: "15px" }, minValue: 0 },
        chartArea: { width: "65%", height: "70%" },
        colors: ['#1A9698'],
        tooltip: { isHtml: true }
    };

    return (
        <Chart
            chartType="BarChart"
            width="100%"
            height="400px"
            diffdata={data}
            options={options}
        />
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
    };
}
export default connect(mapStateToProps)(BarChartDiffDataAWV);