import React, { useEffect, useRef, useState } from 'react'
import HeaderFilter from '../../../components/layout/aweLayout/HeaderFilter'
import SubHeaderFilter from '../../../components/layout/aweLayout/SubHeaderFilter'
import IconButton from '../../../components/CustomButtons/IconButton'
import EditPenIcon from '../../../components/icons/EditPenIcon';
import EyeIcon from '../../../components/icons/EyeIcon';
import PinIcon from '../../../components/icons/PinIcon';
import ListBoxIcon from '../../../components/icons/ListBoxIcon';
import PlusFileIcon from '../../../components/icons/PlusFileIcon';
import BigArrowRightIcon from '../../../components/icons/BigArrowRightIcon';
import Button from '../../../components/layout/aweLayout/Button';
import FlagIcon from '../../../components/icons/FlagIcon';
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { connect } from 'react-redux'
import history from "../../../history"
import { currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload, currentAWERightSidebarType } from '../../../store/actions'
import { MenuItem, Select, LinearProgress } from '@mui/material'
import CustomAweServerSideDatatable from '../../../components/TableGrids/CustomAweServerSideDatatable'
import AWVApi from '../../../assets/constants/AWVRafservice.Instance';
import Moment from 'moment';
import Menu from '@mui/material/Menu';
import { toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AttachmentIcon from '@mui/icons-material/Attachment';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FormControlLabel from '@mui/material/FormControlLabel';
import DotsMenuIcon from '../../../components/icons/DotsMenuIcon';
import AWVCoderWorkBench from '../../../assets/constants/AWVCoderWorkBenchRafservice.Instance';
import {LogFileUploadToS3} from '../../../assets/constants/LogFileUploadToS3'

const AweRecordPage = (props) => {
    const [isControlUser] = useState(JSON.parse(localStorage.getItem('controlUser')));
    const [expandedRows, setExpandedRows] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const openAction = Boolean(anchorEl);
    const [circularDataList, setCircularDataList] = useState({});
    const [siteIdSelect, setsiteIdSelect] = useState('');
    const [providerAddressGroupsSelect, setProviderAddressGroupsSelect] = useState([]);
    const [providerAddressGroupsNameSelect, setProviderAddressGroupsNameSelect] = useState([]);
    const [citySelect, setCitySelect] = useState('');
    const [siteAddressSelect, setSiteAddressSelect] = useState('');
    const [zipSelect, setZipSelect] = useState('');
    const [formAttached, setFormAttached] = useState([]);
    const [formAttachedFlag, setFormAttachedFlag] = useState([0]);
    const [formAssignedFlag, setFormAssignedFlag] = useState('');
    const [formWithoutAtachmentTotal, setFormWithoutAtachmentTotal] = useState(0);
    const [currentActionStatus, setCurrentActionStatus] = useState('');
    const [currentActionAWVID, setCurrentActionAWVID] = useState('');
    const [currentActionUserId, setCurrentActionUserId] = useState('');
    const [selectOnHoldStatus, setSelectOnHoldStatus] = useState('N');
    const [modelFormRole, setModelFormRole] = useState(0);
    const [logMessages, setLogMessages] = useState([]); // Array to store log messages
    const writeToLogFile = (message) => {
       setLogMessages(prevLogMessages => [...prevLogMessages, `${new Date().toISOString()} - ${message}`]);
   };
   const [generateLog, setGenerateLog] = useState(false);
   const [isLoading, setIsLoading] = useState(true);
    const [state, setState] = useState({
        chart_chase_record: null,
    });

    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            loadCircularDataTable();
            fetchFormWithoutAttachmentCount();
        }        
    }, [props.yearSelect, props.providerGroupSelect, props.lobSelect]);
    
    useEffect(() => {
        if (props.aweRightSidebarCloseDatatableReload && props.aweRightSidebarCloseDatatableReload == true) {
            loadCircularDataTable();
            fetchFormWithoutAttachmentCount();
        }        
    }, [props.aweRightSidebarCloseDatatableReload]);

    useEffect(() => {
        if (props.aweRightSidebarCloseDatatableReload && props.aweRightSidebarCloseDatatableReload == true) {
            loadCircularDataTable();
            fetchFormWithoutAttachmentCount();
        }
    }, [props.aweRightSidebarCloseDatatableReload]);


    const toggleRow = (rowIndex) => {
        const isRowExpanded = expandedRows.includes(rowIndex);
        if (isRowExpanded) {
            setExpandedRows(expandedRows.filter((row) => row !== rowIndex));
        } else {
            setExpandedRows([...expandedRows, rowIndex]);
        }
    };

    const expandedRowsReset = () => {
        setExpandedRows([]);
    };

    const handleChangeFormAssigned = (event) => {
        if (event.target.checked == true) {
            setFormAssignedFlag(isControlUser?.user_id);
        } else {
            setFormAssignedFlag("");
        }
    }

    const aweRecordColumns = [
        {
            name: "awv_backend_id",
            label: "Action",
            options: {
                showColumn: true,
                filter: false,
                filterType: 'select',
                search: false,
                sort: false,
                width: '202px',
                otherColumnName: 'all',
                customBodyRender: (value, otherColumnValueArray = '', indexRow = '') => {
                    let controlUser = JSON.parse(localStorage.getItem('controlUser'));
                    return (
                        <>
                            <div class="flex_item_cs gap10">
                                <Tooltip arrow placement="top" title="">
                                    <div>
                                        <IconButton className={"btn-sm "} type="button" onClick={() => toggleRow(indexRow)} data-toggle="collapse" data-target={"#collapseTable" + indexRow} aria-expanded={expandedRows.includes(indexRow)} aria-controls="collapseExample">
                                            {expandedRows.includes(indexRow) ?
                                                <div className={"collapse_btn"}>
                                                    <BigArrowRightIcon className="w16 h16" />
                                                </div>
                                                :
                                                <div className={"collapse_btn collapsed"}>
                                                    <BigArrowRightIcon className="w16 h16" />
                                                </div>
                                            }
                                        </IconButton>
                                    </div>
                                </Tooltip>
                                <Tooltip arrow placement="top" title={"View"}>
                                    <div>
                                        <IconButton className={"text-primary"} buttonType={"default"} onClick={() => notificationRightDrawer(true, 'viewAweFileUpload', value)}>
                                            <EyeIcon className="w24 h24" />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                                <Tooltip arrow placement="top" title={"Additional Attachment"} onClick={() => notificationRightDrawer(true, 'additionalAttachments', value, '', otherColumnValueArray?.cin)}>
                                    <div>
                                        <IconButton className={"text-primary"} buttonType={"default"}>
                                            <PinIcon className="w24 h24" />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                                <Tooltip arrow placement="top" title={"Timeline"} onClick={() => notificationRightDrawer(true, 'timeline', value, '', otherColumnValueArray?.cin)}>
                                    <div>
                                        <IconButton className={"text-primary"} buttonType={"default"}>
                                            <ListBoxIcon className="w24 h24" />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                                <Tooltip arrow placement="top" title={"Quick notes"} onClick={() => notificationRightDrawer(true, 'quickNotes', value, '', otherColumnValueArray?.cin)}>
                                    <div>
                                        <IconButton className={"text-primary"} buttonType={"default"}>
                                            <PlusFileIcon className="w24 h24" />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                                {otherColumnValueArray?.user_assigned && controlUser?.user_id && otherColumnValueArray?.user_assigned == controlUser?.user_id && otherColumnValueArray?.form_status && otherColumnValueArray?.form_status != 'PaymentDone' && otherColumnValueArray?.form_status != 'void' ?
                                    <>
                                        <IconButton
                                            className={"btn-sm"}
                                            style={{ padding: '2px' }}
                                            aria-label="more"
                                            id="long-button"
                                            name={value}
                                            aria-controls={openAction ? 'long-menu' : undefined}
                                            aria-expanded={openAction ? 'true' : undefined}
                                            aria-haspopup="true"
                                            onClick={(event) => handleClickAction(event, value, otherColumnValueArray?.form_status, otherColumnValueArray?.user_assigned, otherColumnValueArray?.awe_display_id, otherColumnValueArray?.on_hold)}
                                        >
                                            <DotsMenuIcon className="w16 h16" />
                                        </IconButton>
                                        <Menu
                                            id="long-menu"
                                            MenuListProps={{
                                                'aria-labelledby': 'long-button',
                                            }}
                                            anchorEl={anchorEl}
                                            open={openAction}
                                            onClose={handleCloseAction}
                                            PaperProps={{
                                                style: {
                                                    maxHeight: 45 * 4.5,
                                                    width: '25ch',
                                                },
                                            }}
                                        >
                                            {(currentActionStatus != '0' && currentActionStatus != '1' && currentActionStatus != '8' && currentActionStatus != '6' && currentActionStatus != '7' && currentActionStatus != '5' && currentActionStatus != '9') ?
                                                <MenuItem key={otherColumnValueArray?.awe_display_id} onClick={() => notificationRightDrawer(true, 'statusAweFileUpload', currentActionAWVID, (currentActionStatus + 1))}>
                                                    <CheckCircleIcon className="mr-3" />{'Approve'}
                                                </MenuItem>
                                                : currentActionStatus == '8' ?
                                                    <MenuItem key={otherColumnValueArray?.awe_display_id} onClick={() => notificationRightDrawer(true, 'statusAweFileUpload', currentActionAWVID, (2 + 1))}>
                                                        <CheckCircleIcon className="mr-3" />{'Approve'}
                                                    </MenuItem>
                                                    :
                                                    <></>
                                            }

                                            {(currentActionStatus != '0' && currentActionStatus != '1' && currentActionStatus != '5' && currentActionStatus != '6' && currentActionStatus != '7' && currentActionStatus != '8' && currentActionStatus != '9') ?
                                                <MenuItem key={otherColumnValueArray?.awe_display_id} onClick={() => notificationRightDrawer(true, 'statusAweFileUpload', currentActionAWVID, 2)}>
                                                    <CancelIcon className="mr-3" />{'Reject'}
                                                </MenuItem>
                                                :
                                                <></>
                                            }
                                            {/* {(currentActionStatus != '0' && currentActionStatus != '1' && currentActionStatus != '8') ? */}
                                            {/* removing check that restricting onhold/unhold option display at reject status */}
                                            {/* hide on-hold from PFPA stage */}
                                            {(currentActionStatus != '0' && currentActionStatus != '1' && currentActionStatus != '4' && currentActionStatus != '5' && currentActionStatus != '9') ?
                                                <MenuItem key={otherColumnValueArray?.awe_display_id} onClick={() => notificationRightDrawer(true, 'onHoldAweFileUpload', currentActionAWVID, 2)}>
                                                    <CancelIcon className="mr-3" />{(selectOnHoldStatus && selectOnHoldStatus == "Y") ? 'Un Hold' : 'On Hold'}
                                                </MenuItem>
                                                :
                                                <></>
                                            }
                                            {(isControlUser && currentActionUserId == isControlUser.user_id && (currentActionStatus == '0' || currentActionStatus == '1' || currentActionStatus == '8')) ?
                                                <MenuItem key={otherColumnValueArray?.awe_display_id} onClick={() => notificationRightDrawer(true, 'addAweFileUpload', currentActionAWVID)}>
                                                    <ModeEditOutlineIcon className="mr-3" />{'Edit'}
                                                </MenuItem>
                                                :
                                                <></>
                                            }
                                            {/* stage change dropdown */}
                                            {(currentActionStatus != '0' && currentActionStatus != '1' && currentActionStatus != '6' && currentActionStatus != '7' && currentActionStatus != '2' && (currentActionStatus != '2' && currentActionStatus != '8')) ?
                                                <MenuItem key={otherColumnValueArray?.awe_display_id} onClick={() => notificationRightDrawer(true, 'stageChange', currentActionAWVID, (currentActionStatus))}>
                                                    <ArrowCircleLeftRoundedIcon className="mr-3" />{'Stage Change'}
                                                </MenuItem>
                                                :
                                                <></>
                                            }
                                            {(currentActionStatus != '0' && currentActionStatus != '1' && currentActionStatus != '5' && currentActionStatus != '6' && currentActionStatus != '7' && currentActionStatus != '9') ?
                                                <MenuItem key={otherColumnValueArray?.awe_display_id} onClick={() => notificationRightDrawer(true, 'statusAweFileUpload', currentActionAWVID, 7)}>
                                                    <DeleteRoundedIcon className="mr-3" />{'Mark as Void'}
                                                </MenuItem>
                                                :
                                                <></>
                                            }
                                        </Menu>
                                    </>
                                    :
                                    <></>
                                }
                            </div>
                        </>
                    );
                }
            }
        },
        {
            name: "user_assigned",
            label: "Assign me",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: false,
                width: '125px',
                otherColumnName: 'all',
                customBodyRender: (value, otherColumnValueArray = '', indexRow = '') => {
                    return (
                        <>
                            <div class="flex_item_cs gap8">
                                {value && value != '' ?
                                    <FormControlLabel
                                        className="custom_checkbox m-0 gap8"
                                        control={
                                            <Checkbox
                                                className="p-0"
                                                id={'checkbox' + indexRow}
                                                key={otherColumnValueArray?.cin}
                                                onClick={() => handleAssignMeAction(otherColumnValueArray, 'unchecked')}
                                                checked={true}
                                            />
                                        }
                                    />
                                    :
                                    <FormControlLabel
                                        className="custom_checkbox m-0 gap8"
                                        control={
                                            <Checkbox
                                                className="p-0"
                                                id={'checkbox' + indexRow}
                                                key={otherColumnValueArray?.cin}
                                                onClick={() => handleAssignMeAction(otherColumnValueArray, 'checked')}
                                            />
                                        }
                                    />
                                }
                                {otherColumnValueArray?.on_hold && otherColumnValueArray?.on_hold == 'Y' ?
                                    <Tooltip title="On Hold">
                                        <div>
                                            <FlagIcon className="w24 h24" />
                                        </div>
                                    </Tooltip>
                                    :
                                    <></>
                                }
                            </div>
                        </>
                    )
                }
            }
        },
        {
            name: "cin",
            label: "CIN/RXID",
            options: {
                showColumn: true,
                filter: true,
                filterType: '',
                search: false,
                sort: true,
                width: '112px',
                otherColumnName: 'awe_display_id',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <span style={{ width: '100px', color: "#1a9698", cursor: "pointer" }} onClick={() => showUserDetailsLoad(value, otherColumnValue)}>
                            {value}
                        </span>
                    );
                },
            }
        },
        {
            name: "awe_display_id",
            label: "AWE ID",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '115px'
            }
        },
        {
            name: "form_delivery_date",
            label: "Form Delivery Date",
            options: {
                showColumn: true,
                filter: true,
                filterType: 'date-range',
                search: false,
                sort: true,
                width: '150px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            {value && value != '' && value != '0000-00-00 00:00:00'?
                                Moment(value).format("YYYY-MM-DD")
                                :
                                ''
                            }
                        </>
                    )
                }
            }
        },
        {
            name: "updated_on",
            label: "Updated on",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '140px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            {value && value != '' ?
                                Moment(value).format("YYYY-MM-DD")
                                :
                                ''
                            }
                        </>
                    )
                }

            }
        },
        {
            name: "batch_id",
            label: "Batch ID",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '115px',
            }
        },
        {
            name: "progress",
            label: "Progress",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '130px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            <div className="flex_item_cs gap10">
                                <span className='flex-shrink-0'>
                                    {`${Math.round(value,)}%`}
                                </span>
                                <LinearProgress className="w-100" sx={{ height: 10 }} variant="determinate" value={value} />
                            </div>
                        </>
                    );
                }
            }
        },
        {
            name: "form_status",
            label: "Status",
            options: {
                showColumn: true,
                filter: true,
                filterType: 'select',
                search: false,
                sort: true,
                width: '104px',
                filterOptions: [
                    'PendingUpload',
                    'pendingforCoordinator',
                    'pendingforCoder',
                    'pendingForPaymentApproval',
                    'ApprovedForPayment',
                    'ApprovedNoPayment',
                    'PaymentDone',
                    'Reject',
                    'void'
                ],
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            <Tooltip title={value} arrow placement="top">
                                <div>
                                    <div class={(value == 'PaymentDone') ? "status_badge badge_primary" : "status_badge badge_warning"}>
                                        {handleStatusName(value)}
                                    </div>
                                </div>
                            </Tooltip>
                        </>
                    );
                }
            }
        },
        {
            name: "provider_group",
            label: "Provider Group",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '170px',
            }
        },
        {
            name: "assignedUserName",
            label: "Assigned to",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '150px',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <Tooltip title={value} arrow placement="top">
                            <div className="table-short-text">
                                {value}
                            </div>
                        </Tooltip>
                    );
                }
            }
        },
        {
            name: "on_hold",
            label: "On Hold",
            options: {
                showColumn: true,
                filter: true,
                filterType: 'select',
                search: false,
                sort: true,
                width: '115px',
                filterOptions: [
                    'Yes',
                    'No'
                ],
            }
        },


        {
            name: "awe_display_id",
            label: "AWE Display Id",
            options: {
                showColumn: false,
                filter: true,
                filterType: '',
                search: false,
                sort: true,
                width: '157px',
            },
        },
        {
            name: "awv_backend_id",
            label: "AWV Display Id",
            options: {
                showColumn: false,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '157px',
            }
        },
        {
            name: "form_url",
            label: "Form Url",
            options: {
                showColumn: false,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '150px',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <Tooltip title={value} arrow placement="top">
                            <div className="table-short-text">
                                {value}
                            </div>
                        </Tooltip>
                    );
                }
            }
        },
        {
            name: "is_nlp_processed",
            label: "Is NLP Processed",
            options: {
                showColumn: false,
                filter: true,
                filterType: 'select',
                search: false,
                sort: true,
                width: '172px',
                filterOptions: [
                    'Yes',
                    'No'
                ],
            }
        },
        
    ];
      
    let aweRecordSubColumns = [
        {
            name: "dx_code",
            label: "Dx CODE",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: false,
                width: '100px',
                otherColumnName: 'dx_description',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <Tooltip title={otherColumnValue ? `${value} - ${otherColumnValue}` : value} arrow placement="top">
                            <div className="table-short-text">
                            {otherColumnValue ? `${value} - ${otherColumnValue}` : value}
                            </div>
                        </Tooltip>
                    );
                }
            }
        },
        {
            name: "nlp_captured",
            label: "NLP Reported",
            cols: "2",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: false,
                width: '100px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            {value && value != '' ?
                                <Tooltip title={value == 'Y' ? 'Yes' : 'No'} arrow placement="top">
                                    <div className="table-short-text">
                                        {value == 'Y' ? 'Yes' : 'No'}
                                    </div>
                                </Tooltip>
                                :
                                <div>

                                </div>
                            }
                        </>
                    );
                }
            }
        },
        {
            name: "historical",
            label: "Historic",
            cols: "2",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: false,
                width: '100px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            {value && value != '' ?
                                <Tooltip title={value == 'Y' ? 'Yes' : 'No'} arrow placement="top">
                                    <div className="table-short-text">
                                        {value == 'Y' ? 'Yes' : 'No'}
                                    </div>
                                </Tooltip>
                                :
                                <div>

                                </div>
                            }
                        </>
                    );
                }
            }
        },
        {
            name: "present_in_form",
            label: "Printed in AWE Form",
            cols: "2",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: false,
                width: '100px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            
                                <Tooltip title={String(value) !== "" ? (String(value) === '0' ? 'No' : 'Yes') : ''} arrow placement="top">
                                    <div className="table-short-text">
                                        {/* {alert(String(value))} */}
                                        {String(value) !== "" ? (String(value) === '0' ? 'No' : 'Yes') : ''}

                                    </div>
                                </Tooltip>
                            
                        </>
                    );
                }
            }
        },
        {
            name: "STATUS",
            label: "Coder Action",
            cols: "2",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: false,
                width: '100px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            {value == 'Rejected' ? 'Rejected' : ''}
                            {value == 'Accepted' ? 'Accepted' : '' }
                            {value == 'Pending for clarification' ? 'Pending for Clarification' : ''}
                        </>
                    );
                }
            }
        },
    ];

    const insertionIndex = 1;
    if (String(props.lobSelect) == '2') {
        aweRecordSubColumns.splice(insertionIndex, 0,
            {
                name: "model_1",
                label: "HCC V24",
                cols: "2",
                options: {
                    showColumn: true,
                    filter: false,
                    filterType: '',
                    search: false,
                    sort: false,
                    width: '100px',
                    otherColumnName: 'model_1_description',
                    customBodyRender: (value, otherColumnValue = '') => {
                        return (
                            <>
                                {value && (value != 'No HCC' && value != '') ?
                                    <Tooltip title={otherColumnValue ? `${value} - ${otherColumnValue}` : value} arrow placement="top">
                                        <div className="table-short-text">
                                            {otherColumnValue ? `${value} - ${otherColumnValue}` : value}
                                        </div>
                                    </Tooltip>
                                    :
                                    <div>

                                    </div>
                                }
                            </>
                        );
                    }
                }
            },
            {
                name: "model_2",
                label: "HCC V28",
                cols: "2",
                options: {
                    showColumn: false,
                    filter: false,
                    filterType: '',
                    search: false,
                    sort: false,
                    width: '150px',
                    otherColumnName: 'model_2_description',
                    customBodyRender: (value, otherColumnValue = '') => {
                        return (
                            <>
                                {value && (value != 'No HCC' && value != '') ?
                                    
                                    <Tooltip title={otherColumnValue ? `${value} - ${otherColumnValue}` : value} arrow placement="top">
                                        <div className="table-short-text">
                                        {otherColumnValue ? `${value} - ${otherColumnValue}` : value}
                                        </div>
                                    </Tooltip>
                                    :
                                    <div>
                                       
                                    </div>
                                }
                            </>
                        );
                    }
                }
            },
        )
    } else {
        aweRecordSubColumns.splice(insertionIndex, 0,
            {
                name: "model_1",
                label: "HCC",
                cols: "2",
                options: {
                    showColumn: true,
                    filter: false,
                    filterType: '',
                    search: false,
                    sort: false,
                    width: '100px',
                    otherColumnName: 'model_1_description',
                    customBodyRender: (value, otherColumnValue = '') => {
                        return (
                            <>
                                {value && (value != 'No HCC' && value != '') ?
                                    <Tooltip title={otherColumnValue ? `${value} - ${otherColumnValue}` : value} arrow placement="top">
                                        <div className="table-short-text">
                                        {otherColumnValue ? `${value} - ${otherColumnValue}` : value}
                                        </div>
                                    </Tooltip>
                                    :
                                    <div>
                                        
                                    </div>
                                }
                            </>
                        );
                    }
                }
            },
        )
    }

    const showUserDetailsLoad = (memberId, awe_display_id) => {
        loadformAWEProcessedRecord(memberId, awe_display_id)
        history.push({
            pathname:'/new_awe/awe-analytics-summary',
            search: `?#`,
            state: {
                cin: memberId,
                awe_display_id: awe_display_id,
                // chart_chase_record:chart_chase_record
            }});    
            setGenerateLog(true)
        // history.push('/new_awe/awe-analytics-summary?cin=' + memberId);
    };
    const loadformAWEProcessedRecord = async (cinMemberId, awe_display_id) => {
        AWVApi.get('/get-awe-processed-record-details?year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + '&cin=' + btoa(cinMemberId) + '&awe_display_id=' + btoa(awe_display_id) )
            .then(res => {
                if (res.data && res.data[0]) {
                    setState({
                        chart_chase_record: res.data[0].chart_chase_record
                    });
                    setIsLoading(false);
                    
                } else {
                    // setId('');
                    setIsLoading(false);
                }
            })
            .catch(err => {
                console.log(err)
            })
    }


    const handleCloseAction = () => {
        setAnchorEl(null);
    };

    const handlechange = (event) => {
        if (event.target.checked == true) {
            setFormAttachedFlag("2,3,4,5,6,7,8");
        } else {
            setFormAttached([]);
            setFormAttachedFlag(0);
        }
    }

    const handleStatusDesc = (status) => {
        if (status && status == 'DefaultStatus') {
            return 'Default Status';
        } else if (status && status == 'PendingUpload') {
            return 'Pending Upload';
        } else if (status && status == 'pendingforCoordinator') {
            return 'Pending for Coordinator';
        } else if (status && status == 'pendingforCoder') {
            return 'Pending for Coder';
        } else if (status && status == 'pendingForPaymentApproval') {
            return 'Pending for Payment Approval';
        } else if (status && status == 'ApprovedForPayment') {
            return 'Approved for Payment';
        } else if (status && status == 'Reject') {
            return 'Reject';
        } else if (status && status == 'PaymentDone') {
            return 'Payment Done';
        } else if (status && status == 'void') {
            return 'Void';
        } else if (status && status == 'ApprovedNoPayment') {
            return 'Approved - No Payment';
        } else {
            return '';
        }
    }

    const handleStatusName = (status) => {
        if (status && status == 'DefaultStatus') {
            return 'DS';
        } else if (status && status == 'PendingUpload') {
            return 'PU';
        } else if (status && status == 'pendingforCoordinator') {
            return 'PFCT';
        } else if (status && status == 'pendingforCoder') {
            return 'PFC';
        } else if (status && status == 'pendingForPaymentApproval') {
            return 'PFPA';
        } else if (status && status == 'ApprovedForPayment') {
            return 'AFP';
        } else if (status && status == 'PaymentDone') {
            return 'PD';
        } else if (status && status == 'Reject') {
            return 'REJ';
        } else if (status && status == 'void') {
            return 'Void';
        } else if (status && status == 'ApprovedNoPayment') {
            return 'ANP';
        } else {
            return '';
        }
    }

    const editHCCDetails = (value, CinId, action = 'Edit', aweId) => {
        if ((value.id && value.id != '') && action == 'Edit') {
            props.currentAWERightSidebarType({ 'type': 'memberHccDetailsReport', 'cin_id': CinId, 'status': 0, 'DX_code': value.DX_code, 'HCC_Code': value.HCC_Code, 'rxc_code': value.rxc_code, 'ndc_code': value.ndc_code, 'cpt_code': value.cpt_code, 'id': value.id });
            props.currentAWERightSidebar(true);
        } else {
            props.currentAWERightSidebarType({ 'type': 'memberHccDetailsReport', 'cin_id': CinId, 'status': 1, 'aweId': aweId });
            props.currentAWERightSidebar(true);
        }
    };

    const notificationRightDrawer = (open, typeValue, valueId, statusId = '', cinId = '') => {
        setAnchorEl('');
        props.currentAWERightSidebarType({ 'type': typeValue, 'awv_backend_id': valueId, 'cin_id': cinId, 'status': statusId });
        props.currentAWERightSidebar(open);
    };

    const notificationRightDrawerSubColumn = (open, typeValue, valueId, statusId = '', cinId = '') => {
        props.currentAWERightSidebarType({ 'type': typeValue, 'awv_id': valueId.awv_id, 'cin_id': valueId.cin, 'status': statusId, 'title': 'HCC CHECKLIST NOTES', 'hcc_code': valueId.HCC_Code, 'hcc_description': valueId.HCC_Description, 'risk_code_type': valueId.risk_code_type });
        props.currentAWERightSidebar(open);
    };

    const handleClickAction = (event, awvId, status, user_assigned, stage_id, onHoldStatus = 'N') => {
        setAnchorEl(event.currentTarget);
        setCurrentActionAWVID(awvId);
        setCurrentActionUserId(user_assigned);
        setSelectOnHoldStatus(onHoldStatus);

        if (status && status == 'DefaultStatus') {
            setCurrentActionStatus(0);
            setModelFormRole(0);
        } else if ((status && status == 'PendingUpload') || stage_id == 1) {
            setCurrentActionStatus(1);
            setModelFormRole(2);
        } else if (status && status == 'pendingforCoordinator') {
            setCurrentActionStatus(2);
            setModelFormRole(3);
        } else if (status && status == 'pendingforCoder') {
            setCurrentActionStatus(3);
            setModelFormRole(4);
        } else if (status && status == 'pendingForPaymentApproval') {
            setCurrentActionStatus(4);
            setModelFormRole(4);
        } else if (status && status == 'ApprovedForPayment') {
            setCurrentActionStatus(5);
            setModelFormRole(4);
        } else if (status && status == 'PaymentDone') {
            setCurrentActionStatus(6);
            setModelFormRole(4);
        } else if (status && status == 'void') {
            setCurrentActionStatus(7);
            setModelFormRole(4);
        } else if (status && status == 'Reject') {
            setCurrentActionStatus(8);
            setModelFormRole(4);
        } else if (status && status == 'ApprovedNoPayment') {
            setCurrentActionStatus(9);
            setModelFormRole(4);
        } else {
            setCurrentActionStatus(1);
            setModelFormRole(2);
        }
    };

    const loadCircularDataTable = () => {
        writeToLogFile("loadCircularDataTable() call");
        let queryStr = '';
        if (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL') {
            queryStr = '&provider_group=' + props.providerGroupSelect;
        }
        writeToLogFile("/get-awv-form-status?payment_year parameter " + props.yearSelect + ' organisation_id=1 lob= ' + props.lobSelect + ' ' + queryStr);
        AWVApi.get('/get-awv-form-status?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + queryStr)
            .then(res => {
                if (res.data) {
                    setCircularDataList(res?.data);
                    writeToLogFile("Data retrieved successfully...");
                }
                writeToLogFile("Error in retrieving data Response..." + res);
            })
            .catch(err => {
                console.log(err);
                writeToLogFile("Error in retrieving data... " + err );
            })
    }

    const handleAssignMeAction = (rowData, checked) => {
        writeToLogFile("AWE Record Page : handleAssignMeAction call, rowData:  " + rowData + ' checked: '+ checked );
        var dataTemp = {
            "awv_backend_id": btoa(String(rowData?.awv_backend_id)),
            "organisation_id": "1",
            "updated_by": isControlUser?.user_id,
            "payment_year": props.yearSelect,
            "lob": props.lobSelect.toString(),
            "assigned": checked,
            "provider_id": isControlUser?.user_id
        }
        writeToLogFile("dataTemp:  " + dataTemp );
        AWVApi.put('/awv_form_assign', dataTemp)
            .then(res => {
                if (res.data) {
                    if (checked && checked == 'unchecked') {
                        toast.success('Record unassigned');
                        loadCircularDataTable();
                        fetchFormWithoutAttachmentCount();
                        props.currentAWERightSidebarCloseDatatableReload(true);
                    } else {
                        toast.success('Record assigned');
                        loadCircularDataTable();
                        fetchFormWithoutAttachmentCount();
                        props.currentAWERightSidebarCloseDatatableReload(true);
                    }
                }
            })
            .catch(err => {
                console.log(err);
            })
    };

    const fetchFormWithoutAttachmentCount = () => {
        writeToLogFile("AWE Record Page : fetchFormWithoutAttachmentCount...");
        let queryStr = '';
        if (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL') {
            queryStr = '&provider_group=' + props.providerGroupSelect;
        }
        let controlUser = JSON.parse(localStorage.getItem('controlUser'));
        let user_id = controlUser?.user_id;
        writeToLogFile("/get-count?payment_year API call, parameters" + ' ' + props.yearSelect + '&organisation_id=1&user_id=' + user_id + '&lob=' + props.lobSelect + queryStr);
        AWVApi.get('/get-count?payment_year=' + props.yearSelect + '&organisation_id=1&user_id=' + user_id + '&lob=' + props.lobSelect + queryStr)
            .then(res => {
                if (res.data) {
                    setFormWithoutAtachmentTotal(res?.data);
                    writeToLogFile("response received successfully...");
                }
                setGenerateLog(true)
            })
            .catch(err => {
                console.log(err);
                writeToLogFile("Error calling an api..." + err);
                setGenerateLog(true)
            })
    }
    useEffect(() => {
        loadHCCDataTable()
    }, props.yearSelect)

    const loadHCCDataTable = () => {
        writeToLogFile("AWE Record Page : loadHCCDataTable() call...");
        AWVCoderWorkBench.get('/data/hcc_v28_list')
            .then(res => {
                if(res.data){
                localStorage.setItem('hcc_v28_list', JSON.stringify(res.data));
                writeToLogFile("/data/hcc_v28_list call...");
                }
            })
            .catch(err => {
                console.log(err)
                writeToLogFile("Error occurred while retrieving data[hcc_v28_list]. Error=  " + err);
            })
    }

    var providerGroupSelectVar = (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL') ? props.providerGroupSelect : '';
    
    useEffect(() => {
        // Generate and download log file when logMessages have been updated
        if (logMessages.length > 0  && generateLog === true) {
        LogFileUploadToS3(logMessages,  localStorage.getItem("access_key"), localStorage.getItem("secret_key"), process.env.REACT_APP_LOG_BUCKET, "AWE_Record_Page")
        }
    }, [generateLog === true, logMessages, props.yearSelect, props.providerGroupSelect, props.lobSelect]); 

    return (
        <>
            <div className="card h-auto mb20">
                <div className="card-body annual_wellness_filter">
                    <HeaderFilter
                        title={'ANNUAL WELLNESS EXAM SUMMARY BY STATUS'}
                    />
                    <SubHeaderFilter
                        title={'Provider Address Grouping Filter'}
                        setZipSelectValue={setZipSelect}
                        setsiteIdSelectValue={setsiteIdSelect}
                        setProviderAddressGroupsSelectValue={setProviderAddressGroupsSelect}
                        setProviderAddressGroupsNameSelectValue={setProviderAddressGroupsNameSelect}
                        setCitySelectValue={setCitySelect}
                        setSiteAddressSelectValue={setSiteAddressSelect}
                    />
                    <div className="summary_status_wrap">
                        <div className="row row-cols-xl-5 row-cols-sm-2 row-cols-1 no-gutters col_border_left">
                            <div className="summary_status_widget widget1 items col">
                                <div className="h-100" >
                                    <LinearProgress sx={{ height: 10 }} variant="determinate" value={100} />
                                    <div className="text-center flex_item_cc px-2 flex-column gap4 ptb40" style={{ height: 'calc(100% - 10px)' }}>
                                        <h2>{circularDataList?.totalFormUploaded}</h2>
                                        <h6>Total Forms Uploaded</h6>
                                    </div>
                                    {/* <ul className="row no-gutters fs13 fw700 text-dark text-center row-cols-3 border-top">
                                        <li className="col">PFC : 1</li>
                                        <li className="col">PFCT : 809</li>
                                        <li className="col">PFPA : 48</li>
                                    </ul>
                                    <ul className="row no-gutters fs13 fw700 text-dark text-center row-cols-3 border-top">
                                        <li className="col">PFC : 1</li>
                                        <li className="col">PFCT : 809</li>
                                        <li className="col">PFPA : 48</li>
                                    </ul> */}
                                </div>
                            </div>
                            <div className="summary_status_widget widget1 items col">
                                <div className="" style={{ height: 'calc(50% + 10px)' }}>
                                    <LinearProgress sx={{ height: 10 }} variant="determinate" value={((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.pendingForCoordinator / circularDataList?.totalFormUploaded) * 100).toFixed(0)} />
                                    <div className="text-center flex_item_cc py-3 px-2">
                                        <h2 className="flex_item_cc flex-shrink-0 lh_1_2 gap4">
                                            {circularDataList?.pendingForCoordinator}
                                            <span className="fs14 fw500 text-primary">
                                                ({((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.pendingForCoordinator / circularDataList?.totalFormUploaded) * 100).toFixed(0)}%)
                                            </span>
                                        </h2>
                                        <h6 className="fs14 fw700">Pending for Coordinator Review</h6>
                                    </div>
                                    <ul className="row no-gutters fs13 fw700 text-dark text-center border-top">
                                        <li className="col border-top-2 border-warning-900 flex_item_cc">
                                            <Tooltip title="On Hold">
                                                <div>
                                                    <FlagIcon className="w24 h24" />
                                                </div>
                                            </Tooltip>
                                            Hold :{circularDataList?.pendingForCoordinator_onhold}
                                        </li>
                                        {/* <li className="col border-top-2 border-danger-900">Rejected :10</li>
                                        <li className="col border-top-2 border-dark-650">Void :11</li> */}
                                    </ul>
                                </div>
                                <div className="" style={{ height: 'calc(50% + 10px)' }}>
                                    <LinearProgress sx={{ height: 10 }} variant="determinate" value={((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.ApprovedForPayment / circularDataList?.totalFormUploaded) * 100).toFixed(0)} />
                                    <div className="text-center flex_item_cc py-3 px-2">
                                        <h2 className="flex_item_cc flex-shrink-0 lh_1_2 gap4">
                                            {circularDataList?.ApprovedForPayment}
                                            <span className="fs14 fw500 text-primary">
                                                ({((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.ApprovedForPayment / circularDataList?.totalFormUploaded) * 100).toFixed(0)}%)
                                            </span>
                                        </h2>
                                        <h6 className="fs14 fw700">Approved For Payment</h6>
                                    </div>
                                    <ul className="row no-gutters fs13 fw700 text-dark text-center border-top">
                                        <li className="col border-top-2 border-warning-900 flex_item_cc">
                                            <Tooltip title="On Hold">
                                                <div>
                                                    <FlagIcon className="w24 h24" />
                                                </div>
                                            </Tooltip>
                                            Hold :{circularDataList?.ApprovedForPayment_onhold}
                                        </li>
                                        {/* <li className="col border-top-2 border-danger-900">Rejected :10</li>
                                        <li className="col border-top-2 border-dark-650">Void :11</li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="summary_status_widget widget1 items col">
                                <div className="" style={{ height: 'calc(50% + 10px)' }}>
                                    <LinearProgress sx={{ height: 10 }} variant="determinate" value={((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.pendingforCoder / circularDataList?.totalFormUploaded) * 100).toFixed(0)} />
                                    <div className="text-center flex_item_cc py-3 px-2">
                                        <h2 className="flex_item_cc flex-shrink-0 lh_1_2 gap4">
                                            {circularDataList?.pendingforCoder}
                                            <span className="fs14 fw500 text-primary">
                                                ({((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.pendingforCoder / circularDataList?.totalFormUploaded) * 100).toFixed(0)}%)
                                            </span>
                                        </h2>
                                        <h6 className="fs14 fw700">Pending for Coder Review</h6>
                                    </div>
                                    <ul className="row no-gutters fs13 fw700 text-dark text-center border-top">
                                        <li className="col border-top-2 border-warning-900 flex_item_cc">
                                            <Tooltip title="On Hold">
                                                <div>
                                                    <FlagIcon className="w24 h24" />
                                                </div>
                                            </Tooltip>
                                            Hold :{circularDataList?.pendingforCoder_onhold}
                                        </li>
                                        {/* <li className="col border-top-2 border-danger-900">Rejected :10</li>
                                        <li className="col border-top-2 border-dark-650">Void :11</li> */}
                                    </ul>
                                </div>
                                <div className="" style={{ height: 'calc(50% + 10px)' }}>
                                    <LinearProgress sx={{ height: 10 }} variant="determinate" value={((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.PaymentDone / circularDataList?.totalFormUploaded) * 100).toFixed(0)} />
                                    <div className="text-center flex_item_cc py-3 px-2">
                                        <h2 className="flex_item_cc flex-shrink-0 lh_1_2 gap4">
                                            {circularDataList?.PaymentDone}
                                            <span className="fs14 fw500 text-primary">
                                                ({((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.PaymentDone / circularDataList?.totalFormUploaded) * 100).toFixed(0)}%)
                                            </span>
                                        </h2>
                                        <h6 className="fs14 fw700">Payment Done</h6>
                                    </div>
                                    <ul className="row no-gutters fs13 fw700 text-dark text-center border-top">
                                        <li className="col border-top-2 border-warning-900 flex_item_cc">
                                            <Tooltip title="On Hold">
                                                <div>
                                                    <FlagIcon className="w24 h24" />
                                                </div>
                                            </Tooltip>
                                            Hold :{circularDataList?.PaymentDone_onhold}
                                        </li>
                                        {/* <li className="col border-top-2 border-danger-900">Rejected :10</li>
                                        <li className="col border-top-2 border-dark-650">Void :11</li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="summary_status_widget widget1 items col">
                                <div className="" style={{ height: 'calc(50% + 10px)' }}>
                                    <LinearProgress sx={{ height: 10 }} variant="determinate" value={((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.pendingForPaymentApproval / circularDataList?.totalFormUploaded) * 100).toFixed(0)} />
                                    <div className="text-center flex_item_cc py-3 px-2">
                                        <h2 className="flex_item_cc flex-shrink-0 lh_1_2 gap4">
                                            {circularDataList?.pendingForPaymentApproval}
                                            <span className="fs14 fw500 text-primary">
                                                ({((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.pendingForPaymentApproval / circularDataList?.totalFormUploaded) * 100).toFixed(0)}%)
                                            </span>
                                        </h2>
                                        <h6 className="fs14 fw700">Pending for Payment Approval</h6>
                                    </div>
                                    <ul className="row no-gutters fs13 fw700 text-dark text-center border-top">
                                        <li className="col border-top-2 border-warning-900 flex_item_cc">
                                            <Tooltip title="On Hold">
                                                <div>
                                                    <FlagIcon className="w24 h24" />
                                                </div>
                                            </Tooltip>
                                            Hold :{circularDataList?.pendingForPaymentApproval_onhold}
                                        </li>
                                        {/* <li className="col border-top-2 border-danger-900">Rejected :10</li>
                                        <li className="col border-top-2 border-dark-650">Void :11</li> */}
                                    </ul>
                                </div>
                                <div className="" style={{ height: 'calc(50% + 10px)' }}>
                                    <LinearProgress sx={{ height: 10 }} variant="determinate" value={((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.ApprovedNoPayment / circularDataList?.totalFormUploaded) * 100).toFixed(0)} />
                                    <div className="text-center flex_item_cc py-3 px-2">
                                        <h2 className="flex_item_cc flex-shrink-0 lh_1_2 gap4">
                                            {circularDataList?.ApprovedNoPayment}
                                            <span className="fs14 fw500 text-primary">
                                                ({((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.ApprovedNoPayment / circularDataList?.totalFormUploaded) * 100).toFixed(0)}%)
                                            </span>
                                        </h2>
                                        <h6 className="fs14 fw700">Approved - No Payment</h6>
                                    </div>
                                    <ul className="row no-gutters fs13 fw700 text-dark text-center border-top">
                                        <li className="col border-top-2 border-warning-900 flex_item_cc">
                                            <Tooltip title="On Hold">
                                                <div>
                                                    <FlagIcon className="w24 h24" />
                                                </div>
                                            </Tooltip>
                                            Hold :{circularDataList?.ApprovedNoPayment_onhold}
                                        </li>
                                        {/* <li className="col border-top-2 border-danger-900">Rejected :10</li>
                                        <li className="col border-top-2 border-dark-650">Void :11</li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="summary_status_widget widget1 items col">
                                <div style={{ height: "calc(50% + 10px)" }}>
                                    <LinearProgress className='liner_danger' sx={{ height: 10 }} variant="determinate" value={((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.PaymentReject / circularDataList?.totalFormUploaded) * 100).toFixed(0)} />
                                    <div className="text-center flex_item_cc py-3 px-2" style={{ height: "calc(100% - 10px)", paddingBottom: "4rem", important: "true", }} >
                                        <h2 className="flex_item_cc flex-shrink-0 lh_1_2 gap4">
                                            {circularDataList?.PaymentReject}
                                            <span className="fs14 fw500 text-primary">
                                                ({((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.PaymentReject / circularDataList?.totalFormUploaded) * 100).toFixed(0)}%)
                                            </span>
                                        </h2>
                                        <h6 className="fs14 fw700">Rejected</h6>
                                    </div> 
                                </div>
                                <div style={{ height: "calc(50% + 10px)" }}>
                                    <LinearProgress className='liner_black' sx={{ height: 10 }} variant="determinate" value={((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.void / circularDataList?.totalFormUploaded) * 100).toFixed(0)} />
                                    <div className="text-center flex_item_cc py-3 px-2" style={{ height: "calc(100% - 30px)" }}>
                                        <h2 className="flex_item_cc flex-shrink-0 lh_1_2 gap4">
                                            {circularDataList?.void}
                                            <span className="fs14 fw500 text-primary">
                                                ({((circularDataList?.totalFormUploaded == 0 ? 0 : circularDataList?.void / circularDataList?.totalFormUploaded) * 100).toFixed(0)}%)
                                            </span>
                                        </h2>
                                        <h6 className="fs14 fw700">Void</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card h-auto">
                <div className="card-body">
                    <CustomAweServerSideDatatable
                        titleName="AWE Record Details"
                        downloadFileName="awe_recod_details"
                        urlLink={`/get-all-awv-record?payment_year=${props.yearSelect}&organisation_id=1&lob=${props.lobSelect}&formAttached=${formAttachedFlag}&assigned_to_me=${formAssignedFlag}&form_attached=${formAttached}&provider_group=${(providerGroupSelectVar && providerGroupSelectVar != '') ? providerGroupSelectVar : ''}&address_grouping=${(providerAddressGroupsNameSelect && providerAddressGroupsNameSelect != '') ? providerAddressGroupsNameSelect : ''}&site_zip=${(zipSelect && zipSelect != '') ? zipSelect : ''}&site_city=${(citySelect && citySelect != '') ? citySelect : ''}&site_address=${(siteAddressSelect && siteAddressSelect != '') ? siteAddressSelect : ''}&site_id=${(siteIdSelect && siteIdSelect != '') ? siteIdSelect : ''}&`}
                        columns={aweRecordColumns}
                        subColumns={aweRecordSubColumns}
                        expandedRows={expandedRows}
                        expandedRowsReset={expandedRowsReset}
                        userAssignedCount={(formWithoutAtachmentTotal?.User_assigned_count) ? formWithoutAtachmentTotal?.User_assigned_count : 0}
                        formWithoutAttachment={(formWithoutAtachmentTotal?.form_without_attachment) ? formWithoutAtachmentTotal?.form_without_attachment : 0}
                        handleChangeFormAssigned={handleChangeFormAssigned}
                        handlechange={handlechange}
                        editActionData={editHCCDetails}
                        formAssignedFlag={formAssignedFlag}
                        formAttachedFlag={formAttachedFlag}
                        zipSelectValue={zipSelect}
                        siteIdSelectValue={siteIdSelect}
                        providerAddressGroupsNameSelectValue={providerAddressGroupsNameSelect}
                        citySelectValue={citySelect}
                        siteAddressSelectValue={siteAddressSelect}
                        cinColumnName={'cin'}
                        awe_display_id={'awe_display_id'}
                        awv_backend_id={'awv_backend_id'}
                        statusColumnName={'form_status'}
                        showColumnBtn={true}
                        searchBox={false}
                        downloadBtn={false}
                        pagination={true}
                        filterBox={true}
                        colSpan={13}
                    />
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        lobSelect: state.moduleFilter.lobSelect,
        aweRightSidebarCloseDatatableReload: state.moduleFilter.aweRightSidebarCloseDatatableReload,
        aweSiteSelect: state.moduleFilter.aweSiteSelect,
        aweProviderGroupingSelect: state.moduleFilter.aweProviderGroupingSelect,
        citySelect: state.moduleFilter.citySelect,
        aweAddressSelect: state.moduleFilter.aweAddressSelect,
        aweZipSelect: state.moduleFilter.aweZipSelect,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(AweRecordPage);