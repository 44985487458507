import React, { useState, useEffect } from "react";
import adminApi from '../../assets/constants/AdminRafservice.Instance';
import { connect } from 'react-redux';
import { dateTimeAdminFormatRender, anchorTagEditMode, statusToFormatRender } from '../../assets/constants/customRender'
import AdminSubHeader from '../../components/home/header/adminSubHeader';
import CardComponent from "../../components/Cards/card";
import ExpandableGridTable from "../../components/TableGrids/ExpandableGridTable.table";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import AdminSlider from '../../components/home/header/adminSlider';
import AddRole from '../../components/Dialog/AddRole';
import ViewRole from '../../components/Dialog/ViewRole';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import VisibilityIcon from '@mui/icons-material/Visibility'; 
import { purple, red } from '@mui/material/colors';
import Swal from 'sweetalert2';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';

const RolesPage = (props) => {
    const [rolesData, setRolesData] = useState([]);
    const [open, setOpen] = useState(false);
    const [viewOpen, setViewOpen] = useState(false);
    const [editRoleData, setEditRoleData] = useState({});

    const rolesColumn = [
        {
            name: "title",
            label: "Name",
        },
        {
            name: "description",
            label: "Description",
        },
        {
            name: "status",
            label: "Status",
            options: statusToFormatRender
        },
        {
            name: "createdAt",
            label: "Created At",
            options: dateTimeAdminFormatRender
        },
        {
            name: "updatedAt",
            label: "Updated At",
            options: dateTimeAdminFormatRender
        },
        {
            name: "id",
            label: "Action",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Tooltip title="View Role"><a className="hyperlink" style={{ cursor: "pointer" }} onClick={() => handleClickViewOpen(value)}><VisibilityIcon color="primary" /></a></Tooltip>
                            <Tooltip title="Edit Role"><a className="hyperlink" style={{ cursor: "pointer" }} onClick={() => handleClickOpen(value)}><ModeEditOutlineIcon color="primary" /></a></Tooltip>
                            <Tooltip title="Delete Role"><a className="hyperlink" style={{ cursor: "pointer" }} onClick={() => handleClickDelete(value)}><DeleteRoundedIcon sx={{ color: red[500] }} /></a></Tooltip>
                        </>
                    );
                }
            }
        },
    ];

    useEffect(() => {
        loadRoleData();
    }, [
    ]);

    const handleClickViewOpen = (value) => {
        setViewOpen(true);
        editLoadRoleData(value);
    };

    const handleViewClose = () => {
        setViewOpen(false);
        setEditRoleData({});
    };

    const handleClickOpen = (value = '') => {
        setOpen(true);
        if (value && value != '') {
            editLoadRoleData(value)
        } else {
            setEditRoleData({});
        }
    };

    const handleClose = () => {
        setOpen(false);
        setEditRoleData({});
    };

    const handleClickDelete = (value) => {
        if (value && value != '') {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this record!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                confirmBtnBsStyle: "success",
                cancelButtonText: 'No, cancel!',
                cancelBtnBsStyle: "danger",
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    adminApi.delete('/role/' + value)
                        .then(res => {
                            Swal.fire({
                                title: 'Deleted!',
                                text: 'Your record has been deleted.',
                                icon: 'success',
                                customClass: {
                                    confirmButton: 'btn btn-success',
                                    cancelButton: 'btn btn-danger',
                                }
                            })
                            loadRoleData();
                        })
                        .catch(err => {
                            toast.error(err?.response?.data?.message);
                        })
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire({
                        title: 'Cancelled',
                        text: 'Your record is safe :)',
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-danger',
                            cancelButton: 'btn btn-danger',
                        }
                    })
                }
            })
        }
    };

    const editLoadRoleData = (value) => {
        adminApi.get('/role/' + value)
            .then(res => {
                setEditRoleData(res.data);
            })
            .catch(err => {
                toast.error(err?.response?.data?.message);
            })
    }

    const loadRoleData = () => {
        adminApi.get('/role')
            .then(res => {
                setRolesData(res.data);
            })
            .catch(err => {
                toast.error(err?.response?.data?.message);
            })
    }

    return (
        <div className="container level-summary">
            <div className="row">
                <AdminSubHeader title={'Roles'} />
                <div className='col-lg-12 pr-0 pl-0'>
                    <div className="list-member mt-4">
                        <CardComponent
                            heading={`Roles`}
                            body={
                                <>
                                    <div> 
                                        <AddRole loadRoleData={loadRoleData} editRoleData={editRoleData} open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} />
                                        <ViewRole loadRoleData={loadRoleData} editRoleData={editRoleData} open={viewOpen} handleClickOpen={handleClickViewOpen} handleClose={handleViewClose} />
                                    </div>
                                    <ExpandableGridTable
                                        data={rolesData}
                                        columns={rolesColumn}
                                        pagination={true}
                                    ></ExpandableGridTable>
                                </>
                            }></CardComponent>
                    </div>
                </div>
            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
    };
}
export default connect(mapStateToProps)(RolesPage);
