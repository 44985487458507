import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import WidgetCard from "../../components/Cards/WidgetCard";
import SingleVerticalBarChart from '../../components/Charts/SingleVerticalBar.Chart';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import GridTable from "../../components/TableGrids/GridTable.table";
import { anchorTag, hccSeperator, numberWithCommas, dateFormatRender,textToOpenOrCloseFormatRender } from '../../assets/constants/customRender'
import history from "../../history";
import {  MAO_Chart_Review_Member,FLAG,MAO_MEMBER_SELECT_TAB,GAP_INSIGHTS,HCC  } from "../../assets/constants/string.constants";
import { percentFormat } from "../../assets/helpers/formats.helpers";
import CardComponent from "../../components/Cards/card";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import GChart from '../../components/GChart/GChart';
import IssuersACA from '../ACA/ACAIssuers.page';
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import SubHeader from '../../components/home/header/subHeader';
import { getViewBySelect } from '../../assets/constants/viewByList';
import { getModelSelect } from '../../assets/constants/modelList';
import { getMetalTierSelect } from '../../assets/constants/metalTierList';
import { getPlanSelect } from '../../assets/constants/planList';
import { getModuleSelect } from '../../assets/constants/moduleList';
import Link from '@material-ui/core/Link';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import BarVertical from '../../components/GChart/BarVertical';
import { getMeInitialSelect} from '../../assets/constants/meInitialList';

//import google from "react-google-charts";
const MaoSuspectMemberDetails = (props) => {

    const [MAOHCCFLAG] = useState(localStorage.getItem(FLAG));


    const memberColumn = [
        {
            label: "Member ID",
            name: "MEMBER_ID",
            options: anchorTag
        },
        {
            name: "Reported_hcc",
            label: "Reported HCCs",
            options: hccSeperator
        },
        {
            name: "Gap_hcc",
            label: "Gap HCCs",
            options: hccSeperator
        },
        {
            name: "Raf_score",
            label: "RAF Score",
        },
        {
            name: "Gap_in_raf_score",
            label: "Gap In RAF Score",
        },
        {
            name: "Revenu_leekage",
            label: "Potential Revenue Impact $",
        },
        {
            name: "Revenue_percent",
            label: "Potential Revenue Impact %",
        },
        {
            name: "Hcc_score",
            label: "Reported HCC Score",
        },
        {
            name: "Gap_in_hcc_score",
            label: "Gap in HCC Score",
        },
        {
            name: "HCC_CAPTURE_RECOMMENDATION",
            label: "RECOMMENDATION",
        },
        {
            name: "OPPORTUNITY_FLAG",
            label: "OPPORTUNITY FLAG",
            options:textToOpenOrCloseFormatRender
        },
        {
            name: "PROVIDER_NAME",
            label: "PROVIDER NAME",
        },
    ];

    const memberTableClick = (rowdata, colData, cellMeta) =>{
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(MAO_Chart_Review_Member, rowdata[cellMeta.rowIndex].MEMBER_ID);
            localStorage.setItem(MAO_MEMBER_SELECT_TAB, GAP_INSIGHTS);
            history.push('/layout/medicare/mao_member_summary')
        }else if ((cellMeta.colIndex === 1) && (colData.props.children != "")) {
            if(typeof(colData.props.children) == "object"){
                localStorage.setItem(HCC,(cellMeta.event.target.innerText).replace(',',''));
            }
            else{
                localStorage.setItem(HCC,rowdata[cellMeta.rowIndex].Reported_hcc);
            }
            history.push('/layout/medicare/reported_hcc')
        }else if ((cellMeta.colIndex === 2) && (colData.props.children != "")) {
            if(typeof(colData.props.children) == "object"){
                localStorage.setItem(HCC,(cellMeta.event.target.innerText).replace(',',''));
            }
            else{
                localStorage.setItem(HCC,rowdata[cellMeta.rowIndex].Gap_hcc);
            }
            history.push('/layout/medicare/gap_hcc')
        }
    }

    useEffect(() => { 
 
    },  [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect()
    ]);

    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0  mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Suspect Member Gap Details'} />
                        </div>
                    </div>
                </div>          
                <div className='col-lg-12 pr-0 pl-0'>
                    <div className="list-member mt-2">
                        <CardComponent
                            heading={`MEMBERS WITH HISTORIC,COMORBID & LAB GAPS - ${props.yearSelect} / ${props.meInitialSelect}`}
                            body={
                                    <ServerSideGridTable
                                        url={`SuspectMemberGapDetail/year/`+getAcaYear()+`/flag/`+MAOHCCFLAG+`/period/`+getMeInitialSelect()+`/member/ALL`}
                                        columns={memberColumn}
                                        tableClick={memberTableClick}
                                    ></ServerSideGridTable>
                                }>
                        </CardComponent>
                    </div>
                </div>
                    
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br/>

                <br/>
                <br/> 

                    
            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(MaoSuspectMemberDetails);
