import PropTypes from "prop-types";

const Dropdown = (props) =>{

    const { list, value, onSelect} = props;

    const handleSelect = (e) =>{
        props.onSelect(e.target.value);
    }

    return(
        <div className="d-flex justify-content-start">
            <div className="btn-group">
            <select className="btn btn-info dropdown-toggle btn-sm" value={value} onChange={handleSelect}>
                {
                    list && list.map(el => <option value={el} key={el}> {el} </option>)
                }
            </select>
            </div>
        </div>
    )

}

Dropdown.propTypes = {
    list: PropTypes.array,
    value: PropTypes.string,
    onSelect : PropTypes.func
}

export default Dropdown;