import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import WidgetCard from "../../components/Cards/WidgetCard";
import SingleVerticalBarChart from '../../components/Charts/SingleVerticalBar.Chart';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import GridTable from "../../components/TableGrids/GridTable.table";
import { anchorTag, hccSeperator, numberWithCommas, dateFormatRender, addSuffixTwoDecimal } from '../../assets/constants/customRender'
import history from "../../history";
import { MAO_MEMBER_ID } from "../../assets/constants/string.constants";
import { percentFormat } from "../../assets/helpers/formats.helpers";
import CardComponent from "../../components/Cards/card";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import GChart from '../../components/GChart/GChart';
import IssuersACA from '../ACA/ACAIssuers.page';
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import SubHeader from '../../components/home/header/subHeader';
import { getViewBySelect } from '../../assets/constants/viewByList';
import { getModelSelect } from '../../assets/constants/modelList';
import { getMetalTierSelect } from '../../assets/constants/metalTierList';
import { getPlanSelect } from '../../assets/constants/planList';
import { getModuleSelect } from '../../assets/constants/moduleList';
import Link from '@material-ui/core/Link';
import { getMeInitialSelect } from '../../assets/constants/meInitialList';
import { connect } from 'react-redux';
import Moment from 'react-moment';
//import google from "react-google-charts";
const MaoEncounterMaoComparisonMember = (props) => {

    const [EncounterYearData, setEncounterYearData] = useState([]);
    const [memberIdData, setMemberIdData] = useState(localStorage.getItem(MAO_MEMBER_ID));
    
    const MaoclaimDetailsColumns = [
        {
            name: "DIAGNOSIS_CODES",
            label: "Diagnosis Codes"
        },
        {
            name: "CLAIM_ID",
            label: "Claim",
        },
        {
            name: "START_DATE",
            label: "DOS Start",
            options: dateFormatRender
        },
        {
            name: "END_DATE",
            label: "DOS End",
            options: dateFormatRender
        },
        {
            name: "MAO_ICN",
            label: "MAO004_ICN",
        },
        {
            name: "RESPONSE",
            label: "Response",
        },
    ]

    const MaoEncounterYearData = [
        {
            name: "title",
            label: "Title"
        },
        {
            name: "encounter",
            label: "Encounter",
            options:addSuffixTwoDecimal, 
        },
        {
            name: "mao",
            label: "MAO",
        },
    ]

    const loadEncounterYearData = () => {
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/MaocomparisonMember/year/'+getAcaYear()+'/period/'+getMeInitialSelect()+'/memberid/'+memberIdData)
            .then(res => {
                // console.log(res)
                if(res && res.length > 0){
                    setEncounterYearData([
                        {'title':'RAF Score', 'encounter':res[0].encounter_raf_score, 'mao': res[0].mao_raf_score},
                        {'title':'HCC Score', 'encounter':res[0].encounter_hcc_score, 'mao': res[0].mao_hcc_score},
                        {'title':'Interaction Score', 'encounter':res[0].encounter_interaction_score, 'mao': res[0].mao_interaction_score},
                        {'title':'Count Score', 'encounter':res[0].encounter_count_score, 'mao': res[0].mao_count_score},
                        {'title':'HCC List', 'encounter':res[0].encounter_hcc_score, 'mao': res[0].mao_hcc_score},
                    ]);
                }
                
                    // // console.log(EncounterYearData)
            })
            .catch(err => {
                // // console.log(err)
            })
    }
//Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    useEffect(() => { 
        loadEncounterYearData();      
    },  [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect()
    ]);

    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Encounter Comparison Member'} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-4">
                    <CardComponent
                        heading={`ENCOUNTER AND MAO COMPARISON : `+memberIdData}
                        body={
                            <GridTable
                                data={EncounterYearData}
                                columns={MaoEncounterYearData}
                            ></GridTable>
                        }></CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0">
                    <div className=''>
                        <div className="list-member mt-4">
                            <CardComponent
                                heading={`ENCOUNTER ELIGIBLE CLAIMS`}
                                body={
                                        <ServerSideGridTable
                                            url={`/MaoclaimDetails/type/claim/memberid/`+memberIdData+`/year/${getAcaYear()}/period/${getMeInitialSelect()}`}
                                            columns={MaoclaimDetailsColumns}
                                        ></ServerSideGridTable>
                                    }>
                            </CardComponent>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0">
                    <div className=''>
                        <div className="list-member mt-4">
                            <CardComponent
                                heading={`ENCOUNTER ELIGIBLE CR ADDS`}
                                body={
                                        <ServerSideGridTable
                                            url={`/MaoclaimDetails/type/cradd/memberid/`+memberIdData+`/year/${getAcaYear()}/period/${getMeInitialSelect()}`}
                                            columns={MaoclaimDetailsColumns}
                                        ></ServerSideGridTable>
                                    }>
                            </CardComponent>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0">
                    <div className=''>
                        <div className="list-member mt-4">
                            <CardComponent
                                heading={`ENCOUNTER ELIGIBLE CR DELETES`}
                                body={
                                        <ServerSideGridTable
                                            url={`/MaoclaimDetails/type/crdel/memberid/`+memberIdData+`/year/`+getAcaYear+`/period/${getMeInitialSelect()}`}
                                            columns={MaoclaimDetailsColumns}
                                        ></ServerSideGridTable>
                                    }>
                            </CardComponent>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br/>

                <br/>
                <br/> 

            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,                
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(MaoEncounterMaoComparisonMember);
