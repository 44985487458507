import React, { useState, useEffect } from "react";
import { currentRoute, currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../../store/actions';
import { connect } from 'react-redux';
import AwvSubHeader from '../../../components/home/header/AwvSubHeader';
import 'react-circular-progressbar/dist/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Embed from '../../../assets/constants/Embed';
import { Amplify } from 'aws-amplify';
import awsconfig from '../../../assets/constants/amplify.config';
import { makeStyles } from '@material-ui/core/styles';

const AWEDashboard = (props) => {
// Configure Amplify using the provided AWS configuration store in file
Amplify.configure(awsconfig);

/**
 * Custom styles for the component.
 */
const useStyles = makeStyles((theme) => ({
  title: {
    paddingTop: theme.spacing(2)
  },
}));

/**
 * Medicare Enrollment Stratification ROI Component.
 * @returns {JSX.Element} - The rendered component.
 */

    return (
        // <div className="container level-summary">
            <div className="container level-summary col-lg-12 pr-0 pl-0 mt-5" style={{ minHeight: "1000px", top: '-5%'}} >

                <Embed dashboardIdName='quick_sight_awe_dashboardId' embedConsole={false} role={'READER'} />

                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br />
                <br />
                <br />

            {/* </div> */}
        </div >
    )
};
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        lobSelect: state.moduleFilter.lobSelect,
        aweRightSidebarCloseDatatableReload: state.moduleFilter.aweRightSidebarCloseDatatableReload,
        aweSiteSelect: state.moduleFilter.aweSiteSelect,
        aweProviderGroupingSelect: state.moduleFilter.aweProviderGroupingSelect,
        citySelect: state.moduleFilter.citySelect,
        aweAddressSelect: state.moduleFilter.aweAddressSelect,
        aweZipSelect: state.moduleFilter.aweZipSelect,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(AWEDashboard);