import React, { useState, useEffect } from "react";
import AWVApi from '../../../assets/constants/AWVRafservice.Instance';
import AWVCoderWorkBench from '../../../assets/constants/AWVCoderWorkBenchRafservice.Instance';
import { connect } from 'react-redux';
import AWVNLPInsightPDF from '../../customRightSidebar/AweSideBar/AWVNLPInsightPDF';
import IconButton from '../../layout/aweLayout/IconButton';
import SortBothIcon from '../../icons/SortBothIcon';
import Button from '../../layout/aweLayout/Button';
import { currentRoute, currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../../store/actions';
import { NavLink } from "react-router-dom";
import {LogFileUploadToS3} from '../../../assets/constants/LogFileUploadToS3'

const CustomAweExpandSubTable = (props) => {
    const [subDataSource, setSubDataSource] = useState([]);
    const [isSubLoading, setIsSubLoading] = useState(false);
    const { columnsToShows, subColumns, colSpan, cinColumnName, statusColumnName, awe_display_id, awv_backend_id } = props;
    const [anchorEl, setAnchorEl] = useState(null);
  //  const [id, setId] = useState(null);
    const [hccDataSource, setHCCDataSource] = useState([]);
    const [hccV28List, setHCCV28List] = useState([]);
    const [chartRecord, setChartRecord] = useState(null);
    const [isRecordPresent, setIsRecordPresent] = useState(null);
    const [cwId, setCwId] = useState(null);
    const queryParams = new URLSearchParams();
    queryParams.set('cin', props?.rowData?.[cinColumnName]);
    const [logMessages, setLogMessages] = useState([]); // Array to store log messages
    const writeToLogFile = (message) => {
       setLogMessages(prevLogMessages => [...prevLogMessages, `${new Date().toISOString()} - ${message}`]);
   };
   const [generateLog, setGenerateLog] = useState(false);
   const [isLoading, setIsLoading] = useState(true); // Add a loading state

    // useEffect(() => {
    //     subColumns.forEach(record => {
    //         if ( props.lobSelect === '1') {
    //             setChartRecord(props?.rowData?.[awe_display_id].startsWith('LACC') ? 'NO' : 'YES');
    //         } else {
    //             setChartRecord('NO');
    //         }
    //     });
    // });
    useEffect(() => {
        loadformAWEProcessedRecord();

    }, [props?.rowData?.[cinColumnName]]);


    useEffect(() => {
        if (props.aweRightSidebarCloseDatatableReload && props.aweRightSidebarCloseDatatableReload == true) {
            // loadformDataTable();
            loadformAWEProcessedRecord();
            props.currentAWERightSidebarCloseDatatableReload(false);
        }
    }, [props.aweRightSidebarCloseDatatableReload]);

    useEffect(() => {
        loadMemberDataHCCCapture();
    }, [props]);
    useEffect(() => {
        loadHCCDataTable()
    }, [props])


    // useEffect(() => {

    // }, [props]);


    const loadformAWEProcessedRecord = async () => {
        writeToLogFile("awe backend id before encryption - ", props?.rowData?.[awv_backend_id])
        var cin = btoa(props?.rowData?.[cinColumnName])
        var awe_display_id = btoa(props?.rowData?.[awe_display_id])
        var awv_backend_id = btoa(props?.rowData?.[awv_backend_id])
        writeToLogFile("loadformAWEProcessedRecord() call ");
        writeToLogFile('/get-awe-processed-record-details?year=' + props.yearSelect +  ' organisation_id=1 lob=' + props.lobSelect + ' cin=' + props?.rowData?.[cinColumnName] + ' awe_display_id=' + props?.rowData?.[awe_display_id] + ' awv_backend_id=' +  props?.rowData?.[awv_backend_id]);
        AWVApi.get('/get-awe-processed-record-details?year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + '&cin=' + btoa(cin) + '&awe_display_id=' + btoa(awe_display_id) + '&awv_backend_id=' + btoa(awv_backend_id) )
            .then(res => {
                if (res.data && res.data[0]) {
                    setChartRecord(res.data[0].chart_chase_record)
                    setIsLoading(false);
                    if ((!props?.rowData?.[awe_display_id].includes('LACC')) && props?.rowData?.[awe_display_id].includes('_C') 
                        && props.lobSelect != '3') {
                    subColumns.forEach((ele, index) => {
                        if (ele.label === "Historic") {
                            subColumns[index].label = "Is Claim Matched";
                            subColumns[index].name = "claim_matched";
                        }
                    });
                }
                    loadformDataTable(res.data[0].chart_chase_record);
                    setIsRecordPresent(res.data[0].present_in_coder_workbench)
                    setCwId(res.data[0].id_of_coder_workbench)
                    writeToLogFile("Data retrieved successfully...");
                } else {
                    writeToLogFile("Error in retrieving data Response..." + res);
                    setIsLoading(false);
                }
            })
            .catch(err => {
                console.log(err)
                writeToLogFile("Error in retrieving data... " + err );
            })
    }

    const loadformDataTable = (chartRecord) => {
        writeToLogFile("awe backend id before encryption - ", props?.rowData?.[awv_backend_id])
        var cin = btoa(props?.rowData?.[cinColumnName])
        var awe_display_id = btoa(props?.rowData?.[awe_display_id])
        var awv_backend_id = btoa(props?.rowData?.[awv_backend_id])
        writeToLogFile("loadformDataTable() call ");
        setIsSubLoading(true);
        setSubDataSource([]);
        var value = [];
        var url = "/get_member_hcc_details?cin=" + btoa(cin) + '&year=' + props?.yearSelect + '&lob=' + props?.lobSelect + '&organisation_id=1' + '&chart_record=' + chartRecord + '&awe_display_id=' + btoa(awe_display_id) + '&awv_backend_id=' + btoa(awv_backend_id)
        writeToLogFile("API call: " + url);
        AWVApi.get(url)
            .then(res => {
                res.data.forEach(element => {
                    element.meat_flag = element.meat_flag != null ? element.meat_flag == 1 ? 'Yes' : 'No' : null;
                });
                value.push(...res.data);
                for (let i = 0; i < value.length; i++) {
                    const data = value[i];
                    if (data.risk_code_type === 'RXC') {
                        data.HCC_Code = data.rxc_code;
                        data.HCC_Description = data.rxc_description;
                    }
                    if (data.source_code_type === 'CPT_CODE') {
                        data.DX_code = data.cpt_code;
                        data.DX_description = data.cpt_description;
                    }
                    else if (data.source_code_type === 'NDC') {
                        data.DX_code = data.ndc_code;
                        data.DX_description = data.ndc_description;
                    }
                }

                setSubDataSource(value);
                setIsSubLoading(false);
                writeToLogFile("Data retrieved successfully...value: " + value);
                setGenerateLog(true)
            })
            .catch(err => {
                console.log(err)
                setIsSubLoading(false);
                writeToLogFile("Error in retrieving data... " + err );
                setGenerateLog(true)
            })
    }

    const loadMemberDataHCCCapture = (CinId) => {
        writeToLogFile("awe backend id before encryption - ", props?.rowData?.[awe_display_id])
        var cin = btoa(props?.rowData?.[cinColumnName])
        var awe_display_id = btoa(props?.rowData?.[awe_display_id])
        var awv_backend_id = btoa(props?.rowData?.[awv_backend_id])
        writeToLogFile("loadMemberDataHCCCapture() call ");
        writeToLogFile('/get-member-hcc-summary?cin= ' + cin + ' year=' + props?.yearSelect + ' lob=' + props?.lobSelect + ' organisation_id=1' + ' awv_backend_id=' +  props?.rowData?.[awv_backend_id]);
        AWVApi.get('/get-member-hcc-summary?cin=' + btoa(cin) + '&year=' + props?.yearSelect + '&lob=' + props?.lobSelect + '&organisation_id=1' + '&awe_display_id=' + btoa(awe_display_id))
            .then(res => {
                setHCCDataSource(res.data);
                writeToLogFile("Data retrieved successfully... " );
            })
            .catch(err => {
                console.log(err)
                writeToLogFile("Error in retrieving data... " + err );
            })

    }

    const loadHCCDataTable = () => {
        writeToLogFile("loadHCCDataTable() call ");
        // setIsSubLoading(true);
        setHCCDataSource([]);
        var value = [];
        writeToLogFile("data/hcc_v28_list call ");
        AWVCoderWorkBench.get('/data/hcc_v28_list')
            .then(res => {
                setHCCV28List(res.data)
                localStorage.setItem('hcc_v28_list', JSON.stringify(res.data));
                writeToLogFile("Data retrieved successfully... " );
            })
            .catch(err => {
                console.log(err)
                writeToLogFile("Error in retrieving data... " + err );
            })
    }

    useEffect(() => {
        // Generate and download log file when logMessages have been updated
        if (logMessages.length > 0  && generateLog === true) {
        LogFileUploadToS3(logMessages,  localStorage.getItem("access_key"), localStorage.getItem("secret_key"), process.env.REACT_APP_LOG_BUCKET, "DX_HCC_Details_Expand_Row")
        }
    }, [generateLog == true,logMessages, props?.rowData?.[cinColumnName], props?.yearSelect,  props?.rowData?.[awe_display_id], props?.lobSelect]); 


    return (
        <>
            <div className="flex_item_cb py-4">
                <NavLink
                    to={{
                        pathname: '/new_awe/awe-coder-workbench',
                        search: '?#',
                        state: {
                            cin: props?.rowData?.[cinColumnName],
                            awv_backend_id: props?.rowData?.[awv_backend_id],
                            hcc_v28_list: hccV28List,
                            year: props.yearSelect,
                            lob: props.lobSelect,
                            awe_display_id: props?.rowData?.[awe_display_id],
                            chart_chase_record: chartRecord,
                            is_cw_present: isRecordPresent,
                            cw_id: cwId
                        }
                    }}
                >
                    <Button
                        type="button"
                        className="btn-sm"
                    >
                        coder workbench
                    </Button>
                </NavLink>
                <p className="mb-0 fw600 fs14">
                    Expected Dx : {hccDataSource && hccDataSource?.Total_Expected ? hccDataSource.Total_Expected : 0}
                </p>
                <p className="mb-0 fw600 fs14">
                    Confirmed Dx: {hccDataSource && hccDataSource?.Total_Confirmed ? hccDataSource.Total_Confirmed : 0}
                </p>
                <p className="mb-0 fw600 fs14">
                    New Dx  : {hccDataSource && hccDataSource?.New_Dx ? hccDataSource.New_Dx : 0}
                </p>
                <p className="mb-0 fw600 fs14">
                    Dx Recapture Rate - {hccDataSource && hccDataSource?.Capture_Rate ? (hccDataSource.Capture_Rate.toFixed(0))+ ' %' : 0 + '%'}
                </p>
                <AWVNLPInsightPDF cin={props?.rowData?.[cinColumnName]} aweMemberId= {props?.rowData?.[awe_display_id]} />
                       
            </div>
            <table class="table">
                <thead>
                    <tr>
                        {subColumns && subColumns.map((elementColumn, indexColumn) => (
                            <th scope="col align-middle"
                                key={indexColumn} style={{ width: (elementColumn?.options && elementColumn?.options?.width) ? elementColumn?.options?.width : '100px', minWidth: (elementColumn?.options && elementColumn?.options?.width) ? elementColumn?.options?.width : '100px', maxWidth: (elementColumn?.options && elementColumn?.options?.width) ? elementColumn?.options?.width : '100px' }}>
                                <div className="flex_item_cs gap4">
                                    {elementColumn?.label}
                                    {elementColumn?.options?.sort && elementColumn?.options?.sort == true ?
                                        <IconButton buttonType={"default"} className={"ml-2 table_sort_icon lh_1"} type="button">
                                            <SortBothIcon className="w16" />
                                        </IconButton>
                                        :
                                        <></>
                                    }
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {isSubLoading && isSubLoading == true &&
                        <tr >
                            <td colSpan={colSpan}>
                                <div className='text-center mt-2 mb-2'>Loading...</div>
                            </td>
                        </tr>
                    }
                    {isSubLoading != true && subDataSource && subDataSource.length == 0 &&
                        <tr >
                            <td colSpan={colSpan}>
                                <div className='text-center mt-2 mb-2'>No Data Found</div>
                            </td>
                        </tr>
                    }
                    {subDataSource && subDataSource.length > 0 && subDataSource.map((elementRow, indexRow) => (
                        <>
                            <tr key={indexRow}>
                                {subColumns && subColumns.map((elementColumn, indexColumn) => (
                                    <td key={indexColumn} style={{ width: (elementColumn?.options && elementColumn?.options?.width) ? elementColumn?.options?.width : '100px' }}>
                                        {elementColumn?.options?.customBodyRender && elementColumn?.options?.otherColumnName == 'all' ?
                                            elementColumn?.options?.customBodyRender(elementRow?.[elementColumn?.name], elementRow, indexRow)
                                            : elementColumn?.options?.customBodyRender && elementColumn?.options?.customBodyRender != '' ?
                                                elementColumn?.options?.customBodyRender(elementRow?.[elementColumn?.name], elementRow?.[elementColumn?.options?.otherColumnName])
                                                : elementColumn?.options?.customBodyRender ?
                                                    elementColumn?.options?.customBodyRender(elementRow?.[elementColumn?.name])
                                                    :
                                                    elementRow?.[elementColumn?.name]
                                        }
                                    </td>
                                ))}
                            </tr>
                        </>
                    ))}
                </tbody>
            </table>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        lobSelect: state.moduleFilter.lobSelect,
        aweRightSidebarCloseDatatableReload: state.moduleFilter.aweRightSidebarCloseDatatableReload,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(CustomAweExpandSubTable);