import React, { useEffect, useRef, useState } from 'react'
import IconButton from '../../../../components/CustomButtons/IconButton'
import CheckListIcon from '../../../../components/icons/CheckListIcon'
import EditPenIcon from '../../../../components/icons/EditPenIcon'
import ForwardLeftIcon from '../../../../components/icons/ForwardLeftIcon'
import PlusFillIcon from '../../../../components/icons/PlusFillIcon'
import CheckTikIcon from '../../../../components/icons/CheckTikIcon'
import InfoLineIcon from '../../../../components/icons/InfoLineIcon'
import CloseLineIcon from '../../../../components/icons/CloseLineIcon'
import CircleIcon from '../../../../components/icons/CircleIcon'
import PlusFileIcon from '../../../../components/icons/PlusFileIcon';
import Button from '@material-ui/core/Button';
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { Link, NavLink } from 'react-router-dom'
import AWS from 'aws-sdk'
import axios from "axios"
import moment from 'moment'
import { connect } from 'react-redux'
import AWVCoderWorkBench from '../../../../assets/constants/AWVCoderWorkBenchRafservice.Instance'
import pdfIcon from '../../../../assets/images/pdf_icon.png'
import CustomAweDatatable from "../../../../components/TableGrids/CustomAweDatatable"
import history from "../../../../history"
import { currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload, currentAWERightSidebarType } from '../../../../store/actions'
// import ChecklistIcon from '@mui/icons-material/Checklist';
import StatusBadge from '../../../../components/layout/aweLayout/StatusBadge';
import AWVApi from '../../../../assets/constants/AWVRafservice.Instance';
import QueryLetter from '../../../../assets/constants/QueryLetter.Instance';
import { useLocation } from 'react-router-dom';
import AddCWDXCoderWorkBench from '../../../../components/Dialog/AddCWDXCoderWorkBench';
import EditCWDXCoderWorkBench from '../../../../components/Dialog/EditCWDXCoderWorkBench';
import QueryLetterCoderWorkBench from '../../../../components/Dialog/QueryLetterCoderWorkBench';
import QueryLetterEditCoderWorkBench from '../../../../components/Dialog/QueryLetterEditCoderWorkBench';
import {LogFileUploadToS3} from '../../../../assets/constants/LogFileUploadToS3';
import { toast } from 'react-toastify';

const aws = require('aws-sdk');

// fetching accesskeys for aws-sdk
const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = localStorage.getItem('access_key');
const SECRET_ACCESS_KEY = localStorage.getItem('secret_key');

const AWVCoderBenchmark = (props) => {
    const [tabvalue, setTabvalue] = useState('1');
    const [coderBenchmarkData, setCoderBenchmarkData] = useState([]);
    const [querryLatterGetData, setQuerryLatterGetData] = useState([]);
    const [queryLetterEdit, setQueryLetterEdit] = useState('');
    const [coderCWDXRecordList, setCoderCWDXRecordList] = useState([]);
    const [coderCWDXLoading, setCoderCWDXLoading] = useState(false);
    const [coderCWPROCCODERecordList, setCoderCWPROCCODERecordList] = useState([]);
    const [coderBenchmarkLoading, setCoderBenchmarkLoading] = useState(false);
    const [coderCWPROCCODELoading, setCoderCWPROCCODELoading] = useState(false);
    const [coderMedicationRecordList, setCoderMedicationRecordList] = useState([]);
    const [coderMedicationLoading, setCoderMedicationLoading] = useState(false);
    const [CWDXRecordStatusCount, setCWDXRecordStatusCount] = useState({
        'Accepted': 0,
        'Rejected': 0,
        'Clarification_Hold': 0,
        'Pending_for_clarification': 0
    });
    const [CWPROCCODERecordStatusCount, setCWPROCCODERecordStatusCount] = useState({
        'Accepted': 0,
        'No_Action': 0,
        'Rejected': 0,
        'Clarification_Hold': 0,
        'Pending_for_clarification': 0
    });
    const [MedicationRecordStatusCount, setMedicationRecordStatusCount] = useState({
        'Accepted': 0,
        'No_Action': 0,
        'Rejected': 0,
        'Clarification_Hold': 0,
        'Pending_for_clarification': 0
    });


    const [anchorEl, setAnchorEl] = useState(null);
    const [selectId, setSelectId] = useState(null);
    const openAction = Boolean(anchorEl);
    const [showUserDetails, setShowUserDetails] = useState(false);
    const params = new URL(window.location.href).searchParams;
    const [userMemberId] = useState(params.get('cin'));
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    // const cin = urlParams.get('cin');
    const [fileUrl, setFileUrl] = useState();
    const location = useLocation();
    const cin =location.state?.cin 
    const [openDxCode, setOpenDxCode] = useState(false);
    const [openEditDxCode, setOpenEditDxCode] = useState(false);
    const [openQueryLetter, setOpenQueryLetter] = useState(false);
    const [openEditQueryLetter, setOpenEditQueryLetter] = useState(false);
    const [editMenuData, setEditMenuData] = useState({});
    const [isRecordPresent, setIsRecordPresent] = useState(null);
    const [cwId, setCwId] = useState(null);
    const [logMessages, setLogMessages] = useState([]); // Array to store log messages
    const writeToLogFile = (message) => {
       setLogMessages(prevLogMessages => [...prevLogMessages, `${new Date().toISOString()} - ${message}`]);
   };
   const [generateLog, setGenerateLog] = useState(false);


    useEffect(() => {
        // pass bench mark id
        loadCoderBenchMark(cin);
        loadCWDXReport(cin);
        loadformAWEProcessedRecord()
    }, []);

    useEffect(() => {
        if (props.aweRightSidebarCloseDatatableReload && props.aweRightSidebarCloseDatatableReload == true) {
            if (tabvalue && tabvalue == '2') {
                loadCWPROCCODEReport(cin);
            } else if (tabvalue && tabvalue == '3') {
                loadMedicationReport(cin);
            } else {
                loadCWDXReport(cin);
                loadformAWEProcessedRecord()
            }
            props.currentAWERightSidebarCloseDatatableReload(false);
        }
    }, [props.aweRightSidebarCloseDatatableReload]);

    const handleTabChange = (newValue) => {
        setTabvalue(newValue);
    };

    const handleQueryLetterOpen = () => {
        if(queryLetterEdit && queryLetterEdit.attempt >=3 ) {
            toast.error('Maximum 3 Query letter is allowed for a member.');
            return false;
        }
        
        setOpenQueryLetter(true);
    };

    const handleQueryLetterClose = () => {
        setOpenQueryLetter(false);
        queryLatterGetDataFunction(cin, coderBenchmarkData.awe_id);
    };

    const handleEditQueryLetterOpen = () => {
        setOpenEditQueryLetter(true);
    };

    const handleEditQueryLetterClose = () => {
        setOpenEditQueryLetter(false);
        queryLatterGetDataFunction(cin, coderBenchmarkData.awe_id);
    };

    const handleDxCodeClickOpen = (value = 'add') => {
        writeToLogFile('handleDxCodeClickOpen'+ { value });
        if (value == 'edit') {
            setOpenEditDxCode(true);
        } else {
            setOpenDxCode(true);
        }
    };

    const handleDxCodeClose = (value = 'add') => {
        writeToLogFile('handleDxCodeClose' + { value });
        if (value == 'edit') {
            setOpenEditDxCode(false);
        } else {
            setOpenDxCode(false);
        }
    };

    useEffect(() => {
        if (tabvalue && tabvalue == '2') {
            loadCWPROCCODEReport(cin);
        } else if (tabvalue && tabvalue == '3') {
            loadMedicationReport(cin);
        } else {
            loadCWDXReport(cin);
            loadformAWEProcessedRecord()
        }
    }, [tabvalue]);


    const notificationRightDrawer = (open, typeValue, valueId) => {
        var selectIdData = selectId;
        setAnchorEl('');
        setSelectId('')
        props.currentAWERightSidebarType({ 'type': typeValue, 'id': selectIdData });
        props.currentAWERightSidebar(open);
    };

    const notificationIconRightDrawer = (open, typeValue, valueId, otherColumnValueArray) => {
        writeToLogFile("Inside notificationIconRightDrawer() " + open, typeValue, valueId, otherColumnValueArray);
        if (typeValue == 'EditCWDXCoderWorkBench') {
            writeToLogFile("Type Value: EditCWDXCoderWorkBench" + " details: " +  ' type ' + typeValue + ' id '+ valueId + ' valueArray ' + otherColumnValueArray+ ' lob ' + location.state?.lob + ' year ' + location.state?.year + ' awe_id ' + location.state?.awe_id +
            ' cw_id '+ cwId + ' is_cw_present: ' + isRecordPresent + ' member_f_name ' + coderBenchmarkData.member_f_name + ' member_l_name ' + coderBenchmarkData.member_l_name + 
            ' member_dob ' + coderBenchmarkData.member_dob);

            props.currentAWERightSidebarType({
                'type': typeValue, 'id': valueId, 'valueArray': otherColumnValueArray, 'lob': location.state?.lob, 'year': location.state?.year, awv_backend_id: location.state?.awv_backend_id,
              'cw_id': cwId, 'is_cw_present': isRecordPresent, 'member_f_name': coderBenchmarkData.member_f_name, 'member_l_name': coderBenchmarkData.member_l_name, 
            'member_dob': coderBenchmarkData.member_dob, 'chart_chase_record': location.state?.chart_chase_record, 'awe_display_id':  location.state?.awe_display_id
        });
        } else {
            props.currentAWERightSidebarType({ 'type': typeValue, 'id': valueId });
        }
        props.currentAWERightSidebar(open);
    };

    const notificationRightDrawerView = (open, typeValue, valueId) => {
        props.currentAWERightSidebarType({ 'type': typeValue, 'id': valueId });
        props.currentAWERightSidebar(open);
    };

    const notificationRightDrawerSubColumn = (open, typeValue, valueId, statusId = '', cinId = '') => {
        writeToLogFile("Inside notificationRightDrawerSubColumn() " + open, typeValue, valueId);
        if (valueId.dx_code.includes('-')) {
            var dx_code = valueId.dx_code.split("-")[0]
            var dx_dexcription = valueId.dx_code.split("-")[1]
        } else {
            var dx_code = valueId.dx_code
            var dx_dexcription = valueId.dx_dexcription
        }
        props.currentAWERightSidebarType({ 'type': typeValue, 'awv_backend_id': String(location.state?.awv_backend_id), 'cin_id': cin, 'hcc_code': dx_code, 'title': 'CHECKLIST NOTES', 'hcc_description': dx_dexcription, 'id': String(valueId.id), 'nlp_captured' : String(valueId.nlp_captured), "nlp_id":String(valueId.nlp_id) });
        props.currentAWERightSidebar(open);
    };

    const dateConvert = (timestamp) => {
        // Create a new Date object using the timestamp
        var date = new Date(timestamp);

        // Get the individual components of the date
        var year = date.getFullYear();
        var month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        var day = date.getDate().toString().padStart(2, '0');

        // Form the "yyyy-mm-dd" format
        var formattedDate = year + '-' + month + '-' + day;
        return formattedDate;

    }

    let coderCWDXRecordColumns = [
        {
            name: "id",
            label: "Action",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: false,
                width: '82px',
                otherColumnName: 'all',
                customBodyRender: (value,otherColumnValueArray = '') => {
                    return (
                        <>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <div className="flex_item_cc gap10">
                                    {otherColumnValueArray?.CW_DX_STATUS && otherColumnValueArray?.CW_DX_STATUS == 'No Action' ?
                                        <div className="dotsAnimation"></div>
                                        :
                                        <div className="ml-2"></div>
                                    }
                                    <Tooltip title="Edit" arrow placement="top">
                                        <div>
                                            <a role="button" data-id={value} className="hyperlink" style={{ cursor: "pointer", color: "#209f9d" }} onClick={() => notificationIconRightDrawer(true, 'EditCWDXCoderWorkBench', value, otherColumnValueArray)}>
                                                <EditPenIcon className="w24 h24" />
                                            </a>
                                        </div>
                                    </Tooltip>
                                    <Tooltip arrow placement="top" title={"Checklist Notes"} onClick={() => notificationRightDrawerSubColumn(true, 'memberHccRemark', otherColumnValueArray)}>
                                        <div>
                                            <IconButton className={"text-primary"} buttonType={"default"}>
                                                <PlusFileIcon className="w24 h24" />
                                            </IconButton>
                                        </div>
                                    </Tooltip>
                                    {/* <Tooltip title="Status" arrow placement="top">
                                        <a role="button" className="hyperlink" style={{ cursor: "pointer", color: "#209f9d" }} onClick={() => notificationIconRightDrawer(true, 'StatusCWDXCoderWorkBench', value)}>
                                            <CheckListIcon className="w24 h24" />
                                        </a>
                                    </Tooltip> */}

                                </div>
                            </Box>
                        </>
                    );
                }
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                showColumn: true,
                filter: true,
                filterType: 'selectStatus',
                search: false,
                sort: true,
                width: '101px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            {value == 'Rejected' && <StatusBadge status={"danger"} text={'Rejected'} />}
                            {value == 'No Action' && <StatusBadge status={"light"} text={'No Action'} />}
                            {value == 'Accepted' && <StatusBadge status={"success"} text={'Accepted'} />}
                            {value == 'Pending for clarification' && <StatusBadge status={"info"} text={'Pending for clarification'} />}
                        </>
                    );
                }
            }
        },
        {
            name: "insufficient_document",
            label: "INSUFFICIENT DOCUMENTATION",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: true,
                sort: true,
                width: '150px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>

                            <Tooltip title={value} arrow placement="top">
                                <div className="table-short-text">
                                    {/* {alert(String(value))} */}
                                    {String(value) !== "" ? (String(value) === '0' ? 'No' : 'Yes') : ''}

                                </div>
                            </Tooltip>

                        </>
                    );
                }
            }
        },
        {
            name: "dx_code",
            label: "Dx CODE",
            options: {
                showColumn: true,
                filter: true,
                filterType: 'text',
                search: true,
                sort: true,
                width: '120px',
                otherColumnName: 'dx_description',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <Tooltip title={otherColumnValue && otherColumnValue != '' ? `${value} - ${otherColumnValue}` : value} arrow placement="top">
                            <div className="table-short-text">
                                {/* {otherColumnValue ? `${value} - ${otherColumnValue}` : value} */}
                                {(otherColumnValue == '') ? value.slice(0, value.indexOf('-')) : value}
                            </div>
                        </Tooltip>
                    );
                }
            }
        },
        {
            name: "dx_description",
            label: "Dx Description",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: true,
                sort: true,
                width: '150px',
                otherColumnName: 'dx_code',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <Tooltip title={value == '' ? otherColumnValue : `${otherColumnValue} - ${value}`} arrow placement="top">
                            <div className="table-short-text">
                                {/* { (value == '') ? otherColumnValue.split(/[-]+/)[1] : value } */}
                                {(value == '') ? otherColumnValue.split(/-/).slice(1).join('-') : value}
                            </div>
                        </Tooltip>
                    );
                }
            }
        },
        {
            name: "nlp_captured",
            label: "NLP Reported",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: true,
                sort: true,
                width: '150px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            {value && value != '' ?
                                <Tooltip title={value == 'Y' ? 'Yes' : 'No'} arrow placement="top">
                                    <div className="table-short-text">
                                        {value == 'Y' ? 'Yes' : 'No'}
                                    </div>
                                </Tooltip>
                                :
                                <div>

                                </div>
                            }
                        </>
                    );
                }
            }
        },
        {
            name: location.state?.chart_chase_record && location.state?.chart_chase_record == 'YES' ? 'claim_matched' : "historical",
            label: location.state?.chart_chase_record && location.state?.chart_chase_record == 'YES' ? 'is claim matched' : "Historic",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: true,
                sort: true,
                width: '150px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            {value && value != '' ?
                                <Tooltip title={value == 'Y' ? 'Yes' : 'No'} arrow placement="top">
                                    <div className="table-short-text">
                                        {value == 'Y' ? 'Yes' : 'No'}
                                    </div>
                                </Tooltip>
                                :
                                <div>

                                </div>
                            }
                        </>
                    );
                }
            }
        },
        {
            name: "page_no",
            label: "PAGE NO",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: true,
                sort: true,
                width: '111px',
            }
        },
        {
            name: "entity_text",
            label: "ENTITY TEXT",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: true,
                sort: true,
                width: '150px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <Tooltip title={value} arrow placement="top">
                            <div className="table-short-text">
                                {value}
                            </div>
                        </Tooltip>
                    );
                }
            }
        },
        {
            name: "dos_begin",
            label: "DOS Begin",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '124px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    if (value != null && value != '' && value != '0000-00-00') {
                        // const formattedDate = moment(dateConvert(value), "YYYY/MM/DD").format("MM/DD/YYYY");
                        return value;
                    } else {
                        return '';
                    }
                },
            }
        },
        {
            name: "dos_end",
            label: "DOS END",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '110px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    //const formattedDate = new Date(value).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
                    if (value != null && value != '' && value !='0000-00-00') {
                        // const formattedDate = moment(dateConvert(value), "YYYY/MM/DD").format("MM/DD/YYYY");
                        return value;
                    } else {
                        return '';
                    }
                },
            }
        },
        {
            name: "rxhcc_v05",
            label: "RXHCC V05",
            options: {
                showColumn: false,
                filter: false,
                filterType: '',
                search: true,
                sort: true,
                width: '150px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            {value && value != '' ?
                                <Tooltip title={value} arrow placement="top">
                                    <div className="table-short-text">
                                        {value}
                                    </div>
                                </Tooltip>
                                :
                                <div>

                                </div>
                            }
                        </>
                    );
                }
            }
        },
        {
            name: "rxhcc_v08",
            label: "RXHCC V08",
            options: {
                showColumn: false,
                filter: false,
                filterType: '',
                search: true,
                sort: true,
                width: '150px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            {value && value != '' ?
                                <Tooltip title={value} arrow placement="top">
                                    <div className="table-short-text">
                                        {value}
                                    </div>
                                </Tooltip>
                                :
                                <div>

                                </div>
                            }
                        </>
                    );
                }
            }
        },
        {
            name: "updated_on",
            label: "Updated On",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: true,
                sort: true,
                width: '150px',
                otherColumnName: '',

            }
        },
    ];

    const insertionIndex = 5;

    if (String(location.state?.lob) === "2") {
        coderCWDXRecordColumns.splice(insertionIndex, 0,
            {
                name: "model_1",
                label: "HCC V24",
                options: {
                    showColumn: true,
                    filter: true,
                    filterType: 'selectCustom',
                    search: true,
                    sort: true,
                    width: '120px',
                    filterName: "Risk Adjustable",
                    filterSelectedOption: "All",
                    optionList: [
                        { 'text': "All", 'value': "All" },
                        { 'text': "Yes", 'value': "Yes" },
                        { 'text': "No", 'value': "No" }
                    ],
                    otherColumnName: 'model_1_description',
                    customBodyRender: (value, otherColumnValue = '', otherColumnName) => {
                        return (
                            <>
                                {value && (value != 'No HCC' && value != '') ?
                                    <Tooltip title={value} arrow placement="top">
                                        <div className="table-short-text">
                                            {/* {(value && value != '') ? value.slice(0, value.indexOf('-')) : ''} */}
                                            {(otherColumnValue == '') ? value.slice(0, value.indexOf('-')) : value}
                                        </div>
                                    </Tooltip>
                                    :
                                    <div>

                                    </div>
                                }
                            </>
                        );
                    }
                }
            },
            {
                name: "model_1_description",
                label: "HCC V24 Description",
                options: {
                    showColumn: false,
                    filter: false,
                    filterType: '',
                    search: true,
                    sort: true,
                    width: '150px',
                    otherColumnName: 'model_1',
                    customBodyRender: (value, otherColumnValue = '', otherColumnName) => {
                        return (
                            <>
                                {otherColumnValue && (otherColumnValue != 'No HCC' && otherColumnValue != '') ?
                                    <Tooltip title={value == '' ? otherColumnValue : `${otherColumnValue} - ${value}`} arrow placement="top">
                                        <div className="table-short-text">
                                            {/* {otherColumnValue.split(/[-]+/).pop()} */}
                                            {/* { (value == '') ? otherColumnValue.split(/[-]+/)[1] : value } */}
                                            {(value == '') ? otherColumnValue.split(/-/).slice(1).join('-') : value}
                                        </div>
                                    </Tooltip>
                                    :
                                    <div>

                                    </div>
                                }
                            </>
                        );
                    }
                }
            },
            {
                name: "model_2",
                label: "HCC V28",
                options: {
                    showColumn: true,
                    filter: false,
                    filterType: '',
                    search: true,
                    sort: true,
                    width: '120px',
                    otherColumnName: 'model_2_description',
                    customBodyRender: (value, otherColumnValue = '') => {
                        return (
                            <>
                                {value && (value != 'No HCC' && value != '') ?
                                    <Tooltip title={value} arrow placement="top">
                                        <div className="table-short-text">
                                            {/* {(value && value != '') ? value.slice(0, value.indexOf('-')) : ''} */}
                                            {(otherColumnValue == '') ? value.slice(0, value.indexOf('-')) : value}
                                        </div>
                                    </Tooltip>
                                    :
                                    <div>

                                    </div>
                                }
                            </>
                        );
                    }
                }
            },
            {
                name: "model_2_description",
                label: "HCC V28 Description",
                options: {
                    showColumn: false,
                    filter: false,
                    filterType: '',
                    search: true,
                    sort: true,
                    width: '150px',
                    otherColumnName: 'model_2',
                    customBodyRender: (value, otherColumnValue = '', otherColumnName) => {
                        return (
                            <>
                                {otherColumnValue && (otherColumnValue != 'No HCC' && otherColumnValue != '') ?
                                    <Tooltip title={value == '' ? otherColumnValue : `${otherColumnValue} - ${value}`} arrow placement="top">
                                        <div className="table-short-text">
                                            {/* {otherColumnValue.split(/[-]+/).pop()} */}
                                            {/* { (value == '') ? otherColumnValue.split(/[-]+/)[1] : value } */}
                                            {(value == '') ? otherColumnValue.split(/-/).slice(1).join('-') : value}
                                        </div>
                                    </Tooltip>
                                    :
                                    <div>

                                    </div>
                                }
                            </>
                        );
                    }
                }
            },
        );
    } else {
        coderCWDXRecordColumns.splice(insertionIndex, 0,
            {
                name: "model_1",
                label: "HCC",
                options: {
                    showColumn: true,
                    filter: true,
                    filterType: 'selectCustom',
                    search: true,
                    sort: true,
                    width: '150px',
                    filterName: "Risk Adjustable",
                    filterSelectedOption: "All",
                    optionList: [
                        { 'text': "All", 'value': "All" },
                        { 'text': "Yes", 'value': "Yes" },
                        { 'text': "No", 'value': "No" }
                    ],
                    otherColumnName: 'model_1_description',
                    customBodyRender: (value, otherColumnValue = '') => {
                        return (
                            <>
                                {value && (value != 'No HCC' && value != '') ?
                                    <Tooltip title={otherColumnValue ? `${value} - ${otherColumnValue}` : value} arrow placement="top">
                                        <div className="table-short-text">
                                            {(value && value != '') ? value.slice(0, value.indexOf('-')) : ''}
                                        </div>
                                    </Tooltip>
                                    :
                                    <div>

                                    </div>
                                }
                            </>
                        );
                    }
                }
            },
            {
                name: "model_1_description",
                label: "HCC Description",
                options: {
                    showColumn: true,
                    filter: false,
                    filterType: '',
                    search: true,
                    sort: true,
                    width: '150px',
                    otherColumnName: 'model_1',
                    customBodyRender: (value, otherColumnValue = '') => {
                        return (
                            <>
                                {otherColumnValue && (otherColumnValue != 'No HCC' && otherColumnValue != '') ?
                                    <Tooltip title={otherColumnValue ? `${otherColumnValue} - ${value}` : value} arrow placement="top">
                                        <div className="table-short-text">
                                            {(value && value != '') ? value : otherColumnValue.split(/[-]+/).pop()}
                                        </div>
                                    </Tooltip>
                                    :
                                    <div>

                                    </div>
                                }
                            </>
                        );
                    }
                }
            },
        );
    }


    const coderCWPROCCODERecordColumns = [
        {
            name: "id",
            label: "Action",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: false,
                width: '82px',
                otherColumnName: 'cw_proc_status',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <div className="flex_item_cc gap10">
                                {otherColumnValue && otherColumnValue == 'No Action' ?
                                    <div className="dotsAnimation"></div>
                                    :
                                    <div className="ml-2"></div>
                                }
                                <Tooltip title="Edit" arrow placement="top">
                                    <div>
                                        <a role="button" className="hyperlink" style={{ cursor: "pointer", color: "#209f9d" }} onClick={() => notificationIconRightDrawer(true, 'EditCWPROCCODECoderWorkBench', value)}>
                                            <EditPenIcon className="w24 h24" />
                                        </a>
                                    </div>
                                </Tooltip>
                                {/* <Tooltip title="Status" arrow placement="top">
                                    <a role="button" className="hyperlink" style={{ cursor: "pointer", color: "#209f9d" }} onClick={() => notificationIconRightDrawer(true, 'StatusCWPROCCODECoderWorkBench', value)}>
                                        <CheckListIcon className="w24 h24" />
                                    </a>
                                </Tooltip> */}
                            </div>
                        </Box>

                    );
                }
            }
        },
        {
            name: "cw_proc_status",
            label: "STATUS",
            options: {
                showColumn: true,
                filter: true,
                filterType: 'selectStatus',
                search: false,
                sort: true,
                width: '101px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            {value == 'Rejected' && <StatusBadge status={"danger"} text={'Rejected'} />}
                            {value == 'No Action' && <StatusBadge status={"light"} text={'No Action'} />}
                            {value == 'Accepted' && <StatusBadge status={"success"} text={'Accepted'} />}
                            {value == 'Pending for clarification' && <StatusBadge status={"info"} text={'Pending for clarification'} />}
                        </>
                    );
                }
            }
        },
        {
            name: "proc_code",
            label: "PROC CODE",
            options: {
                showColumn: true,
                filter: true,
                filterType: 'text',
                search: true,
                sort: true,
                width: '150px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <Tooltip title={value} arrow placement="top">
                            <div className="table-short-text">
                                {value}
                            </div>
                        </Tooltip>
                    );
                }
            }
        },
        {
            name: "page_no",
            label: "PAGE NO",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: true,
                sort: true,
                width: '111px',
            }
        },
        {
            name: "ENTITY_TEXT",
            label: "ENTITY TEXT",
            options: {
                showColumn: true,
                filter: true,
                filterType: 'text',
                search: true,
                sort: true,
                width: '150px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <Tooltip title={value} arrow placement="top">
                            <div className="table-short-text">
                                {value}
                            </div>
                        </Tooltip>
                    );
                }
            }
        },
        {
            name: "dos_begin",
            label: "DOS Begin",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '124px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    if (value != null && value != '') {
                        const formattedDate = moment(value, "YYYY/MM/DD").format("MM/DD/YYYY");
                        return formattedDate;
                    } else {
                        return value;
                    }
                },
            }
        },
        {
            name: "dos_end",
            label: "DOS END",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '111px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    if (value != null && value != '') {
                        const formattedDate = moment(value, "YYYY/MM/DD").format("MM/DD/YYYY");
                        return formattedDate;
                    } else {
                        return value;
                    }
                },
            }
        },
        {
            name: "modifier_1",
            label: "MODIFIER 1",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: true,
                sort: true,
                width: '150px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            {value && value != '' ?
                                <Tooltip title={value} arrow placement="top">
                                    <div className="table-short-text">
                                        {value}
                                    </div>
                                </Tooltip>
                                :
                                <div>
                                    No HCC
                                </div>
                            }
                        </>
                    );
                }
            }
        },
        {
            name: "modifier_2",
            label: "MODIFIER 2",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: true,
                sort: true,
                width: '150px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            {value && value != '' ?
                                <Tooltip title={value} arrow placement="top">
                                    <div className="table-short-text">
                                        {value}
                                    </div>
                                </Tooltip>
                                :
                                <div>
                                    No HCC
                                </div>
                            }
                        </>
                    );
                }
            }
        },
        {
            name: "modifier_3",
            label: "MODIFIER 3",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: true,
                sort: true,
                width: '150px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            {value && value != '' ?
                                <Tooltip title={value} arrow placement="top">
                                    <div className="table-short-text">
                                        {value}
                                    </div>
                                </Tooltip>
                                :
                                <div>
                                    No HCC
                                </div>
                            }
                        </>
                    );
                }
            }
        },
        {
            name: "modifier_4",
            label: "MODIFIER 4",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: true,
                sort: true,
                width: '150px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            {value && value != '' ?
                                <Tooltip title={value} arrow placement="top">
                                    <div className="table-short-text">
                                        {value}
                                    </div>
                                </Tooltip>
                                :
                                <div>
                                    No HCC
                                </div>
                            }
                        </>
                    );
                }
            }
        },
        {
            name: "risk_eligible",
            label: "RISK ELIGIBLE",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: true,
                sort: true,
                width: '145px',
            }
        },
        // {
        //     name: "page_no",
            //     label: "Billing Provider",
            //     options: {
                //         showColumn: true,
                //         filter: false,
                //         filterType: '',
                //         search: true,
                //         sort: true,
                //         width: '111px',
            //     }
        // },
        // {
        //     name: "page_no",
            //     label: "Rendering Provider",
            //     options: {
                //         showColumn: true,
                //         filter: false,
                //         filterType: '',
                //         search: true,
                //         sort: true,
                //         width: '111px',
            //     }
        // },
    ];

    const coderMedicationRecordColumns = [
        {
            name: "id",
            label: "Action",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: false,
                width: '82px',
                otherColumnName: 'status',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <div className="flex_item_cc gap10">
                                {otherColumnValue && otherColumnValue == 'No Action' ?
                                    <div className="dotsAnimation"></div>
                                    :
                                    <div className="ml-2"></div>
                                }
                                <Tooltip title="Edit" arrow placement="top">
                                    <div>
                                        <a role="button" className="hyperlink" style={{ cursor: "pointer", color: "#209f9d" }} onClick={() => notificationIconRightDrawer(true, 'EditMEDICATIONCoderWorkBench', value)}>
                                            <EditPenIcon className="w24 h24" />
                                        </a>
                                    </div>
                                </Tooltip>
                                {/* <Tooltip title="Status" arrow placement="top">
                                    <a role="button" className="hyperlink" style={{ cursor: "pointer", color: "#209f9d" }} onClick={() => notificationIconRightDrawer(true, 'StatusMEDICATIONCoderWorkBench', value)}>
                                        <CheckListIcon className="w24 h24" />
                                    </a>
                                </Tooltip> */}
                            </div>
                        </Box>
                    );
                }
            }
        },
        {
            name: "status",
            label: "STATUS",
            options: {
                showColumn: true,
                filter: true,
                filterType: 'selectStatus',
                search: false,
                sort: true,
                width: '101px',
                otherColumnNameName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            {value == 'Rejected' && <StatusBadge status={"danger"} text={'Rejected'} />}
                            {value == 'No Action' && <StatusBadge status={"light"} text={'No Action'} />}
                            {value == 'Accepted' && <StatusBadge status={"success"} text={'Accepted'} />}
                            {value == 'Pending for clarification' && <StatusBadge status={"info"} text={'Pending for clarification'} />}
                        </>
                    );
                }
            }
        },
        {
            name: "entiy_id",
            label: "Entiy ID",
            options: {
                showColumn: true,
                filter: true,
                filterType: 'text',
                search: true,
                sort: true,
                width: '250px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <Tooltip title={value} arrow placement="top">
                            <div className="table-short-text">
                                {value}
                            </div>
                        </Tooltip>
                    );
                }
            }
        },
        {
            name: "page_no",
            label: "PAGE NO",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: true,
                sort: true,
                width: '111px',
            }
        },
        {
            name: "dos_start",
            label: "DOS BEGIN",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '124px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    if (value != null && value != '') {
                        const formattedDate = moment(value, "YYYY/MM/DD").format("MM/DD/YYYY");
                        return formattedDate;
                    } else {
                        return value;
                    }
                },
            }
        },
        {
            name: "dos_end",
            label: "DOS END",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '111px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    if (value != null && value != '') {
                        const formattedDate = moment(value, "YYYY/MM/DD").format("MM/DD/YYYY");
                        return formattedDate;
                    } else {
                        return value;
                    }
                },
            }
        },
        // {
        //     name: "billing_provider",
            //     label: "Billing Provider",
            //     options: {
                //         showColumn: false,
                //         filter: false,
                //         filterType: '',
                //         search: true,
                //         sort: true,
                //         width: '111px',
            //     }
        // },
        // {
        //     name: "rendering_provider",
            //     label: "Rendering Provider",
            //     options: {
                //         showColumn: false,
                //         filter: false,
                //         filterType: '',
                //         search: true,
                //         sort: true,
                //         width: '111px',
            //     }
        // },
    ];

    const loadCoderBenchMark = (Id) => {
        writeToLogFile("Id...", Id);
        Id = btoa(Id)
        var awe_display_id = btoa(location.state?.awe_display_id)
        writeToLogFile("value after encryption ", Id)
        setCoderBenchmarkLoading(true);
        AWVCoderWorkBench.get('data/cin/' + Id + '/'+ awe_display_id+ '/' + location.state?.year)
            .then(res => {
                setCoderBenchmarkData(res.data);
                queryLatterGetDataFunction(cin, res.data?.awe_id)
                setCoderBenchmarkLoading(false);
            })
            .catch(err => {
                console.log(err);
                setCoderBenchmarkLoading(false);
            })
    }

    const queryLatterGetDataFunction = (tempCin, tempAweID = '') => {
        QueryLetter.get('/get_query_details?query_letter=&awe_id=' + tempAweID + '&cin=' + tempCin+ '&year=' + location.state?.year+ '&lob=' + location.state?.lob)
            .then(res => {
                setQuerryLatterGetData(res.data);
                if (res.data && res.data.length > 0 && res.data[0] && res.data[0]?.attempt) {
                    setQueryLetterEdit(res.data[0]);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const loadformAWEProcessedRecord = () => {
        writeToLogFile("awe backend id before encryption - ", location.state?.awe_display_id)
        var cin_en = btoa(cin)
        var awe_display_id = btoa(location.state?.awe_display_id)
        AWVApi.get('/get-awe-processed-record-details?year=' + location.state?.year + '&organisation_id=1&lob=' + location.state?.lob + '&cin=' + cin_en + '&awe_display_id=' + awe_display_id)
            .then(res => {
                if (res.data && res.data[0]) {
                    setIsRecordPresent(res.data[0].present_in_coder_workbench)
                    setCwId(res.data[0].id_of_coder_workbench)
                    history.push({
                        pathname: '/new_awe/awe-coder-workbench',
                        search: '?cin=' + cin,
                        state: {
                          ...history.location.state,
                          is_cw_present: res.data[0].present_in_coder_workbench,
                          cw_id: res.data[0].id_of_coder_workbench
                        }
                      });
                  
                    // setIsRecordPresent(res.data[0].present_in_coder_workbench)
                    // setCwId(res.data[0].id_of_coder_workbench)
                } else {
                    // setId('');
                }
            })
            .catch(err => {
                console.log(err)
            })
    }


    const loadCWDXReport = () => {
        writeToLogFile("awe backend id before encryption - ", location.state?.awe_display_id)
        var cin_en = btoa(cin)
        var awe_display_id = btoa(location.state?.awe_display_id)
        writeToLogFile("loadCWDXReport() call ");
        setCoderCWDXLoading(true);
        writeToLogFile("/get_member_hcc_details?cin=" + cin_en + ' year=' + location.state?.year + ' organisation_id=1 lob=' + location.state?.lob + ' chart_record=' + location.state?.chart_chase_record + ' awe_display_id=' + awe_display_id + ' awv_backend_id=' + location.state?.awv_backend_id);
        AWVApi.get("/get_member_hcc_details?cin=" + cin_en + '&year=' + location.state?.year + '&organisation_id=1&lob=' + location.state?.lob + '&chart_record=' + location.state?.chart_chase_record + '&awe_display_id=' + awe_display_id)
            .then(res => {
                var Accepted = 0;
                var No_Action = 0;
                var Rejected = 0;
                var Pending_for_clarification = 0;
                res.data && res.data.forEach(element => {
                    if (element.status == 'Accepted') {
                        Accepted++;
                    } else if (element.status == 'Rejected') {
                        Rejected++;
                    } else if (element.status == 'Pending for clarification') {
                        Pending_for_clarification++;
                    }
                });
                setCWDXRecordStatusCount({
                    'Accepted': Accepted,
                    'No_Action': No_Action,
                    'Rejected': Rejected,
                    'Pending_for_clarification': Pending_for_clarification
                })
                writeToLogFile("Data retrieved successfully...");
                setCoderCWDXLoading(false);
                setGenerateLog(true)
            })
            .catch(err => {
                console.log(err);
                setCoderCWDXLoading(false);
                writeToLogFile("Error in retrieving data Response..." + err);
                setGenerateLog(true)
            })
    }

    const loadCWPROCCODEReport = () => {
        writeToLogFile("loadCWPROCCODEReport() call ");
        setCoderCWPROCCODELoading(true);
        writeToLogFile('API Call : data/cw_proc_code/cin/' + cin);
        AWVCoderWorkBench.get('data/cw_proc_code/cin/' + cin)
            .then(res => {
                var Accepted = 0;
                var No_Action = 0;
                var Rejected = 0;
                var Pending_for_clarification = 0;
                res.data && res.data.forEach(element => {
                    if (element.cw_proc_status == 'Accepted') {
                        Accepted++;
                    } else if (element.cw_proc_status == 'No Action') {
                        No_Action++;
                    } else if (element.cw_proc_status == 'Rejected') {
                        Rejected++;
                    } else if (element.cw_proc_status == 'Pending for clarification') {
                        Pending_for_clarification++;
                    }
                });
                writeToLogFile('loadCWPROCCODEReport' + ' ' + Accepted + ' ' + No_Action + ' ' + Rejected + ' ' + Pending_for_clarification);
                setCWPROCCODERecordStatusCount({
                    'Accepted': Accepted,
                    'No_Action': No_Action,
                    'Rejected': Rejected,
                    'Pending_for_clarification': Pending_for_clarification
                })
                setCoderCWPROCCODELoading(false);
            })
            .catch(err => {
                console.log(err);
                setCoderCWPROCCODELoading(false);
                writeToLogFile("Error in retrieving data Response..." + err);
            })
    }

    const loadMedicationReport = () => {
        writeToLogFile("loadMedicationReport() call ");
        setCoderMedicationLoading(true);
        writeToLogFile("API Call: "+ 'data/medication/cin/' + cin);
        AWVCoderWorkBench.get('data/medication/cin/' + cin)
            .then(res => {
                var Accepted = 0;
                var No_Action = 0;
                var Rejected = 0;
                var Pending_for_clarification = 0;
                res.data && res.data.forEach(element => {
                    if (element.status == 'Accepted') {
                        Accepted++;
                    } else if (element.status == 'No Action') {
                        No_Action++;
                    } else if (element.status == 'Rejected') {
                        Rejected++;
                    } else if (element.status == 'Pending for clarification') {
                        Pending_for_clarification++;
                    }
                });
                writeToLogFile('loadMedicationReport', Accepted, No_Action, Rejected, Pending_for_clarification);
                setMedicationRecordStatusCount({
                    'Accepted': Accepted,
                    'No_Action': No_Action,
                    'Rejected': Rejected,
                    'Pending_for_clarification': Pending_for_clarification
                })
                setCoderMedicationLoading(false);
            })
            .catch(err => {
                console.log(err);
                setCoderMedicationLoading(false);
                writeToLogFile("Error in retrieving data Response..." + err);
            })
    }

    const handleOnclickUrl = () => {
        if (params.get('cin') && params.get('cin') != '') {
            history.push('/new_awe/awe_analytics_summary?cin=' + params.get('cin'));
            // props.handleMemberIdSet('');
        } else {
            setShowUserDetails(false);
            // props.handleMemberIdSet('');
        }
    }
    useEffect(() => {
        if (coderBenchmarkData.file_name_url && coderBenchmarkData.file_name_url != '') {
            aws.config.update({
                accessKeyId: ACCESS_KEY,
                secretAccessKey: SECRET_ACCESS_KEY,
                region: REGION
            });
            const s3d = new aws.S3();
            var credentials = {
                accessKeyId: ACCESS_KEY,
                secretAccessKey: SECRET_ACCESS_KEY
            };
            AWS.config.update({ credentials: credentials, region: process.env.REACT_APP_REGION, signatureVersion: "v4" });
            var s3 = new AWS.S3({
                signatureVersion: "v4"
            });

            //let Key = 'test.pdf';
            let url = coderBenchmarkData.file_name_url
            const [protocol, fullHost, ...pathSegments] = url.split('/');
            const [bucketName, port] = pathSegments[0].split('.');
            const objectKey = pathSegments.slice(1).join('/');

            const Key = `${objectKey}`;

            s3.setupRequestListeners = (request) => {
                request.on('build', (req) => {

                    req.httpRequest.headers['x-amz-date'] = process.env.REACT_APP_KeyID;
                });
            }
            /*  adding condition to upload form in different folder as per lob select */
            var presignedGETURL = s3.getSignedUrl('getObject', {
                Bucket: bucketName,
                Key: Key
            });
         
            setFileUrl(presignedGETURL);
            axios({
                url: presignedGETURL,
                responseType: "arraybuffer",
                method: "GET",
            }).then((res) => {

                let file = new Blob([res.data], {
                    type: "application/pdf",
                });

                let fileURL = URL.createObjectURL(file);



            }).catch((err) => { console.log("Error while downloading file...") })

        }
    }, [coderBenchmarkData.file_name_url]);

    const arrowRef = useRef()
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    const handleClose = () => {
        setAnchorEl(null);
    };


    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        // Generate and download log file when logMessages have been updated
        if (logMessages.length > 0  && generateLog === true) {
        LogFileUploadToS3(logMessages,  ACCESS_KEY, SECRET_ACCESS_KEY, process.env.REACT_APP_LOG_BUCKET, "Coder_WorkBench")
        }
    }, [generateLog === true, logMessages, cin, location.state?.year, location.state?.lob,location.state?.awe_display_id]); 

    const onClick = (event, url) => {
        try {
            event.preventDefault();
            axios({
                url: url,
                responseType: "arraybuffer",
                method: "GET",
            }).then((res) => {
                window.location.href = url
            }).catch((err) => {
                // alertTitleClasses(err)
                toast.error('Error while downloading the form');
            })
        } catch (error) {
            toast.error('Error while downloading the form');
        }

    }

    return (
        <>
            <div className="card h-auto mb20">
                <div className="card-body">
                    <div className="card_header flex_item_cb mb24">
                        <h2 className="fs18 card-title">CODER WORKBENCH</h2>
                        {/* <Link to={"/new_awe/awe-record"}><IconButton><ForwardLeftIcon className="w16" /></IconButton></Link> */}
                    </div>
                    <div className="row row-cols-xl-6 row-cols-md-3 row-cols-sm-2 no-gutters mb-3 col_border_left">
                        <div className="items col mb-4">
                            <div className="coder_word_card">
                                <h6>CIN</h6>
                                <p>{(coderBenchmarkData.ih_member_id && coderBenchmarkData.ih_member_id != '') ? coderBenchmarkData.ih_member_id : ''}</p>
                            </div>
                        </div>
                        <div className="items col mb-4">
                            <div className="coder_word_card">
                                <h6>AWE ID</h6>
                                <p>{(coderBenchmarkData.awe_id && coderBenchmarkData.awe_id != '') ? coderBenchmarkData.awe_id : ''}</p>
                            </div>
                        </div>
                        <div className="items col mb-4">
                            <div className="coder_word_card">
                                <h6>First Name</h6>
                                <p>{(coderBenchmarkData.member_f_name && coderBenchmarkData.member_f_name != '') ? coderBenchmarkData.member_f_name : ''}</p>
                            </div>
                        </div>
                        <div className="items col mb-4">
                            <div className="coder_word_card">
                                <h6>Last Name</h6>
                                <p>{(coderBenchmarkData.member_l_name && coderBenchmarkData.member_l_name != '') ? coderBenchmarkData.member_l_name : ''}</p>
                            </div>
                        </div>
                        <div className="items col mb-4">
                            <div className="coder_word_card">
                                <h6>DOB</h6>
                                <p>{(coderBenchmarkData.member_dob && coderBenchmarkData.member_dob != '') ? coderBenchmarkData.member_dob : ''}</p>
                            </div>
                        </div>
                        <div className="items col mb-4">
                            <div className="coder_word_card">
                                <h6>Project ID</h6>
                                <p>{(coderBenchmarkData.project_id && coderBenchmarkData.project_id != '') ? coderBenchmarkData.project_id : ''}</p>
                            </div>
                        </div>
                        <div className="items col mb-4">
                            <div className="coder_word_card">
                                <h6>Chart Status</h6>
                                <p className='badge'>{coderBenchmarkData.chart_status}</p>
                            </div>
                        </div>
                        <div className="items col mb-4">
                            <div className="coder_word_card">
                                <h6>Assigned To</h6>
                                <p>{coderBenchmarkData.assigned_to}</p>
                            </div>
                        </div>
                        <div className="items col mb-4">
                            <div className="coder_word_card">
                                <h6>Assign Date</h6>
                                <p>{coderBenchmarkData.assigned_dt_ts ? moment(coderBenchmarkData.assigned_dt_ts, "YYYY/MM/DD").format("MM/DD/YYYY") : ''}</p>
                            </div>
                        </div>
                        <div className="items col mb-4">
                            <div className="coder_word_card">
                                <h6>File Name URL</h6>
                                <p>
                                    {(coderBenchmarkData.file_name_url && coderBenchmarkData.file_name_url && coderBenchmarkData.file_name_url != '') ?
                                        <>
                                            {fileUrl && fileUrl.includes(".pdf") ?
                                                <a target="_blank" onClick={(e) => onClick(e,fileUrl)}>
                                                    <PlusFileIcon className="w24 h24" />
                                                    <b>{coderBenchmarkData.file_name_url ? coderBenchmarkData.file_name_url?.split('/').pop() : ''}</b>
                                                </a>
                                                :
                                                <a onClick={(e) => onClick(e,fileUrl)} target="_blank" download>
                                                    <PlusFileIcon className="w24 h24" />
                                                    <b> {coderBenchmarkData?.file_name_url ? coderBenchmarkData.file_name_url?.split('/').pop() : ''}</b>
                                                </a>
                                            }
                                        </>
                                        :
                                        <></>
                                    }
                                </p>
                            </div>
                        </div>
                        <div className="items col mb-4">
                            <div className="coder_word_card">
                                <h6>File Format Type</h6>
                                <p>{coderBenchmarkData.file_format_type}</p>
                            </div>
                        </div>
                        <div className="items col mb-4">
                            <div className="coder_word_card">
                                <h6>NLP Processed Date</h6>
                                <p>{coderBenchmarkData.assigned_dt_ts ? moment(coderBenchmarkData.assigned_dt_ts, "YYYY/MM/DD").format("MM/DD/YYYY") : ''}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card h-auto">
                <div className="card-body">
                    <div className="tabs_wrapper mb20 overflow-auto custom-scrollbar">
                        <ul className="nav nav-tabs flex-nowrap" id="myTab" role="tablist" style={{ minWidth: "460px" }}>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="home-tab" data-toggle="tab" data-target="#Diagonsis-code" type="button"
                                    role="tab" aria-controls="Diagonsis-code" aria-selected="true" onClick={() => handleTabChange(1)}
                                >
                                    Diagnosis code
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="profile-tab" data-toggle="tab" data-target="#Procedure-code" type="button"
                                    role="tab" aria-controls="Procedure-code" aria-selected="false" onClick={() => handleTabChange(2)}
                                >
                                    Procedure code
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="contact-tab" data-toggle="tab" data-target="#Medication" type="button"
                                    role="tab" aria-controls="Medication" aria-selected="false" onClick={() => handleTabChange(3)}
                                >
                                    Medication
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="tab-content" id="myTabContent">
                        <div className={(tabvalue && tabvalue == '1') ? "tab-pane fade show active" : "tab-pane fade"} id="Diagonsis-code" role="tabpanel" aria-labelledby="Diagonsis-code-tab">
                            {(tabvalue && tabvalue == '1') ?
                                <CustomAweDatatable
                                    id={'procedure_code'}
                                    titleName="DIAGNOSIS CODE"
                                    UrlAxios={'AWVApi'}
                                    downloadFileName="diagonsis_code"
                                    //urlLink={"data/cw_dx/cin/" + cin}
                                    urlLink={"/get_member_hcc_details?cin=" + btoa(cin) + '&year=' + location.state?.year + '&organisation_id=1&lob=' + location.state?.lob + '&chart_record=' + location.state?.chart_chase_record + '&awe_display_id=' + btoa(location.state?.awe_display_id)}
                                    columns={coderCWDXRecordColumns}
                                    showColumnBtn={true}
                                    searchBox={true}
                                    downloadBtn={true}
                                    downloadBtnReport={location.state?.chart_chase_record && location.state?.chart_chase_record == 'YES' ? true : false}
                                    downloadClaimURL={"/get-chart-chase-claims?cin=" + cin}
                                    downloadClaimFileName={'Claims Report'}
                                    pagination={true}
                                    filterBox={true}
                                    customFilter={['model_1']}
                                    colSpan={13}
                                    addNewEvent={(
                                        <>
                                            {/* <Tooltip title={'Add'} arrow placement="top"> */}
                                            <div className="row ml-1">
                                                <AddCWDXCoderWorkBench loadMenuData={''} editMenuData={editMenuData} open={openDxCode} handleClickOpen={handleDxCodeClickOpen} handleClose={handleDxCodeClose} chart_chase={location.state?.chart_chase_record} awe_display_id={location.state?.awe_display_id}
                                                cw_id={cwId} is_cw_present={isRecordPresent} member_f_name={coderBenchmarkData.member_f_name} member_l_name={coderBenchmarkData.member_l_name} member_dob={coderBenchmarkData.member_dob} awv_backend_id={location.state?.awv_backend_id} />
                                                <EditCWDXCoderWorkBench loadMenuData={''} editMenuData={editMenuData} open={openEditDxCode} handleClickOpen={handleDxCodeClickOpen} handleClose={handleDxCodeClose} chart_chase={location.state?.chart_chase_record} awe_display_id={location.state?.awe_display_id}
                                                awv_backend_id= {location.state?.awv_backend_id}   cw_id={cwId} is_cw_present={isRecordPresent}  />
                                            </div>
                                            {/* </Tooltip> */}
                                        </>
                                    )}
                                    statusOptions={[
                                        { 'value': 'Accepted', 'count': CWDXRecordStatusCount?.Accepted, 'text': 'Accepted', 'colorClass': "", "icon": <CheckTikIcon className="w16 h16" /> },
                                        { 'value': 'Rejected', 'count': CWDXRecordStatusCount?.Rejected, 'text': 'Rejected', 'colorClass': "badge-danger", "icon": <CloseLineIcon className="w16 h16" /> },
                                        { 'value': 'Pending for clarification', 'count': CWDXRecordStatusCount?.Pending_for_clarification, 'text': 'Pending for clarification', 'colorClass': "badge-orange", "icon": <InfoLineIcon className="w16 h16" /> }
                                    ]}
                                />
                                :
                                <></>
                            }
                        </div>
                        <div className={(tabvalue && tabvalue == '2') ? "tab-pane fade show active" : "tab-pane fade"} id="Procedure-code" role="tabpanel" aria-labelledby="Procedure-code-tab">
                            {(tabvalue && tabvalue == '2') ?
                                <CustomAweDatatable
                                    titleName="Procedure code"
                                    downloadFileName="procedure_code"
                                    urlLink={"data/cw_proc_code/cin/" + cin}
                                    columns={coderCWPROCCODERecordColumns}
                                    showColumnBtn={true}
                                    searchBox={true}
                                    downloadBtn={true}
                                    pagination={true}
                                    filterBox={true}
                                    colSpan={12}
                                    addNewEvent={(
                                        <Tooltip title={'Add'} arrow placement="top">
                                            <div>
                                                <IconButton type="button" onClick={() => notificationRightDrawer(true, 'EditCWPROCCODECoderWorkBench', '')}>
                                                    <PlusFillIcon className="w16" />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    )}
                                    statusOptions={[
                                        { 'value': 'Accepted', 'count': CWPROCCODERecordStatusCount?.Accepted, 'text': 'Accepted', 'colorClass': "", "icon": <CheckTikIcon className="w16 h16" /> },
                                        { 'value': 'No Action', 'count': CWPROCCODERecordStatusCount?.No_Action, 'text': 'No Action', 'colorClass': "badge-light", "icon": <CircleIcon className="w16 h16" /> },
                                        { 'value': 'Rejected', 'count': CWPROCCODERecordStatusCount?.Rejected, 'text': 'Rejected', 'colorClass': "badge-danger", "icon": <CloseLineIcon className="w16 h16" /> },
                                        { 'value': 'Pending for clarification', 'count': CWPROCCODERecordStatusCount?.Pending_for_clarification, 'text': 'Pending for clarification', 'colorClass': "badge-orange", "icon": <InfoLineIcon className="w16 h16" /> }
                                    ]}
                                />
                                :
                                <></>
                            }
                        </div>
                        <div className={(tabvalue && tabvalue == '3') ? "tab-pane fade show active" : "tab-pane fade"} id="Medication" role="tabpanel" aria-labelledby="Medication-tab">
                            {(tabvalue && tabvalue == '3') ?
                                <CustomAweDatatable
                                    titleName="Medication"
                                    downloadFileName="medication"
                                    urlLink={"data/medication/cin/" + cin}
                                    columns={coderMedicationRecordColumns}
                                    showColumnBtn={true}
                                    searchBox={true}
                                    downloadBtn={true}
                                    pagination={true}
                                    filterBox={true}
                                    colSpan={6}
                                    addNewEvent={(
                                        <Tooltip title={'Add'} arrow placement="top">
                                            <div>
                                                <IconButton type="button" onClick={() => notificationRightDrawer(true, 'EditMEDICATIONCoderWorkBench', '')}>
                                                    <PlusFillIcon className="w16" />
                                                </IconButton>
                                            </div>
                                        </Tooltip>
                                    )}
                                    statusOptions={[
                                        { 'value': 'Accepted', 'count': MedicationRecordStatusCount?.Accepted, 'text': 'Accepted', 'colorClass': "", "icon": <CheckTikIcon className="w16 h16" /> },
                                        { 'value': 'No Action', 'count': MedicationRecordStatusCount?.No_Action, 'text': 'No Action', 'colorClass': "badge-light", "icon": <CircleIcon className="w16 h16" /> },
                                        { 'value': 'Rejected', 'count': MedicationRecordStatusCount?.Rejected, 'text': 'Rejected', 'colorClass': "badge-danger", "icon": <CloseLineIcon className="w16 h16" /> },
                                        { 'value': 'Pending for clarification', 'count': MedicationRecordStatusCount?.Pending_for_clarification, 'text': 'Pending for clarification', 'colorClass': "badge-orange", "icon": <InfoLineIcon className="w16 h16" /> }
                                    ]}
                                />
                                :
                                <></>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        aweRightSidebarCloseDatatableReload: state.moduleFilter.aweRightSidebarCloseDatatableReload,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(AWVCoderBenchmark);
	