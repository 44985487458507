import { useEffect, useState } from "react";
import CardComponent from "../../components/Cards/card";
import GridTable from "../../components/TableGrids/GridTable.table";
import { anchorTag, addSuffixTwoDecimal } from "./customRender";
import api from "./Rafservice.Instance";
import { FLAG, GAP_OR_REPORTED } from "./string.constants";
import PropTypes from "prop-types";
import history from "../../history";
import GChart from '../../components/GChart/GChart';
import DistributionMemberChart from '../../components/Charts/DistributionMember.Chart';
import { connect } from 'react-redux';

const GapMemberDistribution = (props) => {

    const [GapMemberData, setGapMemberData] = useState([]);
    const { year, type } = props;
    const [ChartData, setChartData] = useState([]);
    const [venChartData, setVenChartData] = useState([]);
    const [venChartLink, setVenChartLink] = useState([]);
    const [venDatashow, setVenDatashow] = useState('');
    const GapMemberCols = [
        {
            label: 'Gap type',
            name: 'gaps'
        },
        {
            label: 'Member Count',
            name: 'count',
            options: anchorTag
        }
    ];

    const loadGapMemberDistribution = () => {
        let data = [];
        let newChartData = [];
        let NewVenChartData = [];
        let NewVenChartLink = [];
            newChartData.push(["Description"]);
            api.get('/acaMemberGapCount/' + year)
                .then(res => {
                    for (const dataObj of res) {
                        data[0] = ['Historic', dataObj.h]
                        data[1] = ['Co-Morbid', dataObj.c]
                        data[2] = ['Lab', dataObj.l]
                        data[3] = ['Pharma', dataObj.p]
                        data[4] = ['Co-Morbid & Historic', dataObj.ch]
                        data[5] = ['Historic & Lab', dataObj.hl]
                        data[6] = ['Historic & Pharma', dataObj.hp]
                        data[7] = ['Co-Morbid & Lab', dataObj.cl]
                        data[8] = ['Co-Morbid & Pharma', dataObj.cp]
                        data[9] = ['Lab & Pharma', dataObj.lp]
                        data[10] = ['Co-Morbid,Historic & Lab', dataObj.chl]
                        data[11] = ['Co-Morbid,Historic & Pharma', dataObj.chp]
                        data[12] = ['Co-Morbid,Lab & Pharma', dataObj.clp]
                        data[13] = ['Historic,Lab & Pharma', dataObj.hlp]
                        data[14] = ['Co-Morbid,Historic,Lab & Pharma', dataObj.chlp]
    
                        newChartData.push(['Historic' + ' ' + dataObj.h.toString()]);
                        newChartData.push(['Co-Morbid' + ' ' + dataObj.c.toString()]);
                        newChartData.push(['Lab' + ' ' + dataObj.l.toString()]);
                        newChartData.push(['Pharma' + ' ' + dataObj.p.toString()]);
                        newChartData.push(['Co-Morbid Historic' + ' ' + dataObj.ch.toString()]);
                        newChartData.push(['Historic Lab' + ' ' + dataObj.hl.toString()]);
                        newChartData.push(['Historic Pharma' + ' ' + dataObj.hp.toString()]);
                        newChartData.push(['Co-Morbid Lab' + ' ' + dataObj.cl.toString()]);
                        newChartData.push(['Co-Morbid Pharma' + ' ' + dataObj.cp.toString()]);
                        newChartData.push(['Lab Pharma' + ' ' + dataObj.lp.toString()]);
                        newChartData.push(['Co-Morbid Historic Lab' + ' ' + dataObj.chl.toString()]);
                        newChartData.push(['Co-Morbid Historic Pharma' + ' ' + dataObj.chp.toString()]);
                        newChartData.push(['Co-Morbid Lab Pharma' + ' ' + dataObj.clp.toString()]);
                        newChartData.push(['Historic Lab Pharma' + ' ' + dataObj.hlp.toString()]);
                        newChartData.push(['Co-Morbid Historic, Lab Pharma' + ' ' + dataObj.chlp.toString()]);
    
                        NewVenChartData = [
                            { name: "Historic", key: ["Historic"], data: dataObj.h },
                            { name: "Co-Morbid", key: ["Co-Morbid"], data: dataObj.c   },
                            { name: "Lab", key: ["Lab"], data: dataObj.l    },
                            { name: "Pharma", key: ["Pharma"], data: dataObj.p   },
                            
                            { name: "Co-Morbid,Historic", data: dataObj.ch, key: ["Co-Morbid", "Historic"]   },
                            { name: "Historic,Lab", data: dataObj.hl, key: ["Historic", "Lab"]   },
                            { name: "Historic,Pharma", data: dataObj.hp, key: ["Historic", "Pharma"]   },
                            { name: "Co-Morbid,Lab", data: dataObj.cl, key: ["Co-Morbid", "Lab"]   },
                            { name: "Co-Morbid,Pharma", data: dataObj.cp, key: ["Co-Morbid", "Pharma"]   },
                            { name: "Lab,Pharma", data: dataObj.lp, key: ["Lab", "Pharma"]   },
                            
                            { name: "Co-Morbid,Historic,Lab", data: dataObj.chl, key: ["Co-Morbid", "Historic", "Lab"]   },
                            { name: "Co-Morbid,Historic,Pharma", data: dataObj.chp, key: ["Co-Morbid", "Historic", "Pharma"]   },
                            { name: "Co-Morbid,Lab,Pharma", data: dataObj.clp, key: ["Co-Morbid", "Lab", "Pharma"]   },
                            { name: "Historic,Lab,Pharma", data: dataObj.hlp, key: ["Historic", "Lab", "Pharma"]   },
                            
                            { name: "Co-Morbid,Historic,Lab,Pharma", data: dataObj.chlp, key: ["Co-Morbid", "Historic", "Lab", "Pharma"]   }
                        ];
                        NewVenChartLink = [
                            { name: "Historic", link:'H', color:'#6db382', index:0 },
                            { name: "Co-Morbid", link:'C', color:'#7c7fe4', index:1},
                            { name: "Lab", link:'L', color:'#ff85ad', index:2},
                            { name: "Pharma", link:'P', color:'#d3b17e', index:3},
                            
                            { name: "Co-Morbid|Historic", link:'CH', color:'#d143a1', index:4},
                            { name: "Historic|Lab", link:'HL', color:'#d143a1', index:5},
                            { name: "Historic|Pharma", link:'HP', color:'#aa4b3e', index:6},
                            { name: "Co-Morbid|Lab", link:'CL', color:'#d143a1', index:7},
                            { name: "Co-Morbid|Pharma", link:'CP', color:'#bb544d', index:8},
                            { name: "Lab|Pharma", link:'LP', color:'#db714c', index:9},
                            
                            { name: "Historic|Co-Morbid|Lab", link:'CHL', color:'#aa4b3e', index:10},
                            { name: "Co-Morbid|Historic|Pharma", link:'CHP', color:'#aa4b3e', index:11},
                            { name: "Co-Morbid|Lab|Pharma", link:'CLP', color:'#a04c3f', index:12},
                            { name: "Historic|Lab|Pharma", link:'HLP', color:'#aa4b3e', index:13},
                            
                            { name: "Co-Morbid|Historic|Lab|Pharma", link:'CHLP', color:'#f35555', index:14} ,
                            
                        ];
                    }
        
                    setGapMemberData(data);
                    setChartData(newChartData);
                    setVenChartData(NewVenChartData);
                    setVenChartLink(NewVenChartLink);
                })
                .catch(err => {
                    // console.log(err);
                })

    };

    const gapMemberClick = (e, el) => {
        if (el.colIndex === 1) {
            switch (el.rowIndex) {
                case 0:
                    localStorage.setItem(FLAG, 'H');
                    break;
                case 1:
                    localStorage.setItem(FLAG, 'C');
                    break;
                case 2:
                    localStorage.setItem(FLAG, 'L');
                    break;
                case 3:
                    localStorage.setItem(FLAG, 'P');
                    break;
                case 4:
                    localStorage.setItem(FLAG, 'CH');
                    break;
                case 5:
                    localStorage.setItem(FLAG, 'HL');
                    break;
                case 6:
                    localStorage.setItem(FLAG, 'HP');
                    break;
                case 7:
                    localStorage.setItem(FLAG, 'CL');
                    break;
                case 8:
                    localStorage.setItem(FLAG, 'CP');
                    break;
                case 9:
                    localStorage.setItem(FLAG, 'LP');
                    break;
                case 10:
                    localStorage.setItem(FLAG, 'CHL');
                    break;
                case 11:
                    localStorage.setItem(FLAG, 'CHP');
                    break;
                case 12:
                    localStorage.setItem(FLAG, 'CLP');
                    break;
                case 13:
                    localStorage.setItem(FLAG, 'HLP');
                    break;
                case 14:
                    localStorage.setItem(FLAG, 'CHLP');
                    break;
            }
            localStorage.setItem(GAP_OR_REPORTED, type)
            history.push('/layout/acaGapSummaryMembers');
        }
    }

    useEffect(() => {
        loadGapMemberDistribution();
    }, [props, year]);
   
    
    return (
        <div >
            {/* <CardComponent
                heading={`DISTRIBUTION OF MEMBERS BASED ON GAPS SOURCE`}
                body={
                    <GChart data={ChartData} chartType="WordTree" height="320px" width="900px"></GChart>                    
                }></CardComponent> */}

            <DistributionMemberChart data={venChartData} type={type} chartLink={venChartLink} MemberChartData={props.MemberChartData} />                    
          {/*
            <CardComponent
                heading={`DISTRIBUTION OF MEMBERS BASED ON GAPS SOURCE`}
                body={
                    <GridTable
                        data={GapMemberData}
                        columns={GapMemberCols}
                        onTableClick={gapMemberClick}
                    ></GridTable>
                }></CardComponent>
            */ }

        </div>
    )
}

GapMemberDistribution.propTypes = {
    year: PropTypes.string,
    type: PropTypes.string
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
    };
  }
export default connect(mapStateToProps)(GapMemberDistribution);