import "react-responsive-carousel/lib/styles/carousel.min.css";
import HeaderForETL from '../../components/home/header/HeaderForETL';


    const Description = (props) => {
        return(
            <div>
                 <HeaderForETL heading={'Descriptions '} />
                 <br/><br/>
                <div className="card">
                <div className="card-header">
                    <span className="d-flex bd-highlight">
                    <h6 className="p-2">File Upload </h6>
                    </span>
                    <div>
                        <br/>
                    <p>
                    <b>File Upload tab </b> allows to upload the file by selecting bucket Name, Folder Name where we want to upload the file and choose file option that we want to upload. 
                    </p>
                    <p>
                    <b># Files </b> slider will shows the total no of files present in bucket and file will able to be downloaded by clicking download button. By clicking on Upload button, the file will be able to upload to S3 Bucket.
                    </p>
                    </div>
                </div>
                </div>
                <br/>
                <br/>
                <div className="card">
                <div className="card-header">
                    <span className="d-flex bd-highlight">
                    <h6 className="p-2">File Download </h6>
                    </span>
                    <div>
                        <br/>
                    <p>
                    <b>File Download tab </b> allows to download the file present in a bucket. <b># Files </b> slider will shows the total no of files present in bucket and file will able to be downloaded by clicking download button.
                    </p>
                    <p>
                    
                    </p>
                    </div>
                </div>
            </div>
            </div>
        );
    };

export default Description;
