import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import GridTable from "../../components/TableGrids/GridTable.table";
import { anchorTag, numberWithCommas, numberWithCommasPrefixDolar, anchorTagnumberWithCommas } from '../../assets/constants/customRender'
import history from "../../history";
import { MAO_MEMBER_ID } from "../../assets/constants/string.constants";
import { getMeInitialSelect } from '../../assets/constants/meInitialList';
import CardComponent from "../../components/Cards/card";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import BarVertical from '../../components/GChart/BarVertical';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import GChart from '../../components/GChart/GChart';
import PieChart from '../../components/GChart/PieChart';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import SubHeader from '../../components/home/header/subHeader';

const MaoChartReviewOverallSummaryPage = (props) => {

    const [OverallSummaryData, setOverallSummaryData] = useState([]);
    const [PopulationForChartData, setPopulationForChartData] = useState([]);
    const [LinkedUnlinkedChartData, setLinkedUnlinkedChartData] = useState([]);

    const OverallSummaryColumn = [
        {
            name: "addedHCCs",
            label: "# Added HCCs",
            options: anchorTag
        },
        {
            name: "deletedHCCs",
            label: "# Deleted HCCs",
            options: anchorTag
        },
        {
            name: "addedICDs",
            label: "# Added ICDs",
            options: numberWithCommas
        },
        {
            name: "deletedICDs",
            label: "# Deleted ICDs",
            options: numberWithCommas
        },
        {
            name: "memberWithChartReview",
            label: "# Members with Chart Review",
            options: anchorTagnumberWithCommas
        },
        {
            name: "crProvidersCount",
            label: "# Number of Providers Submitting Chart Review",
            options: anchorTagnumberWithCommas
        },
        {
            name: "totalMembers",
            label: "# No of Members",
            options: numberWithCommas
        },
        {
            name: "overallReportedRevenue",
            label: "Overall Reported Revenue",
            options: numberWithCommasPrefixDolar
        },
        {
            name: "revenueFromCR",
            label: "Revenue From CR",
            options: numberWithCommasPrefixDolar
        },
        {
            name: "overallRevenueWithCR",
            label: "Overall Revenue With CR",
            options: numberWithCommasPrefixDolar
        },
    ]

    const loadOverallSummaryData = () => {
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/crOverallSummary/year/' + getAcaYear() + '/period/' + getMeInitialSelect())
            .then(res => {
                if (res && res.length > 0) {
                    setOverallSummaryData(res);
                }
            })
            .catch(err => {
                // console.log(err)
            })
    }

    const loadPopulationForChartView = () => {
        let newChartData = [];
        newChartData.push(["Year", "Percentage", { role: 'annotation' }]);
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/crPopulationPercent/year/' + getAcaYear() + '/period/' + getMeInitialSelect())
            .then(res => {
                for (const dataObj of res) {
                    newChartData.push([dataObj.year, dataObj.percentage_Contribution, dataObj.percentage_Contribution + '%']);
                }
                setPopulationForChartData(newChartData);
            })
            .catch(err => {
                // console.log(err);
            })
    }

    const loadLinkedUnlinkedChart = () => {
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/crLinkedvsUnlined/year/' + getAcaYear() + '/period/' + getMeInitialSelect())
            .then(res => {
                let chartDataRow = [];
                chartDataRow.push(["Description", "Count"]);
                if (res.length > 0) {
                    chartDataRow.push([res[0].year + '-Linked', res[0].linkedFlag]);
                    chartDataRow.push([res[0].year + '-Unlinked', res[0].unlinkedFlag]);
                }
                setLinkedUnlinkedChartData(chartDataRow);
            })
    };


    const OverallSummaryTableClick = (colData, cellMeta, rowdata, columns) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            history.push('/layout/medicare/review_hcc_summary')
        } else if ((cellMeta.colIndex === 1) && (colData.props.children != "")) {
            history.push('/layout/medicare/review_hcc_summary')
        } else if ((cellMeta.colIndex === 4) && (colData.props.children != "")) {
            history.push('/layout/medicare/review_member_summary')
        } else if ((cellMeta.colIndex === 5) && (colData.props.children != "")) {
            history.push('/layout/medicare/provider_summary')
        }
    }

    //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            loadOverallSummaryData();
            loadPopulationForChartView();
            loadLinkedUnlinkedChart();
        }
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect()
    ]);

    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Overall Summary'} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="col-lg-12 pr-0 pl-0 mt-4">
                        <CardComponent
                            heading={`CHART REVIEW OVERALL SUMMARY VIEW - ${props.yearSelect} / ${props.meInitialSelect}`}
                            body={
                                <GridTable
                                    data={OverallSummaryData}
                                    columns={OverallSummaryColumn}
                                    onTableClick={OverallSummaryTableClick}
                                ></GridTable>
                            }></CardComponent>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="row col-lg-12 pr-0 pl-0 mt-4">
                        <div className="col-md-6">
                            <CardComponent
                                heading={`POPULATION % FOR CHART REVIEW`}
                                body={
                                    <div className="box-height-chart">
                                        <BarVertical data={PopulationForChartData} chartType="BarChart" height="300px" width="100%" setStorgeItemName={''} redirectUrl={''} ></BarVertical>
                                    </div>
                                }></CardComponent>
                        </div>
                        <div className="col-md-6">
                            <CardComponent
                                heading={`Linked V/s Unlinked`}
                                body={
                                    <div className="box-height-chart">
                                        <PieChart data={LinkedUnlinkedChartData} chartType="PieChart" height="300px" width="100%"></PieChart>
                                    </div>
                                }></CardComponent>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br />

                <br />
                <br />

            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default connect(mapStateToProps)(MaoChartReviewOverallSummaryPage);
