import api from './Rafservice.Instance';
import { 
    SELECT_YEAR, SELECT_INITIAL, 
    SELECT_METAL_TIER, SELECT_VIEW_BY,
    SELECT_PLAN, SELECT_MODEL
} from "../../assets/constants/string.constants";

let MeInitial_list = [];
let MeInitial_select = '';
export const MeInitialList = () =>{

    // api.get('/aca_yearList/')
    // .then(res=>{
        SetMeInitialList(['Initial','Mid','Final']);
      //SetMeInitialList(['Final']);   
        if(localStorage.getItem(SELECT_INITIAL) && localStorage.getItem(SELECT_INITIAL) != ''){
            // localStorage.setItem('YEAR', res[0]);            
            // SetAcaYear(res[0]);
            SetMeInitialSelect(localStorage.getItem(SELECT_INITIAL)); 
        }else{
            localStorage.setItem(SELECT_INITIAL, 'Final');            
            SetMeInitialSelect('Final'); 
        }

    // })
    // .catch(err=>{
    //     // console.log(err);
    // })
}

export const SetMeInitialList = (data) =>{
    MeInitial_list = data;
}

export const SetMeInitialSelect = (data) =>{
    MeInitial_select = data;
    localStorage.setItem(SELECT_INITIAL, data);
}

export const getMeInitialList = () =>{
    return MeInitial_list;
}

export const getMeInitialSelect = () =>{
    return MeInitial_select;
}