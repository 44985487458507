import React, { useRef, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { signOut } from '../../../store/actions';
import { currentRoute } from '../../../store/actions';
import history from '../../../history';
import {
    SELECT_YEAR, SELECT_INITIAL,
    SELECT_METAL_TIER, SELECT_VIEW_BY,
    SELECT_KPI,
    SELECT_PLAN, SELECT_MODEL, SELECT_DISTRIBUTION
} from "../../../assets/constants/string.constants";
import ViewBySelect from '../../../components/DropDowns/ViewBySelect';
import ModelSelect from '../../../components/DropDowns/ModelSelect';
import MetalTierSelect from '../../../components/DropDowns/MetalTierSelect';
import PlanSelect from '../../../components/DropDowns/PlanSelect';
import MeInitialSelect from '../../../components/DropDowns/MeInitialSelect';
import AcaYearSelect from '../../../components/DropDowns/AcaYearSelect';
import KpiModuleSelect from '../../../components/DropDowns/KpiModuleSelect';
import DistributionSelect from '../../../components/DropDowns/DistributionSelect';
import { getAcaYearList, getAcaYear, AcaYearList } from '../../../assets/constants/yearList';
import { getKPIList, getKPISelect, KPIList } from '../../../assets/constants/kpiList';
import { getViewByList, getViewBySelect, ViewByList } from '../../../assets/constants/viewByList';
import { getModelList, getModelSelect, ModelList } from '../../../assets/constants/modelList';
import { getMetalTierList, getMetalTierSelect, MetalTierList } from '../../../assets/constants/metalTierList';
import { getPlanList, getPlanSelect, PlanList } from '../../../assets/constants/planList';
import { getMeInitialList, getMeInitialSelect, MeInitialList } from '../../../assets/constants/meInitialList';
import { getDistributionList, getDistributionSelect, DistributionList } from '../../../assets/constants/distributionList';

const SubHeader = (props) => {

    const [viewBySelect, setViewBySelect] = useState('');
    const [modelSelect, setModelSelect] = useState('');
    const [metalTierSelect, setMetalTierSelect] = useState('');
    const [planSelect, setPlanSelect] = useState('');
    const [meInitialSelect, setMeInitialSelect] = useState('');
    const [year, setYear] = useState();
    const [distribution, setDistribution] = useState();
    const [KPIModuleSelect, setKPIModuleSelect] = useState();

    useEffect(() => {
        ViewByList();
        setViewBySelect(localStorage.getItem(SELECT_VIEW_BY));
        ModelList();
        setModelSelect(localStorage.getItem(SELECT_MODEL));
        MetalTierList();
        setMetalTierSelect(localStorage.getItem(SELECT_METAL_TIER));
        PlanList();
        setPlanSelect(localStorage.getItem(SELECT_PLAN));
        MeInitialList();
        setMeInitialSelect(localStorage.getItem(SELECT_INITIAL));
        AcaYearList();
        setYear(localStorage.getItem(SELECT_YEAR));
        DistributionList();
        setDistribution(localStorage.getItem(SELECT_DISTRIBUTION));
        KPIList();
        setKPIModuleSelect(localStorage.getItem(SELECT_KPI));
    }, []);

    return (
        <>
            <div>
                <span className="d-flex bd-highlight">
                    <h6 className="pt-2 flex-grow-1 header-title-text">{props.title}</h6>
                    {(history.location.pathname == '/layout/medicare/gap_member_summary') && !props.ppg ?
                        <div className="d-flex align-items-center mr-2">
                            <label className="pr-2 pb-0 mb-0">Distribution of</label>
                            {/* <div className="d-flex justify-content-end">
                                <div className="btn-group">
                                    <select className="btn btn-info dropdown-toggle btn" value={DistributionSelect} onChange={handleDistributionSelect} >
                                        <option value="ALL">All Members</option>
                                        <option value="RISK">Risk Impacting Members</option>
                                    </select>
                                </div>
                            </div> */}
                            <DistributionSelect
                                DistributionList={getDistributionList()}
                                distributionSelect={distribution}
                                onDistributionSelect={((e) => { setDistribution(e); })}
                            ></DistributionSelect>
                        </div>
                        :
                        <></>
                    }
                    {(props.moduleSelect == 'ACA') ?
                        <>
                            {/* <div className="d-flex align-items-center mr-2">
                                <label className="pr-2 pb-0 mb-0">View By</label>
                                <ViewBySelect
                                    ViewByList={getViewByList()}
                                    viewBySelect={viewBySelect}
                                    onViewBySelect={((e) => { setViewBySelect(e); })}
                                ></ViewBySelect>
                            </div>
                            <div className="d-flex align-items-center mr-2">
                                <label className="pr-2 pb-0 mb-0">Model</label>
                                <ModelSelect
                                    ModelList={getModelList()}
                                    modelSelect={modelSelect}
                                    onModelSelect={((e) => { setModelSelect(e); })}
                                ></ModelSelect>
                            </div>
                            <div className="d-flex align-items-center mr-2">
                                <label className="pr-2 pb-0 mb-0">Metal Tier</label>
                                <MetalTierSelect
                                    MetalTierList={getMetalTierList()}
                                    metalTierSelect={metalTierSelect}
                                    onMetalTierSelect={((e) => { setMetalTierSelect(e); })}
                                ></MetalTierSelect>
                            </div>
                            <div className="d-flex align-items-center mr-2">
                                <label className="pr-2 pb-0 mb-0">Plan</label>
                                <PlanSelect
                                    PlanList={getPlanList()}
                                    planSelect={planSelect}
                                    onPlanSelect={((e) => { setPlanSelect(e); })}
                                ></PlanSelect>
                            </div> */}
                        </>
                        : (props.moduleSelect == 'Medicare' && props.selectType != 'utilization') ?
                            <>
                                <div className="d-flex align-items-center mr-2">
                                    <label className="pr-2 pb-0 mb-0">Payment Period</label>
                                    <MeInitialSelect
                                        MeInitialList={getMeInitialList()}
                                        meInitialSelect={meInitialSelect}
                                        onMeInitialSelect={((e) => { setMeInitialSelect(e); })}
                                    ></MeInitialSelect>
                                </div>
                            </>
                            :
                            <></>
                    }
                    {props.selectType != 'KPL' ?
                        <div className="d-flex align-items-center">
                            <label className="pr-2 pb-0 mb-0">Year</label>
                            <AcaYearSelect
                                AcaYears={getAcaYearList()}
                                year={year}
                                onAcaYearSelect={((e) => setYear(e))}>
                            </AcaYearSelect>
                        </div>
                        :
                        <></>
                    }
                    {/* {props.selectType == 'KPL' ?
                        <div className="d-flex align-items-center">
                            <label className="pr-2 pb-0 mb-0">KPI</label>
                            <KpiModuleSelect
                                KPIList={getKPIList()}
                                KPIModuleSelect={KPIModuleSelect}
                                onKPIModuleSelect={((e) => setKPIModuleSelect(e))}>
                            </KpiModuleSelect>
                        </div>
                        :
                        <></>
                    } */}

                </span>
            </div>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        userName: state.auth?.userDetails?.userEmail,
        route: state.route.route,
        moduleSelect: state.moduleFilter.moduleSelect,
    };
}
export default connect(mapStateToProps, { signOut, currentRoute })(SubHeader);
