import {
    SIGN_IN,
    SIGN_OUT,
    PERMISSION_ASSIGN,
    CURRENT_ROUTE,
    CURRENT_MODULE_SELECT,
    CURRENT_VIEWBY_SELECT,
    CURRENT_MODEL_SELECT,
    CURRENT_METALTIER_SELECT,
    CURRENT_PLAN_SELECT,
    CURRENT_YEAR_SELECT,
    CURRENT_MEINITIAL_SELECT,
    CURRENT_DISTRIBUTION_SELECT,
    CURRENT_KPI_SELECT,
    CURRENT_LOB_SELECT,
    CURRENT_PROVIDER_GROUP_SELECT,
    CURRENT_PGP_SELECT,
    CURRENT_PCP_SELECT,
    CURRENT_AWE_SITE_SELECT,
    CURRENT_AWE_PROVIDER_GROUPING_SELECT,
    CURRENT_AWE_CITY_SELECT,
    CURRENT_AWE_ADDRESS_SELECT,
    CURRENT_AWE_ZIP_SELECT,
    CURRENT_AWE_RIGHTSIDE_BAR_TYPE,
    CURRENT_AWE_RIGHTSIDE_BAR,
    CURRENT_AWE_RIGHTSIDE_BAR_CLOSE_DATATABLE_RELOAD,
    LOGOUT_MODEL_OPEN,
    LOGOUT_MODEL_CLOSE
} from './types';

export const signIn = (userDetails) => {
    return {
        type: SIGN_IN,
        payload: userDetails
    };
};

export const permssionAssign = (permissionDetails) => {
    return {
        type: PERMISSION_ASSIGN,
        payload: permissionDetails
    };
};

export const signOut = () => {
    return {
        type: SIGN_OUT
    };
};

export const currentRoute = (route) => {
    return {
        type: CURRENT_ROUTE,
        payload: route
    }
}

export const currentModuleSelect = (moduleSelect) => {
    return {
        type: CURRENT_MODULE_SELECT,
        payload: moduleSelect
    }
}

export const currentViewBySelect = (viewBySelect) => {
    return {
        type: CURRENT_VIEWBY_SELECT,
        payload: viewBySelect
    }
}

export const currentModelSelect = (modelSelect) => {
    return {
        type: CURRENT_MODEL_SELECT,
        payload: modelSelect
    }
}

export const currentMetalTierSelect = (metalTierSelect) => {
    return {
        type: CURRENT_METALTIER_SELECT,
        payload: metalTierSelect
    }
}

export const currentPlanSelect = (planSelect) => {
    return {
        type: CURRENT_PLAN_SELECT,
        payload: planSelect
    }
}

export const currentMeInitialSelect = (meInitialSelect) => {
    return {
        type: CURRENT_MEINITIAL_SELECT,
        payload: meInitialSelect
    }
}

export const currentYearSelect = (yearSelect) => {
    return {
        type: CURRENT_YEAR_SELECT,
        payload: yearSelect
    }
}

export const currentDistributionSelect = (distributionSelect) => {
    return {
        type: CURRENT_DISTRIBUTION_SELECT,
        payload: distributionSelect
    }
}

export const currentKPISelect = (distributionSelect) => {
    return {
        type: CURRENT_KPI_SELECT,
        payload: distributionSelect
    }
}

export const currentLOBSelect = (Select) => {
    return {
        type: CURRENT_LOB_SELECT,
        payload: Select
    }
}

export const currentProviderGroupSelect = (Select) => {
    return {
        type: CURRENT_PROVIDER_GROUP_SELECT,
        payload: Select
    }

}
export const currentPGPSelect = (PGPSelect) => {
    return {
        type: CURRENT_PGP_SELECT,
        payload: PGPSelect
    }
}

export const currentPCPSelect = (PCPSelect) => {
    return {
        type: CURRENT_PCP_SELECT,
        payload: PCPSelect
    }
}


export const currentAWESiteSelect = (selectValue) => {
    return {
        type: CURRENT_AWE_SITE_SELECT,
        payload: selectValue
    }
}

export const currentAWEProviderGroupingSelect = (selectValue) => {
    return {
        type: CURRENT_AWE_PROVIDER_GROUPING_SELECT,
        payload: selectValue
    }
}

export const currentAWECitySelect = (selectValue) => {
    return {
        type: CURRENT_AWE_CITY_SELECT,
        payload: selectValue
    }
}

export const currentAWEAddressSelect = (selectValue) => {
    return {
        type: CURRENT_AWE_ADDRESS_SELECT,
        payload: selectValue
    }
}

export const currentAWEZipSelect = (selectValue) => {
    return {
        type: CURRENT_AWE_ZIP_SELECT,
        payload: selectValue
    }
}

export const currentAWERightSidebarType = (rSidebarType) => {
    return {
        type: CURRENT_AWE_RIGHTSIDE_BAR_TYPE,
        payload: rSidebarType
    }
}

export const currentAWERightSidebar = (rSidebar) => {
    return {
        type: CURRENT_AWE_RIGHTSIDE_BAR,
        payload: rSidebar
    }
}

export const currentAWERightSidebarCloseDatatableReload = (sidebar) => {
    return {
        type: CURRENT_AWE_RIGHTSIDE_BAR_CLOSE_DATATABLE_RELOAD,
        payload: sidebar
    }
}

export const logoutModelOpen = (sidebar) => {
    return {
        type: LOGOUT_MODEL_OPEN
    }
}
export const logoutModelClose = (sidebar) => {
    return {
        type: LOGOUT_MODEL_CLOSE
    }
}
