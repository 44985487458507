import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import mainLogo from '../../assets/images/Invent_logo_White.png';

import CustomNavLink from '../CustomNavLink/CustomNavLink';
import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.js";
//import RTLNavbarLinks from "../Navbars/RTLNavbarLinks.js";
import { connect } from 'react-redux';
import styles from "../../assets/jss/layouts/sidebarStyle.js";
import ModuleSelect from '../../components/DropDowns/ETLModuleSelect';
import { getModuleList, getModuleSelect, ModuleList } from '../../assets/constants/ETLmoduleList';

const useStyles = makeStyles(styles);

function Sidebar(props) {
  const classes = useStyles();
  const [routeProp, setRouteProp] = React.useState(props.routes);
  const [activateParentClass, setActivateParentClass] = React.useState('');
  const [moduleSelect, setModuleSelect] = React.useState('');
 
  React.useEffect(() => { 
    ModuleList();
    setModuleSelect(getModuleSelect());    
  }, []);

  React.useEffect(() => {
    
  }, [props.moduleSelect, activateParentClass]);
  const handleClick = (open, key) => {

  };
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const getParentListClass = (route) => {
    if(routeProp.length > 0){
      routeProp && routeProp.forEach(element => {
        if(element.name == route){
          element.subRoutes && element.subRoutes.forEach(elem => {
              if (activeRoute(elem.layout+elem.path)) {
                if(activateParentClass != element.name && element.name == route){
                  setActivateParentClass(element.name);
                }
                return true;
              }
              // console.log(" elem.subRoute "+ elem.subRoute)
              // elem.subRoute.forEach(ele =>{
              //   if (activeRoute(ele.layout+ele.path)) {
              //     if(activateParentClass != elem.name && elem.name == route){
              //       setActivateParentClass(elem.name);
              //     }
              //     return true;
              //   }
              // })
          });
        }        
        if(activateParentClass == route){
          return true;
        }
      });
    }
    return false;
  }
  const { color, logo, image, logoText } = props;

  const links = (
    <List className={classes.list}>
      {
        routeProp.map((prop, key) => {
          return (
            <>
              {(props.moduleSelect == 'DataRepository' && prop.module == 'DataRepository')?
                  <List className={classes.item} key={key} data-route={getParentListClass(prop.name)} data-select={activateParentClass}>
                    <CustomNavLink
                      color={color}
                      logo={logo}
                      image={image}
                      logoText={logoText}
                      prop={prop} 
                      parentSelect={activateParentClass} 
                      itemKey={key}
                      moduleSelect={props.moduleSelect}
                      defaultSelect={'/etl/s3upload'}
                      >
                    </CustomNavLink>
                   
                </List>
              :
                <></>
              }
            </>
          );
        })
        
      },
      
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a className={classNames(classes.logoLink, {
        [classes.logoLinkRTL]: props.rtlActive
      })}>
        <div className={classes.logoImage}>
          <img src={mainLogo} width="100" height="100" alt="" className="logo"></img>
         </div>
        {logoText}
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {<AdminNavbarLinks />}               
            <ModuleSelect
                ModuleList={getModuleList()}
                moduleSelect={moduleSelect}
                onModuleSelect={((e) => { setModuleSelect(e); })}
            ></ModuleSelect>
            <hr />
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            <ModuleSelect
                ModuleList={getModuleList()}
                moduleSelect={moduleSelect}
                onModuleSelect={((e) => { setModuleSelect(e); })}
            ></ModuleSelect>
            <hr />
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : "no images"}
        </Drawer>
      </Hidden>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
      moduleSelect: state.moduleFilter.moduleSelect,
  };
}
export default connect(mapStateToProps)(Sidebar);
Sidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};
