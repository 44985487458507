import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import HeaderFilter from '../../../components/layout/aweLayout/HeaderFilter';
import { currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload, currentAWERightSidebarType } from '../../../store/actions';
import ReportingAnalytics from './awe_analytics_summary/ReportingAnalytics';
import AweAnalytics from './awe_analytics_summary/AweAnalytics';

const AweAnalyticsSummaryPage = (props) => {
  const [tabvalue, setTabvalue] = useState('1');

  const handleTabChange = (newValue) => {
    setTabvalue(newValue);
  };

  return (
    <>
      <HeaderFilter />
      <div className="card h-auto">
        <div className="card-body">
          <div className="tabs_wrapper mb20 overflow-auto custom-scrollbar">
            <ul className="nav nav-tabs flex-nowrap" id="myTab" role="tablist" style={{ minWidth: "460px" }}>
              <li className="nav-item" role="presentation">
                <button className="nav-link active" id="home-tab" data-toggle="tab" data-target="#Reporting-Analytics"
                  type="button" role="tab" aria-controls="Reporting-Analytics" aria-selected="true" onClick={() => handleTabChange(1)}
                >
                  Reporting Analytics
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link" id="profile-tab" data-toggle="tab" data-target="#AWE-Analytics"
                  type="button" role="tab" aria-controls="AWE-Analytics" aria-selected="false" onClick={() => handleTabChange(2)}
                >
                  AWE Analytics
                </button>
              </li>
            </ul>
          </div>
          <div className="tab-content" id="myTabContent">
            <div className={(tabvalue && tabvalue == '1') ? "tab-pane fade show active" : "tab-pane fade"} id="Reporting-Analytics" role="tabpanel" aria-labelledby="Reporting-Analytics-tab">
              {(tabvalue && tabvalue == '1') ?
                <ReportingAnalytics />
                :
                <></>
              }
            </div>
            <div className={(tabvalue && tabvalue == '2') ? "tab-pane fade show active" : "tab-pane fade"} id="AWE-Analytics" role="tabpanel" aria-labelledby="AWE-Analytics-tab">
              {(tabvalue && tabvalue == '2') ?
                <AweAnalytics />
                :
                <></>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  ) 
}
const mapStateToProps = (state) => {
  return {
    yearSelect: state.moduleFilter.yearSelect,
  };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(AweAnalyticsSummaryPage);