import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
 import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import {IC_CONSTANT} from '../../test-dummy';
import { highlightJSON } from '../../highlight-text';
import _ from 'lodash';
import { yellow } from '@material-ui/core/colors';
import { Highlight } from '@material-ui/icons';
import GridTable from "../../components/TableGrids/GridTable.table";
 
 const styles = (theme) => ({
     dialogPaper: {
         maxWidth: '100%',
     },
     root: {
         margin: 0,
         padding: theme.spacing(2),
     },
     closeButton: {
         position: 'absolute',
         right: theme.spacing(1),
         top: theme.spacing(1),
         color: theme.palette.grey[500],
     },
 });
 
 const DialogTitle = withStyles(styles)((props) => {
   const { children, classes, onClose ,...other } = props;
   return (
     <MuiDialogTitle disableTypography className={classes.root} {...other}>
       <Typography variant="h6">{children}</Typography>
       {onClose ? (
         <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
           <CloseIcon />
         </IconButton>
       ) : null}
     </MuiDialogTitle>
   );
 });
 
 const DialogContent = withStyles((theme) => ({
   root: {
     padding: theme.spacing(2),
   },
 }))(MuiDialogContent);
 
 const DialogActions = withStyles((theme) => ({
   root: {
     margin: 0,
     padding: theme.spacing(1),
   },
 }))(MuiDialogActions);
 
 const PhysicianNote = (props) => {
   let wholeText = '';
   const [open, setOpen] = React.useState(false);
   const [note, setNote] = React.useState(wholeText);
   const [ codeDescriptionData, setCodeDescriptionData] =  React.useState([]);
   let highlightArray = [];
   let CodeDescriptionArray = [];
   let CodeDescriptionColumn = [
     {
       label: 'Code',
       name: "Code",
       options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return <strong>{value}</strong>;
        }
      }
     },
     {
       label: 'Description',
       name: 'Description'
     },
     {
       label: 'Score',
       name: 'Score'
     }
   ]
   let array = IC_CONSTANT['Entities'];
   const setFilterHighlightText = () => {
     let key;
     if(props.modalBody.Text){
       key = Object.keys(props.modalBody.Text);
       if(key && props.modalBody.Text[key]){
        highlightArray.push(props.modalBody.Text[key]);
        array = array.filter((item) => {
         return item.Text === props.modalBody.Text[key];
       });
  
        _.forEach(array, function(value, key) {
          let text = '';
          if(array[key].ICD10CMConcepts && array[key].ICD10CMConcepts.length > 0 ){
            CodeDescriptionArray = array[key].ICD10CMConcepts;
            array[key].ICD10CMConcepts.forEach((data, index) => {
              CodeDescriptionArray[index].Score = Number(CodeDescriptionArray[index].Score).toFixed(4);
              highlightArray.push(data.Description);
            });
            setCodeDescriptionData(CodeDescriptionArray);
          }
        });
        // highlightArray.push('Positive for hypertension');
        highlightArray.forEach((text) => {
          highlight(text);
        });
     }
     }
  }

   useEffect(() => {
      setNote('');
      setCodeDescriptionData([]);
   },[props.modalBody.Text && Object.keys(props.modalBody.Text).length === 0])

   useEffect(() => {
       const arrayOfWholeText = highlightJSON['text'];
       if(arrayOfWholeText && arrayOfWholeText.length > 0 ){
         _.forEach(arrayOfWholeText, (value) => {
           wholeText = wholeText.concat(value);
           wholeText = wholeText.concat('. \n');
         });
       } 
       setNote(wholeText);
       setFilterHighlightText();
   }, [props.modalBody.Text && Object.keys(props.modalBody.Text).length > 0]);

   const highlight = (text) => {
    var inputText = document.getElementById("inputText");
    if (inputText) {
      var innerHTML = inputText.innerHTML;
      var index = innerHTML.indexOf(text);
      if (index >= 0) {
        innerHTML =
          innerHTML.substring(0, index) +
          "<span class='highlight'>" +
          innerHTML.substring(index, index + text.length) +
          "</span>" +
          innerHTML.substring(index + text.length);
        inputText.innerHTML = innerHTML;
      }
    }
  }
 
     const handleClickOpen = () => {
         setOpen(true);
     };
 
     const handleClose = () => {
         setOpen(false);
     };

     const renderData = () => {
       let hccDet = {};
       let propsedHcc = {};
       let maxhccDetAll = {};
       let maxhccDetails = {};
       if (
         props.modalBody !== undefined &&
         props.modalBody !== null &&
         props.modalBody.Id !== undefined
       ) {
         const key = Object.keys(props.modalBody.Id);
         if (key != '' && key !== undefined) {
           hccDet.Code = props.modalBody.Id[key];
           hccDet.Description = props.modalBody.Text[key];
           hccDet.Category = props.modalBody.Category[key];
           hccDet.Score = props.modalBody.Score[key];
           maxhccDetAll = props.modalBody.ICD10CMConcepts[key];
           if (maxhccDetAll !== undefined && maxhccDetAll !== null) {
             maxhccDetails = props.modalBody.ICD10CMConcepts[key][0];

             if (maxhccDetails !== undefined && maxhccDetails !== null) {
               for (let index = 0; index < maxhccDetAll.length; index++) {
                 let element = maxhccDetAll[index];
                 if (maxhccDetails.Score < element.Score) {
                   maxhccDetails = element;
                 }
               }

               propsedHcc.Code = maxhccDetails.Code;
               propsedHcc.Description = maxhccDetails.Description;
               propsedHcc.Score = maxhccDetails.Score;
             }
           }
         }
       }
       const physicalNoteCol = [
        {
          label:'HCC',
          name:'HCC'
        },
        {
          label:'Category',
          name: 'Category',
        },
        {
          label:'Score',
          name: 'Score',
        },
        {
          label:'Suggested HCC',
          name: 'Suggested HCC',
        },
        {
          label:'Suggested Score',
          name: 'Suggested Score',
        }
       ];
       hccDet.Score = (hccDet.Score) ? Number(hccDet.Score).toFixed(4) : 0;
       propsedHcc.Score = (propsedHcc.Score) ? Number(propsedHcc.Score).toFixed(4) : 0;
       const physicalNoteData = [
        { 
          "HCC": (hccDet && hccDet.Code && hccDet.Description) ? `${hccDet.Code} : ${hccDet.Description}` : '', 
          "Category": (hccDet && hccDet.Category ) ? `${hccDet.Category}` : ``, 
          "Score": (hccDet && hccDet.Score) ? `${hccDet.Score}` : ``,  
          "Suggested HCC": (propsedHcc && propsedHcc.Code && propsedHcc.Description) ? `${propsedHcc.Code} : ${propsedHcc.Description}` : '',
          "Suggested Score" : (propsedHcc && propsedHcc.Score) ? `${propsedHcc.Score}` : ``, 
       }
       ];

       return (
         <div>
           <GridTable
              data={physicalNoteData}
              columns={physicalNoteCol}
           >
           </GridTable>
           <br/>
           <h5 style={{marginLeft: '20px'}}>{hccDet.Description}</h5>
           <hr/>
           <h6 style={{marginLeft: '20px'}}>Top Inferred concepts</h6>
           <GridTable
              data={codeDescriptionData}
              columns={CodeDescriptionColumn}
           >
           </GridTable>
         </div>
       );
     };
   
   const renderNote = () => {
    return _.map(note, (value, index) => {
      const splittedText = value.split(':');
      return(
        <>
        <span key={index} id="inputText" >
          <strong>{splittedText[0]}</strong> 
          : {splittedText[1]}
          <br></br>
        </span>
        </>
      );
      // wholeText = wholeText.concat(`<strong>${splittedText[0]}</strong>`);
      // wholeText = wholeText.concat(` : ${splittedText[1]}`);
      // wholeText = wholeText.concat('. \n');
    });
   }
 
   const notesStyle = {
      border: '2px solid darkgrey',
      padding: '5px',
      marginTop: '5px',
      marginBottom: '5px',
      marginLeft: '20px',
      marginRight: '20px',
      wordSpacing: 'normal',
      fontSize:' 0.9rem',
      textAlign: 'left',
      fontFamily: '"Source Sans Pro", "Helvetica", "Arial", sans-serif',
      wordBreak: 'break-word',
      whiteSpace: 'pre-line'
   }
   return (
     <div>
       <Button color="primary" onClick={handleClickOpen}>Review</Button>
       <Dialog onClose={handleClose} 
       aria-labelledby="report-generation" 
       open={open} 
       classes={{ paper: props.classes.dialogPaper }} >
         {/* <DialogTitle id="report-generation" onClose={handleClose}>
            
         </DialogTitle> */}
         <DialogContent dividers>
          <Grid container spacing={12}>
            <Grid item xs={6}>
             {renderData()}
            </Grid>
            <Grid item xs={6}>
              <h5 style={{marginLeft: '20px'}}>Physician Note</h5>
              { props.modalBody.Text && Object.keys(props.modalBody.Text).length > 0 ? 
              <p id="inputText" style={notesStyle}>{note}</p> : ''
              }
            </Grid>
          </Grid>
         </DialogContent>
         <DialogActions>
           <Button autoFocus onClick={handleClose} color="primary">
             Close
           </Button>
         </DialogActions>
        </Dialog>
     </div>
   );
 }
 
 export default withStyles(styles)(PhysicianNote);
