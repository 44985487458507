import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import SingleHorizontalBar from "../../components/Charts/SingleHorizontalBar.chart";
import { getAcaIssuer, getAcaIssuerList } from "../../assets/constants/issuerList";
import AcaIssuersSelect from "../../components/DropDowns/AcaIssuersSelect";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag } from "../../assets/constants/customRender";
import history from '../../history';
import { HCC, ISSUER_ID } from "../../assets/constants/string.constants";
import CardComponent from "../../components/Cards/card";
import GChart from '../../components/GChart/GChart';
import BarVerticalHCC from '../../components/GChart/BarVerticalHCC';
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';

const ACAChartReviewHccSummary = (props) => {

    const [HccAddedChartData, setHccAddedChartData] = useState({});
    const [HccDeletedChartData, setHccDeletedChartData] = useState({});
    const [year, setYear] = useState(props.yearSelect);
    const [issuer, setIssuer] = useState(getAcaIssuer());

    const loadAddedHccChartIssuer = () => {
        let hccCode = [];
        let totalCount = [];
        let chartDataRow = [];

        api.get('/ACAcrHccSummaryAdded/year/' + props.yearSelect + '/issuer_id/' + btoa(issuer))
            .then(res => {
                chartDataRow.push(["Code", "Count", { role: 'annotation' }]);
                for (const dataObj of res) {
                    hccCode.push((dataObj.description + ':' + dataObj.hccCode));
                    totalCount.push(dataObj.memberCount);
                    chartDataRow.push([dataObj.description + ':' + dataObj.hccCode, dataObj.memberCount, dataObj.memberCount])
                }
                // console.log("code-99", chartDataRow);
                setHccAddedChartData(chartDataRow);
                //     setHccAddedChartData({
                //     labels: hccCode,
                //     data:  totalCount,
                //     dLabel: "Count"
                // });
            })
    };

    const handleDeletechartClick = (element) => {
        let chartData = element[0]['_chart'].config.data;
        let idx = element[0]['_index'];
        let hcc = chartData.labels[idx];
        let parts = hcc.split(':');
        let hcc1 = parts[1];
        localStorage.setItem("hcc", hcc1);
        localStorage.setItem("issuer_id", issuer);
        history.push('/layout/acaCRhccDelete');
    };

    const loadDeletedHccChartIssuer = () => {
        let hccCode = [];
        let totalCount = [];
        let chartDataRow = [];
        chartDataRow.push(["Score", "Count", { role: 'annotation' }]);
        api.get('/ACAcrHccDeleted/year/' + props.yearSelect + '/issuer_id/' + btoa(issuer))
            .then(res => {
                for (const dataObj of res) {
                    hccCode.push((dataObj.description + ':' + dataObj.hccCode));
                    totalCount.push(dataObj.memberCount);
                    chartDataRow.push([dataObj.description + ':' + dataObj.hccCode, dataObj.memberCount, dataObj.memberCount])
                }
                setHccDeletedChartData(chartDataRow);
                // setHccDeletedChartData({
                //     labels: hccCode,
                //     data: totalCount,
                //     dLabel: "Count"
                // });
            })
    };

    const handleAddchartClick = (element) => {
        let chartData = element[0]['_chart'].config.data;
        let idx = element[0]['_index'];
        let hcc = chartData.labels[idx];
        let parts = hcc.split(':');
        let hcc1 = parts[1];
        localStorage.setItem("HCC", hcc1);
        localStorage.setItem(ISSUER_ID, issuer);
        history.push('/layout/acaCRhccAdd');
    };

    const hccDelColumns = [
        {
            label: "Deleted HCC Code",
            name: "code",
            options: anchorTag
        },
        {
            label: "Description",
            name: "description"
        },
        {
            label: "# Members having this condition Deleted",
            name: "memberCount"
        },
        {
            label: "# Provider Contributed to this condition Deleted",
            name: "providerCount"
        }
    ]

    const hccAddColumns = [
        {
            label: "Added HCC Code",
            name: "code",
            options: anchorTag
        },
        {
            label: "Description",
            name: "description",

        },
        {
            label: "# Members having this condition added",
            name: "memberCount"
        },
        {
            label: "# Provider Contributed to this condition added",
            name: "providerCount"
        }
    ];

    const memberTableClick = (rowdata, colData, cellMeta) => {

        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(ISSUER_ID, issuer);
            localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].code);
            history.push('/layout/acaCRhccAdd')
        }
    }

    const memberDeleteClick = (rowdata, colData, cellMeta) => {

        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(ISSUER_ID, issuer);
            localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].code);
            history.push('/layout/acaCRhccDelete')
        }
    }

    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            loadAddedHccChartIssuer();
            loadDeletedHccChartIssuer();
        }
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect,
        props.meInitialSelect,
        issuer
    ]);

    return (
        <div className="container level-summary">
            <div className="row mb-3">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'HCC SUMMARY'} />
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <CardComponent
                        heading={`Top HCC's added in this Chart review`}
                        body={
                            <BarVerticalHCC data={HccAddedChartData} chartType="BarChart" height="320px" width="100%" setStorgeItemName={HCC} redirectUrl={'acaCRhccAdd'}></BarVerticalHCC>
                        }></CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="hcc">
                        {props.yearSelect ?
                            <CardComponent
                                heading={`HCC's Added`}
                                body={
                                    <ServerSideGridTable
                                        url={`/ACAcrhccsadd/year/${props.yearSelect}/issuer_id/${btoa(issuer)}`}
                                        columns={hccAddColumns}
                                        tableClick={memberTableClick}
                                        defaultSortCol={2}
                                        defaultSortDir={'desc'}
                                    ></ServerSideGridTable>
                                }></CardComponent>
                            :
                            <></>
                        }
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <CardComponent
                        heading={`Top HCC's deleted in this Chart review`}
                        body={
                            <BarVerticalHCC data={HccDeletedChartData} chartType="BarChart" height="320px" width="100%" setStorgeItemName={HCC} redirectUrl={'acaCRhccDelete'}></BarVerticalHCC>
                        }
                    ></CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="hcc pb-5">
                        <div className="pb-5">
                            <CardComponent
                                heading={`HCC's Deleted`}
                                body={
                                    <ServerSideGridTable
                                        url={`/ACAcrHccDel/year/${props.yearSelect}/issuer_id/${btoa(issuer)}`}
                                        columns={hccDelColumns}
                                        tableClick={memberDeleteClick}
                                        defaultSortCol={2}
                                        defaultSortDir={'desc'}
                                    ></ServerSideGridTable>
                                }
                            ></CardComponent>
                        </div>
                    </div>
                </div>
                <br />
                <br />
            </div >
        </div >
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default connect(mapStateToProps)(ACAChartReviewHccSummary);
