import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import CardComponent from "../../components/Cards/card";
import GridTable from "../../components/TableGrids/GridTable.table";
import { getAcaYear, getAcaYearList } from "../../assets/constants/yearList";
import { anchorTag, addSuffixPercent } from "../../assets/constants/customRender";
import { MEMBER_ID } from "../../assets/constants/string.constants";
import history from "../../history";
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';

const ReconSummary = (props) => {


    const [year, setYear] = useState(props.yearSelect);
    const [tableData, setTableData] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            loadMetricTable();
            loadSummaryTable();
        }
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect,
        props.meInitialSelect
    ]);
    const memberColumns = [
        {
            label: "Member ID",
            name: "MEMBER_ID",
            options: anchorTag
        },
        {
            label: "Member Name",
            name: "NAME",
        },
        {
            name: "PROVIDER",
            label: "Provider Name"
        },
        {
            name: "GAP_RISK_SCORE",
            label: "Gap Risk Score"
        },
        {
            name: "MEMBER_PART_OF_CHART_CHASE",
            label: "Member Part Of Chart Chase"

        },
        {
            name: "INITIAL_RECOMMENDATION",
            label: "Initial Recommendation",

        },
        {
            name: "HCC_GAPS_LIST",
            label: "HCC Gap"
        },
        {
            name: "GAP_HCC_CLOSURE",
            label: "HCC Gaps Closure"

        },
        {
            name: "PROSPECTIVE_HCC_CLOSURE",
            label: "Prospective HCC Closure"

        },
        {
            name: "GAP_RISK_SCORE_CLOSED",
            label: "Gap Risk Score Closed"
        },
        {
            name: "PERC_GAP_RISK_SCORE_CLOSED",
            label: "% Gap Risk Score Closed",
            options: addSuffixPercent
        }
    ];




    //{"CATEGORY":"HIGH","TOTAL_MEMBER_HCC":233,"TOTAL_RISK_SCORE":212.7998,"TOTAL_MEMBER_HCC_CLOSED":16,"TOTAL_RISK_SCORE_GAP":12.5247,"TOTAL_RISK_SCORE_GAP_PERC":5.89,"YEAR":2020},
    const summaryColumns = [
        {
            name: "CATEGORY",
            label: "Category"
        },
        {
            name: "TOTAL_MEMBER_HCC",
            label: "# Member HCC"
        },
        {
            name: "TOTAL_MEMBER_HCC_CLOSED",
            label: "# Member HCC Closed"
        },

        {
            name: "TOTAL_RISK_SCORE_GAP",
            label: "# Risk Score Gap"
        },
        {
            name: "TOTAL_RISK_SCORE_GAP_PERC",
            label: " %  Gap Risk Score",
            options: addSuffixPercent
        },
    ];


    const Tableclick = (rowdata, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (rowdata.props.children != "")) {

            localStorage.setItem(MEMBER_ID, rowdata.props.children);
            history.push('/layout/acaReconHCCSummary');
        }
    }

    const loadMetricTable = () => {
        // console.log(props.yearSelect);
        api.get('/ReconsiliationMemberSummary/' + year)
            .then(res => {
                // console.log(res);
                setTableData(res);
            })
            .catch(err => {
                // console.log(err);
            })
    };

    const loadSummaryTable = () => {
        api.get('/getAcaReconCategoryLvlSummary/' + year)
            .then(res => {
                // console.log(res);
                setSummaryData(res);
            })
            .catch(err => {
                // console.log(err);
            })
    };
    return (
        <div className="container level-summary">
            <div className="row mb-3">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Member Reconciliation'} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <CardComponent
                        heading={"SUMMARY"}
                        body={
                            <GridTable
                                data={summaryData}
                                columns={summaryColumns}
                            // pagination={true}
                            //onTableClick={Tableclick}
                            ></GridTable>
                        }></CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <CardComponent
                        heading={"MEMBERS WITH GAPS CLOSED"}
                        body={
                            <GridTable
                                data={tableData}
                                columns={memberColumns}
                                pagination={true}
                                onTableClick={Tableclick}
                            ></GridTable>
                        }></CardComponent>
                </div>
                <br />
                <br />
            </div>
        </div>
    );

}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default connect(mapStateToProps)(ReconSummary);
