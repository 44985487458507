import {
  drawerWidth,
  transition,
  container
} from "../awv-material-dashboard-react.js"

const appStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
    background: "#f7f7f7",
  },
  content: {
    marginTop: "0px",
    padding: "0px 15px 15px 15px",
    height: "100vh"
  },
  container,
  map: {
    marginTop: "70px"
  }
});

export default appStyle;
