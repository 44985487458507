import { useState, useEffect } from "react";
import { addSuffixPercentm, addSuffixTwoPercent, anchorTag, hccSeperator } from "../../assets/constants/customRender";
import api from "../../assets/constants/Rafservice.Instance";
import { ISSUER_ID, METAL_TIER, RISK_MODEL, RAF_RANGE, WIDGET_TYPES, GAP_OR_REPORTED, MEMBER_ID, REPORTED_INSIGHTS, HCC, MEMBER_NAME, CARRIER_ID, PLAN_ID, GENDER } from "../../assets/constants/string.constants";
import { getAcaYear } from "../../assets/constants/yearList";
import CardComponent from "../../components/Cards/card";
import WidgetCard from "../../components/Cards/WidgetCard";
import SingleVerticalBarChart from "../../components/Charts/SingleVerticalBar.Chart";
import GridTable from "../../components/TableGrids/GridTable.table";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import history from "../../history";

import LockOpenIcon from '@material-ui/icons/LockOpen';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import GChart from '../../components/GChart/GChart';
import LineChart from '../../components/GChart/LineChart';
import SubHeader from '../../components/home/header/subHeader';
import { connect } from 'react-redux';

const IssuersACA = (props) => {

    const { year } = props;
    // const [issuer_id] = useState(localStorage.getItem(ISSUER_ID));
    // const issuer_id = props.IssuerId;
    const [WidgetDetails, setWidgetDetails] = useState({});
    const [RafChartData, setRafChartData] = useState({});
    const [MetalChartData, setMetalChartData] = useState({});
    const [ModelChartData, setModelChartData] = useState({});
    const [MetalTierData, setMetalTierData] = useState([]);
    const [RiskModelData, setRiskModelData] = useState([]);
    const [issuer_id, setIssuerId] = useState(localStorage.getItem(ISSUER_ID));
    const [ChartData, setChartData] = useState([]);
    const [cardToggle, setcardToggle] = useState(true);

    // chartData.push(["Risk Score", "Member Count"]);

    let dataIndex = 0;
    if (props.IssuerId !== "") {
        if (issuer_id !== props.IssuerId) {
            setIssuerId(props.IssuerId);
        }
    }

    const loadWidgetDetails = () => {   
        api.get('/acaReportesummary/year/' + props.yearSelect + '/issuerId/' + issuer_id)
            .then(res => {
                setWidgetDetails({
                    'Members': res[0].no_of_members,
                    'Member Plan Combinations': res[0].no_of_plan_member_combo,
                    'Adults': res[0].no_of_adults,
                    'Children': res[0].no_of_children,
                    'Infants': res[0].no_of_infants,
                    'Average Risk Score': res[0].average_risk_score
                });
            })
            .catch(err => {
                // console.log(err);
            })
       

    };

    const WidgetDetailsType = [
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.PERSON,
        WIDGET_TYPES.ADULT,
        WIDGET_TYPES.CHILD,
        WIDGET_TYPES.INFANT,
        WIDGET_TYPES.SCORE
    ]

    const loadRafRangeGraph = () => {
        let rafRange = [];
        let countOfMembers = [];
        let chartDataRow = []; 
        chartDataRow.push(["Risk Score", "Member Count"]);
        api.get('/acarafRangeGraph/year/' + getAcaYear() + '/issuer_id/' + btoa(issuer_id))
            .then(res => {
                for (const dataObj of res) {
                    rafRange.push(dataObj.rafRange);
                    countOfMembers.push(dataObj.countOfMembers);
                    chartDataRow.push([dataObj.rafRange, dataObj.countOfMembers]);
                }
                setChartData(chartDataRow);
                setRafChartData({
                    labels: rafRange,
                    data: countOfMembers,
                    dLabel: "Count",
                })
            })
            .catch(err => {
                // console.log(err);
            })


    }

    const loadGapMetalGraph = () => {
        let metal_tier = [];
        let average_risk_score = [];
        let chartDataRow = [];
        let MetalTierDataTemp = [];
        api.get('/acaYoYMetalTier/year/' + props.yearSelect + '/issuerId/' + btoa(issuer_id))
            .then(res => {
                MetalTierDataTemp = res;
                setMetalTierData(res);
                // setModelChartData(chartDataRow);
            })
            .catch(err => {
                // console.log(err);
            })
        chartDataRow.push(["Metal Tier", "Risk Score", "Year-On-Year Change"]);
        api.get('/acaMetalTierGraph/year/' + props.yearSelect + '/issuerId/' + btoa(issuer_id))
            .then(res => {
                var i = 0;

                for (const dataObj of res) {
                    // metal_tier.push(dataObj.metal_tier);
                    // average_risk_score.push(dataObj.average_risk_score);
                    chartDataRow.push([dataObj.metal_tier, dataObj.average_risk_score, MetalTierDataTemp[i].year_on_year_change]);
                    i++;
                }
                setMetalChartData(chartDataRow);
            })
            .catch(err => {
                // console.log(err);
            })


    }

    const loadGapModelGraph = () => {
        let risk_model = [];
        let average_risk_score = [];
        let chartDataRow = [];
        let RiskModelDataTemp = [];
        api.get('/acaYoYRiskModel/year/' + props.yearSelect + '/issuerId/' + btoa(issuer_id))
            .then(res => {
                RiskModelDataTemp = res;
                setRiskModelData(res);
            })
            .catch(err => {
                // console.log(err)
            })
        chartDataRow.push(["Risk Model", "Risk Score", "Year-On-Year Change"]);
        api.get('/acaRiskModelGraph/year/' + props.yearSelect + '/issuerId/' + btoa(issuer_id))
            .then(res => {
                var i = 0;
                for (const dataObj of res) {
                    // risk_model.push(dataObj.risk_model);
                    // average_risk_score.push(dataObj.average_risk_score);
                    chartDataRow.push([dataObj.risk_model, dataObj.average_risk_score, RiskModelDataTemp[i].year_on_year_change]);
                    i++;
                }
                setModelChartData(chartDataRow);
            })
            .catch(err => {
                // console.log(err);
            })
        

    }

    const loadMetalTierScore = () => {

        api.get('/acaYoYMetalTier/year/' + year + '/issuerId/' + btoa(issuer_id))
            .then(res => {  
                setMetalTierData(res);
                // setModelChartData(chartDataRow);
            })
            .catch(err => {
                // console.log(err);
            })
    }

    const loadRiskModelScore = () => {

        api.get('/acaYoYRiskModel/year/' + year + '/issuerId/' + btoa(issuer_id))
            .then(res => {
                setRiskModelData(res);
            })
            .catch(err => {
                // console.log(err)
            })
    }

    const metalTierColumns = [
        {
            name: "metal_tier",
            label: "Metal Tier"
        },
        {
            name: "year_on_year_change",
            label: "Year-On-Year Change",
            options: addSuffixTwoPercent
        }
    ]

    const riskModelColumns = [
        {
            name: "risk_model",
            label: "Risk Model"
        },
        {
            name: "year_on_year_change",
            label: "Year-On-Year Change",
            options: addSuffixTwoPercent
        }
    ]

    const memberAcaCols = [
        {
            label: "Member ID",
            name: "MEMBER_ID",
            options: anchorTag
        },
        {
            name: "MEMBER_Name",
            label: "Member Name",
        },
        {
            name: "Carrier_ID",
            label: "Carrier ID",
        },
        {
            name: "HCC_POST_HIERARCHY",
            label: "Reported HCC",
            options: hccSeperator
        },
        {
            name: "Risk_Score",
            label: "Risk Score",
        },
        {
            name: "Metal_Tier",
            label: "Metal Tier",
        },
        {
            name: "Risk_Model",
            label: "Risk Model",
        },
        {
            name: "Plan_ID",
            label: "Plan ID",
        }
    ];

    const memberMedicareCols = [
        {
            label: "Member ID",
            name: "MEMBER_ID",
            options: anchorTag
        },
        {
            name: "DEMOGRAPHIC_SCORE",
            label: "Demographic Score",
        },
        {
            name: "OREC_SCORE",
            label: "OREC Score",
        },
        {
            name: "HCC_SCORE",
            label: "HCC Score",
        },
        {
            name: "HCC_COUNT_SCORE",
            label: "Hcc Count Score",
        },
        {
            name: "INTERACTION_SCORE",
            label: "Interaction Score",
        },
        {
            name: "RAF_SCORE",
            label: "RAF Score",
        },
        {
            name: "REVENUE",
            label: "Revenue $",
        }
    ];

    const type = WidgetDetailsType;
    const widgetStyles = {
        position: 'absolute',
        left: 0,
        //right: 20,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.15,
        color: "white"
    };

    const backGround = [
        { backgroundColor: "#AAB7B8" },
        { backgroundColor: "#EB984E" },
        { backgroundColor: "#58D68D" },
        { backgroundColor: "#5DADE2" },
        { backgroundColor: "#AF7AC5" },
        { backgroundColor: "#EC7063" }
    ]


    const getIcon = (type) => {
        var data = ''
        switch (type) {
            case WIDGET_TYPES.ISSUER:
                data = <AccountBalanceTwoToneIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.MEMBERS:
                data = <PeopleAltIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERCENT:
                data = <ShowChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.SCORE:
                data = <BarChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERSON:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.CHILD:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.ADULT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.INFANT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.REVENUE:
                data = <AttachMoneyIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.COUNT:
                data = <ShoppingCartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.LOCKICON:
                data = <LockOpenIcon style={widgetStyles} />
                break;
        }
        return data;
    }


    const memberTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(MEMBER_ID, rowdata[cellMeta.rowIndex].MEMBER_ID);
            localStorage.setItem(MEMBER_NAME, rowdata[cellMeta.rowIndex].MEMBER_Name);
            localStorage.setItem(CARRIER_ID, rowdata[cellMeta.rowIndex].Carrier_ID);
            localStorage.setItem(PLAN_ID, rowdata[cellMeta.rowIndex].Plan_ID);
            localStorage.setItem(GENDER, rowdata[cellMeta.rowIndex].Gender);
            history.push('/layout/acaMember', REPORTED_INSIGHTS)
        }
        else if ((cellMeta.colIndex === 3) && (colData.props.children != "")) {
            if (typeof (colData.props.children) == "object") {
                localStorage.setItem(HCC, (cellMeta.event.target.innerText).replace(',', ''));
            }
            else {
                localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].HCC_POST_HIERARCHY);
            }
            localStorage.setItem(ISSUER_ID, rowdata[cellMeta.rowIndex].ISSUER_ID);
            history.push('/layout/acaReportedHcc')
        }
    };

    const RafChartClick = (element) => {
        let activePoints = element;
        let chartData = activePoints[0]['_chart'].config.data;
        let idx = activePoints[0]['_index'];
        let raf = chartData.labels[idx];
        localStorage.setItem(RAF_RANGE, raf);
        localStorage.setItem(ISSUER_ID, issuer_id)
        history.push('/layout/acaRafDrilldown');
    }

    const MetalChartClick = (element) => {
        let activePoints = element;
        let chartData = activePoints[0]['_chart'].config.data;
        let idx = activePoints[0]['_index'];
        let metal_tier = chartData.labels[idx];
        localStorage.setItem(METAL_TIER, metal_tier);
        localStorage.setItem(GAP_OR_REPORTED, 'reported_model')
        history.push('/layout/acaMemberDetails', METAL_TIER)
    }

    const ModelChartClick = (element) => {
        let activePoints = element;
        let chartData = activePoints[0]['_chart'].config.data;
        let idx = activePoints[0]['_index'];
        let risk_model = chartData.labels[idx];
        localStorage.setItem(RISK_MODEL, risk_model);
        localStorage.setItem(GAP_OR_REPORTED, 'reported_model')
        history.push('/layout/acaMemberDetails', RISK_MODEL)
    }

    useEffect(() => {
        // loadWidgetDetails();
        // loadMetalTierScore();
        // loadRiskModelScore();
        loadRafRangeGraph();
        loadGapMetalGraph();
        loadGapModelGraph();
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect,
        props
    ]);

    let sliderRange = Object.entries(WidgetDetails).length / 3;
    sliderRange = parseInt(sliderRange);
    return (
        <div>
           
            {/* <CardComponent
                heading={`RISK SCORE DISTRIBUTION OF MEMBERS WITH CONDITION`}
                body={
                    <GChart data={ChartData} chartType="Bar" height="320px" width="100%"></GChart>
                 
                }></CardComponent> */}
           
            <div className='row'>
                <div className='col-sm-6'>
                    <CardComponent
                        heading={`REPORTED SCORE BY METAL LEVEL`}
                        body={
                            <div className="box-height-chart">
                                <LineChart data={MetalChartData} className="bar_chart" chartType="LineChart" height="320px" width="100%"></LineChart>
                            </div>
                        }></CardComponent>
                </div>
                <div className='col-sm-6'>
                    <CardComponent
                        heading={`REPORTED SCORE BY MODEL`}
                        body={
                            <div className="box-height-chart">
                                <LineChart data={ModelChartData} className="bar_chart" chartType="LineChart" height="320px" width="100%"></LineChart>
                            </div>
                        }></CardComponent>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-sm-6 ">
                    <CardComponent
                        heading={`YEAR ON YEAR CHANGE IN RISK SCORE BY METAL LEVEL`}
                        body={

                            <div className="box-height-chart" style={{ height: "375px" }}>
                                <GridTable
                                    data={MetalTierData}
                                    columns={metalTierColumns}
                                ></GridTable>
                            </div>
                        }></CardComponent>
                </div>
                <div className="col-sm-6 ">
                    <CardComponent
                        heading={`YEAR ON YEAR CHANGE IN RISK SCORE BY RISK MODEL`}
                        body={

                            <div className="box-height-chart" style={{ height: "375px" }}>
                                <GridTable
                                    data={RiskModelData}
                                    columns={riskModelColumns}
                                ></GridTable>
                            </div>
                        }></CardComponent>
                </div>
            </div>

            <div className="list-member mt-4">
                {props.yearSelect ?
                    <CardComponent
                        heading={`LIST OF MEMBER(S)`}
                        body={
                            <ServerSideGridTable
                                url={`/ACAIssuerMembers/year/${props.yearSelect}/issuer_id/${btoa(issuer_id)}`}
                                columns={memberAcaCols}
                                tableClick={memberTableClick}
                            ></ServerSideGridTable>
                        }></CardComponent>
                    :
                    <></>
                }

            </div>
            <br />
            <br />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
    };
}
export default connect(mapStateToProps)(IssuersACA);