import React from 'react'

export default function InfoLineIcon({ ...props }) {
    return (
        <svg {...props} viewBox="0 0 16 16" fill="none">
            <path d="M3.5413 7.17433C5.48688 3.72477 6.45966 2 8.00016 2C9.54063 2 10.5134 3.72477 12.459 7.17433L12.7014 7.6042C14.3182 10.4707 15.1266 11.904 14.396 12.952C13.6654 14 11.8578 14 8.24263 14H7.7577C4.14254 14 2.33494 14 1.60434 12.952C0.873723 11.904 1.6821 10.4707 3.29886 7.6042L3.5413 7.17433Z" stroke="currentcolor" stroke-width="1.5" />
            <path d="M8 5.33337V8.66671" stroke="currentcolor" stroke-width="1.5" stroke-linecap="round" />
            <path d="M8.00016 11.3333C8.36835 11.3333 8.66683 11.0349 8.66683 10.6667C8.66683 10.2985 8.36835 10 8.00016 10C7.63197 10 7.3335 10.2985 7.3335 10.6667C7.3335 11.0349 7.63197 11.3333 8.00016 11.3333Z" fill="currentcolor" />
        </svg>
    )
}