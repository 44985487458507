import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { Chart } from "react-google-charts";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


const ColumnChartStackAWV = (props) => {
    const chart = useRef(null);
    const [type, setType] = useState('');
    const [data, setData] = useState([]);

    useLayoutEffect(() => {
        if (props.data && props.data.length > 0) {
            var mainCustomArray = [];
            var index = 0;
            var customArray = [];
            customArray[0] = "Month";
            customArray[1] = "Office Visit";
            customArray[2] = "TeleHealth";
            customArray[3] = "In Home Assessment";
            mainCustomArray[index] = customArray;

            for (const key in props.data) {
                var customArray = [];
                if(props.data[key]['Month'] != '0'){                    
                    index++;
                    customArray[0] = props.data[key]['Month'];
                    customArray[1] = props.data[key]['Office Visit'];
                    customArray[2] = props.data[key]['TeleHealth'];
                    customArray[3] = props.data[key]['In Home Assessment'];
                    mainCustomArray[index] = customArray;
                }
            };
            setData(mainCustomArray);
        } else {
            // setData([["Month", props.yearSelect, { role: "style" }],["January", 0, "#1A9698"]]);
            setData([
                ["Month", "Office Visit", "TeleHealth","In Home Assessment"],
                ["JAN", 0, 0, 0],
            ]);
        }

        // Apply chart themes
        // am4core.useTheme(am4themes_animated);

        // // Create chart instance
        // var chart = am4core.create("chartdivStack", am4charts.XYChart);

        // if (props.data && props.data.length > 0) {
        //     chart.data = props.data;
        // } else {
        //     chart.data = [
        //         {
        //             "Month": "Jan",
        //             "Office Visit": 0,
        //             "TeleHealth": 0,
        //             "Total_Visit": 0
        //         }
        //     ];
        // }


        // // Create axes
        // var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        // categoryAxis.dataFields.category = "Month";
        // categoryAxis.title.text = "Month";
        // categoryAxis.renderer.grid.template.location = 0;
        // categoryAxis.renderer.minGridDistance = 20;

        // var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        // valueAxis.title.text = "Total AWE Visit";

        // // Create series
        // var series = chart.series.push(new am4charts.ColumnSeries());
        // series.dataFields.valueY = "Office Visit";
        // series.dataFields.categoryX = "Month";
        // series.name = "2022 Offic Visit";
        // series.tooltipText = "{name}: [bold]{valueY}[/]";
        // series.columns.template.fill = am4core.color("#fcb441"); // fill
        // // This has no effect
        // // series.stacked = true;

        // var series2 = chart.series.push(new am4charts.ColumnSeries());
        // series2.dataFields.valueY = "TeleHealth";
        // series2.dataFields.categoryX = "Month";
        // series2.name = "2022 Telehealth";
        // series2.tooltipText = "{name}: [bold]{valueY}[/]";
        // series2.columns.template.fill = am4core.color("#1A9698"); // fill
        // // Do not try to stack on top of previous series
        // series2.stacked = true;

        // var series3 = chart.series.push(new am4charts.ColumnSeries());
        // series3.dataFields.valueY = "Total_Visit";
        // series3.dataFields.categoryX = "Month";
        // series3.name = "2021 Visits";
        // series3.tooltipText = "{name}: [bold]{valueY}[/]";
        // series3.columns.template.fill = am4core.color("#e3e3e3"); // fill
        // // series3.stacked = true;

        // // Add cursor
        // chart.cursor = new am4charts.XYCursor();

        // // Add legend
        // chart.legend = new am4charts.Legend();
    }, [props.data]);

    const options = {
        // title: "CUMULATIVE (SUM OF ALL MONTHS WITH YEAR)",
        isStacked: true,
        hAxis: { title: "Month" },
        legend: { position: "bottom", maxLines: 3 },
        vAxis: { title: "AWE VISITS", minValue: 0 },
        chartArea: { width: "78%", height: "70%" },
        colors: ['#fcb441','#1A9698','#e3e3e3']
    };

    return (
        <Chart
            chartType="ColumnChart"
            width="100%"
            height="400px"
            data={data}
            options={options}
        />
        // <div id="chartdivStack" style={{ width: "100%", height: "400px", cursor: 'pointer' }}></div>
    );
}
export default ColumnChartStackAWV;