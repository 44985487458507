import React, { useRef, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar } from '../../../store/actions';
import AWVApi from '../../../assets/constants/AWVRafservice.Instance';
import Divider from '@mui/material/Divider';
import Moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import { CircularProgress } from '@material-ui/core';
import IconButton from "../../layout/aweLayout/IconButton";
import Button from "../../layout/aweLayout/Button";
import CloseIcon from "../../icons/CloseIcon";
import {LogFileUploadToS3} from '../../../assets/constants/LogFileUploadToS3'


const TimelineAWE = (props) => {
    const [formDataList, setFormDataList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formDataOnHoldList, setFormOnHoldDataList] = useState([]);
    const [mergeTimelineList, setMergeTimelineList] = useState([]);
    const [logMessages, setLogMessages] = useState([]); // Array to store log messages
    const writeToLogFile = (message) => {
       setLogMessages(prevLogMessages => [...prevLogMessages, `${new Date().toISOString()} - ${message}`]);
   };
   const [generateLog, setGenerateLog] = useState(false);

    useEffect(() => {
        loadformDataTable(props.awv_backend_id, props.CinId);
    }, [props.CinId]);

    useEffect(() => {
            loadformOnHold(props.awv_backend_id, props.CinId);
    }, [formDataList]);

    const notificationRightDrawer = (open, valueId = '') => {
        props.currentAWERightSidebarType({ 'type': 'notification' });
        props.currentAWERightSidebar(open);
    };


    const loadformDataTable = (valueAwv, valueCin) => {
        writeToLogFile("value Awv...", valueAwv, ' cin= ', valueCin);
        valueAwv = btoa(valueAwv)
        valueCin = btoa(valueCin)
        writeToLogFile("value Awv after encryption ", valueAwv, ' cin= ', valueCin)
        writeToLogFile("loadformDataTable() call" +  valueAwv, valueCin);
        setIsLoading(true);
        var url = '/get-awv-remark?cin='+ valueCin + '&awv_backend_id=' + valueAwv + '&lob=' + props?.lobSelect + '&year=' + props?.yearSelect + '&organisation_id=1';
        AWVApi.get(url)
            .then(res => {
                if (res.data && res.data[0]) {
                    var approvalRemarks = (res?.data && res?.data[0] && res?.data[0]['approvalRemarks']) ? res?.data[0]['approvalRemarks'] : [];
                    var rejectionRemarks = (res?.data && res?.data[0] && res?.data[0]['rejectionRemarks']) ? res?.data[0]['rejectionRemarks'] : [];
                    var conactArray = approvalRemarks.concat(rejectionRemarks);
                    conactArray.sort(function (a, b) {
                        var dateA = new Date(a.timestamp.replace(/:(\d{2})$/, '')), dateB = new Date(b.timestamp.replace(/:(\d{2})$/, ''))
                        return dateB - dateA;
                    });
                    if (conactArray && conactArray.length > 0) {
                        setFormDataList(conactArray);
                        setMergeTimelineList(conactArray);
                    } else if (approvalRemarks.length == 0 && rejectionRemarks.length == 0) {
                        setFormDataList([]);
                    }
                }
                writeToLogFile("response received successfully...");
                setIsLoading(false);
                setGenerateLog(true)
            })
            .catch(err => {
                console.log(err);
                setFormDataList([]);
                setIsLoading(false);
                writeToLogFile("Error calling an api..." + err);
                setGenerateLog(true)
            })
    }

    const loadformOnHold = (valueAwv, valueCin) => {
        writeToLogFile("value Awv...", valueAwv, ' cin= ', valueCin);
        valueAwv = btoa(valueAwv)
        valueCin = btoa(valueCin)
        writeToLogFile("value Awv after encryption ", valueAwv, ' cin= ', valueCin)
        writeToLogFile("loadformOnHold...");
        setIsLoading(true);
        AWVApi.get('/get-hold-status-remark?cin=' + valueCin + '&awv_backend_id=' + valueAwv + '&lob=' + props?.lobSelect + '&organisation_id=1&year=' + props?.yearSelect) //'&provider_group=' + props?.providerGroupSelect
            .then(res => {
                var onHoldRemarks = (res?.data && res?.data[0] && res?.data[0]['onHoldRemarks']) ? res?.data[0]['onHoldRemarks'] : [];
                var conactArrayNew = formDataList.concat(onHoldRemarks);
                conactArrayNew.sort(function (a, b) {
                    var dateA = new Date(a.timestamp), dateB = new Date(b.timestamp)
                    return dateB - dateA;
                });
                setMergeTimelineList(conactArrayNew);
                setIsLoading(false);
                writeToLogFile("response received successfully...");
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
                writeToLogFile("Error calling an api..." + err);
            })
    }
    useEffect(() => {
        // Generate and download log file when logMessages have been updated
        if (logMessages.length > 0  && generateLog === true) {
        LogFileUploadToS3(logMessages,  localStorage.getItem("access_key"), localStorage.getItem("secret_key"), process.env.REACT_APP_LOG_BUCKET, "Timeline")
        }
    }, [ generateLog === true, logMessages, props.AwvId, props.CinId]); 


    return (
        <div className="diagnosis_code_sidebar offcanvas offcanvas-end show" tabIndex={-1} aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header flex_item_cb">
                <h5 id="offcanvasRightLabel" className='fs15'>TIMELINE</h5>
                <Tooltip title="Close">
                    <IconButton type="button" buttonType={"default"} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => notificationRightDrawer(false)}>
                        <CloseIcon className="w24" />
                    </IconButton>
                </Tooltip>
            </div>
            <Divider />
            <div className="offcanvas-body pt-1 custom-scrollbar overflow-auto">
                {isLoading ?
                    <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        {mergeTimelineList && mergeTimelineList.length > 0 ?
                            <div className="">
                                <h5 className="fs18 mb10"></h5>
                                <Divider />
                                <div className="history_card_list">
                                    {mergeTimelineList && mergeTimelineList.map((element, index) =>
                                        <div key={index} className={'history_card_item status-primary'}>
                                            <p className="fs12 mb-1 fw700">
                                                {Moment(element.timestamp).format('h:mm a, MMMM DD YYYY')}
                                            </p>
                                            <p className="fs12 mb-1">
                                                <span className="fw700">Remarks : </span>
                                                {element.remarks}
                                            </p>    
                                            <p className="fs12 mb-1">
                                                <span className="fw700">Last updated by : </span>
                                                {(element.user_name && element.user_name != '') ?
                                                    element.user_name
                                                    : (element.form_hold_by && element.form_hold_by != '') ?
                                                        element.form_hold_by
                                                        :
                                                        ''
                                                }
                                            </p>
                                            {element.reason_code_description && element.reason_code_description != '' ?
                                                <p className="fs12 mb-1">
                                                    <span className="fw700">Reason Code : </span>
                                                    {element.reason_code_description}
                                                </p>
                                                :
                                                <></>
                                            }
                                            {/* Add the stage remark - hide */}
                                            {element.current_form_status ?
                                                <p className="fs12 mb-1">
                                                    <span className="fw700">Stage Name : </span>
                                                    {element.current_form_status}
                                                </p>
                                                :
                                                <></>
                                            }
                                            {/* Add the On_hold status */}
                                            {element.onhold_status && element.onhold_status != '' ?
                                                <p className="fs12 mb-1">
                                                    <span className="fw700">On Hold status : </span>
                                                    {element.onhold_status}
                                                </p>
                                                :
                                                <></>
                                            }
                                        </div>
                                    )}
                                </div>
                            </div>
                            :
                            <></>
                        }
                    </>
                }
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar })(TimelineAWE);

