import React, { useRef, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../../store/actions';
import AWVApi from '../../../assets/constants/AWVRafservice.Instance';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import Divider from '@mui/material/Divider';
import { useForm, Controller } from "react-hook-form";
import Box from '@mui/material/Box';
import pdfIcon from '../../../assets/images/pdf_icon.png';
import Moment from 'moment';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import S3 from 'react-aws-s3';
import { toast } from 'react-toastify';
import { string } from "i/lib/util";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import IconButton from "../../layout/aweLayout/IconButton";
import Button from "../../layout/aweLayout/Button";
import CloseIcon from "../../icons/CloseIcon";
import PlusFileIcon from '../../icons/PlusFileIcon';
var AWS = require("aws-sdk");

const S3_BUCKET = process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET;
const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = localStorage.getItem("access_key");
const SECRET_ACCESS_KEY = localStorage.getItem("secret_key");

const AddAWVFileUpload = (props) => {
    const fileInput = React.useRef();
    const [editFormData, setEditFormData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [fileUrl, setFileUrl] = useState();
    const [fileName, setFileName] = useState();
    const [formFileUrl, setFormFileUrl] = useState();
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(true);

    const config = {
        bucketName: process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET,
        //bucketName: 'bucket-awv-form-upload', //process.env.REACT_APP_BUCKET_NAME_Two,
        // dirName: 'bucket-awv-report', 
        region: process.env.REACT_APP_REGION,
        accessKeyId: localStorage.getItem("access_key"),
        secretAccessKey: localStorage.getItem("secret_key"),
        s3Url: 'https://rj3vuo7d30.execute-api.us-east-1.amazonaws.com/v1/upload-form',
    }

    const ReactS3Client = new S3(config);

    useEffect(() => {
        editLoadFormData(props.awv_backend_id);
    }, [props.awv_backend_id]);

    useEffect(() => {
        if (editFormData?.awv_backend_id && editFormData?.awv_backend_id != '') {
            let controlUser = JSON.parse(localStorage.getItem('controlUser'));
            if (editFormData && editFormData?.awv_backend_id) {
                setValue('cin', editFormData?.cin);
                setValue('awv_backend_id', editFormData?.awv_backend_id ? (editFormData?.awv_backend_id).toString() : '');
                setValue('form_url', '');
                setValue('updated_by', controlUser?.user_id);
                setValue('remark', '');
                setValue('lob', props?.lobSelect);
                setValue('file_upload', '');
                setValue('form_url', '');
                setValue('onhold_status', 'Y');
            } else {
                setValue('cin', '');
                setValue('awv_id', '');
                setValue('form_url', '');
                setValue('updated_by', '');
                setValue('remark', '');
                setValue('file_upload', '');
                setValue('form_url', '');
                setValue('onhold_status', 'Y');
            }
            setFileName('');
            setFormFileUrl('');
        }
    }, [editFormData]);

    const notificationRightDrawer = (open, valueId = '') => {
        props.currentAWERightSidebarType({ 'type': 'notification' });
        props.currentAWERightSidebar(open);
    };

    const editLoadFormData = (value) => {
        setIsLoading(true);
        AWVApi.get('/get-all-awv-record?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + '&awv_backend_id=' + btoa(value) + '&iSortCol_0=provider_group&sSortDir_0=asc&iDisplayStart=0&iDisplayLength=1&formAttached=0')
            .then(res => {
                if (res.data && res.data[0]) {
                    setEditFormData(res.data[0]);
                    setIsLoading(false);
                } else {
                    setEditFormData();
                    setIsLoading(false);
                }
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
            })
    }

    // Delacaring date with formate mm-DD-yyyy
    const [currentDate, setCurrentDate] = useState(new Date().toLocaleDateString());
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDate(new Date().toLocaleDateString());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);
    var date = currentDate.split('/');
    var newDate = date[0].toString().padStart(2, '0') + '-' + date[1].toString().padStart(2, '0') + '-' + date[2];

    const handleFileUpload = (event) => {
        setValue('file_upload', event.target.value);
        setFileName(fileInput?.current?.files[0]?.name);

        if (fileInput?.current && fileInput?.current?.files && fileInput?.current?.files[0]) {

            const headers = {
                'Content-Type': 'application/pdf'
            }

            let path = '';
            const lobFolder = (props?.lobSelect === 'ACA' || props?.lobSelect === '1') ? '/aca' :
            (props?.lobSelect === 'Medicaid' || props?.lobSelect === '3') ? '/medicaid' : 
            '/medicare';
            path = `${process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET}/${process.env.REACT_APP_AWE_FORM_UPLOAD_FOLDER}${lobFolder}/${props?.yearSelect}`;
            AWS.config.update({ region: REGION, credentials: new AWS.Credentials(ACCESS_KEY, SECRET_ACCESS_KEY) });
            var file = fileInput?.current?.files[0]; //file?.name
            // changes perform on filename and validate the filename {blank space replace with underscore & through regex we test any Special character present in the filename or not if present then restrict to pass }
            var exa_fileName = fileInput?.current?.files[0]?.name.replaceAll(" ", "_");
            exa_fileName = exa_fileName.slice(0, exa_fileName.lastIndexOf('.'));
            const fileNameArray = fileInput?.current?.files[0].name.split('.');
            const fileExtension = fileNameArray.pop();

            if ( fileExtension.toLowerCase()  != 'pdf') {
                setFileName('');
                setFormFileUrl('');
                setValue('file_upload', '');
                return toast.error("Only PDF file is allowed.");
            }

            const regex = /^[a-zA-Z0-9_.-]+$/;
            if (!regex.test(exa_fileName) || exa_fileName.includes('.')) {
                setFileName('');
                setFormFileUrl('');
                setValue('file_upload', '');
                return toast.error("File name contains special characters is not uploading");
            }
            // here we attach the timestamp formate of (DD-MM-YY) ;
            var fileKey = exa_fileName + "_" + newDate + '.' + fileExtension;
            // var fileKey = exa_fileName + "_" + newDate + ".pdf";
            let upload_params = { Bucket: path, Key: fileKey, Body: file, ServerSideEncryption: 'aws:kms', SSEKMSKeyId: process.env.REACT_APP_KeyID, ContentDisposition: "inline" };
            let upload = new AWS.S3.ManagedUpload({ params: upload_params });
            upload.promise(function (err, data) {
                //window.alert("FILE UPLOADED SUCCESSFULLY data ",data.Location," ERRO  ",err );
                setFormFileUrl(data.Location);
                setValue('form_url', data.Location);
                setFormSubmitisLoading(false)
                var formUrl = data.Location;
                var s3 = new AWS.S3({ signatureVersion: "v4" });
                //let Key = fileKey;
                let Key = "awv-form-upload/" + fileKey;

                s3.setupRequestListeners = (request) => {
                    request.on('build', (req) => {
                        req.httpRequest.headers['x-amz-date'] = process.env.REACT_APP_KeyID;
                    });
                }
                var presignedGETURL = s3.getSignedUrl('getObject', {
                    Bucket: process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET, // S3_BUCKET, //bucket-demo-data-repository
                    Key: Key,
                    Expires: 60
                });
                setFormFileUrl(presignedGETURL);
            });
        }

    }

    const handleFileOpenClick = () => {
        fileInput.current.click();
    }

    const onSubmit = (data) => {
        //event.preventDefault();
        if (editFormData?.awv_backend_id && editFormData?.awv_backend_id != '') {
            setFormSubmitisLoading(true);
            // console.log(data);
            data.form_url = getValues('form_url');
            // adding lob, year, org id in payload
            data.lob = props?.lobSelect;
            data.lob = data.lob.toString();
            data.year = props?.yearSelect;
            data.organisation_id = '1';
            data.current_form_status = "PendingUpload";
            data.cin = btoa(data.cin)
            data.updated_on = Moment().format('YYYY-MM-DD HH:mm:ss');
            data.awv_backend_id = btoa(data.awv_backend_id)
            if(data?.lob)
                    data.lob = data.lob.toString();
            AWVApi.put('/update-awv-form-url?awv_backend_id=' + btoa(data?.awv_backend_id) + '&form_url=&updated_by=' + data?.updated_by, data)
                .then(res => {
                    setValue('memberId', '');
                    setValue('awv_backend_id', '');
                    setValue('form_url', '');
                    setValue('updated_by', '');
                    setValue('remark', '');
                    setFormSubmitisLoading(false);
                    toast.success('Form update success');
                    notificationRightDrawer(false);
                    props.currentAWERightSidebarCloseDatatableReload(true);
                })
                .catch(err => {
                    setFormSubmitisLoading(false);
                    toast.error(err?.response?.data?.message);
                })
        }
    };

    return (
        <div className="diagnosis_code_sidebar offcanvas offcanvas-end show" tabIndex={-1} aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header flex_item_cb">
                <h5 id="offcanvasRightLabel" className='fs15'>
                    EDIT
                </h5>
                <Tooltip title="Close">
                    <IconButton type="button" buttonType={"default"} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => notificationRightDrawer(false)}>
                        <CloseIcon className="w24" />
                    </IconButton>
                </Tooltip>
            </div>
            <Divider />
            <div className="offcanvas-body pt-1 custom-scrollbar overflow-auto">
                <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                    <div className="flex_item_cb custom_row flex-wrap mb20">
                        <div className="custom_col">
                            <div className="">
                                <h6 className="fs14">CIN/RXID</h6>
                                <p className="fs13 fw500">{editFormData?.cin}</p>
                            </div>
                        </div>
                        <div className="custom_col">
                            <div className="">
                                <h6 className="fs14">AWE ID</h6>
                                <p className="fs13 fw500">{editFormData?.awe_display_id}</p>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Upload File</label>
                        <div className="flex_item_cs gap8">
                            <Button type='button' className={"minw120 px-1"} onClick={handleFileOpenClick}> CHOSE FILE </Button>
                            <Controller
                                name="file_upload"
                                control={control}
                                render={({ field }) => (
                                    <input type="file"  {...field} style={{display:"none"}} className="form-control" ref={fileInput} onChange={handleFileUpload} />
                                )}
                                rules={{
                                    required: true,
                                }}
                            />
                        </div>

                        {errors?.file_upload?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    {fileName && fileName != '' ?
                        <div className="form-group mb20">
                            <h6 className="fs14 mb-1">PDF</h6>
                            <div className=''>
                                {formFileUrl && formFileUrl.includes(".pdf") ?
                                    <a href={formFileUrl} target="_blank" className="flex_item_cs gap8 text-primary fs15 fw600">
                                        <PlusFileIcon className="w24 h24" />
                                        <span>{fileName}</span>
                                    </a>
                                    :
                                    <a href={formFileUrl} target="_blank" download className="flex_item_cs gap8 text-primary fs15 fw600">
                                        <PlusFileIcon className="w24 h24" />
                                        <span>{fileName}</span>
                                    </a>
                                }
                            </div>
                        </div>
                        :
                        <></>
                    }
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Remark</label>
                        <Controller
                            name="remark"
                            control={control}
                            render={({ field }) => (
                                <textarea rows={3} {...field} className="form-control" />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.remark?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>

                    <div className="form-group flex_item_cs gap10">
                        <Button type='button' className={"btn-white minw80"} onClick={() => notificationRightDrawer(false)}>CANCEL</Button>
                        <Button type='submit' className={"minw80"} disabled={formSubmitisLoading && formSubmitisLoading == true ? true : false}>SAVE</Button>
                    </div>
                </form>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(AddAWVFileUpload);

