import React, { useState, useEffect, useRef } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag, hccSeperator, addSuffixTwoDecimal} from "../../assets/constants/customRender";
import GridTable from "../../components/TableGrids/GridTable.table";
import history from '../../history';
import { GAP_INSIGHTS, HCC, GENDER,MEMBER_ID, ISSUER_ID, MEMBER_NAME, CARRIER_ID, PLAN_ID } from "../../assets/constants/string.constants";
import CardComponent from "../../components/Cards/card";
import { connect } from 'react-redux';
import BarVerticalHCC from '../../components/GChart/BarVerticalHCC';

const ACAGapHcc= (props) =>{
    const elementRef = '';
    const [year] = useState(props.yearSelect);
    const [hcc, setHcc] = useState(localStorage.getItem(HCC));
    const [issuer_id] = useState(localStorage.getItem(ISSUER_ID));
   // const [HccReportedChartData, setHccReportedChartData] = useState({});  
   // const [HccGAPChartData, setHccGAPChartData] = useState({});  
    const [tableData, setTableData] = useState([]);
    const [HccReportedChartData, setHccReportedChartData] = useState([]);
    const [HccGAPChartData, setHccGAPChartData] = useState([]);
                              
    const loadMetricTable = () =>{
        api.get('/acagaphccDetail/hcc/'+hcc+'/year/'+year+'/issuer_id/'+issuer_id)
        .then(res=>{
            setTableData(res);
        })
        .catch(err=>{
            // console.log(err);
        })
    }

    const  summaryColumns =[
        {
            name: "hccCode",
            label: "HCC Code ",
            options : anchorTag
        },
        {
            name: "description",
            label: "Description",
        },
        {
            name: "totalCount",
            label: "# Members"
        },
        {
            name: "totalGaps",
            label: "# Members with Gap"
        }, 
        
    ]


    const memberColumn = [
        {
            label : "Member ID",
            name:"MEMBER_ID",
            options:anchorTag
        },
        {
            name : "MEMBER_Name",
            label : "Member Name"
        },
        {
            name : "Carrier_ID",
            label : "Carrier ID"
        },	
        
        {
            name : "Reported_hcc",
            label : "Reported HCCs",
            options : hccSeperator
        },
        {
            name : "Gap_hcc",
            label : "Gap HCCs",
            options : hccSeperator
        },
        {
            name : "Risk_score",
            label : "Risk Score",
            options:addSuffixTwoDecimal
        },
        {
            name : "Gap_in_risk_score",
            label : "Gap In Risk Score",
            options:addSuffixTwoDecimal
        },
        {
            name : "Hcc_score",
            label : "Reported HCC Score",
            options:addSuffixTwoDecimal
        },
        {
            name : "Gap_in_hcc_score",
            label : "Gap In HCC Score",
            options:addSuffixTwoDecimal
        },			
        {
            name : "Plan_ID",
            label : "Plan ID"
        }
    ];

    const memberTableClick = (rowdata, colData, cellMeta) =>{
        if((cellMeta.colIndex === 0)&&(colData.props.children != "")){
            localStorage.setItem(MEMBER_ID,rowdata[cellMeta.rowIndex].MEMBER_ID);
            localStorage.setItem(MEMBER_NAME,rowdata[cellMeta.rowIndex].MEMBER_Name);
            localStorage.setItem(CARRIER_ID,rowdata[cellMeta.rowIndex].Carrier_ID);
            localStorage.setItem(PLAN_ID,rowdata[cellMeta.rowIndex].Plan_ID);
            localStorage.setItem(GENDER,rowdata[cellMeta.rowIndex].Gender);
            history.push('/layout/acaMember', GAP_INSIGHTS)
        }
        else if((cellMeta.colIndex === 3)&&(colData.props.children != "")){
            if(typeof(colData.props.children) == "object"){
                localStorage.setItem(HCC,(cellMeta.event.target.innerText).replace(',',''));
            }
            else{
                localStorage.setItem(HCC,rowdata[cellMeta.rowIndex].Reported_hcc);
            }
            localStorage.setItem(ISSUER_ID,rowdata[cellMeta.rowIndex].ISSUER_ID);
            history.push('/layout/acaReportedHcc')
        }
        else if((cellMeta.colIndex === 4)&&(colData.props.children != "")){
            if(typeof(colData.props.children) == "object"){
                setHcc((cellMeta.event.target.innerText).replace(',',''));
            }
            else{
                setHcc(rowdata[cellMeta.rowIndex].Gap_hcc);
            }
            history.push('/layout/AcaGapHcc');
        }
    }

   /*  const loadHccReportedChart = () =>{

        const  handlechartClick = (element) =>{    
            let chartData = element[0]['_chart'].config.data;
            let idx = element[0]['_index'];
            let hcc = chartData.labels[idx];
            let parts = hcc.split(':');
            let hcc1 = parts[1];
             
            localStorage.setItem("hcc",hcc1);
            localStorage.setItem("issuer_id", issuer_id);
            history.push('/layout/AcaGapHcc');
        };

        let hccCode =[];
        let totalCount =[];
        api.get('/acareportedToReported/year/'+year+'/hcc/'+hcc+'/issuer_id/'+issuer_id)
        .then(res=>{
            for (const dataObj of res) {
              hccCode.push((dataObj.description + ':' + dataObj.hccCode));
              totalCount.push(dataObj.percentage);
            }
            setHccReportedChartData({
            labels: hccCode,
            data: totalCount,
            dLabel: "Percentage",
            hccCustomFunc :handlechartClick
        });
        })
    } */

    /* const loadHccGAPChart = () =>{

        const  handlechartClick = (element) =>{    
            let chartData = element[0]['_chart'].config.data;
            let idx = element[0]['_index'];
            let hcc = chartData.labels[idx];
            let parts = hcc.split(':');
            let hcc1 = parts[1];
            localStorage.setItem("hcc",hcc1);
            localStorage.setItem("issuer_id", issuer_id);
            history.push('/layout/AcaGapHcc');
        };

        let hccCode =[];
        let totalCount =[];
        api.get('/acagapToReported/year/'+year+'/hcc/'+hcc+'/issuer_id/'+issuer_id)
        .then(res=>{
            for (const dataObj of res) {
              hccCode.push((dataObj.description + ':' + dataObj.hccCode));
              totalCount.push(dataObj.percentage);
            }
            setHccGAPChartData({
            labels: hccCode,
            data: totalCount,
            dLabel: "Percentage",
            hccCustomFunc :handlechartClick
        });
        })
    } */

    const loadReportedChart = () => {        
        let newChartData = [];
            newChartData.push(["Provider", "Percentage", { role: 'annotation' }]);
            api.get('/acareportedToReported/year/'+year+'/hcc/'+hcc+'/issuer_id/'+issuer_id)
                .then(res => {
                    for (const dataObj of res) {
                        newChartData.push([dataObj.description+':'+dataObj.hccCode, dataObj.percentage, dataObj.percentage+'%']);
                    }
                    setHccReportedChartData(newChartData);
            })
            .catch(err => {
                console.log("Error while fetching data" + err);
            })
    }
    const loadHccGAPChart = () => {        
        let newChartData = [];
            newChartData.push(["Provider", "Percentage", { role: 'annotation' }]);
            api.get('/acagapToReported/year/'+year+'/hcc/'+hcc+'/issuer_id/'+issuer_id)
                .then(res => {
                    for (const dataObj of res) {
                        newChartData.push([dataObj.description+':'+dataObj.hccCode, dataObj.percentage, dataObj.percentage+'%']);
                    }
                    setHccGAPChartData(newChartData);
            })
            .catch(err => {
                console.log("Error while fetching data" + err);
            })
    }
    useEffect(() =>{
         loadMetricTable();
        // loadHccReportedChart();
        // loadHccGAPChart();
           loadReportedChart();
           loadHccGAPChart();
    },[year, hcc]);


    return(
        <div>
            <CardComponent
            heading={`Hcc View - For year : ${year} and Issuer ID : ${issuer_id}`}
            body={
                <GridTable
                    data={tableData}
                    columns={summaryColumns}
                ></GridTable>
            }></CardComponent>
            <br />
            <div className="member-list">
            <CardComponent
            heading={`MEMBERS DETAILS`}
            body={
                <ServerSideGridTable
                    url={`acaGapHcc/year/${year}/hcc/${hcc}/issuer_id/${issuer_id}`}
                    columns={memberColumn}
                    tableClick={memberTableClick}
                ></ServerSideGridTable>
            }></CardComponent>
                </div>
            <br/>
            <div className="row col-lg-12 mt-4">
            <div className="col-md-6">
            <CardComponent
            heading={`Top 5 Co-occurring HCCs for this Reported Condition`}
            body={
            <div className="box-height-chart"> 
                <BarVerticalHCC data={HccReportedChartData} chartType="BarChart" height="300px" width="100%" hAxisTitle={'Percentage (%)'} yAxistitle={"HCC's"} setStorgeItemName={HCC} redirectUrl={'AcaGapHcc'} scrollRef={elementRef}></BarVerticalHCC>
            </div>
            }></CardComponent>
            </div>
            <br/>      
            <div className="col-md-6">      
            <CardComponent
            heading={`Top 5 Co-occurring HCCs for this Gap Condition`}
            body={
                <div className="box-height-chart"> 
                <BarVerticalHCC data={HccGAPChartData} chartType="BarChart" height="300px" width="100%" hAxisTitle={'Percentage (%)'} yAxistitle={"HCC's"} setStorgeItemName={HCC} redirectUrl= {'AcaGapHcc'} scrollRef={elementRef}></BarVerticalHCC>
            </div>
            }></CardComponent>
           </div>
            </div>          
            <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br/>

                <br/>
                <br/>  
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(ACAGapHcc);
