import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import CardComponent from "../../components/Cards/card";
import GridTable from "../../components/TableGrids/GridTable.table";
import { getAcaYear,getAcaYearList } from "../../assets/constants/yearList";
import { anchorTag} from "../../assets/constants/customRender"; 
import { MEMBER_ID } from "../../assets/constants/string.constants";
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';

const ReconHCCSummary = (props) =>{


    const [year,setYear] = useState(props.yearSelect);
    const[memeber,setMember]= useState(localStorage.getItem(MEMBER_ID))
    const [tableData, setTableData] = useState([]);
    useEffect(()=>{
        loadMetricTable();
    },[       
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect,
        props.meInitialSelect
    ]);
//{"MEMBER_ID":"QMXM00000280122","HCC":"88","GAP_RISK_SCORE_CLOSED":null,
//"PERC_RISK_SCORE_GAP_CLOSED":null,"HCC_GAPS_CLOSED":"No","MEMBER_PART_OF_CHASE":"No",
//"YEAR":2019}]
const memberColumns = [
        {
            label : "Member ID",
            name:"MEMBER_ID",
             
        },
        
        {
            name : "HCC",
            label : "HCC"
        },
        {
            name : "GAP_RISK_SCORE_CLOSED",
            label : "Gap Risk Score Closed"            
            
        },
        {
            name : "PERC_RISK_SCORE_GAP_CLOSED",
            label : "Percent Gap Risk Score Closed",
            
        },
        {
            name : "HCC_GAPS_CLOSED",
            label : "HCC Gap Closed"
        },
        
        {
            name : "GAP_CLOSURE_SOURCE",
            label : "Gap Closure Source"
        },
        {
            name : "MEMBER_PART_OF_CHART_CHASE",
            label : "Member Part Of Chart Chase"
        },
         ];
//[{"MEMBER_ID":"QMXM00000281084","HCC":"30","GAP_RISK_SCORE_CLOSED":0.7556,"PERC_RISK_SCORE_GAP_CLOSED":85.39,"HCC_GAPS_CLOSED":"Yes","GAP_CLOSURE_SOURCE":"Medical Claim","YEAR":2020,"MEMBER_PART_OF_CHART_CHASE":null
        const loadMetricTable = () =>{
            api.get('/Reconsiliation/'+props.yearSelect+'/'+memeber)
            .then(res=>{
                // console.log(res);
                setTableData(res);
            })
            .catch(err=>{
                // console.log(err);
            })
        }

        return(
            <div className="container level-summary">
                <div className="row mb-3">
                    <div className="col-lg-12 pr-0 pl-0 mb-3">
                        <div className="state-level">
                            <div className="card-header">
                                <SubHeader title={'Recon HCC Summary'} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 pr-0 pl-0 mb-3">   
                        <CardComponent
                            heading={"HCC DETAILS"}
                            body={
                                <GridTable
                                    data={tableData}
                                    columns={memberColumns}
                                    pagination={true}
                                    
                                ></GridTable>
                            }></CardComponent>
                    </div>
                <br/>
                <br/>     
            </div>
        </div>
        );

}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(ReconHCCSummary);

