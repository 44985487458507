import PropTypes from "prop-types";
import {SetModuleSelect} from '../../assets/constants/moduleList';
import { currentModuleSelect } from '../../store/actions';
import { connect } from 'react-redux';
import history from "../../history";

const ModuleSelect = (props) => {

    const { ModuleList, moduleSelect} = props;
    props.currentModuleSelect(moduleSelect);

    const handleSelect = (e) =>{
        SetModuleSelect(e.target.value);
        props.currentModuleSelect(e.target.value);
        props.onModuleSelect(e.target.value);
        if(e.target.value == 'ACA'){
            history.push('/layout/acaReportedMemberSummary')
        }else if(e.target.value == 'Medicare'){
            history.push('/layout/medicare/qs_member_details')
            // history.push('/layout/medicare/reported_member_summary')
        }else{
            history.push('/layout/acaReportedMemberSummary')
        }
    }

    return(
        <div className="row justify-content-between">
            <div className="col-md-12 btn-group">
            <select className="col-md-12 btn btn-info dropdown-toggle btn" style={{"fontWeight":"bold"}} value={moduleSelect} onChange={handleSelect}>
                {
                    ModuleList && ModuleList.map(el => <option value={el} key={el} > {el} </option>)
                }
            </select>
            </div>
        </div>
    )
}

ModuleSelect.propTypes ={
    ModuleList : PropTypes.array,
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
    };
  }
export default connect(mapStateToProps, {currentModuleSelect})(ModuleSelect);
