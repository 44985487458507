import React, { useEffect } from "react";
import './App.css';
import { Router, Route } from 'react-router-dom';
import LoginComponent from './components/feature/login/login';
import Layout from './components/layout/layout';
import AdminLayout from './components/layout/adminLayout';
import EtlLayout from './components/layout/etlLayout';
import AwvLayout from './components/layout/awvLayout';
import MainAweLayout from './components/layout/MainAweLayout';
import Header from './components/home/header/header';
import HomeComponent from './components/home/home';
import Terms from './components/feature/Terms/terms';
import history from './history';
import { signIn, permssionAssign } from './store/actions';
import { connect } from 'react-redux';
import LogoutModel from './components/Dialog/LogoutModel';
import { getSecret } from '../src/Pages/config/ParameterStore';
import KeyRafservice from '../src/assets/constants/KeyRafservice.Instance';

const App = (props) => {
 
  useEffect(() => {
    let userDetails = JSON.parse(localStorage.getItem('user'));
    let permissionDetails = JSON.parse(localStorage.getItem('controlPermissions'));
    if (permissionDetails) {
      props.permssionAssign(permissionDetails);
    }
    if (userDetails) {
      props.signIn(userDetails);
      history.push('/home');
    }
    document.getElementsByTagName("body")[0].classList.add("normal-layout-container")
    document.getElementsByTagName("body")[0].classList.remove("awe-layout-container")
  }, []);

  const noop = () => {}
  ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
    'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
    'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
    'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn',
  ].forEach((method) => {
    window.console[method] = noop
  })

  useEffect(() => {
    KeyRafservice.get('/keys').then(res => {
      const secretNames = ['s3-access-key', 's3-secret-key']
      getSecret(secretNames, res.data.access_key, res.data.secret_key, res.data.session_token, false)
            .then(secrets => {
              localStorage.setItem('access_key',secrets['s3-access-key'])
              localStorage.setItem('secret_key', secrets['s3-secret-key'])
              localStorage.setItem('session_token', res.data.session_token)
            })
            .catch(error => {
                console.error("Error retrieving secrets:", error);
            });
    }).catch(err => {})
  })
  const authenticate = props.userDetails?.isSignedIn;
  return (
    <Router history={history}>
      {/* <div className="App">
        <Header></Header>
        <div className="router-box"> */}
      {
        authenticate ?
          <React.Fragment>
            <Route path="/home" component={HomeComponent} />
            <Route path="/layout" component={Layout} />
            <Route path="/admin" component={AdminLayout} />
            <Route path="/etl" component={EtlLayout} />
            <Route path="/awe" component={AwvLayout} />
            <Route path="/new_awe" component={MainAweLayout} />
            <LogoutModel />
          </React.Fragment>
          :
          <React.Fragment>
            <Route path="/" exact component={LoginComponent} />
            <Route path="/terms" component={Terms} />
          </ React.Fragment>
      }
      {/* </div>
      </div> */}
    </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.auth
  };
}
export default connect(mapStateToProps, { signIn, permssionAssign })(App);
