import React, { useState, useEffect } from "react";
import AWVApi from '../../../assets/constants/AWVRafservice.Instance';
import AWVServerSideGridTable from "../../../components/TableGrids/AWVServerSideGridTable.table";
import AWVExpandGridTable from "../../../components/TableGrids/AWVExpandGridTable.table";
import { HCC, ISSUER_ID } from "../../../assets/constants/string.constants";
import AwvCardComponent from "../../../components/Cards/AwvCard";
import { connect } from 'react-redux';
import AwvSubHeader from '../../../components/home/header/AwvSubHeader';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import Tooltip from '@mui/material/Tooltip';
import { purple, red } from '@mui/material/colors';
import Awvheader from '../../../components/home/header/Awvheader';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AddAWVFileUpload from '../../../components/Dialog/AddAWVFileUpload';
import AreaChartMultiExcelAWV from '../../../components/Charts/AreaChartMultiExcelAWV';
import ColumnChartSingleExcelAWV from '../../../components/Charts/ColumnChartSingleExcelAWV';
import ColumnChartStackAWV from '../../../components/Charts/ColumnChartStackAWV';
import BarChartDiffDataAWV from '../../../components/Charts/BarChartDiffDataAWV';
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';


const HCCCaptureRate = (props) => {


    return (
        <>
            <div className="row pr-0 pl-0 mt-1">
                <div className="col-lg-6">
                    <AwvCardComponent
                        heading={`Total AWE Visits - Overview`}
                        body={
                            <div className="col-lg-12 row mb-4 mt-4">
                                <div className="col-lg-8 p-0 m-0">
                                    <div className="justify-content-center text-center ml-3 mt-5" style={{ width: '230px' }}>
                                        <CircularProgressbarWithChildren
                                            value={80}
                                            strokeWidth={10}
                                            styles={buildStyles({
                                                pathColor: `#1D9A9B`,
                                            })}
                                        >
                                            <div >
                                                <strong style={{ fontSize: 30, marginTop: 0, color: '#000' }}>80%</strong> <br />
                                                <strong style={{ fontSize: 15, marginTop: 5, color: '#000' }}>Completed</strong>
                                            </div>
                                        </CircularProgressbarWithChildren >
                                        <div className="circular-content mt-3 ml-3 row">
                                            <strong style={{ fontSize: 12, color: '#000' }}>
                                                <div style={{ height: '13px', width: '20px', backgroundColor: '#1D9A9B', position: 'absolute' }}></div>
                                                <div className="ml-4">Completed</div>
                                            </strong>
                                            <strong className="ml-4" style={{ fontSize: 12, color: '#000' }}>
                                                <div style={{ height: '13px', width: '20px', backgroundColor: '#D6D6D6', position: 'absolute' }}></div>
                                                <div className="ml-4">Pending</div>
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 p-0 m-0" style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                    <div className="mt-4 pb-4 pl-2" style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <strong style={{ fontSize: 25, marginTop: 0, color: '#000' }}>1200</strong> <br />
                                        <strong style={{ fontSize: 12, marginTop: 5, color: '#000' }}>Completed AWE Visits</strong>
                                    </div>
                                    <div className="mt-4 pb-4 pl-2" style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <strong style={{ fontSize: 25, marginTop: 0, color: '#1D9A9B' }}>960</strong> <br />
                                        <strong style={{ fontSize: 12, marginTop: 5, color: '#000' }}>Total HCC Captured</strong>
                                    </div>
                                    <div className="mt-4 pb-4 pl-2">
                                        <strong style={{ fontSize: 25, marginTop: 0, color: '#fcb441' }}>80%</strong> <br />
                                        <strong style={{ fontSize: 12, marginTop: 5, color: '#000' }}>HCC Capture Rate</strong>
                                    </div>
                                </div>
                            </div>
                        }
                    />
                </div>
                <div className="col-lg-6">
                    <AwvCardComponent
                        heading={`AWE Visit Rate By Year`}
                        body={
                            <ColumnChartStackAWV />
                        }
                    />
                </div>
            </div>
            <div className="row pr-0 pl-0 mt-3">
                <div className="col-lg-6">
                    <AwvCardComponent
                        heading={`Cumulative (Sum Of All Months With Year)`}
                        body={
                            <BarChartDiffDataAWV />
                        }
                    />
                </div>
                <div className="col-lg-6">
                    <AwvCardComponent
                        heading={`Overall Visits In a Year`}
                        body={
                            <ColumnChartSingleExcelAWV />
                        }
                    />
                </div>
            </div>
        </>
    )

};
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        lobSelect: state.moduleFilter.lobSelect,
    };
}
export default connect(mapStateToProps)(HCCCaptureRate);