import React from 'react'

export default function SearchIcon({...props}) {
    return (
        <svg {...props} viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M7.46143 1.71875C4.44112 1.71875 1.99268 4.16719 1.99268 7.1875C1.99268 10.2078 4.44112 12.6562 7.46143 12.6562C10.4817 12.6562 12.9302 10.2078 12.9302 7.1875C12.9302 4.16719 10.4817 1.71875 7.46143 1.71875ZM1.05518 7.1875C1.05518 3.64943 3.92335 0.78125 7.46143 0.78125C10.9995 0.78125 13.8677 3.64943 13.8677 7.1875C13.8677 10.7256 10.9995 13.5938 7.46143 13.5938C3.92335 13.5938 1.05518 10.7256 1.05518 7.1875ZM12.4425 12.1686C12.6256 11.9855 12.9223 11.9855 13.1054 12.1686L14.3554 13.4186C14.5384 13.6016 14.5384 13.8984 14.3554 14.0814C14.1723 14.2645 13.8756 14.2645 13.6925 14.0814L12.4425 12.8314C12.2594 12.6484 12.2594 12.3516 12.4425 12.1686Z" fill="currentcolor"/>
        </svg>
        
    )
}
