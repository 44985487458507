import React, { useRef, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../../store/actions';
import AWVApi from '../../../assets/constants/AWVRafservice.Instance';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import Divider from '@mui/material/Divider';
import { useForm, Controller } from "react-hook-form";
import Box from '@mui/material/Box';
import pdfIcon from '../../../assets/images/pdf_icon.png';
import Moment from 'moment';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import S3 from 'react-aws-s3';
import { toast } from 'react-toastify';
import { string } from "i/lib/util";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import IconButton from "../../layout/aweLayout/IconButton";
import Button from "../../layout/aweLayout/Button";
import CloseIcon from "../../icons/CloseIcon";
import axios from "axios";
import PlusFileIcon from '../../icons/PlusFileIcon';
import {LogFileUploadToS3} from '../../../assets/constants/LogFileUploadToS3'

var AWS = require("aws-sdk");

const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = localStorage.getItem('access_key');
const SECRET_ACCESS_KEY = localStorage.getItem('secret_key');

const AWVAdditionalAttachments = (props) => {
    const fileInput = React.useRef();
    const [editFormData, setEditFormData] = useState({});
    const [attachmetTypeData, setAttachmetTypeData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [fileUrl, setFileUrl] = useState();
    const [fileName, setFileName] = useState();
    const [formFileUrl, setFormFileUrl] = useState();
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(true);
    const [formDataList, setFormDataList] = useState([]);
    const [logMessages, setLogMessages] = useState([]); // Array to store log messages
    const writeToLogFile = (message) => {
       setLogMessages(prevLogMessages => [...prevLogMessages, `${new Date().toISOString()} - ${message}`]);
   };
   const [generateLog, setGenerateLog] = useState(false);

    useEffect(() => {
        editLoadFormData(props.awv_backend_id);
        getAttachmetTypeData();
        loadformDataTable(props.awv_backend_id, props.CinId);
    }, [props.awv_backend_id]);

    useEffect(() => {
        if (editFormData?.awv_backend_id && editFormData?.awv_backend_id != '') {
            let controlUser = JSON.parse(localStorage.getItem('controlUser'));
            if (editFormData && editFormData?.awv_backend_id) {
                setValue('cin', editFormData?.cin);
                setValue('awv_backend_id', (editFormData?.awv_backend_id).toString());
                setValue('member_id', editFormData?.member_id);
                setValue('year', props?.yearSelect);
                setValue('provider_group', props?.providerGroupSelect);
                setValue('lob', props?.lobSelect);
                setValue('organisation_id', '1');
                setValue('form_url', '');
                setValue('form_attachment_type_id', '');
                setValue('remark', '');
                setValue('created_by', controlUser?.user_id);
                setValue('created_on', Moment().format('YYYY-MM-DD HH:mm:ss'));
            } else {
                setValue('cin', '');
                setValue('awv_backend_id', '');
                setValue('member_id', '');
                setValue('year', props?.yearSelect);
                setValue('provider_group', props?.providerGroupSelect);
                setValue('lob', props?.lobSelect);
                setValue('organisation_id', '1');
                setValue('form_url', '');
                setValue('form_attachment_type_id', '');
                setValue('remark', '');
                setValue('created_by', controlUser?.user_id);
                setValue('created_on', Moment().format('YYYY-MM-DD HH:mm:ss'));
            }
            setFileName('');
            setFormFileUrl('');
        }
    }, [editFormData]);

    const notificationRightDrawer = (open, valueId = '') => {
        props.currentAWERightSidebarType({ 'type': 'notification' });
        props.currentAWERightSidebar(open);
    };

    const generateSignedURL = (url) => {
        var credentials = {
            accessKeyId: ACCESS_KEY,
            secretAccessKey: SECRET_ACCESS_KEY,
        };
        AWS.config.update({
            credentials: credentials,
            region: REGION,
            signatureVersion: 'v4',
        });
        var s3 = new AWS.S3({
            signatureVersion: 'v4',
        });
        const [protocol, fullHost, ...pathSegments] = url.split('/');
        const [bucketName, port] = pathSegments[0].split('.');
        const objectKey = pathSegments.slice(1).join('/');

        const Key = `${objectKey}`;
        s3.setupRequestListeners = (request) => {
            request.on('build', (req) => {
                req.httpRequest.headers['x-amz-date'] = process.env.REACT_APP_KeyID;
            });
        };
        var presignedGETURL = s3.getSignedUrl('getObject', {
            Bucket: bucketName,
            Key: Key,
        });
        return presignedGETURL;
    };
    const loadformDataTable = (valueAwv, valueCin) => {
        writeToLogFile("value Awv...", valueAwv , ' Cin = ', valueCin);
        valueAwv = btoa(valueAwv)
        valueCin = btoa(valueCin) 
        writeToLogFile("value Awv after encryption ", valueAwv,  ' Cin = ', valueCin)
        writeToLogFile("loadformDataTable() call");
        setIsLoading(true);
        AWVApi.get('/get-awe-additional-attachment?cin=' + btoa(valueCin) + '&awv_backend_id=' + btoa(valueAwv) + '&lob=' + props?.lobSelect + '&organisation_id=1&year=' + props?.yearSelect)
            .then((res) => {
                if (res.data) {
                    res.data.forEach((ele, index) => {
                        var url = generateSignedURL(ele.form_url);
                        ele['url'] = url;
                    });
                }
                setFormDataList(res.data);
                setIsLoading(false);
                writeToLogFile("Data retrieved successfully...");
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
                writeToLogFile("Error in retrieving data... " + err );
            })
    }

    const editLoadFormData = (value) => {
        writeToLogFile("value...", value);
        value = btoa(value)
        writeToLogFile("value after encryption ", value)
        writeToLogFile("editLoadFormData() call");
        setIsLoading(true);
        writeToLogFile('/get-all-awv-record?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + '&awvId=' + value + '&iSortCol_0=provider_group&sSortDir_0=asc&iDisplayStart=0&iDisplayLength=1&formAttached=0');
        AWVApi.get('/get-all-awv-record?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + '&awv_backend_id=' + btoa(value) + '&iSortCol_0=provider_group&sSortDir_0=asc&iDisplayStart=0&iDisplayLength=1&formAttached=0')
            .then(res => {
                if (res.data && res.data[0]) {
                    setEditFormData(res.data[0]);
                    setIsLoading(false);
                    writeToLogFile("Data retrieved successfully...");
                } else {
                    setEditFormData();
                    setIsLoading(false);
                    writeToLogFile("Error in retrieving data Response..." + res);
                }
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
                writeToLogFile("Error in retrieving data... " + err );
            })
    }

    const getAttachmetTypeData = () => {
        writeToLogFile("getAttachmetTypeData() call");
        setIsLoading(true);
        AWVApi.get('/get-attachment-type')
            .then(res => {
                if (res.data && res.data[0]) {
                    setAttachmetTypeData(res.data);
                    setIsLoading(false);
                    writeToLogFile("Data retrieved successfully...");
                } else {
                    setEditFormData();
                    setIsLoading(false);
                    writeToLogFile("Error in retrieving data Response..." + res);
                }
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
                writeToLogFile("Error in retrieving data... " + err );
            })
    }

    // Delacaring date with formate dd-mm-yyyy
    const [currentDate, setCurrentDate] = useState(new Date().toLocaleDateString());
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDate(new Date().toLocaleDateString());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);
    var date = currentDate.split('/');
    var newDate = date[0].toString().padStart(2, '0') + '-' + date[1].toString().padStart(2, '0') + '-' + date[2];

    const handleFileUpload = (event) => {
        setValue('file_upload', event.target.value);
        setFileName(fileInput?.current?.files[0]?.name);

        if (fileInput?.current && fileInput?.current?.files && fileInput?.current?.files[0]) {

            const headers = {
                'Content-Type': 'application/pdf'
            }
            let path = '';
            const lobFolder = (props?.lobSelect === 'ACA' || props?.lobSelect === '1') ? '/aca' :
            (props?.lobSelect === 'Medicaid' || props?.lobSelect === '3') ? '/medicaid' : 
            '/medicare';

            path = `${process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET}/${'additional_attachment'}${lobFolder}/${props?.yearSelect}`;
            
            AWS.config.update({ region: REGION, credentials: new AWS.Credentials(ACCESS_KEY, SECRET_ACCESS_KEY) });
            //assigning filename into temp then spliting from '.' and passing it to the filekey with the newDate (dd-mm-yyyy).
            var file = fileInput?.current?.files[0];
            var fileNameArray = file.name.split('.');
            var fileExtension = fileNameArray.pop();
            var exa_fileName = fileInput?.current?.files[0]?.name.replaceAll(
                ' ',
                '_'
            );
            exa_fileName = exa_fileName.slice(0, exa_fileName.lastIndexOf('.'));
            const regex = /^[a-zA-Z0-9_.-]+$/;
            if (!regex.test(exa_fileName) || exa_fileName.includes('.')) {
                setFileName('');
                setFormFileUrl('');
                setValue('file_upload', '');
                return toast.error(
                    'File name contains special characters is not uploading'
                );
            }
            var fileKey = exa_fileName + "_" + newDate + "." + fileExtension; // myarr[0] take the exact file name and now doc will save exact file name with timestamp.
            let upload_params = { Bucket: path, Key: fileKey, Body: file, ServerSideEncryption: 'aws:kms', SSEKMSKeyId: process.env.REACT_APP_KeyID, ContentDisposition: "inline" };
            let upload = new AWS.S3.ManagedUpload({ params: upload_params });
            upload.promise(function (err, data) {
                if(err){
                    setFormSubmitisLoading(false)
                    setValue('form_url', '');
                    setFormFileUrl('');
                    return toast.error(`Error uploading file: ${err.message}`);
                }
                setFormFileUrl(data.Location);
                setValue('form_url', data.Location);
                setFormSubmitisLoading(false)
                var formUrl = data.Location;
                var s3 = new AWS.S3({ signatureVersion: "v4" });
                //let Key = fileKey;
                let Key = process.env.REACT_APP_ADDITIONAL_ATTACHMENT + "/" + fileKey;

                s3.setupRequestListeners = (request) => {
                    request.on('build', (req) => {
                        req.httpRequest.headers['x-amz-date'] = process.env.REACT_APP_KeyID;
                    });
                }
                var presignedGETURL = s3.getSignedUrl('getObject', {
                    Bucket: process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET, // S3_BUCKET, //bucket-demo-data-repository
                    Key: Key,
                    Expires: 60
                });
                setFormFileUrl(presignedGETURL);
            });
        }

    }
    const handleFileOpenClick = () => {
        fileInput.current.click();
    }
    const onSubmit = (data) => {
        writeToLogFile("onSubmit() call ");
        //event.preventDefault();
        // return true;
        if (editFormData?.awv_backend_id && editFormData?.awv_backend_id != '') {
            data.form_attachment_type_id = data.form_attachment_type_id.toString();
            data.provider_group = (data.provider_group == '0') ? 'All' : data.provider_group;
            delete data.file_upload;
            if(data?.lob)
              data.lob = data.lob.toString();
            setFormSubmitisLoading(true);
            console.log(data);
            data.form_url = getValues('form_url');
            writeToLogFile("data: " + data);
            if(data?.lob)
              data.lob = data.lob.toString();
              
            writeToLogFile('awv_backend_id before encryption ', data.awv_backend_id)
            data.cin = btoa(data.cin)
            data.awv_backend_id = btoa(data.awv_backend_id)
            data.member_id = btoa(data.member_id)
            AWVApi.post('/create-awe-additional-attachment', data)
                .then(res => {
                    setValue('cin', '');
                    setValue('awv_backend_id', '');
                    setValue('member_id', '');
                    setValue('form_url', '');
                    setValue('form_attachment_type_id', '');
                    setValue('remark', '');
                    setValue('created_by', '');
                    setValue('created_on', '');
                    setFormSubmitisLoading(false);
                    toast.success('Additional attachments add success');
                    notificationRightDrawer(false);
                    loadformDataTable(props.awv_backend_id, props.CinId);
                    props.currentAWERightSidebarCloseDatatableReload(true);
                    writeToLogFile("Data retrieved successfully...");
                    setGenerateLog(true)
                })
                .catch(err => {
                    setFormSubmitisLoading(false);
                    toast.error(err?.response?.data?.message);
                    writeToLogFile("Error in retrieving data... " + err );
                    setGenerateLog(true)
                })
        }
    };

    useEffect(() => {
        // Generate and download log file when logMessages have been updated
        if (logMessages.length > 0  && generateLog === true) {
        LogFileUploadToS3(logMessages, ACCESS_KEY, SECRET_ACCESS_KEY, process.env.REACT_APP_LOG_BUCKET, "Additonal_Attachment")
        }
    }, [ generateLog === true, logMessages, props.yearSelect,props.AwvId, props.lobSelect]); 

    const onClick = (event, url) => {
        try {
            event.preventDefault();
            axios({
                url: url,
                responseType: "arraybuffer",
                method: "GET",
            }).then((res) => {
                window.location.href = url
            }).catch((err) => {
                // alertTitleClasses(err)
                toast.error('Error while downloading the form');
            })
        } catch (error) {
            toast.error('Error while downloading the form');
        }

    }
    return (
        <div className="diagnosis_code_sidebar offcanvas offcanvas-end show" tabIndex={-1} aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header flex_item_cb">
                <h5 id="offcanvasRightLabel" className='fs15'>Additional Attachments</h5>
                <Tooltip title="Close">
                    <IconButton type="button" buttonType={"default"} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => notificationRightDrawer(false)}>
                        <CloseIcon className="w24" />
                    </IconButton>
                </Tooltip>
            </div>
            <Divider />
            <div className="offcanvas-body pt-1 custom-scrollbar overflow-auto">
                <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model" enctype="multipart/form-data">
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Upload File</label>
                        <div className="flex_item_cs gap8">
                            <Button type='button' className={"minw120 px-1"} onClick={handleFileOpenClick}> CHOSE FILE </Button>
                            <Controller
                                name="file_upload"
                                control={control}
                                render={({ field }) => (
                                    <input type="file"  {...field} style={{display:"none"}} className="form-control" ref={fileInput} onChange={handleFileUpload} />
                                )}
                                rules={{
                                    required: true,
                                }}
                            />
                        </div>

                        {errors?.file_upload?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    {fileName && fileName != '' ?
                        <div className="form-group mb20">
                            <h6 className="fs14 mb-1">PDF</h6>
                            <div className=''>
                                {formFileUrl && formFileUrl.includes(".pdf") ?
                                    <a href={formFileUrl} target="_blank" className="flex_item_cs gap8 text-primary fs15 fw600">
                                        <PlusFileIcon className="w24 h24" />
                                        <span>{fileName}</span>
                                    </a>
                                    :
                                    <a href={formFileUrl} target="_blank" download className="flex_item_cs gap8 text-primary fs15 fw600">
                                        <PlusFileIcon className="w24 h24" />
                                        <span>{fileName}</span>
                                    </a>
                                }
                            </div>
                        </div>
                        :
                        <></>
                    }
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Attachment Type</label>
                        <Controller
                            name="form_attachment_type_id"
                            control={control}
                            render={({ field }) => (
                                <select
                                    {...field}
                                    className="form-control"
                                >
                                    <option value={''} >
                                        Select
                                    </option>
                                    {attachmetTypeData && attachmetTypeData.map((row, key) => (
                                        <option key={key} value={row?.id} >
                                            {row?.attachment_name}
                                        </option>
                                    ))}
                                </select>
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.form_attachment_type_id?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">REMARK</label>
                        <Controller
                            name="remark"
                            control={control}
                            render={({ field }) => (
                                <textarea rows={3} {...field} className="form-control" />
                            )}
                            rules={{
                                required: false,
                            }}
                        />
                        {errors?.remark?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group flex_item_cs gap10">
                        <Button type='button' className={"btn-white minw80"} onClick={() => notificationRightDrawer(false)}>
                            CANCEL
                        </Button>
                        <Button type='submit' className={"minw80"} disabled={formSubmitisLoading && formSubmitisLoading == true ? true : false}>
                            SAVE
                        </Button>
                    </div>
                </form>
                {isLoading ?
                    <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        {formDataList && formDataList.length > 0 ?
                            <div className="">
                                <h5 className="fs18 mb10">History</h5>
                                <Divider />
                                <div className="history_card_list">
                                    {formDataList && formDataList.map((element, index) =>
                                        <div key={index} className={'history_card_item status-primary'}>
                                            <p className="fs12 mb-1 fw700">
                                                {element?.url && element?.url.includes(".pdf") ?
                                                    <a onClick={(e) => onClick(e, element?.url)} target="_blank" className="flex_item_cs gap8 text-primary fs14 fw600">
                                                        <PlusFileIcon className="w24 h24" />
                                                        <span>{element?.form_url.split("/").pop()}</span>
                                                    </a>
                                                    :
                                                    <a onClick={(e) => onClick(e, element?.url)} target="_blank" download className="flex_item_cs gap8 text-primary fs14 fw600">
                                                        <PlusFileIcon className="w24 h24" />
                                                        <span>{element?.form_url.split("/").pop()}</span>
                                                    </a>
                                                }
                                            </p>
                                            <p className="fs13 fw500 lh_1_4 mb-1">
                                                <span className="fw700">Attachmet Type : </span>
                                                {attachmetTypeData && attachmetTypeData.map((row, key) => (
                                                    row.id && element.form_attachment_type_id && row.id == element.form_attachment_type_id ?
                                                        <span key={key} >
                                                            {row?.attachment_name}
                                                        </span>
                                                        :
                                                        <></>
                                                ))}
                                            </p>
                                            <p className="fs13 fw500 lh_1_4 mb-1">
                                                <span className="fw700">Remarks : </span>
                                                {element.remark}
                                            </p>
                                            <p className="fs13 fw500 lh_1_4 mb-1">
                                                <span className="fw700">Update On : </span>
                                                {Moment(element.created_on).format('h:mm a, MMMM DD YYYY')}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            :
                            <></>
                        }
                    </>
                }
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(AWVAdditionalAttachments);

