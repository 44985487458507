import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { MenuItem, LinearProgress } from '@mui/material'
import AWVApi from '../../../assets/constants/AWVMemberDetails.RafService.Instance';
import { signOut, currentRoute, currentAWESiteSelect, currentAWEProviderGroupingSelect, currentAWECitySelect, currentAWEAddressSelect, currentAWEZipSelect } from '../../../store/actions';
import history from '../../../history';
import {
    SELECT_YEAR, SELECT_LOB, SELECT_SITE_ID,
    SELECT_PROVIDER_GROUP, PROVIDER_GROUP_LIST
} from "../../../assets/constants/string.constants";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";

const SubHeaderFilter = (props) => {
    const { formState: { errors }, control } = useForm();
    const [selectValue, setSelectValue] = useState('');
    const [siteIdList, setsiteIdList] = useState([]);
    const [providerAddressGroupsDataList, setProviderAddressGroupsDataList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [siteAddressList, setSiteAddressList] = useState([]);
    const [zipList, setZipList] = useState([]);
    const [siteIdSelect, setsiteIdSelect] = useState('');
    const [providerAddressGroupsSelect, setProviderAddressGroupsSelect] = useState([]);
    const [providerAddressGroupsNameSelect, setProviderAddressGroupsNameSelect] = useState([]);
    const [citySelect, setCitySelect] = useState('');
    const [siteAddressSelect, setSiteAddressSelect] = useState('');
    const [zipSelect, setZipSelect] = useState('');
    useEffect(() => {
        if (props.yearSelect && props.providerGroupSelect && props.yearSelect != '' && props.providerGroupSelect != '') {
            loadSiteId();
            loadProviderAddressGroupData();
            loadCity();
            loadSiteAddress();
            loadZip();
        }
    }, [props.yearSelect, props.providerGroupSelect]);

    useEffect(() => {
        loadSiteId();
        loadProviderAddressGroupData();
        loadSiteAddress();
        loadZip();
    }, [citySelect]);

    useEffect(() => {
        loadSiteIdOnlyYear();
    }, [props.yearSelect])

    const handleSiteSelect = (e) => {
        if (e && e != '') {
            setsiteIdSelect(e.site_id);
            props?.setsiteIdSelectValue(e.site_id);
        } else {
            setsiteIdSelect('');
            props?.setsiteIdSelectValue();
        }
    }

    const handleProviderAddressGroupsSelect = (event) => {
        var setProviderSelected = [];
        var setProviderNameSelected = [];
        if (event != '' && event.length > 0) {
            event.map(function (el) {
                setProviderSelected.push(el.ppg_address_grouping.toString());
                setProviderNameSelected.push(el.ppg_address_grouping)
            })
            //Adding '' and send as comman separated value for multiple filter
            setProviderAddressGroupsSelect(setProviderSelected);
            props?.setProviderAddressGroupsSelectValue(setProviderSelected);
            setProviderAddressGroupsNameSelect('\"' + setProviderNameSelected.join('\",\"') + '\"');
            props?.setProviderAddressGroupsNameSelectValue('\"' + setProviderNameSelected.join('\",\"') + '\"');
        }
        else {
            //Adding '' and send as comman separated value for multiple filter
            setProviderAddressGroupsSelect([]);
            props?.setProviderAddressGroupsSelectValue([]);
            setProviderAddressGroupsNameSelect('');
            props?.setProviderAddressGroupsNameSelectValue();
        }
    }

    const handleCitySelect = (e) => {
        if (e && e != '') {
            setCitySelect(e.site_city);
            props?.setCitySelectValue(e.site_city);
        } else {
            setCitySelect('');
            props?.setCitySelectValue();
        }
    }

    const handleAddressSelect = (e) => {
        if (e && e != '') {
            setSiteAddressSelect(e.site_address);
            props?.setSiteAddressSelectValue(e.site_address);
        } else {
            setSiteAddressSelect('');
            props?.setSiteAddressSelectValue();
        }
    }

    const handleZipSelect = (e) => {
        if (e && e != '') {
            setZipSelect(e.site_zip);
            props?.setZipSelectValue(e.site_zip);
        } else {
            setZipSelect('');
            props?.setZipSelectValue();
        }
    }

    const loadSiteId = () => {
        var providerGroupSelectVar = (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL') ? props.providerGroupSelect : "";
        AWVApi.get(`/getsiteid?year=` + props.yearSelect + `&providerGroup=` + providerGroupSelectVar + `&city=` + citySelect)
            .then(res => {
                if (res.data && res.data.length > 0) {
                    setsiteIdList(res.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const loadSiteIdOnlyYear = () => {
        AWVApi.get(`/getsiteid?year=` + props.yearSelect)
            .then(res => {
                if (res.data && res.data.length > 0) {
                    localStorage.setItem(SELECT_SITE_ID, JSON.stringify(res.data))
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const loadProviderAddressGroupData = () => {
        var providerGroupSelectVar = (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL') ? props.providerGroupSelect : '';
        AWVApi.get(`/getProviderAddressGrouping?year=` + props.yearSelect + `&providerGroup=` + providerGroupSelectVar + `&city=` + citySelect)
            .then(res => {
                if (res.data && res.data.length > 0) {
                    setProviderAddressGroupsDataList(res.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const loadCity = () => {
        var providerGroupSelectVar = (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL') ? props.providerGroupSelect : '';
        AWVApi.get(`/getCity?year=` + props.yearSelect + `&providerGroup=` + providerGroupSelectVar)
            .then(res => {
                if (res.data && res.data.length > 0) {
                    setCityList(res.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const loadSiteAddress = () => {
        var providerGroupSelectVar = (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL') ? props.providerGroupSelect : '';
        AWVApi.get(`/getSiteAddress?year=` + props.yearSelect + `&providerGroup=` + providerGroupSelectVar + `&city=` + citySelect)
            .then(res => {
                if (res.data && res.data.length > 0) {
                    setSiteAddressList(res.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const loadZip = () => {
        var providerGroupSelectVar = (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL') ? props.providerGroupSelect : '';
        AWVApi.get(`/getZip?year=` + props.yearSelect + `&providerGroup=` + providerGroupSelectVar + `&city=` + citySelect)
            .then(res => {
                if (res.data && res.data.length > 0) {
                    setZipList(res.data);
                }

            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <div className={"flex_item_cb flex-wrap mb-3 " + props.className}>
            <h6 className="fw700 fs14 flex-shrink-0  text-light-dark-800">{props.title}</h6>
            <div className="right_part">
                <div className="flex_item_cs flex-wrap gap10">
                    {/* <Select
                        displayEmpty
                        className="custom_select mw180 minw180"
                        value={siteIdSelect}
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={(event) => handleSiteSelect(event)}
                    >
                        {siteIdList && siteIdList.length > 0 && siteIdList.map((element, index) =>
                            <MenuItem key={index} value={element.site_id}> {element.site_id} </MenuItem>
                        )}
                    </Select> */}
                    <div>
                        <Controller
                            name="siteId"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    placeholder={'Select Site ID'}
                                    variant="outlined"
                                    className="custom_select mw180 minw180"
                                    classNamePrefix={"custom_select"}
                                    closeMenuOnSelect={true}
                                    isSearchable={true}
                                    isClearable={true}
                                    options={siteIdList}
                                    getOptionLabel={option => `${option.site_id}`}
                                    getOptionValue={option => `${option.site_id}`}
                                    hideSelectedOptions={true}
                                    backspaceRemovesValue={false}
                                    menuPlacement="bottom"
                                    value={siteIdList.filter(({ site_id }) => (site_id == siteIdSelect && siteIdSelect != ''))}
                                    onChange={e => handleSiteSelect(e)}
                                />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.siteId?.type === "required" && <label className="text-danger">This field is required</label>}
                    </div>
                    {/* <Select
                        displayEmpty
                        isMulti
                        className="custom_select mw200 minw200"
                        value={providerAddressGroupsData.filter(obj => props.providerAddressGroupsSelect.includes(obj.ppg_address_grouping))}
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={(event) => handleProviderAddressGroupsSelect(event)}
                    >
                        {providerAddressGroupsDataList && providerAddressGroupsDataList.length > 0 && providerAddressGroupsDataList.map((element, index) =>
                            <MenuItem key={index} value={element.ppg_address_grouping}> {element.ppg_address_grouping} </MenuItem>
                        )}
                    </Select> */}
                    <div>
                        <Controller
                            name="groups"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    className="custom_select mw200 minw200"
                                    classNamePrefix={"custom_select"}
                                    placeholder={'Provider Add Grouping'}
                                    variant="outlined"
                                    isMulti
                                    closeMenuOnSelect={true}
                                    isSearchable={true}
                                    isClearable={true}
                                    options={providerAddressGroupsDataList}
                                    getOptionLabel={option => `${option.ppg_address_grouping}`}
                                    getOptionValue={option => `${option.ppg_address_grouping}`}
                                    hideSelectedOptions={true}
                                    backspaceRemovesValue={false}
                                    menuPlacement="bottom"
                                    value={providerAddressGroupsDataList.filter(obj => providerAddressGroupsSelect.includes(obj.ppg_address_grouping))}
                                    onChange={e => handleProviderAddressGroupsSelect(e)}
                                // menuIsOpen={true}
                                />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.groups?.type === "required" && <label className="text-danger">This field is required</label>}
                    </div>
                    {/* <Select
                        displayEmpty
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        isClearable={true}
                        className="custom_select mw170 minw170"
                        value={citySelect}
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={(event) => handleCitySelect(event)}
                    >
                        {cityList && cityList.length > 0 && cityList.map((element, index) =>
                            <MenuItem key={index} value={element.site_city}> {element.site_city} </MenuItem>
                        )}
                    </Select> */}
                    <div>
                        <Controller
                            name="stateId"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    placeholder={'Select State'}
                                    className="custom_select mw170 minw170"
                                    classNamePrefix={"custom_select"}
                                    variant="outlined"
                                    closeMenuOnSelect={true}
                                    isSearchable={true}
                                    isClearable={true}
                                    options={siteAddressList}
                                    getOptionLabel={option => `${option.site_address}`}
                                    getOptionValue={option => `${option.site_address}`}
                                    hideSelectedOptions={true}
                                    backspaceRemovesValue={false}
                                    menuPlacement="bottom"
                                    value={siteAddressList.filter(({ site_address }) => (site_address == siteAddressSelect && siteAddressSelect != ''))}
                                    onChange={e => handleAddressSelect(e)}
                                />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.stateId?.type === "required" && <label className="text-danger">This field is required</label>}
                    </div>
                    {/* <Select
                        displayEmpty
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        isClearable={true}
                        className="custom_select mw150 minw150"
                        value={siteAddressSelect}
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={(event) => handleAddressSelect(event)}
                    >
                        {siteAddressList && siteAddressList.length > 0 && siteAddressList.map((element, index) =>
                            <MenuItem key={index} value={element.site_address}> {element.site_address} </MenuItem>
                        )}
                    </Select> */}
                    <div>
                        <Controller
                            name="cityId"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    placeholder={'Select City'}
                                    className="custom_select mw150 minw150"
                                    classNamePrefix={"custom_select"}
                                    variant="outlined"
                                    closeMenuOnSelect={true}
                                    isSearchable={true}
                                    isClearable={true}
                                    options={cityList}
                                    getOptionLabel={option => `${option.site_city}`}
                                    getOptionValue={option => `${option.site_city}`}
                                    hideSelectedOptions={true}
                                    backspaceRemovesValue={false}
                                    menuPlacement="bottom"
                                    value={cityList.filter(({ site_city }) => (site_city == citySelect && citySelect != ''))}
                                    onChange={e => handleCitySelect(e)}
                                />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.cityId?.type === "required" && <label className="text-danger">This field is required</label>}
                    </div>
                    {/* <Select
                        displayEmpty
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        isClearable={true}
                        className="custom_select mw170 minw170"
                        value={zipSelect}
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={(event) => handleZipSelect(event)}
                    >
                        {zipList && zipList.length > 0 && zipList.map((element, index) =>
                            <MenuItem key={index} value={element.site_zip}> {element.site_zip} </MenuItem>
                        )}
                    </Select> */}
                    <div>
                        <Controller
                            name="zipId"
                            control={control}
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    placeholder={'Select Zip Code'}
                                    className="custom_select mw170 minw170"
                                    classNamePrefix={"custom_select"}
                                    variant="outlined"
                                    closeMenuOnSelect={true}
                                    isSearchable={true}
                                    isClearable={true}
                                    options={zipList}
                                    getOptionLabel={option => `${option.site_zip}`}
                                    getOptionValue={option => `${option.site_zip}`}
                                    hideSelectedOptions={true}
                                    backspaceRemovesValue={false}
                                    menuPlacement="bottom"
                                    value={zipList.filter(({ site_zip }) => (site_zip == zipSelect && zipSelect != ''))}
                                    onChange={e => handleZipSelect(e)}
                                />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.zipId?.type === "required" && <label className="text-danger">This field is required</label>}
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        userName: state.auth?.userDetails?.userEmail,
        route: state.route.route,
        moduleSelect: state.moduleFilter.moduleSelect,
        yearSelect: state.moduleFilter.yearSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        aweCitySelect: state.moduleFilter.aweCitySelect,
    };
}
export default connect(mapStateToProps, { signOut, currentRoute, currentAWESiteSelect, currentAWEProviderGroupingSelect, currentAWECitySelect, currentAWEAddressSelect, currentAWEZipSelect })(SubHeaderFilter);
