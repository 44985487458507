import PropTypes from "prop-types";
import {SetMeInitialSelect} from '../../assets/constants/meInitialList';
import { currentMeInitialSelect } from '../../store/actions';
import { connect } from 'react-redux';

const MeInitialSelect = (props) => {

    const {MeInitialList, meInitialSelect} = props;
    props.currentMeInitialSelect(meInitialSelect);

    const handleSelect = (e) =>{
        SetMeInitialSelect(e.target.value);        
        props.currentMeInitialSelect(e.target.value);
        props.onMeInitialSelect(e.target.value);
    }

    return(
        <div className="d-flex justify-content-end">
            <div className="btn-group">
            <select className="btn btn-info dropdown-toggle btn" value={meInitialSelect} onChange={handleSelect}>
                {
                    MeInitialList && MeInitialList.map(el => <option value={el} key={el}> {el} </option>)
                }
            </select>
            </div>
        </div>
    )
}

MeInitialSelect.propTypes ={
    MeInitialList : PropTypes.array,
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
    };
  } 
export default connect(mapStateToProps, {currentMeInitialSelect})(MeInitialSelect);