import React, { useRef, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../store/actions';
import AWVCoderWorkBench from '../../assets/constants/AWVCoderWorkBenchRafservice.Instance';
import Divider from '@mui/material/Divider';
import Moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import { CircularProgress } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { styled } from '@mui/material/styles';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Autocomplete from "@mui/material/Autocomplete";
//import CWB_icd_hcc_json from "../../Pages/config/CWB_icd_hcc_crosswalk.config.json";

const EditCWDXCoderWorkBench = (props) => {
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [formDataList, setFormDataList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
    const [editFormData, setEditFormData] = useState({});
    const [dxCodeDataArray, setDxCodeDataArray] = useState([]);
    const [dxCodeDataArrayPush, setDxCodeDataArrayPush] = useState([]);
    const [dxCodeData, setDxCodeData] = useState([]);
    const [isdxCodeValue, setIsDxCodeValues] = useState(false);
    const [dxCodeValue, setDxCodeValues] = useState();
    const [dxCodeDOSBegin, setDxCodeDOSBegin] = useState();
    useEffect(() => {
        console.log(errors);
    }, [errors]);

    useEffect(async () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const cin = urlParams.get('cin');
        setValue('IH_MEMBER_ID', cin);
        //setValue('DOS_Begin', Moment().format("YYYY-MM-DD"));
        //setDxCodeDOSBegin(Moment().format("YYYY-MM-DD"));
        // await loadformDxCode();
        /*
        const dataArray = await Object.entries(CWB_icd_hcc_json).map(([key, value]) => {
            return { dx_code: key, ...value };
        });
        let valuesArray = [];
        await dataArray.forEach((element, index) => {
            if (!valuesArray.includes(element.dx_code + '-' + element.icd_code_description)) {
                valuesArray.push(element.dx_code + '-' + element.icd_code_description);
            }
        });
        await setDxCodeDataArray(dataArray);
        await setDxCodeDataArrayPush(valuesArray);
        await loadDxCodeData(valuesArray, '');*/
    }, []);

    useEffect(() => {
        if (props?.Id) {
            loadformDataTable(props.Id);
            editLoadFormData(props.Id);
        }
    }, [props?.Id]);

    useEffect(() => {
        if (props?.Id && editFormData?.ID && editFormData?.ID != '') {
            setValue('PAGE_NO', editFormData?.PAGE_NO);
            setValue('IH_MEMBER_ID', editFormData?.IH_MEMBER_ID);
            setValue('DX_CODE', editFormData?.DX_CODE);
            setValue('ENTITY_ID', editFormData?.ENTITY_ID);
            setValue('ENTITY_TEXT', editFormData?.ENTITY_TEXT);
            setValue('REMARKS', editFormData?.REMARKS);
            setValue('DOS_Begin', Moment(editFormData?.DOS_Begin).format("YYYY-MM-DD"));
            setValue('DOS_End', Moment(editFormData?.DOS_End).format("YYYY-MM-DD"));
            setDxCodeDOSBegin(Moment(editFormData?.DOS_Begin).format("YYYY-MM-DD"));
            setDxCodeValues(editFormData?.DX_CODE);
            setIsDxCodeValues(true);

        }
    }, [editFormData]);

    const notificationRightDrawer = (open, valueId = '') => {
        props.currentAWERightSidebarType({ 'type': 'notification' });
        props.currentAWERightSidebar(open);
    };

    const loadformDxCode = () => {
        setIsLoading(true);
        AWVCoderWorkBench.get('data/icd_code') //'&provider_group=' + props?.providerGroupSelect
            .then(res => {
                console.log(res.data);
                setDxCodeDataArray(res.data);
                let valuesArray = [];
                res.data.forEach((element, index) => {
                    if (!valuesArray.includes(element.icd_code + '-' + element.icd_code_description)) {
                        valuesArray.push(element.icd_code + '-' + element.icd_code_description);
                    }
                });
                setDxCodeDataArrayPush(valuesArray);
                loadDxCodeData(valuesArray, '');
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    }

    //load DX codes from JSON
    const handleChangeDxCode = (eventValue) => {
        console.log(eventValue);
        const result = dxCodeDataArray.find((element) => element.icd_code + '-' + element.icd_code_description === eventValue);
        setValue('DX_CODE', eventValue);
        setDxCodeValues(eventValue);
        if (result) {
            setIsDxCodeValues(true);
            if (result?.hcc_v24_description != '') {
                setValue('HCC_v24', result?.hcc_v24 + '-' + result?.hcc_v24_description);
            } else {
                setValue('HCC_v24', result?.hcc_v24);
            }

            if (result?.hcc_v28_description != '') {
                setValue('HCC_V28', result?.hcc_v28 + '-' + result?.hcc_v28_description);
            } else {
                setValue('HCC_V28', result?.hcc_v28);
            }


            if (result?.rxhcc_v05_description != '') {
                setValue('RXHCC_v05', result?.rxhcc_v05 + '-' + result?.rxhcc_v05_description);
            } else {
                setValue('RXHCC_v05', result?.rxhcc_v05);
            }
            if (result?.rxhcc_v08_description != '') {
                setValue('RXHCC_v08', result?.rxhcc_v08 + '-' + result?.rxhcc_v08_description);
            } else {
                setValue('RXHCC_v08', result?.rxhcc_v08);
            }
        }
        setDxCodeData([]);
    }

    const handleInputChangeDxCode = (eventValue) => {
        setIsDxCodeValues(false);
        if (eventValue.length >= 3) {

        } else {
            setDxCodeDataArray([]);
            let valuesArray = [];
            setValue('DX_CODE', eventValue);
            setDxCodeValues(eventValue);
            loadDxCodeData(valuesArray, eventValue);
            setIsLoading(false);

            setValue('DX_CODE', eventValue);
            setDxCodeValues(eventValue);
            return true;
        }
        setIsLoading(true);
        console.log(eventValue);
        var data = { 'search': eventValue };
        AWVCoderWorkBench.post('/data/icd_code', data)
            .then(res => {
                setDxCodeDataArray(res.data);
                let valuesArray = [];
                res.data.forEach((element, index) => {
                    if (!valuesArray.includes(element.icd_code + '-' + element.icd_code_description)) {
                        valuesArray.push(element.icd_code + '-' + element.icd_code_description);
                    }
                });
                setValue('DX_CODE', eventValue);
                setDxCodeValues(eventValue);
                loadDxCodeData(valuesArray, eventValue);
                setIsLoading(false);
            })
            .catch(err => {
                setDxCodeDataArray([]);
                let valuesArray = [];
                setValue('DX_CODE', eventValue);
                setDxCodeValues(eventValue);
                loadDxCodeData(valuesArray, eventValue);
                setIsLoading(false);
            })

    }

    const loadDxCodeData = (dxCodeDataArrayPush, dxCodeValue) => {
        let values = [];
        if (dxCodeDataArrayPush && dxCodeDataArrayPush.length > 0 && dxCodeValue) {
            dxCodeDataArrayPush.forEach((element, index) => {
                if (!values.includes(element) && ((element.toLowerCase()).includes(dxCodeValue.toLowerCase()))) {
                    values.push(element);
                }
            });
            setDxCodeData(values);
        }else{
            setDxCodeData(values);
        }
    };

    const editLoadFormData = (id) => {
        setIsLoading(true);
        AWVCoderWorkBench.get('data/cw_dx/' + id)
            .then(res => {
                console.log(res.data);
                if (res.data && res.data) {
                    setEditFormData(res.data);
                    setIsLoading(false);
                } else {
                    setEditFormData();
                    setIsLoading(false);
                }
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
            })
    }

    const loadformDataTable = (id) => {
        setIsLoading(true);
        AWVCoderWorkBench.get('data/cwb/history/cw_dx/' + id) //'&provider_group=' + props?.providerGroupSelect
            .then(res => {
                console.log(res.data);
                setFormDataList(res.data);
                // setFormDataList([
                //     {
                //         'created_on': '11 Jan 2023',
                //         'notes': 'test2',
                //         'created_by': 'test',
                //     },
                //     {
                //         'created_on': '11 Jan 2023',
                //         'notes': 'test2',
                //         'created_by': 'test2',
                //     },
                // ]);
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    }


    const onSubmit = (data) => {
        console.log(data);
        let controlUser = JSON.parse(localStorage.getItem('user'));
        //data.LAST_UPDATED_ON = Moment().format('YYYY-MM-DD HH:mm:ss');
        data.LAST_UPDATED_BY = controlUser?.userEmail;
  
        if (props?.Id) {
            var apiUrl = 'data/cw_dx/' + props.Id;
        } else {
            var apiUrl = 'data/cw_dx/save';
            //data.CREATED_ON = Moment().format('YYYY-MM-DD HH:mm:ss');
            data.CREATED_BY = controlUser?.userEmail;
        }
        if(isdxCodeValue && dxCodeValue){

        }else{
            data.DX_CODE = "";
            // if(dxCodeValue && dxCodeValue != ''){
                toast.error('Please enter a valid DX CODE value.');
                return true;
            // }
        }
        data.PAGE_NO = data.PAGE_NO ? data.PAGE_NO : "";
        data.ENTITY_ID = data.ENTITY_ID ? data.ENTITY_ID : "";
        data.ENTITY_TEXT = data.ENTITY_TEXT ? data.ENTITY_TEXT : "";
        data.DOS_Begin = Moment(data.DOS_Begin).format("YYYY-MM-DD");
        data.DOS_End = Moment(data.DOS_End).format("YYYY-MM-DD");
        setFormSubmitisLoading(true);
        AWVCoderWorkBench.post(apiUrl, data)
            .then(res => {
                setValue('ID', '');
                setValue('PAGE_NO', '');
                setValue('IH_MEMBER_ID', '');
                setValue('DX_CODE', '');
                setValue('ENTITY_ID', '');
                setValue('ENTITY_TEXT', '');
                setValue('REMARKS', '');
                setValue('LAST_UPDATED_ON', '');
                setValue('LAST_UPDATED_BY', '');
                setValue('DOS_Begin');
                setValue('DOS_End');
                setFormSubmitisLoading(false);
                if (props?.Id) {
                    toast.success('Updated successfully');
                } else {
                    toast.success('Created successfully');
                }
                props.currentAWERightSidebarCloseDatatableReload(true);
                notificationRightDrawer(false);
            })
            .catch(err => {
                setFormSubmitisLoading(false);
                toast.error(err?.response?.data?.message);
            })
    }

    const handleChangeDate = (event) => {
        if (event?.target?.name == 'DOS_Begin') {
            setValue('DOS_Begin', event?.target?.value);
            setValue('DOS_End', '');
            setDxCodeDOSBegin(event?.target?.value);
        }
    }

    return (
        <div style={{ padding: "10px 0px" }}>
            <div key={'index'} variant={'head'} style={{ width: '350px', padding: "10px", height: "50px" }}>
                <div class="float">
                    <div class="float-left">
                        <span><b>{(props?.Id) ? 'Edit' : 'Add'}  CW DX Coder Workbench</b></span>
                    </div>
                    <div class="float-right">
                        <Tooltip title="Close">
                            <CancelIcon style={{ color: "#1A9698", cursor: "pointer" }} onClick={() => notificationRightDrawer(false)} />
                        </Tooltip>
                    </div>
                </div>
            </div>
            <Divider />
            <div>
                <div class="awv-recored-right-sidebar-form mt-3">
                    <div variant={'head'} style={{ width: '350px' }}>
                        <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                            <div className="col-lg-12 align-items-center mb-3">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>MEMBER ID</b></InputLabel>
                                <FormControl fullWidth margin="dense" variant="outlined" className="mt-1" style={{ 'min-width': '70px' }}>
                                    <Controller
                                        className="input-control"
                                        name="IH_MEMBER_ID"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField {...field} variant="outlined" InputProps={{
                                                readOnly: true,
                                            }} />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.IH_MEMBER_ID?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>
                            <div className="col-lg-12 align-items-center mb-3 custom-autocomplete">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>DX CODE</b></InputLabel>
                                <FormControl
                                    fullWidth
                                    margin="dense"
                                    variant="outlined"
                                    style={{ "min-width": "200px" }}
                                >
                                    <Controller
                                        className="input-control"
                                        name="DX_CODE"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                variant="outlined"
                                                value={dxCodeValue}
                                                onChange={(event) => handleInputChangeDxCode(event?.target?.value)}
                                                placeholder="Type to search..."
                                                autoComplete="off"
                                            />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    <ul className="options">
                                        {dxCodeData.map((option, index) => (
                                            <li key={index} onClick={() => handleChangeDxCode(option)}>
                                                {option}
                                            </li>
                                        ))}
                                    </ul>
                                    {errors?.DX_CODE?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>
                            {/* <div className="col-lg-12 align-items-center mb-3">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>PAGE NO</b></InputLabel>
                                <FormControl fullWidth margin="dense" className="mt-1" variant="outlined" style={{ 'min-width': '70px' }}>
                                    <Controller
                                        className="input-control"
                                        name="PAGE_NO"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField {...field} variant="outlined" />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.PAGE_NO?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>
                            <div className="col-lg-12 align-items-center mb-3">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>ENTITY ID</b></InputLabel>
                                <FormControl fullWidth margin="dense" variant="outlined" className="mt-1" style={{ 'min-width': '100px' }}>
                                    <Controller
                                        className="input-control"
                                        name="ENTITY_ID"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField {...field} variant="outlined" />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.ENTITY_ID?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>
                            <div className="col-lg-12 align-items-center mb-3">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>ENTITY TEXT</b></InputLabel>
                                <FormControl fullWidth margin="dense" variant="outlined" className="mt-1" style={{ 'min-width': '100px' }}>
                                    <Controller
                                        className="input-control"
                                        name="ENTITY_TEXT"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField multiline rows={3} {...field} variant="outlined" />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.ENTITY_TEXT?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div> */}

                            <div className="col-lg-12 align-items-center mb-3">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>DOS START</b></InputLabel>
                                <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                    {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                    <Controller
                                        className="input-control"
                                        name="DOS_Begin"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField type="date" {...field} variant="outlined" onChange={handleChangeDate} />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.DOS_Begin?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>
                            <div className="col-lg-12 align-items-center mb-3">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>DOS END</b></InputLabel>
                                <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                    {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                    <Controller
                                        className="input-control"
                                        name="DOS_End"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField type="date" {...field} variant="outlined" inputProps={{ min: Moment(dxCodeDOSBegin).format("YYYY-MM-DD") }} />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.DOS_End?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>

                            <div className="col-lg-12 align-items-center mb-3">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>REMARKS</b></InputLabel>
                                <FormControl fullWidth margin="dense" variant="outlined" className="mt-1" style={{ 'min-width': '100px' }}>
                                    <Controller
                                        className="input-control"
                                        name="REMARKS"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField multiline rows={3} {...field} variant="outlined" />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.REMARKS?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>
                            <div className="col-lg-12 mt-3 mb-3">
                                <Button type='button' variant="contained" color="grey" onClick={() => notificationRightDrawer(false)}>CANCEL</Button>
                                <Button type='submit'
                                    className={'mr-2 ml-2 btn-custom-primary'}
                                    variant="contained"
                                    disabled={formSubmitisLoading && formSubmitisLoading == true ? true : false}
                                >
                                    Save
                                </Button>
                            </div>
                        </form>
                    </div>
                    {isLoading ?
                        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                            <CircularProgress />
                        </div>
                        :
                        <>
                            {formDataList && formDataList.length > 0 ?
                                <>
                                    <div class="row col-lg-12 mt-5 pl-4">
                                        <div class="float">
                                            <div class="float-left">
                                                <span style={{ fontSize: "20px" }}><b>History</b></span>
                                            </div>
                                        </div>
                                    </div>
                                    <Divider />
                                    {formDataList && formDataList.map((element, index) =>
                                        <div key={'index'} variant={'head'} style={{ width: '350px', padding: "10px" }}>
                                            <div className={
                                                element.form_status === 'Accepted' ? 'card p-2 history-custom-primary' :
                                                    element.form_status === 'Rejected' ? 'card p-2 history-custom-danger' :
                                                        element.form_status === 'No Action' ? 'card p-2 history-custom-warning' :
                                                            'card p-2 history-custom-primary'
                                            }>
                                                <span className="mb-1" style={{ fontSize: "11px" }}>
                                                    <b>{Moment(element.created_on).format('h:mm a, MMMM DD YYYY')}</b>
                                                </span>
                                                <span style={{ fontSize: "11px", lineHeight: "1rem" }}>
                                                    <b>Status:- </b>{element.form_status}
                                                </span>
                                                <span style={{ fontSize: "11px", lineHeight: "1rem" }}>
                                                    <b>Remarks:- </b>{element.remarks}
                                                </span>
                                                <div>
                                                    <span className="mr-1" style={{ color: "#777777", fontSize: "11px" }}>
                                                        Last updated by:
                                                    </span>
                                                    <span style={{ color: "#1a9698", fontSize: "11px" }}>
                                                        <b>  {element.user_id}</b>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                                :
                                <></>
                            }
                        </>
                    }
                </div>
            </div>
        </div >
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(EditCWDXCoderWorkBench);

