import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import WidgetCard from "../../components/Cards/WidgetCard";
import SingleVerticalBarChart from '../../components/Charts/SingleVerticalBar.Chart';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag, hccSeperator, numberWithCommas, numberWithCommasPrefixDolar } from '../../assets/constants/customRender'
import history from "../../history";
import { MEMBER_ID, ISSUER_ID, HCC, WIDGET_TYPES, REPORTED_INSIGHTS, MEMBER_NAME, CARRIER_ID, PLAN_ID, GENDER, MAO_RISK_CONDITION_CHART,MAO_Chart_Review_Member, MAO_MEMBER_SELECT_TAB } from "../../assets/constants/string.constants";
import { percentFormat } from "../../assets/helpers/formats.helpers";
import CardComponent from "../../components/Cards/card";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import SubHeader from '../../components/home/header/subHeader';
import Link from '@material-ui/core/Link';
import { connect } from 'react-redux';
import GridTable from "../../components/TableGrids/GridTable.table";
import GChart from '../../components/GChart/GChart';
import { getMeInitialSelect} from '../../assets/constants/meInitialList';
//import google from "react-google-charts";

const HighNeedUtilizationHcc = (props) => {
    const [hccCode, setHccCode] = useState(localStorage.getItem(HCC));
    const [utilizationCountChartData, setUtilizationCountChartData] = useState([]);
    const [utilizationMemberCount, setUtilizationMemberCount] = useState({});
    const [wellnessVisitstableData, setWellnessVisitstableData] = useState([]);
    const [wellnessVisitsChartData, setWellnessVisitsChartData] = useState([]); 
    const [erVisitstableData, setErVisitstableData] = useState([]);
    const [erVisitsChartData, setErVisitsChartData] = useState([]); 
    const [admitsVisitstableData, setAdmitsVisitstableData] = useState([]);
    const [admitsVisitsChartData, setAdmitsVisitsChartData] = useState([]);
 
    const  memberMedicareCols =[
        {
            name: "SERVICE_CATEGORY",
            label: "SERVICE",
        },
        {
            name: "SUBCATEGORY",
            label: "Description",     
        },
        {
            name: "VISITS_PER_1000",
            label: "VISITS/1000",   
            options:numberWithCommas
        },
        {
            name: "COST_PER_1000",
            label: "COST/1000",   
        },
        {
            name: "PERCENT_OF_POPULATION",
            label: "% of Population Used the Service",     
        },
        
    ]

    const clickToConditionLink = () => {
        history.push('/layout/medicare/high_cost_member');
    }

    const loadUtilizationGraph = () => {
        let chartDataRow = [];         
            chartDataRow.push(["Wellness Visits", "Utilization Count", { role: 'annotation' }]);
            api.get(`/suHccSummaryView/${props.yearSelect}/hcc/${hccCode}`)
            .then(res => {
                if(res.length > 0){
                    setUtilizationMemberCount({
                        'no_of_members': res[0].no_of_members,
                        'percent_of_increase_from_previous_year': res[0].percent_of_increase_from_previous_year,
                        'no_of_members_per_1000': res[0].no_of_members_per_1000,
                        'variance_from_benchmark': res[0].variance_from_benchmark,
                    });
                   
                    chartDataRow.push(['Reported', res[0].pmpy_utilization, res[0].pmpy_utilization]);
                    chartDataRow.push(['Benchmark', res[0].pmpy_benchmark, res[0].pmpy_benchmark]);
                    chartDataRow.push(['Comparison', res[0].comparison_pmpy_utilization, res[0].comparison_pmpy_utilization]);
                }else{
                    setUtilizationMemberCount({})
                }          
                setUtilizationCountChartData(chartDataRow);
            })
            .catch(err => {
                // console.log(err);
            })

    }
   
    const loadUtilizationTableGraph = () => {
        let tableDataWellnessRow = [];         
        let tableDataERRow = [];         
        let tableDataAdmitsRow = [];         
        let chartDataWellnessRow = [];         
        let chartDataERRow = [];         
        let chartDataAdmitsRow = [];         
            chartDataWellnessRow.push(["Wellness Visits", "Visits per 1000", { role: 'annotation' }]);
            chartDataERRow.push(["ER Visits", "Visits per 1000", { role: 'annotation' }]);
            chartDataAdmitsRow.push(["Admits Visits", "Visits per 1000", { role: 'annotation' }]);
            api.get(`/utilizationGraph/${props.yearSelect}/hcc/${hccCode}`)
            .then(res => {
               
                if(res.length > 0){                   
                    tableDataAdmitsRow.push(['Reported', res[0].reporting_per_1000, '--']);
                    tableDataAdmitsRow.push(['Benchmark', res[0].benchmark_per_1000, res[0].benchmark_change]);
                    tableDataAdmitsRow.push(['Comparison', res[0].comparison_per_1000, res[0].comparison_change]);

                    chartDataAdmitsRow.push(['Reported', res[0].visits_per_1000, res[0].visits_per_1000]);
                    chartDataAdmitsRow.push(['Benchmark', res[0].benchmark_visits_per_1000, res[0].benchmark_visits_per_1000]);
                    chartDataAdmitsRow.push(['Comparison', res[0].comparison_visits_per_1000, res[0].comparison_visits_per_1000]);
                }
            
                if(res.length > 1){                    
                    tableDataERRow.push(['Reported', res[1].reporting_per_1000, '--']);
                    tableDataERRow.push(['Benchmark', res[1].benchmark_per_1000, res[1].benchmark_change]);
                    tableDataERRow.push(['Comparison', res[1].comparison_per_1000, res[1].comparison_change]);

                    chartDataERRow.push(['Reported', res[1].visits_per_1000, res[1].visits_per_1000]);
                    chartDataERRow.push(['Benchmark', res[1].benchmark_visits_per_1000, res[1].benchmark_visits_per_1000]);
                    chartDataERRow.push(['Comparison', res[1].comparison_visits_per_1000, res[1].comparison_visits_per_1000]);
                }
            
                if(res.length > 2){                    
                    tableDataWellnessRow.push(['Reported', res[2].reporting_per_1000, '--']);
                    tableDataWellnessRow.push(['Benchmark', res[2].benchmark_per_1000, res[2].benchmark_change]);
                    tableDataWellnessRow.push(['Comparison', res[2].comparison_per_1000, res[2].comparison_change]);

                    chartDataWellnessRow.push(['Reported', res[2].visits_per_1000, res[2].visits_per_1000]);
                    chartDataWellnessRow.push(['Benchmark', res[2].benchmark_visits_per_1000, res[2].benchmark_visits_per_1000]);
                    chartDataWellnessRow.push(['Comparison', res[2].comparison_visits_per_1000, res[2].comparison_visits_per_1000]);
                }

                setWellnessVisitstableData(tableDataWellnessRow)
                setWellnessVisitsChartData(chartDataWellnessRow);
                setErVisitsChartData(chartDataERRow);
                setErVisitstableData(tableDataERRow);
                setAdmitsVisitsChartData(chartDataAdmitsRow);
                setAdmitsVisitstableData(tableDataAdmitsRow);
            })
            .catch(err => {
                // console.log(err);
            })

    }

    useEffect(() => {      
        loadUtilizationGraph();
        loadUtilizationTableGraph();
    },  [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect()
    ]);

    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Chronic Condition Dashboard'} selectType={'utilization'}/>
                        </div>
                    </div>
                </div>
                <div className='col-lg-12 pr-0 pl-0'>
                    <CardComponent
                        heading={`CHRONIC CONDITION DASHBOARD - ACUTE MYOCARDIAL INFARCTION : YEAR - ${props.yearSelect}`}
                        body={
                            <div className='row col-lg-12 pr-0 pl-0'>
                                <div class="col-lg-6  mb-3">
                                    <CardComponent
                                        heading={``}
                                        body={                               
                                            <div>
                                                <div className="row">
                                                    <div style={{'float': 'left', 'text-align': 'center', 'padding-top': '30px', 'padding-bottom': '20px', 'width': '50%'}} class="ng-binding">
                                                        <span onClick={()=>clickToConditionLink()} style={{"color": "blue", "cursor":"pointer", "font-weight": "bold"}} class="ng-binding">
                                                            {utilizationMemberCount.no_of_members}
                                                        </span> <br />
                                                        # of Members with Acute Myocardial Infarction <br />
                                                        (1.21 % of population )
                                                    </div>
                                                    <div style={{'float': 'left', 'text-align': 'center', 'padding-top': '30px', 'padding-bottom': '40px', 'width': '50%'}}>
                                                        <span style={{"color": "black", "font-weight": "bold"}} class="ng-binding">
                                                            <span class="glyphicon glyphicon-triangle-bottom"></span> 
                                                            {Math.abs(utilizationMemberCount.percent_of_increase_from_previous_year)}
                                                            %</span> <br /> From Comparison <br /> per 1000
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <div  style={{'float': 'left', 'text-align': 'center', 'padding-top': '30px', 'padding-bottom': '20px', 'width': '50%'}} class="ng-binding">
                                                        <span style={{"color": "blue", "font-weight": "bold"}} class="ng-binding">
                                                            {utilizationMemberCount.no_of_members_per_1000}
                                                        </span>
                                                        <br /> # of Members with Acute Myocardial Infarction <br /> per 1000
                                                    </div>
                                                    <div style={{'float': 'left', 'text-align': 'center', 'padding-top': '30px', 'padding-bottom': '20px', 'width': '50%'}}class="ng-binding">
                                                        <span style={{"color": "black", "font-weight": "bold"}} class="ng-binding">
                                                            <span class="glyphicon glyphicon-triangle-bottom"></span> 
                                                            {Math.abs(utilizationMemberCount.variance_from_benchmark)}
                                                            % </span><br /> From Benchmark <br />
                                                        (141 per 1000 )
                                                    </div>
                                                </div>
                                            </div>
                                    }></CardComponent>
                                </div>
                                <div class="col-lg-6  mb-3">
                                    <CardComponent
                                        heading={``}
                                        body={                               
                                            <div style={{height:'330px'}}>
                                                <GChart data={utilizationCountChartData} chartType="ColumnChart" height="320px" width="100%" hAxisTitle={""} yAxistitle={"Utilization Count"}></GChart>
                                            </div>
                                    }></CardComponent>
                                </div>
                            </div>
                        }>
                    </CardComponent>
                </div>
                <div className='col-lg-12 pr-0 pl-0 mt-5'>
                    <CardComponent
                        heading={`Wellness Visits per 1000`}
                        body={
                            <div className='row col-lg-12 pr-0 pl-0'>
                                <div class="col-lg-6 mb-3">
                                    <CardComponent
                                        heading={``}
                                        body={                               
                                            <div style={{height:'330px'}}>
                                                <GChart data={wellnessVisitsChartData} chartType="BarChart" height="320px" width="100%" hAxisTitle={"Visits per 1000"} yAxistitle={"Utilization Count"}></GChart>
                                            </div>
                                    }></CardComponent>
                                </div>
                                <div class="col-lg-6  mb-3">
                                    <CardComponent
                                        heading={``}
                                        body={                               
                                            <div className="box-height-chart">
                                                <div className="MuiPaper-root MUIDataTable-paper-284 pb-5 MuiPaper-elevation4 MuiPaper-rounded">
                                                    <div className="MUIDataTableFilterList-root-295"></div>
                                                    <div className="MUIDataTable-responsiveStacked-291" style={{"position": "relative", "max-height": "none", "height": "auto"}}>
                                                        <table role="grid" className="MuiTable-root MUIDataTable-tableRoot-286" tabindex="0">
                                                            <caption className="MUIDataTable-caption-293"></caption>
                                                            <thead className="MuiTableHead-root MUIDataTableHead-responsiveStacked-298 MUIDataTableHead-main-297">
                                                                <tr className="MuiTableRow-root MUIDataTableHeadRow-root-301 MuiTableRow-head">
                                                                    <th className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-313 MUIDataTableHeadCell-fixedHeader-314" scope="col" data-colindex="0">
                                                                        <span role="button" className="MUIDataTableHeadCell-toolButton-322" data-testid="headcol-0" tabindex="0">
                                                                            <div className="MUIDataTableHeadCell-sortAction-318">
                                                                                <div className="MUIDataTableHeadCell-data-317">Wellness Visits </div>
                                                                            </div>
                                                                        </span>
                                                                    </th>
                                                                    <th className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-313 MUIDataTableHeadCell-fixedHeader-314" scope="col" data-colindex="1">
                                                                        <span role="button" className="MUIDataTableHeadCell-toolButton-322" data-testid="headcol-1" tabindex="0">
                                                                            <div className="MUIDataTableHeadCell-sortAction-318" title="Sort">
                                                                                <div className="MUIDataTableHeadCell-data-317">Cost per 1000</div>
                                                                            </div>
                                                                        </span>
                                                                    </th>
                                                                    <th className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-313 MUIDataTableHeadCell-fixedHeader-314" scope="col" data-colindex="2">
                                                                        <span role="button" className="MUIDataTableHeadCell-toolButton-322" data-testid="headcol-2" tabindex="0">
                                                                            <div className="MUIDataTableHeadCell-sortAction-318" title="Sort">
                                                                                <div className="MUIDataTableHeadCell-data-317">% change of Reported</div>
                                                                            </div>
                                                                        </span>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="MuiTableBody-root">
                                                            {wellnessVisitstableData && wellnessVisitstableData.map((element, i) => {
                                                                return (
                                                                    <tr key={i} className="MuiTableRow-root MUIDataTableBodyRow-root-329 undefined MUIDataTableBodyRow-responsiveStacked-331 MUIDataTableBody-lastStackedCell-327 MuiTableRow-hover" data-testid="MUIDataTableBodyRow-0" id="MUIDataTableBodyRow-0">
                                                                        <td className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                                                {element[0]}
                                                                            </div>
                                                                        </td>
                                                                        <td className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                                                {'$'+ Number(element[1]).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                            </div>
                                                                        </td>
                                                                        <td className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                                                {element[2]}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                                })
                                                            }    
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="MUIDataTable-liveAnnounce-294" aria-live="polite"></div>
                                                </div>
                                            </div>
                                    }></CardComponent>
                                </div>
                            </div>
                        }>
                    </CardComponent>
                </div>
                <div className='col-lg-12 pr-0 pl-0 mt-5'>
                    <CardComponent
                        heading={`ER Visits per 1000`}
                        body={
                            <div className='row col-lg-12 pr-0 pl-0'>
                                <div class="col-lg-6 mb-3">
                                    <CardComponent
                                        heading={``}
                                        body={                               
                                            <div style={{height:'330px'}}>
                                                <GChart data={erVisitsChartData} chartType="BarChart" height="320px" width="100%" hAxisTitle={"Visits per 1000"} yAxistitle={"Utilization Count"}></GChart>
                                            </div>
                                    }></CardComponent>
                                </div>
                                <div class="col-lg-6  mb-3">
                                    <CardComponent
                                        heading={``}
                                        body={                               
                                            <div className="box-height-chart">
                                                <div className="MuiPaper-root MUIDataTable-paper-284 pb-5 MuiPaper-elevation4 MuiPaper-rounded">
                                                    <div className="MUIDataTableFilterList-root-295"></div>
                                                    <div className="MUIDataTable-responsiveStacked-291" style={{"position": "relative", "max-height": "none", "height": "auto"}}>
                                                        <table role="grid" className="MuiTable-root MUIDataTable-tableRoot-286" tabindex="0">
                                                            <caption className="MUIDataTable-caption-293"></caption>
                                                            <thead className="MuiTableHead-root MUIDataTableHead-responsiveStacked-298 MUIDataTableHead-main-297">
                                                                <tr className="MuiTableRow-root MUIDataTableHeadRow-root-301 MuiTableRow-head">
                                                                    <th className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-313 MUIDataTableHeadCell-fixedHeader-314" scope="col" data-colindex="0">
                                                                        <span role="button" className="MUIDataTableHeadCell-toolButton-322" data-testid="headcol-0" tabindex="0">
                                                                            <div className="MUIDataTableHeadCell-sortAction-318">
                                                                                <div className="MUIDataTableHeadCell-data-317">ER Visits </div>
                                                                            </div>
                                                                        </span>
                                                                    </th>
                                                                    <th className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-313 MUIDataTableHeadCell-fixedHeader-314" scope="col" data-colindex="1">
                                                                        <span role="button" className="MUIDataTableHeadCell-toolButton-322" data-testid="headcol-1" tabindex="0">
                                                                            <div className="MUIDataTableHeadCell-sortAction-318" title="Sort">
                                                                                <div className="MUIDataTableHeadCell-data-317">Cost per 1000</div>
                                                                            </div>
                                                                        </span>
                                                                    </th>
                                                                    <th className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-313 MUIDataTableHeadCell-fixedHeader-314" scope="col" data-colindex="2">
                                                                        <span role="button" className="MUIDataTableHeadCell-toolButton-322" data-testid="headcol-2" tabindex="0">
                                                                            <div className="MUIDataTableHeadCell-sortAction-318" title="Sort">
                                                                                <div className="MUIDataTableHeadCell-data-317">% change of Reported</div>
                                                                            </div>
                                                                        </span>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="MuiTableBody-root">
                                                            {erVisitstableData && erVisitstableData.map((element, i) => {
                                                                return (
                                                                    <tr key={i} className="MuiTableRow-root MUIDataTableBodyRow-root-329 undefined MUIDataTableBodyRow-responsiveStacked-331 MUIDataTableBody-lastStackedCell-327 MuiTableRow-hover" data-testid="MUIDataTableBodyRow-0" id="MUIDataTableBodyRow-0">
                                                                        <td className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                                                {element[0]}
                                                                            </div>
                                                                        </td>
                                                                        <td className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                                                {'$'+ Number(element[1]).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                            </div>
                                                                        </td>
                                                                        <td className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                                                {element[2]}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                                })
                                                            }    
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="MUIDataTable-liveAnnounce-294" aria-live="polite"></div>
                                                </div>
                                            </div>
                                    }></CardComponent>
                                </div>
                            </div>
                        }>
                    </CardComponent>
                </div>
                <div className='col-lg-12 pr-0 pl-0 mt-5'>
                    <CardComponent
                        heading={`Admits Visits per 1000`}
                        body={
                            <div className='row col-lg-12 pr-0 pl-0'>
                                <div class="col-lg-6 mb-3">
                                    <CardComponent
                                        heading={``}
                                        body={                               
                                            <div style={{height:'330px'}}>
                                                <GChart data={admitsVisitsChartData} chartType="BarChart" height="320px" width="100%" hAxisTitle={"Visits per 1000"} yAxistitle={"Utilization Count"}></GChart>
                                            </div>
                                    }></CardComponent>
                                </div>
                                <div class="col-lg-6  mb-3">
                                    <CardComponent
                                        heading={``}
                                        body={                               
                                            <div className="box-height-chart">
                                                <div className="MuiPaper-root MUIDataTable-paper-284 pb-5 MuiPaper-elevation4 MuiPaper-rounded">
                                                    <div className="MUIDataTableFilterList-root-295"></div>
                                                    <div className="MUIDataTable-responsiveStacked-291" style={{"position": "relative", "max-height": "none", "height": "auto"}}>
                                                        <table role="grid" className="MuiTable-root MUIDataTable-tableRoot-286" tabindex="0">
                                                            <caption className="MUIDataTable-caption-293"></caption>
                                                            <thead className="MuiTableHead-root MUIDataTableHead-responsiveStacked-298 MUIDataTableHead-main-297">
                                                                <tr className="MuiTableRow-root MUIDataTableHeadRow-root-301 MuiTableRow-head">
                                                                    <th className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-313 MUIDataTableHeadCell-fixedHeader-314" scope="col" data-colindex="0">
                                                                        <span role="button" className="MUIDataTableHeadCell-toolButton-322" data-testid="headcol-0" tabindex="0">
                                                                            <div className="MUIDataTableHeadCell-sortAction-318">
                                                                                <div className="MUIDataTableHeadCell-data-317">Admits </div>
                                                                            </div>
                                                                        </span>
                                                                    </th>
                                                                    <th className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-313 MUIDataTableHeadCell-fixedHeader-314" scope="col" data-colindex="1">
                                                                        <span role="button" className="MUIDataTableHeadCell-toolButton-322" data-testid="headcol-1" tabindex="0">
                                                                            <div className="MUIDataTableHeadCell-sortAction-318" title="Sort">
                                                                                <div className="MUIDataTableHeadCell-data-317">Cost per 1000</div>
                                                                            </div>
                                                                        </span>
                                                                    </th>
                                                                    <th className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-313 MUIDataTableHeadCell-fixedHeader-314" scope="col" data-colindex="2">
                                                                        <span role="button" className="MUIDataTableHeadCell-toolButton-322" data-testid="headcol-2" tabindex="0">
                                                                            <div className="MUIDataTableHeadCell-sortAction-318" title="Sort">
                                                                                <div className="MUIDataTableHeadCell-data-317">% change of Reported</div>
                                                                            </div>
                                                                        </span>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="MuiTableBody-root">
                                                            {admitsVisitstableData && admitsVisitstableData.map((element, i) => {
                                                                return (
                                                                    <tr key={i} className="MuiTableRow-root MUIDataTableBodyRow-root-329 undefined MUIDataTableBodyRow-responsiveStacked-331 MUIDataTableBody-lastStackedCell-327 MuiTableRow-hover" data-testid="MUIDataTableBodyRow-0" id="MUIDataTableBodyRow-0">
                                                                        <td className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                                                {element[0]}
                                                                            </div>
                                                                        </td>
                                                                        <td className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                                                {'$'+ Number(element[1]).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                            </div>
                                                                        </td>
                                                                        <td className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                                                {element[2]}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                                })
                                                            }    
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className="MUIDataTable-liveAnnounce-294" aria-live="polite"></div>
                                                </div>
                                            </div>
                                    }></CardComponent>
                                </div>
                            </div>
                        }>
                    </CardComponent>
                </div>
                <div className='col-lg-12 pr-0 pl-0'>
                    <div className="list-member mt-5">
                        <CardComponent
                            heading={`UTILIZATION BY CATEGORY OF SERVICE`}
                            body={
                                    <ServerSideGridTable
                                        url={`/SU_Hcc_ServiceCategory/${props.yearSelect}/hcc/${hccCode}`}
                                        columns={memberMedicareCols}
                                    ></ServerSideGridTable>
                                }>
                        </CardComponent>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br/>

                <br/>
                <br/> 

            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(HighNeedUtilizationHcc);
