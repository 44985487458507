import React, { useRef, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import AWS from 'aws-sdk';
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar } from '../../store/actions';
import AWVApi from '../../assets/constants/AWVRafservice.Instance';
import Button from '@material-ui/core/Button';
const aws = require('aws-sdk');


const S3_BUCKET = process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET;
const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = localStorage.getItem("access_key");
const SECRET_ACCESS_KEY = localStorage.getItem("secret_key");

const AWVNLPInsightPDF = (props) => {
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
    const [editFormData, setEditFormData] = useState();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isDataGridCheked, setIsDataGridCheked] = useState(false);

    useEffect(() => {
        AWVApi.get('/get-awe-processed-record-details?year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + '&cin=' + props.cin)
            .then(res => {
                if (res.data && res.data[0]) {
                    setEditFormData(res.data[0]);
                    setIsLoading(false);
                } else {
                    setEditFormData();
                    setIsLoading(false);
                }
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
            })
    }, props.cin)

    const handleClick = (open, form_url, cin, type, aweId) => {
        setAnchorEl('');
        props.currentAWERightSidebarType({ 'type': type, 'cin': cin, 'status': '', 'form_url': form_url, 'aweId': aweId });
        props.currentAWERightSidebar(open);
        setIsDataGridCheked(false);
    };
    return (
        <>
            {typeof props.cin === 'undefined' ?
                <Button disabled={true} style={{
                    position: props.position ? props.position : 'relative',
                    color: 'white',
                    left: props.left ? props.left : '', 
                    transform: 'translateX(-50%)', 
                    padding: props.padding ? props.padding : '7px 25px',
                    fontFamily: "'Source Sans Pro', sans-serif",
                    backgroundColor: 'rgba(46, 147, 147, 0.5)',
                    top: '80%'
                }}>
                    NLP Processed Record
                </Button>
                :
                <Button
                    disabled={typeof editFormData?.processed_pdf === 'undefined' || editFormData?.processed_pdf == null}
                    onClick={() => handleClick(true, editFormData?.processed_pdf, props.cin, 'aweProcessedRecord', editFormData.awv_id)}
                    style={{
                        position: props.position ? props.position : 'relative',
                        color: 'white',
                        left: props.left ? props.left : '',
                        transform: 'translateX(-50%)', 
                        padding: props.padding ? props.padding : '7px 25px',
                        fontFamily: "'Source Sans Pro', sans-serif",
                        backgroundColor: typeof editFormData?.processed_pdf === 'undefined' || editFormData?.processed_pdf == null ? 'rgba(46, 147, 147, 0.5)' : 'rgb(46, 147, 147)',
                        top: '80%'
                    }}
                >
                    NLP Processed Record
                </Button >
            }

        </>
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar })(AWVNLPInsightPDF);