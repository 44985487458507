import React from 'react'

export default function MedicalKitIcon({ ...props }) {
    return (
        <svg {...props} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 12.5049C12.4142 12.5049 12.75 12.8407 12.75 13.2549V14.0049H13.5C13.9142 14.0049 14.25 14.3407 14.25 14.7549C14.25 15.1691 13.9142 15.5049 13.5 15.5049H12.75V16.2549C12.75 16.6691 12.4142 17.0049 12 17.0049C11.5858 17.0049 11.25 16.6691 11.25 16.2549V15.5049H10.5C10.0858 15.5049 9.75 15.1691 9.75 14.7549C9.75 14.3407 10.0858 14.0049 10.5 14.0049H11.25V13.2549C11.25 12.8407 11.5858 12.5049 12 12.5049Z" fill="currentcolor" />
            <path fillRule="evenodd" clipRule="evenodd" d="M11.948 2.00488C11.0495 2.00485 10.3003 2.00483 9.70552 2.08479C9.07773 2.1692 8.51093 2.35487 8.05546 2.81034C7.59999 3.26581 7.41432 3.83261 7.32991 4.4604C7.24995 5.05518 7.24997 5.8044 7.25 6.70288V6.7806C5.22882 6.84673 4.01511 7.08291 3.17157 7.92646C2 9.09803 2 10.9837 2 14.7549C2 18.5261 2 20.4118 3.17157 21.5833C4.34314 22.7549 6.22876 22.7549 9.99998 22.7549H14C17.7712 22.7549 19.6569 22.7549 20.8284 21.5833C22 20.4118 22 18.5261 22 14.7549C22 10.9837 22 9.09803 20.8284 7.92646C19.9849 7.08291 18.7712 6.84673 16.75 6.7806V6.70289C16.75 5.80442 16.7501 5.05518 16.6701 4.4604C16.5857 3.83261 16.4 3.26581 15.9445 2.81034C15.4891 2.35487 14.9223 2.1692 14.2945 2.08479C13.6997 2.00483 12.9505 2.00485 12.052 2.00488H11.948ZM15.25 6.75677V6.75488C15.25 5.79087 15.2484 5.14331 15.1835 4.66028C15.1214 4.19881 15.0142 4.00132 14.8839 3.871C14.7536 3.74068 14.5561 3.63346 14.0946 3.57142C13.6116 3.50648 12.964 3.50488 12 3.50488C11.036 3.50488 10.3884 3.50648 9.90539 3.57142C9.44393 3.63346 9.24643 3.74068 9.11612 3.871C8.9858 4.00132 8.87858 4.19881 8.81654 4.66028C8.75159 5.14331 8.75 5.79087 8.75 6.75488V6.75677C9.14203 6.75488 9.55807 6.75488 10 6.75488H14C14.4419 6.75488 14.858 6.75488 15.25 6.75677ZM16 14.7549C16 16.964 14.2091 18.7549 12 18.7549C9.79086 18.7549 8 16.964 8 14.7549C8 12.5458 9.79086 10.7549 12 10.7549C14.2091 10.7549 16 12.5458 16 14.7549Z" fill="currentcolor" />
        </svg>

    )
}
