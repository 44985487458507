import React from 'react'
import CheckTikIcon from '../../icons/CheckTikIcon'
import InfoLineIcon from '../../icons/InfoLineIcon'
import CloseLineIcon from '../../icons/CloseLineIcon'
import CircleIcon from '../../icons/CircleIcon'
import Tooltip from '@mui/material/Tooltip';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import ErrorIcon from '@mui/icons-material/Error';
 
export default function StatusBadge({ text, status }) {
    return (
        status == "danger" ?
            <Tooltip title={text} arrow placement="top">
                <div className={`status_badge badge_danger`}>
                    <CloseLineIcon className="w16 h16" />
                </div>
            </Tooltip>
            : status == "success" ?
                <Tooltip title={text} arrow placement="top">
                    <div className={`status_badge`}>
                        <CheckTikIcon className="w16 h16" />
                    </div>
                </Tooltip>
                : status == "light" ? 
                    <Tooltip title={text} arrow placement="top">
                        <div className={`status_badge badge-light`}>
                            <CircleIcon className="w16 h16" />
                        </div>
                    </Tooltip>
                    : status == "info" ?
                        <Tooltip title={text} arrow placement="top">
                            <div className={`status_badge badge_orange`}>
                                <InfoLineIcon className="w16 h16" />
                            </div>
                        </Tooltip>
                        : status == "yellow" ?
                        <Tooltip title={text} arrow placement="top">
                            <div className={`status_badge badge_yellow`}>
                                <InfoLineIcon className="w16 h16" />
                            </div>
                        </Tooltip>
                        : status == "notinterested" ?
                        <Tooltip title={text} arrow placement="top">
                            <div className={`status_badge badge_red`}>
                                <NotInterestedIcon className="w16 h16" />
                            </div>
                        </Tooltip>
                        : status == "Open" ?
                        <Tooltip title={text} arrow placement="top">
                            <div className={`status_badge badge_orange`}>
                                <ErrorIcon className="w16 h16" />
                            </div>
                        </Tooltip>
                        : status == "green" ?
                        <Tooltip title={text} arrow placement="top">
                            <div className={`status_badge badge_greeen`}>
                                <CheckTikIcon className="w16 h16" />
                            </div>
                        </Tooltip>
                        : <></>
    )
}
