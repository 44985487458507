import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import GridTable from "../../components/TableGrids/GridTable.table";
import CardComponent from "../../components/Cards/card";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import BarVertical from '../../components/GChart/BarVertical';
import { anchorTag, hccSeperator, numberWithCommasPrefixDolar } from '../../assets/constants/customRender'
import history from "../../history";
import { MAO_Chart_Review_Member, HCC, WIDGET_TYPES, PROVIDER_ID } from "../../assets/constants/string.constants";
import GChart from '../../components/GChart/GChart';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { getMeInitialSelect } from '../../assets/constants/meInitialList';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import SubHeader from '../../components/home/header/subHeader';

const MaoChartReviewProviderSummaryPage = (props) => {

    const [ProviderAddChartData, setProviderAddChartData] = useState([]);
    const [ProviderDeleteChartData, setProviderDeleteChartData] = useState([]);

    const loadProviderAddChartdData = () => {
        let newChartData = [];
        newChartData.push(["Provider", "HCC Count", { role: 'annotation' }]);
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/providerAdd/year/' + getAcaYear() + '/period/' + getMeInitialSelect())
            .then(res => {
                for (const dataObj of res) {
                    newChartData.push([dataObj.providerID, dataObj.hcc_Count, dataObj.hcc_Count]);
                }
                setProviderAddChartData(newChartData);
            })
            .catch(err => {
                // console.log(err);
            })
    }

    const loadProviderDeleteChartdData = () => {
        let newChartData = [];
        newChartData.push(["Provider", "HCC Count", { role: 'annotation' }]);
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/providerDelete/year/' + getAcaYear() + '/period/' + getMeInitialSelect())
            .then(res => {
                for (const dataObj of res) {
                    newChartData.push([dataObj.providerID, dataObj.hcc_Count, dataObj.hcc_Count]);
                }
                setProviderDeleteChartData(newChartData);
            })
            .catch(err => {
                // console.log(err);
            })
    }

    //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            loadProviderAddChartdData();
            loadProviderDeleteChartdData();
        }
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect()
    ]);

    const providerAddColumn = [
        {
            label: "Provider ID",
            name: "ProviderID",
            options: anchorTag
        },
        {
            name: "HCC_Count",
            label: "HCC Count",
        },
        {
            name: "Percertage_Contribution",
            label: "Percertage Contribution",
        },
        {
            name: "Impacted_MemberCount",
            label: "Impacted MemberCount",
        },
    ];

    const providerAddTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(PROVIDER_ID, rowdata[cellMeta.rowIndex].ProviderID);
            history.push('/layout/medicare/chart_review_provider_add')
        }
    }

    const providerDeleteTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(PROVIDER_ID, rowdata[cellMeta.rowIndex].ProviderID);
            history.push('/layout/medicare/chart_review_provider_delete')
        }
    }

    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Provider Summary'} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-4">
                    <CardComponent
                        heading={`TOP 5 PROVIDERS CREATING ADDITION IN CR`}
                        body={
                            <div className="box-height-chart">
                                <BarVertical data={ProviderAddChartData} chartType="BarChart" height="300px" width="100%" setStorgeItemName={PROVIDER_ID} redirectUrl={'medicare/chart_review_provider_add'} yAxistitle={'Providers'} hAxisTitle={'Member Count'}></BarVertical>
                            </div>
                        }></CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-4">
                    <div className="list-member">
                        <CardComponent
                            heading={`PROVIDERS CONTRIBUTION TO ADD'S`}
                            body={
                                <ServerSideGridTable
                                    url={`/providerAddDetails/year/${getAcaYear()}/period/${getMeInitialSelect()}`}
                                    columns={providerAddColumn}
                                    tableClick={providerAddTableClick}
                                ></ServerSideGridTable>
                            }>
                        </CardComponent>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-4">
                    <CardComponent
                        heading={`TOP 5 PROVIDERS CREATING DELETION IN CR`}
                        body={
                            <div className="box-height-chart">
                                <BarVertical data={ProviderDeleteChartData} chartType="BarChart" height="300px" width="100%" setStorgeItemName={PROVIDER_ID} redirectUrl={'medicare/chart_review_provider_delete'} yAxistitle={'Providers'} hAxisTitle={'Member Count'}></BarVertical>
                            </div>
                        }></CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-4">
                    <div className="list-member">
                        <CardComponent
                            heading={`PROVIDERS CONTRIBUTION TO DELETE'S`}
                            body={
                                <ServerSideGridTable
                                    url={`/providerDeleteDetails/year/${getAcaYear()}/period/${getMeInitialSelect()}`}
                                    columns={providerAddColumn}
                                    tableClick={providerDeleteTableClick}
                                ></ServerSideGridTable>
                            }>
                        </CardComponent>
                    </div>
                </div>
            </div>
            <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
            <br />

            <br />
            <br />

        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default connect(mapStateToProps)(MaoChartReviewProviderSummaryPage);
