import React, { useState, useEffect } from "react"; import {
  REPORTED_MEMBER_SUMMARY, PPG_SUMMARY
} from "../../assets/constants/string.constants";
import ReportedMemberSummaryACA from './ReportedMemberSummaryACA';
import PGPDetailsACA from './ACAPGP.page';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../components/Tab/TabPanel";
import { getAcaYearList, getAcaYear, AcaYearList } from '../../assets/constants/yearList';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag, hccSeperator, numberWithCommas } from '../../assets/constants/customRender'
import history from "../../history";
import { MEMBER_ID, ISSUER_ID, HCC, WIDGET_TYPES, REPORTED_INSIGHTS, MEMBER_NAME, CARRIER_ID, PLAN_ID, GENDER, RAF_RANGE } from "../../assets/constants/string.constants";
import { percentFormat } from "../../assets/helpers/formats.helpers";
import CardComponent from "../../components/Cards/card";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import GChart from '../../components/GChart/GChart';
import GChartClick from '../../components/GChart/GChartClick';
import IssuersACA from './ACAIssuers.page';
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import SubHeader from '../../components/home/header/subHeader';
import Link from '@material-ui/core/Link';
import { connect } from 'react-redux';
//import google from "react-google-charts";

const ACAreportedMemberSummary = (props) => {
  const [value, setValue] = useState(REPORTED_MEMBER_SUMMARY);
  const [year, setYear] = useState();

  function a11yProps(index) {

    return {
      id: `wrapped-tab-${index}`,
      "aria-controls": `wrapped-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="container level-summary">
      <div>

   
      <AppBar position="static" color="default">
        <Tabs
          variant="fullWidth"
          textColor="primary"
          indicatorColor="primary"
          value={value}
          onChange={handleChange}
          centered
        >
          <Tab
            value={REPORTED_MEMBER_SUMMARY}
            label="REPORTED MEMBER SUMMARY"
            {...a11yProps(REPORTED_MEMBER_SUMMARY)}
          />
          <Tab
            value={PPG_SUMMARY}
            label="PPG SUMMARY"
            {...a11yProps(PPG_SUMMARY)}
          />
        </Tabs>
        
      </AppBar>
      
      </div>
      <TabPanel value={value} index={REPORTED_MEMBER_SUMMARY}>
        <ReportedMemberSummaryACA></ReportedMemberSummaryACA>
        <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
        <br />
        <br />
        <br />
      </TabPanel>
      <TabPanel value={value} index={PPG_SUMMARY}>
        <PGPDetailsACA page='reportedMemberSummary'></PGPDetailsACA>
        <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
        <br />
        <br />
        <br />
      </TabPanel>
    </div>
  )
};
const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    moduleSelect: state.moduleFilter.moduleSelect,
    viewBySelect: state.moduleFilter.viewBySelect,
    modelSelect: state.moduleFilter.modelSelect,
    metalTierSelect: state.moduleFilter.metalTierSelect,
    planSelect: state.moduleFilter.planSelect,
    yearSelect: state.moduleFilter.yearSelect,
  };
}

export default connect(mapStateToProps)(ACAreportedMemberSummary)
