import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import WidgetCard from "../../components/Cards/WidgetCard";
import SingleVerticalBarChart from '../../components/Charts/SingleVerticalBar.Chart';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import GridTable from "../../components/TableGrids/GridTable.table";
import { anchorTag, hccSeperator, numberWithCommas, dateFormatRender } from '../../assets/constants/customRender'
import history from "../../history";
import { MAO_Allowed_Disallowed_Period, MAO_Allowed_Disallowed_Type,MAO_Allowed_Disallowed_Year,MAO_Allowed_Disallowed_Reasoncode,MAO_Chart_Review_Member, MAO_MEMBER_SELECT_TAB, UTILIZATION, MEMBER_ID } from "../../assets/constants/string.constants";
import { percentFormat } from "../../assets/helpers/formats.helpers";
import CardComponent from "../../components/Cards/card";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import GChart from '../../components/GChart/GChart';
import IssuersACA from '../ACA/ACAIssuers.page';
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import SubHeader from '../../components/home/header/subHeader';
import { getViewBySelect } from '../../assets/constants/viewByList';
import { getModelSelect } from '../../assets/constants/modelList';
import { getMetalTierSelect } from '../../assets/constants/metalTierList';
import { getPlanSelect } from '../../assets/constants/planList';
import { getModuleSelect } from '../../assets/constants/moduleList';
import { getMeInitialSelect} from '../../assets/constants/meInitialList';
import Link from '@material-ui/core/Link';
import { connect } from 'react-redux';
import BarVertical from '../../components/GChart/BarVertical';
import Moment from 'react-moment';
//import google from "react-google-charts";
const MaoDisallowedClaims = (props) => {

    const [drillDownYearData, setDrillDownYearData] = useState([]);
    const [MAOAllowedDisallowedYear] = useState(localStorage.getItem(MAO_Allowed_Disallowed_Year));
    const [MAOAllowedDisallowedType] = useState(localStorage.getItem(MAO_Allowed_Disallowed_Type));
    const [MAOAllowedDisallowedPeriod] = useState(localStorage.getItem(MAO_Allowed_Disallowed_Period));
    const [MAOAllowedDisallowedReasoncode] = useState(localStorage.getItem(MAO_Allowed_Disallowed_Reasoncode));
    const [HccChartData, setHccChartData] = useState([]);


    const ClaimDataColumns = [
        {
            name: "ClaimId",
            label: "ICN"
        },
        {
            name: "MemberId",
            label: "MemberId",
            options: anchorTag
        },
        {
            name: "claimFromDate",
            label: "Start Date DOS",
            options: dateFormatRender
        },
        {
            name: "claimThruDate",
            label: "End Date  DOS",
            options: dateFormatRender
        },
        {
            name: "SubmittedDate",
            label: "Submission Date",
            options: dateFormatRender
        },
        {
            name: "FileSubmittedDate",
            label: "File Submission Date",
            options: dateFormatRender
        },
        {
            name: "ClaimEncouterType",
            label: "ICN Type",
        },
    ];

    const memberTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 1) && (colData.props.children != "")) {
           // localStorage.setItem(MEMBER_ID, rowdata[cellMeta.rowIndex].MEMBER_ID);
            localStorage.setItem(MAO_Chart_Review_Member, rowdata[cellMeta.rowIndex].MemberId);
            localStorage.setItem(MAO_MEMBER_SELECT_TAB, UTILIZATION);
            history.push('/layout/medicare/mao_member_summary')
        }
    }

    //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    useEffect(() => { 
           
    },  [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect()
    ]);

    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Disallowed Claims'} />
                        </div>
                    </div>
                </div>     
                <div className='col-lg-12 pr-0 pl-0'>
                    <div className="list-member mt-4">
                        <CardComponent
                            heading={`CLAIM LIST`}
                            body={
                                    <ServerSideGridTable
                                        url={`maoDissallowedClaimsService/Maoyear/`+MAOAllowedDisallowedYear+`/Maoper/`+MAOAllowedDisallowedPeriod+`/rrflag/`+MAOAllowedDisallowedReasoncode+`/crflag/`+MAOAllowedDisallowedType}
                                        columns={ClaimDataColumns}
                                         tableClick={memberTableClick}
                                    ></ServerSideGridTable>
                                }>
                        </CardComponent>
                    </div>
                </div>
                    
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br/>

                <br/>
                <br/> 
                    
            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(MaoDisallowedClaims);
