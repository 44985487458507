import React from 'react'

export default function DownloadSquareIcon({...props}) {
    return (
        <svg {...props} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M2.27393 12.5C2.27393 7.78595 2.27393 5.42893 3.7384 3.96447C5.20286 2.5 7.55988 2.5 12.2739 2.5C16.9879 2.5 19.345 2.5 20.8094 3.96447C22.2739 5.42893 22.2739 7.78595 22.2739 12.5C22.2739 17.214 22.2739 19.5711 20.8094 21.0355C19.345 22.5 16.9879 22.5 12.2739 22.5C7.55988 22.5 5.20286 22.5 3.7384 21.0355C2.27393 19.5711 2.27393 17.214 2.27393 12.5ZM12.2739 6.75C12.6881 6.75 13.0239 7.08579 13.0239 7.5V12.6893L14.7436 10.9697C15.0365 10.6768 15.5113 10.6768 15.8042 10.9697C16.0971 11.2626 16.0971 11.7374 15.8042 12.0303L12.8042 15.0303C12.6636 15.171 12.4728 15.25 12.2739 15.25C12.075 15.25 11.8842 15.171 11.7436 15.0303L8.7436 12.0303C8.45071 11.7374 8.45071 11.2626 8.7436 10.9697C9.03649 10.6768 9.51137 10.6768 9.80426 10.9697L11.5239 12.6893V7.5C11.5239 7.08579 11.8597 6.75 12.2739 6.75ZM8.27393 16.75C7.85972 16.75 7.52393 17.0858 7.52393 17.5C7.52393 17.9142 7.85972 18.25 8.27393 18.25H16.2739C16.6881 18.25 17.0239 17.9142 17.0239 17.5C17.0239 17.0858 16.6881 16.75 16.2739 16.75H8.27393Z" fill="currentcolor"/>
        </svg>
        
    )
}
