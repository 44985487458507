import React, { useState, useReducer, useEffect } from 'react';
import MultiRangeSlider from "multi-range-slider-react";
import '../../MultiRangeSlider.css';

const RangeSliderChart = (props) => {
    useEffect(() => {
    }, [props.secondValue]);
    return (
        <div className="row mb-3">
            <div className="col-lg-12 pr-0">
                {parseFloat(props.firstValue) > parseFloat(props.secondValue) ?
                    <MultiRangeSlider
                        baseClassName="multi-range-slider-black multi-range-slider-swap"
                        min={parseFloat(props.minValue)}
                        max={parseFloat(props.maxValue)}
                        step={((parseFloat(props.maxValue) - parseFloat(props.minValue)) / 10)}
                        ruler={true}
                        label={true}
                        disabled={true}
                        preventWheel={true}
                        minValue={parseFloat(props.secondValue)}
                        maxValue={parseFloat(props.firstValue)}
                        style={{ innerHeight: '10px' }}
                    />
                    :
                    <MultiRangeSlider
                        baseClassName="multi-range-slider-black"
                        min={parseFloat(props.minValue)}
                        max={parseFloat(props.maxValue)}
                        step={((parseFloat(props.maxValue) - parseFloat(props.minValue)) / 10)}
                        ruler={true}
                        label={true}
                        disabled={true}
                        preventWheel={true}
                        minValue={parseFloat(props.firstValue)}
                        maxValue={parseFloat(props.secondValue)}
                        style={{ innerHeight: '10px' }}
                    />
                }

            </div>
        </div>
    );
};
export default RangeSliderChart;
