import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { Button, CircularProgress } from '@material-ui/core';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles
} from "@material-ui/core/styles";
import { useEffect } from "react";

const AWVServerSideGridTableHCC = (props) => {

    const { title, data, columns, onTableClick, className, isLoading = false, pagination = false } = props;

    // const onTableClick = (e,el,data,columns) =>{
    //          console.log("here inside grid"+ e,el,data,columns);
    //     if(props.onTableClick){
    //         onTableClick(e,el,data,columns);

    //     }        
    // }

    const options = {
        selectableRows: false,
        pagination: props.pagination ? props.pagination : false,
        print: props.print ? props.print : false,
        searchOpen: props.searchOpen ? props.searchOpen : false,
        filter: props.filter ? props.filter : false,
        searchIcon: props.searchIcon ? props.searchIcon : false,
        download: props.download ? props.download : false,
        search: props.search ? props.search : false,
        viewColumns: props.viewColumns ? props.viewColumns : false,
        downloadOptions: {
            filename: 'Download.csv'
        },
        // responsive: "scrollMaxHeight",
        responsive: 'stacked',
        tableBodyHeight: 'auto',
        tableBodyMaxHeight: '900px',
        textLabels: {
            body: {
                noMatch: isLoading ?
                    <CircularProgress />
                    :
                    'Sorry, there is no matching data to display',
            },
        },
        onCellClick: (colData, cellMeta) => {
            // console.log(colData,cellMeta);
            if (props.onTableClick) {
                onTableClick(colData, cellMeta, data, columns);
            }
        },
    }

    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MuiTableCell: {
                    head: {
                        backgroundColor: "#F7F7F7 !important",
                        color: "#202020 !important",
                        fontWeight: "bold !important"
                    },
                    sortOrder: {
                        backgroundColor: "#F7F7F7 !important",
                        color: "#202020 !important",
                        fontWeight: "bold !important"
                    }
                },
                MUIDataTableSelectCell: {
                    headerCell: {
                        backgroundColor: "#F7F7F7 !important",
                        color: "#202020 !important",
                        fontWeight: "bold !important"
                    }
                },
                MUIDataTableHeadCell: {
                    sortActive: {
                        color: "#202020 !important",
                    }
                }
            }
        });
        useEffect(() => {
          //   if(title=="HCC Capture Record")
          //        {let element = document.querySelector('.MuiTablePagination-root'); // Replace with the class name you obtained from inspecting the element
          //   if (element && title==="HCC Capture Record") {
          //     element.classList.add('customPagination_HCC'); // Replace 'new-class' with the class you want to add
          //   }
          //   else if(title!="HCC Capture Record")
          //       element.classList.remove('customPagination_HCC')
          // }
          const parentDiv = document.querySelector(".HCCPagination")
          var targetDiv = parentDiv.querySelector('table tfoot tr td:first-child div');
          if(targetDiv)
          {
            targetDiv.classList.add('customPagination_HCC'); // Replace 'new-class' with the class you want to add

          }
        },[title, data, columns]);
    return (
        <div className={className}>
            {/* {data.length > 0 ?
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={title}
                        data={data}
                        columns={columns}
                        options={options}
                        className={"pb-5"}
                    />
                </MuiThemeProvider>
                :
                isLoading ? <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                    <CircularProgress />
                </div> : <h6 className="text-center">No records found!..</h6>
            } */}
            <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                    title={title}
                    data={data}
                    columns={columns}
                    options={options}
                    className={"HCCPagination"}
                />
            </MuiThemeProvider>
        </div>
    );

}

AWVServerSideGridTableHCC.propTypes = {
    title: PropTypes.array,
    data: PropTypes.array,
    columns: PropTypes.array,
    onTableClick: PropTypes.func,
    pagination: PropTypes.bool,
    isLoading: PropTypes.bool
}

export default AWVServerSideGridTableHCC;