import api from './Rafservice.Instance';
import { 
    SELECT_YEAR, SELECT_INITIAL, 
    SELECT_METAL_TIER, SELECT_VIEW_BY,
    SELECT_PLAN, SELECT_KPI
} from "../../assets/constants/string.constants";

let KPI_list = [];
let KPI_select = '';
export const KPIList = () =>{

    // api.get('/aca_yearList/')
    // .then(res=>{
        SetKPIList([ 
                'PROH',
                'APCV',
                'AIPA',
                'PVMG',
                'OMNI',
                'CFCA',
                'CVPG',
                'PROF',
                'LAKE',
                'CFC',
                'GCMG',
                'APPL',
                'UCLA',
                'AMHS'
        ]);  
        if(localStorage.getItem(SELECT_KPI) && localStorage.getItem(SELECT_KPI) != ''){
            // localStorage.setItem('YEAR', res[0]);            
            // SetAcaYear(res[0]);
            SetKPISelect(localStorage.getItem(SELECT_KPI)); 
        }else{
            localStorage.setItem(SELECT_KPI, 'PROH');            
            SetKPISelect('PROH'); 
        }    
    // })
    // .catch(err=>{
    //     // console.log(err);
    // })
}

export const SetKPIList = (data) =>{
    KPI_list = data;
}

export const SetKPISelect = (data) =>{
    KPI_select = data;
    localStorage.setItem(SELECT_KPI, data);
}

export const getKPIList = () =>{
    return KPI_list;
}

export const getKPISelect = () =>{
    return KPI_select;
}