import { CURRENT_ROUTE } from '../actions/types';

const INITIAL_STATE = {
    route: '/layout/acaReportedMemberSummary'
};


const routeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CURRENT_ROUTE:
            return { ...state, route: action.payload };
        default:
            return state;
    }
};

export default routeReducer;
