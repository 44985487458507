import React, { useState, useEffect } from "react";
import ServerSideGridTable from '../../components/TableGrids/ServerSideGridTable.table';
import { anchorTag, reviewButton } from '../../assets/constants/customRender';
import CardComponent from '../../components/Cards/card';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import AcaYearSelect from "../../components/DropDowns/AcaYearSelect"
import history from "../../history";
import { MEMBER_ID } from "../../assets/constants/string.constants";
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';

const ACACRProviderSummary = (props) => {

    const [year, setYear] = useState(props.yearSelect);

    const MemberCol = [
        {
            label: "Member Id",
            name: "MEMBER_ID",
            options: anchorTag
        },

        {
            name: "AGE",
            label: "Age"
        },
        {
            name: "CURRENT_RAF",
            label: "Current RAF",

        },
        {
            name: "PROJECTED_RAF",
            label: "Projected RAF",

        },
        {
            name: "POTENTIAL",
            label: "Potential",

        },
        {
            name: "ACCEPTED",
            label: "Accepted"
        },
        {
            name: "ESTIMATED_RAF_IMPACT",
            label: "RAF Impact"
        },
        {
            name: "PROVIDER",
            label: "Provider"
        },
        {
            name: "ButtonColumn",
            label: "Coding",
            options: reviewButton

        }

    ];
    const memberTableClick = (rowdata, colData, cellMeta) => {
        if (cellMeta.colIndex === 8 || cellMeta.colIndex === 0) {
            localStorage.setItem(MEMBER_ID, rowdata[cellMeta.rowIndex].MEMBER_ID);
            // console.log("here");
            history.push('/layout/acaProvidersHcc');
        }

    };

    useEffect(() => {

    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect,
        props.meInitialSelect
    ]);

    return (
        <div className="container level-summary">
            <div className="row mb-3">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'HCC Coding Opportunities'} />
                        </div>
                    </div>
                </div>

                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <CardComponent
                        heading={`Coding Opportunity- Year : ${props.yearSelect}`}
                        body={props.yearSelect ?
                            <ServerSideGridTable
                                url={`/ProviderMemberDetailsALL/year/${props.yearSelect}`}
                                columns={MemberCol}
                                tableClick={memberTableClick}
                                defaultSortCol={1}
                                defaultSortDir={'asc'}
                            ></ServerSideGridTable>
                            :
                            <></>
                        }></CardComponent>
                </div>

                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br />

                <br />
                <br />
            </div>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default connect(mapStateToProps)(ACACRProviderSummary);
