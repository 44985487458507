import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import { getAcaYear } from '../../assets/constants/yearList';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag } from '../../assets/constants/customRender'
import history from "../../history";
import { MAO_Chart_Review_Member, WIDGET_TYPES, ISSUER_ID, HCC } from "../../assets/constants/string.constants";
import CardComponent from "../../components/Cards/card";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import SubHeader from '../../components/home/header/subHeader';
import { getViewBySelect } from '../../assets/constants/viewByList';
import { getModelSelect } from '../../assets/constants/modelList';
import { getMetalTierSelect } from '../../assets/constants/metalTierList';
import { getPlanSelect } from '../../assets/constants/planList';
import { getModuleSelect } from '../../assets/constants/moduleList';
import { getMeInitialSelect } from '../../assets/constants/meInitialList';
import { connect } from 'react-redux';
//import google from "react-google-charts";

const MaoChartReviewPage = (props) => {
    const [HccChartData, setHccChartData] = useState({});
    const [WidgetDetails, setWidgetDetails] = useState({});
    const [cardToggle, setcardToggle] = useState(true);
    const [ChartData, setChartData] = useState([]);
    const [issuerData, setIssuerData] = useState([]);
    const [IssuerId, setIssuerId] = useState("");
    const [riskChartData, setRiskChartData] = useState([]);
    const [moduleSelect, setModuleSelect] = useState(getModuleSelect());
    const [viewBySelect, setViewBySelect] = useState(getViewBySelect());
    const [modelSelect, setModelSelect] = useState(getModelSelect());
    const [metalTierSelect, setMetalTierSelect] = useState(getMetalTierSelect());
    const [planSelect, setPlanSelect] = useState(getPlanSelect());

    let sliderRangeInt = 0;
    let dataIndex = 0;

    const backGround = [
        { backgroundColor: "#AAB7B8" },
        { backgroundColor: "#EB984E" },
        { backgroundColor: "#58D68D" },
        { backgroundColor: "#5DADE2" },
        { backgroundColor: "#AF7AC5" },
        { backgroundColor: "#EC7063" }
    ]

    const WidgetDetailsType = [
        WIDGET_TYPES.LOCKICON,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.PERCENT,
        WIDGET_TYPES.SCORE,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.TRENDING,
        WIDGET_TYPES.PERCENT,
    ]

    const type = WidgetDetailsType;
    const widgetStyles = {
        position: 'absolute',
        left: 0,
        //right: 20,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.15,
        color: "white"
    };

    const sliderSettingsAca = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        adaptiveHeight: true,
        variableWidth: true
    };

    const loadWidgetDetails = () => {
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/MaochartReviewMemberSummary/year/' + getAcaYear() + '/period/' + getMeInitialSelect())
            .then(res => {
                setWidgetDetails({
                    '# Total Members': Number(res[0].totalMembersForYear).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    '# Members With CR': Number(res[0].membersWithCR).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    'CR Corrected Revenue': '$' + Number(res[0].crCorrectedRevenue).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    'Overall Revenue With CR': '$' + Number(res[0].overallRevenueWithCR).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    '% Of RAF Score Contributed By CR': '$' + Number(res[0].perOfRAFScoreContributedByCR).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                });
                sliderRangeInt = 6;
            })
            .catch(err => {
                // console.log(err);
            });
    };

    const getIcon = (type) => {
        var data = ''
        switch (type) {
            case WIDGET_TYPES.ISSUER:
                data = <AccountBalanceTwoToneIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.MEMBERS:
                data = <PeopleAltIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERCENT:
                data = <ShowChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.SCORE:
                data = <BarChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERSON:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.CHILD:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.ADULT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.INFANT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.REVENUE:
                data = <AttachMoneyIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.COUNT:
                data = <ShoppingCartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.LOCKICON:
                data = <LockOpenIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.TRENDING:
                data = <TrendingUpIcon style={widgetStyles} />
                break;
        }

        return data;
    }

    const memberMedicareCols = [
        {
            label: "Member ID",
            name: "MemberID",
            options: anchorTag
        },
        {
            name: "ReportedHCCS",
            label: "Reported HCCs",
            options: anchorTag
        },
        {
            name: "AddedHccs",
            label: "Added HCCs",
            options: anchorTag
        },
        {
            name: "DeletedHccs",
            label: "Deleted HCCs",
            options: anchorTag
        },
        {
            name: "ReportedRAF",
            label: "Reported RAF",
        },
        {
            name: "CorrectionInRAF",
            label: "RAF Impact due to CR",
        },
        {
            name: "CorrectedOverallRevenue",
            label: "Overall Revenue Post CR",
        },
        {
            name: "RevenueCorrection",
            label: "Revenue Impact due to CR",
        },
        {
            name: "ChartReviewAsAPercentageOfCorrectedRevenue",
            label: "% Contribution by CR"

        }
    ];

    const memberTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(MAO_Chart_Review_Member, rowdata[cellMeta.rowIndex].MemberID);
            history.push('/layout/medicare/mao_member_summary')
        } else if ((cellMeta.colIndex === 1) && (colData.props.children != "")) {
            if ((rowdata[cellMeta.rowIndex].ReportedHCCS).split(',').length > 0) {
                localStorage.setItem(HCC, (rowdata[cellMeta.rowIndex].ReportedHCCS).split(',')[0]);
                history.push('/layout/medicare/reported_hcc')
            }
        } else if ((cellMeta.colIndex === 2) && (colData.props.children != "")) {
            if ((rowdata[cellMeta.rowIndex].AddedHccs).split(',').length > 0) {
                localStorage.setItem(HCC, (rowdata[cellMeta.rowIndex].AddedHccs).split(',')[0]);
                history.push('/layout/medicare/chart_review_hcc_add')
            }
        } else if ((cellMeta.colIndex === 3) && (colData.props.children != "")) {
            if ((rowdata[cellMeta.rowIndex].DeletedHccs).split(',').length > 0) {
                localStorage.setItem(HCC, (rowdata[cellMeta.rowIndex].DeletedHccs).split(',')[0]);
                history.push('/layout/medicare/chart_review_hcc_delete')
            }
        }
    }

    const HccChartClick = (element) => {
        var activePoints = element;
        var chartData = activePoints[0]['_chart'].config.data;
        var idx = activePoints[0]['_index'];
        var issuer = chartData.labels[idx];
        localStorage.setItem(ISSUER_ID, issuer);
        history.push('/layout/acaIssuerGapsummary');
    };

    const elementissuerIDLinkClick = () => {
        history.push('/layout/acaIssuerGapsummary');
    };

    //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    useEffect(() => {
        setChartData([]);
        if (props.yearSelect && props.yearSelect != '') {
            loadWidgetDetails();
        }
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect()
    ]);

    let sliderRange = Object.entries(WidgetDetails).length / 3;

    sliderRange = parseInt(sliderRange);
    sliderRangeInt = parseInt(sliderRangeInt);
    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Chart Review'} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0">
                    <div className="state-level">
                        {(Object.entries(WidgetDetails).length > 0) ?
                            <SlickSlider WidgetDetails={WidgetDetails} getIcon={getIcon} type={type} />
                            :
                            <></>
                        }
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-4">
                    <CardComponent
                        heading={`LIST OF MEMBER(S)`}
                        body={
                            <ServerSideGridTable
                                url={`/MaocrMemberSummary/year/${getAcaYear()}/period/${getMeInitialSelect()}/max/1000`}
                                columns={memberMedicareCols}
                                tableClick={memberTableClick}
                            ></ServerSideGridTable>
                        }>
                    </CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br />

                <br />
                <br />

            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default connect(mapStateToProps)(MaoChartReviewPage);
