import React, { useRef, useState, useEffect } from "react";
import { connect } from 'react-redux';
import AWVApi from '../../../assets/constants/AWVMemberDetails.RafService.Instance';
import { signOut, currentRoute, currentAWESiteSelect, currentAWEProviderGroupingSelect, currentAWECitySelect, currentAWEAddressSelect, currentAWEZipSelect } from '../../../store/actions';
import history from '../../../history';
import {
    SELECT_YEAR, SELECT_INITIAL,
    SELECT_AWE_SITE, SELECT_AWE_PROVIDER_GROUPING,
    SELECT_AWE_CITY, SELECT_AWE_ADDRESS, SELECT_AWE_ZIP, SELECT_SITE_ID
} from "../../../assets/constants/string.constants";
import AcaYearSelect from '../../DropDowns/AcaYearSelect';
import { getAcaYearList, getAcaYear, AcaYearList } from '../../../assets/constants/yearList';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import FormControl from '@material-ui/core/FormControl';
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";

const AwvTabHeader = (props) => {
    const { formState: { errors }, control } = useForm();
    const [siteIdList, setsiteIdList] = useState([]);
    const [providerAddressGroupsData, setProviderAddressGroupsData] = useState([]);
    const [providersData, setProviderData] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [siteAddressList, setSiteAddressList] = useState([]);
    const [zipList, setZipList] = useState([]);

    // const [siteIdSelect, setsiteIdSelect] = useState('');
    // const [providerAddressGroupsSelect, setProviderAddressGroupsSelect] = useState([]);
    // const [citySelect, setCitySelect] = useState('');
    // const [siteAddressSelect, setSiteAddressSelect] = useState('');
    // const [zipSelect, setZipSelect] = useState('');


    useEffect(() => {
        if (props.yearSelect && props.providerGroupSelect && props.yearSelect != '' && props.providerGroupSelect != '') {
            loadSiteId();
            loadProviderAddressGroupData();
           // loadProviderNameData();
            loadCity();
            loadSiteAddress();
            loadZip();
        }


        // localStorage.setItem(SELECT_AWE_SITE, '');
        // setsiteIdSelect('');
        // props.currentAWESiteSelect('');

        // setProviderAddressGroupsSelect([]);
        // localStorage.setItem(SELECT_AWE_PROVIDER_GROUPING, '');
        // props.currentAWEProviderGroupingSelect('');

        // localStorage.setItem(SELECT_AWE_CITY, '');
        // setCitySelect('');
        // props.currentAWECitySelect('');


        // localStorage.setItem(SELECT_AWE_ADDRESS, '');
        // setSiteAddressSelect('');
        // props.currentAWEAddressSelect('');

        // localStorage.setItem(SELECT_AWE_ZIP, '');
        // setZipSelect('');
        // props.currentAWEZipSelect('');

    }, [props.yearSelect, props.providerGroupSelect]);

    useEffect(() => {
       // if (props.citySelect && props.citySelect != '') {   // commenting condition to resolve on removing city, all address grouping filter should reload
            loadSiteId();
            loadProviderAddressGroupData();
          //  loadProviderNameData();
            loadSiteAddress();
            loadZip();
    //    }

    }, [props.citySelect]);

    useEffect(() => {
        // localStorage.setItem(SELECT_AWE_SITE, '');
        // setsiteIdSelect('');
        // props.currentAWESiteSelect('');

        // setProviderAddressGroupsSelect([]);
        // localStorage.setItem(SELECT_AWE_PROVIDER_GROUPING, '');
        // props.currentAWEProviderGroupingSelect('');

        // localStorage.setItem(SELECT_AWE_CITY, '');
        // setCitySelect('');
        // props.currentAWECitySelect('');

        // localStorage.setItem(SELECT_AWE_ADDRESS, '');
        // setSiteAddressSelect('');
        // props.currentAWEAddressSelect('');

        // localStorage.setItem(SELECT_AWE_ZIP, '');
        // setZipSelect('');
        // props.currentAWEZipSelect('');
    }, []);
    // 
    // Added for siteID render 
    useEffect(()=>{
      loadSiteIdOnlyYear();
    },[props.yearSelect])


    const loadSiteId = () => {
        var providerGroupSelectVar = (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL') ? props.providerGroupSelect : "";
        AWVApi.get(`/getsiteid?year=` + props.yearSelect + `&providerGroup=` + providerGroupSelectVar + `&city=` + props.citySelect)
            .then(res => {
                if (res.data && res.data.length > 0) {
                    setsiteIdList(res.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }
    //storing siteID data into localstorage to render it on EDIT button of coordinator checklist dependency only year. 
    const loadSiteIdOnlyYear = () => {
        AWVApi.get(`/getsiteid?year=` + props.yearSelect )
            .then(res => {
                if (res.data && res.data.length > 0) {
                    localStorage.setItem(SELECT_SITE_ID,JSON.stringify(res.data))
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const loadProviderAddressGroupData = () => {
        var providerGroupSelectVar = (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL') ? props.providerGroupSelect : '';
        AWVApi.get(`/getProviderAddressGrouping?year=` + props.yearSelect + `&providerGroup=` + providerGroupSelectVar + `&city=` + props.citySelect)
            .then(res => {
                if (res.data && res.data.length > 0) {
                    setProviderAddressGroupsData(res.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const loadProviderNameData = () => {
        AWVApi.get(`/pcp_provider_name?year=` + props.yearSelect + `&providerGroup=` + props.providerGroupSelect + `&city=` + props.citySelect)
            .then(res => {
                if (res.data && res.data.length > 0) {
                    setProviderData(res.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const loadCity = () => {
        var providerGroupSelectVar = (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL') ? props.providerGroupSelect : '';
        AWVApi.get(`/getCity?year=` + props.yearSelect + `&providerGroup=` + providerGroupSelectVar)
            .then(res => {
                if (res.data && res.data.length > 0) {
                    setCityList(res.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const loadSiteAddress = () => {
        var providerGroupSelectVar = (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL') ? props.providerGroupSelect : '';
        // awvApi.get(`/getState?year=2020&ppg=`+selectIPACode+ `&ppg_address=` + selectAddressGroup + `&filterValue=`+filterValue)
        AWVApi.get(`/getSiteAddress?year=` + props.yearSelect + `&providerGroup=` + providerGroupSelectVar + `&city=` + props.citySelect)
            .then(res => {
                if (res.data && res.data.length > 0) {
                    setSiteAddressList(res.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const loadZip = () => {
        var providerGroupSelectVar = (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL') ? props.providerGroupSelect : '';
        AWVApi.get(`/getZip?year=` + props.yearSelect + `&providerGroup=` + providerGroupSelectVar + `&city=` + props.citySelect)
            .then(res => {
                if (res.data && res.data.length > 0) {
                    setZipList(res.data);
                }

            })
            .catch(err => {
                console.log(err);
            })
    }

    // const handleSiteSelect = (e) => {
    //     if (e && e != '') {
    //         localStorage.setItem(SELECT_AWE_SITE, e.site_id);
    //         setsiteIdSelect(e.site_id);
    //         props.currentAWESiteSelect(e.site_id);
    //     } else {
    //         localStorage.setItem(SELECT_AWE_SITE, '');
    //         setsiteIdSelect('');
    //         props.currentAWESiteSelect('');
    //     }
    // }

    // const handleProviderAddressGroupsSelect = (event) => {
    //     var setProviderSelected = [];
    //     var setProviderNameSelected = [];
    //     if (event != '' && event.length > 0) {
    //         event.map(function (el) {
    //             setProviderSelected.push(el.ppg_address_grouping.toString());
    //             setProviderNameSelected.push(el.ppg_address_grouping)
    //         })
    //         //Adding '' and send as comman separated value for multiple filter
    //         setProviderAddressGroupsSelect(setProviderSelected);
    //         localStorage.setItem(SELECT_AWE_PROVIDER_GROUPING, '\"' + setProviderNameSelected.join('\",\"') + '\"');
    //         props.currentAWEProviderGroupingSelect('\"' + setProviderNameSelected.join('\",\"') + '\"');
    //     }
    //     else {
    //         //Adding '' and send as comman separated value for multiple filter
    //         setProviderAddressGroupsSelect([]);
    //         localStorage.setItem(SELECT_AWE_PROVIDER_GROUPING, '');
    //         props.currentAWEProviderGroupingSelect('');
    //     }
    //     // console.log(localStorage.getItem(SELECT_AWE_PROVIDER_GROUPING));
    //     // console.log(providerAddressGroupsSelect);
    //     // localStorage.setItem(SELECT_AWE_PROVIDER_GROUPING, e.ppg_address_grouping);
    //     // setProviderAddressGroupsSelect(e.ppg_address_grouping);
    //     // props.currentAWEProviderGroupingSelect(e.ppg_address_grouping);
    // }

    // const handleCitySelect = (e) => {
    //     if (e && e != '') {
    //         localStorage.setItem(SELECT_AWE_CITY, e.site_city);
    //         setCitySelect(e.site_city);
    //         props.currentAWECitySelect(e.site_city);
    //     } else {
    //         localStorage.setItem(SELECT_AWE_CITY, '');
    //         setCitySelect('');
    //         props.currentAWECitySelect('');
    //     }
    // }

    // const handleAddressSelect = (e) => {
    //     if (e && e != '') {
    //         localStorage.setItem(SELECT_AWE_ADDRESS, e.site_address);
    //         setSiteAddressSelect(e.site_address);
    //         props.currentAWEAddressSelect(e.site_address);
    //     } else {
    //         localStorage.setItem(SELECT_AWE_ADDRESS, '');
    //         setSiteAddressSelect('');
    //         props.currentAWEAddressSelect('');
    //     }
    // }

    // const handleZipSelect = (e) => {
    //     if (e && e != '') {
    //         localStorage.setItem(SELECT_AWE_ZIP, e.site_zip);
    //         setZipSelect(e.site_zip);
    //         props.currentAWEZipSelect(e.site_zip);
    //     } else {
    //         localStorage.setItem(SELECT_AWE_ZIP, '');
    //         setZipSelect('');
    //         props.currentAWEZipSelect('');
    //     }
    // }

    return (
        <>
            <div>
                <span className="d-flex bd-highlight">
                    <span className="pt-2 pl-2 flex-grow-1 mt-2" style={{ 'fontSize': '15px' }}><b>{'Provider Address Grouping Filter'}</b></span>
                    <div className="d-flex align-items-center mr-3" >
                        <div className="d-flex justify-content-end" >
                            {/* set width for all searchable filter at top of the pages */}
                            <FormControl className="selectCustomDesign" fullWidth margin="dense" variant="outlined" style={{ 'width': '150px', 'zIndex': '2' }}>
                                <Controller
                                    className="input-control"
                                    name="siteId"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            placeholder={'Select Site ID'}
                                            variant="outlined"
                                            closeMenuOnSelect={true}
                                            isSearchable={true}
                                            isClearable={true}
                                            options={siteIdList}
                                            getOptionLabel={option => `${option.site_id}`}
                                            getOptionValue={option => `${option.site_id}`}
                                            hideSelectedOptions={true}
                                            backspaceRemovesValue={false}
                                            menuPlacement="bottom"
                                            value={siteIdList.filter(({ site_id }) => site_id == props.siteIdSelect)}
                                            onChange={e => props.handleSiteSelect(e)}
                                        />
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.siteId?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mr-3">
                        <div className='d-flex justify-content-end'>
                            <FormControl className="selectCustomDesign" fullWidth margin="dense" variant="outlined" style={{ 'width': '182px', 'zIndex': '2' }}>
                                <Controller
                                    className="input-control"
                                    name="groups"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            placeholder={'Provider Add Grouping'}
                                            variant="outlined"
                                            isMulti
                                            closeMenuOnSelect={true}
                                            isSearchable={true}
                                            isClearable={true}
                                            options={providerAddressGroupsData}
                                            getOptionLabel={option => `${option.ppg_address_grouping}`}
                                            getOptionValue={option => `${option.ppg_address_grouping}`}
                                            hideSelectedOptions={true}
                                            backspaceRemovesValue={false}
                                            menuPlacement="bottom"
                                            value={providerAddressGroupsData.filter(obj => props.providerAddressGroupsSelect.includes(obj.ppg_address_grouping))}
                                            onChange={e => props.handleProviderAddressGroupsSelect(e)}
                                        // menuIsOpen={true}
                                        />
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.groups?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mr-3" >
                        <div className="d-flex justify-content-end" >
                            <FormControl className="selectCustomDesign" fullWidth margin="dense" variant="outlined" style={{ 'width': '150px', 'zIndex': '2' }}>
                                <Controller
                                    className="input-control"
                                    name="cityId"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            placeholder={'Select City'}
                                            variant="outlined"
                                            closeMenuOnSelect={true}
                                            isSearchable={true}
                                            isClearable={true}
                                            options={cityList}
                                            getOptionLabel={option => `${option.site_city}`}
                                            getOptionValue={option => `${option.site_city}`}
                                            hideSelectedOptions={true}
                                            backspaceRemovesValue={false}
                                            menuPlacement="bottom"
                                            value={cityList.filter(({ site_city }) => site_city == props.citySelect)}
                                            onChange={e => props.handleCitySelect(e)}
                                        />
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.cityId?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mr-3">
                        <div className="d-flex justify-content-end" >
                            <FormControl className="selectCustomDesign" fullWidth margin="dense" variant="outlined" style={{ 'width': '150px', 'zIndex': '2' }}>
                                <Controller
                                    className="input-control"
                                    name="stateId"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            placeholder={'Select Address'}
                                            variant="outlined"
                                            closeMenuOnSelect={true}
                                            isSearchable={true}
                                            isClearable={true}
                                            options={siteAddressList}
                                            getOptionLabel={option => `${option.site_address}`}
                                            getOptionValue={option => `${option.site_address}`}
                                            hideSelectedOptions={true}
                                            backspaceRemovesValue={false}
                                            menuPlacement="bottom"
                                            value={siteAddressList.filter(({ site_address }) => site_address == props.siteAddressSelect)}
                                            onChange={e => props.handleAddressSelect(e)}
                                        />
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.stateId?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mr-3">
                        <div className="d-flex justify-content-end" >
                            <FormControl className="selectCustomDesign" fullWidth margin="dense" variant="outlined" style={{ 'width': '150px', 'zIndex': '2' }}>
                                <Controller
                                    className="input-control"
                                    name="zipId"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            placeholder={'Select Zip'}
                                            variant="outlined"
                                            closeMenuOnSelect={true}
                                            isSearchable={true}
                                            isClearable={true}
                                            options={zipList}
                                            getOptionLabel={option => `${option.site_zip}`}
                                            getOptionValue={option => `${option.site_zip}`}
                                            hideSelectedOptions={true}
                                            backspaceRemovesValue={false}
                                            menuPlacement="bottom"
                                            value={zipList.filter(({ site_zip }) => site_zip == props.zipSelect)}
                                            onChange={e => props.handleZipSelect(e)}
                                        />
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.zipId?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>
                    </div>
                </span>
            </div>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        userName: state.auth?.userDetails?.userEmail,
        route: state.route.route,
        moduleSelect: state.moduleFilter.moduleSelect,
        yearSelect: state.moduleFilter.yearSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        aweCitySelect: state.moduleFilter.aweCitySelect,
    };
}
export default connect(mapStateToProps, { signOut, currentRoute, currentAWESiteSelect, currentAWEProviderGroupingSelect, currentAWECitySelect, currentAWEAddressSelect, currentAWEZipSelect })(AwvTabHeader);
