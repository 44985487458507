import React, { useState, useEffect } from "react";
import AWVApi from '../../../assets/constants/AWVRafservice.Instance';
import AWVServerSideGridTable from "../../../components/TableGrids/AWVServerSideGridTable.table";
import AWVExpandGridTable from "../../../components/TableGrids/AWVExpandGridTable.table";
import { HCC, ISSUER_ID } from "../../../assets/constants/string.constants";
import AwvCardComponent from "../../../components/Cards/AwvCard";
import { connect } from 'react-redux';
import AwvSubHeader from '../../../components/home/header/AwvSubHeader';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import Tooltip from '@mui/material/Tooltip';
import { purple, red } from '@mui/material/colors';
import Awvheader from '../../../components/home/header/Awvheader';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AddAWVFileUpload from '../../../components/Dialog/AddAWVFileUpload';
import AreaChartMultiExcelAWV from '../../../components/Charts/AreaChartMultiExcelAWV';
import BarChartDiffDataAWV from '../../../components/Charts/BarChartDiffDataAWV';
import ColumnChartSingleExcelAWV from '../../../components/Charts/ColumnChartSingleExcelAWV';
import ColumnChartStackAWV from '../../../components/Charts/ColumnChartStackAWV';
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const AnalyticsSummary = (props) => {

    const [totalAWVVisitOverview, setTotalAWVVisitOverview] = useState();
    const [selectAWVVisitMonth, setSelectAWVVisitMonth] = useState('1');
    const [chartAWVVisitRate, setChartAWVVisitRate] = useState([]);
    const [chartCumulativeAllYear, setChartCumulativeAllYear] = useState('');
    const [chartOverAllVisitYear, setChartOverAllVisitYear] = useState([]);
    const [formDataList, setFormDataList] = useState([]);

    useEffect(() => {
        if(props.yearSelect && props.yearSelect != ''){
            totalAWVVisitOverviewData();
            chartOverAllVisitYearData();
            chartCumulativeAllYearData();
            chartAWVVisitRateData();
            loadformDataTable();
        }       
    }, [props.yearSelect, props.providerGroupSelect, props.lobSelect]);


    const handleSelectAWVVisitMonth = (e) => {
        setSelectAWVVisitMonth(e.target.value);
    }

    const loadformDataTable = () => {
        // setFormDataList([
        //     {
        //         'id': "1",
        //         'provider_group': "Group Name",
        //         'total_members': "36",
        //         'awv_progress': "40",
        //         'awv_spending': "#DS-9574",
        //         'awv_per_month': "360",
        //     },
        // ]);

        let queryStr = '';
        if (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL')
            queryStr = '&provider_group=' + props.providerGroupSelect;
        AWVApi.get('/get-provider-awe-detail?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + queryStr)
            .then(res => {
                if (res.data) {
                    setFormDataList(res.data);
                } else {
                    setFormDataList([]);
                }
            })
            .catch(err => {
                console.log(err)
            })
    }
    const formDataColumns = [
        {
            name: "Provider_group",
            label: "PROVIDER GROUP",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "Total_Member",
            label: "TOTAL MEMBERS",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "Progress percentage",
            label: "AWV PROGRESS %",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ minWidth: 35 }}>
                                <Typography variant="body2" color="text.secondary">{`${Math.round(
                                    value,
                                )}%`}</Typography>
                            </Box>
                            <Box sx={{ width: '100%', ml: 1 }}>
                                <LinearProgress
                                    sx={{
                                        height: 10,
                                        borderRadius: 5,
                                        [`&.${linearProgressClasses.colorPrimary}`]: {
                                            backgroundColor: [800],
                                        },
                                        [`& .${linearProgressClasses.bar}`]: {
                                            borderRadius: 5,
                                            backgroundColor: '#1a9698',
                                        },
                                    }}
                                    color="primary"
                                    variant="determinate" value={value} />
                            </Box>
                        </Box>
                    );
                }
            }
        },
        {
            name: "AWV_Spending",
            label: "AWV SPENDING",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "awv_form_per_month",
            label: "AVG. AWE'S PROCESSED PER MONTH",
            options: {
                filter: true,
                sort: true
            }
        },
    ];

    // Define a sorting function to sort the months based data - Bugfix ID780
    function monthSortKey(month) {
        const monthsOrder = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return monthsOrder.indexOf(month);
    }
  
    const chartAWVVisitRateData = () => {
        let queryStr = '';
        if (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL')
            queryStr = '&provider_group=' + props.providerGroupSelect;
        AWVApi.get('/get-awv-visit-rate?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + queryStr)
            .then(res => {
                if (res.data) {

                    const responseData = res.data;
                    const sortedData = responseData.sort((a, b) => monthSortKey(a["Month"]) - monthSortKey(b["Month"]));
                    setChartAWVVisitRate(sortedData);
                } else {
                    setChartAWVVisitRate();
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const totalAWVVisitOverviewData = () => {
        var providerGroupSelect = (props.providerGroupSelect != 'ALL') ? props.providerGroupSelect : '';
        let queryStr = '';
        if (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL')
            queryStr = '&provider_group=' + props.providerGroupSelect;
        AWVApi.get('/awv_total_visit?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + queryStr)
            .then(res => {
                if (res.data) {
                    setTotalAWVVisitOverview(res.data);
                } else {
                    setTotalAWVVisitOverview();
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const chartCumulativeAllYearData = () => {
        var providerGroupSelect = (props.providerGroupSelect != 'ALL') ? props.providerGroupSelect : '';
        let queryStr = '';
        if (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL')
            queryStr = '&provider_group=' + props.providerGroupSelect;
        AWVApi.get('/awv_cumulative_visits?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + queryStr)
            .then(res => {
                if (res.data) {
                    setChartCumulativeAllYear(res.data);
                } else {
                    setChartCumulativeAllYear('');
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const chartOverAllVisitYearData = () => {
        var providerGroupSelect = (props.providerGroupSelect != 'ALL') ? props.providerGroupSelect : '';

        let queryStr = '';
        if (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL')
            queryStr = '&provider_group=' + props.providerGroupSelect;

        AWVApi.get('/awv_overall_visit?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + queryStr)
            .then(res => {
                if (res.data) {
                    const responseData = res.data;

                    // Convert the object into an array of key-value pairs, then sort it based on the custom sorting function
                    const sortedDataBasedOnMonth = Object.entries(responseData).sort((a, b) => monthSortKey(a[0]) - monthSortKey(b[0]));

                    // Convert the sorted array back into an object (if necessary)
                    const sortedData = Object.fromEntries(sortedDataBasedOnMonth);
                    setChartOverAllVisitYear(sortedData);
                } else {
                    setChartOverAllVisitYear('');
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <>
            <div className="row pr-0 pl-0 mt-3">
                <div className="col-lg-6 pr-2 pl-2">
                    <AwvCardComponent
                        heading={`Total AWE Visits - Overview`}
                        body={
                            <div className="col-lg-12 pr-0 pl-0 row mb-4 mt-4">
                                <div className="col-lg-8 p-0 m-0">
                                    <div className="justify-content-center text-center ml-3 mt-5" style={{ width: '230px' }}>
                                        <CircularProgressbarWithChildren
                                            value={(totalAWVVisitOverview?.complete_visit) ? totalAWVVisitOverview?.complete_visit : 0}
                                            strokeWidth={10}
                                            styles={buildStyles({
                                                pathColor: `#1D9A9B`,
                                            })}
                                        >
                                            <div >
                                                <strong style={{ fontSize: 30, marginTop: 0, color: '#000' }}>{(totalAWVVisitOverview?.complete_visit) ? totalAWVVisitOverview?.complete_visit : 0}</strong> <br />
                                                <strong style={{ fontSize: 15, marginTop: 5, color: '#000' }}>Completed</strong>
                                            </div>
                                        </CircularProgressbarWithChildren >
                                        <div className="circular-content mt-3 ml-3 row">
                                            <strong style={{ fontSize: 12, color: '#000' }}>
                                                <div style={{ height: '13px', width: '20px', backgroundColor: '#1D9A9B', position: 'absolute' }}></div>
                                                <div className="ml-4">Completed</div>
                                            </strong>
                                            <strong className="ml-4" style={{ fontSize: 12, color: '#000' }}>
                                                <div style={{ height: '13px', width: '20px', backgroundColor: '#D6D6D6', position: 'absolute' }}></div>
                                                <div className="ml-4">Pending</div>
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 p-0 m-0" style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                    <div className="mt-2 pb-3 pl-2" style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <strong style={{ fontSize: 25, marginTop: 0, color: '#000' }}>{(totalAWVVisitOverview?.total_visit) ? totalAWVVisitOverview?.total_visit : 0}</strong> <br />
                                        <strong style={{ fontSize: 12, marginTop: 5, color: '#000' }}>Total AWE Visits</strong>
                                    </div>
                                    <div className="mt-2 pb-3 pl-2" style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <strong style={{ fontSize: 25, marginTop: 0, color: '#1D9A9B' }}>{(totalAWVVisitOverview?.complete_visit) ? totalAWVVisitOverview?.complete_visit : 0}</strong> <br />
                                        <strong style={{ fontSize: 12, marginTop: 5, color: '#000' }}>Completed AWE Visits</strong>
                                    </div>
                                    <div className="mt-2 pb-3 pl-2" style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <strong style={{ fontSize: 25, marginTop: 0, color: '#fcb441' }}>{(totalAWVVisitOverview?.pending_visit) ? totalAWVVisitOverview?.pending_visit : 0}</strong> <br />
                                        <strong style={{ fontSize: 12, marginTop: 5, color: '#000' }}>Pending AWE Visits</strong>
                                    </div>
                                    <div className="mt-2 pb-3 pl-2" >
                                        <strong style={{ fontSize: 25, marginTop: 0, color: '#1D9A9B' }}>{(totalAWVVisitOverview?.void) ? totalAWVVisitOverview?.void : 0}</strong> <br />
                                        <strong style={{ fontSize: 12, marginTop: 5, color: '#000' }}>Void</strong>
                                    </div>
                                </div>
                            </div>
                        }
                    />
                </div>
                <div className="col-lg-6 pr-2 pl-2">
                    <AwvCardComponent
                        heading={`AWE Visit Rate By Year`}
                        body={
                            <>
                                <ColumnChartStackAWV data={chartAWVVisitRate} />
                            </>
                        }
                    />
                </div>
            </div>
            <div className="row pr-0 pl-0 mt-3">
                <div className="col-lg-6 pr-2 pl-2">
                    <AwvCardComponent
                        heading={`Cumulative (Sum Of All Months With Year)`}
                        body={
                            <BarChartDiffDataAWV data={chartCumulativeAllYear} />
                        }
                    />
                </div>
                <div className="col-lg-6 pr-2 pl-2">
                    <AwvCardComponent
                        heading={`Overall Visits In a Year`}
                        body={
                            <ColumnChartSingleExcelAWV data={chartOverAllVisitYear} />
                        }
                    />
                </div>
            </div>
            <div className="card mt-5 row">
                <AWVServerSideGridTable
                    title={'Provider Details'}
                    data={formDataList}
                    columns={formDataColumns}
                    filter={false}
                    search={false}
                    pagination={true}
                ></AWVServerSideGridTable>
            </div>
        </>
    )

};
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        lobSelect: state.moduleFilter.lobSelect,
    };
}
export default connect(mapStateToProps)(AnalyticsSummary);