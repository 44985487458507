import React, { useState, useEffect } from "react";
import PPGSummaryServerSide from "../../components/TableGrids/PPGSummaryServerSide.table";
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
//for download functionality
import AWS from 'aws-sdk';
import axios from "axios";
const aws = require('aws-sdk');

const DownloadDiagnosis = (props) => {

    const [reportName, setReportName] = useState('');
    const fetchReports = () => {
        (async function () {

            const setTableDataList = [];
            let keyInsideFolder = '';
            let monthForDownloadFile = ''
            var s3 = new AWS.S3({
                signatureVersion: "v4"
            });
            try {
                AWS.config.update({
                    accessKeyId: localStorage.getItem("access_key"),
                    secretAccessKey: localStorage.getItem("secret_key"),
                    region: process.env.REACT_APP_REGION
                });
                const s3d = new aws.S3();
                const response = await s3d.listObjectsV2({
                    Bucket: props.bucketName,
                }).promise();
                var key = props.bucketKey 
            
                const json_format = JSON.stringify(response.Contents)
                const stringify = JSON.parse(json_format)
                for (var i = 0; i < stringify.length; i++) {
                    if (stringify[i]['Size'] != 0) {
                        setTableDataList.push(stringify[i]);
                    }
                }
                /* setTableDataList.forEach(element => {
                    keyInsideFolder = element.Key.substr((((element.Key)).lastIndexOf('/') + 1));
                }) */
                //Calling current date function, changes for getting 3 char from Month
                setTableDataList.forEach(element => {
                    if(element.Key.includes(key))
                    keyInsideFolder = element.Key.substr((((element.Key)).lastIndexOf('/') + 1));
                })
                //Calling current date function, changes for getting 3 char from Month
                if (keyInsideFolder != "") {
                    const after_ = keyInsideFolder.substring(keyInsideFolder.indexOf('.'));
               
                }

                setReportName(keyInsideFolder)

            } catch (e) {
                console.log('our error ', e)
            }
        })();
    }
    const handleDownload = () => {
        (async function () {
            try {
                AWS.config.update({
                    accessKeyId: localStorage.getItem("access_key"),
                    secretAccessKey: localStorage.getItem("secret_key"),
                    region: process.env.REACT_APP_REGION
                });
                ;
                var s3 = new AWS.S3({
                    signatureVersion: "v4"
                });
                s3.setupRequestListeners = (request) => {
                    request.on('build', (req) => {

                        req.httpRequest.headers['x-amz-date'] = process.env.REACT_APP_KeyID;
                    });
                }

            } catch (e) {
                console.log('our error ', e)
            }
            
            var key = props.bucketKey  + reportName
            var presignedGETURL = s3.getSignedUrl('getObject', {
                Bucket: props.bucketName,
                Key: key
            });
        
            window.location=presignedGETURL;
        })();
    }
    useEffect(() => {
        fetchReports();
    }, [props.year, props.moduleSelect])
    return (
        <div style={{zIndex: '1', left: '79%',borderRight: '1px solid', height:'35px'}}>
            <Button onClick={handleDownload} ><b> {props.name}</b> </Button>

        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
    };
}
export default connect(mapStateToProps)(DownloadDiagnosis);
