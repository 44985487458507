import React, { useState, useEffect } from "react";
import ServerSideGridTable from '../../components/TableGrids/ServerSideGridTable.table';
import { anchorTag, reviewButton } from '../../assets/constants/customRender';
import CardComponent from '../../components/Cards/card';
import {getAcaYearList, getAcaYear} from '../../assets/constants/yearList'; 
 
import  {MEMBER_ID} from  '../../assets/constants/string.constants';
 
import api from '../../assets/constants/Rafservice.Instance';
import { connect } from 'react-redux';
import PhysicianNote from '../../components/PhysicianNotePopup/PhysicianNote';
 

const  ACACRProviderHCCSummary = (props) =>{
    const [year] = useState(props.yearSelect);
    const [hcc, setHcc] = useState(66);
    const[modalBody, setModalBody]	= useState({});
  const [memberId,setMemberId] = useState(localStorage.getItem(MEMBER_ID));
  

 
const  MemberCol =   [
    
    {
        name : "HCC",
        label : "HCC",
         
    },
    { 
        name : "DOCUMENT",
        label : "Document",
         
    },
    {
        name : "DOS",
        label : "DOS" 
        
    },
    {
        name : "PROVIDER",
        label : "Physician"
    },
    {
        name : "REPORTING_TYPE",
        label : "Type"
    },
    {
        name : "RESULT",
        label : "Result"
    },
    {
        name : "ButtonColumn",
        label:"View Coding Opportunity",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            // console.log(value,tableMeta);
            return (
                <PhysicianNote
                 value={value}
                 tableMeta={tableMeta}
                 updateValue={updateValue}
                 modalBody={modalBody}
                 >
                </PhysicianNote>
            )
         }
        }
         
    }   
  
];
const memberTableClick = (rowdata, colData, cellMeta) =>{
      if(cellMeta.colIndex === 6){
          // console.log("hcc :" + rowdata[cellMeta.rowIndex].HCC);
       setHcc(rowdata[cellMeta.rowIndex].HCC);
       

        //history.push('/layout/acaReportedHccSummary');
    }
   
};


useEffect(() =>{
    loadMetricTable();
},[hcc]);

const loadMetricTable = () =>{
    api.get('/codingOppurtunities/'+memberId+'/ICD-10/'+hcc)
    .then(res=>{
         
          setModalBody(res);
          // console.log(res);
        // console.log(modalBody);
    })
    .catch(err=>{
        // console.log(err);
    })

    api.get('/codingOppurtunities/QMXM00000279809/ICD-10').then((res) => {
        // console.log('Array list', res)
    })
}


 
return(
    <div className="coding">
         
          <CardComponent
          heading={`Coding Opportunity- Year : ${year} - Member : ${memberId}`}
          body={
            
          <ServerSideGridTable
              url={`/ProviderHCCDetailsALL/year/${year}/member/${memberId}`}
            columns={MemberCol}
            tableClick={memberTableClick}         
        ></ServerSideGridTable>
          }>              
          </CardComponent>
          
        </div>
);
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(ACACRProviderHCCSummary);
