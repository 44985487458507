import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers';
import reduxThunk from 'redux-thunk';
// import logger from 'redux-logger'

const configureStore = () => {
    const store = createStore(
        rootReducer,
        window.__REDUX_DEVTOOLS_EXTENSION__
            ? compose(
                // applyMiddleware(reduxThunk, logger),
                applyMiddleware(reduxThunk),
                window.__REDUX_DEVTOOLS_EXTENSION__(),
            )
            : applyMiddleware(reduxThunk),
            // : applyMiddleware(reduxThunk, logger),
    );
    return store;
}
export default configureStore;