import React, { useState, useEffect } from "react";
import Chart from "react-google-charts";

const LineChart = (props) => {
    var { data, chartType, width, height, className } = props;
    // const stly ={'marginTop':'100px'}
    useEffect(() => {        
        // console.log(props);
    }, [props.data]);

    return (
      <div style={{'cursor':'pointer'}}>
        {data.length > 1?
          <Chart
              width={width}
              height={height}
              className={className}
              chartType={chartType}
              loader={<div>Loading Chart</div>}
              data={data}
              options={{ 
                  isStacked: true,
                  curveType: 'function',
                  legend: { position: 'top' },
                  is3D: true,
                  vAxes: [
                      {
                        title: "Risk Score",
                        minValue: 0
                      },
                      {
                        title: "Year-On-Year Change",
                        minValue: 0
                      }
                    ],
                    series: {
                      0: { targetAxisIndex: 0 },
                      1: { targetAxisIndex: 1 }
                    }
              }}
              rootProps={{ 'data-testid': '4' }}
              />
        :
          <h6 className="text-center">No records found!..</h6>
        }
      </div>
        
    )
}
export default LineChart;