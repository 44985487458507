import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import WidgetCard from "../../components/Cards/WidgetCard";
import SingleVerticalBarChart from '../../components/Charts/SingleVerticalBar.Chart';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag, hccSeperator, numberWithCommas } from '../../assets/constants/customRender'
import history from "../../history";
import { MEMBER_ID, ISSUER_ID, HCC, WIDGET_TYPES, REPORTED_INSIGHTS, MEMBER_NAME, CARRIER_ID, PLAN_ID, GENDER, RAF_RANGE } from "../../assets/constants/string.constants";
import { percentFormat } from "../../assets/helpers/formats.helpers";
import CardComponent from "../../components/Cards/card";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import GChart from '../../components/GChart/GChart';
import GChartClick from '../../components/GChart/GChartClick';
import IssuersACA from './ACAIssuers.page';
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import SubHeader from '../../components/home/header/subHeader';
import Link from '@material-ui/core/Link';
import { connect } from 'react-redux';
//import google from "react-google-charts";
const ReportedMemberSummaryACA = (props) => {
    const [HccChartData, setHccChartData] = useState({});
    const [WidgetDetails, setWidgetDetails] = useState({});
    const [cardToggle, setcardToggle] = useState(true);
    const [ChartData, setChartData] = useState([]);
    const [issuerData, setIssuerData] = useState([]);
    const [IssuerId, setIssuerId] = useState("");
    const [riskChartData, setRiskChartData] = useState([]);
    const [year, setYear] = useState(props.yearSelect);
   
    let sliderRangeInt = 0;
    let dataIndex = 0;

    const backGround = [
        { backgroundColor: "#AAB7B8" },
        { backgroundColor: "#EB984E" },
        { backgroundColor: "#58D68D" },
        { backgroundColor: "#5DADE2" },
        { backgroundColor: "#AF7AC5" },
        { backgroundColor: "#EC7063" }
    ]

    const WidgetDetailsType = [
        WIDGET_TYPES.LOCKICON,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.PERCENT,
        WIDGET_TYPES.SCORE,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.TRENDING,
        WIDGET_TYPES.PERCENT,
    ]

    const type = WidgetDetailsType;
    const widgetStyles = {
        position: 'absolute',
        left: 0,
        //right: 20,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.15,
        color: "white"
    };

    const sliderSettingsAca = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        adaptiveHeight: true,
        variableWidth: true
    };

    const loadRiskMemberIssuer = () => {        
        let newChartData = [];   
            newChartData.push(["Risk Score", "Member Count", { role: 'annotation' }]);
            var ISSUERID = localStorage.getItem(ISSUER_ID);
            if(localStorage.getItem(ISSUER_ID) != ''){
                api.get('/acarafRangeGraph/year/' + getAcaYear() + '/issuer_id/' + btoa(ISSUERID))
                    .then(res => {
                        for (const dataObj of res) {
                            newChartData.push([dataObj.rafRange, dataObj.countOfMembers, dataObj.countOfMembers]);
                        }
                        setRiskChartData(newChartData);
                })
            }

    }

    const loadWidgetDetails = () => {
            api.get('/acasummary/year/' + getAcaYear())
                .then(res => {
                    setWidgetDetails({
                        '# Issuers': res[0].no_of_issuers,
                        '# Members': res[0].no_of_members,
                        'Risk Score': Number(res[0].average_risk_score).toFixed(2),
                        'Y-O-Y Change': percentFormat(res[0].year_on_year_change),
                        '# Members Gap': res[0].no_of_members_with_gap,
                    });
                    sliderRangeInt = 5;
                })
                .catch(err => {
                    // console.log(err);
                });

    };

    const getIcon = (type) => {
        var data = ''
        switch (type) {
            case WIDGET_TYPES.ISSUER:
                data = <AccountBalanceTwoToneIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.MEMBERS:
                data = <PeopleAltIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERCENT:
                data = <ShowChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.SCORE:
                data = <BarChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERSON:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.CHILD:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.ADULT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.INFANT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.REVENUE:
                data = <AttachMoneyIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.COUNT:
                data = <ShoppingCartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.LOCKICON:
                data = <LockOpenIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.TRENDING:
                data = <TrendingUpIcon style={widgetStyles} />
                break;
        }

        return data;
    }

    const loadMemberGapByIssuer = () => {

        let issuer_id = [];
        let no_of_members_with_gap = [];
        let newChartData = [];
            newChartData.push(["Issue", "Count"]);
            api.get('/acaGapGraph/year/' + getAcaYear())
                .then(res => {
                    for (const dataObj of res) {
                        issuer_id.push((dataObj.issuer_id));
                        no_of_members_with_gap.push(dataObj.no_of_members_with_gap);
                        newChartData.push([dataObj.issuer_id, dataObj.no_of_members_with_gap]);
                    }
                    setChartData(newChartData);
                    setHccChartData({
                        labels: issuer_id,
                        data: no_of_members_with_gap,
                        dLabel: "Members",
                    });
                })


    }

    const GetRowData = (rowdata) => {
        localStorage.setItem(ISSUER_ID, rowdata[0].ISSUER_ID)
        setIssuerId(rowdata[0].ISSUER_ID);
        loadRiskMemberIssuer();    
    }

    const issuerTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(ISSUER_ID, rowdata[cellMeta.rowIndex].ISSUER_ID);
            setIssuerId(rowdata[0].ISSUER_ID);
        }
    }

    const IssuerColumn = [
        {
            name: "ISSUER_ID",
            label: "Issuer ID",
            options: anchorTag
        },
        {
            name: "NO_OF_MEMBERS",
            label: "Members",
            options: numberWithCommas
        },
        {
            name: "AVERAGE_RISK_SCORE",
            label: "Score"
        },
        {
            name: "YEAR_ON_YEAR_CHANGE",
            label: "Year"
        },
        {
            name: "NO_OF_MEMBERS_WITH_GAP",
            label: "Gap",
            options: numberWithCommas
        },
        {
            name: "PERCENTAGE_GAP",
            label: "% of Gap"
        }
    ]

    const memberColumns = [
        {
            label: "Member ID",
            name: "MEMBER_ID",
            options: anchorTag
        },
        {
            name: "MEMBER_Name",
            label: "Member Name"
        },
        {
            name: "Carrier_ID",
            label: "Carrier ID"
        },
        {
            name: "HCC_POST_HIERARCHY",
            label: "Reported HCC",
            options: hccSeperator
        },
        {
            name: "Risk_Score",
            label: "Risk Score"
        },
        {
            name: "Metal_Tier",
            label: "Metal Tier"
        },
        {
            name: "Risk_Model",
            label: "Risk Model"
        },
        {
            name: "Plan_ID",
            label: "Plan ID"
        }
    ];

    const memberTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(MEMBER_ID, rowdata[cellMeta.rowIndex].MEMBER_ID);
            localStorage.setItem(MEMBER_NAME, rowdata[cellMeta.rowIndex].MEMBER_Name);
            localStorage.setItem(CARRIER_ID, rowdata[cellMeta.rowIndex].Carrier_ID);
            localStorage.setItem(PLAN_ID, rowdata[cellMeta.rowIndex].Plan_ID);
            localStorage.setItem(GENDER, rowdata[cellMeta.rowIndex].Gender);
            history.push('/layout/acaMember', REPORTED_INSIGHTS)
        }
        else if ((cellMeta.colIndex === 3) && (colData.props.children != "")) {
            if (typeof (colData.props.children) == "object") {
                localStorage.setItem(HCC, (cellMeta.event.target.innerText).replace(',', ''));
            }
            else {
                localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].HCC_POST_HIERARCHY);
            }
            localStorage.setItem(ISSUER_ID, rowdata[cellMeta.rowIndex].ISSUER_ID);
            history.push('/layout/acaReportedHcc')
        }
    }

    const loadIssuerDetailsScore = () => {
            api.get('/IssuersDetails/year/' + getAcaYear() +'?iDisplayStart=0&iDisplayLength=10&iSortCol_0=0&sSortDir_0=asc&sSearch=')
            .then(res => {
                if(res.aaData && res.aaData.length > 0){
                    localStorage.setItem(ISSUER_ID, res.aaData[0].ISSUER_ID);
                    setIssuerData({
                        'Issuer ID': res.aaData[0].ISSUER_ID,
                        'Members': res.aaData[0].NO_OF_MEMBERS,
                        'Score':  Number(res.aaData[0].AVERAGE_RISK_SCORE).toFixed(2),
                        'Y-O-Y': res.aaData[0].YEAR_ON_YEAR_CHANGE,
                        'Gap': res.aaData[0].NO_OF_MEMBERS_WITH_GAP,
                        '% of Gap': percentFormat(res.aaData[0].PERCENTAGE_GAP),
                    });
                    GetRowData(res.aaData);
                }
            })
            .catch(err => {
                // console.log(err)
            })

    }

    const HccChartClick = (element) => {
        var activePoints = element;
        var chartData = activePoints[0]['_chart'].config.data;
        var idx = activePoints[0]['_index'];
        var issuer = chartData.labels[idx];
        localStorage.setItem(ISSUER_ID, issuer);
        history.push('/layout/acaIssuerGapsummary');
    };
  
    const elementissuerIDLinkClick = () => {
        history.push('/layout/acaIssuerGapsummary');
    };

    useEffect(() => { 
        setChartData([]);
        loadWidgetDetails();
        loadMemberGapByIssuer();         
        loadIssuerDetailsScore();
        loadRiskMemberIssuer();      
    },  [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect
    ]);

    let sliderRange = Object.entries(WidgetDetails).length / 3;

    sliderRange = parseInt(sliderRange);
    sliderRangeInt = parseInt(sliderRangeInt);
    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={''} />
                        </div>
                      
                        <SlickSlider WidgetDetails={WidgetDetails} getIcon={getIcon} type={type} />
                        

                        {/* {cardToggle && 
                        <div className="card-body container">
                            <div className="card-deck">

                                <Carousel autoPlay={true} interval={100000} infiniteLoop={true}>
                                    {
                                        [...Array(sliderRange + 1)].map((e, i) => {
                                            let el = Object.entries(WidgetDetails);
                                            if (i > 0) {
                                                dataIndex = dataIndex + 5;
                                            }
                                            return (
                                                <div className="card" style={backGround[i++]} key={i}>
                                                    <div className="card-body">
                                                        {el[dataIndex] && type ?
                                                            <div className="relative box">
                                                                <div className="bg1">
                                                                    {el[dataIndex] && type ? getIcon(type[dataIndex]) : null}
                                                                </div>
                                                                <div className="bg1 pl-2 text-left">
                                                                    <p className="main-text">{el[dataIndex] ? el[dataIndex][0] : null}</p>
                                                                    <p className="small-heading">{el[dataIndex] ? el[dataIndex][1] : null}</p>
                                                                </div>
                                                            </div> : null}
                                                        {el[dataIndex + 1] && type ?
                                                            <div className="relative box">
                                                                <div className="bg2">
                                                                    {el[dataIndex + 1] && type ? getIcon(type[dataIndex + 1]) : null}
                                                                </div>
                                                                <div className="bg2 pl-3 text-left">
                                                                    <p className="main-text">{el[dataIndex + 1] ? el[dataIndex + 1][0] : null}</p>
                                                                    <p className="small-heading">{el[dataIndex + 1] ? el[dataIndex + 1][1] : null}</p>
                                                                </div>
                                                            </div> : null}
                                                        {el[dataIndex + 2] && type ?
                                                            <div className="relative box">
                                                                <div className="bg2">
                                                                    {el[dataIndex + 2] && type ? getIcon(type[dataIndex + 2]) : null}
                                                                </div>
                                                                <div className="bg2 pl-3 text-left">
                                                                    <p className="main-text">{el[dataIndex + 2] ? el[dataIndex + 2][0] : null}</p>
                                                                    <p className="small-heading">{el[dataIndex + 2] ? el[dataIndex + 2][1] : null}</p>
                                                                </div>
																										</div> : null}
																										{el[dataIndex + 3] && type ?
                                                            <div className="relative box">
                                                                <div className="bg2">
                                                                    {el[dataIndex + 3] && type ? getIcon(type[dataIndex + 3]) : null}
                                                                </div>
                                                                <div className="bg2 pl-3 text-left">
                                                                    <p className="main-text">{el[dataIndex + 3] ? el[dataIndex + 3][0] : null}</p>
                                                                    <p className="small-heading">{el[dataIndex + 3] ? el[dataIndex + 3][1] : null}</p>
                                                                </div>
																										</div> : null}
																										{el[dataIndex + 4] && type ?
                                                            <div className="relative box">
                                                                <div className="bg2">
                                                                    {el[dataIndex + 4] && type ? getIcon(type[dataIndex + 4]) : null}
                                                                </div>
                                                                <div className="bg2 pl-3 text-left">
                                                                    <p className="main-text">{el[dataIndex + 4] ? el[dataIndex + 4][0] : null}</p>
                                                                    <p className="small-heading">{el[dataIndex + 4] ? el[dataIndex + 4][1] : null}</p>
                                                                </div>
                                                            </div> : null}

                                                    </div>
                                                </div>
                                            )

                                        })
                                    }
                                </Carousel>
                            </div>
                        </div>} */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        {/* <CardComponent
                            heading={'ISSUER(S) DETAILS'}
                            className="issuer-details"
                            body={
                                <ServerSideGridTable
                                    rowData={GetRowData}
                                    url={`/IssuersDetails/year/${year}`}
                                    columns={IssuerColumn}
                                    tableClick={issuerTableClick}></ServerSideGridTable>
                            }></CardComponent> */}
                        <CardComponent
                            heading={'ISSUER(S) DETAILS'}
                            className="issuer-details"
                            body={
                                <div className="box-height-chart">
                                    {/* <hr /> */}
                                    <table>
                                        {Object.entries(issuerData) && Object.entries(issuerData).map((element, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>{element[0]}</td>
                                                    <td className="pr-5 pl-5 pb-2">:</td>
                                                    {element[0] == "Issuer ID"?
                                                        <td>
                                                        <a className="hyperlink" style={{cursor:"pointer"}} href={() => false} onClick={elementissuerIDLinkClick}>
                                                            {element[1]}
                                                        </a>
                                                        </td>
                                                    :
                                                        <td>{element[1]}</td>
                                                    }
                                            
                                                </tr>
                                            )
                                            })
                                        }                                        
                                    </table>
                                
                                </div>
                            }></CardComponent>
                    </div>
                    <br />
                    <div className="col-lg-6">
                        {/* <div className="col-lg-6 gap-issue"> */}
                            <CardComponent
                                heading={`RISK SCORE DISTRIBUTION OF MEMBERS WITH CONDITION`}
                                body={
                                    <div className="box-height-chart">
                                        {/* <GChart data={riskChartData} chartType="ColumnChart"  height="330px" width="100%"></GChart> */}
                                        <GChartClick data={riskChartData} chartType="ColumnChart"  height="330px" width="100%" setStorgeItemName={RAF_RANGE} redirectUrl={'acaRafDrilldown'} hAxisTitle={"RISK SCORE"} yAxistitle={"Member Count"}></GChartClick>
                                    </div>
                            }></CardComponent>
                        {/* </div> */}
                    </div>

                    <div className="col-lg-12 mt-4">
                        <IssuersACA IssuerId={IssuerId} year={props.yearSelect}></IssuersACA>
                    </div>

                    <div className="col-lg-12 mt-4 members mb-4 pr-0">
                        {/* <CardComponent
									heading={'MEMBER LIST'}
									className="members"
									body={
											<ServerSideGridTable
													url={`/ACAStatelevelMembers/year/${year}/max/100`}
													columns={memberColumns}
													tableClick={memberTableClick}
											></ServerSideGridTable>
									}></CardComponent> */}
                    </div>
                </div>
            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
    };
  }
export default connect(mapStateToProps)(ReportedMemberSummaryACA);
