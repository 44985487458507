export const MEMBER_ID = 'MEMBER_ID';
export const MEMBER_NAME = 'MEMBER_NAME';
export const CARRIER_ID = 'CARRIER_ID';
export const PLAN_ID = 'PLAN_ID';
export const ISSUER_ID = 'ISSUER_ID';
export const CLAIM_ID = 'CLAIM_ID';
export const HCC = "HCC";
export const RAF_RANGE = 'Raf_Range';
export const METAL_TIER = 'Metal Tier';
export const RISK_MODEL = 'Risk Model';
export const GAP_OR_REPORTED = 'gap_or_reported';
export const CONDITION_FLAG = 'Condition_flag';
export const REPORTED_INSIGHTS = 'Reported_Insights';
export const PREDICTION = 'Prediction';
export const ENCOUNTERS_SUMMARY = 'Encounters_Summary';
export const GAP_INSIGHTS = 'Gap_Insights';
export const CR_INSIGHTS = 'CR_Insights';
export const UTILIZATION = 'Utilization';
export const HCC_CODE = 'code';
export const LIKELIHOOD_FLAG = 'likelihood_flag';
export const GAP_MODEL = "gap_model";
export const GAP_METAL = "gap_metal";
export const REPORTED_MODEL = "reported_model";
export const REPORTED_METAL = "reported_metal";
export const GAP_ORG_METAL = "gap_org_metal";
export const GAP_ORG = "gap_org";
export const GAP_ISSUER = "gap_issuer";
export const FLAG = "flag";
export const LIKELIHOOD_STATUS = "likelihood_status";
export const REPORTED_HCC = "Reported_hcc";
export const GAP_HCC = "Gap_hcc";
export const GENDER = "Gender";
export const DISCREPANCY_REASON = "discrepancyReason";
export const PROVIDER_ID ="provider_id";
export const RECOMMENDATION_TYPE  ="recommendationType";
export const MAO_RISK_CONDITION_CHART  ="MAORiskConditionChart";
export const MAO_MEMBER_ID  ="MAOMemberId";
export const MAO_Encounter_Type  ="MAOEncounterType";
export const MAO_Total_Encounters  ="MAOTotalEncounters";
export const MAO_Allowed_Disallowed_Period  ="MAOAllowedDisallowedPeriod";
export const MAO_Allowed_Disallowed_Type  ="MAOAllowedDisallowedType";
export const MAO_Allowed_Disallowed_Year  ="MAOAllowedDisallowedYear";
export const MAO_Allowed_Disallowed_Reasoncode  ="MAOAllowedDisallowedReasoncode";
export const MAO_Chart_Review_Member  ="MAOChartReviewMember";
export const MAO_MEMBER_SELECT_TAB  ="MAOMEMBERSELECTTAB";
export const MAO_MEMBER_FORCAST  ="MAOMemberForcast";
export const MAO_EXITING_MEMBER_FORCAST  ="MAOExitingMemberForcast";
export const MAO_TYPE  ="MAO_TYPE";
export const SELECT_YEAR  ="SELECT_YEAR";
export const SELECT_INITIAL  ="SELECT_INITIAL";
export const SELECT_METAL_TIER  ="SELECT_METAL_TIER";
export const SELECT_VIEW_BY  ="SELECT_VIEW_BY";
export const SELECT_PLAN  ="SELECT_PLAN";
export const SELECT_MODEL  ="SELECT_MODEL";
export const SELECT_DISTRIBUTION  ="SELECT_DISTRIBUTION";
export const SELECT_KPI  ="SELECT_KPI";
export const SERVICE_CATEGORY = "SERVICE_CATEGORY";
export const KPI_CATEGORY = "KPI_CATEGORY";
export const KPI_PPG = "KPI_PPG";
export const Plan_Performance_Dashboard = "Plan_Performance_Dashboard";
export const Provider_Group = "Provider_Group";
export const Provider_Performance = "Provider_Performance";
export const CONDITION="condition";
export const SELECT_LOB="SELECT_LOB";
export const SELECT_PROVIDER_GROUP="SELECT_PROVIDER_GROUP";
export const yearLimit =2;
export const Analytics_View = "Anakytics_View";
export const AWV_Provider_Reporting_View = "AWV_Provider_Reporting_View";
export const SELECT_PGP  ="SELECT_PGP";
export const SELECT_PCP  ="SELECT_PCP";
export const SELECT_AWE_SITE = 'SELECT_AWE_SITE';
export const SELECT_AWE_PROVIDER_GROUPING = 'SELECT_AWE_PROVIDER_GROUPING';
export const SELECT_AWE_CITY = 'SELECT_AWE_CITY';
export const SELECT_AWE_ADDRESS = 'SELECT_AWE_ADDRESS';
export const SELECT_AWE_ZIP = 'SELECT_AWE_ZIP';
// Declare const for select site id and ppg for using in localstorage.
export const SELECT_SITE_ID = 'SELECT_SITE_ID';
export const PROVIDER_GROUP_LIST = 'PROVIDER_GROUP_LIST';

export const WIDGET_TYPES = {
    ISSUER : 'Issuer',
    MEMBERS : 'Members',
    SCORE : 'Score',
    PERCENT : 'Percent',
    PERSON : 'Person',
    CHILD : 'Child',
    ADULT : 'Adult',
    INFANT : "Infant",
    REVENUE : 'Revenue',
    COUNT : 'Count'
}

export const CLAIM_ID_DESCRIPTION="Unique ClaimID referenced in the system and loaded.  The link should allow to drill down to service line details. ";
export const CLAIM_TYPE_DESCRIPTION="Claim Type which identifies the claim as Professiona, Inst-Inpatient, or Inst-Outpatient";
export const SERVICE_START_DATE_DESCRIPTION="Dates of service Associated with the claim - Begin Date of Service. Format YYYY-MM-DD";
export const SERVICE_END_DATE_DESCRIPTION="Dates of service Associated with the claim - Begin Date of Service. Format YYYY-MM-DD";
export const BILL_TYPE_CODE_DESCRIPTION="BillType code associated with the claim. Only Institutioanl Inpatient and Outpatient claims will have the BillTypeCode. For Professional Claims Mark it as NA";
export const PLACE_OF_CODE_DESCRIPTION="Place of Serice Code and Descrition of Place of Service Code. For inpatient claims this is derived from the 1nd digit of Bill Type Code";
export const PROVIDER_ID_DESCRIPTION="Provider Number ( NPI) reported on the claim. This will be Billing Provider ID reported on input claim";
export const PROVIDER_NAME_DESCRIPTION="Billing Provider Name";
export const CLAIM_STATUS_DESCRIPTION="status of the claim from Submission Not Submitted/Accepted/Rejected. Derived from Claim Submission Status column";
export const RENDERING_PROVIDER_DESCRIPTION="Include ID&Name together Like 1234561111-ORVERLAKE. Derive from 2 fields in claims table Rendering Provider ID and name. If not available at claim level need to derive from service line and store it";
export const CHARGE_AMOUNT_DESCRIPTION="Total Charge Amount Associated with the claim.Format is $xxx,xxx.xx";
export const ALLOWED_AMOUNT_DESCRIPTION="Total Allowed Amount reported on the claim. If Missing default it as NA. Format is $xxx,xxx.xx";
export const PAID_AMOUNT_DESCRIPTION="Total Paid Amount reported on the claim. If missing default to NAFormat is $xxx,xxx.xx";
export const MEMBER_ID_DESCRIPTION="MemberID reported on the claim. This value is required when we download the data. Since we display details at member level, we don’t need to report it";
export const MEMBER_NAME_DESCRIPTION="Name of the member";
export const NO_OF_SERVICELINE_DESCRIPTION="Total Number of Service Lines for the claim";
export const METRIC_GRID  ="METRIC_GRID";
export const METRIC_EXPAND  ="METRIC_EXPAND";
export const REPORTED_MEMBER_SUMMARY = 'REPORTED MEMBER SUMMARY';
export const PPG_SUMMARY = 'PPG SUMMARY';
export const GAP_MEMBER_SUMMARY = 'GAP MEMBER SUMMARY';
export const MEMBER_WITH_RAF = 'MEMBER WITH RAF';
export const MEMBER_WITH_GAP = 'MEMBER WITH GAP';