/**
 * Creating js file for to load column dynamic
 * Story - PD-40
 * @author ih-sonal
 *
 */
import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables"
import PropTypes from "prop-types";
import api from '../../assets/constants/AWVMemberDetails.RafService.Instance';
import { Button, CircularProgress } from '@material-ui/core';
import {
    createMuiTheme,
    MuiThemeProvider,
} from "@material-ui/core/styles";

import { forEach } from "lodash";

const PPGSummaryServerSide = (props) => {

    const { data, url, onTableClick, defaultSortCol, defaultSortDir, downloadFileName = false, columns ,className} = props;

    const [rowdata, setRowdata] = useState([]);
    const [isDataGridLoading, setIsDataGridLoading] = useState(false);
    const [count, setCount] = useState(1);
    const [RowPerPage, setRowPerPage] = useState(10);
    const [Page] = useState(0);
    const [sortOrder] = useState({});
    const [col] = useState(defaultSortCol ? defaultSortCol : 0);
    const [Dir] = useState(defaultSortDir ? defaultSortDir : "asc");
    const [Search] = useState(['undefined']);
    const [ColumnSearch] = useState("");
    const [TableAfterSearch, setTableAfterSearch] = useState(false);
    const [rowdataCSV, setrowdataCSV] = useState(['Downloading Data...']);
    const [columnName, setColumnName] = useState(['provider_group']);
    const [isLoading, setIsLoading] = useState(true);
   // const [filteredValue, setFilteredValue] = useState([]);
    var filteredValue = []

    const loadTable = (pageNo, rowsOnPage, col, dir, searchtxt, search, data,ColumnSearch) => {
        setIsLoading(true);
        api.get(`${url}iSortCol_0=${col}&sSortDir_0=${dir}&iDisplayStart=${pageNo * rowsOnPage}&iDisplayLength=${rowsOnPage}&ColumnSearch=${(search)}`)
            .then(res => {
                setRowdata(res.data);
                setCount(res.data[0].total_count);
                setIsLoading(false);

            })
            .catch(err => {
                setIsLoading(false);
                console.log(err);
            })

    }
    const loadColumn = () => {
        let values = [];
        if (rowdata != null && rowdata.length > 0) {
            rowdata.forEach((element, index) => {
                for (let [key, value] of Object.entries(element)) {
                    if (!values.includes(key)) {
                        values.push(key)
                    }
                }
            });
            values.pop();
            setColumnName(values)
        }
    }

    const downloadTable = (pageNo, rowsOnPage, col, dir, searchtxt, search, data,ColumnSearch) => {
        api.get(`${url}iSortCol_0=${col}&sSortDir_0=${dir}&iDisplayStart=${pageNo * rowsOnPage}&iDisplayLength=${rowsOnPage}&ColumnSearch=${(search)}`)
            .then(res => {
                setrowdataCSV(res.data);
            })
            .catch(err => {
                console.log(err);
            })

    }

    const ObjectToFindValue = (objectName, keyName) => {
        // console.log('ObjectToFindValue');
        for (let [key, value] of Object.entries(objectName)) {
            if (key === keyName) {
                // console.log(`${key}: ${value}`);
                return value;
            }
        }
    }

    const DefaultOptions = {
        selectableRows: false,
        pagination: props.pagination ? props.pagination : false,
        print: props.print ? props.print : false,
        searchOpen: props.searchOpen ? props.searchOpen : false,
        filter: props.filter ? props.filter : false,
        filterType: 'textField',
        searchIcon: props.searchIcon ? props.searchIcon : false,
        download: props.download ? props.download : false,
        search: props.search ? props.search : false,
        viewColumns: props.viewColumns ? props.viewColumns : false,
        downloadOptions: {
            filename: 'Download.csv',
            filterOptions: { useDisplayedColumnsOnly: true },
            useDisplayedRowsOnly: true,
        },
        // responsive: "scrollMaxHeight",
        responsive: 'stacked',
        tableBodyHeight: 'auto',
        tableBodyMaxHeight: '900px',
        onCellClick: (colData, cellMeta) => {
            //    console.log(colData,cellMeta);
            if (props.onTableClick) {
                onTableClick(colData, cellMeta, data, columns);
            }
        },
        count: count,
        onDownload: (buildHead, buildBody, columns, data) => {
          //  downloadTable(Page, count, columnName[col], Dir, Search);
            let values = [];
            var AllDataArray = []
            let rowArray = [];
            columns.forEach(columnInside => {

                values.push(columnInside.name)
            })
            rowdataCSV.forEach((element, index) => {
                rowArray = [];
                values.forEach((el, i) => {
                    rowArray.push(ObjectToFindValue(element, el));
                });
                AllDataArray.push({ index: index, data: rowArray });
            });
            //data = toArray(filterRowObject)

            return "\uFEFF" + buildHead(columns) + buildBody(AllDataArray);
        },
        onTableChange: (action, tableState) => {
            let filterColumnObject = [];
            let columnArray = [];
            //multiple filter
            if (props.filter == true && action == 'filterChange' && tableState.columns && tableState.filterList) {
                tableState.columns.forEach(element => {
                    if (element.name) {
                        columnArray.push(element.name)
                    }
                });
                if (columnArray && columnArray.length > 0) {
                    tableState.filterList.forEach((element, index) => {
                        if (element && element[0]) {
                            var key = columnArray[index]+'=' + "\""+ element[0]+"\"";
                            //console.log('key ',key)
                            filterColumnObject.push(key)
                        }
                       
                    });
                }
                // console.log("filterColumnObject "+filterColumnObject)
            }
            filteredValue.push(filterColumnObject)
            switch (action) {
                case "changePage":
                    loadTable(tableState.page, tableState.rowsPerPage, columnName[col], Dir, Search);
                    break;
                case "sort":
                    loadTable(tableState.page, tableState.rowsPerPage, columnName[tableState.activeColumn], tableState.sortOrder.direction, Search, filterColumnObject.length > 0 ? JSON.stringify(filterColumnObject) : ColumnSearch);
                    break;
                case "changeRowsPerPage":
                    setRowPerPage(tableState.rowsPerPage);
                    loadTable(tableState.page, tableState.rowsPerPage, columnName[col], Dir, Search, filterColumnObject.length > 0 ? JSON.stringify(filterColumnObject) : ColumnSearch);
                    break;
                case "search":
                    setTableAfterSearch(true);
                    
                    loadTable(tableState.page, tableState.rowsPerPage, columnName[col], Dir, tableState.searchText ? tableState.searchText : Search, ColumnSearch);
                    break;
                case "filterChange":
                    setTableAfterSearch(true);
                    var arrayObject=filterColumnObject;
                    loadTable(tableState.page, tableState.rowsPerPage, columnName[col], Dir, tableState.searchText ? tableState.searchText : Search, arrayObject.length>0 ? arrayObject : ColumnSearch);
                    break;
                case "onSearchClose":
                    // setTableAfterSearch(false);
                    // loadTable(tableState.page,tableState.rowsPerPage,col,Dir,Search,ColumnSearch);
                    break;
            }
        },
        serverSide: true,
    }
    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MuiTableCell: {
                    head: {
                        backgroundColor: "rgb(82, 108, 236) !important",
                        color: "rgb(239, 240, 247) !important",
                        fontWeight: "bold !important"
                    },
                    sortOrder: {
                        backgroundColor: "rgb(82, 108, 236) !important",
                        color: "rgb(239, 240, 247) !important",
                        fontWeight: "bold !important"
                    }
                },
                MUIDataTableSelectCell: {
                    headerCell: {
                        backgroundColor: "rgb(82, 108, 236) !important",
                        color: "rgb(239, 240, 247) !important",
                        fontWeight: "bold !important"
                    }
                },
                MUIDataTableHeadCell: {
                    sortActive: {
                        color: "rgb(239, 240, 247) !important",
                    }
                }
            }
        });


    useEffect(() => {
        loadTable(Page, RowPerPage, columnName[col], Dir, Search);

    }, [url]);

    useEffect(() => {
       downloadTable(Page, count, columnName[col], Dir, Search);

    }, [count]);

    useEffect(() => {
        loadColumn()
    }, [rowdata]);

    return (
        <div className={className} >
            {(rowdata != null && rowdata.length > 0) ?
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        data={rowdata}
                        columns={columnName}
                        options={DefaultOptions}
                        className="card Dynamic_detail_table"
                    />
                </MuiThemeProvider>
                :
                isLoading ? <div style={{ position: 'absolute', top: '50%', left: '50%', height: '50%' }}>
                    <CircularProgress />
                </div > : <h6 className="text-center" >No records found!..</h6>
            }
        </div>
    )
}

PPGSummaryServerSide.propTypes = {
    columns: PropTypes.array,
    url: PropTypes.string,
    tableClick: PropTypes.func,
    defaultSortCol: PropTypes.number,
    defaultSortDir: PropTypes.string
}

export default PPGSummaryServerSide;
