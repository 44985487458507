import api from './Rafservice.Instance';
import { 
    SELECT_YEAR, SELECT_INITIAL, 
    SELECT_METAL_TIER, SELECT_VIEW_BY,
    SELECT_PLAN, SELECT_MODEL
} from "../../assets/constants/string.constants";

let MetalTier_list = [];
let MetalTier_select = '';
export const MetalTierList = () =>{

    // api.get('/aca_yearList/')
    // .then(res=>{
        SetMetalTierList(['All','Silver','Gold','Platinum']); 
        if(localStorage.getItem(SELECT_METAL_TIER) && localStorage.getItem(SELECT_METAL_TIER) != ''){
            // localStorage.setItem('YEAR', res[0]);            
            // SetAcaYear(res[0]);
            SetMetalTierSelect(localStorage.getItem(SELECT_METAL_TIER)); 
        }else{
            localStorage.setItem(SELECT_METAL_TIER, 'All');            
            SetMetalTierSelect('All'); 
        }  
    // })
    // .catch(err=>{
    //     // console.log(err);
    // })
}

export const SetMetalTierList = (data) =>{
    MetalTier_list = data;
}

export const SetMetalTierSelect = (data) =>{
    MetalTier_select = data;
    localStorage.setItem(SELECT_METAL_TIER, data);
}

export const getMetalTierList = () =>{
    return MetalTier_list;
}

export const getMetalTierSelect = () =>{
    return MetalTier_select;
}