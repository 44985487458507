import React, { useRef, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import AWS from 'aws-sdk';
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar } from '../../../store/actions';
import AWVApi from '../../../assets/constants/AWVRafservice.Instance';
import Button from '../../layout/aweLayout/Button';
import { Code } from "@material-ui/icons";
import {LogFileUploadToS3} from '../../../assets/constants/LogFileUploadToS3'
const aws = require('aws-sdk');

const fs = require('fs');

const AWVNLPInsightPDF = (props) => {
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
    const [editFormData, setEditFormData] = useState();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isDataGridCheked, setIsDataGridCheked] = useState(false);
    const [logMessages, setLogMessages] = useState([]); // Array to store log messages
     const writeToLogFile = (message) => {
        setLogMessages(prevLogMessages => [...prevLogMessages, `${new Date().toISOString()} - ${message}`]);
    };
    const [generateLog, setGenerateLog] = useState(false);


    useEffect(() => {
        writeToLogFile("Starting execution of AWVNLPInsightPDF component");
        writeToLogFile("/get-awe-processed-record-details parameter " + 'year:' + props.yearSelect + '&organisation_id=1 lob= ' + props.lobSelect + ' cin= ' + props.cin + ' awe_display_id= ' +  props.aweMemberId);
        AWVApi.get('/get-awe-processed-record-details?year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + '&cin=' + props.cin + '&awe_display_id=' +  props.aweMemberId)
            .then(res => {
                if (res.data && res.data[0]) {
                    setEditFormData(res.data[0]);
                    localStorage.setItem('isRecordPresent', res.data[0].present_in_coder_workbench);
                    localStorage.setItem('cwId', res.data[0].id_of_coder_workbench);
                    setIsLoading(false);
                    writeToLogFile("Data retrieved successfully");
                } else {
                    setEditFormData();
                    setIsLoading(false);
                    writeToLogFile("Error in retrieving data... " + res );
                }
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
                writeToLogFile("Error occurred while retrieving data. Error=  " + err);
            });
            
        writeToLogFile("Form data initialized");
        writeToLogFile("Setting local storage items");
        writeToLogFile("AWVNLPInsightPDF component fully initialized");
    }, [ props.aweMemberId, props.cin, props.yearSelect]);

    useEffect(() => {
        // Generate and download log file when logMessages have been updated
        if (logMessages.length > 0 && generateLog === true) {
        LogFileUploadToS3(logMessages, localStorage.getItem('access_key'), localStorage.getItem('secret_key'), process.env.REACT_APP_LOG_BUCKET, "NLP_Processed_Record")
        }
    }, [logMessages]); 

    const handleClick = (open, form_url, cin, type, aweId, awe_display_id) => {
        setAnchorEl('');
        props.currentAWERightSidebarType({ 'type': type, 'cin': cin, 'status': '', 'form_url': form_url, 'aweId': aweId, 'awe_display_id':awe_display_id });
        props.currentAWERightSidebar(open);
        setIsDataGridCheked(false);
        writeToLogFile("Handle click executed.., Type: " + type + ' cin: ' + cin + ' form_url: ' + form_url + ' aweId: '+ aweId + ' awe_display_id: ' + awe_display_id ); // Log the execution of handleClick
        setGenerateLog(true)
    };
    return (
        <>
            {typeof props.cin === 'undefined' ?
                <Button
                    disabled={true}
                    type="button"
                    className="btn-sm">
                    NLP Processed Record
                </Button>
                :
                <Button 
                    disabled={typeof editFormData?.processed_pdf === 'undefined' || editFormData?.processed_pdf == null}
                    onClick={() => handleClick(true, editFormData?.processed_pdf, props.cin, 'aweProcessedRecord', editFormData.awv_id, props.aweMemberId)}
                    type="button"
                    className="btn-sm">
                    NLP Processed Record
                </Button >
            }

        </>
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar })(AWVNLPInsightPDF);