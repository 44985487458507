import React, { useState, useEffect } from 'react';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import AWVApi from '../../assets/constants/AWVRafservice.Instance';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import pdfIcon from '../../assets/images/pdf_icon.png';
import Divider from '@mui/material/Divider';
import Moment from 'moment';
import S3 from 'react-aws-s3';
import { CircularProgress } from '@material-ui/core';
import AWVServerSideGridTable from "../TableGrids/AWVServerSideGridTable.table";
import Box from '@mui/material/Box';
//import AWS from 'aws-sdk';
//const aws = require('aws-sdk');
var AWS = require("aws-sdk");

const S3_BUCKET = process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET;
const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = localStorage.getItem("access_key");
const SECRET_ACCESS_KEY = localStorage.getItem("secret_key");

const styles = (theme) => ({
    dialogPaper: {
        maxWidth: '80%',
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const AddAWVFileUpload = (props) => {
    const fileInput = React.useRef();
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [year, setYear] = useState(props.yearSelect);
    const [open, setOpen] = React.useState();
    const [isLoading, setIsLoading] = useState(false);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const [fileName, setFileName] = useState();
    const [formFileUrl, setFormFileUrl] = useState();
    const [formDataList, setFormDataList] = useState([]);
    const [editDetailsGridShow, setEditDetailsGridShow] = useState(false);
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    useEffect(() => {
        loadformDataTable()
    }, [props?.editFormData?.awv_id]);
   
    const config = {
        bucketName: process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET, 
        //bucketName: 'bucket-awv-form-upload', //process.env.REACT_APP_BUCKET_NAME_Two,
        // dirName: 'bucket-awv-report', 
        region: process.env.REACT_APP_REGION,
        accessKeyId: localStorage.getItem("access_key"),
        secretAccessKey: localStorage.getItem("secret_key"),
        s3Url: 'https://rj3vuo7d30.execute-api.us-east-1.amazonaws.com/v1/upload-form',
    }
    const ReactS3Client = new S3(config);

    const formDataColumns = [
        {
            name: "avg_hcc_2020",
            label: "2020 AVG HCC SCORE",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ width: '80px', textAlign: "center" }} className="expand-grid-primary-status pt-1 pb-1 pl-3 pr-3" >{value}</span>
                        </Box>
                    );
                }
            }
        },
        {
            name: "avg_hcc_2021",
            label: "2021 AVG HCC SCORE",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "diagnosis_rate",
            label: "DIAGNOSIS RECAPTUE RATE 2020 TO 2021",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ width: '80px', textAlign: "center" }} className="expand-grid-danger-status pt-1 pb-1 pl-3 pr-3" >{value}</span>
                        </Box>
                    );
                }
            }
        },
        {
            name: "change_hcc_score",
            label: "CHANGE IN HCC SCORE 2020 TO 2021",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ width: '80px', textAlign: "center" }} className="expand-grid-primary-status pt-1 pb-1 pl-3 pr-3" >{value}</span>
                        </Box>
                    );
                }
            }
        },
        {
            name: "hcc_benchmarks",
            label: "2021 HCC BENCHMARKS",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "percent_of_benchmark",
            label: "PERCENT OF BENCHMARK USED",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ width: '80px', textAlign: "center" }} className="expand-grid-danger-status pt-1 pb-1 pl-3 pr-3" >{value}</span>
                        </Box>
                    );
                }
            }
        },
        {
            name: "awv_completine",
            label: "AWV COMPLETINE",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ width: '80px', textAlign: "center" }} className="expand-grid-danger-status pt-1 pb-1 pl-3 pr-3" >{value}</span>
                        </Box>
                    );
                }
            }
        },
    ];

    const loadformDataTable = () => {
        setFormDataList([
            {
                'avg_hcc_2020': "1.9",
                'avg_hcc_2021': "1.974",
                'diagnosis_rate': "68.53%",
                'change_hcc_score': "3.86%",
                'hcc_benchmarks': "$18332.23",
                'percent_of_benchmark': "50.53%",
                'awv_completine': "50.53%",
            },
            {
                'avg_hcc_2020': "1.9",
                'avg_hcc_2021': "1.974",
                'diagnosis_rate': "68.53%",
                'change_hcc_score': "3.86%",
                'hcc_benchmarks': "$18332.23",
                'percent_of_benchmark': "50.53%",
                'awv_completine': "50.53%",
            },
            {
                'avg_hcc_2020': "1.9",
                'avg_hcc_2021': "1.974",
                'diagnosis_rate': "68.53%",
                'change_hcc_score': "3.86%",
                'hcc_benchmarks': "$18332.23",
                'percent_of_benchmark': "50.53%",
                'awv_completine': "50.53%",
            },
        ])
       
    }





    /*  Notice that if you don't provide a dirName, the file will be automatically uploaded to the root of your bucket */

    /* This is optional */
    const newFileName = 'test-file';


    const loadEditDetails = (event) => {
        // event.preventDefault();
        setEditDetailsGridShow(!editDetailsGridShow);
    }

    const handleFileUpload = (event) => {
        // console.log(event.target);
        // console.log(fileInput?.current?.files[0]);
        setValue('file_upload', event.target.value);
        setFileName(fileInput?.current?.files[0]?.name);

        var file_name = 'https://bgguzqqgp5.execute-api.us-east-1.amazonaws.com/v1/bucket-member-details/AWE_2022_v3.pdf'; //+ props?.editFormData?.awv_id +'.pdf';
        var api_url = 'https://rj3vuo7d30.execute-api.us-east-1.amazonaws.com/v1/upload-form';

        if (fileInput?.current && fileInput?.current?.files && fileInput?.current?.files[0]) {

            const headers = {
                'Content-Type': 'application/pdf'
            }

            //const data = new FormData(fileInput);

            ///////////////////////////////////////////////////////////////////////////////////

            //var path = "bucket-awv-form-upload";
            var path = process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET+"/" + process.env.REACT_APP_AWE_DIR;


            AWS.config.update({ region: REGION, credentials: new AWS.Credentials(ACCESS_KEY, SECRET_ACCESS_KEY) });
            const temp = fileInput?.current?.files[0]?.name ;
            const myarr= temp.split("."); 
            
            var file = fileInput?.current?.files[0]; //file?.name
            var fileKey = myarr[0]+"_"+ Date.now()+".pdf"; // myarr[0] take the exact file name and now doc will save exact file name with timestamp.
            
            let upload_params = { Bucket: path, Key:fileKey, Body: file, ServerSideEncryption: 'aws:kms', SSEKMSKeyId: process.env.REACT_APP_KeyID, ContentDisposition: "inline", ContentType: "application/pdf" };
            let upload = new AWS.S3.ManagedUpload({ params: upload_params });
            upload.promise(function (err, data) {
                //window.alert("FILE UPLOADED SUCCESSFULLY data ",data.Location," ERRO  ",err );
                setFormFileUrl(data.Location);
                setValue('form_url', data.Location);
                var formUrl = data.Location;
                var s3 = new AWS.S3({signatureVersion: "v4"});
                //let Key = fileKey;
                let Key = process.env.REACT_APP_AWE_DIR + "/" +fileKey;

                s3.setupRequestListeners = (request) => {
                    request.on('build', (req) => {
                      req.httpRequest.headers['x-amz-date'] = process.env.REACT_APP_KeyID;
                    });
                }
                var presignedGETURL  = s3.getSignedUrl('getObject', {
                    Bucket: process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET, // S3_BUCKET, //bucket-demo-data-repository
                    Key: Key,
                    Expires: 60
                   });
                setFormFileUrl(presignedGETURL);
            });
        }

    }

    useEffect(() => {
        let controlUser = JSON.parse(localStorage.getItem('controlUser'));
        if (props.editFormData && props?.editFormData?.awv_id) {
            setValue('cin', props?.editFormData?.cin);
            setValue('awv_id', (props?.editFormData?.awv_id).toString());
            setValue('form_url', '');
            setValue('updated_by', controlUser?.user_id);
            setValue('remark', '');
            setValue('lob', props?.lobSelect);
            setValue('file_upload', '');
            setValue('form_url', '');
        } else {
            setValue('cin', '');
            setValue('awv_id', '');
            setValue('form_url', '');
            setValue('updated_by', '');
            setValue('remark', '');
            setValue('file_upload', '');
            setValue('form_url', '');
        }
        setFileName('');
        setFormFileUrl('');
        setEditDetailsGridShow(false);
    }, [props.editFormData]);

    const onSubmit = (data) => {
        setIsLoading(true);
        //event.preventDefault();
        if (props?.editFormData?.awv_id && props?.editFormData?.awv_id != '') {
            // console.log(data);
            data.form_url = getValues('form_url');
            // AWVApi.put('/update-awv-form-url?awv_id=' + data?.awvId + '&form_url=' + data?.form_url + '&updated_by=' + data?.updated_by + '&remark=' + data?.remark, {})
            AWVApi.put('/update-awv-form-url?awv_id=' + data?.awv_id + '&form_url=&updated_by=' + data?.updated_by + '&remark=' + data?.remark, data)
                .then(res => {
                    props.handleClose();
                    setValue('memberId', '');
                    setValue('awvId', '');
                    setValue('form_url', '');
                    setValue('updated_by', '');
                    setValue('remark', '');
                    props.loadFormData();
                    setIsLoading(false);
                    toast.success('Form update success');
                })
                .catch(err => {
                    setIsLoading(false);
                    toast.error(err?.response?.data?.message);
                })
        }
    };


    return (
        <div>
            <MuiDialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth={true}
                maxWidth={'md'}
                className="awv_model_layout"
            >
                <DialogTitle id="create-group" onClose={props.handleClose}>
                    {/* {props.editFormData && props.editFormData.id && props.editFormData.id != '' ? */}
                    <b>EDIT</b>
                    {/* } */}
                </DialogTitle>
                <Divider />
                <div className='row'>
                    <div className='col-lg-12'>
                        <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model" enctype="multipart/form-data">
                            <DialogContent >
                                {isLoading && isLoading == true ?
                                    <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                                        <CircularProgress />
                                    </div>
                                    :
                                    <></>
                                }
                                <div className="row">
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong>CIN/RXID</strong></div>
                                        <div>{props?.editFormData?.cin}</div>
                                    </div>
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong>AWV ID</strong></div>
                                        <div>{props?.editFormData?.awe_display_id}</div>
                                    </div>
                                    <div className="col-lg-3 align-items-center">
                                        <label class="file">
                                            <InputLabel id="demo-simple-select-outlined-label"><b>Upload File</b></InputLabel>
                                            <FormControl fullWidth margin="dense" variant="outlined" style={{ maxWidth: "220px" }}>
                                                {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                                <Controller
                                                    className="input-control"
                                                    name="file_upload"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input type="file" {...field} ref={fileInput} onChange={handleFileUpload} className="custom_file_upload" />
                                                    )}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                />
                                                {errors?.file_upload?.type === "required" && <label className="text-danger">This field is required</label>}
                                            </FormControl>
                                            <span class="file-custom"></span>
                                        </label>
                                    </div>
                                    {fileName && fileName != '' ?
                                        <div className="col-lg-3 align-items-center">
                                            <InputLabel id="demo-simple-select-outlined-label"><b>PDF</b></InputLabel>
                                            <div className='mt-3'>
                                                <img src={pdfIcon} alt="" className="" style={{ width: '20px', height: '20px', marginLeft: '-7px' }} />
                                                <a href={formFileUrl} target="_blank">{fileName}</a>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                                <div className="row mt-3">
                                    
                                    <div className="col-lg-12 align-items-center">
                                        <InputLabel id="demo-simple-select-outlined-label"><b>REMARK</b></InputLabel>
                                        <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '70px' }}>
                                            {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                            <Controller
                                                className="input-control"
                                                name="remark"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField multiline rows={3} {...field} variant="outlined" />
                                                )}
                                                rules={{
                                                    required: true,
                                                }}
                                            />
                                            {errors?.remark?.type === "required" && <label className="text-danger">This field is required</label>}
                                        </FormControl>
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions className='mt-2'>
                                {/* <Button type='button' onClick={loadEditDetails} className="ml-2 btn-custom-primary" variant="contained">
                                    Edit HCC Details
                                </Button> */}
                                <Button type='button' variant="contained" color="grey" onClick={props.handleClose}>CANCEL</Button>
                                <Button type='submit' className="mr-2 btn-custom-primary" variant="contained"
                                >
                                    {/*  disabled={isLoading && isLoading == true ? true : false}  {props.editFormData && props.editFormData.id && props.editFormData.id != '' ? */}
                                    Update
                                    {/* :
                                        'Save'
                                    } */}
                                </Button>
                            </DialogActions>
                        </form>
                    </div>


                </div>
            </MuiDialog>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
        lobSelect: state.moduleFilter.lobSelect,
    };
}
export default (withStyles(styles), connect(mapStateToProps))(AddAWVFileUpload);
