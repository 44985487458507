import React from 'react'

export default function NotificationIcon({ ...props }) {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_161_2)">
                <path d="M11.8462 24C13.2 24 14.3077 22.8923 14.3077 21.5385H9.38463C9.38463 22.8923 10.48 24 11.8462 24ZM19.2308 16.6154V10.4615C19.2308 6.68308 17.2123 3.52 13.6923 2.68308V1.84615C13.6923 0.824615 12.8677 0 11.8462 0C10.8246 0 10 0.824615 10 1.84615V2.68308C6.46769 3.52 4.46154 6.67077 4.46154 10.4615V16.6154L2 19.0769V20.3077H21.6923V19.0769L19.2308 16.6154Z" fill="rgb(26, 150, 152)" />
            </g>
            <defs>
                <clipPath id="clip0_161_2">
                    <rect width={24} height={24} fill="currentColor" />
                </clipPath>
            </defs>
        </svg>
    )
}