
/**
 * @author ih-sonal
 * PD-61 
 * description : Expanding child row for PPG group
 *
 */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import apien from '../../assets/constants/EncounterSummaryAPI.instance';

const ExpandRowTree = (props) => {

    const { rowData, year, ppg, className, colSpan } = props;
    const [expanded, setExpanded] = React.useState([]);
    const [api, setAPI] = React.useState([]);
    const [columnName, setColumnName] = useState([]);

    const [rowName, setRowName] = useState([]);
    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
    };
    var treeIndex = 0;

    /*
        Calling API to show all column for sub table
    */
    const loadAPI = () => {
        apien.get('/year?metric=' + rowData + '&ppg_group=' + ppg + '&year=' + year)
            .then(res => {
                setAPI(res.data)
            }).catch(err => console.log(err))
    }
    const loadElement = () => {
        let values = [];
        api.map((element) => {
            for (let [key, value] of Object.entries(element)) {
                values.push(value)

            }
            console.log("valuesvaluesvalues " + values);
            setRowName(values)
        })
    }

    const loadColumn = () => {
        let values = [];
        if (api.includes('Loading Data...') == false) {
            api.forEach((element, index) => {
                for (let [key, value] of Object.entries(element)) {
                    if (!values.includes(key)) {
                        values.push(key)
                    }
                }
            });
        }
        setColumnName(values)

    }
      
   /*    function TreeItem(props) {
        const classes = useTreeItemStyles(props);
        return <MuiTreeItem {...props} classes={{ label: classes.label }} />;
      }
 */
      
    useEffect(() => {
        loadAPI()
    }, [rowData])

    useEffect(() => {
        loadColumn()
    }, [api]);

    useEffect(() => {

        loadElement()
    }, [api])
    /*   checking if api response is not 0,
          then show the PPG group and the corresponding columns when expanding PPG    
    */
    return (
        <>
            {api && api.length > 0 ?

                <Box sx={{ width: 1150, overflowY: 'auto' }}  >
                    <TreeView
                        aria-label="controlled"
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        expanded={expanded}
                        onNodeToggle={handleToggle}
                        multiSelect
                    >
                        {api && api.map((element) => (

                            element && element != "" ?
                                <TreeItem nodeId={treeIndex += 1} label={ppg} style={{'font-size': '75%'}}>
                                    {element && element != "" ?
                                        <Table>
                                            <tr>
                                                {columnName.map((ele, index) => (
                                                    <th style={{'paddingLeft':'1%'}} variant={'head'} colSpan={2}>{ele}</th>
                                                ))}
                                            </tr>
                                            <tr>
                                                {rowName.map((ele, index) => (

                                                    <td style={{'paddingLeft':'1%'}}  variant={'head'} colSpan={2}>{ele}</td>
                                                ))}

                                            </tr>
                                        </Table>
                                        :
                                        <>
                                        </>
                                    }
                                </TreeItem>
                                :
                                <></>
                        ))
                        }

                    </TreeView>
                </Box>
                :
                <></>
            }


        </>
    );

}

ExpandRowTree.propTypes = {
    columns: PropTypes.array,
    url: PropTypes.string,
    tableClick: PropTypes.func,
    defaultSortCol: PropTypes.number,
    defaultSortDir: PropTypes.string
}

export default ExpandRowTree;
