import axios from "axios";
import { useEffect, useState } from "react";
import {
  anchorTag,
  numberWithCommasPrefixDolar,
} from "../../assets/constants/customRender";
import {
  getAcaMetricsIssuer,
  getAcaMetricsIssuerList,
} from "../../assets/constants/issuerList";
import api from "../../assets/constants/Rafservice.Instance";
import {
  DISCREPANCY_REASON,
  MEMBER_ID,
  MEMBER_NAME,
  PLAN_ID,
  REPORTED_INSIGHTS,
} from "../../assets/constants/string.constants";
import { getAcaYear } from "../../assets/constants/yearList";
import CardComponent from "../../components/Cards/card";
import AcaIssuersSelect from "../../components/DropDowns/AcaIssuersSelect";
import Dropdown from "../../components/DropDowns/dropDown";
import GridTable from "../../components/TableGrids/GridTable.table";
import AcaYearSelect from "../../components/DropDowns/AcaYearSelect";
import { getAcaYearList } from "../../assets/constants/yearList";
import { DetailsSharp } from "@material-ui/icons";
import history from "../../history";
import "../../assets/css/Datatable.css";
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';

const PCPDetailsACA = (props) => {
  const [memberDiscrepancyDetails, setMemberDiscrepancyDetails] = useState([]);
  const [year, setYear] = useState(props.yearSelect);
  const [isLoading, setIsLoading] = useState(true);
  const [yearList, setyearList] = useState(["2021", ...getAcaYearList()]);

  const discrepancyCols = [
    {
      name: "PPG",
      label: "PPG",
    },
    {
      name: "PPG_DESCRIPTION",
      label: "PPG DESCRIPTION",
      options: {
        display: false,
      },
    },
    {
      name: "PRIMARY_PROVIDER",
      label: "PRIMARY PROVIDER",
    },
    {
      name: "PROVIDER_NAME",
      label: "PROVIDER NAME",
    },
    {
      name: "TOTAL_MEMBERS",
      label: "TOTAL MEMBERS",
    },
    {
      name: "TOTAL_ACTIVE_MEMBERS",
      label: "# ACTIVE MEMBERS",
    },
    {
      name: "TOTAL_NEWLY_ENROLLED_MEMBERS",
      label: "# NEWLY ENROLLED MEMBERS",
    },

    {
      name: "TOTAL_COST",
      label: "$ UTILIZATION COST",
      options: numberWithCommasPrefixDolar,
    },
    {
      name: "TOTAL_ENROLLED_MEMBER_MONTHS",
      label: "TOTAL ENROLLED MEMBER MONTHS",
      options: {
        display: false,
      },
    },
    {
      name: "TOTAL_PROSPECTIVE",
      label: "PROSPECTIVE",
      options: anchorTag,
    },
    {
      name: "TOTAL_RETROSPECTIVE",
      label: "RETROSPECTIVE",
      options: anchorTag,
    },
    {
      name: "TOTAL_MEMBERS_WITH_HISTORIC_GAP",
      label: "MEMBERS WITH HCC GAP",
    },
    {
      name: "TOTAL_OPPORTUNITY_LOST",
      label: "# OPPORTUNITY LOST",
    },
    {
      name: "MEMBERS_WITH_PREVENTIVE_VISITS",
      label: "MEMBERS WITH PREVENTIVE VISITS",
    },
    {
      name: "PREVENTIVE_VISIT_PREVALENCE",
      label: "PREVENTIVE VISIT PREVALENCE",
      options: {
        display: false,
      },
    },
    {
      name: "TARGET_PREVENTIVE_VISITS_MEMBERS",
      label: "TARGET PREVENTIVE VISITS MEMBERS",
      options: {
        display: false,
      },
    },
    {
      name: "EXPECTED_GAP_CLOSURE",
      label: "EXPECTED GAP CLOSURE",
    },
    {
      name: "PERCENT_MEMBERS_WITH_GAPS",
      label: "% MEMBERS WITH GAPS",
    },
    {
      name: "PERCENT_MEMBERS_PROSPECTIVE",
      label: "% MEMBERS PROSPECTIVE",
      options: {
        display: false,
      },
    },
    {
      name: "HCC_SCORE_CAPTURED_PREV_YEAR",
      label: "HCC SCORE CAPTURED PREV YEAR",
    },
    {
      name: "PREVIOUS_YEAR_PREVALENCE_RATE",
      label: "PREVIOUS YEAR PREVALENCE RATE",
      options: {
        display: false,
      },
    },
    {
      name: "TOTAL_COST_PREVENTIVE_VISITS",
      label: "$ COST PREVENTIVE VISITS",
      options: numberWithCommasPrefixDolar,
    },
    {
      name: "HCC_SCORE_CAPTURED_CURRENT_YEAR",
      label: "HCC SCORE CAPTURED CURRENT YEAR",
    },
    {
      name: "HCC_SCORE_CHANGE_YOY",
      label: "HCC SCORE CHANGE YOY",
    },
  ];

  const rarsdTableClick = (colData, cellMeta, rows, e, el) => {
    if (
      cellMeta.colIndex === 10 &&
      colData.props.children != "" &&
      colData.props.children !== 0
    ) {
      let ppg = rows[cellMeta.dataIndex].PPG;

      localStorage.setItem("PPG", ppg);
      let provider = rows[cellMeta.dataIndex].PRIMARY_PROVIDER;

      localStorage.setItem("PRType", "PCP-RETROSPECTIVE");
      localStorage.setItem("PROVIDER_ID", provider);
      history.push("/layout/pcpMemberDetails");
    }

    if (
      cellMeta.colIndex === 9 &&
      colData.props.children != "" &&
      colData.props.children !== 0
    ) {
      let ppg = rows[cellMeta.dataIndex].PPG;
      localStorage.setItem("PPG", ppg);
      let provider = rows[cellMeta.dataIndex].PRIMARY_PROVIDER;

      localStorage.setItem("PRType", "PCP-PROSPECTIVE");
      localStorage.setItem("PROVIDER_ID", provider);
      history.push("/layout/pcpMemberDetails");
    }
  };

  useEffect(() => {
    if (props.yearSelect && props.yearSelect != '') {
      loadPlanList();
    }
  }, [      
    props.moduleSelect,
    props.viewBySelect,
    props.modelSelect,
    props.metalTierSelect,
    props.planSelect,
    props.yearSelect,
    props.meInitialSelect
  ]);

  const loadPlanList = () => {
    setIsLoading(true);
    //// console.log("here in members");
    let data = [];
    let keys = [];
    let details = {};
    api
      .get("/PCPList/" + props.yearSelect)
      .then((res) => {
        keys = Object.keys(res);
        if (keys !== undefined) {
          for (const key of keys) {
            details.PROVIDER_NAME = res[key].PROVIDER_NAME;
            details.TOTAL_COST = res[key].TOTAL_COST;
            details.TOTAL_MEMBERS = res[key].TOTAL_MEMBERS;
            details.PRIMARY_PROVIDER = res[key].PRIMARY_PROVIDER;
            details.PPG = res[key].PPG;
            details.PPG_DESCRIPTION = res[key].PPG_DESCRIPTION;
            details.TOTAL_MEMBERS = res[key].TOTAL_MEMBERS;
            details.TOTAL_ENROLLED_MEMBER_MONTHS =
              res[key].TOTAL_ENROLLED_MEMBER_MONTHS;
            details.TOTAL_MEMBERS_WITH_HISTORIC_GAP =
              res[key].TOTAL_MEMBERS_WITH_HISTORIC_GAP;
            details.TOTAL_OPPORTUNITY_LOST = res[key].TOTAL_OPPORTUNITY_LOST;
            details.MEMBERS_WITH_PREVENTIVE_VISITS =
              res[key].MEMBERS_WITH_PREVENTIVE_VISITS;
            details.TOTAL_NEWLY_ENROLLED_MEMBERS =
              res[key].TOTAL_NEWLY_ENROLLED_MEMBERS;
            details.TOTAL_ACTIVE_MEMBERS = res[key].TOTAL_ACTIVE_MEMBERS;
            details.PREVENTIVE_VISIT_PREVALENCE =
              res[key].PREVENTIVE_VISIT_PREVALENCE;
            details.TARGET_PREVENTIVE_VISITS_MEMBERS =
              res[key].TARGET_PREVENTIVE_VISITS_MEMBERS;
            details.EXPECTED_GAP_CLOSURE = res[key].EXPECTED_GAP_CLOSURE;
            details.PERCENT_MEMBERS_WITH_GAPS =
              res[key].PERCENT_MEMBERS_WITH_GAPS;
            details.PERCENT_MEMBERS_PROSPECTIVE =
              res[key].PERCENT_MEMBERS_PROSPECTIVE;
            details.HCC_SCORE_CAPTURED_PREV_YEAR =
              res[key].HCC_SCORE_CAPTURED_PREV_YEAR;
            details.PREVIOUS_YEAR_PREVALENCE_RATE =
              res[key].PREVIOUS_YEAR_PREVALENCE_RATE;
            details.TOTAL_COST_PREVENTIVE_VISITS =
              res[key].TOTAL_COST_PREVENTIVE_VISITS;
            details.HCC_SCORE_CAPTURED_CURRENT_YEAR =
              res[key].HCC_SCORE_CAPTURED_CURRENT_YEAR;
            details.HCC_SCORE_CHANGE_YOY = res[key].HCC_SCORE_CHANGE_YOY;
            details.TOTAL_COST_PREVENTIVE_VISITS =
              res[key].TOTAL_COST_PREVENTIVE_VISITS;
            details.TOTAL_PROSPECTIVE = res[key].TOTAL_PROSPECTIVE;
            details.TOTAL_RETROSPECTIVE = res[key].TOTAL_RETROSPECTIVE;
            data.push(details);
            details = {};
          }
        }

        setMemberDiscrepancyDetails(data);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <div className="container level-summary">
      <div className="row mb-3">
        <div className="col-lg-12 pr-0 pl-0 mb-3">
          <div className="state-level">
            <div className="card-header">
              <SubHeader title={'PCP Level Summary'} />
            </div>
          </div>
        </div>
        <div className="col-lg-12 pr-0 pl-0 mb-3">
          <CardComponent
            heading={"PCP DETAILS"}
            additionalClass={`text-capital`}
            body={
              <GridTable
                columns={discrepancyCols}
                data={memberDiscrepancyDetails}
                pagination={true}
                search={true}
                filter={true}
                viewColumns={true}
                download={true}
                onTableClick={rarsdTableClick}
                className={"pcp_details_table"}
                isLoading={isLoading}
              ></GridTable>
            }
          ></CardComponent>
        </div>
        <br />
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    moduleSelect: state.moduleFilter.moduleSelect,
    viewBySelect: state.moduleFilter.viewBySelect,
    modelSelect: state.moduleFilter.modelSelect,
    metalTierSelect: state.moduleFilter.metalTierSelect,
    planSelect: state.moduleFilter.planSelect,
    yearSelect: state.moduleFilter.yearSelect,
    meInitialSelect: state.moduleFilter.meInitialSelect,
  };
}
export default connect(mapStateToProps)(PCPDetailsACA);
