import React, { useState, useEffect } from "react";
import AWVApi from '../../assets/constants/AWVRafservice.Instance';
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { Button, CircularProgress } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import { TableHead } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AWVSlickSlider from '../SlickSlider/AWVSlickSlider';
import { connect } from 'react-redux';

const AWVExpandableGridRowSlider = (props) => {
    const [formDataList, setFormDataList] = useState([]);

    useEffect(() => {
        loadformDataTable();
    }, [props]);

    const loadformDataTable = () => {
        //    ?awvId=908&lob=1&memberId=108
        AWVApi.get('/get-awv-remark?cin=' + props.rowData[1]?.props?.children?.props?.children + '&awvId=' + props?.rowData[11] + '&lob=' + props?.lobSelect + '&year=' + props?.yearSelect + '&organisation_id=1')
            .then(res => {
                if (res.data && res.data[0]) {
                    var approvalRemarks = (res?.data[0]['approvalRemarks']) ? res?.data[0]['approvalRemarks'] : [];
                    var rejectionRemarks = (res?.data[0]['rejectionRemarks']) ? res?.data[0]['rejectionRemarks'] : [];
                    var conactArray = approvalRemarks.concat(rejectionRemarks);
                    conactArray.sort(function (a, b) {
                        var dateA = new Date(a.timestamp), dateB = new Date(b.timestamp)
                        return  dateB - dateA;
                    });
                    if (conactArray && conactArray.length > 0) {
                        setFormDataList(conactArray);
                    }
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    return (
        <>
            <TableRow>
                <TableCell variant={'head'} colSpan={11}>
                    <AWVSlickSlider dataSlider={formDataList} />
                </TableCell>
            </TableRow>
        </>
    )
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        lobSelect: state.moduleFilter.lobSelect,
    };
}
export default connect(mapStateToProps)(AWVExpandableGridRowSlider);