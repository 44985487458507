import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../components/Tab/TabPanel";
import {
  CLAIM_ID,
  CR_INSIGHTS,
  GAP_INSIGHTS,
  GENDER,
  HCC,
  ISSUER_ID,
  MEMBER_ID,
  MEMBER_NAME,
  PLAN_ID,
  REPORTED_INSIGHTS,
  UTILIZATION,
  WIDGET_TYPES,
} from "../../assets/constants/string.constants";
import GridTable from "../../components/TableGrids/GridTable.table";
import api from "../../assets/constants/Rafservice.Instance";
import {  getModuleSelect} from '../../assets/constants/moduleList';
import {
  anchorTag,
  numberWithCommas,
  dollerSymbolAndInfoIconWithDisplay,
  stringFold,
  addSuffixTwoDecimal,
  tickCross,
  anchorTagAndInfoIcon,
  infoIcon,
  displayDisableAndInfoIcon,
  dollerSymbolAndInfoIcon
} from "../../assets/constants/customRender";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTableN.table";
import WidgetCard from "../../components/Cards/WidgetCard";
import { revenueFormat } from "../../assets/helpers/formats.helpers";
import SingleHorizontalBar from "../../components/Charts/SingleHorizontalBar.chart";
import history from "../../history";
import CardComponent from "../../components/Cards/card";
import { connect } from 'react-redux';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const MemberSummaryACA = (props) => {
  const classes = useStyles();

  const [value, setValue] = useState(props.location.state);
  const [year] = useState(props.yearSelect);
  const [memberId] = useState(localStorage.getItem(MEMBER_ID));
  const [Member_Name] = useState(localStorage.getItem(MEMBER_NAME));
  const [planId] = useState(localStorage.getItem(PLAN_ID));
  const [comorbidData, setcomorbidData] = useState([]);
  const [SUWidgetDetails, setSUWidgetDetails] = useState({});
  const [SuChartData, setSuChartData] = useState({});
  const [CRWidgetDetails, setCRWidgetDetails] = useState({});
  const [Gapconfiguration, setGapconfiguration] = useState([]);
  const [planDetails, setPlanDetails] = useState([]);
  const [memberScoreDetails, setmemberScoreDetails] = useState([]);
  const [memberDetails, setmemberDetails] = useState([]);
  const selectedModule = getModuleSelect()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const loadMemberScoreDetails = () => {
    let data = [];
    api
      .get(
        "/MemberScores/year/" +
          year +
          "/member_id/" +
          memberId +
          "/plan_id/" +
          planId
      )
      .then((res) => {
        // console.log(res[0]);
        for (const dataObj of res) {
          data[0] = ["HCC Score", Number(dataObj.hcc_score).toFixed(2)];
          data[1] = ["Age Sex Scores", Number(dataObj.age_gender_score).toFixed(2)];
          data[2] = ["HCC Group Scores", Number(dataObj.hcc_group_score).toFixed(2)];
          data[3] = [
            "Severe Illness Interaction Scores",
            dataObj.severe_illness_interaction_scores,
          ];
          data[4] = [
            "Enrollment Duration Scores",
            dataObj.enrollment_duration_scores,
          ];
          data[5] = ["RXC Scores", dataObj.rxc_score];
          data[6] = ["RXC Interaction Scores", dataObj.rxc_interaction_score];
          data[7] = [
            "Maturity Severity Score",
            dataObj.maturity_severity_score,
          ];
        }
        setmemberScoreDetails(data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const memberScoreDetailsCols = ["Score Name", "Scores"];

  const loadMemberDetails = () => {
    let data = [];
    api
      .get(
        "/MemberDetails/year/" +
          year +
          "/member_id/" +
          memberId +
          "/plan_id/" +
          planId
      )
      .then((res) => {
        for (const dataObj of res) {
          data[0] = ["Member Status", dataObj.member_status];
          data[1] = [
            "Member Coverage Dates",
            dataObj.member_cvg_start_date + " :" + dataObj.member_cvg_to_date,
          ];
          data[2] = ["Continuous Coverage Months", dataObj.cont_cvg_month];
          data[3] = [
            "Wellness Visits Performed",
            dataObj.wellness_visits_perfomed,
          ];
          data[4] = ["Immunizations Taken", dataObj.immunizations_taken];
          data[5] = ["Hierarchy Changes", dataObj.hierarchy_changes];
          data[5] = ["Gender", localStorage.getItem(GENDER)];
        }
        setmemberDetails(data);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const memberDetailsCols = ["Parameter", "Status"];

  const loadPlanDetails = () => {
    let details = [];
    api
      .get("/PlanDetails/year/" + year + "/member_id/" + memberId)
      .then((res) => {
        // console.log(res[0]);
        for (const dataObj of res) {
          details.push({
            plan_id: dataObj.plan_id,
            coverage_date:
              dataObj.benefit_start_date + ":" + dataObj.benefit_end_date,
            carrier_id: dataObj.carrier_id,
          });
        }
        setPlanDetails(details);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const planDetailCols = [
    {
      name: "plan_id",
      label: "Plan",
    },
    {
      name: "coverage_date",
      label: "Coverage Date",
    },
    {
      name: "carrier_id",
      label: "Carrier ID",
    },
  ];

  const loadSUWidgetDetails = () => {
    api
      .get("/AcaSuMemberUtilization/" + year + "/id/" + memberId)
      .then((res) => {
        setSUWidgetDetails({
          Utilization: revenueFormat(res[0].utilization),
          "Reported Revenue": revenueFormat(res[0].reportedRevenue),
          "No. of Claims": res[0].claim_count,
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const SUWidgetDetailsType = [
    WIDGET_TYPES.REVENUE,
    WIDGET_TYPES.REVENUE,
    WIDGET_TYPES.COUNT,
  ];

  const loadCRWidgetDetails = () => {
    api
      .get(
        "/ACACRMember/year/" + year + "/id/" + memberId + "/planId/" + planId
      )
      .then((res) => {
        setCRWidgetDetails({
          "Member ID": res[0] ? res[0].memberID : memberId,
          "Reported Risk Score": res[0] ? res[0].reportedRAF : 0,
          "Corrected Risk Score": res[0] ? res[0].correctionInRAF : 0,
        });
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const CRWidgetDetailsType = [
    WIDGET_TYPES.PERSON,
    WIDGET_TYPES.SCORE,
    WIDGET_TYPES.SCORE,
  ];

  const loadComorbidHccTrend = () => {
    api
      .get(
        "/MemberScoresHCC/year/" +
          year +
          "/member_id/" +
          memberId +
          "/plan_id/" +
          planId
      )
      .then((res) => {
        setcomorbidData(res);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const loadSUChartData = () => {
    let suCode = [];
    let percent = [];
    api
      .get("/ACAsuTopfiveServiceCategory/" + year + "/id/" + memberId)
      .then((res) => {
        for (const dataObj of res) {
          suCode.push(dataObj.description + ":" + dataObj.servicecategory);
          percent.push(dataObj.percentContMeberUtilization);
        }
        setSuChartData({
          labels: suCode,
          data: percent,
          dLabel: "RAF Score",
        });
      });
  };

  const loadGapConfiguration = () => {
    api
      .get(
        "/ACASuspectMember/year/" +
          year +
          "/id/" +
          memberId +
          "/plan_id/" +
          planId
      )
      .then((res) => {
        setGapconfiguration(res);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const comborbidCols = [
    {
      name: "hcc",
      label: "HCC",
      options: anchorTag,
    },
    {
      name: "hccDesc",
      label: "Description",
    },
    {
      name: "threeYear",
      label: year - 3,
      options: tickCross,
    },
    {
      name: "twoYear",
      label: year - 2,
      options: tickCross,
    },
    {
      name: "pastYear",
      label: year - 1,
      options: tickCross,
    },
    {
      name: "presentYear",
      label: year,
      options: tickCross,
    },
  ];

  const ReportedclaimCols = [
    {
      label: "Claim Id",
      name: "CLAIM_ID",
      options: anchorTagAndInfoIcon
    },
    {
      label: "Claim Type",
      name: "CLAIM_TYPE",
      options : infoIcon
    },
    {
      label: "Service Start Date",
      name: "Service_Start_Date",
      options : infoIcon
    },
    {
      label: "Service End Date",
      name: "Service_End_Date",
      options : infoIcon
    },
    {
      label: "BIll Type",
      name: "Bill_Type",
      options : displayDisableAndInfoIcon
    },
    {
      label: "Provider ID",
      name: "Provider_ID",
      options : infoIcon
    },
    {
      label: "Provider Name",
      name: "ProviderName",
      options : displayDisableAndInfoIcon
    },
    {
      label: "Diagnosis Code",
      name: "Diagnosis_Codes"
    },
    {
      label: "Reported HCC",
      name: "Reported_HCC",
      options: anchorTagAndInfoIcon,
    },
    {
      label: "Gap HCC",
      name: "GAP_HCC",
      options :{
        display : false
      }
    },
    {
      label: "Retrospective Priority",
      name: "Retrospective_Concurrent_Priority",
      options :{
        display : false
      }
    },
    {
      label: "Risk Eligible",
      name: "RiskEligible",
      options: tickCross,
    },
    {
      label: "Claim Status",
      name: "Claim_Status",
      options : infoIcon
    },
    {
      label: "Rendering Provider",
      name: "Provider_ID",
      options : infoIcon
    },
    {
      label: "Charge Amount",
      name: "ChargeAmount",
      options : dollerSymbolAndInfoIcon
    },
    {
      label: "Amount Allowed",
      name: "AmountAllowed",
      options : dollerSymbolAndInfoIcon
    },
    {
      label: "Paid Amount",
      name: "Paid_Amount",
      options : dollerSymbolAndInfoIcon
    },
    {
      label: "Member ID",
      name: "MEMBER_ID",
      options : infoIcon
    },
    {
      label: "Member Name",
      name: "MEMBER_NAME"
    },
    {
      label: "# of Service Lines",
      name: "No_of_ServiceLines",
      options : infoIcon
    }
  ];

  const ReportedclaimTableClick = (rowdata, colData, cellMeta) => {
    if (cellMeta.colIndex === 0 && colData.props.children != "") {
      localStorage.setItem(CLAIM_ID, rowdata[cellMeta.rowIndex].CLAIM_ID);
      localStorage.setItem(MEMBER_ID, memberId);
      history.push("/layout/acaMemberClaimDetails");
    } else if (cellMeta.colIndex === 4 && colData.props.children != "") {
      localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].HCC);
      history.push("/layout/acaReportedHcc");
    }
  };

  const GapclaimTableClick = (rowdata, colData, cellMeta) => {
    if (cellMeta.colIndex === 0 && colData.props.children != "") {
      localStorage.setItem(CLAIM_ID, rowdata[cellMeta.rowIndex].ClaimId);
      localStorage.setItem(MEMBER_ID, memberId);
      history.push("/layout/acaMemberClaimDetails");
    }
  };

  const ReportedpharmaCols = [
    {
      label: "Claim Id",
      name: "CLAIM_ID",
    },
    {
      label: "National Drug Code",
      name: "NDC_PROCEDURE_CODE",
    },
    {
      label: "RXC",
      name: "RXC",
    },
    {
      label: "Prescription Fill Date",
      name: "PRESCRIPTION_FILL_DATE",
    },
  ];

  const ReportedserviceLineCols = [
    {
      label: "Claim Id",
      name: "CLAIM_ID",
    },
    {
      label: "Procedure Code",
      name: "NDC_PROCEDURE_CODE",
    },
    {
      label: "RXC",
      name: "RXC",
    },
    {
      label: "place Of Service",
      name: "FACILITY_CODE",
    },

    {
      label: "Date Of Service",
      name: "SERVICE_TO_DATE",
    },
  ];

  const gapRiskCols = [
    {
      label: "HCC Code",
      name: "hccCode",
      options: anchorTag,
    },
    {
      label: "HCC Coefficient",
      name: "Hcc_Coef",
      options:addSuffixTwoDecimal
    },
    {
      label: "Historic",
      name: "Historic",
      options: tickCross,
    },
    {
      label: "Co-Morbid",
      name: "Co-morbid",
      options: tickCross,
    },
    {
      label: "Pharma",
      name: "Pharma",
      options: tickCross,
    },
    {
      label: "Lab/ Others",
      name: "Lab",
      options: tickCross,
    },
    {
      label: "Max Probability",
      name: "Max_probability",
    },
    {
      label: "Min Probability",
      name: "Min_probability",
    },
    {
      label: "Likelihood Flag",
      name: "Likelihood_flag",
    },
  ];

  const gapRiskTableClick = (rowdata, colData, cellMeta) => {
    if (cellMeta.colIndex === 0 && colData.props.children != "") {
      let hcc = rowdata[cellMeta.rowIndex].hccCode;
      let parts = hcc.split(":");
      localStorage.setItem(HCC, parts[0]);
      history.push("/layout/AcaGapHcc");
    }
  };

  const gapClaimCols = [
    {
      label: "Claim Id",
      name: "CLAIM_ID",
      options: anchorTagAndInfoIcon
    },
    {
      label: "Claim Type",
      name: "CLAIM_TYPE",
      options : infoIcon
    },
    {
      label: "Service Start Date",
      name: "Service_Start_Date",
      options : infoIcon
    },
    {
      label: "Service End Date",
      name: "Service_End_Date",
      options : infoIcon
    },
    {
      label: "BIll Type",
      name: "Bill_Type",
      options : displayDisableAndInfoIcon
    },
    {
      label: "Provider ID",
      name: "Provider_ID",
      options : infoIcon
    },
    {
      label: "Provider Name",
      name: "ProviderName",
      options : infoIcon
    },
    {
      label: "Diagnosis Code",
      name: "Diagnosis_Codes"
    },
    {
      label: "Reported HCC",
      name: "Reported_HCC",
      options: anchorTagAndInfoIcon,
    },
    {
      label: "Gap HCC",
      name: "GAP_HCC",
      options :{
        display : false
      }
    },
    {
      label: "Retrospective Priority",
      name: "Retrospective_Concurrent_Priority",
      options :{
        display : false
      }
    },
    {
      label: "Risk Eligible",
      name: "RiskEligible",
      options: tickCross,
    },
    {
      label: "Claim Status",
      name: "Claim_Status",
      options : infoIcon
    },
    {
      label: "Rendering Provider",
      name: "Provider_ID",
      options : displayDisableAndInfoIcon
    },
    {
      label: "Charge Amount",
      name: "ChargeAmount",
      options : dollerSymbolAndInfoIcon
    },
    {
      name: "AmountAllowed",
      label: "Amount Allowed",
      options : dollerSymbolAndInfoIcon
    },
    {
      label: "Paid Amount",
      name: "Paid_Amount",
      options : dollerSymbolAndInfoIcon
    },
    {
      label: "Member ID",
      name: "MEMBER_ID",
      options : infoIcon
    },
    {
      label: "Member Name",
      name: "MEMBER_NAME"
    },
    {
      label: "# of Service Lines",
      name: "No_of_ServiceLines",
      options : infoIcon
    }
  ];

  const utilizationClaimCols = [
    {
      label: "Claim Id",
      name: "CLAIM_ID",
      options: anchorTagAndInfoIcon
    },
    {
      label: "Claim Type",
      name: "CLAIM_TYPE",
      options : infoIcon
    },
    {
      label: "Service Start Date",
      name: "Service_Start_Date",
      options : infoIcon
    },
    {
      label: "Service End Date",
      name: "Service_End_Date",
      options : infoIcon
    },
    {
      label: "BIll Type",
      name: "Bill_Type",
      options : infoIcon
    },
    {
      label: "Provider ID",
      name: "Provider_ID",
      options : infoIcon
    },
    {
      label: "Provider Name",
      name: "ProviderName",
      options : infoIcon
    },
    {
      label: "Diagnosis Code",
      name: "Diagnosis_Codes"
    },
    {
      label: "Reported HCC",
      name: "Reported_HCC",
      options: anchorTagAndInfoIcon,
    },
    {
      label: "Gap HCC",
      name: "GAP_HCC",
      options :{
        display : false
      }
    },
    {
      label: "Retrospective Priority",
      name: "Retrospective_Concurrent_Priority",
    },
    {
      label: "Risk Eligible",
      name: "RiskEligible",
      options: tickCross,
    },
    {
      label: "Claim Status",
      name: "Claim_Status",
      options : infoIcon
    },
    {
      label: "Rendering Provider",
      name: "Provider_ID",
      options : infoIcon
    },
    {
      label: "Charge Amount",
      name: "ChargeAmount",
      options : dollerSymbolAndInfoIconWithDisplay
    },
    {
      name: "AmountAllowed",
      label: "Amount Allowed",
      options : dollerSymbolAndInfoIconWithDisplay
    },
    {
      label: "Paid Amount",
      name: "Paid_Amount",
      options : dollerSymbolAndInfoIconWithDisplay
    },
    {
      label: "Member ID",
      name: "MEMBER_ID",
      options : displayDisableAndInfoIcon
    },
    {
      label: "Member Name",
      name: "MEMBER_NAME",
      options : displayDisableAndInfoIcon
    },
    {
      label: "# of Service Lines",
      name: "No_of_ServiceLines",
      options : infoIcon
    }
  ];

  const gapPharmaClaim = [
    {
      label: "Claim Id",
      name: "CLAIM_ID",
    },
    {
      label: "Prescription Fill Date",
      name: "PRESCRIPTION_FILL_DATE",
    },
    {
      label: "NDC",
      name: "NDC",
    },
    {
      label: "RXC",
      name: "RXC",
    },
    {
      label: "Dispensing Provider No.",
      name: "DISPENSING_PROVIDER_NUMBER",
    },
    {
      label: "HCCs",
      name: "HCC",
    },
  ];

  const gapServiceClaim = [
    {
      label: "Claim Id",
      name: "CLAIM_ID",
    },
    {
      label: "Procedure Code",
      name: "PROCEDURE_CODE",
    },
    {
      label: "RXC",
      name: "RXC",
    },
    {
      label: " Date Of Service",
      name: "SERVICE_TO_DATE",
    },
    {
      label: " Place Of Service",
      name: "FACILITY_CODE",
    },

    {
      label: "HCCs",
      name: "HCC",
    },
  ];

  const CrAdded = [
    {
      label: "Claim Id",
      name: "ClaimId",
    },
    {
      label: "Provider Id",
      name: "ProviderId",
    },
    {
      label: "Reported Diagnosis",
      name: "ReportedDiagnosis",
      options: stringFold,
    },
    {
      label: "Date of Service",
      name: "Date_of_Service",
    },
    {
      label: "Reported HCCs",
      name: "ReportedHccs",
      options: anchorTag,
    },
    {
      label: "Added HCCs",
      name: "AddedHccs",
      options: anchorTag,
    },
    {
      label: "Added Diagnosis",
      name: "AddedDiagnosis",
      options: stringFold,
    },
  ];

  const CrDeleted = [
    {
      label: "Claim Id",
      name: "ClaimId",
    },
    {
      label: "Provider Id",
      name: "ProviderId",
    },
    {
      label: "Reported Diagnosis",
      name: "ReportedDiagnosis",
      options: stringFold,
    },
    {
      label: "Date of Service",
      name: "Date_of_Service",
    },
    {
      label: "Reported HCCs",
      name: "ReportedHccs",
      options: anchorTag,
    },
    {
      label: "Deleted HCCs",
      name: "DeletedHccs",
      options: anchorTag,
    },
    {
      label: "Deleted Diagnosis",
      name: "DeletedDiagnosis",
      options: stringFold,
    },
  ];

  const utilizataionCols = [
    {
      label: "Service Category",
      name: "servicecategory",
    },
    {
      label: "Description",
      name: "description",
    },
    {
      label: "Utilization",
      name: "utilization",
    },
    {
      label: "% Contribution to Member Utilization",
      name: "percentContMeberUtilization",
    },
    {
      label: "Number of Claims",
      name: "numberOfClaims",
      options: numberWithCommas,
    },
  ];

  const GapconfigCols = [
    {
      name: "reported_hcc",
      label: "Reported HCCs",
    },
    {
      name: "gap_hcc",
      label: "Gap HCCs",
    },
    {
      name: "raf_score",
      label: "Risk Score",
      options:addSuffixTwoDecimal
    },
    {
      name: "gap_in_raf_score",
      label: "Gap In Risk Score",
      options:addSuffixTwoDecimal
    },
    {
      name: "hcc_score",
      label: "Reported HCC Score",
      options:addSuffixTwoDecimal
    },
    {
      name: "gap_in_hcc_score",
      label: "Gap In HCC Score",
      options:addSuffixTwoDecimal
    },
  ];

  const ComorbidTableClick = (e, el) => {
    if (el.colIndex == 0) {
      // console.log(e,el);
      localStorage.setItem(HCC, e.props.children);
      history.push("/layout/AcaGapHcc");
    }
  };

  useEffect(() => {
     loadMemberScoreDetails();
    loadMemberDetails();
    loadPlanDetails();
    loadComorbidHccTrend();
    loadSUWidgetDetails();
    loadSUChartData();
    loadCRWidgetDetails();
    loadGapConfiguration();
  }, []);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          variant="fullWidth"
          textColor="primary"
          indicatorColor="primary"
          value={value}
          onChange={handleChange}
          centered
        >
          <Tab
            value={REPORTED_INSIGHTS}
            label="Reported Insights"
            {...a11yProps(REPORTED_INSIGHTS)}
          />
          <Tab
            value={GAP_INSIGHTS}
            label="Gap Insights"
            {...a11yProps(GAP_INSIGHTS)}
          />
          <Tab
            value={CR_INSIGHTS}
            label="Chart Review Insights"
            {...a11yProps(CR_INSIGHTS)}
          />
          <Tab
            value={UTILIZATION}
            label="Utilization"
            {...a11yProps(UTILIZATION)}
          />
        </Tabs>
      </AppBar>
      <br />
      <TabPanel value={value} index={REPORTED_INSIGHTS}>
        <CardComponent
          heading={`Member ID : ${memberId} and Member Name : ${Member_Name} and Plan ID : ${planId}`}
          body={
            <div>
              <div className="row">
                <div className="col">
                  <GridTable
                    columns={memberScoreDetailsCols}
                    data={memberScoreDetails}
                  ></GridTable>
                </div>
                <div className="col">
                  <GridTable
                    columns={memberDetailsCols}
                    data={memberDetails}
                  ></GridTable>
                </div>
                <div className="col">
                  <GridTable
                    columns={planDetailCols}
                    data={planDetails}
                  ></GridTable>
                </div>
              </div>
              <br />
              <div className="row"></div>
            </div>
          }
        ></CardComponent>
        <br />
        <CardComponent
          heading={`Comorbid/HCC Trend`}
          body={
            <GridTable
              data={comorbidData}
              columns={comborbidCols}
              onTableClick={ComorbidTableClick}
            ></GridTable>
          }
        ></CardComponent>
        <br />
        <div className="member-list">
          <CardComponent
            heading={`CLAIM DETAIL`}
            body={
              <ServerSideGridTable
                url={`/allacaClaims/year/${year}/member_id/${memberId}/selectedModule/${selectedModule}`}
                columns={ReportedclaimCols}
                tableClick={ReportedclaimTableClick}
                className={"claim_detail_table"}
              ></ServerSideGridTable>
            }
          ></CardComponent>
        </div>
        <br />

        <div className="member-list">
          <CardComponent
            heading={`PHARMA DETAIL`}
            body={
              <ServerSideGridTable
                url={`/acaPharma/year/${year}/member_id/${memberId}/plan_id/${planId}`}
                columns={ReportedpharmaCols}
              ></ServerSideGridTable>
            }
          ></CardComponent>
        </div>
        <br />
        <div className="member-list">
          <CardComponent
            heading={`SERVICE LINE DETAIL`}
            body={
              <ServerSideGridTable
                url={`/acaServiceLine/year/${year}/member_id/${memberId}/plan_id/${planId}`}
                columns={ReportedserviceLineCols}
              ></ServerSideGridTable>
            }
          ></CardComponent>
        </div>
          <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
          <br/>
          <br/>
          <br/> 
      </TabPanel>
      <TabPanel value={value} index={GAP_INSIGHTS}>
        <CardComponent
          heading={`Member ID : ${memberId} and Member Name : ${Member_Name} and Plan ID : ${planId}`}
          body={
            <GridTable
              data={Gapconfiguration}
              columns={GapconfigCols}
            ></GridTable>
          }
        ></CardComponent>
        <br />
        <div className="member-list">
          <CardComponent
            heading={`RISK IMPACTING GAP HCC DETAIL`}
            body={
              <ServerSideGridTable
                url={`/acasuspectMembedetails/${memberId}/year/${year}/plan_id/${planId}/type/Impacting`}
                columns={gapRiskCols}
                tableClick={gapRiskTableClick}
              ></ServerSideGridTable>
            }
          ></CardComponent>
        </div>
        <br />
        <div className="member-list">
          <CardComponent
            heading={`NON RISK IMPACTING GAP HCC DETAIL`}
            body={
              <ServerSideGridTable
                url={`/acasuspectMembedetails/${memberId}/year/${year}/plan_id/${planId}/type/NonImpacting`}
                columns={gapRiskCols.slice(0, 6)}
                tableClick={gapRiskTableClick}
              ></ServerSideGridTable>
            }
          ></CardComponent>
        </div>
        <br />
        <div className="member-list">
          <CardComponent
            heading={`CLAIM DETAIL`}
            body={
              <ServerSideGridTable
              url={`/allacaClaims/year/${year}/member_id/${memberId}/selectedModule/${selectedModule}`}
                columns={gapClaimCols}
                tableClick={GapclaimTableClick}
                className={"claim_detail_table"}
              ></ServerSideGridTable>
            }
          ></CardComponent>
        </div>
        <br />
        <div className="member-list">
          <CardComponent
            heading={`PHARMA CLAIM DETAIL`}
            body={
              <ServerSideGridTable
                url={`/ACAPharmaClaims/${memberId}/year/${year}`}
                columns={gapPharmaClaim}
              ></ServerSideGridTable>
            }
          ></CardComponent>
        </div>
        <br />
        <div className="member-list">
          <CardComponent
            heading={`SERVICE LINE DETAIL`}
            body={
              <ServerSideGridTable
                url={`/acaServiceLineGap/year/${year}/member_id/${memberId}/plan_id/${planId}`}
                columns={gapServiceClaim}
              ></ServerSideGridTable>
            }
          ></CardComponent>
        </div>
        <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
          <br/>
          <br/>
          <br/> 
      </TabPanel>
      <TabPanel value={value} index={CR_INSIGHTS}>
        <WidgetCard
          heading={`Member ID : ${memberId} and Member Name : ${Member_Name} and Plan ID : ${planId}`}
          data={CRWidgetDetails}
          type={CRWidgetDetailsType}
        ></WidgetCard>
        <br />
        <div className="member-list">
          <CardComponent
            heading={`ADDED CODES`}
            body={
              <ServerSideGridTable
                url={`/ACACRMemberHcc/AddedHCCs/id/${memberId}/year/${year}/planId/${planId}`}
                columns={CrAdded}
              ></ServerSideGridTable>
            }
          ></CardComponent>
        </div>
        <br />
        <div className="member-list">
          <CardComponent
            heading={`DELETED CODES`}
            body={
              <ServerSideGridTable
                url={`/ACACRMemberHcc/DeletedHCCs/id/${memberId}/year/${year}/planId/${planId}`}
                columns={CrDeleted}
              ></ServerSideGridTable>
            }
          ></CardComponent>
        </div>
        <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
          <br/>
          <br/>
          <br/> 
      </TabPanel>
      <TabPanel value={value} index={UTILIZATION}>
        <WidgetCard
          heading={`Member ID : ${memberId} and Member Name : ${Member_Name} and Plan ID : ${planId}`}
          data={SUWidgetDetails}
          type={SUWidgetDetailsType}
        ></WidgetCard>
        <br />
        <CardComponent
          heading={`TOP 5 UTILIZED SERVICES BY MEMBER ID : ${memberId}`}
          body={
            <SingleHorizontalBar
              label={SuChartData.labels}
              Data={SuChartData.data}
              dLabel={SuChartData.dLabel}
              xLabel={"Percentage(%)"}
              yLabel={"Service Category"}
              xTick={"%"}
              suffix={"%"}
            ></SingleHorizontalBar>
          }
        ></CardComponent>
        <br />
        <div className="member-list">
          <CardComponent
            heading={`CLAIM DETAIL`}
            body={
              <ServerSideGridTable
              url={`/allacaClaims/year/${year}/member_id/${memberId}/selectedModule/${selectedModule}`}
                columns={utilizationClaimCols}
                tableClick={GapclaimTableClick}
                className={"claim_detail_table"}
              ></ServerSideGridTable>
            }
          ></CardComponent>
        </div>
        <br/>
        <div className="member-list">
          <CardComponent
            heading={`UTILIZATION BY SERVICE CATEGORY`}
            body={
              <ServerSideGridTable
                url={`/ACAsuUtilByServiceCategory/${year}/id/${memberId}/max/100`}
                columns={utilizataionCols}
                defaultSortCol={2}
                defaultSortDir={"desc"}
              ></ServerSideGridTable>
            }
          ></CardComponent>
        </div>
        <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
          <br/>
          <br/>
          <br/> 
      </TabPanel>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
      isSignedIn: state.auth.isSignedIn,
      moduleSelect: state.moduleFilter.moduleSelect,
      viewBySelect: state.moduleFilter.viewBySelect,
      modelSelect: state.moduleFilter.modelSelect,
      metalTierSelect: state.moduleFilter.metalTierSelect,
      planSelect: state.moduleFilter.planSelect,
      yearSelect: state.moduleFilter.yearSelect,        
      meInitialSelect: state.moduleFilter.meInitialSelect,
  };
}
export default connect(mapStateToProps)(MemberSummaryACA);
