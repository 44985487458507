import { useEffect, useState } from "react"
import { anchorTag, hccSeperator, addSuffixTwoDecimal } from "../../assets/constants/customRender";
import { CARRIER_ID, FLAG, GAP_INSIGHTS, GAP_ISSUER, GAP_METAL, GAP_MODEL, GAP_ORG, GAP_ORG_METAL, GAP_OR_REPORTED, GENDER, HCC, ISSUER_ID, LIKELIHOOD_FLAG, LIKELIHOOD_STATUS, MEMBER_ID, MEMBER_NAME, METAL_TIER, PLAN_ID, REPORTED_METAL, REPORTED_MODEL, RISK_MODEL } from "../../assets/constants/string.constants";
import { getAcaYear } from "../../assets/constants/yearList";
import CardComponent from "../../components/Cards/card";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import history from "../../history";
import { connect } from 'react-redux';

const GapSummaryMembersACA = (props) =>{

    const [metal_tier, setmetal_tier] = useState(localStorage.getItem(METAL_TIER)?localStorage.getItem(METAL_TIER):'');
    const [risk_model, setrisk_model] = useState(localStorage.getItem(RISK_MODEL)?localStorage.getItem(RISK_MODEL):'');
    const [issuer_id, setissuer_id] = useState(localStorage.getItem(ISSUER_ID)?localStorage.getItem(ISSUER_ID):'ALL');
    const [likelihood_flag, setlikelihood_flag] = useState(localStorage.getItem(LIKELIHOOD_FLAG));
    const [year, setyear] = useState(props.yearSelect);
    const [gap_or_reported, setgap_or_reported] = useState(localStorage.getItem(GAP_OR_REPORTED)?localStorage.getItem(GAP_OR_REPORTED):'');
    const [flag, setFlag] = useState(localStorage.getItem(FLAG)?localStorage.getItem(FLAG):'');
 
    useEffect(() => {
        setmetal_tier(localStorage.getItem(METAL_TIER)?localStorage.getItem(METAL_TIER):'');
        setrisk_model(localStorage.getItem(RISK_MODEL)?localStorage.getItem(RISK_MODEL):'');
        setissuer_id(localStorage.getItem(ISSUER_ID)?localStorage.getItem(ISSUER_ID):'ALL');
        setlikelihood_flag(localStorage.getItem(LIKELIHOOD_FLAG));
        setyear(props.yearSelect);
        setgap_or_reported(localStorage.getItem(GAP_OR_REPORTED)?localStorage.getItem(GAP_OR_REPORTED):'');
        setFlag(localStorage.getItem(FLAG)?localStorage.getItem(FLAG):' ');
    }, [props]);
      

    const getHeading = () =>{
        let Heading = '';
        switch (gap_or_reported){
            case GAP_MODEL:
                Heading = "Members with Gap and Risk Model : " + risk_model + " and Issuer Id : "+issuer_id +" and year : " +year;
                break
            case GAP_METAL:
                Heading = "Members with Gap and Metal Tier : " + metal_tier + " and Issuer Id : "+issuer_id +" and year : " +year;
                break
            case REPORTED_MODEL:
                Heading = "Members with Risk Model : " + risk_model + " and Issuer Id : "+issuer_id +" and year : " +year;
                break
            case REPORTED_METAL:
                Heading = "Members with Metal Tier : " + metal_tier + " and Issuer Id : "+issuer_id +" and year : " +year;
                break
            case GAP_ORG_METAL:
                Heading = "Members with Gap and Metal Tier : " + metal_tier +" and year : " +year;
                break
            case LIKELIHOOD_STATUS:
                if(likelihood_flag == "EXTREMELY_HIGH")
                    Heading = "EXTREMELY_HIGH : Members with at least 1 Historic or Pharma Gap + At least Gap HCC with Very High or High Likelihood(based on ngram)";
                if(likelihood_flag == "VERY_HIGH")
                    Heading = "VERY_HIGH : Members with at least 1 Historic or Pharma Gap + No Gap HCCs with Very High or High Likelihood (based on ngram)";
                if(likelihood_flag == "HIGH")
                    Heading = "HIGH : Members without Historic or Pharma Gap but At least One Co-morbid Gap + At least One Gap HCC with Very High or High Likelihood(based on ngram)";
                if(likelihood_flag == "MEDIUM")
                    Heading = "MEDIUM : Members without Historic or Pharma Gap but At least One Co-morbid Gap + No Gap HCCs with Very High or High Likelihood(based on ngram)";
                if(likelihood_flag == "LOW")
                    Heading = "LOW : Members with only Lab/Other Gaps + At least One Gap HCC with Very High or High Likelihood(based on ngram)";
                if(likelihood_flag == "VERY_LOW")
                    Heading = "VERY_LOW : Members with only Lab/Other Gaps + No Gap HCCs with Very High or High Likelihood(based on ngram)";
                
                Heading = Heading + " and Issuer Id : "+issuer_id +" and year : " +year;
                break
            case GAP_ORG:
                Heading = getHeadingForOrgIssuer();
                Heading =  Heading +" and year : " +year;
                break
                
            case GAP_ISSUER:
                Heading = getHeadingForOrgIssuer();
                Heading =  Heading + " and Issuer Id : "+issuer_id +" and year : " +year;
                break
        }
        return Heading;
    }

    const getHeadingForOrgIssuer = () =>{
        let Heading ='';
        if(flag == 'H')
            Heading = "Members with only Historic Gaps";
        if(flag == 'C')
            Heading = "Members with only Co-Morbid Gaps";
        if(flag == 'L')
            Heading = "Members with only Lab Gaps";
        if(flag == 'P')
            Heading = "Members with only Pharma Gaps";
        if(flag == 'CH')
            Heading = "Members with Historic & Comorbid Gaps";
        if(flag == 'HL')
            Heading = "Members with Historic & Lab Gaps";
        if(flag == 'HP')
            Heading = "Members with Historic & Pharma Gaps";
        if(flag == 'CL')
            Heading = "Members with Comorbid & Lab Gaps";
        if(flag == 'CP')
            Heading = "Members with Comorbid & Pharma Gaps";
        if(flag == 'LP')
            Heading = "Members with Lab & Pharma Gaps";
        if(flag == 'CHL')
            Heading = "Members with Historic,Comorbid & Lab Gaps";
        if(flag == 'CHP')
            Heading = "Members with Historic,Comorbid & Pharma Gaps";
        if(flag == 'CLP')
            Heading = "Members with Comorbid,Lab & Pharma Gaps";
        if(flag == 'HLP')
            Heading = "Members with Historic,Pharma & Lab Gaps";
        if(flag == 'CHLP')
            Heading = "Members with Historic,Comorbid,Lab & Pharma Gaps";

        return Heading;
    }

    const geturl = () =>{
        let url = ''
        if(gap_or_reported == LIKELIHOOD_STATUS){
            url = '/ACALikelihoodMembers/year/'+year+'/issuer_id/'+issuer_id +'/likelihood_flag/'+likelihood_flag
            return url
        }
        else{
            url = '/ACAMembers/risk_model/'+risk_model+'/metal_tier/'+metal_tier+'/year/'+year+'/gap_or_reported/'+gap_or_reported+'/issuer_id/'+issuer_id +'/flag/'+flag
            return url
        }
    }

    const memberCols = [
        {
            label : "Member ID",
            name:"MEMBER_ID",
            options : anchorTag
        },
		{
            name : "MEMBER_Name",
            label : "Member Name"
        },
        {
            name : "Carrier_ID",
            label : "Carrier ID"
        },
        {
            name : "Gap",
            label : "Gap HCC",
            options: hccSeperator					
		},
        {
            name : "Gap_Score",
            label : "Gap Score",
            options:addSuffixTwoDecimal
        },
        {
            name : "Risk_Score",
            label : "Risk Score",
            options:addSuffixTwoDecimal
        },
        {
            name : "PERCENT_OF_GAP",
            label : "% of Gap"
        },
        {
            name : "Metal_Tier",
            label : "Metal Tier"
        },
        {
            name : "Risk_Model",
            label : "Risk Model"
        },
        {
            name : "Gender",
            label : "Gender"
        },
        {
            name : "Plan_ID",
            label : "Plan ID"
        }
    ];

    const memberTableClick = (rowdata, colData, cellMeta) =>{
        if((cellMeta.colIndex === 0)&&(colData.props.children != "")){
            localStorage.setItem(MEMBER_ID,rowdata[cellMeta.rowIndex].MEMBER_ID);
            localStorage.setItem(MEMBER_NAME,rowdata[cellMeta.rowIndex].MEMBER_Name);
            localStorage.setItem(CARRIER_ID,rowdata[cellMeta.rowIndex].Carrier_ID);
            localStorage.setItem(PLAN_ID,rowdata[cellMeta.rowIndex].Plan_ID);
            localStorage.setItem(GENDER,rowdata[cellMeta.rowIndex].Gender);
            history.push('/layout/acaMember', GAP_INSIGHTS)
        }
        else if((cellMeta.colIndex === 3)&&(colData.props.children != "")){
            if(typeof(colData.props.children) == "object"){
                localStorage.setItem(HCC,(cellMeta.event.target.innerText).replace(',',''));
            }
            else{
                localStorage.setItem(HCC,rowdata[cellMeta.rowIndex].Gap);
            }
            localStorage.setItem(ISSUER_ID,rowdata[cellMeta.rowIndex].ISSUER_ID);
            history.push('/layout/AcaGapHcc')
        }
    };

    return(
        <div>
            <CardComponent
            heading={getHeading()}
            body={
                <ServerSideGridTable
                    url={geturl()}
                    columns={memberCols}
                    tableClick={memberTableClick}
                ></ServerSideGridTable>
            }></CardComponent>
             <br/>
            <br/>     
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(GapSummaryMembersACA);