const serviceOptions = {
 
 
    URI : 'http://localhost:8080/RAFScoreService',
     TOKEN : JSON.parse(localStorage.getItem("Token"))
 
}

export const setToken = (token)=>{
    serviceOptions.TOKEN = token;
}

export default serviceOptions;