import { useEffect, useState } from "react";
import api from "../../assets/constants/Rafservice.Instance";
import { CLAIM_ID, MEMBER_ID } from "../../assets/constants/string.constants";
import { getAcaYear } from "../../assets/constants/yearList";
import CardComponent from "../../components/Cards/card";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { dateFormat } from "../../assets/constants/customRender";
import { connect } from 'react-redux';

const MemberClaimDetailsACA = (props) =>{

    const [claimId] = useState(localStorage.getItem(CLAIM_ID));
    const [memberId] = useState(localStorage.getItem(MEMBER_ID));
    const [year] = useState(props.yearSelect);
    const [status, setStatus] = useState('');
    const [reason, setReason] = useState('');

    const loadClaimStatus = () =>{
        api.get('/acaClaimStatus/year/'+ year +'/claim_id/'+ claimId)
        .then(res=>{
            setStatus(res.aaData[0].ACCEPTED_OR_REJECTED)
            setReason(res.aaData[0].REJECTION_REASON)
        })
        .catch(err=>{
            // console.log(err);
        })
    };

    const claimDetailCols = [ 
        {
            label : "Claim Id",
            name:"CLAIM_ID"
        },
        {
            label : "Service Line No",
            name:"SERVICE_LINE_NO"
        },
        {
            label : "Procedure Code",
            name:"PROCEDURE_CODE"
        },
        {
            label : "Facility Code",
            name:"FACILITY_CODE"
        },
        {
            label : "Provider Number",
            name:"RENDERING_PROVIDER_NUMBER"
        },
        {
            label : "Charge Amount",
            name:"CHARGE_AMOUNT"
        },
        {
            label : "Date of Service",
            name:"DATE_OF_SERVICE",
            Option:dateFormat
        }
    ]

    useEffect(()=>{
        loadClaimStatus();
    })

    return(
        <div className="member-list">
            <CardComponent
            heading={`Service Line Details for Claim ID : ${claimId}`}
            subHeading={`Status : ${status} Rejection Reason :${reason}`}
            body={
                <ServerSideGridTable
                    url={'/acaClaimDetails/year/'+ year +'/claim_id/'+ claimId+'/memberId/'+ memberId}
                    columns={claimDetailCols}                    
                ></ServerSideGridTable>
            }></CardComponent>
             <br/>
            <br/>     
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(MemberClaimDetailsACA);
