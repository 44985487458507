import React, { useState, useEffect } from "react";
import { WIDGET_TYPES } from "../../assets/constants/string.constants";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { connect } from 'react-redux';
import GridTable from "../../components/TableGrids/ETLGrid/GridTable.table";
import CardComponent from "../../components/Cards/card";
import SlickSlider from '../../components/SlickSlider/ETLSlickSlider';
import HeaderForETL from '../../components/home/header/HeaderForETL';
import Popup from './UploadButtonDialog';
import { ByteConverter } from "../../assets/constants/customRender";
const aws = require('aws-sdk');

    //const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
    const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME_Two;
    const REGION =process.env.REACT_APP_REGION;
    const ACCESS_KEY =localStorage.getItem("access_key");
    const SECRET_ACCESS_KEY =localStorage.getItem("secret_key");

    const FileUpload = () => {
        
    const [WidgetDetails, setWidgetDetails] = useState({});
    const [tableData, setTableData] = useState([]);
    const [Count, setCount] = useState(1);
    const [folderName, setFolderName] = useState([]);

    let sliderRangeInt = 0;
    let keyList = [];
    //let Bucket_List = [S3_BUCKET, S3_BUCKET_SECOND];
    let Bucket_List = [S3_BUCKET];
   
    const WidgetDetailsType = [
        WIDGET_TYPES.LOCKICON,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.PERCENT,
        WIDGET_TYPES.SCORE,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.TRENDING,
        WIDGET_TYPES.PERCENT,
    ]
    
    const type = WidgetDetailsType;
    const widgetStyles = {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.15,
        color: "white"
    };

    sliderRangeInt = parseInt(sliderRangeInt);
    const loadData = () =>{
    (async function() {
      const setTableDataList =[]
        try{
          aws.config.update({
            accessKeyId: ACCESS_KEY,
            secretAccessKey: SECRET_ACCESS_KEY,
            region: REGION
          });
          const s3d = new aws.S3();
          const response = await s3d.listObjectsV2({
            Bucket: S3_BUCKET,
          }).promise();
          const json_format = JSON.stringify(response.Contents)
          const stringify = JSON.parse(json_format)
          for (var i = 0; i < stringify.length; i++) {
            if(stringify[i]['Size']!=0){
              setTableDataList.push(stringify[i]);
            }
        }
        setTableData(setTableDataList)
        setFolderName(stringify)
        setCount(setTableDataList.length);
      
        } catch(e) {
          console.log('our error ',e)
        }
      })();}

    const loadFolderCount = () => {

        setWidgetDetails({
            '#Files': Count
        });
    sliderRangeInt = 1;
    };

    const getIcon = (type) => {
    var data = ''
    switch (type) {
        case Count:
            data = <folderOpen style={widgetStyles} />
            break;
    }

    return data;
}

const getKey =() => {
  let value =[]
  folderName.forEach((element, index) => {
        if((element.Key).includes('/')) {
            const keyWithSubString =(element.Key).substr(0,((element.Key).lastIndexOf('/')))
            value.push(keyWithSubString);     
        } else{ //DO NOTHING 
        }
    });
    valueCheck(value)
}
  const valueCheck = (value) =>{
    const newArray = Array.from(new Set(value))  
    newArray.forEach(el => {
     // setSetListSet(el)
      keyList.push(el)
    })
  }

  useEffect(() => { 
    loadData();
  },[]);

  useEffect(() => {
    loadFolderCount();
  },[Count]);

  useEffect(() => {
    getKey()
  })

const GapRecoCols = [
  {
      label: 'Files',
      name: 'Key',
  },
  /* {
      label: 'Type',
      name: 'type'

  }, */
  {
      label: 'Last Modified / Accessible Date',
      name: 'LastModified'

  },
  {
      label: 'Size',
      name: 'Size',
      options : ByteConverter
  },
];

    return (
       <div>
            <div className="card-header">
            <div className="card-header">
                <HeaderForETL heading={'File Upload'} />
            </div>   
            <SlickSlider WidgetDetails={WidgetDetails} getIcon={getIcon} type={type} />
              <Popup
                bucketName = {Bucket_List}
                folderSelectName ={keyList}
                loadData = {loadData}
              ></Popup> 
              <CardComponent
                  body={
                  <div style={{'marginBottom':'4%'}}>
                  <GridTable
                    count={Count}
                    data={tableData}
                    columns={GapRecoCols}
                  >
                  </GridTable>
                  </div>
                  }>
              </CardComponent>
              <div>
              </div>
            </div>  
      </div>           
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
    };
  }
export default connect(mapStateToProps)(FileUpload);
