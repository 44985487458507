import { useState } from "react";
import CardToggle from "./CardToggling";
import PropTypes from "prop-types";

const AwvCardComponent = (props) =>{

    const{heading,subHeading,body,cardRefreshm, additionalClass} = props;

    const [cardToggle, setcardToggle] = useState(true);
    //const [cardRefresh,setCardRefresh] =useState
    
    return(
        <div>
            <div className="card">
                <div className="card-header">
                    <span className="d-flex bd-highlight">
                        <h6 className={'pt-2 flex-grow-1 header-title-text'}>{heading}</h6>
                        <h6 className="p-2 flex-grow-1">{subHeading}</h6>
                        <CardToggle 
                        onClick={(e)=>{setcardToggle(e)}}></CardToggle>
                    </span>
                </div>
                {cardToggle&&<div className="card-body">{body}</div>}
            </div>
        </div>
    )
}

AwvCardComponent.propTypes ={
    heading : PropTypes.string,
    body: PropTypes.element,
    subHeading : PropTypes.string,
    additionalClass:  PropTypes.string
}

export default AwvCardComponent;
