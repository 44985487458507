import React, { useRef, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../../store/actions';
import AWVApi from '../../../assets/constants/AWVRafservice.Instance';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import Divider from '@mui/material/Divider';
import { useForm, Controller } from "react-hook-form";
import Box from '@mui/material/Box';
import pdfIcon from '../../../assets/images/pdf_icon.png';
import Moment from 'moment';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Button from "../../layout/aweLayout/Button";
import CloseIcon from "../../icons/CloseIcon";
import CalendarIcon from "../../icons/CalendarIcon";
import IconButton from "../../layout/aweLayout/IconButton";

const AWVMemberDetailsMultiCoder = (props) => {
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
    const [editFormData, setEditFormData] = useState({});
    const [coderRecordList, setCoderRecordList] = useState({});
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    useEffect(() => {
        if (props.aweRightSidebarType?.cin_id && props.aweRightSidebarType?.cin_id != '') {
            editLoadFormData(props.aweRightSidebarType?.cin_id, props.aweRightSidebarType?.awv_id);
        }

    }, [props.aweRightSidebarType?.cin_id]);

    useEffect(() => {
        if (editFormData?.checklist_id && editFormData?.checklist_id != '') {
            setValue('awe_id', String(props.aweRightSidebarType?.awv_id));
            setValue('cin', String(props.aweRightSidebarType?.cin_id));
        }
    }, [editFormData?.checklist_id]);

    const notificationRightDrawer = (open, valueId = '') => {
        props.currentAWERightSidebarType({ 'type': 'notification' });
        props.currentAWERightSidebar(open);
    };

    const editLoadFormData = (cinId, awvId) => {
        setIsLoading(true);
        AWVApi.get('/getawecoderchecklist?cin=' + cinId + '&awe_id=' + awvId + '&year=' + props?.yearSelect + '&lob=' + props?.lobSelect + '&organisation_id=1')
            // AWVApi.get('/get_member_hcc_details?member_id=3RE5W09MM81')
            .then(res => {
                if (res.data && res.data.length > 0) {
                    console.log(res.data);
                    setCoderRecordList(res.data);
                    setIsLoading(false);
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
            })
    }


    const onSubmit = (data) => {
        if (coderRecordList && coderRecordList.length > 0) {

            setFormSubmitisLoading(true);
            let controlUser = JSON.parse(localStorage.getItem('controlUser'));
            data.cin = props.aweRightSidebarType?.cin_id;
            data.awe_id = props.aweRightSidebarType?.awv_id;
            if (props?.aweRightSidebarType?.status && props?.aweRightSidebarType?.status == '1') {

            } else {
                data.checklist = [];
                coderRecordList && coderRecordList.length > 0 && coderRecordList.map((element, index) =>

                    data.checklist.push({
                        'remarks': (data.remark[element.checklist_id] && data.remark[element.checklist_id] != 'null') && (data.status[element.checklist_id] && data.status[element.checklist_id] != 'null') ? data.remark[element.checklist_id] : '',
                        'status': (data.status[element.checklist_id] && data.status[element.checklist_id] != 'null') ? data.status[element.checklist_id] : '',
                        'checklist_id': element.checklist_id.toString()
                    })
                );
                if (coderRecordList && data.checklist) {
                    delete data.remark;
                    delete data.status;
                    data.updated_by = controlUser?.user_id;
                    // adding lob, year, org id in payload
                    data.lob = props?.lobSelect;
                    data.year = props?.yearSelect;
                    data.organisation_id = '1';
                    if(data?.lob)
                        data.lob = data.lob.toString();
                    AWVApi.put(process.env.REACT_APP_PATH_CODER_CHECKLIST_URL +'/update_awe_coder_checklist', data)
                        .then(res => {
                            setValue('remark', '');
                            setValue('status', '');
                            setValue('checklist_id', '');
                            setValue('awe_id', '');
                            setValue('cin', '');
                            setFormSubmitisLoading(false);
                            toast.success('Your submission of checklist is accepted for updation, it will be updated soon.');
                            notificationRightDrawer(false);
                            props.currentAWERightSidebarCloseDatatableReload(true);
                        })
                        .catch(err => {
                            setFormSubmitisLoading(false);
                            toast.error(err?.response?.data?.message);
                        })
                }
            }
        }

    };
    return (
        <div className="diagnosis_code_sidebar offcanvas offcanvas-end show" tabIndex={-1} aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header flex_item_cb">
                <h5 id="offcanvasRightLabel" className='fs15'>
                    {(props?.aweRightSidebarType?.status && props?.aweRightSidebarType?.status == '1') ?
                        'MULTI CODER CHECKLIST ADD'
                        :
                        'MULTI CODER CHECKLIST EDIT'
                    }
                </h5>
                <Tooltip title="Close">
                    <IconButton type="button" buttonType={"default"} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => notificationRightDrawer(false)}>
                        <CloseIcon className="w24" />
                    </IconButton>
                </Tooltip>
            </div>
            <Divider />
            <div className="offcanvas-body pt-1 custom-scrollbar overflow-auto">
                <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                    {coderRecordList && coderRecordList.length > 0 && coderRecordList.map((element, index) => (
                        <div key={index} data-title={index}>
                            <div className="flex_item_cb custom_row flex-wrap mb20">
                                <div className="custom_col">
                                    <div className="">
                                        <h6 className="fs14">AWE SOURCE</h6>
                                        <p className="fs13 fw500">{element?.description}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group mb20">
                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Status</label>
                                {/* <Controller
                                    name={"status[" + element.checklist_id + "]"}
                                    control={control}
                                    render={({ field }) => ( */}

                                <select
                                    name={"status[" + element.checklist_id + "]"}
                                    className="form-control"
                                    {...register("status[" + element.checklist_id + "]", {
                                        required: false
                                    })}
                                >
                                    <option value={''} selected={(element?.status == '') ? true : false}>
                                        Select
                                    </option>
                                    <option value={'1'} selected={(element?.status == '1') ? true : false}>
                                        Confirmed
                                    </option>
                                    <option value={'0'} selected={(element?.status == '0') ? true : false}>
                                        Not Confirmed
                                    </option>
                                </select>
                                {/* )}
                                    rules={{
                                        required: true,
                                    }}
                                /> */}
                                {(element.checklist_id && errors?.status && errors?.status[element.checklist_id]?.type === "required") && <label className="form-input-error">This field is required</label>}
                            </div>
                            <div className="form-group mb20">
                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2">REMARKS</label>
                                {/* <Controller
                                    name={"remark[" + element.checklist_id + "]"}
                                    control={control}
                                    render={({ field }) => ( */}
                                <textarea rows={3}
                                    name={"remark[" + element.checklist_id + "]"}
                                    className="form-control"
                                    {...register("remark[" + element.checklist_id + "]", {
                                        required: false
                                    })}
                                >
                                    {element?.remark}
                                </textarea>
                                {/* )}
                                    rules={{
                                        required: true,
                                    }}
                                /> */}
                                {(element.checklist_id && errors?.remark && errors?.remark[element.checklist_id]?.type === "required") && <label className="form-input-error">This field is required</label>}
                            </div>
                        </div>
                    ))}

                    <div className="form-group flex_item_cs gap10">
                        <Button type='button' className={"btn-white minw80"} onClick={() => notificationRightDrawer(false)}>CANCEL</Button>
                        <Button type='submit' className={"minw80"}
                            disabled={formSubmitisLoading && formSubmitisLoading == true ? true : false}>
                            {(props?.aweRightSidebarType?.status && props?.aweRightSidebarType?.status == '1') ?
                                'ADD'
                                :
                                'UPDATE'
                            }
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(AWVMemberDetailsMultiCoder);

