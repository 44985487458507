import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../components/Tab/TabPanel";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { connect } from 'react-redux';
import LineChart from '../../components/GChart/LineChart';
import BarVerticalHCC from '../../components/GChart/BarVerticalHCC';
import SubHeader from '../../components/home/header/subHeader';
import { getMeInitialSelect} from '../../assets/constants/meInitialList';
import Button from '@material-ui/core/Button';
import { Tooltip } from '@mui/material';
import {
  CLAIM_ID,
  ENCOUNTERS_SUMMARY,
  CR_INSIGHTS,
  GAP_INSIGHTS,
  MAO_RISK_CONDITION_CHART,
  MAO_MEMBER_ID,
  MEMBER_ID,
  MEMBER_NAME,
  PLAN_ID,
  REPORTED_INSIGHTS,
  UTILIZATION,
  PREDICTION,
  WIDGET_TYPES,
  HCC,
  MAO_MEMBER_SELECT_TAB,
  MAO_Chart_Review_Member
} from "../../assets/constants/string.constants";
import GridTable from "../../components/TableGrids/GridTable.table";
import api from "../../assets/constants/Rafservice.Instance";
import { getAcaYear } from "../../assets/constants/yearList";
import {
  anchorTag,
  numberWithCommas,
  stringFold,
  tickCross,
  addSuffixPercent,
  addSuffixTwoDecimal,
  addSuffixTwoPercent,
  textToSignFormatRender,
  addSuffixDollar
} from "../../assets/constants/customRender";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import WidgetCard from "../../components/Cards/WidgetCard";
import { revenueFormat } from "../../assets/helpers/formats.helpers";
import SingleHorizontalBar from "../../components/Charts/SingleHorizontalBar.chart";
import history from "../../history";
import CardComponent from "../../components/Cards/card";
import SlickSlider from '../../components/SlickSlider/SlickSlider';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const MaoMemberSummary = (props) => {
  const classes = useStyles();

  const [value, setValue] = useState((localStorage.getItem(MAO_MEMBER_SELECT_TAB) && localStorage.getItem(MAO_MEMBER_SELECT_TAB) != '') ? localStorage.getItem(MAO_MEMBER_SELECT_TAB) : REPORTED_INSIGHTS);
  const [year] = useState(props.yearSelect);
  const [memberId] = useState(localStorage.getItem(MEMBER_ID));
  const [Member_Name] = useState(localStorage.getItem(MEMBER_NAME));
  const [planId] = useState(localStorage.getItem(PLAN_ID));
  const [comorbidData, setcomorbidData] = useState([]);
  const [SUWidgetDetails, setSUWidgetDetails] = useState({});
  const [SuChartData, setSuChartData] = useState({});
  const [CRWidgetDetails, setCRWidgetDetails] = useState({});
  const [Gapconfiguration, setGapconfiguration] = useState([]);
  const [planDetails, setPlanDetails] = useState([]);
  const [memberScoreDetails, setmemberScoreDetails] = useState([]); 
  const [memberDetails, setmemberDetails] = useState([]);  
  const [memberIdData, setMemberIdData] = useState(localStorage.getItem(MAO_Chart_Review_Member));  
  const [WidgetReportedInsightsDetails, setWidgetReportedInsightsDetails] = useState({});
  const [HCCContributionReportedInsightsChartData, setHCCContributionReportedInsightsChartData] = useState([]);
  const [HCCTrendReportedInsightsChartData, setHCCTrendReportedInsightsChartData] = useState([]);
  const [tableHCCReportedInsightsDetailsData, settableHCCReportedInsightsDetailsData] = useState([]);
  const [tableGapInsightsDetailsData, settableGapInsightsDetailsData] = useState([]);
  const [WidgetUtilizationDetails, setWidgetUtilizationDetails] = useState({});
  const [HCCUtilizationChartData, setHCCUtilizationChartData] = useState([]);
  const [WidgetCRInsightsDetails, setWidgetCRInsightsDetails] = useState({});
  const [tableCRInsightsDetailsData, settableCRInsightsDetailsData] = useState([]);
  const [WidgetEncountersSummaryDetails, setWidgetEncountersSummaryDetails] = useState({});
  const [MemberEncountersSummaryData, setMemberEncountersSummaryData] = useState([]);

  useEffect(() => {
      loadWidgetEncountersSummaryDetails();
      loadWidgetReportedInsightsDetails();
      loadHCCReportedInsightsContribution();
      loadHCCReportedInsightsTrend();
      loadHCCReportedInsightsDetailsTable();
      loadHCCGapInsightsDetailsTable();
      loadWidgetCRInsightsDetails();
      loadCRInsightsDetailsTable();
      loadWidgetUtilizationDetails();
      loadUtilizationDetailsChart();
  }, [        
    props.moduleSelect,
    props.viewBySelect,
    props.modelSelect,
    props.metalTierSelect,
    props.planSelect,
    getAcaYear(),
    getMeInitialSelect(),
  ]);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

    const WidgetDetailsType = [
      WIDGET_TYPES.LOCKICON,
      WIDGET_TYPES.MEMBERS,
      WIDGET_TYPES.PERCENT,
      WIDGET_TYPES.SCORE,
      WIDGET_TYPES.MEMBERS,
      WIDGET_TYPES.TRENDING,
      WIDGET_TYPES.PERCENT,
    ]

    const type = WidgetDetailsType;
    const widgetStyles = {
        position: 'absolute',
        left: 0,
        //right: 20,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.15,
        color: "white"
    };

    //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    const loadWidgetEncountersSummaryDetails = () => {
          api.get('/MaoCRMember/year/'+getAcaYear()+'/id/'+memberIdData+'/period/'+getMeInitialSelect())
          .then(res => {
              setMemberEncountersSummaryData(res);
              setWidgetEncountersSummaryDetails({
                  'Total Members': res[0].memberID,
                  'Members With CR': res[0].reportedRAF,
                  'CR Corrected Revenue': res[0].correctionInRAF,
                  'Overall Revenue With CR': res[0].correctedOverallRevenue,
                  '% Of RAF Score Contributed By CR': res[0].revenueCorrection,
                  '% Of RAF Score Contributed By CR': res[0].chartReviewAsAPercentageOfCorrectedRevenue,
              });
          })
          .catch(err => {
              // console.log(err);
          });
    };

    //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    const loadWidgetReportedInsightsDetails = () => {
          api.get('/membercompletesummary/id/'+memberIdData+'/year/'+getAcaYear()+'/period/'+getMeInitialSelect())
          .then(res => {
              setWidgetReportedInsightsDetails({
                  'RAF Gap %': res[0].rafGapPercentage,
                  'Current RAF Score': res[0].currentRAF,
                  'Expected RAF Score': res[0].expectedRAF,
                  'Bottom 25 percentile of Utilization': '0',
                  'Yield Ratio': res[0].percentageReportedYield,
                  'Utilization Trend': res[0].percentageUtilizationTrend
              });
          })
          .catch(err => {
              // console.log(err);
          });
    };

    //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    const loadHCCReportedInsightsContribution = () => {        
      let newChartData = [];
          newChartData.push(["HCC", "Percentage", { role: 'annotation' }]);
          api.get('/membercompletehccsummary/id/'+memberIdData+'/year/'+getAcaYear()+'/period/'+getMeInitialSelect())
              .then(res => {
                  for (const dataObj of res) {
                      newChartData.push([dataObj.hcc, dataObj.percentageContToMember, dataObj.percentageContToMember+'%']);
                  }
                  setHCCContributionReportedInsightsChartData(newChartData);
          })
          .catch(err => {
              // console.log(err);
          })
    }

    //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    const loadHCCReportedInsightsTrend = () => {        
      let newChartData = [];
          newChartData.push(["Year", "HCC Score", "CR Score"]);
          api.get('/hccTrend/id/'+memberIdData+'/year/'+getAcaYear()+'/period/'+getMeInitialSelect())
              .then(res => {
                  for (const dataObj of res) {
                      newChartData.push([dataObj.raf_year, dataObj.hcc_score, dataObj.cr_score]);
                  }
                  setHCCTrendReportedInsightsChartData(newChartData);
          })
          .catch(err => {
              // console.log(err);
          })
    }

    //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    const loadHCCReportedInsightsDetailsTable = () =>{
          api.get('/membercompletesummary/id/'+memberIdData+'/year/'+getAcaYear()+'/period/'+getMeInitialSelect())
          .then(res=>{
            settableHCCReportedInsightsDetailsData(res);
          })
          .catch(err=>{
              // console.log(err);
          })
    }
  
    //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    const loadHCCGapInsightsDetailsTable = () =>{
        api.get('/SuspectMember/year/'+getAcaYear()+'/id/'+memberIdData+'/period/'+getMeInitialSelect())
        .then(res => {
          settableGapInsightsDetailsData(res);
        })
        .catch(err => {
            // console.log(err);
        });
    }

    //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    const loadWidgetCRInsightsDetails = () => {
        api.get('/CRMember/year/'+getAcaYear()+'/id/'+memberIdData+'/period/'+getMeInitialSelect())
          .then(res => {
            setWidgetCRInsightsDetails({
                  'Reported RAF': res[0].reportedRAF,
                  'Corrected RAF': res[0].correctionInRAF,
                  'Corrected Overall Revenue':  '$'+ Number(res[0].correctedOverallRevenue).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  'Revenue Correction':  '$'+ Number(res[0].revenueCorrection).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                  '% Contribution by CR': res[0].chartReviewAsAPercentageOfCorrectedRevenue,
              });
          })
          .catch(err => {
              // console.log(err);
          });
    };
    
     //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    const loadCRInsightsDetailsTable = () =>{
        api.get('/CRMember/year/'+getAcaYear()+'/id/'+memberIdData+'/period/'+getMeInitialSelect())
        .then(res => {
          settableCRInsightsDetailsData(res);
        })
        .catch(err => {
            // console.log(err);
        });
    }
   
     //Undefined year and period issue- removing props.yearSelect
    const loadWidgetUtilizationDetails = () => {
          api.get('/suMemberUtilization/'+getAcaYear()+'/id/'+memberIdData)
          .then(res => {
            setWidgetUtilizationDetails({
                  'Utilization': '',
                  'Reported Revenue': '',
                  'Revenue Post CR': '',
                  'Yield': '',
                  'Yield Post CR': '',
                  'No of Claims': ''
              });
          })
          .catch(err => {
              // console.log(err);
          });
    };
    
    const loadUtilizationDetailsChart = () =>{
      let newChartData = [];
          newChartData.push(["HCC", "Percentage", { role: 'annotation' }]);
          api.get('/suTopfiveServiceCategory/'+props.yearSelect+'/id/'+memberIdData)
              .then(res => {
                  for (const dataObj of res) {
                      newChartData.push([dataObj.description, dataObj.percentContMeberUtilization, dataObj.percentContMeberUtilization+'%']);
                  }
                  setHCCUtilizationChartData(newChartData);
          })
          .catch(err => {
              // console.log(err);
          })
    }

    const getIcon = (type) => {
        var data = ''
        switch (type) {
            case WIDGET_TYPES.ISSUER:
                data = <AccountBalanceTwoToneIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.MEMBERS:
                data = <PeopleAltIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERCENT:
                data = <ShowChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.SCORE:
                data = <BarChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERSON:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.CHILD:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.ADULT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.INFANT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.REVENUE:
                data = <AttachMoneyIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.COUNT:
                data = <ShoppingCartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.LOCKICON:
                data = <LockOpenIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.TRENDING:
                data = <TrendingUpIcon style={widgetStyles} />
                break;
        }

        return data;
    }


    const  MemberEncountersSummaryColumns =[
      {
          label: "Reported HCCs",
          name: "reportedHCCS",
      },
      {
          name: "addedHccs",
          label: "Added HCCs",
      },
      {
          name: "deletedHccs",
          label: "Deleted HCCs",
      },
      {
          name: "reportedHCCScore",
          label: "Reported HCC Score",
      },
      {
          name: "correctionInHCCScore",
          label: "Correction In HCC Score",
      },
      {
          name: "reportedInteractionScore",
          label: "Reported Interaction Score",
      },
      {
          name: "correctionInInteractionScore",
          label: "Correction In Interaction Score",
      },
    ]

    const  AddCodesEncountersSummaryColumn =[
      {
          label: "Claim Id",
          name: "ClaimId",
      },
      {
          name: "LinkedFlag",
          label: "Linked Flag",
      },
      {
          name: "ReportedDiagnosis",
          label: "Reported Diagnosis",
      },
      {
          name: "ReportedHccs",
          label: "Reported Hccs",
      },
      {
          name: "AddedHccs",
          label: "Added Hccs",
      },
      {
          name: "AddedDiagnosis",
          label: "Added Diagnosis",
      },  
    ]

    const  DeletedCodesEncountersSummaryColumn =[
      {
          label: "Claim Id",
          name: "ClaimId",
      },
      {
          name: "LinkedFlag",
          label: "Linked Flag",
      },
      {
          name: "ReportedDiagnosis",
          label: "Reported Diagnosis",
      },
      {
          name: "ReportedHccs",
          label: "Reported Hccs",
      },
      {
          name: "DeletedHccs",
          label: "Deleted Hccs",
      },
      {
          name: "DeletedDiagnosis",
          label: "Deleted Diagnosis",
      },
        
    ]

    const  memberClaimDetailsReportedInsightsColumn =[
        {
            name: "ClaimId",
            label: "Claim Id",
            // options : anchorTag
        },
        {
            name: "Type",
            label: "Claim Type",
        },
        {
            name: "Patient_type",
            label: "Patient Type"
        },
        {
            name: "Provider_num",
            label: "Provider No"
        }, 
        {
            name: "otherhcc",
            label: "HCCs"
        }, 
        {
            name: "oldhcc",
            label: "HCCs From Old Model"
        }, 
        {
            name: "newhcc",
            label: "HCCs From New Model"
        }, 
        {
            name: "StartDate",
            label: "Date of Service"
        }, 
        {
            name: "Dateofsubmission",
            label: "Date of submission"
        }, 
        {
            name: "Eligible",
            label: "Eligible for RAF",
            options: textToSignFormatRender
        }, 
        
    ]

    const  summaryHCCReportedInsightsColumns =[
      {
          name: "memberId",
          label: "Member ID"
      },
      {
          name: "currentRAF",
          label: "Current RAF"
      },
      {
        name: "rafGapPercentage",
        label: "Raf Gap Percentage"
    },
    {
          name: "expectedRAF",
          label: "Expected RAF"
    },
    {
        name: "currentUtilization",
        label: "Current Utilization"
    },
    {
        name: "utilizationFlag",
        label: "Utilization Flag"
    },
    {
        name: "percentageReportedYield",
        label: "Percentage Reported Yield",
        options: {
            display: false,
            customBodyRender: value =>{
                if(value && value != '')
                    return (value+' %');
                
            }
          },
          
    },
    {
        name: "percentageUtilizationTrend",
        label: "Percentage Utilization Trend",
        options: {
            display: false,
            customBodyRender: value =>{
                if(value && value != '')
                    return (value+' %');
                
            }
          },
    },
   
    {
        name: "revenuePostCR",
        label: "Revenue Post CR",
        options: {
            display: false,
          },
    },
    {
        name: "percentageCRContribution",
        label: "Percentage CR Contribution"
    },
    {
        name: "reportedRevenue",
        label: "Reported Revenue",
        options: {
            display: false,
          },
    },
    {
        name: "riskScoreIncreasePredection",
        label: "Risk Score Increase Predection"
    },
    {
        name: "totalClaims",
        label: "Total Claims"
    },
    {
        name: "totalHCCCurrentYear",
        label: "Total HCC Current Year"
    },
    {
        name: "percentageIncreaseNewHCC",
        label: "Percentage Increase New HCC"
    },
    {
        name: "percentageChangeInClaim",
        label: "Percentage Change In Claim"
    },
    {
        name: "percentageChangeInClaimString",
        label: "Percentage Change In Claim String"
    },
    ]

    const  summaryGapInsightsColumns =[
      {
          name: "reported_hcc",
          label: "Reported HCCs"
      },
      {
          name: "gap_hcc",
          label: "Gap HCCs",
      },
      {
          name: "raf_score",
          label: "RAF Score",
          options:addSuffixTwoDecimal,
      },
      {
          name: "gap_in_raf_score",
          label: "Gap In RAF Score",
          options:addSuffixTwoDecimal,
      }, 
      {
          name: "revenu_leekage",
          label: "Potential Revenue Impact $",
          options:addSuffixDollar,
      }, 
      {
          name: "reported_revenue",
          label: "Potential Revenue Impact %",
          options:addSuffixTwoPercent,
      }, 
      {
          name: "hcc_score",
          label: "Reported HCC Score",
          options:addSuffixTwoDecimal,
      }, 
      {
          name: "hcc_score_with_gap",
          label: "Gap In HCC Score",
          options:addSuffixTwoDecimal,
      }, 
      
    ]

    const  GapInsightsRepotedHCCColumn =[
      {
          name: "hccCode",
          label: "HCC Code",
          options: anchorTag
      },
      {
          name: "description",
          label: "Description"
      },
    ]

    const GapInsightsRepotedHCCTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].hccCode);
            history.push('/layout/medicare/reported_hcc');
        }
    };


    const  GapInsightsRiskImpactingColumn =[
      {
          name: "hccCode",
          label: "HCC Code",
          options: anchorTag
      },
      {
          name: "cms_model",
          label: "CMS Model"
      },
      {
          name: "Hcc_Coef",
          label: "HCC Coefficient",
          options:addSuffixTwoDecimal,
      },
      {
          name: "Historic",
          label: "Historic",
          options: textToSignFormatRender
      },
      {
          name: "Co-morbid",
          label: "Co-Morbid",
          options: textToSignFormatRender
      },
      {
          name: "Lab",
          label: "Lab / Others",
          options: textToSignFormatRender
      },
      {
          name: "Max_probability",
          label: "Max Probability"
      },
      {
          name: "Min_probability",
          label: "Min Probability"
      },
      {
          name: "Likelihood_flag",
          label: "Likelihood Flag"
      },
      {
          name: "closedBy",
          label: "Gap Closed"
      },
      {
          name: "recommendation",
          label: "Recommendation"
      },
    ]
  
    const GapInsightsRiskImpactingTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].hccCode);
            history.push('/layout/medicare/gap_hcc');
        }
    };

    const  GapInsightsNonRiskImpactingColumn =[
      {
          name: "hccCode",
          label: "HCC Code",
          options: anchorTag
      },
      {
          name: "cms_model",
          label: "CMS Model"
      },
      {
          name: "Hcc_Coef",
          label: "HCC Coefficient",
          options:addSuffixTwoDecimal,
      },
      {
          name: "Historic",
          label: "Historic"
      },
      {
          name: "Co-morbid",
          label: "Co-Morbid"
      },
      {
          name: "Pharma",
          label: "Pharma"
      },
      {
          name: "Lab",
          label: "Lab / Other"
      },
    ]
    const GapInsightsNonRiskImpactingTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].hccCode);
            history.push('/layout/medicare/gap_hcc');
        }
    };

    const  GapInsightsClaimDetailsColumn =[
      {
          name: "ClaimId",
          label: "Claim Id",
          options: anchorTag
      },
      {
          name: "Eligible",
          label: "Eligible For RAF"
      },
      {
          name: "Diagnosis_codes",
          label: "Diagnosis Codes"
      },
      {
          name: "Date_Of_Service",
          label: "Date Of Service"
      },
      {
          name: "Type",
          label: "Claim Type"
      },
      {
          name: "Provider_num",
          label: "Provider No"
      },
      {
          name: "comorbid_gap",
          label: "Comorbid Flag"
      },
      {
          name: "providerPriority",
          label: "Provider Priority"
      },
      {
          name: "otherhcc",
          label: "HCCs"
      },
      {
          name: "comorbid_hcc",
          label: "Comorbid Gap HCCs"
      },
    ]
    const GapInsightsClaimDetailsTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(CLAIM_ID, rowdata[cellMeta.rowIndex].ClaimId);
            history.push('/layout/medicare/claim');
        }
    };
    const  GapInsightsPharmaClaimDetailsColumn =[
      {
          name: "PAYMENT_YEAR",
          label: "PAYMENT YEAR"
      },
      {
          name: "CLAIM_ID",
          label: "CLAIM ID"
      },
      {
          name: "CLAIM_FROM_DATE",
          label: "CLAIM FROM DATE"
      },
      {
          name: "CLAIM_THROUGH_DATE",
          label: "CLAIM THROUGH DATE"
      },
      {
          name: "CLAIM_SUBMISSION_DATE",
          label: "CLAIM SUBMISSION DATE"
      },
      {
        name: "QUANTITY_DISPENSED",
        label: "QUANTITY DISPENSED"
      },
      {
        name: "DAYS_SUPPLY",
        label: "DAYS SUPPLY"
      },
      {
        name: "PRSRN_SRVC_REF_NO",
        label: "PRSRN SRVC REF NO"
      },
      {
        name: "PRESCRIBER_ID",
        label: "PRESCRIBER ID"
      },
      {
        name: "INGREDIENT_COST_PAID",
        label: "INGREDIENT COST PAID"
      },
      {
        name: "RAF_YEAR",
        label: "RAF YEAR"
      },
      {
        name: "RAF_PERIOD",
        label: "RAF PERIOD"
      },
      {
          name: "HCC",
          label: "HCCs"
      },
    ]
    
    const  summaryCRInsightsColumns =[
      {
          name: "reportedHCCS",
          label: "Reported HCCs"
      },
      {
          name: "addedHccs",
          label: "Added HCCs"
      },
      {
          name: "deletedHccs",
          label: "Deleted HCCs"
      },
      {
          name: "reportedHCCScore",
          label: "Reported HCC Score"
      },
      {
          name: "correctionInHCCScore",
          label: "Correction In HCC Score"
      },
      {
          name: "reportedInteractionScore",
          label: "Reported Interaction Score"
      },
      {
          name: "correctionInInteractionScore",
          label: "Correction In Interaction Score"
      },
    ]
  
    const  memberAddedCRInsightsColumn =[
      {
          name: "ClaimId",
          label: "Claim ID"
      },
      {
          name: "ProviderId",
          label: "Provider ID"
      },
      {
          name: "LinkedFlag",
          label: "Linked Flag"
      },
      {
          name: "ReportedDiagnosis",
          label: "Reported Diagnosis"
      },
      {
          name: "ReportedHccs",
          label: "Reported Hccs"
      },
      {
          name: "AddedHccs",
          label: "Added Hccs"
      },
      {
          name: "AddedDiagnosis",
          label: "Added Diagnosis"
      },
    ]
 
    const  memberDeletedCRInsightsColumn =[
      {
          name: "ClaimId",
          label: "Claim ID"
      },
      {
          name: "ProviderId",
          label: "Provider ID"
      },
      {
          name: "LinkedFlag",
          label: "Linked Flag"
      },
      {
          name: "ReportedDiagnosis",
          label: "Reported Diagnosis"
      },
      {
          name: "ReportedHccs",
          label: "Reported Hccs"
      },
      {
          name: "DeletedHccs",
          label: "Deleted Hccs"
      },
      {
          name: "DeletedDiagnosis",
          label: "Deleted Diagnosis"
      },
    ]

    const  memberUtilizationColumn =[
      {
          name: "servicecategory",
          label: "Service Category"
      },
      {
          name: "description",
          label: "Description"
      },
      {
          name: "utilization",
          label: "Utilization"
      },
      {
          name: "percentContMeberUtilization",
          label: "% Contribution to Member Utilization"
      },
      {
          name: "numberOfClaims",
          label: "Number Of Claims"
      },
    ]
 
    const  memberPredictionColumn =[
      {
          name: "CONDITION_CATEGORY",
          label: "CONDITION CATEGORY"
      },
      {
          name: "DATE_OF_PREDICTION",
          label: "DATE OF PREDICTION"
      },
      {
          name: "TWO_YEARS_AGO",
          label: "TWO YEARS AGO"
      },
      {
          name: "ONE_YEAR_AGO",
          label: "ONE YEAR AGO"
      },
      {
          name: "CURRENT_YEAR",
          label: "CURRENT YEAR"
      },
      {
          name: "ONE_YEAR_POST_PREDICTION",
          label: "ONE YEAR POST PREDICTION"
      },
      {
          name: "TWO_YEARS_POST_PREDICTION",
          label: "TWO YEARS POST PREDICTION"
      },
    ]

    const memberClaimDetailsReportedInsightsTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(MAO_Chart_Review_Member, rowdata[cellMeta.rowIndex].ClaimId);
            history.push('/layout/medicare/mao_member_summary')
        }
    }


  return (
    <div className="container level-summary">
        <div className="row mb-3">
            <div className="col-lg-12 pr-0 pl-0">
                <div className="state-level">
                    <div className="card-header">
                        <SubHeader title={'Member Summary'} />
                    </div>
                </div>
            </div>
        </div>
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                variant="fullWidth"
                textColor="primary"
                indicatorColor="primary"
                value={value}
                onChange={handleChange}
                centered
                >
                {/* <Tab
                    value={ENCOUNTERS_SUMMARY}
                    label="Encounters Summary"
                    {...a11yProps(ENCOUNTERS_SUMMARY)}
                /> */}
                <Tab
                    value={REPORTED_INSIGHTS}
                    label="Reported Insights"
                    {...a11yProps(REPORTED_INSIGHTS)}
                />
                <Tab
                    value={GAP_INSIGHTS}
                    label="Gap Insights"
                    {...a11yProps(GAP_INSIGHTS)}
                />
                <Tab
                    value={CR_INSIGHTS}
                    label="Chart Review Insights"
                    {...a11yProps(CR_INSIGHTS)}
                />
                <Tab
                    value={UTILIZATION}
                    label="Utilization"
                    {...a11yProps(UTILIZATION)}
                />
                <Tab
                    value={PREDICTION}
                    label="Prediction"
                    {...a11yProps(PREDICTION)}
                />
                </Tabs>
            </AppBar>
            <br />
            <TabPanel value={value} index={ENCOUNTERS_SUMMARY}>
                <>
                    <div className="mb-1 tab-content-title-div">              
                    <h2>MEMBER REPORTED VIEW - {memberIdData} YEAR - {props.yearSelect} / PERIOD - {props.meInitialSelect}</h2>
                    </div>
                    <div className="row mb-3">
                        <div className="container">
                            {(Object.entries(WidgetEncountersSummaryDetails).length > 0) ?                     
                                <SlickSlider WidgetDetails={WidgetEncountersSummaryDetails} getIcon={getIcon} type={type} />
                            :
                                <></>
                            }  
                        </div>
                    </div>
                    <div className="row mb-3">
                    <div className="col-md-12">
                        <CardComponent
                            heading={``}
                            body={
                                <GridTable
                                    data={MemberEncountersSummaryData}
                                    columns={MemberEncountersSummaryColumns}
                                ></GridTable>
                        }></CardComponent>
                    </div>      
                    </div>      
                    <div className="row mb-3">
                    <div className="col-md-12">
                        <CardComponent
                            heading={`ADDED CODES`}
                            body={
                                <ServerSideGridTable
                                    url={`/MaoCRMemberHcc/AddedHCCs/id/${memberIdData}/year/${getAcaYear()}/period/${getMeInitialSelect()}`}
                                    columns={AddCodesEncountersSummaryColumn}
                                ></ServerSideGridTable>
                            }></CardComponent>
                    </div>
                    </div>
                    <div className="row mb-3">
                    <div className="col-md-12">
                        <CardComponent
                            heading={`DELETED CODES`}
                            body={
                                <ServerSideGridTable
                                    url={`/MaoCRMemberHcc/DeletedHCCs/id/${memberIdData}/year/${getAcaYear()}/period/${getMeInitialSelect()}`}
                                    columns={DeletedCodesEncountersSummaryColumn}
                                ></ServerSideGridTable>
                            }></CardComponent>
                    </div>
                    </div>
                    <div className='col-lg-12 pr-0 pl-0 mt-5'></div>
                </>
            </TabPanel>
            <TabPanel value={value} index={REPORTED_INSIGHTS}>
                <>
                    <div className="mb-1 tab-content-title-div">              
                    <h2>MEMBER REPORTED VIEW - {memberIdData} YEAR - {props.yearSelect} / PERIOD - {props.meInitialSelect}</h2>
                    </div>
                    <div className="row mb-3">
                        <div className="container">
                            {(Object.entries(WidgetReportedInsightsDetails).length > 0) ?                     
                                <SlickSlider WidgetDetails={WidgetReportedInsightsDetails} getIcon={getIcon} type={type} />
                            :
                                <></>
                            }  
                        </div>
                    </div>
                    <div className="row mb-3">
                    <div className="row col-md-12">
                        <div className="col-md-6">
                        <CardComponent
                            heading={`HCC CONTRIBUTION`}
                            body={
                                <div className="box-height-chart"> 
                                    <BarVerticalHCC data={HCCContributionReportedInsightsChartData}  chartType="BarChart" height="320px" width="100%" setStorgeItemName={HCC} redirectUrl={'AcaGapHcc'}></BarVerticalHCC>
                                </div>
                        }></CardComponent>
                        </div> 
                        <div className="col-md-6">
                        <CardComponent
                            heading={`HCC TREND`}
                            body={
                                <div className="box-height-chart"> 
                                        <LineChart data={HCCTrendReportedInsightsChartData} className="bar_chart" chartType="LineChart" height="320px" width="100%"></LineChart>
                                </div>
                        }></CardComponent>
                        </div> 
                    </div>
                    </div>
                    <div className="row mb-3">
                    <div className="col-md-12">
                        <CardComponent
                            heading={`DETAILS`}
                            body={
                                <GridTable
                                    data={tableHCCReportedInsightsDetailsData}
                                    columns={summaryHCCReportedInsightsColumns}
                                    className={"pcp_details_table"}
                                    viewColumns={true}
                                ></GridTable>
                        }></CardComponent>
                    </div>      
                    </div>      
                    <div className="row mb-3">
                    <div className="col-md-12">
                        <CardComponent
                            heading={`CLAIM DETAILS`}
                            body={
                                <ServerSideGridTable
                                    url={`rafclaimdetail/id/${memberIdData}/year/${getAcaYear()}/period/${getMeInitialSelect()}`}
                                    columns={memberClaimDetailsReportedInsightsColumn}
                                    // tableClick={memberClaimDetailsReportedInsightsTableClick}
                                ></ServerSideGridTable>
                            }></CardComponent>
                    </div>
                    </div>
                    <div className='col-lg-12 pr-0 pl-0 mt-5'></div>
                </>
            </TabPanel>
            <TabPanel value={value} index={GAP_INSIGHTS}>
                <>
                    <div className="mb-3 tab-content-title-div">              
                    <h2>MEMBER GAP VIEW - {memberIdData} YEAR - {props.yearSelect} / PERIOD - {props.meInitialSelect}</h2>
                    </div>
                    <div className="row mb-3">
                    <div className="col-md-12">
                        <CardComponent
                            heading={``}
                            body={
                                <GridTable
                                    data={tableGapInsightsDetailsData}
                                    columns={summaryGapInsightsColumns}
                                ></GridTable>
                        }></CardComponent>
                    </div>      
                    </div>      
                    <div className="row mb-3">
                    <div className="col-md-12">
                        <CardComponent
                            heading={`REPORTED HCC'S`}
                            body={
                                <ServerSideGridTable
                                    url={`reportedHcc_suspect/${memberIdData}/year/${getAcaYear()}/period/${getMeInitialSelect()}`}
                                    columns={GapInsightsRepotedHCCColumn}
                                    tableClick={GapInsightsRepotedHCCTableClick}
                                ></ServerSideGridTable>
                            }></CardComponent>
                    </div>
                    </div>
                    <div className="row mb-3">
                    <div className="col-md-12">
                        <CardComponent
                            heading={`RISK IMPACTING GAP HCC DETAIL`}
                            body={
                                <ServerSideGridTable
                                    url={`SuspectMemberGapHcc/id/${memberIdData}/year/${getAcaYear()}/period/${getMeInitialSelect()}/type/Impacting`}
                                    columns={GapInsightsRiskImpactingColumn}
                                    tableClick={GapInsightsRiskImpactingTableClick}
                                ></ServerSideGridTable>
                            }></CardComponent>
                    </div>
                    </div>
                    <div className="row mb-3">
                    <div className="col-md-12">
                        <CardComponent
                            heading={`NON RISK IMPACTING GAP HCC DETAIL`}
                            body={
                                <ServerSideGridTable
                                    url={`SuspectMemberGapHcc/id/${memberIdData}/year/${getAcaYear()}/period/${getMeInitialSelect()}/type/NonImpacting`}
                                    columns={GapInsightsNonRiskImpactingColumn}
                                    tableClick={GapInsightsNonRiskImpactingTableClick}
                                ></ServerSideGridTable>
                            }></CardComponent>
                    </div>
                    </div>
                    <div className="row mb-3">
                    <div className="col-md-12">
                        <CardComponent
                            heading={`CLAIM DETAIL`}
                            body={
                                <ServerSideGridTable
                                    url={`suspectMemberclaimdetails/${memberIdData}/year/${getAcaYear()}/period/${getMeInitialSelect()}`}
                                    columns={GapInsightsClaimDetailsColumn}
                                    tableClick={GapInsightsClaimDetailsTableClick}
                                ></ServerSideGridTable>
                            }></CardComponent>
                    </div>
                    </div>
                    <div className="row mb-3">
                    <div className="col-md-12">
                        <CardComponent
                            heading={`PHARMA CLAIM DETAIL`}
                            body={
                                <ServerSideGridTable
                                    url={`PharmaClaims/${memberIdData}/year/${getAcaYear()}/period/${getMeInitialSelect()}`}
                                    columns={GapInsightsPharmaClaimDetailsColumn}
                                    tableClick={GapInsightsClaimDetailsTableClick}
                                ></ServerSideGridTable>
                            }></CardComponent>
                    </div>
                    </div>
                    <div className='col-lg-12 pr-0 pl-0 mt-5'></div>
                </>    
            </TabPanel>
            <TabPanel value={value} index={CR_INSIGHTS}>
                <div className="member-list">
                    <div>
                        <div className="mb-1 tab-content-title-div">              
                        <h2>MEMBER CR VIEW - {memberIdData} YEAR - {props.yearSelect} / PERIOD - {props.meInitialSelect}</h2>
                        </div>
                        <div className="row mb-3">
                        <div className="container">
                            {(Object.entries(WidgetCRInsightsDetails).length > 0) ?                     
                                <SlickSlider WidgetDetails={WidgetCRInsightsDetails} getIcon={getIcon} type={type} />
                            :
                                <></>
                            }  
                        </div>
                        </div>
                        <div className="row mb-3">
                        <div className="col-md-12">
                            <CardComponent
                                heading={``}
                                body={
                                    <GridTable
                                        data={tableCRInsightsDetailsData}
                                        columns={summaryCRInsightsColumns}
                                    ></GridTable>
                            }></CardComponent>
                        </div>  
                        </div>  
                        <div className="row mb-3">
                        <div className="col-md-12">
                            <CardComponent
                                heading={`ADDED CODES`}
                                body={ 
                                    <ServerSideGridTable
                                        url={`CRMemberHcc/AddedHCCs/id/${memberIdData}/year/${getAcaYear()}/period/${getMeInitialSelect()}`}
                                        columns={memberAddedCRInsightsColumn}
                                        // tableClick={memberTableClick}
                                    ></ServerSideGridTable>
                                }></CardComponent>
                        </div>
                        </div>
                        <div className="row mb-3">
                        <div className="col-md-12">
                            <CardComponent
                                heading={`DELETED CODES`}
                                body={
                                    <ServerSideGridTable
                                        url={`CRMemberHcc/DeletedHCCs/id/${memberIdData}/year/${getAcaYear()}/period/${getMeInitialSelect()}`}
                                        columns={memberDeletedCRInsightsColumn}
                                        // tableClick={memberTableClick}
                                    ></ServerSideGridTable>
                                }></CardComponent>
                        </div>
                        </div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel value={value} index={UTILIZATION}>
                <div>
                    <div className="mb-1 tab-content-title-div">              
                    <h2>UTILIZATION MEMBER SUMMARY VIEW - {memberIdData} YEAR - {props.yearSelect}</h2>
                    </div>
                    <div className="row mb-3">
                        <div className="container">
                            {(Object.entries(WidgetUtilizationDetails).length > 0) ?                     
                                <SlickSlider WidgetDetails={WidgetUtilizationDetails} getIcon={getIcon} type={type} />
                            :
                                <></>
                            }  
                        </div>
                    </div>
                    <div className="row mb-3">
                    <div className="col-md-12">
                        <CardComponent
                            heading={`TOP 5 UTILIZED SERVICES BY MEMBER ID :`}
                            body={
                                <div> 
                                    <BarVerticalHCC data={HCCUtilizationChartData} chartType="BarChart" height="400px" width="100%" setStorgeItemName={HCC} redirectUrl={'AcaGapHcc'}></BarVerticalHCC>
                                </div>
                        }></CardComponent>
                    </div> 
                    </div> 
                    <div className="row mb-3">
                    <div className="col-md-12">
                        <CardComponent
                            heading={`UTILIZATION BY SERVICE CATEGORY`}
                            body={
                                <ServerSideGridTable
                                    url={`suUtilByServiceCategory/${props.yearSelect}/id/${memberIdData}/max/100`}
                                    columns={memberUtilizationColumn}
                                    // tableClick={memberTableClick}
                                ></ServerSideGridTable>
                            }></CardComponent>
                    </div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel value={value} index={PREDICTION}>
                <div>
                <div className="mb-3 tab-content-title-div">              
                    <h2>MEMBER PREDICTION VIEW - {memberIdData} YEAR - {props.yearSelect}</h2>
                </div>
                <div className="row mb-3">
                    <div className="col-md-12">
                        <CardComponent
                            heading={``}
                            body={
                                <ServerSideGridTable
                                    url={`PredictionMember/member_id/${memberIdData}/year/${props.yearSelect}`}
                                    columns={memberPredictionColumn}
                                    // tableClick={memberTableClick}
                                ></ServerSideGridTable>
                            }></CardComponent>
                    </div>
                </div>
                </div>
            </TabPanel>
            <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br/>

                <br/>
                <br/> 
        </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
      isSignedIn: state.auth.isSignedIn,
      moduleSelect: state.moduleFilter.moduleSelect,
      viewBySelect: state.moduleFilter.viewBySelect,
      modelSelect: state.moduleFilter.modelSelect,
      metalTierSelect: state.moduleFilter.metalTierSelect,
      planSelect: state.moduleFilter.planSelect,
      yearSelect: state.moduleFilter.yearSelect,
      meInitialSelect: state.moduleFilter.meInitialSelect,
  };
}
export default connect(mapStateToProps)(MaoMemberSummary);
