import PersonIcon from '@mui/icons-material/Person';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import MenuIcon from '@mui/icons-material/Menu';
import KeyIcon from '@mui/icons-material/Key';
import GroupPage from '../Pages/Admin/group.page';
import PolicyPage from '../Pages/Admin/policy.page';
import RolesPage from '../Pages/Admin/roles.page';
import UsersPage from '../Pages/Admin/users.page';
import PermissionPage from '../Pages/Admin/permission.page';
import MenuPage from '../Pages/Admin/menu.page';
import ModulePage from '../Pages/Admin/module.page';


const adminRoutes = [
  {
    name: "RESPONSIBILITIES",
    icon: AssignmentTurnedInIcon,
    layout: "/admin",
    path: '',
    module: "Admin",
    permissionSlug: 'admin_module_3',
    subRoutes: [
      {
        path: "/users",
        name: "Users",
        icon: PersonIcon,
        component: UsersPage,
        layout: "/admin",
        permissionSlug: 'users_3'
      },
      {
        path: "/groups",
        name: "Group",
        icon: GroupIcon,
        component: GroupPage,
        layout: "/admin",
        permissionSlug: 'group_3'
      },
      {
        path: "/roles",
        name: "Roles",
        icon: SettingsIcon,
        component: RolesPage,
        layout: "/admin",
        permissionSlug: 'roles_3'
      },
      {
        path: "/permssions",
        name: "Permissions",
        icon: KeyIcon,
        component: PermissionPage,
        layout: "/admin",
        permissionSlug: 'permissions_3'
      },
      {
        path: "/menus",
        name: "Menus",
        icon: MenuIcon,
        component: MenuPage,
        layout: "/admin",
        permissionSlug: 'menus_3'
      },
      {
        path: "/modules",
        name: "Modules",
        icon: ViewModuleIcon,
        component: ModulePage,
        layout: "/admin",
        permissionSlug: 'modules_3'
      },
      {
        path: "/policy",
        name: "Policy",
        icon: SummarizeIcon,
        component: PolicyPage,
        layout: "/admin",
        permissionSlug: 'policy_3'
      },
    ]
  },

]

export default adminRoutes;