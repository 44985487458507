import React, { useRef, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../store/actions';
import AWVCoderWorkBench from '../../assets/constants/AWVCoderWorkBenchRafservice.Instance';
import Divider from '@mui/material/Divider';
import Moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import { CircularProgress } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { styled } from '@mui/material/styles';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const EditMEDICATIONCoderWorkBench = (props) => {
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [formDataList, setFormDataList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
    const [editFormData, setEditFormData] = useState({});
    const [dxCodeDOSBegin, setDxCodeDOSBegin] = useState();
    useEffect(() => {
        console.log(errors);
    }, [errors]);

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const cin = urlParams.get('cin');
        setValue('ih_member_id', cin);
    }, []);

    useEffect(() => {
        if (props?.Id) {
            loadformDataTable(props.Id);
            editLoadFormData(props.Id);
        }
    }, [props?.Id]);

    useEffect(() => {
        if (props?.Id && editFormData?.id && editFormData?.id != '') {
            setValue('id', editFormData?.id);
            setValue('page_no', editFormData?.page_no);
            setValue('entiy_id', editFormData?.entiy_id);
            setValue('entiy_text', editFormData?.entiy_text);
            setValue('remarks', editFormData?.remarks);
            setValue('ih_member_id', editFormData?.ih_member_id);
            setValue('dos_start', Moment(editFormData?.dos_start).format("YYYY-MM-DD"));
            setValue('dos_end', Moment(editFormData?.dos_end).format("YYYY-MM-DD"));
            setDxCodeDOSBegin(Moment(editFormData?.dos_start).format("YYYY-MM-DD"));
        }
    }, [editFormData]);

    const notificationRightDrawer = (open, valueId = '') => {
        props.currentAWERightSidebarType({ 'type': 'notification' });
        props.currentAWERightSidebar(open);
    };

    const editLoadFormData = (id) => {
        setIsLoading(true);
        AWVCoderWorkBench.get('data/medication/' + id)
            .then(res => {
                console.log(res.data);
                if (res.data && res.data) {
                    setEditFormData(res.data);
                    setIsLoading(false);
                } else {
                    setEditFormData();
                    setIsLoading(false);
                }
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
            })
    }

    const loadformDataTable = (id) => {
        setIsLoading(true);
        AWVCoderWorkBench.get('data/cwb/history/medication/' + id) //'&provider_group=' + props?.providerGroupSelect
            .then(res => {
                console.log(res.data);
                setFormDataList(res.data);
                // setFormDataList([
                //     {
                //         'created_on': '11 Jan 2023',
                //         'notes': 'test',
                //         'created_by': 'test',
                //     },
                //     {
                //         'created_on': '11 Jan 2023',
                //         'notes': 'test2',
                //         'created_by': 'test2',
                //     },
                // ]);
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    }


    const onSubmit = (data) => {
        let controlUser = JSON.parse(localStorage.getItem('user'));
        //setValue('updated_on', Moment().format('YYYY-MM-DD HH:mm:ss'));
        data.updated_by = controlUser?.userEmail;
        console.log(data);
        if (props?.Id) {
            var apiUrl = 'data/medication/' + props.Id;
        } else {
            var apiUrl = 'data/medication/save';
            //setValue('created_on', Moment().format('YYYY-MM-DD HH:mm:ss'));
            data.created_by = controlUser?.userEmail;
        }
        data.page_no = data.page_no ? data.page_no : "";
        data.entiy_id = data.entiy_id ? data.entiy_id : "";
        data.entiy_text = data.entiy_text ? data.entiy_text : "";
        data.dos_start = Moment(data.dos_start).format("YYYY-MM-DD");
        data.dos_end = Moment(data.dos_end).format("YYYY-MM-DD");
        setFormSubmitisLoading(true);
        AWVCoderWorkBench.post(apiUrl, data)
            .then(res => {
                setValue('id', '');
                setValue('page_no', '');
                setValue('entiy_id', '');
                setValue('entiy_text', '');
                setValue('dos_start', '');
                setValue('dos_end', '');
                setValue('updated_on', '');
                setValue('updated_by', '');
                setFormSubmitisLoading(false);
                if (props?.Id) {
                    toast.success('Updated successfully');
                } else {
                    toast.success('Created successfully');
                }
                props.currentAWERightSidebarCloseDatatableReload(true);
                notificationRightDrawer(false);
            })
            .catch(err => {
                setFormSubmitisLoading(false);
                toast.error(err?.response?.data?.message);
            })
    }

    const handleChangeDate = (event) => {
        if (event?.target?.name == 'dos_start') {
            setValue('dos_start', event?.target?.value);
            setValue('dos_end', '');
            setDxCodeDOSBegin(event?.target?.value);
        }
    }

    return (
        <div style={{ padding: "10px 0px" }}>
            <div key={'index'} variant={'head'} style={{ width: '350px', padding: "10px", height: "50px" }}>
                <div class="float">
                    <div class="float-left">
                        <span><b>{(props?.Id) ? 'Edit' : 'Add'} MEDICATION </b></span>
                    </div>
                    <div class="float-right">
                        <Tooltip title="Close">
                            <CancelIcon style={{ color: "#1A9698", cursor: "pointer" }} onClick={() => notificationRightDrawer(false)} />
                        </Tooltip>
                    </div>
                </div>
            </div>
            <Divider />
            <div>
                <div class="awv-recored-right-sidebar-form mt-3">
                    <div variant={'head'} style={{ width: '350px' }}>
                        <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                            <div className="col-lg-12 align-items-center mb-3">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>MEMBER ID</b></InputLabel>
                                <FormControl fullWidth margin="dense" variant="outlined" className="mt-1" style={{ 'min-width': '70px' }}>
                                    <Controller
                                        className="input-control"
                                        name="ih_member_id"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField type="text" {...field} variant="outlined" InputProps={{
                                                readOnly: true,
                                            }} />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.ih_member_id?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>
                            {/* <div className="col-lg-12 align-items-center mb-3">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>ENTITY ID</b></InputLabel>
                                <FormControl fullWidth margin="dense" variant="outlined" className="mt-1" style={{ 'min-width': '70px' }}>
                                    <Controller
                                        className="input-control"
                                        name="entiy_id"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField type="text" {...field} variant="outlined" />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.entiy_id?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>
                            <div className="col-lg-12 align-items-center mb-3">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>ENTITY TEXT</b></InputLabel>
                                <FormControl fullWidth margin="dense" variant="outlined" className="mt-1" style={{ 'min-width': '100px' }}>
                                    <Controller
                                        className="input-control"
                                        name="entiy_text"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField multiline rows={3} {...field} variant="outlined" />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.entiy_text?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>
                            <div className="col-lg-12 align-items-center mb-3">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>PAGE</b></InputLabel>
                                <FormControl fullWidth margin="dense" variant="outlined" className="mt-1" style={{ 'min-width': '70px' }}>
                                    <Controller
                                        className="input-control"
                                        name="page_no"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField type="text" {...field} variant="outlined" />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.page_no?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div> */}
                            <div className="col-lg-12 align-items-center mb-3">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>DOS START</b></InputLabel>
                                <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                    <Controller
                                        className="input-control"
                                        name="dos_start"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField type="date" {...field} variant="outlined" onChange={handleChangeDate} />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.dos_start?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>
                            <div className="col-lg-12 align-items-center mb-3">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>DOS END</b></InputLabel>
                                <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                    <Controller
                                        className="input-control"
                                        name="dos_end"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField type="date" {...field} variant="outlined" inputProps={{ min: Moment(dxCodeDOSBegin).format("YYYY-MM-DD") }} />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.dos_end?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>
                            <div className="col-lg-12 align-items-center mb-3">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>REMARKS</b></InputLabel>
                                <FormControl fullWidth margin="dense" variant="outlined" className="mt-1" style={{ 'min-width': '100px' }}>
                                    <Controller
                                        className="input-control"
                                        name="remarks"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField multiline rows={3} {...field} variant="outlined" />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.remarks?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>

                            <div className="col-lg-12 mt-3 mb-3">
                                <Button type='button' variant="contained" color="grey" onClick={() => notificationRightDrawer(false)}>CANCEL</Button>
                                <Button type='submit'
                                    className={'mr-2 ml-2 btn-custom-primary'}
                                    variant="contained"
                                    disabled={formSubmitisLoading && formSubmitisLoading == true ? true : false}
                                >
                                    Save
                                </Button>
                            </div>
                        </form>
                    </div>
                    {isLoading ?
                        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                            <CircularProgress />
                        </div>
                        :
                        <>
                            {formDataList && formDataList.length > 0 ?
                                <>
                                    <div class="row col-lg-12 mt-5 pl-4">
                                        <div class="float">
                                            <div class="float-left">
                                                <span style={{ fontSize: "20px" }}><b>History</b></span>
                                            </div>
                                        </div>
                                    </div>
                                    <Divider />
                                    {formDataList && formDataList.map((element, index) =>
                                        <div key={'index'} variant={'head'} style={{ width: '350px', padding: "10px" }}>
                                            <div className={
                                                element.form_status === 'Accepted' ? 'card p-2 history-custom-primary' :
                                                    element.form_status === 'Rejected' ? 'card p-2 history-custom-danger' :
                                                        element.form_status === 'No Action' ? 'card p-2 history-custom-warning' :
                                                            'card p-2 history-custom-primary'
                                            }>
                                                <span className="mb-1" style={{ fontSize: "11px" }}>
                                                    <b>{Moment(element.created_on).format('h:mm a, MMMM DD YYYY')}</b>
                                                </span>
                                                <span style={{ fontSize: "11px", lineHeight: "1rem" }}>
                                                    <b>Status:- </b>{element.form_status}
                                                </span>
                                                <span style={{ fontSize: "11px", lineHeight: "1rem" }}>
                                                    <b>Remarks:- </b>{element.remarks}
                                                </span>
                                                <div>
                                                    <span className="mr-1" style={{ color: "#777777", fontSize: "11px" }}>
                                                        Last updated by:
                                                    </span>
                                                    <span style={{ color: "#1a9698", fontSize: "11px" }}>
                                                        <b>  {element.user_id}</b>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                                :
                                <></>
                            }
                        </>

                    }
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(EditMEDICATIONCoderWorkBench);

