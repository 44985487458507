export const highlightJSON = {
    "text": [
        "MEMBER_ID : QMXM00000279793",
        "REVIEW OF SYSTEMS:  Positive for hypertension, pain with weightbearing or rotation of her hip, past excision of melanoma skin cancer and glaucoma.",
        "PHYSICAL EXAMINATION:",
        "GENERAL:  An alert and oriented lady who is in no acute distress.",
        "HEENT:  Normocephalic.  Eyes:  Pupils equal and reactive to light.",
        "NECK:  Supple.",
        "CHEST:  Clear.",
        "CARDIOVASCULAR:  Rate 72 and regular.",
        "ABDOMEN:  Nontender.",
        "EXTREMITIES:  Examination of her upper extremities reveals she has a normal range of motion.  In the sitting position, she has very limited internal and external rotation of her right hip.  On the left it is somewhat better.  She has about 20 degrees of external rotation, 15 degrees of internal rotation.  She can flex her left hip up past 90 degrees.  On the right, she can flex to about 85.  There is pain with rotation.  She can extend her right knee to a +5 and her left knee nearly fully.  There is subpatellar crepitus.  No skin lesions noted.  Motor and sensory exams all within normal limits.  Dorsal pedis pulses are present and equal bilaterally.  She has a slight leg length inequality with the right leg being shorter.",
        "IMPRESSION:  End stage osteoarthritis of the right hip.  Radiographs previously taken show severe narrowing of the joint space.  There is a periarticular osteophytes and subchondral sclerosis with adjacent cyst."
    ],
    "bold": [
        "MEMBER_ID : QMXM00000279793"
    ],
    "nonbold": [
        "REVIEW OF SYSTEMS:  Positive for hypertension, pain with weightbearing or rotation of her hip, past excision of melanoma skin cancer and glaucoma. PHYSICAL EXAMINATION: GENERAL:  An alert and oriented lady who is in no acute distress. HEENT:  Normocephalic.  Eyes:  Pupils equal and reactive to light. NECK:  Supple. CHEST:  Clear. CARDIOVASCULAR:  Rate 72 and regular. ABDOMEN:  Nontender. EXTREMITIES:  Examination of her upper extremities reveals she has a normal range of motion.  In the sitting position, she has very limited internal and external rotation of her right hip.  On the left it is somewhat better.  She has about 20 degrees of external rotation, 15 degrees of internal rotation.  She can flex her left hip up past 90 degrees.  On the right, she can flex to about 85.  There is pain with rotation.  She can extend her right knee to a +5 and her left knee nearly fully.  There is subpatellar crepitus.  No skin lesions noted.  Motor and sensory exams all within normal limits.  Dorsal pedis pulses are present and equal bilaterally.  She has a slight leg length inequality with the right leg being shorter. IMPRESSION:  End stage osteoarthritis of the right hip.  Radiographs previously taken show severe narrowing of the joint space.  There is a periarticular osteophytes and subchondral sclerosis with adjacent cyst."
    ]
}