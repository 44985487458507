import api from './Rafservice.Instance';
import { 
    SELECT_YEAR, SELECT_INITIAL, 
    SELECT_METAL_TIER, SELECT_VIEW_BY,
    SELECT_PLAN, SELECT_MODEL, SELECT_DISTRIBUTION
} from "../../assets/constants/string.constants";

let Distribution_list = [];
let Distribution_select = '';
export const DistributionList = () =>{

    // api.get('/aca_yearList/')
    // .then(res=>{
        SetDistributionList([
                           // {"key":"ALL", "value":"All Members"},
                            {"key":"RISK", "value":"Risk Impacting Members"}
                        ]);   
        if(localStorage.getItem(SELECT_DISTRIBUTION) && localStorage.getItem(SELECT_DISTRIBUTION) != ''){
            // localStorage.setItem('YEAR', res[0]);            
            // SetAcaYear(res[0]);
            SetDistributionSelect(localStorage.getItem(SELECT_DISTRIBUTION)); 
        }else{
            localStorage.setItem(SELECT_DISTRIBUTION, 'RISK');            
            SetDistributionSelect('RISK'); 
        }

    // })
    // .catch(err=>{
    //     // console.log(err);
    // })
}

export const SetDistributionList = (data) =>{
    Distribution_list = data;
}

export const SetDistributionSelect = (data) =>{
    Distribution_select = data;
    localStorage.setItem(SELECT_DISTRIBUTION, data);
}

export const getDistributionList = () =>{
    return Distribution_list;
}

export const getDistributionSelect = () =>{
    return Distribution_select;
}