import PropTypes from "prop-types";
import { SetAcaIssuer } from "../../assets/constants/issuerList";

const AcaIssuersSelect = (props) => {

    const { AcaIssuers, issuer} = props;

    const handleSelect = (e) =>{
        SetAcaIssuer(e.target.value);
        props.onAcaIssuerSelect(e.target.value);
    }

    return(
        <div className="d-flex justify-content-start">
            <div className="btn-group">
            <select className="btn btn-info dropdown-toggle btn" value={issuer} onChange={handleSelect}>
                {
                    AcaIssuers && AcaIssuers.map(el => <option value={el} key={el}> {el} </option>)
                }
            </select>
            </div>
        </div>
    )
}

AcaIssuersSelect.propTypes ={
    AcaIssuers : PropTypes.array,
}

export default AcaIssuersSelect;