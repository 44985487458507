import { Container } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import TableCell from '@mui/material/TableCell';
import { TableHead } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Moment from 'moment';

const AWVSlickSlider = (props) => {

  useEffect(() => {

  }, [props]);

  const sliderSettingsAca = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          initialSlide: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        }
      }
    ]

  };


  return (
    <div className="mr-3 ml-3">
      <Slider {...sliderSettingsAca}>
        {
          props.dataSlider.map((element, index) => {
            return (
              <div key={index} variant={'head'} colSpan={2} style={{ width: '300px' }}>
                <div className="card p-2 expand-grid-custom" >
                  <span className="mb-1" style={{ fontSize: "19px" }}>
                    {Moment(element.timestamp).format('h:mm a, MMMM DD YYYY')}
                  </span>
                  <span style={{ fontSize: "12px", lineHeight: "1rem", fontWeight: '500' }}>
                    {element.remarks}
                  </span>
                  <div>
                    <span className="mr-1" style={{ color: "#777777", fontSize: "12px" }}>
                      Last updated by:
                    </span>
                    <span style={{ color: "#1a9698", fontSize: "12px" }}>
                      {element.role}
                    </span>
                  </div>
                  {element.reason_code_description ?
                    <div>
                      <span className="mr-1" style={{ color: "#777777", fontSize: "12px" }}>
                        Reason Code:
                      </span>
                      <span style={{ color: "#1a9698", fontSize: "12px" }}>
                        {element.reason_code_description}
                      </span>
                    </div>
                    :
                    <><br/></>
                  }
                </div>
              </div>
            )
          })
        }
      </Slider>
    </div>
  );
}

export default AWVSlickSlider;