let Module_list = [];
let Module_select = '';
export const ModuleList = () =>{

        SetModuleList(['Data Repository']);   
        SetModuleSelect('DataRepository'); 
}

export const SetModuleList = (data) =>{
    Module_list = data;
}

export const SetModuleSelect = (data) =>{    
    Module_select = data;    
}

export const getModuleList = () =>{
    return Module_list;
}

export const getModuleSelect = () =>{
    return Module_select;
}

