import awvRoutes from "../../routes/routeAWV";

let Module_list = [];
let Module_select = '';
export const ModuleList = () =>{

    const getModulePermissionCheck = (layout) => {
        var permissionCheck = false;
        var loacalPermissionGet = JSON.parse(localStorage.getItem('controlPermissions'));
        if (awvRoutes && awvRoutes.length > 0 && loacalPermissionGet && loacalPermissionGet.length > 0) {
            awvRoutes.forEach(element => {
                if(layout == element.module){
                    if (loacalPermissionGet.find(({ permission }) => permission === element.permissionSlug)) {
                        permissionCheck = true;
                        return permissionCheck;
                    }
                }
            });
        }
        return permissionCheck;
    }

    if(getModulePermissionCheck('ACA') && getModulePermissionCheck('Medicare')){
        SetModuleList(['ACA', 'Medicare', 'Medicaid']);
        SetModuleSelect('ACA');
    }else if(getModulePermissionCheck('ACA')){
        SetModuleList(['ACA']);
        SetModuleSelect('ACA');
    }else if(getModulePermissionCheck('Medicare')){
        SetModuleList(['Medicare']);
        SetModuleSelect('Medicare');
    }else if(getModulePermissionCheck('Medicaid')){
        SetModuleList(['Medicaid']);
        SetModuleSelect('Medicaid');
    }
}

export const SetModuleList = (data) =>{
    Module_list = data;
}

export const SetModuleSelect = (data) =>{    
    Module_select = data;    
}

export const getModuleList = () =>{
    return Module_list;
}

export const getModuleSelect = () =>{
    return Module_select;
}

