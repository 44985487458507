import { connect } from 'react-redux';
import React, { useEffect, useState } from "react";
import { currentRoute, currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../../store/actions';
import TabList from '@mui/lab/TabList';
import AWVCoderWorkBench from '../../../assets/constants/AWVCoderWorkBenchRafservice.Instance';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import AwvCardComponent from "../../../components/Cards/AwvCard";
import AWVServerSideGridTable from "../../../components/TableGrids/AWVServerSideGridTable.table";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Tooltip from '@mui/material/Tooltip';
import Button from '@material-ui/core/Button';
import history from "../../../history";
import pdfIcon from '../../../assets/images/pdf_icon.png';
import AWS from 'aws-sdk';
import axios from "axios";
import moment from 'moment';
import { dateFormat } from "../../../assets/constants/customRender";
// import ChecklistIcon from '@mui/icons-material/Checklist';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import CancelIcon from '@mui/icons-material/Cancel';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
const aws = require('aws-sdk');

// fetching accesskeys for aws-sdk
const S3_BUCKET = process.env.REACT_APP_AWE_CODER_WORKBENCH_FILE_URL_BUCKET_NAME;
const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = localStorage.getItem("access_key");
const SECRET_ACCESS_KEY = localStorage.getItem("secret_key");

const AWVCoderBenchmark = (props) => {

    const [tabvalue, setTabvalue] = useState('1');
    const [coderBenchmarkData, setCoderBenchmarkData] = useState([]);
    const [coderCWDXRecordList, setCoderCWDXRecordList] = useState([]);
    const [coderCWDXLoading, setCoderCWDXLoading] = useState(false);
    const [coderCWPROCCODERecordList, setCoderCWPROCCODERecordList] = useState([]);
    const [coderBenchmarkLoading, setCoderBenchmarkLoading] = useState(false);
    const [coderCWPROCCODELoading, setCoderCWPROCCODELoading] = useState(false);
    const [coderMedicationRecordList, setCoderMedicationRecordList] = useState([]);
    const [coderMedicationLoading, setCoderMedicationLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectId, setSelectId] = useState(null);
    const openAction = Boolean(anchorEl);
    const [showUserDetails, setShowUserDetails] = useState(false);
    const params = new URL(window.location.href).searchParams;
    const [userMemberId] = useState(params.get('cin'));
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const cin = urlParams.get('cin');
    const [fileUrl, setFileUrl] = useState();

    useEffect(() => {
        // pass bench mark id

        loadCoderBenchMark(cin);
        loadCWDXReport(cin);
        loadCWPROCCODEReport(cin);
        loadMedicationReport(cin);
    }, []);

    useEffect(() => {
        if (props.aweRightSidebarCloseDatatableReload && props.aweRightSidebarCloseDatatableReload == true) {
            loadCWDXReport(cin);
            loadCWPROCCODEReport(cin);
            loadMedicationReport(cin);
            props.currentAWERightSidebarCloseDatatableReload(false);
        }
    }, [props.aweRightSidebarCloseDatatableReload]);

    const handleTabChange = (event, newValue) => {
        setTabvalue(newValue);
    };

    const handleCloseAction = () => {
        setAnchorEl(null);
    };

    const handleClickAction = (event, valueId) => {
        setAnchorEl(event.currentTarget);
        setSelectId(valueId);
    };

    const notificationRightDrawer = (open, typeValue, valueId) => {
        var selectIdData = selectId;
        setAnchorEl('');
        setSelectId('')
        props.currentAWERightSidebarType({ 'type': typeValue, 'id': selectIdData });
        props.currentAWERightSidebar(open);
    };

    const notificationRightDrawerView = (open, typeValue, valueId) => {
        props.currentAWERightSidebarType({ 'type': typeValue, 'id': valueId });
        props.currentAWERightSidebar(open);
    };

    const notificationIconRightDrawer = (open, typeValue, valueId) => {
        props.currentAWERightSidebarType({ 'type': typeValue, 'id': valueId });
        props.currentAWERightSidebar(open);
    };

    const coderCWDXRecordColumns = [
        {
            name: "ID",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display: 'excluded',
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '95px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '95px'
                    }
                })
            }
        },
        {
            name: "CW_ID",
            label: "CW_ID",
            options: {
                filter: true,
                sort: true,
                display: 'excluded',
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '80px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '80px'
                    }
                })
            }
        },
        {
            name: "IH_MEMBER_ID",
            label: "IH MEMBER ID",
            options: {
                filter: true,
                sort: true,
                display: 'excluded',
                setCellProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        width: '95px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '95px'
                    }
                })
            }
        },
        {
            name: "PAGE_NO",
            label: "PAGE NO",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        width: '90px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '90px'
                    }
                })
            }
        },
        {
            name: "DX_CODE",
            label: "DX CODE",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        width: '130px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '130px'
                    }
                })

            }
        },
        {
            name: "ENTITY_TEXT",
            label: "ENTITY TEXT",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        width: '120px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '120px'
                    }
                })
            }
        },

        {
            name: "DOS_Begin",
            label: "DOS_Begin",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '95px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '95px'
                    }
                }),
                customBodyRender: (value) => {
                    //const formattedDate = new Date(value).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
                    if (value != null && value != '') {
                        const formattedDate = moment(value, "YYYY/MM/DD").format("MM/DD/YYYY");
                        return formattedDate;
                    } else {
                        return value;
                    }
                },
            }
        },
        {
            name: "DOS_End",
            label: "DOS_End",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '95px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '95px'
                    }
                }),
                customBodyRender: (value) => {
                    //const formattedDate = new Date(value).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
                    if (value != null && value != '') {
                        const formattedDate = moment(value, "YYYY/MM/DD").format("MM/DD/YYYY");
                        return formattedDate;
                    } else {
                        return value;
                    }
                },
            }
        },
        {
            name: "HCC_v24",
            label: "HCC_v24",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        width: '130px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '130px'
                    }
                })
            }
        },
        {
            name: "HCC_V28",
            label: "HCC_V28",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        width: '130px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '130px'
                    }
                })
            }
        },
        {
            name: "RXHCC_v05",
            label: "RXHCC_V05",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        width: '130px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '130px'
                    }
                })
            }
        },
        {
            name: "RXHCC_v08",
            label: "RXHCC_V08",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        width: '130px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '130px'
                    }
                })
            }
        },

        {
            name: "CW_DX_STATUS",
            label: "Status",
            options: {
                filter: false,
                filterType: "dropdown",
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        width: '140px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '140px'
                    }
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>

                            {value == 'Rejected' && <span style={{ width: '62px', textAlign: "center", background: "red" }} className="expand-grid-success-status pt-1 pb-1 pl-3 pr-3" title={value}><CancelIcon /></span>}
                            {value == 'No Action' && <span style={{ width: '62px', textAlign: "center", background: "#f1c40f" }} className="expand-grid-success-status pt-1 pb-1 pl-3 pr-3" title={value}>NA</span>}
                            {value == 'Accepted' && <span style={{ width: '62px', textAlign: "center" }} className="expand-grid-success-status pt-1 pb-1 pl-3 pr-3" title={value}><CheckCircleIcon /></span>}
                            {value == 'Pending for clarification' && <span style={{ width: '62px', textAlign: "center" , background: "#E9731D"}} className="expand-grid-success-status pt-1 pb-1 pl-3 pr-3" title="Pending for clarification"><WarningAmberRoundedIcon /></span>}
                            {value == '' && <span style={{ width: '62px', textAlign: "center" }} className="expand-grid-success-status pt-1 pb-1 pl-3 pr-3" title={value}></span>}



                        </Box>
                    );
                }
            }
        },

        {
            name: "ID",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                download: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '120px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '120px'
                    }
                }),

                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {/*
                            <Tooltip title="View">
                                <a className="hyperlink " style={{ cursor: "pointer", color: "#209f9d" }} onClick={() => notificationRightDrawerView(true, 'ViewCWDXCoderWorkBench', value)}>
                                    <VisibilityIcon color="mr-3" style={{ width: "0.9em", height: "0.8em" }} />
                                </a>
                            </Tooltip> */}
                            {/*
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                name={value}
                                aria-controls={openAction ? 'long-menu' : undefined}
                                aria-expanded={openAction ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={(event) => handleClickAction(event, value)}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={openAction}
                                onClose={handleCloseAction}
                                PaperProps={{
                                    style: {
                                        maxHeight: 45 * 4.5,
                                        width: '25ch',
                                    },
                                }}
                            >
                                <MenuItem key={'edit'} onClick={() => notificationRightDrawer(true, 'EditCWDXCoderWorkBench', value)}>
                                    <ModeEditOutlineIcon className="mr-3" />{'Edit'}
                                </MenuItem>
                                <MenuItem key={'status'} onClick={() => notificationRightDrawer(true, 'StatusCWDXCoderWorkBench', value)}>
                                    <FactCheckIcon className="mr-3" />{'Update Status'}
                                </MenuItem>
                            </Menu>
                            */}
                            <Tooltip title="Edit">
                                        <a role="button" className="hyperlink mr-2" style={{ cursor: "pointer", color: "#209f9d" }} onClick={() => notificationIconRightDrawer(true, 'EditCWDXCoderWorkBench', value)}>
                                         <ModeEditOutlineIcon className="" style={{ width: "0.9em", height: "0.8em" }} />
	                                    </a>
                                </Tooltip>
                                <Tooltip title="Update Status">
                                    <a role="button" className="hyperlink mr-2" style={{ cursor: "pointer", color: "#209f9d" }} onClick={() => notificationIconRightDrawer(true, 'StatusCWDXCoderWorkBench', value)}>
                                         <FactCheckIcon className="" style={{ width: "0.9em", height: "0.8em" }} />
                                     </a>
                            </Tooltip>
                        </Box>
                    );
                }
            }
        },
    ];

    const coderCWPROCCODERecordColumns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: true,
                sort: true,
                display: false,
            }
        },
        {
            name: "cw_id",
            label: "CW_ID",
            options: {
                filter: true,
                sort: true,
                display: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '95px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '95px'
                    }
                })
            }
        },

        {
            name: "page_no",
            label: "PAGE_NO",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '110px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '110px'
                    }
                })
            }
        },
        {
            name: "proc_code",
            label: "PROC_CODE",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        width: '170px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '170px'
                    }
                })
            }
        },
        {
            name: "ENTITY_TEXT",
            label: "ENTITY TEXT",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        width: '120px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '120px'
                    }
                })
            }
        },

        {
            name: "dos_begin",
            label: "DOS_BEGIN",
            Option: dateFormat,
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '110px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '110px'
                    }
                }),
                customBodyRender: (value) => {
                    //const formattedDate = new Date(value).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
                    if (value != null && value != '') {
                        const formattedDate = moment(value, "YYYY/MM/DD").format("MM/DD/YYYY");
                        return formattedDate;
                    } else {
                        return value;
                    }
                },
            }
        },
        {
            name: "dos_end",
            label: "DOS_END",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '110px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '110px'
                    }
                }),
                customBodyRender: (value) => {
                    //const formattedDate = new Date(value).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
                    if (value != null && value != '') {
                        const formattedDate = moment(value, "YYYY/MM/DD").format("MM/DD/YYYY");
                        return formattedDate;
                    } else {
                        return value;
                    }
                },
            }
        },
        {
            name: "modifier_1",
            label: "MODIFIER 1",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        width: '130px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '130px'
                    }
                })
            }
        },
        {
            name: "modifier_2",
            label: "MODIFIER 2",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        width: '130px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '130px'
                    }
                })
            }
        },
        {
            name: "modifier_3",
            label: "MODIFIER 3",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        width: '130px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '130px'
                    }
                })
            }
        },
        {
            name: "modifier_4",
            label: "MODIFIER 4",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        width: '130px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "wrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '130px'
                    }
                })
            }
        },

        {
            name: "risk_eligible",
            label: "RISK_ELIGIBLE",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '110px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '110px'
                    }
                })
            }
        },
        {
            name: "cw_proc_status",
            label: "STATUS",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '110px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '110px'
                    }
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>

                            {value == 'Rejected' && <span style={{ width: '62px', textAlign: "center", background: "red" }} className="expand-grid-success-status pt-1 pb-1 pl-3 pr-3" title={value}><CancelIcon /></span>}
                            {value == 'No Action' && <span style={{ width: '62px', textAlign: "center", background: "#f1c40f" }} className="expand-grid-success-status pt-1 pb-1 pl-3 pr-3" title={value}>NA</span>}
                            {value == 'Accepted' && <span style={{ width: '62px', textAlign: "center" }} className="expand-grid-success-status pt-1 pb-1 pl-3 pr-3" title={value}><CheckCircleIcon /></span>}
                            {value == 'Pending for clarification' && <span style={{ width: '62px', textAlign: "center", background: "#E9731D" }} className="expand-grid-success-status pt-1 pb-1 pl-3 pr-3" title="Pending for clarification"><WarningAmberRoundedIcon /></span>}
                            {value == '' && <span style={{ width: '62px', textAlign: "center" }} className="expand-grid-success-status pt-1 pb-1 pl-3 pr-3" title={value}></span>}
                        </Box>
                    );
                }
            }
        },
        {
            name: "id",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                download: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '120px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '120px'
                    }
                }),

                customBodyRender: (value, tableMeta, updateValue) => {
                    return (

                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {/*
                            <Tooltip title="View">
                                <a className="hyperlink " style={{ cursor: "pointer", color: "#209f9d" }} onClick={() => notificationRightDrawerView(true, 'ViewCWPROCCODECoderWorkBench', value)}>
                                    <VisibilityIcon color="mr-3" style={{ width: "0.9em", height: "0.8em" }} />
                                </a>
                            </Tooltip>*/}
                             {/*
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                name={value}
                                aria-controls={openAction ? 'long-menu' : undefined}
                                aria-expanded={openAction ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={(event) => handleClickAction(event, value)}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={openAction}
                                onClose={handleCloseAction}
                                PaperProps={{
                                    style: {
                                        maxHeight: 45 * 4.5,
                                        width: '25ch',
                                    },
                                }}
                            >

                                <MenuItem key={'edit'} onClick={() => notificationRightDrawer(true, 'EditCWPROCCODECoderWorkBench', value)}>
                                    <ModeEditOutlineIcon className="mr-3" />{'Edit'}
                                </MenuItem>
                                <MenuItem key={'status'} onClick={() => notificationRightDrawer(true, 'StatusCWPROCCODECoderWorkBench', value)}>
                                    <FactCheckIcon className="mr-3" />{'Update Status'}
                                </MenuItem>
                            </Menu>
                            */}
                             <Tooltip title="Edit">
                             <a role="button" className="hyperlink mr-2" style={{ cursor: "pointer", color: "#209f9d" }} onClick={() => notificationIconRightDrawer(true, 'EditCWPROCCODECoderWorkBench', value)}>
                                     <ModeEditOutlineIcon className="" style={{ width: "0.9em", height: "0.8em" }} />
	                                 </a>
                             </Tooltip>
                             <Tooltip title="Update Status">
                             <a role="button" className="hyperlink mr-2" style={{ cursor: "pointer", color: "#209f9d" }} onClick={() => notificationIconRightDrawer(true, 'StatusCWPROCCODECoderWorkBench', value)}>
                                     <FactCheckIcon className="" style={{ width: "0.9em", height: "0.8em" }} />
	                                 </a>
                            </Tooltip>

                        </Box>
                    );
                }
            }
        },
    ];

    const coderMedicationRecordColumns = [
        {
            name: "entiy_id",
            label: "Entiy ID",
            options: {
                filter: true,
                sort: true,
                display: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '95px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '95px'
                    }
                })
            }
        },

        {
            name: "page_no",
            label: "PAGE_NO",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '95px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '95px'
                    }
                })
            }
        },
        {
            name: "dos_start",
            label: "DOS_START",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '95px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '95px'
                    }
                }),
                customBodyRender: (value) => {
                    //const formattedDate = new Date(value).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
                    if (value != null && value != '') {
                        const formattedDate = moment(value, "YYYY/MM/DD").format("MM/DD/YYYY");
                        return formattedDate;
                    } else {
                        return value;
                    }
                },
            }
        },
        {
            name: "dos_end",
            label: "DOS_END",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '95px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '95px'
                    }
                }),
                customBodyRender: (value) => {
                    //const formattedDate = new Date(value).toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
                    if (value != null && value != '') {
                        const formattedDate = moment(value, "YYYY/MM/DD").format("MM/DD/YYYY");
                        return formattedDate;
                    } else {
                        return value;
                    }
                },
            }
        },
        {
            name: "status",
            label: "STATUS",
            options: {
                filter: true,
                sort: true,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '110px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '110px'
                    }
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {value == 'Rejected' && <span style={{ width: '62px', textAlign: "center", background: "red" }} className="expand-grid-success-status pt-1 pb-1 pl-3 pr-3" title={value}><CancelIcon /></span>}
                            {value == 'No Action' && <span style={{ width: '62px', textAlign: "center", background: "#f1c40f" }} className="expand-grid-success-status pt-1 pb-1 pl-3 pr-3" title={value}>NA</span>}
                            {value == 'Accepted' && <span style={{ width: '62px', textAlign: "center" }} className="expand-grid-success-status pt-1 pb-1 pl-3 pr-3" title={value}><CheckCircleIcon /></span>}
                            {value == 'Pending for clarification' && <span style={{ width: '62px', textAlign: "center", background: "#E9731D" }} className="expand-grid-success-status pt-1 pb-1 pl-3 pr-3" title="Pending for clarification"><WarningAmberRoundedIcon /></span>}
                            {value == '' && <span style={{ width: '62px', textAlign: "center" }} className="expand-grid-success-status pt-1 pb-1 pl-3 pr-3" title={value}></span>}
                        </Box>
                    );
                }
            }
        },
        {
            name: "id",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                download: false,
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '120px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '120px'
                    }
                }),
                setCellProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        width: '120px'
                    }
                }),
                setCellHeaderProps: () => ({
                    style: {
                        whiteSpace: "nowrap",
                        position: "sticky",
                        background: "white",
                        zIndex: 101,
                        width: '120px'
                    }
                }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {/*
                            <Tooltip title="View">
                                <a className="hyperlink " style={{ cursor: "pointer", color: "#209f9d" }} onClick={() => notificationRightDrawerView(true, 'ViewMEDICATIONCoderWorkBench', value)}>
                                    <VisibilityIcon color="mr-3" style={{ width: "0.9em", height: "0.8em" }} />
                                </a>
                            </Tooltip> */}
                             {/*
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                name={value}
                                aria-controls={openAction ? 'long-menu' : undefined}
                                aria-expanded={openAction ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={(event) => handleClickAction(event, value)}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={openAction}
                                onClose={handleCloseAction}
                                PaperProps={{
                                    style: {
                                        maxHeight: 45 * 4.5,
                                        width: '25ch',
                                    },
                                }}
                            >
                                <MenuItem key={'edit'} onClick={() => notificationRightDrawer(true, 'EditMEDICATIONCoderWorkBench', value)}>
                                    <ModeEditOutlineIcon className="mr-3" />{'Edit'}
                                </MenuItem>
                                <MenuItem key={'status'} onClick={() => notificationRightDrawer(true, 'StatusMEDICATIONCoderWorkBench', value)}>
                                    <FactCheckIcon className="mr-3" />{'Update Status'}
                                </MenuItem>
                            </Menu>
                            */}
                            <Tooltip title="Edit">
                            <a role="button" className="hyperlink mr-2" style={{ cursor: "pointer", color: "#209f9d" }} onClick={() => notificationIconRightDrawer(true, 'EditMEDICATIONCoderWorkBench', value)}>
                                     <ModeEditOutlineIcon className="" style={{ width: "0.9em", height: "0.8em" }} />
	                                 </a>
                             </Tooltip>
                             <Tooltip title="Update Status">
                             <a role="button" className="hyperlink mr-2" style={{ cursor: "pointer", color: "#209f9d" }} onClick={() => notificationIconRightDrawer(true, 'StatusMEDICATIONCoderWorkBench', value)}>
                                     <FactCheckIcon className="" style={{ width: "0.9em", height: "0.8em" }} />
	                                 </a>
	                         </Tooltip>
                        </Box>
                    );
                }
            }
        },
    ];

    const loadCoderBenchMark = (Id) => {
        setCoderBenchmarkLoading(true);
        AWVCoderWorkBench.get('data/cin/' + Id)
            .then(res => {
                console.log(res.data);
                setCoderBenchmarkData(res.data);
                setCoderBenchmarkLoading(false);
            })
            .catch(err => {
                console.log(err);
                setCoderBenchmarkLoading(false);
            })
    }

    const loadCWDXReport = () => {
        setCoderCWDXLoading(true);
        AWVCoderWorkBench.get('data/cw_dx/cin/' + btoa(cin))
            .then(res => {
                console.log(res.data);
                setCoderCWDXRecordList(res.data);
                setCoderCWDXLoading(false);
            })
            .catch(err => {
                console.log(err);
                setCoderCWDXLoading(false);
            })
    }

    const loadCWPROCCODEReport = () => {
        setCoderCWPROCCODELoading(true);
        AWVCoderWorkBench.get('data/cw_proc_code/cin/' + cin)
            .then(res => {
                console.log(res.data);
                setCoderCWPROCCODERecordList(res.data);
                setCoderCWPROCCODELoading(false);
            })
            .catch(err => {
                console.log(err);
                setCoderCWPROCCODELoading(false);
            })

        // setCoderCWPROCCODELoading(true);
        // setCoderCWPROCCODERecordList([{
        //     "ID": "1234",
        //     "Entiy_TEXT": "1234",
        //     "IH_MEMBER_ID": "1234",
        //     "PAGE_NO": "1234",
        //     "DX_CODE": "1234",
        //     "ENTITY_ID": "1234",
        // }]);
        // setCoderCWPROCCODELoading(false);
    }

    const loadMedicationReport = () => {
        setCoderMedicationLoading(true);
        AWVCoderWorkBench.get('data/medication/cin/' + cin)
            .then(res => {
                console.log(res.data);
                setCoderMedicationRecordList(res.data);
                setCoderMedicationLoading(false);
            })
            .catch(err => {
                console.log(err);
                setCoderMedicationLoading(false);
            })
    }

    const handleOnclickUrl = () => {
        if (params.get('cin') && params.get('cin') != '') {
            history.push('/awe/awe_analytics_summary?cin=' + params.get('cin'));
            // props.handleMemberIdSet('');
        } else {
            setShowUserDetails(false);
            // props.handleMemberIdSet('');
        }
    }
    useEffect(() => {
        if (coderBenchmarkData.file_name_url && coderBenchmarkData.file_name_url != '') {
            aws.config.update({
                accessKeyId: ACCESS_KEY,
                secretAccessKey: SECRET_ACCESS_KEY,
                region: REGION
            });
            const s3d = new aws.S3();
            var credentials = {
                accessKeyId: localStorage.getItem("access_key"),
                secretAccessKey: localStorage.getItem("secret_key")
            };
            AWS.config.update({ credentials: credentials, region: process.env.REACT_APP_REGION, signatureVersion: "v4" });
            var s3 = new AWS.S3({
                signatureVersion: "v4"
            });

            //let Key = 'test.pdf';
            let url = coderBenchmarkData.file_name_url
            let Key = url.split('/').pop();

            var keyWithSubString = '';
            //setFileName(Key);

            s3.setupRequestListeners = (request) => {
                request.on('build', (req) => {

                    req.httpRequest.headers['x-amz-date'] = process.env.REACT_APP_KeyID;
                });
            }
            /*  adding condition to upload form in different folder as per lob select */
            let fileKey = '';
            if (props?.lobSelect == 'ACA' || props?.lobSelect == "1") {
                fileKey = "processed-record/aca/" + Key;
            } else {
                fileKey = "processed-record/medicare/" + Key;
            }

            var presignedGETURL = s3.getSignedUrl('getObject', {
                Bucket: S3_BUCKET,
                Key: fileKey
            });
            if (Key.includes('/')) {
                keyWithSubString = Key.substr(((Key).lastIndexOf('/')))
            } else {
                keyWithSubString = Key
            }
            setFileUrl(presignedGETURL);
            axios({
                url: presignedGETURL,
                responseType: "arraybuffer",
                method: "GET",
            }).then((res) => {

                let file = new Blob([res.data], {
                    type: "application/pdf",
                });

                let fileURL = URL.createObjectURL(file);



            }).catch((err) => { console.log("Error while downloading file...") })

        }
    }, [coderBenchmarkData.file_name_url]);

    return (
        <>
            <div className="col-lg-12 pr-0 pl-0 mb-5">
                <div className='' style={{ float: "left", width: "100%" }}>
                    <div style={{ float: "left" }}><h6 className="pt-2 pl-2 flex-grow-1 header-title-text">Coder Workbench</h6></div>
                    <Tooltip title="Back" >
                        <div style={{ float: "right", position: "unset", top: "188px", right: "1%", color: "#1a9698", cursor: "pointer" }}>
                            <i class="fa fa-arrow-circle-left fa-3x" aria-hidden="true" onClick={() => handleOnclickUrl()}></i>
                        </div>
                    </Tooltip>
                </div><br />
                <div className="row pr-0 pl-0">
                    <div className="col-lg-12 pr-3">
                        <div className="card" style={{ borderRadius: "10px !important" }}>
                            <table width={'100%'}>
                                <tr className="mt-2 pb-3 pl-2" style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                    <td className="mt-2 pb-4 pt-2 pl-2" style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <div className="ml-4">MEMBER ID</div>
                                        <div className="ml-4"><b>{coderBenchmarkData.ih_member_id}</b></div>
                                    </td>
                                    <td className="mt-2 pb-4 pt-2 pl-2" style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <div className="ml-4">AWE ID</div>
                                        <div className="ml-4"><b>{coderBenchmarkData.awe_id}</b></div>
                                    </td>
                                    <td className="mt-2 pb-4 pt-2 pl-2" style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <div className="ml-4">MEMBER F NAME</div>
                                        <div className="ml-4"><b>{coderBenchmarkData.member_f_name}</b></div>
                                    </td>
                                    <td className="mt-2 pb-4 pt-2 pl-2" style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <div className="ml-4">MEMBER L NAME</div>
                                        <div className="ml-4"><b>{coderBenchmarkData.member_l_name}</b></div>
                                    </td>

                                </tr>
                                <tr className="mt-2 pb-3 pl-2" style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.12)" }}>


                                    <td className="mt-2 pb-4 pt-2 pl-2" style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <div className="ml-4">MEMBER DOB</div>
                                        <div className="ml-4"><b>{coderBenchmarkData.member_dob}</b></div>
                                    </td>
                                    <td className="mt-2 pb-4 pt-2 pl-2" style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <div className="ml-4">PROJECT ID</div>
                                        <div className="ml-4"><b>{coderBenchmarkData.project_id}</b></div>
                                    </td>
                                    <td className="mt-2 pb-4 pt-2 pl-2" style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <div className="ml-4">File NAME URL</div>
                                        <div className="ml-4">
                                            {(coderBenchmarkData.file_name_url && coderBenchmarkData.file_name_url && coderBenchmarkData.file_name_url != '') ?
                                                <>
                                                    {fileUrl && fileUrl.includes(".pdf") ?
                                                        <a target="_blank" href={fileUrl}>
                                                            <FilePresentIcon className="" style={{ width: "0.9em", height: "0.8em" }} />
                                                            <b>{coderBenchmarkData.file_name_url ? coderBenchmarkData.file_name_url?.split('/').pop() : ''}</b>
                                                        </a>
                                                        :
                                                        <a href={fileUrl} download>
                                                            <FilePresentIcon className="" style={{ width: "0.9em", height: "0.8em" }} />
                                                            <b> {coderBenchmarkData?.file_name_url ? coderBenchmarkData.file_name_url?.split('/').pop() : ''}</b>
                                                        </a>
                                                    }
                                                </>
                                                :
                                                <></>
                                            }
                                        </div>
                                    </td>
                                    <td className="mt-2 pb-4 pt-2 pl-2" style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <div className="ml-4">File Format Type</div>
                                        <div className="ml-4"><b>{coderBenchmarkData.file_format_type}</b></div>
                                    </td>
                                </tr>
                                <tr className="mt-2 pb-3 pl-2" style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.12)" }}>

                                    {/*
                                    <td className="mt-2 pb-4 pt-2 pl-2" style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <div className="ml-4">MARK CHART COMPLETE</div>
                                        <div className="ml-4"><b>No</b></div>
                                    </td>*/}
                                    <td className="mt-2 pb-4 pt-2 pl-2" style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <div className="ml-4">CHART STATUS</div>
                                        <div className="ml-4"><b>{coderBenchmarkData.chart_status}</b></div>
                                    </td>
                                    <td className="mt-2 pb-4 pt-2 pl-2" style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <div className="ml-4">Assigned To</div>
                                        <div className="ml-4"><b>{coderBenchmarkData.assigned_to}</b></div>
                                    </td>
                                    <td className="mt-2 pb-4 pt-2 pl-2" style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                        <div className="ml-4">Assign Date</div>
                                        <div className="ml-4"><b>{coderBenchmarkData.assigned_dt_ts}</b></div>
                                    </td>
                                </tr>

                            </table>
                        </div>
                    </div>
                </div>
                <div className="row pr-2 pl-0 mt-5 mb-5">
                    <div className="col-lg-12 pr-0 pl-2">
                        <div className="col-lg-12 pr-0 pl-0">
                            <Box className='mt-2' sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={tabvalue}>
                                    <Box>
                                        <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                            <Tab label="Diagonsis code" className="mui-tab-label" value="1" style={{ backgroundColor: "#cdffff", borderRadius: "5px", marginRight: "15px" }} />
                                            <Tab label="Procedure code" className="mui-tab-label" value="2" style={{ backgroundColor: "#cdffff", borderRadius: "5px", marginRight: "15px" }} />
                                            <Tab label="Medication" className="mui-tab-label" value="3" style={{ backgroundColor: "#cdffff", borderRadius: "5px", marginRight: "15px" }} />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">
                                        <div className="pl-0 pr-0 row">
                                            <div className="col-lg-12 pr-0 pl-0" style={{ zIndex: '1', top: '13px', left: '85%', borderRadius: "5px" }}>
                                                <Button onClick={() => notificationRightDrawer(true, 'EditCWDXCoderWorkBench', '')} style={{ position: 'absolute', color: 'white', padding: '7px 25px', fontFamily: "'Source Sans Pro', sans-serif", backgroundColor: "rgb(46 147 147)" }} >
                                                    Add
                                                </Button>
                                            </div>
                                            <div className="col-lg-12 pr-0 pl-0" style={{ position: "", minHeight: coderCWDXLoading ? "200px" : coderCWDXRecordList && coderCWDXRecordList.length > 0 ? "30px" : "0px" }}>
                                                <AwvCardComponent
                                                    heading={`Diagonsis code`}
                                                    body={
                                                        <AWVServerSideGridTable
                                                            data={coderCWDXRecordList}
                                                            columns={coderCWDXRecordColumns}
                                                            filter={true}
                                                            search={true}
                                                            pagination={true}
                                                            download={true}
                                                            colSpan={13}
                                                            isLoading={coderCWDXLoading}
                                                            downloadFileName="Diagonsis Code"
                                                        ></AWVServerSideGridTable>
                                                    }>
                                                </AwvCardComponent>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <div className="pl-0 pr-0 row">
                                            <div className="col-lg-12 pr-0 pl-0" style={{ zIndex: '1', top: '13px', left: '85%', borderRadius: "5px" }}>
                                                <Button onClick={() => notificationRightDrawer(true, 'EditCWPROCCODECoderWorkBench', '')} style={{ position: 'absolute', color: 'white', padding: '7px 25px', fontFamily: "'Source Sans Pro', sans-serif", backgroundColor: "rgb(46 147 147)" }} >
                                                    Add
                                                </Button>
                                            </div>
                                            <div className="col-lg-12 pr-0 pl-0" style={{ position: "", minHeight: coderCWDXLoading ? "200px" : coderCWDXRecordList && coderCWDXRecordList.length > 0 ? "30px" : "0px" }}>
                                                <AwvCardComponent
                                                    heading={`Procedure code`}
                                                    body={
                                                        <AWVServerSideGridTable
                                                            data={coderCWPROCCODERecordList}
                                                            columns={coderCWPROCCODERecordColumns}
                                                            filter={true}
                                                            search={true}
                                                            pagination={true}
                                                            download={true}
                                                            isLoading={coderCWPROCCODELoading}
                                                            downloadFileName="Procedure code"
                                                        ></AWVServerSideGridTable>
                                                    }>
                                                </AwvCardComponent>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value="3">
                                        <div className="pl-0 pr-0 row">
                                            <div className="col-lg-12 pr-0 pl-0" style={{ zIndex: '1', top: '13px', left: '85%', borderRadius: "5px" }}>
                                                <Button onClick={() => notificationRightDrawer(true, 'EditMEDICATIONCoderWorkBench', '')} style={{ position: 'absolute', color: 'white', padding: '7px 25px', fontFamily: "'Source Sans Pro', sans-serif", backgroundColor: "rgb(46 147 147)" }} >
                                                    Add
                                                </Button>
                                            </div>
                                            <div className="col-lg-12 pr-0 pl-0" style={{ position: "", minHeight: coderCWDXLoading ? "200px" : coderCWDXRecordList && coderCWDXRecordList.length > 0 ? "30px" : "0px" }}>
                                                <AwvCardComponent
                                                    heading={`Medication`}
                                                    body={
                                                        <AWVServerSideGridTable
                                                            data={coderMedicationRecordList}
                                                            columns={coderMedicationRecordColumns}
                                                            filter={true}
                                                            search={true}
                                                            pagination={true}
                                                            download={true}
                                                            isLoading={coderMedicationLoading}
                                                            downloadFileName="Medication"
                                                        ></AWVServerSideGridTable>
                                                    }>
                                                </AwvCardComponent>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </div>
                    </div>
                </div>
            </div >
            <br />
            <div className='mt-3' style={{ position: "relative", width: "100%", textAlign: "center", fontWeight: "bold", wordWrap: "break-word", fontSize: "12px" }}>
                CPT codes, descriptions and other data only are copyright 1995 - 2022 American Medical Association. All rights reserved. CPT is a registered trademark of the American Medical Association (AMA).
            </div>
        </>
    )

};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        aweRightSidebarCloseDatatableReload: state.moduleFilter.aweRightSidebarCloseDatatableReload,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(AWVCoderBenchmark);
