import PropTypes from "prop-types";
import {SetPlanSelect} from '../../assets/constants/planList';
import { currentPlanSelect } from '../../store/actions';
import { connect } from 'react-redux';

const PlanSelect = (props) => {

    const { PlanList, planSelect} = props;
    props.currentPlanSelect(planSelect);

    const handleSelect = (e) =>{
        SetPlanSelect(e.target.value);        
        props.currentPlanSelect(e.target.value);
        props.onPlanSelect(e.target.value);
    }

    return(
        <div className="d-flex justify-content-end">
            <div className="btn-group">
            <select className="btn btn-info dropdown-toggle btn" value={planSelect} onChange={handleSelect}>
                {
                    PlanList && PlanList.map(el => <option value={el} key={el}> {el} </option>)
                }
            </select>
            </div>
        </div>
    )
}

PlanSelect.propTypes ={
    PlanList : PropTypes.array,
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
    };
  }
export default connect(mapStateToProps, {currentPlanSelect})(PlanSelect);