import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import WidgetCard from "../../components/Cards/WidgetCard";
import SingleVerticalBarChart from '../../components/Charts/SingleVerticalBar.Chart';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag, hccSeperator, numberWithCommas } from '../../assets/constants/customRender'
import history from "../../history";
import { CONDITION,  WIDGET_TYPES, REPORTED_INSIGHTS, MEMBER_NAME, CARRIER_ID, PLAN_ID, GENDER, MAO_RISK_CONDITION_CHART,MAO_Chart_Review_Member, MAO_MEMBER_SELECT_TAB } from "../../assets/constants/string.constants";
import { percentFormat } from "../../assets/helpers/formats.helpers";
import CardComponent from "../../components/Cards/card";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import SubHeader from '../../components/home/header/subHeader';
import Link from '@material-ui/core/Link';
import { connect } from 'react-redux';
import GridTable from "../../components/TableGrids/GridTable.table";
//import google from "react-google-charts";

const ConditionalDrillDown = (props) => {
    const [WidgetDetails, setWidgetDetails] = useState({});
    const [readmissionCountTableData, setReadmissionCountTableData] = useState([]);
    const [memberCondSummaryTableData, setMemberCondSummaryTableData] = useState([]);
    const [condition, setCondition] = useState(localStorage.getItem(CONDITION));
    const WidgetDetailsType = [
        WIDGET_TYPES.LOCKICON,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.PERCENT,
        WIDGET_TYPES.SCORE,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.TRENDING,
        WIDGET_TYPES.PERCENT,
    ]

    const type = WidgetDetailsType;
    const widgetStyles = {
        position: 'absolute',
        left: 0,
        //right: 20,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.15,
        color: "white"
    };

    const loadWidgetDetails = () => {
        ///modelPredictionsummary/2019/Diabetes
         
        api.get('/modelPredictionsummary/'+props.yearSelect+'/'+condition)
        .then(res => {
            setWidgetDetails({
                '# Members': Number(res[0].totalMembers).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                '# Members without Condition':  Number(res[0].totalMembersWithoutCondition).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                '# Potential Members with Condition':  Number(res[0].potentialMembersWithCondition).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                'Potential impact on RAF Score': Number(res[0].potentialImpactOnRAFScore * 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, +' ,')+'%',
                'Potential Revenue Impact':'$'+ Number(res[0].potentialRevenueImpact).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
           
            });
        })
        .catch(err => {
            // console.log(err);
        });
    };
   

    const getIcon = (type) => {
        var data = ''
        switch (type) {
            case WIDGET_TYPES.ISSUER:
                data = <AccountBalanceTwoToneIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.MEMBERS:
                data = <PeopleAltIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERCENT:
                data = <ShowChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.SCORE:
                data = <BarChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERSON:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.CHILD:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.ADULT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.INFANT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.REVENUE:
                data = <AttachMoneyIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.COUNT:
                data = <ShoppingCartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.LOCKICON:
                data = <LockOpenIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.TRENDING:
                data = <TrendingUpIcon style={widgetStyles} />
                break;
        }

        return data;
    }
 
    const  memberMedicareCols =[
        {
            name: "MemberID",
            label: "Member ID",
        },
        {
            name: "likelihood",
            label: "Likelihood of Condition",
        },
        {
            name: "probability",
            label: "Probability"
        },
        {
            name: "changeRafScore",
            label: "RAF Impact thru HCC"
        },
        {
            name: "revenueImpact",
            label: "Potential Revenue Impact"
        },

        
    ]



    useEffect(() => { 
        setCondition(localStorage.getItem(CONDITION))
        loadWidgetDetails();           
    },  [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect,
        props.meInitialSelect,
        localStorage.getItem(CONDITION)
    ]);

    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Conditional Member Summary'} selectType={'utilization'}/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0">
                    <div className="state-level">          
                        {(Object.entries(WidgetDetails).length > 0) ?                     
                            <SlickSlider WidgetDetails={WidgetDetails} getIcon={getIcon} type={type} />
                        :
                            <></>
                        }                       
                    </div>
                </div>
                <div className='col-lg-12 pr-0 pl-0'>
                    <div className="list-member mt-4">
                        <CardComponent
                            heading={`MEMBER LIST`}
                            body={
                                    <ServerSideGridTable
                                    //Condition/Diabetes/2019?
                                        url={`/Condition/${condition}/${props.yearSelect}`}
                                        columns={memberMedicareCols}
                                        // tableClick={memberTableClick}
                                    ></ServerSideGridTable>
                                }>
                        </CardComponent>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br/>

                <br/>
                <br/> 

            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(ConditionalDrillDown);
