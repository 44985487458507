import api from './Rafservice.Instance';
import { 
    SELECT_YEAR, SELECT_INITIAL, 
    SELECT_METAL_TIER, SELECT_VIEW_BY,
    SELECT_PLAN, SELECT_MODEL
} from "../../assets/constants/string.constants";

let ViewBy_list = [];
let ViewBy_select = '';
export const ViewByList = () =>{

    // api.get('/aca_yearList/')
    // .then(res=>{
        SetViewByList(['All']);
        if(localStorage.getItem(SELECT_VIEW_BY) && localStorage.getItem(SELECT_VIEW_BY) != ''){
            // localStorage.setItem('YEAR', res[0]);            
            // SetAcaYear(res[0]);
            SetViewBySelect(localStorage.getItem(SELECT_VIEW_BY));
        }else{
            localStorage.setItem(SELECT_VIEW_BY, 'All');            
            SetViewBySelect('All'); 
        }     
    // })
    // .catch(err=>{
    //     // console.log(err);
    // })
}

export const SetViewByList = (data) =>{
    ViewBy_list = data;
}

export const SetViewBySelect = (data) =>{
    ViewBy_select = data;
    localStorage.setItem(SELECT_VIEW_BY, data);
}

export const getViewByList = () =>{
    return ViewBy_list;
}

export const getViewBySelect = () =>{
    return ViewBy_select;
}