import { useEffect, useState } from "react";
import { anchorTag } from "../../assets/constants/customRender";
import api from "../../assets/constants/Rafservice.Instance";
import { CONDITION_FLAG, HCC, ISSUER_ID, RAF_RANGE } from "../../assets/constants/string.constants";
import { getAcaYear } from "../../assets/constants/yearList";
import CardComponent from "../../components/Cards/card";
import SingleHorizontalBar from "../../components/Charts/SingleHorizontalBar.chart";
import GridTable from "../../components/TableGrids/GridTable.table";
import history from "../../history";
import { connect } from 'react-redux';

const RafDrilDownACA = (props) =>{

    const [rafRange] = useState(localStorage.getItem(RAF_RANGE));
    const [year] = useState(props.yearSelect);
    const [issuer] = useState(localStorage.getItem(ISSUER_ID));
    const [tableData, setTableData] = useState([]);
    const [HccChartData, setHccChartData] = useState({});

    const loadTableData = () =>{
        api.get('/acaRafDrillDown/year/'+year+'/rafRange/'+rafRange+'/issuer_id/'+issuer)
        .then(res=>{
            setTableData(res);
        })
        .catch(err=>{
            // console.log(err);
        })
    }

    const Columns = [
        {
            name: "totalMembers",
            label: "Total Members",
            options: anchorTag
        },
        {
            name: "membersSingleHcc",
            label: "Single Condition Members",
            options: anchorTag
        },
        {
            name: "membersMultipleHcc",
            label: "Multiple Condition Members",
            options: anchorTag
        },
        {
            name: "membersMultipleHccWithInteraction",
            label: "Multiple Conditions with Interaction Members",
            options: anchorTag
        }
    ];

    const loadHccChart = () =>{
        let hccCode =[];
        let totalCount =[];
        api.get('/acaRafDrillDownHccGraph/year/'+year+'/range/'+rafRange +'/issuer_id/'+issuer)
        .then(res=>{
            // console.log(res);
            for (const dataObj of res) {
                hccCode.push((dataObj.description + ':' + dataObj.hccCode));
                totalCount.push(dataObj.totalCount);
            }
            setHccChartData({
                labels: hccCode,
                data:  totalCount,
                dLabel: "Count"
            });
        })
    }

    const hccChartClick =(element) =>{
        let chartData = element[0]['_chart'].config.data;
        let idx = element[0]['_index'];
        let hcc = chartData.labels[idx];
        let parts = hcc.split(':');
        let hcc1 = parts[1];
        localStorage.setItem(HCC,hcc1);
        localStorage.setItem(ISSUER_ID, issuer);
        history.push('/layout/acaHccDrillDown');
    }

    const Tableclick = (element, el) =>{
        let flag = el.colIndex;
        if(flag == 2){
            flag = 23;
        }
        else if(flag == 3){
            flag = 2;
        }
        localStorage.setItem(CONDITION_FLAG,flag);
        history.push('/layout/acaRafDrillDownMember');
    }

    useEffect(()=>{
        loadTableData();
        loadHccChart();
    },[]);

    return(
        <div>
            <CardComponent
            heading={`SPLIT UP FOR MEMBERS WITH RISK SCORE RANGE - ${rafRange} FOR YEAR : ${year} AND ISSUER ID : ${issuer}`}
            body={
                <GridTable
                    data={tableData}
                    columns={Columns}
                    onTableClick={Tableclick}
                ></GridTable>
            }></CardComponent>
            <br/>
            <CardComponent
            heading={`TOP 10 HCC's REPORTED`}
            body={
                <SingleHorizontalBar
                    label = {HccChartData.labels}
                    Data ={HccChartData.data}
                    dLabel = {HccChartData.dLabel}
                    onChartClick = {hccChartClick}
                    xLabel={'Member Count'}
                    yLabel={"HCC's"}
                ></SingleHorizontalBar>
            }></CardComponent>
            <br/>
            <br/>     
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(RafDrilDownACA);