import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import WidgetCard from "../../components/Cards/WidgetCard";
import SingleVerticalBarChart from '../../components/Charts/SingleVerticalBar.Chart';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag, hccSeperator, addSuffixTwoDecimal } from '../../assets/constants/customRender'
import history from "../../history";
import { MEMBER_ID, ISSUER_ID, HCC, WIDGET_TYPES, REPORTED_INSIGHTS, UTILIZATION, MAO_RISK_CONDITION_CHART, MAO_Chart_Review_Member, MAO_MEMBER_SELECT_TAB } from "../../assets/constants/string.constants";
import { percentFormat } from "../../assets/helpers/formats.helpers";
import CardComponent from "../../components/Cards/card";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import GChartClick from '../../components/GChart/GChartClick';
import IssuersACA from '../ACA/ACAIssuers.page';
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import SubHeader from '../../components/home/header/subHeader';
import { getViewBySelect } from '../../assets/constants/viewByList';
import { getModelSelect } from '../../assets/constants/modelList';
import { getMetalTierSelect } from '../../assets/constants/metalTierList';
import { getPlanSelect } from '../../assets/constants/planList';
import { getModuleSelect } from '../../assets/constants/moduleList';
import { getMeInitialSelect } from '../../assets/constants/meInitialList';
import Link from '@material-ui/core/Link';
import { connect } from 'react-redux';
//import google from "react-google-charts";
const MaoMemberSummaryPage = (props) => {
    const [HccChartData, setHccChartData] = useState({});
    const [WidgetDetails, setWidgetDetails] = useState({});
    const [cardToggle, setcardToggle] = useState(true);
    const [ChartData, setChartData] = useState([]);
    const [issuerData, setIssuerData] = useState([]);
    const [IssuerId, setIssuerId] = useState("");
    const [riskChartData, setRiskChartData] = useState([]);
    const [moduleSelect, setModuleSelect] = useState(getModuleSelect());
    const [viewBySelect, setViewBySelect] = useState(getViewBySelect());
    const [modelSelect, setModelSelect] = useState(getModelSelect());
    const [metalTierSelect, setMetalTierSelect] = useState(getMetalTierSelect());
    const [planSelect, setPlanSelect] = useState(getPlanSelect());

    let sliderRangeInt = 0;
    let dataIndex = 0;

    const backGround = [
        { backgroundColor: "#AAB7B8" },
        { backgroundColor: "#EB984E" },
        { backgroundColor: "#58D68D" },
        { backgroundColor: "#5DADE2" },
        { backgroundColor: "#AF7AC5" },
        { backgroundColor: "#EC7063" }
    ]

    const WidgetDetailsType = [
        WIDGET_TYPES.LOCKICON,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.PERCENT,
        WIDGET_TYPES.SCORE,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.TRENDING,
        WIDGET_TYPES.PERCENT,
    ]

    const type = WidgetDetailsType;
    const widgetStyles = {
        position: 'absolute',
        left: 0,
        //right: 20,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.15,
        color: "white"
    };

    const loadRiskMemberIssuer = () => {
        let newChartData = [];
        newChartData.push(["Raf Range", "Count Of Members", { role: 'annotation' }]);
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/MaorafRangeGraph/year/' + getAcaYear() + '/period/' + getMeInitialSelect())
            .then(res => {
                for (const dataObj of res) {
                    newChartData.push([dataObj.rafRange, dataObj.countOfMembers, dataObj.countOfMembers]);
                }
                setRiskChartData(newChartData);
            })
            .catch(err => {
                // console.log(err);
            })
    }

    const loadWidgetDetails = () => {
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/Maomembersummary/year/' + getAcaYear() + '/period/' + getMeInitialSelect())
            .then(res => {
                setWidgetDetails({
                    '# Total Members': Number(res[0].totalMembers).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    '# Active Members': Number(res[0].totalActiveMembers).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    '# Active Members with Condition': Number(res[0].totalActiveMembersWithCondition).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    'Base PMPM': '$' + Number(res[0].basePMPY).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    'Average RAF Score': res[0].averageRAFScore,
                    'Overall Revenue': '$' + Number(res[0].overallRevenue).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                });
                sliderRangeInt = 6;
            })
            .catch(err => {
                // console.log(err);
            });
    };

    const getIcon = (type) => {
        var data = ''
        switch (type) {
            case WIDGET_TYPES.ISSUER:
                data = <AccountBalanceTwoToneIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.MEMBERS:
                data = <PeopleAltIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERCENT:
                data = <ShowChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.SCORE:
                data = <BarChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERSON:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.CHILD:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.ADULT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.INFANT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.REVENUE:
                data = <AttachMoneyIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.COUNT:
                data = <ShoppingCartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.LOCKICON:
                data = <LockOpenIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.TRENDING:
                data = <TrendingUpIcon style={widgetStyles} />
                break;
        }

        return data;
    }


    const GetRowData = (rowdata) => {
        // localStorage.setItem(ISSUER_ID, rowdata[0].ISSUER_ID)
        // setIssuerId(rowdata[0].ISSUER_ID);
        // loadRiskMemberIssuer();    
    }

    const memberMedicareCols = [
        {
            label: "Member ID",
            name: "MEMBER_ID",
            options: anchorTag
        },
        {
            name: "DEMOGRAPHIC_SCORE",
            label: "Demographic Score",
            options: addSuffixTwoDecimal
        },
        {
            name: "OREC_SCORE",
            label: "OREC Score",
        },
        {
            name: "HCC_SCORE",
            label: "HCC Score",
            options: addSuffixTwoDecimal
        },
        {
            name: "HCC_COUNT_SCORE",
            label: "Hcc Count Score",
            options: addSuffixTwoDecimal
        },
        {
            name: "INTERACTION_SCORE",
            label: "Interaction Score",
            options: addSuffixTwoDecimal
        },
        {
            name: "RAF_SCORE",
            label: "RAF Score",
            options: addSuffixTwoDecimal
        },
        {
            name: "REVENUE",
            label: "Revenue $",
        }
    ];

    const memberTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(MAO_Chart_Review_Member, rowdata[cellMeta.rowIndex].MEMBER_ID);
            localStorage.setItem(MAO_MEMBER_SELECT_TAB, UTILIZATION);
            history.push('/layout/medicare/mao_member_summary')
        }
    }


    useEffect(() => {
        setChartData([]);
        if (props.yearSelect && props.yearSelect != '') {
            loadWidgetDetails();
            loadRiskMemberIssuer();
        }
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect()
    ]);

    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Reported Member Summary'} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0">
                    <div className="state-level">
                        {(Object.entries(WidgetDetails).length > 0) ?
                            <SlickSlider WidgetDetails={WidgetDetails} getIcon={getIcon} type={type} />
                            :
                            <></>
                        }
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-2">
                    <CardComponent
                        heading={`RAF SCORE DISTRIBUTION FOR MEMBERS WITH CONDITION`}
                        body={
                            <div className="box-height-chart">
                                <GChartClick data={riskChartData} chartType="ColumnChart" height="330px" width="100%" setStorgeItemName={MAO_RISK_CONDITION_CHART} redirectUrl={'medicare/raf_drill_down'} hAxisTitle={"RISK SCORE"} yAxistitle={"Count"}></GChartClick>
                            </div>
                        }></CardComponent>
                </div>
                <div className='col-lg-12 pr-0 pl-0'>
                    <div className="list-member mt-4">
                        <CardComponent
                            heading={`MEMBER LIST`}
                            body={
                                <ServerSideGridTable
                                    url={`/MaoRafDrillDownMember/year/${getAcaYear()}/period/${getMeInitialSelect()}/max/1000`}
                                    columns={memberMedicareCols}
                                    tableClick={memberTableClick}
                                ></ServerSideGridTable>
                            }>
                        </CardComponent>
                    </div>
                </div>

                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br />

                <br />
                <br />

            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default connect(mapStateToProps)(MaoMemberSummaryPage);
