import React, { useState, useEffect } from 'react';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import AWVApi from '../../assets/constants/AWVRafservice.Instance';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import Divider from '@mui/material/Divider';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import pdfIcon from '../../assets/images/pdf_icon.png';
import Moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import AWS from 'aws-sdk';
const aws = require('aws-sdk');

const S3_BUCKET = 'bucket-awv-form-upload'; //process.env.REACT_APP_BUCKET_NAME_Two;
const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = localStorage.getItem("access_key");
const SECRET_ACCESS_KEY = localStorage.getItem("secret_key");

const styles = (theme) => ({
    dialogPaper: {
        maxWidth: '80%',
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const AWVMemberDetailsCoodinator = (props) => {
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;


    useEffect(() => {
        setValue('awe_source', props?.editFormData?.awe_source);
        setValue('receiver_date', Moment(props?.editFormData?.receiver_date).format("YYYY-MM-DD"));
        setValue('received_method', props?.editFormData?.received_method);
        setValue('additional_data', props?.editFormData?.additional_data);
        setValue('telehealth_checkbox', props?.editFormData?.telehelth_check);/* correct the spell of telehelth to TELEHEALTH */
        setValue('service_date', Moment(props?.editFormData?.service_date).format("YYYY-MM-DD"));
        setValue('remarks', props?.editFormData?.remarks);
        setValue('awe_id', String(props?.editFormData?.AWE_ID));
        setValue('cin', props?.editFormData?.CIN);
        // console.log(props?.editFormData);
    }, [props.editFormData]);

    const onSubmit = (data) => {
        setIsLoading(true);
        if (props?.editFormData?.CIN && props?.editFormData?.CIN != '') {
            let controlUser = JSON.parse(localStorage.getItem('controlUser'));
            // data.cin = props?.editFormData?.CIN;
            if (props?.editFormData?.action && props?.editFormData?.action == 'Add') {
                return true;
                // data.created_by = controlUser?.user_id;
                // data.created_on = Moment().format('YYYY-MM-DD HH:mm:ss');
                // AWVApi.post('/create-hcc-details', data)
                //     .then(res => {
                //         setValue('remarks', '');
                //         setValue('status', '');
                //         setValue('checklist_id', '');
                //         setValue('awe_id', '');
                //         setValue('cin', '');
                //         props.loadFormData();
                //         setIsLoading(false);
                //         toast.success('Coodinator Record add success');
                //         props.handleClose();
                //     })
                //     .catch(err => {
                //         setIsLoading(false);
                //         toast.error(err?.response?.data?.message);
                //     })
            } else {
                data.updated_by = controlUser?.user_id;
                data.additional_data = String(data?.additional_data);
                data.telehealth_checkbox = String(data?.telehealth_checkbox);/* correct the spell of telehelth to TELEHEALTH */
                AWVApi.put('/update_awe_coordinator_checklist', data)
                    .then(res => {
                        setValue('awe_source', '');
                        setValue('receiver_date', '');
                        setValue('received_method', '');
                        setValue('additional_data', '');
                        setValue('service_date', '');
                        setValue('remarks', '');
                        setValue('telehealth_checkbox', '');
                        setValue('awe_id', '');
                        setValue('cin', '');
                        props.loadFormData(props?.editFormData?.CIN, props?.editFormData?.AWE_ID);
                        setIsLoading(false);
                        toast.success('Coodinator Record update success');
                        props.handleClose();
                    })
                    .catch(err => {
                        setIsLoading(false);
                        toast.error(err?.response?.data?.message);
                    })
            }
        }

    };


    return (
        <div>
            <MuiDialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth={true}
                maxWidth={'sm'}
                scroll={'body'}
                className="awv_model_layout"
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="create-group" onClose={props.handleClose}>
                    <strong>
                        {(props?.editFormData?.action && props?.editFormData?.action == 'Add') ?
                            'COORDINATOR CHECKLIST ADD'
                            :
                            'COORDINATOR CHECKLIST EDIT'
                        }
                    </strong>
                </DialogTitle>
                <Divider />
                <div className='row'>
                    <div className='col-lg-12'>
                        <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                            <DialogContent >
                                {isLoading && isLoading == true ?
                                    <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                                        <CircularProgress />
                                    </div>
                                    :
                                    <></>
                                }
                                <div className="row mb-3">
                                    <div className="col-lg-6 align-items-center">
                                        <div className='mb-1'><strong>AWE SOURCE</strong></div>
                                        <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                            {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                            <Controller
                                                className="input-control"
                                                name="awe_source"
                                                value={props?.editFormData?.awe_source}
                                                control={control}
                                                render={({ field }) => (
                                                    // <TextField  {...field} variant="outlined" />
                                                    <Select
                                                        {...field}
                                                        labelId="module-multiple-checkbox-label"
                                                        id="module-multiple-checkbox"
                                                        // value={selectModule}
                                                        // onChange={handleChange}
                                                        label="Module"
                                                        variant="outlined"
                                                        // MenuProps={MenuProps}
                                                        menuPlacement="top"
                                                    >
                                                        <MenuItem key={'Provider'} value={'Provider'} >
                                                            Provider
                                                        </MenuItem>
                                                        <MenuItem key={'HCD'} value={'HCD'} >
                                                        HCD
                                                        </MenuItem>
                                                        <MenuItem key={'Applecare'} value={'Applecare'} >
                                                        Applecare
                                                        </MenuItem>
                                                    </Select>
                                                )}
                                                rules={{
                                                    required: true,
                                                }}
                                            />
                                            {errors?.awe_source?.type === "required" && <label className="text-danger">This field is required</label>}
                                        </FormControl>
                                    </div>
                                    <div className="col-lg-6 align-items-center">
                                        <div className='mb-1'><strong>RECEIVED METHOD</strong></div>
                                        <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                            {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                            <Controller
                                                className="input-control"
                                                name="received_method"
                                                value={props?.editFormData?.received_method}
                                                control={control}
                                                render={({ field }) => (
                                                    // <TextField  {...field} variant="outlined" />
                                                    <Select
                                                        {...field}
                                                        labelId="module-multiple-checkbox-label"
                                                        id="module-multiple-checkbox"
                                                        // value={selectModule}
                                                        // onChange={handleChange}
                                                        label="Module"
                                                        variant="outlined"
                                                        // MenuProps={MenuProps}
                                                        menuPlacement="top"
                                                    >
                                                        {/* correct the spell of emai to email */}
                                                        <MenuItem key={'Secure email'} value={'Secure email'} >
                                                        Secure email
                                                        </MenuItem>
                                                        <MenuItem key={'Fax'} value={'Fax'} >
                                                        Fax
                                                        </MenuItem>
                                                        <MenuItem key={'FTP'} value={'FTP'} >
                                                        FTP
                                                        </MenuItem>
                                                    </Select>
                                                )}
                                                rules={{
                                                    required: true,
                                                }}
                                            />
                                            {errors?.received_method?.type === "required" && <label className="text-danger">This field is required</label>}
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-lg-6 align-items-center">
                                        <div className='mb-1'><strong>RECEIVE DATE</strong></div>
                                        <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                            {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                            <Controller
                                                className="input-control"
                                                name="receiver_date"
                                                value={Moment(props?.editFormData?.receiver_date).format("YYYY-MM-DD")}
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField type="date" {...field} variant="outlined" inputProps={{ max: Moment().format("YYYY-MM-DD") }} />
                                                )}
                                                rules={{
                                                    required: true,
                                                }}
                                            />
                                            {errors?.receiver_date?.type === "required" && <label className="text-danger">This field is required</label>}
                                        </FormControl>
                                    </div>
                                    <div className="col-lg-6 align-items-center">
                                        <div className='mb-1'><strong>SERVICE DATE</strong></div>
                                        <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                            {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                            <Controller
                                                className="input-control"
                                                name="service_date"
                                                value={Moment(props?.editFormData?.service_date).format("YYYY-MM-DD")}
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField type="date" {...field} variant="outlined" inputProps={{ max: Moment().format("YYYY-MM-DD") }} />
                                                )}
                                                rules={{
                                                    required: true,
                                                }}
                                            />
                                            {errors?.service_date?.type === "required" && <label className="text-danger">This field is required</label>}
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row  mb-3">
                                    <div className="col-lg-6 align-items-center">
                                        <div className='mb-1'><strong>ADDITIONAL DATA</strong></div>
                                        <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                            {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                            <Controller
                                                className="input-control"
                                                name="additional_data"
                                                value={props?.editFormData?.additional_data}
                                                control={control}
                                                render={({ field }) => (
                                                    // <TextField  {...field} variant="outlined" />
                                                    <Select
                                                        {...field}
                                                        labelId="module-multiple-checkbox-label"
                                                        id="module-multiple-checkbox"
                                                        // value={selectModule}
                                                        // onChange={handleChange}
                                                        label="Module"
                                                        variant="outlined"
                                                        // MenuProps={MenuProps}
                                                        menuPlacement="top"
                                                    >
                                                        <MenuItem key={'1'} value={'1'} >
                                                            Yes
                                                        </MenuItem>
                                                        <MenuItem key={'0'} value={'0'} >
                                                            No
                                                        </MenuItem>
                                                    </Select>
                                                )}
                                                rules={{
                                                    required: true,
                                                }}
                                            />
                                            {errors?.additional_data?.type === "required" && <label className="text-danger">This field is required</label>}
                                        </FormControl>
                                    </div>
                                    <div className="col-lg-6 align-items-center">
                                        {/* correct the spell of telehelth to TELEHEALTH */}
                                        <div className='mb-1'><strong>TELEHEALTH</strong></div> 
                                        <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                            {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                            <Controller
                                                className="input-control"
                                                name="telehealth_checkbox"
                                                value={props?.editFormData?.telehealth_checkbox}
                                                control={control}
                                                render={({ field }) => (
                                                    // <TextField  {...field} variant="outlined" />
                                                    <Select
                                                        {...field}
                                                        labelId="module-multiple-checkbox-label"
                                                        id="module-multiple-checkbox"
                                                        // value={selectModule}
                                                        // onChange={handleChange}
                                                        label="Module"
                                                        variant="outlined"
                                                        // MenuProps={MenuProps}
                                                        menuPlacement="top"
                                                    >
                                                        <MenuItem key={'1'} value={'1'} >
                                                            Yes
                                                        </MenuItem>
                                                        <MenuItem key={'0'} value={'0'} >
                                                            No
                                                        </MenuItem>
                                                    </Select>
                                                )}
                                                rules={{
                                                    required: true,
                                                }}
                                            />
                                            {errors?.telehealth_checkbox?.type === "required" && <label className="text-danger">This field is required</label>}
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="row  mb-3">
                                    <div className="col-lg-12 align-items-center">
                                        <div className='mb-1'><strong>REMARKS</strong></div>
                                        <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                            {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                            <Controller
                                                className="input-control"
                                                name="remarks"
                                                value={props?.editFormData?.remarks}
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField  {...field} variant="outlined" />
                                                )}
                                                rules={{
                                                    required: true,
                                                }}
                                            />
                                            {errors?.remarks?.type === "required" && <label className="text-danger">This field is required</label>}
                                        </FormControl>
                                    </div>
                                </div>
                            </DialogContent>
                            <DialogActions className='mt-2'>
                                <Button type='button' variant="contained" color="grey" onClick={props.handleClose}>CANCEL</Button>
                                <Button type='submit'
                                    className={'mr-2 btn-custom-primary'}
                                    variant="contained"
                                    disabled={isLoading && isLoading == true ? true : false}
                                >
                                    {(props?.editFormData?.action && props?.editFormData?.action == 'Add') ?
                                        'ADD'
                                        :
                                        'UPDATE'
                                    }
                                </Button>
                            </DialogActions>
                        </form>
                    </div>
                </div>
            </MuiDialog>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        userDetails: state.auth.userDetails,
        yearSelect: state.moduleFilter.yearSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        lobSelect: state.moduleFilter.lobSelect,
    };
}
export default (withStyles(styles), connect(mapStateToProps))(AWVMemberDetailsCoodinator);
