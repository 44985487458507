import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import IconButton from './IconButton'
import MenuBarsIcon from '../../icons/MenuBarsIcon'
import MedicalKitIcon from '../../icons/MedicalKitIcon';
import PieChart3Icon from '../../icons/PieChart3Icon';
import ChartSquareIcon from '../../icons/ChartSquareIcon';
import ChartBarIcon from '../../icons/ChartBarIcon';
import logo from '../../../assets/awe/images/logo.png';
import ArrowDownIcon from '../../icons/ArrowDownIcon';
import SummarizeIcon from '@mui/icons-material/Summarize';

export default function MainSidebar({ sidebarToggle, sidebarOpen }) {
  const [screenSize, setScreenSize] = useState(window.innerWidth)
  const [megaMenuItem, setMegaMenuItem] = useState("")
  useEffect(() => {
    window.addEventListener('resize', function (event) {
      setScreenSize(window.innerWidth)
    });
  }, [])

  const getMenuPermissionCheck = (permissionSlug) => {
    var permissionCheck = false;
    if (permissionSlug && permissionSlug != '') {
      var permissionGets = JSON.parse(localStorage.getItem('controlPermissions'));
      if (permissionGets && permissionGets.length > 0) {
        permissionGets && permissionGets.forEach(elem => {
          if (elem.permission == permissionSlug && elem.status == true) {
            permissionCheck = true;
          }
        });
      }
    }
    return permissionCheck;
  }

  return (
    <aside className={`sidebar-wrapper ${screenSize > 1199 ? sidebarOpen ? "is_mini" : "" : ""}`}>
      <div className="sidebar-header flex_item_cb">
        {!sidebarOpen ? <Link to={"/"}><img src={logo} className="img-fluid" /></Link> : screenSize < 1200 ? <Link to={"/"}><img src={logo} className="img-fluid" /></Link> : ""}
        <IconButton buttonType={"default"} className={"link_white"} onClick={() => sidebarToggle(!sidebarOpen)}><MenuBarsIcon className="w24" /></IconButton>
      </div>
      <ul className="navbar-nav mr-auto sidenav accordion custom-scrollbar text-white" id="navAccordion">
        <li className="nav-item">
          <a data-toggle="collapse" type="button" role='button' data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" className={`nav-link flex_item_cs gap10 collapsed ${megaMenuItem == 1 ? "active" : ""}`} onClick={() => setMegaMenuItem(1)}>
            <span className="icon">
              <MedicalKitIcon className="w24" />
            </span>
            <span className="text">Annual Wellness Exam</span>
            <span className="arrow_icon ml-auto">
              <ArrowDownIcon className="w12" />
            </span>
          </a>
          <ul className="submenu collapse show" id="collapseOne" data-parent="#navAccordion">
            {getMenuPermissionCheck('awv_analytic_tab_8') ?
              <li className="nav-item">
                <NavLink className={`nav-link flex_item_cs gap10 ${(isActive) => isActive ? "active" : ""}`} to={"/new_awe/awe-analytics-summary"}>
                  <span className="icon">
                    <PieChart3Icon className="w24" />
                  </span>
                  <span className="text">AWE Analytics Summary</span>
                </NavLink>
              </li>
              :
              <></>
            }

            {getMenuPermissionCheck('awv_member_details_8') ?
              <li className="nav-item">
                <NavLink className={`nav-link flex_item_cs gap10 ${(isActive) => isActive ? "active" : ""}`} to={"/new_awe/awe-record"}>
                  <span className="icon">
                    <ChartSquareIcon className="w24" />
                  </span>
                  <span className="text">AWE Record</span>
                </NavLink>
              </li>
              :
              <></>
            }
            {/* {getMenuPermissionCheck('awv_member_details_8') ?
              <li className="nav-item">
                <NavLink className={`nav-link flex_item_cs gap10 ${(isActive) => isActive ? "active" : ""}`} to={"/new_awe/awe_reports"}>
                  <span className="icon">
                    <SummarizeIcon className="w24" />
                  </span>
                  <span className="text">Reports</span>
                </NavLink>
              </li>
              :
              <></>
            } */}
            {getMenuPermissionCheck('awv_member_details_8') ?
              <li className="nav-item">
                <NavLink className={`nav-link flex_item_cs gap10 ${(isActive) => isActive ? "active" : ""}`} to={"/new_awe/awe_dashboard"}>
                  <span className="icon">
                    <SummarizeIcon className="w24" />
                  </span>
                  <span className="text">Operational Dashboard</span>
                </NavLink>
              </li>
              :
              <></>
            }
            {getMenuPermissionCheck('awv_member_details_8') ?
              <li className="nav-item">
                <NavLink className={`nav-link flex_item_cs gap10 ${(isActive) => isActive ? "active" : ""}`} to={"/new_awe/operational_dashboard"}>
                  <span className="icon">
                    <SummarizeIcon className="w24" />
                  </span>
                  <span className="text">AWE Universal Report</span>
                </NavLink>
              </li>
              :
              <></>
            }
            <li className="nav-item">
              <NavLink className={`nav-link flex_item_cs gap10 ${(isActive) => isActive ? "active" : ""}`} to={"/new_awe/query_letter_summary"}>
                <span className="icon">
                  <SummarizeIcon className="w24" />
                </span>
                <span className="text">Query Letter Summary</span>
              </NavLink>
            </li>
            {/* {getMenuPermissionCheck('awv_reporting_tab_8') ?
              <li className="nav-item">
                <NavLink className={`nav-link flex_item_cs gap10 ${(isActive) => isActive ? "active" : ""}`} to={"/awe/AWEMemberDetails"}>
                  <span className="icon">
                    <ChartBarIcon className="w24" />
                  </span>
                  <span className="text">AWE Universal Report</span>
                </NavLink>
              </li>
              :
              <></>
            } */}


          </ul>
        </li>
        {/* <li className="nav-item">
          <NavLink className={`nav-link flex_item_cs gap10 ${(isActive) => isActive ? "active" : ""}`} to={"/new_awe/awe_coder_benchmark?cin=10016602"}>
            <span className="icon">
              <ChartBarIcon className="w24" />
            </span>
            <span className="text">Coder WorkBench</span>
          </NavLink>
        </li> */}
      </ul>
    </aside>
  )
}
