import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import WidgetCard from "../../components/Cards/WidgetCard";
import SingleVerticalBarChart from '../../components/Charts/SingleVerticalBar.Chart';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { getMeInitialSelect } from '../../assets/constants/meInitialList';
import { anchorTag, hccSeperator, numberWithCommas, addSuffixTwoDecimal } from '../../assets/constants/customRender'
import history from "../../history";
import { MEMBER_ID, ISSUER_ID, HCC, WIDGET_TYPES, REPORTED_INSIGHTS, MEMBER_NAME, CARRIER_ID, PLAN_ID, GENDER, MAO_MEMBER_ID } from "../../assets/constants/string.constants";
import { percentFormat } from "../../assets/helpers/formats.helpers";
import CardComponent from "../../components/Cards/card";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import GChart from '../../components/GChart/GChart';
import IssuersACA from '../ACA/ACAIssuers.page';
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import SubHeader from '../../components/home/header/subHeader';
import { getViewBySelect } from '../../assets/constants/viewByList';
import { getModelSelect } from '../../assets/constants/modelList';
import { getMetalTierSelect } from '../../assets/constants/metalTierList';
import { getPlanSelect } from '../../assets/constants/planList';
import { getModuleSelect } from '../../assets/constants/moduleList';
import Link from '@material-ui/core/Link';
import { connect } from 'react-redux';
//import google from "react-google-charts";
const MaoEncounterComparison = (props) => {
    const [HccChartData, setHccChartData] = useState({});
    const [WidgetDetails, setWidgetDetails] = useState({});
    const [cardToggle, setcardToggle] = useState(true);
    const [ChartData, setChartData] = useState([]);
    const [issuerData, setIssuerData] = useState([]);
    const [IssuerId, setIssuerId] = useState("");
    const [riskChartData, setRiskChartData] = useState([]);
    const [moduleSelect, setModuleSelect] = useState(getModuleSelect());
    const [viewBySelect, setViewBySelect] = useState(getViewBySelect());
    const [modelSelect, setModelSelect] = useState(getModelSelect());
    const [metalTierSelect, setMetalTierSelect] = useState(getMetalTierSelect());
    const [planSelect, setPlanSelect] = useState(getPlanSelect());

    let sliderRangeInt = 0;
    let dataIndex = 0;

    const backGround = [
        { backgroundColor: "#AAB7B8" },
        { backgroundColor: "#EB984E" },
        { backgroundColor: "#58D68D" },
        { backgroundColor: "#5DADE2" },
        { backgroundColor: "#AF7AC5" },
        { backgroundColor: "#EC7063" }
    ]

    const WidgetDetailsType = [
        WIDGET_TYPES.LOCKICON,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.PERCENT,
        WIDGET_TYPES.SCORE,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.TRENDING,
        WIDGET_TYPES.PERCENT,
    ]

    const type = WidgetDetailsType;
    const widgetStyles = {
        position: 'absolute',
        left: 0,
        //right: 20,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.15,
        color: "white"
    };

    const sliderSettingsAca = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        adaptiveHeight: true,
        variableWidth: true
    };

    const loadRiskMemberIssuer = () => {
        let newChartData = [];
        newChartData.push(["Raf Range", "Count Of Members"]);
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/rafRangeGraph/year/' + getAcaYear() + '/period/' + getMeInitialSelect())
            .then(res => {
                for (const dataObj of res) {
                    newChartData.push([dataObj.rafRange, dataObj.countOfMembers]);
                }
                setRiskChartData(newChartData);
            })
            .catch(err => {
                // console.log(err);
            })

    }

    const loadWidgetDetails = () => {
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/Maocomparisonsummary/year/' + getAcaYear() + '/period/' + getMeInitialSelect())
            .then(res => {
                setWidgetDetails({
                    'Encounter Based RAF': res[0].encounter_raf_score,
                    'Encounter Based HCC Score': res[0].encounter_hcc_score,
                    'Encounter Based Count Score': res[0].encounter_count_score,
                    'MAO Based RAF': res[0].mao_raf_score,
                    'MAO Based HCC': res[0].mao_hcc_score,
                    'MAO Based Count': res[0].mao_count_score
                });
                sliderRangeInt = 6;
            })
            .catch(err => {
                // console.log(err);
            });
    };

    const getIcon = (type) => {
        var data = ''
        switch (type) {
            case WIDGET_TYPES.ISSUER:
                data = <AccountBalanceTwoToneIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.MEMBERS:
                data = <PeopleAltIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERCENT:
                data = <ShowChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.SCORE:
                data = <BarChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERSON:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.CHILD:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.ADULT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.INFANT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.REVENUE:
                data = <AttachMoneyIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.COUNT:
                data = <ShoppingCartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.LOCKICON:
                data = <LockOpenIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.TRENDING:
                data = <TrendingUpIcon style={widgetStyles} />
                break;
        }

        return data;
    }


    const GetRowData = (rowdata) => {
        // localStorage.setItem(ISSUER_ID, rowdata[0].ISSUER_ID)
        // setIssuerId(rowdata[0].ISSUER_ID);
        // loadRiskMemberIssuer();    
    }


    const memberMedicareCols = [
        {
            label: "Member ID",
            name: "MEMBER_ID",
            options: anchorTag
        },
        {
            name: "e_RAF",
            label: "Encounter RAF Score",
            options: addSuffixTwoDecimal
        },
        {
            name: "e_HCC",
            label: "Encounter HCC Score",
            options: addSuffixTwoDecimal
        },
        {
            name: "e_COUNT",
            label: "Encounter Count Score",
            options: addSuffixTwoDecimal
        },
        {
            name: "mao_RAF",
            label: "MAO RAF Score",
            options: addSuffixTwoDecimal
        },
        {
            name: "mao_HCC",
            label: "MAO HCC Score",
            options: addSuffixTwoDecimal
        },
        {
            name: "mao_COUNT",
            label: "MAO Count Score",
            options: addSuffixTwoDecimal
        },
    ];

    const memberTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(MAO_MEMBER_ID, rowdata[cellMeta.rowIndex].MEMBER_ID);
            history.push('/layout/medicare/encounter_comparison_member', REPORTED_INSIGHTS)
        }
    }

    const loadIssuerDetailsScore = () => {
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/membersummary/year/' + getAcaYear() + '/period/' + getMeInitialSelect())
            .then(res => {
                if (res && res.length > 0) {
                    setIssuerData({
                        'Total Members': res[0].totalMembers,
                        'Active Members': res[0].totalActiveMembers,
                        'Active Members with Condition': res[0].totalActiveMembersWithCondition,
                        'Base PMPM': res[0].basePMPY,
                        'Average RAF Score': res[0].averageRAFScore,
                        'Overall Revenue': res[0].overallRevenue
                    });
                    GetRowData([]);
                }
            })
            .catch(err => {
                // console.log(err)
            })
    }

    const HccChartClick = (element) => {
        var activePoints = element;
        var chartData = activePoints[0]['_chart'].config.data;
        var idx = activePoints[0]['_index'];
        var issuer = chartData.labels[idx];
        localStorage.setItem(ISSUER_ID, issuer);
        history.push('/layout/acaIssuerGapsummary');
    };

    const elementissuerIDLinkClick = () => {
        history.push('/layout/acaIssuerGapsummary');
    };

    //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    useEffect(() => {
        setChartData([]);
        if (props.yearSelect && props.yearSelect != '') {
            loadWidgetDetails();
            loadIssuerDetailsScore();
            loadRiskMemberIssuer();
        }
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect()
    ]);

    let sliderRange = Object.entries(WidgetDetails).length / 3;

    sliderRange = parseInt(sliderRange);
    sliderRangeInt = parseInt(sliderRangeInt);
    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'MAO Comparison Summary'} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0">
                    <div className="state-level">
                        {(Object.entries(WidgetDetails).length > 0) ?
                            <SlickSlider WidgetDetails={WidgetDetails} getIcon={getIcon} type={type} />
                            :
                            <></>
                        }
                    </div>
                </div>

                <div className='col-lg-12 pr-0 pl-0'>
                    <div className="list-member mt-3">
                        <CardComponent
                            heading={`DISCREPANT MEMBER LIST`}
                            body={
                                <ServerSideGridTable
                                    url={`/MaoComparisonDiscrepantMember/year/${getAcaYear()}/period/${getMeInitialSelect()}/max/1000`}
                                    columns={memberMedicareCols}
                                    tableClick={memberTableClick}
                                ></ServerSideGridTable>
                            }>
                        </CardComponent>
                    </div>
                </div>

                <div className='col-lg-12 pr-0 pl-0'>
                    <div className="list-member mt-4">
                        <CardComponent
                            heading={`MEMBER LIST`}
                            body={
                                <ServerSideGridTable
                                    url={`/MaoComparisonMember/year/${getAcaYear()}/period/${getMeInitialSelect()}/max/1000`}
                                    columns={memberMedicareCols}
                                    tableClick={memberTableClick}
                                ></ServerSideGridTable>
                            }>
                        </CardComponent>
                    </div>
                </div>

                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br />

                <br />
                <br />
            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default connect(mapStateToProps)(MaoEncounterComparison);
