import React, { useRef, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../../store/actions';
import AWVCoderWorkBench from '../../../assets/constants/AWVCoderWorkBenchRafservice.Instance';
import Divider from '@mui/material/Divider';
import Moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import { CircularProgress } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { styled } from '@mui/material/styles';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Autocomplete from "@mui/material/Autocomplete";
import CWB_icd_hcc_json from "../../../Pages/config/CPT_code_list.json";
import IconButton from "../../layout/aweLayout/IconButton";
import Button from "../../layout/aweLayout/Button";
import CloseIcon from "../../icons/CloseIcon";

const EditCWPROCCODECoderWorkBench = (props) => {
    const { register, formState: { errors }, trigger, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [formDataList, setFormDataList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
    const [editFormData, setEditFormData] = useState({});
    const [dxCodeDataArray, setDxCodeDataArray] = useState([]);
    const [dxCodeDataArrayPush, setDxCodeDataArrayPush] = useState([]);
    const [dxCodeData, setDxCodeData] = useState([]);
    const [isdxCodeValue, setIsDxCodeValues] = useState(false);
    const [dxCodeValue, setDxCodeValues] = useState();
    const [modifier1CodeData, setModifier1CodeData] = useState([]);
    const [modifier2CodeData, setModifier2CodeData] = useState([]);
    const [modifier3CodeData, setModifier3CodeData] = useState([]);
    const [modifier4CodeData, setModifier4CodeData] = useState([]);
    const [modifier1CodeValue, setModifier1CodeValue] = useState();
    const [modifier2CodeValue, setModifier2CodeValue] = useState();
    const [modifier3CodeValue, setModifier3CodeValue] = useState();
    const [modifier4CodeValue, setModifier4CodeValue] = useState();
    const [ismodifier1CodeValue, setIsModifier1CodeValue] = useState(false);
    const [ismodifier2CodeValue, setIsModifier2CodeValue] = useState(false);
    const [ismodifier3CodeValue, setIsModifier3CodeValue] = useState(false);
    const [ismodifier4CodeValue, setIsModifier4CodeValue] = useState(false);
    const [dxCodeDOSBegin, setDxCodeDOSBegin] = useState();
    useEffect(() => {
        console.log(errors);
    }, [errors]);

    useEffect(async () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const cin = urlParams.get('cin');
        setValue('ih_member_id', cin);

    }, []);

    useEffect(() => {
        if (props?.Id) {
            loadformDataTable(props.Id);
            editLoadFormData(props.Id);
        }
    }, [props?.Id]);

    useEffect(() => {
        if (props?.Id && editFormData?.id && editFormData?.id != '') {
            setValue('id', editFormData?.id);
            setValue('page_no', editFormData?.page_no);
            setValue('ih_member_id', editFormData?.ih_member_id);
            setValue('proc_code', editFormData.proc_code);
            setDxCodeValues(editFormData.proc_code);
            setValue('entity_id', editFormData?.entity_id);
            setValue('entity_text', editFormData?.entity_text);
            setValue('remarks', editFormData?.remarks);
            setValue('cw_proc_status', editFormData?.cw_proc_status);

            setValue('modifier_1', editFormData?.modifier_1);
            setModifier1CodeValue(editFormData?.modifier_1);
            setValue('modifier_2', editFormData?.modifier_2);
            setModifier2CodeValue(editFormData?.modifier_2);
            setValue('modifier_3', editFormData?.modifier_3);
            setModifier3CodeValue(editFormData?.modifier_3);
            setValue('modifier_4', editFormData?.modifier_4);
            setModifier4CodeValue(editFormData?.modifier_4);
            setValue('dos_begin', Moment(editFormData?.dos_begin).format("YYYY-MM-DD"));
            setValue('dos_end', Moment(editFormData?.dos_end).format("YYYY-MM-DD"));
            setDxCodeDOSBegin(Moment(editFormData?.dos_begin).format("YYYY-MM-DD"));
            setIsDxCodeValues(true);
            setIsModifier1CodeValue(true);
            setIsModifier2CodeValue(true);
            setIsModifier3CodeValue(true);
            setIsModifier4CodeValue(true);
            console.log(editFormData);
            console.log(getValues());
        }
    }, [editFormData]);

    const notificationRightDrawer = (open, valueId = '') => {
        props.currentAWERightSidebarType({ 'type': 'notification' });
        props.currentAWERightSidebar(open);
    };

    //load DX codes from JSON
    const handleChangeDxCode = (eventValue) => {
        const result = dxCodeDataArray.find((element) => element.hcpcs + '-' + element.description === eventValue);
        setValue('proc_code', eventValue);
        setDxCodeValues(eventValue);
        if (result) {
            setIsDxCodeValues(true);
            setValue('proc_description', result?.description);
            //setValue('proc_code', result?.hcpcs);
            //setValue('HCC_V28', result?.hcc_v28 + '-' + result?.hcc_v28_description);
        }
        setDxCodeData([]);
    }

    const handleInputChangeDxCode = (eventValue) => {
        setIsDxCodeValues(false);
        if (eventValue.length >= 3) {

        } else {
            setDxCodeDataArray([]);
            let valuesArray = [];
            setValue('proc_code', eventValue);
            setDxCodeValues(eventValue);
            loadDxCodeData(valuesArray, eventValue);
            setIsLoading(false);

            setValue('proc_code', eventValue);
            setDxCodeValues(eventValue);
            return true;
        }

        setIsLoading(true);
        console.log(eventValue);
        var data = { 'search': eventValue };
        AWVCoderWorkBench.post('/data/cpt_code', data)
            .then(res => {
                setDxCodeDataArray(res.data);
                let valuesArray = [];
                res.data.forEach((element, index) => {
                    if (!valuesArray.includes(element.hcpcs + '-' + element.description)) {
                        valuesArray.push(element.hcpcs + '-' + element.description);
                    }
                });
                setValue('proc_code', eventValue);
                setDxCodeValues(eventValue);
                loadDxCodeData(valuesArray, eventValue);
                setIsLoading(false);
            })
            .catch(err => {
                setDxCodeDataArray([]);
                let valuesArray = [];
                setValue('proc_code', eventValue);
                setDxCodeValues(eventValue);
                loadDxCodeData(valuesArray, eventValue);
                setIsLoading(false);
            })
    }

    const loadDxCodeData = (dxCodeDataArrayPush, dxCodeValue) => {
        let values = [];
        if (dxCodeDataArrayPush && dxCodeDataArrayPush.length > 0 && dxCodeValue) {
            dxCodeDataArrayPush.forEach((element, index) => {
                if (!values.includes(element) && (element.toLowerCase()).includes(dxCodeValue.toLowerCase())) {
                    values.push(element);
                }
            });
            setDxCodeData(values);
        } else {
            setDxCodeData(values);
        }
    };

    const handleInputChangeModifierCode = (eventValue, modifireType) => {
        if (modifireType == '1') {
            setValue('modifier_1', eventValue);
            setModifier1CodeValue(eventValue);
            setIsModifier1CodeValue(false);
        } else if (modifireType == '2') {
            setValue('modifier_2', eventValue);
            setModifier2CodeValue(eventValue);
            setIsModifier2CodeValue(false);
        } else if (modifireType == '3') {
            setValue('modifier_3', eventValue);
            setModifier3CodeValue(eventValue);
            setIsModifier3CodeValue(false);
        } else if (modifireType == '4') {
            setValue('modifier_4', eventValue);
            setModifier4CodeValue(eventValue);
            setIsModifier4CodeValue(false);
        }
        if (eventValue.length >= 2) {

        } else {
            if (modifireType == '1') {
                setModifier1CodeData([]);
            } else if (modifireType == '2') {
                setModifier1CodeData([]);
            } else if (modifireType == '3') {
                setModifier1CodeData([]);
            } else if (modifireType == '4') {
                setModifier1CodeData([]);
            }
            return true;
        }
        setIsLoading(true);
        console.log(eventValue);
        var data = { 'search': eventValue };
        AWVCoderWorkBench.post('/data/modifier_code', data)
            .then(res => {
                let valuesArray = [];
                res.data.forEach((element, index) => {
                    if (!valuesArray.includes(element.code + '-' + element.description)) {
                        valuesArray.push(element.code + '-' + element.description);
                    }
                });
                if (modifireType == '1') {
                    setModifier1CodeData(valuesArray);
                    console.log(modifier1CodeData);
                } else if (modifireType == '2') {
                    setModifier2CodeData(valuesArray);
                } else if (modifireType == '3') {
                    setModifier3CodeData(valuesArray);
                } else if (modifireType == '4') {
                    setModifier4CodeData(valuesArray);
                }
                setIsLoading(false);
            })
            .catch(err => {
                let valuesArray = [];
                if (modifireType == '1') {
                    setModifier1CodeData(valuesArray);
                } else if (modifireType == '2') {
                    setModifier2CodeData(valuesArray);
                } else if (modifireType == '3') {
                    setModifier3CodeData(valuesArray);
                } else if (modifireType == '4') {
                    setModifier4CodeData(valuesArray);
                }
                setIsLoading(false);
            })
    }

    const handleChangeModifierCode = (eventValue, modifireType) => {
        if (modifireType == '1') {
            const result = modifier1CodeData.find((element) => element == eventValue);
            setValue('modifier_1', eventValue);
            setModifier1CodeValue(eventValue);
            if (result) {
                setIsModifier1CodeValue(true);
                setModifier1CodeData([]);
            }
        } else if (modifireType == '2') {
            const result = modifier2CodeData.find((element) => element == eventValue);
            setValue('modifier_2', eventValue);
            setModifier2CodeValue(eventValue);
            if (result) {
                setIsModifier2CodeValue(true);
                setModifier2CodeData([]);
            }
        } else if (modifireType == '3') {
            const result = modifier3CodeData.find((element) => element == eventValue);
            setValue('modifier_3', eventValue);
            setModifier3CodeValue(eventValue);
            if (result) {
                setIsModifier3CodeValue(true);
                setModifier3CodeData([]);
            }
        } else if (modifireType == '4') {
            const result = modifier4CodeData.find((element) => element == eventValue);
            setValue('modifier_4', eventValue);
            setModifier4CodeValue(eventValue);
            if (result) {
                setIsModifier4CodeValue(true);
                setModifier4CodeData([]);
            }
        }
    }

    const editLoadFormData = (id) => {
        setIsLoading(true);
        AWVCoderWorkBench.get('data/cw_proc_code/' + id)
            .then(res => {
                console.log(res.data);
                if (res.data && res.data) {
                    setEditFormData(res.data);
                    setIsLoading(false);
                } else {
                    setEditFormData();
                    setIsLoading(false);
                }
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
            })
    }

    const loadformDataTable = (id) => {
        setIsLoading(true);
        AWVCoderWorkBench.get('data/cwb/history/cwp_proc_code/' + id) //'&provider_group=' + props?.providerGroupSelect
            .then(res => {
                console.log(res.data);
                setFormDataList(res.data);
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    }

    const handleInputChange1 = (event) => {
        const newValue = event.target.value;

        // Allow only numeric characters
        if (/^\d*$/.test(newValue)) {
            setValue('modifier_1', newValue);
        }
    };

    const handleInputChange2 = (event) => {
        const newValue = event.target.value;

        // Allow only numeric characters
        if (/^\d*$/.test(newValue)) {
            setValue('modifier_2', newValue);
        }
    };

    const handleInputChange3 = (event) => {
        const newValue = event.target.value;

        // Allow only numeric characters
        if (/^\d*$/.test(newValue)) {
            setValue('modifier_3', newValue);
        }
    };

    const handleInputChange4 = (event) => {
        const newValue = event.target.value;

        // Allow only numeric characters
        if (/^\d*$/.test(newValue)) {
            setValue('modifier_4', newValue);
        }
    };


    const onSubmit = (data) => {
        console.log(data);
        let controlUser = JSON.parse(localStorage.getItem('user'));
        //data.last_updated_on = Moment().format('YYYY-MM-DD HH:mm:ss');
        data.last_updated_by = controlUser?.userEmail;
        if (props?.Id) {
            var apiUrl = 'data/cw_proc_code/' + props.Id;
        } else {
            var apiUrl = 'data/cw_proc_code/save';
            //data.created_on = Moment().format('YYYY-MM-DD HH:mm:ss');
            data.created_by = controlUser?.userEmail;
        }
        if (isdxCodeValue && dxCodeValue) {

        } else {
            data.proc_code = "";
            data.proc_description = "";
            toast.error('PROC CODE is Invalid Please select');
            return true;
        }

        if (ismodifier1CodeValue && modifier1CodeValue) {

        } else {
            data.modifier_1 = "";
            if (modifier1CodeValue && modifier1CodeValue != '') {
                toast.error('MODIFIER 1 is Invalid Please select');
                return true;
            }
        }

        if (ismodifier2CodeValue && modifier2CodeValue) {

        } else {
            data.modifier_2 = "";
            if (modifier2CodeValue && modifier2CodeValue != '') {
                toast.error('MODIFIER 2 is Invalid Please select');
                return true;
            }
        }

        if (ismodifier3CodeValue && modifier3CodeValue) {

        } else {
            data.modifier_3 = "";
            if (modifier3CodeValue && modifier3CodeValue != '') {
                toast.error('MODIFIER 3 is Invalid Please select');
                return true;
            }
        }

        if (ismodifier4CodeValue && modifier4CodeValue) {

        } else {
            data.modifier_4 = "";
            if (modifier4CodeValue && modifier4CodeValue != '') {
                toast.error('MODIFIER 4 is Invalid Please select');
                return true;
            }
        }

        data.page_no = data.page_no ? data.page_no : "";
        data.entity_id = data.entity_id ? data.entity_id : "";
        data.entity_text = data.entity_text ? data.entity_text : "";
        data.dos_begin = Moment(data.dos_begin).format("YYYY-MM-DD");
        data.dos_end = Moment(data.dos_end).format("YYYY-MM-DD");
        console.log(data);
        // return true;
        setFormSubmitisLoading(true);
        AWVCoderWorkBench.post(apiUrl, data)
            .then(res => {
                setValue('id', '');
                setValue('page_no', '');
                setValue('ih_member_id', '');
                setValue('proc_code', '');
                setValue('entity_id', '');
                setValue('entity_text', '');
                setValue('last_updated_on', '');
                setValue('last_updated_by', '');
                setValue('modifier_1', '');
                setValue('modifier_2', '');
                setValue('modifier_3', '');
                setValue('modifier_4', '');


                setFormSubmitisLoading(false);
                if (props?.Id) {
                    toast.success('Updated successfully');
                } else {
                    toast.success('Created successfully');
                }
                props.currentAWERightSidebarCloseDatatableReload(true);
                notificationRightDrawer(false);
            })
            .catch(err => {
                setFormSubmitisLoading(false);
                toast.error(err?.response?.data?.message);
            })
    }

    const handleChangeDate = (event) => {
        if (event?.target?.name == 'dos_begin') {
            setValue('dos_begin', event?.target?.value);
            setValue('dos_end', event?.target?.value);
            setDxCodeDOSBegin(event?.target?.value);
            handleManualValidation();
        }
    }
    const handleManualValidation = async () => {
        try {
          // Trigger manual revalidation for all fields
          await trigger();
        } catch (error) {
          //console.error('Manual validation error:', error);
        }
      };
      
    const handleKeyDown = (event) => {
        const options = document.querySelectorAll('.options li');
        const currentIndex = dxCodeData.indexOf(dxCodeValue);
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            if (currentIndex < dxCodeData.length - 1) {
                setDxCodeValues(dxCodeData[currentIndex + 1]);
                options[currentIndex + 1].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            if (currentIndex > 0) {
                setDxCodeValues(dxCodeData[currentIndex - 1]);
                options[currentIndex - 1].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        } else if (event.key === 'Enter') {
            if (currentIndex !== -1) {
                // Simulate click event on the currently selected option
                options[currentIndex].click();
                event.preventDefault(); // Prevent form submission if needed
            }
        }
    };

    return (
        <div className="diagnosis_code_sidebar offcanvas offcanvas-end show" tabIndex={-1} aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header flex_item_cb">
                <h5 id="offcanvasRightLabel" className='fs15'>{(props?.Id) ? 'Edit' : 'Add'}  CW PROC CODE Coder Workbench</h5>
                <Tooltip title="Close">
                    <IconButton type="button" buttonType={"default"} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => notificationRightDrawer(false)}>
                        <CloseIcon className="w24" />
                    </IconButton>
                </Tooltip>
            </div>
            <Divider />
            <div className="offcanvas-body pt-1 custom-scrollbar overflow-auto">
                <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">MEMBER ID</label>
                        <Controller
                            name="ih_member_id"
                            control={control}
                            render={({ field }) => (
                                <input type="text" {...field} className="form-control" readOnly />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.ih_member_id?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>

                    <div className="form-group mb20 custom-autocomplete">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">PROC CODE</label>
                        <Controller
                            name="proc_code"
                            control={control}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    value={dxCodeValue}
                                    onChange={(event) => handleInputChangeDxCode(event?.target?.value)}
                                    placeholder="Type to search..."
                                    autoComplete="off"
                                    className="form-control"
                                    onBlur={handleManualValidation}
                                    onKeyDown={handleKeyDown}
                                />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        <ul className="options custom-scrollbar">
                            {dxCodeData.map((option, index) => (
                                <li key={index} className={option === dxCodeValue ? 'selected' : ''} onClick={() => handleChangeDxCode(option)}>
                                    {option}
                                </li>
                            ))}
                        </ul>
                        {errors?.proc_code?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>

                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">DOS START</label>
                        <Controller
                            name="dos_begin"
                            control={control}
                            render={({ field }) => (
                                <input type="date" className="form-control" {...field} onChange={handleChangeDate} />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.dos_begin?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>

                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">DOS END</label>
                        <Controller
                            name="dos_end"
                            control={control}
                            render={({ field }) => (
                                <input type="date" {...field} className="form-control" min={Moment(dxCodeDOSBegin).format("YYYY-MM-DD")} />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.dos_end?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>

                    <div className="form-group mb20 custom-autocomplete">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">MODIFIER 1</label>
                        {/*
                        <Controller
                            name="modifier_1"
                            control={control}
                            defaultValue={modifier1CodeValue}
                            rules={{ required: true }}
                            render={({ field }) => (
                            <Autocomplete
                                {...field}
                                options={modifier1CodeData}
                                getOptionLabel={(option) => option}
                                
                                renderInput={(params) => (
                                <TextField
                                    {...params}
                                    
                                    onChange={(event) => handleInputChangeModifierCode(event?.target?.value, 1)}
                                    placeholder="Type to search..."
                                    autoComplete="off"
                                    className="form-control"
                                    onBlur={handleManualValidation}
                                    
                                />
                                )}
                            />
                            )}
                        />
                         */}
                        <Controller
                            name="modifier_1"
                            control={control}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    value={modifier1CodeValue}
                                    onChange={(event) => handleInputChangeModifierCode(event?.target?.value, 1)}
                                    placeholder="Type to search..."
                                    autoComplete="off"
                                    className="form-control"
                                    onBlur={handleManualValidation}
                                />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        <ul className="options custom-scrollbar">
                            {modifier1CodeData && modifier1CodeData.map((option, index) => (
                                <li key={index} onClick={() => handleChangeModifierCode(option, 1)}>
                                    {option}
                                </li>
                            ))}
                        </ul>
                       
                        {errors?.modifier_1?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>

                    <div className="form-group mb20 custom-autocomplete">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">MODIFIER 2</label>
                        <Controller
                            name="modifier_2"
                            control={control}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    value={modifier2CodeValue}
                                    onChange={(event) => handleInputChangeModifierCode(event?.target?.value, 2)}
                                    placeholder="Type to search..."
                                    autoComplete="off"
                                    className="form-control"
                                    onBlur={handleManualValidation}
                                />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        <ul className="options custom-scrollbar">
                            {modifier2CodeData && modifier2CodeData.map((option, index) => (
                                <li key={index} onClick={() => handleChangeModifierCode(option, 2)}>
                                    {option}
                                </li>
                            ))}
                        </ul>
                        {errors?.modifier_2?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>

                    <div className="form-group mb20 custom-autocomplete">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">MODIFIER 3</label>
                        <Controller
                            name="modifier_3"
                            control={control}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    value={modifier3CodeValue}
                                    onChange={(event) => handleInputChangeModifierCode(event?.target?.value, 3)}
                                    placeholder="Type to search..."
                                    autoComplete="off"
                                    className="form-control"
                                    onBlur={handleManualValidation}
                                />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        <ul className="options custom-scrollbar">
                            {modifier3CodeData && modifier3CodeData.map((option, index) => (
                                <li key={index} onClick={() => handleChangeModifierCode(option, 3)}>
                                    {option}
                                </li>
                            ))}
                        </ul>
                        {errors?.modifier_3?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>

                    <div className="form-group mb20 custom-autocomplete">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">MODIFIER 4</label>
                        <Controller
                            name="modifier_4"
                            control={control}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    value={modifier4CodeValue}
                                    onChange={(event) => handleInputChangeModifierCode(event?.target?.value, 4)}
                                    placeholder="Type to search..."
                                    autoComplete="off"
                                    className="form-control"
                                    onBlur={handleManualValidation}
                                />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        <ul className="options custom-scrollbar">
                            {modifier4CodeData && modifier4CodeData.map((option, index) => (
                                <li key={index} onClick={() => handleChangeModifierCode(option, 4)}>
                                    {option}
                                </li>
                            ))}
                        </ul>
                        {errors?.modifier_4?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Status</label>
                        <Controller
                            name="cw_proc_status"
                            control={control}
                            render={({ field }) => (
                                <select
                                    {...field}
                                    className="form-control"
                                >
                                    <option value={''} >
                                        Select
                                    </option>
                                    <option value={'Accepted'} >
                                        Accepted
                                    </option>
                                    <option value={'Rejected'} >
                                        Rejected
                                    </option>
                                    <option value={'Pending for clarification'} >
                                        Pending for Clarification
                                    </option>
                                    <option value={'No Action'} >
                                        No Action
                                    </option>
                                </select>
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.cw_proc_status?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">REMARK</label>
                        <Controller
                            name="remarks"
                            control={control}
                            render={({ field }) => (
                                <textarea rows={3} {...field} className="form-control" />
                            )}
                            rules={{
                                required: false,
                            }}
                        />
                        {errors?.remarks?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group flex_item_cs gap10">
                        <Button type='button' className={"btn-white minw80"} onClick={() => notificationRightDrawer(false)}>CANCEL</Button>
                        <Button type='submit' className={"minw80"} disabled={formSubmitisLoading && formSubmitisLoading == true ? true : false}>SAVE</Button>
                    </div>
                </form>
                {isLoading ?
                    <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        {formDataList && formDataList.length > 0 ?
                            <div className="">
                                <h5 className="fs18 mb10">History</h5>
                                <Divider />
                                <div className="history_card_list">
                                    {formDataList && formDataList.map((element, index) =>
                                        <div key={index} className={
                                            element.form_status === 'Accepted' ? 'history_card_item status-primary' :
                                                element.form_status === 'Rejected' ? 'history_card_item status-danger' :
                                                    element.form_status === 'No Action' ? 'history_card_item status-light' :
                                                        element.form_status === 'Pending for clarification' ? 'history_card_item status-warning' :
                                                            'history_card_item status-primary'
                                        }>
                                            <p className="fs12 mb-1 fw700">
                                                {Moment(element.created_on).format('h:mm a, MMMM DD YYYY')}
                                            </p>
                                            <p className="fs12 mb-1">
                                                <span className="fw700">Status :-</span>
                                                {element.form_status}
                                            </p>
                                            <p className="fs12 mb-1">
                                                <span className="fw700">Remarks :-</span>
                                                {element.remarks}
                                            </p>
                                            <p className="fs12 mb-1">
                                                <span className="fw700">Last updated by :-</span>
                                                {element.user_id}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            :
                            <></>
                        }
                    </>
                }
            </div>

        </div >
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(EditCWPROCCODECoderWorkBench);

