import React, { useEffect, useRef, useState } from 'react'
import HeaderFilter from '../../../components/layout/aweLayout/HeaderFilter'
import QueryLetter from '../../../assets/constants/QueryLetter.Instance';
import SubHeaderFilter from '../../../components/layout/aweLayout/SubHeaderFilter'
import IconButton from '../../../components/CustomButtons/IconButton'
import EditPenIcon from '../../../components/icons/EditPenIcon';
import EyeIcon from '../../../components/icons/EyeIcon';
import PinIcon from '../../../components/icons/PinIcon';
import ListBoxIcon from '../../../components/icons/ListBoxIcon';
import PlusFileIcon from '../../../components/icons/PlusFileIcon';
import BigArrowRightIcon from '../../../components/icons/BigArrowRightIcon';
import Button from '../../../components/layout/aweLayout/Button';
import FlagIcon from '../../../components/icons/FlagIcon';
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { connect } from 'react-redux'
import Divider from '@mui/material/Divider';
import history from "../../../history"
import { currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload, currentAWERightSidebarType } from '../../../store/actions'
import { MenuItem, Select, LinearProgress } from '@mui/material'
import CustomAweDatatable from '../../../components/TableGrids/CustomAweDatatable'
import AWVApi from '../../../assets/constants/AWVRafservice.Instance';
import StatusBadge from '../../../components/layout/aweLayout/StatusBadge';
import Moment from 'moment';
import Menu from '@mui/material/Menu';
import { toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AttachmentIcon from '@mui/icons-material/Attachment';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FormControlLabel from '@mui/material/FormControlLabel';
import DotsMenuIcon from '../../../components/icons/DotsMenuIcon';
import AWVCoderWorkBench from '../../../assets/constants/AWVCoderWorkBenchRafservice.Instance';
import { NavLink, useLocation } from "react-router-dom";
import QueryLetterEditCoderWorkBench from '../../../components/Dialog/QueryLetterEditCoderWorkBench';



const QueryLetterSummary = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [queryLetterStats, setQueryLetterStats] = useState([]);
    const [openEditQueryLetter, setOpenEditQueryLetter] = useState(false);
    const [editQueryLetterData, setEditQueryLetterData] = useState('');
    const [queryLetterFilter, setQueryLetterFilter] = useState('');
    const location = useLocation();

    useEffect(() => {
        loadGetQueryState();
    }, [props.yearSelect, props.providerGroupSelect, props.lobSelect]);

    const handleEditQueryLetterOpen = (editData) => {
        setOpenEditQueryLetter(true);
        setEditQueryLetterData(editData);
    };

    const handleEditQueryLetterClose = () => {
        setOpenEditQueryLetter(false);
        props.currentAWERightSidebarCloseDatatableReload(true);
    };

    const loadGetQueryState = () => {
        setIsLoading(true);
        var queryStr = '';
        if (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL') {
            queryStr = '&provider_group=' + props.providerGroupSelect;
        }
        QueryLetter.get('/get_query_letter_stats?year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + queryStr) //'&provider_group=' + props?.providerGroupSelect
            .then(res => {
                setQueryLetterStats(res.data);
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    }

    const queryLetterFilterFunction = (filterData) => {
        // console.log({ filterData });
        if (filterData && filterData != queryLetterFilter) {
            setQueryLetterFilter(filterData);
        } else {
            setQueryLetterFilter('');
        }
    };

    const aweRecordColumns = [
        {
            name: "ql_id",
            label: "Action",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: false,
                width: '130px',
                otherColumnName: 'all',
                customBodyRender: (value, otherColumnValueArray = '') => {
                    return (
                        <>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <div className="flex_item_cc gap10">
                                    {otherColumnValueArray?.status && otherColumnValueArray?.status == 'InProgress' ?
                                        <div className="dotsAnimation"></div>
                                        :
                                        <div className="mr-2"></div>
                                    }
                                    <div>
                                        <NavLink
                                            to={{
                                                pathname: '/new_awe/awe-coder-workbench',
                                                search: '?cin=' + otherColumnValueArray?.cin,
                                                state: {
                                                    awe_id: otherColumnValueArray?.awe_id,
                                                    hcc_v28_list: '',
                                                    year: props.yearSelect,
                                                    lob: props.lobSelect,
                                                    awe_display_id: otherColumnValueArray?.awe_id,
                                                    chart_chase_record: '',
                                                    is_cw_present: '',
                                                    cw_id: ''
                                                }
                                            }}
                                        >
                                            <IconButton className={"text-primary cw-icon-design"} buttonType={"default"}>
                                                CW
                                            </IconButton>
                                        </NavLink>
                                    </div>
                                    <div>
                                        <a role="button" onClick={() => handleEditQueryLetterOpen(otherColumnValueArray)} className="hyperlink" style={{ cursor: "pointer", color: "#209f9d" }}>
                                            <EditPenIcon className="w24 h24" />
                                        </a>
                                    </div>
                                </div>
                            </Box>
                        </>
                    );
                }
            }
        },
        {
            name: "cin",
            label: "CIN/RXID",
            options: {
                showColumn: true,
                filter: true,
                filterType: '',
                search: true,
                sort: true,
                width: '180px'
            }
        },
        {
            name: "awe_id",
            label: "AWE ID",
            options: {
                showColumn: true,
                filter: true,
                filterType: '',
                search: true,
                sort: true,
                width: '180px'
            }
        },

        {
            name: "provider_name",
            label: "Provider",
            options: {
                showColumn: true,
                filter: true,
                filterType: '',
                search: true,
                sort: true,
                width: '202px',
                otherColumnName: 'all',
                customBodyRender: (value, otherColumnValueArray = '') => {
                    return (
                        value && value != '' ?
                            <span >{value}</span>
                            : otherColumnValueArray?.provider_name_other && otherColumnValueArray?.provider_name_other != '' ?
                                <span >{otherColumnValueArray?.provider_name_other}</span>
                                :
                                <></>
                    );
                    <></>
                }
            }
        },

        {
            name: "attempt",
            label: "Attempt",
            options: {
                showColumn: true,
                filter: true,
                filterType: '',
                search: true,
                sort: true,
                width: '150px'
            }
        },
        {
            name: "date_of_service",
            label: "DOR",
            options: {
                showColumn: true,
                filter: true,
                filterType: 'date',
                search: true,
                sort: true,
                width: '180px',
                customBodyRender: (value) => {
                    return (
                        value && value != '' ?
                            <span >{Moment(value).format("MM-DD-YYYY")}</span>
                            :
                            <></>
                    );
                }
            }
        },
        {
            name: "dob",
            label: "DOB",
            options: {
                showColumn: true,
                filter: true,
                filterType: 'date',
                search: true,
                sort: true,
                width: '202px',
                customBodyRender: (value) => {
                    return (
                        value && value != '' ?
                            <span >{Moment(value).format("MM-DD-YYYY")}</span>
                            :
                            <></>
                    );
                    <></>
                }
            }
        },
        {
            name: "status",
            label: "STATUS",
            options: {
                showColumn: true,
                filter: true,
                filterType: 'selectCustom',
                search: true,
                sort: true, // Enable sorting for this column
                width: '202px',
                optionList: [
                    { text: "All", value: "" },
                    { text: "Closed", value: "Closed" },
                    { text: "Not Applicable", value: "NotApplicable" },
                    { text: "In Progress", value: "InProgress" }
                ],
                customBodyRender: (value) => {
                    let statusBadgeProps = { status: "Open", text: "Open" };
                    if (value === 'Closed') statusBadgeProps = { status: "green", text: "Closed" };
                    else if (value === 'NotApplicable') statusBadgeProps = { status: "light", text: "Not Applicable" };
                    else if (value === 'InProgress') statusBadgeProps = { status: "yellow", text: "In Progress" };
                    return <StatusBadge {...statusBadgeProps} />;
                },
                customSortValue: (value) => {
                    // Define a custom sorting function
                    // Map the original data values to their sorting order
                    const sortOrder = {
                        'Closed': 1,
                        'NotApplicable': 2,
                        'InProgress': 3,
                        '': 4, // Consider empty values as lowest priority
                        null: 4,
                    };
                    return sortOrder[value] || 5; // Return a default value if not found in sortOrder
                }
            }
        }
        ,
        {
            name: "action_taken_on",
            label: "Created On",
            options: {
                showColumn: true,
                filter: true,
                filterType: 'date',
                search: true,
                sort: true,
                width: '202px',
                customBodyRender: (value) => {
                    return (
                        value && value != '' ?
                            <span >{Moment(value).format("MM/DD/YYYY")}</span>
                            :
                            <></>
                    );
                }
            }
        },
        {
            name: "subject",
            label: "Recipient",
            options: {
                showColumn: true,
                filter: true,
                filterType: '',
                search: true,
                sort: true,
                width: '202px'
            }
        },
    ];

    return (
        <>
            <div className="card h-auto mb20">
                <div className="card-body annual_wellness_filter">
                    <HeaderFilter
                        title={'QUERY LETTER SUMMARY'}
                    />
                    <div className="row pr-3 pl-3">
                        <div className='col-md-2 p-0 text-center '>
                            <span className="fs18 font-weight-bold" style={{ color: "#1a9698" }}> Total Queries</span> <br />
                            <span >
                                <Divider className="mt-2" style={{ backgroundColor: "#1a9698", borderRadius: "4px", paddingTop: "10px", cursor: "pointer" }} onClick={() => queryLetterFilterFunction('totalQueryLetter')} />
                            </span>
                            <span className='mt-1' style={{ fontSize: '16px' }}></span> <br />
                            <span className='mt-2 font-weight-bold' style={{ fontSize: '30px', cursor: "pointer" }} onClick={() => queryLetterFilterFunction('totalQueryLetter')}>
                                {queryLetterFilter && queryLetterFilter == 'totalQueryLetter' ?
                                    <span style={{ color: "#1a9698" }}>{queryLetterStats?.totalQueryLetter}</span>
                                    :
                                    queryLetterStats?.totalQueryLetter
                                }
                            </span>
                        </div>
                        <div className='col-md-8 p-0 text-center'>
                            <span className="fs18 font-weight-bold" style={{ color: "#FF5367" }}>In Progress Queries</span> <br />
                            <div className="row p-0 mt-2">
                                <div className='col-md-3 pr-0 pt-0 pb-0'>
                                    <span >
                                        <Divider className="" style={{ backgroundColor: "#FF5367", borderRadius: "4px", paddingTop: "10px", marginLeft: "5px", cursor: "pointer" }} onClick={() => queryLetterFilterFunction('queryLetter_90Days')} />
                                    </span>
                                    <span className='mt-1' style={{ fontSize: '16px' }}>{">90 Days"}</span> <br />
                                    <span className='mt-1 font-weight-bold' style={{ fontSize: '30px', cursor: "pointer" }} onClick={() => queryLetterFilterFunction('queryLetter_90Days')}>
                                        {queryLetterFilter && queryLetterFilter == 'queryLetter_90Days' ?
                                            <span style={{ color: "#FF5367" }}>{queryLetterStats?.queryLetter_90Days}</span>
                                            :
                                            queryLetterStats?.queryLetter_90Days
                                        }
                                    </span>
                                </div>
                                <div className='col-md-3 p-0'>
                                    <span >
                                        <Divider className="" style={{ backgroundColor: "#FF7182", borderRadius: "4px", paddingTop: "10px", marginLeft: "5px", cursor: "pointer" }} onClick={() => queryLetterFilterFunction('queryLetter_60to90Days')} />
                                    </span>
                                    <span className='mt-1' style={{ fontSize: '16px' }}>{"60-90 Days"}</span> <br />
                                    <span className='mt-1 font-weight-bold' style={{ fontSize: '30px', cursor: "pointer" }} onClick={() => queryLetterFilterFunction('queryLetter_60to90Days')}>
                                        {queryLetterFilter && queryLetterFilter == 'queryLetter_60to90Days' ?
                                            <span style={{ color: "#FF7182" }}>{queryLetterStats?.queryLetter_60to90Days}</span>
                                            :
                                            queryLetterStats?.queryLetter_60to90Days
                                        }
                                    </span>
                                </div>
                                <div className='col-md-3 p-0'>
                                    <span >
                                        <Divider className="" style={{ backgroundColor: "#FF8896", borderRadius: "4px", paddingTop: "10px", marginLeft: "5px", cursor: "pointer" }} onClick={() => queryLetterFilterFunction('queryLetter_30to60Days')} />
                                    </span>
                                    <span className='mt-1' style={{ fontSize: '16px' }}>{"30-60 Days"}</span> <br />
                                    <span className='mt-1 font-weight-bold' style={{ fontSize: '30px', cursor: "pointer" }} onClick={() => queryLetterFilterFunction('queryLetter_30to60Days')}>
                                        {queryLetterFilter && queryLetterFilter == 'queryLetter_30to60Days' ?
                                            <span style={{ color: "#FF8896" }}>{queryLetterStats?.queryLetter_30to60Days}</span>
                                            :
                                            queryLetterStats?.queryLetter_30to60Days
                                        }
                                    </span>
                                </div>
                                <div className='col-md-3 pl-0 pt-0 pb-0'>
                                    <span >
                                        <Divider className="" style={{ backgroundColor: "#FFB0B9", borderRadius: "4px", paddingTop: "10px", marginLeft: "5px", cursor: "pointer" }} onClick={() => queryLetterFilterFunction('queryLetter_30Days')} />
                                    </span>
                                    <span className='mt-1' style={{ fontSize: '16px' }}>{"<30 Days"}</span> <br />
                                    <span className='mt-1 font-weight-bold' style={{ fontSize: '30px', cursor: "pointer" }} onClick={() => queryLetterFilterFunction('queryLetter_30Days')}>
                                        {queryLetterFilter && queryLetterFilter == 'queryLetter_30Days' ?
                                            <span style={{ color: "#FFB0B9" }}>{queryLetterStats?.queryLetter_30Days}</span>
                                            :
                                            queryLetterStats?.queryLetter_30Days
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-2 p-0 text-center'>
                            <span className="fs18 font-weight-bold" style={{ color: "#536CEB" }}>Closed Queries</span> <br />
                            <span >
                                <Divider className="mt-2" style={{ backgroundColor: "#536CEB", borderRadius: "4px", paddingTop: "10px", marginLeft: "5px", cursor: "pointer" }} onClick={() => queryLetterFilterFunction('closedQueryLetter')} />
                            </span>
                            <span className='mt-1' style={{ fontSize: '16px' }}></span> <br />
                            <span className='mt-2 font-weight-bold' style={{ fontSize: '30px', cursor: "pointer" }} onClick={() => queryLetterFilterFunction('closedQueryLetter')}>
                                {queryLetterFilter && queryLetterFilter == 'closedQueryLetter' ?
                                    <span style={{ color: "#536CEB" }}>{queryLetterStats?.closedQueryLetter}</span>
                                    :
                                    queryLetterStats?.closedQueryLetter
                                }
                            </span>
                        </div>
                    </div>

                    <Divider className="mt-3 mb-3" />
                    <CustomAweDatatable
                        titleName=""
                        downloadFileName="awe_recod_details"
                        UrlAxios={'QueryLetter'}
                        urlLink={`/get_query_details?year=${props.yearSelect}&organisation_id=1&lob=${props.lobSelect}&query_letter=${queryLetterFilter}${(props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL') ? '&provider_group=' + props.providerGroupSelect : ''}`}
                        columns={aweRecordColumns}
                        showColumnBtn={true}
                        searchBox={true}
                        downloadBtn={false}
                        pagination={true}
                        filterBox={true}
                        colSpan={9}
                    />
                </div>
            </div>
            {editQueryLetterData && editQueryLetterData.awe_id != '' ?
                <QueryLetterEditCoderWorkBench
                    queryLetterList={true}
                    open={openEditQueryLetter}
                    handleClose={handleEditQueryLetterClose}
                    aweID={editQueryLetterData?.awe_id}
                    cinId={editQueryLetterData?.cin}
                />
                :
                <></>
            }

        </>
    )
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        lobSelect: state.moduleFilter.lobSelect,
        aweRightSidebarCloseDatatableReload: state.moduleFilter.aweRightSidebarCloseDatatableReload,
        aweSiteSelect: state.moduleFilter.aweSiteSelect,
        aweProviderGroupingSelect: state.moduleFilter.aweProviderGroupingSelect,
        citySelect: state.moduleFilter.citySelect,
        aweAddressSelect: state.moduleFilter.aweAddressSelect,
        aweZipSelect: state.moduleFilter.aweZipSelect,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(QueryLetterSummary);