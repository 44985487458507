import { RAF_RANGE,MEMBER_ID ,HCC} from "../../assets/constants/string.constants";
import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import CardComponent from "../../components/Cards/card";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag, hccSeperator, numberWithCommas,addSuffixTwoPercent,numberWithCommasPrefixDolar } from '../../assets/constants/customRender';
import history from "../../history";
const PredictionHccDrillDown = () => {

    const hccs = useState(localStorage.getItem(HCC));
    // need to check why it is retuning an arry
    const rafRange = useState(localStorage.getItem(RAF_RANGE));
    // need to check why it is retuning an arry
    let rafDRange = rafRange[0];
    let hcc = hccs[0];      

    const  memberMedicareCols =[
        {
            name: "MemberID",
            label: "Member ID",
            options:anchorTag
        },
        {
            name: "DELTA_RAF_SCORE",
            label: "Potential RAF Score",
            options:addSuffixTwoPercent
        },
        {
            name: "NUMBER_OF_CONDITION_CATEGORIES",
            label: "No of Conditions"
        },
     
        {
            name: "DELTA_REVENUE_IMPACT",
            label: "Total Revenue Impact",
            options:numberWithCommasPrefixDolar
        },

        
    ];
    const memberTableClick = (rowdata, colData, cellMeta) =>{
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
           localStorage.setItem(MEMBER_ID, rowdata[cellMeta.rowIndex].MemberID);
            history.push('/layout/medicare/PredictionMemberSummary')
        }
    }

return(
    <div className="level-summary">
    <div className="row pb-2">
      <div className="col-lg-12">
        <div className="state-level">
          <div className="card-header">
            <span className="d-flex bd-highlight">
              <h6 className="pt-2 flex-grow-1">RAF DRILL DOWN FOR HCC</h6>
              <div className="d-flex align-items-center"></div>
            </span>
          </div>
        </div>
      </div>
    </div> 
 <div className='col-lg-12 pr-0 pl-0'>
 <div className="list-member mt-4">
     <CardComponent
         heading={`List of Members with Condition : ${hcc} and RAF Range of : ${rafDRange}  `}
         body={
                 <ServerSideGridTable
                 //PredictionRafDrillDownHcc/hcc/HCC_188/rafRange/(0.486-0.972)/
                     url={`/PredictionRafDrillDownHcc/hcc/${hcc}/rafRange/${rafDRange}/`}
                     columns={memberMedicareCols}
                     tableClick={memberTableClick}
                 ></ServerSideGridTable>
             }>
     </CardComponent>
 </div>
 <br/>
                <br/> 
</div>
</div>
);

};
export default PredictionHccDrillDown;
