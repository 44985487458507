import React, { useState, useEffect } from "react";
import { WIDGET_TYPES } from "../../assets/constants/string.constants";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { connect } from 'react-redux';
import GridTable from "../../components/TableGrids/ETLGrid/GridTable.table";
import CardComponent from "../../components/Cards/card";
import SlickSlider from '../../components/SlickSlider/ETLSlickSlider';
import AccountBalanceTwoToneIcon from '@material-ui/icons/FolderOpen';
import { downloadButton } from "../../assets/constants/customRender";
import AWS from 'aws-sdk';
import HeaderForETL from '../../components/home/header/HeaderForETL';
import { ByteConverter } from "../../assets/constants/customRender";
import axios from "axios";
const aws = require('aws-sdk');
    
    //const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
    const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME_Two;
    const REGION =process.env.REACT_APP_REGION;
    const ACCESS_KEY =localStorage.getItem("access_key");
    const SECRET_ACCESS_KEY =localStorage.getItem("secret_key");

    const FileDownload = (props) => {
        
    const [WidgetDetails, setWidgetDetails] = useState({});
    const [tableData, setTableData] = useState([]);
    const [Count, setCount] = useState(1);
   
    let sliderRangeInt = 0;

    const WidgetDetailsType = [
        WIDGET_TYPES.LOCKICON,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.PERCENT,
        WIDGET_TYPES.SCORE,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.TRENDING,
        WIDGET_TYPES.PERCENT,
    ]

    const type = WidgetDetailsType;
    const widgetStyles = {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.15,
        color: "white"
    };

    sliderRangeInt = parseInt(sliderRangeInt);
    const loadData = () =>{
    (async function() {
      const setTableDataList =[]
        try{
          aws.config.update({
            accessKeyId: ACCESS_KEY,
            secretAccessKey: SECRET_ACCESS_KEY,
            region: REGION
          });
      
          const s3d = new aws.S3();
          const response = await s3d.listObjectsV2({
            Bucket: S3_BUCKET,
          }).promise();
          const json_format = JSON.stringify(response.Contents)
          const stringify = JSON.parse(json_format)

          for (var i = 0; i < stringify.length; i++) {
            if(stringify[i]['Size']!=0){
              setTableDataList.push(stringify[i]);
            }
          }
          setTableData(setTableDataList)
          setCount(setTableDataList.length);
        } catch(e) {
            console.log('our error ',e)
        }
      })();
    }

    const loadFolderCount = () => {
            setWidgetDetails({
                '#Files': Count
            });
        sliderRangeInt = 1;
    };

    const getIcon = (type) => {
      var data = ''
      switch (type) {
        case Count:
            data = <AccountBalanceTwoToneIcon style={widgetStyles} />
            break;
      }
      return data;
    }

    var credentials = {
      accessKeyId: localStorage.getItem("access_key"),
      secretAccessKey : localStorage.getItem("secret_key")
    };

    AWS.config.update({credentials: credentials, region: process.env.REACT_APP_REGION, signatureVersion: "v4"});
    var s3 = new AWS.S3({
      signatureVersion: "v4"});
    
    const downloadClick = (rowdata, colData, cellMeta) => {
    if (cellMeta.colIndex === 3 && colData.props.children != "") {
      let row = cellMeta.rowIndex;
      let Key = rowdata[row].Key;
      var keyWithSubString= '';

      s3.setupRequestListeners = (request) => {
        request.on('build', (req) => {
          
          req.httpRequest.headers['x-amz-date'] = process.env.REACT_APP_KeyID;
        });
      }
      var presignedGETURL  = s3.getSignedUrl('getObject', {
           Bucket: S3_BUCKET,
           Key: Key
          });
          if(Key.includes('/')){
             keyWithSubString = Key.substr(((Key).lastIndexOf('/')))   
          } else {
            keyWithSubString =Key
          }
          axios({
            url: presignedGETURL,
            responseType: "arraybuffer",
            method: "GET",
          }).then((res) =>{
        
          let file = new Blob([res.data], {
            //type: "application/pdf",
          });
         
          let fileURL = URL.createObjectURL(file);
          let a = document.createElement("a");
          a.href = fileURL;
          a.target = "_blank";
          a.download = keyWithSubString;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        
        }).catch((err) => {console.log("Error while downloading file...")})
    }
  };

  const GapRecoCols = [
    {
        label: 'Folder/File Name',
        name: 'Key',
    },
    {
        label: 'Last Modified / Accessible Date',
        name: 'LastModified',
    },
    {
        label: 'Size',
        name: 'Size',
        options: ByteConverter
    },
    {
        label: 'Download',
        name: 'Download',
        options: downloadButton,
    }
];

useEffect(() => { 
    loadData();
   
},[]);
useEffect(() => {
    loadFolderCount();
},[Count]);

    return (
       <div>
            <div className="card-header">
            <HeaderForETL heading={'File Download'} />
                <SlickSlider WidgetDetails={WidgetDetails} getIcon={getIcon} type={type} />
                <CardComponent
                    body={
                    <div style={{'marginBottom':'4%'}}>
                    <GridTable
                     count={Count}
                    data={tableData}
                    columns={GapRecoCols}
                    tableClick={downloadClick}
                   >
                    </GridTable>
                    </div>
                    }></CardComponent>
            </div>  
        </div>
                    
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
    };
  }
  export default connect(mapStateToProps)(FileDownload);
