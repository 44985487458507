import React, { useState, useEffect } from "react";
import adminApi from '../../assets/constants/AdminRafservice.Instance';
import { connect } from 'react-redux';
import { dateTimeAdminFormatRender, anchorTagEditMode, statusToFormatRender } from '../../assets/constants/customRender'
import AdminSubHeader from '../../components/home/header/adminSubHeader';
import CardComponent from "../../components/Cards/card";
import ExpandableGridTable from "../../components/TableGrids/ExpandableGridTable.table";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import AdminSlider from '../../components/home/header/adminSlider';
import AddMenu from '../../components/Dialog/AddMenu';
import ViewMenu from '../../components/Dialog/ViewMenu';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { purple, red } from '@mui/material/colors';
import Swal from 'sweetalert2';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';

const MenuPage = (props) => {
    const [menuData, setMenuData] = useState([]);
    const [open, setOpen] = useState(false);
    const [viewOpen, setViewOpen] = useState(false);
    const [editMenuData, setEditMenuData] = useState({});

    const menuColumn = [
        {
            name: "title",
            label: "Name",
        },
        {
            name: "menu_slug",
            label: "Menu Slug",
        },
        {
            name: "module",
            label: "Module Name",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            {value.title}
                        </>
                    );
                }
            }
        },
        {
            name: "status",
            label: "Status",
            options: statusToFormatRender
        },
        {
            name: "createdAt",
            label: "Created At",
            options: dateTimeAdminFormatRender
        },
        {
            name: "updatedAt",
            label: "Updated At",
            options: dateTimeAdminFormatRender
        },
        {
            name: "id",
            label: "Action",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <>
                            <Tooltip title="View Menu"><a className="hyperlink" style={{ cursor: "pointer" }} onClick={() => handleClickViewOpen(value)}><VisibilityIcon color="primary" /></a></Tooltip>
                            <Tooltip title="Edit Menu"><a className="hyperlink" style={{ cursor: "pointer" }} onClick={() => handleClickOpen(value)}><ModeEditOutlineIcon color="primary" /></a></Tooltip>
                            <Tooltip title="Delete Menu"><a className="hyperlink" style={{ cursor: "pointer" }} onClick={() => handleClickDelete(value)}><DeleteRoundedIcon sx={{ color: red[500] }} /></a></Tooltip>
                        </>
                    );
                }
            }
        },
    ];

    useEffect(() => {
        loadMenuData();
    }, [
    ]);

    const handleClickViewOpen = (value) => {
        setViewOpen(true);
        editLoadMenuData(value);
    };

    const handleViewClose = () => {
        setViewOpen(false);
        setEditMenuData({});
    };

    const handleClickOpen = (value = '') => {
        setOpen(true);
        if (value && value != '') {
            editLoadMenuData(value)
        } else {
            setEditMenuData({});
        }
    };

    const handleClose = () => {
        setOpen(false);
        setEditMenuData({});
    };

    const handleClickDelete = (value) => {
        if (value && value != '') {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this record!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                confirmBtnBsStyle: "success",
                cancelButtonText: 'No, cancel!',
                cancelBtnBsStyle: "danger",
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    adminApi.delete('/menu/' + value)
                        .then(res => {
                            Swal.fire({
                                title: 'Deleted!',
                                text: 'Your record has been deleted.',
                                icon: 'success',
                                customClass: {
                                    confirmButton: 'btn btn-success',
                                    cancelButton: 'btn btn-danger',
                                }
                            })
                            loadMenuData();
                        })
                        .catch(err => {
                            // console.log(err)
                        })
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire({
                        title: 'Cancelled',
                        text: 'Your record is safe :)',
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-danger',
                            cancelButton: 'btn btn-danger',
                        }
                    })
                }
            })
        }
    };

    const editLoadMenuData = (value) => {
        adminApi.get('/menu/' + value)
            .then(res => {
                setEditMenuData(res.data);
            })
            .catch(err => {
                // console.log(err)
            })
    }

    const loadMenuData = () => {
        adminApi.get('/menu')
            .then(res => {
                setMenuData(res.data);
            })
            .catch(err => {
                // console.log(err)
            })
    }

    return (
        <div className="container level-summary">
            <div className="row">
                <AdminSubHeader title={'Menus'} />
                <div className='col-lg-12 pr-0 pl-0'>
                    <div className="list-member mt-4">
                        <CardComponent
                            heading={`Menus`}
                            body={
                                <>
                                    <div>
                                        <AddMenu loadMenuData={loadMenuData} editMenuData={editMenuData} open={open} handleClickOpen={handleClickOpen} handleClose={handleClose} />
                                        <ViewMenu loadMenuData={loadMenuData} editMenuData={editMenuData} open={viewOpen} handleClickOpen={handleClickViewOpen} handleClose={handleViewClose} />
                                    </div>
                                    <ExpandableGridTable
                                        data={menuData}
                                        columns={menuColumn}
                                        pagination={true}
                                    ></ExpandableGridTable>
                                </>
                            }></CardComponent>
                    </div>
                </div>
            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
    };
}
export default connect(mapStateToProps)(MenuPage);
