import React, { useState, useEffect } from 'react';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import AWVApi from '../../assets/constants/AWVRafservice.Instance';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import Divider from '@mui/material/Divider';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import AWVServerSideGridTable from "../TableGrids/AWVServerSideGridTable.table";
import Box from '@mui/material/Box';
import pdfIcon from '../../assets/images/pdf_icon.png';
import Moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import AWS from 'aws-sdk';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FilePresentIcon from '@mui/icons-material/FilePresent';

const aws = require('aws-sdk');

const S3_BUCKET = process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET; //process.env.REACT_APP_BUCKET_NAME_Two;
const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = localStorage.getItem("access_key");
const SECRET_ACCESS_KEY = localStorage.getItem("secret_key");


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const styles = (theme) => ({
    dialogPaper: {
        maxWidth: '80%',
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const StatusAWVFileUpload = (props) => {
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const { register: registerHcc, formState: { errors: errorsHcc }, handleSubmit: handleSubmitHcc, reset: resetHcc, control: controlHcc, setValue: setValueHcc } = useForm();
    const [year, setYear] = useState(props.yearSelect);
    const [open, setOpen] = React.useState();
    const [isLoading, setIsLoading] = useState(false);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const [fileUrl, setFileUrl] = useState();
    const [formDataList, setFormDataList] = useState([]);
    const [rejectionCodeList, setRejectionCodeList] = useState([]);
    const [editDetailsGridShow, setEditDetailsGridShow] = useState(false);
    const [editHcccDetailsIndex, setEditHcccDetailsIndex] = useState('');
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    useEffect(() => {
        if (props?.editFormData?.awv_id && props?.editFormData?.awv_id != '') {
            loadformDataTable();
        }
    }, [props?.editFormData?.awv_id]);

    useEffect(() => {
        loadRejectionCode();
        let controlUser = JSON.parse(localStorage.getItem('controlUser'));
        // awvid, action_status(1 -approved, 2- rejected, 3- void), createdOn, form_status(integer form) , lob, provider_Id, user_id, role, remark
        if (props.modelStatus && props.modelStatus == true) {
            setValue('action_status', 2);
            setValue('role', 2);
        } else {
            setValue('action_status', 1);
            if (props.modelFormRole && props.modelFormRole != '') {
                setValue('role', props.modelFormRole);
            } else {
                setValue('role', 1);
            }
        }


        setValue('reason_code', '');
        setValue('awv_id', props?.editFormData?.awv_id);
        setValue('createdOn', props?.editFormData?.created_on);
        setValue('lob', props?.lobSelect);
        setValue('user_id', controlUser?.user_id);

        setValue('provider_id', props?.editFormData?.user_id);
        setValue('pos_name', props?.editFormData?.POS);
        setValue('form_status', props?.modelFormStatus);
        setEditDetailsGridShow(false);
        setEditHcccDetailsIndex('');


        if (props.open && props.editFormData?.form_url && props.editFormData?.form_url != '') {
            aws.config.update({
                accessKeyId: ACCESS_KEY,
                secretAccessKey: SECRET_ACCESS_KEY,
                region: REGION
            });

            const s3d = new aws.S3();


            var credentials = {
                accessKeyId: localStorage.getItem("access_key"),
                secretAccessKey: localStorage.getItem("secret_key")
            };
            AWS.config.update({ credentials: credentials, region: process.env.REACT_APP_REGION, signatureVersion: "v4" });
            var s3 = new AWS.S3({
                signatureVersion: "v4"
            });

            //let Key = 'test.pdf';
            let url = props.editFormData?.form_url
            let Key = url.split('/').pop();

            var keyWithSubString = '';
            //setFileName(Key);

            s3.setupRequestListeners = (request) => {
                request.on('build', (req) => {

                    req.httpRequest.headers['x-amz-date'] = process.env.REACT_APP_KeyID;
                });
            }

            let fileKey = process.env.REACT_APP_AWE_DIR + "/" + Key;
            var presignedGETURL = s3.getSignedUrl('getObject', {
                Bucket: S3_BUCKET,
                Key: fileKey,
                Expires: 60
            });
            if (Key.includes('/')) {
                keyWithSubString = Key.substr(((Key).lastIndexOf('/')))
            } else {
                keyWithSubString = Key
            }
            axios({
                url: presignedGETURL,
                responseType: "arraybuffer",
                method: "GET",
            }).then((res) => {

                let file = new Blob([res.data], {
                    type: "application/pdf",
                });

                let fileURL = URL.createObjectURL(file);
                //props.editFormData.form_url = fileURL;
                //this.setState('form_url','fileURL')
                setFileUrl(presignedGETURL);

                /*
                let a = document.createElement("a");
                a.href = fileURL;
                a.target = "_blank";
                a.download = keyWithSubString;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);*/

            }).catch((err) => { console.log("Error while downloading file...") })

        }
        // console.log(props?.editFormData);
    }, [props.editFormData]);


    const loadRejectionCode = () => {
        AWVApi.get('/get_rejection_code')
            .then(res => {
                setRejectionCodeList(res.data);
                // console.log(res.data);
            })
            .catch(err => {
                // console.log(err);
            })
    }

    const loadformDataTable = () => {
        // setFormDataList([
        //     {
        //         'avg_hcc_2020': "41",
        //         'avg_hcc_2021': "Intestine Transplant Status/Complications",
        //         'diagnosis_rate': "Z9482",
        //         'change_hcc_score': "Intestine Transplant Status",
        //         'hcc_benchmarks': "Confirmed",
        //         'percent_of_benchmark': "Valid and reported in AWE form",
        //         'awv_completine': "Valid and reported in AWE form",
        //     },
        //     {
        //         'avg_hcc_2020': "254",
        //         'avg_hcc_2021': "Amputation Status, Upper, Limb or Lower Limb",
        //         'diagnosis_rate': "Z89111",
        //         'change_hcc_score': "Acquired absence of right band",
        //         'hcc_benchmarks': "Not Confirmed",
        //         'percent_of_benchmark': "Missing from reporting the condition",
        //         'awv_completine': "Missing from reporting the condition",
        //     },
        //     {
        //         'avg_hcc_2020': "46",
        //         'avg_hcc_2021': "Chronic Pancreatitis",
        //         'diagnosis_rate': "K861",
        //         'change_hcc_score': "Other chronic Pancreatitis",
        //         'hcc_benchmarks': "No Longer Applicable",
        //         'percent_of_benchmark': "The HCC code is no longer applicable",
        //         'awv_completine': "The HCC code is no longer applicable",
        //     },
        // ])



        AWVApi.get('/get_member_hcc_details?cin=' + props?.editFormData?.cin)
            .then(res => {
                setFormDataList(res.data);
                // console.log(res.data);
                // console.log(formDataList);
            })
            .catch(err => {
                // console.log(err);
            })


    }

    const loadEditDetails = (event) => {
        // event.preventDefault();
        setEditDetailsGridShow(!editDetailsGridShow);
    }

    const editHCCActionData = (row, index) => {
        setEditHcccDetailsIndex(index);
        setValueHcc('cin', props?.editFormData?.cin);
        setValueHcc('HCC_Code', row?.HCC_Code);
        setValueHcc('HCC_Description', row?.HCC_Description);
        setValueHcc('DX_code', row?.DX_code);
        setValueHcc('DX_description', row?.DX_description);
        setValueHcc('HCC_Confirmed', row?.HCC_Confirmed);
        setValueHcc('remark', row?.remark);
    }

    const onSubmitHcc = (data) => {
        setIsLoading(true);
        data.cin = data?.CIN;
        AWVApi.put('/update-member-hcc-details', data)
            .then(res => {
                loadformDataTable();
                setEditHcccDetailsIndex('');
                setValueHcc('remark', '');
                setValueHcc('HCC_Confirmed', '');
                setValueHcc('DX_description', '');
                setValueHcc('DX_code', '');
                setValueHcc('HCC_Description', '');
                setValueHcc('HCC_Code', '');
                setValueHcc('cin', '');
                setIsLoading(false);
                toast.success('Member HCC update success');
            })
            .catch(err => {
                setIsLoading(false);
                toast.error(err?.response?.data?.message);
            })
    }

    const onSubmit = (data) => {
        setIsLoading(true);
        if (props?.editFormData?.awv_id && props?.editFormData?.awv_id != '') {
            //AWVApi.post('/create-awv-remark-record?action_status=' + data?.action_status + '&awvId=' + data?.awvid + '&createdOn=' + data?.createdOn + '&form_status=' + data?.action_status + '&lob=' + data?.lob + '&provider_id=' + data?.provider_id + '&remark=' + data?.remark + '&role=' + data?.role + '&user_id=' + data?.user_id, {})
            data.awvId = data.awv_id.toString();
            if (data.pos_name == null)
                data.pos_name = "";
            if (data.form_status == 7)
                data.action_status = '3';
            else
                data.action_status = data.action_status.toString();


            data.form_status = data.form_status.toString();
            data.current_form_status = props?.editFormData?.form_status;


            // const formStatus = {
            //     "DefaultStatus": 0,
            //     "PendingUpload": 1,
            //     "pendingforCoordinator": 2,
            //     "pendingforCoder": 3,
            //     "pendingForPaymentApproval": 4,
            //     "ApprovedForPayment": 5,
            //     "PaymentDone": 6,
            //     "void": 7
            // };

            // if (props.modelStatus && props.modelStatus == true) {
            //     data.form_status = (formStatus[props?.editFormData?.form_status] - 1).toString();;
            // } else {
            //     data.form_status = (formStatus[props?.editFormData?.form_status] + 1).toString();;
            // }
            //console.log(data);
            if (props.modelStatus && props.modelStatus == true && data.current_form_status == "Reject" && props?.editFormData.form_stage == "2") {
                data.form_status = '1';

            }

            if (props.modelStatus == false && data.current_form_status == "Reject" && props?.editFormData.form_stage == "2") {
                data.form_status = '3';
            }

            if (data.form_status == 2 || data.form_status == 3) {
                data.role = '4';
            } else {
                data.role = data.role.toString();
            }

            AWVApi.post('/create-awv-remark-record', data)
                .then(res => {

                    setValue('remark', '');
                    setValue('action_status', '');
                    setValue('awvid', '');
                    setValue('createdOn', '');
                    setValue('lob', '');
                    setValue('user_id', '');
                    setValue('role', '');
                    setValue('reason_code', '');
                    props.loadFormData();
                    setIsLoading(false);
                    if (props.modelStatus && props.modelStatus == true) {
                        toast.success('Remarks added successfully');
                    } else {
                        toast.success('Remarks added successfully');
                    }

                    props.handleClose();
                })
                .catch(err => {
                    setIsLoading(false);
                    toast.error(err?.response?.data?.message);
                })
        }
        // else {
        //     adminApi.post('/module', data)
        //         .then(res => {
        //             props.handleClose();
        //             setValue('id', '');
        //             setValue('title', '');
        //             setValue('description', '');
        //             setValue('status', '1');
        //             setStatusCheck('1');
        //             props.loadFormData();
        //             setIsLoading(false);
        //         })
        //         .catch(err => {
        //             setIsLoading(false);
        //             toast.error(err?.response?.data?.message);
        //         })
        // }
    };


    return (
        <div>
            <MuiDialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth={true}
                maxWidth={'md'}
                scroll={'body'}
                className="awv_model_layout"
                disableBackdropClick
                disableEscapeKeyDown
            >

                <DialogTitle id="create-group" onClose={props.handleClose}>
                    {/*props.modelStatus && props.modelStatus == true ?
                        'REJECT'
                        :
                        'APPROVE'*/
                    }

                    {
                        (() => {
                            if (props.modelStatus && props.modelStatus == true && props.modelFormStatus == 7) {
                                return (
                                    'MARK AS VOID'
                                )
                            } else if (props.modelStatus && props.modelStatus == true) {
                                return (
                                    'REJECT'
                                )
                            } else {
                                return (
                                    'APPROVE'
                                )
                            }
                        })()
                    }

                </DialogTitle>
                <Divider />
                <div className='row'>
                    <div className='col-lg-12'>
                        <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                            <DialogContent >
                                {isLoading && isLoading == true ?
                                    <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                                        <CircularProgress />
                                    </div>
                                    :
                                    <></>
                                }
                                <div className="row mb-3">
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong>CIN/RXID</strong></div>
                                        <div>{props?.editFormData?.cin}</div>
                                    </div>
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong>AWE ID</strong></div>
                                        <div>{props?.editFormData?.awe_display_id}</div>
                                    </div>
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong>UPDATED ON</strong></div>
                                        <div>{props?.editFormData?.updated_on}</div>
                                    </div>
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong>TIME SPENT</strong></div>
                                        <div>{Moment(props?.editFormData?.updated_on).diff(Moment(props?.editFormData?.created_on), 'days') + ' Days'}</div>
                                    </div>
                                </div>
                                <div className="row  mb-3">
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong>PROGRESS</strong></div>
                                        <div>
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Box sx={{ minWidth: 20 }}>
                                                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                                                        props?.editFormData?.progress,
                                                    )}%`}</Typography>
                                                </Box>
                                                <Box sx={{ width: '100%', ml: 1 }}>
                                                    <LinearProgress
                                                        sx={{
                                                            height: 10,
                                                            borderRadius: 5,
                                                            [`&.${linearProgressClasses.colorPrimary}`]: {
                                                                backgroundColor: [800],
                                                            },
                                                            [`& .${linearProgressClasses.bar}`]: {
                                                                borderRadius: 5,
                                                                backgroundColor: '#1a9698',
                                                            },
                                                        }}
                                                        color="primary"
                                                        variant="determinate" value={props?.editFormData?.progress} />
                                                </Box>
                                            </Box>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong>DOCUMENT UPLOADED</strong></div>
                                        <div>
                                            {(props?.editFormData?.form_url && props?.editFormData?.form_url != '') ?
                                                <>
                                                    <FilePresentIcon className="" style={{ width: "0.9em", height: "0.8em" }} />
                                                    <a target="_blank" href={fileUrl}>{props.editFormData?.form_url ? props.editFormData?.form_url?.split('/').pop() : ''}</a>
                                                </>
                                                :
                                                <></>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong>ASSIGNED TO</strong></div>
                                        <div>{props?.editFormData?.assignedUserName}</div>
                                    </div>
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong>STATUS</strong></div>
                                        <div>
                                            <span className="expand-grid-warning-status pt-1 pb-1 pl-3 pr-3">{props?.editFormData?.form_status}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {(((props?.editFormData?.form_status && props?.editFormData?.form_status == 'pendingforCoordinator') || props?.editFormData.form_stage == "2") && props.modelStatus == false) ?
                                        <div className="col-lg-3 align-items-center">
                                            <div className='mb-1'><strong>PLACE OF SERVICE</strong></div>
                                            {/* <InputLabel id="demo-simple-select-outlined-label"><b>PLACE OF SERVICE</b></InputLabel> */}
                                            <FormControl fullWidth>
                                                <Controller
                                                    className="input-control"
                                                    name="pos_name"
                                                    value={props?.editFormData?.POS}
                                                    control={control}
                                                    render={({ field }) => (
                                                        // <TextField  {...field} variant="outlined" />
                                                        <Select
                                                            {...field}
                                                            labelId="module-multiple-checkbox-label"
                                                            id="module-multiple-checkbox"
                                                            variant="outlined"
                                                            menuPlacement="top"
                                                            displayEmpty
                                                            defaultValue=""
                                                        >
                                                            <MenuItem value="">Select</MenuItem>
                                                            <MenuItem value={'In Home Assessment'}>In Home Assessment</MenuItem>
                                                            <MenuItem value={'Office Visit'}>Office Visit</MenuItem>
                                                            <MenuItem value={'TeleHealth'}>Tele Health</MenuItem>
                                                        </Select>
                                                    )}

                                                />
                                            </FormControl>
                                        </div>
                                        : props?.editFormData?.POS && props?.editFormData?.POS != '' ?
                                            <div className="col-lg-3 align-items-center">
                                                <div className='mb-1'><strong>PLACE OF SERVICE</strong></div>
                                                <div>{props?.editFormData?.POS}</div>
                                            </div>
                                            :
                                            <></>
                                    }
                                    {(props.modelStatus && props.modelStatus == true) ?
                                        <div className="col-lg-6 align-items-center">
                                            <div className='mb-1'><strong>REASON CODE</strong></div>
                                            <FormControl fullWidth>
                                                <Controller
                                                    className="input-control"
                                                    name="reason_code"
                                                    value={props?.editFormData?.reason_code}
                                                    control={control}
                                                    render={({ field }) => (
                                                        // <TextField  {...field} variant="outlined" />
                                                        <Select
                                                            {...field}
                                                            labelId="module-multiple-checkbox-label"
                                                            id="module-multiple-checkbox"
                                                            variant="outlined"
                                                            menuPlacement="top"
                                                            displayEmpty
                                                            defaultValue=""
                                                        >
                                                            <MenuItem value="">Select</MenuItem>
                                                            {rejectionCodeList && rejectionCodeList.length > 0 && rejectionCodeList.map((element, index) =>
                                                                <MenuItem key={index} value={element.reason_code_id}>{element.reason_code_description}</MenuItem>
                                                            )}
                                                        </Select>
                                                    )}

                                                />
                                            </FormControl>
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 align-items-center">
                                        <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '100px' }}>
                                            {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                            <Controller
                                                className="input-control"
                                                name="remark"
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField multiline rows={3} {...field} label="REMARK" variant="outlined" />
                                                )}
                                                rules={{
                                                    required: true,
                                                }}
                                            />
                                            {errors?.remark?.type === "required" && <label className="text-danger">This field is required</label>}
                                        </FormControl>
                                    </div>
                                </div>


                                {editDetailsGridShow ?
                                    <div className="row mt-3 mb-5">
                                        <div className="card m-3">
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell align="center">HCC Code</StyledTableCell>
                                                            <StyledTableCell align="center">HCC Description</StyledTableCell>
                                                            <StyledTableCell align="center">DX Code</StyledTableCell>
                                                            <StyledTableCell align="center">DX Description</StyledTableCell>
                                                            <StyledTableCell align="center">HCC Confirmed</StyledTableCell>
                                                            <StyledTableCell align="center">Remarks</StyledTableCell>
                                                            {/* <StyledTableCell align="center">Action</StyledTableCell> */}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {formDataList.map((row, index) => (
                                                            <StyledTableRow key={index}>
                                                                {editHcccDetailsIndex && editHcccDetailsIndex == (index + 1) ?
                                                                    <>
                                                                        <StyledTableCell align="center">
                                                                            <Controller
                                                                                className="input-control"
                                                                                name={"HCC_Code"}
                                                                                value={row.HCC_Code}
                                                                                control={controlHcc}
                                                                                render={({ field }) => (
                                                                                    <TextField  {...field} type="number" variant="outlined" />
                                                                                )}
                                                                            />
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            <Controller
                                                                                className="input-control"
                                                                                name={"HCC_Description"}
                                                                                value={row.HCC_Description}
                                                                                control={controlHcc}
                                                                                render={({ field }) => (
                                                                                    <TextField  {...field} type="text" variant="outlined" />
                                                                                )}
                                                                            />
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            <Controller
                                                                                className="input-control"
                                                                                name={"DX_code"}
                                                                                value={row.DX_code}
                                                                                control={controlHcc}
                                                                                render={({ field }) => (
                                                                                    <TextField  {...field} type="text" variant="outlined" />
                                                                                )}
                                                                            />
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            <Controller
                                                                                className="input-control"
                                                                                name={"DX_description"}
                                                                                value={row.DX_description}
                                                                                control={controlHcc}
                                                                                render={({ field }) => (
                                                                                    <TextField  {...field} type="text" variant="outlined" />
                                                                                )}
                                                                            />
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            <Controller
                                                                                className="input-control"
                                                                                name={"HCC_Confirmed"}
                                                                                value={row.HCC_Confirmed}
                                                                                control={controlHcc}
                                                                                render={({ field }) => (
                                                                                    <Select
                                                                                        {...field}
                                                                                        labelId="module-multiple-checkbox-label"
                                                                                        id="module-multiple-checkbox"
                                                                                        // value={selectModule}
                                                                                        // onChange={handleChange}
                                                                                        label="Module"
                                                                                        variant="outlined"
                                                                                        // MenuProps={MenuProps}
                                                                                        menuPlacement="top"
                                                                                    >
                                                                                        <MenuItem key={'confirmed'} value={'confirmed'} >
                                                                                            Confirmed
                                                                                        </MenuItem>
                                                                                        <MenuItem key={'not confirmed'} value={'not confirmed'} >
                                                                                            Not Confirmed
                                                                                        </MenuItem>
                                                                                        <MenuItem key={'no longer applicable'} value={'no longer applicable'} >
                                                                                            No Longer Applicable
                                                                                        </MenuItem>
                                                                                    </Select>
                                                                                )}
                                                                            />
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            <Controller
                                                                                className="input-control"
                                                                                name={"remark"}
                                                                                value={row.remark}
                                                                                control={controlHcc}
                                                                                render={({ field }) => (
                                                                                    <TextField  {...field} type="text" variant="outlined" />
                                                                                )}
                                                                            />
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            <Button type='submit'
                                                                                className={props.modelStatus && props.modelStatus == true ? 'mr-2 btn-custom-danger' : 'mr-2 btn-custom-primary'}
                                                                                variant="contained"
                                                                                disabled={isLoading && isLoading == true ? true : false}
                                                                            >
                                                                                Submit
                                                                            </Button>
                                                                        </StyledTableCell>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <StyledTableCell align="center">
                                                                            {row.HCC_Code}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row.HCC_Description}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row.DX_code}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row.DX_description}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row.HCC_Confirmed}
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center">
                                                                            {row.remark}
                                                                        </StyledTableCell>
                                                                        {/* <StyledTableCell align="center">
                                                                            <span className="text-danger" style={{ cursor: 'pointer' }} onClick={() => editHCCActionData(row, (index + 1))}>EDIT</span> 
                                                                        </StyledTableCell> */}
                                                                    </>
                                                                }

                                                            </StyledTableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                    </div>
                                    :
                                    <></>
                                }
                            </DialogContent>
                            <DialogActions className='mt-2'>
                                {(props?.editFormData?.form_status && props?.editFormData?.form_status == 'pendingforCoder') ?
                                    <>
                                        <Button type='button' onClick={loadEditDetails} className="ml-2 btn-custom-primary" variant="contained">
                                            View HCC Details
                                        </Button>
                                    </>
                                    :
                                    <></>
                                }
                                <Button type='button' variant="contained" color="grey" onClick={props.handleClose}>CANCEL</Button>
                                <Button type='submit'
                                    className={props.modelStatus && props.modelStatus == true ? 'mr-2 btn-custom-danger' : 'mr-2 btn-custom-primary'}
                                    variant="contained"
                                    disabled={isLoading && isLoading == true ? true : false}
                                >
                                    { /*props.modelStatus && props.modelStatus == true ?
                                        'REJECT'
                                        :
                                        'APPROVE' */
                                    }

                                    {
                                        (() => {
                                            if (props.modelStatus && props.modelStatus == true && props.modelFormStatus == 7) {
                                                return (
                                                    'MARK AS VOID'
                                                )
                                            } else if (props.modelStatus && props.modelStatus == true) {
                                                return (
                                                    'REJECT'
                                                )
                                            } else {
                                                return (
                                                    'APPROVE'
                                                )
                                            }
                                        })()
                                    }

                                </Button>
                            </DialogActions>

                        </form>
                    </div>
                </div>
            </MuiDialog>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        userDetails: state.auth.userDetails,
        yearSelect: state.moduleFilter.yearSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        lobSelect: state.moduleFilter.lobSelect,
    };
}
export default (withStyles(styles), connect(mapStateToProps))(StatusAWVFileUpload);
