import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import WidgetCard from "../../components/Cards/WidgetCard";
import SingleVerticalBarChart from '../../components/Charts/SingleVerticalBar.Chart';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag, hccSeperator, numberWithCommas } from '../../assets/constants/customRender'
import history from "../../history";
import { FLAG, ISSUER_ID, HCC, WIDGET_TYPES, REPORTED_INSIGHTS, MEMBER_NAME, CARRIER_ID, PLAN_ID, GENDER, MAO_RISK_CONDITION_CHART, MAO_Chart_Review_Member, MAO_MEMBER_SELECT_TAB } from "../../assets/constants/string.constants";
import { percentFormat } from "../../assets/helpers/formats.helpers";
import CardComponent from "../../components/Cards/card";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import SubHeader from '../../components/home/header/subHeader';
import Link from '@material-ui/core/Link';
import { connect } from 'react-redux';
import { getMeInitialSelect } from '../../assets/constants/meInitialList';
//import google from "react-google-charts";

const SUMemWithNoWellness = (props) => {
    const [WidgetDetails, setWidgetDetails] = useState({});
    const [readmissionCountTableData, setReadmissionCountTableData] = useState([]);
    const [memberCondSummaryTableData, setMemberCondSummaryTableData] = useState([]);
    const [selectFlag, setSelectFlag] = useState(localStorage.getItem(FLAG));
    const WidgetDetailsType = [
        WIDGET_TYPES.LOCKICON,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.PERCENT,
        WIDGET_TYPES.SCORE,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.TRENDING,
        WIDGET_TYPES.PERCENT,
    ]

    const type = WidgetDetailsType;
    const widgetStyles = {
        position: 'absolute',
        left: 0,
        //right: 20,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.15,
        color: "white"
    };

    const loadWidgetDetails = () => {
        api.get('/suMemberCondSummary/' + props.yearSelect + '/condition/' + selectFlag + '/flag/0')
            .then(res => {
                setWidgetDetails({
                    '# Members': Number(res[0].totalmember).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    'Overall Utilization': '$' + Number(res[0].overallutilization).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    'Overall Revenue': '$' + Number(res[0].overallrevenue).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    'Overall Yield': Number(res[0].overallyield).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' %',
                });
            })
            .catch(err => {
                // console.log(err);
            });
    };


    const getIcon = (type) => {
        var data = ''
        switch (type) {
            case WIDGET_TYPES.ISSUER:
                data = <AccountBalanceTwoToneIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.MEMBERS:
                data = <PeopleAltIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERCENT:
                data = <ShowChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.SCORE:
                data = <BarChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERSON:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.CHILD:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.ADULT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.INFANT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.REVENUE:
                data = <AttachMoneyIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.COUNT:
                data = <ShoppingCartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.LOCKICON:
                data = <LockOpenIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.TRENDING:
                data = <TrendingUpIcon style={widgetStyles} />
                break;
        }

        return data;
    }

    const memberMedicareCols = [
        {
            name: "memberid",
            label: "Member ID",
        },
        {
            name: "utilization",
            label: "Utilization",
        },
        {
            name: "reportedRevenue",
            label: "Reported Revenue"
        },
        {
            name: "revenuePostCR",
            label: "Revenue Post Chart Review"
        },
        {
            name: "reportedYield",
            label: "Reported Yield"
        },
        {
            name: "yieldPostCR",
            label: "Yield Post Chart Review"
        },
        {
            name: "percentContOverall",
            label: "% Contribution to Overall Utilization"
        },

    ]

    //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    useEffect(() => {
        setSelectFlag(localStorage.getItem(FLAG))
        loadWidgetDetails();
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect(),
        localStorage.getItem(FLAG)
    ]);

    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Conditional Member Summary'} selectType={'utilization'} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0">
                    <div className="state-level">
                        {(Object.entries(WidgetDetails).length > 0) ?
                            <SlickSlider WidgetDetails={WidgetDetails} getIcon={getIcon} type={type} />
                            :
                            <></>
                        }
                    </div>
                </div>
                <div className='col-lg-12 pr-0 pl-0'>
                    <div className="list-member mt-4">
                        <CardComponent
                            heading={`MEMBER LIST`}
                            body={props.yearSelect ?
                                <ServerSideGridTable
                                    url={`/suMemberTabledetails/year/${props.yearSelect}/condition/${selectFlag}/flag/0`}
                                    columns={memberMedicareCols}
                                // tableClick={memberTableClick}
                                ></ServerSideGridTable>
                                :
                                <></>
                            }>
                        </CardComponent>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br />

                <br />
                <br />

            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default connect(mapStateToProps)(SUMemWithNoWellness);
