import React, { useRef, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../../store/actions';
import AWVApi from '../../../assets/constants/AWVRafservice.Instance';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import Divider from '@mui/material/Divider';
import { useForm, Controller } from "react-hook-form";
import Box from '@mui/material/Box';
import pdfIcon from '../../../assets/images/pdf_icon.png';
import Moment from 'moment';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { styled } from '@mui/material/styles';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import axios from "axios";
import AWS from 'aws-sdk';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import IconButton from "../../layout/aweLayout/IconButton";
import Button from "../../layout/aweLayout/Button";
import CloseIcon from "../../icons/CloseIcon";
import PlusFileIcon from '../../icons/PlusFileIcon';
import {LogFileUploadToS3} from '../../../assets/constants/LogFileUploadToS3'
const aws = require('aws-sdk');

const S3_BUCKET = process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET;
const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = localStorage.getItem('access_key');
const SECRET_ACCESS_KEY = localStorage.getItem('secret_key');

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const OnHoldAWVFileUpload = (props) => {
    const [editFormData, setEditFormData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { register: registerHcc, formState: { errors: errorsHcc }, handleSubmit: handleSubmitHcc, reset: resetHcc, control: controlHcc, setValue: setValueHcc } = useForm();
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [fileUrl, setFileUrl] = useState();
    const [fileName, setFileName] = useState();
    const [rejectionCodeList, setRejectionCodeList] = useState([]);
    const [onHoldCodeList, setOnHoldCodeList] = useState([]);
    const [editDetailsGridShow, setEditDetailsGridShow] = useState(false);
    const [editHcccDetailsIndex, setEditHcccDetailsIndex] = useState('');
    const [formDataList, setFormDataList] = useState([]);
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
    const [logMessages, setLogMessages] = useState([]); // Array to store log messages
    const writeToLogFile = (message) => {
       setLogMessages(prevLogMessages => [...prevLogMessages, `${new Date().toISOString()} - ${message}`]);
   };
   const [generateLog, setGenerateLog] = useState(false);


    useEffect(() => {
        editLoadFormData(props.awv_backend_id);
        // loadRejectionCode();
        loadOnHoldCode();
    }, [props.awv_backend_id]);

    const generateSignedURL = (url) => {
        aws.config.update({
            accessKeyId: ACCESS_KEY,
            secretAccessKey: SECRET_ACCESS_KEY,
            region: REGION
        });
        const s3d = new aws.S3();
        var credentials = {
            accessKeyId: ACCESS_KEY,
            secretAccessKey: SECRET_ACCESS_KEY
        };
        AWS.config.update({ credentials: credentials, region: process.env.REACT_APP_REGION, signatureVersion: "v4" });
        var s3 = new AWS.S3({
            signatureVersion: "v4"
        });

        const [protocol, fullHost, ...pathSegments] = url.split('/');
        const [bucketName, port] = pathSegments[0].split('.');
        const objectKey = pathSegments.slice(1).join('/');

        const Key = `${objectKey}`;

        s3.setupRequestListeners = (request) => {
            request.on('build', (req) => {

                req.httpRequest.headers['x-amz-date'] = process.env.REACT_APP_KeyID;
            });
        }
        /*  adding condition to upload form in different folder as per lob select */
       
        var presignedGETURL = s3.getSignedUrl('getObject', {
            Bucket: bucketName,
            Key: Key,
            Expires: 60
        });
    
        return presignedGETURL;

        }


    useEffect(() => {
        if (editFormData?.awv_backend_id && editFormData?.awv_backend_id != '') {
            let controlUser = JSON.parse(localStorage.getItem('controlUser'));

            setValue('role', '3');
            setValue('action_status', '4');
            setValue('reason_code', '');
            setValue('awv_backend_id', editFormData?.awv_backend_id);
            //setValue('createdOn', editFormData?.created_on);
            setValue('createdOn', Moment().format('YYYY-MM-DD HH:mm:ss')); // To do  Temp fix. Need to check why time is not coming along with date
            setValue('lob', props?.lobSelect);
            setValue('user_id', controlUser?.user_id);
            setValue('provider_id', controlUser?.user_id);
            setValue('pos_name', (editFormData?.POS) ? editFormData?.POS : "");
            // setValue('pos_name', ""); // To do check for blank value Temp fix.

            setValue('form_status', (props.aweRightSidebarType?.status).toString());
            setValue('current_form_status', editFormData?.form_status);
            setValue('onhold_reason_code', (editFormData.onhold_reason_code) ? editFormData?.onhold_reason_code : "");
            setValue('cin', editFormData?.cin);
            setValue('member_id', editFormData?.member_id);
            setValue('organisation_id', editFormData?.organisation_id ? (editFormData?.organisation_id).toString() : '');
            setValue('payment_year', props.yearSelect);
            setValue('provider_group', editFormData?.provider_group);
            // setValue('onhold_reason_code', "");
            if (editFormData.on_hold && editFormData.on_hold == 'Y') {
                setValue('onhold_status', 'N');
            } else {
                setValue('onhold_status', 'Y');
            }
            // setValue('onhold_status', 'Y'); // implemetion pending

        }
    }, [editFormData]);

    const notificationRightDrawer = (open, valueId = '') => {
        props.currentAWERightSidebarType({ 'type': 'notification' });
        props.currentAWERightSidebar(open);
    };

    // commenting this code- rejection reason codes are not used for onhold
    /* const loadRejectionCode = () => {
        AWVApi.get('/get_rejection_code')
            .then(res => {
                setRejectionCodeList(res.data);
                // console.log(res.data);
            })
            .catch(err => {
                // console.log(err);
            })
    } */
    // created funtion to call onhold data
    const loadOnHoldCode = () => {
        AWVApi.get('/get_onhold_reason_code')
            .then(res => {
                setOnHoldCodeList(res.data);
                //alert(res.data);
            })
            .catch(err => {
                //console.log(err);
            })
    }

    const editLoadFormData = (value) => {
        writeToLogFile("value...", value);
        value = btoa(value)
        writeToLogFile("value after encryption ", value)
        setIsLoading(true);
        AWVApi.get('/get-all-awv-record?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + '&awv_backend_id=' + value + '&iSortCol_0=provider_group&sSortDir_0=asc&iDisplayStart=0&iDisplayLength=1&formAttached=0')
            .then(res => {
                console.log(res.data);
                if (res.data && res.data[0]) {
                    var url = generateSignedURL(res.data[0].form_url);
                    res.data[0]['url'] = url;
                    setEditFormData(res.data[0]);
                    setEditDetailsGridShow(false);
                    setEditHcccDetailsIndex('');
                    setIsLoading(false);
                } else {
                    setEditFormData();
                    setIsLoading(false);
                }
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
            })
    }

    const loadEditDetails = (event) => {
        setEditDetailsGridShow(!editDetailsGridShow);
    }

    const onSubmit = (data) => {
        writeToLogFile("onSubmit() call ");
        if (editFormData?.awv_backend_id && editFormData?.awv_backend_id != '') {
            setFormSubmitisLoading(true);
            data.awv_backend_id =  data.awv_backend_id ? data.awv_backend_id.toString() : '';
            data.form_status = data.form_status ? data.form_status.toString() : "";
            //data.createdOn = data.createdOn.toString(); 
            writeToLogFile("data: " + data);  
            if(data?.lob)
              data.lob = data.lob.toString();
            
            writeToLogFile('awv_backend_id before encryption ', data.awv_backend_id)
            data.cin = btoa(data.cin)
            data.awv_backend_id = btoa(data.awv_backend_id)
            data.member_id = btoa(data.member_id)
            AWVApi.post('/create-awv-remark-record', data)
                .then(res => {
                    setValue('remark', '');
                    setValue('action_status', '');
                    setValue('awv_backend_id', '');
                    setValue('createdOn', '');
                    setValue('lob', '');
                    setValue('user_id', '');
                    setValue('role', '');
                    setValue('reason_code', '');
                    setValue('current_form_status', '');
                    setFormSubmitisLoading(false);
                    if (editFormData.on_hold && editFormData.on_hold == 'Y') {
                        toast.success('Un Hold remark added successfully');;
                    } else {
                        toast.success('On Hold remark added successfully');
                    }

                    props.currentAWERightSidebarCloseDatatableReload(true);
                    notificationRightDrawer(false);
                    writeToLogFile("Data retrieved successfully...");
                    setGenerateLog(true)
                })
                .catch(err => {
                    setFormSubmitisLoading(false);
                    toast.error(err?.response?.data?.message);
                    writeToLogFile("Error in retrieving data... " + err );
                    setGenerateLog(true)
                })
        }
    };
    useEffect(() => {
        // Generate and download log file when logMessages have been updated
        if (logMessages.length > 0  && generateLog === true) {
        LogFileUploadToS3(logMessages,  ACCESS_KEY, SECRET_ACCESS_KEY, process.env.REACT_APP_LOG_BUCKET, "AWE_Record_Hold")
        }
    }, [ generateLog === true, logMessages, props.yearSelect, props.AwvId , props.lobSelect]); 

    const onClick = (event, url) => {
        try {
            event.preventDefault();
            axios({
                url: url,
                responseType: "arraybuffer",
                method: "GET",
            }).then((res) => {
                window.location.href = url
            }).catch((err) => {
                // alertTitleClasses(err)
                toast.error('Error while downloading the form');
            })
        } catch (error) {
            toast.error('Error while downloading the form');
        }

    }
    return (
        <div className="diagnosis_code_sidebar offcanvas offcanvas-end show" tabIndex={-1} aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header flex_item_cb">
                <h5 id="offcanvasRightLabel" className='fs15'>
                    {(editFormData.on_hold && editFormData.on_hold == 'Y') ?
                        'Un Hold'
                        :
                        'HOLD'
                    }
                </h5>
                <Tooltip title="Close">
                    <IconButton type="button" buttonType={"default"} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => notificationRightDrawer(false)}>
                        <CloseIcon className="w24" />
                    </IconButton>
                </Tooltip>
            </div>
            <Divider />
            <div className="offcanvas-body pt-1 custom-scrollbar overflow-auto">
                <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                    <div className="flex_item_cb custom_row flex-wrap mb20">
                        <div className="custom_col">
                            <div className="">
                                <h6 className="fs14">CIN/RXID</h6>
                                <p className="fs13 fw500">{editFormData?.cin}</p>
                            </div>
                        </div>
                        <div className="custom_col">
                            <div className="">
                                <h6 className="fs14">AWE ID</h6>
                                <p className="fs13 fw500">{editFormData?.awe_display_id}</p>
                            </div>
                        </div>
                        <div className="custom_col">
                            <div className="">
                                <h6 className="fs14">UPDATED ON</h6>
                                <p className="fs13 fw500">{Moment(editFormData?.updated_on).format("YYYY-MM-DD")}</p>
                            </div>
                        </div>
                        <div className="custom_col">
                            <div className="">
                                <h6 className="fs14">TIME SPENT</h6>
                                <p className="fs13 fw500">{Moment(editFormData?.updated_on).diff(Moment(editFormData?.created_on), 'days') + ' Days'}</p>
                            </div>
                        </div>
                    </div>
                    <div className="mb20">
                        <h6 className="fs14 mb-1">PROGRESS</h6>
                        <div className="flex_item_cs">
                            <label htmlFor="" className="text-grey-100 fs15">{`${Math.round(editFormData?.progress)}%`}</label>
                            <LinearProgress
                                className="flex-grow-1"
                                sx={{
                                    height: 10,
                                    borderRadius: 5,
                                    [`&.${linearProgressClasses.colorPrimary}`]: {
                                        backgroundColor: [800],
                                    },
                                    [`& .${linearProgressClasses.bar}`]: {
                                        borderRadius: 5,
                                        backgroundColor: '#1a9698',
                                    },
                                }}
                                color="primary"
                                variant="determinate" value={editFormData?.progress} />
                        </div>
                    </div>
                    <Divider className="mb20" />
                    <div className="mb20">
                        <h6 className="fs14 mb-1">DOCUMENT UPLOADED</h6>
                        <div className="">
                            {(editFormData?.url && editFormData?.url && editFormData?.url != '') ?
                                <>
                                    {editFormData?.url && editFormData?.url != '' && editFormData?.url.includes(".pdf") ?
                                        <a target="_blank" onClick={(e) => onClick(e, editFormData?.url)} className="flex_item_cs gap8 text-primary fs15 fw600">
                                            <PlusFileIcon className="w24 h24" />
                                            <span>{editFormData?.form_url ? editFormData?.form_url?.split('/').pop() : ''}</span>
                                        </a>
                                        :
                                        <a onClick={(e) => onClick(e, editFormData?.url)} target="_blank" download className="flex_item_cs gap8 text-primary fs15 fw600">
                                            <PlusFileIcon className="w24 h24" />
                                            <span>{editFormData?.form_url ? editFormData?.form_url?.split('/').pop() : ''}</span>
                                        </a>
                                    }
                                </>
                                :
                                <></>
                            }
                        </div>
                    </div>
                    <Divider className="mb20" />
                    <div className="mb20">
                        <h6 className="fs14">ASSIGNED TO</h6>
                        <p className="fs13 fw500">{editFormData?.assignedUserName}</p>
                    </div>
                    <div className="mb20">
                        <h6 className="fs14">STATUS</h6>
                        <p className="fs13 fw500">{editFormData?.form_status}</p>
                    </div>
                    {(!editFormData.on_hold || editFormData.on_hold !== 'Y') ? (
                        <div className="form-group mb20">
                            <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Status</label>
                            <Controller
                                name="onhold_reason_code"
                                value={editFormData?.onhold_reason_code}
                                control={control}
                                render={({ field }) => (
                                    <select
                                        {...field}
                                        className="form-control"
                                    >
                                        <option value={''} >
                                            Select
                                        </option>
                                        {onHoldCodeList && onHoldCodeList.length > 0 && onHoldCodeList.map((element, index) => (
                                            <option key={index} value={element.id}>{element.onhold_reason_code_description}</option>
                                        ))}
                                    </select>
                                )}
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.onhold_reason_code?.type === "required" && <label className="form-input-error">This field is required</label>}
                        </div>
                    )
                        :
                        <></>
                    }
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Remark</label>
                        <Controller
                            name="remark"
                            control={control}
                            render={({ field }) => (
                                <textarea rows={3} {...field} className="form-control" />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.remark?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>

                    <div className="form-group flex_item_cs gap10">
                        <Button type='button' className={"btn-white minw80"} onClick={() => notificationRightDrawer(false)}>CANCEL</Button>
                        <Button type='submit' className={"minw80"} disabled={formSubmitisLoading && formSubmitisLoading == true ? true : false}>SAVE</Button>
                    </div>
                </form>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(OnHoldAWVFileUpload);

