import Chart from "react-google-charts";
import { BarChart, LinearXAxis, LinearYAxis, LinearYAxisTickSeries, BarSeries, Bar, GuideBar, GridlineSeries, Gridline } from 'reaviz';
import history from "../../history";

const BarVerticalMultiLink = (props) => {
    var { data, chartType, width, height, className, setStorgeItemName, redirectUrl, yAxistitle,  hAxisTitle } = props;

 
    const chartEvents = [
      {
        eventName: "select",
        callback({ chartWrapper }) {          
          var data_row = chartWrapper?.getChart()?.getSelection()[0]?.row;
          if(redirectUrl != '' && data_row != ''){
            redirectUrl && redirectUrl.forEach((element, key)=> {       
              if(key == data_row){
                if(element.length > 0){
                  var data_select = element[1];         
                  if(data_select && data_select != ''){
                    history.push('/layout/'+data_select);
                  }
                }
              } 
            });
          }
        }
      }
    ];
    return (
      <div style={{'cursor':'pointer'}}>
        {data.length > 1?
          <Chart
              width={width}
              height={height}
              className={className}
              chartType={chartType}
              loader={<div>Loading Chart</div>}
              data={data}
              options={{
                  chartArea: { width: '50%', height: '75%' },
                  isStacked: true,
                  colors: ['#5FBEFB','#9292E7','#7AD38C','#F4BC7D','#800000'],
                  legend: { position: 'top',maxLines: 1},
                  vAxis: { minValue: 0, title: yAxistitle},
                  is3D: true,
                  hAxis: {title: hAxisTitle},
              }}
              rootProps={{ 'data-testid': '1' }}
              chartEvents={chartEvents}
          />   
        :
          <h6 className="text-center">No records found!..</h6>
        }
      </div>
    )
    
    // const hasGradient = true;
    // const color = 'blue';
    // const height = props.height;
    // const width = props.width;
    // const hasGuideBar = false;
    // const guide = hasGuideBar ? <GuideBar /> : null;
    // const data = props.data;
    // const gradient = hasGradient ? Bar.defaultProps.gradient : null;

    // return (
    //   <div style={{'margin-left':'200px'}}>
    //     <BarChart
    //       width={width}
    //       height={height}
    //       data={data}
    //       xAxis={<LinearXAxis type="value" />}
    //       yAxis={
    //         <LinearYAxis
    //           type="category"
    //           tickSeries={<LinearYAxisTickSeries tickSize={20} />}
    //         />
    //       }
    //       series={
    //         <BarSeries
    //           colorScheme={color}
    //           layout="horizontal"
    //           bar={<Bar 
    //                 gradient={gradient} 
    //                 guide={guide} 
    //                 onClick={(data, index) => {
    //                   console.log(data);
    //                   console.log(index);
    //                   return '';
    //                 }}
    //               />}
    //         />
    //       }
    //     />
    //   </div>
    // );
}
export default BarVerticalMultiLink;