import React, { useRef, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar } from '../../../store/actions';
import AWVApi from '../../../assets/constants/AWVRafservice.Instance';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import Divider from '@mui/material/Divider';
import { useForm, Controller } from "react-hook-form";
import Box from '@mui/material/Box';
import pdfIcon from '../../../assets/images/pdf_icon.png';
import Moment from 'moment';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import IconButton from "../../layout/aweLayout/IconButton";
import Button from "../../layout/aweLayout/Button";
import CloseIcon from "../../icons/CloseIcon";
import PlusFileIcon from '../../icons/PlusFileIcon';
import axios from "axios";
import {LogFileUploadToS3} from '../../../assets/constants/LogFileUploadToS3'
import AWS from 'aws-sdk';
import { toast } from 'react-toastify';
const aws = require('aws-sdk');

const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = localStorage.getItem('access_key');
const SECRET_ACCESS_KEY = localStorage.getItem('secret_key');


const ViewAWVFileUpload = (props) => {

    const [editFormData, setEditFormData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [fileUrl, setFileUrl] = useState();
    const [fileName, setFileName] = useState();
    const [logMessages, setLogMessages] = useState([]); // Array to store log messages
    const writeToLogFile = (message) => {
       setLogMessages(prevLogMessages => [...prevLogMessages, `${new Date().toISOString()} - ${message}`]);
   };
   const [generateLog, setGenerateLog] = useState(false);


    useEffect(() => {
        editLoadFormData(props.awv_backend_id);
    }, [props.awv_backend_id]);

    const generateSignedURL = (url) => {

        aws.config.update({
            accessKeyId: ACCESS_KEY,
            secretAccessKey: SECRET_ACCESS_KEY,
            region: REGION
        });
        const s3d = new aws.S3();
        var credentials = {
            accessKeyId: localStorage.getItem('access_key'),
            secretAccessKey: localStorage.getItem('secret_key')
        };
        AWS.config.update({ credentials: credentials, region: REGION, signatureVersion: "v4" });
        var s3 = new AWS.S3({
            signatureVersion: "v4"
        });

        const [protocol, fullHost, ...pathSegments] = url.split('/');
        const [bucketName, port] = pathSegments[0].split('.');
        const objectKey = pathSegments.slice(1).join('/');

        const Key = `${objectKey}`;

        s3.setupRequestListeners = (request) => {
            request.on('build', (req) => {

                req.httpRequest.headers['x-amz-date'] = process.env.REACT_APP_KeyID;
            });
        }
        /*  adding condition to upload form in different folder as per lob select */

        var presignedGETURL = s3.getSignedUrl('getObject', {
            Bucket: bucketName,
            Key: Key,
            Expires: 60
        });

        return presignedGETURL;

    }


    const notificationRightDrawer = (open, valueId = '') => {
        props.currentAWERightSidebarType({ 'type': 'notification' });
        props.currentAWERightSidebar(open);
    };

    const editLoadFormData = (value) => {
        writeToLogFile("value...", value);
        value = btoa(value)
        writeToLogFile("value after encryption ", value)
        writeToLogFile("editLoadFormData...");
        setIsLoading(true); 
        var url = '/get-all-awv-record?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + '&awv_backend_id=' + value + '&iSortCol_0=provider_group&sSortDir_0=asc&iDisplayStart=0&iDisplayLength=1&formAttached=0'
       
        AWVApi.get(url)
            .then(res => {
                if (res.data && res.data[0]) {
                    var url = generateSignedURL(res.data[0].form_url);
                    res.data[0]['url'] = url;
                    setEditFormData(res.data[0]);
                    setIsLoading(false);
                    writeToLogFile("response received successfully...");
                    setGenerateLog(true)
                } else {
                    setEditFormData();
                    setIsLoading(false);
                    writeToLogFile("Error in retrieving data Response..." + res);
                    setGenerateLog(true)
                }
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
                writeToLogFile("Error in retrieving data... " + err );
                setGenerateLog(true)
            })
    }

    const onSubmit = (data) => {
        writeToLogFile("Submit= " + data);
        console.log(data);
    };

    useEffect(() => {
        // Generate and download log file when logMessages have been updated
        if (logMessages.length > 0  && generateLog === true) {
        LogFileUploadToS3(logMessages,  ACCESS_KEY, SECRET_ACCESS_KEY, process.env.REACT_APP_LOG_BUCKET, "View_AWE_Record")
        }
    }, [ generateLog === true, logMessages, props.AwvId, props.yearSelect, props.lobSelect]); 

    const onClick = (event, url) => {
        try {
            event.preventDefault();
            axios({
                url: url,
                responseType: "arraybuffer",
                method: "GET",
            }).then((res) => {
                window.location.href = url
            }).catch((err) => {
                // alertTitleClasses(err)
                toast.error('Error while downloading the form');
            })
        } catch (error) {
            toast.error('Error while downloading the form');
        }

    }
    
    return (
        <div className="diagnosis_code_sidebar offcanvas offcanvas-end show" tabIndex={-1} aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header flex_item_cb">
                <h5 id="offcanvasRightLabel" className='fs15'>View</h5>
                <Tooltip title="Close">
                    <IconButton type="button" buttonType={"default"} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => notificationRightDrawer(false)}>
                        <CloseIcon className="w24" />
                    </IconButton>
                </Tooltip>
            </div>
            <Divider />
            <div className="offcanvas-body custom-scrollbar overflow-auto">
                <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model" enctype="multipart/form-data">
                    <div className="flex_item_cb custom_row flex-wrap mb20">
                        <div className="custom_col">
                            <div className="">
                                <h6 className="fs14">CIN/RXID</h6>
                                <p className="fs13 fw500">{editFormData?.cin}</p>
                            </div>
                        </div>
                        <div className="custom_col">
                            <div className="">
                                <h6 className="fs14">AWE ID</h6>
                                <p className="fs13 fw500">{editFormData?.awe_display_id}</p>
                            </div>
                        </div>
                        <div className="custom_col">
                            <div className="">
                                <h6 className="fs14">UPDATED ON</h6>
                                <p className="fs13 fw500">{Moment(editFormData?.updated_on).format("YYYY-MM-DD")}</p>
                            </div>
                        </div>
                        <div className="custom_col">
                            <div className="">
                                <h6 className="fs14">TIME SPENT</h6>
                                <p className="fs13 fw500">{Moment(editFormData?.updated_on).diff(Moment(editFormData?.created_on), 'days') + ' Days'}</p>
                            </div>
                        </div>
                    </div>
                    <div className="mb20">
                        <h6 className="fs14 mb-1">PROGRESS</h6>
                        <div className="flex_item_cs">
                            <label htmlFor="" className="text-grey-100 fs15">{`${Math.round(editFormData?.progress)}%`}</label>
                            <LinearProgress
                                className="flex-grow-1"
                                sx={{
                                    height: 10,
                                    borderRadius: 5,
                                    [`&.${linearProgressClasses.colorPrimary}`]: {
                                        backgroundColor: [800],
                                    },
                                    [`& .${linearProgressClasses.bar}`]: {
                                        borderRadius: 5,
                                        backgroundColor: '#1a9698',
                                    },
                                }}
                                color="primary"
                                variant="determinate" value={editFormData?.progress} />
                        </div>
                    </div>
                    <Divider className="mb20" />
                    <div className="mb20">
                        <h6 className="fs14 mb-1">DOCUMENT UPLOADED</h6>
                        <div className="">
                            {(editFormData?.url && editFormData?.url && editFormData?.url != '') ?
                                <>
                                    {editFormData?.url && editFormData?.url != '' && editFormData?.url.includes(".pdf") ?
                                        <a target="_blank" onClick={(e) => onClick(e, editFormData?.url)} className="flex_item_cs gap8 text-primary fs15 fw600">
                                            <PlusFileIcon className="w24 h24" />
                                            <span>{editFormData?.form_url ? editFormData?.form_url?.split('/').pop() : ''}</span>
                                        </a>
                                        :
                                        <a onClick={(e) => onClick(e, editFormData?.url)} target="_blank" download className="flex_item_cs gap8 text-primary fs15 fw600">
                                            <PlusFileIcon className="w24 h24" />
                                            <span>{editFormData?.form_url ? editFormData?.form_url?.split('/').pop() : ''}</span>
                                        </a>
                                    }
                                </>
                                :
                                <></>
                            }
                        </div>
                    </div>
                    <Divider className="mb20" />
                    <div className="mb20">
                        <h6 className="fs14">ASSIGNED TO</h6>
                        <p className="fs13 fw500">{editFormData?.assignedUserName}</p>
                    </div>
                    <div className="mb20">
                        <h6 className="fs14">STATUS</h6>
                        <p className="fs13 fw500">{editFormData?.form_status}</p>
                    </div>
                    {editFormData?.POS ?
                        <div className="mb20">
                            <h6 className="fs14">POS</h6>
                            <p className="fs13 fw500">{editFormData?.POS}</p>
                        </div>
                        :
                        <></>
                    }
                    {editFormData?.reason_code_description ?
                        <div className="mb20">
                            <h6 className="fs14">Reason Code</h6>
                            <p className="fs13 fw500">{editFormData?.reason_code_description}</p>
                        </div>
                        :
                        <></>
                    }
                    <div className="mb20">
                        <h6 className="fs14">REMARK</h6>
                        <p className="fs13 fw500">{editFormData?.approval_remarks} {editFormData?.rejected_remark}</p>
                    </div>
                    {editFormData?.on_hold_remark ?
                        <div className="mb20">
                            <h6 className="fs14">On Hold/Unhold Remark</h6>
                            <p className="fs13 fw500">{editFormData?.on_hold_remark}</p>
                        </div>
                        :
                        <></>
                    }
                    <div className="mb20">
                        <h6 className="fs14">Last updated on:</h6>
                        <p className="fs13 fw500">{Moment(editFormData?.updated_on).format("YYYY-MM-DD")}</p>
                    </div>
                    <div className="form-group flex_item_cs gap10">
                        <Button type='button' className={"btn-white minw80"} onClick={() => notificationRightDrawer(false)}>
                            CANCEL
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar })(ViewAWVFileUpload);

