import { useState } from "react"
import { anchorTag, hccSeperator } from "../../assets/constants/customRender";
import { CARRIER_ID, FLAG, GAP_INSIGHTS,RECOMMENDATION_TYPE, GAP_ISSUER, GAP_METAL, GAP_MODEL, GAP_ORG, GAP_ORG_METAL, GAP_OR_REPORTED, GENDER, HCC, ISSUER_ID, LIKELIHOOD_FLAG, LIKELIHOOD_STATUS, MEMBER_ID, MEMBER_NAME, METAL_TIER, PLAN_ID, REPORTED_METAL, REPORTED_MODEL, RISK_MODEL } from "../../assets/constants/string.constants";
import { getAcaYear } from "../../assets/constants/yearList";
import CardComponent from "../../components/Cards/card";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import history from "../../history";
import {OpportunityFlag} from  "../../assets/constants/customRender";
import { connect } from 'react-redux';

const GapRecoMembersACA = (props) =>{

    const [metal_tier] = useState(localStorage.getItem(METAL_TIER)?localStorage.getItem(METAL_TIER):'GOLD');
    const [risk_model] = useState(localStorage.getItem(RISK_MODEL)?localStorage.getItem(RISK_MODEL):'ADULT');
    const [issuer_id] = useState(localStorage.getItem(ISSUER_ID));
    const [likelihood_flag] = useState(localStorage.getItem(LIKELIHOOD_FLAG));
    const [year] = useState(props.yearSelect);
    const [gap_or_reported] = useState(localStorage.getItem(GAP_OR_REPORTED));
    const [flag, setFlag] = useState(localStorage.getItem(FLAG)?localStorage.getItem(FLAG):'L');
    const [RecoType] = useState(localStorage.getItem(RECOMMENDATION_TYPE));
      
    const memberCols = [
        {
            label : "Member ID",
            name:"MEMBER_ID",
            options : anchorTag
        },
		{
            name : "GAP_HCC",
            label : "Gap HCC",
            options : anchorTag
        },
        
        {
            name : "GAP_IN_RISK_SCORE",
            label : "Gap Risk Score" 
             				
		},
        {
            name : "GAP_IN_HCC_SCORE",
            label : "GAP HCC Score"
        },
        {
            name : "NEW_RISK_SCORE",
            label : "Risk Score"
        },
        {
            name : "HCC_CAPTURE_RECOMMENDATION",
            label : "Recommendation"
        },
        {
            name : "OPPORTUNITY_FLAG",
            label : "Opportunity",
            options: OpportunityFlag
        },
        {
            name : "PROVIDER",
            label : "Provider Name"
        }
         
    ];

    const memberTableClick = (rowdata, colData, cellMeta) =>{
        // console.log("in table click");
        if((cellMeta.colIndex === 0)&&(colData.props.children != "")){
            localStorage.setItem(MEMBER_ID,rowdata[cellMeta.rowIndex].MEMBER_ID);
              localStorage.setItem(PLAN_ID,rowdata[cellMeta.rowIndex].PLAN_ID);
            localStorage.setItem(GENDER,rowdata[cellMeta.rowIndex].GENDER);
            localStorage.setItem(ISSUER_ID,rowdata[cellMeta.rowIndex].ISSUER_ID);
          
            history.push('/layout/acaMember', GAP_INSIGHTS)
        }
        else if((cellMeta.colIndex === 1)&&(colData.props.children != "")){
            if(typeof(colData.props.children) == "object"){
                localStorage.setItem(HCC,(cellMeta.event.target.innerText).replace(',',''));
            }
            else{
                localStorage.setItem(HCC,rowdata[cellMeta.rowIndex].GAP_HCC);
            }
            localStorage.setItem(ISSUER_ID,rowdata[cellMeta.rowIndex].ISSUER_ID);
            history.push('/layout/AcaGapHcc')
        }
    };

    return(
        <div>
            <CardComponent
            heading={`HCC RECOMENDATION - ${RecoType} - ${year} With Open Opportunity` }
            body={
                <ServerSideGridTable
                    url={'/AcaHccCaptureRecoDrillDown/year/'+year+'/type/'+RecoType+'/flag/'+1}
                    columns={memberCols}
                    tableClick={memberTableClick}
                ></ServerSideGridTable>
            }></CardComponent>
             <br/>
            <br/>     
            <CardComponent
            heading={`HCC RECOMENDATION - ${RecoType} - ${year} With Lost Opportunity` }
            body={
                <ServerSideGridTable
                    url={'/AcaHccCaptureRecoDrillDown/year/'+year+'/type/'+RecoType+'/flag/'+0 }
                    columns={memberCols}
                    tableClick={memberTableClick}
                ></ServerSideGridTable>
            }></CardComponent>
            <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
            <br/>

            <br/>
            <br/>  
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(GapRecoMembersACA);