import axios from "axios";

const AWVApi = axios.create({
  baseURL: "https://5lg6bp6uni.execute-api.us-west-2.amazonaws.com/v1",
  // baseURL: "http://54.224.35.50:3000/api",
  // baseURL: "http://44.193.160.138:8080/RAFScoreService",
});
//Both URL should be same
export const url = "https://5lg6bp6uni.execute-api.us-west-2.amazonaws.com/v1";
// export const url = "http://54.224.35.50:3000/api";
// export const url = "http://44.193.160.138:8080/RAFScoreService";
 
export default AWVApi;
