import { RAF_RANGE,MEMBER_ID } from "../../assets/constants/string.constants";
import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import CardComponent from "../../components/Cards/card";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag, hccSeperator, numberWithCommas ,numberWithCommasPrefixDolar,addSuffixTwoPercent} from '../../assets/constants/customRender';
import history from "../../history";
const PredictionRafDrillDown = () => {

    const rafRange = useState(localStorage.getItem(RAF_RANGE));
    let rafDRange = rafRange[0];

    const  memberMedicareCols =[
        {
            name: "MemberID",
            label: "Member ID",
            options:anchorTag
        },
        {
            name: "TOTAL_POTENTIAL_RAF_SCORE",
            label: "Potential RAF Score",
            options:addSuffixTwoPercent
        },
        {
            name: "NUMBER_OF_CONDITION_CATEGORIES",
            label: "No of Conditions"
        },
     
        {
            name: "TOTAL_POTENTIAL_REVENUE",
            label: "Total Revenue Impact",
            options:numberWithCommasPrefixDolar
        },

        
    ];
    const memberTableClick = (rowdata, colData, cellMeta) =>{
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
           localStorage.setItem(MEMBER_ID, rowdata[cellMeta.rowIndex].MemberID);
            history.push('/layout/medicare/PredictionMemberSummary')
        }
    }

return(
<div className="level-summary">
        <div className="row pb-2">
          <div className="col-lg-12">
            <div className="state-level">
              <div className="card-header">
                <span className="d-flex bd-highlight">
                    
                  <h6 className="pt-2 flex-grow-1 font-weight: bold !important">RAFDrillDown</h6>
                  <div className="d-flex align-items-center"></div>
                </span>
              </div>
            </div>
          </div>
        </div>  
     <CardComponent
         heading={`List of  Members with RAF Range of ${rafDRange}`}
         body={
                 <ServerSideGridTable
                 
                     url={`/PredictionRafDrillDown/${rafDRange}/`}
                     columns={memberMedicareCols}
                     tableClick={memberTableClick}
                 ></ServerSideGridTable>
             }>
     </CardComponent> 
 
<br />
<br />
</div>


);
};
export default PredictionRafDrillDown;
