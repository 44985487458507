import React, { useRef, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { signOut } from '../../../store/actions';
import { currentRoute } from '../../../store/actions';
import history from '../../../history';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ReactTooltip from 'react-tooltip';
import mainLogo from '../../../assets/images/Invent_logo_White.png';
import NavMenu from './navMenu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';
import ModuleSelect from '../../DropDowns/ModuleSelect';
import { getModuleList, getModuleSelect, ModuleList } from '../../../assets/constants/moduleList';
import api, { url } from '../../../assets/constants/Rafservice.Instance';
import { CenterFocusStrong } from "@material-ui/icons";
import Button from '@material-ui/core/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from "axios";
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import GrainIcon from '@mui/icons-material/Grain';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { WidthFull } from "@mui/icons-material";
import { width } from "@amcharts/amcharts4/.internal/core/utils/Utils";
import { ToastContainer, toast } from 'react-toastify';

const Awvheader = (props) => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [currentRoute, setCurrentRoute] = React.useState(null);

    useEffect(() => {
        setCurrentRoute(history?.location?.pathname);
    }, [history.location]);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const settings = ['Profile', 'Dashboard', 'Logout'];
    const handleClick = (event) => {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }


    const logout = () => {
        props.signOut();
        props.currentRoute('/layout/acaReportedMemberSummary');
        history.push('/');
        localStorage.clear();
    }

    return (
        <div style={{ flexGrow: 1, paddingLeft: '25px' }}>
            <div role="presentation" className="breadcrumb" >
                <Box display='flex' flexGrow={1}>
                    <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '5px' }}>
                        <Link
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                            color="inherit"
                            link={"/home"}
                            onClick={() => history.push('/home')}
                        >
                            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                            Home
                        </Link>
                        <Link
                            className="custom-active"
                            underline="hover"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="inherit"
                        >
                            {currentRoute && currentRoute == '/awe/awe_analytics_summary' ?
                                'AWE Analytics Summary'
                                : currentRoute && currentRoute == '/awe/awe_form_list' ?
                                    'AWE Record'
                                    : currentRoute && currentRoute == '/awe/AWEMemberDetails' ?
                                        'AWE Universal Report'
                                        :
                                        'AWE Analytics Summary'
                            }
                        </Link>
                        {/* <Typography
                        sx={{ display: 'flex', alignItems: 'center' }}
                        color="text.primary"
                    >
                        <GrainIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                        Breadcrumb
                    </Typography> */}
                    </Breadcrumbs>
                </Box>
                <div className="mr-5 mt-2 header-notification-badge">
                    {/* <Badge badgeContent={4} color="error" > */}
                    <Badge  color="error" >
                        <NotificationsIcon style={{ color: "#1A9698" }} onClick={props.notificationRightDrawer(true)} />
                    </Badge>
                </div>
                <div>
                    <Box sx={{ flexGrow: 1 }}>
                        <div className="row" style={{ cursor: "pointer" }} onClick={handleOpenUserMenu}>
                            <Tooltip title="Open settings">
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-haspopup="true"
                                    color="inherit"
                                    sx={{ p: 1 }}
                                    style={{ backgroundColor: '#1a9698', color: "#fff", borderRadius: '5px' }}
                                >
                                    <AccountCircle />
                                </IconButton>
                            </Tooltip>
                            <div style={{ width: '100px' }}>
                                <ul style={{ listStyleType: 'none', marginLeft: "-35px", marginRight: "15px", height: '25px', width: "30px" }}>
                                    <li style={{ fontSize: "15px", color: "#1a9698" }} title={props?.userDetails?.userEmail}>{props?.userDetails?.userEmail.split('@')[0]}</li>
                                    <li style={{ fontSize: "10px", color: "#B0BFCD" }} title={'Provider'}>Provider</li>
                                </ul>
                            </div>
                            {anchorElUser ?
                                <IconButton
                                    color="inherit"
                                    edge="start"
                                    style={{ color: '#B0BFCD' }}
                                >
                                    <ExpandLessIcon />
                                </IconButton>
                                :
                                <IconButton
                                    color="inherit"
                                    edge="start"
                                    style={{ color: '#B0BFCD' }}
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            }
                        </div>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {/* {settings.map((setting) => ( */}
                            <MenuItem key={'Profile'} onClick={handleCloseUserMenu}>
                                <Typography textAlign="center">Profile</Typography>
                            </MenuItem>
                            <MenuItem key={'Dashboard'} onClick={() => history.push('/awe/awe_analytics_summary')} href={() => false}>
                                <Typography textAlign="center">Dashboard</Typography>
                            </MenuItem>
                            <MenuItem key={'Logout'} onClick={() => logout()} href={() => false}>
                                <Typography textAlign="center">Logout</Typography>
                            </MenuItem>
                            {/* ))} */}
                        </Menu>
                    </Box>
                </div>
            </div >
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div >
    );
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        userDetails: state.auth?.userDetails,
        route: state.route.route
    };
}
export default connect(mapStateToProps, { signOut, currentRoute })(Awvheader);
