import { getAcaYear, getAcaYearList } from "../../assets/constants/yearList";
import AcaYearSelect from "../../components/DropDowns/AcaYearSelect"
import React, { useState, useEffect } from "react";
import api from "../../assets/constants/Rafservice.Instance";
import TripleVerticalBarChart from "../../components/Charts/TripleVerticalBar.chart";
import FourVerticalBarChart from "../../components/Charts/FourlVerticalBar.chart";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag } from "../../assets/constants/customRender";
import history from "../../history";
import { DISCREPANCY_REASON } from "../../assets/constants/string.constants";
import CardComponent from "../../components/Cards/card";
import BarGChart from '../../components/GChart/BarGChart';
import SubHeader from '../../components/home/header/subHeader';
import { connect } from 'react-redux';
import CardComponentMultiLine from "../../components/Cards/CardMultiLine";

const RarsdComparisonACA = (props) => {

    const [year, setYear] = useState(props.yearSelect);
    const [riskScore, setriskScore] = useState({});
    const [riskScoreData1, setRiskScoreData1] = useState([]);
    const [riskScoreData2, setRiskScoreData2] = useState([]);
    const [riskScoreData3, setRiskScoreData3] = useState([]);
    const [count, setCount] = useState({});
    const [distribution, setDistribution] = useState({});

    const loadCharts = () => {
        let issuerId = [];
        let adultRiskScore = [];
        let childRiskScore = [];
        let infantRiskScore = [];
        let adultCount = [];
        let childCount = [];
        let infantCount = [];
        let discrepencyCount = [];
        let memberRejectionCount = [];
        let nonHccDiscrepencyCount = [];
        let unavailableInMapCount = [];

        let chartDataRow1 = [];
        let chartDataRow2 = [];
        let chartDataRow3 = [];
        api.get('/DiscrepencySummary/year/' + props.yearSelect)
            .then(res => {
                // console.log("code", res);
                let IssID = "" + res[0].issuerId;
                chartDataRow1.push([' ', "Adult", { role: 'annotation' }, "Child", { role: 'annotation' }, "Infant", { role: 'annotation' }]);
                chartDataRow2.push([' ', "Adult", { role: 'annotation' }, "Child", { role: 'annotation' }, "Infant", { role: 'annotation' }]);
                chartDataRow3.push([' ', "Discrepancy (HCC)", { role: 'annotation' }, "Missing (RARSD)", { role: 'annotation' }, "Discrepancy (nonHCC)", { role: 'annotation' }, "Missing (MAP)", { role: 'annotation' }]);
                for (const dataObj of res) {
                    issuerId.push(dataObj.issuerId);
                    adultRiskScore.push(dataObj.adultRiskScore);
                    childRiskScore.push(dataObj.childRiskScore);
                    infantRiskScore.push(dataObj.infantRiskScore);
                    adultCount.push(dataObj.adultCount);
                    childCount.push(dataObj.childCount);
                    infantCount.push(dataObj.infantCount);
                    chartDataRow1.push([dataObj.issuerId.toString(), parseFloat(dataObj.adultRiskScore), parseFloat(dataObj.adultRiskScore), parseFloat(dataObj.childRiskScore), parseFloat(dataObj.childRiskScore), parseFloat(dataObj.infantRiskScore), parseFloat(dataObj.infantRiskScore)]);
                    chartDataRow2.push([dataObj.issuerId.toString(), dataObj.adultCount, dataObj.adultCount, dataObj.childCount, dataObj.childCount, dataObj.infantCount, dataObj.infantCount]);
                    chartDataRow3.push([dataObj.issuerId.toString(), dataObj.discrepencyCount, dataObj.discrepencyCount, dataObj.memberRejectionCount, dataObj.memberRejectionCount, dataObj.nonHccDiscrepencyCount, dataObj.nonHccDiscrepencyCount, dataObj.unavailableInMapCount, dataObj.unavailableInMapCount])

                    discrepencyCount.push(dataObj.discrepencyCount);
                    memberRejectionCount.push(dataObj.memberRejectionCount);
                    nonHccDiscrepencyCount.push(dataObj.nonHccDiscrepencyCount);
                    unavailableInMapCount.push(dataObj.unavailableInMapCount);
                }
                setRiskScoreData1(chartDataRow1);
                setRiskScoreData2(chartDataRow2);
                setRiskScoreData3(chartDataRow3);
                setriskScore({
                    label: issuerId,
                    data1: adultRiskScore,
                    data2: childRiskScore,
                    data3: infantRiskScore,
                    dLabel1: 'Adult',
                    dLabel2: 'Child',
                    dLabel3: 'Infant'
                });

                setCount({
                    label: issuerId,
                    data1: adultCount,
                    data2: childCount,
                    data3: infantCount,
                    dLabel1: 'Adult',
                    dLabel2: 'Child',
                    dLabel3: 'Infant'
                })

                setDistribution({
                    label: issuerId,
                    data1: discrepencyCount,
                    data2: memberRejectionCount,
                    data3: nonHccDiscrepencyCount,
                    data4: unavailableInMapCount,
                    dLabel1: 'Discrepancy (HCC)',
                    dLabel2: 'Missing (RARSD)',
                    dLabel3: 'Discrepancy (nonHCC)',
                    dLabel4: 'Missing (MAP)'
                })
            })
            .catch(err => {
                // console.log(err);
            })


    }

    const discrerpancy = [
        {
            label: "Plan Id",
            name: "PLAN_ID"
        },
        {
            label: "Model",
            name: "RISK_MODEL"
        },
        {
            label: "% Risk Score Gap",
            name: "PERCENT_RISK_SCORE",
        },
        {
            label: "Total Risk Score",
            name: "TOTAL_RISK_SCORE",
        },
        {
            label: "Member Missing (RARSD)",
            name: "MEMBER_REJECTED",
            options: anchorTag
        },
        {
            label: "Member Discrepancy (nonHCC)",
            name: "NON_HCC_DISCREPANCY",
            options: anchorTag
        },
        {
            label: "Member Discrepancy (HCCs)",
            name: "DISCREPANT",
            options: anchorTag
        },
        {
            label: "Member Missing (MAP)",
            name: "UNAVAILABLE_IN_MAP",
            options: anchorTag
        }
    ];

    const rarsdTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 4) && (colData.props.children != "")) {
            localStorage.setItem(DISCREPANCY_REASON, 'MEMBER_REJECTED');
            history.push('/layout/acaRARsdDiscrepancyDetails')
        }
        else if ((cellMeta.colIndex === 5) && (colData.props.children != "")) {
            localStorage.setItem(DISCREPANCY_REASON, 'NON_HCC_DISCREPANCY');
            history.push('/layout/acaRARsdDiscrepancyDetails')
        }
        else if ((cellMeta.colIndex === 6) && (colData.props.children != "")) {
            localStorage.setItem(DISCREPANCY_REASON, 'DISCREPANT');
            history.push('/layout/acaRARsdDiscrepancyDetails')
        }
        else if ((cellMeta.colIndex === 7) && (colData.props.children != "")) {
            localStorage.setItem(DISCREPANCY_REASON, 'UNAVAILABLE_IN_MAP');
            history.push('/layout/acaRARsdDiscrepancyDetails')
        }
    }

    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            loadCharts();
        }
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect
    ]);

    return (
        <div className="level-summary mb-1">
            <div className="row">
                <div className="col-lg-12">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'RARSD Comparison'} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-1">
                <div className="col-sm-4 issuer-box">
                    <CardComponent
                        heading={`RISK SCORE DISCREPANCY % BY ISSUER`}
                        body={
                            <BarGChart data={riskScoreData1} className="bar_chart" chartType="ColumnChart" height="360px" width="100%"></BarGChart>
                        }></CardComponent>
                </div>
                <div className="col-sm-4 issuer-box">
                    <CardComponent
                        heading={`MEMBERS WITH RISK SCORE DISCREPANCY`}
                        body={
                            <BarGChart data={riskScoreData2} chartType="ColumnChart" className="bar_chart" height="360px" width="100%"></BarGChart>
                        }></CardComponent>
                </div>
                <div className="col-sm-4 issuer-box">
                    <CardComponent
                        heading={`DISTRIBUTION OF MEMBERS BY DISCREPANCY`}
                        body={
                            <BarGChart data={riskScoreData3} chartType="ColumnChart" className="bar_chart bar_chart_transform" height="360px" width="100%"></BarGChart>
                        }></CardComponent>
                </div>
            </div>
            {/* 
            <div className="row">
                <div className="col-sm-4">
                    <CardComponent
                    heading={`RISK SCORE DISCREPANCY % BY ISSUER`}
                    body={
                        <TripleVerticalBarChart
                            labels={riskScore.label}
                            Data1={riskScore.data1}
                            Data2={riskScore.data2}
                            Data3={riskScore.data3}
                            dLabel1={riskScore.dLabel1}
                            dLabel2={riskScore.dLabel2}
                            dLabel3={riskScore.dLabel3}
                        ></TripleVerticalBarChart>
                    }></CardComponent>
                </div>
                <div className="col-sm-4">
                    <CardComponent
                    heading={`MEMBERS WITH RISK SCORE DISCREPANCY`}
                    body={
                        <TripleVerticalBarChart
                            labels={count.label}
                            Data1={count.data1}
                            Data2={count.data2}
                            Data3={count.data3}
                            dLabel1={count.dLabel1}
                            dLabel2={count.dLabel2}
                            dLabel3={count.dLabel3}
                        ></TripleVerticalBarChart>
                    }></CardComponent>
                </div>
                <div className="col-sm-4">
                    <CardComponent
                    heading={`DISTRIBUTION OF MEMBERS BY DISCREPANCY`}
                    body={
                        <FourVerticalBarChart
                            labels={distribution.label}
                            Data1={distribution.data1}
                            Data2={distribution.data2}
                            Data3={distribution.data3}
                            Data4={distribution.data4}
                            dLabel1={distribution.dLabel1}
                            dLabel2={distribution.dLabel2}
                            dLabel3={distribution.dLabel3}
                            dLabel4={distribution.dLabel4}
                        ></FourVerticalBarChart>
                    }></CardComponent>
                </div>
            </div> */}

            <br />
            <div className="OverallDiscrepency pb-5">
                {props.yearSelect ?
                    <CardComponent
                        heading={`OVERALL DISCREPANCY`}
                        body={
                            <ServerSideGridTable
                                url={`/OverallDiscrepency/year/${props.yearSelect}`}
                                columns={discrerpancy}
                                tableClick={rarsdTableClick}
                            ></ServerSideGridTable>
                        }></CardComponent>
                    :
                    <></>
                }
            </div>
            <br />
            <br />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
    };
}
export default connect(mapStateToProps)(RarsdComparisonACA);
