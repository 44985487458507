import React, { useState, useEffect } from "react";
import history from "../../history";
import { getAcaYear, getAcaYearList } from "../../assets/constants/yearList";
import api from '../../assets/constants/Rafservice.Instance';
import { anchorTag } from "../../assets/constants/customRender";
import { PROVIDER_ID, SERVICE_CATEGORY } from "../../assets/constants/string.constants";
import { FLAG, ISSUER_ID, UTILIZATION, WIDGET_TYPES, MEMBER_ID, MEMBER_NAME, CARRIER_ID, PLAN_ID, GENDER, MAO_RISK_CONDITION_CHART,MAO_Chart_Review_Member, MAO_MEMBER_SELECT_TAB } from "../../assets/constants/string.constants";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import CardComponent from "../../components/Cards/card";    
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import ServerSideGridTable from '../../components/TableGrids/ServerSideGridTable.table';
import { connect } from 'react-redux';
const SUProviderDetails = (props) => {

    const [year, setYear] = useState(getAcaYear());
    const [hcc, setHcc] = useState( localStorage.getItem("hcc"));
    const [WidgetDetails, setWidgetDetails] = useState({});
    const[providerId,setProviderId] = useState(localStorage.getItem(PROVIDER_ID));
    

    
    const WidgetDetailsType = [
        WIDGET_TYPES.LOCKICON,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.PERCENT,
        WIDGET_TYPES.SCORE,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.TRENDING,
        WIDGET_TYPES.PERCENT,
    ]

    const type = WidgetDetailsType;
    const widgetStyles = {
        position: 'absolute',
        left: 0,
        //right: 20,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.15,
        color: "white"
    };


    const getIcon = (type) => {
        var data = ''
        switch (type) {
            case WIDGET_TYPES.ISSUER:
                data = <AccountBalanceTwoToneIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.MEMBERS:
                data = <PeopleAltIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERCENT:
                data = <ShowChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.SCORE:
                data = <BarChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERSON:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.CHILD:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.ADULT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.INFANT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.REVENUE:
                data = <AttachMoneyIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.COUNT:
                data = <ShoppingCartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.LOCKICON:
                data = <LockOpenIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.TRENDING:
                data = <TrendingUpIcon style={widgetStyles} />
                break;
        }

        return data;
    }

    useEffect(() => { 
         
        loadWidgetDetails();
      }, []);
 
    const loadWidgetDetails = () => {
        //ProviderDetails/year/2019/providerId/01006H
        api.get('/ProviderDetails/year/'+props.yearSelect+'/providerId/'+providerId)
        .then(res => {
            setWidgetDetails({
                 'Provider ID': (res[0].providerID),
                 'Utilization': '$'+ Number(res[0].utilization).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                '% Of Overall Utilization':  Number(res[0].overallPercentageOfUtilization).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+' %',
                'No of Members': Number(res[0].memberCount).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                'No of Service Rendered': Number(res[0].numOfServicesRendered).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            });
        })
        .catch(err => {
            // console.log(err);
        });
    };


    // const memberTableClick = (rowdata, colData, cellMeta) => {
    //     if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            
    //        localStorage.setItem(MEMBER_ID,rowdata[cellMeta.rowIndex].memberID);
    //         history.push('/layout/ServiceCategoryMember');
    //     }
    // };

    const memberTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
           
            //localStorage.setItem(ISSUER_ID, issuer);
           // localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].code);
           //localStorage.setItem(MEMBER_ID,rowdata[cellMeta.rowIndex].memberID);
           localStorage.setItem(MAO_Chart_Review_Member, rowdata[cellMeta.rowIndex].memberID)
            //history.push('/layout/ServiceCategoryMember');
           localStorage.setItem(MAO_MEMBER_SELECT_TAB, UTILIZATION);
           history.push('/layout/medicare/mao_member_summary');
        }
    };


     

    const memberColumns = [
        {
            label: "MemberID",
            name: "memberID",
            options: anchorTag
        },
        {
            label: "Utilization",
            name: "utilization",
        },
        {
            label: "% Contribution of Member  to Overall  Service Code Utilization",
            name: "percentageContribution",
        },
    ];

    const ServiceDataColumns = [ {
        label : "Service Category",
         name  : "serviceCategory",
         options: anchorTag
     },
     {
        label : "Description",
         name:"description"
     },
     {
        label : "Utilization",
         name:"utilization"
     },
     {
        label : "% of Overall Utilization",
         name  : "percentageContribution"
     },
     {
        label : "No of Members",
         name  : "memberCount" 
       //  render: $.fn.dataTable.render.number( ',', '.', 0 )
     }];


    const providerColumns = [
        {
            label: "Provider ID",
            name: "PROVIDER_NUMBER",
             
        },
        {
            label: "Utilization For Specific Service",
            name: "UTILIZATIONSERVICECATEGORY",
        },
        {
            label: "Number Of Visits",
            name: "NUMBEROFVISITS",
        },
        {
            label: "Average Utilization For Service",
            name: "AVERAGEUTILIZATION",
        },
    ];
 

    const SCTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
    
          //localStorage.setItem(ISSUER_ID, issuer);
          // localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].code);
          localStorage.setItem(SERVICE_CATEGORY, rowdata[cellMeta.rowIndex].serviceCategory);
          //console.log(rowdata[cellMeta.rowIndex].serviceCategory);
          history.push('/layout/ServiceCategory')
        }
      };

    return(<div> 
         <div className="col-lg-12 pr-0 pl-0">
                    <div className="state-level">          
                        {(Object.entries(WidgetDetails).length > 0) ?                     
                            <SlickSlider WidgetDetails={WidgetDetails} getIcon={getIcon} type={type} />
                        :
                            <></>
                        }                       
                    </div>
                </div>
       

<CardComponent
            heading={`SERVICE LIST`}
            body={
                <ServerSideGridTable
                url={'/ProviderServiceDetails/'+year+'/providerId/'+providerId}
                columns={ServiceDataColumns}
                tableClick={SCTableClick}
                ></ServerSideGridTable>
            }></CardComponent>
            <br />
            <br/>
            <CardComponent
        heading={`MEMBER LIST`}
        body={   
                <ServerSideGridTable
                    url={`/ProviderMemberDetails/${props.yearSelect}/providerId/${providerId}`}
                    columns={memberColumns}
                    tableClick={memberTableClick}
                    defaultSortCol={0}
                    defaultSortDir={'desc'}
                ></ServerSideGridTable>
        }></CardComponent>
         <br/>
                <br/> 
</div>)

}; 
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps) (SUProviderDetails );

