import React, { useRef, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { signOut } from '../../../store/actions';
import { currentRoute } from '../../../store/actions';
import SlickSlider from '../../../components/SlickSlider/SlickSlider';
import {WIDGET_TYPES } from "../../../assets/constants/string.constants";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@mui/icons-material/Person';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
import SettingsIcon from '@mui/icons-material/Settings';

const AdminSlider = (props) => {

    const [WidgetDetails, setWidgetDetails] = useState({
        '# ROLES': 1,
        '# USERS': 123,
        'GROUP': 10,
        'POLICY': 10,
    });


    const getIcon = (type) => {
        var data = ''
        switch (type) {
            case WIDGET_TYPES.ISSUER:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.SCORE:
                data = <GroupIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERCENT:
                data = <SummarizeIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.MEMBERS:
                data = <SettingsIcon style={widgetStyles} />
                break;
        }

        return data;
    }

    const WidgetDetailsType = [        
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.ISSUER,
        WIDGET_TYPES.SCORE,
        WIDGET_TYPES.PERCENT,
    ]

    const type = WidgetDetailsType;
    const widgetStyles = {
        position: 'absolute',
        left: 0,
        //right: 20,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.15,
        color: "white"
    };

    return (
        <div className='col-lg-12 pr-0 pl-0'>
            <SlickSlider WidgetDetails={WidgetDetails} getIcon={getIcon} type={type} />
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        userName: state.auth?.userDetails?.userEmail,
        route: state.route.route,
        moduleSelect: state.moduleFilter.moduleSelect,
    };
}
export default connect(mapStateToProps, { signOut, currentRoute })(AdminSlider);
