import PropTypes from "prop-types";
import {HorizontalBar} from 'react-chartjs-2';
import checkFloat from "../../assets/helpers/checkFloat";


const SingleHorizontalBar = (props) =>{

    const { label, Data, dLabel,onChartClick,yLabel,xLabel,xTick,yTick,suffix} = props;

    const chartData = {
            labels: label,
            datasets: [
                {
                    label: dLabel,
                    data: Data,
                    backgroundColor: "#5FBEFB",
                    suffix:suffix
                }
            ]
    }

    const defaultOptions = {
       
        maintainAspectRatio: true,
        
        onHover: (event, chartElement) => {
            event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
        
        scales: {
            xAxes: [{
                gridLines: {
                    drawBorder: true,
                    drawOnChartArea: false
                    
                },
                scaleLabel: {
                    display: xLabel ? true:false,
                    labelString: xLabel
                },
                ticks: {
                    callback: function(value, index, values) {
                        if(value>999)
                            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ (xTick?xTick:'');
                        else if(checkFloat(value))
                            return value.toFixed(1)+(xTick?xTick:'');
                        else
                            return value+(xTick?xTick:'');
                    }
                },
               
            }],
            yAxes: [{
                gridLines: {
                    drawBorder: true,
                    drawOnChartArea: false
                            
            },
            scaleLabel: {
                display: yLabel ? true:false,
                labelString:  yLabel 
            },
            ticks: {
                    callback: function(value, index, values) {
                        if(value>999)
                            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")+ (yTick?yTick:'');
                        else if(checkFloat(value))
                            return value.toFixed(1)+(yTick?yTick:'');
                        else
                            return value+(yTick?yTick:'');
                    }
            },
            }
            ]
        }
    };

    const datasetKeyProvider=()=>{ 
     return btoa(Math.random()).substring(0,12)
 }
    
    const handleClick = (element) => {
        if(props.onChartClick && element[0]){
            onChartClick(element);
        }
    }
    
    return(

        <div>
            <HorizontalBar
            data={chartData}
            options={defaultOptions}  
            width={400}
            height={150}
            datasetKeyProvider={datasetKeyProvider}
            onElementsClick ={handleClick}
            />
        </div>
    )
}

SingleHorizontalBar.propTypes ={
    label : PropTypes.array,
    Data : PropTypes.array,
    dLabel :PropTypes.string,
    onChartClick :PropTypes.func,    
    yLabel: PropTypes.string,
    xLabel: PropTypes.string,
    xTick: PropTypes.string,
    yTick: PropTypes.string,
    suffix: PropTypes.string
}
export default SingleHorizontalBar;