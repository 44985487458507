import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import api from '../../assets/constants/Rafservice.Instance';
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import CardComponent from "../../components/Cards/card";
import { anchorTag, hccSeperator, numberWithCommasm, stringFold, addSuffixTwoDecimal } from '../../assets/constants/customRender'
import history from "../../history";
import { MAO_Chart_Review_Member, HCC, WIDGET_TYPES } from "../../assets/constants/string.constants";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import LockOpenIcon from '@material-ui/icons/LockOpen';
import SubHeader from '../../components/home/header/subHeader';
import { getMeInitialSelect } from '../../assets/constants/meInitialList';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';

const MaoChartReviewMemberSummaryPage = (props) => {
    const [WidgetDetails, setWidgetDetails] = useState({});
    //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            loadWidgetDetails();
        }
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect()
    ]);

    const loadWidgetDetails = () => {
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/chartReviewMemberSummary/year/' + getAcaYear() + '/period/' + getMeInitialSelect())
            .then(res => {
                setWidgetDetails({
                    '# Total Members': Number(res[0].totalMembersForYear).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    '# Members With CR': Number(res[0].membersWithCR).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    'CR Corrected Revenue': '$' + Number(res[0].crCorrectedRevenue).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    'Overall Revenue With CR': '$' + Number(res[0].overallRevenueWithCR).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    '% Of RAF Score Contributed By CR': '$' + Number(res[0].perOfRAFScoreContributedByCR).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                });
            })
            .catch(err => {
                // console.log(err);
            });
    };

    const WidgetDetailsType = [
        WIDGET_TYPES.LOCKICON,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.PERCENT,
        WIDGET_TYPES.SCORE,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.TRENDING,
        WIDGET_TYPES.PERCENT,
    ]

    const type = WidgetDetailsType;

    const widgetStyles = {
        position: 'absolute',
        left: 0,
        //right: 20,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.15,
        color: "white"
    };

    const getIcon = (type) => {
        var data = ''
        switch (type) {
            case WIDGET_TYPES.ISSUER:
                data = <AccountBalanceTwoToneIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.MEMBERS:
                data = <PeopleAltIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERCENT:
                data = <ShowChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.SCORE:
                data = <BarChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERSON:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.CHILD:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.ADULT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.INFANT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.REVENUE:
                data = <AttachMoneyIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.COUNT:
                data = <ShoppingCartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.LOCKICON:
                data = <LockOpenIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.TRENDING:
                data = <TrendingUpIcon style={widgetStyles} />
                break;
        }

        return data;
    }

    const memberSummaryColumn = [
        {
            label: "Member ID",
            name: "MemberID",
            options: anchorTag
        },
        {
            name: "ReportedHCCS",
            label: "Reported HCCs",
            options: hccSeperator
        },
        {
            name: "AddedHccs",
            label: "Added HCCs",
            options: hccSeperator
        },
        {
            name: "DeletedHccs",
            label: "Deleted HCCs",
            options: hccSeperator
        },
        {
            name: "ReportedRAF",
            label: "Reported RAF",
            options: addSuffixTwoDecimal,
        },
        {
            name: "CorrectionInRAF",
            label: "RAF Impact due to CR",
            options: addSuffixTwoDecimal,
        },
        {
            name: "CorrectedOverallRevenue",
            label: "Overall Revenue Post CR",
        },
        {
            name: "RevenueCorrection",
            label: "Revenue Impact due to CR",
        },
        {
            name: "ChartReviewAsAPercentageOfCorrectedRevenue",
            label: "% Contribution by CR",
        }
    ];

    const memberSummaryTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(MAO_Chart_Review_Member, rowdata[cellMeta.rowIndex].MemberID);
            history.push('/layout/medicare/mao_member_summary')
        } else if ((cellMeta.colIndex === 1) && (colData.props.children != "")) {
            if (typeof (colData.props.children) == "object") {
                localStorage.setItem(HCC, (cellMeta.event.target.innerText).replace(',', ''));
            }
            else {
                localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].ReportedHCCS);
            }
            history.push('/layout/medicare/reported_hcc')
        } else if ((cellMeta.colIndex === 2) && (colData.props.children != "")) {
            if (typeof (colData.props.children) == "object") {
                localStorage.setItem(HCC, (cellMeta.event.target.innerText).replace(',', ''));
            }
            else {
                localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].AddedHccs);
            }
            history.push('/layout/medicare/chart_review_hcc_add')
        } else if ((cellMeta.colIndex === 3) && (colData.props.children != "")) {
            if (typeof (colData.props.children) == "object") {
                localStorage.setItem(HCC, (cellMeta.event.target.innerText).replace(',', ''));
            }
            else {
                localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].DeletedHccs);
            }
            history.push('/layout/medicare/chart_review_hcc_delete')
        }
    }

    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Member Summary'} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0">
                    <div className="state-level">
                        {(Object.entries(WidgetDetails).length > 0) ?
                            <SlickSlider WidgetDetails={WidgetDetails} getIcon={getIcon} type={type} />
                            :
                            <></>
                        }
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-4">
                    <CardComponent
                        heading={`LIST OF MEMBER(S)`}
                        body={
                            <ServerSideGridTable
                                url={`/crMemberSummary/year/${getAcaYear()}/period/${getMeInitialSelect()}/max/149.7`}
                                columns={memberSummaryColumn}
                                tableClick={memberSummaryTableClick}
                            ></ServerSideGridTable>
                        }>
                    </CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br />

                <br />
                <br />

            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default connect(mapStateToProps)(MaoChartReviewMemberSummaryPage);
