import { useState, useEffect } from 'react';
import { anchorTag, numberWithCommas } from '../../assets/constants/customRender';
import { getAcaIssuer, getAcaIssuerList } from '../../assets/constants/issuerList';
import api from '../../assets/constants/Rafservice.Instance';
import SingleHorizontalBar from '../../components/Charts/SingleHorizontalBar.chart';
import history from '../../history';
import ServerSideGridTable from '../../components/TableGrids/ServerSideGridTable.table';
import { HCC, ISSUER_ID } from '../../assets/constants/string.constants';
import CardComponent from '../../components/Cards/card';
import BarVerticalHCC from '../../components/GChart/BarVerticalHCC';
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';
import Gradient from "javascript-color-gradient";
import MAOHighFreqencyChart from "../../components/Charts/MAOHighFreqencyChart";
import { getMeInitialSelect } from '../../assets/constants/meInitialList';
import { getAcaYear } from '../../assets/constants/yearList';

const MaoReportedHccSummaryPage = (props) => {

    const [HccChartData, setHccChartData] = useState([]);
    const [ChartDataAM4, setChartDataAM4] = useState([]);
    const [type, setType] = useState('');
    const colorGradient = new Gradient();
    colorGradient.setGradient("#006C90", "#A3C3CF");

    const loadHccChartIssuer = () => {
        let chartDataRow = [];
        let newAm4ChartData = [];
        chartDataRow.push(["HCC's", "Member Count"]);
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/ReportedhccByYear/year/' + getAcaYear() + '/period/' + getMeInitialSelect())
            .then(res => {
                for (const dataObj of res) {
                    chartDataRow.push([dataObj.description + ':' + dataObj.hccCode, dataObj.totalCount]);
                }
                let rowData = res;
                colorGradient.setMidpoint(rowData.length);
                const colorArr = colorGradient.getArray();
                for (let i = 0; i < rowData.length; i++) {
                    newAm4ChartData.push({ "key": rowData[i].hccCode, "data": parseInt(rowData[i].totalCount), "description": rowData[i].description, "color": colorArr[i] });
                }
                setHccChartData(chartDataRow);
                setChartDataAM4(newAm4ChartData);

            })

    }

    const hccMedicareColumns = [
        {
            label: "Code",
            name: "code",
            options: anchorTag
        },
        {
            label: "Description",
            name: "description",
        },
        {
            label: "# Total Member",
            name: "count",
        },
    ]

    const hccTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].code);
            history.push('/layout/medicare/reported_hcc');
        }
    };
    //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            loadHccChartIssuer();
        }
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
    ])


    return (
        <div className="level-summary">
            <div className="row pb-2">
                <div className="col-lg-12">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Reported HCC Summary'} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row frequency-report" >
                <MAOHighFreqencyChart data={ChartDataAM4} type={type} year={props.yearSelect} />
            </div>
            {/* <CardComponent
                heading={`HIGH FREQUENCY REPORTED HCC`}
                body={
                    <BarVerticalHCC data={HccChartData} chartType="BarChart" height="400px" width="100%" setStorgeItemName={HCC} redirectUrl={'medicare/reported_hcc'}></BarVerticalHCC>
                }></CardComponent> */}
            <br />
            <div className="hcc-list pb-5">
                <CardComponent
                    heading={`HCC LIST`}
                    body={
                        <ServerSideGridTable
                            url={`/hccsByReoprted/${getAcaYear()}/period/${getMeInitialSelect()}`}
                            columns={hccMedicareColumns}
                            tableClick={hccTableClick}
                            defaultSortCol={0}
                            defaultSortDir={'desc'}
                        ></ServerSideGridTable>
                    }></CardComponent>
            </div>
            <br />
            <br />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default connect(mapStateToProps)(MaoReportedHccSummaryPage);
