import axios from "axios";
const adminApi = axios.create({
  baseURL: "https://qa.admin.map.ihapps.ai/api",
  // baseURL: "http://54.224.35.50:3000/api",
  // baseURL: "http://44.193.160.138:8080/RAFScoreService",
});
//Both URL should be same
export const url = "https://qa.admin.map.ihapps.ai/api";
// export const url = "http://54.224.35.50:3000/api";
// export const url = "http://44.193.160.138:8080/RAFScoreService";
 
export default adminApi;