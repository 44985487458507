import React, { useRef, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../store/actions';
import AWVCoderWorkBench from '../../assets/constants/AWVCoderWorkBenchRafservice.Instance';
import Divider from '@mui/material/Divider';
import Moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import { CircularProgress } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { styled } from '@mui/material/styles';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const StatusMEDICATIONCoderWorkBench = (props) => {
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [formDataList, setFormDataList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
    const [editFormData, setEditFormData] = useState({});

    useEffect(() => {
        loadformDataTable(props.Id);
        editLoadFormData(props.Id);
    }, [props.Id]);

    useEffect(() => {
        if (editFormData?.id && editFormData?.id != '') {
            setValue('id', editFormData?.id);
            setValue('status', editFormData?.status);
            setValue('remarks', editFormData?.remarks);
            let controlUser = JSON.parse(localStorage.getItem('user'));
            //setValue('updated_on', Moment().format('YYYY-MM-DD HH:mm:ss'));
            setValue('updated_by', controlUser?.userEmail);
        }
    }, [editFormData]);

    const notificationRightDrawer = (open, valueId = '') => {
        props.currentAWERightSidebarType({ 'type': 'notification' });
        props.currentAWERightSidebar(open);
    };

    const editLoadFormData = (id) => {
        setIsLoading(true);
        AWVCoderWorkBench.get('data/medication/' + id)
            .then(res => {
                console.log(res.data);
                if (res.data && res.data) {
                    setEditFormData(res.data);
                    setIsLoading(false);
                } else {
                    setEditFormData();
                    setIsLoading(false);
                }
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
            })
    }

    const loadformDataTable = (id) => {
        setIsLoading(true);
        AWVCoderWorkBench.get('data/cwb/history/medication/' + id) //'&provider_group=' + props?.providerGroupSelect
            .then(res => {
                console.log(res.data);
                setFormDataList(res.data);
                // setFormDataList([
                //     {
                //         'created_on': '11 Jan 2023',
                //         'notes': 'test',
                //         'created_by': 'test',
                //     },
                //     {
                //         'created_on': '11 Jan 2023',
                //         'notes': 'test2',
                //         'created_by': 'test2',
                //     },
                // ]);
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    }


    const onSubmit = (data) => {
        console.log(data);
        setFormSubmitisLoading(true);
        AWVCoderWorkBench.post('data/medication/' + props.Id, data)
            .then(res => {
                setValue('id', '');
                setValue('cw_proc_status', '');
                setValue('updated_on', '');
                setValue('updated_by', '');
                setFormSubmitisLoading(false);
                toast.success('Status updated successfully');
                props.currentAWERightSidebarCloseDatatableReload(true);
                notificationRightDrawer(false);
            })
            .catch(err => {
                setFormSubmitisLoading(false);
                toast.error(err?.response?.data?.message);
            })
    }

    return (
        <div style={{ padding: "10px 0px" }}>
            <div key={'index'} variant={'head'} style={{ width: '350px', padding: "10px", height: "50px" }}>
                <div class="float">
                    <div class="float-left">
                        <span><b>Status MEDICATION </b></span>
                    </div>
                    <div class="float-right">
                        <Tooltip title="Close">
                            <CancelIcon style={{ color: "#1A9698", cursor: "pointer" }} onClick={() => notificationRightDrawer(false)} />
                        </Tooltip>
                    </div>
                </div>
            </div>
            <Divider />
            <div>
                <div class="awv-recored-right-sidebar-form mt-3">
                    <div variant={'head'} style={{ width: '350px' }}>
                        <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                            <div className="col-lg-12 align-items-center mb-3 mt-3">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1" >Status</InputLabel>
                                <FormControl fullWidth margin="dense" variant="outlined" className="mt-1" style={{ 'min-width': '200px' }}>
                                    <Controller
                                        className="input-control"
                                        name="status"
                                        control={control}
                                        render={({ field }) => (
                                            <select
                                                {...field}
                                                labelId="cw-module-multiple-checkbox-label"
                                                id="cw-module-multiple-checkbox"
                                                variant="outlined"
                                                menuPlacement="top"
                                                className="btn btn-info dropdown-toggle btn"
                                                style={{ padding: "15px 8px", textAlign: "initial" }}
                                            >
                                                <option value={''} >
                                                    Select
                                                </option>
                                                <option value={'Accepted'} >
                                                    Accepted
                                                </option>
                                                <option value={'Rejected'} >
                                                    Rejected
                                                </option>
                                                <option value={'No Action'} >
                                                    No Action
                                                </option>
                                                <option value={'Pending for clarification'} >
                                                    Pending for clarification
                                                </option>
                                            </select>
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.status?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>
                            <div className="col-lg-12 align-items-center mb-3">
                                <InputLabel id="demo-simple-select-outlined-label" className="mb-1"><b>REMARKS</b></InputLabel>
                                <FormControl fullWidth margin="dense" variant="outlined" className="mt-1" style={{ 'min-width': '100px' }}>
                                    <Controller
                                        className="input-control"
                                        name="remarks"
                                        control={control}
                                        render={({ field }) => (
                                            <TextField multiline rows={3} {...field} variant="outlined" />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.remarks?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>
                            <div className="col-lg-12 mt-3 mb-3">
                                <Button type='button' variant="contained" color="grey" onClick={() => notificationRightDrawer(false)}>CANCEL</Button>
                                <Button type='submit'
                                    className={'mr-2 ml-2 btn-custom-primary'}
                                    variant="contained"
                                    disabled={formSubmitisLoading && formSubmitisLoading == true ? true : false}
                                >
                                    Save
                                </Button>
                            </div>
                        </form>
                    </div>
                    {isLoading ?
                        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                            <CircularProgress />
                        </div>
                        :
                        <>
                            <div class="row col-lg-12 mt-5 pl-4">
                                <div class="float">
                                    <div class="float-left">
                                        <span style={{ fontSize: "20px" }}><b>History</b></span>
                                    </div>
                                </div>
                            </div>
                            <Divider />
                            {formDataList && formDataList.map((element, index) =>
                                <div key={'index'} variant={'head'} style={{ width: '350px', padding: "10px" }}>
                                    <div className={
                                        element.form_status === 'Accepted' ? 'card p-2 history-custom-primary' :
                                            element.form_status === 'Rejected' ? 'card p-2 history-custom-danger' :
                                                element.form_status === 'No Action' ? 'card p-2 history-custom-warning' :
                                                    element.form_status === 'Pending for clarification' ? 'card p-2 history-custom-pending' :
                                                    'card p-2 history-custom-primary'
                                    } >
                                        <span className="mb-1" style={{ fontSize: "11px" }}>
                                            <b>{Moment(element.created_on).format('h:mm a, MMMM DD YYYY')}</b>
                                        </span>
                                        <span style={{ fontSize: "11px", lineHeight: "1rem" }}>
                                            <b>Status:- </b>{element.form_status}
                                        </span>
                                        <span style={{ fontSize: "11px", lineHeight: "1rem" }}>
                                            <b>Remarks:- </b>{element.remarks}
                                        </span>
                                        <div>
                                            <span className="mr-1" style={{ color: "#777777", fontSize: "11px" }}>
                                                Last updated by:
                                            </span>
                                            <span style={{ color: "#1a9698", fontSize: "11px" }}>
                                                <b>  {element.user_id}</b>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>

                    }
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(StatusMEDICATIONCoderWorkBench);

