import React from 'react'
import { useState,useEffect } from 'react';
import { anchorTag, hccSeperator } from '../../assets/constants/customRender';
import { getAcaYear } from '../../assets/constants/yearList';
import ServerSideGridTable from '../../components/TableGrids/ServerSideGridTable.table';
import GridTable from "../../components/TableGrids/GridTable.table";
import api from '../../assets/constants/Rafservice.Instance';
import { ISSUER_ID ,HCC,CR_INSIGHTS,MEMBER_ID,MEMBER_NAME,PLAN_ID,CARRIER_ID, GENDER} from '../../assets/constants/string.constants';
import history from "../../history";
import { connect } from 'react-redux';
import { currentRoute } from '../../store/actions';
import CardComponent from '../../components/Cards/card';

const ACAChartReviewHccAdd = (props) =>{


    const [year] = useState(props.yearSelect);
    const [hcc, setHcc] = useState(localStorage.getItem(HCC));
    const [issuer_id] = useState(localStorage.getItem(ISSUER_ID));
    const [tableData, setTableData] = useState([]);

    const  MemberCol =   [
        {
            label : "Member ID",
            name : "MemberID",
            options: anchorTag
        },
        {
            name : "MEMBER_Name",
            label : "Member Name"
        },
        {
            name : "Carrier_ID",
            label : "Carrier ID"
        },
        {
            name : "ReportedHCCS",
            label : "Reported HCCs",
            options: hccSeperator
        },
        { 
            name : "AddedHccs",
            label : "Added HCCs",
            options: hccSeperator 
        },
        {
            name : "DeletedHccs",
            label : "Deleted HCCs",
            options: hccSeperator
        },
        {
            name : "ReportedRAF",
            label : "Reported RAF"
        },
        {
            name : "CorrectionInRAF",
            label : "RAF Impact due to CR"
        },								
        {
            name : "MetalTier",
            label : "Metal Tier"
        },
        {
            name : "Model",
            label : "Risk Model"
        },
        {
            name : "PlanID",
            label : "Plan ID"
        }
    ];

     
        const memberTableClick = (rowdata, colData, cellMeta) =>{
            if((cellMeta.colIndex === 0)&&(colData.props.children != "")){
                localStorage.setItem(MEMBER_ID,rowdata[cellMeta.rowIndex].MemberID);
                localStorage.setItem(MEMBER_NAME,rowdata[cellMeta.rowIndex].MEMBER_Name);
                localStorage.setItem(CARRIER_ID,rowdata[cellMeta.rowIndex].Carrier_ID);
                localStorage.setItem(PLAN_ID,rowdata[cellMeta.rowIndex].PlanID);
                localStorage.setItem(GENDER,rowdata[cellMeta.rowIndex].Gender);
                history.push('/layout/acaMember', CR_INSIGHTS)
            }
            else if((cellMeta.colIndex === 3)&&(colData.props.children != "")){
                localStorage.setItem(ISSUER_ID,issuer_id);
                props.currentRoute('/layout/acaReportedHccSummary');
                history.push('/layout/acaReportedHccSummary');
            }     
            else  if((cellMeta.colIndex === 4)&&(colData.props.children != "")){
                if(typeof(colData.props.children) == "object"){
                    setHcc((cellMeta.event.target.innerText).replace(',',''));
                }
                else{
                    setHcc(rowdata[cellMeta.rowIndex].AddedHccs)
                }
                localStorage.setItem(ISSUER_ID,issuer_id);
            }
            else  if((cellMeta.colIndex === 5)&&(colData.props.children != "")){
                if(typeof(colData.props.children) == "object"){
                    localStorage.setItem(HCC,(cellMeta.event.target.innerText).replace(',',''));
                }
                else{
                    localStorage.setItem(HCC,rowdata[cellMeta.rowIndex].DeletedHccs);
                }
                localStorage.setItem(ISSUER_ID,issuer_id);
                history.push('/layout/acaCRhccDelete')
             }
        }   

    const  summaryColumns =[
        {
            name: "hccCode",
            label: "HCC Code",
        },
        {
            name: "description",
            label: "Description",
        },
        {
            name: "memberCount",
            label: "# Members"
        },
        {
            name: "providerCount",
            label: "Provider Count"
        }
    ];

    
    const loadMetricTable = () =>{
        api.get('/ACACRHCCDescForAdd/year/' + year+'/HCC/' + hcc+'/issuer_id/'+issuer_id,)
        .then(res=>{
            setTableData(res);
        })
        .catch(err=>{
            // console.log(err);
        })
    }

    useEffect(() =>{
        loadMetricTable();
    },[hcc]);
    return (
        <div>
            <CardComponent
            heading={`ACA CHART REVIEW HCC ADDED DETAIL VIEW - ${year}`}
            body={
                <GridTable
                    data={tableData}
                    columns={summaryColumns}>
                </GridTable>
            }></CardComponent>
            <br />
            <div className="member-list">
            <CardComponent
            heading={`MEMBER LIST`}
            body={
                <ServerSideGridTable
                     url={`/ACAcrHccAddedMemberDetail/year/${year}/hcc/${hcc}/issuer_id/${issuer_id}`}
                    columns={MemberCol}
                    tableClick={memberTableClick}
                ></ServerSideGridTable>
            }></CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br/>

                <br/>
                <br/>     
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
      activateRoute: state.route,
      isSignedIn: state.auth.isSignedIn,
      moduleSelect: state.moduleFilter.moduleSelect,
      viewBySelect: state.moduleFilter.viewBySelect,
      modelSelect: state.moduleFilter.modelSelect,
      metalTierSelect: state.moduleFilter.metalTierSelect,
      planSelect: state.moduleFilter.planSelect,
      yearSelect: state.moduleFilter.yearSelect,        
      meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}

export default connect(mapStateToProps, { currentRoute })(ACAChartReviewHccAdd);
