import api from './Rafservice.Instance';
import { 
    SELECT_YEAR, SELECT_INITIAL, 
    SELECT_METAL_TIER, SELECT_VIEW_BY,
    SELECT_PLAN, SELECT_MODEL
} from "../../assets/constants/string.constants";

let Plan_list = [];
let Plan_select = '';
export const PlanList = () =>{

    // api.get('/aca_yearList/')
    // .then(res=>{
        SetPlanList(['All']);  
        if(localStorage.getItem(SELECT_PLAN) && localStorage.getItem(SELECT_PLAN) != ''){
            // localStorage.setItem('YEAR', res[0]);            
            // SetAcaYear(res[0]);
            SetPlanSelect(localStorage.getItem(SELECT_PLAN)); 
        }else{
            localStorage.setItem(SELECT_PLAN, 'All');            
            SetPlanSelect('All'); 
        }    
    // })
    // .catch(err=>{
    //     // console.log(err);
    // })
}

export const SetPlanList = (data) =>{
    Plan_list = data;
}

export const SetPlanSelect = (data) =>{
    Plan_select = data;
    localStorage.setItem(SELECT_PLAN, data);
}

export const getPlanList = () =>{
    return Plan_list;
}

export const getPlanSelect = () =>{
    return Plan_select;
}