import Chart from 'chart.js';

const LabelInsideChart = () =>{

    //Chart.defaults.scale.gridLines.display = false;
    Chart.defaults.global.defaultFontSize -=3;

    Chart.pluginService.register({
    afterDraw: function(chartInstance) {
        var ctx = chartInstance.chart.ctx;
        ctx.fillStyle = '#444';
        var chartType = chartInstance.chart.config.type;
        ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, 'bold', Chart.defaults.global.defaultFontFamily);
        if(chartType != "pie"){
        var stacked = (chartInstance.scales["x-axis-0"].options.stacked && chartInstance.scales["y-axis-0"].options.stacked);
        }
        //pie chart
        else{   
            chartInstance.data.datasets.forEach(function (dataset, index) {
                        var meta = chartInstance.getDatasetMeta(index);                                        
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'bottom';                        
                        ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, 'bold', Chart.defaults.global.defaultFontFamily);
                        meta.data.forEach(function(element, index) {
                            if (!element.hidden) {
                            var position = element.tooltipPosition();                                                        
                            ctx.fillText(dataset.data[index], position.x, position.y);
                        }
                        });                    
            });                             
        }
        //vertical bar
    if ((chartType == "bar") && !stacked){
         ctx.textAlign = 'center';
        ctx.textBaseline = 'bottom';        
        chartInstance.data.datasets.forEach(function (dataset,index) {
            var meta = chartInstance.getDatasetMeta(index);
            if(!meta.hidden){
            for (var i = 0; i < dataset.data.length; i++) {
                var dataString = dataset.data[i].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                if(dataset.suffix =='$'){
                dataString = dataset.suffix +dataString;
                }
                else if(dataset.suffix =='%'){
                dataString = dataString+dataset.suffix;
                }                
                //dataset._meta[Object.keys(dataset._meta)[0]].data[i]._chart.options.tooltips.callbacks.label;
                var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;                
                ctx.fillText(dataString, model.x, model.y - 2);
            }
        }
        });
    }
    //horizontal bar
    else if (chartType == "horizontalBar" && !stacked){
        ctx.textAlign = 'left';
        ctx.textBaseline = 'bottom';        
        chartInstance.data.datasets.forEach(function (dataset,index) {  
            var meta = chartInstance.getDatasetMeta(index);
            if (!meta.hidden){
            for (var i = 0; i < dataset.data.length; i++) {
                // console.log(dataset)
                var dataString = dataset.data[i].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                // console.log(dataString);
                if(dataset.suffix =='$'){
                dataString = dataset.suffix +dataString;
                // console.log(dataString);
                }else if(dataset.suffix =='%'){
                dataString = dataString+dataset.suffix;
                // console.log(dataString);
                }                
                var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model,
                left = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._xScale.left; 
                var barSize = meta.controller._ruler.barSize;
                //If the data value goes out of the bar uncomment tyhe following code
                ctx.fillText(dataString, ((left + model.x)/2), model.y + 5);     
                /* if (barSize > 8){
                    ctx.fillText(dataString, ((left + model.x)/2), model.y + 5);                 
                }else if(barSize > 5 && barSize < 8) {
                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize-2, 'bold', Chart.defaults.global.defaultFontFamily);
                    ctx.fillText(dataString, ((left + model.x)/2), model.y + 4.5);              
                }
                else if(barSize > 3 && barSize < 5) {
                    ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize-3, 'bold', Chart.defaults.global.defaultFontFamily);
                    ctx.fillText(dataString, ((left + model.x)/2), model.y + 3.8);              
                } */
            }
        }
        });
    }
    //stacked horizontal bar
    else if (chartType == "horizontalBar" && stacked){
        ctx.textAlign = 'left';
        ctx.textBaseline = 'bottom';
        //ctx.fillStyle = '#CB6361';
        chartInstance.data.datasets.forEach(function (dataset,index) {
            var meta = chartInstance.getDatasetMeta(index);
            if(!meta.hidden){
            for (var i = 0; i < dataset.data.length; i++) {
                var dataString = dataset.data[i].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;                
                var Position =(model.x-model.base)/2;
                Position = model.base + Position;                
                ctx.fillText(dataString, Position, model.y+5);                
            }
        }
        });
    }
    //stacked vertical bar
    else if ((chartType == "bar") && stacked){
        ctx.textAlign = 'center';
        ctx.textBaseline = 'top';
        chartInstance.data.datasets.forEach(function (dataset,index) {
            var meta = chartInstance.getDatasetMeta(index);
            if(!meta.hidden){
            for (var i = 0; i < dataset.data.length; i++) {
                var dataString = dataset.data[i].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;                
                ctx.fillText(dataString, model.x, model.y+2);                
            }
        }
        });
    }
    //line graphs
    else if (chartType == "line"){
        ctx.textAlign = 'left';
        ctx.textBaseline = 'bottom';        
        chartInstance.data.datasets.forEach(function (dataset,index) {
            var meta = chartInstance.getDatasetMeta(index);
            if(!meta.hidden){
            for (var i = 0; i < dataset.data.length; i++) {
                var dataString = dataset.data[i].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
                if(i == dataset.data.length-1) {
                    ctx.textAlign = 'right';
                }               
                ctx.fillText(dataString, model.x, model.y - 2);
                ctx.textAlign = 'left';
            }
        }
        });
    }
  }
});
}

export default LabelInsideChart;