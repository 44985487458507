import React, { useState, useEffect } from "react";
import PPGSummaryServerSide from "../../components/TableGrids/PPGSummaryServerSide.table";
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';
import CardComponent from "../../components/Cards/card";
import GridTable from "../../components/TableGrids/GridTable.table";
import { FormatColorResetOutlined } from "@mui/icons-material";
import api from "../../assets/constants/Rafservice.Instance";
import DownloadFile from './DownloadFile';

const ACAMemberWithGap = (props) => {

    const [isLoading, setIsLoading] = useState(true);
    
    return (
        <div className="container level-summary">
            <div className="row mb-3">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={''} />
                        </div>
                    </div>
                </div>
                <div  className="col-lg-12 pr-0 pl-0 mb-3" style={{height:'50%'}}>
            
                <CardComponent
            heading={"Member DETAIL With GAP"}
            additionalClass={`text-capital`}
            display={true}
            year={props.yearSelect}
            name={'Download Summary Report'}
            body={
                <PPGSummaryServerSide
                url={`https://xgd245h3i9.execute-api.us-west-2.amazonaws.com/v1/memberwithgap?`}
                pagination={true}
                search={false}
                viewColumns={true}
                download={true}
                filter={true}
                isLoading={isLoading}
                className={"pcp_details_table"}
            ></PPGSummaryServerSide>
            }
          ></CardComponent>
                </div>
            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
    };
}
export default connect(mapStateToProps)(ACAMemberWithGap);
