import { useState, useEffect } from 'react';
import { anchorTag, numberWithCommas } from '../../assets/constants/customRender';
import { getAcaIssuer, getAcaIssuerList } from '../../assets/constants/issuerList';
import api from '../../assets/constants/Rafservice.Instance';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import SingleHorizontalBar from '../../components/Charts/SingleHorizontalBar.chart';
import AcaIssuersSelect from '../../components/DropDowns/AcaIssuersSelect';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import history from '../../history';
import ServerSideGridTable from '../../components/TableGrids/ServerSideGridTable.table';
import { HCC, ISSUER_ID } from '../../assets/constants/string.constants';
import CardComponent from '../../components/Cards/card';
import BarVerticalHCC from '../../components/GChart/BarVerticalHCC';
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';

const GapHccSummaryACA = (props) => {

    const [year, setYear] = useState(props.yearSelect);
    const [HccChartData, setHccChartData] = useState([]);
    const [issuer, setIssuer] = useState(getAcaIssuer());

    const loadHccChartIssuer = () => {

        const handlechartClick = (element) => {
            let chartData = element[0]['_chart'].config.data;
            let idx = element[0]['_index'];
            let hcc = chartData.labels[idx];
            let parts = hcc.split(':');
            let hcc1 = parts[1];
            localStorage.setItem(HCC, hcc1);
            localStorage.setItem(ISSUER_ID, issuer);
            history.push('/layout/AcaGapHcc');
        };
        let hccCode = [];
        let totalCount = [];
        let chartDataRow = [];
        chartDataRow.push(["RisK Model", "Risk Score", { role: 'annotation' }]);
        api.get('/hccByIssuer/year/' + props.yearSelect + '/issuerId/' + btoa(issuer))
            .then(res => {
                for (const dataObj of res) {
                    hccCode.push((dataObj.description + ':' + dataObj.hccCode));
                    totalCount.push(dataObj.totalGaps);
                    chartDataRow.push([dataObj.description + ':' + dataObj.hccCode, dataObj.totalGaps, dataObj.totalGaps]);
                }
                setHccChartData(chartDataRow);
            })

    }

    const hccAcaColumns = [
        {
            label: "HCC",
            name: "hcc_desc",
            options: anchorTag
        },
        {
            label: "# Members with Comorbid",
            name: "comorbid",
            options: numberWithCommas
        },
        {
            label: "# Members with Historic",
            name: "historic",
            options: numberWithCommas
        },
        {
            label: "# Members with Lab Other",
            name: "lab_other",
            options: numberWithCommas
        },
        {
            label: "# Members with Pharma",
            name: "pharma",
            options: numberWithCommas
        },
        {
            label: "# Multiple Sources",
            name: "members_with_mult_sources",
            options: numberWithCommas
        },
        {
            label: "# Members with Gap",
            name: "total_members_with_gap",
            options: numberWithCommas
        }
    ]

    const hccMedicareColumns = [
        {
            label: "Code",
            name: "code",
            options: anchorTag
        },
        {
            label: "Description",
            name: "description",
        },
        {
            label: "Total Member",
            name: "count",
        },
    ]

    const hccTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(ISSUER_ID, issuer);
            localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].hcc);
            history.push('/layout/AcaGapHcc');
        }
    };

    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            loadHccChartIssuer();
            setIssuer(getAcaIssuer());
        }
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect,
        issuer
    ])

    return (
        <div className="level-summary">
            <div className="row pb-2">
                <div className="col-lg-12">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Gap Hcc Summary'} />
                        </div>
                    </div>
                </div>
            </div>

            <CardComponent
                heading={`ACA HIGH FREQUENCY GAP HCC`}
                body={
                    <BarVerticalHCC data={HccChartData} chartType="BarChart" height="400px" width="100%" setStorgeItemName={HCC} redirectUrl={'AcaGapHcc'}></BarVerticalHCC>
                }></CardComponent>
            <br />
            <div className="hcc-list pb-5">
                {props.yearSelect ?
                    <CardComponent
                        heading={`HCC LIST`}
                        body={
                            <ServerSideGridTable
                                url={`/acahccs/year/${props.yearSelect}/issuerId/${btoa(issuer)}`}
                                columns={hccAcaColumns}
                                tableClick={hccTableClick}
                                defaultSortCol={6}
                                defaultSortDir={'desc'}
                            ></ServerSideGridTable>
                        }></CardComponent>
                    :
                    <></>
                }
            </div>
            <br />
            <br />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
    };
}
export default connect(mapStateToProps)(GapHccSummaryACA);
