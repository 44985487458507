import React, { useRef, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar } from '../../store/actions';
import AWVAdditionalAttachments from './AweSideBar/AWVAdditionalAttachments';
import AWVMemberDetails from './AweSideBar/AWVMemberDetails';
import EditCWDXCoderWorkBench from './AweSideBar/EditCWDXCoderWorkBench';
import EditCWPROCCODECoderWorkBench from './AweSideBar/EditCWPROCCODECoderWorkBench';
import EditMEDICATIONCoderWorkBench from './AweSideBar/EditMEDICATIONCoderWorkBench';
import MemberHCCRemark from './AweSideBar/MemberHCCRemark';
import QuickNotesAWE from './AweSideBar/QuickNotesAWE';
import StatusAWVFileUpload from './AweSideBar/StatusAWVFileUpload';
import StatusCWDXCoderWorkBench from './AweSideBar/StatusCWDXCoderWorkBench';
import StatusCWPROCCODECoderWorkBench from './AweSideBar/StatusCWPROCCODECoderWorkBench';
import StatusMEDICATIONCoderWorkBench from './AweSideBar/StatusMEDICATIONCoderWorkBench';
import TimelineAWE from './AweSideBar/timelineAWE';
import ViewAWVFileUpload from './AweSideBar/ViewAWVFileUpload';
import OnHoldAWVFileUpload from './AweSideBar/OnHoldAWVFileUpload';
import AddAWVFileUpload from './AweSideBar/AddAWVFileUpload';
import AWEProcessedRecord from './AweSideBar/AWEProcessedRecord';
import StageChange from "./AweSideBar/StageChange";
import AWVMemberDetailsCoder from './AweSideBar/AWVMemberDetailsCoder';
import AWVMemberDetailsMultiCoder from './AweSideBar/AWVMemberDetailsMultiCoder';
import AWVMemberDetailsCoodinator from './AweSideBar/AWVMemberDetailsCoodinator';

import NotificationAWE from './AweSideBar/notificationAWE';
import ViewCWDXCoderWorkBench from './ViewCWDXCoderWorkBench';
import ViewCWPROCCODECoderWorkBench from './ViewCWPROCCODECoderWorkBench';
import ViewMEDICATIONCoderWorkBench from './ViewMEDICATIONCoderWorkBench';



const AWERightSideBar = (props) => {

    useEffect(() => {
        console.log('AWERightSideBar',props);
    }, [props.aweRightSidebar, props.aweRightSidebarType]);

    const { aweRightSidebarType } = props;

    const renderRightSidebarContent = () => {
        switch (props.aweRightSidebarType?.type) {
            case 'notification':
                return <NotificationAWE />;
            case 'timeline':
                return <TimelineAWE awv_backend_id={aweRightSidebarType?.awv_backend_id} CinId={aweRightSidebarType?.cin_id} />;
            case 'quickNotes':
                return <QuickNotesAWE awv_backend_id={aweRightSidebarType?.awv_backend_id} CinId={aweRightSidebarType?.cin_id} />;
            case 'additionalAttachments':
                return <AWVAdditionalAttachments awv_backend_id={aweRightSidebarType?.awv_backend_id} CinId={aweRightSidebarType?.cin_id} />;
            case 'addAweFileUpload':
                return <AddAWVFileUpload awv_backend_id={aweRightSidebarType?.awv_backend_id} />;
            case 'statusAweFileUpload':
                return <StatusAWVFileUpload awv_backend_id={aweRightSidebarType?.awv_backend_id} />;
            case 'onHoldAweFileUpload':
                return <OnHoldAWVFileUpload awv_backend_id={aweRightSidebarType?.awv_backend_id} />;
            case 'viewAweFileUpload':
                return <ViewAWVFileUpload awv_backend_id={aweRightSidebarType?.awv_backend_id} />;
            case 'memberHccDetailsReport':
                return <AWVMemberDetails />;
            case 'memberCoderChecklistReport':
                return <AWVMemberDetailsCoder />;
            case 'memberCoordinatorChecklistReport':
                return <AWVMemberDetailsCoodinator />;
            case 'memberMultiCoderChecklistReport':
                return <AWVMemberDetailsMultiCoder />;
            case 'aweProcessedRecord':
                return <AWEProcessedRecord form_url={aweRightSidebarType?.form_url} cin={aweRightSidebarType?.cin} aweId={aweRightSidebarType?.aweId} />;
            case 'memberHccRemark':
                return <MemberHCCRemark
                    AwvId={aweRightSidebarType?.awv_id}
                    CinId={aweRightSidebarType?.cin_id}
                    title={aweRightSidebarType?.title}
                    hcc_code={aweRightSidebarType?.hcc_code}
                    hcc_description={aweRightSidebarType?.hcc_description}
                    id={aweRightSidebarType?.id}
                    nlp_captured={aweRightSidebarType?.nlp_captured}
                    nlp_id={aweRightSidebarType?.nlp_id}
                />;
            case 'ViewCWDXCoderWorkBench':
                return <ViewCWDXCoderWorkBench Id={aweRightSidebarType?.id} />;
            case 'ViewCWPROCCODECoderWorkBench':
                return <ViewCWPROCCODECoderWorkBench Id={aweRightSidebarType?.id} />;
            case 'ViewMEDICATIONCoderWorkBench':
                return <ViewMEDICATIONCoderWorkBench Id={aweRightSidebarType?.id} />;
            case 'EditCWDXCoderWorkBench':
                return <EditCWDXCoderWorkBench Id={aweRightSidebarType?.id} awe_id = {aweRightSidebarType?.awe_id} value={aweRightSidebarType?.valueArray} 
                        lob={aweRightSidebarType?.lob} year={aweRightSidebarType?.year} />;
            case 'EditCWPROCCODECoderWorkBench':
                return <EditCWPROCCODECoderWorkBench Id={aweRightSidebarType?.id} />;
            case 'EditMEDICATIONCoderWorkBench':
                return <EditMEDICATIONCoderWorkBench Id={aweRightSidebarType?.id} />;
            case 'StatusCWDXCoderWorkBench':
                return <StatusCWDXCoderWorkBench Id={aweRightSidebarType?.id} />;
            case 'StatusCWPROCCODECoderWorkBench':
                return <StatusCWPROCCODECoderWorkBench Id={aweRightSidebarType?.id} />;
            case 'StatusMEDICATIONCoderWorkBench':
                return <StatusMEDICATIONCoderWorkBench Id={aweRightSidebarType?.id} />;
            case 'stageChange':
                return <StageChange awv_backend_id={aweRightSidebarType?.awv_backend_id} />;
            default:
                return <></>;
        }
    };

    return (
        <>
            {props.aweRightSidebar && props.aweRightSidebar == true ?
                <>
                    {props.aweRightSidebarType ? renderRightSidebarContent() : <></>}
                    <div className={`overlay ${props.aweRightSidebar ? "isOpen" : ""}`} onClick={() => props.currentAWERightSidebar(false)}></div>
                </>
                :
                <></>
            }
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        userName: state.auth?.userDetails?.userEmail,
        route: state.route.route,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar })(AWERightSideBar);
