import React, { useRef, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../../store/actions';
import AWVCoderWorkBench from '../../../assets/constants/AWVCoderWorkBenchRafservice.Instance';
import Divider from '@mui/material/Divider';
import Moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import { CircularProgress } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { styled } from '@mui/material/styles';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import IconButton from "../../layout/aweLayout/IconButton";
import Button from "../../layout/aweLayout/Button";
import CloseIcon from "../../icons/CloseIcon";

const StatusCWPROCCODECoderWorkBench = (props) => {
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [formDataList, setFormDataList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
    const [editFormData, setEditFormData] = useState({});

    useEffect(() => {
        loadformDataTable(props.Id);
        editLoadFormData(props.Id);
    }, [props.Id]);

    useEffect(() => {
        if (editFormData?.id && editFormData?.id != '') {
            setValue('id', editFormData?.id);
            setValue('cw_proc_status', editFormData?.cw_proc_status);
            setValue('remarks', editFormData?.remarks);
            let controlUser = JSON.parse(localStorage.getItem('user'));
            //setValue('last_updated_on', Moment().format('YYYY-MM-DD HH:mm:ss'));
            setValue('last_updated_by', controlUser?.userEmail);
        }
    }, [editFormData]);

    const notificationRightDrawer = (open, valueId = '') => {
        props.currentAWERightSidebarType({ 'type': 'notification' });
        props.currentAWERightSidebar(open);
    };

    const editLoadFormData = (id) => {
        setIsLoading(true);
        AWVCoderWorkBench.get('data/cw_proc_code/' + id)
            .then(res => {
                console.log(res.data);
                if (res.data && res.data) {
                    setEditFormData(res.data);
                    setIsLoading(false);
                } else {
                    setEditFormData();
                    setIsLoading(false);
                }
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
            })
    }

    const loadformDataTable = (id) => {
        setIsLoading(true);
        AWVCoderWorkBench.get('data/cwb/history/cwp_proc_code/' + id) //'&provider_group=' + props?.providerGroupSelect
            .then(res => {
                console.log(res.data);
                setFormDataList(res.data);
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    }


    const onSubmit = (data) => {
        console.log(data);
        setFormSubmitisLoading(true);
        AWVCoderWorkBench.post('data/cw_proc_code/' + props.Id, data)
            .then(res => {
                setValue('id', '');
                setValue('cw_proc_status', '');
                setValue('last_updated_on', '');
                setValue('last_updated_by', '');
                setFormSubmitisLoading(false);
                toast.success('Status updated successfully');
                props.currentAWERightSidebarCloseDatatableReload(true);
                notificationRightDrawer(false);
            })
            .catch(err => {
                setFormSubmitisLoading(false);
                toast.error(err?.response?.data?.message);
            })
    }

    return (
        <div className="diagnosis_code_sidebar offcanvas offcanvas-end show" tabIndex={-1} aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header flex_item_cb">
                <h5 id="offcanvasRightLabel" className='fs15'>PROCEDURE CODE</h5>
                <Tooltip title="Close">
                    <IconButton type="button" buttonType={"default"} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => notificationRightDrawer(false)}>
                        <CloseIcon className="w24" />
                    </IconButton>
                </Tooltip>
            </div>
            <Divider />
            <div className="offcanvas-body pt-1 custom-scrollbar overflow-auto">
                <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Status</label>
                        <Controller
                            name="cw_proc_status"
                            control={control}
                            render={({ field }) => (
                                <select
                                    {...field}
                                    className="form-control"
                                >
                                    <option value={''} >
                                        Select
                                    </option>
                                    <option value={'Accepted'} >
                                        Accepted
                                    </option>
                                    <option value={'Rejected'} >
                                        Rejected
                                    </option>
                                    <option value={'No Action'} >
                                        No Action
                                    </option>
                                    <option value={'Pending for clarification'} >
                                        Pending for clarification
                                    </option>
                                </select>
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.cw_proc_status?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>

                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">REMARKS</label>
                        <Controller
                            name="remarks"
                            control={control}
                            render={({ field }) => (
                                <textarea rows={3} {...field} className="form-control" />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.remarks?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group flex_item_cs gap10">
                        <Button type='button' className={"btn-white minw80"} onClick={() => notificationRightDrawer(false)}>CANCEL</Button>
                        <Button type='submit' className={"minw80"} disabled={formSubmitisLoading && formSubmitisLoading == true ? true : false}>SAVE</Button>
                    </div>
                </form>
                {isLoading ?
                    <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        {formDataList && formDataList.length > 0 ?
                            <div className="">
                                <h5 className="fs18 mb10">History</h5>
                                <Divider />
                                <div className="history_card_list">
                                    {formDataList && formDataList.map((element, index) =>
                                        <div key={index} className={
                                            element.form_status === 'Accepted' ? 'history_card_item status-primary' :
                                                element.form_status === 'Rejected' ? 'history_card_item status-danger' :
                                                    element.form_status === 'No Action' ? 'history_card_item status-light' :
                                                        element.form_status === 'Pending for clarification' ? 'history_card_item status-orange' :
                                                            'history_card_item status-primary'
                                        }>
                                            <p className="fs12 mb-1 fw700">
                                                {Moment(element.created_on).format('h:mm a, MMMM DD YYYY')}
                                            </p>
                                            <p className="fs12 mb-1">
                                                <span className="fw700">Status :-</span>
                                                {element.form_status}
                                            </p>
                                            <p className="fs12 mb-1">
                                                <span className="fw700">Remarks :-</span>
                                                {element.remarks}
                                            </p>
                                            <p className="fs12 mb-1">
                                                <span className="fw700">Last updated by :-</span>
                                                {element.user_id}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            :
                            <></>
                        }
                    </>
                }
            </div>

        </div >
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(StatusCWPROCCODECoderWorkBench);

