import React, { useState, useEffect } from "react"; import {
    REPORTED_MEMBER_SUMMARY, PPG_SUMMARY
  } from "../../assets/constants/string.constants";
  import MAOReportedMemberSummaryTab from './MAOReportedMemberSummaryTab';
  import MedicarePGP from '../MedicarePage/MedicarePGP.page';
  import AppBar from "@material-ui/core/AppBar";
  import Tabs from "@material-ui/core/Tabs";
  import Tab from "@material-ui/core/Tab";
  import TabPanel from "../../components/Tab/TabPanel";
  import { connect } from 'react-redux';
  //import google from "react-google-charts";
     
  const MaoReportedMemberSummary = (props) => {
    const [value, setValue] = useState(REPORTED_MEMBER_SUMMARY);
    const [year, setYear] = useState();
  
    function a11yProps(index) {
  
      return {
        id: `wrapped-tab-${index}`,
        "aria-controls": `wrapped-tabpanel-${index}`,
      };
    }
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    return (
      <div className="container level-summary">
        <div>
  
     
        <AppBar position="static" color="default">
          <Tabs
            variant="fullWidth"
            textColor="primary"
            indicatorColor="primary"
            value={value}
            onChange={handleChange}
            centered
          >
            <Tab
              value={REPORTED_MEMBER_SUMMARY}
              label="REPORTED MEMBER SUMMARY"
              {...a11yProps(REPORTED_MEMBER_SUMMARY)}
            />
            <Tab
              value={PPG_SUMMARY}
              label="PPG SUMMARY"
              {...a11yProps(PPG_SUMMARY)}
            />
          </Tabs>
          
        </AppBar>
        
        </div>
        <TabPanel value={value} index={REPORTED_MEMBER_SUMMARY}>
          <MAOReportedMemberSummaryTab></MAOReportedMemberSummaryTab>
          <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
          <br />
          <br />
          <br />
        </TabPanel>
        <TabPanel value={value} index={PPG_SUMMARY}>
          <MedicarePGP></MedicarePGP>
          <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
          <br />
          <br />
          <br />
        </TabPanel>
      </div>
    )
  };
  const mapStateToProps = (state) => {
    return {
      isSignedIn: state.auth.isSignedIn,
      moduleSelect: state.moduleFilter.moduleSelect,
      viewBySelect: state.moduleFilter.viewBySelect,
      modelSelect: state.moduleFilter.modelSelect,
      metalTierSelect: state.moduleFilter.metalTierSelect,
      planSelect: state.moduleFilter.planSelect,
      yearSelect: state.moduleFilter.yearSelect,
    };
  }
  export default connect(mapStateToProps)(MaoReportedMemberSummary)
  
