import { Icon } from "@material-ui/core";
import CSVtoArray from "../helpers/csvToArray.helper";
import Button from '@material-ui/core/Button';
import Moment from 'react-moment';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import {TableCell} from '@material-ui/core';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {Tooltip} from '@material-ui/core';
import {IconButton} from '@material-ui/core';
import {
    CLAIM_ID_DESCRIPTION,
    CLAIM_TYPE_DESCRIPTION,
    SERVICE_START_DATE_DESCRIPTION,
    SERVICE_END_DATE_DESCRIPTION,
    BILL_TYPE_CODE_DESCRIPTION,
    PROVIDER_ID_DESCRIPTION,
    PROVIDER_NAME_DESCRIPTION,
    CLAIM_STATUS_DESCRIPTION,
    RENDERING_PROVIDER_DESCRIPTION,
    CHARGE_AMOUNT_DESCRIPTION,
    ALLOWED_AMOUNT_DESCRIPTION,
    PAID_AMOUNT_DESCRIPTION,
    MEMBER_ID_DESCRIPTION,
    MEMBER_NAME_DESCRIPTION,
    NO_OF_SERVICELINE_DESCRIPTION
  } from "../../assets/constants/string.constants";

   // Convert to PST timezone 
export const convertToPacificTime=(istTime)=>{ 
    const istDateTime = new Date(istTime);
    var pacificDateTime;  
    pacificDateTime = new Date(istDateTime.getTime() - (7* 60 * 60 * 1000 ));
    return pacificDateTime;
}
export const anchorTag = {
    customBodyRender: (value, tableMeta, updateValue) => {
        return (
            <a className="hyperlink" style={{cursor:"pointer"}} href={() => false}>{value}</a>
        );
    }
}
export const anchorTagColmnHide = {
    display: false,
    customBodyRender: (value, tableMeta, updateValue) => {
        return (
            <a className="hyperlink" style={{cursor:"pointer"}} href={() => false}>{value}</a>
        );
    }
}
export const anchorTagModelOpen = {
    customBodyRender: (value, tableMeta, updateValue) => {
        return (
            <a className="hyperlink" style={{cursor:"pointer"}} href={() => false}>{'\>>'}</a>
        );
    }
}

export const anchorTagnumberWithCommas = {
    customBodyRender: (value, tableMeta, updateValue) => {
        return (
            <a className="hyperlink" style={{cursor:"pointer"}} href={() => false}>{Number(value).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</a>
        );
    }
}

export const addSuffixPercent = {
    customBodyRender: (value, tableMeta, updateValue) => {
        if(value && value != ''){
            return (value+' %');
        }
    }
}

export const addSuffixTwoPercent = {
    customBodyRender: (value, tableMeta, updateValue) => {
        if(value && value != ''){
            return  (Number(value).toFixed(2)+' %');
        }
        
    }
}

export const addSuffixTwoDecimal = {
    customBodyRender: (value, tableMeta, updateValue) => {
            return Number(value).toFixed(2);
    }
}

export const addSuffixDollar = {
    customBodyRender: (value, tableMeta, updateValue) => {
        if(value && value != ''){
            return ('$ ' + value);
        }
        
    }
}

export const numberWithCommas = {
    customBodyRender: (value, tableMeta, updateValue) => {
        if(value && value > 0){
            value = Number(value).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }else{
            value = 0;
        }
        return (
            value
        );
    }
}

export const numberWithCommasPrefixDolar = {
    customBodyRender: (value, tableMeta, updateValue) => {
        if(value && value > 0){
            value ='$'+ Number(value).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }else{
            value = 0;
        }
        return (
            value
        );
    }
}
export const numberWithCommasPrefixDolarThreeDigit = {
    customBodyRender: (value, tableMeta, updateValue) => {
        if(value && value > 0){
           // value ='$'+ Number(value).toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
           value = Number(value).toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }else{
            value = 0;
        }
        return (
            value
        );
    }
}
export const numberWithCommasPrefixDolarThreeDigitColmnHide = {
    display: false,
    customBodyRender: (value, tableMeta, updateValue) => {
        if(value && value > 0){
           // value ='$'+ Number(value).toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
           value = Number(value).toFixed(3).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }else{
            value = 0;
        }
        return (
            value
        );
    }
}

export const numberWithCommasPrefixDolarColmnHide = {
    display: false,
    customBodyRender: (value, tableMeta, updateValue) => {
        if(value && value > 0){
            value ='$'+ Number(value).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }else{
            value = 0;
        }
        return (
            value
        );
    }
}
export const tickCross = {
    customBodyRender: (value, tableMeta, updateValue) => {
        let data =null;
        if(value == 1){
            data = <Icon className="fa fa-check" />
        }
        else if(value == 0){
            data = <Icon className="fa fa-times" />
        }
        else if(value == 2){
            data = <p className="font-weight-bolder">CR</p>
        }
        else if(value == 'Y'){
            data = <Icon className="fa fa-check" />
        }
        else if(value == 'N'){
            data = <Icon className="fa fa-times" />
        }
        return (
            data
        );
    }
}
 

export const downloadButton = {
    customBodyRender: (value, tableMeta, updateValue) => {
    
        return (
        
        <div>
           <Button>Download</Button>
        
        </div>);
        
            }
};
 
export const deleteButton = {
    customBodyRender: (value, tableMeta, updateValue) => {
    
        return (
        
        <div>
           <Button>Delete</Button>
        
        </div>);
        
            }
};

 
export const stringFold = {
    customBodyRender: (value, tableMeta, updateValue) => {
        var arr = CSVtoArray(value);
	    var output = "";
        if (arr != null) {
            for (var i = 0; i < arr.length; i++) {
                if (i % 2 == 0 && i != 0)
                    output += ",\n"  + arr[i];
                else if (i > 0)
                    output += "," + arr[i];
                else
                    output += arr[i];
            }
        }
	    return output;
    }
}

export const viewButton ={
    customBodyRender: (value, tableMeta, updateValue) => {
        if(value === true ){
         let data = <Button color="primary" >View</Button>;
        return (data);
        }
        else
         return('');
            }
}

export const OpportunityFlag ={
    customBodyRender : (data, type, row, meta) => {
        if (data == '1') {
            data =  'Open';
        } else {
            data ='Lost';
        }
        return data;
    }
};

export const dateFormat =   {
    customBodyRender: (value, tableMeta, updateValue) => {
    let todayTime = new Date(value);
    let month = (todayTime .getMonth() + 1);
    let day = (todayTime .getDate());
    let  year = (todayTime .getFullYear());
    return month + "/" + day + "/" + year;
}
};

export const reviewButton ={
    customBodyRender: (value, tableMeta, updateValue) => {
        if(value === true ){
         let data = <Button>view</Button>;
        return (data);
        }
        else
         return('');
            }
}
export const hccSeperator = {
    customBodyRender: (value, tableMeta, updateValue) => {
        let arr = CSVtoArray(value);
        let i = 0;
        if(arr != undefined){
            if(arr.length >1){
                return <div>{arr.map(el =>(<a className="hyperlink" style={{cursor:"pointer"}} href={() => false}><span>{el}{arr.length>++i?',':''}</span></a>))}</div>
            }
            else{
                return <a className="hyperlink" style={{cursor:"pointer"}} href={() => false}>{value}</a>
            }
        }
    }
}
export const dateFormatRender = {
    customBodyRender: (value, tableMeta, updateValue) => {   
        return (
            <div>
                <Moment date={value} parse="DD-MM-YYYY" format="MM-DD-YYYY" />
            </div>
        )
    }
}
export const dateTimeFormatRender = {
    customBodyRender: (value, tableMeta, updateValue) => {   
        return (
            <div>
                <Moment date={value} parse="DD-MM-YYYY hh:mm:ss" format="MM-DD-YYYY hh:mm:ss" />
            </div>
        )
    }
}
export const dateTimeAdminFormatRender = {
    customBodyRender: (value, tableMeta, updateValue) => {   
        return (
            <div>
                <Moment date={value} parse="YYYY-MM-DD hh:mm:ss" format="MM-DD-YYYY hh:mm:ss" />
            </div>
        )
    }
}
export const anchorTagEditMode = {
    customBodyRender: (value, tableMeta, updateValue) => {
        return (
             <a className="hyperlink" style={{cursor:"pointer"}} href={() => false}><ModeEditOutlineIcon /></a>
        );
    }
}
export const statusToFormatRender = {
    customBodyRender: (value, tableMeta, updateValue) => {   
        return (
            <div>
              {value == '1' || value == 'true'?
                <span className="text-success">Active</span>
              :
                <span className="text-danger">Inactive</span>
              }
            </div>
        )
    }
}
export const textToSignFormatRender = {
    customBodyRender: (value, tableMeta, updateValue) => {   
        return (
            <div>
              {value == '1' || value == 'Y'?
                <span>✔</span>
              :
                <span>✘</span>
              }
            </div>
        )
    }
}
export const textToOpenOrCloseFormatRender = {
    customBodyRender: (value, tableMeta, updateValue) => {   
        return (
            <div>
              {value == '0'?
                <span>Lost</span>
              :value == '1'?
                <span>Open</span>
              :
                <span></span>
              }
            </div>
        )
    }
}
export const ByteConverter = {
    customBodyRender: (value) => {   
        const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
          let l = 0, n = parseInt(value, 10) || 0;
          while(n >= 1024 && ++l){
              n = n/1024;
          }
          return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
        
    }
}
export const anchorTagAndInfoIcon  = {

    customBodyRender: (value, tableMeta, updateValue) => {
        return (
            <a className="hyperlink" style={{cursor:"pointer"}} href={() => false}>{value}</a>
        );
    }, 
    customHeadRender: ({index, ...column}, sortColumn,value) => {
      return (
          <TableCell  onClick={() => sortColumn(index)}  key={index} >
              {column.label} 
              <Tooltip title={CLAIM_ID_DESCRIPTION} arrow>
                <IconButton>
                  <InfoOutlinedIcon/>
                </IconButton>
              </Tooltip>
          </TableCell >
      )
  }
}

export const infoIcon = {
    customHeadRender: ({index, ...column}, sortColumn) => {
        return (
            <TableCell  onClick={() => sortColumn(index)}  key={index}>
                {column.label} 
                <Tooltip title={getDecsription(column.label)} arrow>
                  <IconButton>
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
            </TableCell >
        )
    }
}

export const displayDisableAndInfoIcon  = {
    display: false,
    customHeadRender: ({index, ...column}, sortColumn) => {
      return (
          <TableCell  onClick={() => sortColumn(index)}  key={index}>
              {column.label} 
              <Tooltip title={getDecsription(column.label)} >
                <IconButton>
                  <InfoOutlinedIcon disabled = "true"/>
                </IconButton>
              </Tooltip>
          </TableCell >
      )
  }
}
export const dollerSymbolAndInfoIcon  = {
    display: false,
    customBodyRender: (value, tableMeta, updateValue) => {
        if(value && value != ''){
            return ('$ ' + value);
        }
    },
    customHeadRender: ({index, ...column}, sortColumn,value) => {
      return (
          <TableCell  onClick={() => sortColumn(index)}  key={index}>

              {column.label} 
              <Tooltip title={getDecsription(column.label)} arrow>
                <IconButton>
                  <InfoOutlinedIcon/>
                </IconButton>
              </Tooltip>
          </TableCell >
      )
  }
}


export const dollerSymbolAndInfoIconWithDisplay  = {
    customBodyRender: (value, tableMeta, updateValue) => {
        if(value && value != ''){
            return ('$ ' + value);
        }
    },
    customHeadRender: ({index, ...column}, sortColumn,value) => {
      return (
          <TableCell  onClick={() => sortColumn(index)}  key={index}>

              {column.label} 
              <Tooltip title={getDecsription(column.label)} arrow>
                <IconButton>
                  <InfoOutlinedIcon/>
                </IconButton>
              </Tooltip>
          </TableCell >
      )
  }
}
function getDecsription(label) {
    if (label === 'Claim Type') {
        return CLAIM_TYPE_DESCRIPTION;
    }
    if (label === 'Service Start Date') {
        return SERVICE_START_DATE_DESCRIPTION;
    }
    if (label === 'Service End Date') {
        return SERVICE_END_DATE_DESCRIPTION;
    }
    if (label === 'BIll Type') {
        return BILL_TYPE_CODE_DESCRIPTION;
    }
    if (label === 'Provider ID') {
        return PROVIDER_ID_DESCRIPTION;
    }
    if (label === 'Provider Name') {
        return PROVIDER_NAME_DESCRIPTION;
    }
    if (label === 'Claim Status') {
        return CLAIM_STATUS_DESCRIPTION;
    }
    if (label === 'Rendering Provider') {
        return RENDERING_PROVIDER_DESCRIPTION;
    }
    if (label === 'Charge Amount') {
        return CHARGE_AMOUNT_DESCRIPTION;
    }
    if (label === 'Amount Allowed') {
        return ALLOWED_AMOUNT_DESCRIPTION;
    }
    if (label === 'Paid Amount') {
        return PAID_AMOUNT_DESCRIPTION;
    }
    if (label === 'Memer ID') {
        return MEMBER_ID_DESCRIPTION;
    }
    if (label === 'Member Name') {
        return MEMBER_NAME_DESCRIPTION;
    }
    if (label === '# of Service Lines') {
        return NO_OF_SERVICELINE_DESCRIPTION;
    }
    if (label === 'BIll Type') {
        return BILL_TYPE_CODE_DESCRIPTION;
    }
    return '';
}

