import React, { useState, useReducer, useEffect } from 'react';
import Slider from '@mui/material/Slider';
import MultiRangeSlider from "multi-range-slider-react";
import '../../MultiRangeSlider.css';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../components/Tab/TabPanel";
import CardComponent from "../../components/Cards/card";
import RangeSliderChart from "../../components/Charts/RangeSliderChart";

function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        "aria-controls": `wrapped-tabpanel-${index}`,
    };
}

const RangeSliderDemo = () => {
    // console.log("code-heat-map");
    const [minValue, set_minValue] = useState(18.2);
    const [maxValue, set_maxValue] = useState(24.4);
    const [value, setValue] = useState('Reported Insights');
    const handleInput = (e) => {
        set_minValue(e.minValue);
        set_maxValue(e.maxValue);
    };
    const [minValueSingle, set_minValueSingle] = useState(0);
    const [maxValueSingle, set_maxValueSingle] = useState(24.4);
    const handleInputSingle = (e) => {
        set_minValueSingle(e.minValue);
        set_maxValueSingle(e.maxValue);
    };
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <div className="container level-summary">
                <AppBar position="static" color="default">
                    <Tabs
                        variant="fullWidth"
                        textColor="primary"
                        indicatorColor="primary"
                        value={value}
                        onChange={handleChange}
                        centered
                    >
                        <Tab
                            value={'Reported Insights'}
                            label="Reported Insights"
                            {...a11yProps('Reported Insights')}
                        />
                    </Tabs>
                </AppBar>
                <TabPanel value={value} index={'Reported Insights'}>
                    <CardComponent
                        heading={`value : ${value}`}
                        body={
                            <>
                                <div className="row mb-5">
                                    <RangeSliderChart minValue={minValue} maxValue={maxValue}></RangeSliderChart>
                                </div>
                                <div className="row mt-5 mb-5"></div>
                                <div className="row mt-5">
                                    <div className="col-lg-12 pr-0">
                                        <MultiRangeSlider
                                            baseClassName="multi-range-slider-black"
                                            min={0}
                                            max={100}
                                            step={10}
                                            ruler={true}
                                            label={true}
                                            disabled={true}
                                            preventWheel={false}
                                            minValue={minValueSingle}
                                            maxValue={maxValueSingle}
                                            style={{ innerHeight: '10px' }}
                                        />
                                    </div>
                                </div>
                            </>}
                    ></CardComponent>
                </TabPanel>

            </div>
        </>);
};
export default RangeSliderDemo;
