import { useState } from "react"
import { anchorTag, hccSeperator } from "../../assets/constants/customRender";
import { CARRIER_ID, GAP_OR_REPORTED, GENDER, HCC, ISSUER_ID, MEMBER_ID, MEMBER_NAME, METAL_TIER, PLAN_ID, REPORTED_INSIGHTS, RISK_MODEL } from "../../assets/constants/string.constants";
import { getAcaYear } from "../../assets/constants/yearList";
import CardComponent from "../../components/Cards/card";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import history from "../../history";
import { connect } from 'react-redux';

const MemberListACA = (props) =>{

    const [type] = useState(props.location.state);
    const [metal] = useState(localStorage.getItem(METAL_TIER)?localStorage.getItem(METAL_TIER):'GOLD');
    const [model] = useState(localStorage.getItem(RISK_MODEL)?localStorage.getItem(RISK_MODEL):'ADULT');
    const [issuer] = useState(localStorage.getItem(ISSUER_ID));
    const [year] = useState(props.yearSelect);
    const [gap_or_reported] = useState(localStorage.getItem(GAP_OR_REPORTED));

    const getMetalOrModel = (Type) =>{
        if(Type == METAL_TIER){
            return metal;
        }
        else if(Type == RISK_MODEL){
            return model;
        }
    };

    const memberCols = [
        {
            label : "Member ID",
            name:"MEMBER_ID",
            options : anchorTag
        },
		{
            name : "MEMBER_Name",
            label : "Member Name"
        },
        {
            name : "Carrier_ID",
            label : "Carrier ID"
        },								
        {
            name : "HCC_POST_HIERARCHY",
            label : "Reported HCC",
            options : hccSeperator
        },
        {
            name : "Risk_Score",
            label : "Risk Score"
        },
        {
            name : "Metal_Tier",
            label : "Metal Tier"
        },
        {
            name : "Risk_Model",
            label : "Risk Model"
        },
        {
            name : "Plan_ID",
            label : "Plan ID"
        }
    ];

    const memberTableClick = (rowdata, colData, cellMeta) =>{
        if((cellMeta.colIndex === 0)&&(colData.props.children != "")){
            localStorage.setItem(MEMBER_ID,rowdata[cellMeta.rowIndex].MEMBER_ID);
            localStorage.setItem(MEMBER_NAME,rowdata[cellMeta.rowIndex].MEMBER_Name);
            localStorage.setItem(CARRIER_ID,rowdata[cellMeta.rowIndex].Carrier_ID);
            localStorage.setItem(PLAN_ID,rowdata[cellMeta.rowIndex].Plan_ID);
            localStorage.setItem(GENDER,rowdata[cellMeta.rowIndex].Gender);
            history.push('/layout/acaMember', REPORTED_INSIGHTS)
        }
        else if((cellMeta.colIndex === 3)&&(colData.props.children != "")){
            if(typeof(colData.props.children) == "object"){
                localStorage.setItem(HCC,(cellMeta.event.target.innerText).replace(',',''));
            }
            else{
                localStorage.setItem(HCC,rowdata[cellMeta.rowIndex].HCC_POST_HIERARCHY);
            }
            localStorage.setItem(ISSUER_ID,rowdata[cellMeta.rowIndex].ISSUER_ID);
            history.push('/layout/acaReportedHcc')
        }
    };

    return(
        <div>
            <CardComponent
            heading={`Members with ${type} : ${getMetalOrModel(type)} and Issuer Id : ${issuer} and Year : ${year}`}
            body={
                <ServerSideGridTable
                    url={'/ACAMembers/risk_model/'+model+'/metal_tier/'+metal+'/year/'+year+'/gap_or_reported/'+gap_or_reported+'/issuer_id/'+issuer +'/flag/C'}
                    columns={memberCols}
                    tableClick={memberTableClick}
                ></ServerSideGridTable>
            }></CardComponent>
             <br/>
            <br/>     
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(MemberListACA);