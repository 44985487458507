import { RAF_RANGE,MEMBER_ID,HCC } from "../../assets/constants/string.constants";
import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import CardComponent from "../../components/Cards/card";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag, hccSeperator, numberWithCommas,numberWithCommasPrefixDolar,addSuffixTwoPercent } from '../../assets/constants/customRender';
import history from "../../history";
import BarVertical from '../../components/GChart/BarVertical';
const PredictionRafDeltaDrillDown = () => {

    const rafRange = useState(localStorage.getItem(RAF_RANGE));
    let rafDRange = rafRange[0];
    // const [HccChartData, setHccChartData] = useState({});
    const [HccChartData, setHccChartData] = useState([]);

    const  memberMedicareCols =[
        {
            name: "MemberID",
            label: "Member ID",
            options:anchorTag
        },
        {
            name: "DELTA_RAF_SCORE",
            label: "Potential RAF Score",
            options:addSuffixTwoPercent
        },
        {
            name: "NUMBER_OF_CONDITION_CATEGORIES",
            label: "No of Conditions"
        },
     
        {
            name: "DELTA_REVENUE_IMPACT",
            label: "Total Revenue Impact",
            options:numberWithCommasPrefixDolar
        },

        
    ];
    const memberTableClick = (rowdata, colData, cellMeta) =>{
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
           localStorage.setItem(MEMBER_ID, rowdata[cellMeta.rowIndex].MemberID);
            history.push('/layout/medicare/PredictionMemberSummary')
        }
    }

    const loadHccChart = () =>{
        let hccCode =[];
        let totalCount =[];
        api.get('/PredictedRafDeltaDrillDownGraph/range/'+rafDRange+'/')
        .then(res=>{
            // console.log(res);
            for (const dataObj of res) {
                hccCode.push((dataObj.description + ':' + dataObj.hccCode));
                totalCount.push(dataObj.totalCount);
            }
            setHccChartData({
                labels: hccCode,
                data:  totalCount,
                dLabel: "Count"
            });
        })
    }
    const getDetailForHCCChart = () => {        
        let newChartData = [];
            newChartData.push(["HCC", "Percentage", { role: 'annotation' }]);
            api.get('/PredictedRafDeltaDrillDownGraph/range/'+rafDRange+'/')
                .then(res => {
                    for (const dataObj of res) {
                        newChartData.push([dataObj.description + ':' + dataObj.hccCode, dataObj.totalCount, dataObj.totalCount]);
                    }
                    setHccChartData(newChartData);
            })
            .catch(err => {
                // console.log(err);
            })
    }

    const hccChartClick =(element) =>{
        let chartData = element[0]['_chart'].config.data;
        let idx = element[0]['_index'];
        let hcc = chartData.labels[idx];
        let parts = hcc.split(':');
        let hcc1 = parts[1];
        localStorage.setItem(HCC,hcc1);
        history.push('/layout/medicare/PredictionHccDrillDown');
    }

    
    useEffect(()=>{
        getDetailForHCCChart();
    },[]);
return(
    <div className="level-summary">
    <div className="row pb-2">
      <div className="col-lg-12">
        <div className="state-level">
          <div className="card-header">
            <span className="d-flex bd-highlight">
              <h6 className="pt-2 flex-grow-1"> DELTA RAF DRILL DOWN</h6>
              <div className="d-flex align-items-center"></div>
            </span>
          </div>
        </div>
      </div>
    </div>          
 <div className='col-lg-12 pr-0 pl-0'>
 <div className="list-member mt-4"> 
 <CardComponent
            heading={`TOP 10 conditions with RAF Range of ${rafDRange}`}
            body={
                <div className="box-height-chart"> 
                <BarVertical data={HccChartData} chartType="BarChart" height="300px" width="100%" setStorgeItemName={HCC} redirectUrl={'medicare/PredictionHccDrillDown'} yAxistitle={"HCC's"}  hAxisTitle={'Percentage (%)'}></BarVertical>
                </div>
            }></CardComponent>
            <br/>
     <CardComponent
         heading={`List of Members with RAF Range of ${rafDRange}`}
         body={

            
                 <ServerSideGridTable
                 
                     url={`/PredictionRafDeltaDrillDown/${rafDRange}/`}
                     columns={memberMedicareCols}
                     tableClick={memberTableClick}
                 ></ServerSideGridTable>
             }>
     </CardComponent>
 </div>
 <br/>
 <br/>
</div>
</div>
);

};
export default PredictionRafDeltaDrillDown;
