import React from 'react'

export default function PieChart3Icon({...props}) {
    return (
        <svg {...props} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.9443 4.4403C7.47115 4.08827 8.02766 3.78883 8.60577 3.5444C10.0087 2.95127 10.7101 2.6547 11.6051 3.24796C12.5 3.84123 12.5 4.81248 12.5 6.755V8.755C12.5 10.6406 12.5 11.5834 13.0858 12.1692C13.6716 12.755 14.6144 12.755 16.5 12.755H18.5C20.4425 12.755 21.4138 12.755 22.007 13.6499C22.6003 14.5449 22.3037 15.2463 21.7106 16.6492C21.4662 17.2273 21.1667 17.7838 20.8147 18.3107C19.7159 19.9552 18.1541 21.2369 16.3268 21.9938C14.4996 22.7507 12.4889 22.9487 10.5491 22.5628C8.60929 22.177 6.82746 21.2246 5.42894 19.8261C4.03041 18.4275 3.078 16.6457 2.69215 14.7059C2.3063 12.7661 2.50433 10.7554 3.26121 8.92816C4.01809 7.1009 5.29981 5.53912 6.9443 4.4403Z" fill="currentcolor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M14.2739 2.88307C14.3771 2.48193 14.786 2.24044 15.1871 2.34368C18.9658 3.31624 21.9389 6.28938 22.9115 10.068C23.0147 10.4692 22.7732 10.878 22.3721 10.9812C21.9709 11.0845 21.5621 10.843 21.4588 10.4419C20.6225 7.19252 18.0626 4.63267 14.8132 3.79634C14.4121 3.69309 14.1706 3.28421 14.2739 2.88307Z" fill="currentcolor"/>
        </svg>
        
    )
}
