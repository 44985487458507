/*eslint-disable*/
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import mainLogo from '../../assets/images/Invent_logo_White.png';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import AwvCustomNavLink from '../CustomNavLink/AwvCustomNavLink';
import AwvNavbarLinks from "../Navbars/AwvNavbarLinks.js";
//import RTLNavbarLinks from "../Navbars/RTLNavbarLinks.js";
import { connect } from 'react-redux';
import styles from "../../assets/jss/awsLayouts/sidebarStyle.js";
import ModuleSelect from '../DropDowns/ModuleSelect';
import { getModuleList, getModuleSelect, ModuleList } from '../../assets/constants/moduleList';

const useStyles = makeStyles(styles);

function AwvSidebar(props) {
  const classes = useStyles();
  const [routeProp, setRouteProp] = React.useState(props.routes);
  const [activateParentClass, setActivateParentClass] = React.useState('');
  const [moduleSelect, setModuleSelect] = React.useState('');

  React.useEffect(() => {

  }, []);

  React.useEffect(() => {

  }, [props.moduleSelect, activateParentClass]);
  const handleClick = (open, key) => {

  };
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return window.location.href.indexOf(routeName) > -1 ? true : false;
  }
  const getParentListClass = (route) => {
    if (routeProp.length > 0) {
      routeProp && routeProp.forEach(element => {
        if (element.name == route) {
          element.subRoutes && element.subRoutes.forEach(elem => {
            if (activeRoute(elem.layout + elem.path)) {
              if (activateParentClass != element.name && element.name == route) {
                setActivateParentClass(element.name);
              }
              return true;
            }
          });
        }
        if (activateParentClass == route) {
          return true;
        }
      });
    }
    return false;
  }

  const getMenuPermissionCheck = (permissionSlug) => {
    var permissionCheck = false;
    if (permissionSlug && permissionSlug != '') {
      var permissionGets = JSON.parse(localStorage.getItem('controlPermissions'));
      if (permissionGets && permissionGets.length > 0) {
        permissionGets && permissionGets.forEach(elem => {
          if (elem.permission == permissionSlug && elem.status == true) {
            permissionCheck = false;
          }
        });
      }
    }
    return permissionCheck;
  }

  const { color, logo, image, logoText } = props;

  const links = (
    <List className={classes.list}>
      {
        routeProp.map((prop, key) => {
          if (getMenuPermissionCheck(prop.permissionSlug) == true) {
            return '';
          }
          return (
            <>
              <List className={classes.item} key={key} data-route={getParentListClass(prop.name)} data-select={activateParentClass}>
                <AwvCustomNavLink
                  color={color}
                  logo={logo}
                  image={image}
                  logoText={logoText}
                  prop={prop}
                  parentSelect={activateParentClass}
                  itemKey={key}
                  navOpen={props.open}
                  moduleSelect={props.moduleSelect}
                  defaultSelect={'/admin/home'}
                >
                </AwvCustomNavLink>
              </List>
            </>
          );
        })
      }
    </List>
  );
  var brand = (
    <div className={classes.logo}>
      <a className={classNames(classes.logoLink, {
        [classes.logoLinkRTL]: props.rtlActive
      })}>
        <div className={classes.logoImage}>
          <img src={mainLogo} width="100" height="100" alt="" className="logo"></img>
        </div>
        {logoText}
      </a>
    </div>
  );
  return (
    <div className="awv_sidebar">
      {/* <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          <div className={classes.sidebarWrapper}>
            {<AwvNavbarLinks />}
            <hr />
            <div style={{ 'marginTop': '5px', 'marginLeft': '5px' }}>
              <div className="d-flex">
                <span><AdminPanelSettingsIcon style={{ 'marginTop': '2px', 'width': '1.5em', 'height': '1.5em' }} /></span>
                <span style={{ 'fontSize': '25px' }}>Admin Portals</span>
              </div>
              <a href='' role="button" style={{ 'marginLeft': '35px', 'fontSize': '10px' }}>My Profile</a>
            </div>
            <hr />
            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden> */}
      {/* <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
        > */}
      <div>
        {/* {brand} */}
        <div className={classes.sidebarWrapper}>
          {/* <div style={{ 'marginTop': '5px', 'marginLeft': '5px' }}>
            <div className="d-flex">
              <span><AdminPanelSettingsIcon style={{ 'marginTop': '2px', 'width': '1.5em', 'height': '1.5em' }} /></span>
              <span style={{ 'fontSize': '25px' }} hidden={props.open? '' : 'hidden'}>AWV Portal</span>
            </div>
            <a href='' role="button" style={{ 'marginLeft': '35px', 'fontSize': '10px' }}>My Profile</a>
          </div> */}
          {/* <hr /> */}
          {links}
        </div>
        {/* {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : "no images"} */}
        {/* </Drawer>
      </Hidden> */}
      </div>
    </div >
  );
}
const mapStateToProps = (state) => {
  return {
    moduleSelect: state.moduleFilter.moduleSelect,
  };
}
export default connect(mapStateToProps)(AwvSidebar);
AwvSidebar.propTypes = {
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool
};
