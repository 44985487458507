import React, { useRef, useState, useEffect } from "react";
import { connect } from 'react-redux';
import AWVApi from '../../../assets/constants/AWVRafservice.Instance';
import { signOut, currentRoute, currentLOBSelect, currentProviderGroupSelect, currentYearSelect } from '../../../store/actions';
import history from '../../../history';
import {
    SELECT_YEAR, SELECT_INITIAL,
    SELECT_METAL_TIER, SELECT_VIEW_BY,
    SELECT_KPI, SELECT_LOB, SELECT_PROVIDER_GROUP,
    SELECT_PLAN, SELECT_MODEL, SELECT_DISTRIBUTION, 
    PROVIDER_GROUP_LIST
} from "../../../assets/constants/string.constants";
import AcaYearSelect from '../../../components/DropDowns/AcaYearSelect';
import { getAcaYearList, getAcaYear, AcaYearList } from '../../../assets/constants/yearList';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

const AwvSubHeader = (props) => {

    const [year, setYear] = useState(); // Intialise value with current year
    const [selectLob, setSelectLob] = useState();
    const [selectProviderGroup, setSelectProviderGroup] = useState();
    const [providerGroup, setProviderGroup] = useState([]);
    // const providerGroup = [
    //     "ALL","AIPA", "AMCV", "AMGS", "AMGW", "AMHP", "AMHS", "AMMP","AP","APCV","API","APIA","APPI","APPL","BVMG","CFC","CFCA","CRCM","CSHA","CSMG","CVPG","EHSG","EPDB",
    // 	"GCMG","HCLA","HCPI","HCPM","HD","HESS","LAKE","OMNI","PH","PIPA",
    // 	"PPN","PROF","PROH","PROI","PROM","PRON","PROS","PROV","REMG","SAMG",
    // 	"SIMG","SLMG","SVIP","SVPI","TMG"
    // ];
    useEffect(() => {
        AcaYearList();
        getProviderGroup();
        if (localStorage.getItem(SELECT_YEAR) && localStorage.getItem(SELECT_YEAR) != '') {
            setYear(localStorage.getItem(SELECT_YEAR));
            props.currentYearSelect(localStorage.getItem(SELECT_YEAR));
        } else {
            console.log(getAcaYearList());
            localStorage.setItem(SELECT_YEAR, new Date().getFullYear());
            setYear(new Date().getFullYear());
            props.currentYearSelect(new Date().getFullYear());
        }

        if (localStorage.getItem(SELECT_LOB) && localStorage.getItem(SELECT_LOB) != '') {
            setSelectLob(localStorage.getItem(SELECT_LOB));
            props.currentLOBSelect(localStorage.getItem(SELECT_LOB));
        } else {
            localStorage.setItem(SELECT_LOB, 2);
            setSelectLob(2);
            props.currentLOBSelect(2);
        }

        if (localStorage.getItem(SELECT_PROVIDER_GROUP) && localStorage.getItem(SELECT_PROVIDER_GROUP) != '') {
            setSelectProviderGroup(localStorage.getItem(SELECT_PROVIDER_GROUP));
            props.currentProviderGroupSelect(localStorage.getItem(SELECT_PROVIDER_GROUP));
        } else {
            localStorage.setItem(SELECT_PROVIDER_GROUP, '0');
            setSelectProviderGroup(1);
            props.currentProviderGroupSelect(0);
        }

    }, []);

    const getProviderGroup = () => {
        AWVApi.get('/getprovidergroup')
            .then(res => {
                if (res.data) {
                    setProviderGroup(res?.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        localStorage.setItem(PROVIDER_GROUP_LIST, JSON.stringify(providerGroup))
    })

    const handleLOBSelect = (e) => {
        localStorage.setItem(SELECT_LOB, e.target.value);
        setSelectLob(e.target.value);
        props.currentLOBSelect(e.target.value);
    }
    const handleProviderGroupSelect = (e) => {
        localStorage.setItem(SELECT_PROVIDER_GROUP, e.target.value);
        setSelectProviderGroup(e.target.value);
        props.currentProviderGroupSelect(e.target.value);
    }

    return (
        <>
            <div>
                <span className="d-flex bd-highlight">
                    <h6 className="pt-2 pl-2 flex-grow-1 header-title-text">{props.title}</h6>
                    <div className="d-flex align-items-center mr-3">
                        <label className="pr-2 pb-0 mb-0">Provider Group</label>
                        <div className="d-flex justify-content-end">
                            <div className="btn-group">
                                <select className="btn btn-info dropdown-toggle btn" value={selectProviderGroup} onChange={handleProviderGroupSelect}>
                                    <option value={'ALL'}> ALL </option>
                                    {providerGroup && providerGroup.length > 0 && providerGroup.map((element, index) =>
                                        <option key={index} value={element}> {element} </option>
                                    )}

                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mr-3">
                        <label className="pr-2 pb-0 mb-0">LOB</label>
                        <div className="d-flex justify-content-end">
                            <div className="btn-group">
                                <select className="btn btn-info dropdown-toggle btn" value={selectLob} onChange={handleLOBSelect}>
                                    <option value={1}> ACA </option>
                                    <option value={2}> Medicare </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <label className="pr-2 pb-0 mb-0">Year</label>
                        <AcaYearSelect
                            AcaYears={getAcaYearList()}
                            year={year}
                            onAcaYearSelect={((e) => setYear(e))}>
                        </AcaYearSelect>
                    </div>
                </span>
            </div>
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        userName: state.auth?.userDetails?.userEmail,
        route: state.route.route,
        moduleSelect: state.moduleFilter.moduleSelect,
    };
}
export default connect(mapStateToProps, { signOut, currentRoute, currentLOBSelect, currentProviderGroupSelect, currentYearSelect })(AwvSubHeader);
