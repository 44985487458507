import React, { useRef, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { signOut } from '../../../store/actions';
import { currentRoute } from '../../../store/actions';
import history from '../../../history';
import {
    SELECT_YEAR, SELECT_INITIAL,
    SELECT_METAL_TIER, SELECT_VIEW_BY,
    SELECT_PLAN, SELECT_MODEL, SELECT_DISTRIBUTION
} from "../../../assets/constants/string.constants";
import AcaYearSelect from '../../../components/DropDowns/AcaYearSelect';
import { getAcaYearList, getAcaYear, AcaYearList } from '../../../assets/constants/yearList';
import api from '../../../assets/constants/AdminRafservice.Instance';
import SlickSlider from '../../../components/SlickSlider/SlickSlider';
import { WIDGET_TYPES } from "../../../assets/constants/string.constants";
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@mui/icons-material/Person';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import GroupIcon from '@mui/icons-material/Group';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SettingsIcon from '@mui/icons-material/Settings';

const AdminSubHeader = (props) => {

    const [year, setYear] = useState();
    const [organizationsData, setOrganizationsData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [WidgetDetails, setWidgetDetails] = useState({
        '# ROLES': 0,
        '# USERS': 0,
        'GROUP': 0,
    });

    const getIcon = (type) => {
        var data = ''
        switch (type) {
            case WIDGET_TYPES.ISSUER:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.SCORE:
                data = <GroupIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERCENT:
                data = <SummarizeIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.MEMBERS:
                data = <SettingsIcon style={widgetStyles} />
                break;
        }

        return data;
    }

    const WidgetDetailsType = [
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.ISSUER,
        WIDGET_TYPES.SCORE,
        WIDGET_TYPES.PERCENT,
    ]

    const type = WidgetDetailsType;
    const widgetStyles = {
        position: 'absolute',
        left: 0,
        //right: 20,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.15,
        color: "white"
    };

    useEffect(() => {
        loadOrganizationData();
        loadSliderUserData(0);
    }, []);

    const loadOrganizationData = () => {
        api.get('/organization/active')
            .then(res => {
                setOrganizationsData(res.data);
            })
            .catch(err => {
                // console.log(err)
            })
    }

    const loadUserOrganizationData = (organizationId) => {
        api.get('/user/organization/' + organizationId)
            .then(res => {
                setUsersData(res.data);
            })
            .catch(err => {
                // console.log(err)
            })
    }

    const loadSliderUserData = (organizationId) => {
        api.get('/user/dashboard/' + organizationId)
            .then(res => {
                setWidgetDetails(prevState => ({
                    ...prevState,
                    '# ROLES': res.data.roleCount,
                    '# USERS': res.data.userCount,
                    'GROUP': res.data.groupCount,
                }));
            })
            .catch(err => {
                // console.log(err)
            })
    }

    const handleChange = (event) => {
        if (event.target.value != 'all') {
            loadUserOrganizationData(event.target.value);
            loadSliderUserData(event.target.value);
        } else {
            loadSliderUserData(0);
            setUsersData([]);
        }
    }

    return (
        <>
            <div className="col-lg-12 pr-0 pl-0 mb-3">
                <div className="state-level">
                    <div className="card-header">
                        <span className="d-flex bd-highlight">
                            <h6 className="pt-2 flex-grow-1 header-title-text">{props.title}</h6>
                            {props.userPage && props.userPage == true ?
                                <>
                                    <div className="d-flex align-items-center mr-3">
                                        <label className="pr-2 pb-0 mb-0">Organization</label>
                                        <div className="d-flex justify-content-end">
                                            <div className="btn-group">
                                                <select onChange={handleChange} className="btn btn-info dropdown-toggle btn" >
                                                    <option value={'all'}>All</option>
                                                    {organizationsData && organizationsData.map((element) => (
                                                        <option key={element.id} value={element.id} >
                                                            {element.title}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center mr-3">
                                        <label className="pr-2 pb-0 mb-0">User</label>
                                        <div className="d-flex justify-content-end">
                                            <div className="btn-group">
                                                <select className="btn btn-info dropdown-toggle btn" >
                                                    <option value={'all'}>All</option>
                                                    {usersData && usersData.map((element) => (
                                                        <option key={element.id} value={element.id} >
                                                            {element.email}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <></>
                            }
                        </span>
                    </div>
                </div>
            </div>
            <div className='col-lg-12 pr-0 pl-0'>
                <SlickSlider WidgetDetails={WidgetDetails} getIcon={getIcon} type={type} />
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        </>
    );
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        userName: state.auth?.userDetails?.userEmail,
        route: state.route.route,
        moduleSelect: state.moduleFilter.moduleSelect,
    };
}
export default connect(mapStateToProps, { signOut, currentRoute })(AdminSubHeader);
