export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const PERMISSION_ASSIGN = 'PERMISSION_ASSIGN';
export const CURRENT_ROUTE = 'CURRENT_ROUTE';
export const CURRENT_MODULE_SELECT = 'CURRENT_MODULE_SELECT';
export const CURRENT_VIEWBY_SELECT = 'CURRENT_VIEWBY_SELECT';
export const CURRENT_MODEL_SELECT = 'CURRENT_MODEL_SELECT';
export const CURRENT_METALTIER_SELECT = 'CURRENT_METALTIER_SELECT';
export const CURRENT_PLAN_SELECT = 'CURRENT_PLAN_SELECT';
export const CURRENT_MEINITIAL_SELECT = 'CURRENT_MEINITIAL_SELECT';
export const CURRENT_YEAR_SELECT = 'CURRENT_YEAR_SELECT';
export const CURRENT_KPI_SELECT = 'CURRENT_KPI_SELECT';
export const CURRENT_DISTRIBUTION_SELECT = 'CURRENT_DISTRIBUTION_SELECT';
export const CURRENT_LOB_SELECT = 'CURRENT_LOB_SELECT';
export const CURRENT_PROVIDER_GROUP_SELECT = 'CURRENT_PROVIDER_GROUP_SELECT';
export const CURRENT_PCP_SELECT = 'CURRENT_PCP_SELECT';
export const CURRENT_PGP_SELECT = 'CURRENT_PGP_SELECT';
export const CURRENT_AWE_SITE_SELECT = 'CURRENT_AWE_SITE_SELECT';
export const CURRENT_AWE_PROVIDER_GROUPING_SELECT = 'CURRENT_AWE_PROVIDER_GROUPING_SELECT';
export const CURRENT_AWE_CITY_SELECT = 'CURRENT_AWE_CITY_SELECT';
export const CURRENT_AWE_ADDRESS_SELECT = 'CURRENT_AWE_ADDRESS_SELECT';
export const CURRENT_AWE_ZIP_SELECT = 'CURRENT_AWE_ZIP_SELECT';
export const CURRENT_AWE_RIGHTSIDE_BAR_TYPE = 'CURRENT_AWE_RIGHTSIDE_BAR_TYPE';
export const CURRENT_AWE_RIGHTSIDE_BAR = 'CURRENT_AWE_RIGHTSIDE_BAR';
export const CURRENT_AWE_RIGHTSIDE_BAR_CLOSE_DATATABLE_RELOAD = 'CURRENT_AWE_RIGHTSIDE_BAR_CLOSE_DATATABLE_RELOAD';
export const LOGOUT_MODEL_OPEN = 'LOGOUT_MODEL_OPEN';
export const LOGOUT_MODEL_CLOSE = 'LOGOUT_MODEL_CLOSE';