import { useEffect, useState } from "react";
import { anchorTag, hccSeperator, addSuffixTwoPercent, addSuffixTwoDecimal } from "../../assets/constants/customRender";
import GapMemberDistribution from "../../assets/constants/gapMemberDistribution";
import api from "../../assets/constants/Rafservice.Instance";
import { CARRIER_ID, GAP_INSIGHTS, GAP_METAL, GAP_MODEL, GAP_OR_REPORTED, GENDER, HCC, ISSUER_ID, LIKELIHOOD_FLAG, LIKELIHOOD_STATUS, MEMBER_ID, MEMBER_NAME, METAL_TIER, PLAN_ID, RISK_MODEL, WIDGET_TYPES,GAP_ISSUER } from "../../assets/constants/string.constants";
import CardComponent from "../../components/Cards/card";
import history from "../../history";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';
import GChartClick from '../../components/GChart/GChartClick';

const IssuerGapSummaryACA = (props) =>{

    const [issuer_id] = useState(localStorage.getItem(ISSUER_ID));
    const [WidgetDetails, setWidgetDetails] = useState({});
    const [memberChartData, setMemberChartData] = useState([]);
    const [gapMetalChart, setgapMetalChart] = useState({});
    const [gapModelChart, setgapModelChart] = useState({});
    const [suspectChartData, setSuspectChartData] = useState({});

    const loadWidgetDetails = () => {

        api.get('/acaGapsummary/year/'+props.yearSelect+'/issuerId/'+issuer_id)
        .then(res => {
            setWidgetDetails({
                '# Members with Gap':  Number(res[0].no_of_members).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                '# Adults with Gap':  Number(res[0].no_of_adults).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                '# Children with Gap':  Number(res[0].no_of_children).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),     
                '# Infant with Gap':  Number(res[0].no_of_infants).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),         
                'Average Gap in Risk Score': res[0].average_risk_score
            });                    
        })
        .catch(err => {
            // console.log(err);
        });
    };

    let dataIndex = 0;

    const backGround = [
        { backgroundColor: "#AAB7B8" },
        { backgroundColor: "#EB984E" },
        { backgroundColor: "#58D68D" },
        { backgroundColor: "#5DADE2" },
        { backgroundColor: "#AF7AC5" },
        { backgroundColor: "#EC7063" }
    ]

    const WidgetDetailsType = [
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.ADULT,
        WIDGET_TYPES.CHILD,
        WIDGET_TYPES.INFANT,
        WIDGET_TYPES.SCORE
    ]

		const type = WidgetDetailsType;
    const widgetStyles = {
        position: 'absolute',
        left: 0,
        //right: 20,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.15,
        color: "white"
		};
	
		const getIcon = (type) => {
			var data = ''
			switch (type) {
					case WIDGET_TYPES.ISSUER:
							data = <AccountBalanceTwoToneIcon style={widgetStyles} />
							break;
					case WIDGET_TYPES.MEMBERS:
							data = <PeopleAltIcon style={widgetStyles} />
							break;
					case WIDGET_TYPES.PERCENT:
							data = <ShowChartIcon style={widgetStyles} />
							break;
					case WIDGET_TYPES.SCORE:
							data = <BarChartIcon style={widgetStyles} />
							break;
					case WIDGET_TYPES.PERSON:
							data = <PersonIcon style={widgetStyles} />
							break;
					case WIDGET_TYPES.CHILD:
							data = <PersonIcon style={widgetStyles} />
							break;
					case WIDGET_TYPES.ADULT:
							data = <PersonIcon style={widgetStyles} />
							break;
					case WIDGET_TYPES.INFANT:
							data = <PersonIcon style={widgetStyles} />
							break;
					case WIDGET_TYPES.REVENUE:
							data = <AttachMoneyIcon style={widgetStyles} />
							break;
					case WIDGET_TYPES.COUNT:
							data = <ShoppingCartIcon style={widgetStyles} />
							break;
					case WIDGET_TYPES.LOCKICON:
							data = <LockOpenIcon style={widgetStyles} />
							break;
					case WIDGET_TYPES.TRENDING:
							data = <TrendingUpIcon style={widgetStyles} />
							break;
			}

			return data;
	}
    const loadGapMetalGraph = () => {
        let chartDataRow = [];
        chartDataRow.push(["Metal Tier", "Risk Score"]);
        api.get('/acaOrgMetalTierGraph/year/' + props.yearSelect)
            .then(res => {
                for (const dataObj of res) {
                    chartDataRow.push([dataObj.metal_tier, dataObj.average_gap_score]);
                }
                setMemberChartData(chartDataRow);
                // setChartData({
                //     labels: metal_tier,
                //     data: avg_gap_score,
                //     dLabel: "Avg Risk Score",
                // })
            })
            .catch(err => {
                // console.log(err);
            })
    }

    const loadGapSuslikelihood = () => {
        let chartDataRow = [];         
            chartDataRow.push(["Gap Suspect Likelihood", "Count", { role: 'annotation' }]);
            api.get('/issuerlikelihoodGraph/year/'+props.yearSelect+'/issuerId/'+issuer_id)
            .then(res => {
                for (const dataObj of res) {
                    chartDataRow.push([dataObj.rafRange, dataObj.countOfMembers, dataObj.countOfMembers]);
                }
                setSuspectChartData(chartDataRow);
            })
            .catch(err => {
                // console.log(err);
            })

    }

    const loadGapMetalChart = () => {
        localStorage.setItem(RISK_MODEL , null)
        let chartDataRow = [];         
            chartDataRow.push(["'Metal Tier", "Avg Risk Score", { role: 'annotation' }]);
            api.get('/acaMetalTierGraph/year/'+props.yearSelect+'/issuerId/'+ btoa(issuer_id))
            .then(res => {
                for (const dataObj of res) {
                    chartDataRow.push([dataObj.metal_tier, dataObj.average_gap_score, dataObj.average_gap_score]);
                }
                setgapMetalChart(chartDataRow);
            })
            .catch(err => {
                // console.log(err);
            })

    }

    const loadGapModelChart = () => {
        localStorage.setItem(METAL_TIER , null)
        let chartDataRow = [];         
            chartDataRow.push(["Risk Model", "Avg Risk Score", { role: 'annotation' }]);
            api.get('/acaRiskModelGraph/year/'+props.yearSelect+'/issuerId/'+btoa(issuer_id))
            .then(res => {
                for (const dataObj of res) {
                    chartDataRow.push([dataObj.risk_model, dataObj.average_gap_score, dataObj.average_gap_score]);
                }
                setgapModelChart(chartDataRow);
            })
            .catch(err => {
                // console.log(err);
            })
    }
    const memberColumn = [ 
        {
            label : "Member ID",
            name:"MEMBER_ID",
            options : anchorTag
        },
        {
            name : "MEMBER_Name",
            label : "Member Name"
        },
        {
            name : "Carrier_ID",
            label : "Carrier ID"
        },								
        {
            name : "Gap",
            label : "Gap HCC",
            options : hccSeperator
        },
        {
            name : "Gap_Score",
            label : "Gap Score",
            options:addSuffixTwoDecimal
        },
        {
            name : "Risk_Score",
            label : "Risk Score",
            options:addSuffixTwoDecimal
        },
        {
            name : "PERCENT_OF_GAP",
            label : "% of gap",
            options:addSuffixTwoPercent
        },
        {
            name : "Metal_Tier",
            label : "Metal Tier"
        },
        {
            name : "Risk_Model",
            label : "Risk Model"
        },
        {
            name : "Gender",
            label : "Gender"
        },
        {
            name : "Plan_ID",
            label : "Plan ID"
        }
    ]

    const memberTableClick = (rowdata, colData, cellMeta) =>{
        if((cellMeta.colIndex === 0)&&(colData.props.children != "")){
            localStorage.setItem(MEMBER_ID,rowdata[cellMeta.rowIndex].MEMBER_ID);
            localStorage.setItem(MEMBER_NAME,rowdata[cellMeta.rowIndex].MEMBER_Name);
            localStorage.setItem(CARRIER_ID,rowdata[cellMeta.rowIndex].Carrier_ID);
            localStorage.setItem(PLAN_ID,rowdata[cellMeta.rowIndex].Plan_ID);
            localStorage.setItem(GENDER,rowdata[cellMeta.rowIndex].Gender);
            history.push('/layout/acaMember', GAP_INSIGHTS)
        }
        else if((cellMeta.colIndex === 3)&&(colData.props.children != "")){
            if(typeof(colData.props.children) == "object"){
                localStorage.setItem(HCC,(cellMeta.event.target.innerText).replace(',',''));
            }
            else{
                localStorage.setItem(HCC,rowdata[cellMeta.rowIndex].Gap);
            }
            localStorage.setItem(ISSUER_ID,rowdata[cellMeta.rowIndex].ISSUER_ID);
            history.push('/layout/AcaGapHcc')
        }
    };

    useEffect(() =>{
        loadWidgetDetails();
        loadGapMetalChart();
        loadGapModelChart();
        loadGapMetalGraph();
        loadGapSuslikelihood();
    },[
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect,
        props.meInitialSelect
    ]);

		let sliderRange = Object.entries(WidgetDetails).length / 3;
		sliderRange = parseInt(sliderRange);
	
    return(
        <div className="container level-summary">
            <div className="row mb-3">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Issuer Gap Summary ACA'} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <SlickSlider WidgetDetails={WidgetDetails} getIcon={getIcon} type={type} />
                </div>

                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <GapMemberDistribution
                        year={props.yearSelect}
                        type={GAP_ISSUER}
                        MemberChartData={memberChartData} 
                        >
                    </GapMemberDistribution>
                </div>
                

                {/* Changing SingleVerticalBarChart to  GChartClick to resolve font size issue
                @author Sonal Jain */}

                <div className="row col-lg-12 pr-0 pl-0 mb-3">
                    <div className="col-sm-4">
                    <CardComponent
                        heading={`DISTRIBUTION OF MEMBERS BASED ON GAP SUSPECT LIKELIHOOD`}
                        body={
                            <GChartClick data={suspectChartData} chartType="ColumnChart"  height="300px" width="100%" setStorgeItemName={issuer_id} setStorgeSecondItemName = {GAP_OR_REPORTED} setStorgeSecondValueName = {LIKELIHOOD_STATUS} setStorgeThirdItemName = {LIKELIHOOD_FLAG} setStorgeThirdValueName = {LIKELIHOOD_FLAG} redirectUrl={'acaGapSummaryMembers'} hAxisTitle={"Gap Suspect Likelihood"} yAxistitle={"Member Count"}></GChartClick>
                        }></CardComponent>
                    </div>
                
                    <div className="col-sm-4">
                        <CardComponent
                        heading={`GAP IN RISKSCORE BY METAL LEVEL`}
                        body={
                            <GChartClick data={gapMetalChart} chartType="ColumnChart"  height="320px" width="100%" setStorgeItemName={METAL_TIER} setStorgeSecondItemName = {GAP_OR_REPORTED} setStorgeSecondValueName = {GAP_METAL} redirectUrl={'acaGapSummaryMembers'} hAxisTitle={"Metal Tier"} yAxistitle={"Risk Score"}></GChartClick>
                        }></CardComponent>
                    </div>
                    <div className="col-sm-4">
                        <CardComponent
                        heading={`GAP IN RISKSCORE BY MODEL`}
                        body={
                            <GChartClick data={gapModelChart} chartType="ColumnChart"  height="320px" width="100%" setStorgeItemName={[RISK_MODEL]} setStorgeSecondItemName = {GAP_OR_REPORTED} setStorgeSecondValueName= {GAP_MODEL} redirectUrl={'acaGapSummaryMembers'} hAxisTitle={"Risk Model"} yAxistitle={"Risk Score"}></GChartClick>
                        }></CardComponent>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br/>

                <br/>
                <br/> 
  
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(IssuerGapSummaryACA);
