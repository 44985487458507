import React, { useState, useEffect } from "react";
import GridTable from "../../components/TableGrids/GridTable.table";
import api from "../../assets/constants/Rafservice.Instance";
import CardComponent from "../../components/Cards/card";
import { CONDITION } from "../../assets/constants/string.constants";
import { anchorTag } from '../../assets/constants/customRender';
import history from '../../history';
import SubHeader from '../../components/home/header/subHeader';
import { connect } from 'react-redux';
const ConditionalPrediction = (props) => {


    const [tableData, setTableData] = useState([]);

    const Columns = [
        {
            name: "group",
            label: "Condition/Group Description",
            options: anchorTag
        },
        {
            name: "codes",
            label: "List of Condition Codes"
        }
    ]




    const loadConditionalist = () => {
        api.get('/ConditionPredictor')
            .then(res => {
                //    console.log(res);
                setTableData(res);
            })
            .catch(err => {
                // console.log(err);
            })

    }


    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            loadConditionalist();
        }
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect,
        props.meInitialSelect,
    ]);


    const memberTableClick = (element, el) => {
        let col = el.colIndex;
        if (col == 0) {
            localStorage.setItem(CONDITION, element.props.children);
            history.push('/layout/medicare/ConditionalDrillDown');
        }
    }


    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'CONDITION PREDICTOR'} selectType={'utilization'} />
                        </div>
                    </div>
                </div>
            </div>



            <div className="col-lg-12 pr-0 pl-0">
                <div className="state-level">
                    <CardComponent
                        heading={`CONDITION PREDICTOR`}
                        className="issuer-details"
                        body={
                            <GridTable
                                data={tableData}
                                columns={Columns}
                                pagination={true}
                                download={true}
                                search={true}
                                print={true}
                                viewColumns={true}
                                onTableClick={memberTableClick}
                            ></GridTable>
                        }></CardComponent>
                </div>
            </div>

            <br />
            <br />
        </div>



    );
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default connect(mapStateToProps)(ConditionalPrediction);
