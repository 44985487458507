import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import {
    currentAWERightSidebarType,
    currentAWERightSidebar,
    currentAWERightSidebarCloseDatatableReload,
} from "../../../store/actions";
import AWVApi from "../../../assets/constants/AWVRafservice.Instance";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import Divider from "@mui/material/Divider";
import { useForm, Controller, set } from "react-hook-form";
import Box from "@mui/material/Box";
import pdfIcon from "../../../assets/images/pdf_icon.png";
import Moment from "moment";
import LinearProgress, {
    linearProgressClasses,
} from "@mui/material/LinearProgress";
import Typography from "@material-ui/core/Typography";
import { CircularProgress, OutlinedInput } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import hcc_json from "../../../Pages/config/HCC_Code.config.json";
import dx_json from "../../../Pages/config/DX_Code.config.json";
import Autocomplete from "@mui/material/Autocomplete";
import rxc_json from "../../../Pages/config/RXC_Code.config.json";
import cpt_json from "../../../Pages/config/CPT_Code.config.json";
import ndc_json from "../../../Pages/config/NDC_Code.config.json";
import hcc_aca_json from "../../../Pages/config/HCC_CODE_ACA.config.json"
import IconButton from "../../layout/aweLayout/IconButton";
import Button from "../../layout/aweLayout/Button";
import CloseIcon from "../../icons/CloseIcon";
import PlusFileIcon from '../../icons/PlusFileIcon';

const AWVMemberDetails = (props) => {
    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        control,
        getValues,
        setValue,
    } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
    const [editFormData, setEditFormData] = useState({});
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const [hccData, setHCCData] = useState([]);
    const [selectGroupsArray, setSelectGroupsArray] = useState([]);
    const [hccValue, setHCCValues] = React.useState();
    const [inputValueHCC, setInputValueHCC] = React.useState("");
    const [inputValueHCCDescription, setInputValueHCCDescription] =
        React.useState("");
    const [inputValueDXDescription, setInputValueDXDescription] =
        React.useState("");
    const [dxValue, setDXValues] = React.useState();
    const [dxCodeData, setDXCodeData] = useState([]);
    const [codeList, setCodeList] = useState([]);
    const [riskcodetype, setRiskcodetype] = useState("");
    const [codeListType, setCodeListType] = useState("");
    const [rxcValue, setRXCValues] = React.useState();
    const [inputValueRXCDescription, setInputValueRXCDescription] =
        React.useState();
    const [rxcData, setRXCData] = useState([]);
    const [cptValue, setCPTValues] = React.useState();
    const [cptCodeData, setCPTCodeData] = useState([]);
    const [inputValueCPTDescription, setInputValueCPTDescription] =
        React.useState();
    const [ndcValue, setNDCValues] = React.useState();
    const [ndcCodeData, setNDCodeData] = useState([]);
    const [previousHccValue, setPreviousHccValue] = useState(editFormData?.HCC_Code);
    const [previousRxcValue, setPreviousRxcValue] = useState(editFormData?.rxc_code);
    const [previousDXCode, setpreviousDXCode] = useState(editFormData?.DX_code);
    const [hccRecordData, setHCCRecordData] = useState([]);

    useEffect(() => {
        if (
            props.aweRightSidebarType?.cin_id &&
            props.aweRightSidebarType?.cin_id != ""
        ) {
            editLoadFormData(props.aweRightSidebarType?.cin_id);
        }
        loadHCCRecordData(props.aweRightSidebarType?.cin_id)
    }, [props.aweRightSidebarType?.cin_id]);

    useEffect(() => {

        /*  if (
           (editFormData?.HCC_Code && editFormData?.HCC_Code != "") ||
           (editFormData?.rxc_code && editFormData?.rxc_code != "")
         ) { */
        setValue("remark", editFormData?.remark);
        setValue("HCC_Confirmed", editFormData?.HCC_Confirmed);
        setValue("DX_description", editFormData?.DX_description);
        setValue("meat_flag", editFormData?.meat_flag);
        setValue("DX_code", editFormData?.DX_code);
        setValue("HCC_Description", editFormData?.HCC_Description);
        setValue("HCC_Code", editFormData?.HCC_Code);
        setValue("previous_hcc", editFormData?.HCC_Code ? editFormData?.HCC_Code : "");
        setValue("CIN", editFormData?.CIN);
        setHCCValues(editFormData?.HCC_Code);
        setInputValueRXCDescription(editFormData?.rxc_description);
        setInputValueHCCDescription(editFormData?.HCC_Description);
        setInputValueDXDescription(editFormData?.DX_description);
        setDXValues(editFormData?.DX_code);
        setValue("awe_id", editFormData?.awv_id);
        //RENDERING THE DATA VALUE INTO RIGHT SIDE BAR
        setValue(
            "previous_rxc",
            editFormData?.rxc_code ? editFormData?.rxc_code : ""
        );
        setValue("risk_code_type", editFormData?.risk_code_type);
        setRiskcodetype(editFormData?.risk_code_type);
        setValue("rxc_code", editFormData?.rxc_code);
        setValue("rxc_description", editFormData?.rxc_description);
        updateCodeList(editFormData?.risk_code_type);
        setValue("source_code_type", editFormData?.source_code_type ? editFormData?.source_code_type : "");
        setCodeListType(editFormData?.source_code_type);
        setRXCValues(editFormData?.rxc_code);
        autoPopulateDXDescription(editFormData?.DX_code);
        autoPopulateHccDescription(editFormData?.HCC_Code);
        setValue("cpt_code", editFormData?.cpt_code);
        setCPTValues(editFormData?.cpt_code);
        autoPopulateCPTDescription(editFormData?.cpt_code);
        autoPopulateRxcDescription(editFormData?.rxc_code);
        setValue("ndc_code", editFormData?.ndc_code);
        setNDCValues(editFormData?.ndc_code);
        //adding primary key id to resolve null hcc code update issue
        setValue("id", editFormData?.id ? String(editFormData?.id) : "");
        // }
    }, [editFormData]);

    useEffect(() => {
        setPreviousHccValue(editFormData?.HCC_Code);
        setPreviousRxcValue(editFormData?.rxc_code);
        setpreviousDXCode(editFormData?.DX_code);

    }, [editFormData?.HCC_Code, editFormData?.rxc_code]);

    const notificationRightDrawer = (open, valueId = "") => {
        props.currentAWERightSidebarType({ type: "notification" });
        props.currentAWERightSidebar(open);
    };

    const editLoadFormData = (value) => {
        setIsLoading(true);
        AWVApi.get(
            "/get_member_hcc_details?cin=" +
            btoa(value) +
            "&year=" +
            props?.yearSelect +
            "&lob=" +
            props?.lobSelect +
            "&organisation_id=1"
        )
            .then((res) => {
                if (res.data && res.data.length > 0) {
                    res.data.forEach((element) => {
                        if (
                            (element.id && element.id === props.aweRightSidebarType?.id &&
                                element.DX_code === props.aweRightSidebarType?.DX_code &&
                                element.HCC_Code === props.aweRightSidebarType?.HCC_Code) ||
                            (element.id === props.aweRightSidebarType?.id &&
                                props.aweRightSidebarType?.rxc_code && props.aweRightSidebarType?.rxc_code !== "" &&
                                element.rxc_code === props.aweRightSidebarType?.rxc_code &&
                                (element.ndc_code === props.aweRightSidebarType?.ndc_code ||
                                    element.cpt_code === props.aweRightSidebarType?.cpt_code ||
                                    element.DX_code === props.aweRightSidebarType?.DX_code))
                        ) {
                            setEditFormData(element);
                            setIsLoading(false);
                        }
                    });
                }
                setIsLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });
    };

    const loadHCCRecordData = (value) => {

        AWVApi.get("/get_member_hcc_details?cin=" +
            btoa(value) +
            "&year=" +
            props?.yearSelect +
            "&lob=" +
            props?.lobSelect +
            "&organisation_id=1"
        )
            .then((res) => {
                if (res.data && res.data.length > 0) {
                    setHCCRecordData(res.data);
                }
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false);
            });

    }

    useEffect(() => {

    }, [editFormData]);


    //rendering the codelist as per risk code type changes
    useEffect(() => {
        if (riskcodetype && riskcodetype != "") {
            updateCodeList(riskcodetype);
        }
    }, [riskcodetype]);

    // set value according to LOB .
    const updateCodeList = (event) => {
        const selectedValue = event;
        // setRiskcodetype(selectedValue);
        if (selectedValue === "HCC") {
            setCodeList(["DX_CODE"]);
        } else if (selectedValue === "RXC") {
            setCodeList(["NDC", "CPT_CODE"]);
        }
        //IF RISK CODE TYPE CHANGES DURING EDIT THEN REMOVING PREVIOUS VALUE ASSIGNED SO THAT UPDATE THE NEW VALUE
        if (selectedValue != editFormData?.risk_code_type && props.lobSelect == 1) {
            setCodeListType("")
            setCPTValues("")
            setNDCValues("")
            setHCCValues("")
            setRXCValues("")
            setDXValues("")
        }
    };
    //IF SOURCE CODE TYPE CHANGES DURING EDIT THEN REMOVING PREVIOUS VALUE ASSIGNED SO THAT UPDATE THE NEW VALUE
    useEffect(() => {
        if (codeListType != editFormData?.source_code_type) {
            setCPTValues("")
            setNDCValues("")
            // setHCCValues("")
        }

    }, [codeListType])

    //default selecting the value of source_code_type and risk_code_type in case of medicare
    useEffect(() => {
        if (props.lobSelect == 2) {
            setRiskcodetype("HCC");
            setCodeListType("DX_CODE");
            setValue("risk_code_type", "HCC");
            setValue("source_code_type", "DX_CODE");
        }

    }, [props.lobSelect])
    //RENDER AUTOPOPULATE WHEN RXC_VALUE CHANGES
    useEffect(() => {
        autoPopulateRxcDescription(rxcValue)
    }, [rxcValue])

    //RENDER AUTOPOPULATE WHEN HCC_VALUE CHANGES
    useEffect(() => {
        autoPopulateHccDescription(hccValue)
    }, [hccValue])

    //RENDER AUTOPOPULATE WHEN CPT_VALUE CHANGES
    useEffect(() => {
        autoPopulateCPTDescription(cptValue)
    }, [cptValue])

    //RENDER AUTOPOPULATE WHEN DX_VALUE CHANGES
    useEffect(() => {
        autoPopulateDXDescription(dxValue)
    }, [dxValue])


    //load HCC codes from JSON hcc_json->Medicare || hcc_aca_json -> ACA
    const loadHCCData = (value) => {
        let values = [];
        setHCCValues(value);
        setValue("HCC_Code", value);
        if (props.lobSelect == 2 && hcc_json && hcc_json.length > 0 && value != '') {
            values = hcc_json.filter(element =>
                (element.hcc_code.toLowerCase()).includes(value.toLowerCase())
            ).map(element => element.hcc_code);
            setHCCData(values);
        } else if (hcc_aca_json && hcc_aca_json.length > 0 && value != '') {
            values = hcc_aca_json.filter(element =>
                (element.hcc_code.toLowerCase()).includes(value.toLowerCase())
            ).map(element => element.hcc_code);
            setHCCData(values);
        } else {
            setHCCData(values);
        }
    };

    const loadHCCDataSelected = (value) => {
        let values = [];
        setHCCValues(value);
        setHCCData(values);
        setValue("HCC_Code", value);
    };

    //load DX codes from JSON
    const loadDXData = (value) => {
        let values = [];
        setDXValues(value);
        setValue("DX_code", value);
        if (dx_json && dx_json.length > 0 && value != '') {
            values = dx_json.filter(element =>
                (element.dx_code.toLowerCase()).includes(value.toLowerCase())
            ).map(element => element.dx_code);
            setDXCodeData(values);
        } else {
            setDXCodeData(values);
        }
    };

    const loadDXDataSelected = (value) => {
        let values = [];
        setDXValues(value);
        setDXCodeData(values);
        setValue("DX_code", value);
    };

    //load RXC codes from JSON
    const loadRXCData = (value) => {
        let values = [];
        setRXCValues(value);
        setValue("rxc_code", value);
        if (rxc_json && rxc_json.length > 0 && value != '') {
            values = rxc_json.filter(element =>
                (element.rxc_code.toLowerCase()).includes(value.toLowerCase())
            ).map(element => element.rxc_code);
            setRXCData(values);
        } else {
            setRXCData(values);
        }
    };

    const loadRXCDataSelected = (value) => {
        let values = [];
        setRXCValues(value);
        setRXCData(values);
        setValue("rxc_code", value);
    };

    //load CPT codes from JSON
    const loadCPTData = (value) => {
        let values = [];
        setCPTValues(value);
        setValue("cpt_code", value);
        if (cpt_json && cpt_json.length > 0 && value != '') {
            values = cpt_json.filter(element =>
                (element.cpt_code.toLowerCase()).includes(value.toLowerCase())
            ).map(element => element.cpt_code);
            setCPTCodeData(values);
        } else {
            setCPTCodeData(values);
        }
    };

    const loadCPTDataSelected = (value) => {
        let values = [];
        setCPTValues(value);
        setCPTCodeData(values);
        setValue("cpt_code", value);
    };

    //load NDC codes from JSON
    const loadNDCData = (value, rxc_value = '') => {
        let values = [];
        setNDCValues(value);
        setValue("ndc_code", value);
        if (ndc_json && ndc_json.length > 0 && value != '') {
            values = ndc_json.filter(element =>
                (element.NDC.toLowerCase()).includes(value.toLowerCase())
            ).map(element => element.NDC);
            setNDCodeData(values);
        } else {
            setNDCodeData(values);
        }
    };

    const loadNDCDataSelected = (value, rxc_value = '') => {
        let values = [];
        setNDCValues(value);
        setNDCodeData(values);
        setValue("ndc_code", value);
    };

    const onSubmit = (data) => {
        if ((hccValue && hccValue != "") || (rxcValue && rxcValue != "")) {
            const { aweRightSidebarType } = props;
            setFormSubmitisLoading(true);
            let controlUser = JSON.parse(localStorage.getItem("controlUser"));
            // data.risk_code_type =riskcodetype;
            // data.source_code_type =codeListType;
            data.rxc_code = rxcValue ? rxcValue : "";
            data.ndc_description = "";
            data.rxc_description = inputValueRXCDescription
                ? inputValueRXCDescription
                : "";
            data.ndc_code = ndcValue ? ndcValue : "";
            data.cpt_code = cptValue ? cptValue : "";
            data.cpt_description = inputValueCPTDescription
                ? inputValueCPTDescription
                : "";
            data.HCC_Code = hccValue ? hccValue : "";
            data.HCC_Description = inputValueHCCDescription
                ? inputValueHCCDescription
                : "";
            data.DX_code = dxValue ? dxValue : "";
            data.DX_description = inputValueDXDescription
                ? inputValueDXDescription
                : "";
            data.remark = data.remark ? data.remark : "";
            data.meat_flag =
                data.meat_flag !== null && data.meat_flag !== undefined
                    ? String(data.meat_flag)
                    : "";
            data.cin = props.aweRightSidebarType?.cin_id;
            // adding lob, year, org id in payload
            data.lob = String(props?.lobSelect);
            data.year = String(props?.yearSelect);

            //default selecting the value of source_code_type and risk_code_type in case of medicare
            if (props.lobSelect == 2) {
                data.risk_code_type = "HCC";
                data.source_code_type = "DX_CODE"
            }

            data.organisation_id = "1";
            if (
                props?.aweRightSidebarType?.status &&
                props?.aweRightSidebarType?.status == "1"
            ) {
                data.created_by = controlUser?.user_id;
                data.created_on = Moment().format("YYYY-MM-DD HH:mm:ss");
                data.awe_id = props.aweRightSidebarType?.aweId.toString();
                let errorMessage = "";
                if (aweRightSidebarType) {

                    console.log(hccRecordData);
                    let recordExists = [];
                    recordExists = hccRecordData.filter((val) => val.HCC_Code === data.HCC_Code && val.DX_code === data.DX_code);
                    console.log(recordExists);

                    /*
                    if (aweRightSidebarType.hccCodeList && aweRightSidebarType.hccCodeList.includes(data.HCC_Code)) {
                      errorMessage = 'HCC Code already exists. Kindly, enter new HCC.';
                    } else if (aweRightSidebarType.rxcCodeList && aweRightSidebarType.rxcCodeList.includes(data.rxc_code)) {
                      errorMessage = 'RXC Code already exists. Kindly, enter new RXC.';
                    }
                    */
                    if (recordExists.length > 0) {
                        errorMessage = 'HCC Code and RXC Code already exists. Kindly, enter new HCC details.';
                    }

                }
                if (errorMessage) {
                    setFormSubmitisLoading(false);
                    toast.error(errorMessage);
                } else {
                    data.awe_id = btoa(data.awe_id);
                    data.cin = btoa(data.cin);
                    if(data?.lob)
                     data.lob = data.lob.toString();
                    AWVApi.post("/create-hcc-details", data)
                        .then((res) => {
                            setValue("remark", "");
                            setValue("HCC_Confirmed", "");
                            setValue("DX_description", "");
                            setInputValueDXDescription("");
                            setInputValueRXCDescription("");
                            setValue("meat_flag", "");
                            setValue("DX_code", "");
                            setDXValues("");
                            setValue("HCC_Description", "");
                            setValue("source_code_type", "");
                            setCodeListType("");
                            setValue("risk_code_type", "");
                            setRiskcodetype("");
                            setInputValueHCCDescription("");
                            setValue("HCC_Code", "");
                            setHCCValues("");
                            setValue("previous_hcc", "");
                            setValue("previous_rxc", "");
                            setValue("CIN", "");
                            setHCCValues("");
                            setInputValueHCCDescription("");
                            setInputValueDXDescription("");
                            setInputValueRXCDescription("");
                            setDXValues("");
                            setFormSubmitisLoading(false);
                            toast.success("Member Risk Code added successfully");
                            notificationRightDrawer(false);
                            props.currentAWERightSidebarCloseDatatableReload(true);
                        })
                        .catch((err) => {
                            setFormSubmitisLoading(false);
                            toast.error(err?.response?.data?.message);
                        });
                }
            } else {
                data.updated_by = controlUser?.user_id;
                let errorMessage = "";
                let recordExists = [];
                recordExists = hccRecordData.filter((val) => val.HCC_Code === data.HCC_Code && val.DX_code === data.DX_code);
                if (data.HCC_Code == previousHccValue && data.DX_code == previousDXCode && previousDXCode != '') {
                    recordExists = [];
                }
                if (aweRightSidebarType) {
                    /*
                    if (aweRightSidebarType.hccCodeList && aweRightSidebarType.hccCodeList.includes(data.HCC_Code) && data.HCC_Code != previousHccValue ) {
                      errorMessage = 'HCC Code already exists. Kindly, enter new HCC.';
                    } else if (aweRightSidebarType.rxcCodeList && aweRightSidebarType.rxcCodeList.includes(data.rxc_code) && data.rxc_code != previousRxcValue) {
                      errorMessage = 'RXC Code already exists. Kindly, enter new RXC.';
                    }
                    */
                    if (recordExists.length > 0) {
                        errorMessage = 'HCC Code and RXC Code already exists. Kindly, enter new HCC details.';
                    }
                }
                if (errorMessage) {
                    setFormSubmitisLoading(false);
                    toast.error(errorMessage);
                } else {
                    if(data?.lob)
                        data.lob = data.lob.toString();
                    data.awe_id = btoa(data.awe_id);
                    data.cin = btoa(data.cin);
                    AWVApi.put("/update-member-hcc-details", data)
                        .then((res) => {
                            setValue("remark", "");
                            setValue("HCC_Confirmed", "");
                            setValue("DX_description", "");
                            setInputValueDXDescription("");
                            setInputValueRXCDescription("");
                            setValue("meat_flag", "");
                            setValue("DX_code", "");
                            setDXValues("");
                            setValue("HCC_Description", "");
                            setInputValueHCCDescription("");
                            setValue("HCC_Code", "");
                            setValue("source_code_type", "");
                            setCodeListType("");
                            setValue("risk_code_type", "");
                            setRiskcodetype("");
                            setHCCValues("");
                            setValue("previous_hcc", "");
                            setValue("previous_rxc", "");
                            setValue("CIN", "");
                            setHCCValues("");
                            setInputValueHCCDescription("");
                            setDXValues("");
                            setFormSubmitisLoading(false);
                            toast.success("Member Risk Code updated successfully");
                            notificationRightDrawer(false);
                            props.currentAWERightSidebarCloseDatatableReload(true);
                        })
                        .catch((err) => {
                            setFormSubmitisLoading(false);
                            toast.error(err?.response?.data?.message);
                        });
                }
            }
        }
    };

    //to auto populate HCC dexcription on selection of HCC code hcc_json->Medicare || hcc_aca_json -> ACA
    var hccDesc = "";
    const autoPopulateHccDescription = (value) => {
        if (value && value != "") {
            if (props.lobSelect == 2) {
                hcc_json.forEach((ele, index) => {
                    if (ele.hcc_code == value) {
                        hccDesc = ele.hcc_description;
                        setInputValueHCCDescription(hccDesc);
                        setValue('HCC_Description', hccDesc);
                    }
                })
            }
            else {
                hcc_aca_json.forEach((ele, index) => {
                    if (ele.hcc_code == value) {
                        hccDesc = ele.hcc_description;
                        setInputValueHCCDescription(hccDesc);
                        setValue('HCC_Description', hccDesc);
                    }
                })
            }

        } else {
            setInputValueHCCDescription("");
            setValue('HCC_Description', '');
        }
    };

    //to auto populate dx dexcription on selection of dx code
    var dxDesc = "";
    const autoPopulateDXDescription = (value) => {
        if (value && value != "") {
            dx_json.forEach((ele, index) => {
                if (ele.dx_code == value) {
                    dxDesc = ele.dx_description;
                    setInputValueDXDescription(dxDesc);
                    setValue('DX_description', dxDesc);
                }
            });
        } else {
            setInputValueDXDescription("");
            setValue('DX_description', '');
        }
    };
    //to auto populate RXC dexcription on selection of RXC code
    var rxcDesc = "";
    const autoPopulateRxcDescription = (value) => {

        if (value && value != "") {
            let rxc_value = value.split(" ");
            rxc_json.forEach((ele, index) => {
                if (ele.rxc_code == value) {
                    rxcDesc = ele.rxc_description;
                    setInputValueRXCDescription(rxcDesc);
                    setValue('rxc_description', rxcDesc);
                    loadNDCData(rxc_value[1]);
                }
            });
        } else {
            setInputValueRXCDescription("");
            setValue('rxc_description', '');
            setNDCodeData("");
            loadNDCData("");
            setNDCValues("");
            setRXCValues("");

        }
    };
    //to auto populate CPT dexcription on selection of RXC code
    var cptDesc = "";
    const autoPopulateCPTDescription = (value) => {
        if (value && value != "") {
            cpt_json.forEach((ele, index) => {
                if (ele.cpt_code == value) {
                    cptDesc = ele.cpt_description;
                    setInputValueCPTDescription(cptDesc);
                    setValue('cpt_description', cptDesc)
                }
            });
        } else {
            setInputValueCPTDescription("");
            setValue('cpt_description', '')
        }
    };

    const handleChangeRXCValues = (value) => {

    };

    return (
        <div className="diagnosis_code_sidebar offcanvas offcanvas-end show" tabIndex={-1} aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header flex_item_cb">
                <h5 id="offcanvasRightLabel" className='fs15'>
                    {props?.aweRightSidebarType?.status &&
                        props?.aweRightSidebarType?.status == "1"
                        ? "Member Detail Report ADD"
                        : "Member Detail Report EDIT"}
                </h5>
                <Tooltip title="Close">
                    <IconButton type="button" buttonType={"default"} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => notificationRightDrawer(false)}>
                        <CloseIcon className="w24" />
                    </IconButton>
                </Tooltip>
            </div>
            <Divider />
            <div className="offcanvas-body pt-1 custom-scrollbar overflow-auto">
                <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model" enctype="multipart/form-data">
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">RISK CODE TYPE</label>
                        <div className="flex_item_cs gap8">
                            <Controller
                                name="risk_code_type"
                                value={editFormData?.risk_code_type}
                                control={control}
                                render={({ field }) =>
                                    props.lobSelect == 2 ? (
                                        <>
                                            <select
                                                {...field}
                                                className="form-control"
                                                onClick={(e) => setRiskcodetype(e.target.value)}
                                            >
                                                <option value={''} >
                                                    Select
                                                </option>
                                                <option value={'HCC'} >
                                                    HCC
                                                </option>
                                            </select>
                                        </>
                                    ) : (
                                        <>
                                            <select
                                                {...field}
                                                className="form-control"
                                                onClick={(e) => setRiskcodetype(e.target.value)}
                                            >
                                                <option value={''} >
                                                    Select
                                                </option>
                                                <option value={'HCC'} >
                                                    HCC
                                                </option>
                                                <option value={'RXC'} >
                                                    RXC
                                                </option>
                                            </select>
                                        </>
                                    )
                                }
                                rules={{
                                    required: true,
                                }}
                            />
                        </div>
                        {errors?.risk_code_type?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">SOURCE CODE TYPE</label>
                        <div className="flex_item_cs gap8">
                            <Controller
                                name="source_code_type"
                                value={editFormData?.source_code_type}
                                control={control}
                                render={({ field }) =>
                                    props.lobSelect == 2 ? (
                                        <>
                                            <select
                                                {...field}
                                                className="form-control"
                                                onClick={(e) => setCodeListType(e.target.value)}
                                            >
                                                <option value={''} >
                                                    Select
                                                </option>
                                                <option value={'DX_CODE'} >
                                                    DX_CODE
                                                </option>
                                            </select>
                                        </>
                                    ) : (
                                        <>
                                            <select
                                                {...field}
                                                className="form-control"
                                                onClick={(e) => setCodeListType(e.target.value)}
                                            >
                                                <option value={''} >
                                                    Select
                                                </option>
                                                {codeList && codeList.length > 0 &&
                                                    codeList.map((element, index) => (
                                                        <option
                                                            key={index}
                                                            value={element}
                                                        >
                                                            {element}
                                                        </option>
                                                    ))
                                                }
                                            </select>
                                        </>
                                    )
                                }
                                rules={{
                                    required: true,
                                }}
                            />
                        </div>
                        {errors?.source_code_type?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    {riskcodetype === "RXC" && (
                        <>
                            <div className="form-group mb20 custom-autocomplete">
                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2">RISK CODE</label>
                                <Controller
                                    name="rxc_code"
                                    value={rxcValue}
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            type="text"
                                            onChange={(event) => loadRXCData(event?.target?.value)}
                                            placeholder="Type to search..."
                                            autoComplete="off"
                                            className="form-control"
                                        />
                                    )
                                    }
                                    rules={{
                                        required: true,
                                    }}
                                />
                                <ul className="options custom-scrollbar">
                                    {rxcData.map((option, index) => (
                                        <li key={index} onClick={() => loadRXCDataSelected(option)}>
                                            {option}
                                        </li>
                                    ))}
                                </ul>
                                {errors?.rxc_code?.type === "required" && <label className="form-input-error">This field is required</label>}
                            </div>
                            <div className="form-group mb20">
                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2">RISK CODE DESCRIPTION</label>
                                <Controller
                                    name="rxc_description"
                                    value={inputValueRXCDescription}
                                    control={control}
                                    render={({ field }) => (
                                        <input type="text" {...field} className="form-control" />
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.rxc_description?.type === "required" && <label className="form-input-error">This field is required</label>}
                            </div>
                        </>
                    )}
                    {riskcodetype === "HCC" && (
                        <>
                            <div className="form-group mb20 custom-autocomplete">
                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2">RISK CODE</label>
                                <Controller
                                    name="HCC_Code"
                                    value={hccValue}
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            type="text"
                                            onChange={(event) => loadHCCData(event?.target?.value)}
                                            placeholder="Type to search..."
                                            autoComplete="off"
                                            className="form-control"
                                        />
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                <ul className="options custom-scrollbar">
                                    {hccData.map((option, index) => (
                                        <li key={index} onClick={() => loadHCCDataSelected(option)}>
                                            {option}
                                        </li>
                                    ))}
                                </ul>
                                {errors?.HCC_Code?.type === "required" && <label className="form-input-error">This field is required</label>}
                            </div>
                            <div className="form-group mb20">
                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2">RISK CODE DESCRIPTION</label>
                                <Controller
                                    name="HCC_Description"
                                    value={inputValueHCCDescription}
                                    control={control}
                                    render={({ field }) => (
                                        <input type="text" {...field} className="form-control" />
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.HCC_Description?.type === "required" && <label className="form-input-error">This field is required</label>}
                            </div>
                        </>
                    )}
                    {codeListType === "NDC" || editFormData?.ndc_code ? (
                        <>
                            <div className="form-group mb20 custom-autocomplete">
                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2">SOURCE CODE</label>
                                <Controller
                                    name="ndc_code"
                                    value={ndcValue}
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            type="text"
                                            onChange={(event) => loadNDCData(event?.target?.value)}
                                            placeholder="Type to search..."
                                            autoComplete="off"
                                            className="form-control"
                                        />
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                <ul className="options custom-scrollbar">
                                    {ndcCodeData.map((option, index) => (
                                        <li key={index} onClick={() => loadNDCDataSelected(option)}>
                                            {option}
                                        </li>
                                    ))}
                                </ul>
                                {errors?.ndc_code?.type === "required" && <label className="form-input-error">This field is required</label>}
                            </div>
                        </>
                    ) : codeListType === "CPT_CODE" || editFormData?.cpt_code ? (
                        <>
                            <div className="form-group mb20">
                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2">SOURCE CODE</label>
                                <Controller
                                    name="cpt_code"
                                    value={cptValue}
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            type="text"
                                            onChange={(event) => loadCPTData(event?.target?.value)}
                                            placeholder="Type to search..."
                                            autoComplete="off"
                                            className="form-control"
                                        />
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                <ul className="options custom-scrollbar">
                                    {cptCodeData.map((option, index) => (
                                        <li key={index} onClick={() => loadCPTDataSelected(option)}>
                                            {option}
                                        </li>
                                    ))}
                                </ul>
                                {errors?.cpt_code?.type === "required" && <label className="form-input-error">This field is required</label>}
                            </div>
                            <div className="form-group mb20">
                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2">SOURCE CODE DESCRIPTION</label>
                                <Controller
                                    name="cpt_description"
                                    value={inputValueCPTDescription}
                                    control={control}
                                    render={({ field }) => (
                                        <input type="text" {...field} className="form-control" />
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.cpt_description?.type === "required" && <label className="form-input-error">This field is required</label>}
                            </div>
                        </>
                    ) : codeListType === "DX_CODE" || editFormData?.DX_code ? (
                        <>
                            <div className="form-group mb20 custom-autocomplete">
                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2">SOURCE CODE</label>
                                <Controller
                                    name="DX_code"
                                    value={dxValue}
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            type="text"
                                            onChange={(event) => loadDXData(event?.target?.value)}
                                            placeholder="Type to search..."
                                            autoComplete="off"
                                            className="form-control"
                                        />
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                <ul className="options custom-scrollbar">
                                    {dxCodeData.map((option, index) => (
                                        <li key={index} onClick={() => loadDXDataSelected(option)}>
                                            {option}
                                        </li>
                                    ))}
                                </ul>
                                {errors?.DX_code?.type === "required" && <label className="form-input-error">This field is required</label>}
                            </div>
                            <div className="form-group mb20">
                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2">SOURCE DESCRIPTION</label>
                                <Controller
                                    name="DX_description"
                                    value={inputValueDXDescription}
                                    control={control}
                                    render={({ field }) => (
                                        <input type="text" {...field} className="form-control" />
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.DX_description?.type === "required" && <label className="form-input-error">This field is required</label>}
                            </div>
                        </>
                    ) :
                        <></>
                    }
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">INSUFFICIENT DOCUMENTATION</label>
                        <div className="flex_item_cs gap8">
                            <Controller
                                name="meat_flag"
                                value={editFormData?.meat_flag == 0 ? "No" : "Yes"}
                                control={control}
                                render={({ field }) => (
                                    <select
                                        {...field}
                                        className="form-control"
                                        onClick={(e) => setValue("meat_flag", e.target.value)}
                                    >
                                        <option value={''} >
                                            Select
                                        </option>
                                        <option value={'1'} >
                                            Yes
                                        </option>
                                        <option value={'0'} >
                                            No
                                        </option>
                                    </select>
                                )}
                                rules={{
                                    required: true,
                                }}
                            />
                        </div>
                        {errors?.meat_flag?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">CONFIRMATION</label>
                        <div className="flex_item_cs gap8">
                            <Controller
                                name="HCC_Confirmed"
                                value={editFormData?.HCC_Confirmed}
                                control={control}
                                render={({ field }) => (
                                    <select
                                        {...field}
                                        className="form-control"
                                        onClick={(e) => setValue("HCC_Confirmed", e.target.value)}
                                    >
                                        <option value={''} >
                                            Select
                                        </option>
                                        <option value={'confirmed'} >
                                            Confirmed
                                        </option>
                                        <option value={'not confirmed'} >
                                            Not Confirmed
                                        </option>
                                        <option value={'no longer applicable'} >
                                            No Longer Applicable
                                        </option>
                                        <option value={'acute'} >
                                            Acute
                                        </option>
                                    </select>
                                )}
                                rules={{
                                    required: true,
                                }}
                            />
                        </div>
                        {errors?.HCC_Confirmed?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group flex_item_cs gap10">
                        <Button type='button' className={"btn-white minw80"} onClick={() => notificationRightDrawer(false)}>CANCEL</Button>
                        <Button type='submit'
                            className={"minw80"}
                            disabled={
                                formSubmitisLoading && formSubmitisLoading == true
                                    ? true
                                    : false
                            }
                        >
                            {
                                props?.aweRightSidebarType?.status &&
                                    props?.aweRightSidebarType?.status == "1"
                                    ? "ADD"
                                    : "SAVE" // Label change edit -> save
                            }
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
};
export default connect(mapStateToProps, {
    currentAWERightSidebarType,
    currentAWERightSidebar,
    currentAWERightSidebarCloseDatatableReload,
})(AWVMemberDetails);
