import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../components/Tab/TabPanel";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { connect } from 'react-redux';
import LineChart from '../../components/GChart/LineChart';
import BarVerticalHCC from '../../components/GChart/BarVerticalHCC';
import SubHeader from '../../components/home/header/subHeader';
import { getMeInitialSelect} from '../../assets/constants/meInitialList';
import {
  CLAIM_ID,
  ENCOUNTERS_SUMMARY,
  CR_INSIGHTS,
  GAP_INSIGHTS,
  MAO_RISK_CONDITION_CHART,
  MAO_MEMBER_ID,
  MEMBER_ID,
  MEMBER_NAME,
  PLAN_ID,
  REPORTED_INSIGHTS,
  UTILIZATION,
  PREDICTION,
  WIDGET_TYPES,
  HCC,
  MAO_MEMBER_SELECT_TAB,
  MAO_Chart_Review_Member
} from "../../assets/constants/string.constants";
import GridTable from "../../components/TableGrids/GridTable.table";
import api from "../../assets/constants/Rafservice.Instance";
import { getAcaYear } from "../../assets/constants/yearList";
import {
  anchorTag,
  numberWithCommas,
  stringFold,
  tickCross,
  textToSignFormatRender
} from "../../assets/constants/customRender";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import WidgetCard from "../../components/Cards/WidgetCard";
import { revenueFormat } from "../../assets/helpers/formats.helpers";
import SingleHorizontalBar from "../../components/Charts/SingleHorizontalBar.chart";
import history from "../../history";
import CardComponent from "../../components/Cards/card";
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import GChart from '../../components/GChart/GChart';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const ServiceStatisticsCharts = (props) => {
    const classes = useStyles();

    const [value, setValue] = useState('SOCIO_ECONOMIC');
    const [hccCode, setHccCode] = useState(localStorage.getItem(HCC));
    const [groupDescriptionTableData, setGroupDescriptionTableData] = useState([]);
    const [socioEconomicVisitComparisonChartData, setSocioEconomicVisitComparisonChartData] = useState([]);
    const [socioEconomicCostComparisonChartData, setSocioEconomicCostComparisonChartData] = useState([]);
    const [socioEconomicUtilizedPopulationChartData, setSocioEconomicUtilizedPopulationChartData] = useState([]);
    const [genderVisitComparisonChartData, setGenderVisitComparisonChartData] = useState([]);
    const [genderCostComparisonChartData, setGenderCostComparisonChartData] = useState([]);
    const [genderUtilizedPopulationChartData, setGenderUtilizedPopulationChartData] = useState([]);
    const [ethnicityVisitComparisonChartData, setEthnicityVisitComparisonChartData] = useState([]);
    const [ethnicityCostComparisonChartData, setEthnicityCostComparisonChartData] = useState([]);
    const [ethnicityUtilizedPopulationChartData, setEthnicityUtilizedPopulationChartData] = useState([]);
    
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const loadSocioEconomicGraphChart = () => {
        let VisitComparisonchartDataRow = [];         
        let CostComparisonchartDataRow = [];         
        let UtilizedPopulationchartDataRow = [];         
        VisitComparisonchartDataRow.push(["Population Segment", "Visits/1000 members", { role: 'annotation' }]);
        CostComparisonchartDataRow.push(["Population Segment", "Cost/1000 members", { role: 'annotation' }]);
        UtilizedPopulationchartDataRow.push(["Population Segment", "Percentage", { role: 'annotation' }]);
        api.get(`/SDOH_Statistics/code/${hccCode}/year/${props.yearSelect}/type/SOCIO_ECONOMIC`)
        .then(res => {
            for (const dataObj of res) {
                if(dataObj.visits_per_thousand > 0){
                    VisitComparisonchartDataRow.push([dataObj.group_id, dataObj.visits_per_thousand, dataObj.visits_per_thousand]);
                }
                if(dataObj.utilization_per_thousand > 0){
                    CostComparisonchartDataRow.push([dataObj.group_id, dataObj.utilization_per_thousand, dataObj.utilization_per_thousand]);
                }
                if(dataObj.proportion > 0){
                    UtilizedPopulationchartDataRow.push([dataObj.group_id, dataObj.proportion, dataObj.proportion]);
                }
            }
            setSocioEconomicVisitComparisonChartData(VisitComparisonchartDataRow);
            setSocioEconomicCostComparisonChartData(CostComparisonchartDataRow);
            setSocioEconomicUtilizedPopulationChartData(UtilizedPopulationchartDataRow);
        })
        .catch(err => {
            // console.log(err);
        })
    }
    
    const loadGenderGraphChart = () => {
        let VisitComparisonchartDataRow = [];         
        let CostComparisonchartDataRow = [];         
        let UtilizedPopulationchartDataRow = [];         
        VisitComparisonchartDataRow.push(["Population Segment", "Visits/1000 members", { role: 'annotation' }]);
        CostComparisonchartDataRow.push(["Population Segment", "Cost/1000 members", { role: 'annotation' }]);
        UtilizedPopulationchartDataRow.push(["Population Segment", "Percentage", { role: 'annotation' }]);
        api.get(`/SDOH_Statistics/code/${hccCode}/year/${props.yearSelect}/type/GENDER`)
        .then(res => {
            for (const dataObj of res) {
                if(dataObj.visits_per_thousand > 0){
                    VisitComparisonchartDataRow.push([dataObj.group_id, dataObj.visits_per_thousand, dataObj.visits_per_thousand]);
                }
                if(dataObj.utilization_per_thousand > 0){
                    CostComparisonchartDataRow.push([dataObj.group_id, dataObj.utilization_per_thousand, dataObj.utilization_per_thousand]);
                }
                if(dataObj.proportion > 0){
                    UtilizedPopulationchartDataRow.push([dataObj.group_id, dataObj.proportion, dataObj.proportion]);
                }
            }
            setGenderVisitComparisonChartData(VisitComparisonchartDataRow);
            setGenderCostComparisonChartData(CostComparisonchartDataRow);
            setGenderUtilizedPopulationChartData(UtilizedPopulationchartDataRow);
        })
        .catch(err => {
            // console.log(err);
        })
    }
   
    const loadEthnicityGraphChart = () => {
        let VisitComparisonchartDataRow = [];         
        let CostComparisonchartDataRow = [];         
        let UtilizedPopulationchartDataRow = [];         
        VisitComparisonchartDataRow.push(["Population Segment", "Visits/1000 members", { role: 'annotation' }]);
        CostComparisonchartDataRow.push(["Population Segment", "Cost/1000 members", { role: 'annotation' }]);
        UtilizedPopulationchartDataRow.push(["Population Segment", "Percentage", { role: 'annotation' }]);
        api.get(`/SDOH_Statistics/code/${hccCode}/year/${props.yearSelect}/type/ETHNICITY`)
        .then(res => {
            for (const dataObj of res) {
                if(dataObj.visits_per_thousand > 0){
                    VisitComparisonchartDataRow.push([dataObj.group_id, dataObj.visits_per_thousand, dataObj.visits_per_thousand]);
                }
                if(dataObj.utilization_per_thousand > 0){
                    CostComparisonchartDataRow.push([dataObj.group_id, dataObj.utilization_per_thousand, dataObj.utilization_per_thousand]);
                }
                if(dataObj.proportion > 0){
                    UtilizedPopulationchartDataRow.push([dataObj.group_id, dataObj.proportion, dataObj.proportion]);
                }
            }
            setEthnicityVisitComparisonChartData(VisitComparisonchartDataRow);
            setEthnicityCostComparisonChartData(CostComparisonchartDataRow);
            setEthnicityUtilizedPopulationChartData(UtilizedPopulationchartDataRow);
        })
        .catch(err => {
            // console.log(err);
        })
    }
  
    const loadGroupDescription = () =>{
        api.get('/groupDescription')
        .then(res=>{
            setGroupDescriptionTableData(res);
        })
        .catch(err=>{
            // console.log(err);
        })
    }

    const  groupDescriptionColumns =[
        {
            name: "hccCode",
            label: "Group Id",
        },
        {
            name: "description",
            label: "Description",     
        },        
    ]

    
    useEffect(() => {
        loadSocioEconomicGraphChart()
        loadGenderGraphChart()
        loadEthnicityGraphChart()
        loadGroupDescription()
    }, [        
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect()
    ]);

    return (
        <div className="container level-summary">
            <div className="row mb-3">
                <div className="col-lg-12 pr-0 pl-0">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Comparison Charts'} selectType={'utilization'}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.root}>
                <AppBar position="static" color="default">
                    <Tabs
                    variant="fullWidth"
                    textColor="primary"
                    indicatorColor="primary"
                    value={value}
                    onChange={handleChange}
                    centered
                    >
                        <Tab
                            value={'SOCIO_ECONOMIC'}
                            label="Socio-Economic"
                            {...a11yProps('SOCIO_ECONOMIC')}
                        />
                        <Tab
                            value={'GENDER'}
                            label="Gender"
                            {...a11yProps('GENDER')}
                        />
                        <Tab
                            value={'ETHNICITY'}
                            label="Ethnicity"
                            {...a11yProps('ETHNICITY')}
                        />
                    </Tabs>
                </AppBar>
                <br />
                <TabPanel value={value} index={'SOCIO_ECONOMIC'}>
                    <>
                        <div className="mb-3 tab-content-title-div">              
                            <h2>{hccCode} - WELLNESS VISITS FOR YEAR {props.yearSelect}</h2>
                        </div>
                        <div className="row mb-5">
                            <div className="col-lg-6">
                                <CardComponent
                                    heading={`VISITS COMPARISON`}
                                    body={                          
                                        <div style={{height:'1075px'}}>
                                            <GChart data={socioEconomicVisitComparisonChartData} chartType="BarChart" height="1065px" width="100%" hAxisTitle={"Visits/1000 members"} yAxistitle={"Population Segment"}></GChart>
                                        </div> 
                                }></CardComponent>
                            </div>      
                            <div className="col-lg-6">
                                <CardComponent
                                    heading={``}
                                    body={                         
                                        <GridTable
                                            data={groupDescriptionTableData}
                                            columns={groupDescriptionColumns}
                                    ></GridTable>
                                }></CardComponent>
                            </div>      
                        </div>   
                        <div className="row mb-5">
                            <div className="col-lg-12">
                                <CardComponent
                                    heading={`COST COMPARISON`}
                                    body={                          
                                        <div style={{height:'410px'}}>
                                            <GChart data={socioEconomicCostComparisonChartData} chartType="BarChart" height="400px" width="100%" hAxisTitle={"Cost/1000 members"} yAxistitle={"Population Segment"}></GChart>
                                        </div> 
                                }></CardComponent>
                            </div>         
                        </div>         
                        <div className="row mb-5">
                            <div className="col-lg-12">
                                <CardComponent
                                    heading={`UTILIZED POPULATION PERCENTAGE`}
                                    body={                          
                                        <div style={{height:'410px'}}>
                                            <GChart data={socioEconomicUtilizedPopulationChartData} chartType="BarChart" height="400px" width="100%" hAxisTitle={"Percentage"} yAxistitle={"Population Segment"}></GChart>
                                        </div> 
                                }></CardComponent>
                            </div>         
                        </div>         
                        <div className='col-lg-12 pr-0 pl-0 mt-5'></div>
                    </>
                </TabPanel>
                <TabPanel value={value} index={'GENDER'}>
                    <>
                        <div className="mb-3 tab-content-title-div">              
                            <h2>{hccCode} - WELLNESS VISITS FOR YEAR {props.yearSelect}</h2>
                        </div>
                        <div className="row mb-5">
                            <div className="col-lg-12">
                                <CardComponent
                                    heading={`VISITS COMPARISON`}
                                    body={                          
                                        <div style={{height:'410px'}}>
                                            <GChart data={genderVisitComparisonChartData} chartType="ColumnChart" height="400px" width="100%" hAxisTitle={"Population Segment"} yAxistitle={"Visits/1000 members"}></GChart>
                                        </div>
                                }></CardComponent>
                            </div>         
                        </div>         
                        <div className="row mb-5">
                            <div className="col-lg-12">
                                <CardComponent
                                    heading={`COST COMPARISON`}
                                    body={                          
                                        <div style={{height:'410px'}}>
                                            <GChart data={genderCostComparisonChartData} chartType="ColumnChart" height="400px" width="100%" hAxisTitle={"Population Segment"} yAxistitle={"Cost/1000 members"}></GChart>
                                        </div> 
                                }></CardComponent>
                            </div>         
                        </div>         
                        <div className="row mb-5">
                            <div className="col-lg-12">
                                <CardComponent
                                    heading={`UTILIZED POPULATION PERCENTAGE`}
                                    body={                          
                                        <div style={{height:'410px'}}>
                                            <GChart data={genderUtilizedPopulationChartData} chartType="ColumnChart" height="400px" width="100%" hAxisTitle={"Population Segment"} yAxistitle={"Percentage"}></GChart>
                                        </div> 
                                }></CardComponent>
                            </div>         
                        </div>         
                        <div className='col-lg-12 pr-0 pl-0 mt-5'></div>
                    </>
                </TabPanel>
                <TabPanel value={value} index={'ETHNICITY'}>
                    <>
                        <div className="mb-3 tab-content-title-div">              
                            <h2>{hccCode} - WELLNESS VISITS FOR YEAR {props.yearSelect}</h2>
                        </div>
                        <div className="row mb-5">
                            <div className="col-lg-12">
                                <CardComponent
                                    heading={`VISITS COMPARISON`}
                                    body={                          
                                        <div style={{height:'410px'}}>
                                            <GChart data={ethnicityVisitComparisonChartData} chartType="ColumnChart" height="400px" width="100%" hAxisTitle={"Population Segment"} yAxistitle={"Visits/1000 members"}></GChart>
                                        </div>
                                }></CardComponent>
                            </div>         
                        </div>         
                        <div className="row mb-5">
                            <div className="col-lg-12">
                                <CardComponent
                                    heading={`COST COMPARISON`}
                                    body={                          
                                        <div style={{height:'410px'}}>
                                            <GChart data={ethnicityCostComparisonChartData} chartType="ColumnChart" height="400px" width="100%" hAxisTitle={"Population Segment"} yAxistitle={"Cost/1000 members"}></GChart>
                                        </div> 
                                }></CardComponent>
                            </div>         
                        </div>         
                        <div className="row mb-5">
                            <div className="col-lg-12">
                                <CardComponent
                                    heading={`UTILIZED POPULATION PERCENTAGE`}
                                    body={                          
                                        <div style={{height:'410px'}}>
                                            <GChart data={ethnicityUtilizedPopulationChartData} chartType="ColumnChart" height="400px" width="100%" hAxisTitle={"Population Segment"} yAxistitle={"Percentage"}></GChart>
                                        </div> 
                                }></CardComponent>
                            </div>         
                        </div>         
                        <div className='col-lg-12 pr-0 pl-0 mt-5'></div>
                    </>   
                </TabPanel>
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                    <br/>

                    <br/>
                    <br/> 
            </div>
        </div>
    );
};
const mapStateToProps = (state) => {
  return {
      isSignedIn: state.auth.isSignedIn,
      moduleSelect: state.moduleFilter.moduleSelect,
      viewBySelect: state.moduleFilter.viewBySelect,
      modelSelect: state.moduleFilter.modelSelect,
      metalTierSelect: state.moduleFilter.metalTierSelect,
      planSelect: state.moduleFilter.planSelect,
      yearSelect: state.moduleFilter.yearSelect,
      meInitialSelect: state.moduleFilter.meInitialSelect,
  };
}
export default connect(mapStateToProps)(ServiceStatisticsCharts);
