import React, { useState, useEffect } from 'react';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import QueryLetter from '../../assets/constants/QueryLetter.Instance';
import Button from "../layout/aweLayout/Button";
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '../CustomButtons/IconButton'
import DownloadButton from '../CustomButtons/DownloadButton';
import DownloadPDFSquareIcon from '../icons/DownloadPDFSquareIcon'
import PlusFileIcon from '../icons/PlusFileIcon';
import EditPenIcon from '../icons/EditPenIcon';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import mainLogo from '../../assets/images/Invent_logo_White.png';
import { connect } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import Divider from '@mui/material/Divider';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import pdfIcon from '../../assets/images/pdf_icon.png';
import Moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import ProviderQueryLetter from './ProviderQueryLetter';
import AWVCoderWorkBench from '../../assets/constants/AWVCoderWorkBenchRafservice.Instance'
import axios from "axios";
import { useReactToPrint } from 'react-to-print';
import S3 from 'react-aws-s3';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import html2pdf from 'html2pdf.js';
import html2canvas from 'html2canvas';
var AWS = require("aws-sdk");

/*
const S3_BUCKET = process.env.REACT_APP_AWE_QUERY_LETTER_BUCKET;
const REGION = process.env.REACT_APP_AWE_QUERY_LETTER_REGION;
const ACCESS_KEY = localStorage.getItem("access_key");
const SECRET_ACCESS_KEY = localStorage.getItem("secret_key");
*/

const S3_BUCKET = process.env.REACT_APP_AWE_QUERY_LETTER_BUCKET;
const REGION = process.env.REACT_APP_AWE_QUERY_LETTER_REGION;
const ACCESS_KEY = localStorage.getItem("access_key");
const SECRET_ACCESS_KEY = localStorage.getItem("secret_key");


const styles = (theme) => ({
    dialogPaper: {
        maxWidth: '80%',
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);


const QueryLetterEditCoderWorkBench = (props) => {
    const fileInput = React.useRef();
    const { register, formState: { errors }, trigger, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [isDeliverType, setIsDeliverType] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
    const [queryLetterHistory, setQueryLetterHistory] = useState([]);
    const [queryLetterTemplate, setQueryLetterTemplate] = useState('');
    const [queryLetterEmailEdit, setQueryLetterEmailEdit] = useState('');
    const [memberDetails, setMemberDetails] = useState('');
    const [querryLatterGetData, setQuerryLatterGetData] = useState([]);
    const [pdfData, setPdfData] = useState(null);
    const [fileName, setFileName] = useState();
    const [lastAttempt, setLastAttempt] = useState('');
    const [lastStatus, setLastStatus] = useState('');
    const [attempt, setAttempt] = useState('');
    const [attempOptions, setAttempOptions] = useState('');
    const [formFileUrl, setFormFileUrl] = useState();
    const printRef = React.useRef(null);
    const componentRef = React.useRef(null);

    useEffect(() => {
        reset();
        if (props.open && props.open == true) {
            loadformHistory(props?.cinId, props?.aweID);
            loadGetQueryTemplate();
            setValue('reported_on', Moment().format('YYYY-MM-DD'));
            setValue('delivered_on', Moment().format('YYYY-MM-DD'));
            setValue('awe_id', (props?.aweID && props?.aweID != '') ? props?.aweID : '');
            setValue('cin', (props?.cinId && props?.cinId != '') ? props?.cinId : '');
            setValue('status', 'InProgress');
            setValue('deliver_type', '1');
            setIsDeliverType(1);
            setValue('form_url', '');
            setValue('file_upload', '');
            setFileName('');
            setFormFileUrl('');
            setValue('form_url', '');
            queryLatterGetDataFunction(props?.cinId, props?.aweID);
        }
    }, [props.open]);

    const queryLatterGetDataFunction = (tempCin, tempAweID = '') => {
        QueryLetter.get('/get_query_details?query_letter=&awe_id=&' + tempAweID + '&cin=' + tempCin + '&lob=' + props?.lobSelect + '&year=' + props?.yearSelect)
            .then(res => {
                setQuerryLatterGetData(res.data);
                /*
                if (res.data && res.data.length > 0 && res.data[0] && res.data[0]?.attempt) {

                    let actionData = queryLetterHistory;
                    res.data.forEach((rowData, index) => {
                        let rData= { }
                        rData.transaction_id = '';
                        rData.ql_id = rowData.ql_id;
                        rData.transaction_date = rowData.action_taken_on;
                        rData.type = 'ql_history';
                        rData.attempt = rowData.attempt;
                        rData.status = rowData.status;
                        rData.user_id = rowData.action_taken_by;
                        actionData.push(rData);
                    });
                    setQueryLetterHistory(actionData);
                }*/


                if (res.data && res.data.length > 0 && res.data[0] && res.data[0]?.attempt) {
                    setQueryLetterEmailEdit(res.data[0]);
                    setValue('attempt', res.data[0]?.attempt);
                    setValue('last_attempt', res.data[0]?.attempt);
                    setValue('ql_id', res.data[0]?.ql_id);
                    setAttempt(res.data[0]?.attempt)
                    setLastAttempt(res.data[0]?.attempt);
                    setLastStatus(res.data[0]?.status);
                    AWVCoderWorkBench.get('data/cin/' + tempCin + '/' + tempAweID + '/' + props?.yearSelect)
                        .then(res => {
                            setMemberDetails(res.data);
                            localStorage.setItem("member_name", res.data.member_f_name + ' ' + res.data.member_l_name);
                            localStorage.setItem("member_dob", res.data.member_dob);
                        })
                        .catch(err => {
                            console.log(err);
                        })


                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const config = {
        bucketName: process.env.REACT_APP_AWE_QUERY_LETTER_BUCKET,
        dirName: process.env.REACT_APP_AWE_QUERY_LETTER_DIR_NAME,
        region: process.env.REACT_APP_AWE_QUERY_LETTER_REGION,
        accessKeyId: localStorage.getItem("access_key"),
        secretAccessKey: localStorage.getItem("secret_key"),
        s3Url: 'https://bucket-lacare-uat.s3.us-west-2.amazonaws.com/query_letter/',
    }
    const ReactS3Client = new S3(config);

    const handleFileOpenClick = () => {
        fileInput.current.click();
    }

    const handleAttempChanges = (event) => {
        console.log(event.target.value);

        querryLatterGetData && querryLatterGetData.forEach(element => {
            if (event.target.value == element?.attempt) {
                setQueryLetterEmailEdit(element);
                setValue('attempt', element?.attempt);
            }
        });
    }

    const handleDeliverType = (deliverType) => {
        setIsDeliverType(deliverType);
        setValue('deliver_type', deliverType)
    }

    const generateSignedURL = (url) => {
        var credentials = {
            accessKeyId: ACCESS_KEY,
            secretAccessKey: SECRET_ACCESS_KEY,
        };
        AWS.config.update({
            credentials: credentials,
            region: REGION,
            signatureVersion: 'v4',
        });
        var s3 = new AWS.S3({
            signatureVersion: 'v4',
        });
        var filePath = url.split('/').pop();
        /*  adding condition to upload form in different folder as per lob select */

        var Key = process.env.REACT_APP_AWE_QUERY_LETTER_DIR_NAME + "/" + filePath;

        s3.setupRequestListeners = (request) => {
            request.on('build', (req) => {
                req.httpRequest.headers['x-amz-date'] = process.env.REACT_APP_KeyID;
            });
        };
        var presignedGETURL = s3.getSignedUrl('getObject', {
            Bucket: process.env.REACT_APP_AWE_QUERY_LETTER_BUCKET,
            Key: Key,
            Expires: 120
        });
        return presignedGETURL;
    };

    

    const handleFileUpload = (event) => {
        // console.log(event.target);
        // console.log(fileInput?.current?.files[0]);
        setValue('file_upload', event.target.value);
        setFileName(fileInput?.current?.files[0]?.name);
        handleManualValidation()

        var file_name = 'https://bgguzqqgp5.execute-api.us-east-1.amazonaws.com/v1/bucket-member-details/AWE_2022_v3.pdf'; //+ props?.editFormData?.awv_id +'.pdf';
        var api_url = 'https://rj3vuo7d30.execute-api.us-east-1.amazonaws.com/v1/upload-form';

        if (fileInput?.current && fileInput?.current?.files && fileInput?.current?.files[0]) {

            const headers = {
                'Content-Type': 'application/pdf'
            }

            //const data = new FormData(fileInput);

            ///////////////////////////////////////////////////////////////////////////////////

            //var path = "bucket-awv-form-upload";
            var path = process.env.REACT_APP_AWE_QUERY_LETTER_BUCKET + "/" + process.env.REACT_APP_AWE_QUERY_LETTER_DIR_NAME;


            AWS.config.update({ region: REGION, credentials: new AWS.Credentials(ACCESS_KEY, SECRET_ACCESS_KEY) });
            const temp = fileInput?.current?.files[0]?.name;
            const myarr = temp.split(".");


            var file = fileInput?.current?.files[0];
            var fileNameArray = file.name.split('.');
            var fileExtension = fileNameArray.pop();
            var exa_fileName = fileInput?.current?.files[0]?.name.replaceAll(
                ' ',
                '_'
            );
            exa_fileName = exa_fileName.slice(0, exa_fileName.lastIndexOf('.'));
            const regex = /^[a-zA-Z0-9_.-]+$/;
            if (!regex.test(exa_fileName) || exa_fileName.includes('.')) {
                setFileName('');
                setFormFileUrl('');
                setValue('file_upload', '');
                return toast.error(
                    'File name with special characters is not allowed'
                );
            }


            var file = fileInput?.current?.files[0]; //file?.name
            //var fileKey = myarr[0] + "_" + Date.now() + ".pdf"; // myarr[0] take the exact file name and now doc will save exact file name with timestamp.
            var fileKey = exa_fileName + "_" + Date.now() + "." + fileExtension; // myarr[0] take the exact file name and now doc will save exact file name with timestamp.

            let upload_params = { Bucket: path, Key: fileKey, Body: file, ServerSideEncryption: 'aws:kms', SSEKMSKeyId: process.env.REACT_APP_KeyID, ContentDisposition: "inline"};
            let upload = new AWS.S3.ManagedUpload({ params: upload_params });
            upload.promise(function (err, data) {
                //window.alert("FILE UPLOADED SUCCESSFULLY data ",data.Location," ERRO  ",err );
                setFormFileUrl(data.Location);
                setValue('form_url', data.Location);
                var formUrl = data.Location;
                var s3 = new AWS.S3({ signatureVersion: "v4" });
                //let Key = fileKey;
                let Key = process.env.REACT_APP_AWE_QUERY_LETTER_DIR_NAME + "/" + fileKey;

                s3.setupRequestListeners = (request) => {
                    request.on('build', (req) => {
                        req.httpRequest.headers['x-amz-date'] = process.env.REACT_APP_KeyID;
                    });
                }
                var presignedGETURL = s3.getSignedUrl('getObject', {
                    Bucket: process.env.REACT_APP_AWE_QUERY_LETTER_BUCKET, // S3_BUCKET, //bucket-demo-data-repository
                    Key: Key,
                    Expires: 120
                });
                setFormFileUrl(presignedGETURL);
            });
        }

    }

    const loadGetQueryTemplate = () => {
        setIsLoading(true);
        QueryLetter.get('/get_query_letter_template') //'&provider_group=' + props?.providerGroupSelect
            .then(res => {
                console.log(res.data);
                setQueryLetterTemplate(res.data);
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    }

    const loadformHistory = (tempCin, tempAweID = '') => {
        setIsLoading(true);
        QueryLetter.get('/get_history?awe_id=' + tempAweID + '&cin=' + tempCin + '&ql_id=&lob=' + props?.lobSelect + '&year=' + props?.yearSelect) //'&provider_group=' + props?.providerGroupSelect
            .then(res => {
                console.log(res.data);
                if (res.data) {
                    res.data.forEach((ele, index) => {
                        var url = generateSignedURL(ele.attachment_file_path);
                        ele['url'] = url;
                    });
                }
                setQueryLetterHistory(res.data);
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    }


    const onSubmit = (data) => {
        if (lastAttempt != data.attempt) {
            toast.error('Please select latest attempt.');
            return true;
        }
        setFormSubmitisLoading(true);
        let controlUser = JSON.parse(localStorage.getItem('controlUser'));
        data.form_url = getValues('form_url');
        data.created_by = controlUser?.user_id;
        data.created_on = Moment().format('YYYY-MM-DD HH:mm:ss');
        data.cw_id = data?.awe_id;
        data.cin = getValues('cin').toString();
        data.lob = props?.lobSelect.toString();
        data.ql_id = getValues('ql_id').toString();
        data.updated_by = controlUser?.email;
        data.temp_id = "1";
        data.year = props?.yearSelect.toString();
        data.attachment_file_path = ""
        data.attempt = data.attempt.toString();

        console.log({ data });
        /*
        if(getValues('last_attempt').toString() != data.attempt){
            toast.error('Please select the latest query attempt.');
            return;
        }
        */

        QueryLetter.post('/save_history', data)
            .then(res => {
                toast.success('Query letter status has been updated successfully.');
                props.handleClose();
                setFormSubmitisLoading(false);
            })
            .catch(err => {
                setFormSubmitisLoading(false);
                toast.error(err?.response?.data?.message);
            })
    }

    const getOrdinalSuffix = (number) => {
        const suffixes = ["th", "st", "nd", "rd"];
        const lastDigit = number % 10;
        const suffix = lastDigit <= 3 ? suffixes[lastDigit] : suffixes[0];
        return number + suffix;
    }

    useEffect(() => {
        setAttempOptions('');
        var attempOptiosTemp = [];
        for (let i = 0; i < (attempt); i++) {
            attempOptiosTemp.push(
                <option value={(i + 1)} >
                    {getOrdinalSuffix((i + 1))} Attempt
                </option>
            );
        }
        setAttempOptions(attempOptiosTemp)
    }, [attempt]);

    const convertToPDF = async () => {
        //const filename = 'query_letter_' + Moment().unix() + '.pdf';
        let provider_id= '';
        if(queryLetterEmailEdit.action_taken_to != null || queryLetterEmailEdit.action_taken_to != '')
          provider_id = '_'+queryLetterEmailEdit.action_taken_to+'_';
          const filename = 'QL_' + getValues('awe_id')+provider_id+'_attempt'+getValues('attempt')+ '.pdf';
        const element = document.querySelector('.content-pdf');
        const opt = {
            margin: [24, 10, 23, 10],
            filename: filename,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
            pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
        };

        // html2pdf().set(opt).from(element).toPdf().get('pdf').then(function (pdf) {
        //     const totalPages = pdf.internal.getNumberOfPages();

        //     for (let i = 1; i <= totalPages; i++) {
        //         pdf.setPage(i);

        //         // Header
        //         pdf.setFontSize(10);
        //         pdf.text('Header Text - Page ' + i, pdf.internal.pageSize.width / 2, 10, { align: 'center' });

        //         // Footer
        //         pdf.setFontSize(10);
        //         pdf.text('Footer Text - Page ' + i, pdf.internal.pageSize.width / 2, pdf.internal.pageSize.height - 10, { align: 'center' });

        //     }
        // }).save();

        // Convert header and footer to canvas
        const headerElement = document.querySelector('.header-pdf');
        const footerElement = document.querySelector('.footer-pdf');
        const headerCanvas = await html2canvas(headerElement);
        const footerCanvas = await html2canvas(footerElement);

        // Get header and footer as images
        const headerImgData = headerCanvas.toDataURL('image/png');
        const footerImgData = footerCanvas.toDataURL('image/png');

        html2pdf().set(opt).from(element).toPdf().get('pdf').then((pdf) => {
            const totalPages = pdf.internal.getNumberOfPages();
            const pageWidth = pdf.internal.pageSize.getWidth() - 20;
            const pageHeight = pdf.internal.pageSize.getHeight();
            const headerHeight = headerElement.offsetHeight / 2; // Adjust if needed
            const footerHeight = footerElement.offsetHeight / 2; // Adjust if needed

            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i);

                // Add header
                pdf.addImage(headerImgData, 'PNG', 12, 3, pageWidth, 19);

                // Add footer
                pdf.addImage(footerImgData, 'PNG', 12, pageHeight - footerHeight + 10, pageWidth, 17);
            }

            pdf.save(filename);

        });

    }

    const handlePrintDownload = useReactToPrint({
        content: () => {
            return printRef.current;
        },
        onBeforeGetContent: () => {
            return printRef.current;
        },
        onAfterPrint: () => {
            return printRef.current;
        },
        pageStyle: `
            @page {
                size: A4;
                margin: 2cm !important;
            }
            @media print {
                header {
                }
                footer {
                }
            }
            `
    });

    const getFileName = (url) => {
        // Split the URL by '/'
        const parts = url.split('/');
        // Get the last part (which should be the file name)
        const fileName = parts[parts.length - 1];
        return fileName;
    }

    const getFileNameToDownloadFile = (FileName) => {
        var s3View = new AWS.S3({ signatureVersion: "v4" });
        let TempKey = process.env.REACT_APP_AWE_QUERY_LETTER_DIR_NAME + "/" + FileName;
        var presignedViewURL = s3View.getSignedUrl('getObject', {
            Bucket: process.env.REACT_APP_AWE_QUERY_LETTER_BUCKET, // S3_BUCKET, //bucket-demo-data-repository
            Key: TempKey,
            Expires: 60
        });
        return presignedViewURL;
    }

    const handleManualValidation = async () => {
        try {
            // Trigger manual revalidation for all fields
            await trigger();
        } catch (error) {
            //console.error('Manual validation error:', error);
        }
    };


    return (
        <>
            <div style={{ display: "inline-block" }}>
                {props.queryLetterList && props.queryLetterList == true ?
                    <></>
                    :
                    <IconButton className='mr-2' onClick={() => props.handleClickOpen()}>
                        <EditPenIcon className="w16" />
                    </IconButton>
                }
            </div>
            <MuiDialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth={true}
                maxWidth={'xl'}
                className="awv_model_layout"
                BackdropProps={{
                    style: { backgroundColor: "#787878" } // Adjust the background color here
                }}
            >
                <MuiDialogContent className='custom-scrollbar' style={{ backgroundColor: "#787878" }}>
                    <div className='row' >
                        <div className='col-md-9 text-right p-0' style={{ marginTop: "-18px" }}>
                            <Tooltip title={'Download'} arrow placement="top">
                                <IconButton className={"btn-icon"} type="button" buttonType={"default"} onClick={() => convertToPDF()}>
                                    <DownloadPDFSquareIcon className="w24" />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                    <div className='row' >
                        <div className='col-lg-12'>
                            <div className='row justify-content-md-center col-md-10'>
                                <div className='col-md-9 custom-scrollbar' style={{ padding: "0 0px", margin: "0 20px 10px", overflowY: "scroll", height: "calc(90vh - 100px)" }}>
                                    <div className='row justify-content-end' style={{ display: "none" }}>
                                        {/* <div className='mr-2'>
                                        <Tooltip title={'Print'} arrow placement="top">
                                            <IconButton className={"btn-icon"} buttonType={"default"}>
                                                <DownloadButton className="w24" />
                                            </IconButton>
                                        </Tooltip>
                                    </div> */}
                                        <div style={{ marginTop: "2rem" }}>
                                            {/*
                                            <Tooltip title="Close">
                                                <IconButton className={"btn-icon"} style={{ borderRadius: "50%", height: "30px", width: "30px", padding: "2px" }} type="button" buttonType={"default"} onClick={props.handleClose}>
                                                    <CloseIcon className="w34" />
                                                </IconButton>
                                            </Tooltip>
                                             */}
                                        </div>
                                    </div>
                                    <div ref={printRef} id="pdf-content">
                                        <ProviderQueryLetter queryLetterEmailEdit={queryLetterEmailEdit} memberDetails={memberDetails} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-2'>
                                <div className="diagnosis_code_sidebar offcanvas offcanvas-end show">
                                    <div className="offcanvas-header flex_item_cb">
                                        <h5 id="offcanvasRightLabel" className='fs15'>
                                            Action
                                        </h5>
                                        <Tooltip title="Close">
                                            <IconButton type="button" buttonType={"default"} onClick={props.handleClose}>
                                                <CloseIcon className="w24" />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                    <div className="offcanvas-body pt-1 custom-scrollbar overflow-auto">
                                        <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                                            <div className="form-group mb20">
                                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Attempt </label>
                                                <Controller
                                                    name="attempt"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            className="form-control"
                                                            onChange={handleAttempChanges}
                                                        >
                                                            <option value={''} >
                                                                Select Attempt
                                                            </option>
                                                            {attempOptions}
                                                        </select>
                                                    )}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                />
                                                {errors?.attempt?.type === "required" && <label className="form-input-error">This field is required</label>}
                                            </div>
                                            {/*
                                            <div className="form-group mb20">
                                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Generated By</label>
                                                <Controller
                                                    name="generated_by"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input
                                                            {...field}
                                                            type="text"
                                                            autoComplete="off"
                                                            className="form-control"
                                                        />
                                                    )}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                />
                                                {errors?.generated_by?.type === "required" && <label className="form-input-error">This field is required</label>}
                                            </div>*/}
                                            <div className="form-group mb20">
                                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Delivered On</label>
                                                <Controller
                                                    name="delivered_on"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input
                                                            {...field}
                                                            type="date"
                                                            autoComplete="off"
                                                            className="form-control"
                                                        />
                                                    )}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                />
                                                {errors?.delivered_on?.type === "required" && <label className="form-input-error">This field is required</label>}
                                            </div>
                                            <div className="form-group mb20">
                                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Reported On</label>
                                                <Controller
                                                    name="reported_on"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <input
                                                            {...field}
                                                            type="date"
                                                            autoComplete="off"
                                                            className="form-control"
                                                        />
                                                    )}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                />
                                                {errors?.reported_on?.type === "required" && <label className="form-input-error">This field is required</label>}
                                            </div>
                                            <div className="form-group mb20">
                                                {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Reported On</label> */}
                                                <Controller
                                                    name="deliver_type"
                                                    defaultValue={isDeliverType}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <div>
                                                            <div className='row'>
                                                                <div className='col-md-2 p-0 m-0'>
                                                                    <input
                                                                        type="radio"
                                                                        value="1"
                                                                        {...field}
                                                                        id="mark-as-deliver"
                                                                        onChange={() => handleDeliverType('1')}
                                                                        defaultChecked={true}
                                                                    />
                                                                </div>
                                                                <div className='col-md-10 p-0 m-0'>
                                                                    <label htmlFor="mark-as-deliver">Mark as Deliver</label>
                                                                </div>
                                                            </div>
                                                            <div className='row'>
                                                                <div className='col-md-2 p-0 m-0'>
                                                                    <input
                                                                        type="radio"
                                                                        value="2"
                                                                        {...field}
                                                                        id="response-from-provider"
                                                                        onChange={() => handleDeliverType('2')}
                                                                    />
                                                                </div>
                                                                <div className='col-md-10 p-0 m-0'>
                                                                    <label htmlFor="response-from-provider">Response from Provider</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                />
                                                {errors?.deliver_type?.type === "required" && <label className="form-input-error">This field is required</label>}
                                            </div>
                                            {isDeliverType && isDeliverType == '2' ?
                                                <>
                                                    <div className="form-group mb20">
                                                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Upload File</label>
                                                        <div className="flex_item_cs gap8">
                                                            <Button type='button' className={"minw120 px-1"} onClick={handleFileOpenClick}> CHOOSE FILE </Button>
                                                            <Controller
                                                                name="file_upload"
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <input type="file"  {...field} style={{ display: "none" }} accept=".pdf" className="form-control" ref={fileInput} onChange={handleFileUpload} />
                                                                )}
                                                                rules={{
                                                                    required: true,
                                                                }}
                                                            />
                                                        </div>
                                                        {errors?.file_upload?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                    </div>
                                                    {/*
                                                    {fileName && fileName != '' ?
                                                        <div className="form-group align-items-center">
                                                            <InputLabel id="demo-simple-select-outlined-label"><b>File:</b></InputLabel>
                                                            <div className='mt-3'>
                                                                <img src={pdfIcon} alt="" className="" style={{ width: '20px', height: '20px', marginLeft: '-7px' }} />
                                                                <a href={formFileUrl} target="_blank">{fileName}</a>
                                                            </div>
                                                        </div>
                                                        :
                                                        <></>
                                                    }*/}

                                                    {fileName && fileName != '' ?
                                                        <div className="form-group align-items-center">
                                                            <InputLabel id="demo-simple-select-outlined-label"><b>File:</b></InputLabel>
                                                            <div className='mt-3'>
                                                                {formFileUrl && formFileUrl.includes(".pdf") ?
                                                                    <a href={formFileUrl} target="_blank" className="flex_item_cs gap8 text-primary fs15 fw600">
                                                                        <PlusFileIcon className="w24 h24" />
                                                                        <span>{fileName}</span>
                                                                    </a>
                                                                    :
                                                                    <a href={formFileUrl} target="_blank" download className="flex_item_cs gap8 text-primary fs15 fw600">
                                                                        <PlusFileIcon className="w24 h24" />
                                                                        <span>{fileName}</span>
                                                                    </a>
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        <></>
                                                    }
                                                    

                                                </>
                                                :
                                                <></>
                                            }
                                            <div className="form-group mb20">
                                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Status </label>
                                                <Controller
                                                    name="status"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <select
                                                            {...field}
                                                            className="form-control"
                                                            style={{ fontSize: '14px' }}
                                                        >
                                                            <option value={''} >
                                                                Select
                                                            </option>
                                                            <option value={'Closed'} >
                                                                Closed
                                                            </option>
                                                            <option value={'NotApplicable'} >
                                                                Not Applicable
                                                            </option>
                                                            <option value={'InProgress'} >
                                                                In Progress
                                                            </option>
                                                        </select>
                                                    )}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                />
                                                {errors?.status?.type === "required" && <label className="form-input-error">This field is required</label>}
                                            </div>
                                            <div className="form-group mb20">
                                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Remark</label>
                                                <Controller
                                                    name="remark"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <textarea rows={3} {...field} className="form-control" />
                                                    )}
                                                    rules={{
                                                        required: true,
                                                    }}
                                                />
                                                {errors?.remark?.type === "required" && <label className="form-input-error">This field is required</label>}
                                            </div>
                                            <div className="form-group flex_item_cs gap10">
                                                <Button type='button' className={"btn-white minw80"} onClick={props.handleClose}>CANCEL</Button>
                                                
                                                <Button type='submit' style={{ display: lastStatus === 'Closed' ? 'none' : 'block' }}  className={"minw80"} disabled={formSubmitisLoading && formSubmitisLoading == true ? true : false}>SAVE</Button>
                                            </div>
                                        </form>
                                        {isLoading ?
                                            <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                                                <CircularProgress />
                                            </div>
                                            :
                                            <>
                                                {queryLetterHistory && queryLetterHistory.length > 0 ?
                                                    <div className="">
                                                        <h5 className="fs18 mb10">Action History</h5>
                                                        <Divider />
                                                        <div className="history_card_list">
                                                            {queryLetterHistory && queryLetterHistory.map((element, index) =>
                                                                <div key={index} className={'history_card_item status-primary'}>
                                                                    <p className="fs12 mb-1">
                                                                        <span className="fw700">Attempt : </span>
                                                                        {getOrdinalSuffix(element?.attempt)}
                                                                    </p>
                                                                    <p className="fs12 mb-1">
                                                                        <span className="fw700">Delivered on : </span>
                                                                        {Moment(element?.delivered_on).format('MMMM DD YYYY')}
                                                                    </p>
                                                                    <p className="fs12 mb-1">
                                                                        <span className="fw700">Responded on : </span>
                                                                        {Moment(element?.responded_on).format('MMMM DD YYYY')}
                                                                    </p>
                                                                    <p className="fs12 mb-1">
                                                                        <span className="fw700">Last updated by  : </span>
                                                                        {element?.updated_by}
                                                                    </p>
                                                                    
                                                                    <p className="fs12 mb-1">
                                                                        <span className="fw700">Status : </span>
                                                                        {element.status}
                                                                    </p>

                                                                    <p className="fs12 mb-1">
                                                                        <span className="fw700">Delivery Type : </span>
                                                                        {element.delivery_status && element.delivery_status == 1 ?
                                                                            <>Delivered </>
                                                                            :
                                                                            <> Response from Provider </>

                                                                        }
                                                                    </p>

                                                                    <p className="fs12 mb-1">
                                                                        <span className="fw700">Remark : </span>
                                                                        {element.transaction_remarks}
                                                                    </p>
                                                                    {element.attachment_file_path != null && element.attachment_file_path != "" && element?.url && element.delivery_status == 2 &&
                                                                        <p className="fs12 mb-1">
                                                                            <span className="fw700">Attachments: </span>
                                                                            {element.attachment_file_path && element.attachment_file_path != '' ?
                                                                                <>
                                                                                    
                                                                                    {element?.url && element?.url.includes(".pdf") ?
                                                                                        <a href={element?.url} target="_blank" className="flex_item_cs gap8 text-primary fs14 fw600">
                                                                                            <PlusFileIcon className="w24 h24" />
                                                                                            <span>{element.attachment_file_path ? element.attachment_file_path?.split('/').pop() : ''}</span>
                                                                                        </a>
                                                                                        :
                                                                                        <a href={element?.url} target="_blank" download className="flex_item_cs gap8 text-primary fs14 fw600">
                                                                                            <PlusFileIcon className="w24 h24" />
                                                                                            <span>{element.attachment_file_path ? element.attachment_file_path?.split('/').pop() : ''}</span>
                                                                                        </a>
                                                                                    }
                                                                                    
                                                                                </>
                                                                                :
                                                                                <></>

                                                                            }
                                                                        </p>
                                                                    }
                                                                    {/*
                                                                    {/*
                                                                    <p className="fs12 mb-1">
                                                                        <span className="fw700">Date : </span>
                                                                        {Moment(element.created_on).format('h:mm a, MMMM DD YYYY')}
                                                                    </p>
                                                                    */}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    :
                                                    <></>
                                                }

                                                {querryLatterGetData && querryLatterGetData.length > 0 ?
                                                    <div className="">
                                                        <h5 className="fs18 mb10">Query Letter History</h5>
                                                        <Divider />
                                                        <div className="history_card_list">
                                                            {querryLatterGetData && querryLatterGetData.map((element, index) =>
                                                                <div key={index} className={'history_card_item status-primary'}>
                                                                    <p className="fs12 mb-1">
                                                                        <span className="fw700">Attempt : </span>
                                                                        {getOrdinalSuffix(element?.attempt)}
                                                                    </p>
                                                                    <p className="fs12 mb-1">
                                                                        <span className="fw700">Generated On : </span>
                                                                        {Moment(element?.created_on).format('MMMM DD YYYY')}
                                                                    </p>
                                                                    <p className="fs12 mb-1">
                                                                        <span className="fw700">Generated By : </span>
                                                                        {element?.created_by}
                                                                    </p>
                                                                    <p className="fs12 mb-1">
                                                                        <span className="fw700">Status : </span>
                                                                        {element.status==null?'Open': element.status}
                                                                    </p>
                                                                    {/*
                                                                    {/*
                                                                    <p className="fs12 mb-1">
                                                                        <span className="fw700">Date : </span>
                                                                        {Moment(element.created_on).format('h:mm a, MMMM DD YYYY')}
                                                                    </p>
                                                                    */}
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    :
                                                    <></>
                                                }

                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </MuiDialogContent>
            </MuiDialog >
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        userDetails: state.auth.userDetails,
        yearSelect: state.moduleFilter.yearSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        lobSelect: state.moduleFilter.lobSelect,
    };
}
export default (withStyles(styles), connect(mapStateToProps))(QueryLetterEditCoderWorkBench);
