import { useState, useEffect } from 'react';
import { anchorTag, numberWithCommas, hccSeperator, textToSignFormatRender, dateFormatRender } from '../../assets/constants/customRender';
import { getAcaIssuer, getAcaIssuerList } from '../../assets/constants/issuerList';
import api from '../../assets/constants/Rafservice.Instance';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import { getMeInitialSelect} from '../../assets/constants/meInitialList';
import SingleHorizontalBar from '../../components/Charts/SingleHorizontalBar.chart';
import AcaIssuersSelect from '../../components/DropDowns/AcaIssuersSelect';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import history from '../../history';
import ServerSideGridTable from '../../components/TableGrids/ServerSideGridTable.table';
import { HCC, PROVIDER_ID,MAO_Chart_Review_Member,MAO_TYPE, RAF_RANGE, CLAIM_ID } from '../../assets/constants/string.constants';
import CardComponent from '../../components/Cards/card';
import BarVertical from '../../components/GChart/BarVertical';
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';
import GridTable from "../../components/TableGrids/GridTable.table";
import { numberDecimalFormat,revenueFormat } from "../../assets/helpers/formats.helpers";

const MaoBlendedModel = (props) => {

    const [HCCMODELData, setHCCMODELData] = useState({});
    const [PCCMODELData, setPCCMODELData] = useState({});
    const [BLENDEDMODELData, setBLENDEDMODELData] = useState({});
    const [issuer, setIssuer] = useState(getAcaIssuer());
    const [typeName, settypeName] = useState(localStorage.getItem(MAO_TYPE));
    const [MaoMemberId, setMaoMemberId] = useState(localStorage.getItem(MAO_Chart_Review_Member));
    const [rafRange] = useState(localStorage.getItem(RAF_RANGE));
    const [hcc] = useState(localStorage.getItem(HCC));

    const memberSummaryColumn = [
        {
            label: "Claim Id",
            name: "ClaimId",
            options: anchorTag
        },
        {
            name: "Type",
            label: "Claim Type"
        },
        {
            name: "Patient_type",
            label: "Patient Type"
        },
        {
            name: "Provider_num",
            label: "Provider No.",
        },
        {
            name: "otherhcc",
            label: "HCCs",
            options:hccSeperator
        },
        {
            name: "oldhcc",
            label: "HCCs From Old Model",
            options:hccSeperator
        },
        {
            name: "newhcc",
            label: "HCCs From New model",
            options:hccSeperator
        },
        {
            name: "StartDate",
            label: "Date of Service",
            options:dateFormatRender
        },
        {
            name: "Dateofsubmission",
            label: "Date of Submission",
            options:dateFormatRender
        },
        {
            name: "Eligible",
            label: "Eligible for RAF",
            options:textToSignFormatRender
        },
    ];


    const memberSummaryTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(CLAIM_ID, rowdata[cellMeta.rowIndex].ClaimId);
            history.push('/layout/medicare/claim')
        }else if ((cellMeta.colIndex === 4) && (colData.props.children != "")) {
            if(typeof(colData.props.children) == "object"){
                localStorage.setItem(HCC,(cellMeta.event.target.innerText).replace(',',''));
            }
            else{
                localStorage.setItem(HCC,rowdata[cellMeta.rowIndex].otherhcc);
            }
            history.push('/layout/medicare/reported_hcc')
        }else if ((cellMeta.colIndex === 5) && (colData.props.children != "")) {
            if(typeof(colData.props.children) == "object"){
                localStorage.setItem(HCC,(cellMeta.event.target.innerText).replace(',',''));
            }
            else{
                localStorage.setItem(HCC,rowdata[cellMeta.rowIndex].oldhcc);
            }
            history.push('/layout/medicare/reported_hcc')
        }else if ((cellMeta.colIndex === 6) && (colData.props.children != "")) {
            if(typeof(colData.props.children) == "object"){
                localStorage.setItem(HCC,(cellMeta.event.target.innerText).replace(',',''));
            }
            else{
                localStorage.setItem(HCC,rowdata[cellMeta.rowIndex].newhcc);
            }
            history.push('/layout/medicare/reported_hcc')
        }
    }

    const loadAllModelData = () =>{
        api.get('/MemberRaf1/year/'+getAcaYear()+'/id/'+MaoMemberId+'/period/'+getMeInitialSelect())
        .then(res=>{
            if(res.length > 0){
                setHCCMODELData(res[0]);
            }
        })
        .catch(err=>{
            // // console.log(err);
        })
        api.get('/MemberRaf2/year/'+getAcaYear()+'/id/'+MaoMemberId+'/period/'+getMeInitialSelect())
        .then(res=>{
            if(res.length > 0){
                setPCCMODELData(res[0]);
            }
        })
        .catch(err=>{
            // // console.log(err);
        })
        api.get('/MemberRafBlended/year/'+getAcaYear()+'/id/'+MaoMemberId+'/period/'+getMeInitialSelect())
        .then(res=>{
            if(res.length > 0){
                setBLENDEDMODELData(res[0]);
            }
        })
        .catch(err=>{
            // // console.log(err);
        })
    }

    useEffect(() => {
        loadAllModelData();
        // console.log(HCCMODELData);
        // console.log(PCCMODELData);
        // console.log(BLENDEDMODELData);
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect
    ])

    useEffect(() => {
    }, [
        issuer
    ])

    return (
        <div className="level-summary">
            <div className="row pb-2">
                <div className="col-lg-12">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Blended Model Comparison'} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="hcc-list pb-5 mb-3">
                <CardComponent
                    heading={`BLENDED MODEL COMPARISON : MEMBER ID - ${MaoMemberId} FOR - ${props.yearSelect} / ${props.meInitialSelect} : `}
                    body={
                        <div className="MuiPaper-root MUIDataTable-paper-284 pb-5 MuiPaper-elevation4 MuiPaper-rounded">
                        <div className="MUIDataTableFilterList-root-295"></div>
                        <div className="MUIDataTable-responsiveStacked-291" style={{"position": "relative", "max-height": "none", "height": "auto"}}>
                            <table role="grid" className="MuiTable-root MUIDataTable-tableRoot-286" tabindex="0">
                                <caption className="MUIDataTable-caption-293"></caption>
                                <thead className="MuiTableHead-root MUIDataTableHead-responsiveStacked-298 MUIDataTableHead-main-297">
                                    <tr className="MuiTableRow-root MUIDataTableHeadRow-root-301 MuiTableRow-head">
                                        <th className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-313 MUIDataTableHeadCell-fixedHeader-314" scope="col" data-colindex="0">
                                            <span role="button" className="MUIDataTableHeadCell-toolButton-322" data-testid="headcol-0" tabindex="0">
                                                <div className="MUIDataTableHeadCell-sortAction-318">
                                                    <div className="MUIDataTableHeadCell-data-317">SCORES </div>
                                                </div>
                                            </span>
                                        </th>
                                        <th className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-313 MUIDataTableHeadCell-fixedHeader-314" scope="col" data-colindex="1">
                                            <span role="button" className="MUIDataTableHeadCell-toolButton-322" data-testid="headcol-1" tabindex="0">
                                                <div className="MUIDataTableHeadCell-sortAction-318" title="Sort">
                                                    <div className="MUIDataTableHeadCell-data-317">HCC MODEL</div>
                                                </div>
                                            </span>
                                        </th>
                                        <th className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-313 MUIDataTableHeadCell-fixedHeader-314" scope="col" data-colindex="2">
                                            <span role="button" className="MUIDataTableHeadCell-toolButton-322" data-testid="headcol-2" tabindex="0">
                                                <div className="MUIDataTableHeadCell-sortAction-318" title="Sort">
                                                    <div className="MUIDataTableHeadCell-data-317">PCC MODEL</div>
                                                 </div>
                                            </span>
                                        </th>
                                        <th className="MuiTableCell-root MuiTableCell-head MUIDataTableHeadCell-root-313 MUIDataTableHeadCell-fixedHeader-314" scope="col" data-colindex="3">
                                            <span role="button" className="MUIDataTableHeadCell-toolButton-322" data-testid="headcol-3" tabindex="0">
                                                <div className="MUIDataTableHeadCell-sortAction-318" title="Sort">
                                                    <div className="MUIDataTableHeadCell-data-317">BLENDED MODEL</div>
                                                </div>
                                            </span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="MuiTableBody-root">
                                    <tr className="MuiTableRow-root MUIDataTableBodyRow-root-329 undefined MUIDataTableBodyRow-responsiveStacked-331 MUIDataTableBody-lastStackedCell-327 MuiTableRow-hover" data-testid="MUIDataTableBodyRow-0" id="MUIDataTableBodyRow-0">
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                Demographic Score
                                            </div>
                                        </td>
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                {HCCMODELData && HCCMODELData.demographic_score ? numberDecimalFormat(HCCMODELData.demographic_score) : 0}
                                            </div>
                                        </td>
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                {PCCMODELData && PCCMODELData.demographic_score ? numberDecimalFormat(PCCMODELData.demographic_score) : 0}
                                            </div>
                                        </td>
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                {BLENDEDMODELData && BLENDEDMODELData.demographic_score ? numberDecimalFormat(BLENDEDMODELData.demographic_score) : 0}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="MuiTableRow-root MUIDataTableBodyRow-root-329 undefined MUIDataTableBodyRow-responsiveStacked-331 MUIDataTableBody-lastStackedCell-327 MuiTableRow-hover" data-testid="MUIDataTableBodyRow-0" id="MUIDataTableBodyRow-0">
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                OREC Score
                                            </div>
                                        </td>
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                {HCCMODELData && HCCMODELData.orec_score ? numberDecimalFormat(HCCMODELData.orec_score) : 0}
                                            </div>
                                        </td>
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                {PCCMODELData && PCCMODELData.orec_score ? numberDecimalFormat(PCCMODELData.orec_score) : 0}
                                            </div>
                                        </td>
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                {BLENDEDMODELData && BLENDEDMODELData.orec_score ? numberDecimalFormat(BLENDEDMODELData.orec_score) : 0}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="MuiTableRow-root MUIDataTableBodyRow-root-329 undefined MUIDataTableBodyRow-responsiveStacked-331 MUIDataTableBody-lastStackedCell-327 MuiTableRow-hover" data-testid="MUIDataTableBodyRow-0" id="MUIDataTableBodyRow-0">
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                HCC Score
                                            </div>
                                        </td>
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                {HCCMODELData && HCCMODELData.hcc_score ? numberDecimalFormat(HCCMODELData.hcc_score) : 0}
                                            </div>
                                        </td>
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                {PCCMODELData && PCCMODELData.hcc_score ? numberDecimalFormat(PCCMODELData.hcc_score) : 0}
                                            </div>
                                        </td>
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                {BLENDEDMODELData && BLENDEDMODELData.hcc_score ? numberDecimalFormat(BLENDEDMODELData.hcc_score) : 0}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="MuiTableRow-root MUIDataTableBodyRow-root-329 undefined MUIDataTableBodyRow-responsiveStacked-331 MUIDataTableBody-lastStackedCell-327 MuiTableRow-hover" data-testid="MUIDataTableBodyRow-0" id="MUIDataTableBodyRow-0">
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                HCC Count Score
                                            </div>
                                        </td>
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                {HCCMODELData && HCCMODELData.hcc_count_score ? HCCMODELData.hcc_count_score : 0}
                                            </div>
                                        </td>
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                {PCCMODELData && PCCMODELData.hcc_count_score ? PCCMODELData.hcc_count_score : 0}
                                            </div>
                                        </td>
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                {BLENDEDMODELData && BLENDEDMODELData.hcc_count_score ? BLENDEDMODELData.hcc_count_score : 0}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="MuiTableRow-root MUIDataTableBodyRow-root-329 undefined MUIDataTableBodyRow-responsiveStacked-331 MUIDataTableBody-lastStackedCell-327 MuiTableRow-hover" data-testid="MUIDataTableBodyRow-0" id="MUIDataTableBodyRow-0">
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                Interaction Score
                                            </div>
                                        </td>
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                {HCCMODELData && HCCMODELData.interaction_score ? numberDecimalFormat(HCCMODELData.interaction_score) : 0}
                                            </div>
                                        </td>
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                {PCCMODELData && PCCMODELData.interaction_score ? numberDecimalFormat(PCCMODELData.interaction_score) : 0}
                                            </div>
                                        </td>
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                {BLENDEDMODELData && BLENDEDMODELData.interaction_score ? numberDecimalFormat(BLENDEDMODELData.interaction_score) : 0}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="MuiTableRow-root MUIDataTableBodyRow-root-329 undefined MUIDataTableBodyRow-responsiveStacked-331 MUIDataTableBody-lastStackedCell-327 MuiTableRow-hover" data-testid="MUIDataTableBodyRow-0" id="MUIDataTableBodyRow-0">
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                RAF Score
                                            </div>
                                        </td>
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                {HCCMODELData && HCCMODELData.raf_score ? numberDecimalFormat(HCCMODELData.raf_score) : 0}
                                            </div>
                                        </td>
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                {PCCMODELData && PCCMODELData.raf_score ? numberDecimalFormat(PCCMODELData.raf_score) : 0}
                                            </div>
                                        </td>
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                {BLENDEDMODELData && BLENDEDMODELData.raf_score ? numberDecimalFormat(BLENDEDMODELData.raf_score) : 0}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="MuiTableRow-root MUIDataTableBodyRow-root-329 undefined MUIDataTableBodyRow-responsiveStacked-331 MUIDataTableBody-lastStackedCell-327 MuiTableRow-hover" data-testid="MUIDataTableBodyRow-0" id="MUIDataTableBodyRow-0">
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                Revenue $
                                            </div>
                                        </td>
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                {HCCMODELData && HCCMODELData.revenue ? revenueFormat(HCCMODELData.revenue) : revenueFormat(0)}
                                            </div>
                                        </td>
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                {PCCMODELData && PCCMODELData.revenue ? revenueFormat(PCCMODELData.revenue) : revenueFormat(0)}
                                            </div>
                                        </td>
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                {BLENDEDMODELData && BLENDEDMODELData.revenue ? revenueFormat(BLENDEDMODELData.revenue) : revenueFormat(0)}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="MuiTableRow-root MUIDataTableBodyRow-root-329 undefined MUIDataTableBodyRow-responsiveStacked-331 MUIDataTableBody-lastStackedCell-327 MuiTableRow-hover" data-testid="MUIDataTableBodyRow-0" id="MUIDataTableBodyRow-0">
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                HCC List
                                            </div>
                                        </td>
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                {HCCMODELData && HCCMODELData.hccs ? HCCMODELData.hccs : 0}
                                            </div>
                                        </td>
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                {PCCMODELData && PCCMODELData.hccs ? PCCMODELData.hccs : 0}
                                            </div>
                                        </td>
                                        <td  className="MuiTableCell-root MuiTableCell-body MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedParent-340 MUIDataTableBodyCell-responsiveStackedSmallParent-344" data-colindex="0" data-testid="MuiDataTableBodyCell-0-0">
                                            <div className="MUIDataTableBodyCell-root-333 MUIDataTableBodyCell-stackedCommon-338 MUIDataTableBodyCell-responsiveStackedSmall-343">
                                                {BLENDEDMODELData && BLENDEDMODELData.hccs ? BLENDEDMODELData.hccs : 0}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="MUIDataTable-liveAnnounce-294" aria-live="polite"></div>
                    </div>
                }></CardComponent>
            </div>
            <div className="hcc-list pb-5">
                    <CardComponent  
                        heading={`CLAIM DETAILS`}
                        body={ 
                                <ServerSideGridTable
                                    url={`/rafclaimdetail/id/${MaoMemberId}/year/${getAcaYear()}/period/${getMeInitialSelect()}`}
                                    columns={memberSummaryColumn}
                                    tableClick={memberSummaryTableClick}
                                ></ServerSideGridTable>
                            }>
                    </CardComponent>
            </div>
            <br />
            <br />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(MaoBlendedModel);
