import React from 'react'

export default function PlusFillIcon({...props}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 17 17" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M8.77384 15.1667C5.63114 15.1667 4.0598 15.1667 3.08349 14.1904C2.10718 13.2141 2.10718 11.6427 2.10718 8.50004C2.10718 5.35734 2.10718 3.78599 3.08349 2.80969C4.0598 1.83337 5.63114 1.83337 8.77384 1.83337C11.9165 1.83337 13.4879 1.83337 14.4642 2.80969C15.4405 3.78599 15.4405 5.35734 15.4405 8.50004C15.4405 11.6427 15.4405 13.2141 14.4642 14.1904C13.4879 15.1667 11.9165 15.1667 8.77384 15.1667ZM8.77384 6.00004C9.04998 6.00004 9.27384 6.2239 9.27384 6.50004V8.00004H10.7738C11.05 8.00004 11.2738 8.22391 11.2738 8.50004C11.2738 8.77617 11.05 9.00004 10.7738 9.00004H9.27384V10.5C9.27384 10.7762 9.04998 11 8.77384 11C8.49771 11 8.27384 10.7762 8.27384 10.5V9.00004H6.77384C6.4977 9.00004 6.27384 8.77617 6.27384 8.50004C6.27384 8.22391 6.4977 8.00004 6.77384 8.00004H8.27384V6.50004C8.27384 6.2239 8.49771 6.00004 8.77384 6.00004Z" fill="currentcolor"/>
        </svg>
        
    )
}
