import { useState, useEffect } from 'react';
import api from '../../assets/constants/Rafservice.Instance';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import SingleHorizontalBar from '../../components/Charts/SingleHorizontalBar.chart';
import GridTable from "../../components/TableGrids/GridTable.table";
import ServerSideGridTable from '../../components/TableGrids/ServerSideGridTable.table';
import { anchorTag } from '../../assets/constants/customRender';
import { ISSUER_ID, HCC } from "../../assets/constants/string.constants";
import history from "../../history";
import CardComponent from '../../components/Cards/card';
import GChart from '../../components/GChart/GChart';
import SubHeader from '../../components/home/header/subHeader';
import { connect } from 'react-redux';

const ACAChartReviewOverallSummaryPage = (props) => {

    const [year, setYear] = useState(props.yearSelect);
    const [IssuerPopChartData, setIssuerPopChartData] = useState({});
    const [tableData, setTableData] = useState([]);

    const summaryClick = (element, el) => {
        let col = el.colIndex;
        if (col == 0 || col == 1) {
            history.push('/layout/acaCRHCCSummary');
        }
        if (col == 4) {
            history.push('/layout/acaCRMEMBERSummary');
        }

    }

    const summaryColumns = [
        {
            name: "addedHCCs",
            label: "# Added HCCs",
            options: anchorTag
        },
        {
            name: "deletedHCCs",
            label: "# Deleted HCCs",
            options: anchorTag
        },
        {
            name: "addedICDs",
            label: "# Added ICDs"
        },
        {
            name: "deletedICDs",
            label: "# Deleted ICDs"
        },
        {
            name: "memberWithChartReview",
            label: "# Chart Review",
            options: anchorTag
        },
        {
            name: "crProvidersCount",
            label: "# Chart Review"
        },
        {
            name: "totalMembers",
            label: "# No of Members"
        }
    ]

    const loadMetricTable = () => {
        api.get('/ACAcrOverallSummary/year/' + year)
            .then(res => {
                setTableData(res);
            })
            .catch(err => {
                // console.log(err);
            })
    }

    const issuersCol = [
        {
            name: "ISSUER_ID",
            label: "Issuer ID",
            options: anchorTag
        },
        {
            name: "ADDED_HCC",
            label: "# Added HCC"
        },
        {
            name: "DELETED_HCC",
            label: "# Deleted HCC"
        },
        {
            name: "ADDED_ICD",
            label: "# Added ICD"
        },
        {
            name: "DELETED_ICD",
            label: "# Deleted ICD"
        },
        {
            name: "MEMBERS_WITH_CR",
            label: "# Chart Review",
        },
        {
            name: "CR_PROVIDER_COUNT",
            label: "# Provide count"
        },
        {
            name: "TOTAL_MEMBERS",
            label: "# No of Members",
        }
    ];

    const issuerTableClick = (rowdata, colData, cellMeta) => {

        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(ISSUER_ID, rowdata[cellMeta.rowIndex].ISSUER_ID);
            localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].HCC_POST_HIERARCHY);
            history.push('/layout/acaCRMEMBERSummary')
        }
    }

    const loadPopulationByIssuer = () => {

        let issuer_id = [];
        let percentage_Contribution = [];
        let chartDataRow = [];
        chartDataRow.push(["Issue ID", "Percentage", { role: 'annotation' }]);
        api.get('/ACAcrPopulationPercent/year/' + year)
            .then(res => {
                for (const dataObj of res) {
                    issuer_id.push((dataObj.issuer_id));
                    let issueIDStr = dataObj.issuer_id.toString();
                    percentage_Contribution.push(dataObj.percentage_Contribution);
                    chartDataRow.push([issueIDStr, dataObj.percentage_Contribution, dataObj.percentage_Contribution]);
                }
                setIssuerPopChartData(chartDataRow);
                // setIssuerPopChartData({
                // labels: issuer_id,
                // data: percentage_Contribution,
                // dLabel: "Percentage",
                // });
            })
    }

    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            loadPopulationByIssuer();
            loadMetricTable();
        }
    }, [year]);

    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Overall Summary'} />
                        </div>
                    </div>
                </div>
                <div className="mt-3">

                    <CardComponent
                        heading={`CHART REVIEW OVERALL SUMMARY VIEW - ${year}`}
                        body={
                            <GridTable
                                data={tableData}
                                pagination={true}
                                columns={summaryColumns}
                                onTableClick={summaryClick}
                            ></GridTable>
                        }></CardComponent>

                    <br />

                    <div className="row">
                        <div className="col-md-12">
                            <div className="">
                                <CardComponent
                                    heading={`ISSUER(S) DETAILS`}
                                    body={
                                        <ServerSideGridTable
                                            url={`/ACACRIssuersDetails/year/${year}`}
                                            columns={issuersCol}
                                            tableClick={issuerTableClick}
                                        ></ServerSideGridTable>
                                    }></CardComponent>
                            </div>
                            <br />
                        </div>
                        <div className="col-md-12 ">

                            <CardComponent
                                heading={`POPULATION % FOR CHART REVIEW`}
                                body={
                                    <GChart data={IssuerPopChartData} chartType="ColumnChart" height="320px" width="900px"></GChart>
                                    // <SingleHorizontalBar
                                    //     label={IssuerPopChartData.labels}
                                    //     Data={IssuerPopChartData.data}
                                    //     dLabel={IssuerPopChartData.dLabel}
                                    //     xLabel={'Percentage(%)'}
                                    //     yLabel={'IssuerID'}
                                    //     xTick={'%'}
                                    // ></SingleHorizontalBar>
                                }></CardComponent>
                        </div>
                    </div>

                    <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                    <br />

                    <br />
                    <br />
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default connect(mapStateToProps)(ACAChartReviewOverallSummaryPage);
