import React, { useState, useEffect } from "react";
import AWVApi from '../../../../assets/constants/AWVRafservice.Instance';
import AWVServerSideGridTable from "../../../../components/TableGrids/AWVServerSideGridTable.table";
import AWVExpandGridTable from "../../../../components/TableGrids/AWVExpandGridTable.table";
import { HCC, ISSUER_ID } from "../../../../assets/constants/string.constants";
import AwvCardComponent from "../../../../components/Cards/AwvCard";
import { connect } from 'react-redux';
import AwvSubHeader from '../../../../components/home/header/AwvSubHeader';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import Tooltip from '@mui/material/Tooltip';
import { purple, red } from '@mui/material/colors';
import Awvheader from '../../../../components/home/header/Awvheader';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AddAWVFileUpload from '../../../../components/Dialog/AddAWVFileUpload';
import AreaChartMultiExcelAWV from '../../../../components/Charts/AreaChartMultiExcelAWV';
import ColumnChartSingleExcelAWV from '../../../../components/Charts/ColumnChartSingleExcelAWV';
import ColumnChartStackAWV from '../../../../components/Charts/ColumnChartStackAWV';
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const ProviderOpportunityReport = (props) => {

    return (
        <div className="row pr-0 pl-0 mt-1">
            <div className="col-lg-12 pr-0 pl-0">
                <AwvCardComponent
                    heading={`Physician ID: xxxxxx  Physician Nane: Danial`}
                    body={
                        <div className="col-lg-12 row mb-4 mt-4">
                            <div className="col-lg-6 p-0 m-0 row" >
                                <div className="container align-items-left justify-content-left text-left" >
                                    <strong style={{ fontSize: 20, color: '#1a9698' }}>
                                        <div className="ml-5">Compliance Rate</div>
                                    </strong>
                                </div>
                                <div className="col-lg-6 p-0 m-0" >
                                    <div className="container align-items-center justify-content-center text-center mt-2" style={{ width: '230px' }}>
                                        <CircularProgressbarWithChildren
                                            value={10}
                                            strokeWidth={10}
                                            styles={buildStyles({
                                                pathColor: `#1D9A9B`,
                                            })}
                                        >
                                            <div >
                                                <strong style={{ fontSize: 30, marginTop: 0, color: '#000' }}>{21.60}%</strong> <br />
                                                {/* <strong style={{ fontSize: 15, marginTop: 5, color: '#000' }}>Completed</strong> */}
                                            </div>
                                        </CircularProgressbarWithChildren >
                                    </div>
                                    <div className="container align-items-center justify-content-center text-center mt-2" >
                                        <strong style={{ fontSize: 15, color: '#000' }}>
                                            <div >(454/2102)</div>
                                        </strong>
                                    </div>
                                </div>
                                <div className="col-lg-6 p-0 m-0" >
                                    <div className="container align-items-center justify-content-center text-center mt-2" style={{ width: '230px' }}>
                                        <CircularProgressbarWithChildren
                                            value={10}
                                            strokeWidth={10}
                                            styles={buildStyles({
                                                pathColor: `#1D9A9B`,
                                            })}
                                        >
                                            <div >
                                                <strong style={{ fontSize: 30, marginTop: 0, color: '#000' }}>{20}%</strong> <br />
                                                {/* <strong style={{ fontSize: 15, marginTop: 5, color: '#000' }}>Completed</strong> */}
                                            </div>
                                        </CircularProgressbarWithChildren >
                                    </div>
                                    <div className="container align-items-center justify-content-center text-center mt-2" >
                                        <strong style={{ fontSize: 15, color: '#000' }}>
                                            <div >Total Numerator Complaint Over</div>
                                            <div >Total Elegible Population</div>
                                        </strong>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 p-0 m-0" style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                <div className="container align-items-center justify-content-center text-center " >
                                    <strong style={{ fontSize: 20, color: '#1a9698' }}>
                                        <div >Care Data Gaps</div>
                                    </strong>
                                </div>
                                <div className="container align-items-center justify-content-center text-center mt-2" style={{ width: '230px' }}>
                                    <CircularProgressbarWithChildren
                                        value={10}
                                        strokeWidth={10}
                                        styles={buildStyles({
                                            pathColor: `#1D9A9B`,
                                        })}
                                    >
                                        <div >
                                            <strong style={{ fontSize: 30, marginTop: 0, color: '#000' }}>{1648}</strong> <br />
                                            {/* <strong style={{ fontSize: 15, marginTop: 5, color: '#000' }}>Completed</strong> */}
                                        </div>
                                    </CircularProgressbarWithChildren >
                                </div>
                                <div className="container align-items-center justify-content-center text-center mt-2" >
                                    <strong style={{ fontSize: 15, color: '#000' }}>
                                        <div >Non-Compliant</div>
                                    </strong>
                                </div>
                            </div>
                            <div className="col-lg-3 p-0 m-0" style={{ borderLeft: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                <div className="mt-2 pb-3 pl-2" style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                    <strong style={{ fontSize: 25, marginTop: '15px', color: '#000' }}>{2102}</strong> <br />
                                    <strong style={{ fontSize: 12, marginTop: 5, color: '#000' }}>Total (Elegible Population)</strong>
                                </div>
                                <div className="mt-2 pb-3 pl-2" style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                    <strong style={{ fontSize: 25, marginTop: '15px', color: '#000' }}>{454}</strong> <br />
                                    <strong style={{ fontSize: 12, marginTop: 5, color: '#000' }}>Compliant</strong>
                                </div>
                                <div className="mt-2 pb-3 pl-2" >
                                    <strong style={{ fontSize: 25, marginTop: '15px', color: '#000' }}>{10}</strong> <br />
                                    <strong style={{ fontSize: 12, marginTop: 5, color: '#000' }}>Total Measures</strong><br />
                                    <strong style={{ fontSize: 12, marginTop: 5, color: '#000' }}>(Including Sub-Measures)</strong>
                                </div>
                            </div>
                        </div>
                    }
                />
            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        lobSelect: state.moduleFilter.lobSelect,
    };
}
export default connect(mapStateToProps)(ProviderOpportunityReport);