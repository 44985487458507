import React, { useRef, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar } from '../../../store/actions';
import AWVApi from '../../../assets/constants/AWVRafservice.Instance';
import Divider from '@mui/material/Divider';
import Moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import { CircularProgress } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { styled } from '@mui/material/styles';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import IconButton from "../../layout/aweLayout/IconButton";
import Button from "../../layout/aweLayout/Button";
import CloseIcon from "../../icons/CloseIcon";
import {LogFileUploadToS3} from '../../../assets/constants/LogFileUploadToS3'

const QuickNotesAWE = (props) => {
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [formDataList, setFormDataList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
    const [editFormData, setEditFormData] = useState({});
    const [logMessages, setLogMessages] = useState([]); // Array to store log messages
    const writeToLogFile = (message) => {
       setLogMessages(prevLogMessages => [...prevLogMessages, `${new Date().toISOString()} - ${message}`]);
   };
   const [generateLog, setGenerateLog] = useState(false);


    useEffect(() => {
        loadformDataTable(props.awv_backend_id, props.CinId);
        editLoadFormData(props.awv_backend_id, props.CinId);
    }, [props.CinId]);

    useEffect(() => {
        if (editFormData?.awv_backend_id && editFormData?.awv_backend_id != '') {
            let controlUser = JSON.parse(localStorage.getItem('controlUser'));
            setValue('awv_backend_id', editFormData?.awv_backend_id);
            setValue('cin', editFormData?.cin);
            setValue('year', props?.yearSelect);
            setValue('lob', props?.lobSelect);
            setValue('organisation_id', "1"); //To doi
            setValue('provider_group', props?.providerGroupSelect);
            setValue('member_id', editFormData?.member_id);
            setValue('createdOn', Moment().format('YYYY-MM-DD HH:mm:ss'));
            setValue('created_by', controlUser?.user_id);
            setValue('updated_by', controlUser?.user_id);
        }
    }, [editFormData]);

    const notificationRightDrawer = (open, valueId = '') => {
        props.currentAWERightSidebarType({ 'type': 'notification' });
        props.currentAWERightSidebar(open);
    };

    const editLoadFormData = (valueAwv, valueCin) => {
        writeToLogFile("value Awv...", valueAwv, ' cin= ', valueCin);
        valueAwv = btoa(valueAwv)
        valueCin = btoa(valueCin)
        writeToLogFile("value Awv after encryption ", valueAwv, ' cin= ', valueCin)
        writeToLogFile("editLoadFormData() call ");
        setIsLoading(true);
        writeToLogFile('/get-all-awv-record?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + '&awv_backend_id=' + valueAwv + '&cin=' + valueCin + '&iSortCol_0=provider_group&sSortDir_0=asc&iDisplayStart=0&iDisplayLength=1&formAttached=0')
        AWVApi.get('/get-all-awv-record?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + '&awv_backend_id=' + valueAwv + '&cin=' + valueCin + '&iSortCol_0=provider_group&sSortDir_0=asc&iDisplayStart=0&iDisplayLength=1&formAttached=0')
            .then(res => {
                console.log(res.data);
                if (res.data && res.data[0]) {
                    setEditFormData(res.data[0]);
                    setIsLoading(false);
                    writeToLogFile("Data retrieved successfully...");
                } else {
                    setEditFormData();
                    setIsLoading(false);
                    writeToLogFile("Error in retrieving data Response..." + res);
                }
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
                writeToLogFile("Error in retrieving data... " + err );
            })
    }

    const loadformDataTable = (valueAwv, valueCin) => {
        writeToLogFile("value Awv...", valueAwv, ' cin= ', valueCin);
        valueAwv = btoa(valueAwv)
        valueCin = btoa(valueCin)
        writeToLogFile("value Awv after encryption ", valueAwv, ' cin= ', valueCin)
        writeToLogFile("editLoadFormData() call ");
        setIsLoading(true);
        writeToLogFile('/get_awe_notes?cin=' + valueCin + '&awv_backend_id=' + valueAwv + '&lob=' + props?.lobSelect + '&organisation_id=1&year=' + props?.yearSelect)
        AWVApi.get('/get_awe_notes?cin=' + valueCin + '&awv_backend_id=' + valueAwv + '&lob=' + props?.lobSelect + '&organisation_id=1&year=' + props?.yearSelect) //'&provider_group=' + props?.providerGroupSelect
            .then(res => {
                console.log(res.data);
                setFormDataList(res.data);
                setIsLoading(false);
                writeToLogFile("Data retrieved successfully...");
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
                writeToLogFile("Error in retrieving data... " + err );
            })
    }


    const onSubmit = (data) => {
        writeToLogFile("onSubmit() call ");
        console.log(data);
        data.awv_backend_id =  data.awv_backend_id ? data.awv_backend_id.toString() : '';
        setFormSubmitisLoading(true);
        writeToLogFile("Data: " + data);
        if(data?.lob)
              data.lob = data.lob.toString();

        writeToLogFile('awv_backend_id before encryption ', data.awv_backend_id)
        data.cin = btoa(data.cin)
        data.awv_backend_id = btoa(data.awv_backend_id)
        data.member_id = btoa(data.member_id)
        AWVApi.post('/create-awe-notes', data)
            .then(res => {
                setValue('notes', '');
                setFormSubmitisLoading(false);
                toast.success('Notes added successfully');
                loadformDataTable(props.awv_backend_id, props.CinId);
                // notificationRightDrawer(false);
                writeToLogFile("Data retrieved successfully...");
                    setGenerateLog(true)
            })
            .catch(err => {
                setFormSubmitisLoading(false);
                toast.error(err?.response?.data?.message);
                writeToLogFile("Error in retrieving data... " + err );
                setGenerateLog(true)
            })
    }

    useEffect(() => {
        // Generate and download log file when logMessages have been updated
        if (logMessages.length > 0  && generateLog === true) {
        LogFileUploadToS3(logMessages, localStorage.getItem("access_key"), localStorage.getItem("secret_key"), process.env.REACT_APP_LOG_BUCKET, "Quick_Notes")
        }
    }, [ generateLog === true, logMessages, props?.yearSelect, props.AwvId, props.CinId, props?.lobSelect]); 

    return (
        <div className="diagnosis_code_sidebar offcanvas offcanvas-end show" tabIndex={-1} aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header flex_item_cb">
                <h5 id="offcanvasRightLabel" className='fs15'>QUICK NOTES</h5>
                <Tooltip title="Close">
                    <IconButton type="button" buttonType={"default"} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => notificationRightDrawer(false)}>
                        <CloseIcon className="w24" />
                    </IconButton>
                </Tooltip>
            </div>
            <Divider />
            <div className="offcanvas-body pt-1 custom-scrollbar overflow-auto">
                <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">REMARK</label>
                        <Controller
                            value={getValues('notes')}
                            name="notes"
                            control={control}
                            render={({ field }) => (
                                <textarea rows={3} {...field} className="form-control" />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.notes?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group flex_item_cs gap10">
                        <Button type='button' className={"btn-white minw80"} onClick={() => notificationRightDrawer(false)}>
                            CANCEL
                        </Button>
                        <Button type='submit' className={"minw80"} disabled={formSubmitisLoading && formSubmitisLoading == true ? true : false}>
                            SAVE
                        </Button>
                    </div>
                </form>
                {isLoading ?
                    <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        {formDataList && formDataList.length > 0 ?
                            <div className="">
                                <h5 className="fs18 mb10">History</h5>
                                <Divider />
                                <div className="history_card_list">
                                    {formDataList && formDataList.map((element, index) =>
                                        <div key={index} className={'history_card_item status-primary'}>
                                            <p className="fs12 mb-1 fw700">
                                                {Moment(element.created_on).format('h:mm a, MMMM DD YYYY')}
                                            </p>
                                            <p className="fs12 mb-1">
                                            <span className="fw700">Remarks : </span>
                                                {element.notes}
                                            </p>
                                            <p className="fs12 mb-1">
                                                <span className="fw700">Added by : </span>
                                                {element.created_by}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            :
                            <></>
                        }
                    </>
                }
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar })(QuickNotesAWE);

