import React,{useState, useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { connect } from 'react-redux'; 
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TabPanel from "../../components/Tab/TabPanel";
import { useForm, Controller } from "react-hook-form";
import TextField from '@material-ui/core/TextField';
/* import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle'; */
var AWS = require("aws-sdk");
const styles = (theme) => ({
    dialogPaper: {
        maxWidth: '80%',
        paddingLeft: '20px'
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
        paddingRight : theme.spacing(4),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(-1),
        top: theme.spacing(2),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose} >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const REGION =process.env.REACT_APP_REGION;
const ACCESS_KEY =localStorage.getItem("access_key");
const SECRET_ACCESS_KEY =localStorage.getItem("secret_key");

function a11yProps(index) {
  return {
        id: `wrapped-tab-${index}`,
        "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const UploadButtonDialog = (props) => {

  const { formState: { errors }, control, setValue } = useForm();
  const [statusCheckState, setStatusCheckState] = useState(1);
  const [valueSet, setValueSet] = useState('Enter file Parameters');
  const [selectedFile, setSelectedFile] = useState(null);
  const [open, setOpen] = React.useState();
  const [enteredTextState, setEnteredTextState] = useState(null);
  const [bucketName, setBucketName] = useState('');
  const [bucketNameFolderCreation, setBucketNameFolderCreation] = useState('');
  const [lis,setlis] = React.useState([]);
  const [folderName, setFolderName] = useState('');
  const [selectFolderForUpload, setSelectFolderForUpload] = useState('');
  /* const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState(''); */

   let statusCheck = 1;
  let lis1 =[];
  var s3 = new AWS.S3();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (event) =>{
    setBucketName( event.target.value);
  }
  const handleChangeForFolderCreation = (event) =>{
    setBucketNameFolderCreation( event.target.value);
  }

  const handleFolderChangeForUpload = (event) =>{
    setSelectFolderForUpload(event.target.value) 
  }

  const handleFolderChangeForCreation = (event) =>{
    if(statusCheckState == 1){
        setFolderName('') 
        setValue('Select_Folder', '')
    } else {
        setFolderName(event.target.value) 
      } 
  }

  const handleFileInput = (e) => {
    setSelectedFile(e.target.files[0]);
  }

  const handleInputText = (e) => {
    //setEnteredText= e.target.value;
    setEnteredTextState(e.target.value)
  }

  const handleRadioChange = (event) => {

    const value = event.target.value;
    statusCheck = value
    setValue('status', value);
    setStatusCheckState(value)
    setEnteredTextState('')
    setFolderName('')
    setValue('Select_Folder', '')
  };

  const handleClose = () => {
    props.loadData();
    setValueSet('Enter file Parameters')
    setBucketName('')
    setFolderName('')
    setSelectFolderForUpload('')
    setSelectedFile(null)
    setOpen(false);
    setStatusCheckState(1)
    setEnteredTextState(null)
    setBucketNameFolderCreation('')
    };

  const handleUpload = async (file) => {
    let Value = true;    
    var path = '';
    if (bucketName == "" || !file) {
		    window.alert("Please Select Bucket / File!!! " );
        Value = false;
		}

    if(Value == true){
      if(selectFolderForUpload != ""){
        path = bucketName+'/'+selectFolderForUpload
      } else {
        path =bucketName 
    }

    AWS.config.update({ region:REGION, credentials: new AWS.Credentials(ACCESS_KEY, SECRET_ACCESS_KEY)});

    let upload_params = {Bucket: path, Key: file.name, Body: file, ServerSideEncryption: 'aws:kms', SSEKMSKeyId: process.env.REACT_APP_KeyID};
    let upload = new AWS.S3.ManagedUpload({params: upload_params}); 
    upload.promise(function(err, data ){
      window.alert("FILE UPLOADED SUCCESSFULLY" )
      handleClose()
    });
    // console.log('promise '+promise)
    }
  }

  const createFolder = () => {
    let updatedFoldername = '';
    let Value = true, blankCheck = true;
    AWS.config.region = REGION;
    AWS.config.accessKeyId = ACCESS_KEY;
    AWS.config.secretAccessKey = SECRET_ACCESS_KEY;

    if(bucketNameFolderCreation == "" ) {
      Value = false;
      window.alert("Please Select Bucket Name" );
    } 
    else if(statusCheckState == 0 && (updatedFoldername.includes(null) ||folderName == '')) {
      Value = false;
      window.alert("Please Select Folder " );
    } 
    else if(enteredTextState == '' || enteredTextState == null ){
      blankCheck = false;
      Value = false;
      window.alert("Please Enter Folder Name to be created!!! " );
    }

    if(statusCheckState == 1){
        updatedFoldername = enteredTextState+'/';
    } else {
        updatedFoldername = folderName +'/' +enteredTextState+ '/';
    }

    var params = { 
        Bucket: bucketNameFolderCreation, 
        Key: updatedFoldername,
    };

    if(Value == true && blankCheck == true){
        s3.putObject(params, function(err,data){
        if(err){
          console.log('error!!!');
        } else {
          window.alert('folder Created..')
          handleClose()
        }
        });
    }
  }

  const handleChangeAppBar = (event, newValue) => {
    setValueSet(newValue);
    setBucketName('')
    setFolderName('')
    setSelectFolderForUpload('')
    setSelectedFile(null)
    setStatusCheckState(1)
    setEnteredTextState(null)
    setBucketNameFolderCreation('')
  };

  useEffect(() => {
    setValue('folder_name', '');
    setValue('status', statusCheck);
  })

  return (
        <div>
          <form id="add-verification-model">
          <Button color="primary" onClick={handleClickOpen}>Upload File </Button>
          <Dialog onClose={handleClose} 
                  aria-labelledby="report-generation" 
                  open={open} 
          >
          <DialogTitle id="report-generation" onClose={handleClose}> 
          <AppBar position="static" color="default">
                  <Tabs
                    variant="fullWidth"
                    textColor="primary"
                    indicatorColor="primary"
                    value={valueSet}
                    onChange={handleChangeAppBar}
                    centered
                  > 
                  <Tab
                    value="Enter file Parameters"
                    label="Enter file Parameters"
                    {...a11yProps("Enter file Parameters")}
                  />
                  <Tab
                    value="Create Folder"
                    label="Create Folder"
                    {...a11yProps('Create Folder')}
                  />
                  </Tabs>
          </AppBar>
          </DialogTitle>

          <DialogContent dividers>
              {/* {alert ? <Alert severity='error'>{alertContent}</Alert> : <></> } */}
          <TabPanel value={valueSet} index="Enter file Parameters">
              <>
                <Grid container spacing={3}>
                <Grid item xm={4}>
        <div className="d-flex align-items-center" style={{'min-width':'500px'}}>
          <div style={{'paddingLeft':'5px'}}> 
            <FormControl  variant="outlined" style={{'min-width':'200px'}}>
              <InputLabel id="demo-simple-select-outlined-label">Bucket Name</InputLabel> 
                <Select
                  id='Bucket Name'
                  label='Bucket Name'
                  variant="outlined"
                  name='Bucket Name'
                 onChange={event => handleChange(event)}
                >
                {props.bucketName.map(el => {
                  return(<MenuItem value={el} key={el}>{el}</MenuItem>)
                 })
                  } 
                </Select>
            </FormControl>
          </div>
          <div style={{'paddingLeft':'30px'}}>
            <FormControl  variant="outlined" style={{'min-width':'210px'}}>
              <InputLabel id="demo-simple-select-outlined-label">Select Folder</InputLabel> 
                <Select disabled = {bucketName == '' ? true : false}
                  labelId="demo-simple-select-outlined-label"
                  id='Select Folder'
                  label='Select Folder'
                  variant="outlined"
                  name='Select Folder'
                  onChange={event => handleFolderChangeForUpload(event)}
                >
                { props.folderSelectName.map(el => {
                  return(<MenuItem value={el} key={el}>{el}</MenuItem>)
                  })
                } 
                </Select>
            </FormControl>
          </div>
        </div>
        <br/>
        <div className="d-flex align-items-center" hidden='true'>
            <FormControl  variant="outlined" style={{'min-width':'200px'}}>
              <input type="file" onChange={handleFileInput} />
            </FormControl>
        </div>
        </Grid>
                </Grid> 
              </>

            <DialogActions>
              <Button autoFocus onClick={handleClose} color="primary">
                      <h6> <b> Cancel </b> </h6>
              </Button>
              <Button color="primary" onClick={() => handleUpload(selectedFile)}  >
                      <h6> <b> Upload </b> </h6>
              </Button>
            </DialogActions>
          </TabPanel>
           
          <TabPanel value={valueSet} index="Create Folder">
              <>
                <Grid container spacing={3}>
                <Grid item xm={4}>
                  <div className="d-flex align-items-center" style={{'min-width':'500px'}}>
                    <div style={{'paddingLeft':'5px'}}> 
                      <FormControl  variant="outlined" style={{'min-width':'200px'}}>
                          <InputLabel id="demo-simple-select-outlined-label">Bucket Name</InputLabel> 
                              <Select
                                id='Bucket Name'
                                label='Bucket Name'
                                variant="outlined"
                                name='Bucket Name'
                                onChange={event => handleChangeForFolderCreation(event)}
                                >
                                {props.bucketName.map(el => {
                                return(<MenuItem value={el} key={el}>{el}</MenuItem>)
                                })
                                } 
                              </Select>
                        </FormControl></div>
                    <div style={{'paddingLeft':'30px'}}>
                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label"><b>Is this Parent Folder?</b></FormLabel>
                                <Controller
                                    className="input-control"
                                    name="status"
                                    control={control}
                                    value = 'status'
                                    render={({ field }) => (
                                    <RadioGroup
                                        row
                                        onChange={e => handleRadioChange(e)}
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        >
                                    <FormControlLabel className='ml-2' id='status-active' value="1" control={<Radio color="primary" checked={statusCheckState && statusCheckState == 1 ? true : false} />} label="yes" />
                                    <FormControlLabel className='ml-2' id='status-inactive' value="0" control={<Radio color="primary" checked={statusCheckState == 0 ? true : false} />} label="No" />
                                        </RadioGroup>
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                    />
                                        {errors?.status?.type === "required" && <label className="text-danger">This field is required</label>}
                        </FormControl>
                    </div>
                </div>
                <br/>
                <div className="d-flex align-items-center">
                    {(bucketNameFolderCreation == "" || statusCheckState == 1) ? 
                    <div>
                      <FormControl  variant="outlined" style={{'min-width':'210px'}}>
                          <InputLabel id="demo-simple-select-outlined-label">Select Folder</InputLabel>
                              <Select disabled ='true'
                                labelId="demo-simple-select-outlined-label"
                                id='Select Folder'
                                label='Select Folder'
                                variant="outlined"
                                defaultValue= ''
                                name='Select_Folder'
                                control={control}
                                onChange={event => handleFolderChangeForCreation(event)}
                              >
                                {props.folderSelectName.map(el => {
                                  return(<MenuItem value={''} key={el}>{el}</MenuItem>)
                                })
                                } 
                              </Select> 
                      </FormControl>
                    </div> : 
                    <div>
                      <FormControl  variant="outlined" style={{'min-width':'210px'}}>
                          <InputLabel id="demo-simple-select-outlined-label">Select Folder</InputLabel>
                              <Select 
                                labelId="demo-simple-select-outlined-label"
                                id='Select Folder'
                                label='Select Folder'
                                variant="outlined"
                                name='Select_Folder'
                                control={control}
                                onChange={event => handleFolderChangeForCreation(event)}
                              >
                                {props.folderSelectName.map(el => {
                                  return(<MenuItem value={el} key={el}>{el}</MenuItem>)
                                })
                                } 
                              </Select> 
                      </FormControl>
                    </div> 
                    }
                    <div style={{'paddingLeft':'30px'}}>
                      <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                          <Controller
                            className="input-control"
                            name="folder_name"
                            control={control}
                            defaultValue= 'folder_name'
                            render={({ field }) => (
                              <TextField label="Folder Name" variant="outlined" value={enteredTextState} onChange = {e => handleInputText(e)} />
                              )}
                            rules={{
                                   required: true,
                                }}
                          />
                              {errors?.folder_name?.type === "required" && <label className="text-danger">This field is required</label>}
                      </FormControl>
                    </div>
                </div>                                               
                </Grid>
                </Grid> 
                </>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} color="primary">
                            <h6> <b> Cancel </b> </h6>
                    </Button>
                    <Button color="primary" onClick={() => createFolder()}  >
                            <h6> <b> Create Folder </b> </h6>
                    </Button>
                </DialogActions>
          </TabPanel>
          </DialogContent>
          </Dialog>
        </form>
        </div>
  );
}
const mapStateToProps = (state) => {
  return {
      isSignedIn: state.auth.isSignedIn,
  };
}
export default (withStyles(styles), connect(mapStateToProps))(UploadButtonDialog);
