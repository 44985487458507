import PersonIcon from '@material-ui/icons/Person';
import DescriptionIcon from '@material-ui/icons/Description';
import Description from "../Pages/ETL-S3Configuration/Description";
import FileUpload from "../Pages/ETL-S3Configuration/FileUpload";
import FileDownload from "../Pages/ETL-S3Configuration/FileDownload";
import ReportsDashboard from "../Pages/ETL-S3Configuration/ReportsDashboard";

const etlDashboardRoutes = [
  {
    name: "Catalogue",
    icon: DescriptionIcon,
    layout: "/etl",
    path:'',
    module:"DataRepository",
    permissionSlug: 'reports_datarepo_5',
    subRoutes: [
      {
    path: "/reports",
          name: "Reports",
          icon: PersonIcon,
          component: ReportsDashboard,
          layout: "/etl",
          permissionSlug: 'reports_datarepo_5'
      }
    ]
  },
  // {
  //   name: "ETL Configuration",
  //   icon: DescriptionIcon,
  //   layout: "/etl",
  //   path:'',
  //   module:"DataRepository",
  //   permissionSlug: 'etl_configuration_5',
  //   subRoutes: [
  //         {
  //               path: "/fileUpload",
  //               name: "File Upload",
  //               icon: PersonIcon,
  //               component: FileUpload,
  //               layout: "/etl",
  //               permissionSlug: 'file_upload_5',
  //         },
  //        {
  //         path: "/fileDownload",
  //         name: "File Download",
  //         icon: PersonIcon,
  //         component: FileDownload,
  //         layout: "/etl",
  //         permissionSlug: 'file_download_5',
  //       },
  //   ]
  // },
]

export default etlDashboardRoutes;