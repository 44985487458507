export const IC_CONSTANT = {
    "Entities": [
        {
            "Id": 0,
            "Text": "hypertension",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.9967420697212219,
            "BeginOffset": 63,
            "EndOffset": 75,
            "Attributes": [],
            "Traits": [
                {
                    "Name": "DIAGNOSIS",
                    "Score": 0.9461950659751892
                }
            ],
            "ICD10CMConcepts": [
                {
                    "Description": "Essential (primary) hypertension",
                    "Code": "I10",
                    "Score": 0.8021445870399475
                },
                {
                    "Description": "Hypertensive heart disease with heart failure",
                    "Code": "I11.0",
                    "Score": 0.7612709403038025
                },
                {
                    "Description": "Hypertensive chronic kidney disease with stage 1 through stage 4 chronic kidney disease, or unspecified chronic kidney disease",
                    "Code": "I12.9",
                    "Score": 0.7111008167266846
                },
                {
                    "Description": "Hypertensive heart disease without heart failure",
                    "Code": "I11.9",
                    "Score": 0.7071889042854309
                },
                {
                    "Description": "Hypertension secondary to endocrine disorders",
                    "Code": "I15.2",
                    "Score": 0.6396586894989014
                }
            ]
        },
        {
            "Id": 1,
            "Text": "pain",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.9564837217330933,
            "BeginOffset": 77,
            "EndOffset": 81,
            "Attributes": [],
            "Traits": [
                {
                    "Name": "DIAGNOSIS",
                    "Score": 0.4660525619983673
                }
            ],
            "ICD10CMConcepts": [
                {
                    "Description": "Pain, unspecified",
                    "Code": "R52",
                    "Score": 0.6378946900367737
                },
                {
                    "Description": "Unspecified abdominal pain",
                    "Code": "R10.9",
                    "Score": 0.4970459043979645
                },
                {
                    "Description": "Headache",
                    "Code": "R51",
                    "Score": 0.49242645502090454
                },
                {
                    "Description": "Chest pain, unspecified",
                    "Code": "R07.9",
                    "Score": 0.4883575737476349
                },
                {
                    "Description": "Precordial pain",
                    "Code": "R07.2",
                    "Score": 0.4870343804359436
                }
            ]
        },
        {
            "Id": 3,
            "Text": "melanoma",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.8960100412368774,
            "BeginOffset": 142,
            "EndOffset": 150,
            "Attributes": [
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.9955116510391235,
                    "RelationshipScore": 0.9969848990440369,
                    "Id": 5,
                    "BeginOffset": 151,
                    "EndOffset": 155,
                    "Text": "skin",
                    "Traits": []
                }
            ],
            "Traits": [
                {
                    "Name": "DIAGNOSIS",
                    "Score": 0.8311895728111267
                }
            ],
            "ICD10CMConcepts": [
                {
                    "Description": "Personal history of malignant melanoma of skin",
                    "Code": "Z85.820",
                    "Score": 0.7476122975349426
                },
                {
                    "Description": "Malignant melanoma of skin, unspecified",
                    "Code": "C43.9",
                    "Score": 0.7156683206558228
                },
                {
                    "Description": "Malignant melanoma of other part of trunk",
                    "Code": "C43.59",
                    "Score": 0.6142951250076294
                },
                {
                    "Description": "Malignant melanoma of right lower limb, including hip",
                    "Code": "C43.71",
                    "Score": 0.5827378630638123
                },
                {
                    "Description": "Malignant melanoma of other parts of face",
                    "Code": "C43.39",
                    "Score": 0.5595200061798096
                }
            ]
        },
        {
            "Id": 4,
            "Text": "skin cancer",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.6276293396949768,
            "BeginOffset": 151,
            "EndOffset": 162,
            "Attributes": [],
            "Traits": [
                {
                    "Name": "DIAGNOSIS",
                    "Score": 0.9456526041030884
                }
            ],
            "ICD10CMConcepts": [
                {
                    "Description": "Unspecified malignant neoplasm of skin, unspecified",
                    "Code": "C44.90",
                    "Score": 0.797886312007904
                },
                {
                    "Description": "Unspecified malignant neoplasm of skin of unspecified part of face",
                    "Code": "C44.300",
                    "Score": 0.7260082960128784
                },
                {
                    "Description": "Malignant neoplasm of penis, unspecified",
                    "Code": "C60.9",
                    "Score": 0.6517084240913391
                },
                {
                    "Description": "Other and unspecified malignant neoplasm of skin, unspecified",
                    "Code": "C44.9",
                    "Score": 0.6495559215545654
                },
                {
                    "Description": "Malignant melanoma of skin, unspecified",
                    "Code": "C43.9",
                    "Score": 0.6266300678253174
                }
            ]
        },
        {
            "Id": 6,
            "Text": "glaucoma",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.9970203042030334,
            "BeginOffset": 167,
            "EndOffset": 175,
            "Attributes": [],
            "Traits": [
                {
                    "Name": "DIAGNOSIS",
                    "Score": 0.9298203587532043
                }
            ],
            "ICD10CMConcepts": [
                {
                    "Description": "Unspecified glaucoma",
                    "Code": "H40.9",
                    "Score": 0.8927996754646301
                },
                {
                    "Description": "Hypersecretion glaucoma, bilateral",
                    "Code": "H40.823",
                    "Score": 0.586944580078125
                },
                {
                    "Description": "Hypersecretion glaucoma",
                    "Code": "H40.82",
                    "Score": 0.5798274874687195
                },
                {
                    "Description": "Absolute glaucoma",
                    "Code": "H44.51",
                    "Score": 0.5381458401679993
                },
                {
                    "Description": "Absolute glaucoma, bilateral",
                    "Code": "H44.513",
                    "Score": 0.528877854347229
                }
            ]
        },
        {
            "Id": 9,
            "Text": "oriented",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.9846470355987549,
            "BeginOffset": 229,
            "EndOffset": 237,
            "Attributes": [
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.9997819066047668,
                    "RelationshipScore": 0.9990715980529785,
                    "Id": 7,
                    "BeginOffset": 206,
                    "EndOffset": 213,
                    "Text": "GENERAL",
                    "Traits": []
                }
            ],
            "Traits": [
                {
                    "Name": "SIGN",
                    "Score": 0.9292627573013306
                }
            ],
            "ICD10CMConcepts": [
                {
                    "Description": "Other injury of unspecified body region",
                    "Code": "T14.8",
                    "Score": 0.6741135120391846
                },
                {
                    "Description": "Driver of heavy transport vehicle injured in collision with other and unspecified motor vehicles in traffic accident",
                    "Code": "V69.4",
                    "Score": 0.6564663052558899
                },
                {
                    "Description": "Coma scale, best verbal response, oriented",
                    "Code": "R40.225",
                    "Score": 0.6353193521499634
                },
                {
                    "Description": "Occupant of heavy transport vehicle injured in other and unspecified transport accidents",
                    "Code": "V69",
                    "Score": 0.5670092105865479
                },
                {
                    "Description": "Clonorchiasis",
                    "Code": "B66.1",
                    "Score": 0.530741810798645
                }
            ]
        },
        {
            "Id": 11,
            "Text": "distress",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.9991835951805115,
            "BeginOffset": 262,
            "EndOffset": 270,
            "Attributes": [
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.9997819066047668,
                    "RelationshipScore": 0.9888243079185486,
                    "Id": 7,
                    "BeginOffset": 206,
                    "EndOffset": 213,
                    "Text": "GENERAL",
                    "Traits": []
                },
                {
                    "Type": "ACUITY",
                    "Score": 0.9902533888816833,
                    "RelationshipScore": 0.9999603033065796,
                    "Id": 10,
                    "BeginOffset": 256,
                    "EndOffset": 261,
                    "Text": "acute",
                    "Traits": []
                }
            ],
            "Traits": [
                {
                    "Name": "SIGN",
                    "Score": 0.9805071353912354
                },
                {
                    "Name": "NEGATION",
                    "Score": 0.9757267832756042
                }
            ],
            "ICD10CMConcepts": [
                {
                    "Description": "Acute respiratory distress",
                    "Code": "R06.03",
                    "Score": 0.8490557670593262
                },
                {
                    "Description": "Functional dyspepsia",
                    "Code": "K30",
                    "Score": 0.6753973960876465
                },
                {
                    "Description": "Acute respiratory distress syndrome",
                    "Code": "J80",
                    "Score": 0.6217046976089478
                },
                {
                    "Description": "Orthopnea",
                    "Code": "R06.01",
                    "Score": 0.6089022755622864
                },
                {
                    "Description": "Epigastric pain",
                    "Code": "R10.13",
                    "Score": 0.5972450971603394
                }
            ]
        },
        {
            "Id": 16,
            "Text": "equal",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.9723057746887207,
            "BeginOffset": 312,
            "EndOffset": 317,
            "Attributes": [
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.998505711555481,
                    "RelationshipScore": 0.8400631546974182,
                    "Id": 12,
                    "BeginOffset": 274,
                    "EndOffset": 279,
                    "Text": "HEENT",
                    "Traits": []
                },
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.998596727848053,
                    "RelationshipScore": 0.9311255812644958,
                    "Id": 14,
                    "BeginOffset": 298,
                    "EndOffset": 302,
                    "Text": "Eyes",
                    "Traits": []
                },
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.9925204515457153,
                    "RelationshipScore": 0.9990524649620056,
                    "Id": 15,
                    "BeginOffset": 305,
                    "EndOffset": 311,
                    "Text": "Pupils",
                    "Traits": []
                }
            ],
            "Traits": [
                {
                    "Name": "SIGN",
                    "Score": 0.9478311538696289
                }
            ],
            "ICD10CMConcepts": [
                {
                    "Description": "Other specified multiple gestation, number of chorions and amnions are both equal to the number of fetuses",
                    "Code": "O30.83",
                    "Score": 0.505316972732544
                },
                {
                    "Description": "Other specified multiple gestation, number of chorions and amnions are both equal to the number of fetuses, unspecified trimester",
                    "Code": "O30.839",
                    "Score": 0.5048143267631531
                },
                {
                    "Description": "Other specified multiple gestation, number of chorions and amnions are both equal to the number of fetuses, third trimester",
                    "Code": "O30.833",
                    "Score": 0.4839570224285126
                },
                {
                    "Description": "Other specified multiple gestation, number of chorions and amnions are both equal to the number of fetuses, second trimester",
                    "Code": "O30.832",
                    "Score": 0.4745643436908722
                },
                {
                    "Description": "Other specified multiple gestation, number of chorions and amnions are both equal to the number of fetuses, first trimester",
                    "Code": "O30.831",
                    "Score": 0.3981044888496399
                }
            ]
        },
        {
            "Id": 17,
            "Text": "reactive to light",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.9788231253623962,
            "BeginOffset": 322,
            "EndOffset": 339,
            "Attributes": [
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.998505711555481,
                    "RelationshipScore": 0.9566185474395752,
                    "Id": 12,
                    "BeginOffset": 274,
                    "EndOffset": 279,
                    "Text": "HEENT",
                    "Traits": []
                },
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.998596727848053,
                    "RelationshipScore": 0.8413569927215576,
                    "Id": 14,
                    "BeginOffset": 298,
                    "EndOffset": 302,
                    "Text": "Eyes",
                    "Traits": []
                },
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.9925204515457153,
                    "RelationshipScore": 0.9973151087760925,
                    "Id": 15,
                    "BeginOffset": 305,
                    "EndOffset": 311,
                    "Text": "Pupils",
                    "Traits": []
                }
            ],
            "Traits": [
                {
                    "Name": "SIGN",
                    "Score": 0.9111998677253723
                }
            ],
            "ICD10CMConcepts": [
                {
                    "Description": "Dizziness and giddiness",
                    "Code": "R42",
                    "Score": 0.699178159236908
                },
                {
                    "Description": "Acute reactive otitis externa, bilateral",
                    "Code": "H60.553",
                    "Score": 0.4680382311344147
                },
                {
                    "Description": "Nonspecific reactive hepatitis",
                    "Code": "K75.2",
                    "Score": 0.43030276894569397
                },
                {
                    "Description": "Reactive arthropathy, unspecified",
                    "Code": "M02.9",
                    "Score": 0.41217637062072754
                },
                {
                    "Description": "Acute reactive otitis externa, unspecified ear",
                    "Code": "H60.559",
                    "Score": 0.37236958742141724
                }
            ]
        },
        {
            "Id": 19,
            "Text": "Supple",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.9951953291893005,
            "BeginOffset": 350,
            "EndOffset": 356,
            "Attributes": [
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.9985722303390503,
                    "RelationshipScore": 0.8623684644699097,
                    "Id": 18,
                    "BeginOffset": 343,
                    "EndOffset": 347,
                    "Text": "NECK",
                    "Traits": []
                }
            ],
            "Traits": [
                {
                    "Name": "SIGN",
                    "Score": 0.9696398973464966
                }
            ],
            "ICD10CMConcepts": [
                {
                    "Description": "Chronic ischemic heart disease, unspecified",
                    "Code": "I25.9",
                    "Score": 0.5618748068809509
                },
                {
                    "Description": "Angina pectoris with documented spasm",
                    "Code": "I20.1",
                    "Score": 0.5317291617393494
                },
                {
                    "Description": "Lack of adequate food and safe drinking water",
                    "Code": "Z59.4",
                    "Score": 0.4978512227535248
                },
                {
                    "Description": "Starvation",
                    "Code": "T73.0",
                    "Score": 0.40767747163772583
                }
            ]
        },
        {
            "Id": 21,
            "Text": "Clear",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.8916742205619812,
            "BeginOffset": 368,
            "EndOffset": 373,
            "Attributes": [
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.9986138343811035,
                    "RelationshipScore": 0.8767385482788086,
                    "Id": 20,
                    "BeginOffset": 360,
                    "EndOffset": 365,
                    "Text": "CHEST",
                    "Traits": []
                }
            ],
            "Traits": [
                {
                    "Name": "SIGN",
                    "Score": 0.9163068532943726
                }
            ],
            "ICD10CMConcepts": [
                {
                    "Description": "Unspecified convulsions",
                    "Code": "R56.9",
                    "Score": 0.8921903967857361
                },
                {
                    "Description": "Angina pectoris, unspecified",
                    "Code": "I20.9",
                    "Score": 0.8006583452224731
                },
                {
                    "Description": "Other disorders of lung",
                    "Code": "J98.4",
                    "Score": 0.7551737427711487
                },
                {
                    "Description": "Bipolar disorder, unspecified",
                    "Code": "F31.9",
                    "Score": 0.7414770126342773
                },
                {
                    "Description": "Other specified diseases of anus and rectum",
                    "Code": "K62.89",
                    "Score": 0.7357692122459412
                }
            ]
        },
        {
            "Id": 23,
            "Text": "Rate 72 and regular",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.2655276656150818,
            "BeginOffset": 394,
            "EndOffset": 413,
            "Attributes": [
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.9984884262084961,
                    "RelationshipScore": 0.999786913394928,
                    "Id": 22,
                    "BeginOffset": 377,
                    "EndOffset": 391,
                    "Text": "CARDIOVASCULAR",
                    "Traits": []
                }
            ],
            "Traits": [
                {
                    "Name": "SIGN",
                    "Score": 0.9101862907409668
                }
            ],
            "ICD10CMConcepts": [
                {
                    "Description": "Tachycardia, unspecified",
                    "Code": "R00.0",
                    "Score": 0.6899427175521851
                },
                {
                    "Description": "Excessive and frequent menstruation with regular cycle",
                    "Code": "N92.0",
                    "Score": 0.6262301802635193
                },
                {
                    "Description": "Bradycardia, unspecified",
                    "Code": "R00.1",
                    "Score": 0.5999189615249634
                },
                {
                    "Description": "Schizophrenia, unspecified",
                    "Code": "F20.9",
                    "Score": 0.5433937907218933
                },
                {
                    "Description": "Regular astigmatism",
                    "Code": "H52.22",
                    "Score": 0.5355449318885803
                }
            ]
        },
        {
            "Id": 25,
            "Text": "Nontender",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.9895378351211548,
            "BeginOffset": 427,
            "EndOffset": 436,
            "Attributes": [
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.9976587295532227,
                    "RelationshipScore": 0.8948989510536194,
                    "Id": 24,
                    "BeginOffset": 417,
                    "EndOffset": 424,
                    "Text": "ABDOMEN",
                    "Traits": []
                }
            ],
            "Traits": [
                {
                    "Name": "SIGN",
                    "Score": 0.9480043649673462
                }
            ],
            "ICD10CMConcepts": [
                {
                    "Description": "Hypotension, unspecified",
                    "Code": "I95.9",
                    "Score": 0.8289012312889099
                },
                {
                    "Description": "Demoralization and apathy",
                    "Code": "R45.3",
                    "Score": 0.7135063409805298
                },
                {
                    "Description": "Hypoglycemia, unspecified",
                    "Code": "E16.2",
                    "Score": 0.6992334127426147
                },
                {
                    "Description": "Hypotension",
                    "Code": "I95",
                    "Score": 0.6477418541908264
                },
                {
                    "Description": "Other hypotension",
                    "Code": "I95.89",
                    "Score": 0.6293307542800903
                }
            ]
        },
        {
            "Id": 29,
            "Text": "normal range of motion",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.7991933226585388,
            "BeginOffset": 509,
            "EndOffset": 531,
            "Attributes": [
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.9928620457649231,
                    "RelationshipScore": 0.9979386925697327,
                    "Id": 26,
                    "BeginOffset": 440,
                    "EndOffset": 451,
                    "Text": "EXTREMITIES",
                    "Traits": []
                },
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.9939624667167664,
                    "RelationshipScore": 0.53252112865448,
                    "Id": 28,
                    "BeginOffset": 479,
                    "EndOffset": 490,
                    "Text": "extremities",
                    "Traits": []
                }
            ],
            "Traits": [
                {
                    "Name": "SIGN",
                    "Score": 0.9300790429115295
                }
            ],
            "ICD10CMConcepts": [
                {
                    "Description": "Other specific joint derangements of left hand, not elsewhere classified",
                    "Code": "M24.842",
                    "Score": 0.40950626134872437
                },
                {
                    "Description": "Stiffness of unspecified joint, not elsewhere classified",
                    "Code": "M25.60",
                    "Score": 0.4087371826171875
                },
                {
                    "Description": "Stiffness of unspecified hip, not elsewhere classified",
                    "Code": "M25.659",
                    "Score": 0.39786627888679504
                },
                {
                    "Description": "Stiffness of right hand, not elsewhere classified",
                    "Code": "M25.641",
                    "Score": 0.352027952671051
                },
                {
                    "Description": "Chromosome inversion in normal individual",
                    "Code": "Q95.1",
                    "Score": 0.34201857447624207
                }
            ]
        },
        {
            "Id": 30,
            "Text": "limited internal and external rotation",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.5207802057266235,
            "BeginOffset": 572,
            "EndOffset": 610,
            "Attributes": [
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.9928620457649231,
                    "RelationshipScore": 0.9993069171905518,
                    "Id": 26,
                    "BeginOffset": 440,
                    "EndOffset": 451,
                    "Text": "EXTREMITIES",
                    "Traits": []
                },
                {
                    "Type": "DIRECTION",
                    "Score": 0.9986981153488159,
                    "RelationshipScore": 0.999951958656311,
                    "Id": 33,
                    "BeginOffset": 618,
                    "EndOffset": 623,
                    "Text": "right",
                    "Traits": []
                },
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.9964838027954102,
                    "RelationshipScore": 0.9992640614509583,
                    "Id": 34,
                    "BeginOffset": 624,
                    "EndOffset": 627,
                    "Text": "hip",
                    "Traits": []
                },
                {
                    "Type": "DIRECTION",
                    "Score": 0.9893408417701721,
                    "RelationshipScore": 0.7565418481826782,
                    "Id": 35,
                    "BeginOffset": 637,
                    "EndOffset": 641,
                    "Text": "left",
                    "Traits": []
                }
            ],
            "Traits": [
                {
                    "Name": "SIGN",
                    "Score": 0.7183526754379272
                }
            ],
            "ICD10CMConcepts": [
                {
                    "Description": "Other hemorrhoids",
                    "Code": "K64.8",
                    "Score": 0.38910600543022156
                },
                {
                    "Description": "Limitation of activities due to disability",
                    "Code": "Z73.6",
                    "Score": 0.34997037053108215
                },
                {
                    "Description": "Residual hemorrhoidal skin tags",
                    "Code": "K64.4",
                    "Score": 0.3464256525039673
                },
                {
                    "Description": "Other vasculitis limited to the skin",
                    "Code": "L95.8",
                    "Score": 0.310272216796875
                },
                {
                    "Description": "Internal hirudiniasis",
                    "Code": "B83.4",
                    "Score": 0.3015754222869873
                }
            ]
        },
        {
            "Id": 41,
            "Text": "pain",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.6594225764274597,
            "BeginOffset": 844,
            "EndOffset": 848,
            "Attributes": [],
            "Traits": [],
            "ICD10CMConcepts": [
                {
                    "Description": "Pain, unspecified",
                    "Code": "R52",
                    "Score": 0.26958608627319336
                },
                {
                    "Description": "Pain in right thigh",
                    "Code": "M79.651",
                    "Score": 0.2279476374387741
                },
                {
                    "Description": "Mastodynia",
                    "Code": "N64.4",
                    "Score": 0.20373931527137756
                },
                {
                    "Description": "Chest pain, unspecified",
                    "Code": "R07.9",
                    "Score": 0.1831004023551941
                },
                {
                    "Description": "Pleurodynia",
                    "Code": "R07.81",
                    "Score": 0.18223871290683746
                }
            ]
        },
        {
            "Id": 46,
            "Text": "subpatellar crepitus",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.7753079533576965,
            "BeginOffset": 945,
            "EndOffset": 965,
            "Attributes": [
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.9942299127578735,
                    "RelationshipScore": 0.5078630447387695,
                    "Id": 43,
                    "BeginOffset": 890,
                    "EndOffset": 894,
                    "Text": "knee",
                    "Traits": []
                },
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.9949329495429993,
                    "RelationshipScore": 0.6697869896888733,
                    "Id": 45,
                    "BeginOffset": 916,
                    "EndOffset": 920,
                    "Text": "knee",
                    "Traits": []
                }
            ],
            "Traits": [
                {
                    "Name": "SIGN",
                    "Score": 0.9492132067680359
                }
            ],
            "ICD10CMConcepts": [
                {
                    "Description": "Other specified symptoms and signs involving the circulatory and respiratory systems",
                    "Code": "R09.89",
                    "Score": 0.34160640835762024
                },
                {
                    "Description": "Other specific joint derangements of unspecified hip, not elsewhere classified",
                    "Code": "M24.859",
                    "Score": 0.25158464908599854
                },
                {
                    "Description": "Other meniscus derangements, unspecified meniscus, right knee",
                    "Code": "M23.306",
                    "Score": 0.16865968704223633
                },
                {
                    "Description": "Other specified congenital malformations of skull and face bones",
                    "Code": "Q75.8",
                    "Score": 0.15004278719425201
                },
                {
                    "Description": "Other specified respiratory disorders",
                    "Code": "J98.8",
                    "Score": 0.14945948123931885
                }
            ]
        },
        {
            "Id": 47,
            "Text": "skin lesions",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.9286251664161682,
            "BeginOffset": 971,
            "EndOffset": 983,
            "Attributes": [],
            "Traits": [
                {
                    "Name": "SIGN",
                    "Score": 0.9711204171180725
                },
                {
                    "Name": "NEGATION",
                    "Score": 0.9853364825248718
                }
            ],
            "ICD10CMConcepts": [
                {
                    "Description": "Disorder of the skin and subcutaneous tissue, unspecified",
                    "Code": "L98.9",
                    "Score": 0.6401183605194092
                },
                {
                    "Description": "Other specified disorders of the skin and subcutaneous tissue",
                    "Code": "L98.8",
                    "Score": 0.41232943534851074
                },
                {
                    "Description": "Other seborrheic keratosis",
                    "Code": "L82.1",
                    "Score": 0.3998795747756958
                },
                {
                    "Description": "Dermatitis, unspecified",
                    "Code": "L30.9",
                    "Score": 0.3996816575527191
                },
                {
                    "Description": "Erythematous condition, unspecified",
                    "Code": "L53.9",
                    "Score": 0.38827037811279297
                }
            ]
        },
        {
            "Id": 49,
            "Text": "Motor and sensory exams all within normal limits",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.6370968818664551,
            "BeginOffset": 992,
            "EndOffset": 1040,
            "Attributes": [],
            "Traits": [
                {
                    "Name": "SIGN",
                    "Score": 0.8716551661491394
                }
            ],
            "ICD10CMConcepts": [
                {
                    "Description": "Other symptoms and signs involving the nervous system",
                    "Code": "R29.818",
                    "Score": 0.45609980821609497
                },
                {
                    "Description": "Abnormal reflex",
                    "Code": "R29.2",
                    "Score": 0.2675224244594574
                },
                {
                    "Description": "Paresthesia of skin",
                    "Code": "R20.2",
                    "Score": 0.22686226665973663
                },
                {
                    "Description": "Dual sensory impairment",
                    "Code": "Z73.82",
                    "Score": 0.20737847685813904
                },
                {
                    "Description": "Hereditary motor and sensory neuropathy",
                    "Code": "G60.0",
                    "Score": 0.20599663257598877
                }
            ]
        },
        {
            "Id": 50,
            "Text": "Dorsal pedis pulses are present and equal",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.7395182847976685,
            "BeginOffset": 1043,
            "EndOffset": 1084,
            "Attributes": [
                {
                    "Type": "DIRECTION",
                    "Score": 0.9957335591316223,
                    "RelationshipScore": 0.9999938011169434,
                    "Id": 52,
                    "BeginOffset": 1085,
                    "EndOffset": 1096,
                    "Text": "bilaterally",
                    "Traits": []
                }
            ],
            "Traits": [
                {
                    "Name": "SIGN",
                    "Score": 0.9097039699554443
                }
            ],
            "ICD10CMConcepts": [
                {
                    "Description": "Other specified symptoms and signs involving the circulatory and respiratory systems",
                    "Code": "R09.89",
                    "Score": 0.5831702351570129
                },
                {
                    "Description": "Abnormal reflex",
                    "Code": "R29.2",
                    "Score": 0.36104631423950195
                },
                {
                    "Description": "Tachycardia, unspecified",
                    "Code": "R00.0",
                    "Score": 0.3232863247394562
                },
                {
                    "Description": "Multiple sclerosis",
                    "Code": "G35",
                    "Score": 0.3166631758213043
                },
                {
                    "Description": "Other specified multiple gestation, number of chorions and amnions are both equal to the number of fetuses, second trimester",
                    "Code": "O30.832",
                    "Score": 0.29141056537628174
                }
            ]
        },
        {
            "Id": 53,
            "Text": "leg length inequality",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.8203010559082031,
            "BeginOffset": 1116,
            "EndOffset": 1137,
            "Attributes": [
                {
                    "Type": "DIRECTION",
                    "Score": 0.9973906874656677,
                    "RelationshipScore": 0.9994626641273499,
                    "Id": 55,
                    "BeginOffset": 1147,
                    "EndOffset": 1152,
                    "Text": "right",
                    "Traits": []
                },
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.9936553239822388,
                    "RelationshipScore": 0.9924929738044739,
                    "Id": 56,
                    "BeginOffset": 1153,
                    "EndOffset": 1156,
                    "Text": "leg",
                    "Traits": []
                }
            ],
            "Traits": [
                {
                    "Name": "SIGN",
                    "Score": 0.8282620906829834
                }
            ],
            "ICD10CMConcepts": [
                {
                    "Description": "Other specified soft tissue disorders",
                    "Code": "M79.89",
                    "Score": 0.3872486352920532
                },
                {
                    "Description": "Contusion of right lower leg",
                    "Code": "S80.11",
                    "Score": 0.37664109468460083
                },
                {
                    "Description": "Other specified injuries of unspecified lower leg",
                    "Code": "S89.80",
                    "Score": 0.3430517613887787
                },
                {
                    "Description": "Pain in right lower leg",
                    "Code": "M79.661",
                    "Score": 0.32860228419303894
                },
                {
                    "Description": "Pain in right leg",
                    "Code": "M79.604",
                    "Score": 0.29209160804748535
                }
            ]
        },
        {
            "Id": 57,
            "Text": "End stage osteoarthritis",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.38944685459136963,
            "BeginOffset": 1190,
            "EndOffset": 1214,
            "Attributes": [
                {
                    "Type": "DIRECTION",
                    "Score": 0.9983927607536316,
                    "RelationshipScore": 0.9999942779541016,
                    "Id": 58,
                    "BeginOffset": 1222,
                    "EndOffset": 1227,
                    "Text": "right",
                    "Traits": []
                },
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.9960216879844666,
                    "RelationshipScore": 0.9999686479568481,
                    "Id": 59,
                    "BeginOffset": 1228,
                    "EndOffset": 1231,
                    "Text": "hip",
                    "Traits": []
                }
            ],
            "Traits": [
                {
                    "Name": "DIAGNOSIS",
                    "Score": 0.8793756365776062
                }
            ],
            "ICD10CMConcepts": [
                {
                    "Description": "Unilateral primary osteoarthritis, right knee",
                    "Code": "M17.11",
                    "Score": 0.5134734511375427
                },
                {
                    "Description": "Bilateral primary osteoarthritis of knee",
                    "Code": "M17.0",
                    "Score": 0.5004940629005432
                },
                {
                    "Description": "End stage renal disease",
                    "Code": "N18.6",
                    "Score": 0.4814528524875641
                },
                {
                    "Description": "Hypertensive chronic kidney disease with stage 5 chronic kidney disease or end stage renal disease",
                    "Code": "I12.0",
                    "Score": 0.44886526465415955
                },
                {
                    "Description": "Unilateral primary osteoarthritis, left knee",
                    "Code": "M17.12",
                    "Score": 0.4139467775821686
                }
            ]
        },
        {
            "Id": 60,
            "Text": "narrowing",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.6168617010116577,
            "BeginOffset": 1275,
            "EndOffset": 1284,
            "Attributes": [
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.8140485882759094,
                    "RelationshipScore": 0.999957799911499,
                    "Id": 61,
                    "BeginOffset": 1292,
                    "EndOffset": 1303,
                    "Text": "joint space",
                    "Traits": []
                }
            ],
            "Traits": [],
            "ICD10CMConcepts": [
                {
                    "Description": "Occlusion and stenosis of right carotid artery",
                    "Code": "I65.21",
                    "Score": 0.39895400404930115
                },
                {
                    "Description": "Occlusion and stenosis of bilateral carotid arteries",
                    "Code": "I65.23",
                    "Score": 0.3260422348976135
                },
                {
                    "Description": "Crossing vessel and stricture of ureter without hydronephrosis",
                    "Code": "N13.5",
                    "Score": 0.30047446489334106
                },
                {
                    "Description": "Occlusion and stenosis of left carotid artery",
                    "Code": "I65.22",
                    "Score": 0.2783392667770386
                },
                {
                    "Description": "Occlusion and stenosis of unspecified carotid artery",
                    "Code": "I65.29",
                    "Score": 0.26793745160102844
                }
            ]
        },
        {
            "Id": 62,
            "Text": "periarticular osteophytes",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.9202308058738708,
            "BeginOffset": 1317,
            "EndOffset": 1342,
            "Attributes": [
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.6155885457992554,
                    "RelationshipScore": 0.9319560527801514,
                    "Id": 65,
                    "BeginOffset": 1347,
                    "EndOffset": 1358,
                    "Text": "subchondral",
                    "Traits": []
                }
            ],
            "Traits": [
                {
                    "Name": "SIGN",
                    "Score": 0.5747217535972595
                },
                {
                    "Name": "DIAGNOSIS",
                    "Score": 0.46051597595214844
                }
            ],
            "ICD10CMConcepts": [
                {
                    "Description": "Osteophyte, vertebrae",
                    "Code": "M25.78",
                    "Score": 0.4322817027568817
                },
                {
                    "Description": "Osteophyte, left foot",
                    "Code": "M25.775",
                    "Score": 0.29137441515922546
                },
                {
                    "Description": "Osteophyte, left elbow",
                    "Code": "M25.722",
                    "Score": 0.26840975880622864
                },
                {
                    "Description": "Osteophyte, right foot",
                    "Code": "M25.774",
                    "Score": 0.261856347322464
                },
                {
                    "Description": "Fibromyalgia",
                    "Code": "M79.7",
                    "Score": 0.2609739899635315
                }
            ]
        },
        {
            "Id": 64,
            "Text": "subchondral sclerosis",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.8256004452705383,
            "BeginOffset": 1347,
            "EndOffset": 1368,
            "Attributes": [
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.27346712350845337,
                    "RelationshipScore": 0.9961265921592712,
                    "Id": 63,
                    "BeginOffset": 1317,
                    "EndOffset": 1342,
                    "Text": "periarticular osteophytes",
                    "Traits": []
                }
            ],
            "Traits": [
                {
                    "Name": "DIAGNOSIS",
                    "Score": 0.689074695110321
                }
            ],
            "ICD10CMConcepts": [
                {
                    "Description": "Multiple sclerosis",
                    "Code": "G35",
                    "Score": 0.39976537227630615
                },
                {
                    "Description": "Atherosclerosis of aorta",
                    "Code": "I70.0",
                    "Score": 0.3866845965385437
                },
                {
                    "Description": "Tuberous sclerosis",
                    "Code": "Q85.1",
                    "Score": 0.3685683310031891
                },
                {
                    "Description": "Other systemic sclerosis",
                    "Code": "M34.89",
                    "Score": 0.31013137102127075
                },
                {
                    "Description": "Hepatic sclerosis",
                    "Code": "K74.1",
                    "Score": 0.29719632863998413
                }
            ]
        },
        {
            "Id": 67,
            "Text": "cyst",
            "Category": "MEDICAL_CONDITION",
            "Type": "DX_NAME",
            "Score": 0.8409932255744934,
            "BeginOffset": 1383,
            "EndOffset": 1387,
            "Attributes": [
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.27346712350845337,
                    "RelationshipScore": 0.9982902407646179,
                    "Id": 63,
                    "BeginOffset": 1317,
                    "EndOffset": 1342,
                    "Text": "periarticular osteophytes",
                    "Traits": []
                },
                {
                    "Type": "SYSTEM_ORGAN_SITE",
                    "Score": 0.6155885457992554,
                    "RelationshipScore": 0.8479915261268616,
                    "Id": 65,
                    "BeginOffset": 1347,
                    "EndOffset": 1358,
                    "Text": "subchondral",
                    "Traits": []
                },
                {
                    "Type": "DIRECTION",
                    "Score": 0.586844801902771,
                    "RelationshipScore": 0.9995637536048889,
                    "Id": 66,
                    "BeginOffset": 1374,
                    "EndOffset": 1382,
                    "Text": "adjacent",
                    "Traits": []
                }
            ],
            "Traits": [
                {
                    "Name": "DIAGNOSIS",
                    "Score": 0.5530387759208679
                }
            ],
            "ICD10CMConcepts": [
                {
                    "Description": "Cyst of kidney, acquired",
                    "Code": "N28.1",
                    "Score": 0.7138569951057434
                },
                {
                    "Description": "Pilonidal cyst without abscess",
                    "Code": "L05.91",
                    "Score": 0.6460506916046143
                },
                {
                    "Description": "Solitary cyst of unspecified breast",
                    "Code": "N60.09",
                    "Score": 0.5913975834846497
                },
                {
                    "Description": "Leiomyoma of uterus, unspecified",
                    "Code": "D25.9",
                    "Score": 0.5877670049667358
                },
                {
                    "Description": "Follicular cyst of the skin and subcutaneous tissue, unspecified",
                    "Code": "L72.9",
                    "Score": 0.5736966133117676
                }
            ]
        }
    ],
    "ModelVersion": "0.1.0"
}