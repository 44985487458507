import Tooltip from '@mui/material/Tooltip';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import AWVApi from '../../../../assets/constants/AWVRafservice.Instance';
import IconButton from '../../../../components/CustomButtons/IconButton';
import CustomAweServerSideDatatable from '../../../../components/TableGrids/CustomAweServerSideDatatable';
import CustomAweDatatable from "../../../../components/TableGrids/CustomAweDatatable"
import BigArrowRightIcon from '../../../../components/icons/BigArrowRightIcon';
import EditPenIcon from '../../../../components/icons/EditPenIcon';
import CheckTikIcon from '../../../../components/icons/CheckTikIcon';
import CloseLineIcon from '../../../../components/icons/CloseLineIcon';
import PlusFileIcon from '../../../../components/icons/PlusFileIcon';
import SubHeaderFilter from '../../../../components/layout/aweLayout/SubHeaderFilter';
import history from "../../../../history";
import DownloadFileFromS3 from "../../../ACA/DownloadFileFromS3";
import { currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload, currentAWERightSidebarType } from '../../../../store/actions';
import Button from '../../../../components/layout/aweLayout/Button';
import EyeIcon from '../../../../components/icons/EyeIcon';
import PinIcon from '../../../../components/icons/PinIcon';
import ListBoxIcon from '../../../../components/icons/ListBoxIcon';
import MultipleForwardLeftIcon from '../../../../components/icons/MultipleForwardLeftIcon';
import QuickNoteIcon from '../../../../components/icons/QuickNoteIcon';
import { NavLink } from "react-router-dom";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AWVNLPInsightPDF from '../../../../components/customRightSidebar/AweSideBar/AWVNLPInsightPDF';
import AWVCoderWorkBench from '../../../../assets/constants/AWVCoderWorkBenchRafservice.Instance';
import { MenuItem, Select, LinearProgress } from '@mui/material'
import DotsMenuIcon from '../../../../components/icons/DotsMenuIcon';
import Menu from '@mui/material/Menu';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useLocation } from 'react-router-dom';
import {LogFileUploadToS3} from '../../../../assets/constants/LogFileUploadToS3'

import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from 'react-circular-progressbar';

const ReportingAnalytics = (props) => {
    const location = useLocation();
    const [isControlUser] = useState(JSON.parse(localStorage.getItem('controlUser')));
    const [expandedRows, setExpandedRows] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElAssign, setAnchorElAssign] = useState(null);
    const openAction = Boolean(anchorEl);
    const openActionAssign = Boolean(anchorElAssign);
    const [circularDataList, setCircularDataList] = useState({});
    const [siteIdSelect, setsiteIdSelect] = useState('');
    const [providerAddressGroupsSelect, setProviderAddressGroupsSelect] = useState([]);
    const [providerAddressGroupsNameSelect, setProviderAddressGroupsNameSelect] = useState([]);
    const [citySelect, setCitySelect] = useState('');
    const [siteAddressSelect, setSiteAddressSelect] = useState('');
    const [zipSelect, setZipSelect] = useState('');
    const [formAttached, setFormAttached] = useState([]);
    const [formAttachedFlag, setFormAttachedFlag] = useState([0]);
    const [formAssignedFlag, setFormAssignedFlag] = useState('');
    const [formWithoutAtachmentTotal, setFormWithoutAtachmentTotal] = useState(0);
    const [currentActionStatus, setCurrentActionStatus] = useState('');
    const [currentActionAWVID, setCurrentActionAWVID] = useState('');
    const [currentActionUserId, setCurrentActionUserId] = useState('');
    const [selectOnHoldStatus, setSelectOnHoldStatus] = useState('N');
    const [modelFormRole, setModelFormRole] = useState(0);
    const params = new URL(window.location.href).searchParams;
    const [cinMemberId, setCinMemberId] = useState(location.state?.cin);
    const [aweMemberId, setAweMemberId] = useState('');
    const [memberDetails, setMemberDetails] = useState('');
    const [cinDrilldown, setCINDrilldown] = useState();
    const [memberExtraDetailsData, setMemberExtraDetailsData] = useState('');
    const [showCinDetails, setShowCinDetails] = useState(false);
    const [HCCUserDetails, setHCCUserDetails] = useState();
    const [logMessages, setLogMessages] = useState([]); // Array to store log messages
    const writeToLogFile = (message) => {
       setLogMessages(prevLogMessages => [...prevLogMessages, `${new Date().toISOString()} - ${message}`]);
   };
   const [generateLog, setGenerateLog] = useState(false);
   const [isLoading, setIsLoading] = useState(true);const [state, setState] = useState({
    chart_chase_record: null,
});


    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            loadCircularDataTable();
            fetchFormWithoutAttachmentCount();
        }
    }, [props.yearSelect, props.providerGroupSelect, props.lobSelect]);

    useEffect(() => {
        if (props.yearSelect && cinMemberId && props.yearSelect != '' && cinMemberId != '') {
            setShowCinDetails(true);
            loadformAWEProcessedRecord(cinMemberId, location.state?.awe_display_id)
            loadMemberDetailsReport(cinMemberId, location.state?.awe_display_id);
            loadMemberDataHCCCapture(cinMemberId, location.state?.awe_display_id);
            setCINDrilldown(cinMemberId)
        }
    }, [cinMemberId, props.yearSelect, props.providerGroupSelect, props.lobSelect]);

    useEffect(() => {
        if (props.aweRightSidebarCloseDatatableReload && props.aweRightSidebarCloseDatatableReload == true) {
            loadMemberDetailsExtraData(cinMemberId, aweMemberId);
        }        
    }, [props.aweRightSidebarCloseDatatableReload]);

    const toggleRow = (rowIndex) => {
        const isRowExpanded = expandedRows.includes(rowIndex);
        if (isRowExpanded) {
            setExpandedRows(expandedRows.filter((row) => row !== rowIndex));
        } else {
            setExpandedRows([...expandedRows, rowIndex]);
        }
    };

    const expandedRowsReset = () => {
        setExpandedRows([]);
    };

    const handleChangeFormAssigned = (event) => {
        if (event.target.checked == true) {
            setFormAssignedFlag(isControlUser?.user_id);
        } else {
            setFormAssignedFlag("");
        }
    }

    const handleMemberIdSet = (value) => {
        writeToLogFile("handleMemberIdSet() call ");
        setCinMemberId(value);
    };

    //Calculating Member Age
    const calculateAge = (dob) => {
        if (dob) {
            const today = new Date();
            const birthDay = new Date(dob);
            let age = today.getFullYear() - birthDay.getFullYear();
            const monthDifference = today.getMonth() - birthDay.getMonth();
            if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDay.getDate())) {
                age--;
            }
            return age;
        } else {
            return "";
        }
    }

    const aweRecordColumns = [
        {
            name: "awv_backend_id",
            label: "Action",
            options: {
                showColumn: true,
                filter: false,
                filterType: 'select',
                search: false,
                sort: false,
                width: '85px',
                otherColumnName: 'all',
                customBodyRender: (value, otherColumnValueArray = '', indexRow = '') => {
                    let controlUser = JSON.parse(localStorage.getItem('controlUser'));
                    return (
                        <>
                            <div class="flex_item_cs gap10">
                                <Tooltip arrow placement="top" title="">
                                    <IconButton className={"btn-sm"} type="button" onClick={() => toggleRow(indexRow)} data-toggle="collapse" data-target={"#collapseTable" + indexRow} aria-expanded={expandedRows.includes(indexRow)} aria-controls="collapseExample">
                                        {expandedRows.includes(indexRow) ?
                                            <div className={"collapse_btn"}>
                                                <BigArrowRightIcon className="w16 h16" />
                                            </div>
                                            :
                                            <div className={"collapse_btn collapsed"}>
                                                <BigArrowRightIcon className="w16 h16" />
                                            </div>
                                        }
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </>
                    );
                }
            }
        },
        {
            name: "CIN",
            label: "CIN/RXID",
            options: {
                showColumn: true,
                filter: true,
                filterType: '',
                search: false,
                sort: true,
                width: '112px',
                otherColumnName: 'awe_display_id',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <span style={{ width: '100px', color: "#1a9698", cursor: "pointer" }} onClick={() => showUserDetailsLoad(value, otherColumnValue)}>
                            {value}
                        </span>
                    );
                },
            }
        },
        {
            name: "awe_display_id",
            label: "AWE ID",
            options: {
                showColumn: true,
                filter: true,
                filterType: '',
                search: false,
                sort: true,
                width: '120px',
            }
        },
        {
            name: "member_name",
            label: "Member Name",
            options: {
                showColumn: true,
                filter: true,
                filterType: '',
                search: false,
                sort: true,
                width: '112px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <Tooltip title={value} arrow placement="top">
                            <div className="table-short-text">
                                {value}
                            </div>
                        </Tooltip>
                    );
                }
            }
        },
        {
            name: "address",
            label: "Address",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '112px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <Tooltip title={value} arrow placement="top">
                            <div className="table-short-text">
                                {value}
                            </div>
                        </Tooltip>
                    );
                }
            }
        },
        {
            name: "CITY",
            label: "City",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '112px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <Tooltip title={value} arrow placement="top">
                            <div className="table-short-text">
                                {value}
                            </div>
                        </Tooltip>
                    );
                }
            }
        },
        {
            name: "STATE",
            label: "State",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '100px',
                otherColumnName: '',
            }
        },
        {
            name: "ZIP",
            label: "Zip",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '100px',
                otherColumnName: '',
            }
        },
        {
            name: "PPG",
            label: "PPG",
            options: {
                showColumn: true,
                filter: true,
                filterType: '',
                search: false,
                sort: true,
                width: '112px',
                otherColumnName: '',
            }
        },
        {
            name: "PRIMARY_PROVIDER",
            label: "Primary Provider",
            options: {
                showColumn: true,
                filter: true,
                filterType: '',
                search: false,
                sort: true,
                width: '180px',
                otherColumnName: '',
            }
        },
        {
            name: "SITE_ID",
            label: "Site ID",
            options: {
                showColumn: true,
                filter: true,
                filterType: '',
                search: false,
                sort: true,
                width: '120px',
                otherColumnName: '',
            }
        },
        {
            name: "PCP_Name",
            label: "PCP Name",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '112px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <Tooltip title={value} arrow placement="top">
                            <div className="table-short-text">
                                {value}
                            </div>
                        </Tooltip>
                    );
                }
            }
        },
        {
            name: "STATUS",
            label: "Staus",
            options: {
                showColumn: false,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '112px',
                otherColumnName: '',
            }
        },
        {
            name: "YEAR",
            label: "Year",
            options: {
                showColumn: false,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '157px',
            }
        },
        {
            name: "BENEFIT_START_DATE",
            label: "Benefit Start Date",
            options: {
                showColumn: false,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '157px',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            {value && value != '' ?
                                Moment(value).format("YYYY-MM-DD")
                                :
                                ''
                            }
                        </>
                    )
                }
            }
        },
        {
            name: "BENEFIT_END_DATE",
            label: "Benefit End Date",
            options: {
                showColumn: false,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '157px',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            {value && value != '' ?
                                Moment(value).format("YYYY-MM-DD")
                                :
                                ''
                            }
                        </>
                    )
                }
            }
        },
        {
            name: "BIRTH_DATE",
            label: "Birth Date",
            options: {
                showColumn: false,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '157px',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            {value && value != '' ?
                                Moment(value).format("YYYY-MM-DD")
                                :
                                ''
                            }
                        </>
                    )
                }
            }
        },
        {
            name: "GENDER",
            label: "Gender",
            options: {
                showColumn: false,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '157px',
            }
        },
        {
            name: "HOMEPHONE",
            label: "Home Phone",
            options: {
                showColumn: false,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '157px',
            }
        },
        {
            name: "PCPLicense",
            label: "PCP License",
            options: {
                showColumn: false,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '157px',
            }
        },
        {
            name: "PCP_ADDRESS",
            label: "PCP Address",
            options: {
                showColumn: false,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '157px',
            }
        },
        {
            name: "PCP_CITY",
            label: "PCP City",
            options: {
                showColumn: false,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '157px',
            }
        },
        {
            name: "PCP_PHONE",
            label: "PCP Phone",
            options: {
                showColumn: false,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '157px',
            }
        },
        {
            name: "PCP_STATE",
            label: "PCP State",
            options: {
                showColumn: false,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '157px',
            }
        },
        {
            name: "PCP_ZIP",
            label: "PCP Zip",
            options: {
                showColumn: false,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '157px',
            }
        },
        {
            name: "PPG_DESCRIPTION",
            label: "PPG Description",
            options: {
                showColumn: false,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '157px',
            }
        },
        {
            name: "hicn",
            label: "Hicn",
            options: {
                showColumn: false,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '157px',
            }
        },
        {
            name: "lob",
            label: "Lob",
            options: {
                showColumn: false,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '157px',
            }
        },
        {
            name: "address_grouping",
            label: "Provider Address Group",
            options: {
                showColumn: false,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '157px',
            }
        },
        {
            name: "site_zip",
            label: "Site Zip",
            options: {
                showColumn: false,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '157px',
            }
        },
        {
            name: "site_city",
            label: "Site City",
            options: {
                showColumn: false,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '157px',
            }
        },
        {
            name: "site_address",
            label: "Site Address",
            options: {
                showColumn: false,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '157px',
            }
        },
    ];
    
    let aweRecordSubColumns = [
        {
            name: "dx_code",
            label: "Dx CODE",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: false,
                width: '100px',
                otherColumnName: 'dx_description',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <Tooltip title={otherColumnValue ? `${value} - ${otherColumnValue}` : value} arrow placement="top">
                            <div className="table-short-text">
                                {otherColumnValue ? `${value} - ${otherColumnValue}` : value}
                            </div>
                        </Tooltip>
                    );
                }
            }
        },
        {
            name: "nlp_captured",
            label: "NLP Reported",
            cols: "2",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: false,
                width: '100px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            {value && value != '' ?
                                <Tooltip title={value == 'Y' ? 'Yes' : 'No'} arrow placement="top">
                                    <div className="table-short-text">
                                        {value == 'Y' ? 'Yes' : 'No'}
                                    </div>
                                </Tooltip>
                                :
                                <div>

                                </div>
                            }
                        </>
                    );
                }
            }
        },
        {
            name: (state.chart_chase_record && state.chart_chase_record == null ? location.state?.chart_chase_record :state.chart_chase_record) && (state.chart_chase_record && state.chart_chase_record == null ? location.state?.chart_chase_record :state.chart_chase_record) == 'YES' ? 'claim_matched' : "historical",
            label: (state.chart_chase_record && state.chart_chase_record == null ? location.state?.chart_chase_record :state.chart_chase_record) && (state.chart_chase_record && state.chart_chase_record == null ? location.state?.chart_chase_record :state.chart_chase_record) == 'YES' ? 'is claim matched' : "Historic",
            cols: "2",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: false,
                width: '100px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            {value && value != '' ?
                                <Tooltip title={value == 'Y' ? 'Yes' : 'No'} arrow placement="top">
                                    <div className="table-short-text">
                                        {value == 'Y' ? 'Yes' : 'No'}
                                    </div>
                                </Tooltip>
                                :
                                <div>

                                </div>
                            }
                        </>
                    );
                }
            }
        },
        {
            name: "present_in_form",
            label: "Printed in AWE Form",
            cols: "2",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: false,
                width: '100px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            
                                <Tooltip title={String(value) !== "" ? (String(value) === '0' ? 'No' : 'Yes') : ''} arrow placement="top">
                                    <div className="table-short-text">
                                        {/* {alert(String(value))} */}
                                        {String(value) !== "" ? (String(value) === '0' ? 'No' : 'Yes') : ''}

                                    </div>
                                </Tooltip>
                            
                        </>
                    );
                }
            }
        },
        {
            name: "STATUS",
            label: "Coder Action",
            cols: "2",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: false,
                width: '100px',
                otherColumnName: '',
                customBodyRender: (value, otherColumnValue = '') => {
                    return (
                        <>
                            {value == 'Rejected' ? 'Rejected' : ''}
                            {value == 'Accepted' ? 'Accepted' : ''}
                            {value == 'Pending for clarification' ? 'Pending for Clarification' : ''}
                        </>
                    );
                }
            }
        },
    ];

    const insertionIndex = 1;
    if (String(props.lobSelect) == '2') {
        aweRecordSubColumns.splice(insertionIndex, 0,
            {
                name: "model_1",
                label: "HCC V24",
                cols: "2",
                options: {
                    showColumn: true,
                    filter: false,
                    filterType: '',
                    search: false,
                    sort: false,
                    width: '100px',
                    otherColumnName: 'model_1_description',
                    customBodyRender: (value, otherColumnValue = '') => {
                        return (
                            <>
                                {value && (value != 'No HCC' && value != '') ?
                                    <Tooltip title={otherColumnValue ? `${value} - ${otherColumnValue}` : value} arrow placement="top">
                                        <div className="table-short-text">
                                            {otherColumnValue ? `${value} - ${otherColumnValue}` : value}
                                        </div>
                                    </Tooltip>
                                    :
                                    <div>

                                    </div>
                                }
                            </>
                        );
                    }
                }
            },
            {
                name: "model_2",
                label: "HCC V28",
                cols: "2",
                options: {
                    showColumn: false,
                    filter: false,
                    filterType: '',
                    search: false,
                    sort: false,
                    width: '150px',
                    otherColumnName: 'model_2_description',
                    customBodyRender: (value, otherColumnValue = '') => {
                        return (
                            <>
                                {value && (value != 'No HCC' && value != '') ?

                                    <Tooltip title={otherColumnValue ? `${value} - ${otherColumnValue}` : value} arrow placement="top">
                                        <div className="table-short-text">
                                            {otherColumnValue ? `${value} - ${otherColumnValue}` : value}
                                        </div>
                                    </Tooltip>
                                    :
                                    <div>

                                    </div>
                                }
                            </>
                        );
                    }
                }
            },
        )
    } else {
        aweRecordSubColumns.splice(insertionIndex, 0,
            {
                name: "model_1",
                label: "HCC",
                cols: "2",
                options: {
                    showColumn: true,
                    filter: false,
                    filterType: '',
                    search: false,
                    sort: false,
                    width: '100px',
                    otherColumnName: 'model_1_description',
                    customBodyRender: (value, otherColumnValue = '') => {
                        return (
                            <>
                                {value && (value != 'No HCC' && value != '') ?
                                    <Tooltip title={otherColumnValue ? `${value} - ${otherColumnValue}` : value} arrow placement="top">
                                        <div className="table-short-text">
                                            {otherColumnValue ? `${value} - ${otherColumnValue}` : value}
                                        </div>
                                    </Tooltip>
                                    :
                                    <div>

                                    </div>
                                }
                            </>
                        );
                    }
                }
            },
        )
    }

    const showUserDetailsLoad = (memberCinId, awe_display_id) => {
        writeToLogFile("showUserDetailsLoad() call");
        writeToLogFile("memberCinId; " + memberCinId + " awe_display_id: " + awe_display_id);
        loadformAWEProcessedRecord(memberCinId, awe_display_id)
        setCinMemberId(memberCinId);
        history.push({
            pathname:'/new_awe/awe-analytics-summary',
            search: `?#`,
            state: {
                cin: memberCinId,
                awe_display_id: awe_display_id,
                // chart_chase_record:chart_chase_record
            }});
            setGenerateLog(true)
    };
    const loadformAWEProcessedRecord = async (cinMemberId, awe_display_id) => {
        AWVApi.get('/get-awe-processed-record-details?year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + '&cin=' + btoa(cinMemberId) + '&awe_display_id=' + btoa(awe_display_id) )
            .then(res => {
                if (res.data && res.data[0]) {
                    setState({
                        chart_chase_record: res.data[0].chart_chase_record
                    });
                    setIsLoading(false);
                    
                } else {
                    // setId('');
                    setIsLoading(false);
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleCloseAction = (assignMe = false) => {
        writeToLogFile("handleCloseAction() call");
        if (assignMe == true) {
            setAnchorElAssign(null);
        } else {
            setAnchorEl(null);
        }
    };

    const handlechange = (event) => {
        if (event.target.checked == true) {
            setFormAttachedFlag("2,3,4,5,6,7,8");
        } else {
            setFormAttached([]);
            setFormAttachedFlag(0);
        }
    }

    const handleStatusDesc = (status) => {
        if (status && status == 'DefaultStatus') {
            return 'Default Status';
        } else if (status && status == 'PendingUpload') {
            return 'Pending Upload';
        } else if (status && status == 'pendingforCoordinator') {
            return 'Pending for Coordinator';
        } else if (status && status == 'pendingforCoder') {
            return 'Pending for Coder';
        } else if (status && status == 'pendingForPaymentApproval') {
            return 'Pending for Payment Approval';
        } else if (status && status == 'ApprovedForPayment') {
            return 'Approved for Payment';
        } else if (status && status == 'Reject') {
            return 'Reject';
        } else if (status && status == 'PaymentDone') {
            return 'Payment Done';
        } else if (status && status == 'void') {
            return 'Void';
        } else if (status && status == 'ApprovedNoPayment') {
            return 'Approved - No Payment';
        } else {
            return '';
        }
    }

    const handleStatusName = (status) => {
        if (status && status == 'DefaultStatus') {
            return 'DS';
        } else if (status && status == 'PendingUpload') {
            return 'PU';
        } else if (status && status == 'pendingforCoordinator') {
            return 'PFCT';
        } else if (status && status == 'pendingforCoder') {
            return 'PFC';
        } else if (status && status == 'pendingForPaymentApproval') {
            return 'PFPA';
        } else if (status && status == 'ApprovedForPayment') {
            return 'AFP';
        } else if (status && status == 'PaymentDone') {
            return 'PD';
        } else if (status && status == 'Reject') {
            return 'REJ';
        } else if (status && status == 'void') {
            return 'Void';
        } else if (status && status == 'ApprovedNoPayment') {
            return 'ANP';
        } else {
            return '';
        }
    }

    const editHCCDetails = (value, CinId, action = 'Edit', aweId) => {
        writeToLogFile("editHCCDetails() call" + " Value "+ value + "CinId: " + CinId + " aweId " + aweId);
        if ((value.id && value.id != '') && action == 'Edit') {
            props.currentAWERightSidebarType({ 'type': 'memberHccDetailsReport', 'cin_id': CinId, 'status': 0, 'DX_code': value.DX_code, 'HCC_Code': value.HCC_Code, 'rxc_code': value.rxc_code, 'ndc_code': value.ndc_code, 'cpt_code': value.cpt_code, 'id': value.id });
            props.currentAWERightSidebar(true);
        } else if (value.checklist_id && value.checklist_id != '' && action == 'Edit') {
            props.currentAWERightSidebarType({ 'type': 'memberCoderChecklistReport', 'cin_id': CinId, 'status': 0, 'awv_backend_id': memberDetails?.AWE_ID, 'checklist_id': value.checklist_id });
            props.currentAWERightSidebar(true);
        } else if (action == 'MultiEdit') {
            props.currentAWERightSidebarType({ 'type': 'memberMultiCoderChecklistReport', 'cin_id': CinId, 'status': 0, 'awv_backend_id': memberDetails?.AWE_ID });
            props.currentAWERightSidebar(true);
        } else if (((value.awe_source && value.awe_source != '') || value.awe_source == null) && action == 'Edit') {
            props.currentAWERightSidebarType({ 'type': 'memberCoordinatorChecklistReport', 'cin_id': CinId, 'awv_backend_id': memberDetails?.AWE_ID, 'status': 0, 'awe_source': value.awe_source });
            props.currentAWERightSidebar(true);
        } else {
            props.currentAWERightSidebarType({ 'type': 'memberHccDetailsReport', 'cin_id': CinId, 'status': 1, 'aweId': aweId });
            props.currentAWERightSidebar(true);
        }
    };

    const notificationRightDrawer = (open, typeValue, valueId, statusId = '', cinId = '') => {
        writeToLogFile("notificationRightDrawer() call");
        setAnchorEl('');
        setAnchorElAssign('');
        props.currentAWERightSidebarType({ 'type': typeValue, 'awv_backend_id': valueId, 'cin_id': cinId, 'status': statusId });
        props.currentAWERightSidebar(open);
    };


    const notificationRightDrawerSubColumn = (open, typeValue, valueId, statusId = '', cinId = '') => {
        props.currentAWERightSidebarType({ 'type': typeValue, 'awv_id': valueId.awv_id, 'cin_id': valueId.cin, 'status': statusId, 'title': 'CHECKLIST NOTES', 'hcc_code': valueId.HCC_Code, 'hcc_description': valueId.HCC_Description, 'risk_code_type': valueId.risk_code_type });
        props.currentAWERightSidebar(open);
    };

    const handleClickAction = (event, awvId, status, user_assigned, stage_id, onHoldStatus = 'N', assignMe = false) => {
        writeToLogFile("handleClickAction() call ", "awvId "+ awvId + " status " + status + " user_assigned " + user_assigned + " stage_id " + stage_id + " onHoldStatus " );
        if (assignMe == true) {
            setAnchorElAssign(event.currentTarget);
        } else {
            setAnchorEl(event.currentTarget);
            }
        setCurrentActionAWVID(awvId);
        setCurrentActionUserId(user_assigned);
        setSelectOnHoldStatus(onHoldStatus);

        if (status && status == 'DefaultStatus') {
            setCurrentActionStatus(0);
            setModelFormRole(0);
        } else if ((status && status == 'PendingUpload') || stage_id == 1) {
            setCurrentActionStatus(1);
            setModelFormRole(2);
        } else if (status && status == 'pendingforCoordinator') {
            setCurrentActionStatus(2);
            setModelFormRole(3);
        } else if (status && status == 'pendingforCoder') {
            setCurrentActionStatus(3);
            setModelFormRole(4);
        } else if (status && status == 'pendingForPaymentApproval') {
            setCurrentActionStatus(4);
            setModelFormRole(4);
        } else if (status && status == 'ApprovedForPayment') {
            setCurrentActionStatus(5);
            setModelFormRole(4);
        } else if (status && status == 'PaymentDone') {
            setCurrentActionStatus(6);
            setModelFormRole(4);
        } else if (status && status == 'void') {
            setCurrentActionStatus(7);
            setModelFormRole(4);
        } else if (status && status == 'Reject') {
            setCurrentActionStatus(8);
            setModelFormRole(4);
        } else if (status && status == 'ApprovedNoPayment') {
            setCurrentActionStatus(9);
            setModelFormRole(4);
        } else {
            setCurrentActionStatus(1);
            setModelFormRole(2);
        }
    };

    const loadCircularDataTable = () => {
        writeToLogFile("loadCircularDataTable() call");
        let queryStr = '';
        if (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL') {
            queryStr = '&provider_group=' + props.providerGroupSelect;
        }
        writeToLogFile("/get-awv-form-status?payment_year parameter " + props.yearSelect + ' organisation_id=1 lob= ' + props.lobSelect + ' ' + queryStr);
        AWVApi.get('/get-awv-form-status?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + queryStr)
            .then(res => {
                if (res.data) {
                    setCircularDataList(res?.data);
                    writeToLogFile("Data retrieved successfully...");
                }
                writeToLogFile("Error in retrieving data Response..." + res);
            })
            .catch(err => {
                console.log(err);
                writeToLogFile("Error in retrieving data... " + err );
            })
    }

    const handleAssignMeAction = (rowData, checked) => {
        writeToLogFile("handleAssignMeAction call, rowData:  " + rowData + ' checked: '+ checked );
        var dataTemp = {
            "awv_backend_id": btoa(String(aweMemberId)),
            "organisation_id": "1",
            "updated_by": isControlUser?.user_id,
            "payment_year": props.yearSelect,
            "lob": props.lobSelect,
            "assigned": checked,
            "provider_id": isControlUser?.user_id
        }
        writeToLogFile("dataTemp:  " + dataTemp );
        AWVApi.put('/awv_form_assign', dataTemp)
            .then(res => {
                if (res.data) {
                    if (checked && checked == 'unchecked') {
                        toast.success('Record unassigned');
                    } else {
                        toast.success('Record assigned');
                    }
                    loadMemberDetailsExtraData(cinMemberId, aweMemberId);
                }
            })
            .catch(err => {
                console.log(err);
            })
    };

    const fetchFormWithoutAttachmentCount = () => {
        writeToLogFile("fetchFormWithoutAttachmentCount...");
        let queryStr = '';
        if (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL') {
            queryStr = '&provider_group=' + props.providerGroupSelect;
        }
        let controlUser = JSON.parse(localStorage.getItem('controlUser'));
        let user_id = controlUser?.user_id;
        writeToLogFile("/get-count?payment_year API call, parameters" + ' ' + props.yearSelect + ' organisation_id=1 user_id= ' + user_id + ' lob=' + props.lobSelect + ' ' + queryStr);
        AWVApi.get('/get-count?payment_year=' + props.yearSelect + '&organisation_id=1&user_id=' + user_id + '&lob=' + props.lobSelect + queryStr)
            .then(res => {
                if (res.data) {
                    setFormWithoutAtachmentTotal(res?.data);
                    writeToLogFile("response received successfully...");
                }
            })
            .catch(err => {
                console.log(err);
                writeToLogFile("Error calling an api..." + err);
            })
    }

    const editActionData = (value, CinId, action = 'Edit') => {
        writeToLogFile("Inside editActionData ");
        props.currentAWERightSidebarType({ 'type': 'memberHccDetailsReport', 'cin_id': CinId, 'status': 0, 'awv_id': memberDetails?.AWE_ID, 'DX_code': value.DX_code, 'HCC_Code': value.HCC_Code, 'rxc_code': value.rxc_code, 'ndc_code': value.ndc_code, 'cpt_code': value.cpt_code, 'id': value.id });
        props.currentAWERightSidebar(true);
    }

    const coodinatorRecordColumns = [
        {
            name: "awe_source",
            label: "ACTION",
            options: {
                showColumn: true,
                filter: false,
                filterType: 'select',
                search: false,
                sort: false,
                width: '100px',
                otherColumnName: 'all',
                customBodyRender: (value, otherColumnValueArray = '', indexRow = '') => {
                    return (
                        memberExtraDetailsData && isControlUser && isControlUser.user_id && memberExtraDetailsData.user_assigned
                            && memberExtraDetailsData.user_assigned == isControlUser.user_id
                            && (memberExtraDetailsData?.form_status == 'pendingforCoordinator'
                                || memberExtraDetailsData?.form_status == 'pendingforCoder') ?
                            <div class="flex_item_cs gap10">
                                <Tooltip title="Edit" arrow placement="top">
                                    <div>
                                        <a role="button" className="hyperlink" style={{ cursor: "pointer", color: "#209f9d" }} onClick={() => editHCCDetails({ 'awe_source': value }, cinMemberId)}>
                                            <EditPenIcon className="w24 h24" />
                                        </a>
                                    </div>
                                </Tooltip>
                            </div>
                            :
                            <></>
                    );
                }
            }
        },
        {
            name: "awe_source",
            label: "AWE SOURCE",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '105px',
            }
        },
        {
            name: "receive_date",
            label: "RECEIVE DATE",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '105px',
                customBodyRender: (value) => {
                    return (
                        value && value != '' ?
                            <span >{Moment(value).format("MM/DD/YYYY")}</span>
                            :
                            <></>

                    );
                }
            }
        },
        {
            name: "received_method",
            label: "RECEIVED METHOD",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '120px',
            }
        },
        {
            name: "additional_data",
            label: "ADDITIONAL DATA",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '120px',
                customBodyRender: (value) => {
                    return (
                        <>
                            {value == '1' ?
                                <Tooltip title="Yes" arrow placement="top">
                                    <div className='status_badge badge_primary'>
                                        <CheckTikIcon className="w24 h24" />
                                    </div>
                                </Tooltip>
                                : value == '0' ?
                                    <Tooltip title="No" arrow placement="top">
                                        <div className='status_badge badge_danger'>
                                            <CloseLineIcon className="w24 h24" />
                                        </div>
                                    </Tooltip>
                                    :
                                    <></>
                            }
                        </>
                    );
                }
            }
        },
        {
            name: "telehelth_check",
            label: "CHECKBOX DONE VIA TELEHEALTH",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '165px',
                customBodyRender: (value) => {
                    return (
                        <>
                            {value == '1' ?
                                <Tooltip title="Yes" arrow placement="top">
                                    <div className='status_badge badge_primary'>
                                        <CheckTikIcon className="w24 h24" />
                                    </div>
                                </Tooltip>
                                : value == '0' ?
                                    <Tooltip title="No" arrow placement="top">
                                        <div className='status_badge badge_danger'>
                                            <CloseLineIcon className="w24 h24" />
                                        </div>
                                    </Tooltip>
                                    :
                                    <></>
                            }
                        </>
                    );
                }
            }
        },
        {
            name: "service_date",
            label: "SERVICE DATE",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '105px',
                customBodyRender: (value) => {
                    return (
                        value && value != '' ?
                            <span >{Moment(value).format("MM/DD/YYYY")}</span>
                            :
                            <></>
                    );
                }
            }
        },
        {
            name: "rendering_provider_npi",
            label: "RENDERING PROVIDER NPI  ",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '145px',
            }
        },
        {
            name: "rendering_provider_name",
            label: "RENDERING PROVIDER NAME",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '160px',
            }
        },
        {
            name: "site_id",
            label: "SITE ID",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '110px',
            }
        },
        {
            name: "ppg",
            label: "PPG",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '85px',
            }
        },
        {
            name: "remarks",
            label: "REMARKS",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '115px',
            }
        }
    ];

    const coderRecordColumns = [
        {
            name: "checklist_id",
            label: "ACTION",
            options: {
                showColumn: true,
                filter: false,
                filterType: 'select',
                search: false,
                sort: false,
                width: '202px',
                otherColumnName: 'all',
                customBodyRender: (value, otherColumnValueArray = '', indexRow = '') => {
                    return (
                        memberExtraDetailsData && isControlUser && isControlUser.user_id && memberExtraDetailsData.user_assigned && memberExtraDetailsData.user_assigned == isControlUser.user_id && (memberExtraDetailsData?.form_status == 'pendingforCoder' || memberExtraDetailsData?.form_status == 'pendingForPaymentApproval') ?
                            <div class="flex_item_cs gap10">
                                <Tooltip title="Edit" arrow placement="top">
                                    <div>
                                        <a role="button" className="hyperlink" style={{ cursor: "pointer", color: "#209f9d" }} onClick={() => editHCCDetails({ 'checklist_id': value }, cinMemberId)}>
                                            <EditPenIcon className="w24 h24" />
                                        </a>
                                    </div>
                                </Tooltip>
                            </div>
                            :
                            <></>
                    );
                }
            }
        },
        {
            name: "description",
            label: "AWE SOURCE",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '400px',
            }
        },
        {
            name: "status",
            label: "STATUS",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '115px',
                customBodyRender: (value) => {
                    return (
                        <>
                            {value == '1' ?
                                <Tooltip title="Confirmed" arrow placement="top">
                                    <div className='status_badge badge_primary'>
                                        <CheckTikIcon className="w24 h24" />
                                    </div>
                                </Tooltip>
                                : value == '0' ?
                                    <Tooltip title="Not Confirmed" arrow placement="top">
                                        <div className='status_badge badge_danger'>
                                            <CloseLineIcon className="w24 h24" />
                                        </div>
                                    </Tooltip>
                                    :
                                    <></>
                            }
                        </>
                    );
                }
            }
        },
        {
            name: "remark",
            label: "REMARKS",
            options: {
                showColumn: true,
                filter: false,
                filterType: '',
                search: false,
                sort: true,
                width: '200px',
            }
        }
    ];

    var hccCaptureColumns = []
    if (props.lobSelect == '2') {
        hccCaptureColumns = [
            {
                name: "dx_code",
                label: "Dx CODE",
                options: {
                    showColumn: true,
                    filter: false,
                    filterType: '',
                    search: false,
                    sort: true,
                    width: '100px',
                    otherColumnName: 'dx_description',
                    customBodyRender: (value, otherColumnValue = '') => {
                        return (
                            <Tooltip title={otherColumnValue ? `${value} - ${otherColumnValue}` : value} arrow placement="top">
                                <div className="table-short-text">
                                    {otherColumnValue ? `${value} - ${otherColumnValue}` : value}
                                </div>
                            </Tooltip>
                        );
                    }
                }
            },
            {
                name: "model_1",
                label: "HCC V24",
                cols: "2",
                options: {
                    showColumn: true,
                    filter: false,
                    filterType: '',
                    search: false,
                    sort: true,
                    width: '100px',
                    otherColumnName: 'model_1_description',
                    customBodyRender: (value, otherColumnValue = '') => {
                        return (
                            <>
                                {value && (value != 'No HCC' && value != '') ?
                                    <Tooltip title={otherColumnValue ? `${value} - ${otherColumnValue}` : value} arrow placement="top">
                                        <div className="table-short-text">
                                            {otherColumnValue ? `${value} - ${otherColumnValue}` : value}
                                        </div>
                                    </Tooltip>
                                    :
                                    <div>

                                    </div>
                                }
                            </>
                        );
                    }
                }
            },
            {
                name: "model_2",
                label: "HCC V28",
                cols: "2",
                options: {
                    showColumn: true,
                    filter: false,
                    filterType: '',
                    search: false,
                    sort: true,
                    width: '100px',
                    otherColumnName: 'model_2_description',
                    customBodyRender: (value, otherColumnValue = '') => {
                        return (
                            <>
                                {value && (value != 'No HCC' && value != '') ?

                                    <Tooltip title={otherColumnValue ? `${value} - ${otherColumnValue}` : value} arrow placement="top">
                                        <div className="table-short-text">
                                            {otherColumnValue ? `${value} - ${otherColumnValue}` : value}
                                        </div>
                                    </Tooltip>
                                    :
                                    <div>

                                    </div>
                                }
                            </>
                        );
                    }
                }
            },
            {
                name: "nlp_captured",
                label: "NLP Reported",
                cols: "2",
                options: {
                    showColumn: true,
                    filter: false,
                    filterType: '',
                    search: false,
                    sort: true,
                    width: '100px',
                    otherColumnName: '',
                    customBodyRender: (value, otherColumnValue = '') => {
                        return (
                            <>
                                {value && value != '' ?
                                    <Tooltip title={value == 'Y' ? 'Yes' : 'No'} arrow placement="top">
                                        <div className="table-short-text">
                                            {value == 'Y' ? 'Yes' : 'No'}
                                        </div>
                                    </Tooltip>
                                    :
                                    <div>

                                    </div>
                                }
                            </>
                        );
                    }
                }
            },
            {
                name: (state.chart_chase_record && state.chart_chase_record == null ? location.state?.chart_chase_record :state.chart_chase_record) && (state.chart_chase_record && state.chart_chase_record == null ? location.state?.chart_chase_record :state.chart_chase_record) == 'YES' ? 'claim_matched' : "historical",
                label: (state.chart_chase_record && state.chart_chase_record == null ? location.state?.chart_chase_record :state.chart_chase_record)  && (state.chart_chase_record && state.chart_chase_record == null ? location.state?.chart_chase_record :state.chart_chase_record)  == 'YES' ? 'is claim matched' : "Historic",
                cols: "2",
                options: {
                    showColumn: true,
                    filter: false,
                    filterType: '',
                    search: false,
                    sort: true,
                    width: '100px',
                    otherColumnName: '',
                    customBodyRender: (value, otherColumnValue = '') => {
                        return (
                            <>
                                {value && value != '' ?
                                    <Tooltip title={value == 'Y' ? 'Yes' : 'No'} arrow placement="top">
                                        <div className="table-short-text">
                                            {value == 'Y' ? 'Yes' : 'No'}
                                        </div>
                                    </Tooltip>
                                    :
                                    <div>

                                    </div>
                                }
                            </>
                        );
                    }
                }
            },
            {
                name: "present_in_form",
                label: "Printed in AWE Form",
                cols: "2",
                options: {
                    showColumn: true,
                    filter: false,
                    filterType: '',
                    search: false,
                    sort: true,
                    width: '100px',
                    otherColumnName: '',
                    customBodyRender: (value, otherColumnValue = '') => {
                        return (
                            <>
                            
                                <Tooltip title={String(value) !== "" ? (String(value) === '0' ? 'No' : 'Yes') : ''} arrow placement="top">
                                    <div className="table-short-text">
                                        {/* {alert(String(value))} */}
                                        {String(value) !== "" ? (String(value) === '0' ? 'No' : 'Yes') : ''}

                                    </div>
                                </Tooltip>
                            
                        </>
                        );
                    }
                }
            },
            {
                name: "STATUS",
                label: "Coder Action",
                cols: "2",
                options: {
                    showColumn: true,
                    filter: false,
                    filterType: '',
                    search: false,
                    sort: false,
                    width: '100px',
                    otherColumnName: '',
                    customBodyRender: (value, otherColumnValue = '') => {
                        return (
                            <>
                                {value == 'Rejected' ? 'Rejected' : ''}
                                {value == 'Accepted' ? 'Accepted' : ''}
                                {value == 'Pending for clarification' ? 'Pending for Clarification' : ''}
                            </>
                        );
                    }
                }
            },
        ];
    } else {
        hccCaptureColumns = [
            {
                name: "dx_code",
                label: "Dx CODE",
                options: {
                    showColumn: true,
                    filter: false,
                    filterType: '',
                    search: false,
                    sort: true,
                    width: '100px',
                    otherColumnName: 'dx_description',
                    customBodyRender: (value, otherColumnValue = '') => {
                        return (
                            <Tooltip title={otherColumnValue ? `${value} - ${otherColumnValue}` : value} arrow placement="top">
                                <div className="table-short-text">
                                    {otherColumnValue ? `${value} - ${otherColumnValue}` : value}
                                </div>
                            </Tooltip>
                        );
                    }
                }
            },
            {
                name: "model_1",
                label: "HCC",
                cols: "2",
                options: {
                    showColumn: true,
                    filter: false,
                    filterType: '',
                    search: false,
                    sort: true,
                    width: '100px',
                    otherColumnName: 'model_1_description',
                    customBodyRender: (value, otherColumnValue = '') => {
                        return (
                            <>
                                {value && (value != 'No HCC' && value != '') ?
                                    <Tooltip title={otherColumnValue ? `${value} - ${otherColumnValue}` : value} arrow placement="top">
                                        <div className="table-short-text">
                                            {otherColumnValue ? `${value} - ${otherColumnValue}` : value}
                                        </div>
                                    </Tooltip>
                                    :
                                    <div>

                                    </div>
                                }
                            </>
                        );
                    }
                }
            },
            {
                name: "nlp_captured",
                label: "NLP Reported",
                cols: "2",
                options: {
                    showColumn: true,
                    filter: false,
                    filterType: '',
                    search: false,
                    sort: true,
                    width: '100px',
                    otherColumnName: '',
                    customBodyRender: (value, otherColumnValue = '') => {
                        return (
                            <>
                                {value && value != '' ?
                                    <Tooltip title={value == 'Y' ? 'Yes' : 'No'} arrow placement="top">
                                        <div className="table-short-text">
                                            {value == 'Y' ? 'Yes' : 'No'}
                                        </div>
                                    </Tooltip>
                                    :
                                    <div>

                                    </div>
                                }
                            </>
                        );
                    }
                }
            },
            {
                name: (state.chart_chase_record && state.chart_chase_record == null ? location.state?.chart_chase_record :state.chart_chase_record) && (state.chart_chase_record && state.chart_chase_record == null ? location.state?.chart_chase_record :state.chart_chase_record) == 'YES' ? 'claim_matched' : "historical",
                label: (state.chart_chase_record && state.chart_chase_record == null ? location.state?.chart_chase_record :state.chart_chase_record) && (state.chart_chase_record && state.chart_chase_record == null ? location.state?.chart_chase_record :state.chart_chase_record) == 'YES' ? 'is claim matched' : "Historic",
                cols: "2",
                options: {
                    showColumn: true,
                    filter: false,
                    filterType: '',
                    search: false,
                    sort: true,
                    width: '100px',
                    otherColumnName: '',
                    customBodyRender: (value, otherColumnValue = '') => {
                        return (
                            <>
                                {value && value != '' ?
                                    <Tooltip title={value == 'Y' ? 'Yes' : 'No'} arrow placement="top">
                                        <div className="table-short-text">
                                            {value == 'Y' ? 'Yes' : 'No'}
                                        </div>
                                    </Tooltip>
                                    :
                                    <div>

                                    </div>
                                }
                            </>
                        );
                    }
                }
            },
            {
                name: "present_in_form",
                label: "Printed in AWE Form",
                cols: "2",
                options: {
                    showColumn: true,
                    filter: false,
                    filterType: '',
                    search: false,
                    sort: true,
                    width: '100px',
                    otherColumnName: '',
                    customBodyRender: (value, otherColumnValue = '') => {
                        return (
                            <>
                            
                                <Tooltip title={String(value) !== "" ? (String(value) === '0' ? 'No' : 'Yes') : ''} arrow placement="top">
                                    <div className="table-short-text">
                                        {/* {alert(String(value))} */}
                                        {String(value) !== "" ? (String(value) === '0' ? 'No' : 'Yes') : ''}

                                    </div>
                                </Tooltip>
                            
                        </>
                        );
                    }
                }
            },
            {
                name: "STATUS",
                label: "Coder Action",
                cols: "2",
                options: {
                    showColumn: true,
                    filter: false,
                    filterType: '',
                    search: false,
                    sort: false,
                    width: '100px',
                    otherColumnName: '',
                    customBodyRender: (value, otherColumnValue = '') => {
                        return (
                            <>
                                {value == 'Rejected' ? 'Rejected' : ''}
                                {value == 'Accepted' ? 'Accepted' : ''}
                                {value == 'Pending for clarification' ? 'Pending for Clarification' : ''}
                            </>
                        );
                    }
                }
            },
        ];
    }
    const loadMemberDetailsReport = (CinId, awe_display_id) => {
        CinId = btoa(CinId)
        awe_display_id = btoa(awe_display_id)
        writeToLogFile("loadMemberDetailsReport() call ");
        writeToLogFile("/get-member-detail-drilldown?lob API callm parameters: " + props.lobSelect + ' year= ' + props.yearSelect + ' cin= ' + CinId + ' organisation_id=1 '+ ' awe_display_id= '+awe_display_id);
        AWVApi.get('/get-member-detail-drilldown?lob=' + props.lobSelect + '&year=' + props.yearSelect + '&cin=' + btoa(CinId) + '&organisation_id=1'+ '&awe_display_id='+btoa(awe_display_id))
            .then(res => {
                setMemberDetails(res.data[0]);
                setAweMemberId(res.data[0]?.AWE_ID);
                loadMemberDetailsExtraData(CinId, res.data[0]?.AWE_ID);
                writeToLogFile("Received response successfully...");
            })
            .catch(err => {
                console.log(err)
                writeToLogFile("Error  in response: " + err);
            })
    }

    const loadMemberDataHCCCapture = (CinId, awe_display_id) => {
        CinId = btoa(CinId)
        awe_display_id = btoa(awe_display_id)
        writeToLogFile("loadMemberDataHCCCapture() call");
        writeToLogFile('/get-member-hcc-summary?cin api call, parameters: ' + CinId + ' year= ' + props?.yearSelect + ' lob= ' + props?.lobSelect + ' organisation_id=1');
        AWVApi.get('/get-member-hcc-summary?cin=' + btoa(CinId) + '&year=' + props?.yearSelect + '&lob=' + props?.lobSelect + '&organisation_id=1' + '&awe_display_id='+btoa(awe_display_id))
            .then(res => {
                setHCCUserDetails(res.data);
                writeToLogFile("Received response successfully...");
            })
            .catch(err => {
                console.log(err)
                writeToLogFile("Error  in response: " + err);
            })

    }

    const loadMemberDetailsExtraData = (CinId, AweId) => {
        CinId = btoa(CinId)
        AweId = btoa(AweId)
        writeToLogFile("loadMemberDetailsExtraData() call... " );
        AWVApi.get('/get-all-awv-record?payment_year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + '&formAttached=0&form_attached=&provider_group=&iSortCol_0=cin&sSortDir_0=asc&iDisplayStart=0&iDisplayLength=10&ColumnSearch=cin="' + btoa(CinId) + '",awv_id="' + btoa(AweId) + '"')
            .then(res => {
                if (res.data && res.data.length > 0) {
                    setMemberExtraDetailsData(res.data[0]);
                    writeToLogFile("/get-all-awv-record, data retrieved successfully..." );
                } else {
                    setMemberExtraDetailsData({});
                    writeToLogFile("res data not present" );
                }
            })
            .catch(err => {
                console.log(err)
                writeToLogFile("Error occurred while retrieving data. Error=  " + err);
            })

    }

    const awvCoderWorkbench = () => {
        writeToLogFile("awvCoderWorkbench() call...");
        history.push('/new_awe/awe-coder-workbench');
    }

    const handleOnclickUrl = () => {
        writeToLogFile("handleOnclickUrl() call...");
        if (params.get('cin') && params.get('cin') != '') {
            history.push('/new_awe/awe-record');
            handleMemberIdSet('');
            writeToLogFile(" cin :" + params.get('cin'));
        } else {
            setShowCinDetails(false);
            handleMemberIdSet('');
        }
    }

    useEffect(() => {
        loadHCCDataTable()
    }, [props.yearSelect])

    const loadHCCDataTable = () => {
        writeToLogFile("loadHCCDataTable() call...");
        AWVCoderWorkBench.get('/data/hcc_v28_list')
            .then(res => {
                if(res.data){
                localStorage.setItem('hcc_v28_list', JSON.stringify(res.data));
                writeToLogFile("/data/hcc_v28_list call...");
                }
            })
            .catch(err => {
                console.log(err)
                writeToLogFile("Error occurred while retrieving data[hcc_v28_list]. Error=  " + err);
            })
    }
    var providerGroupSelectVar = (props.providerGroupSelect != 0 && props.providerGroupSelect != 'ALL') ? props.providerGroupSelect : '';
    let controlUser = JSON.parse(localStorage.getItem('controlUser'));

    const lobMap = {
        "1": 'aca',
        "2": 'medicare',
        "3": 'medicaid',
    }

    useEffect(() => {
        // Generate and download log file when logMessages have been updated
        if (logMessages.length > 0  && generateLog === true) {
        LogFileUploadToS3(logMessages,  localStorage.getItem("access_key"), localStorage.getItem("secret_key"), process.env.REACT_APP_LOG_BUCKET, "Reporting_Analytics_DrillDown_Page")
        }
    }, [generateLog === true, logMessages, cinMemberId, props.yearSelect, props.providerGroupSelect, props.lobSelect]); 

    return (
        <>
            <div className="quick_links_menu mb-3">
                <ul className='flex_item_cs flex-wrap'>
                    <li><p className="fw700 fs14 text-light-dark-800">Quick Links</p></li>
                    <li>
                        <a href="#" className="text-primary">
                            <DownloadFileFromS3
                                name={"Provider Master Report"}
                                bucketName={process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET}
                                bucketKey={lobMap[String(props.lobSelect)] + '/' + props?.yearSelect + '/' + process.env.REACT_APP_PATH_PROVIDER_MASTER_REPORT }
                                isQuickLink={true}
                            />
                        </a>
                    </li>
                    <li>
                        <a href="#" className="text-primary">
                            <DownloadFileFromS3
                                name={"Monthly Completion Summary"}
                                bucketName={process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET}
                                bucketKey={lobMap[String(props.lobSelect)] + '/' + props?.yearSelect + '/' + process.env.REACT_APP_PATH_PROVIDER_ADDRESS_GROUP_REPORT}
                                isQuickLink={true}
                            />
                        </a>
                    </li>
                    <li>
                        <a href="#" className="text-primary">
                            <DownloadFileFromS3
                                name={"Monthly Payment Report"}
                                bucketName={process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET}
                                bucketKey={lobMap[String(props.lobSelect)] + '/' + props?.yearSelect + '/' + process.env.REACT_APP_PATH_MONTHLY_PAYMENT_REPORT}
                                isQuickLink={true}
                            />
                        </a>
                    </li>
                    <li>
                        <a href="#" className="text-primary">
                            <DownloadFileFromS3
                                name={"New Members AWE Forms"}
                                bucketName={process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET}
                                bucketKey={lobMap[String(props.lobSelect)] + '/' + props?.yearSelect + '/' +  process.env.REACT_APP_PATH_SAMPLE_AWV_FORM}
                                isQuickLink={true}
                            />
                        </a>
                    </li>
                </ul>
            </div>

            <SubHeaderFilter
                className={"mb-4"}
                title={'Provider Address Grouping Filter'}
                setZipSelectValue={setZipSelect}
                setsiteIdSelectValue={setsiteIdSelect}
                setProviderAddressGroupsSelectValue={setProviderAddressGroupsSelect}
                setProviderAddressGroupsNameSelectValue={setProviderAddressGroupsNameSelect}
                setCitySelectValue={setCitySelect}
                setSiteAddressSelectValue={setSiteAddressSelect}
            />
            
            {cinMemberId && cinMemberId != '' && showCinDetails ?
                <>
                    <div className="flex_item_cb mb-4 flex-wrap">
                        <h6 className="fw700 fs14 flex-shrink-0  text-light-dark-800">Member Report</h6>
                        <div className="flex_item_cb gap8">
                            <Tooltip arrow placement="top" title={"Assign Me"} >
                                    <span>
                                        <FormControlLabel className="custom_checkbox m-0 gap8"
                                            label={<span className="fs14 fw700 flex_item_cc gap8">
                                                Assigned to me {(memberExtraDetailsData?.assignedUserName && memberExtraDetailsData?.assignedUserName != '') ? '(Assigned To : ' + memberExtraDetailsData?.assignedUserName + ')' : ''}
                                            </span>}
                                            control={
                                                <Checkbox
                                                    className="p-0"
                                                    id={'checkbox11'}
                                                    key={memberExtraDetailsData?.cin}
                                                    checked={(memberExtraDetailsData?.user_assigned && memberExtraDetailsData?.user_assigned != '') ? true : false}
                                                    onClick={() => handleAssignMeAction(memberExtraDetailsData, (memberExtraDetailsData?.user_assigned && memberExtraDetailsData?.user_assigned != '') ? 'unchecked' : 'checked')}
                                                />
                                            }
    
                                        />
                                    </span>
                            </Tooltip>

                            <Tooltip arrow placement="top" title={"View"}>
                                <span className=''>
                                    <IconButton className={"btn-icon btn-sm "} type="button" buttonType={"default"} onClick={() => notificationRightDrawer(true, 'viewAweFileUpload', memberExtraDetailsData?.awv_backend_id)}>
                                        <EyeIcon className="w20 h20" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip arrow placement="top" title={"Additional Attachment"} >
                                <span className=''>
                                    <IconButton className={"btn-icon btn-sm "} type="button" buttonType={"default"} onClick={() => notificationRightDrawer(true, 'additionalAttachments', memberExtraDetailsData?.awv_backend_id, '', memberExtraDetailsData?.cin)}>
                                        <PinIcon className="w20 h20" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip arrow placement="top" title={"Timeline"} >
                                <span className=''>
                                    <IconButton className={"btn-icon btn-sm "} type="button" buttonType={"default"} onClick={() => notificationRightDrawer(true, 'timeline', memberExtraDetailsData?.awv_backend_id, '', memberExtraDetailsData?.cin)}>
                                        <ListBoxIcon className="w20 h20" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip arrow placement="top" title={"Quick notes"} >
                                <span className=''>
                                    <IconButton className={"btn-icon btn-sm "} type="button" buttonType={"default"} onClick={() => notificationRightDrawer(true, 'quickNotes', memberExtraDetailsData?.awv_backend_id, '', memberExtraDetailsData?.cin)}>
                                        <QuickNoteIcon className="w20 h20" />
                                    </IconButton>
                                </span>
                            </Tooltip>
{memberExtraDetailsData && memberExtraDetailsData?.user_assigned && controlUser?.user_id && memberExtraDetailsData?.user_assigned == controlUser?.user_id && memberExtraDetailsData?.form_status && memberExtraDetailsData?.form_status != 'PaymentDone' && memberExtraDetailsData?.form_status != 'void' ?
                                 <Tooltip arrow placement="top" title={"Action"} >
                                     <span className=''>
                                         <IconButton
                                             className={"btn-icon btn-sm "}
                                             type="button"
                                             buttonType={"default"}
                                             aria-label="more"
                                             id="long-button"
                                             name={memberExtraDetailsData?.awv_backend_id}
                                             aria-controls={openActionAssign ? 'long-menu' : undefined}
                                             aria-expanded={openActionAssign ? 'true' : undefined}
                                             aria-haspopup="true"
                                             onClick={(event) => handleClickAction(event, memberExtraDetailsData?.awv_backend_id, memberExtraDetailsData?.form_status, memberExtraDetailsData?.user_assigned, memberExtraDetailsData?.awe_display_id, memberExtraDetailsData?.on_hold, true)}
                                         >
                                             <DotsMenuIcon className="w20 h20" />
                                         </IconButton>
                                         <Menu
                                             id="long-menu-member"
                                             MenuListProps={{
                                                 'aria-labelledby': 'long-button',
                                             }}
                                             anchorEl={anchorElAssign}
                                             open={openActionAssign}
                                             onClose={() => handleCloseAction(true)}
                                             PaperProps={{
                                                 style: {
                                                     maxHeight: 45 * 4.5,
                                                     width: '25ch',
                                                 },
                                             }}
                                         >
                                             {(currentActionStatus != '0' && currentActionStatus != '1' && currentActionStatus != '8' && currentActionStatus != '6' && currentActionStatus != '7' && currentActionStatus != '5' && currentActionStatus != '9') ?
                                                 <MenuItem key={memberExtraDetailsData?.awe_display_id} onClick={() => notificationRightDrawer(true, 'statusAweFileUpload', currentActionAWVID, (currentActionStatus + 1))}>
                                                     <CheckCircleIcon className="mr-3" />{'Approve'}
                                                 </MenuItem>
                                                 : currentActionStatus == '8' ?
                                                     <MenuItem key={memberExtraDetailsData?.awe_display_id} onClick={() => notificationRightDrawer(true, 'statusAweFileUpload', currentActionAWVID, (2 + 1))}>
                                                         <CheckCircleIcon className="mr-3" />{'Approve'}
                                                     </MenuItem>
                                                     :
                                                     <></>
                                             }
 
                                             {(currentActionStatus != '0' && currentActionStatus != '1' && currentActionStatus != '5' && currentActionStatus != '6' && currentActionStatus != '7' && currentActionStatus != '8' && currentActionStatus != '9') ?
                                                 <MenuItem key={memberExtraDetailsData?.awe_display_id} onClick={() => notificationRightDrawer(true, 'statusAweFileUpload', currentActionAWVID, 2)}>
                                                     <CancelIcon className="mr-3" />{'Reject'}
                                                 </MenuItem>
                                                 :
                                                 <></>
                                             }
                                             {/* {(currentActionStatus != '0' && currentActionStatus != '1' && currentActionStatus != '8') ? */}
                                             {/* removing check that restricting onhold/unhold option display at reject status */}
                                             {/* hide on-hold from PFPA stage */}
                                             {(currentActionStatus != '0' && currentActionStatus != '1' && currentActionStatus != '4' && currentActionStatus != '5' && currentActionStatus != '9') ?
                                                 <MenuItem key={memberExtraDetailsData?.awe_display_id} onClick={() => notificationRightDrawer(true, 'onHoldAweFileUpload', currentActionAWVID, 2)}>
                                                     <CancelIcon className="mr-3" />{(selectOnHoldStatus && selectOnHoldStatus == "Y") ? 'Un Hold' : 'On Hold'}
                                                 </MenuItem>
                                                 :
                                                 <></>
                                             }
                                             {(controlUser && currentActionUserId == controlUser.user_id && (currentActionStatus == '0' || currentActionStatus == '1' || currentActionStatus == '8')) ?
                                                 <MenuItem key={memberExtraDetailsData?.awe_display_id} onClick={() => notificationRightDrawer(true, 'addAweFileUpload', currentActionAWVID)}>
                                                     <ModeEditOutlineIcon className="mr-3" />{'Edit'}
                                                 </MenuItem>
                                                 :
                                                 <></>
                                             }
                                             {/* stage change dropdown */}
                                             {(currentActionStatus != '0' && currentActionStatus != '1' && currentActionStatus != '6' && currentActionStatus != '7' && currentActionStatus != '2' && (currentActionStatus != '2' && currentActionStatus != '8')) ?
                                                 <MenuItem key={memberExtraDetailsData?.awe_display_id} onClick={() => notificationRightDrawer(true, 'stageChange', currentActionAWVID, (currentActionStatus))}>
                                                     <ArrowCircleLeftRoundedIcon className="mr-3" />{'Stage Change'}
                                                 </MenuItem>
                                                 :
                                                 <></>
                                             }
                                             {(currentActionStatus != '0' && currentActionStatus != '1' && currentActionStatus != '5' && currentActionStatus != '6' && currentActionStatus != '7' && currentActionStatus != '9') ?
                                                 <MenuItem key={memberExtraDetailsData?.awe_display_id} onClick={() => notificationRightDrawer(true, 'statusAweFileUpload', currentActionAWVID, 7)}>
                                                     <DeleteRoundedIcon className="mr-3" />{'Mark as Void'}
                                                 </MenuItem>
                                                 :
                                                 <></>
                                             }
                                         </Menu>
                                     </span>
                                 </Tooltip>
                                 :
                                 <></>
	                             }
                            <Tooltip arrow placement="top" title={"Back"} >
                                <span>
                                    <IconButton className={"btn-icon btn-sm "} type="button" buttonType={"default"} onClick={() => handleOnclickUrl()}>
                                        <MultipleForwardLeftIcon className="w20 h20" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 mb-4">
                            <div className="card border p-3 p-lg-4">
                                <div className="row no-gutters mb-2 col_border_left">
                                    <div className="items col mb-1">
                                        <div className="coder_word_card">
                                            <h6>AWE ID</h6>
                                            <p>{memberDetails?.awe_display_id}</p>
                                        </div>
                                    </div>
                                    <div className="items col mb-1">
                                        <div className="coder_word_card">
                                            <h6>CIN/RXID</h6>
                                            <p>{memberDetails?.CIN}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row no-gutters mb-2 col_border_left">
                                    <div className="items col mb-1">
                                        <div className="coder_word_card">
                                            <h6>PCP Effective Date</h6>
                                            <p>{memberDetails?.Date_of_Service ? Moment(memberDetails?.Date_of_Service).format("YYYY-MM-DD") : ''}</p>
                                        </div>
                                    </div>
                                    <div className="items col mb-1">
                                        <div className="coder_word_card">
                                            <h6>Primary Provider</h6>
                                            <p>{memberDetails?.Rendring_Provider}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row no-gutters mb-2 col_border_left">
                                    <div className="items col mb-1">
                                        <div className="coder_word_card">
                                            <h6>Service Provider Group</h6>
                                            <p>{memberDetails?.Service_Provider_Group}</p>
                                        </div>
                                    </div>
                                    <div className="items col mb-1">
                                        <div className="coder_word_card">
                                            <h6>Pay To Provider Group</h6>
                                            <p>{memberDetails?.Pay_To_Provider_Group}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row no-gutters mb-2 col_border_left">
                                    <div className="items col mb-1">
                                        <div className="coder_word_card">
                                            <h6>Member Address</h6>
                                            <p>{memberDetails?.Member_Address2 ? memberDetails?.Member_Address + memberDetails?.Member_Address2 : memberDetails?.Member_Address}</p>
                                        </div>
                                    </div>
                                    <div className="items col mb-1">
                                        <div className="coder_word_card">
                                            <h6>Site ID</h6>
                                            <p>{memberDetails?.Site_ID}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row no-gutters mb-2 col_border_left">
                                    <div className="items col mb-1">
                                        <div className="coder_word_card">
                                            <h6>DOB(Age)</h6>
                                            <p>
                                                {memberDetails?.BIRTH_DATE ?
                                                    <>{memberDetails?.BIRTH_DATE ? Moment(memberDetails?.BIRTH_DATE).format("YYYY-MM-DD") : ""}({calculateAge(memberDetails?.BIRTH_DATE)})</>
                                                    :
                                                    <></>
                                                }
                                            </p>
                                        </div>
                                    </div>
                                    <div className="items col mb-1">
                                        <div className="coder_word_card">
                                            <h6>Member Name</h6>
                                            <p>{memberDetails?.member_name}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 mb-4">
                            <div className="card border p-3 p-lg-4">
                                <div class="card_header flex_item_cb mb24">
                                    <h2 class="fs18 card-title text-dark">Member Dx Recapture Summary</h2>
                                </div>
                                <div className="card-body p-0">
                                    <div className="row align-items-center no-gutters">
                                        <div className="col-sm-7">
                                            <div className="justify-content-center text-center" style={{ width: '210px' }}>
                                                <CircularProgressbarWithChildren
                                                    value={(HCCUserDetails?.Total_Confirmed) ? HCCUserDetails?.Total_Confirmed : 0}
                                                    strokeWidth={10}
                                                    styles={buildStyles({
                                                        pathColor: `#1D9A9B`,
                                                    })}
                                                >
                                                    <div >
                                                        <strong style={{ fontSize: 30, marginTop: 0, color: '#000' }}>{(HCCUserDetails?.Total_Confirmed) ? HCCUserDetails?.Total_Confirmed : 0}</strong> <br />
                                                        <strong style={{ fontSize: 15, marginTop: 5, color: '#000' }}>Completed</strong>
                                                    </div>
                                                </CircularProgressbarWithChildren >
                                                <div className="circular-content mt-3 ml-3 row">
                                                    <strong style={{ fontSize: 12, color: '#000' }}>
                                                        <div style={{ height: '13px', width: '20px', backgroundColor: '#1D9A9B', position: 'absolute' }}></div>
                                                        <div className="ml-4">Completed</div>
                                                    </strong>
                                                    <strong className="ml-4" style={{ fontSize: 12, color: '#000' }}>
                                                        <div style={{ height: '13px', width: '20px', backgroundColor: '#D6D6D6', position: 'absolute' }}></div>
                                                        <div className="ml-4">Pending</div>
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-5 border-left">
                                            <div className="overview_chart_item_text p-3 border-bottom" style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                                <h6 className="fs18">{((HCCUserDetails?.Total_Expected) ? HCCUserDetails?.Total_Expected : 0).toFixed(0)}</h6>
                                                <p className="fs14 fw700 text-dark">Expected Dx</p>
                                            </div>
                                            <div className="overview_chart_item_text p-3 border-bottom" style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                                <h6 className="fs18 text-primary">{((HCCUserDetails?.Total_Confirmed) ? HCCUserDetails?.Total_Confirmed : 0).toFixed(0)}</h6>
                                                <p className="fs14 fw700 text-dark">Confirmed Dx</p>
                                            </div>
                                            <div className="overview_chart_item_text p-3 border-bottom" style={{ borderBottom: "2px solid rgba(0, 0, 0, 0.12)" }}>
                                                <h6 className="fs18 text-primary">{((HCCUserDetails?.New_Dx) ? HCCUserDetails?.New_Dx : 0).toFixed(0)}</h6>
                                                <p className="fs14 fw700 text-dark">New Dx</p>
                                            </div>
                                            <div className="overview_chart_item_text p-3" >
                                                <h6 className="fs18 text-warning-700">{((HCCUserDetails?.Capture_Rate) ? HCCUserDetails?.Capture_Rate : 0).toFixed(0)}%</h6>
                                                <p className="fs14 fw700 text-dark">Dx Recapture Rate</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {memberExtraDetailsData && memberExtraDetailsData?.form_status && (memberExtraDetailsData?.form_status != 'PendingUpload') ?
                            <div className="col-lg-12 mb-4 mt-3">
                                <CustomAweDatatable
                                    titleName="COORDINATOR CHECKLIST"
                                    downloadFileName=""
                                    UrlAxios={'AWVApi'}
                                    urlLink={'/getawecoordinatorchecklist?cin=' + btoa(cinMemberId) + '&awe_id=' + btoa(aweMemberId) + '&year=' + props?.yearSelect + '&lob=' + props?.lobSelect + '&organisation_id=1'}
                                    columns={coodinatorRecordColumns}
                                    showColumnBtn={false}
                                    searchBox={false}
                                    downloadBtn={false}
                                    pagination={false}
                                    filterBox={false}
                                    colSpan={12}
                                />
                            </div>
                            :
                            <></>
                        }
                        {memberExtraDetailsData && memberExtraDetailsData?.form_status && (memberExtraDetailsData?.form_status != 'PendingUpload' && memberExtraDetailsData?.form_status != 'pendingforCoordinator') ?
                            <>
                                <div className="mb-4 mt-3 col-lg-12">
                                    <CustomAweDatatable
                                        titleName="CODER CHECKLIST"
                                        buttonWrap={<>{((memberExtraDetailsData?.form_status == 'pendingforCoder' || memberExtraDetailsData?.form_status == 'pendingForPaymentApproval') && isControlUser && isControlUser.user_id && memberExtraDetailsData.user_assigned && memberExtraDetailsData.user_assigned == isControlUser.user_id) ?
                                            <Button className="mr-3" onClick={() => editHCCDetails('', cinMemberId, 'MultiEdit')} style={{ position: 'relative', color: 'white', padding: '7px 25px', backgroundColor: "rgb(46 147 147)" }} >
                                                Edit All
                                            </Button>
                                            :
                                            <></>
                                        }
                                            {/* <NavLink to={{
                                                pathname: '/new_awe/awe-coder-workbench',
                                                search: '?cin=' + memberDetails?.CIN,
                                                state: {
                                                    awe_id: aweMemberId,
                                                    year:  props?.yearSelect ,
                                                    lob: props?.lobSelect
                                                }
                                            }}>
                                                <Button onclick={() => awvCoderWorkbench()} style={{ position: 'relative', color: 'white', padding: '7px 25px', backgroundColor: "rgb(46 147 147)" }} >
                                                    Coder Workbench
                                                </Button>
                                            </NavLink> */}
                                            </>
                                            }
                                        downloadFileName=""
                                        UrlAxios={'AWVApi'}
                                        urlLink={'/getawecoderchecklist?cin=' + btoa(cinMemberId) + '&awe_id=' + btoa(aweMemberId) + '&year=' + props?.yearSelect + '&lob=' + props?.lobSelect + '&organisation_id=1'}
                                        columns={coderRecordColumns}
                                        showColumnBtn={false}
                                        searchBox={false}
                                        downloadBtn={false}
                                        pagination={true}
                                        filterBox={false}
                                        colSpan={12}
                                    />
                                </div>
                            </>
                            :
                            <></>
                        }
                        <div className="col-lg-12 mt-3 mb-4">
                            <CustomAweDatatable
                                titleName="Reconciliation Summary"
                                buttonWrap={
                                    <div className='flex_item_ce gap8'>
                                        <span>
                                            <AWVNLPInsightPDF form_url={memberExtraDetailsData?.form_url} cin={cinMemberId} aweMemberId={location.state?.awe_display_id} position={'relative'} />
                                        </span>
                                            <NavLink to={{
                                                pathname: '/new_awe/awe-coder-workbench',
                                                search: '?#' ,
                                                state: {
                                                    cin: cinMemberId,
                                                    awv_backend_id: aweMemberId,
                                                    year: props?.yearSelect,
                                                    lob: props?.lobSelect,
                                                    awe_display_id: location.state?.awe_display_id,
                                                    chart_chase_record: state.chart_chase_record,
                                                    is_cw_present: localStorage.getItem('isRecordPresent'),
                                                    cw_id: localStorage.getItem('cwId')
                                                
                                                }
                                            }}>
                                            <Button
                                                onClick={() => awvCoderWorkbench()}
                                                style={{
                                                    position: 'relative',
                                                    color: 'white',
                                                    padding: '8px 25px',
                                                    backgroundColor: isLoading ? "rgb(46 147 147)" : "rgb(46 147 147)", 
                                                    opacity: isLoading ? 0.6 : 1, 
                                                    cursor: isLoading ? 'not-allowed' : 'pointer' 
                                                }}
                                                disabled={isLoading}
                                            >
                                                <div className="button-content">
                                                    {isLoading ? (
                                                        <>
                                                            <span>coder workbench</span> &nbsp;

                                                            <div className="spinner"></div>
                                                        </>
                                                    ) : (
                                                        'coder workbench'
                                                    )}
                                                </div>
                                                </Button>
                                            </NavLink>
                                            {/* :
                                            <></>
                                        } */}
                                    </div>
                                }
                                downloadFileName=""
                                UrlAxios="AWVApi"
                                urlLink={"/get_member_hcc_details?cin=" + btoa(cinMemberId) + '&year=' + props?.yearSelect + '&lob=' + props?.lobSelect + '&organisation_id=1' + '&chart_record=' + (state.chart_chase_record && state.chart_chase_record == null ? (state.chart_chase_record && state.chart_chase_record == null ? location.state?.chart_chase_record :state.chart_chase_record) :state.chart_chase_record) + '&awe_display_id=' + btoa(location.state?.awe_display_id)}
                                columns={hccCaptureColumns}
                                showColumnBtn={false}
                                searchBox={false}
                                downloadBtn={false}
                                pagination={true}
                                filterBox={false}
                                colSpan={12}
                            />
                        </div>
                    </div>
                </>
                :
                <CustomAweServerSideDatatable
                    titleName="AWE RECORD DETAILS"
                    downloadFileName="awe_recod_details"
                    urlLink={`/get-awv-member-details?year=${props.yearSelect}&organisation_id=1&lob=${props.lobSelect}&provider_group=${(providerGroupSelectVar && providerGroupSelectVar != '') ? providerGroupSelectVar : ''}&address_grouping=${(providerAddressGroupsNameSelect && providerAddressGroupsNameSelect != '') ? providerAddressGroupsNameSelect : ''}&site_zip=${(zipSelect && zipSelect != '') ? zipSelect : ''}&site_city=${(citySelect && citySelect != '') ? citySelect : ''}&site_address=${(siteAddressSelect && siteAddressSelect != '') ? siteAddressSelect : ''}&site_id=${(siteIdSelect && siteIdSelect != '') ? siteIdSelect : ''}&`}
                    columns={aweRecordColumns}
                    subColumns={aweRecordSubColumns}
                    expandedRows={expandedRows}
                    expandedRowsReset={expandedRowsReset}
                    editActionData={editHCCDetails}
                    zipSelectValue={zipSelect}
                    siteIdSelectValue={siteIdSelect}
                    providerAddressGroupsNameSelectValue={providerAddressGroupsNameSelect}
                    citySelectValue={citySelect}
                    siteAddressSelectValue={siteAddressSelect}
                    downloadCustomBtnName={'Download batch template'}
                    downloadCustomBtn={
                        <DownloadFileFromS3
                            bucketName={process.env.REACT_APP_AWE_BATCH_UPLOAD_BUcket}
                            bucketKey={process.env.REACT_APP_AWE_BUCKET_TEMPLATE}
                            isBatchTemplate={true}
                        />
                    }
                    cinColumnName={'CIN'}
                    awe_display_id={'awe_display_id'}
                    awv_backend_id={'awv_backend_id'}
                    statusColumnName={'STATUS'}
                    showColumnBtn={true}
                    searchBox={false}
                    downloadBtn={false}
                    pagination={true}
                    filterBox={true}
                    colSpan={16}
                />
            }

        </>
    )
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        lobSelect: state.moduleFilter.lobSelect,
        aweRightSidebarCloseDatatableReload: state.moduleFilter.aweRightSidebarCloseDatatableReload,
        aweSiteSelect: state.moduleFilter.aweSiteSelect,
        aweProviderGroupingSelect: state.moduleFilter.aweProviderGroupingSelect,
        citySelect: state.moduleFilter.citySelect,
        aweAddressSelect: state.moduleFilter.aweAddressSelect,
        aweZipSelect: state.moduleFilter.aweZipSelect,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(ReportingAnalytics);