import PropTypes from "prop-types";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { WIDGET_TYPES } from "../../assets/constants/string.constants";
import CardToggle from "./CardToggling";
import { useState } from "react";

const WidgetCard = (props) =>{

    const {heading,data,type} = props;
    let i = 0;

    const [cardToggle, setcardToggle] = useState(true);

    const backGround = [
        {backgroundColor: "#AAB7B8"},
        {backgroundColor: "#EB984E"},
        {backgroundColor: "#58D68D"},
        {backgroundColor: "#5DADE2"},
        {backgroundColor: "#AF7AC5"},
        {backgroundColor: "#EC7063"}
    ]

    const widgetStyles = {
        position: 'absolute', 
        left: 0,
        //right: 20,
        top: 0,
        width: '100%', 
        height: '100%', 
        opacity:0.15,
        color:"white"
    };

    const getIcon = (type) =>{
        var data = ''
        switch (type){
            case WIDGET_TYPES.ISSUER:
                data = <AccountBalanceTwoToneIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.MEMBERS:
                data = <PeopleAltIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERCENT:
                data = <ShowChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.SCORE:
                data = <BarChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERSON:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.CHILD:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.ADULT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.INFANT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.REVENUE:
                data = <AttachMoneyIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.COUNT:
                data = <ShoppingCartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.LOCKICON:
                data = <LockOpenIcon style={widgetStyles} />
                break;
            }
        return data;
    }

    return(
        <div className="card">
            <div className="card-header">
                <span className="d-flex bd-highlight">
                    <h6 className="p-2 flex-grow-1">{heading}</h6>
                    <CardToggle onClick={(e)=>{setcardToggle(e)}}></CardToggle>
                </span>
            </div>
            {cardToggle&&<div className="card-body">
                <div className="card-deck">
                    {
                        Object.entries(data).map(el =>
                            {
                                return (
                                <div className="card" style={backGround[i++]} key={el[0]}>
                                        <div className="card-body">
                                          	<div className="mw-100">
                                              {type ? getIcon(type[i - 1]) : null}
                                        	</div>     
																					<div className="mw-100">   
																						<p className="card-text font-weight-bolder mb-0 pl-3">{el[0]}</p>
																						<p className="card-text pl-3">{el[1]}</p>
																					</div>
                                    </div>
                                </div>
                                )
                            })
                    }
                </div>
            </div>}
        </div>
    )
}

WidgetCard.propTypes ={
    heading : PropTypes.string,
    data : PropTypes.object,
    type : PropTypes.array
}

export default WidgetCard;
