import React, { useState, useReducer, useEffect } from 'react';
import MultiRangeSlider from "multi-range-slider-react";
import '../../MultiRangeSlider.css';

const RangeSingleSliderChart = (props) => {
    return (
        <div className="row mb-1">
            <div className="col-lg-12 pr-0">
                <MultiRangeSlider
                    baseClassName="multi-range-slider-black range-single-slider"
                    min={parseFloat(props.minValue)}
                    max={parseFloat(props.maxValue)}
                    step={((parseFloat(props.maxValue) - parseFloat(props.minValue)) / 10)}
                    ruler={true}
                    label={true}
                    disabled={true}
                    preventWheel={true}
                    minValue={parseFloat(props.firstValue)}
                    maxValue={parseFloat(props.secondValue)}
                    style={{ innerHeight: '10px' }}
                />
            </div>
        </div>
    );
};
export default RangeSingleSliderChart;
