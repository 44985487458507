import React, { useEffect, useState, useRef } from 'react';
import { VennDiagram, VennSeries, VennArc, VennLabel, VennOuterLabel, TooltipArea, ChartTooltip, TooltipTemplate } from 'reaviz';
import history from "../../history";
import { FLAG, GAP_OR_REPORTED, METAL_TIER, RISK_MODEL, ISSUER_ID } from "../../assets/constants/string.constants";
import GapSummaryMembersACA from '../../Pages/ACA/ACAGapSummaryMembers.page';
import CardComponent from "../../components/Cards/card";
import GChartClick from '../../components/GChart/GChartClick';

const DistributionMemberChart = (props) =>{

    const myRefChartData = useRef('')
    const [active, setActive] = useState(null);
    const [type, setType] = useState('');

    useEffect(() => {
       
    }, [props]);
  

    const executeScroll = () => {
        myRefChartData.current.scrollIntoView();
    }   
    
    useEffect(() => {
        executeScroll();
    }, [type]);

    return (
        <>
        <div className="row">
            <div className="col-sm-6">
                <CardComponent
                    heading={`Gap in Riskscore by Metal Tier `}
                    body={
                        <div style={{height:'330px'}}>
                             <GChartClick data={props.MemberChartData} chartType="ColumnChart" height="320px" width="100%" setStorgeItemName={METAL_TIER} removeStorageName={[RISK_MODEL,ISSUER_ID,FLAG]} setStorgeSecondItemName={GAP_OR_REPORTED} setStorgeSecondValueName={'gap_org_metal'} redirectUrl={'acaGapSummaryMembers'} hAxisTitle={"Metal Tier"} yAxistitle={"Risk Score"}></GChartClick>
                        </div>
                    }></CardComponent>
            </div>
            <div className="col-lg-6">   
            {props.data && props.chartLink && props.data.length > 0 && props.chartLink.length > 0 ?
                <>     
                    <CardComponent
                        heading={`DISTRIBUTION OF MEMBERS BASED ON GAPS SOURCE`}
                        body={
                        <div className="box-height-chart">
                            <div className="mb-2" style={{height:'20px'}}>{active !== null && (<p>{'Selected Item : '+active}</p>)}</div>
                            <div className="d-flex justify-content-center">
                                <div style={{ margin: '5px', textAlign: 'center', cursor:'pointer' }}>
                                    <VennDiagram
                                        type="starEuler"
                                        height={230}
                                        width={490}
                                        data={props.data}
                                        series={
                                            <VennSeries
                                                colorScheme={(data, index) => {
                                                    var color_change = '';
                                                    props.chartLink && props.chartLink.map((va, ind) => {
                                                        if(index == va.index){
                                                            color_change =  va.color;
                                                        }
                                                    })
                                                    return color_change;
                                                    // return index % 2 ? 'red' : 'blue';
                                                }}
                                                arc={
                                                    <VennArc
                                                    tooltip={false}
                                                    stroke={(data, index, active, hovered) => {
                                                        if (hovered) {
                                                        return 'blue';
                                                        } else if (active) {
                                                        return 'green';
                                                        }
                                                        return 'white';
                                                    }}
                                                    onMouseEnter={({ value }) => {
                                                        setActive(`${value.sets.join(' & ')} - ${value.size.toLocaleString()}`);
                                                    }}
                                                    onMouseLeave={() => setActive(null)}
                                                    onClick={(data, index) => {
                                                        var data_click = '';
                                                        props.chartLink && props.chartLink.map((va, ind) => {
                                                            if(data.value.key == va.name){
                                                                data_click =  va.link;
                                                            }
                                                        })                          
                                                        localStorage.setItem(FLAG, data_click);
                                                        localStorage.setItem(GAP_OR_REPORTED, props.type);
                                                        setType(data_click);
                                                        // history.push('/layout/acaGapSummaryMembers');
                                                        return '';
                                                    }}
                                                    />
                                                }
                                                label={<VennLabel labelType="value" showAll={true} />}
                                            
                                            />
                                        }
                                    />
                                </div>                        
                            </div>                        
                        </div>                        
                        }>
                    </CardComponent>  
                </>
            :
                <></>
            }
            </div>
        </div>
        <br />
        <div ref={myRefChartData}>
            {type != '' ? 
            
                <GapSummaryMembersACA type={type} />
            :
                <></>
            }   
        </div>
        </>
    );
}
export default DistributionMemberChart;