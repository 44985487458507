/**
 * Creating js file for AWV Member Details
 * Story - PD-40
 * @author ih-sonal
 *
 */

import { connect } from 'react-redux';
import React, { useEffect, useState } from "react";
import AWEDetailedAnalysis from './AWEDetailedAnalysis';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import FormControl from '@material-ui/core/FormControl';
import Dropdown from "../../components/DropDowns/dropDown";
import awvApi from '../../assets/constants/AWVMemberDetails.RafService.Instance';

const AWESummaryDashboardHeader = (props) => {

    const { formState: { errors }, control } = useForm();
    const [groupsData, setGroupsData] = useState([]);
    const [selectGroupsArray, setSelectGroupsArray] = useState([]);
    const [providersData, setProviderData] = useState([]);
    const [yearList, setYearList] = useState([]);
    const [year, setYear] = useState();
    const [lobSelect] = useState(['Medicare'])
    const [selectLob, setSelectLob] = useState(lobSelect[0]);
    const [selectProviderArray, setSelectProviderArray] = useState([]);
    const [selectProviderValueArray, setSelectProviderValueArray] = useState('');
    const [selectIPACode, setSelectIPACode] = useState('');
    const [selectIPAName, setSelectIPAName] = useState('');

    const handleLOBSelect = (e) => {
        setSelectLob(e);
    }
    const handleDropdownForYear = (e) => {
        setYear(e)
    }
    const handleDropdownChangeForProviderName = (event) => {
        var setProviderSelected = [];
        var setProviderNameSelected = [];
        if (event != '' && event.length > 0) {
            event.map(function (el) {
                setProviderSelected.push(el.index.toString());
                setProviderNameSelected.push(el.data)
            })
            //Adding '' and send as comman separated value for multiple filter
            setSelectProviderValueArray('\"' + setProviderNameSelected.join('\",\"') + '\"');
            setSelectProviderArray(setProviderSelected);
        }
        else {
            //Adding '' and send as comman separated value for multiple filter
            setSelectProviderValueArray('');
            setSelectProviderArray([]);
        }
    };
    const handleDropdownChange = (event) => {
        var ipaCode = []
        var ipaName = []
        let setGroupSelected = [];
        let setGroupNameSelected = [];
        if (event != '' && event.length > 0) {
            event.map(function (el) {
                var splitIPACode = el.data
                ipaCode.push(splitIPACode.substr(0, (splitIPACode).lastIndexOf('-')))
                ipaName.push(splitIPACode.substr((splitIPACode).lastIndexOf('-') + 1))
                setGroupSelected.push(el.index.toString());
                setGroupNameSelected.push(el.data)
            })
            //Adding '' and send as comman separated value for multiple filter
            setSelectIPACode('\"' + ipaCode.join('\",\"') + '\"');
            setSelectIPAName('\"' + ipaName.join('\",\"') + '\"')
            setSelectGroupsArray(setGroupSelected);
        }
        else {
            setSelectIPACode('');
            setSelectIPAName('')
            setSelectGroupsArray([]);
        }
    };

    const loadGroupData = () => {
        let values = [];
        awvApi.get(`/ppg_group`)
            .then(res => {
                if (res.data != null && !res.data.includes('errorMessage')) {
                    res.data.forEach((element, index) => {
                        for (let [key, value] of Object.entries(element)) {
                            if (!values.includes(value)) {
                                values.push({ index: index, data: value });
                            }
                        }
                    })
                }
                setGroupsData(values);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const loadProviderNameData = () => {
        let values = [];
        awvApi.get(`/pcp_provider_name`)
            .then(res => {
                if (res.data != null && !res.data.includes('errorMessage')) {
                    res.data.forEach((element, index) => {
                        for (let [key, value] of Object.entries(element)) {
                            if (!values.includes(value)) {
                                values.push({ index: index, data: value });
                            }
                        }
                    })
                }
                setProviderData(values);
            })
            .catch(err => {
                console.log(err);
            })
    }
    const loadProviderData = () => {
        var groupsArray = [];

        providersData.forEach(element => {
            groupsArray.push(element.index.toString())
        });
        setSelectProviderArray(groupsArray);
    }

    useEffect(() => {
        loadGroupData();
        loadProviderNameData();
        loadProviderData();
    }, [selectLob]);

    useEffect(() => {
        // setModuleSelect(getModuleSelect())
        setProviderData([])
        setSelectGroupsArray([])
        setSelectIPACode('')
        setSelectIPAName('')
        setSelectProviderArray([])
        setSelectProviderValueArray([])
    }, [selectLob])

    return (
        <>
            <div className='col-lg-12 pr-0 pl-0'>
                <span className="d-flex bd-highlight">
                    <div className="d-flex align-items-center mr-3">
                        <label className="pr-2 pb-0 mb-0 d-flex align-items-center">Provider Group</label>
                        <div className='d-flex justify-content-end'>
                            <div className="btn-group" style={{ width: '200px' }}>
                                <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '220px', 'zIndex': '2' }}>
                                    <Controller
                                        className="input-control"
                                        name="groups"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                variant="outlined"
                                                isMulti
                                                closeMenuOnSelect={true}
                                                isSearchable={true}
                                                isClearable={true}
                                                options={groupsData}
                                                getOptionLabel={option => `${option.data}`}
                                                getOptionValue={option => `${option.index}`}
                                                value={groupsData.filter(obj => selectGroupsArray.includes(obj.index.toString()))}
                                                hideSelectedOptions={true}
                                                backspaceRemovesValue={false}
                                                onChange={e => handleDropdownChange(e)}
                                                menuPlacement="bottom"
                                            />
                                        )}
                                        rules={{
                                            required: true,
                                        }}
                                    />
                                    {errors?.groups?.type === "required" && <label className="text-danger">This field is required</label>}
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <label className="pr-2 pb-0 mb-0 d-flex align-items-center" style={{ 'margin-left': '2px', paddingLeft: '20px' }}>Provider Name</label>
                    <div className="d-flex align-items-center" style={{ width: '200px' }}>

                        <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '220px' }}>
                            <Controller
                                className="input-control"
                                name="providerName"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        variant="outlined"
                                        isMulti
                                        closeMenuOnSelect={true}
                                        isSearchable={true}
                                        isClearable={true}
                                        options={providersData}
                                        getOptionLabel={option => `${option.data}`}
                                        getOptionValue={option => `${option.index}`}
                                        value={providersData.filter(obj => selectProviderArray.includes(obj.index.toString()))}
                                        hideSelectedOptions={true}
                                        backspaceRemovesValue={false}
                                        onChange={e => handleDropdownChangeForProviderName(e)}
                                        menuPlacement="bottom"
                                    />
                                )}
                                rules={{
                                    required: true,
                                }}
                            />
                            {errors?.providerName?.type === "required" && <label className="text-danger">This field is required</label>}
                        </FormControl>
                    </div>
                    <div className="d-flex align-items-center mr-3" style={{ paddingLeft: '28px' }}>
                        <label className="pr-2 pb-0 mb-0">Year</label>
                        <div className="d-flex justify-content-end">
                            <div className="btn-group">
                                <Dropdown
                                    list={yearList}
                                    value={year}
                                    onSelect={((e) => { handleDropdownForYear(e) })}>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mr-3">
                        <label className="pr-2 pb-0 mb-0">LOB</label>
                        <div className="d-flex justify-content-end" >
                            <Dropdown
                                list={lobSelect}
                                value={selectLob}
                                onSelect={((e) => { handleLOBSelect(e) })}>
                            </Dropdown>
                        </div>
                    </div>
                </span>
            </div>

        </>
    )

};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
    };
}
export default connect(mapStateToProps)(AWESummaryDashboardHeader);
