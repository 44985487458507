import React, { useRef, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../../store/actions';
import AWVCoderWorkBench from '../../../assets/constants/AWVCoderWorkBenchRafservice.Instance';
import Divider from '@mui/material/Divider';
import Moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import { CircularProgress } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { styled } from '@mui/material/styles';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
// import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "../../layout/aweLayout/IconButton";
import Button from "../../layout/aweLayout/Button";
import CloseIcon from "../../icons/CloseIcon";
import CalendarIcon from "../../icons/CalendarIcon";
import hcc_json from "../../../Pages/config/HCC_Code.config.json";
import hcc_aca_json from "../../../Pages/config/HCC_CODE_ACA.config.json"
import AWVApi from "../../../assets/constants/AWVRafservice.Instance";
import { useLocation } from 'react-router-dom';
import {LogFileUploadToS3} from '../../../assets/constants/LogFileUploadToS3'

const EditCWDXCoderWorkBench = (props) => {
    const { register, formState: { errors }, trigger, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [formDataList, setFormDataList] = useState([]);
    const [cwdxRemarkList, setCwdxRemarkList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
    const [editFormData, setEditFormData] = useState({});
    const [dxRecordData, setDXRecordData] = useState([]);
    const [dxCodeDataArray, setDxCodeDataArray] = useState([]);
    const [dxCodeDataArrayPush, setDxCodeDataArrayPush] = useState([]);
    const [dxCodeData, setDxCodeData] = useState([]);
    const [isdxCodeValue, setIsDxCodeValues] = useState(false);
    const [dxCodeValue, setDxCodeValues] = useState();
    const [dxCodeDOSBegin, setDxCodeDOSBegin] = useState();
    // new code
    const [hccValue, setHCCValues] = React.useState();
    const [inputValueHCC, setInputValueHCC] = React.useState("");
    const [inputValueHCCDescription, setInputValueHCCDescription] =
        React.useState("");
    const [inputValueDXDescription, setInputValueDXDescription] =
        React.useState("");
    const [dxValue, setDXValues] = React.useState();
    const [previousDXCode, setpreviousDXCode] = useState(editFormData?.DX_code);
    const [hccData, setHCCData] = useState([]);
    const location = useLocation();
    const [editFormDataMember, setEditFormDataMember] = useState({});
    const [hccV28Data, setHccV28Data] = useState([]);
    const [hccV28DataValue, setHccV28DataValue] = useState()
    const [hccV28DataLocalStorage, setHccV28DataLocalStorage] = useState()
    const [logMessages, setLogMessages] = useState([]); // Array to store log messages
    const writeToLogFile = (message) => {
       setLogMessages(prevLogMessages => [...prevLogMessages, `${new Date().toISOString()} - ${message}`]);
   };
   const [generateLog, setGenerateLog] = useState(false);


    var cin = ''

    useEffect(() => {
        if (props?.Id) {
            if (props?.value.nlp_id) {
                loadformDataTable(props.Id);
            } else {
                loadRemarkDataTable(String(props.aweRightSidebarType?.awv_backend_id), props?.value.cin, props?.value.dx_code, props?.value.dx_description)
            }
        }
    }, [props?.Id]);

    useEffect(() => {
        console.log(errors);
    }, [errors]);

    useEffect(async () => {
        // const queryString = window.location.search;
        // const urlParams = new URLSearchParams(queryString);
        cin = location.state?.cin;
        setValue('IH_MEMBER_ID', cin);
    }, []);

    useEffect(() => {
        if (cin) {
            editLoadFormData(cin, props?.lob, props?.year, props.Id, props.historical);
        }
    }, [cin]);

    useEffect(() => {
        // Retrieve and parse the data from localStorage
        const storedData = localStorage.getItem('hcc_v28_list');
        if (storedData) {
            setHccV28DataLocalStorage(JSON.parse(storedData));
        }
    }, []);



    useEffect(() => {
        if (editFormData.length > 0) {
            editFormData.forEach((data, index) => {
                if (props?.Id && data?.id !== '' && props?.Id === data?.id) {
                    setValue('PAGE_NO', data?.page_no);
                    setValue('IH_MEMBER_ID', data?.cin);
                    setValue('DX_CODE', data?.dx_code ? (data?.dx_description ? data?.dx_code + '-' + data?.dx_description : data?.dx_code) : '');
                    // setValue('ENTITY_ID', editFormData?.ENTITY_ID);
                    setValue('ENTITY_TEXT', data?.entity_text);
                    setValue('REMARKS', data?.remarks);
                    setValue('CW_DX_STATUS', data?.status);
                    if (data?.dos_begin && data?.dos_begin != null && data?.dos_begin != '') {
                        setValue('DOS_Begin', Moment(data?.dos_begin).format("YYYY-MM-DD"));
                        setDxCodeDOSBegin(Moment(data?.dos_begin).format("YYYY-MM-DD"));
                    }
                    if (data?.dos_end && data?.dos_end != null && data?.dos_end != '') {
                        setValue('DOS_End', Moment(data?.dos_end).format("YYYY-MM-DD"));
                    }

                    setDxCodeValues(data?.dx_code ? (data?.dx_description ? data?.dx_code + '-' + data?.dx_description : data?.dx_code) : '');
                    setValue('insufficient_document', data?.insufficient_document);
                    data?.model_1 && setValue('HCC_Code', data?.model_1 ? (data?.model_1_description ? data?.model_1 + '-' + data?.model_1_description : data?.model_1) : '');
                    data?.model_2 && setValue('hcc_v28_value', data?.model_2 ? (data?.model_2_description ? data?.model_2 + '-' + data?.model_2_description : data?.model_2) : '');
                    setValue('billing_provider', data?.billing_provider);
                    setValue('rendering_provider', data?.rendering_provider);
                    setIsDxCodeValues(true);
                }
            });
        }
    }, [editFormData]);

    const notificationRightDrawer = (open, valueId = '') => {
        props.currentAWERightSidebarType({ 'type': 'notification' });
        props.currentAWERightSidebar(open);
    };

    const loadformDxCode = () => {
        setIsLoading(true);
        AWVCoderWorkBench.get('data/icd_code/lob/' + props?.lob)
            .then(res => {
                console.log(res.data);
                setDxCodeDataArray(res.data);
                let valuesArray = [];
                res.data.forEach((element, index) => {
                    if (!valuesArray.includes(element.icd_code + '-' + element.icd_code_description)) {
                        valuesArray.push(element.icd_code + '-' + element.icd_code_description);
                    }
                });
                setDxCodeDataArrayPush(valuesArray);
                loadDxCodeData(valuesArray, '');
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    }

    //load DX codes from JSON
    const handleChangeDxCode = (eventValue) => {
        console.log(eventValue);
        const result = dxCodeDataArray.find((element) => element.icd_code + '-' + element.icd_code_description === eventValue);
        setValue('DX_CODE', eventValue);
        setDxCodeValues(eventValue);
        if (result) {
            setIsDxCodeValues(true);
            if (result?.hcc_v24_description != '') {
                setValue('HCC_Code', result?.hcc_v24 + '-' + result?.hcc_v24_description);
            } else {
                setValue('HCC_Code', result?.hcc_v24 && result?.hcc_v24 == 'No HCC' ? '' : result?.hcc_v24);
            }

            if (result?.hcc_v28_description != '') {
                setValue('hcc_v28_value', result?.hcc_v28 + '-' + result?.hcc_v28_description);
            } else {
                setValue('hcc_v28_value', result?.hcc_v28 && result?.hcc_v28 == 'No HCC' ? '' : result?.hcc_v28);
            }


            if (result?.rxhcc_v05_description != '') {
                setValue('RXHCC_v05', result?.rxhcc_v05 + '-' + result?.rxhcc_v05_description);
            } else {
                setValue('RXHCC_v05', result?.rxhcc_v05);
            }
            if (result?.rxhcc_v08_description != '') {
                setValue('RXHCC_v08', result?.rxhcc_v08 + '-' + result?.rxhcc_v08_description);
            } else {
                setValue('RXHCC_v08', result?.rxhcc_v08);
            }
        }
        setDxCodeData([]);
    }

    const handleInputChangeDxCode = (eventValue) => {
        setIsDxCodeValues(false);
        if (eventValue.length >= 3) {

        } else {
            setDxCodeDataArray([]);
            let valuesArray = [];
            setValue('DX_CODE', eventValue);
            setDxCodeValues(eventValue);
            loadDxCodeData(valuesArray, eventValue);
            setIsLoading(false);

            setValue('DX_CODE', eventValue);
            setDxCodeValues(eventValue);
            return true;
        }
        setIsLoading(true);
        console.log(eventValue);
        var data = { 'search': eventValue, 'lob': String(props?.lob) };
        AWVCoderWorkBench.post('/data/icd_code', data)
            .then(res => {
                setDxCodeDataArray(res.data);
                let valuesArray = [];
                res.data.forEach((element, index) => {
                    if (!valuesArray.includes(element.icd_code + '-' + element.icd_code_description)) {
                        valuesArray.push(element.icd_code + '-' + element.icd_code_description);
                    }
                });
                setValue('DX_CODE', eventValue);
                setDxCodeValues(eventValue);
                loadDxCodeData(valuesArray, eventValue);
                setIsLoading(false);
            })
            .catch(err => {
                setDxCodeDataArray([]);
                let valuesArray = [];
                setValue('DX_CODE', eventValue);
                setDxCodeValues(eventValue);
                loadDxCodeData(valuesArray, eventValue);
                setIsLoading(false);
            })

    }

    const loadDxCodeData = (dxCodeDataArrayPush, dxCodeValue) => {
        let values = [];
        if (dxCodeDataArrayPush && dxCodeDataArrayPush.length > 0 && dxCodeValue) {
            dxCodeDataArrayPush.forEach((element, index) => {
                if (!values.includes(element) && ((element.toLowerCase()).includes(dxCodeValue.toLowerCase()))) {
                    values.push(element);
                }
            });
            setDxCodeData(values);
        } else {
            setDxCodeData(values);
        }
    };

    const editLoadFormData = (cin, lob, year, Id = '') => {
        writeToLogFile("awe backend id before encryption - ", String(props.aweRightSidebarType?.awv_backend_id))
        var cin = btoa(cin)
        var awe_display_id = btoa(location.state?.awe_display_id)
        var awv_backend_id = btoa(String(props.aweRightSidebarType?.awv_backend_id))
        setIsLoading(true);
        var url = '/get_member_hcc_details?chart_record=' + location.state?.chart_chase_record + '&cin=' + cin + '&lob=' + lob + '&year=' + year + '&organisation_id=1' + '&awe_display_id=' + awe_display_id + '&awv_backend_id=' + awv_backend_id
        //var url = '/data/cw_dx/' + Id;

        //AWVCoderWorkBench.get(url)
        AWVApi.get(url)
            .then(res => {
                console.log(res.data);
                if (res.data && res.data) {
                    setEditFormData(res.data);
                    setDXRecordData(res.data)
                    setIsLoading(false);
                } else {
                    setEditFormData();
                    setIsLoading(false);
                }
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
            })
    }

    const loadRemarkDataTable = (valueAwv, valueCin, dx_code, dx_description) => {
        writeToLogFile("awe backend id before encryption - ", valueAwv, ' cin = ', valueCin)
        valueAwv = btoa(valueAwv)
        valueCin = btoa(valueCin)
        writeToLogFile("loadRemarkDataTable() call");
        setIsLoading(true);
        var url = '/get-hcc-checklist-remark?cin=' + valueCin + '&awv_backend_id=' + valueAwv + '&lob=' + String(props?.lob) + '&organisation_id=1&year=' + String(props?.year) + '&dx_code=' + dx_code + '&dx_description=' + dx_description
        writeToLogFile("API call: ", url);
        AWVApi.get(url) //'&provider_group=' + props?.providerGroupSelect
            .then(res => {

                var dxRemarks = (res.data && res.data) ? res.data : [];
                var conactArrayNew = formDataList.concat(dxRemarks);
                conactArrayNew.sort(function (a, b) {
                    var dateA = new Date(a.created_on), dateB = new Date(b.created_on)
                    return dateB - dateA;
                });
                setCwdxRemarkList(conactArrayNew);
                setIsLoading(false);
                writeToLogFile("Data retrieved successfully...");
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
                writeToLogFile("Error in retrieving data... " + err );
            })
    }

    const loadformDataTable = (id) => {
        id = btoa(id)
        writeToLogFile("loadformDataTable() call");
        writeToLogFile("API call: " + 'data/cwb/history/cw_dx/' + id);
        // setIsLoading(true);
        AWVCoderWorkBench.get('data/cwb/history/cw_dx/' + btoa(id)) //'&provider_group=' + props?.providerGroupSelect
            .then(res => {
                setCwdxRemarkList(res?.data);
                setFormDataList(res?.data);
                writeToLogFile("Data retrieved successfully...");
            })
            .catch(err => {
                console.log(err);
                writeToLogFile("Error in retrieving data... " + err );
            })
    }

    const dateConvert = (timestamp) => {
        // Create a new Date object using the timestamp
        var date = new Date(timestamp);

        // Get the individual components of the date
        var year = date.getFullYear();
        var month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        var day = date.getDate().toString().padStart(2, '0');

        // Form the "yyyy-mm-dd" format
        var formattedDate = year + '-' + month + '-' + day;
        return formattedDate;

    }

    const onSubmit = (data) => {
        //new code
        writeToLogFile("onSubmit() call ");
        const historyApiUrl = "/create-historic-npl-dx-data";
        const hccChecklistApiUrl =  "/create-hcc-checklist-remark";
        const updateMemberHccApiUrl = "/update-member-hcc-details";
        const saveApiUrl = props?.Id ? `data/cw_dx/${btoa(props.Id)}` : 'data/cw_dx/save';
        const controlUser = JSON.parse(localStorage.getItem('user'));
        const controlUserId = JSON.parse(localStorage.getItem("controlUser"))?.user_id;
        data.LAST_UPDATED_BY = controlUser?.userEmail;
        let history = {}


        if (!isdxCodeValue || !dxCodeValue) {
            data.DX_CODE = "";
            toast.error('DX CODE is Invalid. Please select');
            return true;
        }
        // Helper function to format date
        const formatDate = date => Moment(date).format("YYYY-MM-DD");

        // Common reset function for form values
        const resetFormValues = () => {
            const fieldsToReset = ['ID', 'PAGE_NO', 'IH_MEMBER_ID', 'DX_CODE', 'ENTITY_ID', 'ENTITY_TEXT', 'REMARKS', 'LAST_UPDATED_ON', 'LAST_UPDATED_BY',
                'DOS_Begin', 'DOS_End', 'HCC_Code', 'hcc_v28_value', 'insufficient_document'];
            fieldsToReset.forEach(field => setValue(field, ''));
            setFormSubmitisLoading(false);
        };


        // Function to handle API calls
        const handleApiCall = (api, url, method, data, successMessage, errorMessage) => {
            setFormSubmitisLoading(true);
            writeToLogFile("handleApiCall() "+ "api, url, method, data, successMessage, errorMessage" + api, url, method, data, successMessage, errorMessage);
            api[method](url, data)
                .then(res => {
                    resetFormValues();
                    if (successMessage != '')
                        // var successMessageToDisplay = 
                        res.data.statusCode && res.data.statusCode =='400' ? toast.error(res.data.body) :toast.success(successMessage);

                    props.currentAWERightSidebarCloseDatatableReload(true);
                    notificationRightDrawer(false);
                })
                .catch(err => {
                    if (errorMessage != '') {
                        toast.error(err?.response?.data?.message);
                    }
                    setFormSubmitisLoading(false);
                });
        };

        const getStatusNumber = (status) => {
            const statusMap = {
                "Accepted": "1",
                "Rejected": "2",
                'Pending for clarification': "3",
            };

            return statusMap[status] || 0; // Default to 0 for unknown status
        };

        if (props?.Id) {
            // Prepare history data
            const historyData = [{
                "cin": btoa(props?.value.cin),
                "awv_backend_id": btoa(String(props.aweRightSidebarType?.awv_backend_id)),
                "lob": String(props?.lob),
                "organisation_id": "1",
                "previous_source_code": props?.value.dx_description ? props?.value.dx_code + '-' + props?.value.dx_description : props?.value.dx_code,
                "previous_model_1": props?.value.model_1_description ? props?.value.model_1 + '-' + props?.value.model_1_description : props?.value.model_1,
                "previous_model_2": props?.value.model_2_description ? props?.value.model_2 + '-' + props?.value.model_2_description : props?.value.model_2,
                "previous_DOS_Begin": String(dateConvert(props?.value.dos_begin)),
                "previous_DOS_End": String(dateConvert(props?.value.dos_end)),
                "previous_insufficient_document": String(props?.value.insufficient_document),
                "previous_status": props?.value.status && props?.value.status != '' ? getStatusNumber(props?.value.status) : '',
                "RXHCC_v05": props?.value.rxhcc_v05 ? props?.value.rxhcc_v05 : '',
                "RXCHCC_v08": props?.value.rxhcc_v08 ? props?.value.rxhcc_v08 : '',
                "year": String(props?.year),
                "project_id": props.aweRightSidebarType?.project_id ? props.aweRightSidebarType?.project_id : '', // check
                "current_dx_code": data.DX_CODE ? data.DX_CODE : '',
                "is_nlp": props?.value.nlp_id && props?.value.nlp_id != '' ? 'Y' : '',
                "is_historic": props?.value.historic_id && props?.value.historic_id != '' ? 'Y' : '',
                'awe_display_id': btoa(String(props.aweRightSidebarType?.awe_display_id)),
                'log_api': process.env.REACT_APP_LOG_URL
            }];
            writeToLogFile("historyData:  "+ JSON.stringify(historyData)) 
            handleApiCall(AWVApi, historyApiUrl, 'post', historyData, '', '');

            if (props?.value.nlp_id) {

                // Prepare data for update
                const updateData = {
                    // "CREATED_BY": controlUser?.userEmail,
                    "DOS_Begin": Moment(data.DOS_Begin).format("YYYY-MM-DD"),
                    "DOS_End": Moment(data.DOS_End).format("YYYY-MM-DD"),
                    "PAGE_NO": data.PAGE_NO,
                    "ENTITY_ID": data.ENTITY_ID,
                    "ENTITY_TEXT": data.ENTITY_TEXT || "",
                    "insufficient_document": data.insufficient_document,
                    "HCC_v24": data.HCC_Code,
                    "HCC_V28": data.hcc_v28_value,
                    "REMARKS": data.REMARK,
                    "IH_MEMBER_ID": btoa(data.IH_MEMBER_ID),
                    "DX_CODE": data.DX_CODE,
                    "CW_DX_STATUS": data.CW_DX_STATUS,
                    "LAST_UPDATED_BY": controlUser?.userEmail,
                    "is_present_coder_workbench": props.aweRightSidebarType?.is_cw_present,
                    "CW_ID": props.aweRightSidebarType?.cw_id,
                    'log_api': process.env.REACT_APP_LOG_URL,
                    'awe_display_id': btoa(String(props.aweRightSidebarType?.awe_display_id)),
                    "awv_backend_id": btoa(String(props.aweRightSidebarType?.awv_backend_id)),
                };

                // Perform update
                writeToLogFile("updateData:  "+ JSON.stringify(updateData)) 
                handleApiCall(AWVCoderWorkBench, saveApiUrl, 'post', updateData, 'Updated successfully', 'Error while updating the record');
            } else {
                // Prepare data for save
                const saveData = {
                    "updated_by": controlUserId,
                    "cin": btoa(data.IH_MEMBER_ID),
                    "lob": String(props.lobSelect),
                    "organisation_id": '1',
                    "year": props.yearSelect,
                    "awv_backend_id": btoa(String(props.aweRightSidebarType?.awv_backend_id)),
                    "DX_code": data.DX_CODE ? data.DX_CODE.split("-", 2)[0] : '',
                    "DX_description": data.DX_CODE ? data.DX_CODE.split("-", 2)[1] : '',
                    "model_1": data.HCC_Code ? data.HCC_Code.includes(':') ? data.HCC_Code.split(":", 2)[0] : data.HCC_Code.split("-", 2)[0] : '',
                    "model_1_description": data.HCC_Code ? data.HCC_Code.includes(':') ? data.HCC_Code.split(":", 2)[1] : data.HCC_Code.split("-", 2)[1] : '',
                    "model_2": data.hcc_v28_value ? data.hcc_v28_value.includes(':') ? data.hcc_v28_value.split(":", 2)[0] : data.hcc_v28_value.split("-", 2)[0] : '',
                    "model_2_description": data.hcc_v28_value ? data.hcc_v28_value.includes(':') ? data.hcc_v28_value.split(":", 2)[1] : data.hcc_v28_value.split("-", 2)[1] : '',
                    "insufficient_document": String(data.insufficient_document),
                    "status": data.CW_DX_STATUS,
                    "DOS_Begin": Moment(data.DOS_Begin).format("YYYY-MM-DD"),
                    "DOS_End": Moment(data.DOS_End).format("YYYY-MM-DD"),
                    "id": props?.value.historic_id,
                    "member_id": '',
                    'log_api': process.env.REACT_APP_LOG_URL,
                    'awe_display_id': btoa(String(props.aweRightSidebarType?.awe_display_id)),
                };
                const saveRemark = {
                    "cin": btoa(data.IH_MEMBER_ID),
                    "member_id": '',
                    "awv_backend_id": btoa(String(props.aweRightSidebarType?.awv_backend_id)),
                    "lob": String(props.lobSelect),
                    "organisation_id": '1',
                    "year": props.yearSelect,
                    "dx_code": data.DX_CODE.split("-", 2)[0],
                    "dx_description": data.DX_CODE.split("-", 2)[1],
                    "created_by": controlUserId,
                    "remark": data.REMARK,
                    "project_id": props.aweRightSidebarType?.project_id ? props.aweRightSidebarType?.project_id : '',
                    'awe_display_id': btoa(String(props.aweRightSidebarType?.awe_display_id)),
                    'log_api': process.env.REACT_APP_LOG_URL
                }
                // Perform save
                writeToLogFile("saveRemark:  "+ JSON.stringify(saveRemark)) 
                handleApiCall(AWVApi, hccChecklistApiUrl, 'post', saveRemark, '', '');
                writeToLogFile("saveData:  "+ JSON.stringify(saveData)) 
                handleApiCall(AWVApi, updateMemberHccApiUrl, 'put', saveData, 'Updated successfully', 'Error while updating the record');
            }
        } else {
            // Prepare data for save
            const saveData = {
                "CREATED_BY": controlUser?.userEmail,
                "DOS_Begin": formatDate(data.DOS_Begin),
                "DOS_End": formatDate(data.DOS_End),
                "RXHCC_v05": data.RXHCC_v05,
                "RXHCC_v08": data.RXHCC_v08,
                "lob": props.lobSelect,
                "organisation_id": '1',
                "year": props.yearSelect,
                "insufficient_document": data.insufficient_document,
                "HCC_v24": data.HCC_Code,
                "HCC_V28": data.hcc_v28_value,
                "IH_MEMBER_ID": btoa(data.IH_MEMBER_ID),
                "DX_CODE": data.DX_CODE,
                "CW_DX_STATUS": data.CW_DX_STATUS,
                "REMARKS": data.REMARK,
                "LAST_UPDATED_BY": controlUser?.userEmail,
                //"rendering_provider": data.rendering_provider ? data.rendering_provider : '',
                //"billing_provider": data.billing_provider ? data.billing_provider : '',
                "project_id": props.aweRightSidebarType?.project_id ? props.aweRightSidebarType?.project_id : null,
                'log_api': process.env.REACT_APP_LOG_URL

            };
            let recordExists = [];
            recordExists = dxRecordData.filter((val) => {
                const dxCodeList = val.dx_code && val.dx_code.includes('-') ? val.dx_code.split('-')[0].trim() : val.dx_code;
                const dxCodeInData = data.DX_CODE && data.DX_CODE.includes('-') ? data.DX_CODE.split('-')[0].trim() : data.DX_CODE;
                if (dxCodeList === dxCodeInData) {
                    return data.DX_CODE
                }
            });
            writeToLogFile("saveData:  "+ JSON.stringify(saveData)) 
            writeToLogFile("recordExists:  " + recordExists) 
            if (recordExists.length > 0) {
                toast.error('Diagnosis Code already exists. Kindly, enter new Diagnosis Code details.');
            } else {
                handleApiCall(AWVCoderWorkBench, saveApiUrl, 'post', saveData, 'Created successfully', 'Error while creating the record');
            }
        }
        setGenerateLog(true)
    }

    const handleChangeDate = (event) => {
        writeToLogFile("handleChangeDate() call  " + event) 
        handleManualValidation()
        if (event?.target?.name == 'DOS_Begin') {
            setValue(event?.target?.name, event?.target?.value);
            setValue('DOS_End', event?.target?.value);
            setDxCodeDOSBegin(event?.target?.value);
            // handleManualValidation();

        } else {
            setValue(event?.target?.name, event?.target?.value);
            // handleManualValidation();
        }
    }

    //load HCC codes from JSON hcc_json->Medicare || hcc_aca_json -> ACA
    const loadHCCData = (value) => {
        writeToLogFile("loadHCCData() call  " + value) 
        let values = [];
        setHCCValues(value);
        setValue("HCC_Code", value);
        if (props.lobSelect == 2 && hcc_json && hcc_json.length > 0 && value != '') {
            values = hcc_json.filter(element =>
                (element.hcc_code.toLowerCase()).includes(value.toLowerCase())
            ).map(element => element.hcc_code + '-' + element.hcc_description);
            setHCCData(values);
        } else if (hcc_aca_json && hcc_aca_json.length > 0 && value != '') {
            values = hcc_aca_json.filter(element =>
                (element.hcc_code.toLowerCase()).includes(value.toLowerCase())
            ).map(element => element.hcc_code + '-' + element.hcc_description);
            setHCCData(values);
        } else {
            setHCCData(values);
        }
    };

    const loadHCCV28Data = (value) => {
        writeToLogFile("loadHCCV28Data() call  " + value) 
        let values = [];
        setHccV28DataValue(value);
        setValue("hcc_v28_value", value);

        values = hccV28DataLocalStorage ? hccV28DataLocalStorage.filter(element =>
            (element.hcc_v28).includes(value)
        ).map(element => element.hcc_v28) : '';

        setHccV28Data(values);
    };

    const loadHCCDataSelected = (value) => {
        let values = [];
        setHCCValues(value);
        setHCCData(values);
        setValue("HCC_Code", value);
    };

    const loadHCC_V28DataSelected = (value) => {
        setHccV28DataValue(value)
        setHccV28Data([])
        setValue("hcc_v28_value", value);
    };


    const handleManualValidation = async () => {
        try {
            // Trigger manual revalidation for all fields
            await trigger();
        } catch (error) {
            //console.error('Manual validation error:', error);
        }
    };



    const handleKeyDown = (event) => {
        const options = document.querySelectorAll('.options li');
        const currentIndex = dxCodeData.indexOf(dxCodeValue);
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            if (currentIndex < dxCodeData.length - 1) {
                setDxCodeValues(dxCodeData[currentIndex + 1]);
                options[currentIndex + 1].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            if (currentIndex > 0) {
                setDxCodeValues(dxCodeData[currentIndex - 1]);
                options[currentIndex - 1].scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        } else if (event.key === 'Enter') {
            if (currentIndex !== -1) {
                // Simulate click event on the currently selected option
                options[currentIndex].click();
                event.preventDefault(); // Prevent form submission if needed
            }
        }
    };

    useEffect(() => {
        // Generate and download log file when logMessages have been updated
        if (logMessages.length > 0  && generateLog === true) {
        LogFileUploadToS3(logMessages,  localStorage.getItem("access_key"), localStorage.getItem("secret_key"), process.env.REACT_APP_LOG_BUCKET, "Single_DX_Code_Edit")
        }
    }, [generateLog === true, logMessages, props?.value.cin,  props?.lob, props?.year, props.Id,props?.awe_id, props.lobSelect]); 


    // ends here
    return (
        <div className="diagnosis_code_sidebar offcanvas offcanvas-end show" tabIndex={-1} aria-labelledby="offcanvasRightLabel">
            <div className="offcanvas-header flex_item_cb">
                <h5 id="offcanvasRightLabel" className='fs15'>{(props?.Id) ? 'Edit' : 'Add'} DX Code </h5>
                <Tooltip title="Close">
                    <IconButton type="button" buttonType={"default"} data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => notificationRightDrawer(false)}>
                        <CloseIcon className="w24" />
                    </IconButton>
                </Tooltip>
            </div>
            <Divider />
            <div className="offcanvas-body pt-1 custom-scrollbar overflow-auto">
                <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">MEMBER ID</label>
                        <Controller
                            name="IH_MEMBER_ID"
                            control={control}
                            render={({ field }) => (
                                <input type="text" {...field} className="form-control" readOnly />
                            )}
                            rules={{
                                required: true,
                            }}

                        />
                        {errors?.IH_MEMBER_ID?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>

                    <div className="form-group mb20 custom-autocomplete">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Dx CODE<span style={{ color: 'red' }}> *</span></label>
                        <Controller
                            name="DX_CODE"
                            control={control}
                            render={({ field }) => (
                                <input
                                    {...field}
                                    type="text"
                                    value={dxCodeValue}
                                    onChange={(event) => handleInputChangeDxCode(event?.target?.value)}
                                    placeholder="Type to search..."
                                    autoComplete="off"
                                    className="form-control"
                                    /*readOnly={!!props?.Id}*/
                                    onBlur={handleManualValidation}
                                    onKeyDown={handleKeyDown}

                                />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        <ul className="options custom-scrollbar">
                            {dxCodeData.map((option, index) => (
                                <li key={index} className={option === dxCodeValue ? 'selected' : ''} onClick={() => handleChangeDxCode(option)}>
                                    {option}
                                </li>
                            ))}
                        </ul>
                        {errors?.DX_CODE?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    {props.lobSelect == '2' && props.aweRightSidebarType?.project_id != '6' ? (
                        <>
                            <div className="form-group mb20 custom-autocomplete">
                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2">HCC V24 </label>
                                <Controller
                                    name="HCC_Code"
                                    value={hccValue}
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            type="text"
                                            onChange={(event) => loadHCCData(event?.target?.value)}
                                            placeholder="Type to search..."
                                            autoComplete="off"
                                            className="form-control"
                                            readOnly={true}
                                        />
                                    )}
                                    rules={{
                                        required: false,
                                    }}
                                />
                                <ul className="options custom-scrollbar">
                                    {hccData.map((option, index) => (
                                        <li key={index} onClick={() => loadHCCDataSelected(option)}>
                                            {option}
                                        </li>
                                    ))}
                                </ul>
                                {errors?.HCC_Code?.type === "required" && <label className="form-input-error">This field is required</label>}
                            </div>

                            <div className="form-group mb20 custom-autocomplete">
                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2">HCC V28 </label>
                                <Controller
                                    name="hcc_v28_value"
                                    value={hccV28DataValue}
                                    control={control}
                                    render={({ field }) => (
                                        <input
                                            {...field}
                                            type="text"
                                            onChange={(event) => loadHCCV28Data(event?.target?.value)}
                                            placeholder="Type to search..."
                                            autoComplete="off"
                                            className="form-control"
                                            readOnly={true}
                                        />
                                    )
                                    }
                                    rules={{
                                        required: false,
                                    }}
                                />
                                <ul className="options custom-scrollbar">

                                    {hccV28Data ? hccV28Data.map((option, index) => (
                                        <li key={index} onClick={() => loadHCC_V28DataSelected(option)}>
                                            {option}
                                        </li>
                                    )) : ''}
                                </ul>
                                {errors?.hcc_v28_value?.type === "required" && <label className="form-input-error">This field is required</label>}
                            </div>
                        </>
                    ) : <>
                        <div className="form-group mb20 custom-autocomplete">
                            <label htmlFor="" className="text_c3A3C40 fs13 mb-2">HCC </label>
                            <Controller
                                name="HCC_Code"
                                value={hccValue}
                                control={control}
                                render={({ field }) => (
                                    <input
                                        {...field}
                                        type="text"
                                        onChange={(event) => loadHCCData(event?.target?.value)}
                                        placeholder="Type to search..."
                                        autoComplete="off"
                                        className="form-control"
                                        readOnly={true}
                                    />
                                )}
                                rules={{
                                    required: false,
                                }}
                            />
                            <ul className="options custom-scrollbar">
                                {hccData.map((option, index) => (
                                    <li key={index} onClick={() => loadHCCDataSelected(option)}>
                                        {option}
                                    </li>
                                ))}
                            </ul>
                            {errors?.HCC_Code?.type === "required" && <label className="form-input-error">This field is required</label>}
                        </div>
                    </>}

                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">DOS START <span style={{ color: 'red' }}> *</span></label>
                        <Controller
                            name="DOS_Begin"
                            control={control}
                            render={({ field }) => (
                                <input type="date" className="form-control" {...field} onChange={handleChangeDate} />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.DOS_Begin?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>

                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">DOS END <span style={{ color: 'red' }}> *</span></label>
                        <Controller
                            name="DOS_End"
                            control={control}
                            render={({ field }) => (
                                <input type="date" {...field} className="form-control" min={Moment(dxCodeDOSBegin).format("YYYY-MM-DD")} onChange={handleChangeDate} />
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.DOS_End?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">INSUFFICIENT DOCUMENTATION</label>
                        <div className="flex_item_cs gap8">
                            <Controller
                                name="insufficient_document"
                                value={editFormData?.insufficient_document == 0 ? "No" : "Yes"}
                                control={control}
                                render={({ field }) => (
                                    <select
                                        {...field}
                                        className="form-control"
                                        onClick={(e) => setValue("insufficient_document", e.target.value)}
                                    >
                                        <option value={''} >
                                            Select
                                        </option>
                                        <option value={'1'} >
                                            Yes
                                        </option>
                                        <option value={'0'} >
                                            No
                                        </option>
                                    </select>
                                )}
                                rules={{
                                    required: false,
                                }}
                            />
                        </div>
                        {errors?.insufficient_document?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>
                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Status <span style={{ color: 'red' }}> *</span></label>
                        <Controller
                            name="CW_DX_STATUS"
                            control={control}
                            render={({ field }) => (
                                <select
                                    {...field}
                                    className="form-control"
                                    onChange={handleChangeDate}

                                >
                                    <option value={''} >
                                        Select
                                    </option>
                                    <option value={'Accepted'} >
                                        Accepted
                                    </option>
                                    <option value={'Rejected'} >
                                        Rejected
                                    </option>
                                    {props.aweRightSidebarType?.project_id != '6' ?
                                        <option value={'Pending for clarification'} >
                                            Pending for Clarification
                                        </option>
                                        :
                                        <></>
                                    }
                                </select>
                            )}
                            rules={{
                                required: true,
                            }}
                        />
                        {errors?.CW_DX_STATUS?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>

                    {props.aweRightSidebarType?.project_id == '6'
                        ? <>
                            <div className="form-group mb20">
                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Rendering Provider</label>
                                <Controller
                                    name="rendering_provider"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea rows={1} {...field} className="form-control" />
                                    )}
                                    rules={{
                                        required: false,
                                    }}
                                />
                                {errors?.REMARKS?.type === "required" && <label className="form-input-error">This field is required</label>}
                            </div>
                            <div className="form-group mb20">
                                <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Billing Provider</label>
                                <Controller
                                    name="billing_provider"
                                    control={control}
                                    render={({ field }) => (
                                        <textarea rows={1} {...field} className="form-control" />
                                    )}
                                    rules={{
                                        required: false,
                                    }}
                                />
                                {errors?.REMARKS?.type === "required" && <label className="form-input-error">This field is required</label>}
                            </div>
                        </>
                        :
                        <></>
                    }

                    <div className="form-group mb20">
                        <label htmlFor="" className="text_c3A3C40 fs13 mb-2">REMARK</label>
                        <Controller
                            name="REMARK"
                            control={control}
                            render={({ field }) => (
                                <textarea rows={3} {...field} className="form-control" />
                            )}
                            rules={{
                                required: false,
                            }}
                        />
                        {errors?.REMARKS?.type === "required" && <label className="form-input-error">This field is required</label>}
                    </div>

                    <div className="form-group flex_item_cs gap10">
                        <Button type='button' className={"btn-white minw80"} onClick={() => notificationRightDrawer(false)}>CANCEL</Button>
                        <Button type='submit' className={"minw80"} disabled={formSubmitisLoading && formSubmitisLoading == true ? true : false}>SAVE</Button>
                    </div>
                </form>
                {isLoading ?
                    <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                        <CircularProgress />
                    </div>
                    :
                    <>
                        {cwdxRemarkList && cwdxRemarkList.length > 0 ?
                            <div className="">
                                <h5 className="fs18 mb10">History</h5>
                                <Divider />
                                <div className="history_card_list">
                                    {cwdxRemarkList && cwdxRemarkList.map((element, index) =>
                                        <div key={index} className={
                                            element.form_status === 'Accepted' ? 'history_card_item status-primary' :
                                                element.form_status === 'Rejected' ? 'history_card_item status-danger' :
                                                    element.form_status === 'Pending for clarification' ? 'history_card_item status-warning' :
                                                        'history_card_item status-primary'
                                        } >
                                            <p className="fs12 mb-1 fw700">
                                                {Moment(element.created_on).format('h:mm a, MMMM DD YYYY')}
                                            </p>
                                            <p className="fs12 mb-1">
                                                {element.form_status ?
                                                    <span className="fw700">Status :-</span>
                                                    : <></>}
                                                {element.form_status}
                                            </p>
                                            <p className="fs12 mb-1">
                                                <span className="fw700">Remarks :-</span>
                                                {element.remarks ? element.remarks : element.remark}
                                            </p>
                                            <p className="fs12 mb-1">
                                                <span className="fw700">Last updated by :-</span>
                                                {element.user_id ? element.user_id : element.created_by}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            :
                            <></>
                        }
                    </>
                }
            </div>

        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(EditCWDXCoderWorkBench);

