import { useState } from "react";
import PropTypes from "prop-types";
import history from '../../../history';
import { ArrowLeft, CenterFocusStrong } from "@material-ui/icons";
import Button from '@material-ui/core/Button';

const
HeaderForETL = (props) =>{

    const{heading} = props;
    const style = {
        marginLeft: '85%',
        marginTop: '0.5%'
      };
      
    return(
        <div>
            <div className="card">
                <div className="card-header">
                    <span className="d-flex bd-highlight">
                        <b><h6 className="pt-2 flex-grow-1 header-title-text">{props.heading}</h6></b>
                        <div style={style}>
                        {/* <Button style ={{alignItems:CenterFocusStrong}} color="primary" className="d-flex align-items-left" onClick={() => history.push('/home')}>Home</Button> */}
                      
                        </div>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default HeaderForETL;
