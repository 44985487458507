import React, { useState, useEffect } from "react";
import AWVApi from '../../assets/constants/AWVRafservice.Instance';
import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { Button, CircularProgress } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import { TableHead } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AWVSlickSlider from '../../components/SlickSlider/AWVSlickSlider';
import { connect } from 'react-redux';
import AWVNLPInsightPDF from '../../components/customRightSidebar/AWVNLPInsightPDF';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import Tooltip from '@mui/material/Tooltip';
import { NavLink } from "react-router-dom";
import { currentRoute, currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../store/actions';


const AWVExpandableGridRow = (props) => {
    const [formDataList, setFormDataList] = useState([]);
    const [selectMemberId] = useState(props.rowData[props.cinKeyNumber]?.props?.children?.props?.children);
    const [selectMemberIdStatus] = useState((props?.rowData && props?.rowData[6]) ? props?.rowData[6]?.props?.children?.props?.children : '');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isDataGridCheked, setIsDataGridCheked] = useState(false);
    const [aweId, setAWEId] = useState('');
    const [hccCodeList, setHccCodeList] = useState([]);
    const [rxcCodeList, setRXCCodeList] = useState([]);

    useEffect(() => {
        if (selectMemberId && selectMemberId != '') {
            loadformDataTable();
        }
    }, [selectMemberId]);
    useEffect(() => {
        AWVApi.get('/get-awe-processed-record-details?year=' + props.yearSelect + '&organisation_id=1&lob=' + props.lobSelect + '&cin=' + selectMemberId)
            .then(res => {
                if (res.data && res.data[0]) {
                    setAWEId(res.data[0].awv_id);
                } else {
                    setAWEId('');
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, selectMemberId)

    const notificationRightDrawer = (open, typeValue, valueId, statusId = '', cinId = '') => {
        setAnchorEl('');
        props.currentAWERightSidebarType({ 'type': typeValue, 'awv_id': valueId.awv_id, 'cin_id': valueId.cin, 'status': statusId, 'title': 'CHECKLIST NOTES', 'hcc_code': valueId.HCC_Code, 'hcc_description': valueId.HCC_Description, 'risk_code_type': valueId.risk_code_type });
        props.currentAWERightSidebar(open);
        setIsDataGridCheked(false);
    };

    const loadformDataTable = () => {
        setFormDataList([]);
        var value = [];
        AWVApi.get('/get_member_hcc_details?cin=' + selectMemberId + '&year=' + props?.yearSelect + '&lob=' + props?.lobSelect + '&organisation_id=1')
            // AWVApi.get('/get_member_hcc_details?member_id=3RE5W09MM81')
            .then(res => {
                //  updating reponse output Yes/No will come in place of 0 or 1 in meat flag from backend these changes for expandableGrid
                res.data.forEach(element => {
                    element.meat_flag = element.meat_flag != null ? element.meat_flag == 1 ? 'Yes' : 'No' : null;
                });
                value.push(...res.data);
                for (let i = 0; i < value.length; i++) {
                    const data = value[i];
                    if (data.risk_code_type === 'RXC') {
                        data.HCC_Code = data.rxc_code;
                        data.HCC_Description = data.rxc_description;
                    }
                    if (data.source_code_type === 'CPT_CODE') {
                        data.DX_code = data.cpt_code;
                        data.DX_description = data.cpt_description;
                    }
                    else if (data.source_code_type === 'NDC') {
                        data.DX_code = data.ndc_code;
                        data.DX_description = data.ndc_description;
                    }
                }
                const hccCodeList = value.map((data) => data.HCC_Code);
                const rxcCodeList = value
                  .filter((data) => data.risk_code_type === 'RXC')
                  .map((data) => data.rxc_code);
                setHccCodeList(hccCodeList)
                setRXCCodeList(rxcCodeList)
                setFormDataList(value);
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <>
            <TableRow>
                <TableCell variant={'head'} colSpan={3}>
                    {/* <Button onClick={() => props.editActionData({}, selectMemberId, 'Add')} style={{ position: 'relative', color: 'white', padding: '3px 25px', fontFamily: "'Source Sans Pro', sans-serif", backgroundColor: "rgb(46 147 147)" }} > */}
                    <Button onClick={() => props.editActionData({}, selectMemberId, 'Add', aweId, hccCodeList, rxcCodeList)} style={{ position: 'relative', color: 'white', padding: '3px 20px', fontFamily: "'Source Sans Pro', sans-serif", backgroundColor: "rgb(46 147 147)" }} >
                        Add Risk Code
                    </Button>
                </TableCell>
                <TableCell variant={'head'} colSpan={2}>
                    Total Expected HCC
                </TableCell>
                <TableCell variant={'head'} colSpan={1}>
                    {formDataList && formDataList[0] && formDataList[0]?.Total_Expected_HCC ? formDataList[0].Total_Expected_HCC : 0}
                </TableCell>
                <TableCell variant={'head'} colSpan={1}>
                    Total Confirmed
                </TableCell>
                <TableCell variant={'head'} colSpan={0}>
                    {formDataList && formDataList[0] && formDataList[0]?.Total_Confirmed ? formDataList[0].Total_Confirmed : 0}
                </TableCell>
                <TableCell variant={'head'} colSpan={5}>
                     HCC Capture Rate -  {formDataList && formDataList[0] && formDataList[0]?.HCC_Capture_Rate ? (formDataList[0].HCC_Capture_Rate).toFixed(3) + ' %' : 0 + '%'}
                     &nbsp; <br></br>
                     <AWVNLPInsightPDF cin={selectMemberId} padding={'2px 10px'} position={'relative'} left = {'15%'} />
                     {selectMemberIdStatus && selectMemberIdStatus == 'PFC' ?
                         <NavLink to={'/awe/awe_coder_benchmark?cin=' + selectMemberId}>
                             <Button style={{ position: 'absolute', color: 'white', padding: '3px 5px 3px 5px', margin: '0 0 0 5px', fontFamily: "'Source Sans Pro', sans-serif", backgroundColor: "rgb(46 147 147)" }} >
                                 Coder Workbench
                             </Button>
                         </NavLink>
                         :
                         <></>
                     }
	            </TableCell>
            </TableRow>
            {/* Added scroller */}
            <TableCell style={{ padding: 0 }} colSpan={13} >
                <div
                    style={{
                        // width:"80vw", 
                        overflowX: "scroll",
                    }}>
                    <div style={{
                        width: "auto",
                    }}>
                        <TableRow>
                            {props.subColumns && props.subColumns.map((element, i) => {
                                return (
                                    <TableCell key={i} variant={'head'} style={{ minWidth: "6rem" }} >
                                        {element.label}
                                    </TableCell>
                                )
                            })
                            }
                        </TableRow>
                        {formDataList && formDataList.map((element, i) => {
                            return (
                                <TableRow>
                                    {props.subColumns && props.subColumns.map((ele, i) => {
                                        return (
                                            ele.label == 'ACTION' ?
                                                props.editActionData ?
                                                    <TableCell key={i} >
                                                        <span className="text-danger" style={{ cursor: 'pointer' }} onClick={() => props.editActionData(element, selectMemberId, 'Edit', aweId, hccCodeList, rxcCodeList)}>EDIT</span>
                                                        <Tooltip title="HCC Checklist Notes">
                                                            <a className="hyperlink " style={{ cursor: "pointer", color: "#209f9d" }} onClick={() => notificationRightDrawer(true, 'memberHccRemark', element, '', selectMemberId)}>
                                                                <NoteAddIcon color="#209f9d" style={{ width: "1.1em", height: "1.2em" }} />
                                                            </a>
                                                        </Tooltip>
                                                    </TableCell>
                                                    :
                                                    <TableCell key={i} >
                                                        <span className="text-danger" style={{ cursor: 'pointer' }} >EDIT</span>
                                                    </TableCell>
                                                : ele.customRender ?
                                                    <TableCell key={i}>
                                                        <span style={{ width: '75px', textAlign: "center", backgroundColor: ele.backgroundColor }} className="expand-grid-warning-status pt-1 pb-1 pl-3 pr-3" >{element[ele.name]}</span>
                                                    </TableCell>
                                                    :
                                                    <TableCell key={i}>
                                                        <p style={{ minWidth: "6rem", alignItems: "center" }}>{element[ele.name]}</p>
                                                    </TableCell>
                                        )
                                    })}
                                </TableRow>

                            )

                        })}
                    </div>
                </div>
            </TableCell>

        </>
    )
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        lobSelect: state.moduleFilter.lobSelect,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(AWVExpandableGridRow);