import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import WidgetCard from "../../components/Cards/WidgetCard";
import SingleVerticalBarChart from '../../components/Charts/SingleVerticalBar.Chart';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag, hccSeperator, numberWithCommas } from '../../assets/constants/customRender'
import history from "../../history";
import { MAO_MEMBER_FORCAST, WIDGET_TYPES,MAO_EXITING_MEMBER_FORCAST } from "../../assets/constants/string.constants";
import { percentFormat } from "../../assets/helpers/formats.helpers";
import CardComponent from "../../components/Cards/card";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import GChartClick from '../../components/GChart/GChartClick';
import IssuersACA from '../ACA/ACAIssuers.page';
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import SubHeader from '../../components/home/header/subHeader';
import { getViewBySelect } from '../../assets/constants/viewByList';
import { getModelSelect } from '../../assets/constants/modelList';
import { getMetalTierSelect } from '../../assets/constants/metalTierList';
import { getPlanSelect } from '../../assets/constants/planList';
import { getModuleSelect } from '../../assets/constants/moduleList';
import Link from '@material-ui/core/Link';
import BarVertical from '../../components/GChart/BarVertical';
import { getMeInitialSelect} from '../../assets/constants/meInitialList';
import { connect } from 'react-redux';
const MaoOrecForecast = (props) => {

    const [ProviderSummaryChartData, setProviderSummaryChartData] = useState([]);
    const [ProviderRevenueChartData, setProviderRevenueChartData] = useState([]);
    const [WidgetDetails, setWidgetDetails] = useState({});
    const [MembersForecastData, setMembersForecastData] = useState(localStorage.getItem(MAO_MEMBER_FORCAST));


    const WidgetDetailsType = [
        WIDGET_TYPES.LOCKICON,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.PERCENT,
        WIDGET_TYPES.SCORE,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.TRENDING,
        WIDGET_TYPES.PERCENT,
    ]

    const type = WidgetDetailsType;
    const widgetStyles = {
        position: 'absolute',
        left: 0,
        //right: 20,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.15,
        color: "white"
    };

    const loadWidgetDetails = () => {
        api.get('/forecastDemographicSummary/type/'+MembersForecastData+'/year/'+props.yearSelect)
        .then(res => {
            setWidgetDetails({
                'Existing Members': Number(res[0].existing_member).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                'Projected Members': Number(res[0].projected_member).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                'Forecasted Avg RAF Score': res[0].forecasted_avg_raf,
                'Forecasted Revenue': '$'+ Number(res[0].forecasted_revenue).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
           });
        })
        .catch(err => {
            // console.log(err);
        });
    };

    const getIcon = (type) => {
        var data = ''
        switch (type) {
            case WIDGET_TYPES.ISSUER:
                data = <AccountBalanceTwoToneIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.MEMBERS:
                data = <PeopleAltIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERCENT:
                data = <ShowChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.SCORE:
                data = <BarChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERSON:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.CHILD:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.ADULT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.INFANT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.REVENUE:
                data = <AttachMoneyIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.COUNT:
                data = <ShoppingCartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.LOCKICON:
                data = <LockOpenIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.TRENDING:
                data = <TrendingUpIcon style={widgetStyles} />
                break;
        }

        return data;
    }

    useEffect(() => {    
    },  [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect()
    ]);


    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'EXISTING MEMBERS FORECAST BY '+MembersForecastData+' - '+props.yearSelect} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br/>

                <br/>
                <br/> 

        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,                
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(MaoOrecForecast);
