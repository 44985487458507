import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import WidgetCard from "../../components/Cards/WidgetCard";
import SingleVerticalBarChart from '../../components/Charts/SingleVerticalBar.Chart';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import GridTable from "../../components/TableGrids/GridTable.table";
import { anchorTag, hccSeperator, numberWithCommas, anchorTagnumberWithCommas } from '../../assets/constants/customRender'
import history from "../../history";
import { MAO_Total_Encounters, MAO_Encounter_Type, REPORTED_INSIGHTS, MEMBER_NAME, CARRIER_ID, PLAN_ID, GENDER, MAO_MEMBER_ID } from "../../assets/constants/string.constants";
import { percentFormat } from "../../assets/helpers/formats.helpers";
import CardComponent from "../../components/Cards/card";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import GChart from '../../components/GChart/GChart';
import IssuersACA from '../ACA/ACAIssuers.page';
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import SubHeader from '../../components/home/header/subHeader';
import { getViewBySelect } from '../../assets/constants/viewByList';
import { getModelSelect } from '../../assets/constants/modelList';
import { getMetalTierSelect } from '../../assets/constants/metalTierList';
import { getPlanSelect } from '../../assets/constants/planList';
import { getModuleSelect } from '../../assets/constants/moduleList';
import Link from '@material-ui/core/Link';
import { getMeInitialSelect } from '../../assets/constants/meInitialList';
import { connect } from 'react-redux';
//import google from "react-google-charts";
const MaoEncountersSummaryPage = (props) => {

    const [PresentYearData, setPresentYearData] = useState([]);
    const [PastYearData, setPastYearData] = useState([]);

    const PastYearColumns = [
        {
            name: "year",
            label: "RAF Year"
        },
        {
            name: "totalEncounters",
            label: "# Encounters",
            options: numberWithCommas
        },
        {
            name: "totalValidOriginalClaims",
            label: "# Valid Original Claim",
            options: numberWithCommas
        },
        {
            name: "totalVoidedOriginalClaim",
            label: "# Voided Claim",
            options: numberWithCommas
        },
        {
            name: "totalReplacedOriginalClaims",
            label: "# Replaced Original Claims",
            options: numberWithCommas
        },
        {
            name: "totalValidOriginalCRAdds",
            label: "# Valid Original CR Adds",
            options: numberWithCommas
        },
        {
            name: "totalVoidedCRAdds",
            label: "# Voided CR Adds",
            options: numberWithCommas
        },
        {
            name: "totalReplacedCRAdds",
            label: "# Replaced CR Adds",
            options: numberWithCommas
        },
        {
            name: "totalValidOriginalCRDeletes",
            label: "# Valid Original CR Deletes",
            options: numberWithCommas
        },
        {
            name: "totalVoidedCRDeletes",
            label: "# Voided CR Deletes",
            options: numberWithCommas
        },
        {
            name: "totalReplacedCRDeletes",
            label: "# Replaced CR Deletes",
            options: numberWithCommas
        },
    ]

    const PresentYearColumns = [
        {
            name: "period",
            label: "PERIOD",
        },
        {
            name: "totalEncounters",
            label: "# Encounters",
            options: anchorTagnumberWithCommas
        },
        {
            name: "totalValidOriginalClaims",
            label: "# Valid Original Claim",
            options: numberWithCommas
        },
        {
            name: "totalVoidedOriginalClaim",
            label: "# Voided Claim",
            options: numberWithCommas
        },
        {
            name: "totalReplacedOriginalClaims",
            label: "# Replaced Original Claims",
            options: numberWithCommas
        },
        {
            name: "totalValidOriginalCRAdds",
            label: "# Valid Original CR Adds",
            options: numberWithCommas
        },
        {
            name: "totalVoidedCRAdds",
            label: "# Voided CR Adds",
            options: numberWithCommas
        },
        {
            name: "totalReplacedCRAdds",
            label: "# Replaced CR Adds",
            options: numberWithCommas
        },
        {
            name: "totalValidOriginalCRDeletes",
            label: "# Valid Original CR Deletes",
            options: numberWithCommas
        },
        {
            name: "totalVoidedCRDeletes",
            label: "# Voided CR Deletes",
            options: numberWithCommas
        },
        {
            name: "totalReplacedCRDeletes",
            label: "# Replaced CR Deletes",
            options: numberWithCommas
        },
    ]


    const loadPresentYearEncounterSummary = () => {
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/PresentYearEncounterSummary/Maoyear/' + getAcaYear())
            .then(res => {
                setPresentYearData(res);
            })
            .catch(err => {
                // console.log(err)
            })

    }

    const loadPastYearEncounterSummary = () => {
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/PastYearEncounterSummary/Maoyear/' + getAcaYear())
            .then(res => {
                setPastYearData(res);
            })
            .catch(err => {
                // console.log(err)
            })
    }

    const memberTableClick = (colData, cellMeta, data, columns) => {
        if ((cellMeta.colIndex === 1) && (colData.props.children != "")) {
            localStorage.setItem(MAO_Encounter_Type, 'O');
            localStorage.setItem(MAO_Total_Encounters, 10);
            history.push('/layout/medicare/allowed_disallowed_summary');
        }
    }

    //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            loadPresentYearEncounterSummary();
            loadPastYearEncounterSummary();
        }
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect()
    ]);

    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Encounters Summary'} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-4">
                    <CardComponent
                        heading={`MAO STATISTICS SNAPSHOT - ` + props.yearSelect}
                        body={
                            <div className="box-height-chart">
                                <GridTable
                                    data={PresentYearData}
                                    columns={PresentYearColumns}
                                    onTableClick={memberTableClick}
                                ></GridTable>
                            </div>
                        }></CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-4">
                    <CardComponent
                        heading={`PREVIOUS YEARS`}
                        body={
                            <div className="box-height-chart">
                                <GridTable
                                    data={PastYearData}
                                    columns={PastYearColumns}
                                ></GridTable>
                            </div>
                        }></CardComponent>
                </div>

                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br />

                <br />
                <br />

            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default connect(mapStateToProps)(MaoEncountersSummaryPage);
