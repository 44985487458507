/**
 * @file AWS Secrets Manager Helper
 * @description This module provides a helper function to retrieve secret values from AWS Secrets Manager.
 * @author SONAL JAIN
 * @requires aws-sdk
 * @created 07/04/2024
 */

const AWS = require('aws-sdk');

/**
 * Retrieves a secret value from AWS Secrets Manager.
 * @param {string} secretName - The name of the secret.
 * @returns {Promise<string>} - A promise that resolves to the secret value.
 */
const LogFileUploadToS3 = (logMessages, accessKeyId, secretAccessKey, bucketName, fileName ) => {
    // Upload log messages to S3 bucket when logMessages have been updated
    if (logMessages.length > 0) {
        const logFileContent = logMessages.join('\n'); // Concatenate log messages

        const bucketNameToUpload = bucketName; // Specify your S3 bucket name
        const fileNameToUpload = fileName + '_' + new Date().toISOString() + '.log' 

        const s3 = new AWS.S3({
            accessKeyId: accessKeyId, // Specify your AWS access key ID
            secretAccessKey: secretAccessKey, // Specify your AWS secret access key
            region: "us-west-2", // Specify the AWS region
            signatureVersion: 'v4', // Specify Signature Version 4
        });

        const params = {
            Bucket: bucketNameToUpload,
            Key: process.env.REACT_APP_LOG_FOLDER + fileNameToUpload,
            Body: logFileContent,
            ServerSideEncryption: 'aws:kms', // Server-side encryption using KMS
            SSEKMSKeyId: process.env.REACT_APP_KeyID, // KMS key ID for encryption
            ContentDisposition: "inline", // Specify Content-Disposition header
        };

        // Upload the log file to S3 bucket
        s3.upload(params, (err, data) => {
            if (err) {
                console.error("Error uploading log file to S3:", err);
            } else {
                console.log("Log file uploaded successfully:", data.Location);
            }
        });
    }
        // This component doesn't need to render anything in the UI, so return null
        return null;
};

module.exports = { LogFileUploadToS3 };
