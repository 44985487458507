import api from './Rafservice.Instance';
import { 
    SELECT_YEAR, SELECT_INITIAL, 
    SELECT_METAL_TIER, SELECT_VIEW_BY,
    SELECT_PLAN, SELECT_MODEL
} from "../../assets/constants/string.constants";

let Model_list = [];
let Model_select = '';
export const ModelList = () =>{

    // api.get('/aca_yearList/')
    // .then(res=>{
        SetModelList(['All','Adult','Child','Infant']);   
        if(localStorage.getItem(SELECT_MODEL) && localStorage.getItem(SELECT_MODEL) != ''){
            // localStorage.setItem('YEAR', res[0]);            
            // SetAcaYear(res[0]);
            SetModelSelect(localStorage.getItem(SELECT_MODEL)); 
        }else{
            localStorage.setItem(SELECT_MODEL, 'All');            
            SetModelSelect('All'); 
        }  
    // })
    // .catch(err=>{
    //     // console.log(err);
    // })
}

export const SetModelList = (data) =>{
    Model_list = data;
}

export const SetModelSelect = (data) =>{
    Model_select = data;
    localStorage.setItem(SELECT_MODEL, data);
}

export const getModelList = () =>{
    return Model_list;
}

export const getModelSelect = () =>{
    return Model_select;
}