import React from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { signOut } from '../../store/actions';
import { currentRoute, currentAWERightSidebarType, currentAWERightSidebar } from '../../store/actions';
import { makeStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/awsLayouts/adminStyle.js";
import bgImage from "../../assets/images/sidebar.jpeg";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import mainLogo from '../../assets/images/Invent_logo_White.png';
import ScrollToTopAwv from '../ScrollToTop/ScrollToTopAwv';
// core components
//import Navbar from "../Navbars/Navbar.js";
//import Footer from  "../Footer/Footer.js"
import AwvSidebar from "../Sidebar/AwvSidebar.js";
//import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import routes from "../../routes/awvRoutes.js";
import logo from "../../assets/images/logo.png";
import Awvheader from '../../components/home/header/Awvheader';
import AwvFooter from '../../components/Footer/AwvFooter';

import UploadAWVForm from "../../Pages/AWV/UploadAWVForm.page";
import UploadAWVWorkFlow from "../../Pages/AWV/UploadAWVWorkFlow.page";
import AWVMemberDetails from '../../Pages/AWV/AWVMemberDetails.js';
import AWVCoderBenchmark from '../../Pages/AWV/coder_workbench/AWVCoderBenchmark';

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ReorderIcon from '@mui/icons-material/Reorder';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import RightSideBar from '../customRightSidebar/RightSideBar';


const drawerWidth = 240;

const openedMixin = (theme) => ({
  minWidth: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1.5),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


const useStyles = makeStyles(styles);

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/layout") {
        // // console.log('Paths layout',prop.layout + prop.path);
        return (
          <>
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
            {
              prop.subRoutes && prop.subRoutes.length > 0 ?
                prop.subRoutes.map((subRoute, key1) => {
                  <Route
                    path={subRoute.layout + subRoute.path}
                    component={subRoute.component}
                    key={key1}
                  />
                })
                : ''
            }
          </>
        );
      }
      return null;
    })}
    <Redirect from="/awe" to="/awe/awe_analytics_summary" />
  </Switch>
);

let ps;
function AwvLayout(props) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [image, setImage] = React.useState(bgImage);
  const [color, setColor] = React.useState("blue");
  //const [fixedClasses, setFixedClasses] = React.useState("dropdown show");
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [notificatinDrawer, setNotificatinDrawer] = React.useState(false);
  React.useEffect(() => {
    document.getElementsByTagName("body")[0].classList.add("normal-layout-container")
    document.getElementsByTagName("body")[0].classList.remove("awe-layout-container")
  }, [])
  //console.log("code-material-ui-image", Image);
  const handleImageClick = image => {
    setImage(image);
  };
  // const handleColorClick = color => {
  //   setColor(color);
  // };
  // const handleFixedClick = () => {
  //   if (fixedClasses === "dropdown") {
  //     setFixedClasses("dropdown show");
  //   } else {
  //     setFixedClasses("dropdown");
  //   }
  // };

  const handleDrawerToggle = () => {
    // console.log("code-layout");
    setMobileOpen(!mobileOpen);
  };
  const getRoute = () => {
    return window.location.pathname !== "/awv/maps";
  };
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const notificationRightDrawer = (open, drawerType = '') => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (drawerType && drawerType?.type && drawerType?.type != '') {
      props.currentAWERightSidebarType(drawerType);
    } else {
      props.currentAWERightSidebarType({ 'type': 'notification' });
    }
    props.currentAWERightSidebar(open);
  };

  const getMenuPermissionCheck = (permissionSlug) => {
    var permissionCheck = false;
    if (permissionSlug && permissionSlug != '') {
      var permissionGets = JSON.parse(localStorage.getItem('controlPermissions'));
      if (permissionGets && permissionGets.length > 0) {
        permissionGets && permissionGets.forEach(elem => {
          if (elem.permission == permissionSlug && elem.status == true) {
            permissionCheck = true;
          }
        });
      }
    }
    return permissionCheck;
  }

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    // if (navigator.platform.indexOf("Win") > -1) {
    //   ps = new PerfectScrollbar(mainPanel.current, {
    //     suppressScrollX: true,
    //     suppressScrollY: false
    //   });
    //   document.body.style.overflow = "hidden";
    // }
    // window.addEventListener("resize", resizeFunction);
    // // Specify how to clean up after this effect:
    // return function cleanup() {
    //   if (navigator.platform.indexOf("Win") > -1) {
    //   }
    //   window.removeEventListener("resize", resizeFunction);
    // };
  }, [mainPanel]);
  return (
    <div className="App awv_layout" id="BODYTOPLEVEL">
      <div className={classes.wrapper}>
        <Box sx={{ display: 'flex' }} style={{ width: '100%', important: 'true' }}>
          <CssBaseline />
          {/* <AppBar position="fixed" open={open} >
            <Toolbar>
            </Toolbar>
          </AppBar> */}
          <Drawer
            variant="permanent"
            open={open}
            className="sidebar_custom"
            style={{ width: open ? '20%' : '5%', important: 'true' }}
          >
            <DrawerHeader>
              {open ?
                <div>
                  <div className={classes.logoImage}>
                    <img src={mainLogo} width="100" height="100" alt="" className="logo"></img>
                  </div>
                  <IconButton onClick={handleDrawerClose} >
                    {theme.direction === 'rtl' ? <MenuIcon style={{ color: '#FFF' }} /> : <MenuIcon style={{ color: '#FFF' }} />}
                  </IconButton>
                </div>
                :
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  style={{ color: '#FFF' }}
                  sx={{
                    ...(open && { display: 'none' }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
              }

            </DrawerHeader>
            <Divider className="sidebar-awv-custom" />
            <AwvSidebar
              routes={routes}
              logo={logo}
              image={image}
              handleDrawerToggle={handleDrawerToggle}
              open={open}
              color={color}
            />
          </Drawer>
          <Box component="main" style={{ width: open ? '80%' : '95%', important: 'true' }} >
            {/* <DrawerHeader /> */}
            <Awvheader notificationRightDrawer={notificationRightDrawer} />
            <ScrollToTopAwv>
              <div className={classes.content} style={{ flexGrow: 1, paddingBottom: '25px', paddingLeft: '25px', paddingRight: '25px' }}>
                <Route path="/awe/awe_form_list" component={UploadAWVForm} />
                <Route path="/awe/awe_analytics_summary" component={UploadAWVWorkFlow} />
                <Route path="/awe/AWEMemberDetails" component={AWVMemberDetails} />
                <Route path="/awe/awe_coder_benchmark" component={AWVCoderBenchmark} />
                <div class="clearfix"></div>
                {/*
                <div className='row' style={{ marginRight: "-25px" }}>
                  <div className='col-md-12 pl-0 pr-0' style={{ height: "75px", backgroundColor: "rgb(227, 227, 227)" }}>
                    <AwvFooter />
                  </div>
                </div>
                */}
              </div>


            </ScrollToTopAwv>

          </Box>
          <RightSideBar notificationRightDrawer={notificationRightDrawer} ></RightSideBar>
        </Box>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userName: state.auth?.userDetails?.userEmail,
    route: state.route.route,
    moduleSelect: state.moduleFilter.moduleSelect,
    aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
    aweRightSidebar: state.moduleFilter.aweRightSidebar,
  };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentRoute })(AwvLayout);
