import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import GridTable from "../../components/TableGrids/GridTable.table";
import CardComponent from "../../components/Cards/card";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import BarVerticalHCC from '../../components/GChart/BarVerticalHCC';
import { anchorTag, hccSeperator, numberWithCommasPrefixDolar } from '../../assets/constants/customRender'
import history from "../../history";
import SubHeader from '../../components/home/header/subHeader';
import { MAO_Chart_Review_Member, HCC, WIDGET_TYPES } from "../../assets/constants/string.constants";
import GChart from '../../components/GChart/GChart';
import { connect } from 'react-redux';
import { getMeInitialSelect } from '../../assets/constants/meInitialList';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import Moment from 'react-moment';

const MaoChartReviewHccSummaryPage = (props) => {

    const [ProviderAddChartData, setProviderAddChartData] = useState([]);
    const [ProviderDeleteChartData, setProviderDeleteChartData] = useState([]);

    const OverallSummaryColumn = [
        {
            name: "addedHCCs",
            label: "Added HCCs"
        },
        {
            name: "deletedHCCs",
            label: "Deleted HCCs",
        },
        {
            name: "addedICDs",
            label: "Added ICDs",
        },
        {
            name: "deletedICDs",
            label: "Deleted ICDs",
        },
        {
            name: "memberWithChartReview",
            label: "Members with Chart Review",
        },
        {
            name: "crProvidersCount",
            label: "Number of Providers Submitting Chart Review",
        },
        {
            name: "totalMembers",
            label: "No of Members",
        },
        {
            name: "overallReportedRevenue",
            label: "Overall Reported Revenue",
            options: numberWithCommasPrefixDolar
        },
        {
            name: "revenueFromCR",
            label: "Revenue From CR",
            options: numberWithCommasPrefixDolar
        },
        {
            name: "overallRevenueWithCR",
            label: "Overall Revenue With CR",
            options: numberWithCommasPrefixDolar
        },
    ]


    const loadProviderAddChartdData = () => {
        let newChartData = [];
        newChartData.push(["HCC's", "Member Count", { role: 'annotation' }]);
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/crHccSummaryAdded/year/' + getAcaYear() + '/period/' + getMeInitialSelect())
            .then(res => {
                for (const dataObj of res) {
                    newChartData.push([dataObj.description + ':' + dataObj.hccCode, dataObj.memberCount, dataObj.memberCount]);
                }
                setProviderAddChartData(newChartData);
            })
            .catch(err => {
                // console.log(err);
            })
    }

    const loadProviderDeleteChartdData = () => {
        let newChartData = [];
        newChartData.push(["HCC's", "Member Count", { role: 'annotation' }]);
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/crHccDeleted/' + getAcaYear() + '/period/' + getMeInitialSelect())
            .then(res => {
                for (const dataObj of res) {
                    newChartData.push([dataObj.description + ':' + dataObj.hccCode, dataObj.memberCount, dataObj.memberCount]);
                }
                setProviderDeleteChartData(newChartData);
            })
            .catch(err => {
                // console.log(err);
            })
    }

    //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            loadProviderAddChartdData();
            loadProviderDeleteChartdData();
        }
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect()
    ]);

    const providerAddColumn = [
        {
            label: "Added HCC Code",
            name: "code",
            options: anchorTag
        },
        {
            name: "description",
            label: "Description",
        },
        {
            name: "memberCount",
            label: "# Members having this condition added",
        },
        {
            name: "providerCount",
            label: "# Provider Contributed to this condition added",
        },
    ];

    const providerDeleteColumn = [
        {
            label: "Deleted HCC Code",
            name: "code",
            options: anchorTag
        },
        {
            name: "description",
            label: "Description",
        },
        {
            name: "memberCount",
            label: "# Members having this condition Deleted",
        },
        {
            name: "providerCount",
            label: "# Provider Contributed to this condition Deleted",
        },
    ];

    const providerAddTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].code);
            history.push('/layout/medicare/chart_review_hcc_add')
        }
    }

    const providerDeleteTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].code);
            history.push('/layout/medicare/chart_review_hcc_delete')
        }
    }

    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Chart Review HCC Summary'} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-4">
                    <CardComponent
                        heading={`TOP HCC'S ADDED IN THIS CHART REVIEW`}
                        body={
                            <div className="box-height-chart">
                                <BarVerticalHCC data={ProviderAddChartData} chartType="BarChart" height="300px" width="100%" setStorgeItemName={HCC} redirectUrl={'medicare/chart_review_hcc_add'}></BarVerticalHCC>
                            </div>
                        }></CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-4">
                    <div className="list-member">
                        <CardComponent
                            heading={`HCC'S ADDED`}
                            body={
                                <ServerSideGridTable
                                    url={`/crhccsadd/${getAcaYear()}/period/${getMeInitialSelect()}`}
                                    columns={providerAddColumn}
                                    tableClick={providerAddTableClick}
                                ></ServerSideGridTable>
                            }>
                        </CardComponent>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-4">
                    <CardComponent
                        heading={`TOP HCC'S DELETED IN THIS CHART REVIEW`}
                        body={
                            <div className="box-height-chart">
                                <BarVerticalHCC data={ProviderDeleteChartData} chartType="BarChart" height="300px" width="100%" setStorgeItemName={HCC} redirectUrl={'medicare/chart_review_hcc_delete'}></BarVerticalHCC>
                            </div>
                        }></CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-4">
                    <div className="list-member">
                        <CardComponent
                            heading={`HCC'S DELETED`}
                            body={
                                <ServerSideGridTable
                                    url={`/crHccDel/${getAcaYear()}/period/${getMeInitialSelect()}`}
                                    columns={providerDeleteColumn}
                                    tableClick={providerDeleteTableClick}
                                ></ServerSideGridTable>
                            }>
                        </CardComponent>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br />

                <br />
                <br />
            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default connect(mapStateToProps)(MaoChartReviewHccSummaryPage);
