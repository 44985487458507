import React, { useRef, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar } from '../../store/actions';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import NotificationAWE from './notificationAWE';
import TimelineAWE from './timelineAWE';
import QuickNotesAWE from './QuickNotesAWE';
import AddAWVFileUpload from './AddAWVFileUpload';
import StatusAWVFileUpload from './StatusAWVFileUpload';
import OnHoldAWVFileUpload from './OnHoldAWVFileUpload';
import ViewAWVFileUpload from './ViewAWVFileUpload';
import AWVMemberDetails from './AWVMemberDetails';
import AWVMemberDetailsCoder from './AWVMemberDetailsCoder';
import AWVMemberDetailsCoodinator from './AWVMemberDetailsCoodinator';
import AWVAdditionalAttachments from './AWVAdditionalAttachments';
import AWVMemberDetailsMultiCoder from './AWVMemberDetailsMultiCoder';
import AWEProcessedRecord from './AWEProcessedRecord';
import MemberHCCRemark from './MemberHCCRemark';
import StageChange from "./StageChange";
import ViewCWDXCoderWorkBench from './ViewCWDXCoderWorkBench';
import ViewCWPROCCODECoderWorkBench from './ViewCWPROCCODECoderWorkBench';
import ViewMEDICATIONCoderWorkBench from './ViewMEDICATIONCoderWorkBench';
import EditCWDXCoderWorkBench from './EditCWDXCoderWorkBench';
import EditCWPROCCODECoderWorkBench from './EditCWPROCCODECoderWorkBench'; 
import EditMEDICATIONCoderWorkBench from './EditMEDICATIONCoderWorkBench';
import StatusCWDXCoderWorkBench from './StatusCWDXCoderWorkBench';
import StatusCWPROCCODECoderWorkBench from './StatusCWPROCCODECoderWorkBench';
import StatusMEDICATIONCoderWorkBench from './StatusMEDICATIONCoderWorkBench';

const RightSideBar = (props) => {

    useEffect(() => {
        console.log(props.aweRightSidebarType);
    }, [props.aweRightSidebar, props.aweRightSidebarType]);

    const { aweRightSidebarType } = props;

    const renderRightSidebarContent = () => {
        switch (aweRightSidebarType?.type) {
            case 'notification':
                return <NotificationAWE />;
            case 'timeline':
                return <TimelineAWE AwvId={aweRightSidebarType?.awv_id} CinId={aweRightSidebarType?.cin_id} />;
            case 'quickNotes':
                return <QuickNotesAWE AwvId={aweRightSidebarType?.awv_id} CinId={aweRightSidebarType?.cin_id} />;
            case 'additionalAttachments':
                return <AWVAdditionalAttachments AwvId={aweRightSidebarType?.awv_id} CinId={aweRightSidebarType?.cin_id} />;
            case 'addAweFileUpload':
                return <AddAWVFileUpload AwvId={aweRightSidebarType?.awv_id} />;
            case 'statusAweFileUpload':
                return <StatusAWVFileUpload AwvId={aweRightSidebarType?.awv_id} />;
            case 'onHoldAweFileUpload':
                return <OnHoldAWVFileUpload AwvId={aweRightSidebarType?.awv_id} />;
            case 'viewAweFileUpload':
                return <ViewAWVFileUpload AwvId={aweRightSidebarType?.awv_id} />;
            case 'memberHccDetailsReport':
                return <AWVMemberDetails />;
            case 'memberCoderChecklistReport':
                return <AWVMemberDetailsCoder />;
            case 'memberCoordinatorChecklistReport':
                return <AWVMemberDetailsCoodinator />;
            case 'memberMultiCoderChecklistReport':
                return <AWVMemberDetailsMultiCoder />;
            case 'aweProcessedRecord':
                return <AWEProcessedRecord form_url={aweRightSidebarType?.form_url} cin={aweRightSidebarType?.cin} aweId={aweRightSidebarType?.aweId} />;
            case 'memberHccRemark':
                return <MemberHCCRemark
                    AwvId={aweRightSidebarType?.awv_id}
                    CinId={aweRightSidebarType?.cin_id}
                    title={aweRightSidebarType?.title}
                    hcc_code={aweRightSidebarType?.hcc_code}
                    hcc_description={aweRightSidebarType?.hcc_description}
                    risk_code_type={aweRightSidebarType?.risk_code_type}
                />;
            case 'ViewCWDXCoderWorkBench':
                return <ViewCWDXCoderWorkBench Id={aweRightSidebarType?.id} />;
            case 'ViewCWPROCCODECoderWorkBench':
                return <ViewCWPROCCODECoderWorkBench Id={aweRightSidebarType?.id} />;
            case 'ViewMEDICATIONCoderWorkBench':
                return <ViewMEDICATIONCoderWorkBench Id={aweRightSidebarType?.id} />;
            case 'EditCWDXCoderWorkBench':
                return <EditCWDXCoderWorkBench Id={aweRightSidebarType?.id} />;
            case 'EditCWPROCCODECoderWorkBench':
                return <EditCWPROCCODECoderWorkBench Id={aweRightSidebarType?.id} />;
            case 'EditMEDICATIONCoderWorkBench':
                return <EditMEDICATIONCoderWorkBench Id={aweRightSidebarType?.id} />;
            case 'StatusCWDXCoderWorkBench':
                return <StatusCWDXCoderWorkBench Id={aweRightSidebarType?.id} />;
            case 'StatusCWPROCCODECoderWorkBench':
                return <StatusCWPROCCODECoderWorkBench Id={aweRightSidebarType?.id} />;
            case 'StatusMEDICATIONCoderWorkBench':
                return <StatusMEDICATIONCoderWorkBench Id={aweRightSidebarType?.id} />;
            case 'stageChange':
                return  <StageChange AwvId={aweRightSidebarType?.awv_id} />;
            default:
                return <></>;
        }
    };

    return (
        <>
            {props.aweRightSidebar && props.aweRightSidebar == true ?
                <SwipeableDrawer
                    className="awv_layout_right_sidebar"
                    anchor={'right'}
                    open={props.aweRightSidebar}
                    onClose={props.notificationRightDrawer(false)}
                    onOpen={props.notificationRightDrawer(true)}
                >
                    {aweRightSidebarType ? renderRightSidebarContent() : <></>}
                    {/* {props.aweRightSidebarType?.type == 'notification' ?
                        <NotificationAWE />
                        : props.aweRightSidebarType?.type == 'timeline' ?
                            <TimelineAWE AwvId={props.aweRightSidebarType?.awv_id} CinId={props.aweRightSidebarType?.cin_id} />
                            : props.aweRightSidebarType?.type == 'quickNotes' ?
                                <QuickNotesAWE AwvId={props.aweRightSidebarType?.awv_id} CinId={props.aweRightSidebarType?.cin_id} />
                                : props.aweRightSidebarType?.type == 'additionalAttachments' ?
                                    <AWVAdditionalAttachments AwvId={props.aweRightSidebarType?.awv_id} CinId={props.aweRightSidebarType?.cin_id} />
                                    : props.aweRightSidebarType?.type == 'addAweFileUpload' ?
                                        <AddAWVFileUpload AwvId={props.aweRightSidebarType?.awv_id} />
                                        : props.aweRightSidebarType?.type == 'statusAweFileUpload' ?
                                            <StatusAWVFileUpload AwvId={props.aweRightSidebarType?.awv_id} />
                                         : props.aweRightSidebarType?.type == 'stageChange' ?
                                            <StageChange AwvId={props.aweRightSidebarType?.awv_id} />
                                            : props.aweRightSidebarType?.type == 'onHoldAweFileUpload' ?
                                                <OnHoldAWVFileUpload AwvId={props.aweRightSidebarType?.awv_id} />
                                                : props.aweRightSidebarType?.type == 'viewAweFileUpload' ?
                                                    <ViewAWVFileUpload AwvId={props.aweRightSidebarType?.awv_id} />
                                                    : props.aweRightSidebarType?.type == 'memberHccDetailsReport' ?
                                                        <AWVMemberDetails />
                                                        : props.aweRightSidebarType?.type == 'memberCoderChecklistReport' ?
                                                            <AWVMemberDetailsCoder />
                                                            : props.aweRightSidebarType?.type == 'memberCoordinatorChecklistReport' ?
                                                                <AWVMemberDetailsCoodinator />
                                                                : props.aweRightSidebarType?.type == 'memberMultiCoderChecklistReport' ?
                                                                    <AWVMemberDetailsMultiCoder />
                                                                    : props.aweRightSidebarType?.type == 'aweProcessedRecord' ?
                                                                        <AWEProcessedRecord form_url={props.aweRightSidebarType?.form_url} cin={props.aweRightSidebarType?.cin} aweId={props.aweRightSidebarType?.aweId} />
                                                                        : props.aweRightSidebarType?.type == 'memberHccRemark' ?
                                                                            <MemberHCCRemark AwvId={props.aweRightSidebarType?.awv_id} CinId={props.aweRightSidebarType?.cin_id} title={props.aweRightSidebarType?.title}
                                                                                hcc_code={props.aweRightSidebarType?.hcc_code} hcc_description={props.aweRightSidebarType?.hcc_description} />
                                                                            :
                                                                            <></>
                    } */}
                </SwipeableDrawer>
                :
                <></>
            }
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        userName: state.auth?.userDetails?.userEmail,
        route: state.route.route,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar })(RightSideBar);
