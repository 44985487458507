const awsAmplify = {
    "aws_project_region": "us-west-2",
"aws_cognito_identity_pool_id": "us-west-2:ee53c1ad-cf02-4aef-913c-47a2a13739fc",
"aws_cognito_region": "us-west-2",
"aws_user_pools_id": "us-west-2_yZC85e2xD",
"aws_user_pools_web_client_id": "7kr4vgthdoli1e7scc8s4jdnsc",
"oauth": {},
"aws_cognito_username_attributes": [],
"aws_cognito_social_providers": [],
"aws_cognito_signup_attributes": [
    "EMAIL"
],
"aws_cognito_mfa_configuration": "OFF",
"aws_cognito_mfa_types": [
    "SMS"
],
"aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": 8,
    "passwordPolicyCharacters": []
},
"aws_cognito_verification_mechanisms": [
    "EMAIL"
],
"aws_cloud_logic_custom": [
    {
        "name": "quicksight",
        "endpoint": "https://8is116ehse.execute-api.us-west-2.amazonaws.com/dev",
        "region": "us-west-2"
    }
]
};

export default awsAmplify;
