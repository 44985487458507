import api from '../constants/Rafservice.Instance';
import AcaMedicareRoutes from "../../routes/routes";
let Module_list = [];
let Module_select = '';
export const ModuleList = () => {

    const getModulePermissionCheck = (layout) => {
        var permissionCheck = false;
        var loacalPermissionGet = JSON.parse(localStorage.getItem('controlPermissions'));
        if (AcaMedicareRoutes && AcaMedicareRoutes.length > 0 && loacalPermissionGet && loacalPermissionGet.length > 0) {
            AcaMedicareRoutes.forEach(element => {
                if(layout == element.module){
                    if (loacalPermissionGet.find(({ permission }) => permission === element.permissionSlug)) {
                        permissionCheck = true;
                        return permissionCheck;
                    }
                }
            });
        }
        return permissionCheck;
    }

    // api.get('/aca_yearList/')
    // .then(res=>{
    if(getModulePermissionCheck('ACA') && getModulePermissionCheck('Medicare')){
        SetModuleList(['ACA', 'Medicare']);
        SetModuleSelect('ACA');
    }else if(getModulePermissionCheck('ACA')){
        SetModuleList(['ACA']);
        SetModuleSelect('ACA');
    }else if(getModulePermissionCheck('Medicare')){
        SetModuleList(['Medicare']);
        SetModuleSelect('Medicare');
    }

    // })
    // .catch(err=>{
    //     // console.log(err);
    // })
}

export const SetModuleList = (data) => {
    Module_list = data;
}

export const SetModuleSelect = (data) => {
    Module_select = data;
}

export const getModuleList = () => {
    return Module_list;
}

export const getModuleSelect = () => {
    return Module_select;
}

