import React, { useState, useEffect } from "react";
import PPGSummaryServerSide from "../../components/TableGrids/PPGSummaryServerSide.table";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Link from "@material-ui/core/Link";
const AWS = require("aws-sdk");

const DownloadFileFromS3 = (props) => {
  const handleDownloadFromS3 = async (bucket, key) => {
    const setTableDataList = [];
    let keyInsideFolder = "";
    try {
      AWS.config.update({
        accessKeyId: localStorage.getItem("access_key"),
        secretAccessKey: localStorage.getItem("secret_key"),
        region: process.env.REACT_APP_REGION,
      });
      var s3 = new AWS.S3({
        signatureVersion: "v4",
      });
      let responseQuickList = "";
      let responseAWE = "";
      //for quickLinks
      if (props.isQuickLink) {
        responseQuickList = await s3
          .listObjects({
            Bucket: bucket,
            Prefix: "Quick_link",
          })
          .promise();
      }
      //for RAF
      if (props.isRAF) {
        responseAWE = await s3
          .listObjectsV2({
            Bucket: bucket,
          })
          .promise();
      }

      let Key;
      // Key set for quickLinks
      if (props.isQuickLink) {
        //set the file list state
        const fileList = responseQuickList.Contents.map(({ Key }) => Key);

        let idx = 0;
        fileList.forEach((value, index) => {
          if (value.includes(key)) {
            idx = index;
          }
        });
        Key = fileList[idx];
      }
      // key set for RAF
      else if (props.isRAF) {
        Key = key;

        const json_format = JSON.stringify(responseAWE.Contents);
        const stringify = JSON.parse(json_format);
        for (var i = 0; i < stringify.length; i++) {
          if (stringify[i]["Size"] != 0) {
            setTableDataList.push(stringify[i]);
          }
        }
        //Calling current date function, changes for getting 3 char from Month
        setTableDataList.forEach((element) => {
          if (element.Key.includes(Key))
            keyInsideFolder = element.Key.substr(
              element.Key.lastIndexOf("/") + 1
            );
        });
        const reportName = keyInsideFolder;
        Key += reportName;
      }

      //same key as user pass i.e : download Batch template
      else Key = key;

      //getting url for downloading files
      var presignedGETURL = s3.getSignedUrl("getObject", {
        Bucket: bucket,
        Key: Key,
      });
      window.location = presignedGETURL;
    } catch (e) {
      console.log("our error ", e);
    }
  };
  return (
    <>
      {props.isRAF && (
        <div
          style={{
            zIndex: "1",
            left: "79%",
            height: "35px",
            borderRight: `${props.isPPG ? "none" : "1px solid"}`,
          }}
        >
          <Button
            onClick={() =>
              handleDownloadFromS3(props.bucketName, props.bucketKey)
            }
          >
            {props.name}
          </Button>
        </div>
      )}

      {props.isQuickLink && (
        <Link
          href={"#"}
          className="custom-active quick-links"
          sx={{
            display: "flex",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          color="inherit"
          onClick={() =>
            handleDownloadFromS3(props.bucketName, props.bucketKey)
          }
        >
          {props.name}
        </Link>
      )}

      {props.isBatchTemplate && (
        <Button
          onClick={() =>
            handleDownloadFromS3(props.bucketName, props.bucketKey)
          }
          style={{
            position: "absolute",
            color: "white",
            padding: "9px 25px",
            fontFamily: "'Source Sans Pro', sans-serif",
            backgroundColor: "rgb(46 147 147)",
          }}
        >
          {props.name}
        </Button>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    moduleSelect: state.moduleFilter.moduleSelect,
    viewBySelect: state.moduleFilter.viewBySelect,
    modelSelect: state.moduleFilter.modelSelect,
    metalTierSelect: state.moduleFilter.metalTierSelect,
    planSelect: state.moduleFilter.planSelect,
    yearSelect: state.moduleFilter.yearSelect,
  };
};
export default connect(mapStateToProps)(DownloadFileFromS3);
