import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import {getAcaYear} from '../../assets/constants/yearList';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag, hccSeperator, addSuffixTwoDecimal } from "../../assets/constants/customRender";
import GridTable from "../../components/TableGrids/GridTable.table";
import SingleHorizontalBar from "../../components/Charts/SingleHorizontalBar.chart";
import history from '../../history';
import { GAP_INSIGHTS, HCC, GENDER,MAO_Chart_Review_Member, ISSUER_ID, MAO_MEMBER_SELECT_TAB, REPORTED_INSIGHTS } from "../../assets/constants/string.constants";
import CardComponent from "../../components/Cards/card";
import SubHeader from '../../components/home/header/subHeader';
import { connect } from 'react-redux';
import BarVerticalHCC from '../../components/GChart/BarVerticalHCC';
import { getMeInitialSelect} from '../../assets/constants/meInitialList';
//import google from "react-google-charts";
const MAOSubReportedHcc = (props) => {

    const [hcc, setHcc] = useState(localStorage.getItem(HCC));
    const [issuer_id] = useState(localStorage.getItem(ISSUER_ID));
    const [HccReportedChartData, setHccReportedChartData] = useState({});  
    const [HccGAPChartData, setHccGAPChartData] = useState({});  
    const [tableData, setTableData] = useState([]);
    
    //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
    const loadMetricTable = () =>{
        api.get('/gaphccDetail/hcc/'+hcc+'/year/'+getAcaYear()+'/period/'+getMeInitialSelect())
        .then(res=>{
            setTableData(res);
        })
        .catch(err=>{
            // // console.log(err);
        })
    }

    const  summaryColumns =[
        {
            name: "hccCode",
            label: "HCC Code ",
        },
        {
            name: "description",
            label: "Description",
        },
        {
            name: "totalCount",
            label: "# Members"
        },
        
    ]


    const memberColumn = [
        {
            label : "Member ID",
            name:"MEMBER_ID",
            options: anchorTag
        },
        {
            name : "hcclist",
            label : "Hcc List",
            options: hccSeperator
        },
        {
            name : "DEMOGRAPHIC_SCORE",
            label : "DEMOGRAPHIC SCORE",
            options:addSuffixTwoDecimal,
        },	
        
        {
            name : "OREC_SCORE",
            label : "OREC SCORE",
            options:addSuffixTwoDecimal,
        },
        {
            name : "HCC_SCORE",
            label : "HCC SCORE",
            options:addSuffixTwoDecimal,
        },
        {
            name : "INTERACTION_SCORE",
            label : "INTERACTION SCORE",
            options:addSuffixTwoDecimal,
        },
        {
            name : "RAF_SCORE",
            label : "RAF SCORE",
            options:addSuffixTwoDecimal,
        },
        {
            name : "REVENUE",
            label : "REVENUE"
        },
    ];

    const memberTableClick = (rowdata, colData, cellMeta) =>{
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(MAO_Chart_Review_Member, rowdata[cellMeta.rowIndex].MEMBER_ID);
            localStorage.setItem(MAO_MEMBER_SELECT_TAB, REPORTED_INSIGHTS);
            history.push('/layout/medicare/mao_member_summary')
        }else if ((cellMeta.colIndex === 1) && (colData.props.children != "")) {
            if(typeof(colData.props.children) == "object"){
                localStorage.setItem(HCC,(cellMeta.event.target.innerText).replace(',',''));
            }
            else{
                localStorage.setItem(HCC,rowdata[cellMeta.rowIndex].hcclist);
            }
            history.push('/layout/medicare/reported_hcc')
        }
    }

    const loadHccGAPChart = () =>{

        const  handlechartClick = (element) =>{    
            let chartData = element[0]['_chart'].config.data;
            let idx = element[0]['_index'];
            let hcc = chartData.labels[idx];
            let parts = hcc.split(':');
            let hcc1 = parts[1];
            localStorage.setItem(HCC,hcc1);
            localStorage.setItem(ISSUER_ID, issuer_id);
            history.push('/layout/AcaGapHcc');
        };

        let hccCode =[];
        let totalCount =[];
        let newChartData = [];
        newChartData.push(["HCC's", "Percentage", { role: 'annotation' }]);
        //Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
        api.get('/reportedToReported/year/'+getAcaYear()+'/hcc/'+hcc+'/period/'+getMeInitialSelect())
        .then(res=>{
            // console.log(res);
            for (const dataObj of res) {
                newChartData.push([dataObj.description+':'+dataObj.hccCode, dataObj.percentage, dataObj.percentage]);
            }
            setHccGAPChartData(newChartData);
        //     for (const dataObj of res) {
        //       hccCode.push((dataObj.description + ':' + dataObj.hccCode));
        //       totalCount.push(dataObj.percentage);
        //     }
        //     setHccGAPChartData({
        //     labels: hccCode,
        //     data: totalCount,
        //     dLabel: "Percentage",
        //     // hccCustomFunc :handlechartClick
        // });
        })
    }

    useEffect(() =>{
         loadMetricTable();
         loadHccGAPChart();
    },[      
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear(),
        getMeInitialSelect()
    ]);

    useEffect(() => {
        loadMetricTable();
        loadHccGAPChart();
    }, [hcc]);

    useEffect(() => {
        // console.log(localStorage.getItem(HCC));
        setHcc(localStorage.getItem(HCC));
    }, [localStorage.getItem(HCC)]);

    return(
        <>
            <div className="col-lg-12 pr-0 pl-0 mb-3">
                <CardComponent
                heading={`Hcc View - For year : ${props.yearSelect} `}
                body={
                    <GridTable
                        data={tableData}
                        columns={summaryColumns}
                    ></GridTable>
                }></CardComponent>
            </div>
            <div className="col-lg-12 pr-0 pl-0 mb-3">
                <CardComponent
                    heading={`Top 5 Co-occurring HCCs for this Gap Condition`}
                    body={
                        <BarVerticalHCC data={HccGAPChartData} chartType="BarChart" height="300px" width="100%" setStorgeItemName={HCC} redirectUrl={'medicare/chart_review_hcc_add'} hAxisTitle={'Percentage (%)'} yAxistitle={"HCC's"}></BarVerticalHCC>
               
                        // <SingleHorizontalBar
                        //     label = {HccGAPChartData.labels}
                        //     Data ={HccGAPChartData.data}
                        //     dLabel = {HccGAPChartData.dLabel}
                        //     suffix='%'  
                        //     xTick={'%'}
                        //     xLabel={'Percentage (%)'}
                        //     yLabel={"HCC's"}
                        // ></SingleHorizontalBar>
                }></CardComponent>
            </div>
            <div className="col-lg-12 pr-0 pl-0 mb-3">
                <CardComponent
                heading={`MEMBERS DETAILS`}
                body={
                    <ServerSideGridTable
                        url={`ReportedHcc/year/${getAcaYear()}/hcc/${hcc}/period/${getMeInitialSelect()}`}
                        columns={memberColumn}
                        tableClick={memberTableClick}
                    ></ServerSideGridTable>
                }></CardComponent>
            </div>
            <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
            <br/>
        </>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(MAOSubReportedHcc);
