import React from 'react'

export default function EditPenIcon({ ...props }) {
    return (
        <svg {...props} viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.25 22C3.25 21.5858 3.58579 21.25 4 21.25H20C20.4142 21.25 20.75 21.5858 20.75 22C20.75 22.4142 20.4142 22.75 20 22.75H4C3.58579 22.75 3.25 22.4142 3.25 22Z" fill="currentcolor" />
            <path d="M11.5201 14.9291L17.4368 9.01236C16.6315 8.67721 15.6777 8.12667 14.7757 7.22466C13.8736 6.32249 13.323 5.36857 12.9879 4.56323L7.07106 10.48C6.60937 10.9417 6.37846 11.1726 6.17992 11.4272C5.94571 11.7274 5.74491 12.0523 5.58107 12.3961C5.44219 12.6875 5.33894 12.9973 5.13245 13.6168L4.04356 16.8834C3.94194 17.1883 4.02128 17.5244 4.2485 17.7516C4.47573 17.9788 4.81182 18.0582 5.11667 17.9565L8.38334 16.8677C9.00281 16.6612 9.31256 16.5579 9.60398 16.419C9.94775 16.2552 10.2727 16.0544 10.5729 15.8202C10.8275 15.6216 11.0584 15.3908 11.5201 14.9291Z" fill="currentcolor" />
            <path d="M19.0786 7.37044C20.3071 6.14188 20.3071 4.14999 19.0786 2.92142C17.85 1.69286 15.8581 1.69286 14.6296 2.92142L13.9199 3.63105C13.9296 3.6604 13.9397 3.69015 13.9502 3.72028C14.2103 4.47 14.701 5.45281 15.6243 6.37602C16.5475 7.29923 17.5303 7.78999 18.28 8.05009C18.31 8.0605 18.3396 8.07054 18.3688 8.08021L19.0786 7.37044Z" fill="currentcolor" />
        </svg>
    )
}