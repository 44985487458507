/**
 * @file QuickSight Dashboard Embed Component
 * @description This component renders a QuickSight dashboard using the Amazon QuickSight Embedding SDK.
 * It retrieves the dashboard embed URL from an API and embeds the dashboard in a container element.
 * While the embedding is in progress, a loading spinner is displayed.
 * @requires react
 * @requires aws-amplify
 * @requires @material-ui/core/CircularProgress
 * @requires @material-ui/core/styles
 * @requires amazon-quicksight-embedding-sdk
 * @author SONAL JAIN
 * @created 11/05/2023
 */

import React from 'react';
import { API, Auth } from 'aws-amplify';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { getSecret } from '../../Pages/config/ParameterStore';
var QuickSightEmbedding = require("amazon-quicksight-embedding-sdk");

// Styles for the component
const useStyles = theme => ({
    loading: {
        alignContent: 'center',
        justifyContent: 'center',
        display: 'flex',
        marginTop: theme.spacing(4),
    },
});

class Embed extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: true
        };
        // Create a variable to store the mounted state of the component
        this.mounted = false;
    }

    componentDidMount() {
        const { dashboardIdName } = this.props;
        // set parameter store name to fetch configuration values of quick sight
        const secretNames = ['quick_sight_account_id', dashboardIdName, 'quick_sight_cognito_role_arn', 'quick_sight_identity_pool_id',
            'quick_sight_data_login', 'quick_sight_region'];
            this.mounted = true;
        getSecret(secretNames)
            .then(secrets => {
                
                /* Access the secret values & call the function to retrieve the QuickSight dashboard embed URL 
                when the component mounts */
                this.getQuickSightDashboardEmbedURL(secrets['quick_sight_account_id'], secrets[dashboardIdName],
                    secrets['quick_sight_cognito_role_arn'], secrets['quick_sight_identity_pool_id'], secrets['quick_sight_data_login'],
                    secrets['quick_sight_region']);
            })
            .catch(error => {
                console.error("Error retrieving secrets:", error);
            });
    }
    componentWillUnmount() {
        // Set mounted to false when the component unmounts
        this.mounted = false;
    }


    // Function to retrieve the QuickSight dashboard embed URL
    getQuickSightDashboardEmbedURL = async (accountId, dashboardId, roleArn, IdentityPoolId, logins, region) => {

        // Retrieve the current session to get the necessary data for authentication
        const data = await Auth.currentSession();
        const jwtToken = data.idToken.jwtToken;
        const payloadSub = data.idToken.payload.sub;
        const email = data.idToken.payload.email;

        // Import necessary functions from QuickSight Embedding SDK
        const {
            createEmbeddingContext,
        } = QuickSightEmbedding;

        // Create an embedding context
        // const embeddingContext = await createEmbeddingContext({
        //     onChange: (changeEvent, metadata) => {
        //         console.log('Context received a change', changeEvent, metadata);
        //     },
        // });

        // const embeddingContext = await createEmbeddingContext({
        //     onChange: (changeEvent, metadata) => {
        //         console.log('Context received a change', changeEvent, metadata);
        //     },
        //     onEmbeddableEvent: (event) => {
        //         console.log('Embeddable event:', event);
        //     },
        //     onError: (error) => {
        //         console.log('Embedding error:', error);
        //     },
        // });
        

        const embeddingContext = await createEmbeddingContext({
            onChange: (changeEvent, metadata) => {
                console.log('Context received a change', changeEvent, metadata);
            },
            onEmbeddableEvent: (event) => {
                console.log('Embeddable event:', event);
            },
            onError: (error) => {
                console.log('Embedding error:', error);
            },
            // timeout: 60000 // Set timeout to 60 seconds (adjust as needed)
        });
        

        // Get the container element for embedding the QuickSight dashboard
        const containerDiv = document.getElementById("dashboardContainer");
       

        // Call the API to retrieve the QuickSight dashboard embed URL
        const quicksight = await API.get('quicksight', '/getQuickSightDashboardEmbedURL', {
            headers: {},
            response: true,
            queryStringParameters: {
                jwtToken: jwtToken,
                payloadSub: payloadSub,
                email: email,
                accountId: accountId,
                dashboardId: dashboardId,
                roleArn: roleArn,
                IdentityPoolId: IdentityPoolId,
                logins: logins,
                region: region,
                userRole: this.props.role,
                embedConsole: this.props.embedConsole,
                urlParam: this.props.urlParam
            }
        });
        const originalUrl = quicksight.data.data.EmbedUrl;

        // Set options for embedding the QuickSight dashboard
        const options = {
            url: originalUrl,
            container: containerDiv,
            scrolling: "no",
            footerPaddingEnabled: false,
            height: "100%", // Set the height of the container div to 100% of its parent
            width: "100%", // Set the width of the container div to 100% of its parent
            onEmbeddingComplete: (params) => {
              const { width, height } = params;
              containerDiv.style.height = height + "px"; // Adjust the container div's height to match the embedded dashboard's height
            },
            onChange: (changeEvent, metadata) => {
                switch (changeEvent.eventName) {
                    case 'FRAME_MOUNTED': {
                        console.log("Do something when the experience frame is mounted.");
                        break;
                    }
                    case 'FRAME_LOADED': {
                        console.log("Do something when the experience frame is loaded.");
                        break;
                    }
                }
            },

        };

        const contentOptions = {
            locale: "en-US"
        };

        if (this.mounted) {
            try {
                if (this.props.embedConsole) {
                    await embeddingContext.embedConsole(options, contentOptions);
                } else {
                    await embeddingContext.embedDashboard(options, contentOptions);
                }
            } catch (error) {
                console.error("Error embedding QuickSight dashboard:", error);
                // Handle error as needed
            }
            } 
        // Hide the loader once the embedding is complete
        this.setState({ loader: false });
    };

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.state.loader && (
                    <div className={classes.loading}> <CircularProgress /> </div>
                )}
                <div id="dashboardContainer" className='autoFit'></div>
            </div>
        );
    }
}
export default withStyles(useStyles)(Embed);