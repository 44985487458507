import React, { useState, useEffect } from "react";
import { getAcaYear, getAcaYearList } from "../../assets/constants/yearList";
import WidgetCard from "../../components/Cards/WidgetCard";
import AcaYearSelect from "../../components/DropDowns/AcaYearSelect";
import api from "../../assets/constants/Rafservice.Instance";
import SingleVerticalBarChart from "../../components/Charts/SingleVerticalBar.Chart";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import {
  anchorTag,
  hccSeperator,
  numberWithCommas,
  addSuffixPercent
} from "../../assets/constants/customRender";
import {
  CARRIER_ID,
  GAP_INSIGHTS,
  GAP_ORG,
  GAP_ORG_METAL,
  GAP_OR_REPORTED,
  GENDER,
  HCC,
  ISSUER_ID,
  MEMBER_ID,
  MEMBER_NAME,
  METAL_TIER,
  PLAN_ID,
  WIDGET_TYPES,
  RECOMMENDATION_TYPE,
} from "../../assets/constants/string.constants";
import history from "../../history";
import CardComponent from "../../components/Cards/card";
import GapMemberDistribution from "../../assets/constants/gapMemberDistribution";
import GridTable from "../../components/TableGrids/GridTable.table";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import GChart from '../../components/GChart/GChart';
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import SubHeader from '../../components/home/header/subHeader';
import { connect } from 'react-redux';
import { percentFormat } from "../../assets/helpers/formats.helpers";
const GapMemberSummaryACA = (props) => {

    // console.log(props);

    const [WidgetDetails, setWidgetDetails] = useState({});
    const [ChartData, setChartData] = useState({});
    const [cardToggle, setcardToggle] = useState(true);
    const [issuerData, setIssuerData] = useState([]);
    const [tableData, setTableData] = useState([]);
    let dataIndex = 0;

    const backGround = [
        { backgroundColor: "#AAB7B8" },
        { backgroundColor: "#EB984E" },
        { backgroundColor: "#58D68D" },
        { backgroundColor: "#5DADE2" },
        { backgroundColor: "#AF7AC5" },
        { backgroundColor: "#EC7063" }
    ]

    const loadWidgetDetails = () => {
        
            api.get('/acaGapsummaryOrg/year/'+props.yearSelect)
            .then(res => {
                setWidgetDetails({
                    '# Members with Gap':  Number(res[0].no_of_members).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    '# Adults with Gap':  Number(res[0].no_of_adults).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    '# Children with Gap':  Number(res[0].no_of_children).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),     
                    '# Infant with Gap':  Number(res[0].no_of_infants).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),         
                    'Average Gap in Risk Score': res[0].average_risk_score,
                    'Percentage of Gap': (res[0].percentage_gap+' %')
                });
            })
            .catch(err => {
                // // console.log(err);
            });

    };

    const WidgetDetailsType = [
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.ADULT,
        WIDGET_TYPES.CHILD,
        WIDGET_TYPES.INFANT,
        WIDGET_TYPES.SCORE,
        WIDGET_TYPES.PERCENT
    ];

    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        adaptiveHeight: true,
    };

    const IssuerColumn = [
        {
            name: "ISSUER_ID",
            label: "Issuer ID",
            options: anchorTag
        },
        {
            label: "Gap",
            name: "NO_OF_MEMBERS_WITH_GAP",
            options: numberWithCommas
        },
        {
            label: "Adults",
            name: "NO_OF_ADULTS",
            options: numberWithCommas
        },
        {
            label: "Children",
            name: "NO_OF_CHILDREN",
            options: numberWithCommas
        },
        {
            label: "Infants",
            name: "NO_OF_INFANTS",
            options: numberWithCommas
        },
        {
            label: "Score",
            name: "AVERAGE_RISK_SCORE_WITH_GAP"
        }
    ];

    const issuerTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(ISSUER_ID, rowdata[cellMeta.rowIndex].ISSUER_ID)
            history.push('/layout/acaIssuerGapsummary')
        }
    }

    const loadGapMetalGraph = () => {
        let metal_tier = [];
        let avg_gap_score = [];
        let chartDataRow = [];         
            chartDataRow.push(["Metal Tier", "Risk Score", { role: 'annotation' }]);
            api.get('/acaOrgMetalTierGraph/year/' + props.yearSelect)
            .then(res => {
                // console.log(res);
                for (const dataObj of res) {
                    metal_tier.push(dataObj.metal_tier);
                    avg_gap_score.push(dataObj.average_gap_score);
                    chartDataRow.push([dataObj.metal_tier, dataObj.average_gap_score, dataObj.average_gap_score]);
                }
                setChartData(chartDataRow);
                // setChartData({
                //     labels: metal_tier,
                //     data: avg_gap_score,
                //     dLabel: "Avg Risk Score",
                // })
            })
            .catch(err => {
                // // console.log(err);
            })

    }

    const loadIssuerDetailsScore = () => {
            api.get('/GapIssuersDetails/year/' + props.yearSelect +'?iDisplayStart=0&iDisplayLength=10&iSortCol_0=0&sSortDir_0=asc&sSearch=')
            .then(res => {
                if(res.aaData && res.aaData.length > 0){
                    localStorage.setItem(ISSUER_ID, res.aaData[0].ISSUER_ID)
                    setIssuerData({
                        'Issuer ID': res.aaData[0].ISSUER_ID,
                        'Gap': res.aaData[0].NO_OF_MEMBERS_WITH_GAP,
                        'Adults': res.aaData[0].NO_OF_ADULTS,
                        'Children': res.aaData[0].NO_OF_CHILDREN,
                        'Infants': res.aaData[0].NO_OF_INFANTS,
                        'Score': percentFormat(res.aaData[0].AVERAGE_RISK_SCORE_WITH_GAP),
                    });
                }
            })
            .catch(err => {
                // // console.log(err)
            })
    }

    const memberColumn = [
        {
            label: "ID",
            name: "MEMBER_ID",
            options: anchorTag
        },
        {
            name: "MEMBER_Name",
            label: "Name"
        },
        {
            name: "Carrier_ID",
            label: "Carrier ID"
        },
        {
            name: "Gap",
            label: "Gap HCC",
            options: hccSeperator
        },
        {
            name: "Gap_Score",
            label: "Gap Score"
        },

        {
            name: "Risk_Score",
            label: "Risk Score"
        },
        {
            name: "PERCENT_OF_GAP",
            label: "% of gap"
        },
        {
            name: "Gender",
            label: "Gender"
        },
        {
            name: "Metal_Tier",
            label: "Metal Tier"
        },
        {
            name: "Risk_Model",
            label: "Risk Model"
        },
        {
            name: "Plan_ID",
            label: "Plan ID"
        }
    ]

    const elementissuerIDLinkClick = () => {
        localStorage.setItem(ISSUER_ID, issuerData?.['Issuer ID'])
        history.push("/layout/acaIssuerGapsummary");
    };

  const type = WidgetDetailsType;
  const widgetStyles = {
    position: "absolute",
    left: 0,
    //right: 20,
    top: 0,
    width: "100%",
    height: "100%",
    opacity: 0.15,
    color: "white",
  };

  const getIcon = (type) => {
    var data = "";
    switch (type) {
      case WIDGET_TYPES.ISSUER:
        data = <AccountBalanceTwoToneIcon style={widgetStyles} />;
        break;
      case WIDGET_TYPES.MEMBERS:
        data = <PeopleAltIcon style={widgetStyles} />;
        break;
      case WIDGET_TYPES.PERCENT:
        data = <ShowChartIcon style={widgetStyles} />;
        break;
      case WIDGET_TYPES.SCORE:
        data = <BarChartIcon style={widgetStyles} />;
        break;
      case WIDGET_TYPES.PERSON:
        data = <PersonIcon style={widgetStyles} />;
        break;
      case WIDGET_TYPES.CHILD:
        data = <PersonIcon style={widgetStyles} />;
        break;
      case WIDGET_TYPES.ADULT:
        data = <PersonIcon style={widgetStyles} />;
        break;
      case WIDGET_TYPES.INFANT:
        data = <PersonIcon style={widgetStyles} />;
        break;
      case WIDGET_TYPES.REVENUE:
        data = <AttachMoneyIcon style={widgetStyles} />;
        break;
      case WIDGET_TYPES.COUNT:
        data = <ShoppingCartIcon style={widgetStyles} />;
        break;
      case WIDGET_TYPES.LOCKICON:
        data = <LockOpenIcon style={widgetStyles} />;
        break;
    }
    return data;
  };

    const memberTableClick = (rowdata, colData, cellMeta) => {
        if (cellMeta.colIndex === 0 && colData.props.children != "") {
            localStorage.setItem(MEMBER_ID, rowdata[cellMeta.rowIndex].MEMBER_ID);
            localStorage.setItem(MEMBER_NAME, rowdata[cellMeta.rowIndex].MEMBER_Name);
            localStorage.setItem(CARRIER_ID, rowdata[cellMeta.rowIndex].Carrier_ID);
            localStorage.setItem(PLAN_ID, rowdata[cellMeta.rowIndex].Plan_ID);
            localStorage.setItem(GENDER, rowdata[cellMeta.rowIndex].Gender);
            history.push("/layout/acaMember", GAP_INSIGHTS);
        } else if (cellMeta.colIndex === 3 && colData.props.children != "") {
            if (typeof colData.props.children == "object") {
                localStorage.setItem(
                HCC,
                cellMeta.event.target.innerText.replace(",", "")
                );
            } else {
                localStorage.setItem(HCC, rowdata[cellMeta.rowIndex].Gap);
            }
            localStorage.setItem(ISSUER_ID, rowdata[cellMeta.rowIndex].ISSUER_ID);
            history.push("/layout/AcaGapHcc");
        }
    }

    useEffect(() => {
        loadWidgetDetails();
        loadGapMetalGraph();
        loadIssuerDetailsScore();
        loadMetricTable();
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect
    ]);


    let sliderRange = Object.entries(WidgetDetails).length / 3;
    let sliderRangeInt = Object.entries(WidgetDetails).length;
    sliderRange = parseInt(sliderRange);


    const loadMetricTable = () =>{
            api.get('/ACAGapRecommendationSummary/' + props.yearSelect)
            .then(res=>{
                setTableData(res);
            })
            .catch(err=>{
                // // console.log(err);
            })
    };
    const summaryClick = (element, el) => {
        let col = el.colIndex;
        if (col == 0) {
        //// console.log(element.props.children+ "hello"+el.rowIndex);
        localStorage.setItem(RECOMMENDATION_TYPE, element.props.children);


            history.push('/layout/recoDrillDown');
        }


    };

    const GapRecoCols = [
        {
            label: 'Capture Recommendation',
            name: 'hccCaptureReco',
            options: anchorTag
        },
        {
            label: 'No of Members',
            name: 'totalMembers'

        },
        {
            label: 'Percentage of Gap Revenue',
            name: 'percentageOfMembers',
            options:addSuffixPercent

        },
        {
            label: 'Risk Score Impacted',
            name: 'HccGapScore'

        }
    ];


    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card-header bg-white">
                        <SubHeader title={''} />
                    </div>
                    <SlickSlider WidgetDetails={WidgetDetails} getIcon={getIcon} type={type} />
                    {/* {cardToggle && <div className="card-body">
                        <div className="card-deck">

                            <Carousel autoPlay={true} interval={100000} infiniteLoop={true}>
                                {
                                    [...Array(sliderRange + 1)].map((e, i) => {
                                        let el = Object.entries(WidgetDetails);
                                        if (i > 0) {
                                            dataIndex = dataIndex + 4;
                                        }
                                        return (
                                            <div className="card" style={backGround[i++]} key={i}>
                                                <div className="card-body">
                                                    {el[dataIndex] && type ?
                                                        <div className="relative box">
                                                            <div className="bg1">
                                                                {el[dataIndex] && type ? getIcon(type[dataIndex]) : null}
                                                            </div>
                                                            <div className="bg1 pl-2 text-left" >
                                                                <p className="main-text" style={{width: "180px"}}>{el[dataIndex] ? el[dataIndex][0] : null}</p>
                                                                <p className="small-heading">{el[dataIndex] ? el[dataIndex][1] : null}</p>
                                                            </div>
                                                        </div> : null}

                                                        {el[dataIndex + 1] && type ?
                                                    <div className="relative box">
                                                        <div className="bg2">
                                                            {el[dataIndex + 1] && type ? getIcon(type[dataIndex + 1]) : null}
                                                        </div>
                                                        <div className="bg2 pl-3 text-left">
                                                            <p className="main-text" style={{width: "180px"}}>{el[dataIndex + 1] ? el[dataIndex + 1][0] : null}</p>
                                                            <p className="small-heading">{el[dataIndex + 1] ? el[dataIndex + 1][1] : null}</p>
                                                        </div>
                                                    </div> : null}

                                                    {el[dataIndex + 2] && type ?
                                                    <div className="relative box">
                                                        <div className="bg2">
                                                            {el[dataIndex + 2] && type ? getIcon(type[dataIndex + 2]) : null}
                                                        </div>
                                                        <div className="bg2 pl-3 text-left">
                                                            <p className="main-text" style={{width: "180px"}}>{el[dataIndex + 2] ? el[dataIndex + 2][0] : null}</p>
                                                            <p className="small-heading">{el[dataIndex + 2] ? el[dataIndex + 2][1] : null}</p>
                                                        </div>
                                                    </div> : null}

                                                    {el[dataIndex + 3] && type ?
                                                    <div className="relative box">
                                                        <div className="bg2">
                                                            {el[dataIndex + 3] && type ? getIcon(type[dataIndex + 3]) : null}
                                                        </div>
                                                        <div className="bg2 pl-3 text-left">
                                                            <p className="main-text" style={{width: "180px"}}>{el[dataIndex + 3] ? el[dataIndex + 3][0] : null}</p>
                                                            <p className="small-heading">{el[dataIndex + 3] ? el[dataIndex + 3][1] : null}</p>
                                                        </div>
                                                    </div>: null}
                                                </div>
                                            </div>
                                        )

                                    })
                                }
                            </Carousel>
                        </div>
                    </div>} */}
        {/* <WidgetCard
            heading = {'GAP SUMMARY'}
            data = {WidgetDetails}
            type = {WidgetDetailsType}
                    ></WidgetCard> */}
                </div>
                <div className="col-lg-6">
                    <CardComponent
                        heading={`ISSUER(S) DETAILS`}
                        body={
                            <div style={{height:'315px'}}>
                                <hr />
                                <table>
                                    {Object.entries(issuerData) && Object.entries(issuerData).map((element, i) => {
                                        return (
                                            <tr>
                                                <td>{element[0]}</td>
                                                <td className="pr-5 pl-5 pb-2">:</td>
                                                {element[0] == "Issuer ID"?
                                                    <td>
                                                    <a className="hyperlink" style={{cursor:"pointer"}} href={() => false} onClick={elementissuerIDLinkClick}>
                                                        {element[1]}
                                                    </a>
                                                    </td>
                                                :
                                                    <td>{element[1]}</td>
                                                }
                                        
                                            </tr>
                                        )
                                        })
                                    }
                            </table>                      
                            </div>
                        }></CardComponent>
                </div>
                <br />
                <div className="col-lg-6">
                <CardComponent
                    heading={`HCC CAPTURE RECOMMENDATION- ${props.yearSelect}`}
                    body={
                    <div className="box-height-chart">
                        <GridTable
                            data={tableData}
                            columns={GapRecoCols}
                            onTableClick={summaryClick}
                            >
                        </GridTable>
                    </div>
                }></CardComponent>
                </div>
                {/* <div className="col-lg-6 widget-box">
                    <CardComponent
                        heading={`MEMBERS WITH GAP BY ISSUER(S) !!`}
                        body={
                            <GChart data={ChartData} chartType="Bar" height="320px" width="100%"></GChart>
                        }></CardComponent>
                </div> */}
        <br />
        {/*  <div className="col-lg-12 mt-4 member-list">
                    <CardComponent
                        heading={`MEMBER LIST`}
                        body={
                            <ServerSideGridTable
                                url={`/GapIssuerMemberDetails/year/${year}`}
                                columns={memberColumn}
                                tableClick={memberTableClick}
                            ></ServerSideGridTable>
                        }></CardComponent>
                    </div> */}

                <div className="col-lg-12 mt-4">
                    <GapMemberDistribution
                        year={props.yearSelect}
                        type={GAP_ORG}
                        MemberChartData={ChartData} />
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br />
                <br />
            </div>
        </div>
    )
    }
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
    };
}
export default connect(mapStateToProps)(GapMemberSummaryACA);