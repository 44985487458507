
import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import CardComponent from "../../components/Cards/card";
import GridTable from "../../components/TableGrids/GridTable.table";
import { getAcaYear, getAcaYearList } from "../../assets/constants/yearList";
import { anchorTag } from "../../assets/constants/customRender";
import { PROVIDER_ID } from "../../assets/constants/string.constants";
import history from "../../history";
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';

const ProviderReconSummary = (props) => {

    const [year, setYear] = useState(props.yearSelect);
    const [tableData, setTableData] = useState([]);
    const [summaryData, setSummaryData] = useState([]);
    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            loadMetricTable();
            loadSummaryTable();
        }
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect,
        props.meInitialSelect
    ]);

    const memberColumns = [
        {
            label: "Provider Number",
            name: "PROVIDER_NUMBER",
            options: anchorTag
        },
        {
            label: "Provider Name",
            name: "PROVIDER"

        },


        {
            name: "TOTAL_TARGETTED_HCC",
            label: "# Targetted HCC"
        },
        {
            name: "TOTAL_TARGETTED_MEMBERS",
            label: "# Targetted Members"

        },
        {
            name: "TOTAL_ADDED_HCC_COUNT",
            label: "# Added HCC Count",

        },
        {
            name: "TOTAL_ADDED_MEMBERS",
            label: "# Added Members"
        },
        {
            name: "TOTAL_RISK_SCORE_IMPACTING_HCC",
            label: "# Risk Score Impacting HCC"

        },
        {
            name: "TOTAL_RISK_IMPACTED_MEMBERS",
            label: "# Risk Impacted Members"
        },
        {
            name: "GAP_HCC_CLOSED_ON_CR_RECO",
            label: "Gap HCC Closed On CR Reco"
        },
        {
            name: "IMPACTED_MEMBER_COUNT_IN_CR_RECO",
            label: "Impacted Member Count In CR Reco"
        },
        {
            name: "ADDITIONAL_HCCS_CLOSED_BY_PROVIDER",
            label: "Additional HCCs Closed By Provider"
        },
        {
            name: "EFFECTIVE_YIELD",
            label: "Effective Yield"

        },
        {
            name: "RISK_SCORE_CONTRIBUTION",
            label: "Risk Score Contribution"
        }];
    //{"CATEGORY":"HIGH","GAP_TARGETTED_CHART_CHASE_SCORE":2458.1,
    //"GAP_TARGETTED_PROSPECTIVE_SCORE":0,"CHART_CHASE_GAP_CLOSURE_SCORE":3.08,
    //"PROSPECTIVE_GAP_CLOSURE_SCORE":9.24,"GAP_PENDING_CHARTCHASE_SCORE":2455.02,"YEAR":2020},

    const summaryColumns = [
        {
            name: "CATEGORY",
            label: "Category"
        },
        {
            name: "GAP_TARGETTED_CHART_CHASE_SCORE",
            label: "Gap Targetted Chart Chase Score"

        },
        {
            name: "GAP_TARGETTED_PROSPECTIVE_SCORE",
            label: "Gap Targetted Prospective Score"

        }, {
            name: "CHART_CHASE_GAP_CLOSURE_SCORE",
            label: "Chart Chase Gap Closure Score"

        },
        {
            name: "PROSPECTIVE_GAP_CLOSURE_SCORE",
            label: "Prospective Gap Closure Score"

        },
        {
            name: "GAP_PENDING_CHARTCHASE_SCORE",
            label: "Gap Pending Chart Chase Score"

        }];


    const Tableclick = (rowdata, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (rowdata.props.children != "")) {

            localStorage.setItem(PROVIDER_ID, rowdata.props.children);
            history.push('/layout/acaProviderMemberSummary');
        }
    }



    const loadMetricTable = () => {
        api.get('/getAcaProvidersSummary/' + year)
            .then(res => {
                // console.log(res);
                setTableData(res);
            })
            .catch(err => {
                // console.log(err);
            })
    };
    const loadSummaryTable = () => {
        api.get('/getAcaProviderCategorylevel/' + year)
            .then(res => {
                // console.log(res);
                setSummaryData(res);
            })
            .catch(err => {
                // console.log(err);
            })
    };

    return (
        <div className="container level-summary">
            <div className="row mb-3">
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Provider Reconciliation'} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <CardComponent
                        heading={"SUMMARY DETAILS"}
                        body={
                            <GridTable
                                data={summaryData}
                                columns={summaryColumns}

                            ></GridTable>
                        }></CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mb-3">
                    <CardComponent
                        heading={"PROVIDER DETAILS"}
                        body={
                            <GridTable
                                data={tableData}
                                columns={memberColumns}
                                pagination={true}
                                onTableClick={Tableclick}
                            ></GridTable>
                        }></CardComponent>
                </div>
                <br />
                <br />
            </div>
        </div>
    );

};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
}
export default connect(mapStateToProps)(ProviderReconSummary);
