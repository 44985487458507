/**
 * Creating js file for AWV Member Details
 * Story - PD-40
 * @author ih-sonal
 *
 */

import { connect } from 'react-redux';
import React, { useEffect, useState } from "react";
import awvApi from '../../assets/constants/AWVMemberDetails.RafService.Instance';
import DynamicColumnShow from "../../components/TableGrids/DynamicColumnShow.table";
import FormControl from '@material-ui/core/FormControl';
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import Button from '@material-ui/core/Button';
import axios from "axios";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import Slider from "react-slick";
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ModuleList } from '../../assets/constants/AWVList';
import Dropdown from "../../components/DropDowns/dropDown";
import AwvTabHeader from '../../components/home/header/AwvTabHeader';
import { signOut, currentRoute, currentAWESiteSelect, currentAWEProviderGroupingSelect, currentAWECitySelect, currentAWEAddressSelect, currentAWEZipSelect } from '../../store/actions';

//const AWS = require('aws-sdk');

//for download functionality
import AWS from 'aws-sdk';


const AWEDetailedAnalysis = (props) => {

    const { formState: { errors }, control } = useForm();
    const [value, setValue] = useState('1');
    const [groupsData, setGroupsData] = useState([]);
    const [selectGroupsArray, setSelectGroupsArray] = useState([]);
    const [selectIPACode, setSelectIPACode] = useState('');
    const [selectIPAName, setSelectIPAName] = useState('');
    const [providersData, setProviderData] = useState([]);
    const [selectProviderArray, setSelectProviderArray] = useState([]);
    const [selectProviderValueArray, setSelectProviderValueArray] = useState('');
    const [yearList, setYearList] = useState([]);
    const [year, setYear] = useState();
    const [currentDateProviderView, setCurrentDateProviderView] = useState('');
    const [currentDateAnalyticView, setCurrentDateAnalyticView] = useState('');
    const [monthList, setMonthList] = useState([]);
    const [month, setMonth] = useState();
    const [reportName, setReportName] = useState('');
    const [downloadedFileName, seDownloadedFileName] = useState('');
    const [filePresentFlag, setFilePresentFlag] = useState(false);
    const [filterMonth, setFilterMonth] = useState('');
    const [lobSelect] = useState(['Medicare'])
    const [selectLob, setSelectLob] = useState(lobSelect[0]);
    const [providerAddressGroupsData, setProviderAddressGroupsData] = useState([]);
    const [selectAddressGroupsArray, setSelectAddressGroupsArray] = useState([]);
    const [selectAddressGroup, setSelectAddressGroup] = useState('');
    const [filterValue, setFilterValue] = useState('');
    const [cityList, setCityList] = useState([]);
    const [city, setCity] = useState();
    const [stateList, setStateList] = useState([]);
    const [state, setState] = useState();
    const [zipList, setZipList] = useState([]);
    const [zip, setZip] = useState();
    const [siteIdList, setsiteIdList] = useState([]);
    const [siteId, setSiteId] = useState();
    var filteredValueList = [];
    const [siteIdSelect, setsiteIdSelect] = useState('');
    const [providerAddressGroupsSelect, setProviderAddressGroupsSelect] = useState([]);
    const [providerAddressGroupsNameSelect, setProviderAddressGroupsNameSelect] = useState([]);
    const [citySelect, setCitySelect] = useState('');
    const [siteAddressSelect, setSiteAddressSelect] = useState('');
    const [zipSelect, setZipSelect] = useState('');

    const handleSiteSelect = (e) => {
        if (e && e != '') {
            setsiteIdSelect(e.site_id);
        } else {
            setsiteIdSelect('');
        }
    }

    const handleProviderAddressGroupsSelect = (event) => {
        var setProviderSelected = [];
        var setProviderNameSelected = [];
        if (event != '' && event.length > 0) {
            event.map(function (el) {
                setProviderSelected.push(el.ppg_address_grouping.toString());
                setProviderNameSelected.push(el.ppg_address_grouping)
            })
            //Adding '' and send as comman separated value for multiple filter
            setProviderAddressGroupsSelect(setProviderSelected);
            setProviderAddressGroupsNameSelect('\"' + setProviderNameSelected.join('\",\"') + '\"');
        }
        else {
            //Adding '' and send as comman separated value for multiple filter
            setProviderAddressGroupsSelect([]);
            setProviderAddressGroupsNameSelect('');
        }
    }

    const handleCitySelect = (e) => {
        if (e && e != '') {
            setCitySelect(e.site_city);
        } else {
            setCitySelect('');
        }
    }

    const handleAddressSelect = (e) => {
        if (e && e != '') {
            setSiteAddressSelect(e.site_address);
        } else {
            setSiteAddressSelect('');
        }
    }

    const handleZipSelect = (e) => {
        if (e && e != '') {
            setZipSelect(e.site_zip);
        } else {
            setZipSelect('');
        }
    }


    AWS.config.update({
        accessKeyId: localStorage.getItem("access_key"),
        secretAccessKey: localStorage.getItem("secret_key"),
        region: process.env.REACT_APP_REGION
    });

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    //PD-205 Changes for Download report name
    const CalculateDateAndHandleDownloadedFileName = (year, month, after) => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        if (month != undefined && month != '' && year != undefined) {
            seDownloadedFileName('AWV_DETAILS_REPORT_PY' + year + '_' + month + year + '_' + mm + dd + yyyy + after)
        }
    }

    const handleDropdownChange = (event) => {
        var ipaCode = []
        var ipaName = []
        let setGroupSelected = [];
        let setGroupNameSelected = [];
        if (event != '' && event.length > 0) {
            event.map(function (el) {
                var splitIPACode = el.data
                ipaCode.push(splitIPACode.substr(0, (splitIPACode).lastIndexOf('-')))
                ipaName.push(splitIPACode.substr((splitIPACode).lastIndexOf('-') + 1))
                setGroupSelected.push(el.index.toString());
                setGroupNameSelected.push(el.data)
            })
            //Adding '' and send as comman separated value for multiple filter
            setSelectIPACode('\"' + ipaCode.join('\",\"') + '\"');
            setSelectIPAName('\"' + ipaName.join('\",\"') + '\"')
            setSelectGroupsArray(setGroupSelected);
            loadProviderAddressGroupData('\"' + ipaCode.join('\",\"') + '\"')
            loadSiteId('\"' + ipaCode.join('\",\"') + '\"', selectAddressGroup)
            loadZip('\"' + ipaCode.join('\",\"') + '\"', selectAddressGroup)
            loadState('\"' + ipaCode.join('\",\"') + '\"', selectAddressGroup)
            loadCity('\"' + ipaCode.join('\",\"') + '\"', selectAddressGroup)
        }
        else {
            setSelectIPACode('');
            setSelectIPAName('')
            setSelectGroupsArray([]);
            
            setSelectAddressGroupsArray([]);
            loadSiteId(selectIPACode, '')
            loadZip(selectIPACode, '')
            loadState(selectIPACode, '')
            loadCity(selectIPACode, '')
        }
    };

    const handleDropdownChangeForProviderName = (event) => {
        var setProviderSelected = [];
        var setProviderNameSelected = [];
        if (event != '' && event.length > 0) {
            event.map(function (el) {
                setProviderSelected.push(el.index.toString());
                setProviderNameSelected.push(el.data)
            })
            //Adding '' and send as comman separated value for multiple filter
            setSelectProviderValueArray('\"' + setProviderNameSelected.join('\",\"') + '\"');
            setSelectProviderArray(setProviderSelected);
        }
        else {
            //Adding '' and send as comman separated value for multiple filter
            setSelectProviderValueArray('');
            setSelectProviderArray([]);
        }
    };

    const loadGroupData = () => {
        let values = [];
        awvApi.get(`/ppg_group`)
            .then(res => {
                if (res.data != null && !res.data.includes('errorMessage')) {
                    res.data.forEach((element, index) => {
                        for (let [key, value] of Object.entries(element)) {
                            if (!values.includes(value)) {
                                values.push({ index: index, data: value });
                            }
                        }
                    })
                }
                setGroupsData(values);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const loadProviderNameData = () => {
        let values = [];
        awvApi.get(`/pcp_provider_name`)
            .then(res => {
                if (res.data != null && !res.data.includes('errorMessage')) {
                    res.data.forEach((element, index) => {
                        for (let [key, value] of Object.entries(element)) {
                            if (!values.includes(value)) {
                                values.push({ index: index, data: value });
                            }
                        }
                    })
                }
                setProviderData(values);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const loadData = () => {
        var groupsArray = [];

        groupsData.forEach(element => {
            groupsArray.push(element.index.toString())
        });
        setSelectGroupsArray(groupsArray);
    }

    const Calculate = () => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        setCurrentDateProviderView('AWV_Provider_Reporting_View_' + mm + dd + yyyy)
        setCurrentDateAnalyticView('Analytics_View_' + mm + dd + yyyy)
    }

    const loadProviderData = () => {
        var groupsArray = [];

        providersData.forEach(element => {
            groupsArray.push(element.index.toString())
        });
        setSelectProviderArray(groupsArray);
    }

    const fetchReports = () => {
        (async function () {

            const setTableDataList = [];
            let keyInsideFolder = '';
            let monthForDownloadFile = ''
            var s3 = new AWS.S3({
                signatureVersion: "v4"
            });
            try {
                AWS.config.update({
                    accessKeyId: localStorage.getItem("access_key"),
                    secretAccessKey: localStorage.getItem("secret_key"),
                    region: process.env.REACT_APP_REGION
                });
                const s3d = new AWS.S3();
                const response = await s3d.listObjectsV2({
                    Bucket: process.env.REACT_APP_BUCKET_NAME_AWV,
                    Delimiter: '/',
                    Prefix: 'processed/' + selectLob + '/' + process.env.REACT_APP_CustomerKey + '/' + year + '/' + month + '/'
                }).promise();

                const json_format = JSON.stringify(response.Contents)
                const stringify = JSON.parse(json_format)
                for (var i = 0; i < stringify.length; i++) {
                    if (stringify[i]['Size'] != 0) {
                        setTableDataList.push(stringify[i]);
                    }
                }
                setTableDataList.forEach(element => {
                    keyInsideFolder = element.Key.substr((((element.Key)).lastIndexOf('/') + 1));
                })
                //Calling current date function, changes for getting 3 char from Month
                if (keyInsideFolder != "") {
                    setFilePresentFlag(true);
                    const after_ = keyInsideFolder.substring(keyInsideFolder.indexOf('.'));
                    monthForDownloadFile = month.slice(0, 3)
                    CalculateDateAndHandleDownloadedFileName(year, monthForDownloadFile, after_)
                }
                else {
                    setFilePresentFlag(false);
                }

                setReportName(keyInsideFolder)

            } catch (e) {
                console.log('our error ', e)
            }
        })();
    }
    const getMenuPermissionCheck = (permissionSlug) => {
        var permissionCheck = false;
        if (permissionSlug && permissionSlug != '') {
            var permissionGets = JSON.parse(localStorage.getItem('controlPermissions'));
            if (permissionGets && permissionGets.length > 0) {
                permissionGets && permissionGets.forEach(elem => {
                    if (elem.permission == permissionSlug && elem.status == true) {
                        permissionCheck = true;
                    }
                });
            }
        }
        return permissionCheck;
    }
    const loadYear = () => {
        (async function () {

            const setTableDataList = [];

            let yearArray = [];
            let finalValue = [];
            var subStringForYear1 = '';
            var subStringForYear2 = '';
            var subStringForYear3 = '';
            var s3 = new AWS.S3({
                signatureVersion: "v4"
            });

            try {
                AWS.config.update({
                    accessKeyId: localStorage.getItem("access_key"),
                    secretAccessKey: localStorage.getItem("secret_key"),
                    region: process.env.REACT_APP_REGION
                });

                const s3d = new AWS.S3();
                const response = await s3d.listObjectsV2({
                    Bucket: process.env.REACT_APP_BUCKET_NAME_AWV,
                }).promise();

                const json_format = JSON.stringify(response.Contents)
                const stringify = JSON.parse(json_format)

                for (var i = 0; i < stringify.length; i++) {
                    setTableDataList.push(stringify[i]);
                }
                setTableDataList.forEach(ele => {
                    if (ele.Key.includes(selectLob)) {
                        subStringForYear1 = (ele.Key).substr((((ele.Key)).indexOf('/') + 1))
                        subStringForYear2 = (subStringForYear1).substr((((subStringForYear1)).indexOf('/') + 1))
                        subStringForYear3 = (subStringForYear2).substr((((subStringForYear2)).indexOf('/') + 1))

                        //value4 = (subStringForYear3).substr((((subStringForYear3)).indexOf('/') + 1))
                        if (subStringForYear3 != "") {
                            yearArray.push((subStringForYear3).substr(0, 4))
                        }
                    }
                })
                const newArray = Array.from(new Set(yearArray))
                newArray.forEach(ele => {
                    finalValue.push(ele)
                })
                setYearList(finalValue)
                setYear(finalValue[0])
            } catch (e) {
                console.log('our error ', e)
            }
        })();
    }

    const loadMonth = () => {
        (async function () {

            const setTableDataList = [];
            let monthArray = [];
            let finalValue = [];
            var subStringForMonth1 = '';
            var subStringForMonth2 = '';
            var subStringForMonth3 = '';
            var subStringForMonth4 = '';
            var s3 = new AWS.S3({
                signatureVersion: "v4"
            });

            try {
                AWS.config.update({
                    accessKeyId: localStorage.getItem("access_key"),
                    secretAccessKey: localStorage.getItem("secret_key"),
                    region: process.env.REACT_APP_REGION
                });

                const s3d = new AWS.S3();
                const response = await s3d.listObjectsV2({
                    Bucket: process.env.REACT_APP_BUCKET_NAME_AWV,
                }).promise();

                const json_format = JSON.stringify(response.Contents)
                const stringify = JSON.parse(json_format)
                for (var i = 0; i < stringify.length; i++) {
                    setTableDataList.push(stringify[i]);
                }
                setTableDataList.forEach(ele => {
                    if (ele.Key.includes(year) && ele.Key.includes(selectLob)) {
                        subStringForMonth1 = (ele.Key).substr((((ele.Key)).indexOf('/') + 1))
                        subStringForMonth2 = (subStringForMonth1).substr((((subStringForMonth1)).indexOf('/') + 1))
                        subStringForMonth3 = (subStringForMonth2).substr((((subStringForMonth2)).indexOf('/') + 1))
                        subStringForMonth4 = (subStringForMonth3).substr((((subStringForMonth3)).indexOf('/') + 1))
                        if (subStringForMonth4 != "") {
                            monthArray.push((subStringForMonth4).substr(0, (subStringForMonth4).lastIndexOf('/')))
                        }
                    }
                })
                const newArray = Array.from(new Set(monthArray))
                newArray.forEach(ele => {
                    finalValue.push(ele)
                })
                setMonthList(finalValue)
                setMonth(finalValue[0])
                getMonth(finalValue[0])
            } catch (e) {
                console.log('our error ', e)
            }
        })();

    }
    function getObjKey(obj, Value) {
        for (let [key, value] of Object.entries(obj)) {
            if (key === Value) {
                return value;
            }
        }
    }
    const getMonth = (month) => {
        const monthNumberToLabelMap = {
            'January': '01',
            'February': '02',
            'March': '03',
            'April': '04',
            'May': '05',
            'June': '06',
            'July': '07',
            'August': '08',
            'September': '09',
            'October': '10',
            'November': '11',
            'December': '12'
        }
        setFilterMonth(getObjKey(monthNumberToLabelMap, month))

    }

    const handleLOBSelect = (e) => {
        setSelectLob(e);
    }

    const handleDownload = () => {
        (async function () {
            try {
                AWS.config.update({
                    accessKeyId: localStorage.getItem("access_key"),
                    secretAccessKey: localStorage.getItem("secret_key"),
                    region: process.env.REACT_APP_REGION
                });
                ;
                var s3 = new AWS.S3({
                    signatureVersion: "v4"
                });
                s3.setupRequestListeners = (request) => {
                    request.on('build', (req) => {

                        req.httpRequest.headers['x-amz-date'] = process.env.REACT_APP_KeyID;
                    });
                }

            } catch (e) {
                console.log('our error ', e)
            }

            var key = 'processed/' + selectLob + '/' + process.env.REACT_APP_CustomerKey + '/' + year + '/' + month + '/' + reportName
            var presignedGETURL = s3.getSignedUrl('getObject', {
                Bucket: process.env.REACT_APP_BUCKET_NAME_AWV,
                Key: key
            });
            window.location = presignedGETURL;
            /* axios({
                url: presignedGETURL,
                responseType: "arraybuffer",
                method: "GET",
            }).then((res) => {

                let file = new Blob([res.data], {
                    //type: "application/pdf",
                });
                let fileURL = URL.createObjectURL(file);
                let a = document.createElement("a");
                a.href = fileURL;
                a.target = "_blank";
                a.download = downloadedFileName;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);

            }).catch((err) => { console.log("Error while downloading file...") }) */
        })();
    }

    const handleProviderAddressGrouping = (event) => {
        var ipaCode = []
        let setGroupSelected = [];
        let setGroupNameSelected = [];
        if (event != '' && event.length > 0) {
            event.map(function (el) {
                var splitIPACode = el.data
                ipaCode.push(splitIPACode.substr(0, (splitIPACode).lastIndexOf('-')))
                setGroupSelected.push(el.index.toString());
            })
            //Adding '' and send as comman separated value for multiple filter
            setSelectAddressGroup('\"' + ipaCode.join('\",\"') + '\"');
            setSelectAddressGroupsArray(setGroupSelected);
            loadSiteId(selectIPACode, '\"' + ipaCode.join('\",\"') + '\"')
            loadZip(selectIPACode, '\"' + ipaCode.join('\",\"') + '\"')
            loadState(selectIPACode, '\"' + ipaCode.join('\",\"') + '\"')
            loadCity(selectIPACode, '\"' + ipaCode.join('\",\"') + '\"')
        }
        else {
            setSelectAddressGroup('');
            setSelectAddressGroupsArray([]);
            loadSiteId(selectIPACode, '')
            loadZip(selectIPACode, '')
            loadState(selectIPACode, '')
            loadCity(selectIPACode, '')
        }
    };

    const loadProviderAddressGroupData = (ppg, filterValue) => {
        let values = [];
        // alert("hello provider..." +`/getProviderAddressGrouping?year=2020&ppg=`+ppg+`&filterValues=`+filterValue + siteIdList[0])
        awvApi.get(`/getProviderAddressGrouping?year=` +year+ `&ppg=` + ppg + `&filterValue=` + filterValue)
            .then(res => {
                if (res.data != null && !res.data.includes('errorMessage')) {
                    res.data.forEach((element, index) => {
                        for (let [key, value] of Object.entries(element)) {
                            if (!values.includes(value)) {
                                values.push({ index: index, data: value });
                            }
                        }
                    })
                }
                setProviderAddressGroupsData(values);
          
            })
            .catch(err => {
                console.log(err);
            })
    }

    const loadAddressGroupData = () => {
        var groupsArray = [];

        providerAddressGroupsData.forEach(element => {
            groupsArray.push(element.index.toString())
        });
        setSelectAddressGroupsArray(groupsArray);
      
    }

    const loadCity = (selectIPACode, selectAddressGroup, filterValue) => {
        let values = [];
        let finalValue = [];
        awvApi.get(`/getCity?year=` +year+ `&ppg=` + selectIPACode + `&ppg_address=` + selectAddressGroup + `&filterValue=` + filterValue)
            .then(res => {
                if (res.data != null && !res.data.includes('errorMessage')) {
                    res.data.forEach((element, index) => {
                        for (let [key, value] of Object.entries(element)) {
                            if (!values.includes(value)) {
                                values.push(value);
                            }
                        }
                    })
                }
                const newArray = Array.from(new Set(values))
                newArray.forEach(ele => {
                    finalValue.push(ele)
                })
                setCityList(finalValue);
                //  setCity(finalValue[0])
            })
            .catch(err => {
                console.log(err);
            })
    }

    const cityListDropDown = (e) => {
        e = e.target.value
        setCity(e);
        var cityValue = ""
        if (e == 'ALL') {
            cityValue = ""
        }
        else {
            cityValue = 'site_city="' + e + '"'
        }
        var finalStrung = ""
        setZip('')
        setSiteId('')
        setState('')
        var zipValue = zip && zip.length > 0 ? 'site_zip="' + zip + '",' : ""
        var stateValue = state && state.length > 0 ? 'site_state="' + state + '",' : ""
        var site_idValue = siteId && siteId.length > 0 ? 'site_id="' + siteId + '"' : ""
        //finalStrung = zipValue+stateValue+cityValue+site_idValue
        finalStrung = cityValue
        setFilterValue(finalStrung)
        
        loadProviderAddressGroupData(selectIPACode, finalStrung)
        loadSiteId(selectIPACode, selectAddressGroup, finalStrung)
        loadZip(selectIPACode, selectAddressGroup, finalStrung)
        loadState(selectIPACode, selectAddressGroup, finalStrung)
        // loadCity(selectIPACode,selectAddressGroup,finalStrung)
       
    }

    const loadState = (selectIPACode, selectAddressGroup, filterValue) => {
        let values = [];
        let finalValue = [];
        awvApi.get(`/getState?year=` +year+ `&ppg=` + selectIPACode + `&ppg_address=` + selectAddressGroup + `&filterValue=` + filterValue)
            .then(res => {
                if (res.data != null && !res.data.includes('errorMessage')) {
                    res.data.forEach((element, index) => {
                        for (let [key, value] of Object.entries(element)) {
                            if (!values.includes(value)) {
                                values.push(value);
                            }
                        }
                    })
                }
                const newArray = Array.from(new Set(values))
                newArray.forEach(ele => {
                    finalValue.push(ele)
                })
                setStateList(finalValue);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const stateListDropDown = (e) => {
        e = e.target.value
        var stateValue = ""
        setState(e);
        if (e == 'ALL') {
            stateValue = ""
        }
        else {
            stateValue = 'site_state="' + e + '"'
        }
        var finalStrung = ""
        setZip('')
        setCity('')
        setSiteId('')
        var zipValue = zip && zip.length > 0 ? 'site_zip="' + zip + '",' : ""
        var cityValue = city && city.length > 0 ? 'site_city="' + city + '",' : ""
        var site_idValue = siteId && siteId.length > 0 ? 'site_id="' + siteId + '"' : ""
        //finalStrung = zipValue+stateValue+cityValue+site_idValue
        finalStrung = stateValue
        setFilterValue(finalStrung)
       
        loadProviderAddressGroupData(selectIPACode, finalStrung)
        loadSiteId(selectIPACode, selectAddressGroup, finalStrung)
        loadZip(selectIPACode, selectAddressGroup, finalStrung)
        //loadState(selectIPACode,selectAddressGroup,finalStrung)
        loadCity(selectIPACode, selectAddressGroup, finalStrung)
       
    }

    const loadZip = (selectIPACode, selectAddressGroup, filterValue) => {
        let values = [];
        let finalValue = [];
        awvApi.get(`/getZip?year=` +year+ `&ppg=` + selectIPACode + `&ppg_address=` + selectAddressGroup + `&filterValue=` + filterValue)
            .then(res => {
                if (res.data != null && !res.data.includes('errorMessage')) {
                    res.data.forEach((element, index) => {
                        for (let [key, value] of Object.entries(element)) {
                            if (!values.includes(value)) {
                                values.push(value);
                            }
                        }
                    })
                }
                const newArray = Array.from(new Set(values))
                newArray.forEach(ele => {
                    finalValue.push(ele)
                })
                setZipList(finalValue);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const zipListDropDown = (e) => {
        e = e.target.value
        var zipValue = ""
        setZip(e);
        if (e == 'ALL') {
            zipValue = ""
        }
        else {
            zipValue = 'site_zip="' + e + '"'
        }
        var finalStrung = ""
        setState('')
        setCity('')
        setSiteId('')
        var stateValue = state && state.length > 0 ? 'site_state="' + state + '",' : ""
        var cityValue = 0 && city.length > 0 ? 'site_city="' + city + '",' : ""
        var site_idValue = siteId && siteId.length > 0 ? 'site_id="' + siteId + '"' : ""
        // finalStrung = zipValue+stateValue+cityValue+site_idValue
        finalStrung = zipValue
        setFilterValue(finalStrung)
        
        loadProviderAddressGroupData(selectIPACode, finalStrung)
        loadSiteId(selectIPACode, selectAddressGroup, finalStrung)
        //loadZip(selectIPACode,selectAddressGroup,finalStrung)
        loadState(selectIPACode, selectAddressGroup, finalStrung)
        loadCity(selectIPACode, selectAddressGroup, finalStrung)
    }
    
    const loadSiteId = (selectIPACode, selectAddressGroup, filterValue) => {
        let values = [];
        let finalValue = [];
        awvApi.get(`/getsiteid?year=` +year+ `&ppg=` + selectIPACode + `&ppg_address=` + selectAddressGroup + `&filterValue=` + filterValue)
            .then(res => {
                if (res.data != null && !res.data.includes('errorMessage')) {
                    res.data.forEach((element, index) => {
                        for (let [key, value] of Object.entries(element)) {
                            if (!values.includes(value)) {
                                values.push(value);
                            }
                        }
                    })
                }
                const newArray = Array.from(new Set(values))
                newArray.forEach(ele => {
                    finalValue.push(ele)
                })
                setsiteIdList(finalValue);
                // setSiteId(finalValue[0])
            })
            .catch(err => {
                console.log(err);
            })
    }

    const siteIdListDropDown = (e) => {
        e = e.target.value
        var site_idValue = ""
       
        if (e == 'ALL') {
            site_idValue = ""
            setSiteId('');
        }
        else {
            site_idValue = 'site_id="' + e + '"'
            setSiteId(e);
        }
        var finalStrung = ""
        setZip('')
        setCity('')
        setState('')
        var zipValue = zip && zip.length > 0 ? 'site_zip="' + zip + '",' : ""
        var stateValue = state && state.length > 0 ? 'site_state="' + state + '",' : ""
        var cityValue = city && city.length > 0 ? 'site_city="' + city + '",' : ""
        // finalStrung = zipValue+stateValue+cityValue+site_idValue
        finalStrung = site_idValue
        setFilterValue(finalStrung)
   
        loadProviderAddressGroupData(selectIPACode, finalStrung)
        // loadSiteId(selectIPACode,selectAddressGroup, finalStrung)
        loadZip(selectIPACode, selectAddressGroup, finalStrung)
        loadState(selectIPACode, selectAddressGroup, finalStrung)
        loadCity(selectIPACode, selectAddressGroup, finalStrung)
    }

    useEffect(() => {
        loadGroupData();
        loadProviderAddressGroupData();
        loadData();
        loadAddressGroupData();
        loadProviderNameData();
        loadProviderData();
        loadYear();
        Calculate()
        loadCity();
        loadState();
        loadZip();
        loadSiteId();
    }, [selectLob]);

    useEffect(() => {
        loadMonth();

    }, [year, selectLob])

    useEffect(() => {
        fetchReports();
    }, [year, month, selectLob])

    const handleDropdownForMonth = (e) => {
        setMonth(e)
        getMonth(e)
        setReportName('')
        seDownloadedFileName('')
        fetchReports();
    }

    const handleDropdownForYear = (e) => {
        setYear(e)
        setMonth('')
        setReportName('')
        seDownloadedFileName('')
    }
    useEffect(() => {
        ModuleList();
        // setModuleSelect(getModuleSelect())
        setProviderData([])
        setSelectGroupsArray([])
        setSelectIPACode('')
        setSelectIPAName('')
        setSelectProviderArray([])
        setSelectProviderValueArray([])
    }, [selectLob])

    return (

        <>
            <div className='state-level'>
                <div className="card-header">
                    <div className='col-lg-12 pr-0 pl-0'></div>
                    <span className="d-flex bd-highlight">
                        <div className="d-flex align-items-center mr-3">
                            <label className="pr-2 pb-0 mb-0 d-flex align-items-center">Provider Group</label>
                            <div className='d-flex justify-content-end'>
                                <div className="btn-group" style={{ width: '200px' }}>
                                    <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '220px', 'zIndex': '2' }}>
                                        <Controller
                                            className="input-control"
                                            name="groups"
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    {...field}
                                                    variant="outlined"
                                                    isMulti
                                                    closeMenuOnSelect={true}
                                                    isSearchable={true}
                                                    isClearable={true}
                                                    options={groupsData}
                                                    getOptionLabel={option => `${option.data}`}
                                                    getOptionValue={option => `${option.index}`}
                                                    value={groupsData.filter(obj => selectGroupsArray.includes(obj.index.toString()))}
                                                    hideSelectedOptions={true}
                                                    backspaceRemovesValue={false}
                                                    onChange={e => handleDropdownChange(e)}
                                                    menuPlacement="bottom"
                                                />
                                            )}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                        {errors?.groups?.type === "required" && <label className="text-danger">This field is required</label>}
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        <label className="pr-2 pb-0 mb-0 d-flex align-items-center" style={{ 'margin-left': '2px', paddingLeft: '20px' }}>Provider Name</label>
                        <div className="d-flex align-items-center" style={{ width: '200px' }}>

                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '50px', 'zIndex': '999' }}>
                                <Controller
                                    className="input-control"
                                    name="providerName"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            variant="outlined"
                                            isMulti
                                            closeMenuOnSelect={true}
                                            isSearchable={true}
                                            isClearable={true}
                                            options={providersData}
                                            getOptionLabel={option => `${option.data}`}
                                            getOptionValue={option => `${option.index}`}
                                            value={providersData.filter(obj => selectProviderArray.includes(obj.index.toString()))}
                                            hideSelectedOptions={true}
                                            backspaceRemovesValue={false}
                                            onChange={e => handleDropdownChangeForProviderName(e)}
                                            menuPlacement="bottom"
                                            position={'fixed'} 
                                        />
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.providerName?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>
                        <div className="d-flex align-items-center mr-3" style={{ paddingLeft: '28px' }}>
                            <label className="pr-2 pb-0 mb-0">Year</label>
                            <div className="d-flex justify-content-end">
                                <div className="btn-group">
                                    <Dropdown
                                        list={yearList}
                                        value={year}
                                        onSelect={((e) => { handleDropdownForYear(e) })}>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mr-3">
                            <label className="pr-2 pb-0 mb-0">Month</label>
                            <div className="d-flex justify-content-end">
                                <div className="btn-group">
                                    <Dropdown
                                        list={monthList}
                                        value={month}
                                        onSelect={((e) => { handleDropdownForMonth(e) })}>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mr-3">
                            <label className="pr-2 pb-0 mb-0">LOB</label>
                            <div className="d-flex justify-content-end" >
                                <Dropdown
                                    list={lobSelect}
                                    value={selectLob}
                                    onSelect={((e) => { handleLOBSelect(e) })}>
                                </Dropdown>

                            </div>
                        </div>

                    </span>
                </div>
                <div className="mt-1 pl-2">
                    <AwvTabHeader
                        handleSiteSelect={handleSiteSelect}
                        handleProviderAddressGroupsSelect={handleProviderAddressGroupsSelect}
                        handleCitySelect={handleCitySelect}
                        handleAddressSelect={handleAddressSelect}
                        handleZipSelect={handleZipSelect}
                        siteIdSelect={siteIdSelect}
                        providerAddressGroupsSelect={providerAddressGroupsSelect}
                        providerAddressGroupsNameSelect={providerAddressGroupsNameSelect}
                        citySelect={citySelect}
                        siteAddressSelect={siteAddressSelect}
                        zipSelect={zipSelect}
                    />
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0">

                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider', pl: 3 }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="AWE Provider Reporting View" value="1" />
                                    <Tab label="Analytics View" value="2" />
                                </TabList>
                            </Box>
                            {getMenuPermissionCheck(process.env.REACT_APP_BUCKET_AWV_ReportingView_Slug) ?
                                <TabPanel value='1'>
                                    <div className="list-member mt-4" style={{ "position": "static" }}>
                                        {/* <label className="pr-2 pb-0 mb-0" style={{ 'marginLeft': '20px' }}>Download Complete Report for AWV for Year {year} for month {month}</label> */}
                                        {/* disable button if file is not present */}
                                        {filePresentFlag ?
                                            <Button onClick={handleDownload} > Download Complete Report </Button>
                                            :
                                            <Button disabled='true' onClick={handleDownload} > Download Complete Report </Button>
                                        }
                                        <div className="col-lg-12 pr-0 pl-0 mt-4">
                                            <DynamicColumnShow
                                                url={`/member_details_asm?ipaCode=` + selectIPACode + `&ipaName=` + selectIPAName + `&selectProviderValueArray=`
                                                    + selectProviderValueArray + `&year=${year}` + `&lob=${selectLob}` + `&Month=${filterMonth}` +
                                                    `&address_grouping=` + providerAddressGroupsNameSelect + `&site_zip=` + zipSelect + `&site_city=` + citySelect + `&site_address=` + siteAddressSelect + `&site_id=` + siteIdSelect}
                                                downloadFileName={currentDateProviderView}
                                                filter={true}
                                            ></DynamicColumnShow>
                                        </div>
                                    </div>

                                    <br />
                                    <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                                    <br />
                                </TabPanel>
                                :
                                <></>
                            }
                            {getMenuPermissionCheck(process.env.REACT_APP_BUCKET_AWV_AnalyticView_Slug) ?
                                <TabPanel value='2'>
                                    <div className="list-member mt-4">
                                        {/* <label className="pr-2 pb-0 mb-0" style={{ 'marginLeft': '20px' }}>Download Complete Report for AWV for Year {year} for month {month}</label>
                                   <Button   color="primary" onClick={handleDownload} > {downloadedFileName} </Button> */}
                                        {/* disable button if file is not present */}
                                        {filePresentFlag ?
                                            <Button onClick={handleDownload} > Download Complete Report </Button>
                                            :
                                            <Button disabled='true' onClick={handleDownload} > Download Complete Report </Button>
                                        }
                                        <div className="col-lg-12 pr-0 pl-0 mt-4">
                                            <DynamicColumnShow
                                                url={`/member_details_prev_visits?ipaCode=` + selectIPACode + `&ipaName=` + selectIPAName + 
                                                    `&selectProviderValueArray=` + selectProviderValueArray + `&year=${year}` + `&lob=${selectLob}` + `&Month=${filterMonth}` +
                                                    `&address_grouping=` + providerAddressGroupsNameSelect + `&site_zip=` + zipSelect + `&site_city=` +citySelect + `&site_address=` + siteAddressSelect + `&site_id=` + siteIdSelect}
                                                downloadFileName={currentDateAnalyticView}
                                                filter={true}
                                                defaultSortCol={"MEMBER_ID"}
                                            ></DynamicColumnShow>
                                        </div>
                                    </div>

                                    <br />
                                    <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                                    <br />
                                    <br />
                                    <br />
                                </TabPanel>
                                :
                                <></>
                            }
                        </TabContext>
                        <div className='col-lg-12 pr-0 pl-0'>

                        </div>
                    </Box>

                    <div className='col-lg-12 pr-0 pl-0'>
                    </div>
                </div>
            </div>
        </>
    )

};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        aweSiteSelect: state.moduleFilter.aweSiteSelect,
        aweProviderGroupingSelect: state.moduleFilter.aweProviderGroupingSelect,
        aweCitySelect: state.moduleFilter.aweCitySelect,
        aweAddressSelect: state.moduleFilter.aweAddressSelect,
        aweZipSelect:state.moduleFilter.aweZipSelect,
    };
}
export default connect(mapStateToProps)(AWEDetailedAnalysis);
