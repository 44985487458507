import React, { useState, useEffect } from 'react';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import QueryLetter from '../../assets/constants/QueryLetter.Instance';
import Button from "../layout/aweLayout/Button";
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '../../components/CustomButtons/IconButton'
import PlusFileIcon from '../../components/icons/PlusFileIcon';
import PlusFillIcon from '../../components/icons/PlusFillIcon';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import mainLogo from '../../assets/images/Invent_logo_White.png';
import headerLogo from '../../assets/images/lacareheaderlogo.png';
import footerLogo from '../../assets/images/lacarefooterlogo.png';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HtmlEditorComponent from "../CustomInput/HtmlEditorComponent"
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import Divider from '@mui/material/Divider';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import CustomAutocompleteSingle from '../CustomInput/CustomAutocompleteSingle';
import Box from '@mui/material/Box';
import pdfIcon from '../../assets/images/pdf_icon.png';
import Moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import AWS from 'aws-sdk';
const aws = require('aws-sdk');

const S3_BUCKET = 'bucket-awv-form-upload'; //process.env.REACT_APP_BUCKET_NAME_Two;
const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = localStorage.getItem("access_key");
const SECRET_ACCESS_KEY = localStorage.getItem("secret_key");

const styles = (theme) => ({
    dialogPaper: {
        maxWidth: '80%',
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});


const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const QueryLetterCoderWorkBench = (props) => {
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
    //const [editorValue, setEditorValue] = useState('<p style="margin-left:0px;"><strong>Please return this provider query letter and the clinical documentation clarification by&nbsp;XXXday, Month XX, 2023,</strong> via fax (000) 000-8251 or encrypted secure email to&nbsp;<strong>providerrisk@gmail.com</strong></p><br/><p style="margin-left:0px;">&nbsp;</p><br/><p style="margin-left:0px;">If you have any questions/concerns, send an email to our risk adjustment education team at&nbsp;<span style="color:rgb(0,123,255);">providerrisk@gmail.com</span>&nbsp;or call (000) 000-8251 ex. 4000</p><br/><p style="margin-left:0px;">&nbsp;</p><br/><p style="margin-left:0px;"><u>Remember to include your best contact number</u>&nbsp;in the voicemail, email, or on the fax cover sheet.</p><br/><p style="margin-left:0px;">&nbsp;</p><br/><p style="margin-left:0px;"><u>Non-responsiveness could impact your AWE incentive.</u></p><br/><p style="margin-left:0px;"><u>﻿</u></p><br/><p style="margin-left:0px;">Thank you</p><br/><p style="margin-left:0px;">Reference Dummy HP</p><br/><p style="margin-left:0px;">Provider Risk Adjustment Education Team</p>');
    const [editorValue, setEditorValue] = useState('<p style="margin-left:0px;">Please return this provider query letter and the requested clinical documentation clarification by&nbsp;<span style="font-weight:bold;">XXXday, Month XX, 2024</span></span>, via fax (000) 000-8251 or encrypted secure email to&nbsp;<strong>providerrisk@gmail.com</strong></p><br/><p style="margin-left:0px;">If you have any questions/concerns, please send an email to our risk adjustment education team at&nbsp;<span style="color:rgb(0,123,255);">providerrisk@gmail.com</span>&nbsp;or call (000) 000-8251 ex. 4000</p><br/><p style="margin-left:0px;"><u style="font-weight:bold;">Remember to include your best contact number</u>&nbsp;in the voicemail, email, or on the fax cover sheet.</p><br/><p style="margin-left:0px;"><u style="font-weight:bold;">Non-responsiveness could impact your AWE incentive.</u></p><br><p style="margin-left:0px;">Thank you</p><p style="margin-left:0px;">Reference Dummy HP</p><p style="margin-left:0px;">Provider Risk Adjustment Education Team</p>');
    const [queryLetterEdit, setQueryLetterEdit] = useState('');
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const descriptionElementRef = React.useRef(null);
    const [attempt, setAttempt] = useState('');
    const [formattedDate, setFormattedDate] = useState('');
    const [attempOptions, setAttempOptions] = useState('');
    const [dxCodeData, setDxCodeData] = useState([]);
    const [dxCodeValue, setDxCodeValues] = useState('');
    const [isdxCodeValue, setIsDxCodeValues] = useState(false);
    const [responseEditMode, setResponseEditMode] = useState(false);

    useEffect(() => {
        reset();
        console.log('QueryLetterCoderWorkBench', { props });
        setResponseEditMode(false)
        setDxCodeValues('');
        setDxCodeData([]);
        if (props.open && props.open == true) {
            var memberName = '';
            if (props?.coderBenchmarkData?.member_f_name && props?.coderBenchmarkData.member_f_name != '') {
                memberName = props?.coderBenchmarkData?.member_f_name;
                if (props?.coderBenchmarkData?.member_l_name && props?.coderBenchmarkData.member_l_name != '') {
                    memberName = props?.coderBenchmarkData?.member_f_name + ' ' + props?.coderBenchmarkData?.member_l_name;
                }
            } else if (props?.coderBenchmarkData?.member_l_name && props?.coderBenchmarkData.member_l_name != '') {
                memberName = props?.coderBenchmarkData?.member_l_name;
            }

            setValue('response_details', editorValue);
            setValue('dob', (props?.coderBenchmarkData?.member_dob && props?.coderBenchmarkData.member_dob != '') ? props?.coderBenchmarkData.member_dob : '');
            setValue('awe_id', (props?.coderBenchmarkData?.awe_id && props?.coderBenchmarkData.awe_id != '') ? props?.coderBenchmarkData.awe_id : '');
            setValue('cin', (props?.coderBenchmarkData?.ih_member_id && props?.coderBenchmarkData.ih_member_id != '') ? props?.coderBenchmarkData.ih_member_id : '');
            setValue('member_name', (memberName && memberName != '') ? memberName : '');

            if (props.querryLatterGetData && props.querryLatterGetData.length > 0 && props.querryLatterGetData[0] && props.querryLatterGetData[0]?.attempt) {
                setValue('attempt', props.querryLatterGetData[0]?.attempt + 1);
                setAttempt(props.querryLatterGetData[0]?.attempt + 1)
            } else {
                setValue('attempt', '1');
                setAttempt(1)
            }

            setValue('subject', 'Clinical Documentation Clarification');
            setValue('transaction_date', Moment().format('YYYY-MM-DD'));
            setValue('date_of_service', Moment().format('YYYY-MM-DD'));

        }
        console.log('QueryLetterCoderWorkBench', getValues());
    }, [props.open]);

    useEffect(() => {
        // Get today's date
        const today = new Date();

        // Calculate the date after 10 days
        const futureDate = new Date(today);
        futureDate.setDate(futureDate.getDate() + 10);

        // Format the future date
        const formattedDate = futureDate.toLocaleDateString('en-US', { day: 'numeric', month: 'long', year: 'numeric' });

        // Replace the placeholder text with the formatted date
        const originalText = editorValue;
        const newText = originalText.replace('XXXday, Month XX, 2024', formattedDate);

        // Update the state with the modified text
        setEditorValue(newText);
        setFormattedDate(formattedDate)
    }, []); // Empty dependency array ensures this effect runs only once when the component mounts



    const handleResponseEditMode = () => {
        console.log('handleResponseEditMode');
        setResponseEditMode(true);
    }

    const handleInputChangeDxCode = (eventValue) => {
        setIsDxCodeValues(true);
        setDxCodeValues(eventValue);
        if (containsAlphabeticCharacters(eventValue)) {
            setValue(`action_taken_to`, eventValue);
        }
        if (eventValue.length >= 2) {

        } else {
            setDxCodeData([]);
            setIsDxCodeValues(false);
            return true;
        }
        QueryLetter.get('/get_healthcare_provider_npi_data?search=' + eventValue)
            .then(res => {
                let valuesArray = [];
                res.data.forEach((element, index) => {
                    if (!valuesArray.includes(element.icd_code + '-' + element.icd_code_description)) {
                        valuesArray.push({ 'key': element.npi, 'value': element.first_name + ' ' + element.middle_name + ' ' + element.last_name });
                    }
                });
                setDxCodeData(valuesArray);
                setIsDxCodeValues(false);
            })
            .catch(err => {
                setDxCodeData([]);
                setIsDxCodeValues(false);
            })

    }

    const handleChangeDxCode = (eventValue) => {
        // console.log(eventValue);
        setValue(`action_taken_to`, eventValue?.key);
        setDxCodeValues(eventValue?.value)
        setDxCodeData([]);
    }

    const containsAlphabeticCharacters = (text) => {
        // Regular expression to match any alphabetic character
        var regex = /[a-zA-Z]/;
        // Check if the text contains any alphabetic characters
        return regex.test(text);
    }

    const onSubmit = (data) => {
        setFormSubmitisLoading(true);
        let controlUser = JSON.parse(localStorage.getItem('controlUser'));
        data.created_by = controlUser?.email;
        data.created_on = Moment().format('YYYY-MM-DD HH:mm:ss');
        data.cw_id = getValues('cin');
        data.cin = getValues('cin');
        data.updated_by = controlUser?.email;
        data.temp_id = "1";
        data.lob = props?.lobSelect.toString();
        data.year = props?.yearSelect.toString();
        data.action_taken_to = data.action_taken_to.toString();
        data.attempt = data.attempt.toString();
        data.delivery_method = data['dob'].toString();
        delete data['member_name'];
        delete data['dob'];
        console.log({ data });
        data.org_id = '1';
        QueryLetter.post('/add_query_letter', data)
            .then(res => {
                toast.success('Query letter has been added successfully.');
                props.handleClose();
                setFormSubmitisLoading(false);
            })
            .catch(err => {
                setFormSubmitisLoading(false);
                toast.error(err?.response?.data?.message);
            })
    }


    const handleChangeEditor = (event) => {
        setValue('response_details', event);
        setEditorValue(event);
    }

    const getOrdinalSuffix = (number) => {
        const suffixes = ["th", "st", "nd", "rd"];
        const lastDigit = number % 10;
        const suffix = lastDigit <= 3 ? suffixes[lastDigit] : suffixes[0];
        return number + suffix;
    }

    useEffect(() => {
        setAttempOptions('');
        var attempOptiosTemp = [];
        for (let i = 1; i <= (attempt + 1); i++) {
            attempOptiosTemp.push(
                <option value={i} >
                    {getOrdinalSuffix(i)} Attempt
                </option>
            );
        }
        setAttempOptions(attempOptiosTemp)
    }, [attempt]);


    return (
        <>
            <div style={{ display: "inline-block" }}>
                <IconButton className='mr-2' onClick={() => props.handleClickOpen()}>
                    <PlusFillIcon className="w16" />
                </IconButton>
            </div>

            <MuiDialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth={true}
                maxWidth={'md'}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >

                <DialogTitle id="scroll-dialog-title">
                    <div className='row'>
                        <div className="col-lg-4 text-left">
                            <span style={{ color: "red" }}>
                                {getOrdinalSuffix(attempt)} Attempt
                            </span>
                        </div>
                        <div className="col-lg-4 text-center" style={{ fontSize: "20px" }}>
                            <strong>
                                Provide Query Letter
                            </strong>
                        </div>
                        <div className="col-lg-4 text-right">
                        <span className='p-1' style={{ fontSize: "25px" }}>
                            <img src={headerLogo} style={{ width: "auto", height: "82px" }} alt="" className="logo" />
                            </span>
                        </div>
                    </div>
                </DialogTitle>
                <Divider className="mr-3 ml-3" style={{ backgroundColor: "#1a9698" }} />
                <MuiDialogContent dividers={'paper'} className='custom-scrollbar'>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <form id="queryForm">
                            {isLoading && isLoading == true ?
                                <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                                    <CircularProgress />
                                </div>
                                :
                                <></>
                            }
                            <div>
                                <div className="row mb-2">
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong style={{ fontSize: "14px" }}>Attempt:</strong></div>
                                    </div>
                                    <div className="col-lg-6 align-items-center ">
                                        <Controller
                                            name={`attempt`}
                                            control={control}
                                            render={({ field }) => (
                                                <select
                                                    {...field}
                                                    className="form-control"
                                                    disabled={true}
                                                >
                                                    <option value={''} >
                                                        Select Attempt
                                                    </option>
                                                    {attempOptions}
                                                </select>
                                            )}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                        {errors && errors?.attempt?.type === "required" && <label className="form-input-error">This field is required</label>}
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong style={{ fontSize: "14px" }}>Date:</strong></div>
                                    </div>
                                    <div className="col-lg-6 align-items-center ">
                                        <Controller
                                            name={`transaction_date`}
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="date"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    readOnly={true}
                                                />
                                            )}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                        {errors && errors?.transaction_date?.type === "required" && <label className="form-input-error">This field is required</label>}
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong style={{ fontSize: "14px" }}>Recipient:</strong></div>
                                    </div>
                                    <div className="col-lg-6 align-items-center ">
                                        <Controller
                                            name={`subject`}
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    placeholder="Subject"
                                                />
                                            )}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                        {errors && errors?.subject?.type === "required" && <label className="form-input-error">This field is required</label>}
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong style={{ fontSize: "14px" }}>Member Name:</strong></div>
                                    </div>
                                    <div className="col-lg-6 align-items-center ">
                                        <Controller
                                            name={`member_name`}
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    placeholder="Member Name"
                                                    readOnly={true}
                                                />
                                            )}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                        {errors && errors?.member_name?.type === "required" && <label className="form-input-error">This field is required</label>}
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong style={{ fontSize: "14px" }}>DOB:</strong></div>
                                    </div>
                                    <div className="col-lg-6 align-items-center ">
                                        <Controller
                                            name={`dob`}
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="date"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    readOnly={true}
                                                />
                                            )}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                        {errors && errors?.dob?.type === "required" && <label className="form-input-error">This field is required</label>}
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong style={{ fontSize: "14px" }}>DOR:</strong></div>
                                    </div>
                                    <div className="col-lg-6 align-items-center ">
                                        <Controller
                                            name={`date_of_service`}
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="date"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    max={Moment().format("YYYY-MM-DD")}
                                                />
                                            )}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                        {errors && errors?.date_of_service?.type === "required" && <label className="form-input-error">This field is required</label>}
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong style={{ fontSize: "14px" }}>AWE ID:</strong></div>
                                    </div>
                                    <div className="col-lg-6 align-items-center ">
                                        <Controller
                                            name={`awe_id`}
                                            control={control}
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    type="text"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    readOnly={true}
                                                />
                                            )}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                        {errors && errors?.awe_id?.type === "required" && <label className="form-input-error">This field is required</label>}
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong style={{ fontSize: "14px" }}>To:</strong></div>
                                    </div>
                                    <div className="col-lg-6 align-items-center custom-autocomplete">
                                        <Controller
                                            name={`action_taken_to`}
                                            control={control}
                                            render={({ field }) => (
                                                <CustomAutocompleteSingle
                                                    {...field}
                                                    id={`action_taken_to`}
                                                    value={(dxCodeValue && dxCodeValue != '') ? dxCodeValue : ''}
                                                    onChange={(event) => handleInputChangeDxCode(event?.target?.value)}
                                                    handleOnClick={(event) => handleChangeDxCode(event)}
                                                    options={(dxCodeData && dxCodeData.length > 0) ? dxCodeData : []}
                                                    optionTitle={'key'}
                                                    optionValue={'value'}
                                                    optionListCustome={true}
                                                    placeholder="Type to search..."
                                                    autoComplete="off"
                                                    className="form-control"
                                                />
                                            )}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                        {errors && errors?.action_taken_to?.type === "required" && <label className="form-input-error">This field is required</label>}
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-lg-3 align-items-center">
                                        <div className='mb-1'><strong style={{ fontSize: "14px" }}>Description:</strong></div>
                                    </div>
                                    <div className="col-lg-6 align-items-center ">
                                        <Controller
                                            name={`clarification_description`}
                                            control={control}
                                            render={({ field }) => (
                                                <textarea
                                                    {...field}
                                                    rows="5"
                                                    columns="100"
                                                    autoComplete="off"
                                                    className="form-control"
                                                    placeholder="Enter Clarification Description"
                                                />
                                            )}
                                            rules={{
                                                required: true,
                                            }}
                                        />
                                        {errors && errors?.clarification_description?.type === "required" && <label className="form-input-error">This field is required</label>}
                                    </div>
                                </div>


                            </div>
                            <div className="row mb-2" style={{ marginBottom: "10px" }}>
                                <div className="col-lg-3 align-items-center">
                                    <div className='mb-1'><strong style={{ fontSize: "14px" }}>Response Details:</strong></div>
                                </div>
                                {responseEditMode && responseEditMode == true ?
                                    <>

                                        <div className="col-lg-9 align-items-center ">
                                            <Controller
                                                name={`response_details`}
                                                control={control}
                                                render={({ field }) => (
                                                    <HtmlEditorComponent  {...field} onChange={handleChangeEditor} />
                                                )}
                                                rules={{
                                                    required: true,
                                                }}
                                            />
                                            {errors && errors?.response_details?.type === "required" && <label className="form-input-error">This field is required</label>}
                                        </div>
                                    </>
                                    :
                                    <div className="col-lg-9 align-items-center ">
                                        <div onClick={() => handleResponseEditMode()} style={{ marginLeft: "0rem !important" }}>
                                            <div className="query-text instructions  text-underline mb-3">
                                                <p>Please return this provider query letter and the requested clinical documentation clarification by <strong >{formattedDate}</strong><span className='font-weight-normal'>, via fax (000) 000-8251 or encrypted secure email to </span><span style={{ color: "#007bff" }}>providerrisk@gmail.com</span></p>
                                            </div>
                                            <div className="query-text mb-3">
                                                <p>If you have any questions/concerns, please send an email to our risk adjustment education team at <span style={{ color: "#007bff" }}>providerrisk@gmail.com</span> or call (000) 000-8251 ex. 4000</p>
                                            </div>
                                            <div className="query-text footer mb-3">
                                                <p><u className='font-weight-bold'>Remember to include your best contact number</u> in the voicemail, email, or on the fax cover sheet.</p>
                                            </div>
                                            <div className="query-text mb-3">
                                                <p><u className='font-weight-bold'>Non-responsiveness could impact your AWE incentive.</u></p>
                                            </div>
                                            <div className="mb-3">
                                                <span>Thank you</span><br />
                                                <span>Reference Dummy HP</span><br />
                                                <span>Provider Risk Adjustment Education Team</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="footer footer-pdf d-flex justify-content-between pt-3 border-top border-primary">
                                <div className="address text-muted">
                                    <span>{process.env.REACT_APP_PATH_QUERY_LETTER_FOOTER_ONE}</span><br />
                                    <span>{process.env.REACT_APP_PATH_QUERY_LETTER_FOOTER_TWO}</span>
                                </div>
                                <div className="logo">
                                    <span className='p-1' style={{ fontSize: "25px" }}>
                                        <img src={footerLogo} style={{ width: "auto", height: "40px" }} alt="" className="logo" />
                                    </span>
                                </div>
                            </div>
                        </form>
                    </DialogContentText>
                </MuiDialogContent>
                <Divider className="mr-3 ml-3" style={{ backgroundColor: "#1a9698" }} />
                <DialogActions>

                    <div className="form-group flex_item_cs gap10">
                        <Button type='button' className={"btn-white minw80"} onClick={props.handleClose}>CANCEL</Button>
                        <Button form="queryForm" type='submit' className={"minw80"} onClick={handleSubmit(onSubmit)} disabled={isLoading && isLoading == true ? true : false}>SAVE</Button>
                    </div>
                </DialogActions>
            </MuiDialog >
        </>
    );
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        userDetails: state.auth.userDetails,
        yearSelect: state.moduleFilter.yearSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        lobSelect: state.moduleFilter.lobSelect,
    };
}
export default (withStyles(styles), connect(mapStateToProps))(QueryLetterCoderWorkBench);
