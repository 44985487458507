import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { Chart } from "react-google-charts";
import { connect } from 'react-redux';

const ColumnChartSingleExcelMedicare = (props) => {
    const chart = useRef(null);
    const [type, setType] = useState('');
    const [data, setData] = useState([]);

    useEffect(() => {
        if (props.data && props.data.length != 0) {           
            setData(props.data);
        }else{
            // setData([["Month", props.yearSelect, { role: "style" }],["January", 0, "#1A9698"]]);
            setData([
                    ["Code", 'Total'],
                    ["AHPN", 0],
                ]);
        }
    }, [props.data]);

    // const data = [
    //     ["Month", "2021", { role: "style" }],
    //     ["January", 1000, "#1A9698"],
    //     ["February ", 1170, "#1A9698"],
    //     ["March", 660, "#1A9698"],
    //     ["April", 1030, "#1A9698"],
    //     ["May", 1030, "#1A9698"],
    //     ["June", 1030, "#1A9698"],
    //     ["July", 1030, "#1A9698"],
    //     ["August", 1030, "#1A9698"],
    //     ["September", 1030, "#1A9698"],
    //     ["Octomber", 1030, "#1A9698"],
    //     ["November", 1030, "#1A9698"],
    //     ["December", 1030, "#1A9698"],
    // ];

    const options = {
        // title: "CUMULATIVE (SUM OF ALL MONTHS WITH YEAR)",
        hAxis: { title: "PPG Group", titleTextStyle: { color: "#333" },direction:-1, slantedText:true, slantedTextAngle:90 },
        legend: { position: "right", maxLines: 3 },
        vAxis: { title: "Provider PPG Level", titleTextStyle: { color: "#333" }, minValue: 0 },
        chartArea: { width: "78%", height: "60%" },
        // colors: ['#1A9698']
    };

    return (
        <Chart
            chartType="ColumnChart"
            width="100%"
            height="400px"
            data={data}
            options={options}
        />
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
    };
}
export default connect(mapStateToProps)(ColumnChartSingleExcelMedicare);