import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import GridTable from "../../components/TableGrids/GridTable.table";
import {getAcaYear} from '../../assets/constants/yearList';
 import { HCC, ISSUER_ID } from "../../assets/constants/string.constants";
 import history from "../../history";
 import { anchorTag } from '../../assets/constants/customRender';
import CardComponent from "../../components/Cards/card";
import { connect } from 'react-redux';

const TrigramHccACA = (props) =>{

    const [tableData1, setTableData1] = useState([]);
    const [tableData2, setTableData2] = useState([]);
    const [year] = useState(props.yearSelect);
    const [issuer_id] = useState(localStorage.getItem(ISSUER_ID));
    const [hcc] = useState(localStorage.getItem(HCC));
    
    const  summaryColumns1=[
        {
            name: "hccCode",
            label: "HCC Code",
        },
        {
            name: "description",
            label: "Description",
        },
        {
            name: "totalCount",
            label: "# Members"
        },
        {
            name: "changeInCount",
            label: "Probability"
        }
    ];

    
    const  summaryColumns2 =[
        {
            name: "hccCode",
            label: "HCC Code",
            options: anchorTag
        },
        {
            name: "description",
            label: "Description",
        },
        {
            name: "totalCount",
            label: "# Members"
        },
        {
            name: "changeInCount",
            label: "Probability"
        } 
    ];
   
    const Tableclick = (rowdata, colData, cellMeta) =>{
               
        localStorage.setItem(ISSUER_ID,issuer_id);
        localStorage.setItem(HCC,rowdata[cellMeta.rowIndex].hccCode);
       
        history.push('/layout/acaTrigramHcc');
    }
    

    const loadMetricTable1 = () =>{
        api.get('/ACATrigram/hcc/'+hcc+'/year/'+year+'/issuer_id/'+issuer_id,)
        .then(res=>{
            setTableData1(res);
        })
        .catch(err=>{
            // console.log(err);
        })
    }

    const loadMetricTable2 = () =>{
        api.get('/ACATrigramHcc/hcc/'+hcc+'/year/'+year+'/issuer_id/'+issuer_id,)
        .then(res=>{
            setTableData2(res);
        })
        .catch(err=>{
            // console.log(err);
        })
    }


    useEffect(() =>{
        loadMetricTable1();
        loadMetricTable2();      
    },[year]);

    return(
        <div>
            <CardComponent
            heading={`Health Conditions in Focus - Year : ${year}`}
            body={
                <GridTable
                    data={tableData1}
                    columns={summaryColumns1}
                ></GridTable>
            }></CardComponent>
            <br/>
            <CardComponent
            heading={`You may want to focus on the following Health Conditions as well`}
            body={
                <GridTable
                data={tableData2}
                columns={summaryColumns2}>
                onTableClick={Tableclick}
                </GridTable>
            }></CardComponent>
             <br/>
            <br/>     
       </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(TrigramHccACA);