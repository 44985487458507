import React, { useState, useReducer, useEffect } from "react";
import { connect } from "react-redux";
import { currentRoute } from "../../store/actions";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import ReportDesignDialog from "../../components/Report-Dialog/ReportDesignDialog";
import CardComponent from "../../components/Cards/card";
import {
  downloadButton,
  deleteButton,
  dateFormat,
} from "../../assets/constants/customRender";
import api, { url } from "../../assets/constants/Rafservice.Instance";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import SubHeader from '../../components/home/header/subHeader';

const ReportGeneration = (props) => {
  const [reportURL, setReportURL] = useState("/reportsGenerated");
  // const [sortorder,setSortorder] = useState(false);

  //   const[count,setCount] =  useState(0);
  const reportCol = [
    {
      name: "reportfileName",
      label: "Report Name",
    },
    {
      name: "report_parameters",
      label: "Parameters Description",
    },
    {
      name: "createdOn",
      label: "Created On",
      options: dateFormat,
    },
    {
      name: "id",
      label: "Download",
      options: downloadButton,
    },
    {
      name: "id",
      label: "Delete",
      options: deleteButton,
    },
  ];

  const columns = [
    {
      name: "Report_name",
      label: "Report Design Name",
    },
    {
      name: "description",
      label: "Design Description",
    },
    {
      name: "role",
      label: "Role",
    },
    {
      name: "id",
      label: "Actions",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          //    setSortorder(true);
          let open = true;
          //     setCount(count + 1);
          return (
            <ReportDesignDialog
              value={value}
              tableMeta={tableMeta}
              updateValue={updateValue}
              refreshReport={refreshReportGrid}
            ></ReportDesignDialog>
          );
        },
      },
    },
  ];

  const reportTableClick = (rowdata, colData, cellMeta, value) => {
    if (cellMeta.colIndex === 3 && colData.props.children != "") {
      let row = cellMeta.rowIndex;
      let id = rowdata[row].id;
      downloadReport(id);
    }

    if (cellMeta.colIndex === 4 && colData.props.children != "") {
      let row = cellMeta.rowIndex;
      let id = rowdata[row].id;
      confirmAlert({
        title: "Confirm to delete",
        message: "Are you sure you want to delete this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => deleteReport(id),
          },
          {
            label: "Cancel",
          },
        ],
      });

      refreshReportGrid();
    }
  };

  const refreshReportGrid = () => {
    // console.log("here in Report Refresh");
    setTimeout(() => {
      setReportURL("//reportsGenerated");
      setReportURL("/reportsGenerated");
    }, 1000);
  };

  const deleteReport = (id) => {
    api
      .delete("/deleteReportQuery/" + id)
      .then((res) => {
        // console.log("row deleted");
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const downloadReport = (id) => {
    // console.log("here");
    axios({
      url: url + "/downloadReports/" + id,
      responseType: "arraybuffer",
      method: "GET",
    })
      .then((response) => {
        let header = response.headers;
        let filenameStr = header["content-disposition"].split(" ")[1];
        let filenames = filenameStr.split("=");
        let fileTypes = filenames[1].split(".");
        let filetype = fileTypes[1];
        let filpathForLinux = filenames[1].split("//");
        let filpathForWindows = filenames[1].split("\\");
        let filpath = filpathForLinux;
        if (filpathForLinux[2] === undefined) {
          filpath = filpathForWindows;
        }
        let fileType;

        if (filetype.indexOf("pdf") != -1) {
          fileType = "application/pdf";
        } else {
          fileType = "application/xls";
        }

        let file = new Blob([response.data], {
          type: fileType,
        });
        //trick to download store a file having its URL
        let fileURL = URL.createObjectURL(file);
        let a = document.createElement("a");
        a.href = fileURL;
        a.target = "_blank";
        a.download = filpath[filpath.length - 1];
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        //	$scope.getNotifications(userId);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  return (
    <>
      <div className="level-summary">
        <div className="row pb-2">
          <div className="col-lg-12">
            <div className="state-level">
              <div className="card-header">
                <span className="d-flex bd-highlight">
                  <h6 className="pt-2 flex-grow-1">Report Generation</h6>
                  <div className="d-flex align-items-center"></div>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="row">
            <div className="col-lg-12 reports">
              <CardComponent
                heading={`REPORT DESIGNS`}
                body={
                  <ServerSideGridTable
                    url={`/reports`}
                    columns={columns}
                  ></ServerSideGridTable>
                }
              ></CardComponent>
            </div>
            <br />
            <div className="col-lg-12 reports mt-4 mb-5">
              <CardComponent
                heading={`REPORTS`}
                body={
                  <ServerSideGridTable
                    url={reportURL}
                    columns={reportCol}
                    tableClick={reportTableClick}
                    defaultSortCol={2}
                    defaultSortDir={"desc"}
                  ></ServerSideGridTable>
                }
              ></CardComponent>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    activateRoute: state.route,
    isSignedIn: state.auth.isSignedIn,
    moduleSelect: state.moduleFilter.moduleSelect,
    viewBySelect: state.moduleFilter.viewBySelect,
    modelSelect: state.moduleFilter.modelSelect,
    metalTierSelect: state.moduleFilter.metalTierSelect,
    planSelect: state.moduleFilter.planSelect,
    yearSelect: state.moduleFilter.yearSelect,        
    meInitialSelect: state.moduleFilter.meInitialSelect,
  };
};
export default connect(mapStateToProps, { currentRoute })(ReportGeneration);
