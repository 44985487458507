import React from 'react'

export default function QuickNoteIcon({ ...props }) {
    return (
        <svg {...props} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="svgviewer-output (3)">
                <path id="Vector" d="M9.33341 1.83301H4.00008C3.26675 1.83301 2.67341 2.43301 2.67341 3.16634L2.66675 13.833C2.66675 14.5663 3.26008 15.1663 3.99341 15.1663H12.0001C12.7334 15.1663 13.3334 14.5663 13.3334 13.833V5.83301L9.33341 1.83301ZM10.6667 11.1663H8.66675V13.1663H7.33341V11.1663H5.33341V9.83301H7.33341V7.83301H8.66675V9.83301H10.6667V11.1663ZM8.66675 6.49967V2.83301L12.3334 6.49967H8.66675Z" fill="#E8F5F5" />
            </g>
        </svg>
    )
}