/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import styles from "../../assets/jss/layouts/footerStyle.js";

const useStyles = makeStyles(styles);

export default function AwvFooter(props) { 
    const classes = useStyles();
    return (
        <footer className={classes.footer+" p-2"}>
            <div className={classes.container}>
                <div className={classes.left}>
                    <p>
                        CPT codes, descriptions and other data only are copyright 1995 - 2022 American Medical Association. All rights reserved. CPT is a registered trademark of the American Medical Association (AMA).
                    </p>
                </div>
            </div>
        </footer>
    );
}
