import { useState, useEffect } from 'react';
import { anchorTag, numberWithCommas } from '../../assets/constants/customRender';
import { getAcaIssuer, getAcaIssuerList } from '../../assets/constants/issuerList';
import api from '../../assets/constants/Rafservice.Instance';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import SingleHorizontalBar from '../../components/Charts/SingleHorizontalBar.chart';
import AcaIssuersSelect from '../../components/DropDowns/AcaIssuersSelect';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import history from '../../history';
import ServerSideGridTable from '../../components/TableGrids/ServerSideGridTable.table';
import { HCC, PROVIDER_ID } from '../../assets/constants/string.constants';
import CardComponent from '../../components/Cards/card';
import BarVertical from '../../components/GChart/BarVertical';
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';
import { getMeInitialSelect} from '../../assets/constants/meInitialList';

const MaoProviderRetrospectiveGapsPage = (props) => {

    const [HccChartData, setHccChartData] = useState([]);
    const [issuer, setIssuer] = useState(getAcaIssuer());

    const hccMedicareColumns = [
        {
            label: "Provider Number",
            name: "PROVIDER_NUMBER",
            options: anchorTag
        },
        {
            label: "Total Claims with Comorbid Gap",
            name: "CLAIM_COUNT"
        },
        {
            label: "Unique Comorbid Gap HCC",
            name: "HCC_COUNT",
            options:numberWithCommas
        },
        {
            label: "Unique Members with Comorbid Gap",
            name: "MEMBER_COUNT",
            options:numberWithCommas
        },
        {
            label: "Institutional Claim count",
            name: "INSTITUTIONAL_COUNT",
            options:numberWithCommas
        },
        {
            label: "Professional Claim count",
            name: "PROFESSIONAL_COUNT",
            options:numberWithCommas
        },
    ]

    const hccTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(PROVIDER_ID, rowdata[cellMeta.rowIndex].PROVIDER_NUMBER);
            history.push('/layout/medicare/provider_claim_list');
        }
    };

    useEffect(() => {
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect
    ])
 

    return (
        <div className="level-summary">
            <div className="row pb-2">
                <div className="col-lg-12">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Provider Retrospective Gaps'} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="hcc-list pb-5">
                <CardComponent
                    heading={`PROVIDER LIST`}
                    body={  
                            <ServerSideGridTable
                                url={`/ProviderComorbidSummary/year/${getAcaYear()}/period/${getMeInitialSelect()}`}
                                columns={hccMedicareColumns}
                                tableClick={hccTableClick}
                                defaultSortCol={0}
                                defaultSortDir={'desc'}
                            ></ServerSideGridTable>
                    }></CardComponent>
            </div>
            <br />
            <br />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(MaoProviderRetrospectiveGapsPage);
