import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { Button, CircularProgress } from '@material-ui/core';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles
} from "@material-ui/core/styles";


const DynamicColumnwithData = (props) => {

    const { title, data, columns, onTableClick, fileName, className, isLoading = false, download = false, filter = false } = props;

    const DefaultOptions = {
        selectableRows: false,
        print: props.print ? props.print : false,
        searchOpen: props.searchOpen ? props.searchOpen : false,
        filter: props.filter ? props.filter : false,
        searchIcon: props.searchIcon ? props.searchIcon : false,
        downloadOptions: {
            filename: fileName ? fileName :'Download.csv'
        },
        responsive: 'stacked',
        tableBodyHeight: 'auto',
        tableBodyMaxHeight: '900px',
        downloadOptions: {
            filename: fileName? fileName :'Download.csv'
        },

        onDownload: (buildHead, buildBody, columns, data) => {
            //downloadTable(Page,count,col,Dir,Search);
            let values = [];
            var AllDataArray = []
            let rowArray = [];
            columns.forEach(columnInside => {

                values.push(columnInside.name)
            })
            /* data.forEach((element, index) => {
                rowArray= [];
                values.forEach((el, i) => {
                    rowArray.push(ObjectToFindValue(element, el));
                });
                AllDataArray.push({index:index, data:rowArray});
            }); */
            //data = toArray(filterRowObject)

            return "\uFEFF" + buildHead(columns) + buildBody(data);
        },
        onCellClick: (colData, cellMeta) => {
            if (props.onTableClick) {
                onTableClick(colData, cellMeta, data, columns);
            }
        },
    }
    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MuiTableCell: {
                    head: {
                        backgroundColor: "rgb(82, 108, 236) !important",
                        color: "rgb(239, 240, 247) !important",
                        fontWeight: "bold !important"
                    },
                    sortOrder: {
                        backgroundColor: "rgb(82, 108, 236) !important",
                        color: "rgb(239, 240, 247) !important",
                        fontWeight: "bold !important"
                    }
                },
                MUIDataTableSelectCell: {
                    headerCell: {
                        backgroundColor: "rgb(82, 108, 236) !important",
                        color: "rgb(239, 240, 247) !important",
                        fontWeight: "bold !important"
                    }
                },
            }
        });

    return (
        <div className={className}>
            {data.length > 0 ?
                <MUIDataTable
                    title={title}
                    data={data}
                    columns={columns}
                    options={DefaultOptions}
                    className={"pb-5"}
                />
                :
                isLoading ? <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                    <CircularProgress />
                </div> : <h6 className="text-center">No records found!..</h6>
            }
        </div>
    );
}
export default DynamicColumnwithData;