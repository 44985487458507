import MUIDataTable from "mui-datatables";
import PropTypes from "prop-types";
import { Button, CircularProgress } from '@material-ui/core';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import { TableHead } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
    createMuiTheme,
    MuiThemeProvider,
    withStyles
} from "@material-ui/core/styles";

const ExpandableGridTable = (props) => {

    const { title, data, columns, subColumns = [], onTableClick, className, isLoading = false, pagination = false } = props;

    // const onTableClick = (e,el,data,columns) =>{
    //          console.log("here inside grid"+ e,el,data,columns);
    //     if(props.onTableClick){
    //         onTableClick(e,el,data,columns);

    //     }        
    // }

    const options = {
        selectableRows: false,
        pagination: props.pagination ? props.pagination : false,
        print: props.print ? props.print : false,
        searchOpen: props.searchOpen ? props.searchOpen : false,
        filter: props.filter ? props.filter : false,
        searchIcon: props.searchIcon ? props.searchIcon : false,
        download: props.download ? props.download : false,
        search: props.search ? props.search : false,
        viewColumns: props.viewColumns ? props.viewColumns : false,
        downloadOptions: {
            filename: 'Download.csv'
        },
        // responsive: "scrollMaxHeight",
        responsive: 'stacked',
        tableBodyHeight: 'auto',
        tableBodyMaxHeight: '900px',
        expandableRows: true,
        renderExpandableRow: (rowData, rowMeta) => {

            // console.log(subColumns);
            const colSpan = rowData.length + 1;
            return (
                <>
                    {/* {subColumns && subColumns.length > 0 ?
                        subColumns.forEach((element, index) => {
                                <TableRow>
                                    <TableCell variant={'head'} colSpan={2}>CreatedBy</TableCell>
                                    <TableCell colSpan={2}>CreatedBy</TableCell>
                                </TableRow>

                            
                        })
                        :
                        <></>
                    } */}

                    {data[rowMeta.dataIndex]?.createdByUser && data[rowMeta.dataIndex]?.createdByUser?.id ?
                        <TableRow>
                            <TableCell variant={'head'} colSpan={2}>CreatedBy</TableCell>
                            <TableCell colSpan={(colSpan - 2)}>{data[rowMeta.dataIndex]?.createdByUser?.email}</TableCell>
                        </TableRow>
                        :
                        <></>
                    }
                    {data[rowMeta.dataIndex]?.updatedByUser && data[rowMeta.dataIndex]?.updatedByUser?.id ?
                        <TableRow>
                            <TableCell variant={'head'} colSpan={2}>UpdatedBy</TableCell>
                            <TableCell colSpan={(colSpan - 2)}>{data[rowMeta.dataIndex]?.updatedByUser?.email}</TableCell>
                        </TableRow>
                        :
                        <></>
                    }

                </>
            );
        },
        onCellClick: (colData, cellMeta) => {
            //    console.log(colData,cellMeta);
            if (props.onTableClick) {
                onTableClick(colData, cellMeta, data, columns);
            }
        },
    }

    const getMuiTheme = () =>
        createMuiTheme({
            overrides: {
                MuiTableCell: {
                    head: {
                        backgroundColor: "rgb(82, 108, 236) !important",
                        color: "rgb(239, 240, 247) !important",
                        fontWeight: "bold !important"
                    },
                    sortOrder: {
                        backgroundColor: "rgb(82, 108, 236) !important",
                        color: "rgb(239, 240, 247) !important",
                        fontWeight: "bold !important"
                    }
                },
                MUIDataTableSelectCell: {
                    headerCell: {
                        backgroundColor: "rgb(82, 108, 236) !important",
                        color: "rgb(239, 240, 247) !important",
                        fontWeight: "bold !important"
                    }
                },
                MUIDataTableHeadCell: {
                    sortActive: {
                        color: "rgb(239, 240, 247) !important",
                    }
                }
            }
        });

    return (
        <div className={className}>
            {data.length > 0 ?
                <MuiThemeProvider theme={getMuiTheme()}>
                    <MUIDataTable
                        title={title}
                        data={data}
                        columns={columns}
                        options={options}
                        className={"pb-5"}
                    />
                </MuiThemeProvider>
                :
                isLoading ? <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                    <CircularProgress />
                </div> : <h6 className="text-center">No records found!..</h6>
            }
        </div>
    );

}

ExpandableGridTable.propTypes = {
    title: PropTypes.array,
    data: PropTypes.array,
    columns: PropTypes.array,
    subColumns: PropTypes.array,
    onTableClick: PropTypes.func,
    pagination: PropTypes.bool,
    isLoading: PropTypes.bool
}

export default ExpandableGridTable;