import { useEffect, useState, useRef } from "react";
import { anchorTag, hccSeperator } from "../../assets/constants/customRender";
import api from "../../assets/constants/Rafservice.Instance";
import { CARRIER_ID, GENDER, HCC, ISSUER_ID, MEMBER_ID, MEMBER_NAME, PLAN_ID, REPORTED_INSIGHTS } from "../../assets/constants/string.constants";
import { getAcaYear } from "../../assets/constants/yearList";
import CardComponent from "../../components/Cards/card";
import SingleHorizontalBar from "../../components/Charts/SingleHorizontalBar.chart";
import GridTable from "../../components/TableGrids/GridTable.table";
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import history from "../../history";
import GChart from '../../components/GChart/GChart';
import PieChart from '../../components/GChart/PieChart';
import { connect } from 'react-redux';

const ReportedHccACA = (props) =>{
    const myRefChart = useRef('')
    const [hcc, setHcc] = useState(localStorage.getItem(HCC));
    const [issuer_id, setIssuer_id] = useState(localStorage.getItem(ISSUER_ID));
    const [year, setYear] = useState(props.yearSelect);
    const [hccDetails, sethccDetails] = useState([]);
    const [HccChartData, setHccChartData] = useState({});
    const [ChartData, setChartData] = useState([]);
    const loadHccDetails = () =>{
        // console.log(hcc);
        api.get('/acagaphccDetail/hcc/'+hcc+'/year/'+year+'/issuer_id/'+issuer_id)
        .then(res=>{
            sethccDetails(res);
        })
        .catch(err=>{
            // console.log(err);
        })
    }

    const hccDetailsCols = [
        {
            name:"hccCode",
            label:"HCC Code"
        },
        {
            name:"description",
            label:"Description"
        },
        {
            name:"totalCount",
            label:"# Members"
        }
    ];

    const loadReportedChart = () =>{
        api.get('/acareportedToReported/year/'+year+'/hcc/'+hcc+'/issuer_id/'+issuer_id)
        .then(res=>{
            let hccCode = [];
            let percentage = [];
            let chartDataRow = [];
            chartDataRow.push(["Description", "Percentage", { role: 'annotation' }]);
            for (const dataObj of res) {
                hccCode.push((dataObj.description + ':' + dataObj.hccCode));
                percentage.push(dataObj.percentage);
                chartDataRow.push([dataObj.description + ':' + dataObj.hccCode, dataObj.percentage, dataObj.percentage+'%']);
            }
            setChartData(chartDataRow);
            setHccChartData({
                labels: hccCode,
                data:  percentage,
                dLabel: "Percentage",
            });
        })
    };

    const hccChartClick = (element) =>{
        let chartData = element[0]['_chart'].config.data;
        let idx = element[0]['_index'];
        let hcc = chartData.labels[idx];
        let parts = hcc.split(':');
        let hcc1 = parts[1]; 
        setHcc(hcc1)
    };

    const memberColumns = [
        {
            label : "Member ID",
            name:"MEMBER_ID",
            options: anchorTag
        },
        {
            name : "MEMBER_Name",
            label : "Member Name"
        },
        {
            name : "Carrier_ID",
            label : "Carrier ID"
        },
        {
            name : "HCC_POST_HIERARCHY",
            label : "HCC LIST",
            options: hccSeperator
        },
        {
            name : "Risk_Score",
            label : "Risk Score"
        },
        {
            name : "Metal_Tier",
            label : "Metal Tier"
        },
        {
            name : "Risk_Model",
            label : "Risk Model"
        },
        {
            name : "Gender",
            label : "Gender"
        },
        {
            name : "Plan_ID",
            label : "Plan ID"
        }
    ];

    const memberTableClick = (rowdata, colData, cellMeta) =>{
        if((cellMeta.colIndex === 0)&&(colData.props.children != "")){
            localStorage.setItem(MEMBER_ID,rowdata[cellMeta.rowIndex].MEMBER_ID);
            localStorage.setItem(MEMBER_NAME,rowdata[cellMeta.rowIndex].MEMBER_Name);
            localStorage.setItem(CARRIER_ID,rowdata[cellMeta.rowIndex].Carrier_ID);
            localStorage.setItem(PLAN_ID,rowdata[cellMeta.rowIndex].Plan_ID);
            localStorage.setItem(GENDER,rowdata[cellMeta.rowIndex].Gender);
            history.push('/layout/acaMember', REPORTED_INSIGHTS)
        }
        else if((cellMeta.colIndex === 3)&&(colData.props.children != "")){
            localStorage.setItem(ISSUER_ID,rowdata[cellMeta.rowIndex].ISSUER_ID);
            if(typeof(colData.props.children) == "object"){
                setHcc((cellMeta.event.target.innerText).replace(',',''));
            }
            else{
                setHcc(rowdata[cellMeta.rowIndex].HCC_POST_HIERARCHY)
            }
        }
    };

    const executeScroll = () => {
        myRefChart.current.scrollIntoView();
    } 

    useEffect(()=>{
        loadHccDetails();
        loadReportedChart();
        executeScroll();
    },[hcc])
  
    useEffect(()=>{
        // console.log(props);
        if(props.hcc){
            setHcc(props.hcc);
        }else if(localStorage.getItem(HCC)){
            setHcc(localStorage.getItem(HCC));
        }
        
        executeScroll();
        // loadHccDetails();
        // loadReportedChart();
    },[props.hcc, localStorage.getItem(HCC)])


    return(
        <div ref={myRefChart}>
            <CardComponent
            heading={`HCC VIEW - FOR YEAR : ${year} AND ISSUER ID : ${issuer_id}`}
            body={
                <GridTable
                    data={hccDetails}
                    columns={hccDetailsCols}
                ></GridTable>
            }></CardComponent>
            <br/>
            <CardComponent
            heading={`TOP 5 CO-OCCURRING HCCS FOR THIS REPORTED CONDITION`}
            body={
                <PieChart data={ChartData} chartType="PieChart" height="395px" width="100%"></PieChart>
                // <SingleHorizontalBar
                //     label = {HccChartData.labels}
                //     Data ={HccChartData.data}
                //     dLabel = {HccChartData.dLabel}
                //     onChartClick = {hccChartClick}
                //     xLabel={'Percentage(%)'}
                //     yLabel={"HCC's"}
                //     suffix='%'
                // ></SingleHorizontalBar>

            }></CardComponent>
            <br />
            <div className="member-list">
            <CardComponent
            heading={`MEMBER DETAILS`}
            body={
                <ServerSideGridTable
                    url={'/ACAReportedHcc/year/'+year+'/hcc/'+hcc+'/issuer_id/'+issuer_id}
                    columns={memberColumns}
                    tableClick={memberTableClick}
                ></ServerSideGridTable>
            }></CardComponent>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br/>

                <br/>
                <br/>     
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,        
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(ReportedHccACA);
