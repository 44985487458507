import React, { useEffect, useState } from 'react'
import MainHeader from './aweLayout/MainHeader'
import MainSidebar from './aweLayout/MainSidebar'
import PageHeader from './aweLayout/PageHeader'
import Index from '../../Pages/Awe/Index'
import 'bootstrap'
import "../../assets/awe/css/style.css";
import { Switch, Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import { currentRoute, currentAWERightSidebarType, currentAWERightSidebar, signOut } from '../../store/actions';
import AWERightSideBar from '../customRightSidebar/AWERightSideBar';
import { ToastContainer, toast } from 'react-toastify';
import MainFooter from './aweLayout/MainFooter'
import history from '../../history';

function MainAwvLayout(props) {
  const [sidebarToggle, setSidebarToggle] = useState(false);
  const [titleName, setTitleName] = useState([{
    title: "AWE Analytics Summary",
  }]);
  useEffect(() => {
    document.getElementsByTagName("body")[0].classList.add("awe-layout-container")
    document.getElementsByTagName("body")[0].classList.remove("normal-layout-container")
  }, [])

  useEffect(() => {
    sidebarToggle == true ?
      document.getElementsByTagName("body")[0].classList.add("sidebar_mini")
      : document.getElementsByTagName("body")[0].classList.remove("sidebar_mini")
  }, [sidebarToggle])


  useEffect(() => {
    if (history?.location?.pathname && history?.location?.pathname == '/new_awe/awe-analytics-summary') {
      setTitleName([{
        title: "AWE Analytics Summary",
      }]);
    } else if (history?.location?.pathname && history?.location?.pathname == '/new_awe/awe-record') {
      setTitleName([{
        title: "AWE Record",
      }]);
    } 
    // else if (history?.location?.pathname && history?.location?.pathname == '/new_awe/awe-universal-report') {
    //   setTitleName([{
    //     title: "AWE Universal Report",
    //   }]);
    // } 
    // else if (history?.location?.pathname && history?.location?.pathname == '/new_awe/awe_reports') {
    //   setTitleName([{
    //     title: "Reports",
    //   }]);
    // } 
    else if (history?.location?.pathname && history?.location?.pathname == '/new_awe/awe_dashboard') {
      setTitleName([{
        title: "Operational Dashboard",
      }]);
    } else if (history?.location?.pathname && history?.location?.pathname == '/new_awe/operational_dashboard') {
      setTitleName([{
        title: "AWE Universal Report",
      }]);
    } else if (history?.location?.pathname && history?.location?.pathname == '/new_awe/query_letter_summary') {
      setTitleName([{
        title: "Query Letter Summary",
      }]);
    }
     else if (history?.location?.pathname && history?.location?.pathname == '/new_awe/awe-coder-workbench') {
      setTitleName([{
        link:"/new_awe/awe-record",
        title: "AWE Record",
      }, {
        title: "Coder Workbench",
      }]);
    }
  }, [history?.location?.pathname]);



  const rightDrawer = (open, drawerType = '') => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (drawerType && drawerType?.type && drawerType?.type != '') {
      props.currentAWERightSidebarType(drawerType);
    } else {
      props.currentAWERightSidebarType({ 'type': 'notification' });
    }
    props.currentAWERightSidebar(open);
  };

  return (
    <div className={`wrapper awe-layout-container`}>
      <MainHeader titleName={titleName} sidebarOpen={sidebarToggle} sidebarToggle={setSidebarToggle} />
      <MainSidebar sidebarOpen={sidebarToggle} sidebarToggle={setSidebarToggle} />
      <main className="main-wrapper custom-scrollbar">
        {/* <PageHeader /> */}
        <Index />
        <AWERightSideBar notificationRightDrawer={rightDrawer} ></AWERightSideBar>
      </main>
      {props?.location && props?.location?.pathname && props?.location?.pathname == '/new_awe/awe_coder_benchmark' ?
        <MainFooter />
        :
        <></>
      }
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  )
}
const mapStateToProps = (state) => {
  return {
    isSignedIn: state.auth.isSignedIn,
    userName: state.auth?.userDetails?.userEmail,
    route: state.route.route,
    moduleSelect: state.moduleFilter.moduleSelect,
    aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
    aweRightSidebar: state.moduleFilter.aweRightSidebar,
  };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentRoute })(MainAwvLayout);
