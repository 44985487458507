import React, { useEffect, useState } from "react";
import Card from '@material-ui/core/Card';
import './home.css';
import history from '../../history';
import { AcaYearList } from '../../assets/constants/yearList';
import LabelInsideChart from "../Charts/LabelInsideChart";
import adminRoutes from "../../routes/adminRoutes";
import etlRoutes from "../../routes/etlRoutes";
import AcaMedicareRoutes from "../../routes/routes";
import { connect } from 'react-redux';
import Header from '..//home/header/header';
import AWVRoutes from "../../routes/awvRoutes";

const Home = (props) => {

    const [raisedETL, setraisedETL] = useState(false);
    const [raisedRAF, setraisedRAF] = useState(false);
    const [raisedProvider, setraisedProvider] = useState(false);
    const [raisedPredictive, setraisedPredictive] = useState(false);
    const [raisedAdmin, setraisedAdmin] = useState(false);
    const [raisedAWV, setraisedAWV] = useState(false);
    const [loacalPermission, setLoacalPermission] = useState([]);

    useEffect(() => {
        AcaYearList();
        LabelInsideChart();
        document.body.style.overflow = "auto";
        document.getElementsByTagName("body")[0].classList.add("normal-layout-container")
        document.getElementsByTagName("body")[0].classList.remove("awe-layout-container")
    }, []);

    useEffect(() => {

    }, [props.permissionDetails]);

    const getModulePermissionCheck = (routes) => {
        var permissionCheck = false;
        var loacalPermissionGet = props.permissionDetails;
        if (routes && routes.length > 0 && loacalPermissionGet && loacalPermissionGet.length > 0) {
            routes.forEach(element => {
                if (loacalPermissionGet.find(({ permission }) => permission === element.permissionSlug)) {
                    permissionCheck = true;
                    return permissionCheck;
                }
            });
        }
        return permissionCheck;
    }


    return (
        <div className="App  main-layout-background">
            <Header></Header>
            <div className="router-box">
                <div className="container modular-page">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12 mt-5 pt-5">
                            <div className="text-center">
                                <h2 className="home-heading text-white mt-5 pt-5">Modular Analytical Platform for Risk Adjustment</h2>
                            </div>
                        </div>
                        <div className="offset-md-2 col-md-4 col-sm-4 col-xs-12 col-mob">
                            {getModulePermissionCheck(etlRoutes) == true ?
                                <Card raised={raisedETL} className="ui-item bg-green text-center" onClick={() => history.push('/etl/reports')} onMouseOver={() => { setraisedETL(true) }} onMouseOut={() => { setraisedETL(false) }}>
                                    <h2><i className="fa fa-cog"></i></h2>
                                    <h6>Data Repository</h6>
                                </Card>
                                :
                                <Card className="ui-item bg-green text-center disabled">
                                    <h2><i className="fa fa-cog"></i></h2>
                                    <h6>Data Repository</h6>
                                </Card>
                            }
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12 col-mob">
                            {getModulePermissionCheck(AcaMedicareRoutes) == true ?
                                <Card raised={raisedRAF} className="ui-item bg-purple text-center" onClick={() => history.push('/layout/acaReportedMemberSummary')} onMouseOver={() => { setraisedRAF(true) }} onMouseOut={() => { setraisedRAF(false) }}>
                                    <h2>
                                        <i className="fa fa-television" aria-hidden="true"></i>
                                        <h6>RAF Application</h6>
                                    </h2>
                                </Card>
                                :
                                <Card className="ui-item bg-purple text-center disabled" >
                                    <h2>
                                        <i className="fa fa-television" aria-hidden="true"></i>
                                        <h6>RAF Application</h6>
                                    </h2>
                                </Card>
                            }
                        </div>
                        <div className="offset-md-2 col-md-4 col-sm-4 col-xs-12 col-mob">
                            <Card raised={raisedProvider} className="ui-item bg-lblue text-center" onMouseOver={() => { setraisedProvider(true) }} onMouseOut={() => { setraisedProvider(false) }}>
                                <h2><i className="fa fa-user-md"></i></h2>
                                <h6>Provider Portal</h6>
                            </Card>
                        </div>
                        <div className="col-md-4 col-sm-4 col-xs-12 col-mob">
                            <Card raised={raisedPredictive} className="ui-item bg-yellow  text-center" onMouseOver={() => { setraisedPredictive(true) }} onMouseOut={() => { setraisedPredictive(false) }}>
                                <h2><i className="fa fa-bar-chart"></i></h2>
                                <h6>Predictive Modeling Application</h6>
                            </Card>
                        </div>
                        <div className="offset-md-2 col-md-4 col-sm-4 col-xs-12 col-mob">
                            {getModulePermissionCheck(adminRoutes) == true ?
                                <Card raised={raisedAdmin} className="ui-item bg-yellow  text-center" onClick={() => history.push('admin/users')} onMouseOver={() => { setraisedAdmin(true) }} onMouseOut={() => { setraisedAdmin(false) }}>
                                    <h2><i className="fa fa-user"></i></h2>
                                    <h6>Admin</h6>
                                </Card>
                                :
                                <Card className="ui-item bg-yellow  text-center disabled" >
                                    <h2><i className="fa fa-user"></i></h2>
                                    <h6>Admin</h6>
                                </Card>
                            }
                        </div>

                        {/* <div className="col-md-4 col-sm-4 col-xs-12 col-mob"> */}
                            {/* {getModulePermissionCheck(AWVRoutes) == false ? */}
                            {/* <Card raised={raisedAWV} className="ui-item bg-purple text-center" onClick={() => history.push('/awe/awe_analytics_summary')} onMouseOver={() => { setraisedAWV(true) }} onMouseOut={() => { setraisedAWV(false) }}>
                                <h2>
                                    <i className="fa fa-user" aria-hidden="true"></i>
                                    <h6>AWE Application</h6>
                                </h2>
                            </Card> */}
                            {/* :
                            <Card raised={raisedAWV} className="ui-item bg-purple text-center disabled" >
                            <h2>
                                <i className="fa fa-user" aria-hidden="true"></i>
                                <h6>AWE Application</h6>
                            </h2>
                        </Card>
                        } */}
                        {/* </div> */}
                        <div className="col-md-4 col-sm-4 col-xs-12 col-mob">
                            {getModulePermissionCheck(AWVRoutes) == true ?
                                <Card raised={raisedAWV} className="ui-item bg-purple text-center" onClick={() => history.push('/new_awe/awe-analytics-summary')} onMouseOver={() => { setraisedAWV(true) }} onMouseOut={() => { setraisedAWV(false) }}>
                                    <h2>
                                        <i className="fa fa-user" aria-hidden="true"></i>
                                        <h6>AWE Application</h6>
                                    </h2>
                                </Card>
                                :
                                <Card raised={raisedAWV} className="ui-item bg-purple text-center disabled" >
                                    <h2>
                                        <i className="fa fa-user" aria-hidden="true"></i>
                                        <h6>AWE Application</h6>
                                    </h2>
                                </Card>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        permissionDetails: state.auth.permissionDetails,
    };
}
export default connect(mapStateToProps)(Home);
