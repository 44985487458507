import { useState, useEffect } from 'react';
import { anchorTag, numberWithCommas, hccSeperator, textToSignFormatRender, dateFormatRender } from '../../assets/constants/customRender';
import { getAcaIssuer, getAcaIssuerList } from '../../assets/constants/issuerList';
import api from '../../assets/constants/RafserviceKPI.Instance';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import SingleHorizontalBar from '../../components/Charts/SingleHorizontalBar.chart';
import AcaIssuersSelect from '../../components/DropDowns/AcaIssuersSelect';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import history from '../../history';
import ServerSideGridTable from '../../components/TableGrids/ServerSideGridTable.table';
import { HCC, PROVIDER_ID, MAO_Chart_Review_Member, MAO_TYPE, RAF_RANGE, KPI_CATEGORY, KPI_PPG, Provider_Group } from '../../assets/constants/string.constants';
import CardComponent from '../../components/Cards/card';
import BarVertical from '../../components/GChart/BarVertical';
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';
import GridTable from "../../components/TableGrids/GridTable.table";
import GaugeChart from 'react-gauge-chart'
import RangeSingleSliderChart from '../../components/Charts/RangeSingleSliderChart';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const HealthcareInsights = (props) => {
    const [expanded, setExpanded] = useState(false);
    const [selectValue, setSelectValue] = useState('');
    const [selectIndexValue, setSelectIndexValue] = useState(0);
    const [selectTypeValue, setSelectTypeValue] = useState('');
    const [selectMemberSummaryObject, setSelectMemberSummaryObject] = useState({});
    const [selectMemberSummaryData, setSelectMemberSummaryData] = useState([]);
    const [memberSummaryKPIData, setMemberSummaryKPIData] = useState([]);
    const [PreventiveServicesTotal, setPreventiveServicesTotal] = useState(0);
    const [LowValueCareTotal, setLowValueCareTotal] = useState(0);
    const [HCCCodingAnalysisTotal, setHCCCodingAnalysisTotal] = useState(0);


    const memberSummaryColumn = [
        {
            label: "#Members Eligible",
            name: "TOTAL Members Eligible"
        },
        {
            label: "#Members Completed",
            name: "Total Members Completed"
        },
        {
            label: "% Completed ",
            name: "Completed"
        },
        {
            label: "#Providers Below Average",
            name: "Total Providers Below Average"
        },
        {
            label: "#Providers Above Average",
            name: "Total Providers Above Average"
        },
    ];

    const loadDataCount = () => {
        api.get('/data')
            .then(res => {
                if (res.data) {
                    setMemberSummaryKPIData(res.data);
                }

            })
            .catch(err => {
                // console.log(err)
            })
    }
    const chartStyle = {
        height: 'auto',
        width: 200,
    }

    const KPIchartClick = (KPITitle, KPIPPG) => {
        localStorage.setItem(KPI_CATEGORY, KPITitle);
        localStorage.setItem(KPI_PPG, KPIPPG);
        props.handleChange('', Provider_Group);
    }

    useEffect(() => {
        loadDataCount();

        // console.log(HCCMODELData);
        // console.log(PCCMODELData);
        // console.log(BLENDEDMODELData);
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect,
        props.kpiModuleSelect,
    ]);

    useEffect(() => {
        setPreventiveServicesTotal(HealthcareDataFilter('Preventive Services'));
        setLowValueCareTotal(HealthcareDataFilter('Low Value Care'));
        setHCCCodingAnalysisTotal(HealthcareDataFilter('HCC Coding Analysis'));
    }, [memberSummaryKPIData]);

    const handleChange = (panel, index, type) => (event, isExpanded) => {
        if (panel['KPI'] != '') {
            setSelectMemberSummaryObject(panel);
            setSelectMemberSummaryData(panel);
            setSelectValue(panel['KPI']);
            setSelectTypeValue(panel['KPI Grouping']);
            setSelectIndexValue(index);
        } else {
            setSelectMemberSummaryObject({});
            setSelectMemberSummaryData([]);
            setSelectValue('');
            setSelectTypeValue('');
            setSelectIndexValue(0);
        }
        setExpanded(isExpanded ? panel['KPI'] : false);
    };

    const HealthcareDataFilter = (KPIGrouping) => {
        var groupMemberCount = 0;
        memberSummaryKPIData && memberSummaryKPIData.forEach(element => {
            if (element['KPI Grouping'] == KPIGrouping && element['PPG'] == 'ALL') {
                groupMemberCount++;
            }
        });
        return groupMemberCount;
    }

    var PreventiveServicesIndex = 0;
    var LowValueCareIndex = 0;
    var HCCCodingAnalysisIndex = 0;
    return (
        <div className="level-summary">
            <div className="hcc-list pb-5">
                <div className="row mt-3">
                    <div className="col-lg-12">
                        <CardComponent
                            heading={`Preventive Visits`}
                            body={
                                <div className="row mt-3 mb-5">
                                    {memberSummaryKPIData && PreventiveServicesTotal > 0 && memberSummaryKPIData.map((element, index) => {
                                        return (
                                            element['KPI Grouping'] == 'Preventive Services' && element['PPG'] == 'ALL' ?
                                                <>
                                                    <div className="col-lg-4 mb-4">
                                                        <Accordion expanded={expanded === element['KPI']} onChange={handleChange(element, ((PreventiveServicesIndex += 1) % 3 == 0) ? PreventiveServicesIndex : ((PreventiveServicesIndex + 1) % 3 == 0) ? (PreventiveServicesIndex + 1) : (PreventiveServicesIndex + 2))}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls={element['KPI'] + "-content"}
                                                                id={element['KPI'] + "-header"}
                                                            >
                                                                <div className='col-lg-12 pr-0 mb-3'>
                                                                    <div className='col-lg-12 pr-0 mb-2'>
                                                                        {element['KPI']} – Member Count
                                                                    </div>
                                                                    <div className='col-lg-11 pr-0 justify-content-center'>
                                                                        <RangeSingleSliderChart
                                                                            minValue={
                                                                                element['min']
                                                                            }
                                                                            maxValue={
                                                                                element['max']
                                                                            }
                                                                            firstValue={
                                                                                element['Completed'].replace('%', "")
                                                                            }
                                                                            secondValue={
                                                                                element['Completed'].replace('%', "")
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </AccordionSummary>
                                                        </Accordion>
                                                    </div>
                                                    {(PreventiveServicesTotal % 3 != 0) && (PreventiveServicesIndex % 3 != 0) && (PreventiveServicesTotal == PreventiveServicesIndex) ?
                                                        <>
                                                            <span data-id={PreventiveServicesIndex += 1}></span>
                                                            {(PreventiveServicesIndex % 3 != 0) ?
                                                                <span data-id={PreventiveServicesIndex += 1}></span>
                                                                :
                                                                <></>
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                    }
                                                    {selectMemberSummaryObject && expanded && (PreventiveServicesIndex % 3 == 0) && PreventiveServicesIndex == selectIndexValue && selectTypeValue == 'Preventive Services' ?
                                                        <div className="container" style={{ "marginTop": "-37px", "marginBottom": "25px" }}>
                                                            <div className="col-lg-12 bg-white">
                                                                <div className="pb-3"></div>
                                                                <div className="col-lg-12 mt-3 mb-3">
                                                                    <span style={{ color: '#526CEC', fontSize: '17px', fontWeight: "700" }}>
                                                                        {selectMemberSummaryObject['KPI'] + ' – Member Count'}
                                                                    </span>
                                                                </div>
                                                                <div className="row col-lg-12">
                                                                    <div class="col-lg-6">
                                                                        <div className='justify-content-center'>
                                                                            <div onClick={() => KPIchartClick(selectMemberSummaryObject['KPI'], 'ALL')} style={{ 'cursor': 'pointer' }}>
                                                                                <RangeSingleSliderChart
                                                                                    minValue={
                                                                                        selectMemberSummaryObject['min']
                                                                                    }
                                                                                    maxValue={
                                                                                        selectMemberSummaryObject['max']
                                                                                    }
                                                                                    firstValue={
                                                                                        selectMemberSummaryObject['Completed'].replace('%', "")
                                                                                    }
                                                                                    secondValue={
                                                                                        selectMemberSummaryObject['Completed'].replace('%', "")
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='d-flex justify-content-center mb-2'>
                                                                            {selectMemberSummaryObject && selectMemberSummaryObject['Completed'] && selectMemberSummaryObject["Y-O-Y Change"].includes("-") > 0 ?
                                                                                <h3><b>{selectMemberSummaryObject['Completed'] + ' (Decreased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                : selectMemberSummaryObject && selectMemberSummaryObject['Completed'] ?
                                                                                    <h3><b>{selectMemberSummaryObject['Completed'] + ' (Increased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6 mt-2">
                                                                        <GridTable
                                                                            data={[selectMemberSummaryData]}
                                                                            columns={memberSummaryColumn}
                                                                        ></GridTable>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        :
                                                        <></>
                                                    }
                                                </>
                                                :
                                                <></>
                                        )
                                    })}


                                </div>
                            }>
                        </CardComponent>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-12">
                        <CardComponent
                            heading={`Low Value Care`}
                            body={
                                <div className="row mt-3 mb-5">
                                    {memberSummaryKPIData && LowValueCareTotal > 0 && memberSummaryKPIData.map((element, index) => {
                                        return (
                                            element['KPI Grouping'] == 'Low Value Care' && element['PPG'] == 'ALL' ?
                                                <>
                                                    <div className="col-lg-4 mb-4">
                                                        <Accordion expanded={expanded === element['KPI']} onChange={handleChange(element, ((LowValueCareIndex += 1) % 3 == 0) ? LowValueCareIndex : ((LowValueCareIndex + 1) % 3 == 0) ? (LowValueCareIndex + 1) : (LowValueCareIndex + 2))}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls={element['KPI'] + "-content"}
                                                                id={element['KPI'] + "-header"}
                                                            >
                                                                <div className='col-lg-12 pr-0 mb-3'>
                                                                    <div className='col-lg-12 pr-0 mb-2'>
                                                                        {element['KPI']} – Member Count
                                                                    </div>
                                                                    <div className='col-lg-11 pr-0 justify-content-center'>
                                                                        <RangeSingleSliderChart
                                                                            minValue={
                                                                                element['min']
                                                                            }
                                                                            maxValue={
                                                                                element['max']
                                                                            }
                                                                            firstValue={
                                                                                element['Completed'].replace('%', "")
                                                                            }
                                                                            secondValue={
                                                                                element['Completed'].replace('%', "")
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </AccordionSummary>
                                                        </Accordion>
                                                    </div>
                                                    {(LowValueCareTotal % 3 != 0) && (LowValueCareIndex % 3 != 0) && (LowValueCareTotal == LowValueCareIndex) ?
                                                        <>
                                                            <span data-id={LowValueCareIndex += 1}></span>
                                                            {(LowValueCareIndex % 3 != 0) ?
                                                                <span data-id={LowValueCareIndex += 1}></span>
                                                                :
                                                                <></>
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                    }
                                                    {selectMemberSummaryObject && expanded && (LowValueCareIndex % 3 == 0) && LowValueCareIndex == selectIndexValue && selectTypeValue == 'Low Value Care' ?
                                                        <div className="container" style={{ "marginTop": "-37px", "marginBottom": "25px" }}>
                                                            <div className="col-lg-12 bg-white">
                                                                <div className="pb-3"></div>
                                                                <div className="col-lg-12 mt-3 mb-3">
                                                                    <span style={{ color: '#526CEC', fontSize: '17px', fontWeight: "700" }}>
                                                                        {selectMemberSummaryObject['KPI'] + ' – Member Count'}
                                                                    </span>
                                                                </div>
                                                                <div className="row col-lg-12">
                                                                    <div class="col-lg-6">
                                                                        <div className='justify-content-center'>
                                                                            <div onClick={() => KPIchartClick(selectMemberSummaryObject['KPI'], 'ALL')} style={{ 'cursor': 'pointer' }}>
                                                                                <RangeSingleSliderChart
                                                                                    minValue={
                                                                                        selectMemberSummaryObject['min']
                                                                                    }
                                                                                    maxValue={
                                                                                        selectMemberSummaryObject['max']
                                                                                    }
                                                                                    firstValue={
                                                                                        selectMemberSummaryObject['Completed'].replace('%', "")
                                                                                    }
                                                                                    secondValue={
                                                                                        selectMemberSummaryObject['Completed'].replace('%', "")
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='d-flex justify-content-center mb-2'>
                                                                            {selectMemberSummaryObject && selectMemberSummaryObject['Completed'] && selectMemberSummaryObject["Y-O-Y Change"].includes("-") > 0 ?
                                                                                <h3><b>{selectMemberSummaryObject['Completed'] + ' (Decreased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                : selectMemberSummaryObject && selectMemberSummaryObject['Completed'] ?
                                                                                    <h3><b>{selectMemberSummaryObject['Completed'] + ' (Increased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6 mt-2">
                                                                        <GridTable
                                                                            data={[selectMemberSummaryData]}
                                                                            columns={memberSummaryColumn}
                                                                        ></GridTable>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        :
                                                        <></>
                                                    }
                                                </>
                                                :
                                                <></>
                                        )
                                    })}


                                </div>
                            }>
                        </CardComponent>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-12">
                        <CardComponent
                            heading={`HCC Coding Analysis`}
                            body={
                                <div className="row mt-3 mb-5">
                                    {memberSummaryKPIData && memberSummaryKPIData.map((element, index) => {
                                        return (
                                            element['KPI Grouping'] == 'HCC Coding Analysis' && element['PPG'] == 'ALL' ?
                                                <>
                                                    <div className="col-lg-4 mb-4">
                                                        <Accordion expanded={expanded === element['KPI']} onChange={handleChange(element, ((HCCCodingAnalysisIndex += 1) % 3 == 0) ? HCCCodingAnalysisIndex : ((HCCCodingAnalysisIndex + 1) % 3 == 0) ? (HCCCodingAnalysisIndex + 1) : (HCCCodingAnalysisIndex + 2))}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls={element['KPI'] + "-content"}
                                                                id={element['KPI'] + "-header"}
                                                            >
                                                                <div className='col-lg-12 pr-0 mb-3'>
                                                                    <div className='col-lg-12 pr-0 mb-2'>
                                                                        {element['KPI']} – Member Count
                                                                    </div>
                                                                    <div className='col-lg-11 pr-0 justify-content-center'>
                                                                        <RangeSingleSliderChart
                                                                            minValue={
                                                                                element['min']
                                                                            }
                                                                            maxValue={
                                                                                element['max']
                                                                            }
                                                                            firstValue={
                                                                                element['Completed'].replace('%', "")
                                                                            }
                                                                            secondValue={
                                                                                element['Completed'].replace('%', "")
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </AccordionSummary>
                                                        </Accordion>
                                                    </div>
                                                    {(HCCCodingAnalysisTotal % 3 != 0) && (HCCCodingAnalysisIndex % 3 != 0) && (HCCCodingAnalysisTotal == HCCCodingAnalysisIndex) ?
                                                        <>
                                                            <span data-id={HCCCodingAnalysisIndex += 1}></span>
                                                            {(HCCCodingAnalysisIndex % 3 != 0) ?
                                                                <span data-id={HCCCodingAnalysisIndex += 1}></span>
                                                                :
                                                                <></>
                                                            }
                                                        </>
                                                        :
                                                        <></>
                                                    }
                                                    {selectMemberSummaryObject && expanded && (HCCCodingAnalysisIndex % 3 == 0) && HCCCodingAnalysisIndex == selectIndexValue && selectTypeValue == 'HCC Coding Analysis' ?
                                                        <div className="container" style={{ "marginTop": "-37px", "marginBottom": "25px" }}>
                                                            <div className="col-lg-12 bg-white">
                                                                <div className="pb-3"></div>
                                                                <div className="col-lg-12 mt-3 mb-3">
                                                                    <span style={{ color: '#526CEC', fontSize: '17px', fontWeight: "700" }}>
                                                                        {selectMemberSummaryObject['KPI'] + ' – Member Count'}
                                                                    </span>
                                                                </div>
                                                                <div className="row col-lg-12">
                                                                    <div class="col-lg-6">
                                                                        <div className='justify-content-center'>
                                                                            <div onClick={() => KPIchartClick(selectMemberSummaryObject['KPI'], 'ALL')} style={{ 'cursor': 'pointer' }}>
                                                                                <RangeSingleSliderChart
                                                                                    minValue={
                                                                                        selectMemberSummaryObject['min']
                                                                                    }
                                                                                    maxValue={
                                                                                        selectMemberSummaryObject['max']
                                                                                    }
                                                                                    firstValue={
                                                                                        selectMemberSummaryObject['Completed'].replace('%', "")
                                                                                    }
                                                                                    secondValue={
                                                                                        selectMemberSummaryObject['Completed'].replace('%', "")
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='d-flex justify-content-center mb-2'>
                                                                            {selectMemberSummaryObject && selectMemberSummaryObject['Completed'] && selectMemberSummaryObject["Y-O-Y Change"].includes("-") > 0 ?
                                                                                <h3><b>{selectMemberSummaryObject['Completed'] + ' (Decreased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                : selectMemberSummaryObject && selectMemberSummaryObject['Completed'] ?
                                                                                    <h3><b>{selectMemberSummaryObject['Completed'] + ' (Increased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6 mt-2">
                                                                        <GridTable
                                                                            data={[selectMemberSummaryData]}
                                                                            columns={memberSummaryColumn}
                                                                        ></GridTable>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        :
                                                        <></>
                                                    }
                                                </>
                                                :
                                                <></>
                                        )
                                    })}


                                </div>
                            }>
                        </CardComponent>
                    </div>
                </div>

                {/* <div className="row mt-3">
                    <div className="col-lg-12">
                        <CardComponent
                            heading={`Preventive Visits`}
                            body={
                                <div className="row mt-3 mb-5">
                                    {memberSummaryKPIData && PreventiveServicesTotal > 0 && memberSummaryKPIData.map((element, index) => {
                                        return (
                                            element['KPI Grouping'] == 'Preventive Services' && element['PPG'] == 'ALL' ?
                                                <>
                                                    <div className="col-lg-6 mb-4">
                                                        <Accordion expanded={expanded === element['KPI']} onChange={handleChange(element, ((PreventiveServicesIndex += 1) % 2 == 0) ? PreventiveServicesIndex : (PreventiveServicesIndex + 1))}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls={element['KPI'] + "-content"}
                                                                id={element['KPI'] + "-header"}
                                                            >
                                                                <div className='col-lg-12 pr-0 mb-3'>
                                                                    <div className='col-lg-12 pr-0 mb-2'>
                                                                        {element['KPI']} – Member Count
                                                                    </div>
                                                                    <div className='col-lg-11 pr-0 justify-content-center'>
                                                                        <RangeSingleSliderChart
                                                                            minValue={
                                                                                element['min']
                                                                            }
                                                                            maxValue={
                                                                                element['max']
                                                                            }
                                                                            firstValue={
                                                                                element['Completed'].replace('%', "")
                                                                            }
                                                                            secondValue={
                                                                                element['Completed'].replace('%', "")
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </AccordionSummary>
                                                        </Accordion>
                                                    </div>
                                                    {(PreventiveServicesTotal % 2 != 0) && (PreventiveServicesIndex % 2 != 0) && (PreventiveServicesTotal == PreventiveServicesIndex) ?
                                                        <span data-id={PreventiveServicesIndex += 1}></span>
                                                        :
                                                        <></>
                                                    }
                                                    {selectMemberSummaryObject && expanded && (PreventiveServicesIndex % 2 == 0) && PreventiveServicesIndex == selectIndexValue && selectTypeValue == 'Preventive Services' ?
                                                        <div className="container" style={{ "marginTop": "-37px", "marginBottom": "25px" }}>
                                                            <div className="col-lg-12 bg-white">
                                                                <div className="pb-3"></div>
                                                                <div className="col-lg-12 mt-3 mb-3">
                                                                    <span style={{ color: '#526CEC', fontSize: '17px', fontWeight: "700" }}>
                                                                        {selectMemberSummaryObject['KPI'] + ' – Member Count'}
                                                                    </span>
                                                                </div>
                                                                <div className="row col-lg-12">
                                                                    <div class="col-lg-6">
                                                                        <div className='justify-content-center'>
                                                                            <div onClick={() => KPIchartClick(selectMemberSummaryObject['KPI'], 'ALL')} style={{ 'cursor': 'pointer' }}>
                                                                                <RangeSingleSliderChart
                                                                                    minValue={
                                                                                        selectMemberSummaryObject['min']
                                                                                    }
                                                                                    maxValue={
                                                                                        selectMemberSummaryObject['max']
                                                                                    }
                                                                                    firstValue={
                                                                                        selectMemberSummaryObject['Completed'].replace('%', "")
                                                                                    }
                                                                                    secondValue={
                                                                                        selectMemberSummaryObject['Completed'].replace('%', "")
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='d-flex justify-content-center mb-2'>
                                                                            {selectMemberSummaryObject && selectMemberSummaryObject['Completed'] && selectMemberSummaryObject["Y-O-Y Change"].includes("-") > 0 ?
                                                                                <h3><b>{selectMemberSummaryObject['Completed'] + ' (Decreased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                : selectMemberSummaryObject && selectMemberSummaryObject['Completed'] ?
                                                                                    <h3><b>{selectMemberSummaryObject['Completed'] + ' (Increased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6 mt-2">
                                                                        <GridTable
                                                                            data={[selectMemberSummaryData]}
                                                                            columns={memberSummaryColumn}
                                                                        ></GridTable>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        :
                                                        <></>
                                                    }
                                                </>
                                                :
                                                <></>
                                        )
                                    })}


                                </div>
                            }>
                        </CardComponent>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-12">
                        <CardComponent
                            heading={`Low Value Care`}
                            body={
                                <div className="row mt-3 mb-5">
                                    {memberSummaryKPIData && LowValueCareTotal > 0 && memberSummaryKPIData.map((element, index) => {
                                        return (
                                            element['KPI Grouping'] == 'Low Value Care' && element['PPG'] == 'ALL' ?
                                                <>
                                                    <div className="col-lg-6 mb-4">
                                                        <Accordion expanded={expanded === element['KPI']} onChange={handleChange(element, ((LowValueCareIndex += 1) % 2 == 0) ? LowValueCareIndex : (LowValueCareIndex + 1))}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls={element['KPI'] + "-content"}
                                                                id={element['KPI'] + "-header"}
                                                            >
                                                                <div className='col-lg-12 pr-0 mb-3'>
                                                                    <div className='col-lg-12 pr-0 mb-2'>
                                                                        {element['KPI']} – Member Count
                                                                    </div>
                                                                    <div className='col-lg-11 pr-0 justify-content-center'>
                                                                        <RangeSingleSliderChart
                                                                            minValue={
                                                                                element['min']
                                                                            }
                                                                            maxValue={
                                                                                element['max']
                                                                            }
                                                                            firstValue={
                                                                                element['Completed'].replace('%', "")
                                                                            }
                                                                            secondValue={
                                                                                element['Completed'].replace('%', "")
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </AccordionSummary>
                                                        </Accordion>
                                                    </div>       
                                                    {(LowValueCareTotal % 2 != 0) && (LowValueCareIndex % 2 != 0) && (LowValueCareTotal == LowValueCareIndex) ?
                                                        <span data-id={LowValueCareIndex += 1}></span>
                                                        :
                                                        <></>
                                                    }
                                                    {selectMemberSummaryObject && expanded && (LowValueCareIndex % 2 == 0) && LowValueCareIndex == selectIndexValue && selectTypeValue == 'Low Value Care' ?
                                                        <div className="container" style={{ "marginTop": "-37px", "marginBottom": "25px" }}>
                                                            <div className="col-lg-12 bg-white">
                                                                <div className="pb-3"></div>
                                                                <div className="col-lg-12 mt-3 mb-3">
                                                                    <span style={{ color: '#526CEC', fontSize: '17px', fontWeight: "700" }}>
                                                                        {selectMemberSummaryObject['KPI'] + ' – Member Count'}
                                                                    </span>
                                                                </div>
                                                                <div className="row col-lg-12">
                                                                    <div class="col-lg-6">
                                                                        <div className='justify-content-center'>
                                                                            <div onClick={() => KPIchartClick(selectMemberSummaryObject['KPI'], 'ALL')} style={{ 'cursor': 'pointer' }}>
                                                                                <RangeSingleSliderChart
                                                                                    minValue={
                                                                                        selectMemberSummaryObject['min']
                                                                                    }
                                                                                    maxValue={
                                                                                        selectMemberSummaryObject['max']
                                                                                    }
                                                                                    firstValue={
                                                                                        selectMemberSummaryObject['Completed'].replace('%', "")
                                                                                    }
                                                                                    secondValue={
                                                                                        selectMemberSummaryObject['Completed'].replace('%', "")
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='d-flex justify-content-center mb-2'>
                                                                            {selectMemberSummaryObject && selectMemberSummaryObject['Completed'] && selectMemberSummaryObject["Y-O-Y Change"].includes("-") > 0 ?
                                                                                <h3><b>{selectMemberSummaryObject['Completed'] + ' (Decreased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                : selectMemberSummaryObject && selectMemberSummaryObject['Completed'] ?
                                                                                    <h3><b>{selectMemberSummaryObject['Completed'] + ' (Increased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6 mt-2">
                                                                        <GridTable
                                                                            data={[selectMemberSummaryData]}
                                                                            columns={memberSummaryColumn}
                                                                        ></GridTable>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        :
                                                        <></>
                                                    }
                                                </>
                                                :
                                                <></>
                                        )
                                    })}


                                </div>
                            }>
                        </CardComponent>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-lg-12">
                        <CardComponent
                            heading={`HCC Coding Analysis`}
                            body={
                                <div className="row mt-3 mb-5">
                                    {memberSummaryKPIData && memberSummaryKPIData.map((element, index) => {
                                        return (
                                            element['KPI Grouping'] == 'HCC Coding Analysis' && element['PPG'] == 'ALL' ?
                                                <>
                                                    <div className="col-lg-6 mb-4">
                                                        <Accordion expanded={expanded === element['KPI']} onChange={handleChange(element, ((HCCCodingAnalysisIndex += 1) % 2 == 0) ? HCCCodingAnalysisIndex : (HCCCodingAnalysisIndex + 1))}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls={element['KPI'] + "-content"}
                                                                id={element['KPI'] + "-header"}
                                                            >
                                                                <div className='col-lg-12 pr-0 mb-3'>
                                                                    <div className='col-lg-12 pr-0 mb-2'>
                                                                        {element['KPI']} – Member Count
                                                                    </div>
                                                                    <div className='col-lg-11 pr-0 justify-content-center'>
                                                                        <RangeSingleSliderChart
                                                                            minValue={
                                                                                element['min']
                                                                            }
                                                                            maxValue={
                                                                                element['max']
                                                                            }
                                                                            firstValue={
                                                                                element['Completed'].replace('%', "")
                                                                            }
                                                                            secondValue={
                                                                                element['Completed'].replace('%', "")
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </AccordionSummary>
                                                        </Accordion>
                                                    </div>
                                                    {(HCCCodingAnalysisTotal % 2 != 0) && (HCCCodingAnalysisIndex % 2 != 0) && (HCCCodingAnalysisTotal == HCCCodingAnalysisIndex) ?
                                                        <span data-id={HCCCodingAnalysisIndex += 1}></span>
                                                        :
                                                        <></>
                                                    }
                                                    {selectMemberSummaryObject && expanded && (HCCCodingAnalysisIndex % 2 == 0) && HCCCodingAnalysisIndex == selectIndexValue && selectTypeValue == 'HCC Coding Analysis' ?
                                                        <div className="container" style={{ "marginTop": "-37px", "marginBottom": "25px" }}>
                                                            <div className="col-lg-12 bg-white">
                                                                <div className="pb-3"></div>
                                                                <div className="col-lg-12 mt-3 mb-3">
                                                                    <span style={{ color: '#526CEC', fontSize: '17px', fontWeight: "700" }}>
                                                                        {selectMemberSummaryObject['KPI'] + ' – Member Count'}
                                                                    </span>
                                                                </div>
                                                                <div className="row col-lg-12">
                                                                    <div class="col-lg-6">
                                                                        <div className='justify-content-center'>
                                                                            <div onClick={() => KPIchartClick(selectMemberSummaryObject['KPI'], 'ALL')} style={{ 'cursor': 'pointer' }}>
                                                                                <RangeSingleSliderChart
                                                                                    minValue={
                                                                                        selectMemberSummaryObject['min']
                                                                                    }
                                                                                    maxValue={
                                                                                        selectMemberSummaryObject['max']
                                                                                    }
                                                                                    firstValue={
                                                                                        selectMemberSummaryObject['Completed'].replace('%', "")
                                                                                    }
                                                                                    secondValue={
                                                                                        selectMemberSummaryObject['Completed'].replace('%', "")
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='d-flex justify-content-center mb-2'>
                                                                            {selectMemberSummaryObject && selectMemberSummaryObject['Completed'] && selectMemberSummaryObject["Y-O-Y Change"].includes("-") > 0 ?
                                                                                <h3><b>{selectMemberSummaryObject['Completed'] + ' (Decreased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                : selectMemberSummaryObject && selectMemberSummaryObject['Completed'] ?
                                                                                    <h3><b>{selectMemberSummaryObject['Completed'] + ' (Increased by ' + selectMemberSummaryObject["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                                                    :
                                                                                    <></>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-6 mt-2">
                                                                        <GridTable
                                                                            data={[selectMemberSummaryData]}
                                                                            columns={memberSummaryColumn}
                                                                        ></GridTable>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        :
                                                        <></>
                                                    }
                                                </>
                                                :
                                                <></>
                                        )
                                    })}


                                </div>
                            }>
                        </CardComponent>
                    </div>
                </div> */}
                {/* <div className="row mt-5 mb-5">
                    <div className="col-lg-12">
                        <Accordion expanded={expanded === 'HCC'} onChange={handleChange('HCC')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="HCC-content"
                                id="HCC-header"
                            >
                                <div className='col-lg-12 pr-0 mb-3'>
                                    <div className='col-lg-12 pr-0 mb-3'>
                                        <b>HCC CAPTURE RATE</b>
                                    </div>
                                    <div className='row'>
                                        <div className='col-lg-6'>
                                            <div className='col-lg-12 pr-0 mb-2'>
                                                HCC – Member Count
                                            </div>
                                            <div className='col-lg-11 pr-0 justify-content-center'>
                                                <RangeSingleSliderChart
                                                    minValue={
                                                        30
                                                    }
                                                    maxValue={
                                                        95
                                                    }
                                                    firstValue={
                                                        memberSummaryObjectHCC && memberSummaryObjectHCC.Completed ?
                                                            parseInt(memberSummaryObjectHCC?.Completed.replace('%', ""))
                                                            :
                                                            0
                                                    }
                                                    secondValue={
                                                        memberSummaryObjectHCC && memberSummaryObjectHCC.Completed ?
                                                            parseInt(memberSummaryObjectHCC?.Completed.replace('%', ""))
                                                            :
                                                            0
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className='col-lg-6'>
                                            <div className='col-lg-12 pr-0'>
                                                Life Long HCC – Member Count
                                            </div>
                                            <div className='col-lg-11 pr-0 justify-content-center'>
                                                <RangeSingleSliderChart
                                                    minValue={
                                                        39
                                                    }
                                                    maxValue={
                                                        95
                                                    }
                                                    firstValue={
                                                        memberSummaryObjectLongHCC && memberSummaryObjectLongHCC.Completed ?
                                                            parseInt(memberSummaryObjectLongHCC?.Completed.replace('%', ""))
                                                            :
                                                            0
                                                    }
                                                    secondValue={
                                                        memberSummaryObjectLongHCC && memberSummaryObjectLongHCC.Completed ?
                                                            parseInt(memberSummaryObjectLongHCC?.Completed.replace('%', ""))
                                                            :
                                                            0
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </AccordionSummary>
                        </Accordion>
                    </div>
                    {selectMemberSummaryObject && selectMemberSummaryData && expanded == 'HCC' ?
                        <div className="container" style={{ "marginTop": "-15px" }}>
                            <div className="col-lg-12 bg-white">
                                <div className="pb-3"></div>
                                <div className="row col-lg-12">
                                    <div class="col-lg-6">
                                        <div className="col-lg-12 mt-3 mb-3">
                                            <span style={{ color: '#526CEC', fontSize: '17px', fontWeight: "700" }}>
                                                {'HCC – Member Count'}
                                            </span>
                                        </div>
                                        <div className='justify-content-center'>
                                            <div onClick={() => KPIchartClick('HCC', 'ALL')} style={{ 'cursor': 'pointer' }}>
                                                <RangeSingleSliderChart
                                                    minValue={
                                                        30
                                                    }
                                                    maxValue={
                                                        95
                                                    }
                                                    firstValue={
                                                        memberSummaryObjectHCC && memberSummaryObjectHCC.Completed ?
                                                            parseInt(memberSummaryObjectHCC?.Completed.replace('%', ""))
                                                            :
                                                            0
                                                    }
                                                    secondValue={
                                                        memberSummaryObjectHCC && memberSummaryObjectHCC.Completed ?
                                                            parseInt(memberSummaryObjectHCC?.Completed.replace('%', ""))
                                                            :
                                                            0
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-center mb-2'>
                                            {memberSummaryObjectHCC && memberSummaryObjectHCC.Completed && memberSummaryObjectHCC["Y-O-Y Change"].includes("-") > 0 ?
                                                <h3><b>{memberSummaryObjectHCC.Completed + ' (Decreased by ' + memberSummaryObjectHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                : memberSummaryObjectHCC && memberSummaryObjectHCC.Completed ?
                                                    <h3><b>{memberSummaryObjectHCC.Completed + ' (Increased by ' + memberSummaryObjectHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        <GridTable
                                            data={memberSummaryDataHCC}
                                            columns={memberSummaryColumn}
                                        ></GridTable>
                                    </div>
                                    <div class="col-lg-6">
                                        <div className="col-lg-12 mt-3 mb-3">
                                            <span style={{ color: '#526CEC', fontSize: '17px', fontWeight: "700" }}>
                                                {'Life Long HCC – Member Count'}
                                            </span>
                                        </div>
                                        <div className='justify-content-center'>
                                            <div onClick={() => KPIchartClick('LFHCC', 'ALL')} style={{ 'cursor': 'pointer' }}>
                                                <RangeSingleSliderChart
                                                    minValue={
                                                        39
                                                    }
                                                    maxValue={
                                                        95
                                                    }
                                                    firstValue={
                                                        memberSummaryObjectLongHCC && memberSummaryObjectLongHCC.Completed ?
                                                            parseInt(memberSummaryObjectLongHCC?.Completed.replace('%', ""))
                                                            :
                                                            0
                                                    }
                                                    secondValue={
                                                        memberSummaryObjectLongHCC && memberSummaryObjectLongHCC.Completed ?
                                                            parseInt(memberSummaryObjectLongHCC?.Completed.replace('%', ""))
                                                            :
                                                            0
                                                    }
                                                />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-center mb-2'>
                                            {memberSummaryObjectLongHCC && memberSummaryObjectLongHCC.Completed && memberSummaryObjectLongHCC["Y-O-Y Change"].includes("-") > 0 ?
                                                <h3><b>{memberSummaryObjectLongHCC.Completed + ' (Decreased by ' + memberSummaryObjectLongHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                : memberSummaryObjectLongHCC && memberSummaryObjectLongHCC.Completed ?
                                                    <h3><b>{memberSummaryObjectLongHCC.Completed + ' (Increased by ' + memberSummaryObjectLongHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                    :
                                                    <></>
                                            }
                                        </div>
                                        <GridTable
                                            data={memberSummaryDataLongHCC}
                                            columns={memberSummaryColumn}
                                        ></GridTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <></>
                    }
                </div> */}
                {/* <div className="row mt-3 mb-3">
                    <div className="col-lg-6">
                        <CardComponent
                            heading={`Preventive visits (awv - preventive visits)`}
                            body={
                                <>
                                    <div className='justify-content-center'>
                                        <div onClick={() => KPIchartClick('AWV', 'ALL')} style={{ 'cursor': 'pointer' }}>
                                            <RangeSingleSliderChart
                                                minValue={
                                                    30
                                                }
                                                maxValue={
                                                    90
                                                }
                                                firstValue={
                                                    memberSummaryObjectAWV && memberSummaryObjectAWV.Completed ?
                                                        parseInt(memberSummaryObjectAWV?.Completed.replace('%', ""))
                                                        :
                                                        0
                                                }
                                                secondValue={
                                                    memberSummaryObjectAWV && memberSummaryObjectAWV.Completed ?
                                                        parseInt(memberSummaryObjectAWV?.Completed.replace('%', ""))
                                                        :
                                                        0
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center mb-2'>
                                        {memberSummaryObjectAWV && memberSummaryObjectAWV.Completed && memberSummaryObjectAWV["Y-O-Y Change"].includes("-") > 0 ?
                                            <h3><b>{memberSummaryObjectAWV.Completed + ' (Decreased by ' + memberSummaryObjectAWV["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                            : memberSummaryObjectAWV && memberSummaryObjectAWV.Completed ?
                                                <h3><b>{memberSummaryObjectAWV.Completed + ' (Increased by ' + memberSummaryObjectAWV["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                :
                                                <></>
                                        }
                                    </div>
                                    <GridTable
                                        data={memberSummaryDataAWV}
                                        columns={memberSummaryColumn}
                                    ></GridTable>
                                </>
                            }>
                        </CardComponent>
                    </div>
                    <div className="col-lg-6">
                        <CardComponent
                            heading={`Low Value Care (AEV - Low Value Care)`}
                            body={
                                <>
                                    <div className='justify-content-center'>
                                        <div onClick={() => KPIchartClick('AED', 'ALL')} style={{ 'cursor': 'pointer' }}>
                                            <RangeSingleSliderChart
                                                minValue={
                                                    4
                                                }
                                                maxValue={
                                                    39
                                                }
                                                firstValue={
                                                    memberSummaryObjectAEV && memberSummaryObjectAEV.Completed ?
                                                        parseInt(memberSummaryObjectAEV?.Completed.replace('%', ""))
                                                        :
                                                        0
                                                }
                                                secondValue={
                                                    memberSummaryObjectAEV && memberSummaryObjectAEV.Completed ?
                                                        parseInt(memberSummaryObjectAEV?.Completed.replace('%', ""))
                                                        :
                                                        0
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center mb-2'>
                                        {memberSummaryObjectAEV && memberSummaryObjectAEV.Completed && memberSummaryObjectAEV["Y-O-Y Change"].includes("-") > 0 ?
                                            <h3><b>{memberSummaryObjectAEV.Completed + ' (Decreased by ' + memberSummaryObjectAEV["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                            : memberSummaryObjectAEV && memberSummaryObjectAEV.Completed ?
                                                <h3><b>{memberSummaryObjectAEV.Completed + ' (Increased by ' + memberSummaryObjectAEV["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                :
                                                <></>
                                        }
                                    </div>
                                    <GridTable
                                        data={memberSummaryDataAEV}
                                        columns={memberSummaryColumn}
                                    ></GridTable>
                                </>
                            }>
                        </CardComponent>
                    </div>
                </div>
                <div className="row mt-3 mb-3">
                    <div className="col-lg-6">
                        <CardComponent
                            heading={`HCC Capture Rate (HCC - Member Count)`}
                            body={
                                <>
                                    <div className='justify-content-center'>
                                        <div onClick={() => KPIchartClick('HCC', 'ALL')} style={{ 'cursor': 'pointer' }}>
                                            <RangeSingleSliderChart
                                                minValue={
                                                    30
                                                }
                                                maxValue={
                                                    95
                                                }
                                                firstValue={
                                                    memberSummaryObjectHCC && memberSummaryObjectHCC.Completed ?
                                                        parseInt(memberSummaryObjectHCC?.Completed.replace('%', ""))
                                                        :
                                                        0
                                                }
                                                secondValue={
                                                    memberSummaryObjectHCC && memberSummaryObjectHCC.Completed ?
                                                        parseInt(memberSummaryObjectHCC?.Completed.replace('%', ""))
                                                        :
                                                        0
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center mb-2'>
                                        {memberSummaryObjectHCC && memberSummaryObjectHCC.Completed && memberSummaryObjectHCC["Y-O-Y Change"].includes("-") > 0 ?
                                            <h3><b>{memberSummaryObjectHCC.Completed + ' (Decreased by ' + memberSummaryObjectHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                            : memberSummaryObjectHCC && memberSummaryObjectHCC.Completed ?
                                                <h3><b>{memberSummaryObjectHCC.Completed + ' (Increased by ' + memberSummaryObjectHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                :
                                                <></>
                                        }
                                    </div>
                                    <GridTable
                                        data={memberSummaryDataHCC}
                                        columns={memberSummaryColumn}
                                    ></GridTable>
                                </>
                            }>
                        </CardComponent>
                    </div>
                    <div className="col-lg-6">
                        <CardComponent
                            heading={`HCC Capture Rate (Life Long HCC - HCC Capture Rate)`}
                            body={
                                <>
                                    <div className='justify-content-center'>
                                        <div onClick={() => KPIchartClick('LFHCC', 'ALL')} style={{ 'cursor': 'pointer' }}>
                                            <RangeSingleSliderChart
                                                minValue={
                                                    39
                                                }
                                                maxValue={
                                                    95
                                                }
                                                firstValue={
                                                    memberSummaryObjectLongHCC && memberSummaryObjectLongHCC.Completed ?
                                                        parseInt(memberSummaryObjectLongHCC?.Completed.replace('%', ""))
                                                        :
                                                        0
                                                }
                                                secondValue={
                                                    memberSummaryObjectLongHCC && memberSummaryObjectLongHCC.Completed ?
                                                        parseInt(memberSummaryObjectLongHCC?.Completed.replace('%', ""))
                                                        :
                                                        0
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-center mb-2'>
                                        {memberSummaryObjectLongHCC && memberSummaryObjectLongHCC.Completed && memberSummaryObjectLongHCC["Y-O-Y Change"].includes("-") > 0 ?
                                            <h3><b>{memberSummaryObjectLongHCC.Completed + ' (Decreased by ' + memberSummaryObjectLongHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                            : memberSummaryObjectLongHCC && memberSummaryObjectLongHCC.Completed ?
                                                <h3><b>{memberSummaryObjectLongHCC.Completed + ' (Increased by ' + memberSummaryObjectLongHCC["Y-O-Y Change"] + ' from previous Year)'}</b></h3>
                                                :
                                                <></>
                                        }
                                    </div>
                                    <GridTable
                                        data={memberSummaryDataLongHCC}
                                        columns={memberSummaryColumn}
                                    ></GridTable>
                                </>
                            }>
                        </CardComponent>
                    </div>
                </div> */}
            </div>
            <div className="hcc-list pb-5 mb-3">
            </div>
            <br />
            <br />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
        kpiModuleSelect: state.moduleFilter.kpiModuleSelect,
    };
}
export default connect(mapStateToProps)(HealthcareInsights);
