import { useState, useEffect } from 'react';
import { anchorTag, numberWithCommas } from '../../assets/constants/customRender';
import { getAcaIssuer, getAcaIssuerList } from '../../assets/constants/issuerList';
import api from '../../assets/constants/Rafservice.Instance';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import SingleHorizontalBar from '../../components/Charts/SingleHorizontalBar.chart';
import AcaIssuersSelect from '../../components/DropDowns/AcaIssuersSelect';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import history from '../../history';
import ServerSideGridTable from '../../components/TableGrids/ServerSideGridTable.table';
import { HCC, ISSUER_ID,PROVIDER_ID } from '../../assets/constants/string.constants';
import CardComponent from '../../components/Cards/card';
import BarVertical from '../../components/GChart/BarVertical';
import { connect } from 'react-redux';
import SubHeader from '../../components/home/header/subHeader';
//Undefined year and period issue- removing props.yearSelect, props.meInitialSelect 
import { getMeInitialSelect } from '../../assets/constants/meInitialList';

const MaoGapsByPCPPage = (props) => {

    const [HccChartData, setHccChartData] = useState([]);
    const [issuer, setIssuer] = useState(getAcaIssuer());

    
    const hccMedicareColumns = [
        {
            label: "#PCP ID",
            name: "PCP_ID",
            options: anchorTag
        },
        {
            label: "PCP Name",
            name: "PROVIDER_NAME"
        },
        {
            label: "# Members (A)=B+C+F",
            name: "NO_OF_MEMBERS",
            options:numberWithCommas
        },
        {
            label: "# No Gap (B)",
            name: "NO_OF_MEMBERS_WITH_NO_GAP",
            options:numberWithCommas
        },
        {
            label: "# With Gaps (C)=D+E",
            name: "NO_OF_MEMBERS_WITH_GAPS",
            options:numberWithCommas
        },
        {
            label: "# Prospective (D)",
            name: "NO_OF_PROSPECTIVE",
            options:numberWithCommas
        },
        {
            label: "# Retrospective (E)",
            name: "NO_OF_RETROSPECTIVE",
            options:numberWithCommas
        },
        {
            label: "% of Members with Gap (C/A*100)",
            name: "PERC_MEMBERS_WITH_GAP",
            options:numberWithCommas
        },
        {
            label: "Potential Risk Score",
            name: "POTENTIAL_RISK_SCORE",
            options:numberWithCommas
        },
        {
            label: "Opportunity lost (F)",
            name: "NO_OF_MEMBERS_OPP_LOST",
            options:numberWithCommas
        },
    ]

    const hccTableClick = (rowdata, colData, cellMeta) => {
        if ((cellMeta.colIndex === 0) && (colData.props.children != "")) {
            localStorage.setItem(PROVIDER_ID, rowdata[cellMeta.rowIndex].PCP_ID);
            history.push('/layout/medicare/provider_pcp_summary');
        }
    };

    useEffect(() => {
    }, [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        getAcaYear()
    ])

    useEffect(() => {
    }, [
        issuer
    ])

    return (
        <div className="level-summary">
            <div className="row pb-2">
                <div className="col-lg-12">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'PROVIDER RECOMMENDATION SUMMARY'} />
                        </div>
                    </div>
                </div>
            </div>

            <div className="hcc-list pb-5">
                <CardComponent
                    heading={`PROVIDER RECOMMENDATION SUMMARY`}
                    body={  
                            <ServerSideGridTable
                                url={`/pcpProviderSummary/year/${getAcaYear()}/period/${getMeInitialSelect()}`}
                                columns={hccMedicareColumns}
                                tableClick={hccTableClick}
                                defaultSortCol={0}
                                defaultSortDir={'desc'}
                            ></ServerSideGridTable>
                    }></CardComponent>
            </div>
            <br />
            <br />
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(MaoGapsByPCPPage);
