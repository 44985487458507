import React, { useState, useEffect } from "react";
import Chart from "react-google-charts";
import history from "../../history";

const GChartClick = (props) => {
    var { data, chartType, width, height, className, setStorgeItemName, setStorgeSecondItemName, setStorgeSecondValueName, setStorgeThirdItemName, setStorgeThirdValueName,redirectUrl, hAxisTitle, yAxistitle, removeStorageName } = props;
    // const stly ={'marginTop':'100px'}
    
    useEffect(() => {        
 
    }, [props.data]);

    const chartEvents = [
        {
          eventName: "select",
            callback({ chartWrapper }) {
                var data_row = chartWrapper.getChart().getSelection()[0].row;
                data && data.forEach((element, key)=> {     
                if(key == (data_row + 1)){
                    if(element.length > 0){
                    var data_select = element[0].split(":");
                        if(data_select.length > 0){
                            if(data_select && data_select[0]){
                                if(removeStorageName && removeStorageName.length > 0){
                                    for (var key in removeStorageName) {
                                        // console.log("key " + key + " has value " + removeStorageName[key]);
                                        localStorage.setItem(removeStorageName[key], 'null');
                                    }
                                }
                                if(setStorgeSecondItemName && setStorgeSecondValueName){
                                    localStorage.setItem(setStorgeSecondItemName, setStorgeSecondValueName);
                                }
                                if(setStorgeThirdItemName && setStorgeThirdValueName){
                                    localStorage.setItem(setStorgeThirdItemName, data_select[0]);
                                }
                                localStorage.setItem(setStorgeItemName, data_select[0]);
                                history.push('/layout/'+redirectUrl);
                            }
                        }
                    }
                } 
                });
            }
        }
    ];

    return (
        <div style={{'cursor':'pointer'}}>
            {data.length > 1?
                <Chart
                    width={width}
                    height={height}
                    className={className}
                    chartType={chartType}
                    loader={<div>Loading Chart</div>}
                    data={data}           
                    options={{
                        isStacked: true,
                        legend: { position: 'top',maxLines: 99},
                        vAxis: { minValue: 0},
                        is3D: true,
                        colors: ['#5FBEFB','#9292E7','#7AD38C','#F4BC7D','#800000'],
                        hAxis: {
                            title: hAxisTitle,
                            minValue: 0,
                        },
                        vAxis: {
                            title: yAxistitle,
                        },
                    }}
                    rootProps={{ 'data-testid': '1' }}
                    chartEvents={chartEvents}
                />
            :
                <h6 className="text-center">No records found!..</h6>
            }
        </div>
    )
}
export default GChartClick;