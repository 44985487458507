import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { CognitoUser } from "amazon-cognito-identity-js";
import UserPool from '../../../assets/constants/user-pool';
import SnackBar from '../snackbar/snackbar';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: 'white',
    padding: '20px'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textField: {
    width: '100%',
    marginTop: '10px'
  },
}));

const ForgotPassword = () => {
  const [snackBarStatus, setSnackBarStatus] = useState({ open: false, message: '' });
  const [stage, setStage] = useState(1);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [values, setValues] = React.useState({
    passwordType: '',
    showPasswordType: false,
    confirmPasswordType: '',
    showConfirmPasswordType: false
  });

  useEffect(() => {
    document.getElementsByTagName("body")[0].classList.add("normal-layout-container")
    document.getElementsByTagName("body")[0].classList.remove("awe-layout-container")
  }, []);

  const handleClickShowPassword = () => {
    setValues({ ...values, showPasswordType: !values.showPassword });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({ ...values, showConfirmPasswordType: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getUser = () => {
    // console.log(email, UserPool)
    return new CognitoUser({
      Username: email.toLowerCase(),
      Pool: UserPool
    });
  };

  const sendCode = (event) => {
    const user = getUser();
    // console.log(user);
    user.forgotPassword({
      onSuccess: data => {
        setSnackBarStatus({ open: true, message: 'Verification code send successfully!' });
      },
      onFailure: err => {
        setSnackBarStatus({ open: true, message: 'Some error occured while sending verification code !' });
      },
      inputVerificationCode: data => {
        setSnackBarStatus({ open: true, message: 'Verification code send successfully!' });
        setStage(2);
      }
    });
    event.preventDefault();
  };

  const resetPassword = event => {

    if (password !== confirmPassword) {
      console.error("Passwords are not the same");
      return;
    } else {
      getUser().confirmPassword(code, password, {
        onSuccess: data => {
          setSnackBarStatus({ open: true, message: 'Password get changed successfully!' });
          setStage(1);
          setEmail("");
        },
        onFailure: err => {
          setSnackBarStatus({ open: true, message: 'Some error occured while changing password !' });
        }
      });
    }
    event.preventDefault();
  };

  const classes = useStyles();
  return (
    <div>
      <SnackBar open={snackBarStatus.open} message={snackBarStatus.message} />
      <form className={classes.form} noValidate autoComplete="off">
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={email}
          onChange={event => setEmail(event.target.value)}
          autoFocus
        />
        <Button fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={(event) => sendCode(event)}
        >
          Send Verification Code
        </Button>
        {stage === 2 && (
          <>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              type="number"
              id="code"
              label="Code"
              name="code"
              value={code}
              min="0"
              onChange={event => { setCode(event.target.value); setSnackBarStatus({ open: false }); }}
              autoFocus
            />
            <FormControl className={clsx(classes.textField)} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Password * </InputLabel>
              <OutlinedInput
                fullWidth
                required
                id="outlined-adornment-password"
                type={values.showPasswordType ? 'text' : 'password'}
                value={password}
                onChange={event => setPassword(event.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPasswordType ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={100}
              />
            </FormControl>
            <FormControl className={clsx(classes.textField)} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">Confirm Password * </InputLabel>
              <OutlinedInput
                fullWidth
                required
                id="outlined-adornment-password"
                type={values.showConfirmPasswordType ? 'text' : 'password'}
                value={confirmPassword}
                onChange={event => setConfirmPassword(event.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showConfirmPasswordType ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={150}
              />
            </FormControl>
            <Button fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={(event) => resetPassword(event)}
            >
              Change Password
            </Button>
          </>
        )}
      </form>
    </div>
  );
}

export default ForgotPassword;