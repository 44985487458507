import React from 'react';
import "../../assets/awe/css/style.css";

const DownloadButton = (props) => {
  return (
    // <button className="download-button">
    <button class="btn"   style={{
        // position:'relative',
        color: 'white',
        // left: '',
        // transform: 'translateX(-50%)', 
        padding: '6px 16px',
        fontFamily: "'Source Sans Pro', sans-serif",
        backgroundColor: props?.color,
        top: '80%'
    }}><i class="fa fa-download" 
    > Download Claim</i>
      {/* <span className="download-icon">&#9660;</span> */}
    </button>
  );
}

export default DownloadButton;
