import React, { useState, useEffect } from "react";
import AWVApi from '../../assets/constants/AWVRafservice.Instance';
import AWVServerSideGridTable from "../../components/TableGrids/AWVServerSideGridTable.table";
import AWVExpandGridTable from "../../components/TableGrids/AWVExpandGridTable.table";
import { HCC, ISSUER_ID } from "../../assets/constants/string.constants";
import AwvCardComponent from "../../components/Cards/AwvCard";
import { connect } from 'react-redux';
import AwvSubHeader from '../../components/home/header/AwvSubHeader';
import AwvTabHeader from '../../components/home/header/AwvTabHeader';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import Tooltip from '@mui/material/Tooltip';
import { purple, red } from '@mui/material/colors';
import Awvheader from '../../components/home/header/Awvheader';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AddAWVFileUpload from '../../components/Dialog/AddAWVFileUpload';
import AreaChartMultiExcelAWV from '../../components/Charts/AreaChartMultiExcelAWV';
import ColumnChartSingleExcelAWV from '../../components/Charts/ColumnChartSingleExcelAWV';
import ColumnChartStackAWV from '../../components/Charts/ColumnChartStackAWV';
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AnalyticsSummary from './mainTab/analytics_summary';
import HCCCaptureRate from './mainTab/hcc_capture_rate';
import ReportingAnalysis from './mainTab/reporting_analysis';
import Link from '@material-ui/core/Link';
import axios from "axios";
import AWS from 'aws-sdk';
import { handleDownloadFromS3 } from "../ACA/DownloadFile";
import DownloadFileFromS3 from "../ACA/DownloadFileFromS3";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const UploadAWVWorkFlow = (props) => {
    const [formDataList, setFormDataList] = useState([]);
    const [year, setYear] = useState(props.yearSelect);
    const [open, setOpen] = useState(false);
    const [editFormData, setEditFormData] = useState({});
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [tabValue, setTabValue] = React.useState('1');
    const [isDataGridLoading, setIsDataGridLoading] = useState(false);
    const params = new URL(window.location.href).searchParams;
    const [userMemberId] = useState(params.get('cin'));
    const [siteIdSelect, setsiteIdSelect] = useState('');
    const [providerAddressGroupsNameSelect, setProviderAddressGroupsNameSelect] = useState([]);
    const [providerAddressGroupsSelect, setProviderAddressGroupsSelect] = useState([]);
    const [citySelect, setCitySelect] = useState('');
    const [siteAddressSelect, setSiteAddressSelect] = useState('');
    const [zipSelect, setZipSelect] = useState('');

    const handleSiteSelect = (e) => {
        if (e && e != '') {
            setsiteIdSelect(e.site_id);
        } else {
            setsiteIdSelect('');
        }
    }

    const handleProviderAddressGroupsSelect = (event) => {
        var setProviderSelected = [];
        var setProviderNameSelected = [];
        if (event != '' && event.length > 0) {
            event.map(function (el) {
                setProviderSelected.push(el.ppg_address_grouping.toString());
                setProviderNameSelected.push(el.ppg_address_grouping)
            })
            //Adding '' and send as comman separated value for multiple filter
            setProviderAddressGroupsSelect(setProviderSelected);
            setProviderAddressGroupsNameSelect('\"' + setProviderNameSelected.join('\",\"') + '\"');
        }
        else {
            //Adding '' and send as comman separated value for multiple filter
            setProviderAddressGroupsSelect([]);
            setProviderAddressGroupsNameSelect('');
        }
    }
    const handleCitySelect = (e) => {
        if (e && e != '') {
            setCitySelect(e.site_city);
        } else {
            setCitySelect('');
        }
    }

    const handleAddressSelect = (e) => {
        if (e && e != '') {
            setSiteAddressSelect(e.site_address);
        } else {
            setSiteAddressSelect('');
        }
    }

    const handleZipSelect = (e) => {
        if (e && e != '') {
            setZipSelect(e.site_zip);
        } else {
            setZipSelect('');
        }
    }


    useEffect(() => {
        if (userMemberId && userMemberId != '') {
            setTabValue('1');
        }
    }, [userMemberId]);

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const openAction = Boolean(anchorEl);
    const handleClickAction = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleCloseAction = () => {
        setAnchorEl(null);
    };
    const formDataColumns = [
        {
            name: "provider_group",
            label: "PROVIDER GROUP",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "total_member",
            label: "TOTAL MEMBERS",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "awv_progress",
            label: "AWE PROGRESS %",
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({ style: { minWidth: "800px", maxWidth: "800px", width: "800px !important" } }),
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ minWidth: 35 }}>
                                <Typography variant="body2" color="text.secondary">{`${Math.round(
                                    value,
                                )}%`}</Typography>
                            </Box>
                            <Box sx={{ width: '100%', ml: 1 }}>
                                <LinearProgress
                                    sx={{
                                        height: 10,
                                        borderRadius: 5,
                                        [`&.${linearProgressClasses.colorPrimary}`]: {
                                            backgroundColor: [800],
                                        },
                                        [`& .${linearProgressClasses.bar}`]: {
                                            borderRadius: 5,
                                            backgroundColor: '#1a9698',
                                        },
                                    }}
                                    color="primary"
                                    variant="determinate" value={value} />
                            </Box>
                        </Box>
                    );
                }
            }
        },
        {
            name: "awv_spending",
            label: "AWE SPENDING",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "awv_per_month",
            label: "AVG. AWV'S PER MONTH",
            options: {
                filter: true,
                sort: true,
            }
        },
    ];


    const loadformDataTable = () => {
        setFormDataList([
            {
                'id': "1",
                'provider_group': "Group Name",
                'total_member': "36",
                'awv_progress': "40",
                'awv_spending': "#DS-9574",
                'awv_per_month': "360",
            },
            {
                'id': "2",
                'provider_group': "Group Name",
                'total_member': "36",
                'awv_progress': "40",
                'awv_spending': "#DS-9574",
                'awv_per_month': "360",
            },
            {
                'id': "3",
                'provider_group': "Group Name",
                'total_member': "36",
                'awv_progress': "40",
                'awv_spending': "#DS-9574",
                'awv_per_month': "360",
            }
        ])
        // api.get('/Fourgram/hcc/' + hcc + '/year/' + year + '/issuer_id/' + issuer_id,)
        //     .then(res => {
        //         setFormDataList(res);
        //     })
        //     .catch(err => {
        //         // console.log(err);
        //     })
    }


    useEffect(() => {
        if (props.yearSelect && props.yearSelect != '') {
            loadformDataTable();
        }
    }, [props.yearSelect, props.providerGroupSelect, props.lobSelect]);

    const handleClickOpen = (value = '') => {
        setOpen(true);
        if (value && value != '') {
            editLoadFormData(value)
        } else {
            setEditFormData({});
        }
    };

    const handleClose = () => {
        setOpen(false);
        setEditFormData({});
    };

    const editLoadFormData = (value) => {
        // adminApi.get('/module/' + value)
        //     .then(res => {
        // setEditFormData(res.data);
        setEditFormData({});
        // })
        // .catch(err => {
        //     console.log(err?.response?.data?.message)
        // })
    }
    //Adding for quicklink enable
    // const [fileList,setFileList] = useState([]);
   

    // const handleListFiles= async ()=>{
    //        // working of this function --- all file data came from the aws bucket are fetch and storage the data in one place in a form of arrOfObject.
    //         try {
    //             AWS.config.update({
    //                 accessKeyId:localStorage.getItem("access_key"),
    //                 secretAccessKey:localStorage.getItem("secret_key"),
    //                 region:process.env.REACT_APP_REGION,
    //             });
    //             var s3 = new AWS.S3({
    //                 signatureVersion : 'v4',
    //             });
               
    //             //retrieve the list of objects in the S3 bucket
    //             const response = await s3.listObjects({
    //                 Bucket : process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET,
    //                 Prefix: 'Quick_link'
    //             })
    //             .promise();
    //             //set the file list state
    //             setFileList(response.Contents.map(({Key})=>Key));

    //         } catch (e) {
    //             console.log('our error',e);
    //         }
       
    // }
    
     // rendering the data once the application load & store into the fileList.
    //  useEffect(()=> {
    //     // handleListFiles();
    //     handleListFiles(setFileList)
    // },[]);
   
    // for downloaded the files 
    // const handleDownloadForQuickLink = async (path)=>{
    //     // var temp = fileList[4]
    //     // // alert(temp)
      
    //     let idx = 0;
    //     fileList.forEach((key,index)=>{
    //         if(key.includes(path)){
    //             idx = index;
    //         }
    //     })
       
    //      var file = fileList[idx] 
    //     // alert(file);
    //         try {
    //             AWS.config.update({
    //                 accessKeyId:localStorage.getItem("access_key"),
    //                 secretAccessKey:localStorage.getItem("secret_key"),
    //                 region:process.env.REACT_APP_REGION,
    //             });
    //             var s3 = new AWS.S3({
    //                 signatureVersion : 'v4',
    //             });
             

              

    //         } catch (e) {
    //             console.log('our error',e);
    //         }
           
    //         var presignedGETURL = s3.getSignedUrl('getObject',{
    //             Bucket : process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET,
    //             Key:file, //  here through SelectedFile.key generate the url for downloading

    //         });
    //         window.location = presignedGETURL;
    //        /*  await axios({
    //             url:presignedGETURL,
    //             responseType:"arraybuffer",
    //             method:"GET",
    //         }).then((res)=>{ 
    //             let file = new Blob([res.data],{
    //                 //type:"application/pdf",
    //             });
    //             let fileURL = URL.createObjectURL(file);
    //             let a = document.createElement("a");
    //             a.href=fileURL;
    //             a.target="_blank";
    //             a.download = "AWE Batch Upload Template.csv";
    //             a.click();
    //             document.body.removeChild(a);

                
    //         }).catch((err) => {console.log("Error while downloading file...")}) */
            
        

    // };
   

    return (
        <div className="container level-summary">
            <div className="row card">
                <div className="m-2">
                    <AwvSubHeader title={''} />
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0">
                    <div className="state-level">
                        <div className="col-lg-12 pr-0 pl-0 mt-4">
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={tabValue}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                                            <Tab label="REPORTING ANALYTICS" value="1" />
                                            <Tab label="AWE ANALYTICS SUMMARY" value="2" />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="2">
                                        <AnalyticsSummary />
                                    </TabPanel>
                                    {/* <TabPanel value="2">
                                        <HCCCaptureRate />
                                    </TabPanel> */}
                                    <TabPanel value="1" style={{ padding: '0px' }}>
                                        <div className="mb-3 row p-3 mr-0 ml-0" style={{ backgroundColor: "#e3e3e3" }}>
                                            <div className="mr-2">
                                                <b>Quick Links</b>
                                            </div>
                                            <div className="mr-2">
                                                |
                                            </div>
                                            <div className="mr-2">
                                                {/* <Link href={'#'} className="custom-active quick-links" sx={{ display: 'flex', display: 'flex', alignItems: 'center', cursor: "pointer" }} color="inherit" onClick={() =>handleDownloadFromS3(process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET,process.env.REACT_APP_PATH_PROVIDER_MASTER_REPORT)}>
                                                    Provider Master Report
                                                </Link> */}

                                                <DownloadFileFromS3
                                                 name={"Provider Master Report"}
                                                 bucketName ={process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET}
                                                 bucketKey ={props.lobSelect === 2 || props.lobSelect === "2" ? process.env.REACT_APP_PATH_PROVIDER_MASTER_REPORT
                                                 : process.env.REACT_APP_PATH_PROVIDER_MASTER_REPORT_ACA }
                                                 isQuickLink={true}
                                                
                                                />
                                            </div>
                                            <div className="mr-2">
                                                |
                                            </div>
                                            {/* removing Weekly - Provider Group Report report as mentioned - ID 791 */}
                                            {/* <div className="mr-2">
                                                <DownloadFileFromS3
                                                 name={"Weekly - Provider Group Report"}
                                                 bucketName ={process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET}
                                                 bucketKey ={props.lobSelect === 2 || props.lobSelect === "2" ? process.env.REACT_APP_PATH_PROVIDER_GROUP_REPORT
                                                            : process.env.REACT_APP_PATH_PROVIDER_GROUP_REPORT_ACA}
                                                 isQuickLink={true}
                                                
                                                />
                                            </div>
                                             <div className="mr-2">
                                                |
                                            </div>
                                             */}
                                           
                                            <div className="mr-2">
                                                {/* <Link href={'#'} className="custom-active quick-links" sx={{ display: 'flex', alignItems: 'center', cursor: "pointer" }} color="inherit" onClick={() =>handleDownloadFromS3(process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET,process.env.REACT_APP_PATH_PROVIDER_ADDRESS_GROUP_REPORT)}>
                                                {/* Monthly Provider Report }
                                                Monthly Completion Summary
                                                </Link> */}
                                                <DownloadFileFromS3
                                                 name={"Monthly Completion Summary"}
                                                 bucketName ={process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET}
                                                 bucketKey ={props.lobSelect === 2 || props.lobSelect === "2" ? process.env.REACT_APP_PATH_PROVIDER_ADDRESS_GROUP_REPORT
                                                            : process.env.REACT_APP_PATH_PROVIDER_ADDRESS_GROUP_REPORT_ACA}
                                                 isQuickLink={true}
                                                
                                                />

                                            </div>
                                            <div className="mr-2">
                                                |
                                            </div>
                                            <div className="mr-2">
                                            {/* <Link href={'#'} className="custom-active quick-links" sx={{ display: 'flex', alignItems: 'center', cursor: "pointer" }} color="inherit" onClick={()=>handleDownloadFromS3(process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET,process.env.REACT_APP_PATH_MONTHLY_PAYMENT_REPORT)}>
                                                Monthly Payment Report
                                                </Link> */}
                                                <DownloadFileFromS3
                                                 name={"Monthly Payment Report"}
                                                 bucketName ={process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET}
                                                 bucketKey ={props.lobSelect === 2 || props.lobSelect === "2" ? process.env.REACT_APP_PATH_MONTHLY_PAYMENT_REPORT
                                                            : process.env.REACT_APP_PATH_MONTHLY_PAYMENT_REPORT_ACA}
                                                 isQuickLink={true}
                                                
                                                />
                                            </div>
                                            <div className="mr-2">
                                                |
                                            </div>
                                            <div className="mr-2">
                                            {/* <Link href={'#'} className="custom-active quick-links" sx={{ display: 'flex', alignItems: 'center', cursor: "pointer" }} color="inherit" onClick={()=>handleDownloadFromS3(process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET,process.env.REACT_APP_PATH_SAMPLE_AWE_FORM}>
                                               Sample AWE form
                                                </Link> */}
                                                <DownloadFileFromS3
                                                 name={"New Members AWE Forms"}
                                                 bucketName ={process.env.REACT_APP_AWE_FILE_UPLOAD_BUCKET}
                                                 bucketKey ={props.lobSelect === 2 || props.lobSelect === "2" ? process.env.REACT_APP_PATH_SAMPLE_AWV_FORM
                                                            : process.env.REACT_APP_PATH_SAMPLE_AWV_FORM_ACA}
                                                 isQuickLink={true}
                                                
                                                />
                                            </div>
                                        </div>
                                        <AwvTabHeader
                                            handleSiteSelect={handleSiteSelect}
                                            handleProviderAddressGroupsSelect={handleProviderAddressGroupsSelect}
                                            handleCitySelect={handleCitySelect}
                                            handleAddressSelect={handleAddressSelect}
                                            handleZipSelect={handleZipSelect}
                                            siteIdSelect={siteIdSelect}
                                            providerAddressGroupsSelect={providerAddressGroupsSelect}
                                            providerAddressGroupsNameSelect={providerAddressGroupsNameSelect}
                                            citySelect={citySelect}
                                            siteAddressSelect={siteAddressSelect}
                                            zipSelect={zipSelect}
                                        />
                                        <ReportingAnalysis
                                            siteIdSelect={siteIdSelect}
                                            providerAddressGroupsSelect={providerAddressGroupsSelect}
                                            providerAddressGroupsNameSelect={providerAddressGroupsNameSelect}
                                            citySelect={citySelect}
                                            siteAddressSelect={siteAddressSelect}
                                            zipSelect={zipSelect}
                                        />
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </div>
                        <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                        <br />
                        <br />
                        <br />
                    </div>
                </div>
            </div>
        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        lobSelect: state.moduleFilter.lobSelect,
    };
}
export default connect(mapStateToProps)(UploadAWVWorkFlow);