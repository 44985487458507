/**
 * @file AWS Secrets Manager Helper
 * @description This module provides a helper function to retrieve secret values from AWS Secrets Manager.
 * @author SONAL JAIN
 * @requires aws-sdk
 * @created 15/05/2023
 */

const AWS = require('aws-sdk');

/**
 * Retrieves a secret value from AWS Secrets Manager.
 * @param {string} secretName - The name of the secret.
 * @returns {Promise<string>} - A promise that resolves to the secret value.
 */
export const getSecret = async (secretName, access_key, secret_key,session_token, dashboard) => {
  let accessKey  = access_key ? access_key : localStorage.getItem('access_key')
  let secretKey  = secret_key ? secret_key : localStorage.getItem('secret_key')
  let sessionToken  = session_token ? session_token : localStorage.getItem('session_token')
  // Set the AWS credentials using the provided environment variables
  var credentials = {
    accessKeyId: accessKey,
    secretAccessKey: secretKey,
    ...(dashboard ? {} : { sessionToken }),
  };
 
  
  // Update the AWS configuration with the provided credentials, region, and signature version
  AWS.config.update({credentials: credentials, region: process.env.REACT_APP_REGION, signatureVersion: "v4"});

  // Create an instance of the AWS SSM client
  const ssm = new AWS.SSM();

  // Set the parameters for retrieving the secret value cal get parameters
  const parameters = await ssm.getParameters({
    Names: secretName, // Pass the secret name in an array
    WithDecryption: true
  }).promise();

  const apiEndpoints = parameters.Parameters.reduce((endpoints, parameter) => {
    endpoints[parameter.Name] = parameter.Value;
    return endpoints;
  }, {});
  
  return apiEndpoints;
};

// module.exports = { getSecret };
