import PropTypes from "prop-types";
import {SetViewBySelect} from '../../assets/constants/viewByList';
import { currentViewBySelect } from '../../store/actions';
import { connect } from 'react-redux';

const ViewBySelect = (props) => {

    const { ViewByList, viewBySelect} = props;
    props.currentViewBySelect(viewBySelect);

    const handleSelect = (e) =>{
        SetViewBySelect(e.target.value);        
        props.currentViewBySelect(e.target.value);
        props.onViewBySelect(e.target.value);
    }

    return(
        <div className="d-flex justify-content-end">
            <div className="btn-group">
            <select className="btn btn-info dropdown-toggle btn" value={viewBySelect} onChange={handleSelect}>
                {
                    ViewByList && ViewByList.map(el => <option value={el} key={el}> {el} </option>)
                }
            </select>
            </div>
        </div>
    )
}

ViewBySelect.propTypes ={
    ViewByList : PropTypes.array,
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
    };
  }
export default connect(mapStateToProps, {currentViewBySelect})(ViewBySelect);