import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReportedHccACA from "../../Pages/ACA/ACAReportedHcc.page";
import { HCC } from "../../assets/constants/string.constants";
import CardComponent from "../../components/Cards/card";
import { TreeMap, TreeMapSeries, TreeMapRect, TreeMapLabel, ChartTooltip, TooltipArea, TooltipTemplate } from 'reaviz';
 
am4core.useTheme(am4themes_animated);

const ACAHighFreqencyChart = (props) =>{
    const chart = useRef(null);
    const [type, setType] = useState('');
    const dataGridLoad = useRef(null);
    const [active, setActive] = useState(null);

    const scrollToBottom = () => {
        dataGridLoad.current.scrollIntoView();
    }

    useLayoutEffect(() => {
        // var x = am4core.create("chartdivHeatMap", am4charts.TreeMap);
        // // console.log(props.data);
        // x.data = [];
        // /* Set color step */
        // x.colors.step = 0;
        
        // /* Define data fields */
        // x.dataFields.value = "value";
        // x.dataFields.name = "name";
        // x.dataFields.code = "code";
        // x.dataFields.color = "color";

        // x.zoomable = false;
        // x.layoutAlgorithm = x.binaryTree;
        // // x.scrollbarSeries = true;
        // // x.scrollbarX = new am4core.Scrollbar();
        // // x.scrollbarX.parent = x.bottomAxesContainer;
        
        // // x.scrollbarY = new am4core.Scrollbar();
        // // x.scrollbarY.parent = x.leftAxesContainer;
        
        // /* Configure top-level series */
        // var level1 = x.seriesTemplates.create("0");
        // var level1_column = level1.columns.template;
        // level1_column.column.cornerRadius(10, 10, 10, 10);
        // level1_column.fillOpacity = 0.8;
        // level1_column.stroke = am4core.color("#fff");
        // level1_column.strokeWidth = 5;
        // level1_column.strokeOpacity = 1;
        // level1.columns.template.events.on("hit", function(ev) {
        //     localStorage.setItem(HCC, ev.target.dataItem.dataContext.code);
        //     setType(ev.target.dataItem.dataContext.code);
        //     scrollToBottom();
        // });
        // /* Add bullet labels */
        // var level1_bullet = level1.bullets.push(new am4charts.LabelBullet());
        // level1_bullet.locationY = 0.5;
        // level1_bullet.locationX = 0.5;
        // level1_bullet.label.text = "{code}";
        // level1_bullet.label.fill = am4core.color("#fff");

        // // Create series
        // var series = x.series.push(new am4charts.ColumnSeries());
        // series.dataFields.valueY = "value";
        // series.dataFields.categoryX = "name";
        // series.tooltipText = "{name}: [bold]{value}[/]";
        // series.tooltip.label.interactionsEnabled = true;
        // series.tooltip.label.wrap = true;
        // series.tooltip.getFillFromObject = true;
        // series.tooltip.label.textAlign = "middle";
        // series.tooltip.label.fontSize = 20;
        // series.tooltip.background.fill = am4core.color("#000");
        // series.tooltip.label.fill = am4core.color("#fff");
        // series.columns.template.hoverOnFocus = true;


        // var shadow = series.tooltip.background.filters.getIndex(0);
        // shadow.dx = 10;
        // shadow.dy = 10;
        // shadow.blur = 5;
        // shadow.color = am4core.color("#f55");

        // x.hideCredits = true;

        // chart.current = x;
    
        // return () => {
        //     x.dispose();
        // };
    }, [props.data]);


    return (
        <>
            <div className="col-lg-12 pb-3" >
                <CardComponent
                    heading={`ACA HIGH FREQUENCY REPORTED HCC`}
                    body={
                        <div className="mb-2">
                            {/* <div id="chartdivHeatMap" style={{width:"100%", height: "530px", cursor: 'pointer' }}></div> */}
                            <div className="mb-3" style={{height:'20px'}}>{active !== null && (<p>{'Selected Item :- '+active}</p>)}</div>
                            {props.data && props.data.length > 0 ?
                                <TreeMap
                                    height={400}
                                    width={'100%'}
                                    data={props.data}
                                    series={
                                        <TreeMapSeries
                                            colorScheme={(data, index) => {
                                                return data.color;
                                            }}
                                            rect={
                                                <TreeMapRect
                                                    tooltip={false}
                                                    animated={true}
                                                    cursor={'pointer'}                                    
                                                    onMouseEnter={(event, data) => {
                                                        setActive(data.data.description + ' : ' + data.data.key +' = '+data.data.data);
                                                    }}
                                                    onMouseLeave={(event, data) => {
                                                        setActive(null)
                                                    }}
                                                    onClick={(event, data) => {
                                                        localStorage.setItem(HCC, data.data.key);
                                                        setType(data.data.key);
                                                        scrollToBottom();
                                                    }}
                                                />
                                            }
                                        />
                                    }
                                /> 
                            :
                                <></>
                            }
                            <div ref={dataGridLoad} />
                        </div>  
                    }></CardComponent>
            </div>
  
            {type != ''?
                <div className="col-lg-12 pb-3">
                    <div className="hcc-list mt-2">
                        <div className="pb-2">
                            <ReportedHccACA hcc={type} year={props.year} />
                        </div>
                    </div>
                </div>
            :
                <></>
            }
        </>

    );
}
export default ACAHighFreqencyChart;