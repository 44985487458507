import PropTypes from "prop-types";
import {SetDistributionSelect} from '../../assets/constants/distributionList';
import { currentDistributionSelect } from '../../store/actions';
import { connect } from 'react-redux';

const DistributionSelect = (props) => {

    const {DistributionList, distributionSelect} = props;
    props.currentDistributionSelect(distributionSelect);
    
    const handleSelect = (e) =>{
        SetDistributionSelect(e.target.value);        
        props.currentDistributionSelect(e.target.value);
        props.onDistributionSelect(e.target.value);
    }

    return(
        <div className="d-flex justify-content-end">
            <div className="btn-group">
            <select className="btn btn-info dropdown-toggle btn" value={distributionSelect} onChange={handleSelect}>
                {
                    DistributionList && DistributionList.map((el, ke) => <option value={el.key} key={ke}> {el.value} </option>)
                }
            </select>
            </div>
        </div>
    )
}

DistributionSelect.propTypes ={
    DistributionList : PropTypes.array,
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
    };
  } 
export default connect(mapStateToProps, {currentDistributionSelect})(DistributionSelect);