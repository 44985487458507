import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { Chart } from "react-google-charts";
import { connect } from 'react-redux';

const ColumnChartSingleExcelAWV = (props) => {
    const chart = useRef(null);
    const [type, setType] = useState('');
    const [data, setData] = useState([]);

    useEffect(() => {
        if (props.data && Object.keys(props.data).length != 0) {
            var mainCustomArray = [];
            var index = 0;
            var customArray = [];
            customArray[0] = "Month";
            customArray[1] = props.yearSelect;
            mainCustomArray[index] = customArray;

            for (const key in props.data) {
                var customArray = [];
                index++;
                customArray[0] = key;
                if (props.data?.[key] || props.data?.[key] == 0) {
                    customArray[1] = props.data?.[key];
                }
                mainCustomArray[index] = customArray;         
            };
            setData(mainCustomArray);
        }else{
            // setData([["Month", props.yearSelect, { role: "style" }],["January", 0, "#1A9698"]]);
            setData([
                    ["Month", props.yearSelect, { role: "style" }],
                    ["Jan", 0, "#1A9698"],
                    ["Feb ", 0, "#1A9698"],
                    ["Mar", 0, "#1A9698"],
                    ["Apr", 0, "#1A9698"],
                    ["May", 0, "#1A9698"],
                    ["Jun", 0, "#1A9698"],
                    ["Jul", 0, "#1A9698"],
                    ["Aug", 0, "#1A9698"],
                    ["Sep",0, "#1A9698"],
                    ["Oct", 0, "#1A9698"],
                    ["Nov", 0, "#1A9698"],
                    ["Dec", 0, "#1A9698"],
                ]);
        }
    }, [props.data]);

    // const data = [
    //     ["Month", "2021", { role: "style" }],
    //     ["January", 1000, "#1A9698"],
    //     ["February ", 1170, "#1A9698"],
    //     ["March", 660, "#1A9698"],
    //     ["April", 1030, "#1A9698"],
    //     ["May", 1030, "#1A9698"],
    //     ["June", 1030, "#1A9698"],
    //     ["July", 1030, "#1A9698"],
    //     ["August", 1030, "#1A9698"],
    //     ["September", 1030, "#1A9698"],
    //     ["Octomber", 1030, "#1A9698"],
    //     ["November", 1030, "#1A9698"],
    //     ["December", 1030, "#1A9698"],
    // ];

    const options = {
        // title: "CUMULATIVE (SUM OF ALL MONTHS WITH YEAR)",
        hAxis: { title: "Month", titleTextStyle: { color: "#333" } },
        legend: { position: "bottom", maxLines: 3 },
        vAxis: { title: "AWE VISITS", titleTextStyle: { color: "#333" }, minValue: 0 },
        chartArea: { width: "78%", height: "70%" },
        colors: ['#1A9698']
    };

    return (
        <Chart
            chartType="ColumnChart"
            width="100%"
            height="400px"
            data={data}
            options={options}
        />
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
    };
}
export default connect(mapStateToProps)(ColumnChartSingleExcelAWV);