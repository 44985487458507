import {
    CURRENT_MODULE_SELECT,
    CURRENT_VIEWBY_SELECT,
    CURRENT_MODEL_SELECT,
    CURRENT_METALTIER_SELECT,
    CURRENT_PLAN_SELECT,
    CURRENT_YEAR_SELECT,
    CURRENT_MEINITIAL_SELECT,
    CURRENT_DISTRIBUTION_SELECT,
    CURRENT_KPI_SELECT,
    CURRENT_PROVIDER_GROUP_SELECT,
    CURRENT_LOB_SELECT,
    CURRENT_AWE_SITE_SELECT,
    CURRENT_AWE_PROVIDER_GROUPING_SELECT,
    CURRENT_AWE_CITY_SELECT,
    CURRENT_AWE_ADDRESS_SELECT,
    CURRENT_AWE_ZIP_SELECT,
    CURRENT_AWE_RIGHTSIDE_BAR_TYPE,
    CURRENT_AWE_RIGHTSIDE_BAR,
    CURRENT_AWE_RIGHTSIDE_BAR_CLOSE_DATATABLE_RELOAD,
} from '../actions/types';
const INITIAL_STATE = {
    moduleSelect: '',
    viewBySelect: '',
    modelSelect: '',
    metalTierSelect: '',
    planSelect: '',
    yearSelect: '',
    meInitialSelect: '',
    distributionSelect: '',
    kpiModuleSelect: '',
    lobSelect: '',
    providerGroupSelect: '',
    aweSiteSelect: '',
    aweProviderGroupingSelect: '',
    aweCitySelect: '',
    aweAddressSelect: '',
    aweZipSelect: '',
    aweRightSidebarType: { 'type': 'notification' },
    aweRightSidebar: false,
    aweRightSidebarCloseDatatableReload: false
};

const filterReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CURRENT_MODULE_SELECT:
            return { ...state, moduleSelect: action.payload };
        case CURRENT_VIEWBY_SELECT:
            return { ...state, viewBySelect: action.payload };
        case CURRENT_MODEL_SELECT:
            return { ...state, modelSelect: action.payload };
        case CURRENT_METALTIER_SELECT:
            return { ...state, metalTierSelect: action.payload };
        case CURRENT_PLAN_SELECT:
            return { ...state, planSelect: action.payload };
        case CURRENT_YEAR_SELECT:
            return { ...state, yearSelect: action.payload };
        case CURRENT_MEINITIAL_SELECT:
            return { ...state, meInitialSelect: action.payload };
        case CURRENT_DISTRIBUTION_SELECT:
            return { ...state, distributionSelect: action.payload };
        case CURRENT_KPI_SELECT:
            return { ...state, kpiModuleSelect: action.payload };
        case CURRENT_LOB_SELECT:
            return { ...state, lobSelect: action.payload };
        case CURRENT_PROVIDER_GROUP_SELECT:
            return { ...state, providerGroupSelect: action.payload };
        case CURRENT_AWE_SITE_SELECT:
            return { ...state, aweSiteSelect: action.payload };
        case CURRENT_AWE_PROVIDER_GROUPING_SELECT:
            return { ...state, aweProviderGroupingSelect: action.payload };
        case CURRENT_AWE_CITY_SELECT:
            return { ...state, aweCitySelect: action.payload };
        case CURRENT_AWE_ADDRESS_SELECT:
            return { ...state, aweAddressSelect: action.payload };
        case CURRENT_AWE_ZIP_SELECT:
            return { ...state, aweZipSelect: action.payload };
        case CURRENT_AWE_RIGHTSIDE_BAR_TYPE:
            return { ...state, aweRightSidebarType: action.payload };
        case CURRENT_AWE_RIGHTSIDE_BAR:
            return { ...state, aweRightSidebar: action.payload };
        case CURRENT_AWE_RIGHTSIDE_BAR_CLOSE_DATATABLE_RELOAD:
            return { ...state, aweRightSidebarCloseDatatableReload: action.payload };
        default:
            return state;
    }
};

export default filterReducer;