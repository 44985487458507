import React,{useState, useEffect} from 'react';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import api from '../../assets/constants/Rafservice.Instance';
import { SetAcaYearList } from '../../assets/constants/yearList';
import { getAcaYear, getAcaYearList } from "../../assets/constants/yearList";
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import { MEMBER_ID, ISSUER_ID} from "../../assets/constants/string.constants";
import { connect } from 'react-redux';

const styles = (theme) => ({
    dialogPaper: {
        maxWidth: '80%',
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const ReportDesignDialog = (props) => {

    const [year, setYear] = useState(props.yearSelect);
    const [issuer_id, setIssuerId] = useState("");
    const [MetalTierData, setMetalTierData] = useState([]);
    const [RiskModelData, setRiskModelData] = useState([]);
    const [open, setOpen] = React.useState();
    const [paraFields,setParaFields] = React.useState([]);
    const [reportData,setReportData] = React.useState({});
    const [lis,setlis] = React.useState([]);
    let lis1 =[];
    const handleClickOpen = () => {
       
      createUI(props.value)
        setOpen(true);
    };

    useEffect(() => {
      setIssuerId(localStorage.getItem(ISSUER_ID));
      loadGapMetalGraph(localStorage.getItem(ISSUER_ID));
    }, [issuer_id]);

    const loadGapMetalGraph = (issuer_id) => {
      issuer_id = btoa(issuer_id);      
      api.get('/acaYoYMetalTier/year/2019/issuerId/' + issuer_id)
          .then(res => {  
           setMetalTierData(res);
          })
          .catch(err => {
          // console.log(err);
      })
      api.get('/acaYoYRiskModel/year/2019/issuerId/' + btoa(issuer_id))
          .then(res => {
            setRiskModelData(res);
          })
          .catch(err => {
          // console.log(err)
      })
    }

    const validateAndGenerateReport = (format) =>{
      let Value = true;
      let mapObject ={};
      let id =  paraFields[0].report_id;
			let time = new Date();
      for(let i =0;i<paraFields.length;i++){
          let variable = document.getElementById(paraFields[i].parameterName);
		      if (reportData?.[paraFields[i].parameterName] == "")
		      {
		          window.alert("Please enter a valid entry for " +paraFields[i].parameterName);
		          variable.focus();
		          Value = false;
		          return false; 
		      }

          var key = paraFields[i].parameterName;
          if(paraFields[i].parameterName == 'YEAR' || paraFields[i].parameterName == 'RISK_MODEL' || paraFields[i].parameterName == 'ISSUER_ID' || paraFields[i].parameterName == 'METAL_TIER'){
            mapObject[key] = variable.textContent;
          }else{
            mapObject[key] = variable.value;
          }        
		  }
      if(Value == true){			
        setOpen(false);
          api.post('/excuteReport/'+id+'/1/'+format+'/'+time,mapObject)
          .then( res =>{
            // console.log("here"+props);
          alert("Report generated");
          props.refreshReport();
        })
        .catch(err=>{
          // console.log(err);
      })
      };
  };
    
    const handlePdf= () =>{
      let format = "pdf";
     validateAndGenerateReport (format);
      
  };

  
    const handleCSV= () =>{
        let format = "xls";
     validateAndGenerateReport (format);
    }

    const handleChange = (event) =>{
      setReportData(prevState => ({
        ...prevState,
        [event.target.name] : event.target.value
      }));
    }
    

    const createUI = (value) =>{
      api.get('/generateParameterList/'+value)
      .then(res=>{
           setParaFields(res);
           for (let i=0;i<res.length;i++) {
             lis1.push(<Grid item xm={3}>
               {res[i].parameterName == "YEAR" ?
                  <div className="d-flex align-items-center">
                    <FormControl  variant="outlined" style={{'min-width':'200px'}}>
                      <InputLabel id="demo-simple-select-outlined-label">{res[i].parameterName}</InputLabel>      
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id={res[i].parameterName}
                        label={res[i].parameterName}
                        variant="outlined"
                        name={res[i].parameterName}
                        onChange={event => handleChange(event)}
                      >
                        {getAcaYearList() && getAcaYearList().map(el => {
                            return(<MenuItem value={el} key={el}>{el}</MenuItem>)
                          })
                        } 
                      </Select>
                    </FormControl>
                  </div>
                : res[i].parameterName == "RISK_MODEL" ?
                  <div className="d-flex align-items-center">
                    <FormControl variant="outlined" style={{'min-width':'200px'}}>
                      <InputLabel id="demo-simple-select-outlined-label">{res[i].parameterName}</InputLabel>      
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id={res[i].parameterName}
                        label={res[i].parameterName}
                        variant="outlined"
                        name={res[i].parameterName}
                        onChange={event => handleChange(event)}
                      >
                        {RiskModelData && RiskModelData.map((el, index) => {
                            return(<MenuItem value={el.risk_model} key={index}>{el.risk_model}</MenuItem>)
                          })
                        } 
                      </Select>
                    </FormControl>
                  </div>
                : res[i].parameterName == "ISSUER_ID" ?
                  <div className="d-flex align-items-center">
                    <FormControl variant="outlined" style={{'min-width':'200px'}}>
                      <InputLabel id="demo-simple-select-outlined-label">{res[i].parameterName}</InputLabel>      
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id={res[i].parameterName}
                        label={res[i].parameterName}
                        variant="outlined"
                        name={res[i].parameterName}
                        onChange={event => handleChange(event)}
                      >                        
                         <MenuItem value={issuer_id}>{issuer_id}</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                : res[i].parameterName == "METAL_TIER" ?
                  <div className="d-flex align-items-center">
                    <FormControl variant="outlined" style={{'min-width':'200px'}}>
                      <InputLabel id="demo-simple-select-outlined-label">{res[i].parameterName}</InputLabel>      
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id={res[i].parameterName}
                        label={res[i].parameterName}
                        variant="outlined"
                        name={res[i].parameterName}
                        onChange={event => handleChange(event)}
                      >
                        {MetalTierData && MetalTierData.map((el, index) => {
                            return(<MenuItem value={el.metal_tier} key={index}>{el.metal_tier}</MenuItem>)
                          })
                        } 
                      </Select>
                    </FormControl>
                  </div>
                :
                  <TextField id={res[i].parameterName} label={res[i].parameterName} variant="outlined" />
              }
               
               </Grid>);
              setReportData(prevState => ({
                ...prevState,
                [res[i].parameterName] : ''
              })); 
           };       
           setlis(lis1);
      })
      .catch(err=>{
         // console.log(err);
      }) 
    };


 
    const handleClose = () => {
        setOpen(false);
        
    };

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>Generate Report</Button>
      <Dialog onClose={handleClose} 
      aria-labelledby="report-generation" 
      open={open} >
        <DialogTitle id="report-generation" onClose={handleClose}>
            Enter Report Parameters
        </DialogTitle>
        <DialogContent dividers>
            < Grid container spacing={3}>
                {lis}
            </Grid> 
          

        </DialogContent>
         
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button color="primary" onClick={handlePdf}>
            Generate PDF
          </Button>
          <Button color="primary"  onClick={handleCSV}>
            Generate CSV
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
      isSignedIn: state.auth.isSignedIn,
      moduleSelect: state.moduleFilter.moduleSelect,
      viewBySelect: state.moduleFilter.viewBySelect,
      modelSelect: state.moduleFilter.modelSelect,
      metalTierSelect: state.moduleFilter.metalTierSelect,
      planSelect: state.moduleFilter.planSelect,
      yearSelect: state.moduleFilter.yearSelect,        
      meInitialSelect: state.moduleFilter.meInitialSelect,
  };
}
export default (withStyles(styles), connect(mapStateToProps))(ReportDesignDialog);
