import PersonIcon from '@material-ui/icons/Person';
import DescriptionIcon from '@material-ui/icons/Description';
import AWVMemberDetails from "../Pages/AWV/AWVMemberDetails";

const awvDashboardRoutes = [
    {
        name: "AWV Analytics",
        icon: DescriptionIcon,
        layout: "/awv",
        path: '',
        module: 'ACA',
        permissionSlug: 'awv_analytics_8',
        subRoutes: [
           /*  {
                path: "/AWVMemberSummary",
                name: "AWV Member Summary",
                icon: PersonIcon,
                component: AWVMemberSummary,
                layout: "/awv",
                permissionSlug: 'reported_hcc_summary_1'
            }, */
            {
                path: "/AWVMemberDetails",
                name: "AWV Member Details",
                icon: PersonIcon,
                component: AWVMemberDetails,
                layout: "/awv",
                permissionSlug: 'awv_member_details_8',
                roleSlug: 'awv_member_details_8'
            },
        ]
    },
    {
        name: "AWV Analytics",
        icon: DescriptionIcon,
        layout: "/awv",
        path: '',
        module: 'Medicare',
        permissionSlug: 'awv_analytics_8',
        subRoutes: [
           /*  {
                path: "/AWVMemberSummary",
                name: "AWV Member Summary",
                icon: PersonIcon,
                component: AWVMemberSummary,
                layout: "/awv",
                permissionSlug: 'reported_hcc_summary_1'
            }, */
            {
                path: "/AWVMemberDetails",
                name: "AWV Member Details",
                icon: PersonIcon,
                component: AWVMemberDetails,
                layout: "/awv",
                permissionSlug: 'awv_member_details_8',
                roleSlug: 'awv_member_details_8'
            },
        ]
    },

]

export default awvDashboardRoutes;