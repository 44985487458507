import React from 'react';
import ReactDOM from 'react-dom';
import history from './history';
import { signOut, logoutModelOpen } from './store/actions';
import './index.css';
import 'font-awesome/css/font-awesome.min.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import createStore from './store/store.config';
import api from '../src/assets/constants/Rafservice.Instance';
import adminApi from '../src/assets/constants/AdminRafservice.Instance';
import aweapi from '../src/assets/constants/AWVRafservice.Instance';
import awesummaryapi from '../src/assets/constants/AWESummaryRafservice.instance';
import awememberdetailsapi from '../src/assets/constants/AWVMemberDetails.RafService.Instance';
import encountersummaryapi from '../src/assets/constants/EncounterSummaryAPI.instance';
import AWVCoderWorkBench from '../src/assets/constants/AWVCoderWorkBenchRafservice.Instance';
import QueryLetter from '../src/assets/constants/QueryLetter.Instance';
import KeyRafservice from '../src/assets/constants/KeyRafservice.Instance';
import { getSecret } from '../src/Pages/config/ParameterStore';

const store = createStore();

const logout = () => {
  store.dispatch(logoutModelOpen());
}
const addHeadersToRequest = (request) => {
  request.headers['Access-Control-Allow-Origin'] = '*'; 
  request.headers['Access-Control-Allow-Headers'] = 'Content-Type'; 
  if (request.method) {
    request.headers['Access-Control-Allow-Methods'] = request.method.toUpperCase();
  }
  return request;
}

KeyRafservice.interceptors.request.use(request => {
  let token = JSON.parse(localStorage.getItem("Token"));
  request.headers.Authorization = token;
  return request;
},
  error => {
    return Promise.reject(error);
  })
  KeyRafservice.interceptors.response.use(response => {
  return response;
},
  error => {
    if (error && error?.response && error?.response?.status && error?.response?.status == '401') {
      // logout();
      return true;
    }
    return Promise.reject(error);
  })


api.interceptors.request.use(request => {
  let token = JSON.parse(localStorage.getItem("Token"));
  request.headers.Authorization = token;
  // // console.log(request);
  return request;
},
  error => {
    // console.log(error);
    return Promise.reject(error);
  })

AWVCoderWorkBench.interceptors.request.use(request => {
  let token = JSON.parse(localStorage.getItem("Token"));
  request.headers.Authorization = token;
  // // console.log(request);
  return request;
},
  error => {
    // console.log(error);
    return Promise.reject(error);
  })

api.interceptors.response.use(response => {
  //// console.log(response);
  if (response.data) {
    return response.data;
  }
  else {
    return response;
  }
},
  error => {
    // console.log(error);
    if (error && error?.response && error?.response?.status && error?.response?.status == '401') {
      logout();
      return true;
    }
    return Promise.reject(error);
  })

//admin api
adminApi.interceptors.request.use(request => {
  let token = localStorage.getItem("controlAccessToken");
  request.headers.Authorization = token;
  // // console.log(request);
  return request;
},
  error => {
    // console.log(error);
    return Promise.reject(error);
  })
adminApi.interceptors.response.use(response => {
  return response;
},
  error => {
    if (error && error?.response && error?.response?.status && error?.response?.status == '401') {
      logout();
      return true;
    }
    return Promise.reject(error);
  })

aweapi.interceptors.request.use(request => {
  let token = JSON.parse(localStorage.getItem("Token"));
  request.headers.Authorization = token;
  // addHeadersToRequest(request);
  // // console.log(request);
  return request;
},
  error => {
    // console.log(error);
    return Promise.reject(error);
  })
aweapi.interceptors.response.use(response => {
  return response;
},
  error => {
    if (error && error?.response && error?.response?.status && error?.response?.status == '401') {
      logout();
      return true;
    }
    return Promise.reject(error);
  })

awesummaryapi.interceptors.request.use(request => {
  let token = JSON.parse(localStorage.getItem("Token"));
  request.headers.Authorization = token;
  // addHeadersToRequest(request);
  // // console.log(request);
  return request;
},
  error => {
    // console.log(error);
    return Promise.reject(error);
  })
awesummaryapi.interceptors.response.use(response => {
  return response;
},
  error => {
    if (error && error?.response && error?.response?.status && error?.response?.status == '401') {
      logout();
      return true;
    }
    return Promise.reject(error);
  })

awememberdetailsapi.interceptors.request.use(request => {
  let token = JSON.parse(localStorage.getItem("Token"));
  request.headers.Authorization = token;
  // addHeadersToRequest(request);
  // // console.log(request);
  return request;
},
  error => {
    // console.log(error);
    return Promise.reject(error);
  })
awememberdetailsapi.interceptors.response.use(response => {
  return response;
},
  error => {
    if (error && error?.response && error?.response?.status && error?.response?.status == '401') {
      logout();
      return true;
    }
    return Promise.reject(error);
  })

encountersummaryapi.interceptors.request.use(request => {
  let token = JSON.parse(localStorage.getItem("Token"));
  request.headers.Authorization = token;
  // addHeadersToRequest(request);
  // // console.log(request);
  return request;
},
  error => {
    // console.log(error);
    return Promise.reject(error);
  })
encountersummaryapi.interceptors.response.use(response => {
  return response;
},
  error => {
    if (error && error?.response && error?.response?.status && error?.response?.status == '401') {
      logout();
      return true;
    }
    return Promise.reject(error);
  })

QueryLetter.interceptors.request.use(request => {
  let token = JSON.parse(localStorage.getItem("Token"));
  request.headers.Authorization = token;
  // addHeadersToRequest(request);
  return request;
},
  error => {
    // console.log(error);
    return Promise.reject(error);
  })
QueryLetter.interceptors.response.use(response => {
  return response;
},
  error => {
    if (error && error?.response && error?.response?.status && error?.response?.status == '401') {
      logout();
      return true;
    }
    return Promise.reject(error);
  })

aweapi.interceptors.request.use(request => {
  let token = JSON.parse(localStorage.getItem("Token"));
  request.headers.Authorization = token;
  // addHeadersToRequest(request);
  return request;
},
  error => {
    // console.log(error);
    return Promise.reject(error);
  })

awesummaryapi.interceptors.request.use(request => {
  let token = JSON.parse(localStorage.getItem("Token"));
  request.headers.Authorization = token;
  // addHeadersToRequest(request);
  return request;
},
  error => {
    // console.log(error);
    return Promise.reject(error);
  })

awememberdetailsapi.interceptors.request.use(request => {
  let token = JSON.parse(localStorage.getItem("Token"));
  request.headers.Authorization = token;
  // addHeadersToRequest(request);
  return request;
},
  error => {
    // console.log(error);
    return Promise.reject(error);
  })

encountersummaryapi.interceptors.request.use(request => {
  let token = JSON.parse(localStorage.getItem("Token"));
  request.headers.Authorization = token;
  // addHeadersToRequest(request);
  return request;
},
  error => {
    // console.log(error);
    return Promise.reject(error);
  })

  QueryLetter.interceptors.request.use(request => {
    let token = JSON.parse(localStorage.getItem("Token"));
    request.headers.Authorization = token;
    // addHeadersToRequest(request);
    return request;
},
  error => {
    // console.log(error);
    return Promise.reject(error);
  })

  KeyRafservice.get('/keys').then(res => {
    const secretNames = ['s3-access-key', 's3-secret-key']
    getSecret(secretNames, res.data.access_key, res.data.secret_key, res.data.session_token, false)
          .then(secrets => {
            localStorage.setItem('access_key',secrets['s3-access-key'])
            localStorage.setItem('secret_key', secrets['s3-secret-key'])
            localStorage.setItem('session_token', res.data.session_token)
          })
          .catch(error => {
              console.error("Error retrieving secrets:", error);
          });
  }).catch(err => {})

ReactDOM.render(
  <Provider store={store}>
    <App />,
  </Provider>,
  document.getElementById('root')

);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();