import PropTypes from "prop-types";
import {SetKPISelect} from '../../assets/constants/kpiList';
import { currentKPISelect } from '../../store/actions';
import { connect } from 'react-redux';

const KpiModuleSelect = (props) => {

    const {KPIList, KPIModuleSelect} = props;
    props.currentKPISelect(KPIModuleSelect);

    const handleSelect = (e) =>{
        SetKPISelect(e.target.value);        
        props.currentKPISelect(e.target.value);
        props.onKPIModuleSelect(e.target.value);
    }

    return(
        <div className="d-flex justify-content-end">
            <div className="btn-group">
            <select className="btn btn-info dropdown-toggle btn" value={KPIModuleSelect} onChange={handleSelect}>
                {
                    KPIList && KPIList.map(el => <option value={el} key={el}> {el} </option>)
                }
            </select>
            </div>
        </div>
    )
}

KpiModuleSelect.propTypes ={
    KPIList : PropTypes.array,
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
    };
  } 
export default connect(mapStateToProps, {currentKPISelect})(KpiModuleSelect);