import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { MenuItem, LinearProgress } from '@mui/material'
import AWVApi from '../../../assets/constants/AWVRafservice.Instance';
import api from '../../../assets/constants/Rafservice.Instance';
import { signOut, currentRoute, currentLOBSelect, currentProviderGroupSelect, currentYearSelect } from '../../../store/actions';
import history from '../../../history';
import {
    SELECT_YEAR, SELECT_LOB,
    SELECT_PROVIDER_GROUP, PROVIDER_GROUP_LIST, yearLimit
} from "../../../assets/constants/string.constants";
import Select from "react-select";

const HeaderFilter = (props) => {
    const [selectYear, setSelectYear] = useState(); // Intialise value with current year
    const [selectLob, setSelectLob] = useState();
    const [selectProject, setSelectProject] = useState(6);
    const [selectProviderGroup, setSelectProviderGroup] = useState();
    const [providerGroupList, setProviderGroupList] = useState([]);
    const [providerGroupOnlyList, setProviderGroupOnlyList] = useState([]);
    const [yearList, setYearList] = useState([]);
    const lobList = [
        { 'value': 1, 'label': 'ACA' },
        { 'value': 2, 'label': 'Medicare' },
        { 'value': 3, 'label': 'Medicaid' }
    ];
    const projectList = [
        { 'value': 6, 'label': 'ACA-ChartChase' }
    ];

    useEffect(() => {
        const fetchData = async () => {
            await getYearList();
            await getProviderGroup();
            await oldSelectedData();
        };

        fetchData();
    }, []);

    const oldSelectedData = () => {
        if (localStorage.getItem(SELECT_YEAR) && localStorage.getItem(SELECT_YEAR) != '') {
            setSelectYear(localStorage.getItem(SELECT_YEAR));
            props.currentYearSelect(localStorage.getItem(SELECT_YEAR));
        } else {
            if (props?.selectedYear && props?.selectedYear != '') {
                localStorage.setItem(SELECT_YEAR, props?.selectedYear);
                setSelectYear(props?.selectedYear);
                props.currentYearSelect(props?.selectedYear);
            } else {
                localStorage.setItem(SELECT_YEAR, new Date().getFullYear());
                setSelectYear(new Date().getFullYear());
                props.currentYearSelect(new Date().getFullYear());
            }
        }

        if (localStorage.getItem(SELECT_LOB) && localStorage.getItem(SELECT_LOB) != '') {
            setSelectLob(localStorage.getItem(SELECT_LOB));
            props.currentLOBSelect(localStorage.getItem(SELECT_LOB));
        } else {
            localStorage.setItem(SELECT_LOB, 2);
            setSelectLob(2);
            props.currentLOBSelect(2);
        }

        if (localStorage.getItem(SELECT_PROVIDER_GROUP) && localStorage.getItem(SELECT_PROVIDER_GROUP) != '') {
            setSelectProviderGroup(localStorage.getItem(SELECT_PROVIDER_GROUP));
            props.currentProviderGroupSelect(localStorage.getItem(SELECT_PROVIDER_GROUP));
        } else {
            localStorage.setItem(SELECT_PROVIDER_GROUP, 'ALL');
            setSelectProviderGroup('ALL');
            props.currentProviderGroupSelect('ALL');
        }
    }

    const getProviderGroup = () => {
        AWVApi.get('/getprovidergroup')
            .then(res => {
                if (res.data) {
                    setProviderGroupOnlyList(res?.data);
                    let ProviderGroupOptions = [];
                    ProviderGroupOptions.push({ value: 'ALL', label: "ALL" });
                    res?.data.map((groupName) => {
                        ProviderGroupOptions.push({ value: groupName, label: groupName.toString() })
                    });
                    setProviderGroupList(ProviderGroupOptions);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const getYearList = () => {
        api.get('/yearList')
            .then(res => {
                if (res) {
                    let resArray = [];
                    for (var i = 0; i <= yearLimit; i++) {
                        resArray.push(res[i]);
                    }
                    let yearsOptions = resArray.map((year) => ({
                        value: year,
                        label: year.toString(),
                    }));
                    setYearList(yearsOptions);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        localStorage.setItem(PROVIDER_GROUP_LIST, JSON.stringify(providerGroupOnlyList))
    })

    const handleLOBSelect = (e) => {
        localStorage.setItem(SELECT_LOB, e.value);
        setSelectLob(e.value);
        props.currentLOBSelect(e.value);
    }
    const handleProviderGroupSelect = (e) => {
        localStorage.setItem(SELECT_PROVIDER_GROUP, e.value);
        setSelectProviderGroup(e.value);
        props.currentProviderGroupSelect(e.value);
    }
    const handleYearSelect = (e) => {
        localStorage.setItem(SELECT_YEAR, e.value);
        setSelectYear(e.value);
        props.currentYearSelect(e.value);
    }

    return (
        <div className={"card_header flex_item_cb flex-wrap mb24 " + props.className}>
            <h2 className="fs18 card-title">{props.title}</h2>
            <div className="right_part">
                <div className="flex_item_cc justify-content-start flex-wrap">

                    {props?.providerGroup && props?.providerGroup == 'hide' ?
                        <></>
                        :
                        <div className="flex_item_cs flex-wrap flex-sm-nowrap gap10">
                            {props.title && props.title != '' ?
                                <label htmlFor="" className="text-dark flex-shrink-0 fs14">Provider Group</label>
                                :
                                <></>
                            }
                            <Select
                                className="custom_select minw85 flex-grow-1"
                                classNamePrefix={"custom_select"}
                                placeholder={'Provider Group'}
                                variant="outlined"
                                closeMenuOnSelect={true}
                                DropdownIndicator={false}
                                isSearchable={true}
                                options={providerGroupList}
                                getOptionLabel={option => `${option.label}`}
                                getOptionValue={option => `${option.value}`}
                                hideSelectedOptions={true}
                                backspaceRemovesValue={false}
                                menuPlacement="bottom"
                                value={providerGroupList.filter(({ value }) => value == selectProviderGroup)}
                                onChange={e => handleProviderGroupSelect(e)}
                            />
                            {/* <Select
                            displayEmpty
                            className="custom_select minw80 flex-grow-1"
                            value={selectProviderGroup}
                            inputProps={{ 'aria-label': 'Without label' }}
                            onChange={(event) => handleProviderGroupSelect(event)}
                        >
                            <MenuItem value="ALL"> <em>All</em> </MenuItem>
                            {providerGroup && providerGroup.length > 0 && providerGroup.map((element, index) =>
                                <MenuItem key={index} value={element}> {element} </MenuItem>
                            )}
                        </Select> */}
                        </div>
                    }
                    {props.pageName && props.pageName == 'ChartChasePage' ?
                        <div className="flex_item_cs flex-wrap flex-sm-nowrap gap10">
                            {props.title && props.title != '' ?
                                <label htmlFor="" className="text-dark flex-shrink-0 fs13">Project</label>
                                :
                                <></>
                            }
                            <Select
                                className="custom_select minw100 flex-grow-1"
                                classNamePrefix={"custom_select"}
                                placeholder={'Project'}
                                variant="outlined"
                                closeMenuOnSelect={true}
                                DropdownIndicator={false}
                                options={projectList}
                                getOptionLabel={option => `${option.label}`}
                                getOptionValue={option => `${option.value}`}
                                hideSelectedOptions={true}
                                backspaceRemovesValue={false}
                                menuPlacement="bottom"
                                value={projectList.filter(({ value }) => value == selectProject)}
                            />
                        </div>
                        :
                        <div className="flex_item_cs flex-wrap flex-sm-nowrap gap10">
                            {props.title && props.title != '' ?
                                <label htmlFor="" className="text-dark flex-shrink-0 fs13">LOB</label>
                                :
                                <></>
                            }
                            <Select
                                className="custom_select minw100 flex-grow-1"
                                classNamePrefix={"custom_select"}
                                placeholder={'LOB'}
                                variant="outlined"
                                closeMenuOnSelect={true}
                                DropdownIndicator={false}
                                options={lobList}
                                getOptionLabel={option => `${option.label}`}
                                getOptionValue={option => `${option.value}`}
                                hideSelectedOptions={true}
                                backspaceRemovesValue={false}
                                menuPlacement="bottom"
                                value={lobList.filter(({ value }) => value == selectLob)}
                                onChange={e => handleLOBSelect(e)}
                            />
                            {/* <Select
                        displayEmpty
                        className="custom_select minw100 flex-grow-1"
                        value={selectLob}
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={(event) => handleLOBSelect(event)}
                    >
                        <MenuItem value={1}> ACA </MenuItem>
                        <MenuItem value={2}> Medicare </MenuItem>
                    </Select> */}
                        </div>
                    }

                    <div className="flex_item_cs flex-wrap flex-sm-nowrap gap10">
                        {props.title && props.title != '' ?
                            <label htmlFor="" className="text-dark flex-shrink-0 fs13">Year</label>
                            :
                            <></>
                        }
                        <Select
                            className="custom_select minw100 flex-grow-1"
                            classNamePrefix={"custom_select"}
                            placeholder={'Year'}
                            variant="outlined"
                            closeMenuOnSelect={true}
                            DropdownIndicator={false}
                            options={yearList}
                            getOptionLabel={option => `${option.label}`}
                            getOptionValue={option => `${option.value}`}
                            hideSelectedOptions={true}
                            backspaceRemovesValue={false}
                            menuPlacement="bottom"
                            value={yearList.filter(({ value }) => value == selectYear)}
                            onChange={e => handleYearSelect(e)}
                        />
                        {/* <Select
                            displayEmpty
                            className="custom_select minw100 flex-grow-1"
                            value={selectYear}
                            inputProps={{ 'aria-label': 'Without label' }}
                            onChange={(event) => handleYearSelect(event)}
                        >
                            {yearList && yearList.length > 0 && yearList.map((element, index) =>
                                <MenuItem key={index} value={element}> {element} </MenuItem>
                            )}
                        </Select> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        userName: state.auth?.userDetails?.userEmail,
        route: state.route.route,
        moduleSelect: state.moduleFilter.moduleSelect,
    };
}
export default connect(mapStateToProps, { signOut, currentRoute, currentLOBSelect, currentProviderGroupSelect, currentYearSelect })(HeaderFilter);
