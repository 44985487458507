import React, { useState, useEffect } from "react";
import AWVApi from '../../../assets/constants/AWVRafservice.Instance';
import AWVServerSideGridTable from "../../../components/TableGrids/AWVServerSideGridTable.table";
import AWVExpandGridTable from "../../../components/TableGrids/AWVExpandGridTable.table";
import { HCC, ISSUER_ID } from "../../../assets/constants/string.constants";
import AwvCardComponent from "../../../components/Cards/AwvCard";
import { connect } from 'react-redux';
import AwvSubHeader from '../../../components/home/header/AwvSubHeader';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import Tooltip from '@mui/material/Tooltip';
import { purple, red } from '@mui/material/colors';
import Awvheader from '../../../components/home/header/Awvheader';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AddAWVFileUpload from '../../../components/Dialog/AddAWVFileUpload';
import AreaChartMultiExcelAWV from '../../../components/Charts/AreaChartMultiExcelAWV';
import ColumnChartSingleExcelAWV from '../../../components/Charts/ColumnChartSingleExcelAWV';
import ColumnChartStackAWV from '../../../components/Charts/ColumnChartStackAWV';
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import MemberDetailsReport from './subTab/member_details_report';
import ProviderOpportunityReport from './subTab/provider_opportunity_report';
import ReconciliateGapSummary from './subTab/reconciliate_gap_summary';

const ReportingAnalysis = (props) => {
    const [subTabvalue, setSubTabvalue] = React.useState('3');
    const params = new URL(window.location.href).searchParams;
    const [memberId, setMemberId] = useState(params.get('cin'));

    const handleSubTabChange = (event, newValue) => {
        setSubTabvalue(newValue);
    };

    const handleMemberIdSet = (value) => {
        setMemberId(value);
    };

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={subTabvalue}>
                <Box>
                    <TabList onChange={handleSubTabChange} aria-label="lab API tabs example">
                        {/*  <Tab label="PROVIDER OPPORTUNITY REPORT" className="mui-tab-label" value="1" style={{backgroundColor:"#cdffff",borderRadius:"5px",marginRight:"15px"}} />
                        <Tab label="RECONCILIATE/ GAP SUMMARY" className="mui-tab-label" value="2" style={{backgroundColor:"#cdffff",borderRadius:"5px",marginRight:"15px"}}/> */}
                        {memberId && memberId != '' ?
                            <Tab label="MEMBER REPORT" className="mui-tab-label" value="3" style={{ backgroundColor: "#cdffff", borderRadius: "5px", marginRight: "15px" }} />
                            :
                            <Tab label="MEMBER DETAIL REPORT" className="mui-tab-label" value="3" style={{ backgroundColor: "#cdffff", borderRadius: "5px", marginRight: "15px" }} />
                        }

                    </TabList>
                </Box>
                <TabPanel value="1">
                    <ProviderOpportunityReport />
                </TabPanel>
                <TabPanel value="2">
                    <ReconciliateGapSummary />
                </TabPanel>
                <TabPanel value="3" style={{ padding: '15px' }}>
                    <MemberDetailsReport
                        handleMemberIdSet={handleMemberIdSet}
                        siteIdSelect={props.siteIdSelect}
                        providerAddressGroupsSelect={props.providerAddressGroupsSelect}
                        providerAddressGroupsNameSelect={props.providerAddressGroupsNameSelect}
                        citySelect={props.citySelect}
                        siteAddressSelect={props.siteAddressSelect}
                        zipSelect={props.zipSelect}
                    />
                </TabPanel>
            </TabContext>
        </Box>
    )

};
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        lobSelect: state.moduleFilter.lobSelect,
    };
}
export default connect(mapStateToProps)(ReportingAnalysis);