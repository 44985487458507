import React, { useRef, useState, useEffect } from "react";
import { connect } from 'react-redux';
import { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../store/actions';
import AWVApi from '../../assets/constants/AWVRafservice.Instance';
import Tooltip from '@mui/material/Tooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import Divider from '@mui/material/Divider';
import { useForm, Controller } from "react-hook-form";
import Box from '@mui/material/Box';
import pdfIcon from '../../assets/images/pdf_icon.png';
import Moment from 'moment';
import Button from '@material-ui/core/Button';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const AWVMemberDetailsCoder = (props) => {
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
    const [editFormData, setEditFormData] = useState({});
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;

    useEffect(() => {
        if (props.aweRightSidebarType?.cin_id && props.aweRightSidebarType?.cin_id != '') {
            editLoadFormData(props.aweRightSidebarType?.cin_id, props.aweRightSidebarType?.awv_id);
        }
    }, [props.aweRightSidebarType?.cin_id]);

    useEffect(() => {
        if (editFormData?.checklist_id && editFormData?.checklist_id != '') {
            setValue('remark', editFormData?.remark);
            setValue('status', editFormData?.status);
            setValue('checklist_id', String(editFormData?.checklist_id));
            setValue('awe_id', String(props.aweRightSidebarType?.awv_id));
            setValue('cin', String(props.aweRightSidebarType?.cin_id));
        }
    }, [editFormData?.checklist_id]);

    const notificationRightDrawer = (open, valueId = '') => {
        props.currentAWERightSidebarType({ 'type': 'notification' });
        props.currentAWERightSidebar(open);
    };

    const editLoadFormData = (cinId, awvId) => {
        setIsLoading(true);
        AWVApi.get('/getawecoderchecklist?cin=' + cinId + '&awe_id=' + awvId + '&year='+ props?.yearSelect + '&lob=' + props?.lobSelect + '&organisation_id=1')
            // AWVApi.get('/get_member_hcc_details?member_id=3RE5W09MM81')
            .then(res => {
                if (res.data && res.data.length > 0) {
                    res.data.forEach(element => {
                        if (props.aweRightSidebarType?.checklist_id && element.checklist_id == props.aweRightSidebarType?.checklist_id) {
                            setEditFormData(element);
                            setIsLoading(false);
                        }
                    });
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false);
            })
    }




    const onSubmit = (data) => {
        if (data?.checklist_id && data?.checklist_id != '') {

            setFormSubmitisLoading(true);
            let controlUser = JSON.parse(localStorage.getItem('controlUser'));
            data.cin = props.aweRightSidebarType?.cin_id;
            if (props?.aweRightSidebarType?.status && props?.aweRightSidebarType?.status == '1') {

            } else {
                data.checklist = [];
                data.updated_by = controlUser?.user_id;
                data.remarks = data.remark;
                delete data.description;
                data.checklist.push({
                    'remarks': (data.remark && data.remark != 'null') ? data.remark : '',
                    'status': (data.status && data.status != 'null') ? data.status : '',
                    'checklist_id': data.checklist_id
                })
                delete data.remark;
                delete data.status;
                delete data.checklist_id;
                // adding lob, year, org id in payload
                data.lob = props?.lobSelect;
                data.year = props?.yearSelect;
                data.organisation_id = '1';
                if(data?.lob)
                    data.lob = data.lob.toString();
                AWVApi.put('https://y3r5o7igxbzzag6ffmdejo3gky0twrxi.lambda-url.us-west-2.on.aws/update_awe_coder_checklist', data)
                    .then(res => {
                        setValue('remark', '');
                        setValue('status', '');
                        setValue('checklist_id', '');
                        setValue('awe_id', '');
                        setValue('cin', '');
                        setFormSubmitisLoading(false);
                        toast.success('Your submission of checklist is accepted for updation, it will be updated soon.');
                        notificationRightDrawer(false);
                        props.currentAWERightSidebarCloseDatatableReload(true);
                    })
                    .catch(err => {
                        setFormSubmitisLoading(false);
                        toast.error(err?.response?.data?.message);
                    })
            }
        }

    };

    return (
        <div style={{ padding: "10px 0px" }}>
            <div key={'index'} variant={'head'} style={{ width: '350px', padding: "10px", height: "50px" }}>
                <div class="float">
                    <div class="float-left">
                        <span>
                            <b>
                                {(props?.aweRightSidebarType?.status && props?.aweRightSidebarType?.status == '1') ?
                                    'CODER CHECKLIST ADD'
                                    :
                                    'CODER CHECKLIST EDIT'
                                }
                            </b>
                        </span>
                    </div>
                    <div class="float-right">
                        <Tooltip title="Close">
                            <CancelIcon style={{ color: "#1A9698", cursor: "pointer" }} onClick={() => notificationRightDrawer(false)} />
                        </Tooltip>
                    </div>
                </div>
            </div>
            <Divider />
            <div class="awv-recored-right-sidebar-form">
                {isLoading == false ?
                    <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                        <div className="col-lg-12 align-items-center mb-3 mt-3">
                            <div className='mb-1'><strong>AWE SOURCE</strong></div>
                            <div>{editFormData?.description}</div>
                        </div>
                        <div className="col-lg-12 align-items-center mb-3 mt-3">
                            <div className='mb-1'><strong>Status</strong></div>
                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                <Controller
                                    className="input-control"
                                    name="status"
                                    value={editFormData?.status}
                                    control={control}
                                    render={({ field }) => (
                                        // <TextField  {...field} variant="outlined" />
                                        <Select
                                            {...field}
                                            labelId="module-multiple-checkbox-label"
                                            id="module-multiple-checkbox"
                                            // value={selectModule}
                                            // onChange={handleChange}
                                            label="Module"
                                            variant="outlined"
                                            // MenuProps={MenuProps}
                                            menuPlacement="top"
                                        >
                                            <MenuItem key={'Confirmed'} value={'1'} >
                                                Confirmed
                                            </MenuItem>
                                            <MenuItem key={'not_confirmed'} value={'0'} >
                                                Not Confirmed
                                            </MenuItem>
                                        </Select>
                                    )}
                                    rules={{
                                        required: true,
                                    }}
                                />
                                {errors?.status?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>
                        <div className="col-lg-12 align-items-center mb-3 mt-3">
                            <div className='mb-1'><strong>REMARKS</strong></div>
                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                <Controller
                                    className="input-control"
                                    name="remark"
                                    value={editFormData?.remark}
                                    control={control}
                                    render={({ field }) => (
                                        <TextField  {...field} variant="outlined" />
                                    )}
                                    rules={{
                                        required: false,
                                    }}
                                />
                                {errors?.remark?.type === "required" && <label className="text-danger">This field is required</label>}
                            </FormControl>
                        </div>
                        <div className="col-lg-12 align-items-center mb-3">
                            <Button type='button' variant="contained" color="grey" onClick={() => notificationRightDrawer(false)}>CANCEL</Button>
                            <Button type='submit'
                                className={'mr-2 btn-custom-primary mr-2 ml-2'}
                                variant="contained"
                                disabled={formSubmitisLoading && formSubmitisLoading == true ? true : false}
                            >
                                {(props?.aweRightSidebarType?.status && props?.aweRightSidebarType?.status == '1') ?
                                    'ADD'
                                    :
                                    'UPDATE'
                                }
                            </Button>
                        </div>
                    </form>
                    : isLoading ?
                        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                            <CircularProgress />
                        </div>
                        :
                        <></>
                }
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload })(AWVMemberDetailsCoder);

