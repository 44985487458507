import React, { useRef, useState, useEffect } from "react";
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import adminApi from '../../assets/constants/AdminRafservice.Instance';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import PlusFillIcon from '../icons/PlusFillIcon'
import { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload } from '../../store/actions';
import AWVCoderWorkBench from '../../assets/constants/AWVCoderWorkBenchRafservice.Instance';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import CustomAutocomplete from '../CustomInput/CustomAutocomplete';
import Divider from '@mui/material/Divider';
import Moment from 'moment';
import CancelIcon from '@mui/icons-material/Cancel';
import { CircularProgress } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "../layout/aweLayout/IconButton";
import Button from "../layout/aweLayout/Button";
import CloseIcon from '@material-ui/icons/Close';
import CalendarIcon from "../icons/CalendarIcon";
import hcc_json from "../../Pages/config/HCC_Code.config.json";
import hcc_aca_json from "../../Pages/config/HCC_CODE_ACA.config.json"
import AWVApi from "../../assets/constants/AWVRafservice.Instance";
import { useLocation } from 'react-router-dom';
import {LogFileUploadToS3} from '../../assets/constants/LogFileUploadToS3'

const styles = (theme) => ({
    dialogPaper: {
        maxWidth: '80%',
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const AddCWDXCoderWorkBench = (props) => {
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const { register, formState: { errors }, trigger, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [formDataList, setFormDataList] = useState([]);
    const [cwdxRemarkList, setCwdxRemarkList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitisLoading, setFormSubmitisLoading] = useState(false);
    const [editFormData, setEditFormData] = useState({});
    const [dxRecordData, setDXRecordData] = useState([]);
    const [dxCodeDataArray, setDxCodeDataArray] = useState([]);
    const [dxCodeDataArrayPush, setDxCodeDataArrayPush] = useState([]);
    const [dxCodeData, setDxCodeData] = useState([]);
    const [isdxCodeValue, setIsDxCodeValues] = useState(false);
    const [dxCodeValue, setDxCodeValues] = useState([]);
    const [dxCodeDOSBegin, setDxCodeDOSBegin] = useState();
    const [tabvalue, setTabvalue] = useState('1');
    // new code
    const [hccValue, setHCCValues] = React.useState([]);
    const [inputValueHCC, setInputValueHCC] = React.useState("");
    const [inputValueHCCDescription, setInputValueHCCDescription] =
        React.useState("");
    const [inputValueDXDescription, setInputValueDXDescription] =
        React.useState("");
    const [dxValue, setDXValues] = React.useState();
    const [previousDXCode, setpreviousDXCode] = useState(editFormData?.DX_code);
    const [hccData, setHCCData] = useState([]);
    const [aweId, setAWEId] = useState([]);
    const location = useLocation();
    const [editFormDataMember, setEditFormDataMember] = useState({});
    const [hccV28Data, setHccV28Data] = useState([]);
    const [hccV28DataValue, setHccV28DataValue] = useState([])
    const [hccV28DataLocalStorage, setHccV28DataLocalStorage] = useState()
    const [dataInputs, setDataInputs] = useState(['', '', '', '']);
    const [logMessages, setLogMessages] = useState([]); // Array to store log messages
    const writeToLogFile = (message) => {
       setLogMessages(prevLogMessages => [...prevLogMessages, `${new Date().toISOString()} - ${message}`]);
   };
   const [generateLog, setGenerateLog] = useState(false);

    var cin = ''

    useEffect(() => {
        reset();
        if (tabvalue && tabvalue == '2') {
            dataInputs.forEach((element, index) => {
                setValue(`DX_CODE_${index}`, '');
                setValue(`HCC_Code_${index}`, '');
                setValue(`hcc_v28_value_${index}`, '');
                setValue(`DOS_Begin_${index}`, '');
                setValue(`DOS_End_${index}`, '');
                setValue(`insufficient_document_${index}`, '');
                setValue(`PAGE_NO_${index}`, '');
                setValue(`CW_DX_STATUS_${index}`, 'Accepted');
                setValue(`REMARK_${index}`, '');
            });
        } else {
            dataInputs.forEach((element, index) => {
                setValue(`DX_CODE_${index}`, '');
                setValue(`HCC_Code_${index}`, '');
                setValue(`hcc_v28_value_${index}`, '');
            });
            setValue(`DOS_Begin`, '');
            setValue(`DOS_End`, '');
            setValue(`insufficient_document`, '');
            setValue(`PAGE_NO`, '');
            setValue(`CW_DX_STATUS`, 'Accepted');
            setValue(`REMARK`, '');
            
        }
        setIsDxCodeValues([]);
        setDxCodeValues([]);
        setDataInputs(['', '', '', '']);
        // const queryString = window.location.search;
        // const urlParams = new URLSearchParams(queryString);
        cin = location.state?.cin;
        setValue('IH_MEMBER_ID', cin);
    }, [props?.open]);


    useEffect(() => {
        reset();
        if (tabvalue && tabvalue == '2') {
            dataInputs.forEach((element, index) => {
                setValue(`DX_CODE_${index}`, '');
                setValue(`HCC_Code_${index}`, '');
                setValue(`hcc_v28_value_${index}`, '');
                setValue(`DOS_Begin_${index}`, '');
                setValue(`DOS_End_${index}`, '');
                setValue(`insufficient_document_${index}`, '');
                setValue(`PAGE_NO_${index}`, '');
                setValue(`CW_DX_STATUS_${index}`, 'Accepted');
                setValue(`REMARK_${index}`, '');
            });
        } else {
            dataInputs.forEach((element, index) => {
                setValue(`DX_CODE_${index}`, '');
                setValue(`HCC_Code_${index}`, '');
                setValue(`hcc_v28_value_${index}`, '');
            });
            setValue(`DOS_Begin`, '');
            setValue(`DOS_End`, '');
            setValue(`insufficient_document`, '');
            setValue(`PAGE_NO`, '');
            setValue(`CW_DX_STATUS`, 'Accepted');
            setValue(`REMARK`, '');
            
        }
        setIsDxCodeValues([]);
        setDxCodeValues([]);
        setDataInputs(['', '', '', '']);
        // const queryString = window.location.search;
        // const urlParams = new URLSearchParams(queryString);
        cin = location.state?.cin;
        setValue('IH_MEMBER_ID', cin);

    }, [tabvalue]);

    useEffect(() => {
        console.log(errors);
    }, [errors]);


    useEffect(async () => {
        reset();
        // const queryString = window.location.search;
        // const urlParams = new URLSearchParams(queryString);
        cin = location.state?.cin;
        setValue('IH_MEMBER_ID', cin);
    }, [props?.open]);


    useEffect(() => {
        // Retrieve and parse the data from localStorage
        const storedData = localStorage.getItem('hcc_v28_list');
        if (storedData) {
            setHccV28DataLocalStorage(JSON.parse(storedData));
        }
    }, []);

    const notificationRightDrawer = (open, valueId = '') => {
        props.currentAWERightSidebarType({ 'type': 'notification' });
        props.currentAWERightSidebar(open);
    };

    //load DX codes from JSON
    const handleChangeDxCode = (eventValue, index = 0) => {
        writeToLogFile("handleChangeDxCode() call");
        const result = dxCodeDataArray.find((element) => element.icd_code + '-' + element.icd_code_description === eventValue);
        setValue(`DX_CODE_${index}`, eventValue);
        // console.log('handleChangeDxCode', { eventValue }, { index });
        for (let indexTemp = 0; indexTemp <= index; indexTemp++) {
            setDxCodeValues(prevValues => {
                const updatedValues = [...prevValues]; // Create a copy of the array
                if (indexTemp == index) {
                    updatedValues[index] = eventValue; // Set the value at the specified index
                } if (updatedValues[indexTemp]) {

                } else {
                    updatedValues[indexTemp] = ''; // Set the value at the specified index
                }
                return updatedValues; // Return the updated array
            }, indexTemp);
        }
        if (result) {
            setIsDxCodeValues(true);
            if (result?.hcc_v24_description != '') {
                for (let indexTemp = 0; indexTemp <= index; indexTemp++) {
                    setHCCValues(prevValues => {
                        const updatedValues = [...prevValues]; // Create a copy of the array
                        if (indexTemp == index) {
                            updatedValues[indexTemp] = result?.hcc_v24 + '-' + result?.hcc_v24_description; // Set the value at the specified index
                        } if (updatedValues[indexTemp]) {

                        } else {
                            updatedValues[indexTemp] = ''; // Set the value at the specified index
                        }
                        return updatedValues; // Return the updated array
                    }, indexTemp);
                }
                setValue(`HCC_Code_${index}`, result?.hcc_v24 + '-' + result?.hcc_v24_description);
            } else {
                for (let indexTemp = 0; indexTemp <= index; indexTemp++) {
                    setHCCValues(prevValues => {
                        const updatedValues = [...prevValues]; // Create a copy of the array
                        if (indexTemp == index) {
                            updatedValues[indexTemp] = result?.hcc_v24 && result?.hcc_v24 == 'No HCC' ? '' : result?.hcc_v24; // Set the value at the specified index
                        } if (updatedValues[indexTemp]) {

                        } else {
                            updatedValues[indexTemp] = ''; // Set the value at the specified index
                        }
                        return updatedValues; // Return the updated array
                    }, indexTemp);
                }
                setValue(`HCC_Code_${index}`, result?.hcc_v24 && result?.hcc_v24 == 'No HCC' ? '' : result?.hcc_v24);
            }

            if (result?.hcc_v28_description != '') {
                for (let indexTemp = 0; indexTemp <= index; indexTemp++) {
                    setHccV28DataValue(prevValues => {
                        const updatedValues = [...prevValues]; // Create a copy of the array
                        if (indexTemp == index) {
                            updatedValues[indexTemp] = result?.hcc_v28 + '-' + result?.hcc_v28_description; // Set the value at the specified index
                        } if (updatedValues[indexTemp]) {

                        } else {
                            updatedValues[indexTemp] = ''; // Set the value at the specified index
                        }
                        return updatedValues; // Return the updated array
                    }, indexTemp);
                }
                setValue(`hcc_v28_value_${index}`, result?.hcc_v28 + '-' + result?.hcc_v28_description);
            } else {


                for (let indexTemp = 0; indexTemp <= index; indexTemp++) {
                    setHccV28DataValue(prevValues => {
                        const updatedValues = [...prevValues]; // Create a copy of the array
                        if (indexTemp == index) {
                            updatedValues[indexTemp] = result?.hcc_v28 && result?.hcc_v28 == 'No HCC' ? '' : result?.hcc_v28; // Set the value at the specified index
                        } if (updatedValues[indexTemp]) {

                        } else {
                            updatedValues[indexTemp] = ''; // Set the value at the specified index
                        }
                        return updatedValues; // Return the updated array
                    }, indexTemp);
                }
                setValue(`hcc_v28_value_${index}`, result?.hcc_v28 && result?.hcc_v28 == 'No HCC' ? '' : result?.hcc_v28);
            }

            if (result?.rxhcc_v05_description != '') {
                setValue(`RXHCC_v05_${index}`, result?.rxhcc_v05 + '-' + result?.rxhcc_v05_description);
            } else {
                setValue(`RXHCC_v05_${index}`, result?.rxhcc_v05);
            }
            if (result?.rxhcc_v08_description != '') {
                setValue(`RXHCC_v08_${index}`, result?.rxhcc_v08 + '-' + result?.rxhcc_v08_description);
            } else {
                setValue(`RXHCC_v08_${index}`, result?.rxhcc_v08);
            }
        }

        for (let indexTemp = 0; indexTemp <= index; indexTemp++) {
            setDxCodeData(prevValues => {
                const updatedValues = [...prevValues]; // Create a copy of the array
                if (indexTemp == index) {
                    updatedValues[indexTemp] = []; // Set the value at the specified index
                } if (updatedValues[indexTemp]) {

                } else {
                    updatedValues[indexTemp] = []; // Set the value at the specified index
                }
                return updatedValues; // Return the updated array
            }, indexTemp);
        }

        handleManualValidation()
        // console.log('handleChangeDxCode', { dxCodeData }, { dxCodeValue }, { hccValue }, { hccV28DataValue }, { hccV28DataValue });
    }

    const handleInputChangeDxCode = (event, eventValue, index = 0) => {
        writeToLogFile("handleInputChangeDxCode() call" + " event, eventValue " + event+ eventValue);
        writeToLogFile({ event }, event?.nativeEvent?.inputType);
        // console.log('handleInputChangeDxCode', { eventValue }, { index });
        setIsDxCodeValues(false);
        setValue(`DX_CODE_${index}`, eventValue);

        if (event && event?.nativeEvent && event?.nativeEvent?.inputType && event?.nativeEvent?.inputType == 'deleteContentBackward' && eventValue.length >= 8) {
            setValue(`DX_CODE_${index}`, '');
            setValue(`HCC_Code_${index}`, '');
            setValue(`hcc_v28_value_${index}`, '');
            setValue(`RXHCC_v05_${index}`, '');
            setValue(`RXHCC_v08_${index}`, '');
        }

        if (eventValue.length >= 3) {

        } else {
            setDxCodeDataArray([]);
            let valuesArray = [];
            for (let indexTemp = 0; indexTemp <= index; indexTemp++) {
                setDxCodeValues(prevValues => {
                    const updatedValues = [...prevValues]; // Create a copy of the array
                    if (indexTemp == index) {
                        updatedValues[indexTemp] = eventValue; // Set the value at the specified index
                    } else if (updatedValues[indexTemp]) {

                    } else {
                        updatedValues[indexTemp] = '';
                    }
                    return updatedValues; // Return the updated array
                }, indexTemp);
            }

            loadDxCodeData(valuesArray, eventValue, index);
            setIsLoading(false);
            return true;
        }
        setIsLoading(true);
        var data = { 'search': eventValue, 'lob': String(props?.lobSelect) };
        writeToLogFile("Post API Call: "+  '/data/icd_code'+ data);
        AWVCoderWorkBench.post('/data/icd_code', data)
            .then(res => {
                setDxCodeDataArray(res.data);
                let valuesArray = [];
                res.data.forEach((element, index) => {
                    if (!valuesArray.includes(element.icd_code + '-' + element.icd_code_description)) {
                        valuesArray.push(element.icd_code + '-' + element.icd_code_description);
                    }
                });

                // setValue(`DX_CODE_${index}`, eventValue);
                for (let indexTemp = 0; indexTemp <= index; indexTemp++) {
                    setDxCodeValues(prevValues => {
                        const updatedValues = [...prevValues]; // Create a copy of the array
                        if (indexTemp == index) {
                            updatedValues[indexTemp] = eventValue; // Set the value at the specified index
                        } else if (updatedValues[indexTemp]) {

                        } else {
                            updatedValues[indexTemp] = '';
                        }
                        return updatedValues; // Return the updated array
                    }, indexTemp);
                }
                loadDxCodeData(valuesArray, eventValue, index);
                setIsLoading(false);
                writeToLogFile("Data retrieved successfully...");
            })
            .catch(err => {
                setDxCodeDataArray([]);
                let valuesArray = [];
                // setValue(`DX_CODE_${index}`, eventValue);
                for (let indexTemp = 0; indexTemp <= index; indexTemp++) {
                    setDxCodeValues(prevValues => {
                        const updatedValues = [...prevValues]; // Create a copy of the array
                        if (indexTemp == index) {
                            updatedValues[indexTemp] = eventValue; // Set the value at the specified index
                        } else if (updatedValues[indexTemp]) {

                        } else {
                            updatedValues[indexTemp] = '';
                        }
                        return updatedValues; // Return the updated array
                    }, indexTemp);
                }
                loadDxCodeData(valuesArray, eventValue, index);
                setIsLoading(false);
                writeToLogFile("Error in retrieving data... " + err );
            })

    }

    const loadDxCodeData = (dxCodeDataArrayPush, codeValue, index = 0) => {
        writeToLogFile("loadDxCodeData() call" );
        let values = [];
        if (dxCodeDataArrayPush && dxCodeDataArrayPush.length > 0 && codeValue) {
            dxCodeDataArrayPush.forEach((element, index) => {
                if (!values.includes(element) && ((element.toLowerCase()).includes(codeValue.toLowerCase()))) {
                    values.push(element);
                }
            });
            for (let indexTemp = 0; indexTemp <= index; indexTemp++) {
                setDxCodeData(prevValues => {
                    const updatedValues = [...prevValues]; // Create a copy of the array
                    if (indexTemp == index) {
                        updatedValues[indexTemp] = values; // Set the value at the specified index
                    } else if (updatedValues[indexTemp]) {

                    } else {
                        updatedValues[indexTemp] = [];
                    }
                    return updatedValues; // Return the updated array
                }, indexTemp);
            }

        } else {
            for (let indexTemp = 0; indexTemp <= index; indexTemp++) {
                setDxCodeData(prevValues => {
                    const updatedValues = [...prevValues]; // Create a copy of the array
                    if (indexTemp == index) {
                        updatedValues[indexTemp] = values; // Set the value at the specified index
                    } else if (updatedValues[indexTemp]) {

                    } else {
                        updatedValues[indexTemp] = [];
                    }
                    return updatedValues; // Return the updated array
                }, indexTemp);
            }
        }
    };

    const dateConvert = (timestamp) => {
        // Create a new Date object using the timestamp
        var date = new Date(timestamp);

        // Get the individual components of the date
        var year = date.getFullYear();
        var month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        var day = date.getDate().toString().padStart(2, '0');

        // Form the "yyyy-mm-dd" format
        var formattedDate = year + '-' + month + '-' + day;
        return formattedDate;

    }
    const validateDate = (dateString) => {
        if (Array.isArray(dateString)) {
            return dateString.every(date => validateSingleDate(date));
        } else if (typeof dateString === 'string') {
            return validateSingleDate(dateString);
        }
        
        return false; // Handle invalid input
    };
    
    const validateSingleDate = (date) => {
        const dateParts = date.split("-");
        const year = parseInt(dateParts[0], 10);
        const month = parseInt(dateParts[1], 10);
        const day = parseInt(dateParts[2], 10);
    
        const isValidYear = year >= 2000 && year <= 9999;
        const isValidMonthDay = month >= 1 && month <= 12 && day >= 1 && day <= 31;
    
        return isValidYear && isValidMonthDay;
    };

    const onSubmit = (data) => {
        //new code
        writeToLogFile("onSubmit() call ");
        const historyApiUrl = "/create-historic-npl-dx-data";
        const hccChecklistApiUrl = props.aweRightSidebarType?.project_id && props.aweRightSidebarType?.project_id == '6' ? "/create-chart-chase-hcc-checklist-remark" : "/create-hcc-checklist-remark";
        const updateMemberHccApiUrl = props.aweRightSidebarType?.project_id && props.aweRightSidebarType?.project_id == '6' ? "/update-chart-chase-member-dx-details" : "/update-member-hcc-details";
        const saveApiUrl = props?.Id ? `data/cw_dx/${props.Id}` : 'data/cw_dx/save';
        const controlUser = JSON.parse(localStorage.getItem('user'));
        const controlUserId = JSON.parse(localStorage.getItem("controlUser"))?.user_id;
        data.LAST_UPDATED_BY = controlUser?.userEmail;
        let history = {}

        // console.log('onSubmit', dxCodeValue, data);

        // if (!isdxCodeValue || !dxCodeValue) {
        //     toast.error('DX CODE is Invalid. Please select');
        //     return true;
        // }
        var totalLength = dataInputs.length;
        for (let index = 0; index <= totalLength; index++) {
            if (data[`DX_CODE_${index}`] && data[`DX_CODE_${index}`] != '' && data[`DX_CODE_${index}`] != 'undefined' && data[`DX_CODE_${index}`].length > 8 && data[`DX_CODE_${index}`].includes("-")) {

            } else {
                if (data[`DX_CODE_${index}`] == '' || data[`DX_CODE_${index}`] === undefined) {
                    data[`DX_CODE_${index}`] = '';
                } else {
                    toast.error(data[`DX_CODE_${index}`] + ' DX CODE is Invalid. Please select');
                    return true;
                }
            }
        }
        // Helper function to format date
        const formatDate = date => Moment(date).format("YYYY-MM-DD");

        // Common reset function for form values
        const resetFormValues = () => {
            const fieldsToReset = ['ID', 'PAGE_NO', 'IH_MEMBER_ID', 'DX_CODE', 'ENTITY_ID', 'ENTITY_TEXT', 'REMARKS', 'LAST_UPDATED_ON', 'LAST_UPDATED_BY',
                'DOS_Begin', 'DOS_End', 'HCC_Code', 'hcc_v28_value', 'insufficient_document'];
            fieldsToReset.forEach(field => setValue(field, ''));
            setFormSubmitisLoading(false);
        };


        // Function to handle API calls
        const handleApiCall = (api, url, method, data, successMessage, errorMessage) => {
            setFormSubmitisLoading(true);
            writeToLogFile("handleApiCall() "+ "api, url, method, data, successMessage, errorMessage" + api, url, method, data, successMessage, errorMessage);
            api[method](url, data)
                .then(res => {
                    resetFormValues();
                    if (successMessage != '')
                        toast.success(successMessage);
                    setFormSubmitisLoading(false);
                    props.handleClose()
                    props.currentAWERightSidebarCloseDatatableReload(true);
                    notificationRightDrawer(false);
                })
                .catch(err => {
                    if (errorMessage != '') {
                        toast.error(err?.response?.data?.message);
                    }
                    setFormSubmitisLoading(false);
                });
        };

        var totalLength = dataInputs.length;
        var DX_CODE_Temp = [];
        var HCC_Code_Temp = [];
        var hcc_v28_value_Temp = [];
        var RXHCC_v05_Temp = [];
        var RXHCC_v08_Temp = [];
        if (tabvalue && tabvalue == '2') {
            var DOS_Begin_Temp = [];
            var DOS_End_Temp = [];
            var insufficient_document_Temp = [];
            var CW_DX_STATUS_Temp = [];
            var PAGE_NO_Temp = [];
            var REMARK_Temp = [];
        }

        for (let index = 0; index <= totalLength; index++) {
            if (data[`DX_CODE_${index}`] && data[`DX_CODE_${index}`] != '' && data[`DX_CODE_${index}`] != 'undefined') {
                if (tabvalue && tabvalue == '2') {
                    if (DX_CODE_Temp.includes(data[`DX_CODE_${index}`]) && DOS_Begin_Temp.includes(data[`DOS_Begin_${index}`])) {
                        toast.error('"' + data[`DX_CODE_${index}`] + '" already added for given date.');
                        return true;
                    } else {
                        DX_CODE_Temp.push(data[`DX_CODE_${index}`]);
                    }
                } else {
                    if (!DX_CODE_Temp.includes(data[`DX_CODE_${index}`])) {
                        DX_CODE_Temp.push(data[`DX_CODE_${index}`]);
                    } else {
                        toast.error('"' + data[`DX_CODE_${index}`] + '" already added for given date.');
                        return true;
                    }
                }
                HCC_Code_Temp.push(data[`HCC_Code_${index}`]);
                hcc_v28_value_Temp.push(data[`hcc_v28_value_${index}`]);
                RXHCC_v05_Temp.push(data[`RXHCC_v05_${index}`]);
                RXHCC_v08_Temp.push(data[`RXHCC_v08_${index}`]);
                if (tabvalue && tabvalue == '2') {
                    DOS_Begin_Temp.push(formatDate(data[`DOS_Begin_${index}`]));
                    DOS_End_Temp.push(formatDate(data[`DOS_End_${index}`]));
                    insufficient_document_Temp.push(data[`insufficient_document_${index}`]);
                    CW_DX_STATUS_Temp.push((data[`CW_DX_STATUS_${index}`] === undefined) ? 'Accepted' : data[`CW_DX_STATUS_${index}`]);
                    PAGE_NO_Temp.push(data[`PAGE_NO_${index}`]);
                    REMARK_Temp.push(data[`REMARK_${index}`]);
                }
            }
        }
        data.DX_CODE = DX_CODE_Temp;
        data.HCC_Code = HCC_Code_Temp;
        data.hcc_v28_value = hcc_v28_value_Temp;
        data.RXHCC_v05 = RXHCC_v05_Temp;
        data.RXHCC_v08 = RXHCC_v08_Temp;
        if (tabvalue && tabvalue == '2') {
            data.DOS_Begin = DOS_Begin_Temp;
            data.DOS_End = DOS_End_Temp;
            data.CW_DX_STATUS = CW_DX_STATUS_Temp;
            data.PAGE_NO = PAGE_NO_Temp;
            data.insufficient_document = insufficient_document_Temp;
            data.REMARK = REMARK_Temp;
        } else {
            data.DOS_Begin = formatDate(data.DOS_Begin);
            data.DOS_End = formatDate(data.DOS_End);
            data.CW_DX_STATUS = (data.CW_DX_STATUS === undefined) ? 'Accepted' : data.CW_DX_STATUS;
            data.PAGE_NO = data.PAGE_NO;
            data.insufficient_document = data.insufficient_document;
            data.REMARK = data.REMARK;
        }

        // Prepare data for save
        const saveData = {
            "CREATED_BY": controlUser?.userEmail,
            "DOS_Begin": data.DOS_Begin,
            "DOS_End": data.DOS_End,
            "RXHCC_v05": data.RXHCC_v05,
            "RXHCC_v08": data.RXHCC_v08,
            "lob": props.lobSelect,
            "organisation_id": '1',
            "year": props.yearSelect,
            "insufficient_document": data.insufficient_document,
            "HCC_v24": data.HCC_Code,
            "HCC_V28": data.hcc_v28_value,
            "IH_MEMBER_ID": btoa(data.IH_MEMBER_ID),
            "DX_CODE": data.DX_CODE,
            //"CW_DX_STATUS": data.CW_DX_STATUS,
            "CW_DX_STATUS": data.CW_DX_STATUS,
            "REMARKS": data.REMARK,
            "LAST_UPDATED_BY": controlUser?.userEmail,
            "PAGE_NO": data.PAGE_NO,
            //"rendering_provider": data.rendering_provider ? data.rendering_provider : '',
            //"billing_provider": data.billing_provider ? data.billing_provider : '',
            "project_id": props.aweRightSidebarType?.project_id ? props.aweRightSidebarType?.project_id : null,
            "is_present_coder_workbench": props?.is_cw_present,
            "coder_workbench_id": props?.cw_id,
            "coder_workbench":
            {
                "ih_member_id": data.IH_MEMBER_ID,
                'cl_member_id': data.IH_MEMBER_ID,
                "member_f_name": props?.member_f_name,
                "member_l_name": props?.member_l_name,
                "member_dob": props?.member_dob,
                "created_by": controlUser?.userEmail,
                "updated_by": controlUser?.userEmail,
                "year": props.yearSelect,
                "lob": props.lobSelect,
                "awe_id": btoa(String(props?.awe_display_id)),
                "awv_backend_id": btoa(props?.awv_backend_id)
            },
            'log_api': process.env.REACT_APP_LOG_URL
        };
        writeToLogFile("saveData: " + JSON.stringify(saveData));
        const dosBegin = data.DOS_Begin;
        const dosEnd = data.DOS_End;

        if (validateDate(dosBegin) && validateDate(dosEnd)) {
            handleApiCall(AWVCoderWorkBench, saveApiUrl, 'post', saveData, 'Created successfully', 'Error while creating the record');
            setGenerateLog(true)
        } else {
            toast.error('Please enter a valid date');
            setGenerateLog(true)
            return true;
        }
    }

    const handleChangeDate = (event, index = 0) => {
        handleManualValidation()
        if (tabvalue && tabvalue == '2') {
            if (event?.target?.name == `DOS_Begin_${index}`) {
                setValue(`DOS_Begin_${index}`, event?.target?.value);
                setValue(`DOS_End_${index}`, event?.target?.value);
            } else {
                setValue(`DOS_End_${index}`, event?.target?.value);
            }
        } else {
            if (event?.target?.name == `DOS_Begin`) {
                setValue(`DOS_Begin`, event?.target?.value);
                setValue(`DOS_End`, event?.target?.value);
            } else {
                setValue(`DOS_End`, event?.target?.value);
            }
        }
    }


    const handleManualValidation = async () => {
        try {
            // Trigger manual revalidation for all fields
            await trigger();
        } catch (error) {
            //console.error('Manual validation error:', error);
        }
    };

    const handleAddInput = () => {
        setDataInputs([...dataInputs, '']);
    };

    const handleRemoveInput = (index) => {
        // console.log('handleRemoveInput before', { index }, { dataInputs }, dxCodeData, dxCodeValue, getValues())

        const newInputs = [...dataInputs];
        newInputs.splice((index - 1), 1);
        setDataInputs(newInputs);

        if (tabvalue && tabvalue == '2') {
            setValue(`DX_CODE_${index}`, '');
            setValue(`HCC_Code_${index}`, '');
            setValue(`hcc_v28_value_${index}`, '');
            setValue(`RXHCC_v05_${index}`, '');
            setValue(`RXHCC_v08_${index}`, '');
            setValue(`DOS_Begin_${index}`, '');
            setValue(`DOS_End_${index}`, '');
            setValue(`insufficient_document_${index}`, '');
            setValue(`PAGE_NO_${index}`, '');
            setValue(`CW_DX_STATUS_${index}`, 'Accepted');
            setValue(`REMARK_${index}`, '');
        } else {
            setValue(`DX_CODE_${index}`, '');
            setValue(`HCC_Code_${index}`, '');
            setValue(`hcc_v28_value_${index}`, '');
            setValue(`RXHCC_v05_${index}`, '');
            setValue(`RXHCC_v08_${index}`, '');
        }

        var totalLength = dataInputs.length;
        for (let indexTemp = index; indexTemp <= totalLength; indexTemp++) {
            if (tabvalue && tabvalue == '2') {
                setValue(`DX_CODE_${indexTemp}`, (getValues(`DX_CODE_${indexTemp + 1}`)) ? getValues(`DX_CODE_${indexTemp + 1}`) : '');
                setValue(`HCC_Code_${indexTemp}`, (getValues(`HCC_Code_${indexTemp + 1}`)) ? getValues(`HCC_Code_${indexTemp + 1}`) : '');
                setValue(`hcc_v28_value_${indexTemp}`, (getValues(`hcc_v28_value_${indexTemp + 1}`)) ? getValues(`hcc_v28_value_${indexTemp + 1}`) : '');
                setValue(`RXHCC_v05_${indexTemp}`, (getValues(`RXHCC_v05_${indexTemp + 1}`)) ? getValues(`RXHCC_v05_${indexTemp + 1}`) : '');
                setValue(`RXHCC_v08_${indexTemp}`, (getValues(`RXHCC_v08_${indexTemp + 1}`)) ? getValues(`RXHCC_v08_${indexTemp + 1}`) : '');
                setValue(`DOS_Begin_${indexTemp}`, (getValues(`DOS_Begin_${indexTemp + 1}`)) ? getValues(`DOS_Begin_${indexTemp + 1}`) : '');
                setValue(`DOS_End_${indexTemp}`, (getValues(`DOS_End_${indexTemp + 1}`)) ? getValues(`DOS_End_${indexTemp + 1}`) : '');
                setValue(`insufficient_document_${indexTemp}`, (getValues(`insufficient_document_${indexTemp + 1}`)) ? getValues(`insufficient_document_${indexTemp + 1}`) : '');
                setValue(`PAGE_NO_${indexTemp}`, (getValues(`PAGE_NO_${indexTemp + 1}`)) ? getValues(`PAGE_NO_${indexTemp + 1}`) : '');
                setValue(`CW_DX_STATUS_${indexTemp}`, (getValues(`CW_DX_STATUS_${indexTemp + 1}`)) ? getValues(`CW_DX_STATUS_${indexTemp + 1}`) : '');
                setValue(`REMARK_${indexTemp}`, (getValues(`REMARK_${indexTemp + 1}`)) ? getValues(`REMARK_${indexTemp + 1}`) : '');
            } else {
                setValue(`DX_CODE_${indexTemp}`, (getValues(`DX_CODE_${indexTemp + 1}`)) ? getValues(`DX_CODE_${indexTemp + 1}`) : '');
                setValue(`HCC_Code_${indexTemp}`, (getValues(`HCC_Code_${indexTemp + 1}`)) ? getValues(`HCC_Code_${indexTemp + 1}`) : '');
                setValue(`hcc_v28_value_${indexTemp}`, (getValues(`hcc_v28_value_${indexTemp + 1}`)) ? getValues(`hcc_v28_value_${indexTemp + 1}`) : '');
                setValue(`RXHCC_v05_${indexTemp}`, (getValues(`RXHCC_v05_${indexTemp + 1}`)) ? getValues(`RXHCC_v05_${indexTemp + 1}`) : '');
                setValue(`RXHCC_v08_${indexTemp}`, (getValues(`RXHCC_v08_${indexTemp + 1}`)) ? getValues(`RXHCC_v08_${indexTemp + 1}`) : '');
            }
        }

        const newInputsTest = [...dxCodeData];
        newInputsTest.splice(index, 1);
        setDxCodeData(newInputsTest);

        const newInputsTest1 = [...dxCodeValue];
        newInputsTest1.splice(index, 1);
        setDxCodeValues(newInputsTest1);


        // console.log('handleRemoveInput after', { index }, { dataInputs }, { dxCodeValue }, { dxCodeData }, getValues())
        // setDxCodeValues
        // const [dxCodeData, setDxCodeData] = useState([]);
        // const [dxCodeValue, setDxCodeValues] = useState([]);
    };

    const handleTabChange = (newValue) => {
        setTabvalue(newValue);
    };
        // Regular expression pattern to allow only alphanumeric characters and spaces
        const numericPattern = /^[0-9]*$/;
        
    useEffect(() => {
        // Generate and download log file when logMessages have been updated
        if (logMessages.length > 0  && generateLog === true) {
        LogFileUploadToS3(logMessages,  localStorage.getItem("access_key"), localStorage.getItem("secret_key"), process.env.REACT_APP_LOG_BUCKET, "Add_Multiple_DX_Code")
        }
    }, [generateLog === true, logMessages, cin, props.yearSelect, props?.awe_display_id, props.lobSelect]); 

    return (
        <div>
            <div className='d-flex flex-row-reverse mr-1'>
                <div>
                    {/* <Tooltip title="Add Menu"> */}
                    {/* <Button type="button" alignItems="center" color="primary" onClick={() => props.handleClickOpen('')}>
                            <AddCircleOutlineIcon style={{ 'fontSize': '30px' }} />
                        </Button> */}
                    <IconButton type="button" onClick={() => props.handleClickOpen('')}>
                        <PlusFillIcon className="w16" />
                    </IconButton>
                    {/* </Tooltip> */}
                </div>
            </div>
            <MuiDialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth={true}
                maxWidth={'xl'}
            >
                <DialogTitle id="create-group" onClose={props.handleClose}>
                    {props.editMenuData && props.editMenuData.id && props.editMenuData.id != '' ?
                        'Update Menu'
                        :
                        'Add Bulk DX Code - ' + getValues('IH_MEMBER_ID')
                    }
                </DialogTitle>
                <div className="offcanvas-body pt-1 custom-scrollbar overflow-auto">
                    <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                        <DialogContent >
                            <div className="tabs_wrapper mb20 overflow-auto custom-scrollbar">
                                <ul className="nav nav-tabs flex-nowrap" id="myTab" role="tablist" style={{ minWidth: "460px" }}>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="home-tab" data-toggle="tab" data-target="#single-dx-code" type="button"
                                            role="tab" aria-controls="single-dx-code" aria-selected="true" onClick={() => handleTabChange(1)}
                                        >
                                            Multiple DX Code Same Date
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="profile-tab" data-toggle="tab" data-target="#multiple-dx-code" type="button"
                                            role="tab" aria-controls="multiple-dx-code" aria-selected="false" onClick={() => handleTabChange(2)}
                                        >
                                            Multiple DX Code Different Date
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content" id="myTabContent">
                                <div className={(tabvalue && tabvalue == '1') ? "tab-pane fade show active" : "tab-pane fade"} id="single-dx-code" role="tabpanel" aria-labelledby="single-dx-code-tab">
                                    {(tabvalue && tabvalue == '1') ?
                                        <div className={'mui_custom_table'} style={{ minHeight: "100px" }}>
                                            <div class="table-responsive custom-scrollbar">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">
                                                                Dx CODE
                                                            </th>
                                                            {props.lobSelect == '2' && props.aweRightSidebarType?.project_id != '6' ?
                                                                <>
                                                                    <th scope="col">
                                                                        HCC V24
                                                                    </th>
                                                                    <th scope="col">
                                                                        HCC V28
                                                                    </th>
                                                                </>
                                                                :
                                                                <th scope="col">
                                                                    HCC
                                                                </th>
                                                            }
                                                            <th scope="col">
                                                                DOS START
                                                            </th>
                                                            <th scope="col">
                                                                DOS END
                                                            </th>
                                                            <th scope="col">
                                                                INSUFFICIENT DOCUMENTATION
                                                            </th>
                                                            <th scope="col">
                                                                PAGE NO
                                                            </th>
                                                            <th scope="col">
                                                                Status
                                                            </th>
                                                            <th scope="col">
                                                                REMARK
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="form-group mb20 custom-autocomplete">
                                                                    {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Dx CODE<span style={{ color: 'red' }}> *</span></label> */}
                                                                    <Controller
                                                                        name={`DX_CODE_0`}
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <CustomAutocomplete
                                                                                {...field}
                                                                                id={`DX_CODE_0`}
                                                                                index={0}
                                                                                value={getValues(`DX_CODE_0`)}
                                                                                onChange={(event) => handleInputChangeDxCode(event, event?.target?.value, 0)}
                                                                                handleOnClick={(event) => handleChangeDxCode(event, 0)}
                                                                                options={(dxCodeData && dxCodeData.length > 0) ? dxCodeData[0] : []}
                                                                                optionTitle={'title'}
                                                                                optionValue={'value'}
                                                                                optionListCustome={true}
                                                                                editDataValue={true}
                                                                                placeholder="Type to search..."
                                                                                autoComplete="off"
                                                                                className="form-control"
                                                                                ModelOpen={props?.open}
                                                                            />
                                                                        )}
                                                                        rules={{
                                                                            required: true,
                                                                        }}
                                                                    />
                                                                    {errors && errors?.DX_CODE_0?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                </div>
                                                            </td>
                                                            {props.lobSelect == '2' && props.aweRightSidebarType?.project_id != '6' ?
                                                                <>
                                                                    <td>
                                                                        <div className="form-group mb20 custom-autocomplete">
                                                                            {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">HCC V24 </label> */}
                                                                            <Controller
                                                                                name={`HCC_Code_${0}`}
                                                                                value={getValues(`HCC_Code_${0}`)}
                                                                                control={control}
                                                                                render={({ field }) => (
                                                                                    <input
                                                                                        {...field}
                                                                                        type="text"
                                                                                        placeholder="Type to search..."
                                                                                        autoComplete="off"
                                                                                        className="form-control"
                                                                                        readOnly={true}
                                                                                    />
                                                                                )}
                                                                                rules={{
                                                                                    required: false,
                                                                                }}
                                                                            />
                                                                            {errors && errors?.HCC_Code_0?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group mb20 custom-autocomplete">
                                                                            {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">HCC V28 </label> */}
                                                                            <Controller
                                                                                name={`hcc_v28_value_${0}`}
                                                                                value={getValues(`hcc_v28_value_${0}`)}
                                                                                control={control}
                                                                                render={({ field }) => (
                                                                                    <input
                                                                                        {...field}
                                                                                        type="text"
                                                                                        placeholder="Type to search..."
                                                                                        autoComplete="off"
                                                                                        className="form-control"
                                                                                        readOnly={true}
                                                                                    />
                                                                                )
                                                                                }
                                                                                rules={{
                                                                                    required: false,
                                                                                }}
                                                                            />
                                                                            {errors && errors?.hcc_v28_value_0?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                        </div>
                                                                    </td>
                                                                </>
                                                                :
                                                                <td>
                                                                    <div className="form-group mb20 custom-autocomplete">
                                                                        {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">HCC V24 </label> */}
                                                                        <Controller
                                                                            name={`HCC_Code_${0}`}
                                                                            value={getValues(`HCC_Code_${0}`)}
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <input
                                                                                    {...field}
                                                                                    type="text"
                                                                                    placeholder="Type to search..."
                                                                                    autoComplete="off"
                                                                                    className="form-control"
                                                                                    readOnly={true}
                                                                                />
                                                                            )}
                                                                            rules={{
                                                                                required: false,
                                                                            }}
                                                                        />
                                                                        {errors && errors?.HCC_Code_0?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                    </div>
                                                                </td>
                                                            }
                                                            <td>
                                                                <div className="form-group mb20">
                                                                    {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">DOS START <span style={{ color: 'red' }}> *</span></label> */}
                                                                    <Controller
                                                                        name="DOS_Begin"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <input type="date" className="form-control" {...field} onChange={handleChangeDate} />
                                                                        )}
                                                                        rules={{
                                                                            required: true,
                                                                        }}
                                                                    />
                                                                    {errors?.DOS_Begin?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="form-group mb20">
                                                                    {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">DOS END <span style={{ color: 'red' }}> *</span></label> */}
                                                                    <Controller
                                                                        name="DOS_End"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <input type="date" {...field} className="form-control" min={Moment(getValues(`DOS_Begin`)).format("YYYY-MM-DD")} onChange={handleChangeDate} />
                                                                        )}
                                                                        rules={{
                                                                            required: true,
                                                                        }}
                                                                    />
                                                                    {errors?.DOS_End?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="form-group mb20">
                                                                    {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">INSUFFICIENT DOCUMENTATION</label> */}
                                                                    <div className="flex_item_cs gap8">
                                                                        <Controller
                                                                            name="insufficient_document"
                                                                            value={editFormData?.insufficient_document == 0 ? "No" : "Yes"}
                                                                            control={control}
                                                                            render={({ field }) => (
                                                                                <select
                                                                                    {...field}
                                                                                    className="form-control"
                                                                                    onClick={(e) => setValue("insufficient_document", e.target.value)}
                                                                                >
                                                                                    <option value={''} >
                                                                                        Select
                                                                                    </option>
                                                                                    <option value={'1'} >
                                                                                        Yes
                                                                                    </option>
                                                                                    <option value={'0'} >
                                                                                        No
                                                                                    </option>
                                                                                </select>
                                                                            )}
                                                                            rules={{
                                                                                required: false,
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    {errors?.insufficient_document?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="form-group mb20">
                                                                    {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">REMARK</label> */}
                                                                    <Controller
                                                                        name="PAGE_NO"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <input type="text" {...field} className="form-control" />
                                                                        )}
                                                                        rules={{
                                                                            required: false,
                                                                            pattern: {
                                                                                value: numericPattern,
                                                                                message: 'Special character are not allowed',
                                                                            },
                                                                        }}
                                                                    />
                                                                    {errors?.PAGE_NO?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="form-group mb20">
                                                                    {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Status <span style={{ color: 'red' }}> *</span></label> */}
                                                                    <Controller
                                                                        name="CW_DX_STATUS"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <select
                                                                                {...field}
                                                                                className="form-control"
                                                                            /*onChange={handleChangeDate}*/
                                                                                style={{ paddingRight: "30px" }}

                                                                            >

                                                                                <option value={'Accepted'} selected>
                                                                                    Accepted
                                                                                </option>
                                                                                <option value={'Rejected'} >
                                                                                    Rejected
                                                                                </option>
                                                                                {props.aweRightSidebarType?.project_id != '6' ?
                                                                                    <option value={'Pending for clarification'} >
                                                                                        Pending for Clarification
                                                                                    </option>
                                                                                    :
                                                                                    <></>
                                                                                }
                                                                            </select>
                                                                        )}
                                                                    /*rules={{
                                                                        required: true,
                                                                    }}*/
                                                                    />
                                                                    {errors?.CW_DX_STATUS?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="form-group mb20">
                                                                    {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">REMARK</label> */}
                                                                    <Controller
                                                                        name="REMARK"
                                                                        control={control}
                                                                        render={({ field }) => (
                                                                            <textarea rows={1} {...field} className="form-control" />
                                                                        )}
                                                                        rules={{
                                                                            required: false,
                                                                        }}
                                                                    />
                                                                    {errors?.REMARKS?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        {dataInputs && dataInputs.length > 0 ?
                                                            dataInputs.map((input, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <div className="form-group mb20 custom-autocomplete">
                                                                            {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Dx CODE<span style={{ color: 'red' }}> *</span></label> */}
                                                                            <Controller
                                                                                name={`DX_CODE_${(index + 1)}`}
                                                                                control={control}
                                                                                render={({ field }) => (
                                                                                    <CustomAutocomplete
                                                                                        {...field}
                                                                                        id={`DX_CODE${(index + 1)}`}
                                                                                        index={(index + 1)}
                                                                                        value={getValues(`DX_CODE_${(index + 1)}`)}
                                                                                        onChange={(event) => handleInputChangeDxCode(event, event?.target?.value, (index + 1))}
                                                                                        handleOnClick={(event) => handleChangeDxCode(event, (index + 1))}
                                                                                        options={(dxCodeData && dxCodeData.length > 0) ? dxCodeData[(index + 1)] : []}
                                                                                        optionTitle={'title'}
                                                                                        optionValue={'value'}
                                                                                        optionListCustome={true}
                                                                                        editDataValue={true}
                                                                                        placeholder="Type to search..."
                                                                                        autoComplete="off"
                                                                                        className="form-control"
                                                                                    />
                                                                                )}
                                                                                rules={{
                                                                                    required: false,
                                                                                }}
                                                                            />
                                                                            {errors && errors[`DX_CODE_${(index + 1)}`]?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                        </div>
                                                                    </td>
                                                                    {props.lobSelect == '2' && props.aweRightSidebarType?.project_id != '6' ?
                                                                        <>
                                                                            <td>
                                                                                <div className="form-group mb20 custom-autocomplete">
                                                                                    {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">HCC V24 </label> */}
                                                                                    <Controller
                                                                                        name={`HCC_Code_${(index + 1)}`}
                                                                                        value={getValues(`HCC_Code_${(index + 1)}`)}
                                                                                        control={control}
                                                                                        render={({ field }) => (
                                                                                            <input
                                                                                                {...field}
                                                                                                type="text"
                                                                                                placeholder="Type to search..."
                                                                                                autoComplete="off"
                                                                                                className="form-control"
                                                                                                readOnly={true}
                                                                                            />
                                                                                        )}
                                                                                        rules={{
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    {errors && errors[`HCC_Code_${(index + 1)}`]?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="form-group mb20 custom-autocomplete">
                                                                                    {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">HCC V28 </label> */}
                                                                                    <Controller
                                                                                        name={`hcc_v28_value_${(index + 1)}`}
                                                                                        value={getValues(`hcc_v28_value_${(index + 1)}`)}
                                                                                        control={control}
                                                                                        render={({ field }) => (
                                                                                            <input
                                                                                                {...field}
                                                                                                type="text"
                                                                                                placeholder="Type to search..."
                                                                                                autoComplete="off"
                                                                                                className="form-control"
                                                                                                readOnly={true}
                                                                                            />
                                                                                        )
                                                                                        }
                                                                                        rules={{
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    {errors && errors[`hcc_v28_value_${(index + 1)}`]?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                                </div>
                                                                            </td>
                                                                        </>
                                                                        :
                                                                        <td>
                                                                            <div className="form-group mb20 custom-autocomplete">
                                                                                {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">HCC V24 </label> */}
                                                                                <Controller
                                                                                    name={`HCC_Code_${(index + 1)}`}
                                                                                    value={getValues(`HCC_Code_${(index + 1)}`)}
                                                                                    control={control}
                                                                                    render={({ field }) => (
                                                                                        <input
                                                                                            {...field}
                                                                                            type="text"
                                                                                            placeholder="Type to search..."
                                                                                            autoComplete="off"
                                                                                            className="form-control"
                                                                                            readOnly={true}
                                                                                        />
                                                                                    )}
                                                                                    rules={{
                                                                                        required: false,
                                                                                    }}
                                                                                />
                                                                                {errors && errors[`HCC_Code_${(index + 1)}`]?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                            </div>
                                                                        </td>
                                                                    }
                                                                    <td>
                                                                        <Button type='button' color="danger" size={'sm'} className="mb-3 mr-2 sm" onClick={() => handleRemoveInput((index+1))}><DeleteRoundedIcon className="w16" /></Button>
                                                                    </td>
                                                                    <td colspan="6"></td>
                                                                </tr>
                                                            ))
                                                            :
                                                            <tr>
                                                                <td></td>
                                                                <td></td>
                                                                <td></td>
                                                                <td>

                                                                </td>
                                                                <td colspan="6"></td>
                                                            </tr>
                                                        }
                                                        <tr>
                                                            <td colspan="9">
                                                                {dataInputs && dataInputs ?
                                                                    <Button type='button' size={'sm'} className="mr-2 sm" onClick={handleAddInput}>Add New Row</Button>
                                                                    :
                                                                    <></>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                                <div className={(tabvalue && tabvalue == '2') ? "tab-pane fade show active" : "tab-pane fade"} id="multiple-dx-code" role="tabpanel" aria-labelledby="multiple-dx-code-tab">
                                    {(tabvalue && tabvalue == '2') ?
                                        <div className={'mui_custom_table'} style={{ minHeight: "100px" }}>
                                            <div class="table-responsive custom-scrollbar">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">
                                                                Dx CODE
                                                            </th>
                                                            {props.lobSelect == '2' && props.aweRightSidebarType?.project_id != '6' ?
                                                                <>
                                                                    <th scope="col">
                                                                        HCC V24
                                                                    </th>
                                                                    <th scope="col">
                                                                        HCC V28
                                                                    </th>
                                                                </>
                                                                :
                                                                <th scope="col">
                                                                    HCC
                                                                </th>
                                                            }
                                                            <th scope="col">
                                                                DOS START
                                                            </th>
                                                            <th scope="col">
                                                                DOS END
                                                            </th>
                                                            <th scope="col">
                                                                INSUFFICIENT DOCUMENTATION
                                                            </th>
                                                            <th scope="col">
                                                                PAGE NO
                                                            </th>
                                                            <th scope="col">
                                                                Status
                                                            </th>
                                                            <th scope="col">
                                                                REMARK
                                                            </th>
                                                            <th scope="col">
                                                                Action
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {dataInputs && dataInputs.length > 0 ?
                                                            dataInputs.map((data, index) => (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <div className="form-group mb20 custom-autocomplete">
                                                                            {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Dx CODE<span style={{ color: 'red' }}> *</span></label> */}
                                                                            <Controller
                                                                                name={`DX_CODE_${(index)}`}
                                                                                control={control}
                                                                                render={({ field }) => (
                                                                                    <CustomAutocomplete
                                                                                        {...field}
                                                                                        id={`DX_CODE[${index}]`}
                                                                                        index={index}
                                                                                        value={getValues(`DX_CODE_${(index)}`)}
                                                                                        onChange={(event) => handleInputChangeDxCode(event, event?.target?.value, index)}
                                                                                        handleOnClick={(event) => handleChangeDxCode(event, index)}
                                                                                        options={(dxCodeData && dxCodeData.length > 0) ? dxCodeData[index] : []}
                                                                                        optionTitle={'title'}
                                                                                        optionValue={'value'}
                                                                                        optionListCustome={true}
                                                                                        editDataValue={true}
                                                                                        placeholder="Type to search..."
                                                                                        autoComplete="off"
                                                                                        className="form-control"
                                                                                        ModelOpen={props?.open}
                                                                                    />
                                                                                )}
                                                                                rules={{
                                                                                    required: true,
                                                                                }}
                                                                            />
                                                                            {errors && errors[`DX_CODE_${(index)}`]?.type && (typeof errors[`DX_CODE_${(index)}`]?.ref?.value !== 'undefined' ? errors[`DX_CODE_${(index)}`]?.ref?.value === '' : true) && <label className="form-input-error">This field is required</label>}
                                                                        </div>
                                                                    </td>
                                                                    {props.lobSelect == '2' && props.aweRightSidebarType?.project_id != '6' ? (
                                                                        <>
                                                                            <td>
                                                                                <div className="form-group mb20 custom-autocomplete">
                                                                                    {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">HCC V24 </label> */}
                                                                                    <Controller
                                                                                        name={`HCC_Code_${(index)}`}
                                                                                        value={getValues(`HCC_Code_${(index)}`)}
                                                                                        control={control}
                                                                                        render={({ field }) => (
                                                                                            <input
                                                                                                {...field}
                                                                                                type="text"
                                                                                                placeholder="Type to search..."
                                                                                                autoComplete="off"
                                                                                                className="form-control"
                                                                                                readOnly={true}
                                                                                            />
                                                                                        )}
                                                                                        rules={{
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    {errors && errors[`HCC_Code_${(index)}`]?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="form-group mb20 custom-autocomplete">
                                                                                    {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">HCC V28 </label> */}
                                                                                    <Controller
                                                                                        name={`hcc_v28_value_${(index)}`}
                                                                                        value={getValues(`hcc_v28_value_${(index)}`)}
                                                                                        control={control}
                                                                                        render={({ field }) => (
                                                                                            <input
                                                                                                {...field}
                                                                                                type="text"
                                                                                                placeholder="Type to search..."
                                                                                                autoComplete="off"
                                                                                                className="form-control"
                                                                                                readOnly={true}
                                                                                            />
                                                                                        )
                                                                                        }
                                                                                        rules={{
                                                                                            required: false,
                                                                                        }}
                                                                                    />
                                                                                    {errors && errors[`hcc_v28_value_${(index)}`]?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                                </div>
                                                                            </td>
                                                                        </>)
                                                                        :
                                                                        <td>
                                                                            <div className="form-group mb20 custom-autocomplete">
                                                                                {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">HCC V28 </label> */}
                                                                                <Controller
                                                                                    name={`HCC_Code_${(index)}`}
                                                                                    value={getValues(`HCC_Code_${(index)}`)}
                                                                                    control={control}
                                                                                    render={({ field }) => (
                                                                                        <input
                                                                                            {...field}
                                                                                            type="text"
                                                                                            placeholder="Type to search..."
                                                                                            autoComplete="off"
                                                                                            className="form-control"
                                                                                            readOnly={true}
                                                                                        />
                                                                                    )
                                                                                    }
                                                                                    rules={{
                                                                                        required: false,
                                                                                    }}
                                                                                />
                                                                                {errors && errors[`HCC_Code_${(index)}`]?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                            </div>
                                                                        </td>
                                                                    }
                                                                    <td>
                                                                        <div className="form-group mb20">
                                                                            {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">DOS START <span style={{ color: 'red' }}> *</span></label> */}
                                                                            <Controller
                                                                                name={`DOS_Begin_${index}`}
                                                                                control={control}
                                                                                render={({ field }) => (
                                                                                    <input type="date" className="form-control" {...field} onChange={(event) => handleChangeDate(event, index)} />
                                                                                )}
                                                                                rules={{
                                                                                    required: true,
                                                                                }}
                                                                            />
                                                                            {errors && errors[`DOS_Begin_${(index)}`]?.type === "required" && (typeof errors[`DOS_Begin_${(index)}`]?.ref?.value !== 'undefined' ? errors[`DOS_Begin_${(index)}`]?.ref?.value === '' : true) && <label className="form-input-error">This field is required</label>}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group mb20">
                                                                            {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">DOS END <span style={{ color: 'red' }}> *</span></label> */}
                                                                            <Controller
                                                                                name={`DOS_End_${index}`}
                                                                                control={control}
                                                                                render={({ field }) => (
                                                                                    <input type="date"  {...field} className="form-control" min={Moment(getValues(`DOS_Begin_${(index)}`)).format("YYYY-MM-DD")} onChange={(event) => handleChangeDate(event, index)} />
                                                                                )}
                                                                                rules={{
                                                                                    required: true,
                                                                                }}
                                                                            />
                                                                            {errors && errors[`DOS_End_${(index)}`]?.type === "required" && (typeof errors[`DOS_End_${(index)}`]?.ref?.value !== 'undefined' ? errors[`DOS_End_${(index)}`]?.ref?.value === '' : true) && <label className="form-input-error">This field is required</label>}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group mb20">
                                                                            {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">INSUFFICIENT DOCUMENTATION</label> */}
                                                                            <div className="flex_item_cs gap8">
                                                                                <Controller
                                                                                    name={`insufficient_document_${index}`}
                                                                                    control={control}
                                                                                    render={({ field }) => (
                                                                                        <select
                                                                                            {...field}
                                                                                            className="form-control"
                                                                                            onClick={(e) => setValue(`insufficient_document_${index}`, e.target.value)}
                                                                                        >
                                                                                            <option value={''} >
                                                                                                Select
                                                                                            </option>
                                                                                            <option value={'1'} >
                                                                                                Yes
                                                                                            </option>
                                                                                            <option value={'0'} >
                                                                                                No
                                                                                            </option>
                                                                                        </select>
                                                                                    )}
                                                                                    rules={{
                                                                                        required: false,
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            {errors && errors[`insufficient_document_${(index)}`]?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group mb20">
                                                                            {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">REMARKS</label> */}
                                                                            <Controller
                                                                                name={`PAGE_NO_${index}`}
                                                                                control={control}
                                                                                render={({ field }) => (
                                                                                    <input type="text" {...field} className="form-control" />
                                                                                )}
                                                                                rules={{
                                                                                    required: false,
                                                                                    pattern: {
                                                                                        value: numericPattern,
                                                                                        message: 'special character not allowed',
                                                                                    },
                                                                                }}
                                                                            />
                                                                            {errors && errors[`PAGE_NO_${(index)}`]?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                            {errors[`PAGE_NO_${(index)}`]?.type === 'pattern' && (
                                                                                <p className="form-input-error">{errors[`PAGE_NO_${(index)}`].message}</p>
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group mb20">
                                                                            {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">Status <span style={{ color: 'red' }}> *</span></label> */}
                                                                            <Controller
                                                                                name={`CW_DX_STATUS_${index}`}
                                                                                control={control}
                                                                                render={({ field }) => (
                                                                                    <select
                                                                                        {...field}
                                                                                        className="form-control"
                                                                                        style={{ paddingRight: "30px" }}

                                                                                    >
                                                                                        <option value={'Accepted'} >
                                                                                            Accepted
                                                                                        </option>
                                                                                        <option value={'Rejected'} >
                                                                                            Rejected
                                                                                        </option>
                                                                                        {props.aweRightSidebarType?.project_id != '6' ?
                                                                                            <option value={'Pending for clarification'} >
                                                                                                Pending for Clarification
                                                                                            </option>
                                                                                            :
                                                                                            <></>
                                                                                        }
                                                                                    </select>
                                                                                )}
                                                                            />
                                                                            {errors && errors[`CW_DX_STATUS_${(index)}`]?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="form-group mb20">
                                                                            {/* <label htmlFor="" className="text_c3A3C40 fs13 mb-2">REMARKS</label> */}
                                                                            <Controller
                                                                                name={`REMARKS_${index}`}
                                                                                control={control}
                                                                                render={({ field }) => (
                                                                                    <textarea rows={1} value={data?.REMARKS} {...field} className="form-control" />
                                                                                )}
                                                                                rules={{
                                                                                    required: false,
                                                                                }}
                                                                            />
                                                                            {errors && errors[`REMARKS_${(index)}`]?.type === "required" && <label className="form-input-error">This field is required</label>}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        {index != 0 ?
                                                                            <Button type='button' color="danger" size={'sm'} className="mb-3 mr-2 sm" onClick={() => handleRemoveInput((index))}><DeleteRoundedIcon className="w16" /></Button>
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            ))
                                                            :
                                                            <></>
                                                        }
                                                        <tr>
                                                            <td colspan="10">
                                                                {dataInputs && dataInputs ?
                                                                    <Button type='button' size={'sm'} className="mr-2 sm" onClick={handleAddInput}>Add New Row</Button>
                                                                    :
                                                                    <></>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions className='mt-2'>
                            <div className="form-group flex_item_cs gap10">
                                <Button type='button' className={"btn-white minw80"} onClick={() => props.handleClose()}>CANCEL</Button>
                                <Button type='submit' className={"minw80"} disabled={formSubmitisLoading && formSubmitisLoading == true ? true : false}>SAVE</Button>
                            </div>
                        </DialogActions>
                    </form>
                </div>
            </MuiDialog>
        </div>
    );
}


const mapStateToProps = (state) => {
    return {
        yearSelect: state.moduleFilter.yearSelect,
        lobSelect: state.moduleFilter.lobSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        aweRightSidebarType: state.moduleFilter.aweRightSidebarType,
        aweRightSidebar: state.moduleFilter.aweRightSidebar,
    };
}
export default (withStyles(styles), connect(mapStateToProps, { currentAWERightSidebarType, currentAWERightSidebar, currentAWERightSidebarCloseDatatableReload }))(AddCWDXCoderWorkBench);
