import React, { useState, useReducer, useEffect } from 'react';
import Chart from "react-google-charts";
import api from '../../assets/constants/Rafservice.Instance';

const TreeMapDemo = () => {
    const [ChartData, setChartData] = useState([]);
    const loadHeatChart = () => {
        let newChartData = [];
        newChartData.push(["Description"]);
        api.get('/acaMemberGapCountforAllYears')
            .then(res => {
                let data;
                for (let i = 0; i < res.length; i++) {
                    data = res[i];
                    let AllKeys = Object.keys(data);
                    let AllValues = Object.values(data);
                    let year = AllValues[0];
                    let a = 0;
                    for (const dataObj of AllKeys) {
                        // // console.log("wordtree", res[i]);
                        if (a > 2 && AllValues[a]>0) {
                            newChartData.push([year.toString() + " " + dataObj + " " + AllValues[a]]);
                        }
                        a++;
                    }
                }
                if (ChartData.length === 0) {
                    // console.log("word-tree##", newChartData)                    
                    setChartData(newChartData);
                }

            })
    }
    useEffect(() => {
        // console.log('code-use-effect');
        loadHeatChart();
    });

    return (
        <>
            <div className="container">
        <Chart
            data={[
                ['Year', 'Sales', 'Expenses', 'Profit'],
                ['2014', 1000, 400, 200],
                ['2015', 1170, 460, 250],
                ['2016', 660, 1120, 300],
                ['2017', 1030, 540, 350],
            ]}
            chartType="Bar"
            width={600}
            height={400}
            options={{
                chartArea: { width: '50%', height: '75%' },
                isStacked: true,
                legend: { position: 'top',maxLines: 1},
                vAxis: { minValue: 0},
                is3D: true,
            }}
            rootProps={{ 'data-testid': '1' }}
        />
        </div>
        </>);
};


export default TreeMapDemo;
