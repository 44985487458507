import React, { useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const HtmlEditorComponent = (props) => {

  // Configuration for the editor's toolbar
  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // Basic formatting options
    // ['blockquote', 'code-block'], // Block elements
    // [{ 'header': [1, 2, false] }], // Headers
    // [{ 'list': 'ordered' }, { 'list': 'bullet' }], // Lists
    // [{ 'script': 'sub' }, { 'script': 'super' }], // Subscript and superscript
    // [{ 'indent': '-1' }, { 'indent': '+1' }], // Indentation
    // [{ 'direction': 'rtl' }], // Text direction
    // [{ 'size': ['small', false, 'large', 'huge'] }], // Font sizes
    // [{ 'header': [1, 2, 3, 4, 5, 6, false] }], // Headers
    // ['link', 'image', 'video'], // Embed links, images, and videos
    // [{ 'color': [] }, { 'background': [] }], // Text and background colors
    // [{ 'font': [] }], // Font styles
    // [{ 'align': [] }], // Alignment options
    // ['clean'], // Remove formatting
  ];

  return (
    <Box sx={{ width: '100%', maxWidth: 630, mb: 2, mt: 2 }}>
      <ReactQuill
        theme="snow"
        {...props}
        style={{ height: '300px', marginBottom: '16px' }}
        modules={{
          toolbar: toolbarOptions, // Use the defined toolbar
        }}
      />
    </Box>
  );
};

export default HtmlEditorComponent;
