import React, { useState, useEffect } from 'react';
import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import AWVApi from '../../assets/constants/AWVRafservice.Instance';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContentText from '@material-ui/core/DialogContentText';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import { connect } from 'react-redux';
import { useForm, Controller } from "react-hook-form";
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import Divider from '@mui/material/Divider';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import pdfIcon from '../../assets/images/pdf_icon.png';
import Moment from 'moment';
import { CircularProgress } from '@material-ui/core';
import axios from "axios";
import AWS from 'aws-sdk';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const aws = require('aws-sdk');

const S3_BUCKET = 'bucket-awv-form-upload'; //process.env.REACT_APP_BUCKET_NAME_Two;
const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = localStorage.getItem("access_key");
const SECRET_ACCESS_KEY = localStorage.getItem("secret_key");


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const styles = (theme) => ({
    dialogPaper: {
        maxWidth: '80%',
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const AWVMemberDetailsAllCoder = (props) => {
    const { register, formState: { errors }, handleSubmit, reset, control, getValues, setValue } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;


    useEffect(() => {
        setValue('awe_id', String(props?.editFormData?.AWE_ID));
        setValue('cin', props?.editFormData?.CIN);
    }, [props.editFormData]);

    const onSubmit = (data) => {
        setIsLoading(true);
        if (props?.editFormData?.CIN && props?.editFormData?.CIN != '') {
            let controlUser = JSON.parse(localStorage.getItem('controlUser'));
            // data.cin = props?.editFormData?.CIN;
            if (props?.editFormData?.action && props?.editFormData?.action == 'Add') {
                return true;
                // data.created_by = controlUser?.user_id;
                // data.created_on = Moment().format('YYYY-MM-DD HH:mm:ss');
                // AWVApi.post('/create-hcc-details', data)
                //     .then(res => {
                //         setValue('remarks', '');
                //         setValue('status', '');
                //         setValue('checklist_id', '');
                //         setValue('awe_id', '');
                //         setValue('cin', '');
                //         props.loadFormData();
                //         setIsLoading(false);
                //         toast.success('Coder Record add success');
                //         props.handleClose();
                //     })
                //     .catch(err => {
                //         setIsLoading(false);
                //         toast.error(err?.response?.data?.message);
                //     })
            } else {
                data.checklist = [];
                props?.editFormData?.coderRecordList && props?.editFormData?.coderRecordList.length > 0 && props?.editFormData?.coderRecordList.map((element, index) =>
                    data.checklist.push({
                        'remarks': (data.remark[index] && data.remark[index] != 'null') ? data.remark[index] : '',
                        'status': (data.status[index] && data.status[index] != 'null') ? data.status[index] : '',
                        'checklist_id': element.checklist_id.toString()
                    })
                );

                if (props?.editFormData?.coderRecordList && data.checklist) {
                    data.updated_by = controlUser?.user_id;
                    delete data.remark;
                    delete data.status;
                    AWVApi.put('https://elhd4maxlwzu4ycswkmkqow3oy0muplw.lambda-url.us-east-1.on.aws/v1/update_awe_coder_checklist', data)
                        .then(res => {
                            setValue('awe_id', '');
                            setValue('cin', '');
                            props.loadFormData(props?.editFormData?.CIN, props?.editFormData?.AWE_ID);
                            setIsLoading(false);
                            toast.success('Your submission of checklist is accepted for updation, it will be updated soon.');
                            props.handleClose();
                        })
                        .catch(err => {
                            setIsLoading(false);
                            toast.error(err?.response?.data?.message);
                        })
                }

            }
        }

    };


    return (
        <div>
            <MuiDialog
                open={props.open}
                onClose={props.handleClose}
                fullWidth={true}
                maxWidth={'md'}
                scroll={'body'}
                className="awv_model_layout"
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle id="create-group" onClose={props.handleClose}>
                    <strong>
                        {(props?.editFormData?.action && props?.editFormData?.action == 'Add') ?
                            'CODER CHECKLIST ADD'
                            :
                            'CODER CHECKLIST EDIT'
                        }
                    </strong>
                </DialogTitle>
                <Divider />
                <div className='row'>
                    <div className='col-lg-12'>
                        <form onSubmit={handleSubmit(onSubmit)} id="add-verification-model">
                            <DialogContent >
                                {isLoading && isLoading == true ?
                                    <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                                        <CircularProgress />
                                    </div>
                                    :
                                    <></>
                                }
                                <div className="row m-2">
                                    <TableContainer component={Paper}>
                                        <Table aria-label="customized table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell style={{ width: "25%" }}>AWE SOURCE</StyledTableCell>
                                                    <StyledTableCell style={{ width: "25%" }}>Status</StyledTableCell>
                                                    <StyledTableCell style={{ width: "50%" }}>REMARKS</StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {props?.editFormData?.coderRecordList && props?.editFormData?.coderRecordList.length > 0 && props?.editFormData?.coderRecordList.map((element, index) =>
                                                    <StyledTableRow key={index} style={{ backgroundColor: "#fff" }}>
                                                        <StyledTableCell align="center">
                                                            <div>{element?.description}</div>
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '100px' }}>
                                                                {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                                                <Controller
                                                                    className="input-control"
                                                                    name={"status[" + index + "]"}
                                                                    value={element?.status}
                                                                    defaultValue={element?.status}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        // <TextField  {...field} variant="outlined" />
                                                                        <Select
                                                                            {...field}
                                                                            labelId="module-multiple-checkbox-label"
                                                                            id="module-multiple-checkbox"
                                                                            // value={selectModule}
                                                                            // onChange={handleChange}
                                                                            // label="Module"
                                                                            variant="outlined"
                                                                            // MenuProps={MenuProps}
                                                                            menuPlacement="top"
                                                                        >
                                                                            <MenuItem key={'Confirmed'} value={'1'} >
                                                                                Confirmed
                                                                            </MenuItem>
                                                                            <MenuItem key={'not_confirmed'} value={'0'} >
                                                                                Not Confirmed
                                                                            </MenuItem>
                                                                        </Select>
                                                                    )}
                                                                />
                                                                {errors?.status?.type === "required" && <label className="text-danger">This field is required</label>}
                                                            </FormControl>
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            <FormControl fullWidth margin="dense" variant="outlined" style={{ 'min-width': '200px' }}>
                                                                {/* <InputLabel id="demo-simple-select-outlined-label">User Name</InputLabel> */}
                                                                <Controller
                                                                    className="input-control"
                                                                    name={"remark[" + index + "]"}
                                                                    value={element?.remark}
                                                                    defaultValue={element?.remark}
                                                                    key={index}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <TextField  {...field} variant="outlined" />
                                                                    )}
                                                                />
                                                                {errors?.remark?.type === "required" && <label className="text-danger">This field is required</label>}
                                                            </FormControl>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>

                            </DialogContent>
                            <DialogActions className='mt-2'>
                                <Button type='button' variant="contained" color="grey" onClick={props.handleClose}>CANCEL</Button>
                                <Button type='submit'
                                    className={'mr-2 btn-custom-primary'}
                                    variant="contained"
                                    disabled={isLoading && isLoading == true ? true : false}
                                >
                                    {(props?.editFormData?.action && props?.editFormData?.action == 'Add') ?
                                        'ADD'
                                        :
                                        'UPDATE'
                                    }
                                </Button>
                            </DialogActions>
                        </form>
                    </div>
                </div>
            </MuiDialog>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        userDetails: state.auth.userDetails,
        yearSelect: state.moduleFilter.yearSelect,
        providerGroupSelect: state.moduleFilter.providerGroupSelect,
        lobSelect: state.moduleFilter.lobSelect,
    };
}
export default (withStyles(styles), connect(mapStateToProps))(AWVMemberDetailsAllCoder);
