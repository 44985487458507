import React, { useState, useEffect } from "react";
import api from '../../assets/constants/Rafservice.Instance';
import WidgetCard from "../../components/Cards/WidgetCard";
import SingleVerticalBarChart from '../../components/Charts/SingleVerticalBar.Chart';
import AcaYearSelect from '../../components/DropDowns/AcaYearSelect';
import { getAcaYearList, getAcaYear } from '../../assets/constants/yearList';
import ServerSideGridTable from "../../components/TableGrids/ServerSideGridTable.table";
import { anchorTag, hccSeperator, numberWithCommas } from '../../assets/constants/customRender'
import history from "../../history";
import { MAO_MEMBER_FORCAST, WIDGET_TYPES } from "../../assets/constants/string.constants";
import { percentFormat } from "../../assets/helpers/formats.helpers";
import CardComponent from "../../components/Cards/card";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import GChartClick from '../../components/GChart/GChartClick';
import IssuersACA from '../ACA/ACAIssuers.page';
import SlickSlider from '../../components/SlickSlider/SlickSlider';
import SubHeader from '../../components/home/header/subHeader';
import { getViewBySelect } from '../../assets/constants/viewByList';
import { getModelSelect } from '../../assets/constants/modelList';
import { getMetalTierSelect } from '../../assets/constants/metalTierList';
import { getPlanSelect } from '../../assets/constants/planList';
import { getModuleSelect } from '../../assets/constants/moduleList';
import Link from '@material-ui/core/Link';
import BarVertical from '../../components/GChart/BarVertical';
import { connect } from 'react-redux';
const MaoForecastSummaryPage = (props) => {

    const [ProviderSummaryChartData, setProviderSummaryChartData] = useState([]);
    const [ProviderRevenueChartData, setProviderRevenueChartData] = useState([]);
    const [WidgetDetails, setWidgetDetails] = useState({});


    const WidgetDetailsType = [
        WIDGET_TYPES.LOCKICON,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.PERCENT,
        WIDGET_TYPES.SCORE,
        WIDGET_TYPES.MEMBERS,
        WIDGET_TYPES.TRENDING,
        WIDGET_TYPES.PERCENT,
    ]

    const type = WidgetDetailsType;
    const widgetStyles = {
        position: 'absolute',
        left: 0,
        //right: 20,
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0.15,
        color: "white"
    };

    const loadWidgetDetails = () => {
        api.get('/forecastSummary/type/TOTAL_MEMBERS/year/'+props.yearSelect)
        .then(res => {
            setWidgetDetails({
                '# Existing Members': Number(res[0].existing_member).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                '# Projected Members': Number(res[0].projected_member).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                'Forecasted Avg RAF Score': res[0].forecasted_avg_raf,
                'Forecasted Revenue': '$'+ Number(res[0].forecasted_revenue).toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
           });
        })
        .catch(err => {
            // console.log(err);
        });
    };

    const getIcon = (type) => {
        var data = ''
        switch (type) {
            case WIDGET_TYPES.ISSUER:
                data = <AccountBalanceTwoToneIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.MEMBERS:
                data = <PeopleAltIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERCENT:
                data = <ShowChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.SCORE:
                data = <BarChartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.PERSON:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.CHILD:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.ADULT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.INFANT:
                data = <PersonIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.REVENUE:
                data = <AttachMoneyIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.COUNT:
                data = <ShoppingCartIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.LOCKICON:
                data = <LockOpenIcon style={widgetStyles} />
                break;
            case WIDGET_TYPES.TRENDING:
                data = <TrendingUpIcon style={widgetStyles} />
                break;
        }

        return data;
    }


    const loadProviderAddChartdData = () => {        
        let newChartData = [];
            newChartData.push(["Type of members", "RAF Score", { role: 'annotation' }]);
            api.get('/forecastSummarydetail/'+props.yearSelect)
                .then(res => {
                    for (const dataObj of res) {
                        newChartData.push([dataObj.name, dataObj.value, dataObj.value]);
                    }
                    setProviderSummaryChartData(newChartData);
            })
            .catch(err => {
                // console.log(err);
            })
    }
 
    const loadProviderDeleteChartdData = () => {        
        let newChartData = [];
            newChartData.push(["Type of members", "Revenue in Millions ($)", { role: 'annotation' }]);
            api.get('/forecastSummarydetailByRevenue/'+props.yearSelect)
                .then(res => {
                    for (const dataObj of res) {
                        newChartData.push([dataObj.name, dataObj.value, dataObj.value]);
                    }
                    setProviderRevenueChartData(newChartData);
            })
            .catch(err => {
                // console.log(err);
            })
    }


    useEffect(() => { 
        loadProviderAddChartdData();      
        loadProviderDeleteChartdData();   
        loadWidgetDetails();          
    },  [
        props.moduleSelect,
        props.viewBySelect,
        props.modelSelect,
        props.metalTierSelect,
        props.planSelect,
        props.yearSelect,
        props.meInitialSelect
    ]);


    return (
        <div className="container level-summary">
            <div className="row">
                <div className="col-lg-12 pr-0 pl-0">
                    <div className="state-level">
                        <div className="card-header">
                            <SubHeader title={'Financial Forecasted Summary'} />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0">
                    <div className="state-level">          
                        {(Object.entries(WidgetDetails).length > 0) ?                     
                            <SlickSlider WidgetDetails={WidgetDetails} getIcon={getIcon} type={type} />
                        :
                            <></>
                        }                       
                    </div>
                </div>
                <div className="col-lg-12 pr-0 pl-0 mt-4">
                    <div className="list-member">
                        <CardComponent
                            heading={`POPULATION WISE SPLITUP`}
                            body={
                                    <div className="col-lg-12 row mb-5"> 
                                        <div className="col-lg-6"> 
                                            <CardComponent
                                                heading={`SPLIT OF REVENUE (IN MILLIONS)`}
                                                body={
                                                        <BarVertical data={ProviderRevenueChartData} chartType="BarChart" height="300px" width="100%" yAxistitle={'Type of members'} hAxisTitle={'Revenue in Millions ($)'} setStorgeItemName={MAO_MEMBER_FORCAST} redirectUrl={'medicare/member_forecast'}></BarVertical>
                                                            }>
                                            </CardComponent>
                                        </div>
                                        <div className="col-lg-6"> 
                                            <CardComponent
                                                heading={`SPLIT OF RAF SCORE`}
                                                body={
                                                        <BarVertical data={ProviderSummaryChartData} chartType="BarChart" height="300px" width="100%" yAxistitle={'Type of members'} hAxisTitle={'RAF Score'} setStorgeItemName={MAO_MEMBER_FORCAST} redirectUrl={'medicare/member_forecast'}></BarVertical>
                                                            }>
                                            </CardComponent>
                                        </div>
                                    </div>
                                }>
                        </CardComponent>
                    </div>
                </div>
            </div>
            <div className="col-lg-12 pr-0 pl-0 mt-5"></div>
                <br/>

                <br/>
                <br/> 

        </div>
    )
};
const mapStateToProps = (state) => {
    return {
        isSignedIn: state.auth.isSignedIn,
        moduleSelect: state.moduleFilter.moduleSelect,
        viewBySelect: state.moduleFilter.viewBySelect,
        modelSelect: state.moduleFilter.modelSelect,
        metalTierSelect: state.moduleFilter.metalTierSelect,
        planSelect: state.moduleFilter.planSelect,
        yearSelect: state.moduleFilter.yearSelect,                
        meInitialSelect: state.moduleFilter.meInitialSelect,
    };
  }
export default connect(mapStateToProps)(MaoForecastSummaryPage);
