import React, { useState, useReducer, useEffect } from 'react';
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../components/Tab/TabPanel";
import CardComponent from "../../components/Cards/card";
import SubHeader from '../../components/home/header/subHeader';
import {
    Plan_Performance_Dashboard,
    Provider_Group,
    Provider_Performance
} from "../../assets/constants/string.constants";
import HealthcareInsights from './healthcare_insights';
import ProviderGroup from './provider_group';
import ProviderPerformance from './provider_performance';


function a11yProps(index) {
    return {
        id: `wrapped-tab-${index}`,
        "aria-controls": `wrapped-tabpanel-${index}`,
    };
}

const HealthcareDashboardPage = () => {
    const [value, setValue] = useState(Plan_Performance_Dashboard);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <>
            <div className="container level-summary">
                <div className="row pb-2">
                    <div className="col-lg-12">
                        <div className="state-level">
                            <div className="card-header">
                                <SubHeader title={'HealthCare Insights'} selectType={'KPL'} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-3">
                    <AppBar position="static" color="default">
                        <Tabs
                            variant="fullWidth"
                            textColor="primary"
                            indicatorColor="primary"
                            value={value}
                            onChange={handleChange}
                            centered
                        >
                            <Tab
                                value={Plan_Performance_Dashboard}
                                label="Plan Performance Dashboard"
                                {...a11yProps(Plan_Performance_Dashboard)}
                            />
                            <Tab
                                value={Provider_Group}
                                label="Provider Group"
                                {...a11yProps(Provider_Group)}
                            />
                            <Tab
                                value={Provider_Performance}
                                label="Provider Performance"
                                {...a11yProps(Provider_Performance)}
                            />
                        </Tabs>
                    </AppBar>
                    <br />
                    <TabPanel value={value} index={Plan_Performance_Dashboard}>
                        <div className="row">
                            <div className="col-lg-12 pr-0">
                                <HealthcareInsights handleChange={handleChange} />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={Provider_Group}>
                        <div className="row mb-5">
                            <div className="col-lg-12 pr-0">
                                <ProviderGroup handleChange={handleChange} />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={Provider_Performance}>
                        <div className="row mb-5">
                            <div className="col-lg-12 pr-0">
                                <ProviderPerformance handleChange={handleChange} />
                            </div>
                        </div>
                    </TabPanel>
                </div>
            </div>
        </>);
};
export default HealthcareDashboardPage;
